import React from "react"
import styled from "styled-components"

const Error = styled.div`
  background: orangered;
  display: flex;
  color: white;
  font-weight: bold;
  justify-content: center;
  padding: 0.5em;
`

const CartaoErro = () => (
  <Error>
    Houve um erro ao efetuar a cobrança do seu plano, favor revisar ou atualizar os dados do seu cartão!
  </Error>
)

export default CartaoErro
