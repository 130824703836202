import React from "react";

export const USER_ROLE = {
  STUDENT: "Aluno",
  TUTOR: "Tutor",
  ADMINISTRATOR: "Administrador",
  MODERATOR: "Moderador",
  MEMBERS: "Membros"
};

export const ICON = {
  MENU_BARS: "icon-menu",
  TRACKS: "icon-tracks",
  BACK_ARROW: "icon-back",
  NEXT_ARROW: "icon-next",
  FIRST_ARROW: "icon-previous",
  LAST_ARROW: "icon-last",
  DOWN_ARROW: "fa fa-angle-down",
  UP_ARROW: "icon-up",
  USER: "icon-user",
  USERS: "icon-users",
  CIRCLE: "icon-circle",
  PAPER_CLIP: "icon-attach",
  CALENDAR: "icon-calendar",
  CLOSE: "icon-close",
  HEART: "icon-heart",
  BELL: "icon-bell",
  SEARCH: "icon-search",
  TROPHY: "icon-trophy",
  PLAY: "icon-play",
  SHARE: "icon-share",
  SEND: "icon-send",
  CLOSE_MODAL: "icon-close-x",
  MESSAGE: "icon-message",
  CITE: "icon-cite",
  LIKE: "icon-like",
  SAD: "icon-sad",
  DISLIKE: "icon-dislike",
  FILE: "icon-file",
  PDF: "icon-pdf",
  IMAGE: "icon-image",
  JPG: "icon-jpg",
  VIDEO: "icon-video",
  ELLIPSIS: "icon-more",
  REMOVE: "icon-remove",
  SLIDERS: "icon-controls",
  PENCIL: "icon--pencil",
  NOTIFICATION: "icon-notification",
  GRADUATION: "icon-formation",
  CONTACTS: "icon-contacts",
  LOCK: "icon-lock",
  UNLOCK: "icon-unlock",
  QUEST: "icon-quest",
  GLOBE: "icon-globe",
  TIME: "icon-time",
  FILES: "icon-files",
  RIBBON: "icon-ribbon",
  COGS: "icon-cogs",
  MONEY: "icon-money",
  USEFUL: "icon-useful",
  USELESS: "icon-useless",
  MARKED_USEFUL: "icon-marked-useful",
  MARKED_USELESS: "icon-marked-useless",
  ENVELOPE: "icon-envelope"
};


export const REACTIONS = {
  ACT_LIKE: {
    ICON: ICON.HEART,
    LABEL: 'Gostei',
  },
  ACT_GRR: {
    ICON: ICON.SAD,
    LABEL: 'Não gostei'
  },
  ACT_CARD: {
    ICON_LIKE: ICON.USEFUL,
    ICON_GRR: ICON.USELESS,
    ICON_ACTIVE_LIKE: ICON.MARKED_USEFUL,
    ICON_ACTIVE_GRR: ICON.MARKED_USELESS,
    LABEL_REACTION: 'Gostou?',
    LABEL_LIKE: 'Gostou!',
    LABEL_GRR: 'Não Gostou!'
  }
}

export const TIMELINE_ACTIONS = {
  ORDER: "Mais recentes",
  RESPONSES: "Respostas",
  MENTIONS: "Menções",
  COURSE_SUGESTIONS: "Cursos Sugeridos",
  GROUP_SUGESTIONS: "Grupos Sugeridos",
  CONVERSATIONS: "Minhas Conversas",
  LIKE: "Curtir",
  DISLIKE: "Descurtir",
  RETRY: "Responder",
  HIDE: "Esconder",
  CITE: "Citar",
  EDIT_COMMENT: "Editar Comentário",
  REMOVE_COMMENT: "Remover Comentário",
  REMOVE_POST: "Remover Postagem",
  RETRY_COMMENT: "Responder Comentário",
  NEW_POST: "Criar uma nova conversa",
  EDIT_POST: "Editar Postagem",
  ITERATIONS: "18",
  MOST_COMMENTED: "Mais comentadas",
  MOST_RECENT: "Mais recentes",
  INVITE_MEMBER: "Convidar Membro",
  EDIT_GROUP: "Editar Grupo",
  LIST_REACTIONS: "Ver interações",
  MANAGE_MEMBERS: "Gerenciar Solicitações",
  ACCESS: "Acessar",
  REACT: "Interagir",
};

export const ACTION = {
  CANCEL: "Cancelar",
  CONFIRM: "Confirmar",
  SAVE: "Salvar",
  INVITE: "Convidar",
  GUEST: "Convidado",
  ACCEPT: "Aceitar",
  DECLINE: "Recusar",
  VIEW_ALL: "Ver Todos",
  RESEND: "Reenviar",
  CLEAR: "Limpar",
  LEAVE: "Sair",
  JOIN: "Participar",
  SELECT_MEMBER_ROLE: "Selecione o cargo do membro",
  SELECT: "Selecione...",
  LEAVE_GROUP: "Sair do Grupo",
  JOIN_GROUP: "Participar do Grupo",
  SOLICITATION_PENDING: "Solicitação pendente",
  CANCEL_SOLICITATION: "Cancelar solicitação",
  CREATE_GROUP: "Criar Grupo",
  EDIT_GROUP: "Editar Grupo",
  TRANSFER_DOMAIN: "Transferir Posse",
  ALTER_ROLE: "Alterar cargo",
  DEMOVE_MEMBER: "Demover Membro",
  INVITE_MEMBER: "Convidar Membro",
  WRITE_POST: "Criar nova conversa",
  NEW_CONTENT_POST: "Esse campo serve para interação entre os alunos e alunas da plataforma. Poste aqui seus comentários sobre as pílulas de aprendizagem (vídeos, textos, infográficos, etc.). Seus comentários poderão ser vistos, curtidos e respondidos por outros alunos e alunas.",
  NEW_GROUP_CONVERSATION: "Escreva abaixo as informações para iniciar uma nova conversa no grupo",
  MODIFY_GROUP_CONVERSATION: "Modifique abaixo as informações de sua postagem no grupo",
  MORE_NOTIFICATIONS_GROUP: "Mais notificações sobre grupos",
  MORE_NOTIFICATIONS: "Mais notificações",
  CUSTOMIZE_STUDIES: "Personalizar estudos",
  EDIT_PROFILE: "Editar Perfil",
  SEARCH_GROUP: "Buscar Grupo",
  SEARCH_MEMBER: "Buscar Membro",
  SEARCH_GROUP_OR_MEMBER: "Buscar Grupo ou Membro",
  VIEW_INVITES: "Ver Solicitações",
  SEARCH: "Buscar",
  PLACEHOLDER_TITLE: "Ex: Gestão Financeira",
  MARK_AS_DOUBT: "Marcar como dúvida",
  REMOVE_MEMBER: "Remover Membro",
  PROMOVE_MEMBER: "Promover Membro",
  DROP_ARCHIVE: "Arraste e solte o arquivo aqui",
  IMAGE_ALT: "Attachment Image",
  TO_SEARCH: "Realizar Busca",
  SEARCH_RESULTS: "Resultados da Busca",
  ADVANCED_SEARCH: "Pesquisa Avançada",
  SEE_MORE: "Ver mais",
  SEE_MINUS: "Ver menos",
  SELECT_ROLE: "Selecione um cargo para continuar",
  CONTINUE_COURSE: "Retome seu curso atual",
  RETRY: "Responder",
  RETRY_QUEST: "respondeu sua dúvida",
  VIEW_MORE: "Ver Mais",
  VIEW_PROFILE: "Ver Perfil",
  ACCESS: "Acessar",
  SEND_NOW: "Enviar Agora",
  REPLY: "Responder",
  HIDE: "Esconder",
  RETRY_COMMENT: "Responder Comentário",
  RECENTS: "Mais recentes"
};

export const CONFIRMATION = {
  CONFIRM_JOIN_GROUP: "Para postar no grupo você deve fazer parte dele. Deseja realmente participar?",
  CONFIRM_REMOVE_MEMBER: "Deseja realmente remover esse membro?",
  CONFIRM_PROMOVE_MEMBER: "Deseja realmente promover esse membro?",
  CONFIRM_DEMOVE_MEMBER: "Deseja realmente demover esse membro?",
  CONFIRM_TRANSFER_DOMAIN: "Deseja realmente transferir a posse desse grupo?",
  CONFIRM_ALTER_MEMBER_ROLE: "Deseja realmente alterar o cargo desse membro?",
  SUCCESS_SOLICITATION: "Solicitação enviada com sucesso!",
  WAIT_RESPONSE_ADMINISTRATOR: "Aguarde resposta dos administradores do grupo.",
  WHAT_SUBJECT_SEARCH: "Qual tipo de assunto deseja abordar?",
  HOW_DO_YOU_TIME: "Quanto tempo você tem?",
  CONFIRM_REMOVE_POST: "Deseja realmente remover essa postagem?",
};

export const PAGE_INFORMATION = {
  GROUP_MEMBERS: "Membros do Grupo",
  PENDING_REQUESTS: "Solicitações Pendentes",
  RECOMENDED_GROUPS: "Grupos Recomendados",
  COURSES: "Cursos",
  GROUP: "Grupo",
  GROUPS: "Grupos",
  MEMBERS: "Membros",
  MEMBER: "Membro",
  POSTS: "Postagens",
  CONTACTS: "Contatos",
  SOCIAL: "Social",
  CONTENT: "Conteúdo",
  YOUR_HAVE: "Você possui",
  MY_GROUPS: "Meus Grupos",
  MY_COURSES: "Meus Cursos",
  MY_POSTS: "Minhas Postagens",
  ABOUT_ME: "Sobre Mim",
  ABOUT: "Sobre",
  EXPERTISE: "Especialidades",
  SCHOOL_GRADE: "Formação Acadêmica",
  PROFESSIONAL_EXPERIENCE: "Experiência Profissional",
  LINKEDIN_PROFILE: "Perfil do Linkedin:",
  PROFILE_OF: "Perfil do",
  PROFILE: "Perfil",
  AWARDS: "Conquistas",
  MY_INVITES: "Meus Convites",
  MY_SOLICITATIONS: "Minhas Solicitações",
  COURSES_TRAIL_COMPLETED: "Cursos e Trilhas Concluídos",
  GROUP_NAME: "Nome do Grupo",
  PRIVACY: "Privacidade",
  GROUP_PRIVACY: "Privacidade do Grupo",
  GROUP_DESCRIPTION: "Descrição do Grupo",
  AREAS_OF_INTEREST: "Áreas de Interesse",
  MEMBER_SINCE: "Membro desde",
  POST_DATE: "Data da publicação",
  ADMINISTERED_BY: "Administrado por ",
  PENDING_INVITES: "Convites Pendentes",
  INVITED_YOU_GROUP: "Convidou você para participar do grupo",
  CONTENT_TYPE: "Tipo de Conteúdo",
  MATERIAL_TYPE: "Tipo de Material",
  PUBLIC_GROUPS: "Grupos Públicos",
  PRIVATE_GROUPS: "Grupos Privados",
  TEACHERS: "Professores",
  STUDENTS: "Alunos",
  HOT_COURSES: "Hot Courses",
  LIT_TALKS: "LIT Talks",
  WEBINARS: "Webinars",
  TUTORIALS: "Tutoriais",
  BOOKS: "Livros",
  VIDEOS: "Videos",
  FORUMS: "Fóruns",
  PDFS: "PDFs",
  AVALIATIONS: "Avaliações",
  MORE: "Saiba Mais",
  VISIBILITY: "Visibilidade",
  SCORMS: "Scorms",
  COMMENTS: "Comentários",
  PUBLISHED_IN: "Publicado em",
  DATE_PUBLISHED: "na data de",
  PUBLISHED_DATE: "Publicado na data de",
  PUBLISHED_WHEN: "Há",
  IN: "em",
  WHO_LIKED: "Veja quem gostou desta postagem",
  INVITED_BY: "Convidado por",
  SOLICITATION_DATE: "Data da solicitação",
  WHO_INTERACTED: "Veja quem interagiu nesta postagem",
  THEY_LIKED: "Curtiram",
  LIKED: "Gostaram",
  GRR: "Não gostaram",
  THEY_DIDNT_LIKE: "Não Curtiram",
  FEED: "Feed",
  HELLO: "Olá",
  WELCOME_AGAIN: "Seja bem-vindo novamente.",
  TALKS_BY: "LIT Talks by",
  WEBINARS: "WEBINARS",
  HE: "O",
  FAVORITE: "Favorito",
  MODULES: "Módulos",
  HOURS: "horas",
  SUGESTION_PEOPLES: "Pessoas que talvez você conheça",
  SUGESTION_CONTACT: "Sugestão de Contato",
  WHO_INTERACTED: "Veja quem interagiu nesta postagem",
  DELETE_POST: "Remover Postagem"
};

export const NOTIFICATION_TYPE = {
  EVALUATION_RESULT: "Resultado de Avaliação",
  EVALUATION_PENDING: "Avaliação Pendente",
  PROFILE_CHANGES: "Nova Alteração",
  NOTIFICATION: "Nova Notificação",
  FINANCIAL_PENDENCY: "Pendência Financeira",
  GROUP_SUGESTION: "Sugestão de Grupo",
  CONTENT_SUGESTION: "Sugestão de Curso",
  COMMUNICATION: "Comunicado",
  EVALUATION: "Avaliação",
  PEOPLE_SUGESTION: "Sugestâo de Pessoas",
  LIT_TALKS: "Lit Talks",
  WEBINARS: "Webinars"
};

export const MENU_NAVIGATION = {
  PROFILE: "Meu Perfil",
  ACCOUNT: "Minha Conta",
  FEED: "Feed"
};

export const VISIBILITY_OPTIONS = {
  private: {
    value: 'private',
    label: "Privado"
  },
  public: {
    value: 'public',
    label: 'Publico'
  }
}

export const VISIBILITY_OPTIONS_SELECT = [
  {
    value: 'private',
    label: "Privado"
  },
  {
    value: 'public',
    label: 'Publico'
  }
];

export const PRIVIVACY_OPTIONS = {
  private: {
    value: 'private',
    label: "Privado"
  },
  public: {
    value: 'public',
    label: 'Publico'
  }
}

export const PRIVIVACY_OPTIONS_SELECT = [
  {
    value: 'private',
    label: "Privado"
  },
  {
    value: 'public',
    label: 'Publico'
  }
];

export const PROGRESS_INFORMATION = {
  COURSES_COMPLETED: "Cursos Concluídos",
  COURSES_IN_PROGRESS: "Cursos em Andamento",
  GROUPS: "Grupos",
  MODULE: "Módulo",
  MODULE_OF: " de "
};

export const STATUS = {
  TRUE: "true",
  FALSE: "false",
  PENDING: "Pendente",
  COMPLETED: "Concluído",
  APROVED: "Aprovado",
  REPROVED: "Reprovado",
  IN_PROGRESS: "Em andamento",
  COMPLETED_IN: "Concluído em ",
  START_DATE: "Iniciado em",
  DISABLED: "disabled",
  TIME_LEFT: "Tempo Restante",
  NOTE: "Nota Final"
};

export const ACCESS = {
  PUBLIC: "Público",
  PRIVATE: "Privado",
  LIMITED: "Limitado"
};

export const COLOR = {
  GRAY: "gray",
  LIGHT: "white",
  DARK: "black",
  DANGER: "red",
  SUCCESS: "green",
  WARNING: "yellow",
  LIGHT_GRAY: "light-gray",
  PRIMARY: "blue",
  SECONDARY: "secondary",
  TEXT_PRIMARY: "text",
  BLOCK_LIGHT: "block-light",
  BLOCK_PRIMARY: "block-primary",
  BLOCK_EDIT: "block-edit",
  BLOCK_GRADIENT: "block-gradient",
  LOADING_BAR: "#27aee1"
};

export const SIZE = {
  EXTRA_SMALL: "xsmall",
  SMALL: "small",
  MEDIUM: "medium",
  MEDIUM_LARGE: 'mlarge',
  LARGE: "large",
  EXTRA_LARGE: "xlarge"
};

export const APPEARANCE = {
  PRIMARY: "primary",
  OUTLINE: "outline",
  OUTLINE_PRIMARY: "outlinePrimary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  DARK: "dark",
  LIGHT: "light",
  GRADIENT: "gradient",
  CARD: "card",
  PROGRESS: "progress",
  AVATAR: "avatar",
  LOGO: "logo",
  LINK: "link",
  LINK_ICON_ACTIVE: "linkIconActive",
  MODAL: "modal",
  PAGINATION: "pagination",
  TAG: "tag",
  BADGE: "badge",
  CLOSE: "close",
  STRIPED: "striped",
  HIDDEN: "hidden",
  SINGLE: "single",
  MULTIPLE: "multi",
  INVISIBLE: "invisible",
  UNDERLINE: "underline",
  MODAL_CONFIRM: "modal-confirm",
  ARCHIVE_ITEM: "archive-item",
  NEW_POST: "new-post",
  TITLE: "title",
  NOTIFICATION: "notification",
  CARD_TITLE: "card-title",
  CARD_DESCRIPTION: "card-description",
  POST: "post",
  COMMENT: "comment",
  MODAL_HEADER: "modal-header",
  MODAL_SEARCH_HEADER: "modal-search-header",
  EDIT_POST: "edit-post",
  FEED: "feed",
  HEADER_PAGE: "header-page",
  BANNER_BLOCK: "banner-block",
  FILTER: "filter",
  SIMPLE_ANSWER: "simple-answer",
  EVALUATION: "evaluation",
  MORE: "more",
  CARD_REACTION: "card-reaction",
  MEMBER: "member",
  AVATAR_MEMBER: "avatar-member",
  WHITE_BUTTON: "white-button",
  BLUR: "blur",
  MOBILE_MENU: "mobile-menu"
};

export const BORDER_TYPE = {
  NO_BORDER: "noBorder",
  BORDER: "border",
  BORDER_LIGHT: "border-light"
};

export const POSITION = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
  NONE: "none",
  TOP_CENTER: "top-center",
  TOP_JUSTIFY: "top-justify",
  TOP_LEFT_CENTER: "top-left-center",
  TOP_RIGHT: "top-right",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  JUSTIFY: "justify",
  ABOVE_RIGHT: "above-right"
};

export const ORIENTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
  FULL_WIDTH: "full-width",
  AFASTABLE: "afastable",
  AJUSTABLE: "ajustable",
  DEFAULT: "default"
};

export const FONT_TYPE = {
  NORMAL: "normal",
  ITALIC: "italic",
  BOLD: "bold",
  LIGHT: "light",
  BOLDER: "bolder",
  THIN: "thin",
  MEDIUM_BOLD: "medium-bold"
}

export const TRANSFORM = {
  UPPERCASE: "uppercase",
  LOWERCASE: "lowercase",
  CAPITALIZE: "capitalize",
  FIRST_LETTER: "first-letter"
}

export const NUMBER = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4"
}

export const FORMAT = {
  ROUNDED: "rounded",
  BUTTON_1x3: "button1x3"
}

export const COMPARATION_ESPECIFIC_VARIABLE = {
  ALL_GROUPS: "allGroups",
}

export const SELECT_OPTIONS = {
  OPTION_PUBLIC: "public",
  PUBLIC: "Público",
  OPTION_PRIVATE: "private",
  PRIVATE: "Privado",
  OPTION_FINANCIAL: "finance",
  FINANCIAL: "Financeiro",
  OPTION_MANAGEMENT_PEOPLE: "management-people",
  MANAGEMENT_PEOPLE: "Gestão de Pessoas",
  OPTION_PERSONAL_FINANCES: "personal-finances",
  PERSONAL_FINANCES: "Finanças Pessoais",
  OPTION_VIRTUAL_MARKET: "virtual-market",
  VIRTUAL_MARKET: "Mercado Virtual",
  OPTION_CRYPTCOINS: "cryptocurrencies",
  CRYPTCOINS: "Criptomoedas",
  OPTION_BITCOINS: "bitcoins-market",
  BITCOINS: "Mercado Bitcoin",
  OPTION_ACCOUNTING: "applied-accounting",
  ACCOUNTING: "Contabilidade Aplicada",
  OPTION_ABUSIVE_INTEREST: "abusive-interest",
  ABUSIVE_INTEREST: "Juros Abusivos",
  OPTION_TAXES: "taxes",
  TAXES: "Impostos"
}

export const DEFAULT_VALUES = {
  LIST: [],
  OBJECT: {},
  COMPONENT: <></>,
  FUNCTION: () => { },
  STRING: "",
  NUMBER: 0,
  BOOLEAN: false,
  DATE: new Date()
};

export const TYPES = {
  GROUP_SUGESTION: "GROUP-SUGESTION",
  CONTENT_SUGESTION: "CONTENT-SUGESTION",
  COMMUNICATION: "COMMUNICATION",
  COMMUNICATION_DOUBT: "DOUBT",
  COMMUNICATION_ANSWARE: "ANSWARE",
  COMMUNICATION_MENTION: "MENTION",
  LANE: "LANE",
  PEOPLE_SUGESTION: "PEOPLE-SUGESTION",
  EVALUATION: "EVALUATION",
  EVALUATION_ITEM: "EVALUATION-ITEM"
};

