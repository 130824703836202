import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  button {
    display: flex;
    flex-direction: row;
    padding: 0;
    align-items: center;

    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #777;

    &:hover {
      opacity: 0.8;
    }

    svg {
      margin-right: 5px;
      font-size: 25px;
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    .help {
      flex-direction: row;
      align-items: center;
      margin-right: 15px;

      svg {
        margin-right: 5px;
      }
    }
  }
`;
