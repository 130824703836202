import React from "react";
import {
  SIZE,
  ACTION,
  FONT_TYPE,
  COLOR,
  CONFIRMATION,
  PAGE_INFORMATION,
  ORIENTATION,
  APPEARANCE,
  TRANSFORM,
  FORMAT
} from "../../../constants";

import ResultsSearch from "../ResultsSearch";
import PageLeftInfo from "../../../../LitAtomicComponents/containers/PageLeftInfo";
import Container from "../../../../LitAtomicComponents/containers/PageContainer";

import Actions from "../../../../LitAtomicComponents/Actions";
import Text from "../../../../LitAtomicComponents/basicComponents/Text";
import Search from "../../../../LitAtomicComponents/Inputs/Search";
import Toggle from "../../../../LitAtomicComponents/Inputs/Toggle";
import Checkbox from "../../../../LitAtomicComponents/Inputs/Checkbox";
import ComboBox from "../../../../LitAtomicComponents/Inputs/ComboBox";
import StepsRange from "../../../../LitAtomicComponents/Inputs/StepsRange";
import SpacingBlock from "../../../../LitAtomicComponents/basicComponents/SpacingBlock";

import Row30x70 from "../../../../LitAtomicComponents/defaultAreas/Row_30-70";

import { FilterBlock, Selection, SimpleBlock } from "./styles";

export default ({ groups, selectSearchOptions }) => {
  return (
    <>
      <Container>
        <Row30x70
          left={[
            <PageLeftInfo
              itemsToRender={[
                <SpacingBlock size={SIZE.MEDIUM}>
                  <FilterBlock>
                    <Search label={ACTION.TO_SEARCH} />
                  </FilterBlock>
                  <FilterBlock>
                    <ComboBox
                      placeholder={ACTION.SELECT}
                      options={selectSearchOptions}
                      label={CONFIRMATION.WHAT_SUBJECT_SEARCH}
                      name="selectSearchOptions"
                      isMulti={true}
                      fontType={FONT_TYPE.BOLD}
                      color={COLOR.DARK}
                    />
                  </FilterBlock>
                  <FilterBlock>
                    <SimpleBlock>
                      <Toggle
                        text={PAGE_INFORMATION.CONTENT}
                        transform={TRANSFORM.UPPERCASE}
                      />
                    </SimpleBlock>
                    <SimpleBlock>
                      <Text
                        text={PAGE_INFORMATION.CONTENT_TYPE}
                        size={SIZE.MEDIUM}
                        fontType={FONT_TYPE.BOLD}
                        color={COLOR.DARK}
                      />
                    </SimpleBlock>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.COURSES}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.HOT_COURSES}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.LIT_TALKS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.WEBINARS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.TUTORIALS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                  </FilterBlock>
                  <FilterBlock>
                    <SimpleBlock>
                      <Text
                        text={PAGE_INFORMATION.MATERIAL_TYPE}
                        size={SIZE.MEDIUM}
                        fontType={FONT_TYPE.BOLD}
                        color={COLOR.DARK}
                      />
                    </SimpleBlock>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.BOOKS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.VIDEOS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.FORUMS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.PDFS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.AVALIATIONS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.MORE}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                    <Selection>
                      <Checkbox
                        text={PAGE_INFORMATION.SCORMS}
                        orientation={ORIENTATION.VERTICAL}
                      />
                    </Selection>
                  </FilterBlock>

                  <SimpleBlock>
                    <Text
                      text={CONFIRMATION.HOW_DO_YOU_TIME}
                      size={SIZE.MEDIUM}
                      fontType={FONT_TYPE.BOLD}
                      color={COLOR.DARK}
                    />
                    <StepsRange />
                  </SimpleBlock>

                  <SimpleBlock>
                    <Toggle
                      text={PAGE_INFORMATION.SOCIAL}
                      transform={TRANSFORM.UPPERCASE}
                    />
                  </SimpleBlock>
                  <FilterBlock>
                    <FilterBlock>
                      <Selection>
                        <Checkbox
                          text={PAGE_INFORMATION.PUBLIC_GROUPS}
                          orientation={ORIENTATION.VERTICAL}
                        />
                      </Selection>
                      <Selection>
                        <Checkbox
                          text={PAGE_INFORMATION.PRIVATE_GROUPS}
                          orientation={ORIENTATION.VERTICAL}
                        />
                      </Selection>
                      <Selection>
                        <Checkbox
                          text={PAGE_INFORMATION.TEACHERS}
                          orientation={ORIENTATION.VERTICAL}
                        />
                      </Selection>
                      <Selection>
                        <Checkbox
                          text={PAGE_INFORMATION.STUDENTS}
                          orientation={ORIENTATION.VERTICAL}
                        />
                      </Selection>
                    </FilterBlock>
                  </FilterBlock>

                  <SimpleBlock>
                    <Actions
                      orientation={ORIENTATION.FULL_WIDTH}
                      format={FORMAT.BUTTON_1x3}
                      itemsToRender={[
                        {
                          type: "button",
                          action: null,
                          size: SIZE.MEDIUM,
                          appearance: APPEARANCE.MODAL,
                          buttonText: ACTION.CLEAR
                        },
                        {
                          type: "button",
                          action: null,
                          size: SIZE.MEDIUM,
                          appearance: APPEARANCE.PRIMARY,
                          buttonText: ACTION.SAVE
                        }
                      ]}
                    />
                  </SimpleBlock>
                </SpacingBlock>
              ]}
            />
          ]}
          right={<ResultsSearch groups={groups} />}
        />
      </Container>
    </>
  );
};
