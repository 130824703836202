import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  ICON,
  PROGRESS_INFORMATION,
  SIZE,
  APPEARANCE,
  ACTION,
  PAGE_INFORMATION,
  CONFIRMATION,
  ORIENTATION,
  POSITION,
  FONT_TYPE
} from "../../../constants";

import Spacing from "../../basicComponents/Spacing";
import MemberInfo from "../../Info/DataInfo";
import IconInfo from "../../Info/IconInfo";
import Column3x3 from "../../defaultAreas/Column_3x3";
import Column2x2 from "../../defaultAreas/Column_2x2";
import Select from "react-select";
import Text from "../../basicComponents/Text";
import ModalCall from "../../CallModal/GenericModalCall";
import ModalMessage from "../GenericModalMessage";
import formatDate from "../../../../utils/formatDate.js";
import { toast } from "react-toastify";
import { ModalBody, ButtonAtBottom } from "./styles";
import { toastDefaultOptions } from '../../../../utils/functions'

export default ({
  member,
  user,
  groupRoles,
  alterMemberRole,
  removeMember,
  transferGroupPossession,
  isAdministrator,
  pageImage
}) => {
  const [newRole, setNewRole] = useState("");
  const [alterRoles] = useState(groupRoles.filter(r => r.value != member.role));
  const [transferOwnership, setTransferOwnership] = useState(false);

  function onChangeNewRole(event) {
    if (event.value == user.role) {
      setTransferOwnership(true);
    } else {
      setTransferOwnership(false);
    }

    setNewRole(event.value);
  }

  function showAdministratorOptions() {
    if (isAdministrator && user.cr_code != member.cr_code) {
      return (
        <>
          <Column2x2
            left={
              <>
                <Text
                  fontType={FONT_TYPE.BOLD}
                  text={ACTION.SELECT_MEMBER_ROLE}
                />
                <Select
                  placeholder={ACTION.SELECT}
                  options={alterRoles}
                  onChange={onChangeNewRole}
                  name="newRole"
                  id="newRole"
                />
              </>
            }
            right={
              <>
                <Spacing size={SIZE.SMALL} />
                <ModalCall
                  buttonText={
                    transferOwnership
                      ? ACTION.TRANSFER_DOMAIN
                      : ACTION.ALTER_ROLE
                  }
                  modalHeaderText={
                    transferOwnership
                      ? ACTION.TRANSFER_DOMAIN
                      : ACTION.ALTER_ROLE
                  }
                  pageImage={pageImage}
                  appearance={APPEARANCE.OUTLINE_PRIMARY}
                >
                  <ModalMessage
                    action={() => {
                      if (!newRole) return toast(ACTION.SELECT_ROLE, toastDefaultOptions);

                      transferOwnership
                        ? transferGroupPossession()
                        : alterMemberRole(newRole);
                    }}
                    message={
                      transferOwnership
                        ? CONFIRMATION.CONFIRM_TRANSFER_DOMAIN
                        : CONFIRMATION.CONFIRM_ALTER_MEMBER_ROLE
                    }
                  />
                </ModalCall>
              </>
            }
          />

          <Spacing size={SIZE.MEDIUM} />

          <ButtonAtBottom>
            <ModalCall
              size={SIZE.MEDIUM}
              orientation={ORIENTATION.FULL_WIDTH}
              buttonText={ACTION.REMOVE_MEMBER}
              modalHeaderText={ACTION.REMOVE_MEMBER}
              pageImage={pageImage}
              appearance={APPEARANCE.OUTLINE_PRIMARY}
            >
              <ModalMessage
                action={() => removeMember(member)}
                message={CONFIRMATION.CONFIRM_REMOVE_MEMBER}
              />
            </ModalCall>
          </ButtonAtBottom>
        </>
      );
    }
  }

  return (
    <>
      <ModalBody>
        <MemberInfo
          id={member.id}
          name={member.name}
          img={member.img}
          description={`${PAGE_INFORMATION.MEMBER_SINCE} ${formatDate(
            member.memberSince
          )}`}
          role={member.role}
          size={SIZE.MEDIUM}
          orientation={ORIENTATION.VERTICAL}
        />

        <Link to={`/beta/social/externalgroup/${member.cr_code}`}>
          <Text
            text={"Ver perfil"}
            position={POSITION.CENTER}
            fontType={FONT_TYPE.BOLD}
          />
        </Link>

        <Spacing size={SIZE.MEDIUM} />

        <Column3x3
          left={
            <IconInfo
              icon={ICON.TROPHY}
              title={PROGRESS_INFORMATION.COURSES_COMPLETED}
              subtitle={member.coursesCompleted}
            />
          }
          middle={
            <IconInfo
              icon={ICON.PLAY}
              title={PROGRESS_INFORMATION.COURSES_IN_PROGRESS}
              subtitle={member.coursesInProgress}
            />
          }
          right={
            <IconInfo
              icon={ICON.USERS}
              title={PROGRESS_INFORMATION.GROUPS}
              subtitle={member.groups}
            />
          }
        />

        <Spacing size={SIZE.MEDIUM} />

        {showAdministratorOptions()}
      </ModalBody>
    </>
  );
};
