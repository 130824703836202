import React, { memo, useEffect, useState } from "react"

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import LeftSideBar from "./LeftSideBar"
import RightSideBar from "../PerfilAluno/RightSideBar"
import Sobre from "./Sobre"
import Loading from "../Loading/Loading"
import api from "../../utils/api"

const PerfilPublico = ({ match }) => {
  const [profile, setProfile] = useState(null)
  const { idProfile } = match.params

  const fetchProfile = async () => {
    try {
      const response = await api.get(`findProfile/${idProfile}`)
      console.log(response, 'caiu aqui')
      setProfile(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [idProfile])

  if (!profile) {
    return <Loading width={40} />
  }

  return (
    <div>
      <NavBarSuperior />

      <div className="container-fluid" id="perfil">
        <div className="row">
          <div className="col-md-2 " id="left-side-bar">
            <LeftSideBar profile={profile} />
          </div>
          <div className="col-md-7" id="main">
            <Sobre profile={profile} />
          </div>
          <RightSideBar idProfile={profile.idProfile} />
        </div>
      </div>
    </div>
  )
}

export default memo(PerfilPublico)
