import React, { useEffect, useState, useMemo } from 'react';
import {Route, Redirect, withRouter} from 'react-router-dom';

import { isAuthenticated } from '../../utils/auth';
import { isUserStorage } from '../../utils/functions';
import { hasActiveSubscriptions } from '../../utils/activeSubscriptions';
import { gateway } from '../../utils/api';

import Loading from '../Loading/Loading';
import spinner from '../../images/paul_loading.gif';

import { LoadingContainer } from './styles';

const PrivateRoute = ({ component: Component, path, history, ...rest}) => {
  const [redirectToPlans, setRedirectToPlans] = useState(false);
  const [isRenderComponent, setIsRenderComponent] = useState(false);
  const withoutVoucherRoutes = [
    '/invoicePaymentView',
    '/historico-de-pagamento',
    '/certificados',
    '/onboarding'
  ];

  const voucherAtivo = JSON.parse(localStorage.getItem('voucherAtivo'));
  let activeContract = false;
  if (localStorage.getItem('activeContract') !== 'undefined') {
    activeContract = JSON.parse(localStorage.getItem('activeContract'));
  }
  
  async function fetchSettings() {
    try {
      const response = await gateway.get(
        'webbff/lit_app_web/settings/white-label'
      );
      localStorage.setItem('settings', JSON.stringify(response));
    } catch (error) {
      console.error(error);
    } finally {
      setIsRenderComponent(true);
    }
  }

  async function isActive() {
    const active = await hasActiveSubscriptions();
    if (!active && !['/planos', '/payment', '/validador'].concat(withoutVoucherRoutes).includes(path))
      setRedirectToPlans(true);
  }

  const renderComponet = useMemo(() => {
    return isRenderComponent;
  }, [isRenderComponent]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchSettings();
        isActive();
      } catch (error) {
        console.log(error);
      } finally {
        setIsRenderComponent(true);
      }
    };

    fetchData();
  }, [Component]);

  useEffect(() => {
    if (redirectToPlans) {
      history.push('/payment');
    } else {
      const settings = localStorage.getItem('settings');
      if (isAuthenticated() && isUserStorage() && !settings) {
        setIsRenderComponent(false);
        return; 
      }
      setIsRenderComponent(true);
    }
  }, [redirectToPlans, history]);

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() &&
        isUserStorage() &&
        ((!redirectToPlans && activeContract) ||
          voucherAtivo ||
          withoutVoucherRoutes.includes(props.location.pathname)) ? (
          <>
            {!renderComponet ? (
              <LoadingContainer>
                <img
                  alt="loading"
                  style={{
                    maxHeight: '80%',
                    animation: 'loading-spin infinite 10s linear'
                  }}
                  src={spinner}
                />
                <p>Carregando configurações</p>
              </LoadingContainer>
            ) : (
              <>
                <Component {...props} />
              </>
            )}
          </>
        ) : (
          <>
            {redirectToPlans && (
              <Redirect
                to={{ pathname: '/payment', state: { from: props.location } }}
              />
            )}
            {!redirectToPlans && (
              <Redirect
                to={{ pathname: '/', state: { from: props.location } }}
              />
            )}
          </>
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
