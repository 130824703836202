import swal from "@sweetalert/with-react";
import React from "react";
import { FaExclamationTriangle, FaSpinner } from "react-icons/fa";

import { Container, Button, NavigationContainer, LoadingWrapper} from "./styles";

function ActionFooter({
  disabled,
  allowGoBack,
  onExit,
  onGoBack,
  onGoNext,
  onConfirm,
  disableNextButton,
  showFinishButton,
  onFinish,
  loading
}) {

  const handleNextQuestion = () => {
    swal({
      title: `Ir para a próxima questão`,
      text: `Confirme sua resposta antes de ir para a próxima pergunta`,
      buttons: {
        confirm: {
          text: "PROSSEGUIR",
          value: true,
          visible: true,
          className: "btn-alert btn-alert-confirm-onboarding"
        },
        cancel: {
          text: "OK",
          value: false,
          visible: true,
          className: "btn-alert btn-alert-confirm-onboarding"
        }
      }
    }).then(value => {
      if (!value) {
        return;
      }
      onGoNext()
    });
  };

  return (
    <Container>
      <aside>
        <Button
          id="exitButton"
          disabled={disabled}
          color="#fdcdcd"
          onClick={() => {
            if (onExit) {
              onExit();
            }
          }}
        >
          <FaExclamationTriangle /> SAIR
        </Button>
      </aside>

      <main>
        <NavigationContainer>
          <Button
            color="#27aae1"
            disabled={!allowGoBack || disabled}
            onClick={() => {
              if (onGoBack) {
                onGoBack();
              }
            }}
          >
            ANTERIOR
          </Button>

          <Button
            color="#27aae1"
            disabled={!allowGoBack || disabled || disableNextButton || showFinishButton}
            onClick={() => {
              if (onGoNext) {
                handleNextQuestion();
              }
            }}
          >
            PRÓXIMA
          </Button>
        </NavigationContainer>

        <Button
          id="finishButton"
          disabled={disabled || loading}
          color="#99d278"
          onClick={() => {
            if (showFinishButton && onFinish) {
              onFinish();
            } else if (onConfirm) {
              onConfirm();
            }
          }}
        > 
          {loading && (
             <LoadingWrapper>
              <FaSpinner size={20} />
            </LoadingWrapper>
          )}
          {!loading && (
            showFinishButton ? "FINALIZAR" : "CONFIRMAR E AVANÇAR"
          )}
        </Button>
      </main>
    </Container>
  );
}

export default ActionFooter;
