import React, { useMemo } from 'react';
import { Bar } from "react-chartjs-2";

import { ContainerChart } from './styles';

function ProgressChart({data = []}) {
  const definyColor = (status, gradient) => {
    return status === 'DONE' ?  gradient : "#ff0000";
  }

  const dataChart = useMemo(() => {
    const labels = data.map(item => item.label)

    const createTitle = (strings) => {
      const titles = []
      for (let i = 0; i < strings.length; i++) {
        const title = `A${i + 1}`;
        titles.push(title);
      }
      return titles;
    }
    const createTitles = createTitle(labels);
    return {
      titles: createTitles,
      labels
    }

  })

  const dataMedia = canvas => {
    const ctx = canvas.getContext("2d")
    const gradient = ctx.createLinearGradient(0, 50, 0, 180)
    gradient.addColorStop(0, "#27AAE1")
    gradient.addColorStop(1, "#D7DF23")

    return {
      labels: dataChart.titles,
      datasets: [
        {
          label: false,
          borderWidth: 1,
          backgroundColor: data.map(item => {
            return definyColor(item.status, gradient)
          }),
          borderColor:data.map(item => {
            return definyColor(item.status, gradient)
          }),
          hoverBackgroundColor:data.map(item => {
            return definyColor(item.status, gradient)
          }),
          hoverBorderColor: data.map(item => {
            return definyColor(item.status, gradient)
          }),
          data: data.map(item => (item.value / 10).toFixed(1)),
        },
      ],
    }
  }

  const optMedia = {
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            max: 10,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  }
  return (
    <ContainerChart>
      <React.Fragment>
        <h5 className="text-center">Médias</h5>
        <Bar data={dataMedia} options={optMedia} />
        <legend className="ml-3">
        {dataChart.titles.map((item, index) => (
          <div style={{ fontSize: 13 }} key={item}>
            <b>{item}</b>
            <p
              style={{ display: "inline" }}
            >{`: ${dataChart.labels[index]}`}</p>
          </div>
        ))}
      </legend>
      </React.Fragment>
    </ContainerChart> 
  );
}

export default ProgressChart;
