import React from "react";
import {
  FONT_TYPE,
  SIZE,
  POSITION,
  COLOR,
  TRANSFORM,
  STATUS
} from "../../../constants";
import Text from "../Text";
import Row20x80 from "../../defaultAreas/Row_20-80";
import { InfoTime, Time } from "./styles";

export default ({ evaluation, timeLeft }) => {
  return (
    <>
      <InfoTime>
        <Row20x80
          alignItems={POSITION.TOP_LEFT_CENTER}
          left={[
            <Text
              text={evaluation ? STATUS.NOTE : STATUS.TIME_LEFT}
              size={SIZE.EXTRA_SMALL}
              color={COLOR.LIGHT}
              transform={TRANSFORM.UPPERCASE}
            />
          ]}
          right={[
            <Time>
              <Text
                text={evaluation ? evaluation.toFixed(1) : timeLeft}
                size={SIZE.LARGE}
                fontType={FONT_TYPE.BOLDER}
                color={COLOR.LIGHT}
              />
            </Time>
          ]}
        />
      </InfoTime>
    </>
  );
};
