import React, { useCallback, useEffect, useContext } from "react";

import Webex from "webex";
import _ from "lodash";
import screenfull from "screenfull";

import Screen from "./screen";
import { MeetingContext } from "../../MeetingContext";

export default React.memo(function Meeting({ meetingDial }) {
  const {
    meeting,
    meetingEnded,
    setMeetingLoading,
    setMeeting,
    setMeetingEnded,
    setExitingFromMeeting,
    setLocalStream,
    setMeetingFullscreen
  } = useContext(MeetingContext);

  let remoteVideoMedia;
  let remoteShareMedia;

  const [meetingAddress, meetingPin] = meetingDial.split(":");

  const handleError = error => {
    console.error(error);
  };

  const endMeetingDebounce = useCallback(
    _.debounce(() => {
      setMeetingEnded(true);
      setExitingFromMeeting(false);
    }, 500),
    []
  );

  const startWebexClient = () => {
    return new Promise(async resolve => {
      const webex = Webex.init({
        config: {
          logger: {
            level: "error"
          },
          meetings: {
            reconnection: {
              enabled: true
            }
          }
          // Any other sdk config we need
        },
        credentials: {
          access_token: localStorage.getItem("webex_access_token")
        }
      });

      // Register our device with Webex cloud
      if (!webex.meetings.registered) {
        await webex.meetings.register();
      }

      resolve(webex);
    });
  };

  function cleanUpMedia() {
    const mediaElements = [
      document.getElementById("remote-view-video"),
      document.getElementById("remote-view-audio")
    ];
    mediaElements.forEach(elem => {
      if (elem.srcObject) {
        elem.srcObject.getTracks().forEach(track => track.stop());
        // eslint-disable-next-line
        elem.srcObject = null;
      }
    });
  }

  function bindMeetingEvents() {
    // meeting is a meeting instance, not a promise, so to know if things break,
    // we'll need to listen for the error event.
    meeting.on("error", handleError);

    const setMediaValue = (mediaType, value) => {
      switch (mediaType) {
        case "remoteVideo":
          remoteVideoMedia = value;
          document.getElementById("remote-view-video").srcObject = value;
          break;
        case "remoteAudio":
          document.getElementById("remote-view-audio").srcObject = value;
          break;
        case "remoteShare":
          remoteShareMedia = value;
          break;
        default:
      }
    };

    // Handle media streams changes to ready state
    meeting.on("media:ready", media => {
      if (!meetingEnded) setMediaValue(media.type, media.stream);
    });

    // Handle media streams stopping
    meeting.on("media:stopped", media => {
      // when call ends, media:stopped is triggered
      cleanUpMedia(true);
      setMediaValue(media.type, media.stream);
      endMeetingDebounce();
    });

    meeting.on("meeting:startedSharingRemote", () => {
      document.getElementById("remote-view-video").srcObject = remoteShareMedia;
    });

    meeting.on("meeting:stoppedSharingRemote", () => {
      document.getElementById("remote-view-video").srcObject = remoteVideoMedia;
    });

    meeting.on("all", event => {
      console.log(event);
    });
  }

  // Join the meeting and add media
  const joinMeeting = async () => {
    bindMeetingEvents();

    await meeting.join({ pin: meetingPin, moderator: false });

    const mediaSettings = {
      receiveVideo: true,
      receiveAudio: true,
      receiveShare: true,
      sendVideo: true,
      sendAudio: true,
      sendShare: false
    };

    // TODO: Can I get localStream properties and set them individualy on meeting actions after toggling audio/video devices?
    const [localStream, localShare] = await meeting.getMediaStreams(
      mediaSettings
    );
    setLocalStream(localStream);

    await meeting.addMedia({
      localStream,
      localShare,
      mediaSettings
    });

    await Promise.all([meeting.muteVideo(), meeting.muteAudio()]);
    
    // For some reason, Webex doesn't trigger update events after `meeting.muteAudio`, 
    // so we have to mute member directly to update audio status in meeting sidebar. 
    await meeting.members.muteMember(meeting.members.selfId)

    setMeetingLoading(false);
  };

  const updateMeetingFullscreen = () => {
    setMeetingFullscreen(screenfull.isFullscreen);
  };

  const onToggleMeetingFullscreen = () => {
    screenfull.toggle(document.getElementById("meeting-container"));
  };

  screenfull.on("change", () => {
    updateMeetingFullscreen();
  });

  useEffect(() => {
    async function InitMeeting() {
      const webex = await startWebexClient();

      // Create the meeting
      const _meeting = await webex.meetings.create(meetingAddress);

      setMeeting(_meeting);
    }
    InitMeeting();
  }, [setMeeting]);

  useEffect(() => {
    if (meeting) {
      joinMeeting();
    }
  }, [meeting]);

  return (
    <>
      <Screen onToggleMeetingFullscreen={onToggleMeetingFullscreen}/>
    </>
  );
});
