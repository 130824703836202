import React from "react";
import {
  PAGE_INFORMATION,
  ICON,
  COLOR,
  APPEARANCE,
  POSITION,
  SIZE,
  FONT_TYPE,
  ORIENTATION
} from "../../../constants";
import Button from "../Button";
import Text from "../Text";
import Icon from "../Icon";
import Block from "../Block";
import Line from "../Line";
import { Span } from "./styles";

export default ({
  type,
  iconType,
  notificationType,
  totalNotifications,
  hiddenBlock,
  setHiddenBlock
}) => {
  return (
    <>
      <Block
        color={COLOR.BLOCK_PRIMARY}
        appearance={APPEARANCE.NOTIFICATION}
        hidden={hiddenBlock}
      >
        <Span>
          <Icon icon={iconType(type)} size={SIZE.MEDIUM} />
        </Span>
        <Line
          alignItems={POSITION.TOP_LEFT_CENTER}
          orientation={ORIENTATION.AJUSTABLE}
        >
          <Text size={SIZE.MEDIUM} text={PAGE_INFORMATION.YOUR_HAVE} />
          <Text
            text={totalNotifications}
            size={SIZE.LARGE}
            fontType={FONT_TYPE.BOLD}
            color={COLOR.DARK}
          />
          <Text
            text={notificationType(type)}
            size={SIZE.SMALL}
            fontType={FONT_TYPE.BOLD}
            color={COLOR.TEXT_PRIMARY}
          />
        </Line>
        <Button
          icon={ICON.CLOSE}
          appearance={APPEARANCE.CLOSE}
          position={POSITION.TOP_RIGHT}
          onClick={() => setHiddenBlock(true)}
        />
      </Block>
    </>
  );
};
