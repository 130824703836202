import React, { PureComponent } from "react"

import placeholder from "../../images/placeholder-group.png"

class ImagePreview extends PureComponent {
  state = {
    thumb: undefined,
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) return

    const reader = new FileReader()

    reader.onloadend = () => {
      this.setState({ thumb: reader.result })
    }
    reader.readAsDataURL(nextProps.file)
  }

  render() {
    const { thumb } = this.state
    const { file } = this.props

    if (!file) return <img src={placeholder} alt="Grupo" className="img-thumbnail" />

    return <img src={thumb} alt={file.name} className="img-thumbnail" />
  }
}

export default ImagePreview
