import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { UserActions } from "../../store/ducks/user";
import spinner from "../../images/paul_loading.gif";
import SocialLinks from "./SocialLinks";
import FooterColumns from "./FooterColumns";

const Loading = styled.div`
  position: fixed;
  z-index: 201;
  top: 66px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(0px);
  background: transparent;
  height: calc((100% - 66px) - 75px);
`;

const IMG_LIT = require("../../images/lit.png");

const FooterHome = () => {
  const [loading, setLoading] = useState(false);
  const settings = JSON.parse(localStorage.getItem("settings")) || null;
  const tenant = localStorage.getItem("tenant") || "";

  const company = useMemo(() => localStorage.getItem("company") || " ", []);
  
  return (
    <>
      {loading && (
        <div className="loadingNavBarMyLibrary">
          <Loading>
            <img
              alt="loading"
              style={{
                maxHeight: "85%",
                animation: "loading-spin infinite 10s linear"
              }}
              src={spinner}
            />
            <div style={{ textAlign: "center", maxWidth: "800px" }}>
              <p style={{ fontWeight: "bold" }} className="no-margin">
                AGUARDE
              </p>
              <p className="no-margin">Carregando Biblioteca virtual</p>
              <p className="no-margin">Bons Estudos!</p>
              <p className="no-margin">Time LIT</p>
            </div>
          </Loading>
        </div>
      )}
      <div
        className="container-fluid container-footer"
        style={{
          background: _.get(settings, `${tenant}.footerBackgroundColor`, "")
        }}
      >
        <footer
          className="footer row justify-content-start"
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <div  className="logo-footer col-md-3 col-sm-6">
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: 'space-between',
              height: '90%'
            }}>

            {settings && settings[tenant] ? (
                <img style={{
                  width: 80,
                }}  src={settings[tenant].footerLogoUrl} className="img-fluid" alt={`LOGO ${tenant}`} />
              ) : null}

              <img style={{
                width: 200,
                marginTop: 20
              }} src={IMG_LIT} className="img-fluid" alt="Logo LIT" />
              <div>
                <p className="no-margin" style={{ color: '#fff' }}>Dúvidas? Converse com a gente no 
                <span style={{   textDecoration: 'underline' }}> sac@lit.com.br</span></p>
              </div>              
            </div>
          </div>

          {company.toUpperCase() !== "ANGLO" && (
            <>
              <FooterColumns setLoading={setLoading} />
              <SocialLinks />
            </>
          )}
        </footer>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions.ProfileActions, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(FooterHome));
