import React from "react";
import { SIZE, APPEARANCE, BORDER_TYPE } from "../../../constants";
import Text from "../../basicComponents/Text";
import Image from "../../basicComponents/Image";
import { Info, Description } from "./styles";

export default ({
  image,
  title,
  subtitle,
  orientation,
  imageSize,
  size,
  fontType,
  textAlign
}) => {
  return (
    <>
      <Info orientation={orientation}>
        <Image
          src={image}
          appearance={APPEARANCE.AVATAR}
          size={imageSize ? imageSize : SIZE.MEDIUM}
          border={BORDER_TYPE.BORDER}
        />
        <Description>
          <Text
            size={size ? size : SIZE.MEDIUM}
            text={title}
            fontType={fontType}
            textAlign={textAlign}
          />
          <Text
            size={SIZE.MEDIUM}
            text={subtitle}
            fontType={fontType}
            textAlign={textAlign}
          />
        </Description>
      </Info>
    </>
  );
};
