import styled from 'styled-components';

// TIPO 1
// background: #a8ff78;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to bottom, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(to bottom, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

// TIPO 2
// background: #a8ff78;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to left, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(to left, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

// TIPO 3
// background: #a8ff78;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to top, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(to top, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

// TIPO 4
// background: #a8ff78;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


export const Container = styled.div`
  width: 230px;
  position: relative;
  display: flex !important;
  background: #fff;

  padding: 5px 5px;
  border: 1px solid ${({ active }) => (active ? "#27aae1" : "#dfdfdf")};
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 100px;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.8;
  }

  
  p {
    text-transform: uppercase;
    font-size: 10px;
    margin: auto;
  }

  p.highlight {
    font-weight: bold;
    font-size: 15px;
  }

`;


export const Grade = styled.h4`
  margin: 0;
  padding: 0;
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 20px;

  color: ${props => {
    switch (props.status) {
      case "IN PROGRESS":
        return "#ede01a";
      case "DONE":
        return "#27AAE1";
      case "DONE_REPROVED":
        return "#de1626";
      default:
        return "#b9b9b9";
      }
  }}
`
