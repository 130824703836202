/**
 * Types
 */

export const Types = {
  SET_ALERT: "alert/SET_ALERT",
  REMOVE_ALERT: "alert/REMOVE_ALERT",
  SET_SMART_BAR: "alert/SET_SMART_BAR",
  REMOVE_SMART_BAR: "alert/REMOVE_SMART_BAR",
  SET_OPEN_PLANS: "alert/SET_OPEN_PLANS",
  REMOVE_OPEN_PLANS: "alert/REMOVE_OPEN_PLANS"
}
