import React, { memo, useState, useEffect } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import styled from "styled-components"
import Modal from "react-responsive-modal"
import { toast } from 'react-toastify'
import ImagePreview from "../../ImagePreview"
import api from "../../../utils/api"
import { API_URL } from "../../../utils/constants"
import { toastErrorAndWarningOptions } from '../../../utils/functions'

const ErrorMessage = styled.span`
  color: #e50000;
`

const ValidationSchema = Yup.object().shape({
  groupName: Yup.string()
    .min(3, "Nome do grupo muito curto")
    .max(60, "Nome do grupo muito longo")
    .required("Nome do grupo obrigatório"),
  privacity: Yup.string().required("Selecione a privacidade do grupo"),
  category: Yup.string().required("Selecione uma categoria"),
  description: Yup.string()
    .min(10, "Conte um pouco mais sobre seu grupo")
    .required("Descrição obrigatória"),
})

const GroupForm = ({ modalOpen, closeModal, onSaveGroup }) => {
  const [categories, setCategories] = useState({})
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)

  const getCategories = async () => {
    try {
      const response = await api.get("trackSuggestion")
      setCategories(response)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastErrorAndWarningOptions, closeButton: false });
    }
  }

  useEffect(() => {
    getCategories()
  }, [])

  const updateGroup = async idGroup => {
    try {
      const data = {
        idGroup,
        linkPhoto: `${API_URL}downdImgGroup/${idGroup}`,
      }
      await api.post("updateLinkPhoto", data)
      onSaveGroup(idGroup)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastErrorAndWarningOptions, closeButton: false });
    }
  }

  const uploadImage = async (idGroup, fileImage) => {
    try {
      console.log(fileImage)
      await api.post(`uploadImgGroup/${idGroup}`, fileImage, {
        headers: { "Content-Type": "application/json" },
      })
      updateGroup(idGroup)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastErrorAndWarningOptions, closeButton: false });
    }
  }

  const submitForm = async values => {
    setIsSubmittingForm(true);
    const data = {
      groupName: values.groupName,
      typeMember: 1,
      description: values.description,
      privacity: values.privacity,
      category: values.category,
      idGroupOwner: [
        {
          idGroupOwner: localStorage.getItem("idOwner"),
        },
      ],
    }
    try {
      const response = await api.post("newGroup", data)
      if (!values.linkPhoto) {
        onSaveGroup(response.idGroup)
      } else {
        uploadImage(response.idGroup, values.linkPhoto)
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastErrorAndWarningOptions, closeButton: false });
    } finally {
      setIsSubmittingForm(false);
    }
  }

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      classNames={{ modal: "lit-modal-form" }}
      closeOnOverlayClick={false}
    >
      <div className="modal-content" id="modalGroup">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Criar grupo
          </h5>
        </div>
        <div className="modalBody-container">
          <div className="modal-body">
            <Formik
              initialValues={{
                groupName: "",
                privacity: "",
                category: "",
                description: "",
                linkPhoto: undefined,
              }}
              onSubmit={submitForm}
              validationSchema={ValidationSchema}
              render={({
                values,
                errors,
                touched,
                resetForm,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="d-flex flex-column align-items-center avatar-photo-perfil mb-3">
                    Capa do grupo
                    <ImagePreview file={values.linkPhoto} />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={e => setFieldValue("linkPhoto", e.currentTarget.files[0])}
                    />
                  </div>

                  <div className="form-group">
                    <label>Nome do seu grupo</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputGroupName"
                      name="groupName"
                      value={values.groupName}
                      onChange={handleChange}
                      placeholder="Ex: Gestão Financeira"
                    />
                    {errors.groupName && touched.groupName && (
                      <ErrorMessage>{errors.groupName}</ErrorMessage>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Privacidade do grupo</label>
                    <select
                      className="form-control"
                      value={values.privacity}
                      name="privacity"
                      onChange={handleChange}
                    >
                      <option defaultValue>Selecione a privacidade</option>
                      <option value="false">Público</option>
                      <option value="true">Privado</option>
                    </select>

                    {errors.privacity && touched.privacity && (
                      <ErrorMessage>{errors.privacity}</ErrorMessage>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Categoria</label>
                    <select
                      className="form-control"
                      value={values.category}
                      name="category"
                      onChange={handleChange}
                    >
                      <option defaultValue>Selecione a categoria</option>
                      {categories.length > 0 &&
                        categories.map(cat => {
                          return (
                            <option key={cat.idSuggestion} value={cat.nameSuggestion}>
                              {cat.nameSuggestion}
                            </option>
                          )
                        })}
                    </select>

                    {errors.category && touched.category && (
                      <ErrorMessage>{errors.category}</ErrorMessage>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Descrição do grupo</label>
                    <textarea
                      type="text"
                      className="form-control"
                      rows="5"
                      name="description"
                      id="inputGroupName"
                      value={values.description}
                      onChange={handleChange}
                      maxLength="250"
                      placeholder="Digite uma descrição do grupo"
                    />
                    {errors.description && touched.description && (
                      <ErrorMessage>{errors.description}</ErrorMessage>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-modal__cancel"
                      disabled={isSubmittingForm}
                      onClick={() => {
                        resetForm()
                        closeModal()
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmittingForm}
                      className={`btn btn-modal__approve ${isSubmittingForm && " btn-loading"}`}
                    >
                      Criar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default memo(GroupForm)
