import React from "react";
import {
  APPEARANCE,
  PAGE_INFORMATION,
  ORIENTATION,
  POSITION,
  SIZE,
  ACTION,
  ICON,
  COLOR
} from "../../../constants";
import TitleIconPrivacy from "../../basicComponents/TitleIconPrivacy";
import Button from "../../basicComponents/Button";
import OverflowContent from "../../basicComponents/OverflowContent";
import MemberBlock from "../../basicComponents/MemberBlock";
import Row30x70 from "../../defaultAreas/Row_30-70";
import { Content, Title, Icon } from "./styles";

export default ({ title, content }) => {
  return (
    <>
      <Content>
        <Title>
          <Row30x70
            position={POSITION.TOP_CENTER}
            left={[
              <Icon>
                <TitleIconPrivacy
                  text={title}
                  description={PAGE_INFORMATION.SUGESTION_CONTACT}
                  icon={ICON.CONTACTS}
                  borderColor={COLOR.GRAY}
                  size={SIZE.MEDIUM}
                  color={COLOR.GRAY}
                />
              </Icon>
            ]}
            right={[
              <Button
                text={ACTION.VIEW_MORE}
                appearance={APPEARANCE.MODAL}
                position={POSITION.RIGHT}
                icon={ICON.NEXT_ARROW}
                iconPosition={POSITION.RIGHT}
              />
            ]}
          />
        </Title>
        <OverflowContent
          orientation={ORIENTATION.HORIZONTAL}
          size={SIZE.MEDIUM}
        >
          {content.map(item => (
            <MemberBlock
              title={item.metadata.name}
              description={item.metadata.role}
              image={item.metadata.picture}
            />
          ))}
        </OverflowContent>
      </Content>
    </>
  );
};
