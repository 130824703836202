import React, { memo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Img from "react-image"

import placeholder from "../../../images/placeholder-avatar.png"

import api from "../../../utils/api"

const Solicitacoes = ({ userRequest, grupoId, getInvites, onAcceptInvite }) => {
  const [detailUser, setDetailUser] = useState({})

  const acceptInvitationGroup = async idProfile => {
    try {
      await api.get(`acceptInvitation/${grupoId}/${idProfile}`)
      getInvites()
      onAcceptInvite()
    } catch (error) {
      console.log(error)
    }
  }

  const recuseInviteGroup = async idProfile => {
    try {
      await api.get(`newRecuseInvite/${grupoId}/${idProfile}`)
      getInvites()
    } catch (error) {
      console.log(error)
    }
  }

  const fetchDetails = async () => {
    const { idProfile } = userRequest.idProfile
    try {
      const response = await api.get(`getDatailOfProfile/${idProfile}/${grupoId}`)
      setDetailUser(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <li
      className="list-group-item"
      style={{
        border: "none",
        display: "flex",
        paddingRight: 0,
        paddingLeft: "10px",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "inline-flex", marginBottom: "10px", alignItems: "center" }}>
        <Link
          to={`/${detailUser.teacher ? "perfilProfessor" : "perfilPublico"}/${userRequest.idProfile}`}
        >
          <Img
            src={[userRequest.linkPhoto, placeholder]}
            className="rounded-circle img-fluid mr-2"
            data-tip={userRequest.name}
            style={{
              maxWidth: "70px",
              minHeight: "70px",
              maxHeight: "70px",
              minWidth: "70px",
            }}
          />
        </Link>
        <div>
          <Link
            className="member"
            style={{ textAlign: "left" }}
            to={`/${detailUser.teacher ? "perfilProfessor" : "perfilPublico"}/${userRequest.idProfile}`}
          >
            {userRequest.name}

            {detailUser && (
              <div
                style={{
                  fontSize: "75%",
                  color: "black",
                }}
              >
                {detailUser.teacher ? "Professor" : "Aluno"}
                <br />
                {detailUser.company}
                <br />
                {detailUser.office}
              </div>
            )}
          </Link>
          <div style={{ alignSelf: "center" }}>
            <button
              type="button"
              className="btn btn-xsm btn-xsm-outline-save-lit"
              onClick={() => acceptInvitationGroup(userRequest.idProfile)}
            >
              Aceitar
            </button>
            <button
              type="button"
              className="btn btn-xsm btn-xsm-outline-cancel-lit ml-1"
              onClick={() => recuseInviteGroup(userRequest.idProfile)}
            >
              Recusar
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}

export default memo(Solicitacoes)
