import styled, { css } from "styled-components";
import { APPEARANCE, SIZE, BORDER_TYPE, FORMAT, POSITION } from "../../../constants";

export const Image = styled.img`
  
  ${props => (props.appearance === APPEARANCE.AVATAR) && css` 
    border-radius: 50%;
    width: 40px;
    height: 40px; 
    margin-right: 5px;
    float: left;
    object-fit: cover;
    max-width: 100%;
  `}

  ${props => (props.appearance === APPEARANCE.AVATAR_MEMBER) && css` 
    border-radius: 50%;
    width: 55px;
    height: 55px; 
    object-fit: cover;
    max-width: 100%;
  `}
  
  ${props => (props.appearance === APPEARANCE.LOGO) && css`
    width: auto;
    height: 60px;
    object-fit: contain;
    max-width: 100%;
    display: inline-block;
    margin: 0 15px;
    @media (max-width: 320px) {
      width: 50px;
      margin: 0;
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.CARD) && css`
    max-width: 100%;
    margin-bottom: 10px;
  `}
  
  ${props => (props.appearance === APPEARANCE.BANNER_BLOCK) && css`
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
  `}
  
  ${props => (props.appearance === APPEARANCE.MODAL_HEADER) && css`
    position: absolute;
    top: 70px;
    left: 50px;
    @media (max-width: 579px) {
      top: 60px;
      left: 30px;
    }
  `}

  ${props => (props.appearance === APPEARANCE.AVATAR && props.size === SIZE.MEDIUM) && css` 
    border-radius: 50%;
    width: 120px!important;
    height: 120px!important; 
    margin-right: 10px;
    float: none;
    object-fit: cover;
    max-width: 100%;
    border: 1px solid var(--image-member-border)!important;
  `}

  ${props => (props.appearance === APPEARANCE.AVATAR && props.size === SIZE.SMALL) && css` 
    border-radius: 50%;
    width: 80px!important;
    height: 80px!important; 
    margin-right: 20px;
    float: none;
    object-fit: cover;
    max-width: 100%;
  `}

  ${props => (props.border === BORDER_TYPE.NO_BORDER) && css`
    border: 0;
  `}

  ${props => (props.border === BORDER_TYPE.BORDER) && css`
    border: 1px solid var(--image-border);
  `}

  ${props => (props.appearance === APPEARANCE.AVATAR_MEMBER && props.border === BORDER_TYPE.BORDER) && css`
    border: 1px solid var(--image-member-border);
  `}

  ${props => (props.border === BORDER_TYPE.BORDER_LIGHT) && css`
    border: 1px solid var(--image-border-light);
  `}

  ${props => (props.format === FORMAT.ROUNDED) && css`
    border-radius: 50%;
  `}

  ${props => (props.size === SIZE.SMALL) && css`
    width: 120px;
    height: 120px;
    object-fit: cover;
    max-width: 100%;
  `}
    
  ${props => (props.size === SIZE.MEDIUM) && css`
    width: 215px;
    height: 135px;
    object-fit:cover;
    max-width: 100%;
    @media screen and (max-width: 1060px) {
      width: 180px;
      height: 120px;
    }
    @media screen and (max-width: 579px) {
      width: 120px;
      height: 90px;
    }
  `}
  
  ${props => (props.size === SIZE.LARGE) && css`
    width: 300px;
    height: 185px;
    margin: 0 1rem;
    object-fit: cover;
    max-width: 100%;
    @media screen and (max-width: 991px) {
      width: 200px;
      height: 165px;
      object-fit: contain;
      background-color: var(--image-background);
    }
    @media screen and (max-width: 579px) {
      width: 120px;
      height: 90px;
      margin-bottom: 10px;
    }
  `}
  
  ${props => (props.position === POSITION.BOTTOM_RIGHT) && css`
    position: absolute;
    bottom: 0;
    right: 0;
  `}
  
  ${props => (props.position === POSITION.ABOVE_RIGHT) && css`
    margin-left: -30px;
  `}

`;
