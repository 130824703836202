import { Types } from "./types"
/**
 * Actions
 */
export const Creators = {
  getProfileRequest: idProfile => ({
    type: Types.GET_PROFILE_REQUEST,
    payload: { idProfile },
  }),
  getProfileSuccess: data => ({
    type: Types.GET_PROFILE_SUCCESS,
    payload: { data },
  }),
  getProfileFailure: error => ({
    type: Types.GET_PROFILE_FAILURE,
    payload: { error },
  }),
  setOpenDirectPayment: data => ({
    type: Types.SET_DIRECT_OPEN_PAYMENT,
    payload: { data },
  }),
  toggleViewNPS: (show, origin) => ({
    type: Types.TOGGLE_VIEW_NPS,
    payload: { show, origin },
  }),
  sendNPSRequest: (idProfile, rating) => ({
    type: Types.SEND_NPS_REQUEST,
    payload: { idProfile, rating },
  }),
  sendNPSSuccess: () => ({
    type: Types.SEND_NPS_SUCCESS,
    payload: {},
  }),
  sendNPSFailure: error => ({
    type: Types.SEND_NPS_FAILURE,
    payload: { error },
  }),

  toggleModalRestrict: (status, type = 'course') => ({
    type: Types.TOGGLE_MODAL_RESTRICT,
    payload: { status, type },
  }),

  toggleModalRestrictLitPass: (status, type = 'course') => ({
    type: Types.TOGGLE_MODAL_RESTRICT_LITPASS,
    payload: { status, type },
  }),
}
