import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Array} communications //list of communications - posts
 * @param {Boolean} isAdministrator //verifies that the author of the post is an administrator
 * @param {Array} group //group data
 * @param {Array} member //member data
 * @param {Function} createPost //function that create post
 * @param {Function} deleteCommunication //function that delete comment
 * @param {Array} members //list of members to display in items
 * @param {Function} updatePost //function that updates post
 * @param {Function} createComment //function that create comment
 * @param {Boolean} hiddenCreatePost //show or hide create post component
 * @param {Boolean} interationsModalIsOpen //show or hide modal invitations
 * @param {Boolean} setInterationsModalIsOpen //show or hide create post component
 * @param {Function} loadCommunicationReactions //looks for reactions to be displayed for comments click events
 * @param {Function} loadReactionPeople //seeks reactions to be linked to users displayed in the post
 * @param {Function} loadMoreCommunications //load more posts on the page
 * @param {Array} reactionsTypes //list types of reactions
 * @param {Boolean} hasMoreCommunications //check if there are more posts
 * @param {Function} createComment //function used to create comment
 * @param {} showTimer //video run time
 * @param {} isContentPage //check if there is content
**/

export default ({
  communications = DEFAULT_VALUES.LIST,
  isAdministrator = DEFAULT_VALUES.BOOLEAN,
  group = DEFAULT_VALUES.LIST,
  member = DEFAULT_VALUES.LIST,
  createPost = DEFAULT_VALUES.FUNCTION,
  deleteCommunication = DEFAULT_VALUES.FUNCTION,
  members = DEFAULT_VALUES.LIST,
  updatePost = DEFAULT_VALUES.FUNCTION,
  createComment = DEFAULT_VALUES.FUNCTION,
  hiddenCreatePost = DEFAULT_VALUES.BOOLEAN,
  interationsModalIsOpen = DEFAULT_VALUES.BOOLEAN,
  setInterationsModalIsOpen = DEFAULT_VALUES.BOOLEAN,
  loadCommunicationReactions = DEFAULT_VALUES.FUNCTION,
  loadReactionPeople = DEFAULT_VALUES.FUNCTION,
  loadMoreCommunications = DEFAULT_VALUES.FUNCTION,
  reactionsTypes = DEFAULT_VALUES.LIST,
  hasMoreCommunications = DEFAULT_VALUES.BOOLEAN,
  updateCommunicationReactions = DEFAULT_VALUES.FUNCTION,
  showTimer,
  isContentPage,
  isLoading
}) => {

  return (
    <>
      <Screen
        isAdministrator={isAdministrator}
        communications={communications}
        group={group}
        hasMoreCommunications={hasMoreCommunications}
        member={member}
        createPost={createPost}
        members={members}
        updatePost={updatePost}
        createComment={createComment}
        deleteCommunication={deleteCommunication}
        hiddenCreatePost={hiddenCreatePost}
        reactionsTypes={reactionsTypes}
        updateCommunicationReactions={updateCommunicationReactions}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
        loadCommunicationReactions={loadCommunicationReactions}
        loadReactionPeople={loadReactionPeople}
        loadMoreCommunications={loadMoreCommunications}
        showTimer={showTimer}
        isContentPage={isContentPage}
        isLoading={isLoading}
      />
    </>
  );
};
