import React from "react";
import { SwitchBlock, SwitchLabel, Toggle } from "./styles";

export default ({ text, check, onChange, transform }) => {
  return (
    <>
      <SwitchBlock>
        <SwitchLabel
          control={<Toggle color="primary" name={text} />}
          label={text}
          checked={check}
          onChange={onChange}
          transform={transform}
        />
      </SwitchBlock>
    </>
  );
};
