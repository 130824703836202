import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 30px 0 30px;
  padding-top: 10px;
  .parent {
    width: 85%;
    position: relative;
    overflow: hidden;
    margin: auto;
  }

  @media(max-width: 710px) {
    flex-direction: column;
    .parent {
      width: 100%;
    }
  }

  .slider-container {
  /* if you want to have a scrollbar, you can add overflow here */ 
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem 2rem;
    padding: 3px;
    scroll-behavior: smooth;
  }

  .slider-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .btn {
    position: absolute;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn svg {
    font-size: 30px;
    color: #27aae1;
  }

  .prev {
    left: 0rem;
  }
  .next {
    right: 0rem;
  }
  .disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  .child {
    padding: 5px 20px;
    background: #59eb97;
    text-align: center;
    width: 100%;
    margin-right: 10px;
    border-radius: 21px;
  }
`

export const Content = styled.div`
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  @media(max-width: 710px) {
    flex-direction: column;
    .parent {
      width: 100%;
    }
  }
`

export const BoxTimerWithNumberQuestions = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  @media(max-width: 710px) {
    padding: 10px 45px;
  }
`

const Box = styled.div`
  padding: 4px;
  text-align: center;
  background-color: #27aae1;
  font-size: 20px;
  width: 180px;
  color: #fff;
  
  & + div {
    margin-left: 10px;
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

export const TextTimer = styled(Box)`
  
`

export const NumberOfQuestions = styled(Box)`
  
`

export const Button = styled.div`
  padding: 12px 35px;
  border: ${props => props.current ? '1px' : '0px'} solid #27aae1;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-size: 20px;
  /* font-weight: ${props => props.current ? 'bold' : 'normal'}; */
  margin-right: 10px;
  border-radius: 5px;

  & + button {
    border-top: 0;
  }
 
  &:disabled {
    cursor: not-allowed;
  }

  background: ${props => {
    if(props.current) {
      return '#fff';
    }
    if(props.concluded) {
      return '#27aae1';
    }
    if(props.notCompleted) {
      return '#e03b38'
    }

    return '#E0E0E0';
  }};

  color: ${props => {
    if(props.current) {
      return '#27aae1'
    }

    if(props.concluded || props.notCompleted) {
      return '#fff';
    }

    return ''
  }};
`

