import React, { memo } from "react";
import {formatDateDMY} from '../../../utils/functions'


const ListInvoice = ({invoiceList}) => {
    return (
        <table className="table table-borderless table-sm">
            <thead>
                <tr>
                    <th scope="col">Data</th>
                </tr>
            </thead>
            <tbody>
                {invoiceList.map((invoice, index) => (
                    <tr key={index}>
                        <td>{formatDateDMY(invoice.created_at)}</td>
                        <td>
                            <a href={invoice.nfe_link} target="_blank">
                                <i
                                    style={{ color: "#27aae1", cursor: "pointer" }}
                                    className="fa fa-print"
                                    aria-hidden="true"
                                />
                            </a>  
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ListInvoice
