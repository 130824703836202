import React, { useState, useEffect } from "react"
import md5 from 'md5'
import { toast } from "react-toastify"
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Slide from '@material-ui/core/Slide';
import { fetchUseAndPolicyTerms } from '../../business/auth'
import { toastErrorAndWarningOptions } from '../../utils/functions'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: 'linear-gradient(45deg, rgba(2, 196, 255, 1) 25%, rgba(142, 230, 123, 1) 100%)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function ModalPolitica({ visible, onClose, userEmail }) {
  const classes = useStyles();

  const [formattedPolicyTerm, setFormattedPolicyTerm] = useState('')

  async function fetchTerms() {
    try {
      const { policyTerm } = await fetchUseAndPolicyTerms();
      const policyTermText = policyTerm.termText;

      const policyTermHash = userEmail ? md5(`${policyTerm.id}-${userEmail}`) : '';
      setFormattedPolicyTerm(policyTermText.replace('**assinatura**', policyTermHash));
    } catch (error) {
      toast.error('Erro ao buscar termo!', toastErrorAndWarningOptions)
      onClose()
    }
  };

  useEffect(() => {
    if (visible) {
      fetchTerms()
    }
  }, [visible])

  return (
    <div className={classes.container}>
      {formattedPolicyTerm.length > 0 ? (
        <Dialog fullScreen open={visible} onClose={onClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                {/* <CloseIcon /> */}
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Política de Privacidade
              </Typography>
              <Button autoFocus color="inherit" onClick={onClose}>
                Fechar
              </Button>
            </Toolbar>
          </AppBar>
          <div className="modal-content">
            <div className="modal-body d-flex justify-content-center">
              <div dangerouslySetInnerHTML={{ __html: formattedPolicyTerm }} />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-blue" onClick={onClose}>
                Fechar
              </button>
            </div>
          </div>
        </Dialog>
      ) : ''}
    </div>
  )
}
