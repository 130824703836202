import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import * as Styles from './styles';

function ProgressBar({ data }) {
  const {countFinishedObjects  = 0, countObjects = 0} = data   
  return (
    <Tooltip title="Progresso" placement="top">
      <Styles.Container>
        <Styles.BarProgress
        className="mr-2"
        min={0}
        value={parseInt((countFinishedObjects * 100) / countObjects, 10) || 0}
        max={100}
        />
          {`${countFinishedObjects}/${countObjects}`}
      </Styles.Container>
  </Tooltip>
  );
}

export default ProgressBar;
