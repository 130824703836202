/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import InputMask from "react-input-mask";
import { Checkbox } from "antd";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import swal from "@sweetalert/with-react";
import axios from "axios";
import _ from "lodash"
import { ButtonLinks } from "../CreditCardPayment/styles";
import ModalTermos from "../../../Modals/ModalTermos";
import { validarCpf, toastErrorAndWarningOptions } from "../../../../utils/functions";
import api, { gateway } from "../../../../utils/api";
import { findPaymentTerm } from "../../../../business/payment";
import ModalViewTerm from "../../../Modals/ModalViewTerm";
import PaymentMethodType from "../../paymentMethodType";
import TrialText from "../TrialText";
import { LabelField, ItemFormField, InputText, ItemFormFieldDisabled } from "../../../Styles/FormStyle"
import Loading from '../../../Loading/Loading';

class InvoicePayment extends Component {
  constructor(props) {
    super(props);
    const { plan } = this.props;
    const termId =
      plan.payments && findPaymentTerm(plan, PaymentMethodType.BOLETO);

    const { paymentImmediate, idTotvsCourses } = plan.config || {}

    const hasPaymentError = localStorage.getItem("57e0854551b1dca003c67c384c37a346") === 'true';

    this.state = {
      termId,
      term: null,
      syncProfileData: true,
      paymentError: "",
      termData: null,
      isOpeningTerms: false,
      acceptedTerm: false,
      showModal: false,
      modalTerms: false,
      modalData: null,
      loading: false,
      paymentImmediate,
      idTotvsCourses,
      hasPaymentError,
      validationSchema: Yup.object().shape({
        wl_shopperfirstname: Yup.string()
          .required("Campo obrigatório")
          .default(""),
        wl_shopperlastname: Yup.string()
          .required("Campo obrigatório")
          .default(""),
        cf_shoopercpf: Yup.string()
          .test("is-cpf", "CPF inválido", validarCpf)
          .default(""),
        wx_shopperpostalcode: Yup.string()
          .required("Campo obrigatório")
          .default(""),
        wx_shopperstreet: Yup.string()
          .required("Campo obrigatório")
          .default(""),
        wx_shoppercity: Yup.string()
          .required("Campo obrigatório")
          .default(""),
        wx_shoppercountry: Yup.string()
          .required("Campo obrigatório")
          .default(""),
        ex_shopperhousenumber: Yup.string()
          .required("Campo obrigatório")
          .default(""),
        wx_shopperstate: Yup.string()
          .required("Campo obrigatório")
          .default("")
      })
    };
  }

  acceptTerm = () => {
    const { acceptedTerm } = this.state;
    this.setState({ acceptedTerm: !acceptedTerm, paymentError: false });
  };

  goToTerm = () => {
    const { termId, acceptedTerm } = this.state;
    if (!acceptedTerm) {
      this.setState({ isOpeningTerms: true });
      gateway
        .get(`/term/${termId}`)
        .then(() => {
          // The endpoint works, go to the new modal
          const { plan } = this.props;
          this.setState({
            modalData: {
              termType: "PLAN",
              idPlan: plan.id,
              termId,
              planType: plan.planType,
              fromPayment: true
            },
            showModal: true
          });
        })
        .catch(error => {
          console.log(error);
          toast.error("Erro ao buscar termo!", toastErrorAndWarningOptions);
        })
        .finally(() => {
          this.setState({ isOpeningTerms: false });
        });
    }
  };

  updateProfileData = async formValues => {
    const { profile } = this.props;
    const newProfile = {
      ...profile,
      firstName: formValues.wl_shopperfirstname,
      lastName: formValues.wl_shopperlastname,
      cpf: formValues.cf_shoopercpf.replace(/\D/g, ""),
      zipCode: formValues.wx_shopperpostalcode.replace(/\D/g, ""),
      address: formValues.wx_shopperstreet,
      addressNumber: formValues.ex_shopperhousenumber,
      city: formValues.wx_shoppercity,
      uf: formValues.wx_shopperstate,
      country: "BR"
    };
    return api.put("updateProfileTO", newProfile);
  };

  componentDidMount() {
    // this.fetchOnboardingPages();
  }

  onChangeSyncProfileData = () => {
    const { syncProfileData } = this.state;
    this.setState({ syncProfileData: !syncProfileData });
  };

  performFormSubmit = async (formValues, actions, callback = null) => {
    const { onSubmit, installments, claimId } = this.props;
    const { term, syncProfileData } = this.state;

    try {
      this.setState({ loading: true });

      if (syncProfileData) {
        this.updateProfileData(formValues);
      }

      await onSubmit(formValues, actions, term, installments, claimId);
    } finally {
      this.setState({ loading: false });
      !callback || callback()
    }
  }

  billetCreated = async () => {
    swal({
      title: `Boleto Gerado com sucesso`,
      text: `Seu boleto foi gerado, o acesso ao plano contratado será liberado assim que o banco der a baixa, o que acontece normalmente entre 24h a 48h.`,
      buttons: {
        confirm: {
          text: "Continuar",
          value: true,
          visible: true,
          className: "btn-alert btn-alert-confirm-onboarding"
        }
      }
    });
  }

  handleSubmitForm = (formValues, actions) => {
    const {
      plan,
      handleTrial,
      installments,
      setInstallmentsError,
    } = this.props;

    const { termId, acceptedTerm, paymentImmediate } = this.state;

    if (!installments) {
      setInstallmentsError();
      actions.setSubmitting(false);
      return;
    }

    if (termId && !acceptedTerm) {
      this.setState({
        paymentError: "Você precisa aceitar os termos e condições"
      });
      actions.setSubmitting(false);
      return;
    }

    if (this.props.showMessageCPF === true) {
      actions.setSubmitting(false);
      swal({
        title: `Ops, esse CPF já está sendo utilizado!`,
        text: `Olá, como você já usufruiu do período de trial anteriormente, a cobrança do seu plano será feita imediatamente após a confirmação.`,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "btn-alert"
          },
          confirm: {
            text: "Continuar",
            value: true,
            visible: true,
            className: "btn-alert btn-alert-confirm-onboarding"
          }
        }
      }).then(value => {
        if (value) {
          this.performFormSubmit(formValues, actions, this.billetCreated);
        }
      });
    } else if (!plan.trial || paymentImmediate) {
      handleTrial(() => {
        this.performFormSubmit(formValues, actions, this.billetCreated);
      })
    } else {
      this.performFormSubmit(formValues, actions, this.billetCreated);
    }
  };

  openModal = () => {
    this.setState({ modalTerms: true });
  };

  searchEnterCEP = (e, setFieldValue) => {
    if (e.keyCode === 13 && e.target.value) {
      this.searchCEP(e.target.value, setFieldValue)
    }
  }

  searchCEP = async (cep, setFieldValue) => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      const {data} = response;
      
      setFieldValue('wx_shopperstreet', _.get(data, 'logradouro'))
      setFieldValue('wx_shopperstate', _.get(data, 'uf'))
      setFieldValue('wx_shoppercity', _.get(data, 'localidade'))
      
    } catch (error) {
      this.setState({ erroCep: true })
      window.alert("CEP não encontrado")
    }
  }

  render() {
    const {
      termId,
      isOpeningTerms,
      modalData,
      showModal,
      paymentError,
      loading,
      hasPaymentError
    } = this.state;
    const { profile, back, error } = this.props;
    return (
      
      <div>
        <div className="row justify-content-md-center">
          <div className="col-md-8 col-12">
          <p>
            Para que possamos gerar seu boleto precisamos que nos informe alguns
            dados:
          </p>
          </div>
        </div>
        <Formik
          initialValues={{
            wl_shopperfirstname: profile.firstName,
            wl_shopperlastname: profile.lastName,
            cf_shoopercpf: profile.cpf || "",
            wx_shopperpostalcode: profile.zipCode || "",
            wx_shopperstreet: profile.address || "",
            ex_shopperhousenumber: profile.addressNumber || "",
            wx_shoppercountry: "BR",
            wx_shopperstate: profile.uf || "",
            wx_shoppercity: profile.city || "",
            wx_shoppercomplement: profile.complement || "",
            wx_shopperneighborhood: profile.neighborhood || ""
          }}
          validationSchema={this.InvoicePaymentvalidationSchema}
          onSubmit={(formValues, actions) => {
            this.handleSubmitForm(formValues, actions);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            handleSubmit,
            setFieldValue
          }) => (
            <React.Fragment>
              {isSubmitting || loading 
                ? <div className="box-loading">
                    <Loading width="30" message="Gerando boleto ..." />
                  </div>
                : <>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-12">
                      <p style={{ fontWeight: "bold" }}>Dados pessoais</p> 
                      <div className="form-group">
                        <LabelField>Nome</LabelField>
                        <ItemFormField>
                        <InputText
                          type="text"
                          className="form-control"
                          placeholder="Nome"
                          name="wl_shopperfirstname"
                          value={values.wl_shopperfirstname}
                          onChange={handleChange("wl_shopperfirstname")}
                          onBlur={handleBlur("wl_shopperfirstname")}
                        />
                        </ItemFormField>
                        {errors.wl_shopperfirstname &&
                          touched.wl_shopperfirstname && (
                            <p style={{ color: "red" }}>
                              {errors.wl_shopperfirstname}
                            </p>
                          )}
                      </div>
                      <div className="form-group">
                        <LabelField>Sobrenome</LabelField>
                        <ItemFormField>
                        <InputText
                          type="text"
                          className="form-control"
                          placeholder="Sobrenome"
                          name="wl_shopperlastname"
                          value={values.wl_shopperlastname}
                          onChange={handleChange("wl_shopperlastname")}
                          onBlur={handleBlur("wl_shopperlastname")}
                        />
                        </ItemFormField>
                        {errors.wl_shopperlastname &&
                          touched.wl_shopperlastname && (
                            <p style={{ color: "red" }}>
                              {errors.wl_shopperlastname}
                            </p>
                          )}
                      </div>
                      <div className="form-group">
                        <LabelField>CPF</LabelField>
                        <ItemFormFieldDisabled>
                        <InputMask
                          mask="999.999.999-99"
                          type="text"
                          className="form-control"
                          placeholder="CPF"
                          name="cf_shoopercpf"
                          value={values.cf_shoopercpf}
                          maskChar={null}
                          onChange={handleChange("cf_shoopercpf")}
                          onBlur={handleBlur("cf_shoopercpf")}
                          style={{ height: 51 }}
                        />
                        </ItemFormFieldDisabled>
                        {errors.cf_shoopercpf && touched.cf_shoopercpf && (
                          <p style={{ color: "red" }}>{errors.cf_shoopercpf}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-12">
                      <div className="row">
                          <div className="col-md-12">
                            <p style={{ fontWeight: "bold" }}>
                              Informações sobre endereço
                            </p>
                          </div>

                          <div className="col-12">
                            <div className="form-group">
                            <LabelField>CEP</LabelField>
                            <ItemFormField>
                              <InputMask
                                type="text"
                                mask="99999-999"
                                className="form-control"
                                placeholder="99999-999"
                                name="wx_shopperpostalcode"
                                value={values.wx_shopperpostalcode}
                                maskChar={null}
                                onChange={handleChange("wx_shopperpostalcode")}
                                onBlur={handleBlur("wx_shopperpostalcode")}
                                style={{ height: 51 }}
                                onKeyUp={e => this.searchEnterCEP(e, setFieldValue)}
                              />
                              </ItemFormField>
                              {errors.wx_shopperpostalcode &&
                                touched.wx_shopperpostalcode && (
                                  <p style={{ color: "red" }}>
                                    {errors.wx_shopperpostalcode}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <LabelField>Logradouro</LabelField>
                              <ItemFormFieldDisabled>
                                <InputText
                                type="text"
                                className="form-control"
                                placeholder="Ex.: Rua da hora"
                                name="wx_shopperstreet"
                                value={values.wx_shopperstreet}
                                onChange={handleChange("wx_shopperstreet")}
                                onBlur={handleBlur("wx_shopperstreet")}
                                readOnly
                              />
                              </ItemFormFieldDisabled>
                              {errors.wx_shopperstreet && touched.wx_shopperstreet && (
                                <p style={{ color: "red" }}>{errors.wx_shopperstreet}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <LabelField>Número</LabelField>
                              <ItemFormField>
                                <InputText
                                type="text"
                                className="form-control"
                                placeholder="Número"
                                name="ex_shopperhousenumber"
                                value={values.ex_shopperhousenumber}
                                onChange={handleChange("ex_shopperhousenumber")}
                                onBlur={handleBlur("ex_shopperhousenumber")}
                                ref={input => {
                                  this.inputNumero = input
                                }}
                              />
                              </ItemFormField>
                              {errors.ex_shopperhousenumber &&
                                touched.ex_shopperhousenumber && (
                                  <p style={{ color: "red" }}>
                                    {errors.ex_shopperhousenumber}
                                  </p>
                                )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <LabelField>Complemento (Opcional)</LabelField>
                              <ItemFormField>
                                <InputText
                                type="text"
                                className="form-control"
                                placeholder="Complemento"
                                name="wx_shoppercomplement"
                                value={values.wx_shoppercomplement}
                                onChange={handleChange("wx_shoppercomplement")}
                                onBlur={handleBlur("wx_shoppercomplement")}
                              />
                              </ItemFormField>
                              {errors.wx_shoppercomplement &&
                                touched.wx_shoppercomplement && (
                                  <p style={{ color: "red" }}>
                                    {errors.wx_shoppercomplement}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <LabelField>Bairro</LabelField>
                              <ItemFormFieldDisabled>
                                <InputText
                                type="text"
                                className="form-control"
                                placeholder="Ex.: Centro"
                                name="wx_shopperneighborhood"
                                value={values.wx_shopperstreet}
                                onChange={handleChange("wx_shopperneighborhood")}
                                onBlur={handleBlur("wx_shopperneighborhood")}
                                readOnly
                              />
                              </ItemFormFieldDisabled>
                              {errors.wx_shopperneighborhood && touched.wx_shopperneighborhood && (
                                <p style={{ color: "red" }}>{errors.wx_shopperneighborhood}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                                <LabelField>Cidade</LabelField>
                                <ItemFormFieldDisabled>
                                  <InputText
                                  type="text"
                                  className="form-control"
                                  placeholder="Cidade"
                                  name="wx_shoppercity"
                                  value={values.wx_shoppercity}
                                  onChange={handleChange("wx_shoppercity")}
                                  onBlur={handleBlur("wx_shoppercity")}
                                  readOnly
                                />
                                </ItemFormFieldDisabled>
                                {errors.wx_shoppercity && touched.wx_shoppercity && (
                                  <p style={{ color: "red" }}>{errors.wx_shoppercity}</p>
                                )}
                              </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <LabelField>UF</LabelField>
                              <ItemFormFieldDisabled>
                                <InputText
                                type="text"
                                className="form-control"
                                placeholder="UF"
                                name="wx_shopperstate"
                                value={values.wx_shopperstate}
                                maxLength="2"
                                onChange={e =>
                                  handleChange("wx_shopperstate")(
                                    e.target.value.toUpperCase()
                                  )
                                }
                                onBlur={handleBlur("wx_shopperstate")}
                                readOnly
                              />
                              </ItemFormFieldDisabled>
                              {errors.wx_shopperstate && touched.wx_shopperstate && (
                                <p style={{ color: "red" }}>{errors.wx_shopperstate}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <LabelField>País</LabelField>
                              <ItemFormFieldDisabled>
                                <InputText
                                type="text"
                                className="form-control"
                                placeholder="Brasil"
                                name="wx_shoppercountry"
                                value={values.wx_shoppercountry}
                                maxLength="2"
                                onChange={e =>
                                  handleChange("wx_shoppercountry")(
                                    e.target.value.toUpperCase()
                                  )
                                }
                                onBlur={handleBlur("wx_shoppercountry")}
                                readOnly
                              />
                              </ItemFormFieldDisabled>
                              {errors.wx_shoppercountry && touched.wx_shoppercountry && (
                                <p style={{ color: "red" }}>{errors.wx_shoppercountry}</p>
                              )}
                            </div>
                        </div>

                        {termId && (
                          <div style={{ margin: "0 auto" }}>
                            <Checkbox
                              id="acceptTerms"
                              onChange={this.acceptTerm}
                              onClick={this.acceptTerm}
                            >
                              <div style={{ marginLeft: "20px", marginTop: "-23px" }}>
                                <span>Aceito os</span>
                                <ButtonLinks type="button" onClick={this.goToTerm}>
                                  termos
                                </ButtonLinks>
                                <span>e condições</span>
                              </div>
                            </Checkbox>
                            {isOpeningTerms && <p>Carregando termos...</p>}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 text-center">
                    {error ||
                      (paymentError && (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <p style={{ color: "red" }}>{error || paymentError}</p>
                        </div>
                      ))}

                    <div style={{ width: "100%", textAlign: "center", marginBottom: 10 }}>
                      Seus dados de perfil serão atualizados
                    </div>
                  </div>
                  <TrialText plan={this.props.plan} selectedPaymentMethod={this.props.selectedPaymentMethod} />
                  <ModalTermos
                    visible={this.state.modalTerms}
                    onClose={() => this.setState({ modalTerms: false })}
                  />

                  <div className="box-buttons" style={{ marginTop: "20px" }}>
                    {!hasPaymentError && <button
                      style={{ marginRight: "15px" }}
                      id="btnBack"
                      className="btn btn-lg"
                      type="button"
                      onClick={back}
                    >
                      Voltar
                    </button>}
                    <button
                      type="button"
                      disabled={isSubmitting}
                      style={{
                        marginTop: "0px",
                        maxWidth: "200px",
                        paddingRight: "-320px"
                      }}
                      className={`btn btn-blue btn-lg btn-block${(isSubmitting ||
                        loading) &&
                        " btn-loading"}`}
                      onClick={handleSubmit}
                      id="btn-confirm-1"
                    >
                      Gerar boleto
                    </button>
                  </div>
                </>}
              
            </React.Fragment>
          )}
        </Formik>
        <ModalViewTerm
          visible={showModal}
          {...modalData}
          onSetTerm={term => this.setState({ term })}
          onClose={() => this.setState({ showModal: false, paymentError: "" })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.user.profile.data
  };
};

export default connect(mapStateToProps)(InvoicePayment);
