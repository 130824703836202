/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import React, { Component } from "react";
import _ from 'lodash';
import { connect } from "react-redux";

import RCSlider, { Handle } from "rc-slider";

import { bindActionCreators } from "redux";

import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions";
import { saveOnboardingSteps } from "../../../../business/onboarding";
import Upload from "./subcomponents/Upload/index";

import { DYNAMIC_QUESTION_TYPE } from "../../../../utils/constants";

import {
  Container,
  Title,
  ContainerForm,
  TextError,
  BoxButtons,
  Button,
  TextRanger,
  ContainerSlider
} from "./styles";

const Slider = RCSlider;

const handle = (props, labels) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Handle value={value} {...restProps}>
      <div className="inner">
        <div className={`wdc-tooltip${dragging ? " active" : ""}`}>
          <span className="wdc-tooltip-inner">{labels[value]}</span>
        </div>
      </div>
    </Handle>
  );
};

class OnboardingPageDynamic extends Component {
  constructor(props) {
    super(props);
    const { questions, previousAnswers, isVisibleTitle } = props;
    const { data } = props;
    const fileConfig = _.get(data, "qx_dynamicquestions", []).map((question) => {
      if(_.get(question, "ox_questionconfiguration.wx_fieldtype") === "FILE") {
        const admittedFiles = _.get(question, 
          "ox_questionconfiguration.ox_fieldproperties.qx_admittedfiles", [])
        .map(item => item.toUpperCase());
        const maxFileSize = _.get(question, "ox_questionconfiguration.ox_fieldproperties.nx_maxfilesize");
        return { admittedFiles, maxFileSize }
      }
    }).filter(config => config);

    this.state = {
      fileConfig: fileConfig.length ? fileConfig[0] : null,
      answers: questions.map(q => {
        if (previousAnswers) {
          const possiblePreviousAnswer = props.previousAnswers.find(it => {
            // Validação usada na adaptação do onboarding no perfil do usuario
            if (it.id) {
              return it.id && it.id.localeCompare(q._id) === 0;
            }
            if (it._id) {
              return it._id && it._id.localeCompare(q._id) === 0;
            }
            return null;
          });
          // Validação usada na adaptação do onboarding no perfil do usuario
          if (
            possiblePreviousAnswer && possiblePreviousAnswer.wx_lastquestionanswer &&
            possiblePreviousAnswer && possiblePreviousAnswer.ox_questionconfiguration.wx_fieldtype ===
              "TOGGLE"
          ) {
            possiblePreviousAnswer.wx_lastquestionanswer =
              possiblePreviousAnswer.wx_lastquestionanswer === "true"
                ? true
                : possiblePreviousAnswer.wx_lastquestionanswer === "false"
                ? false
                : possiblePreviousAnswer.wx_lastquestionanswer;
          }
          
          if (possiblePreviousAnswer) {
            return possiblePreviousAnswer.hasOwnProperty("answer")
              ? possiblePreviousAnswer.answer
              : possiblePreviousAnswer.wx_lastquestionanswer;
          }
        }
        if (
          q.ox_questionconfiguration.wx_fieldtype === DYNAMIC_QUESTION_TYPE.TEXT
        ) {
          return "";
        }
        if (
          q.ox_questionconfiguration.wx_fieldtype ===
          DYNAMIC_QUESTION_TYPE.RANGE
        ) {
          const midpoint =
            (q.ox_questionconfiguration.ox_fieldproperties.nx_minvalue ||
              0 + q.ox_questionconfiguration.ox_fieldproperties.nx_maxvalue ||
              0) / 2;
          return Math.ceil(midpoint);
        }
        if (
          q.ox_questionconfiguration.wx_fieldtype ===
          DYNAMIC_QUESTION_TYPE.TOGGLE
        ) {
          return false;
        }

        if (
          q.ox_questionconfiguration.wx_fieldtype ===
          DYNAMIC_QUESTION_TYPE.FILE
        ) {
          return "";
        }
        // Unknown type. Avoid this.
        console.warn(
          `Question configuration with unknown type: ${JSON.stringify(
            q.ox_questionconfiguration,
            null,
            2
          )}`
        );
        return null;
      }),
      validationErrors: props.questions.map(_ => null),
      isVisibleTitle,
      id: data._id
    };
  }

  setAnswerAtIndex(index, answer) {
    const { answers } = this.state;
    
    if (index < 0 || index >= answers.length) {
      console.warn(
        `Index out of range when setting dynamic answer: Tried to set answer ${answer} at index ${index} while answers has length ${this.state.answers.length}`
      );
    }
    this.setState(oldState => {
      const newAnswers = oldState.answers.slice();
      const newErrors = oldState.validationErrors;
      newAnswers[index] = answer;
      newErrors[index] = null;
      return {
        answers: newAnswers,
        validationErrors: newErrors
      };
    });

    if (this.props.savePageDynamic) {
      setTimeout(
        function() {
          // Start the timer
          this.props.savePageDynamic();
        }.bind(this),
        500
      );
    }
  }

  saveForm = async () => {
    const { questions, onboardingData, index } = this.props;
    const { answers, id } = this.state;
    if (questions.length !== answers.length) {
      console.warn("Questions and answers have different lengths!");
    }
    const qa = [];
    const validationErrors = [];
    for (let i = 0; i < questions.length; i += 1) {
      const question = questions[i];
      const answer = answers[i];
      const possibleError = this.validate(question, answer);
      qa.push({
        id: question._id,
        answer
      });
      validationErrors.push(possibleError);
    }

    this.setState({
      validationErrors
    });
    if (validationErrors.some(e => !!e)) {
      throw new Error("Houve erro de validação em um ou mais campos.");
    }

    const data = {
      wx_step: "GOALS",
      _id: onboardingData[index]._id,
      ox_formdata: {
        qx_questionanswers: qa.map(item => {
          return {
            rk_questionid: item.id,
            wx_questionanswer: item.answer
          };
        })
      }
    };

    this.saveDataInState(true);
    await saveOnboardingSteps(data);
  };

  saveDataInState = (concluded = false) => {
    const { answers } = this.state;
    const {
      questions,
      setDynamicQuestions,
      index,
      onboardingData,
      setOnbordingPageConcluded
    } = this.props;
    const dataActiveStep = Object.assign({}, onboardingData[index]);
    const qa = [];
    for (let i = 0; i < questions.length; i += 1) {
      const question = questions[i];
      const answer = answers[i];
      qa.push({
        id: question._id,
        answer
      });
    }
    setDynamicQuestions(qa);
    if (onboardingData.length === 0) return; // No perfil do usuario, não traz onboardingData
    setOnbordingPageConcluded(
      index,
      dataActiveStep,
      onboardingData[index].bf_concluded || concluded
    );
  };

  validate(question, answer) {
    if (
      question.ox_questionconfiguration.wx_fieldtype ===
      DYNAMIC_QUESTION_TYPE.TEXT
    ) {
      if (!answer || "".localeCompare(answer) === 0) {
        return "Por favor, preencha este campo.";
      }
      return null;
    }
    if (
      question.ox_questionconfiguration.wx_fieldtype ===
      DYNAMIC_QUESTION_TYPE.SELECT
    ) {
      if (!answer || "".localeCompare(answer) === 0) {
        return "Por favor, preencha este campo.";
      }
      return null;
    }
    if (
      question.ox_questionconfiguration.wx_fieldtype ===
      DYNAMIC_QUESTION_TYPE.RANGE
    ) {
      if (typeof Number(answer) !== "number") {
        // I can't imagine how this can happen.
        return "Por favor, preencha este campo.";
      }
      if (
        Number(answer) <
          question.ox_questionconfiguration.ox_fieldproperties.nx_minvalue ||
        Number(answer) >
          question.ox_questionconfiguration.ox_fieldproperties.nx_maxvalue
      ) {
        return "Valor fora do permitido.";
      }
      return null;
    }
    if (
      question.ox_questionconfiguration.wx_fieldtype ===
      DYNAMIC_QUESTION_TYPE.TOGGLE
    ) {
      return null;
    }
    if (
      question.ox_questionconfiguration.wx_fieldtype ===
      DYNAMIC_QUESTION_TYPE.FILE
    ) {
      if (!answer || "".localeCompare(answer) === 0) {
        return "Por favor, preencha este campo.";
      }
      return null;
    }

    console.warn("Field of unknown type; skipping validation.");
    return null;
  }

  renderMarks = (max, labels) => {
    const marks = {};
    for (let i = 0; i <= max; i++) {
      marks[i] = labels[i];
    }
    return marks;
  };

  render() {
    const { index, questions, onboardingData } = this.props;
    const { answers, validationErrors, isVisibleTitle, fileConfig } = this.state;

    return (
      <Container className="container">
        {isVisibleTitle && <Title> {index + 1}. {_.get(onboardingData[index], 'wx_title', 'Suas Metas de Estudos')}</Title>}

        <ContainerForm>
          {questions.map((q, i) => (
            <div className="row justify-content-md-center" key={q._id}>
              <div className="col-md-8 form-group">
                {q.ox_questionconfiguration.wx_fieldlabel &&
                "".localeCompare(q.ox_questionconfiguration.wx_fieldlabel) !==
                  0 ? (
                  <label style={{ fontWeight: "bold" }}>
                    {q.ox_questionconfiguration.wx_fieldlabel}
                  </label>
                ) : null}

                {q.ox_questionconfiguration.wx_fieldtype ===
                  DYNAMIC_QUESTION_TYPE.TEXT && (
                  <input
                    className="form-control"
                    id={`component-${i}`}
                    type="text"
                    placeholder={q.ox_questionconfiguration.wx_fieldplaceholder}
                    onChange={e => {
                      this.setAnswerAtIndex(i, e.target.value);
                    }}
                    value={answers[i] || ""}
                  />
                )}

                {q.ox_questionconfiguration.wx_fieldtype ===
                  DYNAMIC_QUESTION_TYPE.SELECT && (
                  <select
                    className="form-control"
                    id={`component-${i}`}
                    value={answers[i] || ""}
                    onChange={e => {
                      this.setAnswerAtIndex(i, e.target.value);
                    }}
                    name={q.ox_questionconfiguration.wx_fieldname}
                  >
                    {q.ox_questionconfiguration.ox_fieldproperties.qx_selectoptions.map(
                      option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      )
                    )}
                  </select>
                )}

                {q.ox_questionconfiguration.wx_fieldtype ===
                  DYNAMIC_QUESTION_TYPE.RANGE && (
                  <ContainerSlider style={{ marginBottom: "20px" }}>
                    <Slider
                      dots
                      id={`component-${i}`}
                      handle={props =>
                        handle(
                          props,
                          q.ox_questionconfiguration.ox_fieldproperties
                            .qx_rangestepshints
                        )
                      }
                      onChange={e => {
                        this.setAnswerAtIndex(i, Number(e));
                      }}
                      handleStyle={[
                        { backgroundColor: "#fff", borderColor: "#27aae1" },
                        { backgroundColor: "#27aae1", borderColor: "#27aae1" }
                      ]}
                      trackStyle={[
                        { backgroundColor: "#27aae1", borderColor: "#27aae1" },
                        { backgroundColor: "#27aae1" }
                      ]}
                      railStyle={{ height: 5 }}
                      defaultValue={Number(answers[i])}
                      min={
                        q.ox_questionconfiguration.ox_fieldproperties
                          .nx_minvalue
                      }
                      max={
                        q.ox_questionconfiguration.ox_fieldproperties
                          .nx_maxvalue
                      }
                      marks={this.renderMarks(
                        q.ox_questionconfiguration.ox_fieldproperties
                          .nx_maxvalue,
                        q.ox_questionconfiguration.ox_fieldproperties
                          .qx_rangestepslabels
                      )}
                      step={
                        q.ox_questionconfiguration.ox_fieldproperties
                          .nx_rangestep
                      }
                    />

                    <TextRanger>
                      {
                        q.ox_questionconfiguration.ox_fieldproperties
                          .qx_rangestepshints[
                          Math.round(
                            (answers[i] -
                              q.ox_questionconfiguration.ox_fieldproperties
                                .nx_minvalue) /
                              q.ox_questionconfiguration.ox_fieldproperties
                                .nx_rangestep
                          )
                        ]
                      }
                    </TextRanger>
                  </ContainerSlider>
                )}
                {q.ox_questionconfiguration.wx_fieldtype ===
                  DYNAMIC_QUESTION_TYPE.TOGGLE && (
                  <BoxButtons>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <Button
                        type="button"
                        className="btn"
                        id={`button-component-${i}-not`}
                        active={!answers[i]}
                        onClick={() => {
                          this.setAnswerAtIndex(i, false);
                        }}
                      >
                        Não
                      </Button>
                      <Button
                        id={`button-component-${i}-yes`}
                        type="button"
                        className="btn"
                        active={!!answers[i]}
                        onClick={() => {
                          this.setAnswerAtIndex(i, true);
                        }}
                      >
                        Sim
                      </Button>
                    </div>
                  </BoxButtons>
                )}

                {q.ox_questionconfiguration.wx_fieldtype ===
                  DYNAMIC_QUESTION_TYPE.FILE && (
                  <Upload 
                    setAnswerAtIndex={(urlFile) => this.setAnswerAtIndex(i, urlFile)} 
                    answers={answers[i]} 
                    fileConfig={fileConfig}   
                  />
                )}
                {!!validationErrors[i] && (
                  <TextError>{validationErrors[i]}</TextError>
                )}
              </div>
            </div>
          ))}
        </ContainerForm>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  onboardingData: state.onboarding.onboardingData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OnboardingActions
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(OnboardingPageDynamic);
