import jwtDecode from "jwt-decode";

export const TOKEN_KEY = "@Lit/token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = token => {
  const decodeToken = jwtDecode(token);
  localStorage.setItem(TOKEN_KEY, token);
  if (decodeToken.company) {
    localStorage.setItem("company", decodeToken.company);
  }

  if(decodeToken.tenant) {
    const tokenTenant = localStorage.getItem("tokenTenant");
    if(!tokenTenant) {
      localStorage.setItem("tokenTenant", decodeToken.tenant);
    }
    localStorage.setItem("tenant", decodeToken.tenant);
  }

  if(decodeToken.crCode) {
    const tokenCrCode = localStorage.getItem("tokenCrCode"); 
    if(!tokenCrCode){
      localStorage.setItem("tokenCrCode", decodeToken.crCode);
    }
    localStorage.setItem("crCodeProfile", decodeToken.crCode);
  }
};
export const logout = () => {
  localStorage.clear();
};
export const urlencode = (str) => {
  str = (`${str  }`).toString();

  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return encodeURIComponent(str)
    .replace('!', '%21')
    .replace('\'', '%27')
    .replace('(', '%28')
    .replace(')', '%29')
    .replace('*', '%2A')
    .replace('%20', '+');
}