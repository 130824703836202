import React, { Component } from "react"
import Img from "react-image"
import { Link } from "react-router-dom"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import styled from "styled-components"
import Loading from "../Loading/Loading"

import { API_URL_IMAGES } from "../../utils/constants"

import api from "../../utils/api"

const ButtonWrap = styled.button`
  cursor: pointer;
  position: absolute;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 1.3em;
  outline: transparent;

  &:focus {
    outline: transparent;
  }
`

const ButtonAfter = styled(ButtonWrap)`
  left: 0;
`
const ButtonNext = styled(ButtonWrap)`
  right: 0;
`
class Ministrados extends Component {
  state = {
    fetching: false,
    disciplinas: [],
  }

  responsive = {
    0: { items: 1 },
    1024: { items: 3 },
    2048: { items: 4 },
  }

  componentDidMount() {
    this.loading()

    this.courses()
  }

  courses = async () => {
    const { idProfile } = this.props
    try {
      const response = await api.get(`listdisciplinesprofessor/${idProfile}/`)
      this.setState({ disciplinas: response })
      this.loading()
    } catch (error) {
      console.log(error)
      this.loading()
    }
  }

  loading = () => {
    const { fetching } = this.state
    this.setState({ fetching: !fetching })
  }

  renderItensCourses = () => {
    const { disciplinas } = this.state
    return disciplinas.map(curso => {
      return (
        <Link key={`${curso.name}`} to={`/curso/${curso.totvsId}`}>
          <div className="container-img mx-1 ">
            <Img
              alt="Curso"
              className="rounded-0 img-course img-responsive projetos projeto-mascara"
              src={`${API_URL_IMAGES}images/courses/${curso.totvsId}.jpg`}
            />
          </div>
        </Link>
      )
    })
  }

  render() {
    const { fetching, disciplinas } = this.state

    if (fetching) {
      return <Loading width={40} />
    }

    return (
      <div className="bg-white-perfil" id="sobre-usuario">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <h5>Cursos Ministrados</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12" id="search-grupo">
              {disciplinas.length > 0 ? (
                <div className="d-flex flex-wrap">
                  <AliceCarousel
                    items={this.renderItensCourses()}
                    responsive={this.responsive}
                    dotsDisabled
                    buttonsDisabled
                    infinite={false}
                    mouseDragEnabled
                    ref={el => {
                      this.Carousel = el
                    }}
                  />
                  <ButtonAfter type="button" onClick={() => this.Carousel.slidePrev()}>
                    <i className="fa fa-angle-double-left" aria-hidden="true" />
                  </ButtonAfter>
                  <ButtonNext type="button" onClick={() => this.Carousel.slideNext()}>
                    <i className="fa fa-angle-double-right" aria-hidden="true" />
                  </ButtonNext>
                </div>
              ) : (
                <p>Não há cursos ministrados</p>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ministrados
