import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../../constants";

/**
 * @param {String} textHeader //text displayed at the top of the modal
 * @param {String} idModal //modal identification code
 * @param {Function} closeModal //action triggered when clicking on modal close button
 * @param {String} pageImage //image displayed at the top of the modal
**/

export default ({
  textHeader = DEFAULT_VALUES.STRING,
  idModal = DEFAULT_VALUES.STRING,
  closeModal = DEFAULT_VALUES.FUNCTION,
  pageImage = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        textHeader={textHeader}
        pageImage={pageImage}
        idModal={idModal}
        closeModal={closeModal}
      />
    </>
  );
};
