const imagens = {
  videoIcon: require("../images/videoIcon.png"),
  leituraIcon: require("../images/leituraIcon.png"),
  vamosPraticarIcon: require("../images/vamosPraticarIcon.png"),
  forumAvaliativoIcon: require("../images/forumAvaliativoIcon.png"),
  livrosIcon: require("../images/livrosIcon.png"),
  scormIcon: require("../images/scormIcon.png"),
  xlsIcon: require("../images/xlsIcon.png"),
  docIcon: require("../images/docIcon.png"),
  saintPaulLit: require("../images/saintPaulLit.png"),
  simuladoIcon: require("../images/simuladoIcon.png"),
  pdfImg: require("../images/pdf-img.png"),
  docImg: require("../images/doc-img.png"),
  txtImg: require("../images/txt-img.png"),
  jpgImg: require("../images/jpg-img.png"),
  pptImg: require("../images/ppt-img.png"),
  xlsImg: require("../images/xls-img.png"),
  zipImg: require("../images/zip-img.png"),
  notFound: require("../images/not-found-img.png"),
  certificateIcon: require("../images/certificate-icon.png"),
  iconCheck: require("../images/icon-check.png"),
  volume: require("../images/volume.png"),
  mute: require("../images/mute-audio.png"),
};

export default imagens;
