import React, { Component } from "react"

import Loading from "../Loading/Loading"

import api from "../../utils/api"

class Especialidades extends Component {
  state = {
    fetching: false,
    especialidades: [],
  }

  componentDidMount() {
    this.loading()
    this.getEspecialidades()
  }

  getEspecialidades = async () => {
    try {
      const { idProfile } = this.props
      const response = await api.get(`trackSuggestionByStudent/${idProfile}`)
      this.loading()
      this.setState({ especialidades: response })
    } catch (error) {
      console.log(error)
      this.loading()
    }
  }

  loading = () => {
    const { fetching } = this.state
    this.setState({ fetching: !fetching })
  }

  render() {
    const { fetching, especialidades } = this.state

    if (fetching) {
      return <Loading width={40} />
    }

    return (
      <div className="cursos bg-white-perfil" id="especialidades">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-6">
              <h5>Especialidades</h5>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div data-toggle="buttons">
                {especialidades.map(suggestion => {
                  return (
                    <label
                      className="btn btn-grey interesses-space"
                      key={suggestion.suggestion.idSuggestion}
                    >
                      <input type="checkbox" />
                      <i
                        className="fa fa-star-o blue-tooltip"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="151 XP"
                      />{" "}
                      {suggestion.suggestion.nameSuggestion}
                    </label>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Especialidades
