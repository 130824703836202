import React, { useEffect } from "react";
import { FaSpinner } from "react-icons/fa";

import ForumComment from "./subcomponents/ForumComment";
import Pagination from "./subcomponents/Pagination";
import { Container, LoadingWrapper } from "./styles";

function ForumView({
  viewMode,
  loading,
  data = [],
  page = 1,
  pageLimit = 100,
  totalPages = 0,
  onAnswer,
  onPageChange,
  hasNextPage,
  hasPrevPage,
  redirectTo,
  getRootProps
}) {
  useEffect(() => {
    const element = document.getElementById(redirectTo);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [data, redirectTo]);

  return (
    <Container>
      {loading && (
        <LoadingWrapper>
          <FaSpinner size={20} color="#1890ff" />
        </LoadingWrapper>
      )}

      {!loading && data && data.length === 0 && (
        <p>Esse fórum ainda não possuí nenhuma publicação, seja o primeiro!</p>
      )}

      {!loading &&
        data &&
        data.map((item, index) => (
          <ForumComment
            viewMode={viewMode}
            path={`${index}`}
            onAnswer={onAnswer}
            key={item.code}
            data={item}
            lastItem={data.length - 1 === index}
            getRootProps={getRootProps}
          />
        ))}

      <footer>
        {totalPages > 0 && (
          <Pagination
            page={page}
            pageLimit={pageLimit}
            totalPages={totalPages}
            onPageChange={onPageChange}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
          />
        )}
      </footer>
    </Container>
  );
}

export default ForumView;
