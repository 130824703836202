import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #eeeeee;
`;

export const LoadingContainer = styled.div`
  text-align: center;
  padding-top: 20%;
  margin-bottom: -20%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingLabel = styled.div`
  padding-left: 12px;
  font-size: 1.25rem;
`;
