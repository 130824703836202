import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { Creators as PaymentActions } from '../../store/ducks/payments/actions';
import {
  setSEOIndexOff,
  renewToken,
  toastDefaultOptions
} from '../../utils/functions';
import api from '../../utils/api';
import adsTracking from '../../utils/adsTracking';

import PaymentMethodsSection from './componentes/PaymentMethodsSection';
import Template from './_template';

import { proccessInvoicePayment } from '../../business/payment';

import { UserActions } from '../../store/ducks/user';
import history from '../../utils/history';
import ConfirmCharge from '../ConfirmCharge';

class Formulario extends Component {
  static contextTypes = {
    router: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.state = {
      plan: null,
      paymentError: '',
      paymentInvoiceError: '',
      payrollError: '',
      sendPayment: false,
      installments: 1,
      editPayment: false,
      cpf: props.profile.data.cpf,
      showTrial: false,
      onSubmit: null
    };
  }

  eCommerceEvent(plan, response, callback) {
    adsTracking.sendGoogleAnalyticsEcommerceEvent({
      eventName: 'purchase',
      step: {
        id: response.idTransaction || null,
        revenue: plan.price,
        /** validar se esta é a melhor forma de pegar o cupom de desconto  */
        coupon: _.get(plan, 'claims.0', '')
      },
      products: [
        {
          name: plan.description,
          id: plan.id,
          price: plan.price,
          brand: 'LIT',
          // category: adsTracking.CATEGORY_DEFAULT,
          quantity: 1
        }
      ],
      callback
    });
  }

  componentDidMount() {
    const { location } = this.props;
    if (!location.state) {
      history.push('/planos');
      return;
    }
    this.setState({ plan: location.state.plan });
    setSEOIndexOff();
    adsTracking.sendGoogleAnalyticsEcommerceEvent({
      step: adsTracking.STEP5_ACCESS_PAYMENT_PAGE,
      products: [
        {
          name: location.state.plan.description,
          id: location.state.plan.id,
          price: location.state.plan.price,
          brand: 'LIT',
          // category: adsTracking.CATEGORY_DEFAULT,
          quantity: 1
        }
      ]
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state.plan) {
      if (prevProps.location.state.plan !== prevState.plan) {
        this.updatePlan(prevProps.location.state.plan);
      }
    }
  }

  updatePlan = plan => {
    this.setState({ plan });
  };

  notify = text => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000
    });
  };

  handleTrial = onSubmit => {
    const { plan } = this.state;
    if (plan.isPayrollMethod) {
      onSubmit();
    } else {
      this.setState({ onSubmit, showTrial: true });
    }
  };

  pay = async pagamento => {
    const { location } = this.props;
    const { router } = this.context;
    const { plan } = this.state;

    this.setState({ sendPayment: true, showTrial: false });

    try {
      const response = await api.post('doRecurringPayment', pagamento);
      const userData = await api.get('/me');
      let redirectPath = '';

      await renewToken();

      localStorage.setItem('voucherAtivo', true);
      localStorage.setItem('freeUser', response.freeUser);
      localStorage.setItem('libraryUser', response.libraryUser);
      localStorage.setItem('socialUser', response.socialUser);
      localStorage.setItem('showPaul', response.showPaul);
      localStorage.setItem('redirectMyCourse', response.redirectMyCourse);
      localStorage.removeItem('paymentInfo');
      localStorage.removeItem('data-card');
      localStorage.removeItem('planByUrl');
      localStorage.removeItem('typeByUrl');

      if (response.redirectMyCourse) {
        redirectPath = '/cursos';
      } else if (location.state && location.state.voucher) {
        redirectPath = {
          pathname: `carreira`,
          state: { isPartner: true }
        };
      } else if (userData.onBoardIncomplete) {
        redirectPath = '/sucesso_litpass';
      } else {
        redirectPath = '/sucesso_litpass?onboardIsCompleted=true';
      }

      this.eCommerceEvent(plan, response, () => {
        router.history.push(redirectPath);
      });
    } catch (error) {
      if (error.response) {
        this.setState({
          paymentError:
            error.response.data.status === 400
              ? error.response.data.message
              : 'Erro ao validar o cartão, favor revise os dados do formulário!',
          sendPayment: false
        });
      } else {
        this.setState({
          paymentError: 'Um erro ocorreu ao realizar o pagamento.',
          sendPayment: false
        });
      }
      const { paymentError } = this.state;
      adsTracking.sendGoogleAnalyticsEvent({
        eventName: 'purchase_error',
        eventCategory: 'purchase_flow',
        eventLabel: `error:${paymentError}`,
        eventValue: plan.id,
        extraData: {
          price: plan.price
        }
      });
    }
  };

  payRoll = async pagamento => {
    const { location } = this.props;
    const { router } = this.context;
    this.setState({ sendPayment: true, showTrial: false });
    const { plan } = this.state;

    try {
      const response = await api.post('dopayment', pagamento);

      await renewToken();

      let redirectPath = '';
      localStorage.setItem('voucherAtivo', true);
      localStorage.setItem('freeUser', response.freeUser);
      localStorage.setItem('libraryUser', response.libraryUser);
      localStorage.setItem('socialUser', response.socialUser);
      localStorage.setItem('showPaul', response.showPaul);
      localStorage.removeItem('paymentInfo');
      if (response.redirectMyCourse) {
        redirectPath = '/cursos';
      } else if (location.state && location.state.voucher) {
        redirectPath = {
          pathname: `onboarding`,
          state: { isPartner: true }
        };
      } else {
        redirectPath = '/onboarding';
      }
      this.eCommerceEvent(plan, response, () => {
        router.history.push(redirectPath);
      });
    } catch (error) {
      if (error.response) {
        this.setState({
          payrollError: error.response.data.message,
          sendPayment: false
        });
      } else {
        this.setState({
          payrollError: 'Um erro ocorreu ao realizar o pagamento.',
          sendPayment: false
        });
      }
      const { payrollError } = this.state;
      adsTracking.sendGoogleAnalyticsEvent({
        eventName: 'purchase_error',
        eventCategory: 'purchase_flow',
        eventLabel: `error:${payrollError}`,
        eventValue: plan.id,
        extraData: {
          price: plan.price
        }
      });
    }
  };

  handleInvoicePayment = async (
    formValue,
    actions,
    term,
    installments,
    claimId
  ) => {
    const { plan, editPayment } = this.state;
    const { router } = this.context;
    const { ...restFormValues } = formValue;
    this.setState({ showTrial: false });
    try {
      const response = await proccessInvoicePayment(
        plan,
        restFormValues,
        editPayment,
        term,
        installments,
        claimId
      );
      actions.setSubmitting(false);
      const { nextPage, nextPageParams, data } = response;

      localStorage.setItem('voucherAtivo', data.voucherAtivo);
      localStorage.setItem('freeUser', data.freeUser);
      localStorage.setItem('libraryUser', data.libraryUser);
      localStorage.setItem('socialUser', data.socialUser);
      localStorage.setItem('showPaul', data.showPaul);
      localStorage.removeItem('paymentInfo');

      this.eCommerceEvent(plan, response, () => {
        router.history.push(nextPage, { nextPageParams });
      });
    } catch (error) {
      actions.setSubmitting(false);
      if (error.response) {
        this.setState({
          paymentInvoiceError: error.response.data.message,
          sendPayment: false
        });
      } else {
        this.setState({
          paymentInvoiceError: 'Um erro ocorreu ao realizar o pagamento.',
          sendPayment: false
        });
      }

      const { paymentInvoiceError } = this.state;
      adsTracking.sendGoogleAnalyticsEvent({
        eventName: 'purchase_error',
        eventCategory: 'purchase_flow',
        eventLabel: `error:${paymentInvoiceError}`,
        eventValue: plan.id,
        extraData: {
          price: plan.price
        }
      });
    }
  };

  back = () => {
    const { router } = this.context;
    router.history.push({
      pathname: '/planos',
      state: { isBackPayment: true }
    });
  };

  voucherFree = async () => {
    const idProfile = localStorage.getItem('idProfile');
    const { plan } = this.state;
    // eslint-disable-next-line no-shadow
    const { history } = this.props;
    try {
      const response = await api.get(
        `doAtribuirVoucher/${idProfile}/${plan.voucher}`
      );

      await renewToken();

      localStorage.setItem('voucherAtivo', true);
      localStorage.setItem('freeUser', response.freeUser);
      localStorage.setItem('libraryUser', response.libraryUser);
      localStorage.setItem('socialUser', response.socialUser);
      localStorage.setItem('showPaul', response.showPaul);
      history.push('/onboarding');
      adsTracking.sendGoogleAnalyticsEvent({
        eventName: 'purchase_success',
        eventCategory: 'purchase_flow',
        eventLabel: 'VOUCHER',
        eventValue: plan.id,
        extraData: {
          price: plan.price
        },
        callback: () => {
          history.push('/onboarding');
        }
      });
    } catch (error) {
      this.setState({
        paymentError: 'Um erro ocorreu ao aplicar o voucher.',
        sendPayment: false
      });
      const { paymentError } = this.state;
      adsTracking.sendGoogleAnalyticsEvent({
        eventName: 'purchase_error',
        eventCategory: 'purchase_flow',
        eventLabel: `error:${paymentError}`,
        eventValue: plan.id,
        extraData: {
          price: plan.price
        }
      });
    }
  };

  redirectPi = event => {
    event.preventDefault();
    this.setState({ sendPayment: true });
    this.voucherFree();
  };

  notify = (text, duration = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: duration
    });
  };

  resetPaymentError = () => {
    this.setState({ paymentError: '' });
  };

  render() {
    const {
      installments,
      sendPayment,
      plan,
      paymentError,
      paymentInvoiceError,
      payrollError,
      showTrial,
      onSubmit,
      cpf
    } = this.state;

    const { location } = this.props;

    return (
      <>
        {showTrial ? (
          <ConfirmCharge
            onSubmit={() => onSubmit()}
            onCancel={() => this.setState({ showTrial: false })}
          />
        ) : (
          <Template>
            {plan && (
              <PaymentMethodsSection
                paymentData={plan}
                location={location}
                loading={sendPayment}
                back={this.back}
                onCreditCardPayment={this.pay}
                onPayrollSubmit={this.payRoll}
                onInvoicePayment={this.handleInvoicePayment}
                error={paymentError}
                paymentInvoiceError={paymentInvoiceError}
                payrollError={payrollError}
                onRedirectPi={this.redirectPi}
                installments={installments}
                cpf={cpf}
                handleTrial={this.handleTrial}
                resetPaymentError={this.resetPaymentError}
              />
            )}
          </Template>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    paymentSelect: state.payments,
    profile: state.user.profile
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(PaymentActions, UserActions.ProfileActions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Formulario)
);
