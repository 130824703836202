import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { toast } from "react-toastify"
import { gateway } from "../../utils/api"
import { toastErrorAndWarningOptions, toastDefaultOptions } from '../../utils/functions'
import ModalViewTerm from "../Modals/ModalViewTerm"

const TextTitle = styled.h3`
  font-family: "Dosis", sans-serif;
  font-size: 2.2em;
  text-align: center;
  font-weight: lighter;
  margin-bottom: 10px !important;
  font-stretch: condensed;
  color: #27aae1;
`

const Wrapper = styled.div`
  max-width: 100%;
`

class RightSideBar extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  constructor() {
    super()
    this.state = {
      termId: "",
      assinatura: "",
      termText: "",
      error: "",
      loading: false,
      modalTerms: false
    }
  }

  componentDidMount() {
    // const path = window.location.pathname.split("/")
    // const hash = path[path.length - 1]

    // if (term !== "validador") {
    //   this.setState({ hash }, this.validarCertificado)
    // }
  }

  notify = (text, timeClose = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: timeClose,
    })
  }

  getTerm = async event => {
    if (event) {
      event.preventDefault()
    }
    this.setState({ error: "", loading: true })
    const { assinatura } = this.state

    try {
      const url = `/subscription/signature/${assinatura}`
      const response = await gateway.get(url)

      this.setState({ loading: false, termText: response, modalTerms: true })

    } catch (error) {
      this.setState({ loading: false })
      toast.error('Erro ao buscar termo!', toastErrorAndWarningOptions)
    }
  }

  removeHash = () => {
    this.setState({ assinatura: "", error: "" })
  }


  render() {
    const { assinatura, loading, error, modalTerms, termText } = this.state
    return (
      <Fragment>
        <TextTitle>Buscar Termo</TextTitle>
        <Wrapper>
          <form style={{ marginTop: "2em" }} onSubmit={this.getTerm}>
            <div className="field-float-label-pattern">
              <div>
                <input
                  type="text"
                  placeholder="Assinatura digital"
                  className="form-control input-not-yellow input-inline-block"
                  id="inputCodigoConfirmacao"
                  value={assinatura}
                  onChange={evt => this.setState({ assinatura: evt.target.value })}
                  required
                />
                <label htmlFor="inputCodigoConfirmacao">Assinatura digital</label>
              </div>
            </div>

            <div className="row justify-content-center">
              <button
                type="submit"
                disabled={loading}
                className={`btn btn-login btn-FontWeight6${loading && " btn-loading"}`}
              >
                Buscar
              </button>
            </div>
          </form>
          {error && (
            <p className="text-center m-2" style={{ color: "#e50000" }}>
              {error}
            </p>
          )}
        </Wrapper>
        <ModalViewTerm
          visible={modalTerms}
          title="Termo"
          text={termText}
          onClose={() => (this.setState({ modalTerms: false }))}
        />
      </Fragment>
    )
  }
}

export default RightSideBar
