import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Container = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 85px;
  right: 0px;
  width: 310px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: transparent !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px;

  width: 300px;
  margin: 5px auto;
  height: 425px;
  max-width: 100%;
  overflow-y: hidden;

  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2);

  ul {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  }

  ul {
    flex: 1;
    overflow-y: auto;
    padding: 0;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px;
      overflow-y: auto;

      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }

      span {
        padding: 5px 15px;
        border-radius: 20px;

        small {
          display: block;
          font-weight: bold;
        }
      }
    }

    li.received {
      justify-content: flex-start;

      img {
        margin-right: 10px;
      }

      span {
        background-color: #42b3c9;
        color: #fff;
      }
    }

    li.sent {
      flex-direction: row-reverse;

      img {
        margin-left: 10px;
      }

      span {
        background-color: #f3f3f3;
        color: #9fa1a5;
      }
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 1px solid #dfdfdf;
    border-radius: 5px;

    textarea {
      flex: 1;
      resize: none;
      border: none;
      outline: none;
      height: 45px;
    }

    button {
      background-color: transparent;
      color: #42b3c9;
      cursor: pointer;
      border: none;
      outline: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    animation: ${rotate360} 1s linear infinite;
    margin-right: 5px;
  }
`;

export const ButtonClose = styled.button`
  border: none;
  background: transparent;
  text-align: right;
  padding: 10px;
  cursor: pointer;

  svg {
    animation: none !important;
  }
`;
