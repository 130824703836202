import React from "react";
import { APPEARANCE } from "../../../constants";
import ButtonModal from "../../basicComponents/Button";
import Modal from "../../Modals/NoHeaderModal";
import { Agrupable } from "./styles";

export default ({
  child,
  modalHeaderText,
  isOpen = undefined,
  handleIsOpen
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function toggleModal() {
    handleIsOpen && handleIsOpen(!isOpen);
    setIsOpen(!modalIsOpen);
  }

  return (
    <>
      <Agrupable>
        <ButtonModal
          appearance={APPEARANCE.INVISIBLE}
          text={""}
          onClick={toggleModal}
        />
        <Modal
          isOpen={isOpen !== undefined ? isOpen : modalIsOpen}
          closeModal={toggleModal}
          textHeader={modalHeaderText}
        >
          {child}
        </Modal>
      </Agrupable>
    </>
  );
};
