/**
 * Types
 */
export const Types = {
  GET_COURSE_REQUEST: "course/GET_COURSE_REQUEST",
  GET_COURSE_SUCCESS: "course/GET_COURSE_SUCCESS",
  GET_COURSE_FAILURE: "course/GET_COURSE_FAILURE",
  GET_COURSE_PROGRESS_REQUEST: "course/GET_COURSE_PROGRESS_REQUEST",
  GET_COURSE_PROGRESS_SUCCESS: "course/GET_COURSE_PROGRESS_SUCCESS",

  SET_DISCIPLINE_SELECTED: "course/SET_DISCIPLINE_SELECTED",
  SET_COURSE_SELECTED: "course/SET_COURSE_SELECTED",
  SET_NAVIGATION_CONTENT: "course/SET_NAVIGATION_CONTENT",
  SET_PLAYER_POSITION: "course/SET_PLAYER_POSITION",
  SET_DISCIPLINE_PROGRESS: "course/SET_DISCIPLINE_PROGRESS",
  SET_DISCIPLINE_SUCCESS: "course/SET_DISCIPLINE_SUCCESS",
  SET_AUTOPLAY_VIDEOS: "course/SET_AUTOPLAY_VIDEOS",
  SET_WANING_DISPLAYED: "course/SET_WANING_DISPLAYED",

  SET_LAST_SYNCED_GRADE_DISCIPLINE: "course/SET_LAST_SYNCED_GRADE_DISCIPLINE",

  FETCH_SIMULATED_GRADES_BY_UC: "course/FETCH_SIMULATED_GRADES_BY_UC",

  SYNC_GRADE_BY_DISCIPLINE: "course/SYNC_GRADE_BY_DISCIPLINE",
  DEFINE_DISCIPLINE_AS_SYNCHRONIZED: "course/DEFINE_DISCIPLINE_AS_SYNCHRONIZED",

  FETCH_EVALUATION_GRADE: "course/FETCH_EVALUATION_GRADE",
  FETCH_FORUM_GRADES_BY_UC: "course/FETCH_FORUM_GRADES_BY_UC",

  FORCE_SYNC: "course/FORCE_SYNC",
  SET_VALUE_FORCE_SYNC: "course/SET_VALUE_FORCE_SYNC",

  RESET_COURSE: "course/RESET_COURSE"
};
