/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevDisable: true,
      nextDisable: !!(
        this.refs && this.refs.offsetWidth >= this.refs.scrollWidth
      )
    };
  }

  componentDidMount() {
    this.checkButtons(this.refs.offsetWidth, this.refs.scrollWidth);
  }

  componentDidUpdate({ currentValue }) {
    if (this.props && this.props.children && (currentValue !== this.props.currentValue))
      this.scrollContainer(this.props.children);
  }

  handleNext = () => {
    this.setState({ prevDisable: false });
    const offsetWidthValue = this.refs.offsetWidth;
    const scrollWidthValue = this.refs.scrollWidth;

    this.refs.scrollLeft += offsetWidthValue / 2;
    this.checkButtons(offsetWidthValue, scrollWidthValue);
  };

  checkButtons = (offsetWidthValue, scrollWidthValue) => {
    const { prevDisable } = this.state;
    if (!prevDisable && this.refs.scrollLeft <= 0) {
      this.setState({ prevDisable: true });
    }
    this.setState({
      nextDisable: this.refs.scrollLeft + offsetWidthValue >= scrollWidthValue
    });
  };

  scrollContainer(children) {
    const elementMarginRight = 10;
    let scrolledWidth = 0;

    const childrenCurrentItem = children.find(({ props: { innerRef, current } }) => {
      if (innerRef.current)
        scrolledWidth += innerRef.current.offsetWidth + elementMarginRight;

      if (current) return true;
    });

    if (!childrenCurrentItem) return;

    const { props: { innerRef: currentItemInnerRef } } = childrenCurrentItem

    if (currentItemInnerRef.current) {
      scrolledWidth -= currentItemInnerRef.current.offsetWidth + elementMarginRight;
      (this.refs.scrollLeft || this.refs.scrollLeft === 0) && (this.refs.scrollLeft = scrolledWidth);
    }
  }

  render() {
    const offsetWidthValue = this.refs.offsetWidth;
    const scrollWidthValue = this.refs.scrollWidth;
    const { children } = this.props;

    return (
      <div
        className="slider-container"
        ref={el => {
          this.refs = el;
        }}
      >
        <div className="slider-wrapper">{children}</div>
        <div
          className="btn prev"
          onClick={() => {
            this.refs.scrollLeft -= offsetWidthValue / 2;
            this.checkButtons(offsetWidthValue, scrollWidthValue);
          }}
        >
          <MdChevronLeft />
        </div>
        <div className="btn next" onClick={this.handleNext}>
          <MdChevronRight />
        </div>
      </div>
    );
  }
}

export default Slider;
