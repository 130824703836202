import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaSpinner, FaCheck } from "react-icons/fa";
import { AiOutlineUpload } from "react-icons/ai";
import _ from "lodash";

import { gateway } from "../../../../../../utils/api";
import { Container, DropZone, LoadingWrapper, SuccessWrapper } from "./styles";

import LitEditor from '../../../../../shared/LitEditor'

function Upload({
  renderLabel,
  value,
  index,
  onAnswerChange,
  onDisableActions,
  onUserComment,
  userComment
}) {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(async acceptedFiles => {
    const [file] = acceptedFiles;
    
    if (file) {
      try {
        onDisableActions(true);
        setLoading(true);

        const formData = new FormData();

        formData.append("file", file);
        formData.append("mediaType", "ASSESSMENT_RESPONSE");

        const response = await gateway.post("/media/media/upload", formData);
  
        onAnswerChange(
          index,
          _.get(response, "ox_standard.ox_identification.cr_code", null)
        );
        onDisableActions(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: loading,
    multiple: false
  });

  return (
    <Container>
      <DropZone {...getRootProps()}>
        <input {...getInputProps()} />

        {loading && (
          <LoadingWrapper>
            <FaSpinner size={20} color="#1890ff" />
            <span>Realizando upload do seu arquivo ...</span>
          </LoadingWrapper>
        )}

        {!loading && !value && (
          <p id="text-upload">
            Arraste e solte um arquivo aqui ou clique para selecionar.
            <AiOutlineUpload />
          </p>
        )}

        {!loading && value && (
          <SuccessWrapper>
            <FaCheck size={20} color="#1890ff" />
            <p>Upload realizado com sucesso</p>
            <small>
              Arraste e solte um arquivo aqui ou clique para selecionar um novo
              arquivo.
            </small>
          </SuccessWrapper>
        )}
      </DropZone>

      <div className="form-group w-100 mt-4">
              <label>Adicione um comentário</label>
              <textarea
                className="form-control"
                rows="4"
                value={userComment}
                disabled={loading}
                onChange={e => onUserComment(e.target.value)}
              ></textarea>
            </div>

    </Container>
  );
}

export default Upload;
