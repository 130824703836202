import React, { useState, useMemo, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import swal from '@sweetalert/with-react';
import * as Yup from 'yup';
import _ from 'lodash';

import { validarCpf as validateCpf } from '../../../../utils/functions';
import Text from '../Text';
import Loading from '../Loading';
import PaymentTerm from '../PaymentTerm';
import handleFormSubmitHelper from '../../helpers/handleFormSubmit';
import { gateway } from '../../../../utils/api';
import * as Styles from './styles';
import TextPaymentDefault from '../TextPaymentDefault';

import * as GeneralStyles from '../../styles';

function PayrollForm({ selectedPlan, onPaymentSuccess }) {
  const validationSchema = Yup.object().shape({
    cpf: Yup.string()
      .test('is-cpf', 'CPF inválido', validateCpf)
      .default('')
  });

  const [showTerm, setShowTerm] = useState(false);
  const [userTerm, setUserTerm] = useState(null);
  const [isCpfDisabled, setIsCpfDisabled] = useState(false);
  const [generatingTerm, setGeneratingTerm] = useState(false);
  const [alreadyGeneratedTerm, setAlreadyGeneratedTerm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(validationSchema.cast());

  const termTemplateReference = useMemo(() => {
    const foundPaymentMethod = selectedPlan.paymentMethods.find(
      paymentMethod => paymentMethod.type === 'PAYROLL'
    );

    return _.get(foundPaymentMethod, 'termTemplateReference', null);
  }, [selectedPlan]);

  async function handleGenerateTerm(values) {
    try {
      if (alreadyGeneratedTerm) {
        setShowTerm(true);
        return;
      }

      const { cpf } = values;

      setGeneratingTerm(true);
      await gateway.put('/webbff/lit_app_web/user/personaldata', {
        cpf: cpf.replace(/\D/g, '')
      });

      const response = await gateway.post(
        '/webbff/lit_app_web/term/generatePaymentTerm',
        {
          termTemplateCode: termTemplateReference,
          offerCode: selectedPlan.code
        }
      );

      setUserTerm(response);
      setShowTerm(true);
      setAlreadyGeneratedTerm(true);
    } catch (error) {
      console.log(error);
      const message = _.get(
        error,
        'response.data.message',
        'Não foi possível gerar o termo.'
      );
      swal({
        title: `Ops, ocorreu um erro.`,
        text: message
      });
    } finally {
      setGeneratingTerm(false);
    }
  }

  async function handleSubmitPayment() {
    try {
      setLoading(true);

      const paymentRequestBody = {
        type: 'PAYROLL',
        offerReference: selectedPlan.code,
        term:
          termTemplateReference && userTerm
            ? {
              mediaCode: _.get(
                userTerm,
                'ox_standard.ox_identification.cr_code'
              ),
              templateCode: termTemplateReference
            }
            : null,
        sellerCode: localStorage.getItem('sellerCode'),
        sellerLinkId: localStorage.getItem('sellerLinkId'),
      };


      const url = selectedPlan.alreadyPurchased
        ? '/webbff/lit_app_web/payment/updatePaymentMethod'
        : '/contract/contract/processSubscription';
      const method = selectedPlan.alreadyPurchased ? 'put' : 'post';

      const paymentResponse = await gateway[method](url, paymentRequestBody);

      onPaymentSuccess({
        selectedPlan,
        paymentResult: paymentResponse.paymentInfo,
        paymentMethod: 'PAYROLL',
        contractCode: _.get(
          paymentResponse,
          'contract.ox_standard.ox_identification.cr_code',
          ''
        )
      });
    } catch (error) {
      console.log(error);
      const message = _.get(
        error,
        'response.data.message',
        'Não foi possível processar o pagamento.'
      );
      swal({
        title: `Ops, ocorreu um erro.`,
        text: message
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function loadUserCpf() {
      try {
        const response = await gateway.get(
          '/webbff/lit_app_web/user/personaldata'
        );

        setInitialValues({
          cpf: response.cpf || ''
        });
        setIsCpfDisabled(!!response.cpf);
      } catch (error) {
        console.log(error);
      }
    }

    loadUserCpf();
  }, []);

  return (
    <>
      {!termTemplateReference && (
        <Text bold>Nenhum termo cadastrado para esse método de pagamento</Text>
      )}

      {termTemplateReference && (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleGenerateTerm}
          render={({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm
          }) => (
            <Styles.Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-4 col-sm-12">
                  <div className="form-group">
                    <Styles.LabelField>Confirme seu CPF</Styles.LabelField>
                    <Styles.ItemFormField>
                      <InputMask
                        maskChar={null}
                        mask="999.999.999-99"
                        name="cpf"
                        value={values.cpf}
                        onChange={event => {
                          setAlreadyGeneratedTerm(false);
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        disabled={isCpfDisabled}
                      >
                        {inputProps => {
                          return (
                            <Styles.InputText
                              {...inputProps}
                              disabled={isCpfDisabled}
                              placeholder="000.000.000-00"
                              error={touched.cpf && errors.cpf}
                              className="form-control"
                            />
                          );
                        }}
                      </InputMask>
                    </Styles.ItemFormField>
                  </div>
                </div>
              </div>

              <div className="row d-flex align-items-center">
                <div className="col-sm-12 col-lg-8">
                  <TextPaymentDefault />
                </div>
                <div className="col-sm-12 col-lg-4 d-flex align-items-center">
                  <div
                    className="box-buttons"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <GeneralStyles.CleanButton
                      visual="outline"
                      onClick={() => resetForm()}
                    >
                      Limpar
                    </GeneralStyles.CleanButton>

                    <GeneralStyles.ConfirmButton
                      disabled={loading || generatingTerm}
                    >
                      {loading || generatingTerm ? <Loading /> : 'Gerar termo'}
                    </GeneralStyles.ConfirmButton>
                  </div>
                </div>
              </div>

              <PaymentTerm
                visible={showTerm}
                onBack={() => setShowTerm(false)}
                onAccept={formValues =>
                  handleFormSubmitHelper({
                    selectedPlan,
                    processPaymentFunction: handleSubmitPayment,
                    formValues
                  })
                }
                onReject={() => setShowTerm(false)}
                termUrl={_.get(userTerm, 'ox_specific.wu_url')}
                processingAccept={loading}
              />
            </Styles.Form>
          )}
        />
      )}
    </>
  );
}

export default PayrollForm;
