import React, { useState } from "react";
import { toast } from "react-toastify";

import { ErrorLabel, Title } from "./styles";
import { gateway } from "../../../../utils/api";
import { maskPhone, toastErrorAndWarningOptions } from "../../../../utils/functions"
import { sendWhats } from "../../utilSendWhats";
import RequiredSpan from "../../../RequiredSpan";
import SubmitButton from "./SubmitButton";
import notify from '../../notify'

export default ({ coupon, couponLink, onRecommendationAdded }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorName, setErrorName] = useState("");
  const [recommendation, setRecommendation] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  
  const updateRecommendationState = (key, value) => setRecommendation({
    ...recommendation,
    [key]: value
  });
  
  const onSubmitForm = async () => {
    const { name, email, phoneNumber: phone } = recommendation
    
    if(!name) {
      setErrorName('O nome é obrigatório');
      return;
    }

    if(!email && !phone) {
      setError('Informe o email ou WhatsApp');
      return;
    }

    try {
      setLoading(true);
      const phoneNumber = phone ? phone.replace(/\D/g, "") : ""
      const userName = localStorage.getItem('firstName')
      const data = {
        name,
        email: email || null,
        phoneNumber,
        cupom: coupon,
        link: couponLink,
        invited: name,
        inviter: userName
      }

      await gateway.post('recommendation/mgm/invitations', data)

      if (phone && email) {
        sendWhats(coupon, name, phone)
        notify("Compartilhado com Email e WhatsApp")
      }
      if (phone && !email) {
        sendWhats(coupon, name, phone)
        notify("Compartilhado com WhatsApp")
      }
      if (email && !phone) {
        notify("Email enviado com sucesso")
      }

      setRecommendation({
        name: "", 
        email: "", 
        phoneNumber: ""
      });
      
      setLoading(false);

      onRecommendationAdded();
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.warn(error.response.data.message, toastErrorAndWarningOptions);
      console.log(error);
    }

  }
  
  return (
    <div className="row">
      <div className="col-md-12" style={{marginBottom: '20px'}}>
        <Title>Enviar cupom por email</Title>
      </div>
      <div className="col-md-3">
        <div className="field-float-label-pattern">
          <input
            type="text"
            id="name"
            autoComplete="off"
            placeholder="Nome *"
            className="form-control input-not-yellow"
            value={recommendation.name}
            onChange={(evt) => {
              updateRecommendationState('name', evt.target.value);
              setErrorName("");
            }}
          />

          {errorName && <ErrorLabel>{errorName}</ErrorLabel>}

          <label style={{ left: "auto" }} htmlFor="name">
            Nome <RequiredSpan />
          </label>
        </div>
      </div>
      <div className="col-md-3">

        <div className="field-float-label-pattern">
          <input
            type="text"
            id="email"
            autoComplete="off"
            placeholder="Email"
            className="form-control input-not-yellow"
            value={recommendation.email}
            onChange={(evt) => {
              updateRecommendationState('email', evt.target.value);
              setError("");
            }}
          />

          {error && <ErrorLabel>{error}</ErrorLabel>}

          <label htmlFor="email">Email</label>
        </div>
      </div>
      <div className="col-md-3">
        <div className="field-float-label-pattern">
          <input
            type="text"
            autoComplete="off"
            id="phone"
            placeholder="WhatsApp"
            data-for="indicacao"
            data-tip="Para envio no WhatsApp desbloqueie o popup do navegador"
            className="form-control input-not-yellow"
            value={recommendation.phoneNumber}
            onChange={(evt) => {
              updateRecommendationState('phoneNumber', maskPhone(evt.target.value.slice(0, 15)));
              setError("");
            }}
          />
          <label htmlFor="phone">WhatsApp</label>
        </div>
      </div>
      <div className="col-md-3">
        <SubmitButton onClicked={onSubmitForm} loading={loading}/>
      </div>
    </div>
  );
}
