import styled, { css } from "styled-components";
import { ORIENTATION } from "../../../constants";

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${props => (props.orientation === ORIENTATION.HORIZONTAL) && css`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  `}
  
`;

export const Description = styled.span`
  label {
    display: block;
  }
`;
