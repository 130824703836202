/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { gateway } from '../../../../../../utils/api';

import Screen from './screen';

function LaneWarnings({
  data,
  title,
  organizationCode,
  onClick,
  errorWarningsMessage
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [warnings, setWarnings] = useState([]);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    if (data) {
      const docs = _.get(data, 'docs', []);
      const page = _.get(data, 'page', 1);
      const limit = _.get(data, 'limit', 10);
      setLimit(limit);
      setWarnings(docs);
      setCurrentPage(page + 1);
    }
  }, [data]);

  const getMoreWarnings = useCallback(async () => {
    if (currentPage) {
      try {
        const query = `?limit=${limit}&page=${currentPage}`;
        const url = organizationCode
          ? `webbff/lit_app_web/classes/${organizationCode}/warnings${query}`
          : `webbff/lit_app_web/warnings${query}`;
        const response = await gateway.get(url);
        const hasNextPage = _.get(response, 'hasNextPage', false);
        const newWarnings = _.get(response, 'docs', []);

        setCurrentPage(hasNextPage ? currentPage + 1 : null);
        setWarnings(prevWarnings => [...prevWarnings, ...newWarnings]);
      } catch (error) {
        console.log(error);
      }
    }
  }, [currentPage, limit, organizationCode]);

  return (
    <Screen
      title={title}
      errorWarningsMessage={errorWarningsMessage}
      warnings={warnings}
      getMoreWarnings={getMoreWarnings}
      onClick={onClick}
    />
  );
}

export default LaneWarnings;
