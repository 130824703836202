import React, { useState } from 'react';
import PropTypes from "prop-types";

import moment from "moment"
import "moment/locale/pt-br"
import _ from 'lodash';
import Icon from "../../../../../LitAtomicComponents/basicComponents/Icon";
import { COLOR, ICON, SIZE } from "../../../../../constants";


import * as Styles from './styles';


function Screen({warning, onClick}) {

  const [headerGradient, setHeaderGradient] = useState(true);

  const settings = JSON.parse(localStorage.getItem('settings'));
  const tenant = localStorage.getItem('tenant') || '';

  const { headerBackgroundColor, headerBackgroundGradient } = _.get(
      settings,
      `${tenant}`,
      {}
    );

  const renderBackgroundNav = () =>
    headerGradient ? headerBackgroundGradient || headerBackgroundColor : 'rgb(123, 197, 149)';

  const cuttingTextDescription = description => { 
    const maxLength = 50;

    description = description.replace(/(<([^>]+)>)/gi, "")

    const hasSpace = description.slice(0, maxLength).lastIndexOf(" ") !== -1;

    if (description.length > maxLength) {
      if (hasSpace) {
        return `${description.slice(
          0,
          description.slice(0, maxLength).lastIndexOf(" ")
        )}...`;
      }
      return `${description.slice(0, maxLength)}...`;
    }
    return description;
  };

  return (
    // <Styles.CardContainer onClick={() => onClick(warning)}>
    //   <Styles.CardHeader>
    //     <Styles.Title>{warning.name}</Styles.Title>
    //   </Styles.CardHeader>
    //   <Styles.CardBody>
    //     <Styles.Message>
    //       {cuttingTextDescription(warning.message)}
    //     </Styles.Message>
    //   </Styles.CardBody>
    //   <Styles.CardFooter>
    //     <Styles.DateMessage>
    //         <Icon
    //         icon={ICON.CALENDAR}
    //         color={COLOR.DARK}
    //         size={SIZE.SMALL}
    //         />{moment(new Date(warning.date)).format('DD/MM/YYYY')}
    //       </Styles.DateMessage>
    //   </Styles.CardFooter>
    // </Styles.CardContainer>
    <Styles.Card>
      <Styles.CardHeader>
        <Styles.CardTitle>{cuttingTextDescription(warning.name)}</Styles.CardTitle>
      </Styles.CardHeader>
      <Styles.CardDivision />
      <Styles.CardContent>
        <Styles.CardText>
          {cuttingTextDescription(warning.message)}
        </Styles.CardText>
        <Styles.CardFooter>
          <Styles.CardTime dateTime={warning.publishDate}>
            Publicado {cuttingTextDescription(warning.time)}
          </Styles.CardTime>
          <Styles.CardButton type='button' onClick={() => onClick(warning)}>
            Ver mais
          </Styles.CardButton>
        </Styles.CardFooter>
      </Styles.CardContent>
    </Styles.Card>
  );
}

Screen.propTypes = {
  warning: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

Screen.defaultProps = {
  onClick: () => {}
}

export default Screen;
