import React from "react";

import LitEditor from '../../../../../shared/LitEditor'

function Descriptive({ value, index, onAnswerChange }) {
  return (
    <div className="form-group w-100">
       <LitEditor
        data={value}
        border
        minHeight={200}
        showToolbar={false}
        onChange={data => {
          if (onAnswerChange) {
            onAnswerChange(index, data);
          }
        }}
      />
      {/* <textarea
        className="form-control"
        id={`item-${id}-${index}`}
        value={value}
        rows="4"
        onChange={event => {
          
        }}
      ></textarea> */}
    </div>
  );
}

export default Descriptive;
