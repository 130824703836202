/* eslint-disable prefer-destructuring */
import React from "react"
import Parser from "html-react-parser"
import domToReact from "html-react-parser/lib/dom-to-react"
import axios from "axios"
import { WATSON_URL, watsonHeaderConfig } from "../../utils/constants"

import { Creators as PaulChatActions } from "../../store/ducks/paulChat/actions"

import FeedbackButton from "./components/FeedbackButton"
import PerguntaUsuario from "./components/PerguntaUsuario"
import RespostaWatson from "./components/RespostaWatson"
import TagA from "./components/TagA"
import TagImg from "./components/TagImg"

export const fetchWatsonAPI = (dispatch, user, message, context) => {
  const data = { user, question: message, context }
  axios
    .post(`${WATSON_URL}conversation`, data, { headers: watsonHeaderConfig })

    .then(async response => {
      console.log("watson res:", response.data)
      let watsonAnswer
      const { text, context: contextResponse } = response.data.output
      if (text[0].length === 1) {
        watsonAnswer = text
      } else {
        watsonAnswer = text[0]
      }

      if (watsonAnswer.toLowerCase().includes("{{nome}}")) {
        const userName = `${localStorage.getItem("firstName")}`
        watsonAnswer = watsonAnswer.replace("{{nome}}", userName)
        watsonAnswer = watsonAnswer.replace(/Watson/i, "Paul")
      }

      const changed = await dispatch(PaulChatActions.didSubjectChanged(response.data.context.discipline))
      dispatch(PaulChatActions.addToChatHistory(response.data))
      dispatch(PaulChatActions.setChatMessageContext(contextResponse))
      dispatch(PaulChatActions.addButtonFeedback())
      dispatch(PaulChatActions.setSendingMessage(false))
      dispatch(
        PaulChatActions.addChatMessage({
          sender: "watson",
          message: watsonAnswer,
          res: response.data,
          showFeedback: changed,
        })
      )
    })
    .catch(error => {
      console.log(error)
    })
}

export const sendMessage = (dispatch, user, message, context, auto = false) => {
  // console.log("user:", user)
  // console.log("message: ", message)
  // console.log("autoMessage: ", auto)
  // console.log("context: ", context)

  dispatch(PaulChatActions.setSendingMessage(true))
  if (auto) {
    message = message.split("'")[1]
  } else {
    dispatch(PaulChatActions.addChatMessage({ sender: "user", message }))
  }

  fetchWatsonAPI(dispatch, user, message, context)
}

export const messagesComponentGenerator = (
  dispatch,
  user,
  { sender, message, res, showFeedback },
  context,
  key
) => {
  if (sender === "button") {
    return <FeedbackButton />
  }
  if (sender === "watson") {
    // fazer a verificação para diversos tipos de resposta.

    if (message.includes("<img")) {
      const imgPos = message.indexOf("<img")
      if (
        message.lastIndexOf("<br", imgPos) === -1 ||
        (message.lastIndexOf("<br", imgPos) < imgPos && message.lastIndexOf("<br", imgPos) < imgPos - 8)
      ) {
        message = message.replace(/<img/g, "<br/><img")
      }
    }
    const component = Parser(message, {
      replace: domNode => {
        // console.log(domNode);
        if (domNode.type === "tag" && domNode.name === "a") {
          return (
            <TagA
              onSend={() => sendMessage(dispatch, user, domNode.attribs["ng-click"], context, true)}
              href={domNode.attribs.href}
            >
              {domToReact(domNode.children)}
            </TagA>
          )
        }
        if (domNode.type === "tag" && domNode.name === "img") {
          return <TagImg src={domNode.attribs.src} />
        }
        return null
      },
    })
    return (
      <RespostaWatson key={key} index={key} res={res} message={message} showFeedback={showFeedback}>
        {" "}
        {component}{" "}
      </RespostaWatson>
    )
  }
  return <PerguntaUsuario key={key} message={message} />
}

// maybe next function here
