import React, { useContext } from 'react';
import { Modal } from 'react-responsive-modal';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LitButton } from '../../shared/LitButton'; 

import { gateway } from '../../../utils/api';
import {
  generateSecurePassword,
} from '../../../utils/functions';

import NavBarContext from '../../../context/navBar/NavBarContext';
import { Container, ContentForm } from "./styles";

export function ModalRegisterPasswordMobile() {
  const {
    showModalRegisterMobilePassword,
    closeModalRegisterMobilePassword,
} = useContext(NavBarContext)

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
    .min(6, "A senha deve conter 6 ou mais caracteres.")
    .required("Senha obrigatório"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "As senhas não coincidem")
      .required("Confirmação de senha obrigatório"),
  });

  const handleSubmit = async(values, { resetForm }) => {
    try {
      const body = {
        password: generateSecurePassword(values.password),
      }
      const url = '/webbff/lit_app_web/login/create-mobile-password';
      const res = await gateway.post(url, body);
      resetForm();
      closeModalRegisterMobilePassword()
      localStorage.removeItem('hasPasswordMobile')
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
        open={showModalRegisterMobilePassword}
        closeOnOverlayClick={false}
        onClose={() => closeModalRegisterMobilePassword()}
        classNames={{
          overlay: 'registerPasswordMobileModalOverlay',
          modal: 'registerPasswordMobileModal'
        }}
        center
      >
      <Container>
        <p> Olá!</p>
        <p>Para ter acesso ao aplicativo do LIT, é muito simples. Basta cadastrar uma senha e confirmar. Depois é so acessar.</p>
    
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
        {({ isValid, isSubmitting }) => (
          <ContentForm>
            <div>
              <label htmlFor="password">Senha:</label>
              <Field
                type="password"
                id="password"
                name="password"
                placeholder="Digite sua senha"
              />
              <ErrorMessage name="password" component="span" />
            </div>

            <div>
              <label htmlFor="confirmPassword">Confirmar Senha:</label>
              <Field
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirme sua senha"
              />
              <ErrorMessage name="confirmPassword" component="span" />
            </div>

            <LitButton type="submit" disabled={!isValid || isSubmitting}>Criar senha</LitButton>
          </ContentForm>
        )}
        </Formik>

      </Container>
      </Modal>
  );
}
