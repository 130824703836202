import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Array} header //list of items that make up the table header (th)
 * @param {Array} content //list of items that make up the table body (td)
 * @param {String} appearance //{STRIPED} changes the appearance of the table
**/

export default ({
  header = DEFAULT_VALUES.LIST,
  content = DEFAULT_VALUES.LIST,
  appearance = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        header={header}
        content={content}
        appearance={appearance}
      />
    </>
  );
};
