import styled from "styled-components"

export const ContainerCourses = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const BoxLogo = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.bg}
`

export const Item = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: center;
`

export const TextP = styled.p`
  font-size: 1.3em;
  letter-spacing: 1px;
  color: #fff;

  &:first-child {
    font-weight: 600;
  }
`

export const TextTitle = styled.h2`
  color: #fff;
  font-weight: 600;
  margin-bottom: 1em;
`

const ButtonWrap = styled.button`
  cursor: pointer;
  position: absolute;
  border: none;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50px;
  font-size: 1.2em;
  outline: transparent;
  transition: background 1s;

  &:focus {
    outline: transparent;
  }
`

const ButtonWrapCarousel = styled.button`
  cursor: pointer;
  position: absolute;
  border: none;
  padding: 0 15px;
  height: 250px;
  background: transparent;
  font-size: 1.2em;
  outline: transparent;
  transition: background 1s;

  i {
    color: transparent;
  }

  &:hover {
    background: rgba(0,0,0,0.7);
    i {
      color: #fff;
    }
  }

  &:focus {
    outline: transparent;
  }

  i {
    color: transparent;
  }
`

export const ButtonAfter = styled(ButtonWrap)`
  left: 2em;
  top: 50%;
  transform: translateY(-50%);
`

export const ButtonNext = styled(ButtonWrap)`
  right: 2em;
  top: 50%;
  transform: translateY(-50%);
`

export const ButtonAfterCarousel = styled(ButtonWrapCarousel)`
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const ButtonNextCarousel = styled(ButtonWrapCarousel)`
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`
