import { Types } from "./types"

/**
 * Actions
 */

export const Creators = {
  increaseWatsonChatSubjectCounter: () => ({
    type: Types.INCREASE_WATSON_CHAT_SUBJECT_COUNTER,
  }),
  updateWatsonChatSubject: (subject, changed) => ({
    type: Types.UPDATE_WATSON_CHAT_SUBJECT,
    payload: { subject, changed },
  }),

  didSubjectChanged: newSubject => ({
    type: Types.ON_SUBJECT_CHANGED,
    payload: { newSubject },
  }),

  setChatMessageContext: context => ({
    type: Types.SET_CHAT_MESSAGE_CONTEXT,
    payload: { context },
  }),

  addToChatHistory: data => ({
    type: Types.ADD_MESSAGE_TO_CHAT_HISTORY,
    payload: { data },
  }),

  addChatMessage: data => ({
    type: Types.ADD_CHAT_MESSAGE,
    payload: { ...data },
  }),

  removeFeedbackButton: messages => ({
    type: Types.REMOVE_OLD_FEEDBACK_BUTTON,
    payload: { messages },
  }),

  addButtonFeedback: () => ({
    type: Types.ADD_BUTTON_FEEDBACK,
  }),

  setSendingMessage: status => ({
    type: Types.SET_SENDING_MESSAGE,
    payload: { status },
  }),

  setUserSession: user => ({
    type: Types.SET_USER_SESSION,
    payload: { user: user.replace(/\s/g, "") },
  }),

  resetOnLeaving: () => ({
    type: Types.SEND_FEEDBACK_ON_LEAVING,
  }),

  sendFeedbackRequest: (avaliation = true) => ({
    type: Types.SEND_FEEDBACK_REQUEST,
    payload: { avaliation },
  }),
  sendFeedbackSuccess: () => ({
    type: Types.SEND_FEEDBACK_SUCCESS,
  }),
  sendFeedbackFailure: () => ({
    type: Types.SEND_FEEDBACK_FAILURE,
  }),
}
