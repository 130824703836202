import React from "react"
import { connect } from "react-redux"
import { sendMessage } from "./methods"
import { verifyObject } from "../../utils/functions"

const BADGE_WHATSON = require("../../images/withWatsonBadge/badge_primary_white.png")

class PerguntarWatson extends React.Component {
  state = {
    question: "",
  }

  componentDidUpdate(prevProps) {
    const { context, fromNavbar, dispatch, user } = this.props
    if (!verifyObject(context) && verifyObject(prevProps.context) && fromNavbar) {
      sendMessage(dispatch, user, "pergunta('como o LIT funciona')", context, true)
    }
  }

  onQuestionSubmit = e => {
    e.preventDefault()
    const { question } = this.state
    const { dispatch, user, context } = this.props

    if (question) {
      sendMessage(dispatch, user, question, context)
      this.setState({ question: "" })
    }
  }

  onChange = e => {
    const question = e.target.value
    this.setState({ question })
  }

  onHandleKeyPress = e => {
    if (e.keyCode === 13 && e.shiftKey) {
      return
    }
    if (e.keyCode === 13 && !e.shiftKey) {
      this.onQuestionSubmit(e)
    }
  }

  render() {
    const { question } = this.state
    return (
      <div className="row enviarPerguntaWatson" style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <div className="col-12 d-flex align-items-center">
          <form
            onSubmit={this.onQuestionSubmit}
            className="d-flex align-items-center"
            style={{ width: "100%" }}
          >
            <textarea
              placeholder="O que você deseja saber?"
              value={question}
              onChange={this.onChange}
              onKeyDown={this.onHandleKeyPress}
            />

            <button type="submit" className="enviarPerguntaWatsonButton">
              Enviar
            </button>
            <img src={BADGE_WHATSON} alt="Imagem" className="img-responsive perguntaWatson__image" />
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.paulChat.user,
  context: state.paulChat.chatMessageContext,
})

export default connect(mapStateToProps)(PerguntarWatson)
