import React from "react";
import {
  APPEARANCE,
  FONT_TYPE,
  ORIENTATION,
  POSITION,
  SIZE
} from "../../../constants";
import Text from "../../basicComponents/Text";
import Button from "../../basicComponents/Button";
import OverflowContent from "../../basicComponents/OverflowContent";
import NotificationBlock from "../../basicComponents/NotificationBlock";
import Row60x40 from "../../defaultAreas/Row_60-40";
import { Title, Content } from "./styles";

export default ({ title, buttonText, notification }) => {
  return (
    <>
      <Content>
        <Title>
          <Row60x40
            position={POSITION.TOP_CENTER}
            left={[
              <Text
                text={title}
                fontType={FONT_TYPE.BOLD}
                position={POSITION.LEFT}
                size={SIZE.LARGE}
              />
            ]}
            right={[
              <Button
                text={buttonText}
                appearance={APPEARANCE.MODAL}
                position={POSITION.RIGHT}
              />
            ]}
          />
        </Title>
        <OverflowContent
          orientation={ORIENTATION.HORIZONTAL}
          size={SIZE.MEDIUM}
        >
          {notification.map(item => (
            <NotificationBlock
              type={item.type}
              totalNotifications={item.value}
            />
          ))}
        </OverflowContent>
      </Content>
    </>
  );
};
