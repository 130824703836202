/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from "react-copy-to-clipboard";
import RCSlider from "rc-slider";
import notify from "../../../../Recommendations/notify"


import {
  Container,
  Title,
  ContainerAttempts,
  ContainerChart,
  ContainerSlider,
  Button,
  ContainerBtn,
  ButtonCopy,
  ButtonEvaluation
} from "./styles";

function Instructions({ assessment, courseCrCode }) {
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    if (assessment) {
      setShowData(true);
    }
  }, [assessment]);

  const performanceGraphic = (assessment && assessment.graphics && assessment.graphics.performanceGraphic) || [];
  const performanceGraphicData = performanceGraphic.map(item => item.value / 10);
  const performanceGraphicLabels = performanceGraphic.map(item => item.title);
  const performanceGraphicLegend = performanceGraphic.map(item => item.label);

  const highestGrade = (assessment && assessment.graphics && assessment.graphics.highestGrade) || [];
  const highestGradeData = highestGrade.map(item => item.value / 10);

  const color = assessment && assessment.type === "FINAL_EXAM" ? "#fcee1e" : "#27aae1";

  const dataUcs = () => {
    return {
      labels: performanceGraphicLabels,
      datasets: [
        {
          label: assessment.name,
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          stack: "1",
          hoverBackgroundColor: color,
          hoverBorderColor: color,
          data: performanceGraphicData
        },
        {
          label: 'Maior Nota',
          backgroundColor: '#27aae1',
          borderColor: '#27aae1',
          borderWidth: 1,
          stack: "2",
          hoverBackgroundColor: '#27aae1',
          hoverBorderColor: '#27aae1',
          data: highestGradeData
        }
      ]
    };
  };

  const opt = {
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            max: 10
          }
        }
      ]
    }
  };

  const trackingLabels = tracking => {
    const verifyTracking = tracking.reduce(
      (accumulator, item) => {
        if (item.status) {
          accumulator[item.status] = (
            <>
              <p style={{ marginBottom: 0 }}>{item.status}</p>
              <p style={{ marginBottom: 0, marginTop: -70 }}>{item.date}</p>
            </>
          );
        }

        return accumulator;
      },
      {
        "Não iniciado": (
          <>
            <p style={{ marginBottom: 0 }}>Não iniciado</p>
          </>
        ),
        Iniciado: (
          <>
            <p style={{ marginBottom: 0 }}>Iniciado</p>
          </>
        ),
        Entregue: (
          <>
            <p style={{ marginBottom: 0 }}>Entregue</p>
          </>
        ),
        Corrigido: (
          <>
            <p style={{ marginBottom: 0 }}>Corrigido</p>
          </>
        )
      }
    );

    return Object.keys(verifyTracking).map(
      trackingKey => verifyTracking[trackingKey]
    );
  };

  const Slider = RCSlider;

  const renderMarks = (max, labels) => {
    const marks = {};
    for (let i = 0; i <= max; i++) {
      marks[i] = labels[i];
    }
    return marks;
  };

  const goToAssessment = () => {
    const code = assessment.cr_code;
    const redirectRoute = `/assessment-waiting-room?code=${code}&courseCrCode=${courseCrCode}`;
    window.open(redirectRoute, "__blank");
  };

  const goToAssessmentView = code => {
    const assessmentCode = assessment.cr_code;
    const responseCode = code;
    const redirectRoute = `/assessment-view?assessmentCode=${assessmentCode}&responseCode=${responseCode}`;
    window.open(redirectRoute, "__blank");
  };

  const handleCopyToClipboard = () => {
    notify(`Protocola copiado com sucesso!`)
  }

  return (
    showData && (
      <Container>
        <Title>Desempenho {assessment.name}</Title>
        {assessment.type === "FINAL_EXAM" && (
          <ContainerChart>
            <React.Fragment>
              <Bar data={dataUcs} options={opt} />
              <legend className="ml-3">
                {performanceGraphicLabels.map((item, index) => (
                  <div style={{ fontSize: 13 }} key={item}>
                    <b>{item}</b>
                    <p style={{ display: "inline" }}>{`: ${performanceGraphicLegend[index]}`}</p>
                  </div>
                ))}
              </legend>
            </React.Fragment>
          </ContainerChart>
        )}

        {assessment.attempts && assessment.attempts.length === 0 && (
          <p className="text-center" style={{ marginTop: "20px" }}>
            Sem informações de desempenho apara essa avaliação.
          </p>
        )}

        {assessment.attempts && assessment.attempts.length > 0 && (
          <div style={{ height: 500, overflow: "auto" }}>
            {assessment.attempts.map((item, index) => {
              return (
                <ContainerAttempts key={index}>
                  {item.tracking && (
                    <ContainerSlider>
                      <Slider
                        dots
                        dotStyle={() => {
                          return <span>123123</span>;
                        }}
                        disabled
                        id={`component-${item}`}
                        trackStyle={[
                          {
                            backgroundColor: "#27aae1",
                            borderColor: "#27aae1"
                          },
                          { backgroundColor: "#27aae1" }
                        ]}
                        // railStyle={{ height: 5 }}
                        defaultValue={item.tracking.length}
                        min={0}
                        max={3}
                        marks={renderMarks(3, trackingLabels(item.tracking))}
                      />
                    </ContainerSlider>
                  )}
                  <div className="table-responsive">
                    <table className="table table-sm table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Tentativa</th>
                          <th scope="col">Protocolo</th>
                          <th scope="col">Data</th>
                          <th scope="col">Nota</th>
                          <th scope="col" width="30%">
                            Resultado
                          </th>
                          <th scope="col"> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                          <Tooltip title="Clique para copiar" aria-label="add" placement="top">
                            <CopyToClipboard text={item.crCode} onCopy={handleCopyToClipboard}>
                              <ButtonCopy data-for="copy" data-tip={item.crCode}>
                                  {item.crCode.substring(0,10)}...
                                </ButtonCopy>
                            </CopyToClipboard>
                          </Tooltip>
                          </td>
                          <td>{item.date}</td>
                          <td>
                            {item.tracking && item.tracking[item.tracking.length - 1].status !== "Corrigido" 
                              ? ('-') : (item.finalGrade)
                            }
                          </td>
                          <td>{item.result}</td>
                          <td>
                            {item.allowassessmentresponse
                            ? <ButtonEvaluation
                                disabled={
                                  item.tracking &&
                                  item.tracking[item.tracking.length - 1]
                                    .status !== "Corrigido"
                                }
                                onClick={() => goToAssessmentView(item.crCode)}
                              >
                                Ver avaliação
                              </ButtonEvaluation>
                            : <></>
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ContainerAttempts>
              );
            })}
          </div>
        )}

        <ContainerBtn>
          <Button height={50} width={200} onClick={goToAssessment}>
            Iniciar nova avaliação
          </Button>
        </ContainerBtn>
      </Container>
    )
  );
}

Instructions.propTypes = {
  assessment: PropTypes.object.isRequired
};

export default Instructions;
