import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;

  p#text-upload {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 5px;
    
    justify-content: center;

    svg {
      margin-left: 10px;
      font-size: 30px;
    }
  }
`;

export const DropZone = styled.div`
  width: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;

  padding: 15px 0;

  &:hover {
    border-color: #1890ff;
  }

  p {
    margin: 0;
  }
`;

export const LoadingWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    animation: ${rotate360} 1s linear infinite;
    margin-right: 5px;
  }
`;

export const SuccessWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    margin-right: 5px;
  }
`;

export const TextImage = styled.p`
  color: white;
  margin: 0;
`;

export const ContainerImage = styled.div`
  padding: 10px;
  .img_wrap {
    position: relative;
    max-height: 300px;
    width: 300px;
  }

  .img_size {
    width: 300px;
    max-height: 300px;
  }

  .img_description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(29, 106, 154, 0.72);
    visibility: hidden;
    opacity: 0;
    height: 100%;

    transition: opacity .2s, visibility .2s;
  }

  .img_wrap:hover .img_description {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }

  img {
    max-width: 100%;
  }
`;

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border: none;
    background-color: #fff;
    cursor: pointer;
  }

  span {
    align-items: flex-end;
    display: flex;
    color: #58595b;
    font-size: 12px;
    margin-right: 5px;
  }
`;

export const ThumbDrop = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  margin-top: 10px;
  box-sizing: border-box;
`;

export const ThumbInnerDrop = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

export const CameraBtn = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
`;

export const BoxCamera = styled.div`
  margin-left: 10px;
  width:10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TakePhotoBtn = styled.button`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #27AAE1;
  border: 0px solid #27AAE1;
  max-width: 180px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 20px;
  color: white;
`;


export const Title = styled.h2`
`

export const BoxCamUnavaible = styled.div`
  min-width: 640px;
  min-height: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
`
