import { toast } from 'react-toastify';
import api, { gateway } from '../utils/api';
import PlanType from '../Components/Pagamento/planType';
import PaymentMethodType from '../Components/Pagamento/paymentMethodType';
import { renewToken, toastErrorAndWarningOptions } from '../utils/functions';

export const handleCpfConsultation = async (cpf, documentType) => {
  try {
    const idProfile = localStorage.getItem('idProfile');
    const url = `/webbff/lit_app_web/profile/cpf/${cpf}/${idProfile}/${documentType}`;
    await gateway.get(url);
  } catch (error) {
    throw error;
  }
};

export const searchBasePrices = async () => {
  try {
    const pricesResponse = await api.get('getPaymentTypes');
    return {
      monthly:
        pricesResponse && pricesResponse[0] ? pricesResponse[0].totalValue : 0,
      yearly:
        pricesResponse && pricesResponse[1] ? pricesResponse[1].totalValue : 0
    };
  } catch (err) {
    throw err;
  }
};

export async function getClaimsPlan(plan) {
  let findPlanPayroll = null;

  if (plan.payments === undefined) return {};

  findPlanPayroll = plan.payments.find(
    payment => payment.type === PaymentMethodType.FOLHA
  );

  let isPayrollMethod = false;
  if (plan.payments.length === 1 && findPlanPayroll) {
    return {};
  }

  if (plan.payments.length >= 1 && findPlanPayroll) {
    isPayrollMethod = true;
  }

  try {
    const idProfile = localStorage.getItem('idProfile');
    const response = await gateway.get(
      `/claim/offer/${plan.id}?ox_accountreference.wk_type=ID_PROFILE&ox_accountreference.rk_reference=${idProfile}`
    );
    const claims = response.map(claim => {
      return {
        claimId: claim.ox_identification.cr_code,
        registerUser: claim.ox_identification.ck_registeruser,
        active: claim.ox_identification.bf_active,
        discountData: {
          type: claim.rk_coupon.ox_value.wk_valuetype,
          value: claim.rk_coupon.ox_value.nx_value
        },
        cupom: claim.rk_coupon.ox_identification.cr_code
      };
    });
    return {
      claims,
      isPayrollMethod
    };
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function getBiggerInstallment(obj, installment) {
  let biggerInstallment = -Infinity;
  let installmentDescription = 'cartão';

  if (obj && Object.keys(obj).length > 0) {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const valueInstallment = parseInt(obj[key].nx_maxinstallments, 10);
        if (valueInstallment >= biggerInstallment) {
          if (valueInstallment <= installment) {
            biggerInstallment = valueInstallment;
          } else {
            biggerInstallment = parseInt(installment, 10);
          }
          switch (key) {
            case 'boleto':
              installmentDescription = 'no boleto';
              break;
            default:
              installmentDescription = 'no cartão';
              break;
          }
        }
      }
    }
  } else {
    biggerInstallment = installment;
  }

  return {
    installmentDescription,
    biggerInstallment
  };
}

export async function getInstallmentsForAnnualPlan(valueWithDiscount) {
  try {
    const response = await api.get(
      `payment/installments/discount/${valueWithDiscount}/`
    );
    return response.installment;
  } catch (error) {
    console.log(error);
  }
  return 1;
}

export async function getVoucherValues(code, selectedPlanType) {
  try {
    const response = await api.get(
      `/consultVoucherNumber/${code}/${selectedPlanType === 'monthly' ? 0 : 1}`
    );
    const {
      voucherResponse: { status, valor },
      installments,
      voucherNumber,
      name
    } = response;

    return {
      isValid: status === 'OK',
      discount: valor,
      installments,
      code: voucherNumber,
      name
    };
  } catch (error) {
    throw error;
  }
}

export async function checkVoucherValidationAndType(code) {
  try {
    const response = await api.get(`/consultVoucherNumber/${code}/2`);

    const {
      type,
      voucherResponse: { status }
    } = response;

    return {
      isValid: status === 'OK',
      type
    };
  } catch (error) {
    throw error;
  }
}

export async function loadPlansAndCampaigns(
  prices,
  idProfile,
  idPlano,
  tipoId,
  planType
) {
  const plansAndCampaigns = {
    monthly: [],
    yearly: [],
    selectedPlanId: null,
    recommendedPlanId: null
  };

  let currentPlan = null;
  let shouldSelectPlan = false;

  // 1 - Load Plans
  try {
    const urlOriginParam = localStorage.getItem('urlOriginParam');
    const urlPlansParam = localStorage.getItem('urlPlansParam');
    const urlRedirect = localStorage.getItem('urlRedirect');
    const origin = urlOriginParam || urlRedirect;
    let plans;
    if (urlPlansParam) {
      const listPlans = urlPlansParam.split(',');

      plans = await gateway.post(`/plans/composition`, {
        plans: listPlans.map(item => ({ id: item }))
      });
      plans = [plans];
    } else {
      plans = await gateway.get(`/plans?${origin ? `o=${origin}` : ''}`);
    }
    const filteredPlans = plans.filter(
      plan => plan.yearly.useTimes > 0 || plan.montly.useTimes > 0
    );

    shouldSelectPlan = filteredPlans.length === 1;

    /* ### Inicio da verificação se o plano existe ############################### */
    if (urlPlansParam) {
      currentPlan = plans;
    } else {
      currentPlan = plans.filter(plan => plan._id === idPlano);
    }

    if (Object.keys(currentPlan).length === 0 && idPlano) {
      if (tipoId !== 'CAMPANHA') {
        currentPlan = await gateway.get(`/plans/list/${idPlano}`);
        if (currentPlan) {
          /* Add o plano atual na lista de planos (no final) */
          plans.push(currentPlan);
        }
      }
    }
    /* ############################################################################ */

    plans.forEach(async plan => {
      // is monthly plan
      if (
        (plan.montly && plan.montly.useTimes > 0) ||
        (plan._id === idPlano && planType.toUpperCase() === 'MENSAL')
      ) {
        const discountPercent = plan.montly ? plan.montly.discount : 0;
        const discountValue = (prices.monthly * discountPercent) / 100;
        const price = prices.monthly - discountValue;
        const fullPrice = prices.monthly;

        plansAndCampaigns.monthly.push({
          discountPercent,
          discountValue,
          price,
          fullPrice,
          payments: plan.payments,
          company: plan.company,
          config: plan.config,
          priority: plan.config.priority,
          spotlight: plan.config.spotlight,
          litpass: plan.config.litpass,
          id: plan._id,
          idByFront: `monthly${plan._id}`,
          description: plan.description,
          formOfPayment: 0,
          planType: PlanType.Monthly,
          features: plan.features
            ? plan.features
              .filter(feature => feature.bf_monthly)
              .map(feature => feature)
            : [],
          isCampaign: false,
          trial: plan.config.trial,
          voucherPrefix: plan.config.voucherPrefix
        });
      }

      // is yearly plan
      if (
        (plan.yearly && plan.yearly.useTimes > 0) ||
        (plan._id === idPlano && planType.toUpperCase() === 'ANUAL')
      ) {
        const discountPercent = plan.yearly ? plan.yearly.discount : 0;
        const discountValue = (prices.yearly * discountPercent) / 100;
        const price = prices.yearly - discountValue;
        const fullPrice = prices.yearly;
        plansAndCampaigns.yearly.push({
          discountPercent,
          discountValue,
          price,
          fullPrice,
          id: plan._id,
          payments: plan.payments,
          company: plan.company,
          config: plan.config,
          priority: plan.config.priority,
          spotlight: plan.config.spotlight,
          description: plan.description,
          installmentsConfig: plan.installmentsConfig,
          formOfPayment: 1,
          planType: PlanType.Yearly,
          idByFront: `yearly${plan._id}`,
          features: plan.features
            ? plan.features
              .filter(feature => feature.bf_yearly)
              .map(feature => feature)
            : [],
          isCampaign: false,
          trial: plan.config.trial,
          voucherPrefix: plan.config.voucherPrefix
        });
      }
    });
  } catch (err) {
    console.log(err);
  }

  // 2 - Load campaigns
  try {
    const redirect = localStorage.getItem('urlRedirect') || null;
    const campaigns = await api.get(
      `getActiveCampaignList/${idProfile}/origin/${redirect}`
    );

    /* ### Inicio da verificação se o plano existe ############################### */
    currentPlan = campaigns.filter(campaign => campaign.id === idPlano);
    if (Object.keys(currentPlan).length === 0 && idPlano) {
      if (tipoId === 'CAMPANHA') {
        currentPlan = await api.get(`/getCampaign/${idPlano}`);
        if (currentPlan) {
          /* Add o plano atual na lista de planos (no final) */
          campaigns.push(currentPlan);
        }
      }
    }
    /* ############################################################################ */

    campaigns.forEach(campaing => {
      // is monthly campaign
      if (campaing.montlyAble) {
        const discountPercent = campaing.singleDiscount * 100;
        const discountValue = (prices.monthly * discountPercent) / 100;
        const price = prices.monthly - discountValue;
        const fullPrice = prices.monthly;
        plansAndCampaigns.monthly.push({
          discountPercent,
          discountValue,
          price,
          fullPrice,
          id: campaing.id,
          codVoucher: campaing.codVoucher,
          idByFront: `monthly${campaing.id}`,
          description: campaing.campaignName,
          priority: campaing.priority,
          spotlight: campaing.spotlight,
          formOfPayment: 0,
          planType: PlanType.Monthly,
          features: campaing.litFeatures
            ? campaing.litFeatures
              .filter(feature => feature.enable)
              .map(feature => feature)
            : [],
          isCampaign: true
        });
      }

      // is yealy campaign
      if (campaing.yearlyAble) {
        const discountPercent = campaing.yearDiscount * 100;
        const discountValue = (prices.yearly * discountPercent) / 100;
        const price = prices.yearly - discountValue;
        const fullPrice = prices.yearly;
        plansAndCampaigns.yearly.push({
          discountPercent,
          discountValue,
          price,
          fullPrice,
          id: campaing.id,
          codVoucher: campaing.codVoucher,
          idByFront: `yearly${campaing.id}`,
          description: campaing.campaignName,
          priority: campaing.priority,
          spotlight: campaing.spotlight,
          formOfPayment: 1,
          planType: PlanType.Yearly,
          features: campaing.litFeatures
            ? campaing.litFeatures
              .filter(feature => feature.enable)
              .map(feature => feature)
            : [],
          isCampaign: true
        });
      }
    });
  } catch (err) {
    console.log(err.response);
  }

  const getClaninsMonthlyPromises = plansAndCampaigns.monthly.map(
    async plan => {
      if (plan.isCampaign) {
        return plan;
      }
      const { claims, isPayrollMethod } = await getClaimsPlan(plan);
      return {
        ...plan,
        claims,
        isPayrollMethod
      };
    }
  );

  const getClaninsAndInstallmentsYearlyPromises = plansAndCampaigns.yearly.map(
    async plan => {
      const installment = await getInstallmentsForAnnualPlan(plan.price);
      const valueInstallments = plan.price / installment;

      if (plan.isCampaign) {
        return {
          ...plan,
          installment,
          valueInstallments
        };
      }
      const { claims, isPayrollMethod } = await getClaimsPlan(plan);
      return {
        ...plan,
        claims,
        isPayrollMethod,
        installment,
        valueInstallments
      };
    }
  );

  plansAndCampaigns.yearly = await Promise.all(
    getClaninsAndInstallmentsYearlyPromises
  );
  plansAndCampaigns.monthly = await Promise.all(getClaninsMonthlyPromises);

  plansAndCampaigns.yearly = plansAndCampaigns.yearly.map(plan => {
    if (plan.installmentsConfig) {
      const {
        installmentDescription,
        biggerInstallment
      } = getBiggerInstallment(plan.installmentsConfig, plan.installment);
      const valueInstallments = plan.price / biggerInstallment;
      return {
        ...plan,
        installment: biggerInstallment,
        installmentDescription,
        valueInstallments
      };
    }
    return plan;
  });

  plansAndCampaigns.monthly.sort((a, b) => b.priority - a.priority);
  plansAndCampaigns.yearly.sort((a, b) => b.priority - a.priority);

  let recommendation = null;

  try {
    const email = localStorage.getItem('ra');
    const userRecommendation = await gateway.get(
      `/webbff/lit_app_web/recommendation/plan/received?email=${email}`
    );

    if (userRecommendation && Object.keys(userRecommendation).length > 0) {
      const availablePlans = plansAndCampaigns.yearly.concat(
        plansAndCampaigns.monthly
      );

      if (availablePlans.some(ap => ap.id === userRecommendation.plan.id)) {
        recommendation = userRecommendation;
      }
    }
  } catch (err) {
    if (!err.response || err.response.status !== 404) {
      throw err;
    }
  }

  if (shouldSelectPlan) {
    const monthlyPlan = plansAndCampaigns.monthly[0];
    const yearlyPlan = plansAndCampaigns.yearly[0];

    if (yearlyPlan) {
      plansAndCampaigns.selectedPlanId = yearlyPlan.id;
    }

    if (monthlyPlan) {
      plansAndCampaigns.selectedPlanId = monthlyPlan.id;
    }
  }

  if (recommendation) {
    plansAndCampaigns.selectedPlanId = recommendation.plan.id;
    plansAndCampaigns.recommendedPlanId = recommendation.plan.id;
  }

  return {
    plans: plansAndCampaigns.monthly.concat(plansAndCampaigns.yearly),
    selectedPlanId: plansAndCampaigns.selectedPlanId,
    recommendedPlanId: plansAndCampaigns.recommendedPlanId
  };
}

export async function doAtribuirVoucher(plan, router) {
  const idProfile = localStorage.getItem('idProfile');
  try {
    const response = await api.get(
      `doAtribuirVoucher/${idProfile}/${plan.voucher}`
    );

    await renewToken();

    localStorage.setItem('voucherAtivo', true);
    localStorage.setItem('freeUser', response.freeUser);
    localStorage.setItem('libraryUser', response.libraryUser);
    localStorage.setItem('socialUser', response.socialUser);
    localStorage.setItem('showPaul', response.showPaul);
    router.history.push('/onboarding');
  } catch (error) {
    console.log(error);
    toast.error('O código do cupom não é válido.', {
      ...toastErrorAndWarningOptions,
      closeButton: false
    });
  }
}

export async function doAtribuirVoucherPerfil(plan) {
  const idProfile = localStorage.getItem('idProfile');

  try {
    const response = await api.get(
      `doAtribuirVoucherPerfil/${idProfile}/${plan.voucher}`
    );

    await renewToken();

    localStorage.setItem('freeUser', response.freeUser);
    localStorage.setItem('libraryUser', response.libraryUser);
    localStorage.setItem('socialUser', response.socialUser);
    localStorage.setItem('showPaul', response.showPaul);
  } catch (error) {
    console.log('ASSS', error);
  }
}

export async function proccessInvoicePayment(
  selectedPlan,
  invoiceFormValues,
  editingPayment,
  term,
  installments,
  claimId
) {
  try {
    const idProfile = localStorage.getItem('idProfile');
    const body = {
      idPlan: selectedPlan.id,
      type: PaymentMethodType.BOLETO,
      recurrence:
        selectedPlan.planType === PlanType.Monthly ? 'MENSAL' : 'ANUAL',
      term,
      installments,
      claimId: claimId || null,
      forms: {
        ox_orderreference: {
          rk_userprofile: idProfile,
          rk_paymentreference: selectedPlan.id,
          ws_paymentreferencetype: 'PLAN'
        },
        ox_operationvalues: {
          ni_installments: installments,
          ox_basevalue: {
            wx_currency: 'BRL',
            nf_value: selectedPlan.fullPrice
          },
          ox_discountvalue: {
            wx_currency: 'BRL',
            nf_value: selectedPlan.discountValue
          }
        },
        ox_invoicepaymentdata: {
          ...invoiceFormValues,
          cf_shoopercpf: invoiceFormValues.cf_shoopercpf.replace(/\D/g, ''),
          wx_shopperpostalcode: invoiceFormValues.wx_shopperpostalcode.replace(
            /\D/g,
            ''
          )
        }
      }
    };
    const response = await api.post('/dopayment', body);

    await renewToken();

    const {
      freeUser,
      socialUser,
      libraryUser,
      showPaul,
      onboard,
      activeUser,
      paymentReturn: invoiceData
    } = response;

    return {
      nextPage: 'invoicePaymentView',
      nextPageParams: {
        fromPayment: true,
        userHasAccess: activeUser,
        editingPayment,
        invoiceData
      },
      data: {
        freeUser,
        socialUser,
        libraryUser,
        showPaul,
        voucherAtivo: activeUser,
        onboard
      }
    };
  } catch (error) {
    throw error;
  }
}

export async function getInvoicePayments() {
  try {
    const idProfile = localStorage.getItem('idProfile');
    const url = `payment/invoice?userProfileId=${idProfile}&status=OPEN`;
    return gateway.get(url);
  } catch (error) {
    throw error;
  }
}

export function findPaymentTerm(plan, paymentMethod) {
  const havePaymentTerm = plan.payments.find(
    item => item.type === paymentMethod
  );

  if (havePaymentTerm) {
    return havePaymentTerm.term;
  }

  return null;
}
