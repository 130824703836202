import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../../constants";

/**
 * @param {String} textHeader //text displayed at the top of the modal
 * @param {Function} closeModal //action triggered when clicking on modal close button
**/


export default ({
  textHeader = DEFAULT_VALUES.STRING,
  closeModal = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <Screen
        textHeader={textHeader}
        closeModal={closeModal}
      />
    </>
  );
};
