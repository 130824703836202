import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { format, parseISO } from "date-fns";

import { gateway } from '../../../../../../utils/api'

export function RecurrencyList({offerData}) {

  const [dataList, setDataList] = useState([])
  const [installments, setInstallment] = useState(0)
  const [price, setPrice] = useState(0)

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });

  async function getRecurrencyData(offerData) {
    try {
      const {
        recurrencyPeriodQuantity, 
        recurrencyPeriodType,
        recurrencyQuantity,
        installments,
        price
      } = offerData
      setInstallment(installments)
      const url = `offer/offer/getRecurrencyCalendar?price=${price}&installments=${installments}&recurrencyQuantity=${recurrencyQuantity}&recurrencyPeriodType=${recurrencyPeriodType}&recurrencyPeriodQuantity=${recurrencyPeriodQuantity}`
      const response = await gateway.get(url)
      const formattedData = response.map(item => {
        const portionPrice = _.get(item, 'installments.0.price')
        return {
          dateRecorrency: item.date && format(parseISO(item.date), "dd/MM/yyyy"),
          price: item.recurrencyPrice && formatter.format(item.recurrencyPrice),
          installments: item.installments.length,
          portionPrice: portionPrice && formatter.format(portionPrice)
        }
      })
      setDataList(formattedData) 
    } catch (error) {
      setDataList([]) 
    }
  }

  useEffect(() => {
    if(offerData.installments && offerData.price && offerData.installments !== installments || offerData.price !== price) {
      getRecurrencyData(offerData)
      setPrice(offerData.price)
    }
  }, [offerData, installments, price])

  return (
    <>
      {dataList.length > 0 && (
        <div className="table-responsive">
          <hr />
          <h4 style={{fontSize: '17px'}}>Detalhamento de parcelas</h4>
          <table className="table">
            <thead>
              <tr>
                <th>Data</th>
                <th>Valor</th>
                <th>Parcelas</th>
                <th>Valor parcela</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((item, index) => (
                <tr key={index}>
                  <td>{item.dateRecorrency}</td>
                  <td>{item.price}</td>
                  <td>{item.installments}</td>
                  <td>{item.portionPrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
        </div>
      )}
    </>
  )
}
