import React, { Component } from "react"
import { connect } from "react-redux"
import Img from "react-image"
import Modal from "react-responsive-modal"
import EditAvatarModal from "./EditAvatarModal"

import placeholder from "../../images/placeholder-avatar.png"

import api, { gateway } from "../../utils/api"

class AvatarUsuarioLogado extends Component {
  state = {
    modalOpen: false,
    experiences: [],
  }

  componentDidMount() {
    const idProfile = localStorage.getItem('idProfile');
    this.fetchOffices(Number(idProfile));
  }

  fetchOffices = async id => {
    try {
      const response = await gateway.get(`/webbff/lit_app_web/profile/findOfficeNetwork/${id}`)
      if (!response.message) {
        this.setState({
          experiences: response,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  closeModal = () => {
    console.log("close")
    this.setState({ modalOpen: false })
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  _getRole = login => {
    if(login.role) {
      switch (login.role.idRole) {
        case 1:
          return "Administrador"
        case 2:
          return "Estudante"
        case 3:
          return "Professor"
        case 4:
          return "Empresa"
        case 5:
          return "Chefe"
        case 6:
          return "Lider"
        case 7:
          return "Supervisor"
        case 8:
          return "Administrador LIT"
        default:
          return ""
      }
    }

    return ""
  }

  render() {
    const {
      profile: { firstName, lastName, linkPhoto, login, enableSocialName, socialName },
      profile,
    } = this.props
    const { modalOpen, experiences } = this.state
    return (
      <div>
        <div className="avatar-photo-perfil ">
          <div className="row justify-content-center">
            <div className="lit-editAvatar">
              <Img src={[`${linkPhoto}`, placeholder]} decode={false} />
              <div className="caption">
                <div className="blur" />
                <div className="button-center">
                  <button type="button" onClick={this.openModal} className="btn btn-outline-primary">
                    <i className="fa fa-camera" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center marginb-10 d-flex align-items-center justify-content-center">
          <div className="col-12">
            <h6>
              {enableSocialName ? socialName : `${firstName} ${lastName}`}
            </h6>
            <div style={{ marginBottom: 10 }}>
              <span>{login ? this._getRole(login) : null}</span>
            </div>
          </div>
          <br />
          {experiences && experiences.length !== 0 && (
            <div>
              <div className="col-12">
                <h5 className="perfil-empresa">{experiences[0].company}</h5>
              </div>
              <div className="col-12">
                <span className="spanUserRole-perfil">{experiences[0].profession}</span>
              </div>
            </div>
          )}
        </div>
        <Modal open={modalOpen} onClose={this.closeModal} classNames={{ modal: "lit-perfil-form" }}>
          <EditAvatarModal closeModal={this.closeModal} finishUpdate={this.fetchAll} user={profile} />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.user.profile.data,
})

export default connect(mapStateToProps)(AvatarUsuarioLogado)
