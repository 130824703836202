import React, { useMemo, useState } from 'react';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import _ from 'lodash';
import * as Styles from './styles';

function CampaignOffer({
    showCampaign,
    setPlanCampaign,
    selectedPlan
}) {

    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);

    const campaigns = useMemo(() => {
       return _.get(selectedPlan, 'campaigns', [])
    }, [selectedPlan]);

    const renderPlansCards = campaigns && campaigns.length > 0 && campaigns.map(campaign => {
            const originalPrice = _.get(selectedPlan, 'basePrice', 0);
            const discountPercent = (campaign.basePrice*100)/originalPrice;
            return <Styles.Container key={campaign.code}>
                <Styles.HeaderCampaign>
                    <Styles.ContentDiscount>
                        Economize até {discountPercent && `${discountPercent.toFixed(1)}%`}!
                    </Styles.ContentDiscount>
                    <Styles.ContentDiscountText>
                        {campaign.name}<br />R${campaign.formattedPriceByMonth}
                    </Styles.ContentDiscountText>
                </Styles.HeaderCampaign>
                <Styles.ContentOffer>
                    <p>Contratando {campaign.name} o valor total passará a ser {discountPercent && `${discountPercent.toFixed(1)}% a`} menos</p>
                    <Styles.CampaignButton 
                        type="submit"
                        className="btn btn-blue"
                        onClick={() => setPlanCampaign(campaign.code)}>
                        Adquirir oferta
                    </Styles.CampaignButton>
                </Styles.ContentOffer>
            </Styles.Container>
        });

    return showCampaign && <Styles.Cards itemsQuantity={campaigns.length}>
            <Styles.ContainerSlider>
            <Styles.CustomItemsCarousel
                numberOfPlaceholderItems={1}
                numberOfCards={window.screen.width <= 600 ? 1 : 3}
                slidesToScroll={1}
                showSlither
                freeScrolling={false}
                activePosition="center"
                chevronWidth={40}
                outsideChevron
                rightChevron={<MdChevronRight size={70} />}
                leftChevron={<MdChevronLeft size={70} />}
                requestToChangeActive={newIndex => {
                setActiveCarouselIndex(newIndex);
                }}
                activeItemIndex={activeCarouselIndex}
            >
                {renderPlansCards}
            </Styles.CustomItemsCarousel>
            </Styles.ContainerSlider>
        </Styles.Cards>
}

export default CampaignOffer;
