import styled from "styled-components";
import { Link } from "react-router-dom";

export const DropDownMenuContainer = styled.li`
    .dropdown-menu{
        padding: 10px 15px;
        min-width: 400px !important;
        max-height: ${props => props.menuMaxWindow ? `${props.menuMaxWindow}px`: '300px'}
        overflow-y: auto;
        background: white !important;
        .dropdown-item{
            padding: 0px !important;
            color: #676767
        }

        @media screen and (max-width: 768px){
          min-width: 0 !important;
          width: 100% !important;
          max-height: ${props => props.menuMaxWindow ? `${Math.round(((70*props.menuMaxWindow)/100))}px !important`: '300px !important'}

          a.dropdown-item{
            text-align: left !important;
          }
        }

        ::-webkit-scrollbar {
          width: 3px;
          cursor: pointer;
        }
    
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }
    
        ::-webkit-scrollbar-thumb {
          background: #c1c1c1;
          border-radius: 10px;
        }
    }
`;

export const HoverUserName = styled.div`
  font-weight: 600;
  :hover {
    color: #27aae1;
    transition-duration: 0.4s;
  }
`;

export const MenuSectionTitle = styled.h4`
  color: #444;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  border-top: 1px solid #dbdbdb;
  padding-top: 15px;
`;

export const Divider = styled.div`
  margin-top: 20px;
  height: 1px;
  background-color: #dbdbdb;
  width: 100%;
  margin-bottom: 10px;
`;

export const LinkLogin = styled(Link)`
  color: #02c4fd;
  text-decoration: underline;
`;

export const TenantImageLink = styled.a`
  img#tenant-logo {
    width: auto !important;
    max-width: none !important;
  }
`;

export const ButtonModal = styled.button`
  cursor: pointer;
  color: #000 !important;
  font-weight: bold;
`
