import styled from 'styled-components'

export const ContainerPlay = styled.div`
  .react-player-modal {
    width: 100% !important;
    @media(max-width: 710px) {
      height: 100% !important;
    }
  }
`
