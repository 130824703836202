import styled, { keyframes, css } from "styled-components";
import { darken } from "polished";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  border-left: ${({ isChild }) => (isChild ? "0px" : "1px")} solid #c0c0c0;
  width: 100%;
  ${props =>
    props.isLastInNoChildren &&
    css`
      border-left: 0;
    `}

  & + div {
    margin-top: 20px;
  }

  background: ${props => props.isTeacher ? "#e8eef1" : "white"};
  padding: 10px 20px;

  position: relative;
  & > button {
    cursor: pointer;
    color: #b2b2b2;
    border: none;
    
    &:hover {
      opacity: 0.8;
    }
  }

  ${props =>
    props.isTeacher &&
    css`
      div {
        background: #e8eef1 !imporant;
      }
    `}

  ${props =>
    props.isChild &&
    css`
      &:before {
        content: "";
        width: 40px;
        height: 40px;
        background: transparent;
        position: absolute;
        top: -37px;
        left: -34px;
        border-right: 0px;
        border-left: 0px;
        border-top: 0px;
        border-bottom: 1px;
        border-style: solid;
        border-color: #c0c0c0;
        /* border: 1px solid #c0c0c0 */
        border-radius: 50%;
      }
    `}

  #box-medias {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 26px;
    margin-bottom: 10px;

    & button {
      background-color: transparent;
      border: none;
      color: #42b3c9;
      padding: 12px 16px;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .ck.ck-editor__editable_inline{
    min-height: 120px;
  }
`;

export const ContentTest = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 10;
    position: absolute;
    top: -20px;
    left: -18px;
  }
`;

export const Content = styled.div`
  
  position: relative;
  background: ${props => props.isTeacher ? "transparent !important" : "white"};
  padding: 15px 20px;

  div#box-info {
    background: ${props => props.isTeacher ? "transparent !important" : "white"};
    z-index: 10;
    margin-left: 15px;

    p {
      margin: 0;
      padding: 0;
      font-weight: 400;
      margin-right: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  & > span {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 10;
  }

  #box-message {
    /* width: 100%; */
    min-height: 40px;
    /* margin: 0px 0; */
    margin-top: 20px;
    margin-bottom: 10px;
    & > p {
      /* flex: 1; */
      margin: 0;
      padding: 5px;
      font-size: 14px;
      border-radius: 5px;
      margin-right: 5px;
      background-color: ${({ isUserComment }) =>
        isUserComment ? "#f3f3f3" : "#fff"};
    }

    button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border: 0;
      background: transparent;
      font-size: 20px;
      cursor: pointer;
      color: #ccc;
    }
  }
`;

export const BoxButton = styled.div`
  padding-left: 25px;
  button {
    background-color: transparent;
    margin-left: 5px;
    border: none;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      opacity: 0.8;
    }
  }
`

export const Children = styled.div`
  padding: 10px;

  margin-left: 10px;
  position: relative;
  /* & + div {
    margin-top: 30px;
  } */
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const InputWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  margin: 15px;
  margin-left: 55px;

  img {
    height: 40px;
    width: 40px;
    border-radius: 25px;
  }

  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${darken(0.2, "#f3f3f3")};
    border-radius: 5px;
    height: 100px;
    padding: 5px;
    margin-left: 15px;

    ::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    textarea {
      border: none;
      height: 100%;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    animation: ${rotate360} 1s linear infinite;
    margin-right: 5px;
  }
`;

export const DropZone = styled.div`
  width: 100%;
  text-align: center;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;

  padding: 15px 0;

  &:hover {
    border-color: #1890ff;
  }

  p {
    margin: 0;
  }
`;

export const DropZoneClip = styled.div`
  text-align: center;
  cursor: pointer;
  border: 1px solid #26ace7;
  border-radius: 7px;

  padding: 15px 25px;

  &:hover {
    border-color: #1890ff;
  }

  p {
    margin: 0;
  }
`;

export const ThumbsContainer = styled.div`
  padding-left: 65px;
  margin-bottom: 15px;
`;

export const Thumb = styled.div`
  display: inline-flex;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
`;

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

export const Img = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
  }

  span {
    align-items: flex-end;
    display: flex;
    color: #000;
    font-size: 12px;
    margin-right: 5px;
  }
`;

export const ThumbDrop = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

export const ThumbInnerDrop = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  span{
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 10;
  }
`;

export const ForumCommentsActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const CommentButtonsActions = styled.div`
  margin-top: 20px;
`;

export const WrapperButtons = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-top: 20px;
`;

export const ButtonBlue = styled.button`
  background: #26ace7 !important;
  box-shadow: 0 5px 10px rgb(38 172 231 / 10%);
  width: 100%;
  border-radius: 7px;
  padding: 10px 20px;
  margin-top: 20px;
  color: white !important;
  border: none;
  cursor: pointer;
`;

export const ButtonTransparent = styled.button`
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  color: #444 !important;
  border: none;
  margin-right: 10px;
  cursor: pointer;
`;

export const TeacherAlert = styled.div`
  display: block
  background: #88c7e7;
  color: white;
  padding: 5px;
  border-radius: 5px;
`;
