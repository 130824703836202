/* eslint-disable no-bitwise */

import React, { useMemo, useState, useEffect, useCallback } from "react";
import { FaSpinner, FaRegComments, FaPaperclip } from "react-icons/fa";
import { parseISO, formatDistance } from "date-fns";
import { useDropzone } from "react-dropzone";
import pt from "date-fns/locale/pt";
import mimeTypes from "mime-types";
import _ from "lodash";
import Axios from "axios";
import fileDownload from "js-file-download";
import DragAndDrop from "../../../../../../utils/DragAndDrop";
import ViewEditor from '../../../../../shared/ViewEditor';
import LitEditor from '../../../../../shared/LitEditor';
import { renderUploadThumb } from "../../../../../../utils/functions";

import avatar from "../../../../../../images/placeholder-avatar.png";
import { gateway } from "../../../../../../utils/api";

import {
  Container,
  Content,
  Children,
  InputWrapper,
  LoadingWrapper,
  DropZoneClip,
  Thumb,
  ThumbInner,
  ThumbsContainer,
  MediaContainer,
  ThumbDrop,
  ThumbInnerDrop,
  BoxButton,
  UserInfo,
  ForumCommentsActions,
  WrapperButtons,
  CommentButtonsActions,
  ButtonBlue,
  ButtonTransparent,
  TeacherAlert
} from "./styles";

const STATEMENT_TEXT_LIMIT = 350;

function ForumComment({
  data,
  isChild,
  onAnswer,
  path,
  viewMode,
  lastItem,
  ...rest
}) {
  const [showAnswerInput, setShowAnswerInput] = useState(false);
  const [answerText, setAnswerText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [thumbs, setThumbs] = useState(null);
  const [showMoreText, setShowMoreText] = useState(false);
  const [answering, setAnswering] = useState(false)

  const rawStatement = useMemo(() => {
    return _.get(data, "message", "");
  }, [data]);

  const statement = useMemo(() => {
    if (rawStatement.length > STATEMENT_TEXT_LIMIT && !showMoreText) {
      return rawStatement.slice(0, STATEMENT_TEXT_LIMIT);
    }

    return rawStatement;
  }, [data, showMoreText]);

  const isUserComment = useMemo(() => {
    const idProfile = localStorage.getItem("idProfile");
    return data && data.sourceReference === idProfile;
  }, [data]);

  const date = useMemo(() => {
    let time = "sem data";
    const createAt = _.get(data, "createAt", null);
    if (createAt) {
      const parsedDate = parseISO(createAt);
      time = formatDistance(parsedDate, Date.now(), {
        locale: pt,
        addSuffix: true
      });
    }
    return time;
  }, [data]);

  const focusInput = useCallback(() => {
    const element = document.getElementById(`input-${data.code}`);
    console.log(element)
    if (element) {
      element.focus();
    }
  }, [data]);

  async function handleOnAnswer() {
    try {
      setLoading(true);
      await onAnswer(data, answerText, path, attachments);
      setAttachments([]);
      setFiles([]);
      setThumbs(null);
      setAnswerText("");
      setShowAnswerInput(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const downloadMedia = (e, media) => {
    const url = media.path;
    const filename = media.name;
    const extension = media.path
      .slice(((media.path.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();

    Axios.get(url, {
      responseType: "blob"
    }).then(res => {
      fileDownload(res.data, `${filename}.${extension}`);
    });
  };

  const handleAttachmentOpen = (e, attachment) => {
    const extVerify = attachment.type.toLowerCase();

    if (!extVerify.includes("pdf")) {
      e.preventDefault();
      downloadMedia(e, attachment);

      return;
    }

    const mediaUrl = attachment.path;
    window.open(mediaUrl, "_blank");
  };

  const handleAttachment = attachment => {
    attachments.push(attachment);
    setAttachments(attachments);
  };

  const renderAttachment = attachment => {
    const extension = attachment.type;

    return (
      <Thumb key={attachment.name}>
        <ThumbInner>{renderUploadThumb(extension, attachment)}</ThumbInner>
      </Thumb>
    );
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      const [file] = acceptedFiles;
      files.push(file);
      files.map(file => {
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
      });
      setFiles(files);

      const thumbImg = files.map(file => {
        let extension = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );

        if (!extension) {
          extension = mimeTypes.extension(file.type) || "ND";
        }

        return (
          <ThumbDrop key={file.name}>
            <ThumbInnerDrop>
              {renderUploadThumb(extension, file)}
            </ThumbInnerDrop>
          </ThumbDrop>
        );
      });

      setThumbs(thumbImg);

      if (file) {
        try {
          setLoadingAttachment(true);

          const formData = new FormData();
          let mediaType = "";

          if (file.type !== "") {
            mediaType = _.get(file, "type", null);
          } else {
            mediaType = file.name.slice(
              ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
            );
          }

          formData.append("file", file);
          formData.append("mediaType", mediaType);

          const response = await gateway.post("/media/media/upload", formData);
          handleAttachment(
            _.get(response, "ox_standard.ox_identification.cr_code", null)
          );
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingAttachment(false);
        }
      }
    },
    [thumbs]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: loading,
    multiple: false
  });

  useEffect(() => {
    if (showAnswerInput) {
      focusInput();
    }
  }, [showAnswerInput, data, focusInput]);

  return (
    <Container
      isLastInNoChildren={data.answers.length === 0 && lastItem}
      id={data.code}
      isChild={isChild}
      isTeacher={data.isTeacher}
      {...rest}
    >
      <Content isTeacher={data.isTeacher} isUserComment={isUserComment}>
        <UserInfo>
          <span style={{ backgroundImage: `url(${avatar})` }}></span>
          <div id="box-info">
            {data.isTeacher && <TeacherAlert>Resposta do professor</TeacherAlert>}
            <p id="name">{_.get(data, "user.name", "Usuário LIT")}</p>
            <small>{date}</small>
          </div>  
        </UserInfo>

        <div id="box-message">
          {/* <p>{data.message}</p> */}
          <ViewEditor data={statement} style={{
              margin: 0,
              padding: '5px',
              fontSize: '14px',
              borderRadius: '5px',
              marginRight: '5px',
              backgroundColor: data.isTeacher ? '#e8eef1' : '#fff', 
              fontFamily: '"Lato", sans-serif;'   
            }} 
          />
         
          {!viewMode && (
            <ForumCommentsActions>
              <button
                type="button"
                onClick={() => {
                  if (isChild) {
                    onAnswer();
                  } else if (showAnswerInput) {
                    focusInput();
                  } else {
                    setShowAnswerInput(true);
                  }
                }}
              >
                <FaRegComments color="#26ace7" style={{ marginRight: 10 }} /> <small>Responder</small>
              </button>
            </ForumCommentsActions>
          )}
        </div>

       
      </Content>
      <BoxButton>
       {rawStatement.length > STATEMENT_TEXT_LIMIT && (
          <button
            type="button"
            style={{margin: 0, padding: 0}}
            onClick={() => setShowMoreText(!showMoreText)}
          >
            {showMoreText ? "Ver menos" : "Ver mais"}
          </button>
        )}
      </BoxButton>        
     

      {data.attachments.length > 0 && (
        <div id="box-medias">
          {data.attachments.map((attachment, i) => {
            return (
              <MediaContainer>
                <button
                  type="button"
                  onClick={e => handleAttachmentOpen(e, attachment)}
                >
                  {renderAttachment(attachment)}
                </button>
                <span>Anexo {i + 1}</span>
              </MediaContainer>
            );
          })}
        </div>
      )}

      {data && data.answers && data.answers.length > 0 && (
        <Children>
          {data.answers.map((answer, index) => (
            <ForumComment
              isChild
              path={`${path}.answers.${index}`}
              key={answer.code}
              data={answer}
              onAnswer={() => {
                if (showAnswerInput) {
                  focusInput();
                } else {
                  setShowAnswerInput(true);
                }
              }}
            />
          ))}
        </Children>
      )}

      {showAnswerInput && (
        <>
          
            <input id={`input-${data.code}`} style={{width: 0, height: 0, border: 0}} />
            <div style={{height: 'auto'}}> 
              <DragAndDrop handleDrop={onDrop}>
                {/* <textarea
                
                  id={`input-${data.code}`}
                  placeholder="Digite um comentário ou arraste e solte um arquivo aqui."
                  value={answerText}
                  onChange={event => setAnswerText(event.target.value)}
                ></textarea> */}
                
                <LitEditor
                  border={0}
                  showToolbar
                  style={{ width: '100%', display: 'block', flexDirection: 'initial' }}
                  data={answerText}
                  onChange={newValue => setAnswerText(newValue)}
                />
                
                {/* {loadingAttachment && (
                  <LoadingWrapper>
                    <FaSpinner size={20} color="#0000ff" />
                  </LoadingWrapper>
                )}
                {!loadingAttachment && (
                  <button type="button" onClick={handleOnAnswer}>
                    {loading ? (
                      <LoadingWrapper>
                        <FaSpinner size={20} color="#0000ff" />
                      </LoadingWrapper>
                    ) : (
                      "Responder"
                    )}
                  </button>
                )} */}
              </DragAndDrop>
              <WrapperButtons>
                  <DropZoneClip {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!loadingAttachment && <><FaPaperclip /> Anexar arquivo</>}
                      </DropZoneClip>
                      {loadingAttachment && (
                        <LoadingWrapper>
                          <FaSpinner size={20} color="#0000ff" />
                        </LoadingWrapper>
                      )}
                </WrapperButtons>
            </div>
            
            <CommentButtonsActions>
                <WrapperButtons>
                  {!loading && (
                    showAnswerInput && <>
                        <ButtonTransparent
                          onClick={() => setShowAnswerInput(currentState => !currentState)}
                          >Cancelar</ButtonTransparent>
                        <ButtonBlue
                          onClick={handleOnAnswer}
                          >{loading ? (
                            <LoadingWrapper>
                              <FaSpinner size={20} color="#0000ff" />
                            </LoadingWrapper>
                          ) : (
                            "Publicar"
                          )}</ButtonBlue>
                      </>
                        )}
                </WrapperButtons>
              </CommentButtonsActions>

          <ThumbsContainer>{thumbs}</ThumbsContainer>
        </>
      )}
    </Container>
  );
}

export default ForumComment;
