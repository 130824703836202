import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} placeholder //component markup text
 * @param {String} name //file name
 * @param {String} refs //field reference value
 * @param {Boolean} required //default field value
 * @param {String} label //text displayed above the field
 * @param {String} value //default field value
 * @param {Function} onChange //action performed when clicking on the component
 * @param {Function} onBlur //action performed when clicking outside on the component
**/

export default ({
  placeholder = DEFAULT_VALUES.STRING,
  name = DEFAULT_VALUES.STRING,
  refs = DEFAULT_VALUES.STRING,
  required = DEFAULT_VALUES.BOOLEAN,
  label = DEFAULT_VALUES.STRING,
  value = DEFAULT_VALUES.STRING,
  onChange = DEFAULT_VALUES.FUNCTION,
  onBlur = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <Screen
        placeholder={placeholder}
        name={name}
        value={value}
        refs={refs}
        label={label}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};
