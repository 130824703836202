import React from "react";
import Screen from "./screen";

export default ({ isOpen, closeModal }) => {
  return (
    <>
      <Screen isOpen={isOpen} closeModal={closeModal} />
    </>
  );
};
