import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import * as Styles from './styles';

const LOGO_LIT = require("../../../../images/lit.png");

const Navbar = ({title, handleExit, ...rest}) => {
  return (
    <>
      <Styles.Nav  {...rest}>
        <Styles.Content>
          <a className='navbar-brand'>
            <img src={LOGO_LIT} alt='Logo LIT' width="120" />
          </a>
          <Styles.ButtonExit href="javascript:void(0)" onClick={handleExit}>
            <FaSignOutAlt /> Sair da avaliação
          </Styles.ButtonExit>
        </Styles.Content>
      </Styles.Nav>
      <Styles.Row>
        <Styles.ContentTitle>
          <Styles.Title>{title}</Styles.Title>
        </Styles.ContentTitle>
      </Styles.Row>
    </>
  )
}

export default Navbar
