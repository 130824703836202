/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { gateway } from "../../../../../../utils/api";

import Screen from './screen';

function LaneTrails({ data, trail = {}, contentCode, onClick, errorTrailsMessage }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [trails, setTrails] = useState([]);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    if (data) {
      const docs = _.get(data, 'docs', []);
      const page = _.get(data, 'page', 1);
      const limit = _.get(data, 'limit', 10);
      setLimit(limit);
      setTrails(docs);
      setCurrentPage(page + 1);
    }
  }, [data]);

  const getMoreTrails = useCallback(async () => {
    if (currentPage) {
      try {
        const query = `?limit=${limit}&page=${currentPage}&childType=trail`;
        const url = `webbff/lit_app_web/classes/${contentCode}/content${query}`;
        const response = await gateway.get(url);
        const hasNextPage = _.get(response, 'hasNextPage', false);
        const newTrails = _.get(response, 'docs', []);

        setCurrentPage(hasNextPage ? currentPage + 1 : null);
        setTrails(prevTrails => [...prevTrails, ...newTrails]);
      } catch (error) {
        console.log(error);
      }
    }
  }, [currentPage, limit, contentCode]);


  return (
    <Screen
      trail={trail|| {}} 
      errorTrailsMessage={errorTrailsMessage} 
      trails={trails} 
      getMoreTrails={getMoreTrails} 
      onClick={onClick} 
    />
  );
}

export default LaneTrails;
