import styled from "styled-components";
import { } from "../../../constants";

export const Dashed = styled.div`
  border: 2px dashed var(--dropzone-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: space-around;
  padding: 0;
  min-height: 100px;
  max-height: 200px;
  width: 100%;
  color: var(--dropzone-text);
  cursor: pointer;
  &:hover {
    color: var(--dropzone-text-focus);
    border-color: var(--dropzone-border-focus);
    cursor: pointer;
  }
`;

export const DropContent = styled.div`
  cursor: pointer;
  align-self: strech;
  width: 100%;
  label {
    text-align: center!important;
  }
`;

export const Drop = styled.div`
  max-height: 100%;
  margin: 10px;
  display: inline-block;
`;
