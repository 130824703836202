import md5 from 'md5';

import { gateway } from '../utils/api';

import {
  logCreateUser,
  generateSecurePassword,
  getLoginTenantURL
} from '../utils/functions';

export async function fetchUseAndPolicyTerms() {
  try {
    const response = await gateway.get('/term/default');
    const useTerm = response.find(item => item.wl_termtype === 'USE');
    const policyTerm = response.find(item => item.wl_termtype === 'POLICY');
    if (
      useTerm &&
      useTerm.qi_presentation &&
      useTerm.qi_presentation[0] &&
      useTerm.qi_presentation[0].wu_media_html &&
      policyTerm &&
      policyTerm.qi_presentation &&
      policyTerm.qi_presentation[0] &&
      policyTerm.qi_presentation[0].wu_media_html
    ) {
      return {
        useTerm: {
          id: useTerm._id,
          termText: useTerm.qi_presentation[0].wu_media_html
        },
        policyTerm: {
          id: policyTerm._id,
          termText: policyTerm.qi_presentation[0].wu_media_html
        }
      };
    }
    throw new Error('Erro ao buscar termos!');
  } catch (error) {
    throw new Error('Erro ao buscar termos');
  }
}

export async function register(registerData) {
  let body = null;
  let status = null;
  const response = {
    data: null,
    error: null
  };

  const urlParams = new URLSearchParams(window.location.search);
  const origin = urlParams.get('o');

  const loginTenant = getLoginTenantURL(window.location.href);

  try {
    // Fetch terms to generate signature hash
    const { useTerm, policyTerm } = await fetchUseAndPolicyTerms();

    // hash generation
    const terms = {
      id_useterm: useTerm.id,
      hash_useterm: md5(`${useTerm.id}-${registerData.email}`),
      date_useterm: new Date(),
      id_policyterm: policyTerm.id,
      hash_policyterm: md5(`${policyTerm.id}-${registerData.email}`),
      date_policyterm: new Date()
    };

    body = {
      firstName: registerData.name,
      lastName: registerData.lastName,
      phoneNumber: registerData.phoneNumber.replace(/\s|-/g, ''),
      linkPhoto: '',
      login: {
        username: registerData.email,
        password: generateSecurePassword(registerData.password),
        enabled: true,
        role: {
          idRole: 2
        }
      },
      responsible: {
        name: registerData.responsible.name,
        email: registerData.responsible.email,
        cpf: registerData.responsible.cpf
      },
      loginTenant,
      terms
    };

    if (origin) {
      body.origin = origin;
    }

    const url = '/webbff/lit_app_web/login/signup';
    const res = await gateway.post(url, body);
    status = {
      message: 'Criado com sucesso',
      status: 201,
      dateRequest: Date.now(),
      urlCalled: '/cadastroPerfil'
    };

    response.data = res;
    logCreateUser(body, status);
  } catch (err) {
    response.error = err;
    if (err.response) {
      status = {
        message: err.response.data.message,
        status: err.response.status,
        dateRequest: err.response.data.timestamp,
        urlCalled: '/cadastroPerfil'
      };
      logCreateUser(body, status);
    }
  }

  return response;
}
