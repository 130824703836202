import React from "react"
import { connect } from "react-redux"

import PerguntarWatson from "./PerguntarWatson"
import { messagesComponentGenerator, fetchWatsonAPI } from "./methods"

const imagePaulPensando = require("../../images/paul_loading.gif")
const imagePaulStandyBy = require("../../images/paul_standby.gif")
// Passar os inline css para classes
class WatsonChat extends React.Component {
  componentDidMount() {
    const { dispatch, user } = this.props

    fetchWatsonAPI(dispatch, user, "Olá", {})
  }

  componentDidUpdate() {
    this.autoScrollBottom()
  }

  autoScrollBottom = () => {
    //      const chatBox = document.getElementById("watsonMessageChatBox");
    //      chatBox.scrollTop = chatBox.scrollHeight;
    this.messagesEnd.scrollIntoView({ behavior: "smooth", block: "end" })
  }

  render() {
    const { dispatch, user, context, sendingMessage, chatMessages, fromNavbar } = this.props
    return (
      <main className="col-sm-12 chatWatson">
        {" "}
        {/* mudar para col-sm-9 quando as recomendações estiverem prontas */}
        <ul className="chatWatsonBox" style={{ paddingLeft: "8%", paddingRight: "8%" }}>
          <li className="d-flex justify-content-center chat-paul-standby">
            <img
              style={{ width: "150px", height: "100px", marginBottom: "10px" }}
              src={sendingMessage ? imagePaulPensando : imagePaulStandyBy}
              alt="Imagem"
            />
          </li>
          {chatMessages.map((message, index) =>
            messagesComponentGenerator(dispatch, user, message, context, index)
          )}
          <li
            style={{ height: "20px" }}
            ref={el => {
              this.messagesEnd = el
            }}
          />
        </ul>
        <PerguntarWatson fromNavbar={fromNavbar} />
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.paulChat.user,
    chatMessages: state.paulChat.chatMessages,
    context: state.paulChat.chatMessageContext,
    sendingMessage: state.paulChat.sendingMessage,
  }
}

export default connect(mapStateToProps)(WatsonChat)
