import React, {useContext} from "react";
import * as Styles from "./styles";
import {MeetingContext} from "../../MeetingContext";

const LOGO_LIT = require("../../../../../images/logo-black.png");

export default function MeetingVideoBox({ onToggleMeetingFullscreen }) {
  const { isMeetingFullscreen } = useContext(MeetingContext);
  
  return (
    <Styles.VideoBox>
      <Styles.LogoBar className={isMeetingFullscreen ? 'fullscreen' : ''}>
        <img src={LOGO_LIT} alt="Logo LIT" />
      </Styles.LogoBar>

      <Styles.VideoContainer className={isMeetingFullscreen ? 'fullscreen' : ''}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          onDoubleClick={() => onToggleMeetingFullscreen()}
          style={{ width: "100%", height: "100%" }}
          id="remote-view-video"
          autoPlay
          playsInline
        />

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio id="remote-view-audio" autoPlay />
      </Styles.VideoContainer>
    </Styles.VideoBox>
  );
}
