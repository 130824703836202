import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    margin-left: 10px;
  }

  button.btn-primary {
    background: #27aae1;
    border-color: #27aae1;
  }
`;
