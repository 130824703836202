/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"


class ReprovadoAvalicaoFinal extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody } = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                7. Fui reprovado na avaliação final, o que fazer?
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                 <p>
                    Você pode fazer a sua Avaliação Final até 10 vezes. Sugerimos que você reveja os conteúdos do curso novamente, antes de fazer uma nova tentativa.     
                </p>
                <p>
                    Para saber quais tópicos você precisa desenvolver mais, entre no curso em andamento e clique na opção Feedback do Curso e depois em Avaliação Final.
                    <br />
                    Ao fazer isso, irá abrir um gráfico de notas. As barras mais baixas do gráfico são as Unidades de Conhecimento que você mais precisa desenvolver,
                    <br />
                    antes de fazer uma nova tentativa na Avaliação Final.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ReprovadoAvalicaoFinal;
