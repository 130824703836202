import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} left //receives the content that will be displayed in the left area
 * @param {Component} right //receives the content that will be displayed in the right area
 * @param {String} alignItems //{TOP_CENTER, TOP_LEFT_CENTER} alignment of items within the column
**/

export default ({
  left = DEFAULT_VALUES.COMPONENT,
  right = DEFAULT_VALUES.COMPONENT,
  alignItems = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        left={left}
        right={right}
        alignItems={alignItems}
      />
    </>
  );
};
