import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/** 
 * Infinite Scroll with action
 * @param {Component} children //content displayed.
 * @param {number} dataLength //content list length.
 * @param {Function} loadAction //callback to load more items.
 * @param {string} scrollableTarget //reference to scroll container.
 * @param {boolean} hasMore //indicates the existence of more content that can be loaded.
*/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
  dataLength = DEFAULT_VALUES.NUMBER,
  loadAction = DEFAULT_VALUES.FUNCTION,
  scrollableTarget = 'scrollableDiv',
  hasMore = true
}) => {
  return (
    <>
      <Screen
        child={children}
        dataLength={dataLength}
        loadAction={loadAction}
        scrollableTarget={scrollableTarget}
        hasMore={hasMore}
      />
    </>
  );
};
