import styled, { css } from "styled-components";
import { SIZE } from "../../../constants";

export const Spacings = styled.div`
  
  ${props => (props.size === SIZE.SMALL) && css`
    > div {
      margin: 1rem 0;
    }
    @media (max-width: 767px) {
      > div {
        margin: 10px 0;
      }
    }
  `}
  
  ${props => (props.size === SIZE.MEDIUM) && css`
    > div {
      margin: 2rem 0;
    }
    @media (max-width: 767px) {
      > div {
        margin: 1rem 0;
      }
    } 
  `}

  ${props => (props.size === SIZE.LARGE) && css`
    > div {
      margin: 3rem 0;
    }
    @media (max-width: 767px) {
      > div {
        margin: 2rem 0;
      }
    }
  `}
  
`;
