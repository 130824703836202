import React, { useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';

import { ButtonAfterCarousel, ButtonNextCarousel, ContainerCourses, Item, BoxLogo } from './styles';

const Courses = ({ data }) => {
  const [coursesState, setCoursesState] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  let carouselRef;

  const onSlideChanged = e => {
    setCurrentIndex(e.item);
  };

  const renderItems = () => {
    if (data.courses) {
      return data.courses.map(item => {
        return (
          <Item
            key={item.title}
            title={item.title}
            imageUrl={item.imageUrl}
          />
        );
      });
    }
  };

  useEffect(() => {
    setCoursesState(renderItems());
  }, [data]);

  return (
    <ContainerCourses>
      <>
        {data.courses && data.courses.length && (
          <>
             <AliceCarousel
                items={coursesState}
                mouseDragEnabled
                buttonsDisabled
                dotsDisabled
                startIndex={0}
                slideToIndex={currentIndex}
                onSlideChanged={onSlideChanged}
                autoPlay={data.courses.length > 1}
                autoPlayInterval={10000}
                ref={el => (carouselRef = el)}
              />

              {data.courses.length > 1 && (
                <>
                  <ButtonAfterCarousel className='btn-carousel' type="button" onClick={() => carouselRef.slidePrev()}>
                    <i className="fa fa-angle-double-left" aria-hidden="true" />
                  </ButtonAfterCarousel>
                  <ButtonNextCarousel className='btn-carousel' type="button" onClick={() => carouselRef.slideNext()}>
                    <i className="fa fa-angle-double-right" aria-hidden="true" />
                  </ButtonNextCarousel>
                </>
              )}
          </>
        )}
        {data && !data.courses && (
          <BoxLogo bg={data.backgroundGradient || data.backgroundColor}>
            <img src={data.logoUrl} alt="" />
          </BoxLogo>
        )}
      </>
    </ContainerCourses>
  );
};

export default Courses;
