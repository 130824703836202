import React from "react"
import ReactPlayer from "react-player"

import Duration from '../Duration'
import {ContainerPlay} from './styles'

const style = {
  video: {
    width: "100%",
    height: "100%",
  },
}

const speeds = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]
const counterMaxValue = 5;

class Play extends React.Component {
  constructor() {
    super()
    this.state = {
      playing: false,
      volume: 1,
      muted: false,
      replay: false,
      played: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      shareScreen: false,
      counterTime: 0,
      startFrom: 0,
      like: null,
      fineshed: false,
      playedSeconds: null,
      isCounting: false,
      isEndVideo: false,
      counter: counterMaxValue,
      timer: 0,
    }
  }

  componentDidMount() {
    
  }


  getPlagetPlyedTime = () => {
    const { duration, played } = this.state
    const date = new Date(duration * played * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = this.pad(date.getUTCSeconds())

    if (hh) {
      return `${hh}:${this.pad(mm)}:${ss}`
    }

    return `${mm}:${ss}`
  }

  pad = string => {
    return `0${string}`.slice(-2)
  }

  onReady = (e) => {
    this.getCurrentPosition()
  }

  onPlay = () => {
    this.setState({ playing: true })
  }

  onPause = () => {
    const { loop } = this.state
    this.setState({ playing: loop })
  }

  onEnded = () => {
    console.log('Fim')
  }

  onProgress = state => {
    const { seeking, counterTime } = this.state
    if (!seeking) {
      this.setState(state)
    }

    this.setState({ counterTime: counterTime + 1 })
  }

  playPause = () => {
    const { playing } = this.state
    this.setState({ playing: !playing, replay: false })
  }

  toggleMuted = () => {
    const { muted } = this.state
    this.setState({ muted: !muted })
  }

  onSeekMouseDown = () => {
    this.setState({ seeking: true })
  }

  onSeekChange = e => {
    console.log("SeekChange", e.target.value)
    this.setState({ played: parseFloat(e.target.value) })
  }

  onSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  onClickFullscreen = () => {
    // screenfull.request(findDOMNode(this.player))
  }

  onStart = () => {
    const { startFrom } = this.state
    this.player.seekTo(startFrom || 0)
  }

  shareMenu = () => {
    const { shareScreen } = this.state
    this.setState({ shareScreen: !shareScreen })
  }

  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) })
  }

  ref = player => {
    this.player = player
  }

  _handleContextMenu = e => {
    e.preventDefault()
  }

  render() {
    const {
      playing,
      volume,
      muted,
      played,
      duration,
      playbackRate,
      replay,
     
    } = this.state
    const { url } = this.props

    return (
      <div className="d-flex flex-column" onContextMenu={this._handleContextMenu}>
        <ContainerPlay>
          <ReactPlayer
            url={url}
            className="react-player-modal"
            style={style.video}
            ref={this.ref}
            onStart={this.onStart}
            onPlay={this.onPlay}
            onPause={this.onPause}
            playbackRate={playbackRate}
            onDuration={dur => this.setState({ duration: dur })}
            onProgress={this.onProgress}
            onEnded={this.onEnded}
            muted={muted}
            playing={playing}
            volume={volume}
          />
        </ContainerPlay>
        <input
          type="range"
          min={0}
          max={1}
          step="any"
          value={played}
          onMouseDown={this.onSeekMouseDown}
          onChange={this.onSeekChange}
          onMouseUp={this.onSeekMouseUp}
        />
        <div className="video-control d-flex align-items-center">
          {replay ? (
            <a data-tip="Play" role="button" className="btn" onClick={this.playPause}>
               <i className="fa fa-repeat" aria-hidden="true" />
            </a>
          ) : (
             <a data-tip="Play" role="button" className="btn" onClick={this.playPause}>
              {playing ? (
                <i className="fa fa-pause" aria-hidden="true" />
              ) : (
                <i className="fa fa-play" aria-hidden="true" />
              )}
            </a>
          )}
         
          <div
            data-tip="Liga, aumenta e diminui som e desliga"
            className="volume d-flex align-items-center"
          >
            <a role="button" className="btn" onClick={this.toggleMuted}>
              {muted ? (
                <i className="fa fa-volume-off" aria-hidden="true" />
              ) : (
                <i className="fa fa-volume-up" aria-hidden="true" />
              )}
            </a>
            <div className="volume-range">
              <input
                id="volume-range"
                type="range"
                min={0}
                max={1}
                step="any"
                value={volume}
                onChange={this.setVolume}
              />
            </div>
          </div>
          <Duration seconds={duration * played} /> / <Duration seconds={duration} />
          <div className="d-flex align-items-center ml-auto p-2">
            <div className="btn-group dropup">
              <button
                type="button"
                data-tip="Ajuste a velocidade do vídeo"
                className="btn"
                style={{ color: "white", background: "transparent", borderRadius: ".25rem" }}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {playbackRate}x <i className="fa fa-clock-o" />
              </button>
              <div className="dropdown-menu video-control">
                {speeds.map(x => {
                  return (
                    <button
                      key={x}
                      className="dropdown-item"
                      onClick={() => this.setState({ playbackRate: x })}
                      type="button"
                    >
                      {x === playbackRate && "✓"} {x === 1 ? "Normal" : x}
                    </button>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Play
