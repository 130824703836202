import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  margin: 30px 0;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  .rc-slider {
    height: 5px !important;
    padding: 0;
  }
`;

export const ContainerChart = styled.div`
  margin: 20px auto;
  max-width: 450px;
`;

export const ContainerAttempts = styled.div`
  border-bottom: 1px solid #dfdfdf;

  /* border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 5px; */
  & + div {
    margin-top: 30px;
  }

  ul {
    padding: 0;
    li {
      text-align: center;
      font-size: 16px;
      padding: 8px 5px;
      background: #eee;
      & + li {
        margin-top: 10px;
      }
    }
  }
`;

export const Title = styled.h3`
  width: 100%;
  text-align: center;
`;

export const WrapperInfo = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 673px) {
    flex-wrap: wrap;
  }
`;

export const WrapperInfoTracking = styled.div`
  display: flex;
  margin-bottom: 20px;

  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    max-width: 200px;
  }

  @media (max-width: 673px) {
    flex-wrap: wrap;
  }
`;

export const BoxInfo = styled.div`
  background: #fff;
  width: 250px;
  height: 100px;
  padding: 7px 5px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 673px) {
    width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & + div {
    margin-left: 5px;
  }

  p {
    text-transform: uppercase;
    font-size: 14px;
    margin: auto;
  }

  p.highlight {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const ContainerSlider = styled.div`
  margin-bottom: 80px !important;
  margin-top: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 500px;
  ::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 0px !important;
  }
`;

export const TextRanger = styled.span`
  text-align: center;
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;

export const ContainerBtn = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  background: #27aae1;
  border-radius: 5px;
  width: ${props => (props.width ? `${props.width}px` : '110px')};
  height: ${props => (props.height ? `${props.height}px` : '30px')};
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 5px 10px;
  cursor: pointer;
  font-family: Lato;

  &:disabled {
    cursor: not-allowed;
  }
`;
