import styled, { css } from "styled-components";
import { APPEARANCE } from "../../../constants";

export const Table = styled.table`
  width: 100%;
`;

export const TableHeader = styled.thead`
  background: var(--white);
  width: 100%
`;

export const HeaderContent = styled.th`
  width: auto!important;
  padding: 5px 10px;
`;

export const TableBody = styled.tbody`
  width: 100%;
`;

export const BodyContent = styled.td`
  width: auto!important;
  padding: 5px 10px;
`;

export const TableRow = styled.tr`
  
  ${props => (props.appearance === APPEARANCE.STRIPED) && css`
    width: 100%;
    :nth-of-type(odd) {
      background: var(--table-striped-odd); 
    }
    :nth-of-type(even) {
      background: var(--table-striped-even); 
    }
  `}

`;
