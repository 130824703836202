import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Pagination from "../../Pagination"
import { SocialActions } from "../../../store/ducks/social"
import NavBarSuperior from "../../NavBarSuperior/NavBarSuperior"
import Perfil from "../Perfil"
import Main from "./Main"
import Post from "./Post"
import MeusGrupos from "./MeusGrupos"
import Membros from "./Membros"
import AddUsers from "./AddUsers"
import Solicitacoes from "./Solicitacoes"
import { LoaderPost } from "../../LoaderAnim"
import api from "../../../utils/api"

import "./Media.css"

/** **************
 Owner - 1
 Member - 2
 Waiting - 3
 NDA - 0
 ************ */

class Grupo extends React.Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    groupStatus: {
      privacity: null,
      typeMember: null,
    },
    page: 1,
    totalPages: 0,
    postList: [],
    membro: true,
    refreshMembers: false,
    inviteRequestList: [],
    loadingPosts: false,
  }

  componentDidMount() {
    this.fetchAll()
    this._verifyGroup()
  }

  componentWillReceiveProps(next) {
    const { match } = this.props
    if (match.params.idGrupo !== next.match.params.idGrupo) {
      this.fetchAll(next.match.params.idGrupo)
    }
  }

  getGroupData = () => {
    this.getPosts()
  }

  getPosts = async id => {
    this.setState({ loadingPosts: true })
    const { match } = this.props
    const idGroup = id || match.params.idGrupo
    const { page } = this.state
    const idProfile = localStorage.getItem("idProfile")
    try {
      const response = await api.get(`findPostByGroup/${idGroup}/${idProfile}?page=${page - 1}&size=15`)

      this.setState({ postList: response.posts, totalPages: response.totalPages, loadingPosts: false })
    } catch (error) {
      this.setState({ loadingPosts: false })
    }
  }

  onPageChanged = data => {
    const { page } = this.state
    const newPage = data.currentPage
    if (newPage !== page) {
      console.log(page)
      console.log(newPage)

      this.setState({ page: newPage }, this.getPosts)
    }
  }

  groupList = () => {
    const idProfile = localStorage.getItem("idProfile")
    const { getMyGroupsRequest } = this.props
    getMyGroupsRequest(idProfile)
  }

  getInvites = async id => {
    const { match } = this.props
    const idGroup = id || match.params.idGrupo
    try {
      const response = await api.get(`getProfilesToAcceptInvites/${idGroup}`)
      this.setState({ inviteRequestList: response })
    } catch (error) {
      console.log(error)
    }
  }

  fetchAll = id => {
    this.getInvites(id)
    this.getPosts(id)
    this.groupList()
    // this.props.dispatch(fetchMyGroups(localStorage.getItem('idProfile')))
  }

  _verifyGroup = async () => {
    const idProfile = localStorage.getItem("idProfile")
    const { match } = this.props
    const idGroup = match.params.idGrupo
    try {
      const response = await api.get(`verifyMemberInGroup/${idProfile}/${idGroup}`)
      this.setState({ groupStatus: response })
    } catch (error) {
      console.log(error)
    }
  }

  onAcceptInvite = () => {
    const { refreshMembers } = this.state
    this.setState({ refreshMembers: !refreshMembers })
  }

  render() {
    const {
      groupStatus,
      inviteRequestList,
      postList,
      membro,
      loadingPosts,
      refreshMembers,
      totalPages,
      page,
    } = this.state
    const {
      match: {
        params: { idGrupo },
      },
      myGroups,
    } = this.props
    return (
      <div id="container">
        <NavBarSuperior />
        <div className="container-fluid space-navbar" id="social">
          <div className="row">
            <div className="col-md-3 col-xl-2">
              <Perfil />
              {groupStatus.typeMember === 1 && (
                <React.Fragment>
                  <div className="card" style={{ marginBottom: 30 }}>
                    <div
                      className="card-header with-flex"
                      style={{ paddingLeft: "1rem", paddingRight: 0 }}
                    >
                      <span className="titulo">Convidar para o grupo</span>
                    </div>
                    <AddUsers onAcceptInvite={this.onAcceptInvite} idGroup={idGrupo} />
                  </div>
                  {inviteRequestList.length !== 0 && (
                    <div className="card">
                      <div className="card-header sidebar with-flex">
                        <span className="titulo">{inviteRequestList.length} Pedidos em aberto</span>
                      </div>
                      <div className="card-body" style={{ padding: 0 }}>
                        <ul className="list-group" style={{ paddingLeft: 0, paddingRight: 0 }}>
                          {inviteRequestList.map(openInviteRequest => {
                            return (
                              <Solicitacoes
                                key={openInviteRequest.idProfile}
                                getInvites={this.getInvites}
                                grupoId={idGrupo}
                                userRequest={openInviteRequest}
                                onAcceptInvite={this.onAcceptInvite}
                              />
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="col-md-7">
              <Main
                idGroup={idGrupo}
                getPosts={this.getPosts}
                groupStatus={groupStatus}
                getGroupData={this.getGroupData}
                onJoinPublic={this._verifyGroup}
              />
              {((groupStatus.typeMember !== 0 && groupStatus.typeMember !== 3) ||
                !groupStatus.privacity) && (
                <div className=" mb-3">
                  {postList.length > 0 && (
                    <div className="card d-flex mb-2 flex-row align-items-center justify-content-end">
                      <Pagination
                        totalRecords={totalPages}
                        pageLimit={10}
                        pageNeighbours={1}
                        page={page}
                        onPageChanged={this.onPageChanged}
                      />
                    </div>
                  )}
                  <div className="">
                    {loadingPosts && <LoaderPost size={5} />}

                    {postList.map(post => {
                      return (
                        <Post
                          key={post.idTimeLine}
                          groupOwner={groupStatus.typeMember}
                          post={post}
                          membro={membro}
                          getPosts={this.getPosts}
                        />
                      )
                    })}
                  </div>
                  {postList.length > 0 && (
                    <div className="card d-flex flex-row align-items-center justify-content-end">
                      <Pagination
                        totalRecords={totalPages}
                        pageLimit={10}
                        pageNeighbours={1}
                        page={page}
                        onPageChanged={this.onPageChanged}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-md-2 col-xl-3">
              <div className="card">
                <div className="card-header with-flex">
                  <span className="titulo">Meus grupos de prática</span>
                </div>
                <div className="card-body" style={{ padding: 0 }}>
                  <ul className="list-group">
                    {myGroups.data.map(grupo => {
                      if (parseInt(idGrupo, 10) === parseInt(grupo.idGroup, 10)) return null
                      return <MeusGrupos key={grupo.idGroup} grupo={grupo} atual={idGrupo} />
                    })}
                  </ul>
                </div>
              </div>
              <Membros refresh={refreshMembers} idGroup={idGrupo} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  myGroups: state.social.myGroups,
})

const mapDispatchToProps = dispatch => bindActionCreators(SocialActions.myGroupsActions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Grupo)
