import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Navbar from "../Termos/Navbar"

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import GrupoInteresse from "./GruposInteresses"
import WatsonBadgeFooter from "./WatsonBadgeFooter"
import api, {gateway} from "../../utils/api"

const GIF_LIT = require("../../images/animacao_paul_standby.gif")

class Interesses extends PureComponent {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    trackSuggestionsList: [],
    listaSelecionados: [],
    forceSuggestions: false,
  }

  get navSuperior() {
    return JSON.parse(localStorage.getItem("firstLogin")) ? <Navbar /> : <NavBarSuperior />
  }

  componentWillMount() {
    const { router } = this.context
    
    if(router.route.location && router.route.location.state) {
      const {location: {state : {forceSuggestions = true}}} = router.route;
      this.setState({forceSuggestions});
    }

    if (localStorage.getItem("completedPersonality") !== "true") {
      this._getTrackSuggestion()
      // this._getPerfil()
    } else if (localStorage.getItem("completedPersonality") === "true") {
      router.history.push("/resultados")
    }
  }

  _getPerfil = async () => {
    try {
      const { router } = this.context
      const response = await gateway.get(`/webbff/lit_app_web/findPrivateProfile/${localStorage.getItem("idProfile")}`)
      localStorage.setItem("completedPersonality", response.completedPersonality)
      if (response.completedPersonality) {
        router.history.push("/resultados")
      }
    } catch (error) {
      console.log(error)
    }
  }

  _getTrackSuggestion = async () => {
    try {
      const response = await api.get("trackSuggestion")
      const trackSuggestions = response.filter(
        suggestion => suggestion.idSuggestion !== 16 && suggestion.idSuggestion !== 18
      )
      this.setState({ trackSuggestionsList: trackSuggestions })
    } catch (error) {
      console.log(error)
    }
  }

  nextStep = () => {
    const {forceSuggestions} = this.state
    const { router } = this.context
    const firstLogin = JSON.parse(localStorage.getItem("firstLogin"))
    const finishOnBoarding = JSON.parse(localStorage.getItem("finishOnBoarding"))
    if(forceSuggestions) {
      router.history.push("/home")
      return;
    }
    if (firstLogin && !finishOnBoarding) {
      router.history.push("/formacao")
    } else {
      router.history.push("/paulDicas")
    }
  }

  render() {
    const { trackSuggestionsList, listaSelecionados, forceSuggestions } = this.state
    return (
      <div id="interesses">
        {this.navSuperior}

        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row">
            <div className="col-12">
              <div className="container" id="objetivos-interesses">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="container" id="card-interesses">
                      <div>
                        <div className="row justify-content-md-center">
                          <div className="col-10 text-center text-body-pi">
                            <img
                              alt="gif_lit"
                              className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                              src={GIF_LIT}
                            />
                            <h5 style={{ color: "#27aae1" }} className="text-center">
                              Quais áreas você gostaria de aprimorar?
                            </h5>
                            <p className="text-center">
                              Você pode selecionar várias áreas de interesse. A ordem de sua seleção
                              estabelece as prioridades da sua aprendizagem e a personalização do seu
                              perfil.
                            </p>
                          </div>
                        </div>
                        <div className="row justify-content-md-center">
                          <GrupoInteresse
                            onSelect={selected => this.setState({ listaSelecionados: selected })}
                            TrackSuggestionsList={trackSuggestionsList}
                          />
                        </div>

                        <div className="row justify-content-center" id="selecionar-personalizar">
                          <div className="d-flex justify-content-center col-2">
                            <button
                              type="button"
                              disabled={listaSelecionados.length === 0}
                              onClick={this.nextStep}
                              className="btn btn-blue btn-FontWeight6"
                              id="btn-next-step"
                            >
                              {forceSuggestions ? 'Finalizar' : 'Próximo passo'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <WatsonBadgeFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.user.profile.data,
})

export default connect(mapStateToProps)(Interesses)
