import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../constants";

/**
 * @param {Array} data //list of items that will be presented
 * @param {Number} dataLength //number of items that will be presented
 * @param {String} grid //{ONE, TWO, THREE} number of items presented per line
 * @param {Function} callback //action that will be shooted at the click of the line
 * @param {Component} actions //buttons rendered on each row
 * @param {} openExternalPage //external page reference
**/

export default ({
  data,
  dataLength,
  grid,
  action,
  callback,
  actions,
  openExternalPage
}) => {
  return (
    <>
      <Screen
        openExternalPage={openExternalPage}
        data={data}
        dataLength={dataLength}
        grid={grid}
        action={action}
        actions={actions}
        callback={callback}
      />
    </>
  );
};
