import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import { openCallToAction } from '../../../../business/cta';

import Screen from "./screen";
import Loading from "../../../Loading/Loading";
import { gateway } from "../../../../utils/api";

function Calendar() {
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [classes, setClasses] = useState(null);

  const handleChangeDate = useCallback(value => {
    setSelectedDate(value);
  }, []);

  async function loadEvents(
    startDate = false,
    endDate = false
  ) {
    try {

      setLoadingEvents(true);
      
      const url = `webbff/lit_app_web/event/all`;
      
      const date = new Date();
      const firstDayOfMonth = startDate ? startDate : new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDayOfMonth = endDate 
            ? new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1)
            : new Date(date.getFullYear(), date.getMonth() + 1, 1);
      
      const body = {
        classesCodes: classes,
        startDate: firstDayOfMonth,
        date: lastDayOfMonth
      };

      const response = await gateway.post(url, body);

      const formattedEvents = response.map((event, index) => {
        return {
          id: index,
          title: event.name || "Sem descrição",
          start: new Date(event.dateStart),
          end: new Date(event.dateEnd),
          hexColor: _.get(event, "color.hex", "27aae1"),
          available: _.get(event, 'available', true),
          cta: () => event.available && openCallToAction(event.cta)
        };
      });
      setEvents(formattedEvents);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingEvents(false);
    }
  }

  const handleActiveStartDateChange = dates => {
    if(dates.view === 'month'){
      const startDate = dates.activeStartDate;
      const endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0);
      
      loadEvents(
        startDate,
        endDate
      );
    }
  }

  useEffect(() => {
    if(classes){
      loadEvents();
    }
  }, [classes]);

  useEffect(() => {
    async function fetchClasses() {
      try {
        const response = await gateway("/webbff/lit_app_web/classes");
        const organizationCrCodes = response.map(organization => {
          return _.get(organization, 'organizationCode');
        });
        setClasses(organizationCrCodes);
      } catch (error) {
        console.log(error);
        setClasses([]);
      }
    }
    fetchClasses();
  }, []);

  return (
    <>
    <Screen
          events={events}
          selectedDate={selectedDate}
          onChangeDate={handleChangeDate}
          handleActiveStartDateChange={handleActiveStartDateChange}
          loading={loadingEvents}
        />
    </>
  );
}

Calendar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  turma: PropTypes.object.isRequired
};

export default Calendar;
