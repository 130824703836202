import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  background: red
`;

const Box = styled.div`
  padding: 4px;
  text-align: center;
  background-color: #e0e0e0;
  font-size: 20px;
  width: 180px;

  & + div {
    margin-left: 10px;
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

export const TextTimer = styled(Box)``;

export const ValueText = styled.span`
  display: inline-block;
  margin-bottom: 12px;
`;

export const DiscountText = styled.span`
  margin-left: 0;
  display: inline-block;
  margin-bottom: 12px;
`;

export const DescriptionText = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const BoxPrice = styled.div``;

export const Button = styled.button`
  padding: 4px 10px;
  border-radius: 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  margin-left: 15px;

  background: white;
  color: #27aae1;
  border: 2px solid #27aae1;
  &:hover {
    color: white;
    background: #27aae1;
  }

  span {
    display: inline-block;
    margin: 0 5px;
    flex: 1;
  }
`;

export const QrCodeImage = styled.div`
  margin-top: 20px;
  width: 300px;
  height: 300px;
`;
