import styled from "styled-components";
import { } from "../../../constants";

export const LeftBar = styled.div`
  display: inline-block;
  width: 80%;
  @media screen and (max-width: 767px) {
     width: 100%;
  }
`;

export const RightBar = styled.div`
  display: inline-block;
  width: 20%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
