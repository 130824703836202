import React from 'react';
import PropTypes from "prop-types";

import Screen from './screen'

function CardWarning({warning, onClick}) {
  return (
    <Screen warning={warning} onClick={onClick} />
  );
}

CardWarning.propTypes = {
  warning: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

CardWarning.defaultProps = {
  onClick: () => {}
}

export default CardWarning;
