import React, { useState, useEffect } from "react";
import { gateway } from "../../../utils/api";
import { toastDefaultOptions } from '../../../utils/functions'
import Screen from "./screen";
import { TIMELINE_ACTIONS } from "../constants";
import successImage from "../../../images/success-image.png";
import litLogo from "../../../images/lit-white.png";
import _ from "lodash";
import { toast } from 'react-toastify';
import showToast from "../../../utils/showToast";

export default () => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  const [groupCode, setGroupCode] = useState(getGroupCode());

  const [isMember, setIsMember] = useState(false);
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isSolicitationPending, setIsSolicitationPending] = useState(false);
  const [visibility, setVisibility] = useState("private");

  const [groupInformation, setGroupInformation] = useState(null);

  const [changeImage, setChangeImage] = useState(null);

  const [editGroupModalIsOpen, setEditGroupModalIsOpen] = useState(false);
  const [inviteMemberModalIsOpen, setInviteMemberModalIsOpen] = useState(false);
  const [manageMembersModalIsOpen, setManageMembersModalIsOpen] = useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [interationsModalIsOpen, setInterationsModalIsOpen] = React.useState(false);

  const [communicationPage, setCommunicationPage] = useState(2);
  const [solicitationsPage, setSolicitationsPage] = useState(2);
  const [hasMoreSolicitaions, setHasMoreSolicitations] = useState(false);
  const [hasMoreCommunications, setHasMoreCommunications] = useState(true);

  const [searchUserPage, setSearchUserPage] = useState(1);

  const [memberDetail, setMemberDetail] = useState({ img: "", name: "" });

  const [profiles, setProfiles] = useState([]);
  const [communications, setCommunications] = useState([]);

  const [members, setMembers] = useState([]);
  const [solicitations, setSolicitations] = useState([]);

  const [totalMembers, setTotalMembers] = useState(0);
  const [groupRoles, setGroupRoles] = useState([]);
  const [optionsPage, setOptionsPage] = useState([]);

  const [areasOfInterest, setAreasOfInterest] = useState([]);

  const [reactionsTypes, setReactionsTypes] = useState([]);

  const [group, setGroup] = useState({
    id: 0,
    name: "",
    image: "",
    administrator: "",
    description: ""
  });

  const [modalJoinIsOpen, setJoinIsOpen] = useState(false);

  function getGroupCode() {
    return window.location.pathname.split("/").slice(-1)[0];
  }

  function openJoinModal() {
    setJoinIsOpen(true);
  }

  function closeJoinModal() {
    setJoinIsOpen(false);
  }

  const [infoPage, setInfoPage] = useState({
    logo: litLogo,
    title: group.name,
    img: group.image
  });

  const [adminOptionsPage] = useState([
    {
      action: () => setInviteMemberModalIsOpen(true),
      description: TIMELINE_ACTIONS.INVITE_MEMBER
    },
    {
      action: () => setEditGroupModalIsOpen(true),
      description: TIMELINE_ACTIONS.EDIT_GROUP
    },
    {
      action: () => setManageMembersModalIsOpen(true),
      description: TIMELINE_ACTIONS.MANAGE_MEMBERS
    }
  ]);

  const [memberOptionsPage] = useState([
    {
      action: () => setInviteMemberModalIsOpen(true),
      description: TIMELINE_ACTIONS.INVITE_MEMBER
    }
  ]);

  async function loadGroupInfo() {
    try {
      setLoadingProgress(50);
      const response = await gateway.get(`/webbff/lit_app_web/social/getGroupInfo/${groupCode}`);

      setGroup(response);
      setGroupInformation(true);
      setMemberDetail(_.get(response, 'member', null));
      setIsSolicitationPending(_.get(response, 'isSolicitationPending', false));
      setMembers(_.get(response, 'groupedMembers', []));
      setVisibility(_.get(response, 'visibility', 'private'));
      setTotalMembers(_.get(response, 'totalMembers', 0));
      setSolicitations(_.get(response, 'solicitations.docs', []));
      setCommunications(_.get(response, 'communications', []));
      setIsAdministrator(_.get(response, 'isAdministrator', false));
      setIsMember(_.get(response, 'isMember', false));
      setInfoPage({
        logo: litLogo,
        title: _.get(response, 'name', ''),
        img: _.get(response, 'image', '')
      });

      if (_.get(response, 'isAdministrator', false)) {
        setOptionsPage(adminOptionsPage);
      }
      else {
        setOptionsPage(memberOptionsPage);
      }

      setLoadingProgress(100);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function loadGroupRoles() {
    try {
      const response = await gateway.get(`/webbff/lit_app_web/social/getAllGroupRoles`);

      setGroupRoles(_.get(response, 'roles', []));
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function loadMoreSolicitations() {
    try {
      setLoadingProgress(50);

      const response = await gateway.get(`/webbff/lit_app_web/social/getPendingSolicitations/${groupCode}?page=${solicitationsPage}&limit=20`);

      setSolicitations([...solicitations, ..._.get(response, 'docs', [])]);

      setSolicitationsPage(solicitationsPage + 1);
      setHasMoreSolicitations(_.get(response, 'hasNextPage', false));

      setLoadingProgress(100);
    }
    catch (error) {
      setLoadingProgress(100);
    }
  }

  async function loadAreasOfInterest() {
    try {
      const response = await gateway.get(`/webbff/lit_app_web/social/getAllAreasOfInterest`);

      setAreasOfInterest(_.get(response, 'roles', []));
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function loadCommunicationReactions(communicationCode) {
    try {
      setLoadingProgress(50);

      const response = await gateway.get(`/webbff/lit_app_web/social/getCommunicationReactions/${communicationCode}`);

      setLoadingProgress(100);

      return response;
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function loadReactionPeople(communicationCode, reactionType, page = 1, limit = 10) {
    try {
      setLoadingProgress(50);

      const response = await gateway.get(`/webbff/lit_app_web/social/getReactionPeople/${communicationCode}/${reactionType}?page=${page}&limit=${limit}`);

      setLoadingProgress(100);

      return response;
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function leaveGroup() {
    try {
      setLoadingProgress(50);

      const body = {
        groupCode,
      };

      const response = await gateway.put(`/webbff/lit_app_web/social/leaveGroup`, body);

      await loadGroupInfo();

      setLoadingProgress(100);
      showToast(response);
    } catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function joinGroup() {
    try {
      setLoadingProgress(50);

      const body = {
        groupCode
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/joinGroup`, body);

      await loadGroupInfo();

      setLoadingProgress(100);
      showToast(response);
    } catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function updateCommunicationReactions(communicationCode, reactionType, removeReactionType) {
    try {
      setLoadingProgress(50);

      const body = {
        reactionType: reactionType != removeReactionType ? reactionType : undefined,
        communicationCode,
        removeReactionType,
        destinationCode: groupCode,
        destinationType: 'GROUP',
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/updateCommunicationReactions`, body);

      setCommunications(_.get(response, 'docs', []));

      setLoadingProgress(100);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function loadReactionsTypes() {
    try {
      setLoadingProgress(50);

      const response = await gateway.get(`/webbff/lit_app_web/social/getAllReactionTypes`);

      setReactionsTypes(_.get(response, 'roles', []));

      setLoadingProgress(100);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function updatePost(content, communicationId, files, removedFiles) {
    try {
      setLoadingProgress(50);

      const toInsert = [];

      for (let i = 0; i < files.length; i++) {
        const savedFileCode = await saveFile(files[i]);
        if (savedFileCode)
          toInsert.push({ code: savedFileCode });
      }

      const body = {
        attachs: {
          toInsert,
          toDelete: removedFiles
        },
        content,
        communicationId,
        organizationCode: groupCode,
      };

      const response = await gateway.put(`/webbff/lit_app_web/social/updateCommunication`, body);

      setCommunications(_.get(response, 'docs', []));

      setLoadingProgress(100);
      showToast(response);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function createPost(content, files) {
    try {
      setLoadingProgress(50);

      const attachs = [];

      for (let i = 0; i < files.length; i++) {
        const savedFileCode = await saveFile(files[i]);
        if (savedFileCode)
          attachs.push({ code: savedFileCode });
      }

      const body = {
        content,
        attachs,
        code: groupCode,
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/createCommunication`, body);

      setCommunications(_.get(response, 'docs', []));
      setLoadingProgress(100);
      showToast(response);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function createComment(content, postCode, files) {
    try {
      setLoadingProgress(50);

      const attachs = [];

      for (let i = 0; i < files.length; i++) {
        const savedFileCode = await saveFile(files[i]);
        if (savedFileCode)
          attachs.push({ code: savedFileCode });
      }

      const body = {
        content,
        attachs,
        parentId: postCode,
        code: groupCode,
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/createCommunication`, body);

      setCommunications(_.get(response, 'docs', []));
      setLoadingProgress(100);
      showToast(response);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function saveFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append("mediaType", file.type.toUpperCase());
    const response = await gateway.post("/media/media/upload", formData);
    return _.get(response, "ox_standard.ox_identification.cr_code", null);
  }

  async function alterMemberRole(memberCode, roleCode) {
    try {
      if (!roleCode)
        return toast('Selecione o novo cargo do membro para continuar', { ...toastDefaultOptions, autoClose: 6000 });

      setLoadingProgress(50);

      const body = {
        memberCode,
        groupCode,
        roleCode
      };

      const response = await gateway.put(`/webbff/lit_app_web/social/alterRole`, body);

      await loadGroupInfo();

      setLoadingProgress(100);
      showToast(response);
    } catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function loadMoreCommunications() {
    try {
      setLoadingProgress(50);

      const response = await gateway.get(`/webbff/lit_app_web/social/getCommunications/${groupCode}?page=${communicationPage}&limit=5`)

      setCommunicationPage(communicationPage + 1);

      setCommunications([...communications, ..._.get(response, 'docs', [])]);
      setHasMoreCommunications(_.get(response, 'hasNextPage', false));
      setLoadingProgress(100);
    }
    catch (error) {
      setLoadingProgress(100);
    }
  }

  async function removeMember(memberCode) {
    try {
      setLoadingProgress(50);

      const body = {
        memberCode: memberCode,
        groupCode
      };

      const response = await gateway.put(`/webbff/lit_app_web/social/removeMember`, body);

      setMembers(_.get(response, 'updatedMembersList.groupRoles', []));
      setTotalMembers(_.get(response, 'updatedMembersList.totalMembers', 0));

      setLoadingProgress(100);
      showToast(response);
    } catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function transferGroupPossession(newGroupOwnerCode) {
    try {
      setLoadingProgress(50);

      const body = {
        groupCode,
        newGroupOwnerCode,
        ownerRoleCode: _.get(memberDetail, 'role', ''),
      };

      const response = await gateway.put(`/webbff/lit_app_web/social/transferGroupOwnsership`, body);

      await loadGroupInfo();

      setLoadingProgress(100);
      showToast(response);
    } catch (error) {
      setLoadingProgress(100);
      showToast({ message: 'Não foi possível transferir a posse do grupo, o membro referenciado não é um moderador' }, true);
    }
  }

  async function deleteCommunication(communicationId) {
    try {
      setLoadingProgress(50);
      const body = {
        communicationId,
        organizationCode: groupCode
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/deleteCommunication`, body);

      setCommunications(_.get(response, 'docs', []));

      setLoadingProgress(100);
      showToast(response);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function inviteMember(profileCode) {
    try {
      setLoadingProgress(50);

      const body = {
        groupCode,
        invitedProfileCode: profileCode,
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/inviteMember`, body);

      showToast(response);
      setLoadingProgress(100);
    } catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function searchMembers(name) {
    try {
      const response = await gateway.post(`/webbff/lit_app_web/social/searchUsers?limit=20`, { name, groupCode });
      setProfiles(response);
      setSearchUserPage(1);
    }
    catch (error) {
      showToast(error, true);
    }
  }

  async function searchMoreMembers(name) {
    try {
      const response = await gateway.post(`/webbff/lit_app_web/social/searchUsers?page=${searchUserPage}&limit=20`, { name, groupCode });
      setProfiles([...profiles, ...response]);
      setSearchUserPage(searchUserPage + 1);
    }
    catch (error) {
      showToast(error, true);
    }
  }

  async function acceptJoinSolicitation(solicitationCode, memberCode) {
    try {
      setLoadingProgress(50);

      const body = {
        groupCode,
        recommendationCode: solicitationCode,
        memberCode: memberCode,
      };

      const response = await gateway.put(`/webbff/lit_app_web/social/acceptJoinSolicitation`, body);

      setMembers(_.get(response, 'groupedMembers.groupRoles', []));
      setTotalMembers(_.get(response, 'groupedMembers.totalMembers', 0));
      setSolicitations(_.get(response, 'solicitations', []));

      setLoadingProgress(100);
      showToast(response);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function declineJoinSolicitation(solicitationCode) {
    try {
      setLoadingProgress(50);

      const body = {
        groupCode,
        recommendationCode: solicitationCode,
      };

      const response = await gateway.put(`/webbff/lit_app_web/social/declineJoinSolicitation`, body);

      setSolicitations(_.get(response, 'solicitations', []));

      setLoadingProgress(100);
      showToast(response);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function cancelSolicitation() {
    try {
      setLoadingProgress(50);

      const body = {
        groupCode,
        action: 'REFUSE'
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/manageJoinSolicitation`, body);

      await loadGroupInfo();

      setLoadingProgress(100);
      showToast(response);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  function handleImageChange(event) {
    setChangeImage(event.target.files[0]);
  }

  function solicitationPending() {
    toast("Solicitação pendente, aguarde aprovação", { ...toastDefaultOptions, autoClose: 6000 });
  }

  function leaveGroupAdministrator() {
    toast("É necessário transferir a posse do grupo para poder sair", { ...toastDefaultOptions, autoClose: 6000 });
  }

  async function updateGroup(groupInfo) {
    try {
      setLoadingProgress(50);

      const response = await gateway.put(`/webbff/lit_app_web/social/editGroup`, groupInfo);

      await loadGroupInfo();

      setEditGroupModalIsOpen(false);
      setLoadingProgress(100);
      showToast(response);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  useEffect(() => {
    loadGroupInfo();
    loadGroupRoles();
    loadAreasOfInterest();
    loadReactionsTypes();
  }, []);


  return (
    <>
      <Screen
        progress={loadingProgress}
        infoPage={infoPage}
        group={group}
        member={memberDetail}
        members={members}
        isMember={isMember}
        groupRoles={groupRoles}
        isAdministrator={isAdministrator}
        leaveGroupAdministrator={leaveGroupAdministrator}
        isSolicitationPending={isSolicitationPending}
        solicitationPending={solicitationPending}
        groupInformation={groupInformation}
        communications={communications}
        optionsPage={optionsPage}
        successImage={successImage}
        changeImage={changeImage}
        areasOfInterest={areasOfInterest}
        membersMockList={profiles}
        profiles={profiles}
        leaveGroup={leaveGroup}
        joinGroup={joinGroup}
        openJoinModal={openJoinModal}
        closeJoinModal={closeJoinModal}
        modalJoinIsOpen={modalJoinIsOpen}
        updatePost={updatePost}
        createComment={createComment}
        createPost={createPost}
        deleteCommunication={deleteCommunication}
        removeMember={removeMember}
        alterMemberRole={alterMemberRole}
        transferGroupPossession={transferGroupPossession}
        inviteMember={inviteMember}
        searchMembers={searchMembers}
        handleImageChange={handleImageChange}
        updateGroup={updateGroup}
        acceptJoinSolicitation={acceptJoinSolicitation}
        declineJoinSolicitation={declineJoinSolicitation}
        editGroupModalIsOpen={editGroupModalIsOpen}
        editGroupModalHandleOpen={setEditGroupModalIsOpen}
        inviteMemberModalIsOpen={inviteMemberModalIsOpen}
        inviteMemberModalHandleOpen={setInviteMemberModalIsOpen}
        manageMembersModalIsOpen={manageMembersModalIsOpen}
        manageMembersModalHandleOpen={setManageMembersModalIsOpen}
        solicitations={solicitations}
        totalMembers={totalMembers}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        cancelSolicitation={cancelSolicitation}
        visibility={visibility}
        loadCommunicationReactions={loadCommunicationReactions}
        loadReactionPeople={loadReactionPeople}
        loadMoreSolicitations={loadMoreSolicitations}
        loadMoreCommunications={loadMoreCommunications}
        reactionsTypes={reactionsTypes}
        updateCommunicationReactions={updateCommunicationReactions}
        hasMoreSolicitaions={hasMoreSolicitaions}
        hasMoreCommunications={hasMoreCommunications}
        searchMoreMembers={searchMoreMembers}
      />
    </>
  );
};
