import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../../constants";

/**
 * @param {Array} notifications //loads notification data for the current user (logged in)
 * @param {Array} feedItems //loads data from display components (cards) of the feed
 * @param {Boolean} hasNextPage //function that checks if there is more content to be loaded
 * @param {Function} nextPage //function that loads content on the scroll
 **/

export default ({
  notifications = DEFAULT_VALUES.LIST,
  feedItems = DEFAULT_VALUES.LIST,
  hasNextPage = DEFAULT_VALUES.BOOLEAN,
  nextPage = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <Screen
        notifications={notifications}
        feedItems={feedItems}
        hasNextPage={hasNextPage}
        nextPage={nextPage}
      />
    </>
  );
};
