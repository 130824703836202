import styled from "styled-components";

export const LoginBox = styled.div`
  padding: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 100%;
  height: 100vh;
`;

export const Wrapper = styled.div`
  max-width: 100%;
`;

export const TextH3Header = styled.h3`
  color: #02c4fd;
`;

export const LinkLogin = styled.button`
  color: #02c4fd;
  text-decoration: underline;
  border: none;
  background: none !important;
  cursor: pointer;
`;
