import React, { useMemo, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { ContainerChart } from "./styles";

function IndividualChart({ data = [], forumData = [] }) {
  const [dataForums, setDataForum] = useState([]);

  useEffect(() => {
    const values = data.length > 0 ? data : forumData;
    const formatedForumsData = values.map(item => {
      const foundForum = forumData.find(forum => forum.label === item.label) 
      if(foundForum) {
        return (foundForum.value / 10).toFixed(1);
      }
      return 0;
    });
    setDataForum(formatedForumsData)
  }, [data, forumData]);
 
  const dataChart = useMemo(() => {
    let values = data.length > 0 ? data : forumData;
    values = values.reduce((acc, current) => {
      const item = acc.find(el => el.title === current.title);

      if (!item) {
        acc.push(current);
      }

      return acc;
    }, []);

    return {
      labels: values.map(item => item.title),
      legends: values.map(item => item.label)
    };
  }, [data, forumData]);

  const dataUcs = () => {

    const datasets = [
      {
        label: "Avaliação Final",
        backgroundColor: "#D7DF23",
        borderColor: "#D7DF23",
        borderWidth: 1,
        stack: "2",
        hoverBackgroundColor: "#D7DF23",
        hoverBorderColor: "#D7DF23",
        data: data.map(item => (item.value / 10).toFixed(1))
      }
    ]

    if(forumData && forumData.length > 0) {
      datasets.push(
        {
          label: "Fórum",
          backgroundColor: "#27aae1",
          borderColor: "#27aae1",
          borderWidth: 1,
          stack: "1",
          hoverBackgroundColor: "#27aae1",
          hoverBorderColor: "#27aae1",
    
          data: dataForums
        }
      )
    }

    return {
      labels: dataChart.labels,
      datasets
    };
  };

  const opt = {
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            max: 10
          }
        }
      ]
    }
  };

  return (
    <ContainerChart>
      <h5 className="text-center">Notas por Módulos</h5>
      <Bar data={dataUcs} options={opt} />
      <legend className="ml-3">
        {dataChart.labels.map((item, index) => (
          <div style={{ fontSize: 13 }} key={item}>
            <b>{item}</b>
            <p
              style={{ display: "inline" }}
            >{`: ${dataChart.legends[index]}`}</p>
          </div>
        ))}
      </legend>
    </ContainerChart>
  );
}

export default IndividualChart;
