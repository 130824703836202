import React from "react"
import spinner from "../../images/loading.svg"
import "./Loading.css"

const Loading = ({ img, width, height, message = 'Carregando' }) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      {!img ? <span className="mb-2">{message}</span> : null}
      <img
        src={spinner}
        alt="Carregando"
        className="loading"
        width={width || "auto"}
        height={height || "auto"}
      />
    </div>
  )
}

export default Loading
