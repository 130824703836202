import React from "react";
import { DropdownItem } from "./styles";

export default ({ action, option }) => {
  return (
    <>
      <DropdownItem onClick={action}>{option}</DropdownItem>
    </>
  );
};
