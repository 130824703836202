import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Simplert from 'react-simplert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Creators as ProfileActions } from '../../store/ducks/user/profile/actions';
import { Creators as OnboardingActions } from '../../store/ducks/onboarding/actions';
import { estados } from '../../utils/constants';
import AvatarUsuarioLogado from '../AvatarUsuarios/AvatarUsuarioLogado';
import {
  maskPhone,
  generateSecurePassword,
  toastErrorAndWarningOptions,
  toastDefaultOptions
} from '../../utils/functions';
import RequiredSpan from '../RequiredSpan';
import Onboarding from '../Onboarding';
import ModalProfile from './components/ModalProfile';
import OnboardingSidebar from '../Onboarding/components/OnboardingSidebar';

import api, { gateway } from '../../utils/api';

class LeftSideBar extends Component {
  constructor(props) {
    super(props);
    const { perfil } = this.props;
    this.state = {
      passChangeEmail: localStorage.getItem('ra'),
      passChangeSenhaAtual: '',
      passChangeSenhaNew: '',
      passChangeSenhaConfirm: '',
      modalIsOpen: false,
      enableSocialName: perfil.enableSocialName || false,
      idProfile: perfil.idProfile || '',
      firstName: perfil.firstName || '',
      socialName: perfil.socialName || '',
      lastName: perfil.lastName || '',
      gender: perfil.gender || '',
      phoneNumber: perfil.phoneNumber || '',
      cellphoneNumber: perfil.cellphoneNumber || '',
      address: perfil.address || '',
      numero: perfil.addressNumber || '',
      complemento: perfil.addressAddOn || '',
      neighborhood: perfil.neighborhood || '',
      city: perfil.city || '',
      country: perfil.country || '',
      uf: perfil.uf || '',
      zipCode: perfil.zipCode || '',
      about: perfil.about || '',
      citizenship: perfil.citizenship || '',
      provider: '',
      loading: false,
      privacidades: {},
      isValidatePass: true,
      editAboutLenght: 0,
      modalOnboarding: false
    };
  }

  componentDidMount() {
    this.searchProfiles();
  }

  searchProfiles = () => {
    const { getProfileRequest } = this.props;
    const idProfile = localStorage.getItem('idProfile');
    getProfileRequest(Number(idProfile));

    const { idProfile: id } = this.state;
    if (id) {
      this.getPrivacidade();
    }
  };

  componentWillReceiveProps(nextProps) {
    const { perfil: prevPerfil } = this.props;

    const { perfil } = nextProps;

    if (perfil !== prevPerfil) {
      this.setState(
        {
          idProfile: perfil.idProfile || '',
          firstName: perfil.firstName || '',
          lastName: perfil.lastName || '',
          socialName: perfil.socialName || '',
          enableSocialName: perfil.enableSocialName || false,
          gender: perfil.gender || '',
          phoneNumber: perfil.phoneNumber || '',
          cellphoneNumber: perfil.cellphoneNumber || '',
          address: perfil.address || '',
          numero: perfil.addressNumber || '',
          complemento: perfil.addressAddOn || '',
          neighborhood: perfil.neighborhood || '',
          city: perfil.city || '',
          country: perfil.country || '',
          uf: perfil.uf || '',
          zipCode: perfil.zipCode || '',
          about: perfil.about || '',
          citizenship: perfil.citizenship || ''
        },
        this.getPrivacidade
      );
    }
  }

  handleSelectChange = e => {
    const { privacidades } = this.state;
    privacidades[e.target.id] = e.target.value === 'true';
    this.setState({
      privacidades
    });
  };

  handleCheckboxChange = e => {
    this.setState({
      enableSocialName: e.target.checked,
    });
  };

  handleCepChange = evt => {
    const value = evt.target.value.slice(0, 8);
    this.setState({ zipCode: value });
  };

  searchCEP = async () => {
    try {
      const { zipCode } = this.state;
      const response = await axios.get(
        `https://viacep.com.br/ws/${zipCode}/json/`
      );

      this.setState({
        neighborhood: response.data.bairro || '',
        city: response.data.localidade || '',
        address: response.data.logradouro || '',
        uf: response.data.uf || '',
        country: 'Brasil'
      });

      this.inputNumero.focus();
      this.setState({ erroCep: false });
    } catch (error) {
      this.setState({ erroCep: true });
      console.log(error);
    }
  };

  searchEnterCEP = e => {
    if (e.keyCode === 13 && e.target.value) {
      this.searchCEP();
    }
  };

  onSubmit = async () => {
    this.setState({ loading: true });
    const { getProfileRequest, perfil } = this.props;
    const {
      firstName,
      lastName,
      gender,
      about,
      socialName,
      enableSocialName,
      citizenship,
      phoneNumber,
      cellphoneNumber,
      zipCode,
      address,
      numero,
      complemento,
      neighborhood,
      city,
      uf,
      country
    } = this.state;
    const data = {
      ...perfil,
      about,
      firstName,
      lastName,
      socialName,
      enableSocialName,
      gender,
      citizenship,
      phoneNumber,
      cellphoneNumber,
      zipCode,
      address,
      addressNumber: numero,
      addressAddOn: complemento,
      neighborhood,
      city,
      uf,
      country
    };
    try {
      await gateway.put('/webbff/lit_app_web/person/updatePerson', data)
      getProfileRequest(perfil.idProfile);
      this.setPrivacys();
      this.setState({ modalIsOpen: false, loading: false, alert: false });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        ...toastErrorAndWarningOptions,
        closeButton: false
      });
    }
  };

  setPrivacys = async () => {
    const { privacidades } = this.state;
    try {
      const data = {
        ...privacidades
      };
      const response = await gateway.put('/webbff/lit_app_web/profile/setPrivacyByIdProfile', data);
      this.setState({ privacidades: response });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        ...toastErrorAndWarningOptions,
        closeButton: false
      })
    } finally {
      this.searchProfiles();
    }
  };

  validateFormPessoal = () => {
    const { firstName, lastName, citizenship, gender } = this.state;
    if (firstName.trim() === '') return 'Por favor preencha o campo Nome';
    if (lastName.trim() === '') return 'Por favor preencha o campo Sobrenome';
    if (citizenship.trim() === '')
      return 'Por favor preencha o campo Nacionalidade';
    if (gender.trim() === '') return 'Por favor preencha o campo Gênero';
    return null;
  };

  validateFormContato = () => {
    const { phoneNumber } = this.state;
    if (phoneNumber.trim() === '')
      return 'Por favor preencha o campo de telefone';
    return null;
  };

  validateFormEndereco = () => {
    const {
      erroCep,
      zipCode,
      address,
      numero,
      neighborhood,
      city,
      uf,
      country
    } = this.state;
    if (erroCep) return 'Cep incorreto, preencha um CEP válido';
    if (zipCode.trim() === '') return 'Por favor preencha o campo CEP';
    if (address.trim() === '') return 'Por favor preencha o campo endereço';
    if (numero.trim() === '') return 'Por favor preencha o campo número';
    if (neighborhood.trim() === '') return 'Por favor preencha o campo bairro';
    if (city.trim() === '') return 'Por favor preencha o campo cidade';
    if (uf.trim() === '') return 'Por favor preencha o campo estado';
    if (country.trim() === '') return 'Por favor preencha o campo país';
    return null;
  };

  getPrivacidade = async () => {
    try {
      const { idProfile } = this.state;
      const response = await gateway.get(`/webbff/lit_app_web/profile/getPrivacyByIdProfile/${idProfile}`);

      this.setState({ privacidades: response });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        ...toastErrorAndWarningOptions,
        closeButton: false
      });
    }
  };

  trocarSenha = async () => {
    const {
      passChangeEmail,
      passChangeSenhaNew,
      passChangeSenhaAtual,
      passChangeSenhaConfirm
    } = this.state;

    if (
      passChangeSenhaAtual === '' ||
      passChangeSenhaNew === '' ||
      passChangeSenhaNew !== passChangeSenhaConfirm
    ) {
      this.setState({
        passChangeSenhaConfirm: ''
      });
      alert('Erro encontrado!');
      return;
    }
    try {
      const data = {
        email: passChangeEmail,
        newPassword: generateSecurePassword(passChangeSenhaNew),
        oldPassword: generateSecurePassword(passChangeSenhaAtual)
      };
      this.setState({ loading: true });
      const response = await gateway.post(
        '/user/changePasswordCheckingOld',
        data
      );
      this.setState({
        passChangeSenhaNew: '',
        passChangeSenhaAtual: '',
        passChangeSenhaConfirm: ''
      });
      toast.success(response.message, {
        ...toastDefaultOptions,
        closeButton: false
      });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      toast.error('Verifique se a senha atual foi digitada corretamente', {
        ...toastErrorAndWarningOptions,
        closeButton: false
      });
    }
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openOboarding = () => {
    this.setState({ modalOnboarding: true });
  };

  closeOboarding = () => {
    this.setState({ modalOnboarding: false });
  };

  handleSocialInfos = social => {
    console.log('social', social);

    this.setState({
      modalIsOpen: true,
      alert: true,
      firstName: social.profile.firstName,
      lastName: social.profile.lastName,
      linkPhoto: social.profile.linkPhoto,
      provider: social.platform
    });
  };

  handleSocialError = err => {
    console.log('social', err);
  };

  handleChange = evt => {
    let isValidatePass = true;
    const {
      target: { value, name }
    } = evt;

    // Valida a senha
    if (name === 'passChangeSenhaNew') {
      if (value.length >= 0 && value.length <= 6) {
        isValidatePass = false;
      } else {
        isValidatePass = true;
      }
    }

    this.setState({
      [name]: value,
      isValidatePass,
      editAboutLenght: value.length
    });
  };

  changeGender = event => {
    this.setState({ gender: event.target.value });
  };

  render() {
    const {
      firstName,
      lastName,
      gender,
      about,
      citizenship,
      phoneNumber,
      cellphoneNumber,
      socialName,
      zipCode,
      address,
      numero,
      complemento,
      neighborhood,
      city,
      uf,
      country,
      modalIsOpen,
      modalOnboarding,
      privacidades,
      passChangeEmail,
      passChangeSenhaNew,
      passChangeSenhaAtual,
      passChangeSenhaConfirm,
      loading,
      alert,
      provider,
      isValidatePass,
      editAboutLenght
    } = this.state;
    return (
      <div id="perfil-infos" className="container-fluid">
        <div className="row bg-white-perfil">
          <div className="col-12 perfil-infos">
            <AvatarUsuarioLogado />

            <div className="row text-center">
              <div className="col-12">
                <button
                  style={{ cursor: 'pointer' }}
                  className="btn btn-blue margint-10 btnEdit-perfilAluno btn-FontWeight6"
                  type="button"
                  onClick={this.openModal}
                  data-tip="Edite seu perfil, dados pessoais, privacidade e troque sua senha"
                >
                  Editar acesso
                </button>
                <br />
                <br />
                <br />
                {<OnboardingSidebar isVisibleText={false} />}
                <button
                  style={{ cursor: 'pointer' }}
                  className="btn btn-blue margint-10 btnEdit-perfilAluno btn-FontWeight6"
                  type="button"
                  onClick={this.openOboarding}
                  data-tip="Edite seu perfil, dados pessoais, privacidade e troque sua senha"
                >
                  Visualizar
                </button>
                <Modal
                  open={modalIsOpen}
                  onClose={this.closeModal}
                  classNames={{ modal: 'lit-perfil-form' }}
                  closeOnOverlayClick={false}
                >
                  <div className="modal-content">
                    <div className="modal-header" style={{ padding: 0 }}>
                      <ul className="nav nav-tabs" id="abaModal" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active text-sm-center"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="true"
                          >
                            Perfil
                            {this.validateFormPessoal() !== null ? (
                              <span style={{ color: 'red' }}>*</span>
                            ) : (
                              ''
                            )}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-sm-center"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#personal"
                            role="tab"
                            aria-controls="personal"
                            aria-selected="false"
                          >
                            Dados pessoais
                            {this.validateFormEndereco() !== null ||
                              this.validateFormContato() != null ? (
                              <span style={{ color: 'red' }}>*</span>
                            ) : (
                              ''
                            )}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-sm-center"
                            id="privacidade-tab"
                            data-toggle="tab"
                            href="#privacidade"
                            role="tab"
                            aria-controls="privacidade"
                            aria-selected="false"
                          >
                            Privacidade
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-sm-center"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#changepass"
                            role="tab"
                            aria-controls="changepass"
                            aria-selected="false"
                          >
                            Trocar senha
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="modal-body">
                      <div className="tab-content" id="abaModalContent">
                        {/* Panel de perfil */}
                        <div
                          className="tab-pane fade show active"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <section>
                            <div className="profile-inner">
                              <div className="profile-form-content">
                                <div className="profile-form-inner">
                                  <div className="profile-form-header">
                                    <h4>
                                      <FontAwesomeIcon
                                        icon={['far', 'id-badge']}
                                      />{' '}
                                      Pessoal
                                    </h4>
                                  </div>
                                  {this.validateFormPessoal() !== null ? (
                                    <div className="form-row">
                                      <div className="col">
                                        <p
                                          style={{
                                            color: 'red',
                                            textAlign: 'center',
                                            marginBottom: '0'
                                          }}
                                        >
                                          {this.validateFormPessoal()}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="form-row profile-form-row">
                                    <div className="col">
                                      <label htmlFor="firstName">
                                        Nome <RequiredSpan />
                                      </label>
                                      <input
                                        placeholder="Nome"
                                        required
                                        name="firstName"
                                        value={firstName}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="col">
                                      <label htmlFor="lastName">
                                        Sobrenome <RequiredSpan />
                                      </label>
                                      <input
                                        placeholder="Sobrenome"
                                        required
                                        name="lastName"
                                        value={lastName}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-row">
                                    <div className="col">
                                      <label htmlFor="socialName">
                                        Nome Social
                                      </label>
                                      <input
                                        placeholder="Nome Social"
                                        required
                                        name="socialName"
                                        value={socialName}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div>
                                      <label htmlFor="enableSocialName">
                                        Habilitar Nome Social:
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="enableSocialName"
                                        disabled={!socialName}
                                        checked={this.state.enableSocialName}
                                        onChange={this.handleCheckboxChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-row">
                                    <div className="col">
                                      <label htmlFor="citizenship">
                                        Nacionalidade <RequiredSpan />
                                      </label>
                                      <input
                                        placeholder="Nacionalidade"
                                        required
                                        name="citizenship"
                                        value={citizenship}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="col">
                                      <label htmlFor="gender">
                                        Gênero <RequiredSpan />
                                      </label>
                                      <div className="profile-form-holder profile-select-holder">
                                        <select
                                          required
                                          onChange={this.handleChange}
                                          value={gender}
                                          className="profile-form-control"
                                          name="gender"
                                        >
                                          <option value="" readOnly>
                                            Gênero
                                          </option>
                                          <option value="feminino">
                                            Feminino
                                          </option>
                                          <option value="masculino">
                                            Masculino
                                          </option>
                                          <option value="outro">Outro</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-row">
                                    <div className="col">
                                      <label htmlFor="about">Sobre você</label>
                                      <textarea
                                        placeholder="Resumo sobre você"
                                        rows="7"
                                        value={about}
                                        name="about"
                                        onChange={this.handleChange}
                                        maxLength="1024"
                                      />
                                      <label
                                        style={{
                                          textAlign: 'right',
                                          fontSize: '12px'
                                        }}
                                      >
                                        {editAboutLenght} caracteres de 1024
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          {/* <div className="divider" />

                          <section>
                            <div className="profile-inner">
                              <div className="profile-form-content">
                                <div className="profile-form-inner profile-form-inner-last">
                                  <div className="form-row profile-form-row">
                                    <div className="col-6">
                                      <SocialFacebook
                                        handleSocialLogin={this.handleSocialInfos}
                                        handleSocialError={this.handleSocialError}
                                      >
                                        <button type="button" className="btn btn-sm btn-block false">
                                          <label style={{ cursor: "pointer" }}>
                                            <FontAwesomeIcon
                                              icon={["fab", "facebook-square"]}
                                              color="#3B5998"
                                              className="icon-space"
                                              size="lg"
                                            />
                                            Vincular informações do Facebook
                                          </label>
                                        </button>
                                      </SocialFacebook>
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-row">
                                    <div className="col-6">
                                      <SocialGoogle
                                        handleSocialLogin={this.handleSocialInfos}
                                        handleSocialError={this.handleSocialError}
                                      >
                                        <button type="button" className="btn btn-sm btn-block false">
                                          <label style={{ cursor: "pointer" }}>
                                            <svg
                                              aria-hidden="true"
                                              className="svg-icon native iconGoogle icon-space"
                                              width="17.5"
                                              height="17.5"
                                              viewBox="0 0 18 18"
                                            >
                                              <path
                                                d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z"
                                                fill="#4285F4"
                                              />
                                              <path
                                                d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z"
                                                fill="#34A853"
                                              />
                                              <path
                                                d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z"
                                                fill="#FBBC05"
                                              />
                                              <path
                                                d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z"
                                                fill="#EA4335"
                                              />
                                            </svg>
                                            Vincular informações do Google
                                          </label>
                                        </button>
                                      </SocialGoogle>
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-row">
                                    <div className="col-6">
                                      <SocialLinkedin
                                        onLoginSuccess={this.handleSocialInfos}
                                        onLoginFailure={this.handleSocialError}
                                        shape="block"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section> */}
                        </div>

                        {/* Panel de dados pessoais */}
                        <div
                          className="tab-pane fade"
                          id="personal"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <section>
                            <div className="profile-inner">
                              <div className="profile-form-content">
                                <div className="profile-form-inner">
                                  <div className="profile-form-header">
                                    <h4>
                                      <FontAwesomeIcon icon="phone-alt" />{' '}
                                      Contato
                                    </h4>
                                  </div>
                                  {this.validateFormContato() !== null ? (
                                    <div className="form-row">
                                      <div className="col">
                                        <p
                                          style={{
                                            color: 'red',
                                            textAlign: 'center',
                                            marginBottom: '0'
                                          }}
                                        >
                                          {this.validateFormContato()}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="form-row profile-form-row">
                                    <div className="col-3">
                                      <label htmlFor="phoneNumber">
                                        Telefone <RequiredSpan />
                                      </label>
                                      <input
                                        placeholder="Telefone"
                                        type="tel"
                                        name="phoneNumber"
                                        maxLength="15"
                                        pattern="([0-9]{2})/s?[0-9]{4,5}-[0-9]4"
                                        value={maskPhone(phoneNumber)}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="col-3">
                                      <label htmlFor="cellphoneNumber">
                                        Celular
                                      </label>
                                      <input
                                        placeholder="Celular"
                                        type="tel"
                                        maxLength="15"
                                        pattern="([0-9]{2})/s?[0-9]{4,5}-[0-9]4"
                                        value={maskPhone(cellphoneNumber)}
                                        name="cellphoneNumber"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <div className="divider" />

                          <section>
                            <div className="profile-inner">
                              <div className="profile-form-content">
                                <div className="profile-form-inner">
                                  <div className="profile-form-header">
                                    <h4>
                                      <FontAwesomeIcon
                                        icon={['far', 'address-card']}
                                      />{' '}
                                      Endereço
                                    </h4>
                                  </div>
                                  {this.validateFormEndereco() !== null ? (
                                    <div className="form-row">
                                      <div className="col">
                                        <p
                                          style={{
                                            color: 'red',
                                            textAlign: 'center',
                                            marginBottom: '0'
                                          }}
                                        >
                                          {this.validateFormEndereco()}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="form-row profile-form-row">
                                    <div className="col-4">
                                      <label htmlFor="zipCode">
                                        CEP <RequiredSpan />
                                      </label>
                                      <input
                                        type="number"
                                        placeholder="CEP"
                                        value={zipCode}
                                        onKeyUp={this.searchEnterCEP}
                                        name="zipCode"
                                        onChange={evt =>
                                          this.handleCepChange(evt)
                                        }
                                      />
                                    </div>
                                    <div className="col-2">
                                      <button
                                        type="button"
                                        className="btn btn-block btn-blue"
                                        onClick={this.searchCEP}
                                      >
                                        <i className="fa fa-search" />
                                      </button>
                                    </div>
                                    <div className="col">
                                      <label htmlFor="logradouro">
                                        Logradouro <RequiredSpan />
                                      </label>
                                      <input
                                        placeholder="Logradouro"
                                        name="address"
                                        value={address}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-row">
                                    <div className="col">
                                      <label htmlFor="numero">
                                        Número <RequiredSpan />
                                      </label>
                                      <input
                                        placeholder="Número"
                                        required
                                        ref={input => {
                                          this.inputNumero = input;
                                        }}
                                        value={numero}
                                        name="numero"
                                        onChange={this.handleChange}
                                        maxLength="8"
                                      />
                                    </div>
                                    <div className="col">
                                      <label htmlFor="complemento">
                                        Complemento
                                      </label>
                                      <input
                                        placeholder="Complemento"
                                        required
                                        value={complemento}
                                        name="complemento"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-row">
                                    <div className="col">
                                      <label htmlFor="neighborhood">
                                        Bairro <RequiredSpan />
                                      </label>
                                      <input
                                        placeholder="Bairro"
                                        value={neighborhood}
                                        name="neighborhood"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="col">
                                      <label htmlFor="city">
                                        Cidade <RequiredSpan />
                                      </label>
                                      <input
                                        placeholder="Cidade"
                                        value={city}
                                        name="city"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-row">
                                    <div className="col">
                                      <label htmlFor="states">Estado</label>
                                      <Select
                                        name="states"
                                        onChange={({ value }) => {
                                          this.setState({ uf: value });
                                        }}
                                        value={estados.filter(
                                          ({ value }) => value === uf
                                        )}
                                        getOptionLabel={opt => opt.label}
                                        getOptionValue={opt => opt.value}
                                        options={estados}
                                        placeholder="Estado"
                                        isClearable
                                      />
                                    </div>
                                    <div className="col">
                                      <label htmlFor="country">País</label>
                                      <input
                                        placeholder="País"
                                        value={country}
                                        name="country"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>

                        {/* Panel de privacidade */}
                        <div
                          className="tab-pane fade"
                          id="privacidade"
                          role="tabpanel"
                          aria-labelledby="privacidade-tab"
                        >
                          <section>
                            <div className="profile-inner">
                              <div className="profile-form-content">
                                <div className="profile-form-inner">
                                  <div className="form-row profile-form-row">
                                    <div className="col">
                                      <h5>
                                        <FontAwesomeIcon icon="user-shield" />{' '}
                                        Escolha quais campos os demais
                                        onlearners podem visualizar quando
                                        interagem com você!
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-column">
                                    <div className="col-3">
                                      <label>Sobre: </label>
                                    </div>
                                    <div className="profile-form-holder profile-select-holder">
                                      <select
                                        onChange={this.handleSelectChange}
                                        value={privacidades.about}
                                        className="profile-form-control"
                                        id="about"
                                      >
                                        <option value="true">Público</option>
                                        <option value="false">Privado</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-column">
                                    <div className="col-3">
                                      <label>Acadêmico: </label>
                                    </div>
                                    <div className="profile-form-holder profile-select-holder">
                                      <select
                                        onChange={this.handleSelectChange}
                                        value={privacidades.academic}
                                        className="profile-form-control"
                                        id="academic"
                                      >
                                        <option value="true">Público</option>
                                        <option value="false">Privado</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-column">
                                    <div className="col-3">
                                      <label>Perfil profissional: </label>
                                    </div>
                                    <div className="profile-form-holder profile-select-holder">
                                      <select
                                        onChange={this.handleSelectChange}
                                        value={privacidades.professionalProfile}
                                        className="profile-form-control"
                                        id="professionalProfile"
                                      >
                                        <option value="true">Público</option>
                                        <option value="false">Privado</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-row profile-form-column">
                                    <div className="col-3">
                                      <label>Cargo: </label>
                                    </div>
                                    <div className="profile-form-holder profile-select-holder">
                                      <select
                                        onChange={this.handleSelectChange}
                                        value={privacidades.officeProfile}
                                        className="profile-form-control"
                                        id="officeProfile"
                                      >
                                        <option value="true">Público</option>
                                        <option value="false">Privado</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>

                        {/* Panel de alteração de senha */}
                        <div
                          className="tab-pane fade"
                          id="changepass"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <section>
                            <div className="profile-inner">
                              <div className="profile-form-content">
                                <div className="profile-form-inner">
                                  <div className="profile-form-header">
                                    <h4>
                                      <FontAwesomeIcon icon="user-lock" />{' '}
                                      Trocar senha
                                    </h4>
                                  </div>
                                  <div
                                    className="form-row profile-form-row"
                                    style={{ justifyContent: 'center' }}
                                  >
                                    <div className="col-6">
                                      <label htmlFor="email">Email</label>
                                      <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="E-mail atual"
                                        value={passChangeEmail}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="form-row profile-form-row"
                                    style={{ justifyContent: 'center' }}
                                  >
                                    <div className="col-6">
                                      <label htmlFor="passCurrent">
                                        Senha atual
                                      </label>
                                      <input
                                        type="password"
                                        id="passCurrent"
                                        placeholder="Senha atual"
                                        value={passChangeSenhaAtual}
                                        name="passChangeSenhaAtual"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="form-row profile-form-row"
                                    style={{ justifyContent: 'center' }}
                                  >
                                    <div className="col-6">
                                      <label htmlFor="newPass">
                                        Nova senha
                                      </label>
                                      <input
                                        type="password"
                                        id="newPass"
                                        placeholder="Nova senha"
                                        value={passChangeSenhaNew}
                                        name="passChangeSenhaNew"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="form-row profile-form-row"
                                    style={{ justifyContent: 'center' }}
                                  >
                                    <div className="col-6">
                                      <label htmlFor="newPassConfirm">
                                        Confirmar nova senha
                                      </label>
                                      <input
                                        type="password"
                                        id="newPassConfirm"
                                        placeholder="Confirmar senha"
                                        value={passChangeSenhaConfirm}
                                        name="passChangeSenhaConfirm"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="form-row profile-form-row"
                                    style={{ justifyContent: 'center' }}
                                  >
                                    <div className="col-6">
                                      {passChangeSenhaAtual !== '' &&
                                        passChangeSenhaNew !==
                                        passChangeSenhaConfirm &&
                                        passChangeSenhaConfirm !== '' ? (
                                        <p style={{ color: '#e50000' }}>
                                          As senhas não coincidem
                                        </p>
                                      ) : null}
                                      {!isValidatePass ? (
                                        <p style={{ color: '#e50000' }}>
                                          A senha deve conter no minimo 6
                                          digitos!{' '}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div
                                    className="form-row profile-form-row"
                                    style={{ justifyContent: 'center' }}
                                  >
                                    <div className="col-6">
                                      <button
                                        className={`btn btn-blue btn-block ${loading &&
                                          ' btn-loading'}`}
                                        onClick={this.trocarSenha}
                                        type="button"
                                        disabled={
                                          loading ||
                                          passChangeSenhaAtual === '' ||
                                          passChangeSenhaNew === '' ||
                                          (passChangeSenhaNew !==
                                            passChangeSenhaConfirm &&
                                            passChangeSenhaConfirm !== null)
                                        }
                                      >
                                        Trocar senha
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-modal__cancel"
                        type="button"
                        onClick={this.closeModal}
                      >
                        Cancelar
                      </button>

                      <button
                        type="button"
                        disabled={
                          loading ||
                          this.validateFormEndereco() !== null ||
                          this.validateFormPessoal() !== null ||
                          this.validateFormContato() !== null
                        }
                        className={`btn btn-modal__approve ${loading &&
                          ' btn-loading'}`}
                        onClick={this.onSubmit}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                  <Simplert
                    showSimplert={alert}
                    type="success"
                    title={`Recebemos suas informações do ${provider}`}
                    message="Para aplicá-las ao seu perfil, clique em Salvar"
                    customCloseBtnText="Ok"
                    customCloseBtnClass="btn btn-blue"
                  />
                </Modal>
                <ModalProfile
                  visible={modalOnboarding}
                  onClose={this.closeOboarding}
                  content={<Onboarding notVisibleNavBar />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    perfil: state.user.profile.data
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ProfileActions,
      ...OnboardingActions
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);
