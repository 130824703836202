import React from "react";
import { APPEARANCE, POSITION, COLOR, REACTIONS } from "../../../constants";
import Actions from "../../Actions";
import {} from "./styles";

export default ({ useful }) => {
  function validateLabel(value) {
    switch (value) {
      case true:
        return REACTIONS.ACT_CARD.LABEL_LIKE;
      case false:
        return REACTIONS.ACT_CARD.LABEL_GRR;
      default:
        return REACTIONS.ACT_CARD.LABEL_REACTION;
    }
  }

  function iconLike(value) {
    switch (value) {
      case true:
        return REACTIONS.ACT_CARD.ICON_ACTIVE_LIKE;
      case false:
        return REACTIONS.ACT_CARD.ICON_LIKE;
      default:
        return REACTIONS.ACT_CARD.ICON_LIKE;
    }
  }

  function iconDislike(value) {
    switch (value) {
      case true:
        return REACTIONS.ACT_CARD.ICON_GRR;
      case false:
        return REACTIONS.ACT_CARD.ICON_ACTIVE_GRR;
      default:
        return REACTIONS.ACT_CARD.ICON_GRR;
    }
  }

  return (
    <>
      <Actions
        position={POSITION.RIGHT}
        itemsToRender={[
          {
            type: "button",
            icon: iconDislike(useful),
            color: COLOR.TEXT_PRIMARY,
            appearance: APPEARANCE.CARD_REACTION,
            position: POSITION.LEFT,
            action: () => {}
          },
          {
            type: "button",
            icon: iconLike(useful),
            color: COLOR.TEXT_PRIMARY,
            buttonText: validateLabel(useful),
            appearance: APPEARANCE.CARD_REACTION,
            position: POSITION.LEFT,
            action: () => {}
          }
        ]}
      />
    </>
  );
};
