import React from "react";
import Text from "../../basicComponents/Text";
import { TextArea } from "./styles";

export default ({
  size,
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur
}) => {
  return (
    <>
      {label && <Text text={label} />}

      <TextArea
        size={size}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      >
        {value}
      </TextArea>
    </>
  );
};
