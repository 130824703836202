import React, { useEffect, useState, useCallback } from "react";
import _ from 'lodash';
import NavBarContext from "./NavBarContext"
import { gateway } from '../../utils/api';

const NavBarProvider = ({children}) => {

  const [token, setToken] = useState(localStorage.getItem('@Lit/token'));
    const [showLinkBoletos, setShowLinkBoletos] = useState(false)
    const [showRecommendationPlanAlert, setShowRecommendationPlanAlert] = useState(false)
    const [hasExpiredOrNearExpirationInvoice, setHasExpiredOrNearExpirationInvoice] = useState(false)
    const [showMyInvoicesButton, setShowMyInvoicesButton] = useState(false)
    const [tenantName, setTenantName] = useState('')
    const [tenancy, setTenancy] = useState([])
    const [showGenericAlert, setShowGenericAlert] = useState(false)
    const [showModalRegisterMobilePassword, setShowModalRegisterMobilePassword] = useState(false)

    const setTenancyName = () => {
      const tenancyJSON = localStorage.getItem('tenancy');
      let tenancys = [];
    
      if (tenancyJSON !== null && typeof tenancyJSON !== 'undefined') {
        try {
          tenancys = JSON.parse(tenancyJSON);
        } catch (error) {
          console.error('Erro ao analisar JSON em tenancy:', error);
        }
      } else {
        
        tenancys = tenancy;
      }
    
      const tenantToken = localStorage.getItem('tenant');
      const tenancyFound = tenancys.find(
        tenancyItem => tenancyItem.tenancy === tenantToken
      );
    
      if (tenancyFound) {
        setTenantName(tenancyFound.name);
      } else {
        console.error('Tenancy não encontrada ou não tem a propriedade "name".');
      }
    }
    

    const fetchOrdersPayments = async () => {
        try {
            const email = localStorage.getItem('ra');
            const response = await gateway.post(
              '/webbff/lit_app_web/payment/invoice/getUserInvoices',
              {
                ownerEmail: email
              }
            );

            if (response.length > 0) {
                setShowLinkBoletos(true)
            }
          } catch (error) {
            console.error(error);
            setShowLinkBoletos(false)
          }
    }

    const getRecommendationByPlan = async () => {
      try {
        const response = await gateway(
          'recommendation/recommendation/recommendations-by-plans'
        );
        let freeLicenses = 0;
        let quantityRecommended = 0;
        response.map(data => {
          freeLicenses += data.freeLicenses;
          quantityRecommended += data.recommendations.length;
        });
        if (freeLicenses > quantityRecommended) {
          setShowRecommendationPlanAlert(true)
        }
      } catch (error) {
        console.error(error);
        setShowRecommendationPlanAlert(false)
      }
    }

    const consultUserInvoiceHistory = async () => {
      try {
        const response = await gateway(
          '/webbff/lit_app_web/payment/invoice/history?detailedHistory=false'
        );
        setHasExpiredOrNearExpirationInvoice(response.hasExpiredOrNearExpirationInvoice)
        setShowMyInvoicesButton(response.hasInvoicePayments)

      } catch (error) {
        console.error(error);
        setHasExpiredOrNearExpirationInvoice(false)
        setShowMyInvoicesButton(false)
      }
    }

    const fetchTenancy = async () => {
      try {
        const response = await gateway('/webbff/shared/tenancy/profiles');
        const responseOrder = _.sortBy(response, o => o.tenancy.toLowerCase());
        localStorage.setItem('tenancy', JSON.stringify(responseOrder));
        setTenancy(responseOrder)
        setTenancyName();

      } catch (error) {
        console.error(error);
      }
    }

    const fetchAlertExpiredOrNearDue = async () => {
      try {
        const response = await gateway.get('/webbff/lit_app_web/payment/invoice/isOpenInvoices?withDiffInDays=true');
        setShowGenericAlert(response)
      } catch (error) {
        console.log('Erro ao buscar notificação');
      }
    };

    const openModalRegisterMobilePassword = useCallback(() => {
      setShowModalRegisterMobilePassword(true)
    }, []);

    const closeModalRegisterMobilePassword = useCallback(() => {
      setShowModalRegisterMobilePassword(false)
    }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newToken = localStorage.getItem('@Lit/token');
      if (newToken !== token) {
        setToken(newToken);
      }
    }, 1000); 

    return () => clearInterval(intervalId);
  }, [token]);


  const fetchData = async () => {
      await Promise.all([
      fetchOrdersPayments(),
      getRecommendationByPlan(),
      consultUserInvoiceHistory(),
      fetchTenancy(),
      fetchAlertExpiredOrNearDue()
      ])
    }
    useEffect(() => {


      window.addEventListener('userLoggedIn', fetchData);

      return () => {
          window.removeEventListener('userLoggedIn', fetchData);
      };
  }, []);
    
    useEffect(() => {
      if (token) {
        fetchData()
      }
    }, [token]);


    return (
        <NavBarContext.Provider 
          value={{
            showLinkBoletos, 
            showRecommendationPlanAlert,
            hasExpiredOrNearExpirationInvoice,
            showMyInvoicesButton,
            tenantName,
            tenancy,
            showGenericAlert,
            openModalRegisterMobilePassword,
            closeModalRegisterMobilePassword,
            showModalRegisterMobilePassword,
            setTenancyName
          }}>
            {children}
        </NavBarContext.Provider>
    )
}



export default NavBarProvider
