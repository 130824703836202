import React from 'react';
import PropTypes from "prop-types";

import * as Styles from './styles';

function Screen() {
  return (
    <Styles.Container>
      <p>Timeline !!!</p>
    </Styles.Container>
  );
}

// Screen.propTypes = {

// };


export default Screen;
