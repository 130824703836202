import React from 'react';
import { GoVerified } from "react-icons/go";

import { Container } from './styles';

function FinishAlert({ type }) {

  if (type === 'EXAM' || type === 'Exame') {
    return (
      <Container>
        <GoVerified />
        <h3>Exercício enviado com sucesso!</h3>
        <p>
          Veja na próxima tela quais questões você acertou e quais errou, com a resolução delas,
          para que você aprenda e acerte nas próximas tentativas. Bons estudos!
        </p>
      </Container>
    )
  }

  if (type ==='SELF_ASSESSMENT') {
    return (
      <Container>
        <GoVerified />
        <h3>Avaliação enviada com sucesso!</h3>
        <p>
          Veja na próxima tela nossa recomendação de quais módulos você poderia pular, pois já tem conhecimento sobre o tema, e quais deve focar.
        </p>
      </Container>
    );
  }

  return (
    <Container>
      <GoVerified />
      <h3>Avaliação enviada com sucesso!</h3>
    </Container>
  );
}

export default FinishAlert;
