import React, { Component } from 'react'

import moment from 'moment'
import 'moment/locale/pt-br'
import { API_URL, headerConfig } from '../../utils/constants'
import TableItens from './TableItens'
import Search from './Search'
import {
	DivTable,
	DivAlertSuccess,
	DivAlertError,
	TextSuccess,
	TextError
} from './styles'

const dadosFake = [
	{
		idLogin: 152,
		nome: 'augusto cesar',
		email: 'augusto.gonzaga2010@gmail.com',
		valor: '99,99',
		desconto: 40,
		valorCobrado: '49,99',
		dataRegistro: '11/01/2017',
		dataCobranca: '10/04/2018',
		Status: true
	},
	{
		idLogin: 122,
		nome: 'augusto cesar',
		email: 'augusto.gonzaga2010@gmail.com',
		valor: '99,99',
		desconto: 40,
		valorCobrado: '49,99',
		dataRegistro: '11/01/2017',
		dataCobranca: '10/04/2018',
		Status: true
	}
]

class EfetuarCobranca extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dateInit: moment(),
			checked: false,
			selectedUsers: [],
			users: [],
			saveSuccess: [],
			saveError: []
		}
	}

	handleSelectedUsers = (
		allSelected,
		individualSelected,
		selectedUser,
		checked
	) => {
		// console.log('allSelected', allSelected)
		// console.log('individualSelected', individualSelected)
		// console.log('selectedUser', selectedUser)
		// console.log('checked', checked)
		let selectedUsers = this.state.selectedUsers
		if (allSelected && !individualSelected) {
			this.state.users.forEach(user => {
				// dadosFake.forEach(user => {
				let index = selectedUsers.indexOf(parseInt(user.idLogin))
				if (index === -1) {
					selectedUsers.push(parseInt(user.idLogin))
				}
			})
		}
		if (!allSelected && !individualSelected) {
			selectedUsers = []
		}
		if (individualSelected) {
			if (checked) {
				selectedUsers.push(parseInt(selectedUser))
				// this.state.users.length ALTERAR QUANDO FOR DINAMICO
				// if (selectedUsers.length === dadosFake.length) {
				if (selectedUsers.length === this.state.users.length) {
					this.checkAll()
				}
			}

			let index = selectedUsers.indexOf(parseInt(selectedUser))
			if (index > -1) {
				selectedUsers.splice(index, 1)
			}
			this.setState({ checked })
		}
		this.setState({ selectedUsers })
	}

	checkAll() {
		this.setState({ checked: !this.state.checked })
		this.handleSelectedUsers(!this.state.checked, false)
	}

	search = () => {
		console.log('object')
	}

	faturarSelecionados = async () => {
		this.setState({ saveSuccess: [], saveError: [] })
		const date = moment(this.state.dateInit).format('YYYY-MM-DD')
		let { saveSuccess, saveError } = this.state
		if (this.state.selectedUsers.length === 0) {
			const url = `${API_URL}doRecurringSchedulePayment/${date}/0`
			console.log(url)
			const promise = await fetch(url, {
				method: 'GET',
				headers: headerConfig.headers
			})
			if (promise.ok) {
				saveSuccess.push(0)
			} else {
				saveError.push(0)
			}
			this.setState({ saveSuccess, saveError })
		}
		this.state.selectedUsers.map(async (user, index) => {
			const url = `${API_URL}doRecurringSchedulePayment/${date}/${user}`
			const promise = await fetch(url, {
				method: 'GET',
				headers: headerConfig.headers
			})
			if (promise.ok) {
				saveSuccess.push(user)
			}
			if (!promise.ok) {
				saveError.push(user)
			}
			// console.log(url)
			// console.log(promise)
			// console.log(saveError)
			// console.log(saveSuccess)
			this.setState({ saveSuccess, saveError })
		})
		setTimeout(() => {
			this.setState({ saveSuccess: [], saveError: [] })
		}, 5000)
	}

	render() {
		return (
			<div>
				<h2>Efetuar Cobrança</h2>
				<Search
					label="Data de faturamento"
					maxDate={moment()}
					selectedDate={this.state.dateInit}
					onChange={date => {
						this.setState({
							dateInit: date
						})
					}}
					onClickSearch={this.search}
				/>
				{this.state.users.length > 0 ? (
					<DivTable>
						{this.state.saveSuccess.length > 0 && (
							<DivAlertSuccess>
								<TextSuccess>
									{`O${this.state.saveSuccess.length > 1 ? 's' : ''} id${
										this.state.saveSuccess.length > 1 ? 's' : ''
									}: ${this.state.saveSuccess.map(id => ` ${id}`)} ${
										this.state.saveSuccess.length > 1 ? 'foram' : 'foi'
									} faturado${
										this.state.saveSuccess.length > 1 ? 's' : ''
									} com sucesso`}
								</TextSuccess>
							</DivAlertSuccess>
						)}

						{this.state.saveError.length > 0 && (
							<DivAlertError>
								<TextError>
									{`O${this.state.saveError.length > 1 ? 's' : ''} id${
										this.state.saveError.length > 1 ? 's' : ''
									}: ${this.state.saveError.map(id => ` ${id}`)} não ${
										this.state.saveError.length > 1 ? 'foram' : 'foi'
									} faturado${this.state.saveError.length > 1 ? 's' : ''}`}
								</TextError>
							</DivAlertError>
						)}
						<table className="table table-striped">
							<thead className="tableHead-voucherTable">
								<tr>
									<th className="timeline-center">
										<input
											onChange={() => this.checkAll()}
											type="checkbox"
											checked={this.state.checked}
										/>
									</th>
									<th className="timeline-center">Id Login</th>
									<th className="timeline-center">Nome</th>
									<th className="timeline-center">E-mail</th>
									<th className="timeline-center">Valor</th>
									<th className="timeline-center">Desconto</th>
									<th className="timeline-center">Valor a ser Cobrado</th>
									<th className="timeline-center">Data Registro</th>
									<th className="timeline-center">Data Cobrança</th>
									<th className="timeline-center">Status</th>
								</tr>
							</thead>
							<tbody className="tableBody-voucherTable">
								{this.state.users.map((dados, index) => {
									return (
										<TableItens
											key={index}
											handleSelectedUsers={this.handleSelectedUsers}
											value={
												this.state.checked ||
												this.state.selectedUsers.includes(dados.idLogin)
											}
											user={dados}
										/>
									)
								})}
							</tbody>
						</table>
					</DivTable>
				) : null}
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<button
						className="btn btn-FontWeight6 btnGerarCupom-createVoucher"
						onClick={this.faturarSelecionados}
					>
						Faturar Selecionados
					</button>
				</div>
			</div>
		)
	}
}

// const mapStateToProps = state => {
// 	return {
// 		allowViewVoucher: state.Vouchers.user.allowViewVoucher
// 	}
// }

// export default connect(mapStateToProps, undefined)(EfetuarCobranca)
export default EfetuarCobranca
