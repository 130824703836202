import styled from 'styled-components';

export const Container = styled.div`

    background-color: ${props => props.isActive ? '#d7f0fc' : 'transparent'} !important;
    color: ${props => props.isActive ? '#27aae1' : 'black'} !important;
    cursor: pointer;
    
`
export const ContentItem = styled.li`
    font-size: 16px;
    padding: 5px 15px;
`
export const ContentItemChild = styled.div`
  cursor: ${props => props.available ? 'pointer' : 'not-allowed'} !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: -8px;
  width: 100%;

  .circle-with-grade{
    font-size: 20px;
    color: white;
    width: 36px !important;
    height: 32px !important;
    border-radius: 27% !important;
  }
`
export const ContainerIcon = styled.div`
    padding: 6px 10px 0px 0px;
    color: ${props => {
      switch(props.status){
       case 'IN PROGRESS':
         return '#838383';
       case 'DONE_REPROVED':
         return '#de1626';
       case 'DONE':
         return '#27aae1';
       default:
         return '#27AAE1'
     }
   }};
    font-size: 18px;
`

export const GradeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;


export const ActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`

export const Numero = styled.span`
  color: ${props => props.approved ? '#3cd99e' : '#de1626'};
  margin-right: 0.5em;
`

export const Text = styled.span`
  color: ${props => {
     switch(props.status){
      case 'IN PROGRESS':
        return '#838383';
      case 'DONE_REPROVED':
        return '#de1626';
      case 'DONE':
        return '#27aae1';
      default:
        return ''
    }
  }};
  margin-right: 15px;
`;

export const Grade = styled.div`
  background: ${props => {
     switch(props.status){
      case 'IN PROGRESS':
        return '#838383';
      case 'DONE_REPROVED':
        return '#de1626';
      case 'DONE':
        return '#27aae1';
      default:
        return ''
    }
  }};
`;
