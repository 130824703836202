import React, { useCallback, useState } from "react";
import Axios from "axios";
import fileDownload from "js-file-download";
import { FaCloudDownloadAlt } from "react-icons/fa";
import _ from "lodash";

import { Container, DropZone, SuccessWrapper } from "./styles";

function Upload({
  renderLabel,
  value,
  index,
  onAnswerChange,
  onDisableActions
}) {

  function downloadMedia(e, media) {
  
    const url = media.path;
    // const filename = media.name;
    const extension = media.path.slice(
      ((media.path.lastIndexOf(".") - 1) >>> 0) + 2
    );

    console.log(url)
    console.log(extension)

    Axios.get(url, {
      responseType: "blob"
    }).then(res => {
      fileDownload(res.data, `arquivo.${extension}`);
    });
  }
  

  return (
    <Container>
      <DropZone>
     
        {value && (
          <SuccessWrapper>
           
            <p onClick={e => downloadMedia(e, value)}>
              <FaCloudDownloadAlt size={20} color="#1890ff" />
           
                {/* <a href={value.path} download target="_blank"> */}
                  {/* {" "} */}
                  Fazer download do arquivo
                {/* </a> */}
           
              
            </p>
           
          </SuccessWrapper>
        )}
      </DropZone>
    </Container>
  );
}

export default Upload;
