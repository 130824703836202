import React from 'react';
import styled from 'styled-components';
import history from '../../utils/history';
import Informacoes from './Informacoes';

const IMG_LIT = require('../../images/lit.png');

const NavLit = styled.nav`
  background-image: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    /* padding: 0; */
    margin: 0;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

function logout() {
  history.push('/logout');
}

const Template = ({ havePlan, children, showHeader = false }) => (
  <div id="pagamento" style={{ overflow: 'hidden' }}>
    {havePlan && (
      <div className="aviso">
        <h5>
          Seu período trial expirou, você precisa realizar o pagamento para
          continuar tendo acesso a todo o conteúdo do LIT
        </h5>
      </div>
    )}
    <NavLit className="navbar navbar-lit navbar-expand-lg bg-gradient-lit">
      <a className="navbar-brand">
        <img src={IMG_LIT} alt="logo_lit" style={{ width: '100px' }} />
      </a>
      <button type="button" onClick={() => logout()}>
        SAIR
      </button>
    </NavLit>
    <div className="container" style={{ maxWidth: 1370 }}>
      <div className="row">
        {showHeader && (
          <div className="col-md-12">
            {/* <BugReport /> */}
            <Informacoes />
          </div>
        )}

        <div id="pagamento-page" className="col-md-12">
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default Template;
