import React, { Component } from "react"
import { ButtonLinks, CpfContainer, Bold, LinkBlue } from "../CreditCardPayment/styles"
import PaymentMethodType from '../../paymentMethodType'
import PlanType from "../../planType"
import ModalTermos from "../../../Modals/ModalTermos";
import CHARGE_MESSAGES from './ENUM_MESSAGES'

class TrialText extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalTerms: false
    }
  }

  getAnualMessage = () => {
    const { paymentImmediate, idTotvsCourses } = this.props.plan.config || {}

    if (paymentImmediate) {
      return (
        <div className="col-md-12">
          <div className="text-center text-light">
            <p style={{ marginBottom: 0 }}>
              {
                idTotvsCourses && idTotvsCourses.length > 0
                  ? CHARGE_MESSAGES.NO_TRIAL_BUYING_COURSE
                  : CHARGE_MESSAGES.IMMEDIATE_CHARGE
              }
            </p>
          </div>
        </div>
      )
    }
  }

  checkRecurrence = () => {
    const { discountValue, discountPercent, config = {} } = this.props.plan || {}

    return this.props.plan &&
      (
        !config.idTotvsCourses ||
        config.idTotvsCourses.length === 0
      ) &&
      discountValue &&
      discountValue > 0 &&
      discountPercent !== 100
  }

  getChargeMessage = () => {
    const { planType, config = {} } = this.props.plan || {}
    const planTypeString = planType === PlanType.Monthly ? CHARGE_MESSAGES.MONTH : CHARGE_MESSAGES.YEAR
    const trialMessage = config.trialDays && config.trialDays > 0 ? CHARGE_MESSAGES.TRIAL_MESSAGE : ''
    const chargeMessages = CHARGE_MESSAGES.AUTHORIZATION_CHARGE

    return (
      <p style={{ marginBottom: 0 }}>
        {chargeMessages[0]}
        {
          this.checkRecurrence() &&
          <>
            {", "}
            <Bold>
              {trialMessage}
              {chargeMessages[1]}
              {planTypeString}
              {chargeMessages[2]}
            </Bold>
          </>
        }
        .
      </p>
    )
  }

  openModal = () => {
    this.setState({ modalTerms: true });
  }

  getTermsAndConditionsMessage = () => (
    <p>
      {CHARGE_MESSAGES.NO_REFUND}
      <ButtonLinks type="button" onClick={() => this.openModal()}>
        {CHARGE_MESSAGES.HERE_LINK}
      </ButtonLinks>
    </p>
  )

  getAnualPlanMessage = () => this.props.selectedPaymentMethod === PaymentMethodType.CREDITO && <p>{CHARGE_MESSAGES.ANUAL_PLAN}</p>

  render() {
    return (
      <>
        <CpfContainer>
          <div className="row justify-content-md-center" style={{ flex: 1 }}>
            <div className="col-md-8 col-12">
              <div className="text-center text-light">
              <p>A cobrança desse plano será feita imediatamente após a confirmação do pagamento. Termos e condições disponíveis aqui.
                No caso do plano de 1 ano e plano de 2 ano, pelo presente, você autoriza o LIT a cobrar de você com recorrência automática no vencimento até que você cancele a sua inscrição. <LinkBlue href="javascript:void(0)" onClick={this.openModal}>Termos e condições disponíveis aqui</LinkBlue>.</p>
                {/* {this.getChargeMessage()}
                {this.getTermsAndConditionsMessage()}
                {this.getAnualPlanMessage()} */}
              </div>
            </div>
            {/* {this.getAnualMessage()} */}
          </div>
        </CpfContainer>
        <ModalTermos
          visible={this.state.modalTerms}
          onClose={() => this.setState({ modalTerms: false })}
        />
      </>
    )
  }
}

export default TrialText
