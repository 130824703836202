import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp, FaRegMoneyBillAlt } from 'react-icons/fa';

import Progress from '../../shared/Progress';

import { Button, ButtonCollapse, BoxInfo, Info } from './styles';

const OrderItem = ({ order, index }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const percent =
    (order.transactionData.paidInstallmentsCount /
      order.transactionData.installmentsCount) *
    100;

  return (
    <div key={`card-${index}`}>
      <div className="card-header" style={{background: '#27aae1'}} id={`heading${index}`}>
        <h5 className="mb-0">
          <ButtonCollapse
            onClick={() =>
              setIsCollapsed(prevState => {
                return !prevState;
              })
            }
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="button"
            className="btn"
            data-toggle="collapse"
            data-target={`#collapse${index}`}
            aria-expanded="true"
            aria-controls={`collapse${index}`}
          >
            <BoxInfo>
              <Info>
                <span>
                  {order.offerTitle}, R$ {order.transactionData.offerPrice}
                </span>
                <span id="parcelas">
                  <FaRegMoneyBillAlt size="20px" />{' '}
                  {`${order.transactionData.paidInstallmentsCount}/${order.transactionData.installmentsCount}`}
                </span>
              </Info>
              <Progress
                background="#D7DF23"
                progress={percent}
                height="8px"
                width="100%"
                showProgress={false}
              />
            </BoxInfo>
            {isCollapsed && <FaAngleUp />}
            {!isCollapsed && <FaAngleDown />}
          </ButtonCollapse>
        </h5>
      </div>

      <div
        id={`collapse${index}`}
        className={`collapse ${index + 1 === 1 && 'show'}`}
        aria-labelledby={`heading${index}`}
        data-parent="#accordion"
      >
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Vencimento</th>
                  <th scope="col">Status</th>
                  <th scope="col">Valor Total</th>
                  <th scope="col"> </th>
                </tr>
              </thead>
              <tbody>
                {order &&
                  order.invoiceData &&
                  order.invoiceData.map((invoice, index) => (
                    <tr key={`list-${index}`}>
                      <td>{invoice.installmentDueDate}</td>
                      <td>
                        {invoice && invoice.installmentIsPaid
                          ? 'Pago'
                          : 'Em aberto'}
                      </td>
                      <td>R$ {invoice.installmentPrice}</td>
                      <td>
                        {invoice.installmentIsPaid && (
                          <button
                            type="button"
                            className="btn btn-icon mb-2 pt-0 pl-0 pb-1 pr-2 ml-auto"
                            data-for="cardTip"
                          >
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              style={{ color: '#27aae1' }}
                            ></i>
                          </button>
                        )}
                        {!invoice.installmentIsPaid && invoice.installmentInvoiceUrl && (
                          <a
                            href={invoice.installmentInvoiceUrl}
                            target="blank"
                            data-tip="Fazer download do boleto de cancelamento."
                            data-for="invoiceTip"
                          >
                            <Button>
                              Visualizar Boleto
                            </Button>
                          </a>
                        )}

                        {!invoice.installmentIsPaid && !invoice.installmentInvoiceUrl && (
                          <p>Disponível para download a partir de {invoice.installmentInvoiceGenerateDate}</p>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
