/* eslint-disable no-alert */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FileBase64 from 'react-file-base64';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Icon from '@material-ui/core/Icon';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import api from '../../utils/api';

import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';

// import DuvidaNota from "./Tabs/DuvidaNota"
// import ErroAoAcessarCurso from "./Tabs/ErroAoAcessarCurso"
// import AcessarNota from "./Tabs/AcessarNota"
// import NaoRecebiCertificado from "./Tabs/NaoRecebiCertificado"
import ComoFuncionaAvalicacao from './Tabs/ComoFuncionaAvalicacao';
// import FuiReprovado from "./Tabs/FuiReprovado"
// import QualValorLIT from "./Tabs/QualValorLIT"
import OndeVejoCursos from './Tabs/OndeVejoCursos';
// import PrecisoPagarCertificado from "./Tabs/PrecisoPagarCertificado"
// import ComoFuncionaMBA from "./Tabs/ComoFuncionaMBA"
import CertificadoLit from './Tabs/CertificadoLit';
import ProblemaDeAcesso from './Tabs/ProblemaDeAcesso';
import AcessarCertificado from './Tabs/AcessarCertificado';
import ParticiparAulaOnline from './Tabs/ParticiparAulaOnline';
import ReprovadoAvalicaoFinal from './Tabs/ReprovadoAvaliacaoFinal';
import PlanoAnualVencimento from './Tabs/PlanoAnualVencimento';
import CancelarAcesso from './Tabs/CancelarAcesso';
import MBASaintPaul from './Tabs/MBASaintPaul';
import TrilhasLIT from './Tabs/TrilhasLIT';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  subtitle: {
    fontWeight: 'bold'
  },
  modalHeader: {
    width: 'auto',
    height: '50px',
    background: 'transparent',
    boxShadow: 'none',
    marginTop: '60px'
  }
};

export default class Ajuda extends Component {
  state = {
    error: null,
    img: null,
    loading: false,
    descricao: '',
    modalIsOpen: false,
    maxWidth: 'xl'
  };

  getFiles = async files => {
    const fileBlob = await this.dataURLtoBlob(files.base64);
    this.setState({ img: fileBlob });
  };

  dataURLtoBlob = async dataurl => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  send = e => {
    this.setState({ loading: true });
    e.preventDefault();
    const { descricao, error, img } = this.state;
    const bugReportResult = {
      desc: descricao,
      error
    };

    const idProfile = localStorage.getItem('idProfile');
    if (img) {
      api
        .post(`newTicket/profile/${idProfile}/file`, bugReportResult)
        .then(res => {
          if (res) {
            if (res.idTicket) {
              api
                .post(`/ticket/file/${res.idTicket}`, img, {
                  headers: { 'Content-Type': 'image/jpeg' }
                })
                .then(res2 => {
                  alert(res2.message);
                  this.setState({
                    descricao: '',
                    error: '',
                    img: null,
                    loading: false
                  });
                })
                .catch(() => {
                  alert('OPS! Falha no envio do anexo!');
                  this.setState({ loading: false });
                });
            }
            alert('Relátorio enviado. Obrigado!');
            this.setState({
              descricao: '',
              error: '',
              img: null,
              loading: false
            });
          }
        })
        .catch(() => {
          alert('Encontramos um problema, por favor, tente novamente!');
          this.setState({ loading: false });
        });
    } else {
      api
        .post(`newTicket/profile/${idProfile}`, bugReportResult)
        .then(() => {
          alert('Relátorio enviado. Obrigado!');
          this.setState({
            descricao: '',
            error: '',
            img: null,
            loading: false
          });
        })
        .catch(() => {
          alert('Encontramos um problema, por favor, tente novamente!');
          this.setState({ loading: false });
        });
    }
  };

  changeVisible = () => {
    const { visibleBody } = this.state;
    this.setState({ visibleBody: !visibleBody });
  };

  showModal = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  render() {
    const { descricao, error, loading, maxWidth, modalIsOpen } = this.state;

    return (
      <div>
        <div>
          <NavBarSuperior home courses />
          <div className="container-fluid" style={{ paddingTop: '40px' }}>
            <div className="row">
              <div className="col-12">
                <div className="container">
                  <h1>TOP 10 dúvidas sobre o LIT</h1>
                  <p style={{ fontSize: '20px' }}>
                    Abaixo temos uma lista das 10 principais dúvidas ou
                    problemas mais comuns entre nossos alunos e alunas. Espero
                    que te ajude!
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="container">
                  <CertificadoLit />
                  <ProblemaDeAcesso />
                  <AcessarCertificado />
                  <OndeVejoCursos />
                  <ComoFuncionaAvalicacao />
                  <TrilhasLIT />
                  <ReprovadoAvalicaoFinal />
                  <PlanoAnualVencimento />
                  <CancelarAcesso />
                  <MBASaintPaul />
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ marginBottom: '30px', marginTop: '30px' }}
            >
              <div className="col-12">
                <div className="container">
                  <h2>Sua dúvida não foi respondida?</h2>
                  <p>
                    Entre em contato com nosso time pelo sac@lit.com.br, que
                    iremos te ajudar de <br />
                    segunda a sexta, em horário comercial.
                  </p>
                  <div>
                    <button
                      id="openModal"
                      className="btn-modal__approve"
                      onClick={this.showModal}
                      type="button"
                    >
                      Enviar minha pergunta
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Dialog
              fullScreen
              maxWidth={maxWidth}
              open={modalIsOpen}
              onClose={this.closeModal}
              TransitionComponent={Transition}
              scroll="body"
              aria-labelledby="max-width-dialog-title"
            >
              <AppBar style={styles.modalHeader}>
                <Toolbar
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Icon
                    style={{ cursor: 'pointer' }}
                    edge="start"
                    color="inherit"
                    onClick={this.closeModal}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </Icon>
                </Toolbar>
              </AppBar>

              <NavBarSuperior />

              <div
                className="modal-content"
                id="ajuda"
                style={{ overflow: 'hidden' }}
              >
                <br />
                <div
                  id="modal-ajuda"
                  className="modal-body"
                  style={{ overflow: 'hidden', marginTop: '30px' }}
                >
                  <div className="step-progress" style={{ overflow: 'hidden' }}>
                    <div className="row">
                      <div className="col-12">
                        {/* <div className="container">
                  <h1>Ajuda</h1>
                </div> */}
                        <div className="container" id="atendimento">
                          <div className="atendimento__container">
                            <div
                              className="col-12 d-flex flex-column"
                              style={{ marginBottom: 30 }}
                            >
                              <h4>Como podemos ajudar?</h4>
                              <div className="row justify-content-around align-items-center">
                                <Link
                                  className="btn btn__atendimento"
                                  to={{
                                    pathname: '/chatPaul',
                                    state: { fromNavBar: true }
                                  }}
                                  data-tip="Informações gerais sobre o LIT, assinatura, planos, requisitos de sistema etc."
                                >
                                  Como funciona o LIT?
                                </Link>
                                <a
                                  className="btn btn__atendimento"
                                  href="https://lit.com.br/cursos-e-trilhas"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  data-tip="Informações sobre avaliações, material didático, critérios de aprovação, certificados, tutoria e inteligência artificial"
                                >
                                  {'Cursos & Trilhas no LIT'}
                                </a>
                                <a
                                  className="btn btn__atendimento"
                                  href="https://lit.com.br/cursos-e-trilhas/mbas"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  data-tip="Todas as informações que você precisa saber para cursar um MBA da Saint Paul por meio do LIT"
                                >
                                  MBAs da Saint Paul no LIT
                                </a>
                                <a
                                  className="btn btn__atendimento"
                                  href="https://lit.com.br/perguntas-frequentes-paul"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  data-tip="Recuperação de senha, emissão de certificados, problemas em um curso ou trilha, ou qualquer outra dificuldade relacionadas à parte técnica do LIT"
                                >
                                  Dificuldades Técnicas
                                </a>
                              </div>
                            </div>

                            <div className="col-12 row justify-content-md-center">
                              <p style={{ margin: 0 }}>
                                Caso não encontre o que procura, por favor
                                preencha os campos do formulário e responderemos
                                o mais breve possível.{' '}
                              </p>
                              <p style={{ maringBottom: 20 }}>
                                Seus comentários, perguntas e sugestões são
                                muito importantes para nós.
                              </p>
                              <form className="col-12" onSubmit={this.send}>
                                <div className="col-12 row">
                                  <div
                                    className="form-group align-items-center col-4"
                                    style={{ paddingLeft: '0' }}
                                  >
                                    <h6 style={{ fontSize: 18 }}>Assunto:</h6>
                                    <select
                                      className="form-control mt-2"
                                      required
                                      name="error"
                                      onFocus={this.handleInputFocus}
                                      value={error}
                                      onChange={evt =>
                                        this.setState({
                                          error: evt.target.value
                                        })
                                      }
                                    >
                                      <option value="DÚVIDA">
                                        Ainda estou com uma dúvida
                                      </option>
                                      <option value="SUGESTÃO">
                                        Enviar uma sugestão
                                      </option>
                                      <option value="ELOGIO">
                                        Enviar um elogio
                                      </option>
                                      <option value="BUG">
                                        Reportar um bug
                                      </option>
                                    </select>
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{ marginLeft: '50px' }}
                                  >
                                    <div
                                      className="row d-flex align-items-center no-gutters"
                                      style={{ height: '50%' }}
                                    >
                                      <h6
                                        style={{
                                          marginBottom: '8px',
                                          fontSize: 18
                                        }}
                                      >
                                        Anexe qualquer imagem ou print de tela
                                        que possa nos ajudar com sua dúvida ou
                                        bug:
                                      </h6>
                                    </div>
                                    <div className="row no-gutters">
                                      <FileBase64
                                        multiple={false}
                                        onDone={this.getFiles}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <textarea
                                  className="form-control mt-2"
                                  required
                                  rows="8"
                                  cols="40"
                                  placeholder="Deixe sua anotações aqui"
                                  value={descricao}
                                  onChange={evt =>
                                    this.setState({
                                      descricao: evt.target.value
                                    })
                                  }
                                />
                                <div
                                  style={{
                                    backgroundColor: '#fff',
                                    padding: '15px'
                                  }}
                                  className="row d-flex justify-content-end"
                                >
                                  <button
                                    id="btnCancel"
                                    className="btn btn-modal__cancel mr-2"
                                    type="button"
                                    onClick={this.closeModal}
                                  >
                                    Voltar
                                  </button>
                                  <button
                                    id="sendMesege"
                                    className={`btn btn-modal__approve float-right${loading &&
                                      ' btn-loading'}`}
                                    disabled={loading}
                                    type="submit"
                                  >
                                    Enviar
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
