import React from "react";
import {
  APPEARANCE,
  FONT_TYPE,
  POSITION,
  SIZE,
  BORDER_TYPE,
  TRANSFORM
} from "../../../constants";

import Text from "../../basicComponents/Text";
import Image from "../../basicComponents/Image";
import Spacing from "../../basicComponents/Spacing";
import { Link } from "react-router-dom";

import { ListItem, Span, ButtonsDiv, Inline } from "./styles";

export default ({
  id,
  name,
  img,
  description,
  solicitationDate,
  role,
  link,
  size,
  color,
  orientation,
  secondImage,
  listButtons,
  actions,
  child,
  style
}) => {
  return (
    <ListItem key={id + name} style={style} orientation={orientation}>
      {link ? (
        <Link to={link}>
          <Image
            src={img}
            appearance={APPEARANCE.AVATAR}
            size={size}
            border={BORDER_TYPE.BORDER}
          />
        </Link>
      ) : (
        <Image
          src={img}
          appearance={APPEARANCE.AVATAR}
          size={size}
          border={BORDER_TYPE.BORDER}
        />
      )}
      {secondImage && (
        <Image
          src={secondImage}
          appearance={APPEARANCE.AVATAR}
          position={POSITION.ABOVE_RIGHT}
          size={size}
          border={BORDER_TYPE.BORDER_LIGHT}
        />
      )}
      {size === SIZE.MEDIUM && <Spacing size={SIZE.SMALL} />}
      <Span color={color} listButtons={listButtons}>
        {size !== SIZE.MEDIUM
          ? [
              <Text
                size={SIZE.SMALL}
                fontType={FONT_TYPE.BOLD}
                text={name}
                transform={TRANSFORM.CAPITALIZE}
              />,
              <Text size={SIZE.SMALL} text={description} />,
              <Text
                size={SIZE.SMALL}
                text={
                  solicitationDate
                    ? `Data da solicitação: ${new Date(
                        solicitationDate
                      ).toLocaleDateString()}`
                    : ""
                }
              />,
              child
            ]
          : [
              link ? (
                <Link to={link}>
                  <Text
                    size={SIZE.LARGE}
                    fontType={FONT_TYPE.BOLD}
                    text={name}
                  />
                </Link>
              ) : (
                <Text size={SIZE.LARGE} fontType={FONT_TYPE.BOLD} text={name} />
              )
            ]}
      </Span>
      <ButtonsDiv>{actions}</ButtonsDiv>
    </ListItem>
  );
};
