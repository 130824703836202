import React from "react";
import { SimpleRow } from "./styles";

export default ({ alignItems, child, orientation, hidden }) => {
  return (
    <>
      <SimpleRow
        alignItems={alignItems}
        orientation={orientation}
        hidden={hidden}
      >
        {child}
      </SimpleRow>
    </>
  );
};
