import styled from 'styled-components';

export const Container = styled.div`
   width: 100%;
  .box + div {
    margin-top: 20px;
  }

  

  .parent {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: auto;
    & + div {
      margin-top: 20px;
    }
    /* padding: 1rem; */
  }
  .slider-container {
  /* if you want to have a scrollbar, you can add overflow here */ 
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem .1rem;
    padding: 0px;
    scroll-behavior: smooth;
    &:hover {
      .prev {
        opacity: 1;
        /* visibility: visible; */
      }
      .next {
        opacity: 1;
        /* visibility: visible; */
      }
    }
  }
  
`;


export const StyleLane = styled.div`
  /* width: 265px; */
  /* margin: 10px; */
  padding: 0 5px;
  & + div {
    /* margin-left: 10px; */
  }
  position: relative;
  text-align: center;
  font-size: 26px;
`

export const Title = styled.h6`
  padding: 0 20px;
`

export const EmptyListText = styled.p`
  margin: 0;
  padding: 0 20px;
`
export const ContentLoadingCoursesTrail = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
