import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ProgressBar from '../ProgressBar';

import * as Styles from './styles';

function SideBarMenuItemCourse({
    tip,
    title,
    handleSelectedItem = null,
    icon,
    iconNumeric = false,
    data,
    showProgress = true,
    progress,
    ...rest
}) {

  return (
    <Styles.TooltipContainer>
       <Styles.Container 
        onClick={() => {
            if(handleSelectedItem && typeof handleSelectedItem === 'function'){
                handleSelectedItem();
            }
        }}
        data-icon-numeric={iconNumeric}
        {...rest}
        >
          <Styles.ContainerIcon>
            {iconNumeric ? <span>{iconNumeric}</span> : <i className={`fa fa-${icon}`}></i>}
          </Styles.ContainerIcon>
          <Styles.ItemDescription>
              {title.length > 30 
                  ? <Tooltip title={title} aria-label="add" placement="top">
                          <span>{title.substring(0,30)} ...</span>
                      </Tooltip>
                  : title
              }
              {showProgress && <ProgressBar data={data} />}
          </Styles.ItemDescription>
      </Styles.Container>
    </Styles.TooltipContainer>
  )
}

export default SideBarMenuItemCourse;
