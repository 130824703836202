import React from "react"
import { Link } from "react-router-dom"
import Img from "react-image"
import placeholder from "../../images/placeholder-professor.png"

const Professor = ({ name, email, idProfile, image }) => {
  return (
    <div className="col-6">
      {idProfile ? (
        <div className="row avatar">
          <Link
            to={{
              pathname: `/perfilProfessor/${idProfile}`,
              state: { email },
            }}
          >
            <Img
              src={[image, placeholder]}
              className="rounded-circle"
              decode={false}
              style={{ maxWidth: 70, maxHeight: 70 }}
            />
          </Link>
          <div className="avatar-body align-self-center">
            <Link
              to={{
                pathname: `/perfilProfessor/${idProfile}`,
                state: { email },
              }}
            >
              <h6>{name}</h6>
            </Link>
          </div>
        </div>
      ) : (
        <div className="row avatar">
          <div>
            <Img
              src={[image, placeholder]}
              className="rounded-circle"
              decode={false}
              style={{ maxWidth: 70, maxHeight: 70 }}
            />
          </div>
          <div className="avatar-body align-self-center">
            <div>
              <h6 style={{color: '#aba7a7'}}>{name}</h6>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Professor
