import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 20px;
  right: 0px;

  width: 350px;
  height: 490px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5px;

  width: 280px;
  margin: 5px auto;
  height: 100%;
  max-width: 100%;
  overflow-y: hidden;

  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  ul {
    max-height: 390px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  }
`;

export const Content = styled.div`
  max-height: 390px;

  li {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-y: auto;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    span {
      padding: 5px 15px;
      border-radius: 20px;
    }
  }

  li.received {
    justify-content: flex-start;
    margin-bottom: 5px;

    img {
      margin-right: 10px;
    }

    span {
      background-color: #42b3c9;
      color: #fff;
    }
  }

  li.sent {
    flex-direction: row-reverse;
    margin-bottom: 5px;

    img {
      margin-left: 10px;
    }

    span {
      background-color: #f3f3f3;
      color: #9fa1a5;
    }
  }
`;

export const BoxMessageEnded = styled.div`
  position: fixed;
  bottom: 30px;
  width: 268px;

  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
`

export const SendMessage = styled.div`
  position: fixed;
  bottom: 30px;
  width: 268px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid #dfdfdf;
  border-radius: 5px;

  textarea {
    flex: 1;
    resize: none;
    border: none;
    outline: none;
  }

  button {
    background-color: transparent;
    color: #27aae1;
    cursor: pointer;
    border: none;
    outline: none;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const CloseBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    flex: 1;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 16px;
    color: #27aae1;
    font-weight: 500;
  }
`;

export const CloseBtn = styled.button`
  background-color: transparent;
  outline: none;
  border: none;

  cursor: pointer;
`;
