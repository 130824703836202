import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} pageImage //path (url) of the image that will be displayed at the top of the modal
 * @param {String} modalHeaderText //text presented in the modal title
 * @param {Boolean} isOpen //informs if the modal is open or not
 * @param {Boolean} handleIsOpen //deforms the status of the modal - open or not
**/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
  modalHeaderText = DEFAULT_VALUES.STRING,
  isOpen,
  handleIsOpen
}) => {
  return (
    <>
      <Screen
        child={children}
        modalHeaderText={modalHeaderText}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
      />
    </>
  );
};
