/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"


class ComoFuncionaAvalicacao extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody} = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                5. Como funcionam as avaliações no LIT?
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                 <p className="no-m-b">
                  Para obter o certificado dos cursos com avaliação é preciso fazer a Avaliação Final. 
                  <br />
                  Caso sua nota final nesta avaliação seja maior ou igual a 7,00 (sete), você conquistará o certificado.
                 </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ComoFuncionaAvalicacao
