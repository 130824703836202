/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import _ from "lodash";
import LaneHoc from "./LaneHoc";

import CardBook from "./CardBook";

import { gateway } from "../../utils/api";

const StyleLivros = {
  margin: 10,
  position: "relative",
  lineHeight: "100px",
  textAlign: "center",
  fontSize: 26,
  minWidth: 200,
  maxWidth: 300,
};

export default function LaneBooks({ data }) {
  const docs = _.get(data, "docs", []);
  const laneCrCode = _.get(data, "lane.id", null);
  const { page, limit } = data;
  const [books, setBooks] = useState(docs);
  const [currentPage, setCurrentPage] = useState(page);

  if (!books.length) return null;

  const getMoreBooks = () => {
    if (currentPage) {
      const queryParams = `?lane=${laneCrCode}&page=${currentPage}&limit=${limit}`;
      const url = `webbff/lit_app_web/home${queryParams}`;
      gateway
        .get(url)
        .then(response => {
          const hasNextPage = _.get(response, "hasNextPage", false);
          const newBooks = _.get(response, "docs", []);

          setCurrentPage(hasNextPage ? currentPage + 1 : null);
          setBooks([...books, ...newBooks]);
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  return (
    <div>
      <LaneHoc
        tooltipText=""
        title="Livros da biblioteca virtual"
        paginationHook={getMoreBooks}
      >
        {books.map(book => {
          return (
            <div style={StyleLivros} key={`${book.id}`}>
              <CardBook book={book} />
            </div>
          );
        })}
      </LaneHoc>
    </div>
  );
}
