import React  from "react";
import Webcam from "react-webcam";
import _ from "lodash";
import { TakePhotoBtn, Title, BoxCamUnavaible } from "../styles";

import { gateway } from "../../../../../../../utils/api";

const WebcamCapture = ({setAnswer, onCloseModal}) => {
  const webcamRef = React.useRef(null);
  const [devices, setDevices] = React.useState([]);

  const capture = React.useCallback(
    async () => {
      const fileBase64 = webcamRef.current.getScreenshot();
      const res = await fetch(fileBase64);
      const fileBlob = await res.blob();
      const file = new File([fileBlob], "file.jpeg", { type: "image/jpeg" });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("mediaType", "FILE_ONBOARDING_RESPONSE");
      const response = await gateway.post("/media/media/upload", formData);
      const urlFile = _.get(response, "ox_specific.wu_url");
      setAnswer(urlFile);
      onCloseModal();
    },
    [webcamRef]
  );

  const handleDevices = React.useCallback(
    mediaDevices =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );
  
  React.useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices]
  );

  if(devices && !devices.length){
    return (
      <>
        <BoxCamUnavaible>
          <Title>Câmera indisponível</Title>
        </BoxCamUnavaible>
      </>
    )
  }

  return (
    <>
      {devices && devices.length && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TakePhotoBtn onClick={capture}>Capturar Foto</TakePhotoBtn>
          </div>
        </>
      )}
    </>
  );
};

export default WebcamCapture;
