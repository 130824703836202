import { Types } from "./types"

/**
 * Reducer
 */

const INITIAL_STATE = {
  loading: false,
  called: false,
  error: null,
  data: [],
}

export default function pendingInvitations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PENDING_INVITATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.GET_PENDING_INVITATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        called: true,
        data: action.payload.data,
      }
    case Types.GET_PENDING_INVITATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        called: true,
        error: true,
      }
    default:
      return state
  }
}
