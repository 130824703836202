import React from "react";
import Link from "../basicComponents/Link";
import { MenuContainer, Navigation, NavigationItem } from "./styles";

export default ({ orientation, options, color, size, hiddenMenu }) => {
  return (
    <>
      <MenuContainer hiddenMenu={hiddenMenu}>
        <Navigation orientation={orientation}>
          {options.map(item => (
            <NavigationItem initialStatus={item.initialStatus}>
              <Link
                href={item.link}
                text={item.description}
                target={item.target}
                color={color}
                size={size}
              />
            </NavigationItem>
          ))}
        </Navigation>
      </MenuContainer>
    </>
  );
};
