import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash";
import { Creators as CourseActions } from "../../../store/ducks/course/actions"
import api, {gateway} from "../../../utils/api"

class Praticar extends PureComponent {
  componentDidMount() {
    this.getVMP()
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props
    if (prevProps.selected.url !== selected.url) {
      this.getVMP()
    }
  }

  getVMP = () => {
    const { selected } = this.props
    api
      .get(selected.url)
      .then(response => {
        const urlToOpen = response.message

        window.open(urlToOpen, "newwindow", "width=900,height=700")
        this.endOfFile()
      })
      .catch(err => {
        console.log(err)
      })
  }

  endOfFile = () => {
    const idProfile = localStorage.getItem("idProfile")
    const { idCourse, selected, incrementCourseProgress } = this.props

    const progress = {
      idProfile,
      idTotvsCourse: idCourse,
      bbDiscipline: selected.idDiscipline,
      spxAttachment: selected.idAttachment,
      endOfFile: true,
    }

    const objectStatusProgress = _.get(selected, 'contentProgress.ox_status.wk_status', 'IN_PROGRESS');

    gateway
      .post("/userProgress/saveProgress", progress)
      .then(res => {
        console.log("RESPOSTA endOfFile", res.data)
        document.getElementById(selected.idAttachment).classList.add("active")
        if(objectStatusProgress !== "DONE") {
          incrementCourseProgress();
        }
      })
      .catch(err => console.log(err))
  }

  render() {
    return <div />
  }
}

const mapStateToProps = state => ({
  selected: state.course.selected,
  idCourse: state.course.data.idCourse,
})

const mapDispatchToProps = dispatch => bindActionCreators(CourseActions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Praticar)
