import styled, { css } from "styled-components";
import { POSITION } from "../../../constants";

export const LeftBar = styled.div`
  display: inline-block;
  text-align: center;
  width: 30%;
  @media screen and (max-width: 991px) {
    float: left;
  }
  @media screen and (max-width: 579px) {
    width: 100%;
  }

  ${props => (props.alignItems === POSITION.TOP_CENTER) && css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `}

  ${props => (props.alignItems === POSITION.TOP_LEFT_CENTER) && css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  `}

`;

export const RightBar = styled.div`
  display: inline-block;
  text-align: center;
  width: 70%;
  @media screen and (max-width: 579px) {
    width: 100%;
  }

  ${props => (props.alignItems === POSITION.TOP_CENTER) && css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `}

  ${props => (props.alignItems === POSITION.TOP_LEFT_CENTER) && css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  `}
  
`;
