import { Types } from "./types";

/**
 * Actions
 */

export const Creators = {
  getGroupInformation: data => ({
    type: Types.GET_GROUP_INFORMATION,
    payload: { data }
  })
};
