import styled from "styled-components"

export const LoginBox = styled.div`
  padding: 0 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 100%;
  height: 100vh;
`
export const RightSide = styled.div`
  background-color: #fff;
`

export const LoginHeaderBlack = styled.h3`
  font-family: "Dosis", sans-serif;
  font-size: 2.2em;
  font-weight: lighter;
  margin-bottom: 10px !important;
  font-stretch: condensed;
  color: #000;
`
export const TextHelperBlack = styled.h6`
  font-weight: lighter;
  margin-bottom: 10px;
  font-stretch: condensed;
  color: #000;
`

export const LoginHeader = styled.h3`
  font-family: "Dosis", sans-serif;
  font-size: 2.2em;
  font-weight: lighter;
  margin-bottom: 10px !important;
  font-stretch: condensed;
  color: #27aae1;
`

export const TextHelper = styled.h6`
  font-weight: lighter;
  margin-bottom: 10px;
  font-stretch: condensed;
  color: #27aae1;
`

export const Wrapper = styled.div`
  max-width: 100%;
`

export const ButtonLinks = styled.button`
  color: #27aae1;
  background-color: transparent;
  border: none;
  cursor: pointer;
`


export const ErrorMessage = styled.span`
  color: #e50000;
`
