/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react'
import { Formik } from 'formik';
import {IoMdClipboard} from 'react-icons/io'
import * as Yup from 'yup';

import {validateDate} from '../../../../utils/functions'

import {
  Form, 
  TextErro,  
  Header, 
  HeaderBoxIcon,
  HeaderText,
  ButtonCancel,
  ButtonConfirm,
  InputMaskDate, 
  Label
} from './styles'

const validationSchema = Yup.object().shape({
  wx_office: Yup.string().required('Campo obrigatório').default(''),
  wx_company: Yup.string().required('Campo obrigatório').default(''),
  wx_description: Yup.string().default(''),
  dx_dateinit: Yup.string().required('Campo obrigatório').default(''),
  dx_dateend: Yup.string().default(''),
});

export default function ProfessionalExperienceForm({cancel, submitForm, item, options}){
  const [dateInitError, setDateInitError] = useState('')
  const [dateEndError, setDateEndError] = useState('')
  
  let isCurrentPosition = true //item && item.bf_currentjob || false;
  let isMainJob = item && item.bf_mainjob || false
  const [currentPosition, setCurrentPosition] = useState(isCurrentPosition)
  const [mainJob, setMainJob] = useState(isMainJob)

  useEffect(() => {

    const element = document.getElementById('professionalForm')
    element.scrollIntoView({ behavior: 'smooth' })

    if(item && item.bf_currentjob){
      isCurrentPosition = true
    } else {
      isCurrentPosition = false
    }
    if(item && item.bf_mainjob) {
      isMainJob = true
    }
    setCurrentPosition(isCurrentPosition)
    setMainJob(isMainJob)
  }, [item])

  function handleSubmitForm(formValues) {
    const {rk_professionalexperienceid, id = Date.now()} = item || {};

    let data = {
        id,
        bf_mainjob: mainJob,
        ...formValues,
        dx_dateend: formValues.dx_dateend,
        bf_currentjob: currentPosition
    }

    if(rk_professionalexperienceid){
      data = {
        rk_professionalexperienceid,
        ...data
      }
    }

    if(dateInitError || dateEndError) {
      return
    }
    
    if(!data.dx_dateend && !currentPosition) {
      setDateEndError('Data de término ou emprego atual deve ser informado')
      return
    }

    submitForm(data)
  }

  function handleValidateDate(e, values) {

    if(!values.dx_dateend){
      setDateInitError('')
    }

    if(e.target.value) {
      const isValidDate = validateDate(e.target.value)

      if(!isValidDate) {
        if(e.target.name === 'dx_dateinit') {
          setDateInitError('Data inválida')
        }
    
        if(e.target.name === 'dx_dateend') {
          setDateEndError('Data inválida')
        }
      } else {
  
        const startDate = Number(values.dx_dateinit.split('/')[1])
        const endDate = Number(values.dx_dateend.split('/')[1])
     
        if(startDate >= endDate) {
          setDateInitError('A data de início não pode ser maior que a de conclusão')
        } else {
          setDateInitError('')
        }

        if(e.target.name === 'dx_dateinit') {
          setDateInitError('')
        }
    
        if(e.target.name === 'dx_dateend') {
          setDateEndError('')
        }
      }
    } 
  }

  function handleChangeMainJob() {
    setMainJob(value => !value)
  }

  function handleChangeCurrentPosition() {
    setCurrentPosition(value => !value)
    setDateEndError('')
  }

  function formatDateEnd(i) {
    if(i && i.dx_dateend) {
      const pattern = /\d{2}\/\d{2}\/\d{4}/g
      if(pattern.test(i.dx_dateend)) {
        const [, month, year] = i.dx_dateend.split('/')
        return `${month}/${year}`
      }
      return item.dx_dateend
    }
    return ''
  }

  function formatDateInit(i) {
    if(i && i.dx_dateinit) {
      const pattern = /\d{2}\/\d{2}\/\d{4}/g
      if(pattern.test(i.dx_dateinit)) {
        const [, month, year] = i.dx_dateinit.split('/')
        return `${month}/${year}`
      }
      return item.dx_dateinit
    }
    return ''
  }

  return (
    <Form>
      <div id="professionalForm" style={{position: 'absolute', top: '-50px'}}></div>
      <Header>
          <HeaderBoxIcon type="button"><IoMdClipboard size="16px" /></HeaderBoxIcon>
          <HeaderText>Experiência Profissional</HeaderText>
      </Header>
      <Formik
          enableReinitialize
          initialValues={{
            wx_office: item ? item.wx_office : '',
            wx_company: item ? item.wx_company : '',
            wx_description: item ? item.wx_description : '',
            dx_dateend: formatDateEnd(item),
            dx_dateinit: formatDateInit(item),
          }}
          validationSchema={validationSchema}
          onSubmit={(formValues, actions) => {
            handleSubmitForm(formValues, actions);
          }}
        >
          {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <React.Fragment>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <Label>Cargo *</Label>
                  <select
                    className="form-control"
                    required
                    style={{padding: '.4rem .75rem'}}
                    id="wx_formationtype"
                    value={values.wx_office}
                    onChange={handleChange('wx_office')}
                    onBlur={handleBlur('wx_office')}
                    name="wx_office"
                  >
                    <option value="" readOnly>Escolher</option>
                    {options.map(option => (
                      <option key={option.wx_optionvalue} value={option.wx_optionvalue}>{option.wx_optionlabel}</option>
                    ))}
                  
                  </select>
                  {errors.wx_office && touched.wx_office && (
                    <TextErro>{errors.wx_office}</TextErro>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Label>Empresa *</Label>
                  <input
                    type="text"
                    id="wx_company"
                    className="form-control"
                    placeholder="Ex: Saint Paul"
                    name="wx_company"
                    value={values.wx_company}
                    onChange={handleChange('wx_company')}
                    onBlur={handleBlur('wx_company')}
                  />
                  {errors.wx_company && touched.wx_company && (
                    <TextErro>{errors.wx_company}</TextErro>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <Label>Data de Início *</Label>
                <InputMaskDate
                   className="form-control"
                   id="professional_dx_dateinit"
                   name="dx_dateinit"
                   value={values.dx_dateinit}
                   mask="99/9999"
                   maskChar={null}
                   placeholder="MM/YYYY"
                   onChange={(e) => {
                    setDateInitError('') 
                    handleChange(e)
                   }}
                   onBlur={(e) => {
                     handleBlur(e) 
                     handleValidateDate(e, values)
                   }}
                />
                {errors.dx_dateinit && touched.dx_dateinit && !dateInitError && (
                  <TextErro>Campo obrigatório</TextErro>
                )}
                {dateInitError && (
                  <TextErro>{dateInitError}</TextErro>
                )}
              </div>
              {
                currentPosition ? (
                  <div className="form-group col-md-6">
                    <h6 className="blue" style={{fontWeight: 'bold', marginTop: '40px'}}>Até o momento</h6>
                  </div>
                ) : (
                  <div className="form-group col-md-6">
                    <Label>Data de Término</Label>
                    <InputMaskDate
                      className="form-control"
                      id="professional_dx_dateend"
                      name="dx_dateend"
                      disabled={currentPosition}
                      value={values.dx_dateend}
                      mask="99/9999"
                      maskChar={null}
                      placeholder="MM/YYYY"
                      onChange={(e) => {
                        setDateEndError('') 
                        handleChange(e)
                      }}
                      onBlur={(e) => {
                        handleBlur(e) 
                        handleValidateDate(e, values)
                      }}
                    />
                    {dateEndError && (
                      <TextErro>{dateEndError}</TextErro>
                    )}
                  </div>
                )
              }
            </div>
            
            <div className="row">
              <div className="form-group col-md-6 d-flex align-items-center">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    checked={mainJob}
                    type="checkbox"
                    id="input-check-main-job"
                    onChange={handleChangeMainJob}
                  />{" "}
                  Este é meu cargo principal
                </label>
              </div>
              <div className="form-group col-md-6 d-flex align-items-center">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    checked={currentPosition}
                    type="checkbox"
                    id="input-check-current-position"
                    onChange={handleChangeCurrentPosition}
                  />{" "}
                  Este é meu cargo atual
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-group">
                <Label>Sobre</Label>
                <textarea 
                  className="form-control"
                  placeholder="Sobre você"
                  rows="6"
                  maxLength="250"
                  id="wx_description"
                  value={values.wx_description}
                  onChange={handleChange('wx_description')}
                  onBlur={handleBlur('wx_description')}
                ></textarea>
                {errors.wx_description && touched.wx_description && (
                  <TextErro>Campo obrigatório</TextErro>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <ButtonCancel id="btn-cancel" type="button" onClick={cancel}>Cancelar</ButtonCancel>
                <ButtonConfirm id="btn-confirm" onClick={handleSubmit} type="button">Salvar</ButtonConfirm>
              </div>
            </div>       
          </React.Fragment>
        )}
        </Formik>
    </Form>
  )
}
