import styled from "styled-components";

export const CouponLabel = styled.h3`
  margin-top: 10px;
  color: #47b1de;
`
export const Button = styled.button.attrs({
  type: "button"
})`
  width: 100%;
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  margin: 0;
`
