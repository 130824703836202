import styled, { css } from "styled-components";
import { ORIENTATION } from "../../constants";

export const MenuContainer = styled.span`
    
  ${props => (props.hiddenMenu) && css`
    nav { opacity: 0 }
    @media (max-width: 767px) {
      position: fixed;
      left: 0;
      z-index: 10;
      height: 100vh;
      width: 100vw;
      background: transparent;
      top: 60px;
      border-top: 2px solid var(--white);
      opacity: 1;
      :before {
        content: "";
        display: block;
        background: var(--menu-mobile-overlay);
        height: 100vh;
        width: 100vw;
        position: fixed;
        left: 0;
        right: 0;
        z-index: -1;
      }
      nav {
        position: fixed;
        left: 0;
        z-index: 10;
        height: 100vh;
        width: 280px;
        background: linear-gradient( 
          220deg, 
          var(--background-gradient-primary) 20%, 
          var(--background-gradient-secondary) 160% 
        );
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        opacity: 1;
        ol {
          width: 100%;
          text-align: left;
          padding-left: 2rem;
          border-bottom: 1px solid #CCC;
          border-bottom: 5px solid transparent;
          &:hover {
            background: #34b0ae;
          }
        }
      }
    }
  `};

`;

export const Navigation = styled.nav`
  
  ${props => (props.orientation === ORIENTATION.VERTICAL) && css`
    display: inline-block;
    text-transform: uppercase;
    background: var(--transparent); 
    ol {
      display: block;
      cursor: pointer;
    }
  `};

  ${props => (props.orientation === ORIENTATION.HORIZONTAL) && css`
    display: inline-block;
    text-transform: uppercase;
    background: var(--transparent); 
    width: auto;
    transition: all ease .3s;
    opacity: 1;
    ol {
      border: 0;
      display: inline-block;
      text-transform: uppercase;
      background: var(--transparent); 
      cursor: pointer;
    }
    @media (max-width: 991px) {
      position: absolute;
    }
    @media (max-width: 767px) {
      opacity: 0;
      position: fixed;
      left: -380px;
    }
  `};

`;

export const NavigationItem = styled.ol`
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  width: 120px;
  padding: 15px 0;
  &:active,
  &:hover, 
  &.active {
    border-bottom: 5px solid var(--white);
  }

  ${props => (props.initialStatus === "active") && css`
    border-bottom: 5px solid var(--white)!important;
  `}
  
`;
