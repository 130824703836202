import React from 'react';

import ViewEditor from '../../../shared/LitEditor/ViewEditor';
import { LitButton } from '../../../shared/LitButton';
import PlanType from './planType';

import * as Styles from './styles';

const buttonStyles = {
  width: '100%',
  'margin-top': 'auto',
  fontWeight: "bold"
}

function PlanCard({ plan, onSelectPlan }) {
  return (
    <Styles.PlanItem
      id={plan.code}
      key={plan.code}
      promo={!!plan.spotlight}
      selected={!!plan.alreadyPurchased}
      data-for={`plan${plan}`}
      data-tip
      aria-haspopup="true"
      data-iscapture="true"
    >
      <h4>
        {plan.planType === PlanType.Monthly && <b>Adquirir</b>}
        {plan.planType === PlanType.Yearly && (
          <b>{plan.formOfPayment === 0 ? 'Mensal' : ''}</b>
        )}
      </h4>

      <Styles.PlanName>
        <h3>{plan.name}</h3>
      </Styles.PlanName>

      <Styles.PriceDescription>
        <Styles.PlanTypeView></Styles.PlanTypeView>

       
        {plan.firstPaymentMethodLabel ? (
          <Styles.PlanPrice
            style={{
              fontSize: 15,
              color: '#000',
              fontWeight: 'normal',
              'text-decoration': 'line-through'
            }}
          > 
            de R$ {plan.formattedBasePrice}
          </Styles.PlanPrice>
        ) : (
          <Styles.PlanPrice
            style={{
              fontSize: 15,
              color: '#000',
              fontWeight: 'normal',
            }}
          > 
            &nbsp;
          </Styles.PlanPrice>
        )}

      
       {!plan.firstPaymentMethodLabel &&
          plan.paymentType &&
          plan.paymentType === 'RECURRENCY' && (
            <Styles.PlanPrice
              style={{
                'margin-bottom': '10px',
                'margin-top': 0,
              }}
            >
              R$ {plan.formattedBasePrice}
            </Styles.PlanPrice>
          )}

        {!plan.firstPaymentMethodLabel &&
          plan.paymentType &&
          plan.paymentType !== 'RECURRENCY' && (
            <Styles.PlanPrice>R$ {plan.formattedBasePrice}</Styles.PlanPrice>
        )}

        {plan.firstPaymentMethodLabel && (
          <Styles.FirstDescription>
            {plan.firstPaymentMethodLabel ? plan.firstPaymentMethodLabel.replace('Cartão de crédito', 'cartão') : ''}
          </Styles.FirstDescription>
        )}

        {plan.secondPaymentMethodLabel && (
          <Styles.SecondDescription>
            {plan.secondPaymentMethodLabel.replace('Cartão de crédito', 'cartão')}
          </Styles.SecondDescription>
        )}

  
        {!plan.secondPaymentMethodLabel && plan.maxInstallments > 1 && (
          <Styles.SecondDescription>
            {plan.maxInstallments}x   
            R$ {(plan.basePrice / plan.maxInstallments)
            .toFixed(2).toString().replace('.', ',')} no{' '}
            {plan.maxInstallmentsMethod === 'PIX' ? 'Pix' : ''}
            {plan.maxInstallmentsMethod === 'CREDIT' ? 'cartão' : ''}
            {plan.maxInstallmentsMethod === 'INVOICE' ? 'boleto' : ''}
          </Styles.SecondDescription>
        )}
      </Styles.PriceDescription>

      <div
        className="plan-features"
        style={{
          display: 'block',
          marginTop: 25,
          marginBottom: 10
        }}
      >
        <ViewEditor data={plan.features} />
      </div>
      
      <LitButton
        style={{
          ...buttonStyles,
          'font-size': `${plan.alreadyPurchased ? '12px' : '16px'}`
        }}
        onClick={event => {
          event.stopPropagation();
          onSelectPlan(plan);
        }}
      >
        <span style={{textTransform: "uppercase"}}>
          {plan.alreadyPurchased ? 'ALTERAR FORMA DE PAGAMENTO' : 'Inscreva-se'}
        </span>
      </LitButton>

      {plan.spotlight && (
        <div className="flag-promo">
          <span>{plan.spotlight}</span>
        </div>
      )}
    </Styles.PlanItem>
  );
}

export default PlanCard;
