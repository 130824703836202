import React from 'react';
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {} //component does not yet receive parameters
**/

const marks = [
  {
    value: 0,
    label: '1 min',
  },
  {
    value: 15,
    label: '15 min',
  },
  {
    value: 30,
    label: '30 min',
  },
  {
    value: 45,
    label: '45 min',
  },
  {
    value: 60,
    label: '60 min',
  },
  {
    value: 100,
    label: '120 min',
  },
];

function valuetext(value) {
  return `${value} min`;
}

export default () => {
  return (
    <Screen
      getAriaValueText={valuetext}
      defaultValue={[15, 30]}
      marks={marks}
      step={null}
    />
  );
}
