import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} children //rendered content within the component
 * @param {String} color //{BLOCK_LIGHT, BLOCK_PRIMARY, BLOCK_EDIT, BLOCK_GRADIENT} sets the color of the block
 * @param {String} appearance //{NEW_POST, NOTIFICATION, BANNER_BLOCK, CARD, EVALUATION, MEMBER, ARCHIVE_ITEM} block appearance
 * @param {String} orientation //{AFASTABLE} list of slider components
 * @param {Boolean} hidden //button action above the slider
*/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
  color = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING,
  orientation = DEFAULT_VALUES.STRING,
  hidden = DEFAULT_VALUES.BOOLEAN
}) => {
  return (
    <>
      <Screen
        child={children}
        color={color}
        appearance={appearance}
        orientation={orientation}
        hidden={hidden}
      />
    </>
  );
};
