import React, {useState} from 'react';

import { Container, ContainerVideo } from './styles';
import Play from './Play'
import Modal from '../Modal'

function SurveyVideoComponent({id, path, name}) {
  const [showModal, setShowModal] = useState(false)
  return (
    <Container id={id}>
      <button type="button" onClick={() => setShowModal(true)}>Visualizar vídeo</button>
      <Modal
        visible={showModal}
        title={name}
        onClose={() => setShowModal(false)}
      >
        <ContainerVideo>
          <Play url={path} />
        </ContainerVideo>
      </Modal>
    </Container>
  );
}

export default SurveyVideoComponent;
