import React from "react"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import Img from "react-image"
import Modal from "react-responsive-modal"
import Simplert from "react-simplert"
import Linkify from "react-linkify"
import moment from "moment"
import "moment/locale/pt-br"
import placeholder from "../../../images/placeholder-avatar.png"

import Comentario from "./Comentario"
import Attachment from "./Attachment"
import EditComment from "./Forms/EditComments"

import { API_URL_IMAGES } from "../../../utils/constants"

import api from "../../../utils/api"

class Post extends React.Component {
  state = {
    modalOpen: false, // novo modal
    willReport: null,
    alert: false,
    comment: "",
    commentList: [],
    file: null,
    imagePreviewUrl: "",
    hasLike: false,
    contadorLike: 0,
    ext: "",
    commentBox: false,
    previewAttachment: false,
    reason: "",
    extra: "",
    openEditarComentario: false,
  }

  componentWillMount() {
    this._fetchLikes()
    this.getCommentByPost()
  }

  deletePost = () => {
    this.setState({
      alert: true,
    })
  }

  confirmDelete = async idTimeline => {
    try {
      const { getPosts } = this.props
      await api.put(`deletingPost/${idTimeline}`, null, {
        headers: { "Content-Type": "application/json" },
      })
      getPosts()
    } catch (error) {
      console.log(error)
    }
  }

  reportComment = async () => {
    const { willReport, reason, extra } = this.state
    if (reason === "") {
      alert("Escolha uma razão")
      return
    }
    try {
      const data = {
        memberReporting: {
          idGroupMember: localStorage.getItem("idMember"),
        },
        postReported: {
          idTimeLine: willReport,
        },
        report: reason,
        reportComment: extra,
      }
      await api.post(`newReportPost`, data)
      alert("Denuncia registrada, obrigado.")
      this.setState({
        modalOpen: false,
        willReport: null,
        reason: "",
        extra: "",
      })
    } catch (error) {
      console.log(error)
    }
  }

  openReport = id => {
    this.setState({ willReport: id, modalOpen: true })
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false })
  }

  getCommentByPost = async () => {
    try {
      const { post } = this.props
      const response = await api.get(`getCommentsByPost/${post.idTimeLine}`)
      const commentList = response.reverse()
      this.setState({ commentList })
    } catch (error) {
      console.log(error)
    }
  }

  _fetchLikes = async () => {
    const { post } = this.props
    const idProfile = localStorage.getItem("idProfile")
    try {
      const response = await api.get(`findLikesOfPosts/${post.idTimeLine}/${idProfile}`)

      this.setState({ hasLike: response.liked, contadorLike: response.size })
    } catch (error) {
      console.log(error)
    }
  }

  _createLike = async () => {
    this.btnLike.setAttribute("disabled", true)
    const { post } = this.props
    const { hasLike } = this.state
    try {
      const data = {
        timeLine: {
          idTimeLine: post.idTimeLine,
        },
        memberLike: {
          idGroupMember: localStorage.getItem("idMember"),
        },
        likePost: true,
      }
      const response = await api.post("newLike", data)
      console.log("Created like: ", response)
      this.setState({ hasLike: !hasLike })
      this.btnLike.removeAttribute("disabled")
      this._fetchLikes()
    } catch (error) {
      console.log(error)
    }
  }

  showComment = () => {
    const { commentBox } = this.state
    this.setState({ commentBox: !commentBox })
  }

  setCommentBoxTrue = () => {
    this.setState({ commentBox: true })
  }

  newComment = async () => {
    const { comment, file, ext } = this.state
    const { post } = this.props
    const idGroupMember = localStorage.getItem("idMember")
    const currentDate = moment.utc()

    if (comment === "" || comment.trim() === "") {
      alert("O comentário não pode ser vazio!")
      return
    }
    try {
      const data = {
        comment,
        date: currentDate,
        post: {
          idTimeLine: post.idTimeLine,
        },
        memberComment: {
          idGroupMember,
        },
      }
      const response = await api.post("newComment", data)
      if (file != null) {
        this.commentFile(response.idComment, ext)
      } else {
        this.setState({ comment: "" })
        this.getCommentByPost()
        this._fetchLikes()
      }
    } catch (error) {
      console.log(error)
    }
  }

  commentFile = async (id, ext) => {
    try {
      const { file } = this.state

      await api.post(`uploadImgComment/${id}/${ext}`, file)
      this.setState({
        file: null,
        imagePreviewUrl: null,
        ext: "",
      })
      this.updateWithAttachment(id, ext)
    } catch (error) {
      console.log(error)
    }
  }

  updateWithAttachment = async (id, ext) => {
    const { comment } = this.state
    const { post } = this.props
    const idGroupMember = localStorage.getItem("idMember")
    const currentDate = moment.utc()
    try {
      const data = {
        idComment: id,
        comment,
        date: currentDate,
        linkMedia: `downdImgComment/${id}/${ext}`,
        post: {
          idTimeLine: post.idTimeLine,
        },
        memberComment: {
          idGroupMember,
        },
      }
      await api.put("updateComment", data)
      this.setState({ comment: "" })
      this.getCommentByPost()
      this._fetchLikes()
    } catch (error) {
      console.log(error)
    }
  }

  _handleImageChange = e => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    if (file === undefined) return

    if (
      !["jpg", "mp4", "mp3", "pdf", "xlsx", "xls", "doc", "docx", "ppt", "pptx"].includes(
        file.name.split(".")[1]
      )
    ) {
      alert("Extensão de arquivo não suportada!")
      return
    }

    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
        ext: file.name.split(".")[1],
      })
    }

    reader.readAsDataURL(file)
  }

  editComentario = () => {
    this.setState({
      openEditarComentario: true,
    })
  }

  cancelEdit = () => {
    this.setState({ openEditarComentario: false })
  }

  render() {
    const {
      imagePreviewUrl,
      ext,
      previewAttachment,
      file,
      openEditarComentario,
      commentList,
      hasLike,
      contadorLike,
      commentBox,
      comment,
      modalOpen,
      reason,
      extra,
      alert,
    } = this.state
    const { post, groupOwner, getPosts, membro } = this.props

    const idProfile = parseInt(localStorage.getItem("idProfile"), 10)

    let textComment
    if (commentList.length > 1) {
      textComment = " Comentários"
    } else if (commentList.length === 1) {
      textComment = " Comentário"
    } else {
      textComment = " Comentar "
    }

    let $attachment
    let $imagePreview = null

    if (imagePreviewUrl && ext === "jpg") {
      $imagePreview = (
        <div className="d-flex justify-content-center align-items-center">
          <div onClick={() => this.setState({ previewAttachment: true })} role="button">
            <img
              className="img-thumbnail"
              alt="Anexo"
              style={{ width: "auto", height: "250px" }}
              src={imagePreviewUrl}
            />
          </div>
          {previewAttachment && (
            <Lightbox
              mainSrc={imagePreviewUrl}
              onCloseRequest={() => {
                this.setState({ previewAttachment: false })
              }}
            />
          )}
        </div>
      )
    } else if (imagePreviewUrl) {
      $imagePreview = <p>{file.name}</p>
    } else {
      $imagePreview = <div className="previewText" />
    }

    const urlGetMedia = `${API_URL_IMAGES}images/social/posts/${post.idTimeLine}`
    const urlImgProfile = post.linkPhoto
      ? `${API_URL_IMAGES}images/profile/${post.idProfile}.jpg`
      : placeholder

    if (post.linkMedia) {
      if (post.linkMedia.endsWith("jpg")) {
        $attachment = <Attachment url={`${urlGetMedia}.jpg`} ext="jpg" />
      } else if (post.linkMedia.endsWith("mp4")) {
        $attachment = <Attachment url={`${urlGetMedia}.mp4`} ext="mp4" />
      } else if (post.linkMedia.endsWith("mp3")) {
        $attachment = <Attachment url={`${urlGetMedia}.mp3`} ext="mp3" />
      } else if (post.linkMedia.endsWith("pdf")) {
        $attachment = <Attachment url={`${urlGetMedia}.pdf`} ext="pdf" />
      } else if (post.linkMedia.includes("kaltura")) {
        $attachment = <Attachment url={post.linkMedia} ext="VOD" curso />
      } else if (post.linkMedia.includes("downloadAttachment")) {
        $attachment = <Attachment url={post.linkMedia} ext="pdf" curso />
      } else {
        $attachment = (
          <a href={post.linkMedia} style={{ color: "#27aae1" }}>
            <i className="fa fa-download" /> Baixar anexo
          </a>
        )
      }
    } else {
      $attachment = <div />
    }

    return (
      <div className="post-card" style={{ padding: "1em", marginBottom: "10px" }}>
        <div className="pl-3">
          <div className="row avatar">
            <Link to={`/${post.idRole === 3 ? "perfilProfessor" : "perfilPublico"}/${post.idProfile}`}>
              <Img className="rounded-circle" style={{ width: 70, height: 70 }} src={urlImgProfile} />
            </Link>
            <div className="avatar-social avatar-body">
              <h6>
                <Link
                  to={`/${post.idRole === 3 ? "perfilProfessor" : "perfilPublico"}/${post.idProfile}`}
                >
                  {post.nameProfile}
                </Link>
              </h6>
              <span>
                <em>
                  {moment(post.datePost)
                    .zone(0)
                    .fromNow()}
                </em>
              </span>
            </div>
            <div className="dropdown ml-auto">
              <a
                className="ml-auto"
                role="button"
                id="dropdownOptions"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-caret-down" aria-hidden="true" />
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownOptions">
                <a
                  className="dropdown-item"
                  onClick={() => {
                    this.openReport(post.idTimeLine)
                  }}
                  role="button"
                >
                  Denunciar
                </a>
                {post.idProfile === idProfile && (
                  <a
                    data-tip="Fez um comentário e escreveu algo errado? Pode editar clicando aqui"
                    className="dropdown-item"
                    onClick={this.editComentario}
                    role="button"
                  >
                    Editar
                  </a>
                )}
                {groupOwner === 1 || post.idProfile === idProfile ? (
                  <a className="dropdown-item" onClick={this.deletePost} role="button"  id="btn-excluir-post">
                    Excluir
                  </a>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row social-publicacao--descricao-container" style={{ marginTop: 15 }}>
            <div className="col-12">
              {openEditarComentario ? (
                <EditComment
                  from="post"
                  refreshPosts={getPosts}
                  cancelEdit={this.cancelEdit}
                  post={post}
                />
              ) : (
                <Linkify
                  properties={{
                    target: "_blank",
                    style: { color: "#27aae1", fontWeight: "bold" },
                  }}
                >
                  <p>{post.content.trim()}</p>
                </Linkify>
              )}
            </div>
          </div>
          {!openEditarComentario && (
            <div className="row">
              <div className="col-12">{$attachment}</div>
            </div>
          )}
          <div className="row social-publicacao--img-container">
            <div className="col-12">
              <div className="container-fluid">
                <div className="row">
                  <button
                    type="button"
                    className="btn btn-icon"
                    onClick={this.setCommentBoxTrue}
                    style={{ padding: 0 }}
                  >
                    <i className="fa fa-comment-o" />
                    {textComment}
                    &nbsp;
                    <span className="badge badge-primary" style={{ backgroundColor: "#27AAE1" }}>
                      {commentList.length}
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-icon"
                    ref={c => {
                      this.btnLike = c
                    }}
                    disabled={!membro}
                    onClick={this._createLike}
                  >
                    {!hasLike ? (
                      <i className="fa fa-heart-o" style={{ color: "#27AAE1" }} />
                    ) : (
                      <i className="fa fa-heart" style={{ color: "#27AAE1" }} />
                    )}

                    {!hasLike ? " Curtir " : " Descurtir "}

                    <span className="badge badge-primary" style={{ backgroundColor: "#27AAE1" }}>
                      {contadorLike}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {commentBox ? (
            <div>
              {membro && (
                <div className="row social-publicacao--img-container">
                  <div className="form-group col-12">
                    <div className="form-group">
                      <textarea
                        rows="5"
                        className="form-control"
                        id="inputComment"
                        value={comment}
                        onChange={evt => this.setState({ comment: evt.target.value })}
                        placeholder="Escreva um comentário..."
                      />
                      <button
                        type="button"
                        className="btn btn-blue mr-3"
                        onClick={this.newComment}
                        disabled={comment === "" || comment.trim() === ""}
                      >
                        Comentar
                      </button>
                      <label className="btn btn-blue mt-2">
                        Anexar arquivo
                        <input
                          type="file"
                          id="exampleInputFile"
                          accept="image/jpeg, application/pdf, audio/mpeg, audio/mp3, video/mp4, .xlsx, .xls , .doc, .docx, .ppt, .pptx"
                          hidden
                          onChange={e => this._handleImageChange(e)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">{$imagePreview}</div>
                </div>
              )}
              {commentBox
                ? commentList.map((commentary, index) => {
                    return (
                      <Comentario
                        key={commentary.idComment}
                        quantidadeComentarios={commentList.length}
                        comment={commentary}
                        child={index}
                        membro={membro}
                        getPosts={getPosts}
                        getCommentByPost={this.getCommentByPost}
                      />
                    )
                  })
                : null}

              <a
                className="btn btn-icon"
                style={{ padding: 0 }}
                role="button"
                onClick={this.showComment}
              >
                Ocultar todos os comentários
              </a>
            </div>
          ) : null}
        </div>
        <Modal open={modalOpen} onClose={this.onCloseModal} classNames={{ modal: "lit-modal-form" }}>
          <div className="modal-content" id="modalGroup">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Denunciar publicação
              </h5>
            </div>
            <div className="modalBody-container">
              <div className="modal-body">
                <div className="form-group">
                  <label>Motivo da denúncia</label>
                  <select
                    className="form-control"
                    value={reason}
                    onChange={evt => this.setState({ reason: evt.target.value })}
                  >
                    <option defaultValue>Selecione o motivo</option>
                    <option value="nudez">Conteúdo impróprio com apelo sexual</option>
                    <option value="ofensa">Conteúdo ofensivo</option>
                    <option value="violencia">Conteúdo proibido ou violento</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Outras informações</label>
                  <textarea
                    type="text"
                    className="form-control"
                    rows="5"
                    id="inputGroupName"
                    value={extra}
                    onChange={evt =>
                      this.setState({
                        extra: evt.target.value,
                      })
                    }
                    required
                    placeholder="Utilize este espaço caso deseje incrementar a denúncia"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-modal__cancel" onClick={this.onCloseModal}>
                Cancelar
              </button>
              <button type="submit" onClick={this.reportComment} className="btn btn-modal__approve">
                Salvar
              </button>
            </div>
          </div>
        </Modal>
        <Simplert
          showSimplert={alert}
          type="error"
          title="Deseja excluir essa publicação?"
          message="Esta ação não pode ser revertida"
          useConfirmBtn
          onConfirm={() => this.confirmDelete(post.idTimeLine)}
          customConfirmBtnText="Confirmar"
          customCloseBtnText="Cancelar"
          customConfirmBtnClass="btn btn-modal__approve"
          customCloseBtnClass="btn btn-modal__cancel"
        />
      </div>
    )
  }
}

export default Post
