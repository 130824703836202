import styled from 'styled-components'

export const Container = styled.div`
  #send-for-email {
    border:0;
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #27aae1;
    font-size: 32px;
  }

  button {
    width: 100%;
  }

  .field-float-label-pattern label {
    position: absolute;
    color: #aaa;
    left: .9em;
    top: 0em;
    transition: none;
  }
`

export const RecommendationsTitleBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
`

export const RecommendationBox = styled.div`
  border-radius: 5px;
`

export const Line = styled.tr`
  > td {
      vertical-align: middle;
    }
`

export const ButtonCollapse = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  text-decoration: none;
  padding-left: 0;
  &:hover {
    text-decoration:none;
  }
  &:active, &:focus {
    box-shadow: none;
    outline: none;
    text-decoration:none;
  }

  div span {
    font-weight: bold;
  }

  i {
    color: #27aae1;
  }
`

export const BoxTitle = styled.div`
  margin-top: 10px;
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  margin: 0;
`

export const ContainerError = styled.div`
  text-align: center;
  p {
    font-size: 16px;
  }
`

export const TextErro = styled.p`
  margin: 0;
  padding: 0;
  color: red;
  font-size: 12px;
`

export const TitleRecommendations = styled.p`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight:bold;
  margin-bottom: 0px;
`
