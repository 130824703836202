import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} id //post identification code
 * @param {String} code //identification code
 * @param {Array} member //data of the user who posted or commented
 * @param {Array} members //list of members to display in items
 * @param {Array} postGroup //group in which the post was made
 * @param {Array} postMember //data of the user who posted or commented
 * @param {Array} postContent //content to be rendered in the post
 * @param {Array} postArchives //list of files attached to the post
 * @param {Array} postComments //post comment list
 * @param {Date} postDate //displays post date
 * @param {String} postDescription //displays post content
 * @param {Number} totalLikes //number of "like" reactions
 * @param {Number} totalDislikes //number of "unlike" reactions
 * @param {Function} updatePost //function that updates post
 * @param {Function} createComment //function that create comment
 * @param {Function} deleteCommunication //function that delete comment
 * @param {Boolean} isAdministrator //verifies that the author of the post is an administrator
 * @param {Array} reactionsTypes //list types of reactions
 * @param {Function} updateCommunicationReactions //function that updates comment
 * @param {Boolean} hasLike //check if there are likes on the post
 * @param {Boolean} isOwner //checks if the post is from the logged in user
 * @param {Boolean} hiddenCreatePost //show or hide create post component
 * @param {Boolean} interationsModalIsOpen //show or hide modal invitations
 * @param {Boolean} setInterationsModalIsOpen //show or hide create post component
 * @param {Function} loadCommunicationReactions //looks for reactions to be displayed for comments click events
 * @param {Function} loadReactionPeople //seeks reactions to be linked to users displayed in the post
 * @param {Boolean} hasReaction //check if reaction list is empty
 * @param {Array} reactions //list of reactions to display
 * @param {} showTimer //video run time
**/

export default ({
  id = DEFAULT_VALUES.NUMBER,
  code = DEFAULT_VALUES.STRING,
  member = DEFAULT_VALUES.LIST,
  members = DEFAULT_VALUES.LIST,
  postGroup = DEFAULT_VALUES.LIST,
  postMember = DEFAULT_VALUES.LIST,
  postContent = DEFAULT_VALUES.LIST,
  postArchives = DEFAULT_VALUES.LIST,
  postComments = DEFAULT_VALUES.LIST,
  postDate = DEFAULT_VALUES.DATE,
  postDescription = DEFAULT_VALUES.STRING,
  totalLikes = DEFAULT_VALUES.NUMBER,
  totalDislikes = DEFAULT_VALUES.NUMBER,
  updatePost = DEFAULT_VALUES.FUNCTION,
  createComment = DEFAULT_VALUES.FUNCTION,
  deleteCommunication = DEFAULT_VALUES.FUNCTION,
  isAdministrator = DEFAULT_VALUES.BOOLEAN,
  reactionsTypes = DEFAULT_VALUES.LIST,
  updateCommunicationReactions = DEFAULT_VALUES.FUNCTION,
  hasLike = DEFAULT_VALUES.BOOLEAN,
  isOwner = DEFAULT_VALUES.BOOLEAN,
  likes = DEFAULT_VALUES.NUMBER,
  hiddenCreatePost = DEFAULT_VALUES.BOOLEAN,
  interationsModalIsOpen = DEFAULT_VALUES.BOOLEAN,
  setInterationsModalIsOpen = DEFAULT_VALUES.BOOLEAN,
  loadCommunicationReactions = DEFAULT_VALUES.FUNCTION,
  loadReactionPeople = DEFAULT_VALUES.FUNCTION,
  hasReaction = DEFAULT_VALUES.BOOLEAN,
  reactions = DEFAULT_VALUES.LIST,
  showTimer
}) => {
  return (
    <>
      <Screen
        id={id}
        code={code}
        member={member}
        members={members}
        postMember={postMember}
        postContent={postContent}
        postGroup={postGroup}
        postArchives={postArchives}
        postComments={postComments}
        postDate={postDate}
        reactions={reactions}
        postDescription={postDescription}
        isAdministrator={isAdministrator}
        totalLikes={totalLikes}
        totalDislikes={totalDislikes}
        updatePost={updatePost}
        createComment={createComment}
        deleteCommunication={deleteCommunication}
        hiddenCreatePost={hiddenCreatePost}
        reactionsTypes={reactionsTypes}
        updateCommunicationReactions={updateCommunicationReactions}
        hasLike={hasLike}
        isOwner={isOwner}
        likes={likes}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
        loadCommunicationReactions={loadCommunicationReactions}
        loadReactionPeople={loadReactionPeople}
        hasReaction={hasReaction}
        showTimer={showTimer}
      />
    </>
  );
};
