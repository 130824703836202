import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from 'react';
import swal from '@sweetalert/with-react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { Creators as CourseActions } from '../../store/ducks/newCourse/actions';

import SurveyView from './subcomponents/SurveyView';
import ActionFooter from './subcomponents/ActionFooter';
import AssessmentModal from './subcomponents/Assessment';
import NavBar from './subcomponents/Navbar';
import Header from './subcomponents/Header';
import ExitAlert from './subcomponents/ExitAlert';
import FinishAlert from './subcomponents/FinishAlert';
import spinner from '../../images/paul_loading.gif';
import { gateway } from '../../utils/api';
import history from '../../utils/history';

import { Container, Loading, Content } from './styles';

function Assessment({ location }) {
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingFinishAssessment, setLoadingFinishAssessment] = useState(false);
  const [currentSurveyIndex, setCurrentSurveyIndex] = useState(0);
  const [answeredSurveys, setAnsweredSurveys] = useState([]);
  const [unAnsweredSurveys, setUnAnsweredSurveys] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [actionsDisabled, setActionsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [userComment, setUserComment] = useState('');

  const [responseData, setResponseData] = useState(null);

  const skipCloseMessage = useRef(false);

  const dispatch = useDispatch();

  const currentSurvey = useMemo(() => {
    return {
      data: _.get(assessmentData, `surveys.${currentSurveyIndex}`, null),
      answers: _.get(answers, `${currentSurveyIndex}.alternatives`, null)
    };
  }, [assessmentData, currentSurveyIndex, answers]);

  const numberOfSurveys = useMemo(() => {
    return assessmentData ? _.size(assessmentData.surveys || []) : 0;
  }, [assessmentData]);

  function handleErrorAlert() {
    swal({
      title: `Ops, ocorreu um erro.`,
      text: `Não foi possível buscar os dados dessa avaliação.`,
      buttons: {
        confirm: {
          text: 'Continuar',
          value: true,
          visible: true,
          className: 'btn-alert btn-alert-confirm-onboarding'
        }
      }
    }).then(() => {
      skipCloseMessage.current = true;
      window.close();
    });
  }

  useEffect(() => {
    window.addEventListener('beforeunload', e => {
      if (!skipCloseMessage.current) {
        const confirmationMessage =
          'Suas alterações não serão salvas e uma tentativa será contabilizada!';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }

      return null;
    });
  }, []);

  async function fetchAssessmentData() {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const assessmentType = params.get('type');
    const courseCode = params.get('course');
    const idProfile = localStorage.getItem('idProfile');
    const localStorageKey = `${code}_${idProfile}`;
    try {
      console.log('Buscando dados da API');
      let url = `/webbff/lit_app_web/evaluation/${code}`;
      url +=
        assessmentType === 'SELF_ASSESSMENT' && courseCode
          ? `/course/${courseCode}`
          : '';
      const response = await gateway.get(url);
      const initialAnswers = (response.surveys || []).map(survey => {
        return {
          surveyCode: survey.code,
          sessionId: survey.sessionId,
          alternatives: (survey.alternatives || []).map(alternative => {
            return {
              alternativeId: alternative.id,
              value: ''
            };
          })
        };
      });
      setAnswers(initialAnswers);
      setAssessmentData(response);
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({ initialAnswers, response, savedAt: new Date().getTime() })
      );
      setLoading(false);
    } catch (error) {
      handleErrorAlert();
      setLoading(false);
    }
  }

  function updateInitialAnswers(answers) {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const idProfile = localStorage.getItem('idProfile');
    const localStorageKey = `${code}_${idProfile}`;
    const savedAssessment = localStorage.getItem(localStorageKey);
    if (savedAssessment) {
      const { response, savedAt } = JSON.parse(savedAssessment);
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({ initialAnswers: answers, response, savedAt })
      );
    }
  }

  function removeAssessmentDataToLocalStorage() {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const idProfile = localStorage.getItem('idProfile');
    const localStorageKey = `${code}_${idProfile}`;
    localStorage.removeItem(localStorageKey);
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const idProfile = localStorage.getItem('idProfile');
    const localStorageKey = `${code}_${idProfile}`;
    function loadAssessment() {
      const savedAssessment = localStorage.getItem(localStorageKey);
      if (savedAssessment) {
        const { initialAnswers, response, savedAt } = JSON.parse(savedAssessment);
        const currentTime = new Date().getTime();
        const timeLimit = response.timeLimit * 1000; 
        const elapsedTime = currentTime - savedAt;
        if (elapsedTime < timeLimit) {
          const updatedTimeLimit = (timeLimit - elapsedTime) / 1000; // Convert back to seconds
          const updatedResponse = { ...response, timeLimit: updatedTimeLimit };
          localStorage.setItem(
            localStorageKey,
            JSON.stringify({ initialAnswers, response: updatedResponse, savedAt })
          );
          setAnswers(initialAnswers);
          setAssessmentData(updatedResponse);
          setLoading(false);
        } else {
          console.log('Tempo limite atingido, buscando novos dados da API');
          fetchAssessmentData();
        }
      } else {
        fetchAssessmentData();
      }
    }
    if (code) {
      loadAssessment();
    } else {
      handleErrorAlert();
    }
  }, []);

  useEffect(() => {
    if(answers.length > 0){
      updateInitialAnswers(answers);
    }
  }, [answers])

  function handleConfirmAnswer() {
    const currentSurveyAnswers = _.get(currentSurvey, 'answers');
    const foundAnswer = currentSurveyAnswers.find(item => {
      let { value } = item;
      if (typeof item.value === 'boolean') {
        value = true;
      }
      return value;
    });
    if (foundAnswer) {
      setAnsweredSurveys(prevState => {
        if (!prevState.includes(currentSurveyIndex)) {
          return [...prevState, currentSurveyIndex];
        }
        return prevState;
      });
      if (unAnsweredSurveys.length > 0) {
        const filteredUnAnsweredSurveys = unAnsweredSurveys.filter(
          el => el !== currentSurveyIndex
        );
        setUnAnsweredSurveys(filteredUnAnsweredSurveys);
      }
      setCurrentSurveyIndex(prevState => {
        return prevState < numberOfSurveys ? prevState + 1 : prevState;
      });
    } else {
      swal({
        title: `Atenção!`,
        text: `Você não selecionou nenhuma alternativa, ao confirmar você concorda que não terá nenhuma pontuação para essa questão. `,
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: false,
            visible: true,
            className: 'btn-alert btn-alert-cancel-assessment'
          },
          confirm: {
            text: 'Confirmar e avançar',
            value: true,
            visible: true,
            className: 'btn-alert btn-alert-finish-assessment'
          }
        }
      }).then(value => {
        if (value) {
          setUnAnsweredSurveys(prevState => {
            if (!prevState.includes(currentSurveyIndex)) {
              return [...prevState, currentSurveyIndex];
            }
            return prevState;
          });
          setCurrentSurveyIndex(prevState => {
            return prevState < numberOfSurveys ? prevState + 1 : prevState;
          });
        }
      });
    }
  }

  function handleGoBack() {
    setCurrentSurveyIndex(prevState => {
      return prevState > 0 ? prevState - 1 : prevState;
    });
  }

  function handleGoNext() {
    setCurrentSurveyIndex(prevState => {
      return prevState < numberOfSurveys ? prevState + 1 : prevState;
    });
  }

  async function handleFinish(forceSubmit) {
    if (
      [...answeredSurveys, ...unAnsweredSurveys].length !== numberOfSurveys &&
      !forceSubmit
    ) {
      swal({
        title: 'Atenção',
        text:
          'Você precisa responder todas questões para finalizar essa tentativa!',
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            className: 'btn-alert btn-alert'
          }
        }
      });
      return;
    }
    try {
      setLoadingFinishAssessment(true);
      const params = new URLSearchParams(location.search);
      const courseCode = params.get('course');
      const requestBody = {
        assessmentCode: assessmentData.assessmentCode,
        courseCode: assessmentData.courseCode,
        courseCrCode: courseCode,
        response: {
          responseCode: assessmentData.responseCode,
          surveys: answers
        },
        userComment
      };

      const retorno = await gateway.post(
        `/webbff/lit_app_web/evaluation/response/send`,
        requestBody
      );

      const examResponseCrCode = retorno.ox_standard.ox_identification.cr_code;
      const assessmentSummary = await gateway.get(
        `webbff/lit_app_web/evaluation/${assessmentData.assessmentCode}/summary`
      );
      const examResponseCorrectionIsDone = assessmentSummary.find(
        assessment => assessment.crCode === examResponseCrCode
      );

      const graphics = _.get(retorno, 'progress.graphics', []);
      setAssessment(graphics);
      dispatch(CourseActions.setRefreshCourse(true));
      swal({
        content: <FinishAlert type={assessmentData.assessmentType} />,
        buttons: {
          confirm: {
            text: 'Finalizar',
            value: true,
            visible: true,
            className: 'btn-alert btn-alert-finish-assessment'
          }
        }
      }).then(value => {
        removeAssessmentDataToLocalStorage();
        if (value) {
          if (
            assessmentData.assessmentType !== 'FINAL_EXAM'
          ) {
            if (graphics.length > 0) {
              setShowModal(graphics.length > 0);
              setResponseData({ examResponseCorrectionIsDone, examResponseCrCode });
            } else {
              let lastTrackingStatus = '';
              const { tracking = null } = examResponseCorrectionIsDone

              if (tracking && tracking[tracking.length - 1].status)
                lastTrackingStatus = tracking[tracking.length - 1].status

              if (examResponseCorrectionIsDone && lastTrackingStatus.toLowerCase() === "corrigido") {
                return history.push(`/assessment-view?assessmentCode=${assessmentData.assessmentCode}&responseCode=${examResponseCrCode}`)
              }
              skipCloseMessage.current = true;
              window.close();
            }
          }
          if (assessmentData.assessmentType === 'FINAL_EXAM') {
            localStorage.setItem('openProgress', JSON.stringify(true));
            window.close();
          }
        }
      });
    } catch (error) {
      swal({
        title: `Ops, ocorreu um erro.`,
        text: `Não foi possível enviar as respostas dessa sua tentativa, tente novamente!`,
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            className: 'btn-alert btn-alert'
          }
        }
      });
    }
    setLoadingFinishAssessment(false);
  }

  function handleExit() {
    swal({
      content: <ExitAlert />,
      buttons: {
        confirm: {
          text: 'Sair',
          value: true,
          visible: true,
          className: 'btn-alert btn-alert-exit-assessment'
        },
        cancel: {
          text: 'Permanecer',
          value: false,
          visible: true,
          className: 'btn-alert btn-alert-stay-assessment'
        }
      }
    }).then(value => {
      if (value) {
        skipCloseMessage.current = true;
        window.close();
      }
    });
  }

  const handleAnswerChange = useCallback(
    (selectedAlternativeIndex, value) => {
      setAnswers(prevState => {
        const newState = prevState.map((surveyAnswers, surveyIndex) => {
          if (surveyIndex === currentSurveyIndex) {
            return {
              ...surveyAnswers,
              alternatives: (surveyAnswers.alternatives || []).map(
                (alternative, alternativeIndex) => {
                  if (alternativeIndex === selectedAlternativeIndex) {
                    return {
                      ...alternative,
                      value
                    };
                  }

                  if (
                    currentSurvey.data.type === 'SINGLE_CHOICE' ||
                    currentSurvey.data.type === 'PROGRESSIVE'
                  ) {
                    return {
                      ...alternative,
                      value: false
                    };
                  }

                  return alternative;
                }
              )
            };
          }

          return surveyAnswers;
        });
        return newState;
      });
    },
    [currentSurveyIndex, currentSurvey]
  );

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    skipCloseMessage.current = true;
    window.close();
  }, [responseData])

  return (
    <>
      {loading && (
        <Loading>
          <img
            alt="loading"
            style={{
              maxHeight: '85%',
              animation: 'loading-spin infinite 10s linear'
            }}
            src={spinner}
          />
        </Loading>
      )}

      {!loading && assessmentData && (
        <>
          <NavBar style={{ marginBottom: 0 }} />
          <Container>
            <Header
              allowChange={assessmentData.allowReturn}
              numberOfQuestions={numberOfSurveys}
              limitTime={assessmentData.timeLimit}
              currentValue={currentSurveyIndex}
              concludes={answeredSurveys}
              notCompleted={unAnsweredSurveys}
              onChange={setCurrentSurveyIndex}
              onFinish={() =>
                swal({
                  title: `Ops, acabou o tempo.`,
                  text: `Infelizmente você não terminou a avaliação antes do tempo, clique abaixo para fechar a avaliação.`,
                  buttons: {
                    confirm: {
                      text: 'Continuar',
                      value: true,
                      visible: true,
                      className: 'btn-alert btn-alert-confirm-onboarding'
                    }
                  }
                }).then(value => {
                  if (value) {
                    handleFinish(true);
                  }
                })
              }
            />
            <Content>
              <main>
                {currentSurveyIndex === numberOfSurveys &&
                  assessmentData.assessmentType !== 'FINAL_EXAM' && (
                    <p>
                      Você chegou ao fim, clique em Finalizar para ver nossa recomendação de
                      quais conteúdos você poderia pular,
                      pois já tem conhecimento sobre o tema, e quais deve focar.
                    </p>
                  )}

                {currentSurveyIndex === numberOfSurveys &&
                  assessmentData.assessmentType === 'FINAL_EXAM' && (
                    <p>
                      Você chegou ao fim, clique em Finalizar para enviar suas
                      respostas e conferir quais módulos você precisa estudar
                      mais.
                    </p>
                  )}
                {currentSurveyIndex !== numberOfSurveys && (
                  <SurveyView
                    surveyData={currentSurvey.data}
                    surveyAnswers={currentSurvey.answers}
                    onAnswerChange={handleAnswerChange}
                    onUserComment={setUserComment}
                    userComment={userComment}
                    numberQuestion={currentSurveyIndex + 1}
                    onDisableActions={disabled => {
                      setActionsDisabled(disabled);
                    }}
                  />
                )}
              </main>
              {showModal && (
                <AssessmentModal
                  showModal={showModal}
                  closeModal={handleCloseModal}
                  assessment={assessment}
                />
              )}
            </Content>
            <footer>
              <ActionFooter
                disabled={actionsDisabled}
                allowGoBack={assessmentData.allowReturn}
                onGoBack={handleGoBack}
                onGoNext={handleGoNext}
                onConfirm={handleConfirmAnswer}
                showFinishButton={currentSurveyIndex === numberOfSurveys}
                disableNextButton={currentSurveyIndex === numberOfSurveys - 1}
                onFinish={handleFinish}
                onExit={handleExit}
                loading={loadingFinishAssessment}
              />
            </footer>
          </Container>
        </>
      )}
    </>
  );
}

export default Assessment;
