import styled from "styled-components";
import Slider from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import { } from "../../../constants";

export const Carousel = styled(Slider)``;

export const Limit = styled.span`
  position: relative;
  .alice-carousel { padding-left: 5px; }
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    position: absolute;
    top: 0;
    height: 145px;
    width: 50px;
    background: var(--button-arrow-background);
    line-height: 140px;
    transition: all .25s ease;
    opacity: 0;
    p {
      :before {
        content: "";
        color: var(--button-arrow-text);
        font-weight: 800;
        font-family: monospace;
        font-size: 54px;
      }
      span {
        display: none;
      }
    }
    @media (max-width: 1440px) {
      height: 180px;
    }
    @media (max-width: 1200px) {
      height: 150px;
    }
  }
  .alice-carousel__prev-btn {
    border-radius: 0 4px 4px 0;
    left: 0;
    p { 
      :before {
        content: "<";
      }
    }
  }
  .alice-carousel__next-btn {
    border-radius: 4px 0 0 4px;
    right: 0;
    p { 
      :before {
        content: ">";
      }
    }
  }
  &:hover .alice-carousel__prev-btn,
  &:hover .alice-carousel__next-btn {
    opacity: 1;
  }
  .alice-carousel__prev-btn-item.__inactive, 
  .alice-carousel__next-btn-item.__inactive {
    opacity: 0.25;
  }
  .alice-carousel__dots {
    margin: 5px 0 0;
    li {     
      width: 28px;
      margin-right: 8px;
      border-radius: 5px;
      &.__active { background: var(--overflow-bar)!important; }
      &:hover { background: var(--overflow-track); }
    }
  }
`;
