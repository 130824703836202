import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { Container, Title, BoxInfo } from "./styles";

function Header({name}) {

  const [headerGradient, setHeaderGradient] = useState(false);
  const [tenant] = useState(localStorage.getItem('tenant') || '');

  const [settings] = useState(JSON.parse(localStorage.getItem('settings')) || null);

  useEffect(() => {
    const { headerBackgroundColor } = _.get(
      settings,
      `${tenant}`,
      {}
    );
    setHeaderGradient(headerBackgroundColor)
  }, [tenant, settings])

  return (
    <Container>
      <BoxInfo headerGradient={headerGradient}>
        <Title headerGradient={headerGradient}>{name}</Title>
      </BoxInfo>
    </Container>
  );
}

export default Header;
