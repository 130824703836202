import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} id //tab link activation key
 * @param {Component} children //content displayed within the tab link
 * @param {String} appearance //{FEED} tab link appearance
**/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
  id = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        child={children}
        id={id}
        appearance={appearance}
      />
    </>
  );
};
