import styled from 'styled-components';

export const Container = styled.div`
  width: 15rem;
  height: 10rem;
  cursor: pointer;
  background: #FFFFF;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 0;
  text-align: left;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  display: block;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  margin-top: 10px;
`;

export const HeaderContainer = styled.div`
  padding: 1rem;
  background: #27aae1;
  text-align: center;
`;

export const Title = styled.h4`
  margin: 0;
  font-size: 1rem;
`;

export const Message = styled.p`
  margin: 0;
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const DateMessage = styled.p`
  margin-top: auto;
  font-size: 1rem;
  overflow: hidden;
`;

export const ColIcon = styled.div`
  width: 15%;
  background-color: rgb(123, 197, 149);
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const ColDefault = styled.div`
  padding: 10px;
  display: flex;
  width: 100%;
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const Row = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  display: flex;
  width: 100%;
  margin-top: 5px;
`;

export const Col = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  align-items: center;
  align-content: center;
`;

export const CardContainer = styled.div`
  width: 15rem;
  height: 10rem;
  overflow: hidden;
  cursor: pointer;
  background: #FFFFF;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 0;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  position: relative;
`;

export const CardBody = styled.div`
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
  width: 18rem;
  height: 12rem;
`;

export const CardHeader = styled.header`
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 0.25rem solid #27aae1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const CardTitle = styled.h4`
  margin: 0;
  font-size: 1rem;
  color: #231f20;
  font-family: Dosis, sans-serif bold;
`;

export const CardContent = styled.div`
  line-height: 1.6;
  padding: 1rem;
`;

export const CardText = styled.p`
  font-size: 0.825rem;
  color: #231f20;
  margin: 0;
`;

export const CardTime = styled.time`
  font-size: 0.75rem;
  color: #231f20;

  &:hover {
    color: #27aae1;
  }
`;

export const CardFooter = styled.footer`
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardButton = styled.button`
  background: #27aae1;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 8px;
  border: none;
  font-family: Lato, sans-serif;
  width: 4rem;
  height: 2rem;
  min-width: 4rem;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

export const CardDivision = styled.div`
  width: 14rem;
  height: 0.375rem;
  background: #27aae1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;
