import React, { memo } from "react"
import Grupo from "./Grupo"
import { randomNumber } from "../../../utils/constants"

const IMG_SOLL_OFF = require("../../../images/solicitacao_off.png")

const styles = {
  row: {
    marginRight: 0,
    marginLeft: 0,
  },
}

const Solicitacoes = ({ openInvites }) => {
  return (
    <div className="card mb-3 ">
      <div className="card-header with-flex">
        <span className="titulo" data-tip="Veja todas os grupos que solicitou participação">
          <img alt="solicitacoes" src={IMG_SOLL_OFF} width={25} style={{ marginRight: 5 }} />
          Solicitações
        </span>
      </div>
      <div className="row" style={styles.row}>
        {openInvites.data.map(invite => (
          <Grupo grupo={invite} key={randomNumber()} pending />
        ))}
      </div>
    </div>
  )
}

export default memo(Solicitacoes)
