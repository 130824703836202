import React, {useMemo, useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import swal from "@sweetalert/with-react";
import _ from 'lodash';

import history from '../../../../../../utils/history';
import {gateway} from '../../../../../../utils/api';

import Loading from '../../../../../Loading/Loading';
import Screen from './screen';

function ContentCourseModal({course, turma}) {
  const [loadingEvaluation, setLoadingEvaluation] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingLearningObjects, setLoadingLearningObjects] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const [events, setEvents] = useState([]);
  const [learningObjects, setLearningObjects] = useState([]);

  const courseProgress = useMemo(() => {
    return (100 *  course.countFinishedObjects) / course.countObjects || 0;
  }, [course]);

  const handleRedirectCourse = useCallback((code) => {
    history.push(`/curso-novo/${code}`)
  }, []);

  const handleGoToLearningObjectCourse = useCallback((learningObject) => {
    const courseCode = _.get(learningObject, 'courseCode');
    const objectCrCode = _.get(learningObject, 'crCode');
    history.push(
      {
        pathname: `/curso-novo/${courseCode}`
      },
      {
        object: objectCrCode
      }
    );
  }, []);

  const handleViewCertificate = useCallback((certificate) => {
    window.open(certificate.url, "_blank");
  }, []);

  useEffect(() => {
    async function loadEvaluations() {
      try {
        setLoadingEvaluation(true);
        const url = `webbff/lit_app_web/course/${course.code}/evaluations`;
        const response = await gateway.get(url);
        setEvaluations(response);
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingEvaluation(false);
      }
    }

    async function loadEvents() {
      try {
        setLoadingEvents(true);
        const url = `webbff/lit_app_web/event/course`;
        const body = {
          classCode: turma.contentCode,
          courseCode: course.code
        }
        const response = await gateway.post(url, body);
        setEvents(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingEvents(false);
      }
    }

    async function loadLearningObjects() {
      try {
        setLoadingLearningObjects(true);
        const url = `webbff/lit_app_web/classes/${course.code}/objects`;
        let response = await gateway.get(url);
        setLearningObjects(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingLearningObjects(false);
      }
    }

    loadEvaluations();
    loadEvents();
    loadLearningObjects();
  }, [course, turma]);

  return (
    <>
      {(loadingEvaluation || loadingEvents || loadingLearningObjects) && (
        <Loading img width={30} height={30} />
      )}
      {(!loadingEvaluation && !loadingEvents && !loadingLearningObjects) && (
        <Screen 
          course={course}
          events={events}
          learningObjects={learningObjects}
          evaluations={evaluations} 
          courseProgress={courseProgress} 
          redirectToCourse={handleRedirectCourse}
          goToLearningObjectCourse={handleGoToLearningObjectCourse}
          viewCertificate={handleViewCertificate}
          loadingEvaluation={loadingEvaluation}
          loadingEvents={loadingEvents}
        />
      )}
   </>
  );
}

ContentCourseModal.propTypes = {
  course: PropTypes.object.isRequired,
  turma: PropTypes.object.isRequired
};

export default ContentCourseModal;
