import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import notify from '../../notify'
import { Title, Button } from "./styles";

export default ({ couponLink }) => {

  const handleCopyToClipboard = (type) => {
    if(type === 'link') {
      notify(`Copiado link de desconto`)
    } else {
      notify('Copiado cupom de desconto')
    }
  }
  
  return (
    <div className="row">
      <div className="col-md-12" style={{marginBottom: '20px'}}>
        <Title>Link com desconto</Title>
      </div>
      <div className="col-md-9">
        <div className="field-float-label-pattern">
          <input
            type="text"
            id="cupomOldUser"
            autoComplete="off"
            className="form-control input-not-yellow"
            value={couponLink}
            disabled
          />
        </div>
      </div>
      <div className="col-md-3">
        <CopyToClipboard text={couponLink} onCopy={() => handleCopyToClipboard('link')}>
          <Button className="btn btn-blue">
            Copiar
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
}
