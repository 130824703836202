import React from "react";
import Screen from "./screen";
import { TIMELINE_ACTIONS, DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} title //title that will be displayed above the block
 * @param {Array} feedItems //list of items to be presented in cards
 * @param {Number} evaluationGrid //number of evaluation blocks that are displayed per line
 * @param {Number} perLine //number of blocks that are displayed per line
 * @param {Array} notifications //content list of notifications
 * @param {Boolean} hasNextPage //function that checks if there is more content to be loaded
 * @param {Function} nextPage //function that loads content on the scroll
**/

export default ({
  title = DEFAULT_VALUES.STRING,
  feedItems = DEFAULT_VALUES.LIST,
  evaluationGrid = DEFAULT_VALUES.NUMBER,
  perLine = DEFAULT_VALUES.NUMBER,
  notifications = DEFAULT_VALUES.LIST,
  hasNextPage = DEFAULT_VALUES.BOOLEAN,
  nextPage = DEFAULT_VALUES.FUNCTION
}) => {

  const orderFilter = [
    {
      action: null,
      description: TIMELINE_ACTIONS.ORDER
    },
    {
      action: null,
      description: TIMELINE_ACTIONS.RESPONSES
    },
    {
      action: null,
      description: TIMELINE_ACTIONS.MENTIONS
    },
    {
      action: null,
      description: TIMELINE_ACTIONS.COURSE_SUGESTIONS
    },
    {
      action: null,
      description: TIMELINE_ACTIONS.GROUP_SUGESTIONS
    },
  ];

  return (
    <>
      <Screen
        title={title}
        feedItems={feedItems}
        evaluationGrid={evaluationGrid}
        perLine={perLine}
        notifications={notifications}
        hasNextPage={hasNextPage}
        nextPage={nextPage}
        orderFilter={orderFilter}
      />
    </>
  );
};
