/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

const TrilhasLIT = () => {
  const [visibleBody, setVisibleBody] = useState(false);

  const changeVisible = () => {
    setVisibleBody(!visibleBody);
  };

  return (
    <div className="box-tabs">
      <div>
        <div className="row cursor-pointer" onClick={changeVisible}>
          <div className="col-12 headerTab">
            <h5 className="d-inline-block" style={{ fontSize: '1.3em' }}>
              6. O que são as Trilhas do LIT?
            </h5>
            <button id="btn-tab" type="button" className="btn btn-icon ml-auto">
              {visibleBody ? (
                <i className="fa fa-chevron-up" aria-hidden="true" />
              ) : (
                <i className="fa fa-chevron-down" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
        {visibleBody && (
          <div className="row">
            <div className="col">
              <p>
                As Trilhas do LIT são cursos agrupados e ordenados por nosso
                time de especialistas, de acordo com cada área de conhecimento,
                para que sua aprendizagem seja mais completa. Ao realizar todos
                os cursos que compõem a Trilha, você receberá um certificado por
                cada curso concluído e outro certificado da Trilha, que
                contempla a carga horária total de todos os cursos feitos.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrilhasLIT;
