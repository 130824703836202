import React, { memo, useState, useEffect } from "react";
import _ from 'lodash';
import styled from "styled-components";
import axios from "axios";
import { gateway } from "../../utils/api";

import CardMyCourse from "./CardMyCourse";

import CustomContentLoader from "../Home/CustomContentLoader";

const WrapMyCourses = styled.div`
  overflow-x: hidden;
  min-height: 62.3vh;
`
const MyCoursesTitle = styled.h6`
  font-size: 1.65em;
  padding: 10px;
`

const MyCouses = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 10px;
`

const { CancelToken } = axios
const source = CancelToken.source();

const LaneNewCoursesInProgress = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCoursesProgress = async () => {
    setLoading(true);
    const url = "webbff/lit_app_web/home/lane/type/LANE_IN_PROGRESS";
    try {
      const response = await gateway.get(url, { CancelToken: source.token });
      setCourses(_.get(response, 'docs', []));
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCoursesProgress();
    return () => {
      source.cancel("Cancelado por troca de rota")
    }
  }, []);


  if(loading) {
    return <CustomContentLoader tooltipText="Acesse os cursos que você iniciou e ainda não concluiu" title="Carregando meus cursos" />
  }

  return (
    <WrapMyCourses>
      <MyCoursesTitle>Meus Cursos e Trilhas</MyCoursesTitle>
      <MyCouses>
        {courses.length === 0 ? (
          <h5>Nenhum curso encontrado para exibição</h5>
        ) : (
          courses.map(course => {
            return (
              <CardMyCourse
                key={`meu-curso-${course.idCourse}`}
                id={course.idCourse}
                nome={course.curso}
                course={course}
              />
            )
          })
        )}
      </MyCouses>
    </WrapMyCourses>
  )
}

export default memo(LaneNewCoursesInProgress)
