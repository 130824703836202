import React from "react";
import { TabContainer } from "./styles";

export default ({ child, id }) => {
  return (
    <>
      <TabContainer defaultActiveKey={id}>{child}</TabContainer>
    </>
  );
};
