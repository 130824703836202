/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import _ from "lodash";
import { toast } from "react-toastify"
import md5 from 'md5'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    // background: 'linear-gradient(45deg, rgba(2, 196, 255, 1) 25%, rgba(142, 230, 123, 1) 100%)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalProfile ({ visible, onClose, content }) {

  const [headerBackgroundByTenant, setHeaderBackgroundByTenant] = useState('')

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    const tenantOnboarding = localStorage.getItem('tenantOnboarding')
    const { headerBackgroundColor } = _.get(
      settings,
      `${tenantOnboarding}`,
      {}
    );
    setHeaderBackgroundByTenant(headerBackgroundColor)
  }, [])

    const classes = useStyles();
		return (
			<>
           <Dialog fullScreen open={visible} onClose={onClose} TransitionComponent={Transition}>
           <AppBar style={{ backgroundColor: headerBackgroundByTenant}} className={classes.appBar}>
             <Toolbar>
               <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                 {/* <CloseIcon /> */}
               </IconButton>
               <Typography variant="h6" className={classes.title}>
                 Onboarding
               </Typography>
               <Button autoFocus color="inherit" onClick={onClose}>
                 Fechar
               </Button>
             </Toolbar>
           </AppBar>
            {content}
         </Dialog>
      </>
		)
}