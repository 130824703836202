/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import SideBarMenuItemUC from '../SideBarMenuItemUC';

function SobreMenuItem({ data, openCourseProgress }) {
  const handleClick = () => {
    if (openCourseProgress) {
      openCourseProgress(data);
    }
  };
  return <SideBarMenuItemUC
            tip="Visualize o seu progresso nesse curso"
            title="Feedback do curso"
            handleSelectedItem={handleClick}
            icon="comment"
          />
}

export default SobreMenuItem;
