import React, { Component } from "react"
import { connect } from "react-redux"
import Modal from "react-responsive-modal"
import ModalVideo from "../../ModalVideo"
import api from "../../../utils/api"

class Praticar extends Component {
  state = {
    modal: false,
  }

  componentDidMount = () => {
    this.openModal()
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props
    if (prevProps.selected.url !== selected.url) {
      this.openModal()
    }
  }

  openModal = () => {
    this.setState({ modal: true })
  }

  closeModal = () => {
    this.setState({ modal: false })
  }

  openForum = async () => {
    try {
      const { selected } = this.props
      const response = await api.get(selected.url)
      const urlToOpen = response.message
      this.closeModal()
      if (response.status === 200) {
        window.open(urlToOpen, "newwindow", "width=900,height=700")
      }
    } catch (error) {
      console.log(error.response)
    }
  }

  render() {
    const { modal } = this.state
    const { selected } = this.props
    return (
      <Modal
        open={modal}
        onClose={this.closeModal}
        classNames={{ modal: "lit-perfil-form" }}
        closeOnOverlayClick={false}
      >
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              justifyContent: "center",
            }}
          >
            <h5
              className="modal-title"
              style={{
                color: "#27aae1",
              }}
            >
              {selected.attachmentName}
            </h5>
          </div>
          <div
            className="modal-body"
            style={{
              background: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ModalVideo indexTutorial={7} titleModal="Forum" body>
              <div
                style={{
                  width: "70%",
                  textAlign: "center",
                }}
              >
                <p>
                  Cada curso do LIT tem três tipos de avaliações: estudo de caso, avaliação prática
                  individual e avaliação final.{" "}
                </p>
                <p>
                  A partir do momento em que você realiza qualquer avaliação no curso, o LIT considera
                  que você o está cursando para obtenção de certificado. Você tem 90 dias para realizar
                  todas as avaliações desse curso e será considerado aprovado quando obtiver nota igual
                  ou superior a 7,0 (sete) em cada uma das avaliações.{" "}
                </p>
                <p>
                  A Avaliação por Estudo de caso é feita por meio de fóruns moderados pelos tutores
                  onlearning, que promovem a discussão de conteúdos ao longo da disciplina, de modo a
                  proporcionar reflexão, dinamizar e trazer as discussões para a prática dos negócios. Os
                  tutores onlearning avaliam a sua participação individual na discussão e atribuem uma
                  nota à essa participação. Esse fórum é assíncrono, ou seja não tem hora marcada.
                </p>
                <p>
                  Geralmente tem um Estudo de Caso por Unidade de Conhecimento, somando em média 4 por
                  curso. A qualidade das suas participações serão avaliadas pelo Tutor Onlearning e você
                  receberá sua nota em até 10 dias úteis.
                </p>
                <p>
                  Para outras perguntas relacionadas às avaliações, acesse o FAQ ou pergunte ao Paul.
                </p>
                <p>
                  Você será levado para o ambiente de avaliações do LIT. Por favor desabilite o
                  bloqueador de pop-up do seu navegador.{" "}
                </p>
              </div>
            </ModalVideo>

            <button type="button" className="btn btn-blue mt-3" onClick={this.openForum}>
              Iniciar fórum avaliativo
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  selected: state.course.selected,
})

export default connect(mapStateToProps)(Praticar)
