import React from "react";
import { PageLeftContent } from "./styles";

export default ({ itemsToRender }) => {
  return (
    <>
      <PageLeftContent>{itemsToRender}</PageLeftContent>
    </>
  );
};
