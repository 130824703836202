import React from "react";
import { Spacings } from "./styles";

export default ({ size, child }) => {
  return (
    <>
      <Spacings size={size}>{child}</Spacings>
    </>
  );
};
