import React from "react";
import Icon from "../Icon";
import Text from "../Text";
import Clickable from "../Clickable";
import { Button, Agrupable } from "./styles";

export default ({
  border,
  appearance,
  color,
  mode,
  size,
  orientation,
  position,
  onClick,
  onLeave,
  onEnter,
  icon,
  iconPosition,
  text,
  badgeValue,
  valueInteractions,
  actionValueInterations
}) => {
  return (
    <Agrupable position={position}>
      <Button
        type="button"
        onClick={onClick}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        border={border}
        appearance={appearance}
        color={color}
        mode={mode}
        size={size}
        orientation={orientation}
        badgeValue={badgeValue}
      >
        {icon && <Icon icon={icon} color={color} iconPosition={iconPosition} />}
        {text}
      </Button>
      {valueInteractions > 0 && (
        <Clickable onClick={actionValueInterations} appearance={appearance}>
          <Text text={`(${valueInteractions})`} size={size} />
        </Clickable>
      )}
    </Agrupable>
  );
};
