import styled, { css } from "styled-components";
import { SIZE } from "../../../constants";

export const Spacing = styled.div`
  
  ${props => (props.size === SIZE.EXTRA_SMALL) && css`
    padding: 10px 0 0!important;
    @media (max-width: 767px) {
      margin: 2px 0 0!important;
    }
  `}
  
  ${props => (props.size === SIZE.SMALL) && css`
    padding: 1rem 0 0!important;
    @media (max-width: 767px) {
      margin: 10px 0 0!important;
    }
  `}

  ${props => (props.size === SIZE.MEDIUM) && css`
    padding: 2rem 0 0!important;
    @media (max-width: 767px) {
      padding: 1rem 0 0!important;
    } 
  `}

  ${props => (props.size === SIZE.LARGE) && css`
    padding: 4rem 0 0!important;
    @media (max-width: 767px) {
      padding: 2rem 0 0!important;
    }
  `}
  
`;
