/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-const-assign */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { FaUserGraduate } from 'react-icons/fa';

import * as Yup from 'yup';
import Upload from '../../../Onboarding/components/OnboardingPageDynamic/subcomponents/Upload';

import { validateDate } from '../../../../utils/functions';

import Autocomplete from '../../Autocomplete';

import {
  Form,
  TextErro,
  Header,
  HeaderBoxIcon,
  HeaderText,
  ButtonCancel,
  ButtonConfirm,
  InputMaskDate,
  Label,
  ContainerDates
} from './styles';

const validationSchema = Yup.object().shape({
  wx_course: Yup.string()
    .required('Campo obrigatório')
    .default(''),
  wx_institution: Yup.string()
    .required('Campo obrigatório')
    .default(''),
  wx_formationtype: Yup.string()
    .required('Campo obrigatório')
    .default(''),
  dx_dateinit: Yup.string()
    .required('Campo obrigatório')
    .default(''),
  dx_dateend: Yup.string()
    .required('Campo obrigatório')
    .default('')
});

export default function AcademicExperienceForm({
  cancel,
  submitForm,
  item,
  isPosGraduate = false,
  isCourse = false,
  options,
  courses,
  institutions,
  onSearch,
  loadingInstitutions,
  loadingCourses
}) {
  const [dateInitError, setDateInitError] = useState('');
  const [dateEndError, setDateEndError] = useState('');
  const [answer, setAnswer] = useState(item && item.wu_certificateurl);

  let isInProgrees = false;
  const [inProgress, setInProgress] = useState(isInProgrees);

  useEffect(() => {
    const element = document.getElementById('academicForm');
    element.scrollIntoView({ behavior: 'smooth' });

    if (item && item.bf_inprogress) {
      isInProgrees = true;
    } else {
      isInProgrees = false;
    }
    setInProgress(isInProgrees);
  }, [item]);

  function handleSubmitForm(formValues) {
    const { rk_academicexperienceid, id = Date.now() } = item || {};
    let data = {
      id,
      bf_postgraduate: isPosGraduate,
      bf_inprogress: inProgress,
      wu_certificateurl: answer,
      ...formValues
    };

    if (rk_academicexperienceid) {
      data = {
        rk_academicexperienceid,
        ...data
      };
    }

    if (dateInitError || dateEndError) {
      return;
    }

    submitForm(data);
  }

  function showDateErrorMessage({
    firstYear,
    secondYear,
    firstMonth,
    secondMonth
  }) {
    if(firstYear === secondYear && firstMonth > secondMonth) {
      setDateInitError(
        'A data de início não pode ser maior que a de conclusão'
      );
      return;
    }

    if (firstYear > secondYear) {
      setDateInitError(
        'A data de início não pode ser maior que a de conclusão'
      );
      return;
    }

    setDateInitError('');
    setDateEndError('');
  }

  function handleValidateDate(e, values) {
    if (!values.dx_dateend) {
      setDateInitError('');
    }
  
    if (e.target.value) {
      const isValidDate = validateDate(e.target.value);
  
      if (!isValidDate) {
        if (e.target.name === 'dx_dateinit') {
          setDateInitError('Data inválida');
        }
  
        if (e.target.name === 'dx_dateend') {
          setDateEndError('Data inválida');
        }
      } else {
        const newMonth = Number(e.target.value.split('/')[0]);
        const newYear = Number(e.target.value.split('/')[1]);

        const startMonth = Number(values.dx_dateinit.split('/')[0]);
        const startYear = Number(values.dx_dateinit.split('/')[1]);

        const endMonth = Number(values.dx_dateend.split('/')[0]);
        const endYear = Number(values.dx_dateend.split('/')[1]);
        

        if (e.target.name === 'dx_dateinit') {
          showDateErrorMessage({
            firstYear: newYear,
            secondYear: endYear,
            firstMonth: newMonth,
            secondMonth: endMonth,
          });
        }
  
        if (e.target.name === 'dx_dateend') {
          showDateErrorMessage({
            firstYear: startYear,
            secondYear: endYear,
            firstMonth: startMonth,
            secondMonth: endMonth,
          });
        }
      }
    }
  }

  function handleChangeMainCourse() {
    setInProgress(value => !value);
    setDateInitError('');
  }

  function searchInstituition(value, type) {
    onSearch(value, type);
  }

  function searchCourses(value, type) {
    onSearch(value, type);
  }

  function setAnswerAtIndex(answer) {
    setAnswer(answer);
  }

  return (
    <Form>
      <div
        id="academicForm"
        style={{ position: 'absolute', top: '-50px' }}
      ></div>
      <Header>
        <HeaderBoxIcon type="button">
          <FaUserGraduate size="14px" />
        </HeaderBoxIcon>
        <HeaderText>{isPosGraduate ? 'Pós-Graduação' : !isPosGraduate && !isCourse ? 'Graduação' : 'Curso/Treinamento'}</HeaderText>
      </Header>
      <Formik
        enableReinitialize
        initialValues={{
          wx_course: item ? item.wx_course : '',
          wx_institution: item ? item.wx_institution : '',
          wx_formationtype: item ? item.wx_formationtype : isCourse ? 'Curso' : '',
          dx_dateend: item ? item.dx_dateend : '',
          dx_dateinit: item ? item.dx_dateinit : ''
        }}
        validationSchema={validationSchema}
        onSubmit={(formValues, actions) => {
          handleSubmitForm(formValues, actions);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <React.Fragment>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <Label>Curso *</Label>
                  <Autocomplete
                    id="wx_course"
                    name="wx_course"
                    value={values.wx_course}
                    suggestions={courses}
                    withRequestAPI
                    placeholder="Ex: Economia"
                    onHandleClick={handleChange('wx_course')}
                    onHandleChange={handleChange('wx_course')}
                    onHandleSearch={e =>
                      searchCourses(
                        e,
                        isPosGraduate
                          ? 'posgraduate-courses'
                          : 'graduate-courses'
                      )
                    }
                    onHandleBlur={handleBlur('wx_course')}
                    loading={loadingCourses}
                  />

                  {errors.wx_course && touched.wx_course && (
                    <TextErro>{errors.wx_course}</TextErro>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Label>Instituição de Ensino *</Label>
                  <Autocomplete
                    id="wx_institution"
                    name="wx_institution"
                    value={values.wx_institution}
                    suggestions={institutions}
                    withRequestAPI
                    placeholder="Ex: Saint Paul"
                    onHandleClick={handleChange('wx_institution')}
                    onHandleChange={handleChange('wx_institution')}
                    onHandleSearch={e => searchInstituition(e, 'institutions')}
                    onHandleBlur={handleBlur('wx_institution')}
                    loading={loadingInstitutions}
                  />
                  {errors.wx_institution && touched.wx_institution && (
                    <TextErro>{errors.wx_institution}</TextErro>
                  )}
                </div>
              </div>
              {!isCourse && (
                <div className="form-group col-md-4">
                  <Label>
                    {isPosGraduate
                      ? 'Tipo de Pós-Graduação'
                      : 'Tipo de Graduação'}{' '}
                    *
                  </Label>
                  <select
                    className="form-control"
                    required
                    id="wx_formationtype"
                    value={values.wx_formationtype}
                    onChange={handleChange('wx_formationtype')}
                    onBlur={handleBlur('wx_formationtype')}
                    name="wx_formationtype"
                  >
                    <option value="" readOnly>
                      Escolher
                    </option>
                    {options.map(option => (
                      <option
                        key={option.wx_optionvalue}
                        value={option.wx_optionvalue}
                      >
                        {option.wx_optionlabel}
                      </option>
                    ))}
                  </select>
                  {errors.wx_formationtype && touched.wx_formationtype && (
                    <TextErro>{errors.wx_formationtype}</TextErro>
                  )}
                </div>
              )}
              
              </div>
            <ContainerDates className="row">
              <div className="form-group col-md-4">
                <Label>Data de Início *</Label>
                <InputMaskDate
                  className="form-control"
                  id="dx_dateinit"
                  name="dx_dateinit"
                  value={values.dx_dateinit}
                  mask="99/9999"
                  maskChar={null}
                  placeholder="MM/YYYY"
                  onChange={e => {
                    setDateInitError('');
                    handleChange(e);
                  }}
                  onBlur={e => {
                    handleBlur(e);
                    handleValidateDate(e, values);
                  }}
                />
                {errors.dx_dateinit &&
                  touched.dx_dateinit &&
                  !dateInitError && <TextErro>{errors.dx_dateinit}</TextErro>}
                {dateInitError && <TextErro>{dateInitError}</TextErro>}
              </div>
              <div className="form-group col-md-4">
                <Label>
                  {inProgress ? 'Previsão de Conclusão' : 'Data de Conclusão'}{' '}
                </Label>
                <InputMaskDate
                  className="form-control"
                  id="dx_dateend"
                  name="dx_dateend"
                  value={values.dx_dateend}
                  mask="99/9999"
                  maskChar={null}
                  placeholder="MM/YYYY"
                  onChange={e => {
                    setDateEndError('');
                    handleChange(e);
                  }}
                  onBlur={e => {
                    handleBlur(e);
                    handleValidateDate(e, values);
                  }}
                />
                {errors.dx_dateend && touched.dx_dateend && !dateEndError && (
                  <TextErro>{errors.dx_dateend}</TextErro>
                )}
                {dateEndError && <TextErro>{dateEndError}</TextErro>}
              </div>
              <div className="form-group col-md-4">
                <label
                  className="form-check-label"
                  style={{ marginTop: '40px' }}
                >
                  <input
                    className="form-check-input"
                    checked={inProgress}
                    type="checkbox"
                    id="input-check-inprogress"
                    onChange={handleChangeMainCourse}
                  />{' '}
                  Em andamento
                </label>
              </div>
            </ContainerDates>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <Label>Certificado *</Label>
                  <Upload
                    setAnswerAtIndex={urlFile => setAnswerAtIndex(urlFile)}
                    answers={answer}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <ButtonCancel id="btn-cancel" type="button" onClick={cancel}>
                  Cancelar
                </ButtonCancel>
                <ButtonConfirm
                  id="btn-confirm"
                  onClick={handleSubmit}
                  type="button"
                >
                  Salvar
                </ButtonConfirm>
              </div>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </Form>
  );
}
