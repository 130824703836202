/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

import RCSlider from "rc-slider";

import {
  Container,
  Title,
  ContainerAttempts,
  ContainerChart,
  ContainerSlider,
  Button,
} from "./styles";

function Instructions({ assessment }) {
  const [showData, setShowData] = useState(false);
  useEffect(() => {
    if (assessment) {
      setShowData(true);
    }
  }, [assessment]);

  const graphics = (assessment && assessment.graphics) || [];
  const data = graphics.map(item => item.value / 10);

  const labels = graphics.map(item => item.title);
  const legend = graphics.map(item => item.label);
  const color = "#27aae1";

  const dataUcs = () => {
    return {
      labels,
      datasets: [
        {
          label: assessment.name,
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          stack: "1",
          hoverBackgroundColor: color,
          hoverBorderColor: color,
          data
        }
      ]
    };
  };

  const opt = {
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            max: 10
          }
        }
      ]
    }
  };

  const trackingLabels = tracking => {
    const verifyTracking = tracking.reduce(
      (accumulator, item) => {
        if (item.status) {
          accumulator[item.status] = (
            <>
              <p style={{ marginBottom: 0 }}>{item.status}</p>
              <p style={{ marginBottom: 0, marginTop: -70 }}>{item.date}</p>
            </>
          );
        }

        return accumulator;
      },
      {
        "Não iniciado": (
          <>
            <p style={{ marginBottom: 0 }}>Não iniciado</p>
          </>
        ),
        Iniciado: (
          <>
            <p style={{ marginBottom: 0 }}>Iniciado</p>
          </>
        ),
        Entregue: (
          <>
            <p style={{ marginBottom: 0 }}>Entregue</p>
          </>
        ),
        Corrigido: (
          <>
            <p style={{ marginBottom: 0 }}>Corrigido</p>
          </>
        )
      }
    );

    return Object.keys(verifyTracking).map(
      trackingKey => verifyTracking[trackingKey]
    );
  };

  const Slider = RCSlider;

  const renderMarks = (max, labels) => {
    const marks = {};
    for (let i = 0; i <= max; i++) {
      marks[i] = labels[i];
    }
    return marks;
  };

  const goToAssessmentView = code => {
    const redirectRoute = `/assessment-forum?code=${code}`;
    window.open(redirectRoute, "__blank");
  };

  return (
    showData && (
      <Container>
        <Title>Desempenho {assessment.name}</Title>
        {assessment.cr_code === "Fóruns" && (
          <ContainerChart>
            <React.Fragment>
              <Bar data={dataUcs} options={opt} />
              <legend className="ml-3">
                {labels.map((item, index) => (
                  <div style={{ fontSize: 13 }} key={item}>
                    <b>{item}</b>
                    <p style={{ display: "inline" }}>{`: ${legend[index]}`}</p>
                  </div>
                ))}
              </legend>
            </React.Fragment>
          </ContainerChart>
        )}

        {assessment.attempts && assessment.attempts.length === 0 && (
          <p className="text-center" style={{ marginTop: "20px" }}>
            Sem informações de desempenho apara essa avaliação.
          </p>
        )}

        {assessment.attempts && assessment.attempts.length > 0 && (
          <div style={{ height: 500, overflow: "auto" }}>
            {assessment.attempts.map((item, index) => {
              return (
                <ContainerAttempts key={index}>
                  {item.tracking && (
                    <ContainerSlider>
                      <Slider
                        dots
                        dotStyle={() => {
                          return <span>123123</span>;
                        }}
                        disabled
                        id={`component-${item}`}
                        trackStyle={[
                          {
                            backgroundColor: "#27aae1",
                            borderColor: "#27aae1"
                          },
                          { backgroundColor: "#27aae1" }
                        ]}
                        // railStyle={{ height: 5 }}
                        defaultValue={item.tracking.length}
                        min={0}
                        max={3}
                        marks={renderMarks(3, trackingLabels(item.tracking))}
                      />
                    </ContainerSlider>
                  )}
                  <div className="table-responsive">
                    <table className="table table-sm table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Módulo</th>
                          <th scope="col">Data</th>
                          <th scope="col">Nota</th>
                          <th scope="col" width="30%">
                            Resultado
                          </th>
                          <th scope="col"> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{item.contentName}</td>
                          <td>{item.date}</td>
                          <td>{(Math.floor(item.value) / 10).toFixed(1)}</td>
                          <td>{item.result}</td>
                          <td>
                            <Button
                              disabled={
                                item.tracking &&
                                item.tracking[item.tracking.length - 1]
                                  .status !== "Corrigido"
                              }
                              onClick={() => goToAssessmentView(item.crCode)}
                            >
                              Ver avaliação
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ContainerAttempts>
              );
            })}
          </div>
        )}
      </Container>
    )
  );
}

Instructions.propTypes = {
  assessment: PropTypes.object.isRequired
};

export default Instructions;
