import styled from 'styled-components';
import { Pix } from "styled-icons/fa-brands"
import {  FileInvoice } from "styled-icons/fa-solid"
import { CreditCard2BackFill } from "styled-icons/bootstrap"
import { Check } from "styled-icons/boxicons-regular"
import { Discount } from "styled-icons/boxicons-solid"
import { Close } from "styled-icons/evil"

import { LitButton } from '../../../shared/LitButton';


export const BoxPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const CloseIcon = styled(Close)`
  background: rgba(0,0,0,.6);
  color: #fff;
  width: 19px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -8px;
`

export const PlanName = styled.h3`
  font-size: 20px;
  padding: 0;
  margin-bottom: 10px;
  color: #FCEE1E;
  font-weight: bold;
`


export const PixIcon = styled(Pix)`
  color: #27AAE1;
  width: 30px;
  
`

export const InvoiceIcon = styled(FileInvoice)`
  color: #27AAE1;
  width: 25px;
`

export const CreditIcon = styled(CreditCard2BackFill)`
  color: #27AAE1;
  width: 30px;
`

export const PayrollIcon = styled(Discount)`
  color: #27AAE1;
  width: 35px;
`

export const CheckerIcon = styled(Check)`
  background: #27AAE1;
  color: #fff;
  width: 20px;
`

export const Container = styled.div`
  background-color: #f3f4f7;
  min-height: 100vh;
  padding: 0 0 100px 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
`;

export const Sidebar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    45deg,
    rgba(2, 196, 255, 1) 25%,
    rgba(142, 230, 123, 1) 100%
  );

  padding: 10px 20px;

  .logoLit {
    width: 100px;
  }

  @media (min-width: 992px) {
    height: 100vh;
    .logoLit {
      margin: 30px auto;
      width: 200px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  & > header {
    width: 100%;
  }

  @media (min-width: 992px) {
    & > header {
      width: 90%;
    }

    height: 100%;
  }
`;

export const MainWrapper = styled.div`
  overflow-y: hidden;
  padding-bottom: 15px;
`;

export const PriceWrapper = styled.div`
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px;
  margin-right: 20px;

  small {
    font-size: 16px;
  }
`;

export const PaymentMethods = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  overflow-x: auto;
  align-items: center;

  span {
    font-size: 24px;
    font-weight: bold;
    margin: 0 20px;
    flex-shrink: 0;
    cursor: pointer;
    color: #777;
  }

  span.selected {
    font-size: 30px;
    color: #27AAE1;
  }

  span:hover {
    opacity: 0.8;
  }
`;

export const NavLit = styled.nav`
  background-image: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    /* padding: 0; */
    margin: 0;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ShowCouponButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    width: 25px;
  }
`

export const ButtonPlan = styled.div`
  width: 120px;
  height: 70px;
  border: none;
  color: #dbdbdb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  padding:  5px 10px;
  img {
    max-width: ${props => (props.methodType === "CREDIT" || props.methodType === "PAYROLL") ? "50%" : "100%"};
  }

  @media screen and (max-width: 600px) {
    img {
      max-width: ${props => (props.methodType === "CREDIT" || props.methodType === "PAYROLL") ? "100%" : "100%"};
    }
  }

  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  border: 4px solid ${props => props.active ? ' #27AAE1' : 'transparent'};

  position: relative;

  span {
    visibility: ${props => props.active ? "visible" : "hidden"};
    position: absolute;
    bottom: 0;
    right: 0;
  }

  & + div {
    margin-left: 18px;
  }

  &:focus {
    outline: none;
    border: none;
  }

  &:disabled {
    background: #c4c0c0;
  }

  button {
    width: 100%;
    border: none;
    border-radius: 30px;
    flex: 1;
    cursor: pointer;

    span {
      font-size: 16px;
      font-family: "Lato", sans-serif;
      cursor: pointer;
    }
  }
`;

export const ContainerPlanTypes = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  background: transparent;
  padding: 5px;
`;

export const AndroidPaymentButton = styled.button`
  color: ${props => (props.active ? '#000' : '#777')} !important;
  background: transparent !important;
  font-weight: ${props => (props.active ? 'bold' : 'initial')} !important;
  
  &:hover {
      background:transparent !important;
  }

  span {
    color: ${props => (props.active ? '#000' : '#777')} !important;
  }

`;

export const PaymentResume = styled.div`
  color: white;
  width: 100%;
  border-radius: 5px;
  
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const PaymentTotalPriceView = styled.div`
  background: #27aae1;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  justify-content: space-between;
  width: 100%;
`;

export const PriceResumeDescription = styled.div`
  width: 100%;
`;

export const TotalPriceView = styled.div`
  font-size: 25px;
`;

export const CampaignBackButton = styled.button`
  height: 45px;
  border-radius: 5px;
  padding: 7px 15px;
  text-align: center;
  display: flex;

  background-color: #e4e4e4;
  color: #444;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  font-size: 0.8rem;
`;

export const ContentCenter = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ItemFormFieldSelect = styled.div`
  border: 1px solid #27aae1;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;

  select {
    border: none !important;
    height: 51px !important;
    background: transparent !important;
  }
`;

export const LabelField = styled.label`
  font-weight: bold;
  color: #444;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 20px;
  margin-left: 15px;
`;

export const PaymentTextResume = styled.div`
  color: #444;
`;

export const ContentLoading = styled.div`
  width: 100%;
  min-height: 100vh;
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ItemFormField = styled.div`
  border: 2px solid #27aae1;
  padding: 0 20px;
  border-radius: 15px;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 75%;
  margin-right: 20px;
`;

export const InputText = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 19.9rem; */
  height: 51px;
  border: none;
  background-color: #fff !important;
  border-radius: 10px !important;
  font-size: 16px;
  padding: 0rem 1rem 0rem;

  &:-webkit-input-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19.9rem;
    height: 3.2rem;
    border: none;
    border-radius: 10px;
    font-size: 19px;
    padding: 0rem 1rem 0rem;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus {
    outline: none;
    padding: 0rem 1rem 0rem;
  }

  &::before {
    background: linear-gradient(
      45deg,
      rgba(2, 196, 255, 1) 0%,
      rgba(142, 230, 123, 1) 100%
    );
  }

  &:focus ~ label {
    visibility: visible;
  }
`;

export const CupomBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
  padding: 0 10px;
  width: 48%;
  margin-top: ${props => props.setMarginTop ? "30px" : "0"};
  border: 1px solid ${props => props.showBorder ? "rgba(0,0,0,.1)" : "transparent"};
  @media screen and (max-width: 768px) {
    padding: ${props => props.showBorder ? "15px" : "0 15px"};
    width: ${props => props.showBorder ? "92%" : "100%"};
    margin: 0 auto;
  }
`

export const ConfirmButton = styled(LitButton)`
  margin-top: 0;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`

export const TextInVoice = styled.p`
  font-size: 14px;
  margin: 0;
  width: 100%;
  max-width: 600px;
  span {
    font-weight: bold;
  }
`
