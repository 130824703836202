import React, { Component } from "react"
import { API_URL, headerConfig } from "../../utils/constants"

import api from "../../utils/api"

class Trilhas extends Component {
  state = {
    cursos: [],
    trilhas: [],
    progresso: [],
  }

  componentDidMount() {
    this.fetchTrilhasByUser()
  }

  fetchTrilhasByUser = async () => {
    const {
      profile: {
        idProfile,
        login: { idLogin, username },
      },
    } = this.props

    try {
      const url = `getStudentTrail/${idProfile}/${idLogin}/${username}/`
      const response = await api.get(url)
      console.log(response)
      this.setState({ cursos: response.trailCourse })
      this.reorganizarDadosTrilhasAndamento()
    } catch (error) {
      console.log(error)
    }
  }

  reorganizarDadosTrilhasAndamento = () => {
    if (this.state.cursos.length >= 1) {
      const data = []
      this.state.cursos.map(({ courseTotvs: trilha, disciplines }, index) => {
        const porcentagem =
          trilha.countFinishedObjects === trilha.countObjects
            ? 100
            : (trilha.countFinishedObjects * 100) / trilha.countObjects
        const curso = trilha.curso
        const uc_Concluidas = trilha.countFinishedObjects
        const uc_Totais = trilha.countObjects

        const disciplinas = []
        disciplines.map(disciplina => {
          const nomeDisciplina = disciplina.disciplineName

          const disciplinaConcluidas = disciplina.countFinishedObjects
          const disciplinaTotal = disciplina.countObjects
          const disciplinaFinalizada = disciplinaConcluidas === disciplinaTotal

          let mediaFinal = 0
          disciplina.avaliation.map(avaliacao => {
            return (mediaFinal += avaliacao.gridFinal)
          })
          mediaFinal /= disciplina.avaliation.length

          const object = {
            nomeDisciplina,
            disciplinaConcluidas,
            disciplinaTotal,
            disciplinaFinalizada,
            mediaFinal,
            uc: disciplina.uc,
          }

          return disciplinas.push(object)
        })

        const object = {
          trilha: {
            porcentagem,
            curso,
            uc_Concluidas,
            uc_Totais,
          },
          disciplinas,
        }

        return data.push(object)
      })

      this.setState(() => ({
        trilhas: data,
      }))
    }
  }

  render() {
    return (
      <main>
        <div className=" bg-white-perfil">
          <TrilhasEmAndamento trilhas={this.state.trilhas !== [] ? this.state.trilhas : false} />
        </div>
      </main>
    )
  }
}

export default Trilhas

const TrilhasEmAndamento = props => (
  <div className="bg-white-perfil contentTrails-viewUserVoucher">
    <div className="container-fluid ">
      <div className="row">
        <div className="col-12">
          <h5>Trilhas em andamento</h5>
        </div>
      </div>
      <div className="row ">
        <div className="col-12 trilhas-perfil">
          <p>Alguns dos cursos em andamento fazem parte de outros cursos.</p>
          <p>Acompanhe o desenvolvimento abaixo</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div id="accordion" role="tablist">
            {props.trilhas.length >= 1 ? (
              props.trilhas.map(({ trilha, disciplinas }, index) => (
                <div key={index}>
                  <div className="card">
                    <div className="card-header">
                      <div
                        className="row"
                        data-toggle="collapse"
                        data-target={`#collapse${index}`}
                        aria-expanded="true"
                        aria-controls={`collapse${index}`}
                      >
                        <div className="col-7">
                          <h5 className="mb-0">{trilha.curso}</h5>
                        </div>
                        <div className="col-2">
                          <span>
                            {trilha.uc_Concluidas}/{trilha.uc_Totais}
                          </span>
                        </div>
                        <div className="col-3">
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${trilha.porcentagem}%` }}
                              aria-valuenow={trilha.uc_Concluidas}
                              aria-valuemin="0"
                              aria-valuemax={trilha.uc_Totais}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body col-12 d-flex justify-content-between discipline-progress">
                      <div className="col-4">
                        <span className="discipline-progress_content">
                          {disciplinas.disciplinaAtual}/{disciplinas.totalDisciplinas}
                        </span>{" "}
                        Disciplinas concluídas
                      </div>
                      <div className="col-5">
                        <span className="discipline-progress_content">
                          {disciplinas.uc_AtuaisCompletas}/{disciplinas.uc_AtuaisTotais}
                        </span>{" "}
                        Unidades de conhecimento
                      </div>
                      <div className="col-3">
                        <span className="discipline-progress_content">{disciplinas.tempoRestate}</span>{" "}
                        horas restantes
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>Este usuário possui nenhuma trilha até o momento.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)
