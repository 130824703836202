import React, { Component } from "react"
import api from "../../utils/api"
import ToggleButton from "./ToggleButton"

class GrupoInteresse extends Component {
  state = {
    listaSelecionados: [],
  }

  componentWillMount() {
    this.fetchUserSuggestions()
  }

  componentWillUnmount() {
    this._sendSuggestions()
  }

  _getGrau = idSuggestion => {
    const { listaSelecionados } = this.state
    const index = listaSelecionados.findIndex(suggestion => {
      return suggestion.id === idSuggestion
    })
    if (index !== -1) {
      return `${index + 1}`
    }
    return ""
  }

  handleSelect = idSuggestion => {
    const { listaSelecionados } = this.state
    const { onSelect } = this.props
    const index = listaSelecionados.findIndex(suggestion => {
      return suggestion.id === idSuggestion
    })
    if (index !== -1) {
      this.setState(
        {
          listaSelecionados: listaSelecionados.filter((idSuggestion, i) => i !== index),
        },
        () => onSelect(listaSelecionados)
      )
    } else {
      this.setState(
        {
          listaSelecionados: [
            ...listaSelecionados,
            { id: idSuggestion, grau: listaSelecionados.length + 1 },
          ],
        },
        () => onSelect(listaSelecionados)
      )
    }
  }

  fetchUserSuggestions = () => {
    const idProfile = localStorage.getItem("idProfile")
    const { onSelect } = this.props
    api
      .get(`trackSuggestionByStudent/${idProfile}`)
      .then(response => {
        const selected = []
        response.map(a => {
          const obj = {
            grau: a.size,
            id: a.suggestion.idSuggestion,
            name: a.suggestion.nameSuggestion,
          }
          return selected.push(obj)
        })
        this.setState({
          listaSelecionados: selected,
        })
        onSelect(selected)
      })
      .catch(err => {
        console.log(err)
      })
  }

  _sendSuggestions() {
    const { listaSelecionados } = this.state
    const idProfile = localStorage.getItem("idProfile")

    const suggestionsArray = listaSelecionados.map(suggestion => {
      return {
        idProfile,
        typeSuggestion: suggestion.id,
        size: suggestion.grau,
      }
    })
    api
      .post("setStudentSuggestion", suggestionsArray)
      .then(response => {
        console.log("setting suggestion", response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    const { TrackSuggestionsList } = this.props
    return (
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        className="grupo-interesse col-10"
        data-toggle="buttons"
      >
        {TrackSuggestionsList.map((trackSuggestion, i) => {
          return (
            <ToggleButton
              key={trackSuggestion.typeSuggestion}
              id={`btn-${i}`}
              idTrackSuggestion={trackSuggestion.typeSuggestion}
              name={trackSuggestion.nameSuggestion}
              handleSelect={this.handleSelect}
              grau={this._getGrau(trackSuggestion.typeSuggestion)}
            />
          )
        })}
      </div>
    )
  }
}

export default GrupoInteresse
