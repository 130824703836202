import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} title //content display title
 * @param {String} description //content display description
 * @param {String} image //path (url) of the content display image
 * @param {String} author //name of the author linked to the content
*/

export default ({
  title = DEFAULT_VALUES.STRING,
  description = DEFAULT_VALUES.STRING,
  image = DEFAULT_VALUES.STRING,
  author = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        title={title}
        description={description}
        author={author}
        image={image}
      />
    </>
  );
};
