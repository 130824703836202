import React from 'react'

import {Container} from './styles'

export default function Progress({
  progress, 
  showProgress = true,
  height,
  width,
  background,
  color
}){
  const [style, setStyle] = React.useState({});
  setTimeout(() => {
		const newStyle = {
			opacity: 1,
			width: `${progress}%`
		}
		
		setStyle(newStyle);
	}, 200);
	
	return (
    <Container height={height} width={width} color={color} background={background}>
      {showProgress && (
       <div className="progress">
         <div className="progress-done" style={style}>
           {progress}%
         </div>
        </div>
      )}
      {!showProgress && (
       <div className="progress">
         <div className="progress-done" style={style} />
        </div>
      )}
    </Container>
	)
}