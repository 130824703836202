import _ from "lodash";
import { call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import api, { gateway } from "../../utils/api";
import { toastErrorAndWarningOptions } from '../../utils/functions'
import { Creators as CourseActions } from "../ducks/course/actions";
import { Creators as ProfileActions } from "../ducks/user/profile/actions";
import history from "../../utils/history";

export function* getCourse(action) {
  const { idCourse } = action.payload;
  const email = localStorage.getItem("ra");

  try {
    const response = yield call(gateway.get, `course/${idCourse}/${email}`);

    if (response.lockedCoures) {
      history.push("/home");
      yield put(ProfileActions.toggleModalRestrictLitPass(true));
      return;
    }
    yield put(CourseActions.getCourseSuccess(response));
    let dataAbout;
    let filePath;
    let typeFile;
    const media = response.about.find(res => res.typeAttachment === "PDF");
    if (media && media.path) {
      filePath = media.path;
      typeFile = media.typeAttachment;
    } else {
      filePath = response.about[0].path;
      typeFile = response.about[0].typeAttachment;
    }
    if (response.about.length) {
      if (response.about.length > 1) {
        dataAbout = {
          description: response.about[0].description,
          idAttachment: response.about[1].idAttachment,
          idChildren: response.about[1].idChildren,
          idContent: response.about[1].idContent,
          idDiscipline: response.about[1].idDiscipline,
          type: typeFile,
          name: response.about[0].attachmentName,
          path: filePath
        };
      } else {
        dataAbout = {
          description: response.about[0].description,
          idAttachment: response.about[0].idAttachment,
          idChildren: response.about[0].idChildren,
          idContent: response.about[0].idContent,
          idDiscipline: response.about[0].idDiscipline,
          type: typeFile,
          name: response.about[0].attachmentName,
          path: filePath
        };
      }
    }

    const firstUCArray = _.get(response, "disciplines.0.contents.0", []);
    const firstDiscipline = _.get(firstUCArray, "childrens.0", {});

    if (!response.lastViewedObject) {
      yield put(CourseActions.setCourseSelected(dataAbout, "about"));
    } else if (response.lastViewedObject.type !== "VMP") {
      yield put(CourseActions.setCourseSelected(response.lastViewedObject));
    } else {
      yield put(CourseActions.setCourseSelected(firstDiscipline));
    }
  } catch (err) {
    console.log("Erro", err);
    if (err.response) {
      err.response = {
        ...err.response,
        data: {
          message: err.response.data.message || err.response.data.error,
          ...err.response.data
        }
      };
      if (err.response.status === 401) {
        toast.warn(err.response.data.message, toastErrorAndWarningOptions);
        history.push("/");
        return;
      }
      yield put(CourseActions.getCourseFailure(err.response));
    }
  }
}

export function* getCourseProgress({ payload }) {
  try {
    const ra = localStorage.getItem("ra");
    const response = yield call(
      api.get,
      `getGetCountingObjects/${ra}/${payload.idCourse}/`
    );

    yield put(CourseActions.getCourseProgressSuccess(response));
  } catch (error) {
    console.log(error.response);
  }
}

export function* getDisciplineProgress({ payload }) {
  try {
    const ra = localStorage.getItem("ra");
    const response = yield call(gateway.get, `course/${payload.idCourse}/${ra}`);
    const currentDiscipline = response.disciplines.find(({ idDiscipline }) => idDiscipline === payload.idDiscipline)
    yield put(CourseActions.setDisciplineSucess(currentDiscipline));
  } catch (error) {
    console.log(error.response);
  }
}

export function* syncUserGradeByDiscipline({ payload }) {
  try {
    const state = yield select();

    const idProfile = localStorage.getItem("idProfile");
    const ra = localStorage.getItem("ra");

    const discipline = state.course.data.disciplines.find(
      item => item.idDiscipline === payload
    );
    if (!discipline.synchronized) {
      yield call(gateway.post, "/userGradebook/syncUserGradeByDiscipline", {
        idProfile,
        email: ra,
        idDiscipline: discipline.idDiscipline
      });

      yield put(CourseActions.defineDisciplineAsSynchronizedAction(payload));
    }
  } catch (error) {
    console.log(error);
    yield put(CourseActions.defineDisciplineAsSynchronizedAction(payload));
  }
}

export function* forceSyncUserGradeByDiscipline({ payload }) {
  try {
    const state = yield select();

    const idProfile = localStorage.getItem("idProfile");
    const ra = localStorage.getItem("ra");

    const discipline = state.course.data.disciplines.find(
      item => item.idDiscipline === payload
    );

    yield call(gateway.post, "/userGradebook/syncUserGradeByDiscipline", {
      idProfile,
      email: ra,
      idDiscipline: discipline.idDiscipline
    });

    yield put(CourseActions.setForceSyncDiscipline(true));
  } catch (error) {
    console.log(error);
    yield put(CourseActions.setForceSyncDiscipline(false));
  }
}
