import React from "react"

import logoNaReal from "../../../../images/serieNaRealLogo.jpg"
import IniciativaNaReal from "../../../../images/serieNaRealIniciativa.jpg"
import CuradoriaNaReal from "../../../../images/serieNaRealCuradoria.jpg"
import { BradescoStyle } from "../../../../utils/styles"
import { LoginBox, LoginHeaderBlack, TextHelperBlack } from "../../styles"
import RegisterPartnerForm from "../RegisterPartnerForm"

export default function Bradesco({
  userDetails,
  handleRegisterSuccess,
  handleRegisterFailure,
  resetErrors,
}) {
  document.title = "Na Real - Criar Conta"
  const link = document.querySelector("link[rel*='icon']") || document.createElement("link")
  link.type = "image/x-icon"
  link.rel = "shortcut icon"
  link.href = " https://www.lit.com.br/hubfs/landingpage/bradesco/favicon.ico"
  document.getElementsByTagName("head")[0].appendChild(link)

  return (
    <div className="row" style={{ height: "100vh", background: "#FFF" }}>
      <div className="col-md-7  d-flex flex-column justify-content-around align-items-center bg-white">
        <picture>
          <img width={300} src={logoNaReal} alt="logo na real" />
        </picture>
        <picture className="d-flex w-100 mt-4 flex-wrap justify-content-around">
          <div>
            <h4 className="font-weight-bold mt-3">INICIATIVA</h4>
            <img width={300} src={IniciativaNaReal} alt="iniciativa na real" />
          </div>
          <div>
            <h4 className="font-weight-bold mt-3">CURADORIA E TECNOLOGIA</h4>
            <img width={300} src={CuradoriaNaReal} alt="curadoria na real" />
          </div>
        </picture>
      </div>
      <div
        className="col-md-5"
        style={{
          background: "#f3f2f1",
        }}
      >
        <LoginBox>
          <LoginHeaderBlack>Criar conta</LoginHeaderBlack>
          <TextHelperBlack>
            Esse é o último passo
            <br />
            Crie uma senha e finalize seu cadastro para assistir gratuitamente a série{" "}
            <BradescoStyle>Na Real</BradescoStyle>.
          </TextHelperBlack>

          <RegisterPartnerForm
            bradesco
            userData={userDetails}
            resetErrors={resetErrors}
            doRegisterSuccess={handleRegisterSuccess}
            doRegisterFailure={handleRegisterFailure}
          />
        </LoginBox>
      </div>
    </div>
  )
}
