import React, { Component, Fragment } from "react"
import { maskPhone } from "../../../utils/functions"
import api from "../../../utils/api"
import Tooltip from "../../Tooltip"
import { sendWhats } from "../../Recommendations/utilSendWhats"
import Loading from "../../Loading/Loading"

const StyleIcons = { color: "#27aae1", cursor: "pointer" }

export default class ListaIndicacoes extends Component {
  state = {
    edit: false,
    name: "",
    email: "",
    phone: "",
    loading: false,
  }

  resend = async (indicacao, edit = false, notify = true) => {
    this.setState({ loading: indicacao.id })
    const idProfile = localStorage.getItem("idProfile")
    const {
      location: { host },
    } = window
    const voucher = indicacao.voucher.voucherNumber
    const { email, phone, name } = this.state
    const { notify: notification, refreshList } = this.props
    let emailData
    let phoneData
    let nameData
    let sendEmail
    if (edit) {
      emailData = email
      phoneData = phone ? phone.replace(/\D/g, "") : ""
      nameData = name
      sendEmail = true
      this.setState({ edit: null })
    } else {
      emailData = indicacao.email
      phoneData = indicacao.whatsappNumber ? indicacao.whatsappNumber.replace(/\D/g, "") : ""
      nameData = indicacao.name
      sendEmail = true
    }
    if (indicacao.permitNotif !== notify) {
      sendEmail = false
    }
    const url = `mgmVoucherSent/${voucher}/`
    const data = {
      email: emailData,
      host,
      idProfile,
      name: nameData,
      phone: phoneData,
      sendEmail,
      permitNotif: notify,
    }

    try {
      const response = await api.put(url, data)
      if (indicacao.permitNotif !== notify) {
        notification("Preferência de notificação alterada")
      } else if (phone && email) {
        sendWhats(voucher, name, phone)
        notification("Compartilhado com WhatsApp e email com sucesso")
      } else if (phone && !email) {
        sendWhats(voucher, name, phone)
        notification("Compartilhado com WhatsApp")
      } else if (email && !phone) {
        notification(response.message)
      }
      this.setState({ edit: null, name: "", email: "", phone: "", loading: false })
      refreshList()
    } catch (error) {
      this.setState({ loading: false })
      console.log(error.response)
    }
  }

  formatDate = date => {
    const data = date.split("-")
    return `${data[2]}/${data[1]}/${data[0]}`
  }

  editTable = indic => {
    this.setState({ edit: indic.id, name: indic.name, email: indic.email, phone: indic.whatsappNumber })
  }

  cancelEdit = () => {
    this.setState({ edit: null, name: "", email: "", phone: "" })
  }

  switchStatus = status => {
    switch (status) {
      case "Disponível":
        return <span className="badge badge-info badge-lit-info w-100">{status}</span>
      case "Em Uso":
        return <span className="badge badge-success badge-lit-success w-100">{status}</span>
      case "Em Trial":
        return <span className="badge badge-warning badge-lit-warning w-100">{status}</span>
      case "Cancelado":
        return <span className="badge badge-danger w-100">{status}</span>
      default:
        return null
    }
  }

  renderIcons = indic => {
    const { edit, loading } = this.state
    if (edit === indic.id) {
      return (
        <Fragment>
          <i
            data-for="acoes"
            data-tip="Cancelar edição"
            style={{ cursor: "pointer" }}
            onClick={this.cancelEdit}
            className="fa fa-times"
            aria-hidden="true"
          />
          <i
            data-for="acoes"
            data-tip="Salvar"
            style={StyleIcons}
            onClick={() => this.resend(indic, true)}
            className="fa fa-check"
            aria-hidden="true"
          />
        </Fragment>
      )
    }
    if (loading === indic.id) {
      return <Loading img width={15} height={15} />
    }
    return (
      <Fragment>
        <i
          data-for="acoes"
          data-tip="Editar"
          style={StyleIcons}
          onClick={() => this.editTable(indic)}
          className="fa fa-pencil"
          aria-hidden="true"
        />
        {indic.permitNotif ? (
          <i
            data-for="acoes"
            data-tip="Desativar notificações"
            style={StyleIcons}
            onClick={() => this.resend(indic, false, false)}
            className="fa fa-bell-slash"
            aria-hidden="true"
          />
        ) : (
          <i
            data-for="acoes"
            data-tip="Ativar notificações"
            style={StyleIcons}
            onClick={() => this.resend(indic, false, true)}
            className="fa fa-bell"
            aria-hidden="true"
          />
        )}
        <i
          data-for="acoes"
          data-tip="Reenviar convite"
          style={StyleIcons}
          onClick={() => this.resend(indic)}
          className="fa fa-share-square-o "
          aria-hidden="true"
        />
      </Fragment>
    )
  }

  render() {
    const { edit, name, email, phone } = this.state
    const { indicacoes } = this.props
    return (
      <Fragment>
        <Tooltip id="acoes" />
        <table className="table table-borderless table-sm">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Email</th>
              <th scope="col">WhatsApp</th>
              <th scope="col">Último envio</th>
              <th scope="col">Status</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            {indicacoes.map(indic => (
              <tr key={indic.id}>
                {edit === indic.id ? (
                  <Fragment>
                    <td>
                      <div className="field-float-label-pattern">
                        <input
                          type="text"
                          className="form-control input-not-yellow"
                          value={name}
                          onChange={evt => this.setState({ name: evt.target.value })}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="field-float-label-pattern">
                        <input
                          type="text"
                          className="form-control input-not-yellow"
                          value={email}
                          onChange={evt => this.setState({ email: evt.target.value })}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="field-float-label-pattern">
                        <input
                          type="text"
                          className="form-control input-not-yellow"
                          value={phone}
                          onChange={evt =>
                            this.setState({ phone: maskPhone(evt.target.value.slice(0, 15)) })
                          }
                        />
                      </div>
                    </td>
                  </Fragment>
                ) : (
                  <Fragment>
                    <td>{indic.name}</td>
                    <td>{indic.email}</td>
                    <td>{maskPhone(indic.whatsappNumber)}</td>
                  </Fragment>
                )}

                <td>
                  <div>{this.formatDate(indic.sendDates[indic.sendDates.length - 1])}</div>
                </td>
                <td>
                  <div>{this.switchStatus(indic.status)}</div>
                </td>
                <td>
                  {indic.status === "Disponível" && (
                    <div className="d-flex justify-content-around">{this.renderIcons(indic)}</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    )
  }
}
