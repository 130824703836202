/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions";
import { saveOnboardingSteps } from "../../../../business/onboarding";

import { fetchSelectType } from "../../../../business/onboarding";

import LitSelect from "../../../shared/Select";

import Select from "react-select";

import {
  Container,
  Title,
  BoxSections,
  Section,
  TextError,
  Label
} from "./styles";

class OnboardingPageListBox extends Component {
  constructor(props) {
    super(props);
    const { data, isVisibleTitle } = props;

    this.state = {
      areaItems:
        data.qx_listbox && data.qx_listbox.area
          ? data.qx_listbox.area.map(item => ({ value: item, label: item }))
          : [],
      typeItems:
        data.qx_listbox && data.qx_listbox.type
          ? data.qx_listbox.type.map(item => ({ value: item, label: item }))
          : [],
      levelItems:
        data.qx_listbox && data.qx_listbox.level
          ? data.qx_listbox.level.map(item => ({ value: item, label: item }))
          : [],
      formEvaluationItems:
        data.qx_listbox && data.qx_listbox.evaluation
          ? data.qx_listbox.evaluation.map(item => ({
              value: item,
              label: item
            }))
          : [],
      errorMessage: null,
      formatedAreaItems: [],
      formatedTypeItems: [],
      formatedLevelItems: [],
      formatedFormEvaluationItems: [],
      isVisibleTitle
    };
  }

  componentDidMount() {
    this.fetchListBox("LISTBOX_AREA", "formatedAreaItems");
    this.fetchListBox("LISTBOX_TYPE", "formatedTypeItems");
    this.fetchListBox("LISTBOX_LEVEL", "formatedLevelItems");
    this.fetchListBox("LISTBOX_EVALUATION", "formatedFormEvaluationItems");
  }

  fetchListBox = async (listbox, state) => {
    try {
      const response = await fetchSelectType(listbox);

      const formatedItems = response.map(item => ({
        value: item.wx_optionvalue,
        label: item.wx_optionlabel
      }));
      const obj = {};
      obj[state] = formatedItems;
      this.setState(obj);
    } catch (error) {
      alert(`Erro ao buscar ${listbox}`);
    }
  };

  saveForm = async () => {
    const possibleError = this.validateSelection();

    if (possibleError) {
      throw new Error(possibleError);
    }

    const {
      areaItems,
      typeItems,
      levelItems,
      formEvaluationItems
    } = this.state;

    const formData = {
      wx_step: "LISTBOX",
      ox_formdata: {
        area: areaItems && areaItems ? areaItems.map(item => item.value) : [],
        type: typeItems && typeItems ? typeItems.map(item => item.value) : [],
        level:
          levelItems && levelItems ? levelItems.map(item => item.value) : [],
        evaluation:
          formEvaluationItems && formEvaluationItems
            ? formEvaluationItems.map(item => item.value)
            : []
      }
    };
    this.saveDataInState(true);
    await saveOnboardingSteps(formData);
  };

  saveDataInState = (concluded = false) => {
    const {
      areaItems,
      typeItems,
      levelItems,
      formEvaluationItems
    } = this.state;

    const { index, onboardingData, setOnbordingPageConcluded } = this.props;
    const dataActiveStep = Object.assign({}, onboardingData[index]);

    dataActiveStep.qx_listbox = {
      area: areaItems && areaItems ? areaItems.map(item => item.value) : [],
      type: typeItems && typeItems ? typeItems.map(item => item.value) : [],
      level: levelItems && levelItems ? levelItems.map(item => item.value) : [],
      evaluation:
        formEvaluationItems && formEvaluationItems
          ? formEvaluationItems.map(item => item.value)
          : []
    };

    setOnbordingPageConcluded(
      index,
      dataActiveStep,
      onboardingData[index].bf_concluded || concluded
    );
  };

  validateSelection = () => {
    let result = null;

    const {
      areaItems,
      typeItems,
      levelItems,
      formEvaluationItems
    } = this.state;

    if (
      areaItems === null ||
      areaItems.length === 0 ||
      typeItems === null ||
      typeItems.length === 0 ||
      levelItems === null ||
      levelItems.length === 0 ||
      formEvaluationItems === null ||
      formEvaluationItems.length === 0
    ) {
      result = "Selecione ao menos uma opção.";
    }

    this.setState({ errorMessage: result });
    return result;
  };

  upperCaseState = s => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleItems = () => {
    const {
      formatedAreaItems,
      formatedTypeItems,
      formatedLevelItems,
      formatedFormEvaluationItems
    } = this.state;

    this.setState({ areaItems: formatedAreaItems });
    this.setState({ typeItems: formatedTypeItems });
    this.setState({ levelItems: formatedLevelItems });
    this.setState({ formEvaluationItems: formatedFormEvaluationItems });
  };

  render() {
    const { index } = this.props;
    const { isVisibleTitle } = this.state;

    const handle = {
      areaItems: e => this.setState({ areaItems: e }),
      typeItems: e => this.setState({ typeItems: e }),
      levelItems: e => this.setState({ levelItems: e }),
      formEvaluationItems: e => this.setState({ formEvaluationItems: e })
    };

    const options = [
      {
        label: "Selecione suas áreas de interesse",
        state: "areaItems"
      },
      {
        label: "Selecione modalidades de curso",
        state: "typeItems"
      },
      {
        label: "Selecione o nível do curso",
        state: "levelItems"
      },
      {
        label: "Selecione as formas de avaliação",
        state: "formEvaluationItems"
      }
    ];

    return (
      <Container className="container">
        {isVisibleTitle && <Title> {index + 1}. O que você procura?</Title>}

        <BoxSections>
          <Section>
            <div className="row justify-content-md-center">
              {options.map((item, i) => {
                return (
                  <div className="col-md-8" id={item.state}>
                    <div className="form-group">
                      <Label>{item.label}</Label>
                      <LitSelect
                        id={i}
                        isMulti
                        name={item.state}
                        options={
                          this.state[
                            `formated${this.upperCaseState(item.state)}`
                          ]
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Selecionar"
                        onChange={handle[item.state]}
                        value={this.state[item.state]}
                        allowSelectAll={true}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row justify-content-md-center mt-2">
              <button
                className="actionBtn"
                id="select-all"
                onClick={this.handleItems}
              >
                Selecionar tudo
              </button>
            </div>
            <div className="row justify-content-md-center mt-3 mb- 3">
              <small>
                Com base em suas escolhas, criaremos uma lane na home do LIT com
                o nome Suas Escolhas.
              </small>
            </div>
          </Section>
          {this.state.errorMessage && (
            <TextError>{this.state.errorMessage}</TextError>
          )}
        </BoxSections>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  onboardingData: state.onboarding.onboardingData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OnboardingActions
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(OnboardingPageListBox);
