import React, { memo } from "react"
import { Link } from "react-router-dom"
import NavBar from "../Termos/Navbar"
import WatsonBadgeFooter from "./WatsonBadgeFooter"
import ModalVideo from "../ModalVideo"

const GIF_LIT = require("../../images/animacao_paul_standby.gif")

const PaulDicas = () => {
  return (
    <div id="personalizar">
      <NavBar />

      <div className="container-fluid" style={{ paddingTop: 60 }}>
        <div className="row">
          <div className="col-12">
            <div className="container" id="objetivos-personalizar">
              <div className="row justify-content-md-center">
                <div className="col-10 objetivos-personalizar--desc-usuario text-center">
                  <img
                    alt="Curso"
                    className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                    src={GIF_LIT}
                  />
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-10 objetivos-personalizar--desc-usuario text-center">
                  <h5 style={{ color: "#27aae1" }} className="text-center">
                    Personalize como você aprende melhor!
                  </h5>
                  <h6>Esse é último e mais importante passo da sua personalização inicial no LIT.</h6>
                  <br />
                  <p>
                    Quero te ajudar a aprender mais, mais rápido e melhor e para adaptar sua experiência
                    é fundamental que eu conheça você. Você só precisa fazer essa análise uma vez! Após
                    esse passo, recomendarei as formas de aprendizagem mais aderentes ao seu perfil, por
                    exemplo, se você aprende melhor ao assistir a um vídeo ou ao ler um texto. Você terá
                    acesso ao relatório completo que fiz sobre seu perfil.
                  </p>
                  <p>
                    A forma que eu traço seu perfil de aprendizagem é por meio de análise de textos de
                    sua autoria. Quanto mais detalhes você me der, melhor será o resultado da análise.
                  </p>
                  <p>
                    Preciso analisar <b style={{ color: "#000" }}>textos de sua autoria</b>, devem ser
                    trechos de coisas que você já escreveu. Textos que tenham a sua cara, como e-mails,
                    mensagens de WhatsApp, posts, etc., tanto textos de dia a dia quanto profissionais.{" "}
                    <b style={{ color: "#000" }}>Você pode copiar e colar</b> esses textos nas áreas a
                    seguir. Estimamos que você demore 10 minutos para finalizar esse passo e com isso o
                    LIT já estará personalizado para você!
                  </p>
                  <br />
                  <p style={{ fontSize: "14px" }}>
                    Importante: Nenhuma das informações fornecidas nesta análise serão usadas para outras
                    finalidades, além dessa personalização.
                  </p>
                </div>
              </div>

              <div className="col row align-items-center justify-content-center interest-saveSend mb-3">
                <Link to="/home">
                  <button type="button" className="btn btn-lightblue btn-FontWeight6 mr-3">
                    Personalizar depois
                  </button>
                </Link>
                <Link to="/personalizar">
                  <button type="button" className="btn btn-blue btnSave-interest btn-FontWeight6 mr-3">
                    Personalizar agora
                  </button>
                </Link>
              </div>
              <WatsonBadgeFooter />
            </div>
          </div>
        </div>
      </div>
      <ModalVideo indexTutorial={4} modal titleModal="personalização do perfil" />
    </div>
  )
}

export default memo(PaulDicas)
