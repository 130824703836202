import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 0;
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: 468px) {
    label.interesses-space {
      display: block !important;
      width: 100% !important;
    }
  }
`
export const Title = styled.h2`
  margin-bottom: 10px;
`

export const ContainerForm = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const ContainerButtons = styled.div``

export const TextError = styled.p`
  margin: 0;
  color: red;
  text-align: center;
`
