import React from "react";
import Line from "../../basicComponents/Line";
import { LeftBar, RightBar } from "./styles";

export default ({ left, right }) => {
  return (
    <>
      <Line>
        <LeftBar>{left}</LeftBar>
        <RightBar>{right}</RightBar>
      </Line>
    </>
  );
};
