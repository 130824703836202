import React from "react"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import Modal from "react-responsive-modal"
import Img from "react-image"
import { toast } from 'react-toastify'
import Linkify from "react-linkify"
import Simplert from "react-simplert"
import moment from "moment"
import "moment/locale/pt-br"
import placeholder from "../../../images/placeholder-avatar.png"
import Resposta from "./Resposta"
import Attachment from "./Attachment"
import EditComment from "./Forms/EditComments"
import { API_URL } from "../../../utils/constants"
import api from "../../../utils/api"
import { toastErrorAndWarningOptions, toastDefaultOptions } from '../../../utils/functions'

class Comentario extends React.Component {
  state = {
    commentReplyList: [],
    commentReply: "",
    alert: false,
    commentBox: false,
    file: "",
    imagePreviewUrl: "",
    ext: "",
    hasLike: false,
    contadorLike: 0,
    willReport: null,
    modalOpen: false,
    reason: "",
    extra: "",
    openEditarComentario: false,
  }

  componentWillMount() {
    const {
      comment: { idComment },
    } = this.props
    this.findAnswer()
    this._fetchLikes(idComment)
  }

  deleteComment = () => {
    this.setState({
      alert: true,
    })
  }

  confirmDelete = async id => {
    const { getCommentByPost } = this.props
    try {
      await api.put(`deletingComment/${id}`)
      getCommentByPost()
    } catch (error) {
      console.log(error.response)
    }
  }

  _fetchLikes = () => {
    const {
      comment: { idComment },
    } = this.props
    const idProfile = localStorage.getItem("idProfile")
    api.get(`findLikesOfComment/${idComment}/${idProfile}`).then(res => {

      this.setState({ hasLike: res.liked, contadorLike: res.size })
    })
  }

  _createLike = async () => {
    this.btnLike.setAttribute("disabled", true)
    const {
      comment: { idComment },
    } = this.props
    const { hasLike } = this.state
    const data = {
      comment: {
        idComment,
      },
      memberLike: {
        idGroupMember: localStorage.getItem("idMember"),
      },
      likeComment: true,
    }
    try {
      await api.post("newLikeComment", data)
      this.setState({ hasLike: !hasLike })
      this._fetchLikes()
      this.btnLike.removeAttribute("disabled")
    } catch (error) {
      console.log(error)
    }
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
      willReport: null,
      reason: "",
      extra: "",
    })
  }

  reportComment = async () => {
    const { willReport, reason, extra } = this.state
    if (reason === "") {
      alert("Escolha uma razão")
      return
    }
    try {
      const data = {
        memberReporting: {
          idGroupMember: localStorage.getItem("idMember"),
        },
        commentReported: {
          idComment: willReport,
        },
        report: reason,
        reportComment: extra,
      }
      await api.post("newReport", data)
      alert("Denuncia registrada, obrigado.")
      this.setState({
        modalOpen: false,
        willReport: null,
        reason: "",
        extra: "",
      })
    } catch (error) {
      console.log(error)
    }
  }

  sendCommentReply = async () => {
    const { comment, getPosts } = this.props

    const idGroupMember = localStorage.getItem("idMember")
    const { commentReply, file, ext, commentBox } = this.state
    const currentTime = moment.utc()

    if (commentReply === "" || commentReply.trim() === "") {
      alert("A resposta não pode ser vazia!")
      return
    }
    try {
      const data = {
        answer: commentReply,
        dateAnswer: currentTime,
        commentAnswers: {
          idComment: comment.idComment,
        },
        memberAnswer: {
          idGroupMember,
        },
      }
      const response = await api.post("newAnswerComment", data)

      toast('Comentário adicionado com sucesso.', toastDefaultOptions)
      if (file !== null && file !== "") {
        this.answerFile(response.idAnswer, ext)

      } else {
        this.setState({
          commentReply: "",
          commentBox: !commentBox,
        })
        getPosts(comment.post.group.idGroup)
        this.findAnswer()
      }
    } catch (error) {
      console.log(error)
      toast.error('Falha ao adicionar comentário.', { ...toastErrorAndWarningOptions, closeButton: false });
    }
  }

  answerFile = async (id, ext) => {
    try {
      await api.post(`uploadImgAnswer/${id}/${ext}`)
      this.setState({
        file: null,
        imagePreviewUrl: null,
        ext: "",
      })
      this.updateWithAttachment(id, ext)
    } catch (error) {
      console.log(error)
    }
  }

  updateWithAttachment = async (id, ext) => {
    const {
      comment: { idComment },
    } = this.props
    const idGroupMember = localStorage.getItem("idMember")
    const { commentReply, commentBox } = this.state
    const currentTime = moment.utc()

    try {
      const data = {
        idAnswer: id,
        linkMedia: `${API_URL}downdImgAnswer/${id}/${ext}`,
        answer: commentReply,
        dateAnswer: currentTime,
        commentAnswers: {
          idComment,
        },
        memberAnswer: {
          idGroupMember,
        },
      }
      await api.put("updateAnswerComment", data)
      this.setState({ commentReply: "", commentBox: !commentBox })
      this.findAnswer()
    } catch (error) {
      console.log(error)
    }
  }

  findAnswer = async () => {
    const {
      comment: {
        idComment,
        post: {
          group: { idGroup },
          idTimeLine,
        },
      },
    } = this.props

    try {
      const response = await api.get(`findAnswer/${idGroup}/${idTimeLine}/${idComment}`)
      const commentReplyList = response.reverse()
      this.setState({ commentReplyList })
    } catch (error) {
      console.log(error)
    }
  }

  showCommentReply = () => {
    const { commentBox } = this.state
    this.setState({
      commentBox: !commentBox,
    })
  }

  _handleImageChange = e => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    if (file === undefined) return

    if (
      !["jpg", "mp4", "mp3", "pdf", "xlsx", "xls", "doc", "docx", "ppt", "pptx"].includes(
        file.name.split(".")[1]
      )
    ) {
      alert("Extensão de arquivo não suportada!")
      return
    }

    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
        ext: file.name.split(".")[1],
      })
    }

    reader.readAsDataURL(file)
  }

  openReport = id => {
    this.setState({ willReport: id, modalOpen: true })
  }

  editComentario = () => {
    this.setState({ openEditarComentario: true })
  }

  cancelEdit = () => {
    this.setState({ openEditarComentario: false })
  }

  render() {
    const {
      imagePreviewUrl,
      ext,
      lightboxPreview,
      file,
      modalOpen,
      reason,
      extra,
      openEditarComentario,
      commentReplyList,
      hasLike,
      contadorLike,
      commentBox,
      commentReply,
      alert,
    } = this.state
    const {
      comment,
      child,
      groupOwner,
      quantidadeComentarios,
      getCommentByPost,
      membro,
      getPosts,
    } = this.props

    const idProfile = parseInt(localStorage.getItem("idProfile"), 10)
    const idOwner = parseInt(localStorage.getItem("idOwner"), 10)

    let isProfessor
    if (quantidadeComentarios <= 1) {
      isProfessor = null
    } else if (comment.memberComment.profiles.login.role.idRole === 3) {
      isProfessor = "risk-professor-group"
    } else {
      isProfessor = "risk-group"
    }

    let textAwnsers
    if (commentReplyList.length > 1) {
      textAwnsers = " Respostas"
    } else if (commentReplyList.length === 1) {
      textAwnsers = " Resposta"
    } else {
      textAwnsers = " Responder"
    }

    let $imagePreview
    let $attachment = null
    if (imagePreviewUrl && ext === "jpg") {
      $imagePreview = (
        <div className="d-flex justify-content-center align-items-center">
          <div
            role="button"
            onClick={() => {
              this.setState({ lightboxPreview: true })
            }}
          >
            <img
              className="img-thumbnail"
              alt="Anexo"
              style={{ width: "auto", height: "250px" }}
              src={imagePreviewUrl}
            />
          </div>
          {lightboxPreview && (
            <Lightbox
              mainSrc={imagePreviewUrl}
              onCloseRequest={() => {
                this.setState({ lightboxPreview: false })
              }}
            />
          )}
        </div>
      )
    } else if (imagePreviewUrl) {
      $imagePreview = <p>{file.name}</p>
    } else {
      $imagePreview = <div className="previewText" />
    }

    if (comment.linkMedia) {
      if (comment.linkMedia.endsWith("jpg")) {
        $attachment = <Attachment url={comment.linkMedia} ext="jpg" />
      } else if (comment.linkMedia.endsWith("mp4")) {
        $attachment = <Attachment url={comment.linkMedia} ext="mp4" />
      } else if (comment.linkMedia.endsWith("mp3")) {
        $attachment = <Attachment url={comment.linkMedia} ext="mp3" />
      } else if (comment.linkMedia.endsWith("pdf")) {
        $attachment = <Attachment url={comment.linkMedia} ext="pdf" />
      } else {
        $attachment = (
          <a href={comment.linkMedia} style={{ color: "#27aae1" }}>
            <i className="fa fa-download" /> Baixar anexo
          </a>
        )
      }
    } else {
      $attachment = <div />
    }

    let notFirstChild = ""
    if (child === 0) {
      notFirstChild = ""
    } else {
      notFirstChild = "notFirstChild"
    }

    const reportModal = (
      <Modal open={modalOpen} onClose={this.closeModal} classNames={{ modal: "lit-modal-form" }}>
        <div className="modal-content" id="modalGroup">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Reportar comentário
            </h5>
            <button type="button" className="close" onClick={this.closeModal} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modalBody-container">
            <div className="modal-body">
              <div className="form-group">
                <label>Motivo da denúncia</label>
                <select
                  className="form-control"
                  value={reason}
                  onChange={evt => this.setState({ reason: evt.target.value })}
                >
                  <option defaultValue>Selecione o motivo</option>
                  <option value="nudez">Conteúdo impróprio com apelo sexual</option>
                  <option value="ofensa">Conteúdo ofensivo</option>
                  <option value="violencia">Conteúdo proibido ou violento</option>
                </select>
              </div>
              <div className="form-group">
                <label>Outras informações</label>
                <textarea
                  type="text"
                  className="form-control"
                  rows="5"
                  id="inputGroupName"
                  value={extra}
                  onChange={evt =>
                    this.setState({
                      extra: evt.target.value,
                    })
                  }
                  required
                  placeholder="Utilize este espaço caso deseje incrementar a denúncia"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-modal__cancel" onClick={this.closeModal}>
              Cancelar
            </button>
            <button type="submit" onClick={this.reportComment} className="btn btn-modal__approve">
              Salvar
            </button>
          </div>
        </div>
      </Modal>
    )

    return (
      <div className="postComment-socialGroup">
        <div className={`row comentarios ${notFirstChild}`}>
          <div className="col-12">
            <div className="row avatar" style={{ marginTop: 0 }}>
              <Link
                to={`/${comment.memberComment.profiles.login.role.idRole === 3
                  ? "perfilProfessor"
                  : "perfilPublico"
                  }/${comment.memberComment.profiles.idProfile}`}
              >
                <Img
                  className="rounded-circle"
                  style={{ width: 70, height: 70 }}
                  src={[
                    comment.memberComment.profiles.linkPhoto,
                    `${API_URL}downdImg/${comment.memberComment.profiles.idProfile}`,
                    placeholder,
                  ]}
                />
              </Link>
              <div className="avatar-social avatar-body">
                <h6>
                  {comment.memberComment.profiles.firstName} {comment.memberComment.profiles.lastName}
                </h6>
                <span>
                  <em>{moment(new Date(comment.date)).fromNow()}</em>
                </span>
              </div>
              <div className="dropdown ml-auto">
                <a
                  className="ml-auto"
                  role="button"
                  id="dropdownOptions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-caret-down" aria-hidden="true" />
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownOptions">
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      this.openReport(comment.idComment)
                    }}
                    role="button"
                  >
                    Denunciar
                  </a>
                  {comment.memberComment.profiles.idProfile === idProfile && (
                    <a className="dropdown-item" onClick={this.editComentario} role="button">
                      Editar
                    </a>
                  )}
                  {groupOwner === idOwner || comment.memberComment.profiles.idProfile === idProfile ? (
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        this.deleteComment()
                      }}
                      role="button"
                    >
                      Excluir
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row comentario-avatar">
              <div className={isProfessor} />
              <div className="col">
                {openEditarComentario ? (
                  <EditComment
                    from="comentario"
                    refreshPosts={getCommentByPost}
                    cancelEdit={this.cancelEdit}
                    post={comment}
                  />
                ) : (
                  <Linkify
                    properties={{
                      target: "_blank",
                      style: { color: "#27aae1", fontWeight: "bold" },
                    }}
                  >
                    <p>{comment.comment.trim()}</p>
                  </Linkify>
                )}

                {!openEditarComentario && (
                  <div className="row">
                    <div className="col-12">{$attachment}</div>
                  </div>
                )}

                <div className="row">
                  <button
                    type="button"
                    className="btn btn-icon"
                    onClick={this.showCommentReply}
                    disabled={!membro}
                  >
                    <i className="fa fa-comment-o" />
                    {textAwnsers} &nbsp;
                    <span className="badge badge-primary" style={{ backgroundColor: "#27AAE1" }}>
                      {commentReplyList.length}
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-icon"
                    ref={c => {
                      this.btnLike = c
                    }}
                    disabled={!membro}
                    onClick={() => this._createLike()}
                  >
                    {!hasLike ? (
                      <i className="fa fa-heart-o" style={{ color: "#27AAE1" }} />
                    ) : (
                      <i className="fa fa-heart" style={{ color: "#27AAE1" }} />
                    )}

                    {!hasLike ? " Curtir " : " Descurtir "}

                    <span className="badge badge-primary" style={{ backgroundColor: "#27AAE1" }}>
                      {contadorLike}
                    </span>
                  </button>
                </div>

                {commentBox ? (
                  <div className="row">
                    <div className="form-group col-12">
                      <div className="form-group">
                        <textarea
                          rows="5"
                          className="form-control"
                          id="inputComment"
                          value={commentReply}
                          onChange={evt => this.setState({ commentReply: evt.target.value })}
                          placeholder="Escreva uma resposta..."
                        />
                        <button
                          type="button"
                          className="btn btn-blue mr-3"
                          onClick={this.sendCommentReply}
                          disabled={commentReply === "" || commentReply.trim() === ""}
                        >
                          Comentar
                        </button>
                        <label className="btn btn-blue mt-2">
                          Anexar arquivo
                          <input
                            type="file"
                            id="exampleInputFile"
                            accept="image/jpeg, application/pdf, audio/mpeg, audio/mp3, video/mp4, .xlsx, .xls , .doc, .docx, .ppt, .pptx"
                            hidden
                            onChange={e => this._handleImageChange(e)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-12">{$imagePreview}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {commentReplyList.map(commentReplyItem => {
          return (
            <Resposta
              key={commentReplyItem.idAnswer}
              comment={comment}
              commentReply={commentReplyItem}
              getPosts={getPosts}
              findAnswer={this.findAnswer}
            />
          )
        })}
        {reportModal}
        <Simplert
          showSimplert={alert}
          type="error"
          title="Deseja excluir essa publicação?"
          message="Esta ação não pode ser revertida"
          useConfirmBtn
          onConfirm={() => this.confirmDelete(comment.idComment)}
          customConfirmBtnText="Confirmar"
          customCloseBtnText="Cancelar"
          customConfirmBtnClass="btn btn-blue"
          customCloseBtnClass="btn btn-danger"
        />
      </div>
    )
  }
}

export default Comentario
