import React from "react";

import Screen from "./screen";

export default function MeetingChat() {
  const chat = [];
  const onSentMessageClick = (/* message */) => {};

  return <Screen chat={chat} onSentMessageClick={onSentMessageClick} />;
}
