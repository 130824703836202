import React from "react";

import { Container } from "./styles";

function TrueOrFalse({ renderLabel, value, index, onAnswerChange }) {
  return (
    <Container>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button
          type="button"
          className={`btn mr-1 ${
            typeof value === "boolean" && value
              ? "btn-primary"
              : "btn-secondary"
          }`}
          onClick={() => {
            onAnswerChange(index, true);
          }}
        >
          V
        </button>

        <button
          type="button"
          className={`btn ml-1 ${
            typeof value === "boolean" && !value
              ? "btn-primary"
              : "btn-secondary"
          }`}
          onClick={() => {
            onAnswerChange(index, false);
          }}
        >
          F
        </button>
      </div>

      <label>{renderLabel && renderLabel()}</label>
    </Container>
  );
}

export default TrueOrFalse;
