import { Types } from "./types"

/**
 * Actions
 */

export const Creators = {
  setPersonalityInsight: personalityInsight => ({
    type: Types.SET_PERSONALITY_INSIGHT,
    payload: { personalityInsight },
  }),
  getPersonalityInsightRequest: idProfile => ({
    type: Types.GET_PERSONALITY_INSIGHT_REQUEST,
    payload: { idProfile },
  }),
  getPersonalityInsightSuccess: data => ({
    type: Types.GET_PERSONALITY_INSIGHT_SUCCESS,
    payload: { data },
  }),
  getPersonalityInsightFailure: error => ({
    type: Types.GET_PERSONALITY_INSIGHT_FAILURE,
    payload: { error },
  }),
}
