import styled from 'styled-components'

export const ContainerCursos = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  margin-bottom: 20px;
`

export const CourseItem = styled.div`
  text-align: center;
  max-width: 300px;
  background: #f1f1f140;
  cursor: pointer;
  border: 1px solid #cccc;
  padding: 5px;
  & + div {
    margin-left: 10px
  }
  
  bold {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    & + bold {
      margin-top: 2px;
    }
  }
  
`