/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';

import Lane from '../../../../../shared/Lane';
import CustomContentLoader from '../../../../../Home/CustomContentLoader';
import CardEvent from '../CardEvent';
import * as Styles from './styles';

function Screen({ events , onClick, errorEventsMessage, fullButton, title= "Eventos da turma", loading }) {

  if(!events || loading) {
    return (
      <CustomContentLoader
        tooltipText="Carregando eventos"
        title="Carregando eventos"
      />
    )
  }

  return (
    <Styles.Container>
      <Lane title={title} fullButton={fullButton} linkForAll="/eventos">
        {!loading && !events.length && (
          <Styles.EmptyListText>
            {errorEventsMessage || 'Sem eventos'}
          </Styles.EmptyListText>
        )}
        {events.map(event => {
          return (
            <Styles.Wrapper key={`${event.id}`}>
              <CardEvent 
                event={event} 
                onClick={(event) => onClick(event)}
              />
            </Styles.Wrapper>
          );
        })}
      </Lane>
    </Styles.Container>
  );
}

export default Screen;
