import React from "react";
import {
  ICON,
  COLOR,
  APPEARANCE,
  POSITION,
  SIZE,
  FONT_TYPE,
  ACTION,
  BORDER_TYPE
} from "../../../constants";
import Button from "../Button";
import Text from "../Text";
import Block from "../Block";
import Image from "../Image";
import Clickable from "../Clickable";
import { Span, ButtonAtBottom } from "./styles";

export default ({ title, description, image, hiddenBlock, setHiddenBlock }) => {
  return (
    <>
      <Block
        color={COLOR.LIGHT}
        appearance={APPEARANCE.MEMBER}
        hidden={hiddenBlock}
      >
        <Image
          src={image}
          alt={title}
          appearance={APPEARANCE.AVATAR_MEMBER}
          size={SIZE.EXTRA_SMALL}
          border={BORDER_TYPE.BORDER}
        />
        <Span>
          <Text
            size={SIZE.MEDIUM}
            fontType={FONT_TYPE.BOLD}
            appearance={APPEARANCE.TITLE}
            text={title}
          />
          <Text
            size={SIZE.SMALL}
            fontType={FONT_TYPE.ITALIC}
            appearance={APPEARANCE.TITLE}
            text={`(${description})`}
          />
        </Span>
        <ButtonAtBottom>
          <Clickable>
            <Text
              size={SIZE.MEDIUM}
              fontType={FONT_TYPE.BOLD}
              text={ACTION.VIEW_PROFILE}
            />
          </Clickable>
          <Button
            icon={ICON.CLOSE}
            appearance={APPEARANCE.CLOSE}
            position={POSITION.BOTTOM_RIGHT}
            onClick={() => setHiddenBlock(true)}
          />
        </ButtonAtBottom>
      </Block>
    </>
  );
};
