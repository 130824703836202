import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} text //text that will be displayed for click showing options
 * @param {String} icon //icon that will be displayed for click showing options
**/

export default ({
  text = DEFAULT_VALUES.STRING,
  icon = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        text={text}
        icon={icon}
      />
    </>
  );
};
