import React, { useState, useEffect } from "react"
import moment from 'moment'

import styled from "styled-components"
import Countdown from './Countdown'

import history from '../../utils/history'

const Content = styled.div`
  display: flex;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
  background: ${props => props.background ? props.background : '#F5EB5A'};
  color: white;
  text-transform: uppercase;
  padding: 0.1em 0px;
  justify-content: space-between;
  p {
    font-weight: 800;
    font-size: 14px;
    margin-top: 14px;
  }
  .circle  
  {
    text-align: left;
    font-family: 'Arial', sans-serif;    
    width:30px;height:30px;
    border: solid 0px;
    background-color: ${props => props.textColor ? props.textColor : '#62BCE1'};
    border-radius:100px;
    color: ${props => props.textColor ? props.textColor : '#717171'};
    cursor: hand; // Pro IE
    cursor: pointer; // Pro FF
  }

  div:nth-child(1) {
    flex:1;
    text-align: center;
  }
  div:nth-child(2) {
    text-align: center;
    margin-top: 4px;
  }
  div:nth-child(3) {
    text-align: left;
  }
  @media (max-width: 770px) {
    flex-direction: column;
    justify-content: space-between;
    p {
      font-weight: 800;
      font-size: 8px;
      margin-top: 8px;
    }
    div:nth-child(1) {
      flex:1;
      text-align: center;
      margin-left: -20%; 
    }
    div:nth-child(2) {
      text-align: center;
      margin-top: 5px;
      max-width: 50%;    
      margin-left: 250px;
    }
    div:nth-child(3) {
      flex:1;
      text-align: center;
      margin-left: 25px;
      margin-bottom: 10px;
    }
  }

  #buttonClose {
    background: transparent;
    border: none;
    width: 35px;
    height: 35px;
    font-size: 14px;
    position: absolute;
    font-weight: bold;
    right: 14px;
    top: 14px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
    @media (max-width: 770px) {
      display: block 
    }
  }
`

// showCloseButton={false} - Não a opção fechar :: default é true

const SmartBar = ({ 
  text = "<p>Texto Padrão</p>", 
  background, 
  onClose, 
  showCloseButton = true, 
  timer,
  textbutton,
  redirectToPerfil  
}) => {

  const calculateTimeLeft = () => {
    const difference = +new Date(timer) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hs: Math.floor((difference / (1000 * 60 * 60)) % 24),
        min: Math.floor((difference / 1000 / 60) % 60),
        seg: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval || 0]} {interval}
      </span>
    );
  });

  const viewProfilePayments = () => {
    redirectToPerfil()
    history.push({ pathname: `/perfilAluno`, state: { openModal: true } })    
  }
  
  moment.locale('pt-br')
  const timerTemp = moment(timer).format('DD/MM/YYYY')
  
  const dataFinal = moment(timerTemp, "DD/MM/YYYY")
  dataFinal.add({hour:8,minute:0,second:0,millisecond:0})

  const now = moment();

  return (
    <Content background={background} textColor={text.textColor}>
      <div >
        <div dangerouslySetInnerHTML={{ __html: text }} style={{ textAlign: "center", marginLeft: "14%", marginTop: "25px" }} />
      </div>

      <div className="row d-flex align-items-center justify-content-left" style={{ marginRight: "7%", marginLeft: "1%" }}>
        {now < dataFinal ?
          <Countdown timeTillDate={moment(dataFinal).format('DD MM YYYY, h:mm a')} timeFormat="DD MM YYYY, h:mm a" background={background} textColor={text.textColor} /> 
          : <span className="col-md-2 row d-flex align-items-center justify-content-md-center " style={{ marginLeft: "50px", color: "#000000" }}>Time's up!</span>}
      </div>
      
      <button onClick={() => onClose()} id="buttonClose" type="button">X</button>

      <div className="row d-flex align-items-center justify-content-left" style={{ paddingLeft: "50px", marginRight: "10%" }}>
        <div>
          {showCloseButton && <button id="btnEditSignature" type='button' className='btn btn-blue' onClick={() => viewProfilePayments()}>{textbutton}</button>}
        </div>
      </div>
    </Content>
  )
}

export default SmartBar 



