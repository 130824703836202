import React, { Component } from "react"

import Loading from "../Loading/Loading"
import Curso from "./Trilha/Curso"

import api from "../../utils/api"

class Trilhas extends Component {
  state = {
    loading: true,
    cursos: [],
    called: false,
  }

  componentDidUpdate(prevProps) {
    const { trails } = this.props
    const { called } = this.state
    if (prevProps.trails !== trails && !called) {
      this.fetchCursos()
    }
  }

  fetchCursos = async () => {
    const idProfile = localStorage.getItem("idProfile")
    const ra = localStorage.getItem("ra")
    const idLogin = localStorage.getItem("idLogin")
    try {
      this.setState({ loading: true })
      const response = await api.get(`getStudyingCourses/${idProfile}/${idLogin}/${ra}/`)
      this.setState({ cursos: response.cursando || [], loading: false, called: true })
    } catch (error) {
      console.log(error)
      this.setState({ loading: false })
    }
  }

  stopLoading = value => {
    this.setState({ loading: value })
  }

  render() {
    const { loading, cursos } = this.state

    if (loading) {
      return (
        <main>
          <div className=" bg-white-perfil">
            <div className="row">
              <div className="col-12">
                <h5>Cursos e trilhas em andamento</h5>
              </div>
            </div>
            <div className="row">
              <div id="trilhasAndamento" className="col-12">
                <div id="accordion">
                  <Loading width={40} />
                </div>
              </div>
            </div>
          </div>
        </main>
      )
    }

    return (
      <main>
        <div className=" bg-white-perfil">
          {cursos && cursos.length > 0 ? (
            cursos.map(curso => {
              return <Curso key={`${curso.curso}${curso.idCourse}`} {...curso} />
            })
          ) : (
            <div>Você não possui nenhuma trilha até o momento.</div>
          )}
        </div>
      </main>
    )
  }
}

export default Trilhas
