import React from "react"
import { LinkedIn } from "react-linkedin-login-oauth2"
import { urlencode } from "../../utils/auth"

import linkedinIcon from "../../images/linkedinIcon.svg"

const SocialLinkedin = ({ handleFailure, handleSuccess, loading }) => {
  return (
    <div className="d-inline-block">
    <LinkedIn
      clientId="7817bws2sdn30i"
      redirectUri={`${urlencode(window.location.origin)}/linkedin`}
      onSuccess={handleSuccess}
      onError={handleFailure}
      scope="openid profile email"
    >
      {({ linkedInLogin }) => (
        <button
          type="button"
          onClick={linkedInLogin}
          className={`btn btn-rounded ${loading && " btn-loading"}`}
          style={{ backgroundColor: "transparent", padding: 0 }}
        >
          <img src={linkedinIcon} alt="LinkedinIcon" />
        </button>
      )}
    </LinkedIn>
  </div>
  )
}
export default SocialLinkedin
