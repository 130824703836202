import React, { useState, useEffect, useMemo, useRef } from 'react';
import InputMask from 'react-input-mask';
import { FaSearch } from 'react-icons/fa';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { validarCpf as validateCpf } from '../../../../utils/functions';
import Text from '../Text';
import Select from '../SelectInput';
import Loading from '../Loading';

import { gateway } from '../../../../utils/api';
import PaymentTerm from '../PaymentTerm';
import generateInstallmentsOptions from '../../helpers/generateInstallmentsOptions';
import TextPaymentDefault from '../TextPaymentDefault';
import Input from '../Input';
import * as Styles from './styles';
import * as GeneralStyles from '../../styles';

function InvoiceForm({
  selectedPlan,
  onSubmit,
  userTerm,
  loading,
  generatingTerm,
  handleGenerateTerm,
  showTerm,
  setShowTerm,
}) {

  const formRefer = useRef(null);
  const validationSchema = Yup.object().shape({
    installments: Yup.number()
      .nullable()
      .default(1),
    wl_shopperfirstname: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    wl_shopperlastname: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    cf_shoopercpf: Yup.string()
      .test('is-cpf', 'CPF inválido', validateCpf)
      .default(''),
    wx_shopperpostalcode: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    wx_shopperstreet: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    wx_shoppercity: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    wx_shoppercountry: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    ex_shopperhousenumber: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    wx_shopperstate: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    neighborhood: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    additional_details: Yup.string()
      .default(''),
  });
  const [paymentMethod] = useState('INVOICE');
  const installmentData = useSelector(installments => installments.installments);
  const [termAccepted, setTermAccepted] = useState(false);
  const [isTermVisible, setIsTermVisible] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState({
    wl_shopperfirstname: false,
    wl_shopperlastname: false,
    cf_shoopercpf: false,
    wx_shopperpostalcode: false,
    wx_shopperstreet: true,
    wx_shoppercity: true,
    wx_shoppercountry: true,
    ex_shopperhousenumber: false,
    wx_shopperstate: true
  });

  const installmentsOptions = useMemo(
    () => generateInstallmentsOptions(selectedPlan, paymentMethod),
    [selectedPlan]
  );

  async function searchCEP({ values, setFieldValue }) {
    const { wx_shopperpostalcode } = values;

    if (!wx_shopperpostalcode) {
      return;
    }

    let newDisabledInputs = { ...disabledInputs };

    try {
      setLoadingCep(true);
      const response = await gateway.get(`location/${wx_shopperpostalcode}`);
      newDisabledInputs.wx_shopperstreet = !!response.ws_address;
      newDisabledInputs.wx_shoppercity = !!response.ws_city;
      newDisabledInputs.wx_shopperstate = !!response.ws_uf;
      newDisabledInputs.wx_shoppercountry = true;

      setFieldValue('wx_shopperstreet', response.ws_address);
      setFieldValue('wx_shoppercity', response.ws_city);
      setFieldValue('wx_shopperstate', response.ws_uf);
      setFieldValue('wx_shoppercountry', 'BR');
      setFieldValue('neighborhood', response.ws_neighborhood)
    } catch (error) {
      newDisabledInputs = Object.keys(newDisabledInputs).reduce(
        (acc, inputName) => {
          acc[inputName] = false;
          return acc;
        },
        {}
      );
    } finally {
      setDisabledInputs(newDisabledInputs);
      setLoadingCep(false);
    }
  }

  useEffect(() => {
    if (formRefer) {
      const { installments } = installmentData;
      formRefer.current.setFieldValue(
        'installments',
        installments
      )
    }
  }, [installmentData]);

  return (
    <Formik
      ref={formRefer}
      validationSchema={validationSchema}
      initialValues={validationSchema.cast()}
      onSubmit={handleGenerateTerm}
      render={({
        values,
        touched,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        resetForm,
        handleSubmit
      }) => (
        <>
          <Styles.Form onSubmit={handleSubmit}>
            <Text bold size={22}>
              Dados de Cobrança
            </Text>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <Input
                    placeholder="Nome"
                    name="wl_shopperfirstname"
                    label='Nome'
                    value={values.wl_shopperfirstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabledInputs.wl_shopperfirstname}
                    className="form-control"
                    error={
                      touched.wl_shopperfirstname &&
                      errors.wl_shopperfirstname
                    }
                  />

                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <Input
                    label="Sobrenome"
                    placeholder="Sobrenome"
                    name="wl_shopperlastname"
                    value={values.wl_shopperlastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabledInputs.wl_shopperlastname}
                    className="form-control"
                    error={
                      touched.wl_shopperlastname && errors.wl_shopperlastname
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <Select
                    name="installments"
                    label="Número de parcelas"
                    placeholder="Número de parcelas"
                    options={installmentsOptions}
                    value={values.installments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.installments && errors.installments}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <InputMask
                    maskChar={null}
                    mask="999.999.999-99"
                    name="cf_shoopercpf"
                    value={values.cf_shoopercpf}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabledInputs.cf_shoopercpf}
                  >
                    {inputProps => (
                      <Input
                        {...inputProps}
                        placeholder="000.000.000-00"
                        label='CPF'
                        error={touched.cf_shoopercpf && errors.cf_shoopercpf}
                        className="form-control"
                      />
                    )}
                  </InputMask>
                </div>
              </div>
            </div>

            <Text bold size={22} className="mt-3">
              Endereço Fiscal
            </Text>

            <div className="row mt-1">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="input-group">
                    <InputMask
                      maskChar={null}
                      mask="99999-999"
                      name="wx_shopperpostalcode"
                      value={values.wx_shopperpostalcode}
                      onChange={handleChange}
                      onBlur={event => {
                        handleBlur(event);
                        searchCEP({ values, setFieldValue });
                      }}
                      disabled={disabledInputs.wx_shopperpostalcode}
                    >
                      {inputProps => (
                        <Input
                          withIcon
                          {...inputProps}
                          loading={loadingCep}
                          label="CEP"
                          placeholder="Digite seu CEP ou escolha pesquisar"
                          sufix={<FaSearch style={{
                            alignItems: 'center',
                            display: 'flex',
                            cursor: 'pointer'
                          }}
                            onClick={() => {
                              searchCEP({ values, setFieldValue });
                            }} />}
                          className="form-control"
                          error={
                            touched.wx_shopperpostalcode &&
                            errors.wx_shopperpostalcode
                          }
                          onKeyUp={event => {
                            const enterKeyCode = 13;
                            if (
                              event.keyCode === enterKeyCode &&
                              event.target.value
                            ) {
                              searchCEP({ values, setFieldValue });
                            }
                          }}

                        />
                      )}

                    </InputMask>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <Styles.LabelField>Número</Styles.LabelField>
                    <Input
                      placeholder="Número"
                      name="ex_shopperhousenumber"
                      value={values.ex_shopperhousenumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disabledInputs.ex_shopperhousenumber}
                      className="form-control"
                      error={
                        touched.ex_shopperhousenumber &&
                        errors.ex_shopperhousenumber
                      }
                    />

                </div>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-sm-6">
                <div className="form-group">
                  <Styles.LabelField>Complemento</Styles.LabelField>
                    <Input
                      placeholder="Complemento"
                      name="additional_details"
                      value={values.additional_details}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <Styles.LabelField>Logradouro</Styles.LabelField>
                    <Input
                      placeholder="Logradouro"
                      name="wx_shopperstreet"
                      value={values.wx_shopperstreet}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      error={
                        touched.wx_shopperstreet && errors.wx_shopperstreet
                      }
                    />
                </div>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-sm-6">
                <div className="form-group">
                  <Styles.LabelField>Bairro</Styles.LabelField>
                    <Input
                      placeholder="Bairro"
                      name="neighborhood"
                      value={values.neighborhood}
                      onChange={handleChange}
                      onBlur={handleBlur}

                      error={touched.neighborhood && errors.neighborhood}
                      className="form-control"
                    />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <Styles.LabelField>Cidade</Styles.LabelField>

                    <Input
                      placeholder="Cidade"
                      value={values.wx_shoppercity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.wx_shoppercity && errors.wx_shoppercity}
                      className="form-control"
                    />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <Styles.LabelField>Estado</Styles.LabelField>
                    <Input
                      placeholder="Estado"
                      name="wx_shopperstate"
                      value={values.wx_shopperstate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.wx_shopperstate && errors.wx_shopperstate}

                      className="form-control"
                    />

                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <Styles.LabelField>País</Styles.LabelField>
                    <Input
                      placeholder="País"
                      name="wx_shoppercountry"
                      value={values.wx_shoppercountry}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.wx_shoppercountry && errors.wx_shoppercountry
                      }
                      className="form-control"
                    />
                </div>
              </div>
            </div>




            <div className="row d-flex align-items-center">
              <div className="col-sm-12 col-lg-8">
                <GeneralStyles.TermWithCheckBoxContainer>
                  <TextPaymentDefault
                    selectedPlan={selectedPlan}
                    paymentMethod={paymentMethod}
                  />
                </GeneralStyles.TermWithCheckBoxContainer>
              </div>
              <div className="col-sm-12 col-lg-4 d-flex align-items-center">
                <div
                  className="box-buttons"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <GeneralStyles.CleanButton
                    visual="outline"
                    onClick={() => resetForm()}
                  >
                    Limpar
                  </GeneralStyles.CleanButton>

                  <GeneralStyles.ConfirmButton
                    disabled={generatingTerm || loading}
                  >
                    {loading || generatingTerm ? <Loading /> : 'Gerar boleto'}
                  </GeneralStyles.ConfirmButton>
                </div>
              </div>
            </div>
          </Styles.Form>

          <PaymentTerm
            visible={showTerm}
            onBack={() => setShowTerm(false)}
            onAccept={() => onSubmit(formRefer.current.state.values)}
            onReject={() => setShowTerm(false)}
            termUrl={_.get(userTerm, 'ox_specific.wu_url')}
            processingAccept={loading}
          />
        </>
      )}
    />
  );
}

export default InvoiceForm;
