import { call, put } from "redux-saga/effects"

import { gateway } from "../../utils/api"
import { Creators as NotifcationsActions } from "../ducks/notifications/actions"



export function* getNotification(action) {
  try {
    const quantity = action.payload.locale === "navbar" ? 5 : 0
    const idProfile = localStorage.getItem("idProfile")
    const response = yield call(gateway.get, `/webbff/lit_app_web/notification/consultNotifications/${quantity}/${idProfile}`)
 
    if (response.length) {
      yield put(NotifcationsActions.getNotificationSuccess(response))
    } else {
      yield put(NotifcationsActions.getNotificationFailure("Não foram encontradas notificações"))
    }
  } catch (err) {
    yield put(NotifcationsActions.getNotificationFailure(err.response))
  }
}

export function* countNotificationsNotRead() {
  try {
    const idProfile = localStorage.getItem("idProfile")
    const response = yield call(gateway.get, `countHistoryNotfNotRead/${idProfile}`)

    yield put(NotifcationsActions.countNotificationsNotReadSuccess(response.message))
  } catch (err) {
    yield put(NotifcationsActions.countNotificationsNotReadFailure(err.response))
  }
}
