/**
 * Types
 */
export const Types = {
  VOUCHER_USER_TYPE: "voucher/VOUCHER_USER_TYPE",
}

/**
 * Reducer
 */

const INITIAL_STATE = {
  allowCreateVoucher: false,
  allowViewVoucher: false,
}

export default function profile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.VOUCHER_USER_TYPE:
      return {
        ...state,
        allowCreateVoucher: action.payload.allowCreateVoucher,
        allowViewVoucher: action.payload.allowViewVoucher,
      }
    default:
      return state
  }
}
/**
 * Actions
 */
export const Creators = {
  setUserTypeVoucher: idRole => {
    const allowCreateVoucher = idRole === 1
    const allowViewVoucher = idRole === 1 || idRole === 5 || idRole === 6 || idRole === 7

    return {
      type: Types.VOUCHER_USER_TYPE,
      payload: { allowCreateVoucher, allowViewVoucher },
    }
  },
}
