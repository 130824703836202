import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import api from '../../../utils/api';
import { toastDefaultOptions } from '../../../utils/functions';
import * as Styles from './styles';

class SaibaMais extends Component {
  notify = text => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 8500
    });
  };

  handleAnexo = obj => {
    const media = _.get(obj, 'media', '');
    switch (media && media.type) {
      case "PDF":
      case "VIDEO":
      case "EXE":
      case "TXT":
      case "XLS":
      case "ZIP":
        this.downloadAnexo(media);
        break;
      // case "BIB":
      //   this.startBook(obj.idAttachment)
      //   break

      default:
        console.log(media && media.type, 'download handler not found');
        break;
    }
  };

  startBook = (path = '') => {
    const { handle } = this.state;
    if (handle === 2) {
      if (path !== '') {
        this.selectBook(path);
      }
    } else {
      const idProfile = localStorage.getItem('idProfile');
      api
        .get(`library/oauth/${idProfile}`)
        .then(response => {
          if (response.status === 200) {
            console.log('Autenticou livro', response);
            this.setState({
              authlivro: response.message,
              handle: handle + 1
            });
            if (path !== '') {
              this.selectBook(path);
            }
          }
        })
        .catch(error => console.log(error));
    }
  };

  selectBook = path => {
    window.open(`${path}`);
  };

  downloadAnexo = ({ path }) => {
    if (path) {
      const linkDownload = document.createElement('a');
      linkDownload.setAttribute('download', '');
      linkDownload.setAttribute('href', path);
      linkDownload.setAttribute('target', '_blank');
      linkDownload.click();
    }
  };

  render() {
    const { selected, course } = this.props;
    let { knowMore = [] } =  course;

    if(course.type === 'TRAIL') {
      const { courseCode } = selected;
      const { children = [] } = course;
      children.forEach(child => {
        if(child.code === courseCode) {
          const { knowMore: knowMoreChild } = child;
          knowMore = knowMoreChild;
        }
      });
    }
    
    return (
      <div className="col-lg-8">
        <Styles.Container>
          <h4>SAIBA MAIS</h4>

          {knowMore.length === 0 && (
            <p style={{ fontSize: "14px", textAlign: "center" }}>
              Nenhum item para ser exibido.
            </p>
          )}

          {knowMore.map(lib => {
              return (
                <Styles.ItemContainer key={lib.id}>
                  <Styles.ContainerIcon>
                      {lib.type === 'BOOK'
                        ? lib.type
                        : _.get(lib, 'media.type', '?')}
                      {/* {lib.typeAttachment === "EXE" ? "XLS" : lib.typeAttachment} */}
                  </Styles.ContainerIcon>
                  <Styles.ItemDescription>
                    {_.get(lib, 'name', '?')}
                  </Styles.ItemDescription>
                  {_.get(lib, 'media', false) && (
                    <Styles.BtnDownload
                      type="button"
                      className="btn btn-light"
                      onClick={() => this.handleAnexo(lib)}
                    >
                      <i className="fa fa-download" aria-hidden="true" />
                      <a target="" />
                    </Styles.BtnDownload>
                  )}
                </Styles.ItemContainer>
              );
          })}

          {selected.knowMore && selected.knowMore.length > 0 && (
            <>
              <hr
                style={{
                  width: '100%',
                  margin: '10px auto',
                  background: '#f3f2f0'
                }}
              />
              <p style={{ fontSize: '16px', textAlign: 'center' }}>
                Saiba mais: {selected.name}
              </p>
              {selected.knowMore.map(lib => {
                return (
                  <Styles.Objeto key={lib.id}>
                    <div className="anexo-saiba-mais-curso">
                      <div className="anexo-texto-saiba-mais-curso">
                        {_.get(lib, 'media.type', '?')}
                      </div>
                    </div>
                    <span style={{ flex: 2, fontSize: 14 }}>
                      {_.get(lib, 'media.name', '?')}
                    </span>

                    {_.get(lib, 'media', false) && (
                      <button
                        type="button"
                        className="btn btn-light align-self-end"
                        onClick={() => this.handleAnexo(lib)}
                      >
                        <i className="fa fa-download" aria-hidden="true" />
                        <a target="" />
                      </button>
                    )}
                  </Styles.Objeto>
                );
              })}
            </>
          )}
        </Styles.Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selected: state.newCourse.selected,
  course: state.newCourse.data
});

export default connect(mapStateToProps)(SaibaMais);
