import styled, { css } from "styled-components";
import { APPEARANCE } from "../../../constants";

export const LinkTo = styled.span`
  
  ${props => (props.appearance === APPEARANCE.LINK) ? css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover, 
    &:hover * {
      cursor: pointer;
      color: var(--link-primary);
    } 
    i {
      margin-left: 5px;
    }
  ` : `
    &:hover,
    &:hover * {
      cursor: pointer;
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.LINK_ICON_ACTIVE) && css`
    color: var(--link-primary);
    background: var(--transparent);
    text-transform: initial;
    display: inline-block;
    border-radius: 5px;
    border: 0; 
    cursor: pointer;
    text-align: center;
    right: 0;
    transition: ease .3s all;
    position: relative;
    i {
      margin-right: 5px;
      color: var(--link-icon-primary);
    }
    &:hover {
      cursor: pointer;
      color: var(--link-primary-hover);
      background: var(--transparent);
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.MORE) && css`
    background: var(--transparent);
    display: block;
    border: 0; 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    padding-top: 0;
    height: 0;
    padding-bottom: 2rem;
    bottom: 0;
    width: 100%;
    label { 
      display: table!important;
      margin: 1rem auto;
      width: auto!important;
    }
    i {
      margin-left: 5px;
      font-size: 120%;
    }
    &:hover {
      cursor: pointer;
      color: var(--link-primary);
      background: var(--transparent);
    }
  `}

  ${props => (props.appearance === APPEARANCE.BLUR) && css`
    background: transparent;
    border: 0;
    text-align: center;
    -webkit-transition: ease .3s all;
    transition: ease .3s all;
    position: relative;
    padding-top: 0;
    height: 0;
    padding-bottom: 1rem;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    cursor: pointer;
    &:before {
      content: "";
      background: white;
      display: block;
      width: calc(100% + 15px);
      height: 80px;
      position: absolute;
      bottom: 0;
      left: -15px;
      z-index: 0;
      -webkit-filter: blur(10px);
      filter: blur(10px);
    }
    label { 
      display: table!important;
      margin: 1rem auto;
      width: auto!important;
      z-index: 1;
      position: relative;
    }
    i {
      margin-left: 5px;
      font-size: 120%;
    }
  `}
  
`;
