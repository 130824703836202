import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} type //defines the type of display of the component
 * @param {Array} content //defines the content to be displayed
 * @param {Number} maxCharacters //defines the maximum character value
 * @param {Number} totalMembers //defines the total number of members
 * @param {String} administrator //inform the name of the administrator
 * @param {Number} totalModules //informs the total number of modules
 * @param {Number} totalHours //reports the number of hours
 * @param {Boolean} favorite //tells whether the content is a favorite or not
**/

export default ({
  type = DEFAULT_VALUES.STRING,
  content = DEFAULT_VALUES.LIST,
  maxCharacteres = 80,
  totalMembers = DEFAULT_VALUES.NUMBER,
  administrator = DEFAULT_VALUES.STRING,
  totalModules = DEFAULT_VALUES.NUMBER,
  totalHours = DEFAULT_VALUES.NUMBER,
  favorite = DEFAULT_VALUES.BOOLEAN
}) => {

  const [showMore, setShowMore] = React.useState(true);

  function formatNumber(value) {
    const convertNumber = /\B(?=(\d{3})+(?!\d))/g;
    const limit = 2000;

    if (value > limit) {
      value = `+ ${limit}`;
    } else {
      value = value;
    }
    return value.toString().replace(convertNumber, '.');
  }

  return (
    <>
      <Screen
        type={type}
        content={content}
        totalMembers={totalMembers}
        administrator={administrator}
        totalModules={totalModules}
        totalHours={totalHours}
        favorite={favorite}
        maxCharacteres={maxCharacteres}
        showMore={showMore}
        setShowMore={setShowMore}
        formatNumber={formatNumber}
      />
    </>
  );
};
