import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 0;
  max-width: 700px;
  margin: 0 auto;
`

export const Title = styled.h2`
  margin-bottom: 10px;
`


export const BoxSections = styled.div`
  margin-top: 20px;
`

export const Section = styled.section`
  & + section {
    margin-top: 20px;
  }
`

export const SectionTitle = styled.h5`
  padding-bottom: 5px;
  border-bottom: 1px solid #c1c1c1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TextError = styled.p`
  margin: 0;
  margin-top: 10px;
  color: red;
`

export const ButtonInfo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background: #27aae1;
  border-radius: 50%;
  border: 0;
  color: #fff;
  margin-right: 10px;
`

export const TextInfo = styled.p`
  background: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 12px;
  width: 300px;
  padding: 5px 10px;
  font-size: 14px;
  text-align: center;
`



