import 'react-intl-tel-input/dist/main.css';
import ptBr from 'react-phone-number-input/locale/pt-BR'
import React, { Fragment, useState } from "react"
import { formatPhoneNumber } from 'react-phone-number-input'
import { Formik } from "formik"
import * as Yup from "yup"
// import IntlTelInput from 'react-intl-tel-input';
import { toast } from 'react-toastify';
import {
  toastErrorAndWarningOptions,
  isDomainValid,
  isPhoneValid
} from '../../../utils/functions';


import { ErrorMessage, ButtonLinks } from "../styles"
import { InputLabel, ButtonRegister, InputWrap, InputText, InputPhone2 } from "../../Styles/FormStyle"

import {register} from '../../../business/auth'

import ModalPolitica from "../../Modals/ModalPolitica"
import ModalTermos from "../../Modals/ModalTermos"

import ModalResponsible from './ModalResponsible';


const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Campo muito curto!")
    .max(50, "Campo muito longo!")
    .required("Primeiro nome obrigatório"),
  lastName: Yup.string()
    .min(2, "Campo muito curto!")
    .max(50, "Campo muito longo!")
    .required("Sobrenome obrigatório"),
  phoneNumber: Yup.string()
    .required('Telefone obrigatório'),
  email: Yup.string()
    .email("email inválido")
    .required("Email obrigatório"),
  password: Yup.string()
    .min(6, "A senha deve conter 6 ou mais caracteres.")
    .required("Senha obrigatório"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas não coincidem")
    .required("Confirmação de senha obrigatório"),
  terms: Yup.boolean().oneOf([true], "É necessário aceitar os termos de uso!"),
  
})

const RegisterForm = ({ doRegisterSuccess, doRegisterFailure, userDetails, litpass }) => {

    const [disableButton, setDisableButton] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false)

    const [openModalResponsible, setOpenModalResponsible] = useState(false)
    const [responsible, setResponsible] = useState({ name: "", email: "", cpf: "" })    

    const onCloseModal = () => setOpenModalResponsible(false);
    const registerUser = async (values, actions) => {
      if(!!values.email.match(/@brb\.com\.br$/) === false){
        values.responsible = responsible;
        const response = await register(values)
        if(response.data) {
          doRegisterSuccess(response.data)
        } else {
          doRegisterFailure(response.error, values.email)
          actions.setSubmitting(false)
        }
      } 

      if(!!values.email.match(/@brb\.com\.br$/) === true) {
        const BRBErrorMessage = 'Identificamos que você tem um benefício pelo Banco de Brasília, seu acesso deve ser feito através da plataforma de educação corporativa da sua empresa.'
        toast.error(BRBErrorMessage, toastErrorAndWarningOptions);
        actions.setSubmitting(false)
      }
    }

  const openModal = (setFieldValue, modalName) => {
    setFieldValue(modalName, true)
  }

  const handleChangeEmail = (e) => {
    setDisableButton(isDomainValid(e.target.value))
  }

  const validatePhoneNumber = (
    phoneNumber
  ) => {
    if(phoneNumber) {
      const isValid = isPhoneValid(formatPhoneNumber(phoneNumber))
      if(isValid) {
        setDisableButton(false)
        setPhoneNumberError(false)
      } else {
        setDisableButton(true)
        setPhoneNumberError(true)
      }
    }
  }

  return (
    <Fragment>
      <Formik
        initialValues={{
          name: userDetails && userDetails.firstName ? userDetails.firstName : "",
          lastName: userDetails && userDetails.lastName ? userDetails.lastName : "",
          email: userDetails && userDetails.email ? userDetails.email : "",
          phoneNumber: userDetails && userDetails.phoneNumber ? `+55${userDetails.phoneNumber}` : "",
          password: "",
          passwordConfirm: "",
          dateOfBirth: "",
          terms: "",
          modalTerms: false,
          modalPolitics: false,
        }}
        validationSchema={ValidationSchema}
        onSubmit={registerUser}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => { 
                   
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-group d-flex justify-content-between">
                <InputWrap error={errors.name && touched.name} className="space">
                  <InputText
                    placeholder="Primeiro nome"
                    className="form-control "
                    id="inputName"
                    value={values.name}
                    name="name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <InputLabel htmlFor="inputName">Primeiro nome</InputLabel>
                </InputWrap>
                <InputWrap error={errors.lastName && touched.lastName} className="space">
                  <InputText
                    placeholder="Sobrenome"
                    className="form-control"
                    id="inputLastName"
                    value={values.lastName}
                    name="lastName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <InputLabel htmlFor="inputLastName">Sobrenome</InputLabel>
                </InputWrap>
              </div>
     
              <div className="form-group">
                <InputWrap error={errors.email && touched.email}>
                  <InputText
                    type="email"
                    placeholder={(litpass) ? "Email corporativo" : "E-mail"}
                    className="form-control"
                    id="inputEmail"
                    name="email"
                    onChange={e => {
                      handleChange(e)
                      handleChangeEmail(e)
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    required
                  />
                  <InputLabel htmlFor="inputEmail">E-mail</InputLabel>
                </InputWrap>
                
              </div>
  
              <div className="form-group">
                <InputWrap error={(errors.phoneNumber && touched.phoneNumber || phoneNumberError)}>
                  <InputPhone2
                    fieldName="phoneNumber"
                    placeholder="Telefone"
                    id="inputPhoneNumber"
                    name="phoneNumber"
                    labels={ptBr}
                    international={false}
                    country="BR"
                    defaultCountry="BR"
                    value={values.phoneNumber}
                    onChange={(value) => {
                      validatePhoneNumber(value)
                      setFieldValue("phoneNumber", value)
                    }} 
                  />               
                  <InputLabel htmlFor="inputPhoneNumber">Telefone</InputLabel>
                </InputWrap>
              </div>
              
  
              <div className="form-group d-flex justify-content-between">
                <InputWrap error={errors.password && touched.password} className="space">
                  <InputText
                    type="password"
                    placeholder="Senha"
                    className="form-control"
                    id="inputPassword"
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <InputLabel htmlFor="inputPassword">Senha</InputLabel>
                </InputWrap>
  
                <InputWrap error={errors.passwordConfirm && touched.passwordConfirm} className="space">
                  <InputText
                    type="password"
                    placeholder="Confirmar senha"
                    className="form-control"
                    id="inputPasswordConfirm"
                    value={values.passwordConfirm}
                    name="passwordConfirm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <InputLabel htmlFor="inputPasswordConfirm">Confirmar senha</InputLabel>
                </InputWrap>
              </div>
  
              <div className="d-flex align-items-center">
                <div id="termos" style={{ marginRight: "10px" }}>
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        value={values.terms}
                        name="terms"
                        id="input-check"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="cr" id="icon-check">
                        <i id="icon" className="cr-icon fa fa-check" />
                      </span>
                      Aceito os
                      <ButtonLinks type="button" onClick={() => openModal(
                        setFieldValue,
                        'modalTerms',
                      )}>
                        termos
                      </ButtonLinks>
                      e condições de uso e a
                      <ButtonLinks type="button" onClick={() => openModal(
                        setFieldValue,
                        'modalPolitics',
                      )}>
                        política de privacidade
                      </ButtonLinks>
                    </label>
                  </div>
                  {errors.terms && touched.terms && <ErrorMessage>{errors.terms}</ErrorMessage>}
                </div>
              </div>
              <div className="d-flex align-items-center mt-1 justify-content-center">
                <ButtonRegister
                  id="btn-cadastro"
                  className={`btn ${isSubmitting && "btn-loading"}`}
                  type="submit"
                  disabled={isSubmitting || disableButton || !values.terms || phoneNumberError}
                >
                  CADASTRAR
                </ButtonRegister>
              </div>
              <ModalTermos
                visible={values.modalTerms}
                userEmail={values.email}
                onClose={() => setFieldValue("modalTerms", false)}
              />
              <ModalPolitica
                visible={values.modalPolitics}
                userEmail={values.email}
                onClose={() => setFieldValue("modalPolitics", false)}
              />
            </form>
          )
        }}
      />
      <ModalResponsible 
              openModal={openModalResponsible}
              onCloseModal={onCloseModal}
              setResponsible={setResponsible}
            />
    </Fragment>
  )
}

export default RegisterForm
