/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { GiTicket } from 'react-icons/gi';
import { toast } from 'react-toastify';
import { MdDone } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import CreditCardPayment from '../CreditCardPayment';
import Payroll from '../Payroll';
import InvoicePayment from '../InvoicePayment';
import PIXPayment from '../PIXPayment';
import api from '../../../../utils/api';
import {
  ContainerPlanTypes,
  ButtonPlan,
  AndroidPaymentButton,
  Text,
  FeatureItem,
  PlanFormContainer,
  PaymentResume,
  PaymentTotalPriceView,
  TotalPriceView
} from '../../styles';
import { PlanItem } from '../../../Planos/styles';
import { formatReal, toastDefaultOptions } from '../../../../utils/functions';
import PaymentMethodType from '../../paymentMethodType';
import PlanType from '../../planType';
import { handleCpfConsultation } from '../../../../business/payment';
import adsTracking from '../../../../utils/adsTracking';
import {
  LabelField,
  ItemFormField,
  ItemFormFieldSelect
} from '../../../../Components/Styles/FormStyle';

function PaPaymentMethodsSection({
  paymentData,
  error,
  location,
  loading,
  back,
  onCreditCardPayment,
  onPayrollSubmit,
  onInvoicePayment,
  onRedirectPi,
  paymentInvoiceError,
  payrollError,
  cpf,
  handleTrial,
  resetPaymentError,
  isEdit
}) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [claimId, setClaim] = useState('');
  const [discount, setDiscount] = useState(0);
  const [price, setPrice] = useState(0);
  const [installments, setInstallments] = useState(null);
  const [errorInstallments, seterrorInstallments] = useState(false);
  const [installmentOptions, setInstallmentOptions] = useState(0);
  const [showMessageCPF, setShowMessageCPF] = useState(false);
  const [cpfState, setCPFState] = useState(cpf);
  const [documentType, setDocumentType] = useState();
  const [idTotvsCourses, setIdTotvsCourses] = useState([]);
  const [installmentPrice, setInstallmentPrice] = useState(0);

  const changeInstallmentOptions = type => {
    let instalmentValue, instalmentPriceFromTotalValue;
    if (paymentData.installmentsConfig) {
      const installmentKey = type
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const tempInstallments = paymentData.installmentsConfig[installmentKey];
      if (tempInstallments) {
        if (tempInstallments.nx_maxinstallments <= paymentData.installment) {
          instalmentValue = tempInstallments.nx_maxinstallments;
        } else {
          instalmentValue = paymentData.installment;
        }
        setInstallmentOptions(instalmentValue);
      } else {
        instalmentValue = paymentData.installment;
        setInstallmentOptions(paymentData.installment);
      }
      instalmentPriceFromTotalValue =
        paymentData.price / parseInt(instalmentValue);
      setInstallmentPrice(instalmentPriceFromTotalValue);
    } else {
      instalmentValue = paymentData.installment;
      setInstallmentOptions(paymentData.installment);
      setInstallmentPrice(instalmentPriceFromTotalValue);
    }
  };

  const getDocumentTypeAndCpf = async () => {
    try {
      const response = await api.get('me');
      setDocumentType(response.documentType);
      setCPFState(response.cpf);
      // eslint-disable-next-line no-shadow
    } catch (error) {
      console.log('Ops, Falha em consultar tipo de documento');
    }
  };

  const CPFVerification = async () => {
    try {
      const response = await handleCpfConsultation(cpfState, documentType);
      if (response) {
        const certificates = localStorage.getItem('certificates');
        // let message = `Verificamos que o usuário ${response.firstName}, com o login ${response.email} já está utilizando esse mesmo documento, caso você deseje
        //               continuar com esse mesmo CPF você perderá direito ao período
        //               TRIAL e a cobrança será feita imediatamente.`
        // eslint-disable-next-line no-unused-vars
        let message =
          'Pelo presente, você autoriza o LIT a cobrar de você, após o período de teste, todo mês automaticamente até que você cancele sua inscrição. Não podemos emitir reembolsos após a cobrança em seu cartão. Termos e condições disponíveis aqui.';
        if (certificates) {
          message =
            'Seu CPF já foi utilizado em um plano TRIAL, por esse motivo no momento da assinatura a cobrança será feita imediatamente.';
        }
        setShowMessageCPF(true);
      } else {
        setShowMessageCPF(false);
      }
      // eslint-disable-next-line no-shadow
    } catch (error) {
      console.log('Ops, Erro ao consultar CPF');
    }
  };

  useEffect(() => {
    getDocumentTypeAndCpf();
  }, []);

  useEffect(() => {
    if (documentType) {
      CPFVerification();
      const paymentDataAsPlan = paymentData;
      setDiscount(paymentData.discountValue);
      setPrice(paymentData.price);
      setIdTotvsCourses(_.get(paymentData, 'config.idTotvsCourses', []));

      if (!paymentDataAsPlan.payments) {
        paymentDataAsPlan.payments = [{ type: PaymentMethodType.CREDITO }];
      }

      if (paymentDataAsPlan && paymentDataAsPlan.payments) {
        let supportedPaymentMethods = [
          {
            type: PaymentMethodType.CREDITO,
            title: 'Cartão de crédito'
          },
          {
            type: PaymentMethodType.FOLHA,
            title: 'Desconto em folha'
          },
          {
            type: PaymentMethodType.BOLETO,
            title: 'Boleto'
          },
          {
            type: PaymentMethodType.PIX,
            title: 'PIX'
          }
        ];

        if (documentType !== 'IDBRASIL') {
          supportedPaymentMethods = [
            {
              type: PaymentMethodType.CREDITO,
              title: 'Cartão de crédito'
            },
            {
              type: PaymentMethodType.FOLHA,
              title: 'Desconto em folha'
            }
          ];
        }
        const filteredPaymentMethods = paymentDataAsPlan.payments
          .map(method =>
            supportedPaymentMethods.find(item => item.type === method.type)
          )
          /**
           * Remove unsupported payment methods ('find' returns 'undefined' for methods
           * not present in 'supportedPaymentMethods' and payment method FOLHA if plan type is Yearly
           */
          .filter(method => {
            if (method) {
              if (
                (method.type === PaymentMethodType.BOLETO &&
                  paymentData.planType === PlanType.Monthly) ||
                (method.type === PaymentMethodType.FOLHA &&
                  paymentData.planType === PlanType.Yearly)
              ) {
                return false;
              }
              return true;
            }
            return false;
          })
          .sort((a, b) => a.title.localeCompare(b.title));

        let actualSelectedPaymentMethod = PaymentMethodType.CREDITO;
        if (filteredPaymentMethods && filteredPaymentMethods.length === 1) {
          actualSelectedPaymentMethod = filteredPaymentMethods[0].type;
          setSelectedPaymentMethod(filteredPaymentMethods[0].type);
        } else {
          const hasPayroll = filteredPaymentMethods.some(
            item => item.type === PaymentMethodType.FOLHA
          );
          setSelectedPaymentMethod(
            hasPayroll ? PaymentMethodType.FOLHA : PaymentMethodType.CREDITO
          );
          actualSelectedPaymentMethod = hasPayroll
            ? PaymentMethodType.FOLHA
            : PaymentMethodType.CREDITO;
        }
        changeInstallmentOptions(actualSelectedPaymentMethod);

        setPaymentMethods(filteredPaymentMethods);
      } else {
        setInstallmentOptions(paymentData.installment);
      }
    }
  }, [documentType]);

  const handleSelectedPaymentMethod = type => {
    adsTracking.sendGoogleAnalyticsEvent({
      eventName: 'click_payment_option',
      eventCategory: 'purchase_flow',
      eventLabel: `method:${type}`,
      eventValue: paymentData.id,
      extraData: {
        price: paymentData.price
      }
    });
    setInstallments(null);
    setSelectedPaymentMethod(type);
    changeInstallmentOptions(type);
  };

  const handleChangeInstallments = evt => {
    resetPaymentError();
    setInstallments(evt.target.value !== 'Selecione' ? evt.target.value : null);
    seterrorInstallments(false);
  };

  const handleChangeClaim = e => {
    const foundClaim = paymentData.claims.find(
      i => i.claimId === e.target.value
    );
    setClaim(e.target.value);
    if (foundClaim) {
      const {
        discountData: { type, value }
      } = foundClaim;
      let newDiscount = 0;
      let newPrice = 0;
      if (type === 'PERCENT') {
        newDiscount =
          paymentData.discountValue + (value / 100) * paymentData.price;
        newPrice = paymentData.price - (value / 100) * paymentData.price;
        setDiscount(newDiscount);
        setPrice(newPrice);
      } else {
        newDiscount = paymentData.discountValue + value;
        newPrice = paymentData.price - value;
        setDiscount(newDiscount);
        setPrice(newPrice);
      }
      toast('Cupom aplicado com sucesso', toastDefaultOptions);
    } else {
      setDiscount(paymentData.discountValue);
      setPrice(paymentData.price);
    }
  };

  const renderOptionText = item => {
    const {
      discountData: { type, value }
    } = item;
    if (type === 'PERCENT') {
      return `${item.cupom} (${value}%)`;
    }
    return `${item.cupom} (R$ ${value})`;
  };

  const certificates = localStorage.getItem('certificates');
  return (
    <>
      {paymentMethods.length > 1 && (
        <ContainerPlanTypes>
          {paymentMethods.map((method, index) => (
            <ButtonPlan
              key={method.type}
              active={selectedPaymentMethod === method.type}
              testID={`androidPaymentButtonMethod-${index}`}
              selected={selectedPaymentMethod === method.type}
              onClick={() => handleSelectedPaymentMethod(method.type)}
              style={{ background: 'none' }}
            >
              <AndroidPaymentButton
                id={`androidPaymentButtonMethod-${index}`}
                type="button"
                active={selectedPaymentMethod === method.type}
              >
                <span>{method.title}</span>
              </AndroidPaymentButton>
            </ButtonPlan>
          ))}
        </ContainerPlanTypes>
      )}
      <PaymentResume style={{ marginTop: paymentMethods.length > 1 ? 0 : 30 }}>
        <PaymentTotalPriceView>
          <small style={{ fontSize: 12 }}>Total do pagamento</small>
          <TotalPriceView>
            R${' '}
            {paymentData && paymentData.price ? formatReal(price) : 'Selecione'}
          </TotalPriceView>
        </PaymentTotalPriceView>
        <small style={{ display: 'flex' }}>
          {selectedPaymentMethod !== PaymentMethodType.PIX &&
          selectedPaymentMethod !== PaymentMethodType.FOLHA &&
          installmentOptions
            ? `Em até ${installmentOptions}x sem juros.`
            : `A vista`}
        </small>
      </PaymentResume>
      <p>
        Para que possamos finalizar o processo e iniciar seus estudos,
        precisamos que nos informe a forma de pagamento e alguns dados.
      </p>
      <PlanFormContainer>
        <div className="formulario" style={{ flex: 1 }}>
          <div style={{ width: '100%', padding: '0px', margin: '0px' }}>
            {selectedPaymentMethod !== PaymentMethodType.PIX && (
              <div className="row">
                {paymentData.claims &&
                  paymentData.claims.length > 0 &&
                  (selectedPaymentMethod === PaymentMethodType.CREDITO ||
                    selectedPaymentMethod === PaymentMethodType.BOLETO) && (
                    <div className="col-md-6 d-flex align-items-center justify-content-between">
                      <Text>
                        <GiTicket /> <span>Aplicar cupom:</span>
                      </Text>
                      <select
                        className="form-control"
                        value={claimId}
                        name="cupom"
                        id="select-cupom"
                        onChange={handleChangeClaim}
                      >
                        <option value="">Cupom não selecionado</option>
                        {paymentData.claims.map((item, index) => {
                          return (
                            <option key={index} value={item.claimId}>
                              {renderOptionText(item)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
              </div>
            )}

            {selectedPaymentMethod !== PaymentMethodType.PIX && (
              <div
                className="row justify-content-md-center"
                style={{ marginBottom: '20px', backgroundColor: 'red' }}
              >
                <div className="col-md-8 col-12">
                  {paymentData &&
                    (paymentData.planType === 1 ||
                      paymentData.planType === 'yearly') &&
                    (selectedPaymentMethod === PaymentMethodType.CREDITO ||
                      selectedPaymentMethod === PaymentMethodType.BOLETO) && (
                      <>
                        {certificates && (
                          <div
                            className="col-12"
                            style={{
                              marginTop: '-60px',
                              marginBottom: '16px',
                              background: '#22ace2',
                              color: 'white',
                              padding: '12px',
                              borderRadius: '10px'
                            }}
                          >
                            <>
                              <b> AVISO </b>: Olá, como você já usufruiu do
                              período de trial anteriormente, a cobrança do seu
                              plano será feita imediatamente após a confirmação.
                            </>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <LabelField>Número de parcelas</LabelField>
                              <ItemFormFieldSelect>
                                <select
                                  className="form-control"
                                  required
                                  value={installments}
                                  name="parcelas"
                                  onChange={handleChangeInstallments}
                                >
                                  {!installments && (
                                    <option
                                      key={0}
                                      value={null}
                                      selected
                                      disabled
                                      hidden
                                    >
                                      Selecione
                                    </option>
                                  )}
                                  {Array(Number(installmentOptions))
                                    .fill()
                                    .map((item, index) => {
                                      const nro = index + 1;
                                      const formated =
                                        nro < 10 ? `0${nro}` : nro;
                                      return (
                                        <option key={nro} value={nro}>
                                          {formated}
                                        </option>
                                      );
                                    })}
                                </select>
                              </ItemFormFieldSelect>
                              {errorInstallments && (
                                <p style={{ fontSize: '13px', color: 'red' }}>
                                  Selecione o número de parcelas
                                </p>
                              )}
                              <small className="font-weight-bold mb-2 mt-0">
                                {installments
                                  ? `${installments} x de R$ ${formatReal(
                                      paymentData.price / installments
                                    )}`
                                  : ''}
                              </small>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            )}

            {selectedPaymentMethod === PaymentMethodType.CREDITO && (
              <CreditCardPayment
                plan={paymentData}
                selectedPaymentMethod={selectedPaymentMethod}
                back={back}
                location={location}
                loading={loading}
                onSubmit={onCreditCardPayment}
                error={error}
                installments={installments}
                claimId={claimId}
                handleRedirectPi={onRedirectPi}
                setInstallmentsError={() => seterrorInstallments(true)}
                showMessageCPF={showMessageCPF}
                handleTrial={handleTrial}
                resetPaymentError={resetPaymentError}
                isEdit={isEdit}
              />
            )}

            {selectedPaymentMethod === PaymentMethodType.FOLHA && (
              <Payroll
                plan={paymentData}
                back={back}
                location={location}
                loading={loading}
                onSubmit={onPayrollSubmit}
                error={payrollError}
                handleRedirectPi={onRedirectPi}
                handleTrial={handleTrial}
              />
            )}

            {selectedPaymentMethod === PaymentMethodType.BOLETO && (
              <InvoicePayment
                plan={paymentData}
                back={back}
                location={location}
                loading={loading}
                installments={installments}
                claimId={claimId}
                onSubmit={onInvoicePayment}
                error={paymentInvoiceError}
                handleRedirectPi={onRedirectPi}
                setInstallmentsError={() => seterrorInstallments(true)}
                showMessageCPF={showMessageCPF}
                documentType={documentType}
                handleTrial={handleTrial}
              />
            )}

            {selectedPaymentMethod === PaymentMethodType.PIX && (
              <PIXPayment
                plan={paymentData}
                location={location}
                loading={loading}
                back={back}
                onSubmit={onInvoicePayment}
                error={paymentInvoiceError}
                handleRedirectPi={onRedirectPi}
                setInstallmentsError={() => seterrorInstallments(true)}
                showMessageCPF={showMessageCPF}
                documentType={documentType}
                handleTrial={handleTrial}
              />
            )}
          </div>
        </div>
      </PlanFormContainer>
      <div className="col-md-12" style={{ marginTop: 15 }}></div>
    </>
  );
}

export default PaPaymentMethodsSection;
