import React, { Component } from 'react'

import {CircularProgressbar} from 'react-circular-progressbar'


export default class CircularProgressbarchart extends Component {
	constructor(props) {
		super(props)
		const {percentage} = this.props

		this.state = {
			percentage,
		}
	}

	render() {
		const {percentage} = this.state
		const {index = 0} = this.props
		const colors = ['#0cb2e3', '#43dba2', '#f8ee1e', '#0cb2e3', '#43dba2', '#f8ee1e']
		return (
			<CircularProgressbar
				value={percentage}
				text={`${percentage}%`}
				styles={{
					path: { stroke: `${colors[index]}` },
				}}
			/>
		)
	}
}
