import React, {useMemo} from 'react';

import {Container} from './styles'

function Radio({ labelText, checked, id, color, textPosition,viewMode, status, ...rest }) {

  const labelStyles = useMemo(() => {
    if(viewMode && checked) {
      return {
        color: '#6ac259',
      };
    }

    return {}
  }, [viewMode, checked])

  const inputColor = useMemo(() => {
    if(viewMode && status) {
      switch (status) {
        case 'INCORRECT':
          return '#e03b38';
        case 'CORRECT':
          return '#6ac259';
        default:
          return '';
      }
    }
    return ''
  }, [viewMode, status])

  return (
    <Container viewMode={viewMode} status={status} color={inputColor} textPosition={textPosition}>
      <input id={id} {...rest} type="radio" checked={checked}></input>
      <label style={{...labelStyles}} htmlFor={id}>{labelText}</label>
    </Container>
  );
}

export default Radio;
