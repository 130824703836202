import React from "react";
import {
  COLOR,
  APPEARANCE,
  SIZE,
  FONT_TYPE,
  BORDER_TYPE,
  TRANSFORM
} from "../../../constants";
import Text from "../Text";
import Image from "../Image";
import Block from "../Block";
import Clickable from "../Clickable";
import { Span, Description, ContentAtBottom } from "./styles";

export default ({ title, description, author, image }) => {
  return (
    <>
      <Block
        color={COLOR.BLOCK_PRIMARY}
        appearance={APPEARANCE.BANNER_BLOCK}
        delay={1000}
      >
        <Clickable>
          <Span>
            <Image
              src={image}
              appearance={APPEARANCE.BANNER_BLOCK}
              border={BORDER_TYPE.NO_BORDER}
            />
          </Span>
          <Description>
            <Text
              size={SIZE.MEDIUM}
              text={title}
              fontType={FONT_TYPE.BOLD}
              color={COLOR.LIGHT}
              appearance={APPEARANCE.TITLE}
            />
            <Text
              size={SIZE.MEDIUM}
              text={description}
              color={COLOR.LIGHT}
              appearance={APPEARANCE.TITLE}
            />
          </Description>
          <ContentAtBottom>
            <Text
              size={SIZE.EXTRA_SMALL}
              text={author}
              transform={TRANSFORM.UPPERCASE}
              color={COLOR.LIGHT}
              fontType={FONT_TYPE.ITALIC}
            />
          </ContentAtBottom>
        </Clickable>
      </Block>
    </>
  );
};
