import React from "react";
import { ICON, COLOR, ACTION, SIZE, TRANSFORM } from "../../../constants";
import Icon from "../../basicComponents/Icon";
import Text from "../../basicComponents/Text";
import { Search, InputSearch } from "./styles";

export default ({ label, value, onChange }) => {
  return (
    <>
      <Search>
        {label && (
          <Text
            text={label}
            transform={TRANSFORM.UPPERCASE}
            size={SIZE.MEDIUM}
          />
        )}
        <Icon color={COLOR.DARK} icon={ICON.SEARCH} />

        <InputSearch
          type="search"
          placeholder={ACTION.SEARCH}
          aria-label={ACTION.SEARCH}
          value={value}
          onChange={onChange}
          onKeyDown={onChange}
        />
      </Search>
    </>
  );
};
