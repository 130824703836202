import styled from 'styled-components';

export const Nav = styled.nav`
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0%, #27aae1),
    color-stop(100%, #fcee1e)
  );
`

export const Content = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`


