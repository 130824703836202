import styled, { css } from "styled-components";
import { COLOR } from "../../../constants";

export const BlockTitle = styled.span`
  label:first-of-type {
    height: 18px;
    min-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    padding-top: 0;
  }
  @media (max-width: 991px) {
    label {
      margin-left: 30px!important;
      width: 80%!important;
    }
    div {
      display: inline!important;
      margin: 0!important;
    }
  }
`;

export const IconType = styled.span`
  
  border-left: 8px solid var(--transparent);
  position: absolute;
  left: 8px;
  margin-top: 3px;
  padding-left: 15px;

  ${props => (props.color === COLOR.PRIMARY) && css`
    border-color: var(--default-blue);
  `}

  ${props => (props.color === COLOR.SECONDARY) && css`
    border-color: var(--default-yellow);
  `}

  ${props => (props.color === COLOR.SUCCESS) && css`
    border-color: var(--default-green);
  `}

  ${props => (props.color === COLOR.LIGHT) && css`
    border-color: var(--white);
  `}

  ${props => (props.color === COLOR.DARK) && css`
    border-color: var(--black);
  `}

  ${props => (props.color === COLOR.GRAY) && css`
    border-color: var(--default-gray);
  `}

  @media (max-width: 991px) {
    padding-left: 10px;
  }

`;

export const IconPrivacy = styled.span`
  display: table;
  margin: 3px;
  text-align: right;
  width: 100%;
  i { font-size: 180%; }
  @media (max-width: 991px) {
    position: absolute;
    top: 12px;
    right: 8px;
    width: auto;
    i { font-size: 130%; }
  }
`;
