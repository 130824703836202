import React from "react";

import { Container, Button, NavigationContainer } from "./styles";

function ActionFooter({ 
    onExit, 
    onGoBack,
    onGoNext,
    disableNextButton,
    allowassessmentresponse
   }) {
  return (
    <Container>
      <Button
        id="exitButton"
        color="#f8cecc"
        onClick={() => {
          if (onExit) {
            onExit();
          }
        }}
        // messengerSpacing={messenger}
      >
        SAIR
      </Button>
      {allowassessmentresponse && (
        <main>
          <NavigationContainer>
            <Button
              color="#27aae1"
              onClick={() => {
                if (onGoBack) {
                  onGoBack();
                }
              }}
            >
              ANTERIOR
            </Button>

            <Button
              color="#27aae1"
              disabled={disableNextButton}
              onClick={() => {
                if (onGoNext) {
                  onGoNext();
                }
              }}
            >
              PRÓXIMA
            </Button>
          </NavigationContainer>
        </main>
      )}
    </Container>
  );
}

export default ActionFooter;
