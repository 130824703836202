import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {CircularProgressbar} from 'react-circular-progressbar'

import {Container, Title, Text, ContainerProgressBar, ContainerWatsonBage} from './styles'

import WatsonBadgeFooter from "../../../Carreira/WatsonBadgeFooter"

const GIF_LIT = require("../../../../images/animacao_paul_standby.gif")

export default function OnboardingSidebar({ isVisibleText }) {

  const [percentage, setPercentage] = useState(0)
  const onboardingData = useSelector(state => state.onboarding.onboardingData)
    
  useEffect(() => {
   if(onboardingData.length > 0) {
    const stagesCompleted = onboardingData
      .map(i => i.bf_concluded)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    const percent = (stagesCompleted / onboardingData.length) * 100
  
    setPercentage(Math.ceil(percent))
   }
  }, [onboardingData])
  
  return(
    // Mostra todos itenso do "Seu Onboarding no LIT" ou apenas 2 itens para o Perfil do Usuário
    isVisibleText ? (
      <Container>
          <img
            alt="Curso"
            className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
            src={GIF_LIT}
          />
        <Title>Seu Onboarding no LIT</Title>
          <Text>
            Para nós é muito importante te conhecer um pouco mais, para que 
            possamos direcionar melhor o seu aprendizado. Por isso, preencha
            as informações a seguir e nos ajude te conhecer melhor.
          </Text>
        <ContainerProgressBar>
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
          />
        </ContainerProgressBar>        
      </Container>
    ) : (
      <>
        <Title>Seu Onboarding</Title>
        <ContainerProgressBar>
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
          />
        </ContainerProgressBar>              
      </>
    ))
}
