import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES, ICON } from "../../../constants";

/**
 * @param {String} text //displays title information - first line
 * @param {String} description //displays subtitle information - second line
 * @param {String} icon //icon displayed next to the text
 * @param {Boolean} locked //content block locked
 * @param {Boolean} showLocked //defines whether the lock icon (fix card) should be displayed
 * @param {String} appearance //title font appearance
 * @param {String} color //title font color
 * @param {String} size //title font size
 * @param {String} transform //title font transformation
 * @param {String} borderColor //{PRIMARY, SECONDARY, SUCCESS, LIGHT, DARK, GRAY} side border color displayed next to the title
**/

export default ({
  text = DEFAULT_VALUES.STRING,
  description = DEFAULT_VALUES.STRING,
  icon = DEFAULT_VALUES.STRING,
  locked = DEFAULT_VALUES.BOOLEAN,
  showLocked = DEFAULT_VALUES.BOOLEAN,
  appearance = DEFAULT_VALUES.STRING,
  color = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING,
  transform = DEFAULT_VALUES.STRING,
  borderColor = DEFAULT_VALUES.STRING
}) => {

  function validateIcon(value) {
    switch (value) {
      case false:
        return ICON.UNLOCK;
      case true:
        return ICON.LOCK;
      default:
        return;
    }
  }

  return (
    <>
      <Screen
        color={color}
        size={size}
        transform={transform}
        icon={icon}
        text={text}
        description={description}
        locked={locked}
        showLocked={showLocked}
        appearance={appearance}
        borderColor={borderColor}
        validateIcon={validateIcon}
      />
    </>
  );
};
