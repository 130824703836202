/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from "react"
import _ from 'lodash';
import { toast } from "react-toastify"
import Loading from "../../Loading/Loading"
import { maskPhone, toastErrorAndWarningOptions, toastDefaultOptions } from "../../../utils/functions"
import Tooltip from "../../Tooltip"
import RequiredSpan from "../../RequiredSpan"
import { sendRecommendationPlanWhats } from "./sendRecommendationPlanWhats"
import { gateway } from '../../../utils/api'
import RecommendationList from './RecommendationList'

import {
  Title,
  TextErro,
  Container,
  RecommendationBox,
  BoxTitle
} from './styles'

class IndicacaoPlano extends Component {
  state = {
    visibleBody: false,
    recomendations: [],
    plans: [],
    selectedPlan: {},
    totalLicenses: 0,
    totalRecommendations: 0,
    name: "",
    error: "",
    errorName:"",
    errorSelectPlan: "",
    email: "",
    phone: "",
    baseUrl: `${process.env.REACT_APP_API_URL.replace(':8443/api/', '')}`,
    loading: true,
    renderComponent: false,
  }

  componentDidMount() {
    this.getPlansRecomendations();
    this.verifyCollapse();
  }

  selectPlan = (selected) => {
    const { plans } = this.state;
    const plan = plans.find((searchPlan) => searchPlan.code === selected);
    this.setState({ selectedPlan: plan || {}, errorSelectPlan: '' })
  }

  getPlansRecomendations = async () => {
    try {
      const response = await gateway.get('recommendation/recommendation/recommendations-by-plans')
      const { recommendedUsers, plans } = this.mountRecommendationUsersAndPlans(response);

      this.setState({
        loading: false,
        recomendations: recommendedUsers,
        renderComponent: !!response,
        plans
      })
    } catch (error) {
      console.log('error', error)
      this.setState({
        loading: false, 
        renderComponent: false
      })
    }
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  verifyCollapse = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const open = urlParams.get('open');
    if(open === 'recommendationPlan'){
      this.setState({ visibleBody: true })
    }
  }

  sendRecommendation = async () => {
    const {name, email, phone, selectedPlan} = this.state

    const hasError = this.validateData(name, email, selectedPlan);

    if(hasError){
      return;
    }

    try {
      this.setState({ loading: true })
      const phoneNumber = phone ? phone.replace(/\D/g, "") : ""
      const userName = localStorage.getItem('firstName')
      const data = {
        name,
        email,
        phoneNumber,
        invited: name,
        inviter: userName,
        code: selectedPlan.code
      }
      
      if(selectedPlan.type === 'OFFER') {
        await gateway.post('webbff/lit_app_web/recommendation/offer/invitation', data)
      } else {
        await gateway.post('webbff/lit_app_web/recommendation/plan/invitation', data)
      }
      
      if (phone && email) {
        sendRecommendationPlanWhats(name, phone)
        this.notify("Compartilhado com Email e WhatsApp")
      }
      if (phone && !email) {
        sendRecommendationPlanWhats(name, phone)
        this.notify("Compartilhado com WhatsApp")
      }
      if (email && !phone) {
        this.notify("Email enviado com sucesso")
      }

      this.setState({ name: "", email: "", phoneNumber: "", selectedPlan: {}, loading: false, phone: "" })
      this.getPlansRecomendations();
     
    } catch (error) {
      this.setState({ loading: false })
      toast.warn(error.response.data.message, toastErrorAndWarningOptions)
      console.log(error)
    }

  }

  notify = (text, duration = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
    })
  }

  validateData(name, email, selectedPlan) {
    let hasError = false;
    if (!name) {
      this.setState({ errorName: 'O nome é obrigatório' });
      hasError = true;
    }
    if (!email) {
      this.setState({ error: 'O e-mail é obrigatório' });
      hasError = true;
    }
    const balancePlan = _.get(selectedPlan, 'freeLicenses', 0) - _.get(selectedPlan, 'quantityRecommended', 0);
    if (!balancePlan) {
      this.setState({ errorSelectPlan: 'Esse plano já atingiu o limite de recomendações' });
      hasError = true;
    }
    if(!_.get(selectedPlan, 'code', false)) {
      this.setState({ errorSelectPlan: 'O plano é obrigatório' });
      hasError = true;
    }
    return hasError;
  }

  mountRecommendationUsersAndPlans(response) {
    const plans = [];
    const recommendedUsers = [];
    let totalLicenses = 0;
    let totalRecommendations = 0;
    const defaultValue = { value: '' };
    response.map((data) => {
      plans.push({ ...data, quantityRecommended: data.recommendations.length });
      totalLicenses += data.freeLicenses;
      totalRecommendations += data.recommendations.length;
      return data.recommendations.map((recomendationData) => {
        const email = _.get(recomendationData, 'wx_datatype', '');
        const id = _.get(recomendationData, '_id', '');
        const metadata = _.get(recomendationData, 'qx_metadata', []);
        const { value: name } = _.defaultTo(_.find(metadata, ['key', 'invited']), defaultValue);
        const { value: phoneNumber } = _.defaultTo(_.find(metadata, ['key', 'phoneNumber']), defaultValue);
        const { value: date } = _.defaultTo(_.find(metadata, ['key', 'date']), defaultValue);
        const planName = _.get(data,'planDescription');
        recommendedUsers.push({ id, email, name, phoneNumber, date, planName });
      });
    });
    this.setState({ totalLicenses, totalRecommendations });
    return { plans, recommendedUsers };
  }

  render() {
    const { 
      visibleBody,
      name,
      phone,
      email,
      renderComponent,
      recomendations, 
      loading,
      plans,
      selectedPlan,
      totalLicenses,
      totalRecommendations,
      error,
      errorName,
      errorSelectPlan,
    } = this.state
    
    if (loading) {
      return <Loading width={40} />;
    }

    if (!renderComponent) {
      return null;
    }

    return (
      <Fragment>
        {plans.length > 0 && (
        <RecommendationBox className="cursos bg-white-perfil" id="indicacao-usuario">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="d-inline-block" style={{ fontSize: "1.5em" }}>
                  Indique os membros da sua família
                </h5>
                <button type="button" className="btn btn-icon ml-auto" onClick={this.changeVisible}>
                  {visibleBody ? (
                    <i className="fa fa-chevron-up" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-chevron-down" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>
            {visibleBody && (
              <React.Fragment>
                <div className="row">
                  <Tooltip id="indicacao" place="bottom" />
                  <div className="col">
                    <p style={{ lineHeight: "normal", fontSize: "1em", textAlign: 'justify'}}>
                      Inclua seus dependentes no seu plano.<br />
                    </p>
                  </div>
                </div>
                  <Container>
                    <div className="row">
                      <BoxTitle className="col-md-6">
                        <Title>Escolha o plano</Title>
                      </BoxTitle>
                      <BoxTitle className="col-md-3">
                        <Title>Licenças disponíveis</Title>
                      </BoxTitle>
                      <BoxTitle className="col-md-3">
                        <Title>Dependentes</Title>
                      </BoxTitle>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="field-float-label-pattern">
                        <select
                          className="form-control"
                          value={selectedPlan.code || ""}
                          onChange={(e) => {
                            this.selectPlan(e.target.value);
                          }}
                        >
                          <option key="" value="">
                                  {'Escolha o plano'}
                          </option>
                          {plans.map(
                            option => (
                              <option key={option.code} value={option.code}>
                                {option.planDescription}
                              </option>
                            )
                          )}
                        </select>
                        {errorSelectPlan && (
                          <TextErro>{errorSelectPlan}</TextErro>
                        )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <p>{_.get(selectedPlan,'freeLicenses', totalLicenses)}</p>
                      </div>
                      <div className="col-md-3">
                        <p>{_.get(selectedPlan, 'quantityRecommended', totalRecommendations)}</p>
                      </div>
                      
                    </div>


                    <div className="row">
                      <div className="col-md-12" style={{marginBottom: '20px'}}>
                        <Title>Incluir dependente</Title>
                      </div>
                      <div className="col-md-3">
                        <div className="field-float-label-pattern">
                          <input
                            type="text"
                            id="name"
                            autoComplete="off"
                            placeholder="Nome *"
                            className="form-control input-not-yellow"
                            value={name}
                            onChange={evt => this.setState({ 
                              name: evt.target.value,
                              errorName: "" 
                            })}
                          />
                           {errorName && (
                              <TextErro>{errorName}</TextErro>
                            )}
                          <label style={{ left: "auto" }} htmlFor="name">
                            Nome <RequiredSpan />
                          </label>
                        </div>
                       
                      </div>
                      <div className="col-md-3">
                        <div className="field-float-label-pattern">
                          <input
                            type="text"
                            id="email"
                            autoComplete="off"
                            placeholder="Email *"
                            className="form-control input-not-yellow"
                            value={email}
                            onChange={evt => this.setState({ 
                              email: evt.target.value,
                              error: ""
                             })}
                          />
                          {error && (
                          <TextErro>{error}</TextErro>
                        )}    
                          <label htmlFor="email">Email <RequiredSpan /></label>
                        </div>
                       
                      </div>
                      <div className="col-md-3">
                        <div className="field-float-label-pattern">
                          <input
                            type="text"
                            autoComplete="off"
                            id="phone"
                            placeholder="WhatsApp"
                            data-for="indicacao"
                            data-tip="Para envio no WhatsApp desbloqueie o popup do navegador"
                            className="form-control input-not-yellow"
                            value={phone}
                            onChange={evt =>
                              this.setState({ 
                                phone: maskPhone(evt.target.value.slice(0, 15)),
                                error: "" 
                              })
                            }
                          />
                          <label htmlFor="phone">WhatsApp</label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <button
                          onClick={() => this.sendRecommendation()}
                          style={{marginTop: '-20px'}}
                          type="button"
                          className={`btn btn-blue ${loading && "btn-loading"}`}
                        >
                          Enviar
                        </button>
                      </div>   
                    </div>

                    {recomendations && recomendations.length > 0 && (
                      <RecommendationList sendList={recomendations} />
                    )}        
                  </Container>
              </React.Fragment>
            )}
          </div>
        </RecommendationBox>
      )}
      </Fragment>
    )
  }
}

export default IndicacaoPlano;
