import axios from 'axios';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { GoVerified } from "react-icons/go";
import { toast } from 'react-toastify';
import adsTracking from '../../utils/adsTracking';
import { Button, Container, ContentImg, NavLit, PaidPlanInfo } from './styles';

import history from '../../utils/history';

import IMG_LIT from '../../images/lit.png';

function ThankYou({ match, location }) {
  const tenant = localStorage.getItem('tenant');
  const [isOnboardCompleted, setIsOnboardCompleted] = useState(false);
  const [thankYouPageMessage, setThankYouPageMessage] = useState({ title: "", text: "", planInfo: true });
  const { plan } = match.params;
  const {
    paidPlan,
    paymentMethod,
    paidValue,
    paymentResult,
    contractCode
  } = location.state;

  useEffect(() => {
    const tenant = localStorage.getItem('tenant');
    if (paidPlan.isExtension && tenant !== "INTERPLAYERS") {
      const text = (
        <p style={{ color: '#fff' }}>
          Sua jornada ainda não terminou! Clique em &lsquo;Iniciar Onboarding&rsquo; e preencha os dados a seguir para finalizar sua matrícula
        </p>
      )

      toast.success(text, {
        icon: false,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#7ae190'
        },
        theme: "colored",
      });
    }
  }, [paidPlan,localStorage])

  const companyText = async () => {
    const message = await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/webbff/lit_app_web/payment/plan/thankYouPage?classificationPlan=${paymentResult.classificationPlan}`);
    setThankYouPageMessage({
      title: message.data.title,
      text: message.data.text,
      planInfo: message.data.planInfo
    });
  }

  const paymentMethods = {
    CREDIT: 'Cartão de Crédito',
    PIX: 'Pix',
    INVOICE: 'Boleto',
    BILLED: 'Plano gratuito'
  };

  const handlePaidPrice = useCallback(() => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    });
    return formatter.format(paidValue || 0);
  }, [paidValue]);

  const eCommerceEvent = (plan, paidValue, contractCode, response) => {
    const { transactionCode } = response;
    const tenant = localStorage.getItem('tenant')
    if (tenant && (tenant === 'LIT_BUSINESS' || tenant === 'SAINTPAUL')) {
      const eventName = tenant === 'LIT_BUSINESS' ? 'purchase_LIT' : 'purchase_SAINTPAUL'
      adsTracking.sendGoogleAnalyticsEcommerceEvent({
        eventName,
        step: {
          id: contractCode,
          revenue: paidValue,
          /** validar se esta é a melhor forma de pegar o cupom de desconto  */
          coupon: _.get(plan, 'claims.0', '')
        },
        products: [
          {
            name: plan.name,
            id: plan.code,
            price: paidValue,
            brand: 'LIT',
            // category: adsTracking.CATEGORY_DEFAULT,
            quantity: 1
          }
        ],
        transactionCode,
      });
    }

  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const onboardIsCompleted = urlParams.get('onboardIsCompleted');
    setIsOnboardCompleted(onboardIsCompleted);
    eCommerceEvent(paidPlan, paidValue, contractCode, paymentResult);
    companyText(paidPlan.code);
  }, []);

  function logout() {
    history.push('/logout');
  }

  return (
    <>
      <NavLit className="navbar navbar-lit navbar-expand-lg bg-gradient-lit">
        <a className="navbar-brand">
          <img src={IMG_LIT} alt="logo_lit" style={{ width: '100px' }} />
        </a>
        <button type="button" onClick={() => logout()}>
          SAIR
        </button>
      </NavLit>
      <Container>
        <div className="row justify-content-md-center">
          <div className="col-md-6 col-12 rounded">
            <div className="p-2">
              <ContentImg>
                <GoVerified />
              </ContentImg>

              <h4>
                {thankYouPageMessage.title}
              </h4>


              {paidValue > 0 && thankYouPageMessage.planInfo && (
                <div className="mt-4 mb-5">
                  <PaidPlanInfo>Plano: {paidPlan.name}</PaidPlanInfo>
                  <PaidPlanInfo>
                    Forma de pagamento: {paymentMethods[paymentMethod]}
                  </PaidPlanInfo>
                  <PaidPlanInfo>Valor: {handlePaidPrice()}</PaidPlanInfo>
                </div>
              )}

              {plan === 'corporativo' && tenant !== "INTERPLAYERS" && (
                <div className="mt-4 mb-5">
                  <p>Você ainda precisa preencher alguns dados nas telas a seguir. Clique no botão abaixo para começar.</p>
                  <p>Lembrando que sua Nota Fiscal só poderá ser emitida após finalizar seu cadastro.</p>
                </div>
              )}

              {tenant === "INTERPLAYERS" && (
                <div className="mt-4 mb-5">
                  {thankYouPageMessage.text}
                </div>
              )}

              {isOnboardCompleted ? (
                <a href="/home">
                  <Button>
                    Ir para a home
                  </Button>
                </a>
              ) : (
                <a href="/onboarding">
                  <Button>
                    INICIAR
                  </Button>
                </a>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>

  );
}

export default ThankYou;
