import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} size //{EXTRA_SMALL, SMALL, MEDIUM, LARGE} spacing block size
**/

export default ({
  size = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        size={size}
      />
    </>
  );
};
