import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 20px 30px;
  max-width: 3024px;
  margin: 20px auto;
`;

export const ContainerBtn = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  width: 100%;
  height: 45px;
  background: ${({ color }) => color || "transparent"};
  border: 1px solid ${({ color }) => (color ? darken(0.1, color) : "#000")};
  max-width: 250px;
  cursor: pointer;
  opacity: 1;
  cursor: "pointer";

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const Loading = styled.div`
  position: fixed;
  z-index: 201;
  top: 66px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(0px);
  background: transparent;
  height: calc((100% - 66px) - 75px);
`;
