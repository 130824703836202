import React, { useContext } from "react";

import MeetingTabPanel from "../MeetingTabPanel";
import MeetingControls from "../MeetingControls";

import * as Styles from "./styles";

import { MeetingContext } from "../../MeetingContext";

export default function MeetingSidebar() {
  const { meetingLoading, isMeetingFullscreen } = useContext(MeetingContext);
  return (
    <Styles.Sidebar>
      {!meetingLoading && <MeetingTabPanel />}
      {!isMeetingFullscreen && <MeetingControls />}
    </Styles.Sidebar>
  );
}
