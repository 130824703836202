import React from "react";
import {
  BORDER_TYPE,
  SIZE,
  TRANSFORM,
  FONT_TYPE,
  POSITION
} from "../../../constants";

import Text from "../../basicComponents/Text";
import Icon from "../../basicComponents/Icon";
import Image from "../../basicComponents/Image";

import { Info, Span } from "./styles";

export default ({ icon, image, title, subtitle, color, size, orientation }) => {
  return (
    <>
      <Info color={color} orientation={orientation}>
        {icon && <Icon size={SIZE.MEDIUM} icon={icon} />}
        {image && (
          <Image
            size={SIZE.MEDIUM}
            border={BORDER_TYPE.NO_BORDER}
            src={image}
          />
        )}
        <Span>
          <Text
            size={SIZE.EXTRA_SMALL}
            transform={TRANSFORM.UPPERCASE}
            text={title}
            textAlign={POSITION.CENTER}
          />
          {subtitle && (
            <Text
              size={size ? size : SIZE.LARGE}
              fontType={FONT_TYPE.BOLD}
              text={subtitle}
              transform={TRANSFORM.CAPITALIZE}
              textAlign={POSITION.CENTER}
            />
          )}
        </Span>
      </Info>
    </>
  );
};
