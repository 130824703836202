import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} size //{SMALL, MEDIUM, LARGE} space block size
 * @param {Component} children //space block content
**/

export default ({
  size = DEFAULT_VALUES.STRING,
  children = DEFAULT_VALUES.COMPONENT
}) => {
  return (
    <>
      <Screen
        size={size}
        child={children}
      />
    </>
  );
};
