import React from "react";
import Screen from "./screen";

export default ({ groups, action, actionDecline, hasMoreInvites, loadMoreInvites }) => {
  return (
    <>
      <Screen
        groups={groups}
        hasMoreInvites={hasMoreInvites}
        acceptJoinSolicitation={action}
        declineJoinSolicitation={actionDecline}
        loadMoreInvites={loadMoreInvites}
      />
    </>
  );
};
