import React, { memo, Fragment } from "react";
import styled from "styled-components";
import { API_URL_IMAGES } from "../../utils/constants";
import { openCallToAction } from '../../business/cta';

const DivWrapper = styled.div`
  background-color: #202123;
  height: 400px;
`;

const DivBanner = styled.div`
  height: 400px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  position: relative;
  cursor: ${props => (props.link ? "pointer" : "normal")};
`;

const getLinkStyle = (linkLength, index) => {
  let styles = {};

  if (linkLength === 2) {
    if (index === 0) {
      styles = {
        position: "absolute",
        height: "38px",
        width: "28%",
        top: "420px"
      };
    } else {
      styles = {
        position: "absolute",
        height: "38px",
        width: "71%",
        right: "0px",
        top: "420px"
      };
    }
  } else if (linkLength === 3) {
    if (index === 0) {
      styles = {
        position: "absolute",
        height: "100%",
        width: "35%",
        left: 0,
        top: 0
      };
    }
    if (index === 1) {
      styles = {
        position: "absolute",
        height: "100%",
        width: "24%",
        left: "34%",
        top: 0
      };
    }
    if (index === 2) {
      styles = {
        position: "absolute",
        height: "100%",
        width: "42%",
        right: 0,
        top: 0
      };
    }
  }

  return styles
}

const RedirectLink = ({ data, linkLength, children = null, index }) => {
  const styles = getLinkStyle(linkLength, index);

  return <a onClick={() => openCallToAction(data)} style={styles}> {children} </a>;
};

const Slide = ({ banner, fullImagePath }) => {
  const renderChildrenBanner = () => {
    const { links } = banner;
    const linkLength = links.length;
    const image = fullImagePath ? banner.fileName : `${API_URL_IMAGES}images/home/carousel/${banner.fileName}`;

    if (linkLength === 1) {
      return (
        <RedirectLink data={links[0]} linkLength={linkLength}>
          <DivBanner link image={image} />
        </RedirectLink>
      );
    }

    if (linkLength > 1) {
      return (
        <Fragment>
          <DivBanner link image={image} />
          {links.map((link, index) => (
            <RedirectLink
              key={link._id}
              data={link}
              index={index}
              linkLength={linkLength}
            />
          ))}
        </Fragment>
      );
    }

    return <DivBanner image={image} />;
  };

  return <DivWrapper>{renderChildrenBanner()}</DivWrapper>;
};

export default memo(Slide);
