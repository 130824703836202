import { useEffect } from "react"
import { setSEOIndexOff } from "../../utils/functions"

// import { Container } from './styles';

export default function Logout({ history }) {
  useEffect(() => {
    setSEOIndexOff()
    localStorage.clear()
    history.push(`/`)
  }, [history])

  return null
}
