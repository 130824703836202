import React, { Fragment } from "react"

import background from "../../../../images/lit.png"

import { LoginBox, LoginHeaderBlack } from "../../styles"
import RegisterPartnerForm from "../RegisterPartnerForm"

export default function Generic({
  userDetails,
  handleRegisterSuccess,
  handleRegisterFailure,
  resetErrors,
}) {
  return (
    <div className="row" style={{ height: "100vh", background: "#FFF" }}>
      <Fragment>
        <div className="col-md-7 d-none d-md-flex bg-loginLeft big">
          <img src={background} className="logo" alt="LIT" />
        </div>
        <div className="col-md -7 d-md-none bg-loginLeft small">
          <img src={background} className="logo" alt="LIT" />
        </div>
      </Fragment>
      <div
        className="col-md-5"
        style={{
          background: "#f3f2f1",
        }}
      >
        <LoginBox>
          <LoginHeaderBlack>Criar conta</LoginHeaderBlack>

          <RegisterPartnerForm
            userData={userDetails}
            resetErrors={resetErrors}
            doRegisterSuccess={handleRegisterSuccess}
            doRegisterFailure={handleRegisterFailure}
          />
        </LoginBox>
      </div>
    </div>
  )
}
