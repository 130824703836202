import React from "react";
import { Image } from "./styles";
import placeholder from "../../../../images/placeholder-group.png";

export default ({ appearance, size, border, format, position, alt, src }) => {
  return (
    <Image
      src={src || placeholder}
      alt={alt}
      appearance={appearance}
      size={size}
      border={border}
      format={format}
      position={position}
    />
  );
};
