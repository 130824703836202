/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {Img, Title, Container, ContainerText} from './styles'

import {saveOnboardingSteps} from '../../../../business/onboarding'
import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions"


class OnboardingPageWelcome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  saveForm = async () => {
    const {setOnbordingPageConcluded, index, onboardingData} = this.props
    const data = {
      "wx_step": "WELCOME"
    }
    setOnbordingPageConcluded(index, onboardingData[index])
    await saveOnboardingSteps(data);
  };


  render() {
  
    const {data, profile, index} = this.props
    const {wx_titlehtml: title, wx_descriptionhtml: description, wu_logoimageurl: url } = data.ox_welcomeconfiguration

    return(
      <Container className="container">
        <Title> {index + 1}. Boas-vindas</Title>
        <div className="row justify-content-md-center">
          <div className="col-10 objetivos-personalizar--desc-usuario text-body-pi">
            {url && <Img src={url} alt="" />}
            {(title && title.length > 0) ? 
              ( 
                <h5 dangerouslySetInnerHTML={{ __html: title }} />
              ) : 
              ( 
                <h5>Olá, {profile.firstName}!</h5>
              )
            }

            {description ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              <ContainerText>
                <p>
                  Estamos muito felizes com o seu interesse em fazer parte da nossa comunidade. 
                  No LIT você tem acesso a mais de 100 cursos, em diversas áreas, 
                  e pode fazer quantos cursos quiser, quando e onde preferir.
                </p>
                <p>
                  Você sempre vai encontrar novidades, adicionamos com frequência cursos novos sobre os temas em alta
                    no momento. São mais de 25 mil horas de conteúdo, 2.000 exercícios e casos
                  com resolução passo a passo e 10 trilhas de MBA.
                </p>
                <p>
                  Adapte sua experiência de aprendizado de acordo com o seu perfil. 
                  No LIT, cada aluno é único e tem um caminho personalizado.
                </p>
              </ContainerText>
            )}
            
          </div>
        </div>
        {/* <WatsonBadgeFooter /> */}
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    profile: state.user.profile.data,
    onboardingData: state.onboarding.onboardingData,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...OnboardingActions
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps, 
  null,  
  {
    forwardRef: true,
  }
)(OnboardingPageWelcome);
