import React from "react";
import Screen from "./screen";
import { TIMELINE_ACTIONS, DEFAULT_VALUES } from "../../../constants";
import showToast from "../../../../utils/showToast";

/**
 * @param {String} id //post identification code
 * @param {String} code //identification code
 * @param {Array} member //data of the user who posted or commented
 * @param {Array} members //list of members to display in items
 * @param {Array} reactions //list of reactions to display
 * @param {Array} postMember //data of the user who posted or commented
 * @param {Array} postContent //content to be rendered in the post
 * @param {Array} postGroup //group in which the post was made
 * @param {Array} postArchives //list of files attached to the post
 * @param {Boolean} isAdministrator //verifies that the author of the post is an administrator
 * @param {Number} totalLikes //number of "like" reactions
 * @param {Number} totalDislikes //number of "unlike" reactions
 * @param {Function} updatePost //function that updates post
 * @param {Function} createComment //function that create comment
 * @param {Function} deleteCommunication //function that delete comment
 * @param {Array} reactionsTypes //list types of reactions
 * @param {Function} updateCommunicationReactions //function that updates comment
 * @param {Boolean} hasLike //check if there are likes on the post
 * @param {Boolean} isOwner //checks if the post is from the logged in user
 * @param {Boolean} hasReaction //check if reaction list is empty
 * @param {Boolean} hiddenCreatePost //show or hide create post component
 * @param {Array} loadCommunicationReactions //looks for reactions to be displayed for comments click events
 * @param {Array} loadReactionPeople //seeks reactions to be linked to users displayed in the post
 * @param {Boolean} hideReply //show or hide component of responses
 * @param {Date} postDate //displays post date
**/

export default ({
  id = DEFAULT_VALUES.STRING,
  code = DEFAULT_VALUES.STRING,
  member = DEFAULT_VALUES.LIST,
  members = DEFAULT_VALUES.LIST,
  reactions = DEFAULT_VALUES.LIST,
  postMember = DEFAULT_VALUES.LIST,
  postContent = DEFAULT_VALUES.LIST,
  postGroup = DEFAULT_VALUES.LIST,
  postArchives = DEFAULT_VALUES.LIST,
  isAdministrator = DEFAULT_VALUES.BOOLEAN,
  totalLikes = DEFAULT_VALUES.NUMBER,
  totalDislikes = DEFAULT_VALUES.NUMBER,
  updatePost = DEFAULT_VALUES.FUNCTION,
  createComment = DEFAULT_VALUES.FUNCTION,
  deleteCommunication = DEFAULT_VALUES.FUNCTION,
  reactionsTypes = DEFAULT_VALUES.LIST,
  updateCommunicationReactions = DEFAULT_VALUES.FUNCTION,
  hasLike = DEFAULT_VALUES.BOOLEAN,
  isOwner = DEFAULT_VALUES.BOOLEAN,
  hasReaction = DEFAULT_VALUES.BOOLEAN,
  hiddenCreatePost = DEFAULT_VALUES.BOOLEAN,
  loadCommunicationReactions = DEFAULT_VALUES.LIST,
  loadReactionPeople = DEFAULT_VALUES.LIST,
  hideReply = DEFAULT_VALUES.BOOLEAN,
  postDate,
  videoTime,
  showTimer
}) => {
  const [hiddenAnswer, setHiddenAnswer] = React.useState(true);
  const [hiddenEditComment, setHiddenEditComment] = React.useState(true);
  const [hiddenInterations, setHiddenInterations] = React.useState(true);
  const [interationsModalIsOpen, setInterationsModalIsOpen] = React.useState(false);

  function editPost(content, files, removeFiles) {
    if (videoTime != null && videoTime != undefined)
      content = `<${videoTime}>${content}`;

    updatePost(content, id, files, removeFiles);
  }

  function setVideoTime() {
    try {
      const video = document.getElementsByTagName('video')[0];
      video.play();
      video.currentTime = videoTime.toString();
    }
    catch (error) {
      showToast({ message: 'Não foi possivel definir o tempo do video' }, true)
    }
  }

  return (
    <>
      <Screen
        id={id}
        code={code}
        member={member}
        members={members}
        postMember={postMember}
        isAdministrator={isAdministrator}
        postContent={postContent}
        postArchives={postArchives}
        postGroup={postGroup}
        totalLikes={totalLikes}
        postDate={postDate}
        totalDislikes={totalDislikes}
        hiddenAnswer={hiddenAnswer}
        hiddenEditComment={hiddenEditComment}
        setHiddenAnswer={setHiddenAnswer}
        setHiddenEditComment={setHiddenEditComment}
        savePostEdit={editPost}
        saveNewComment={createComment}
        hiddenCreatePost={hiddenCreatePost}
        hasLike={hasLike}
        isOwner={isOwner}
        reactions={reactions}
        hasReaction={hasReaction}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
        hiddenInterations={hiddenInterations}
        setHiddenInterations={setHiddenInterations}
        loadCommunicationReactions={loadCommunicationReactions}
        loadReactionPeople={loadReactionPeople}
        reactionsTypes={reactionsTypes}
        updateCommunicationReactions={updateCommunicationReactions}
        hideReply={hideReply}
        deleteCommunication={deleteCommunication}
        showTimer={showTimer}
        setVideoTime={setVideoTime}
        videoTime={videoTime}
      />
    </>
  );
};
