import React from "react";
import _ from "lodash";

const ICON_APPLE = require("../../images/apple_icon.svg");
const ICON_GOOGLE = require("../../images/g_icon.png");

const SocialLinks = () => {
  const settings = JSON.parse(localStorage.getItem("settings")) || null;
  const tenant = localStorage.getItem("tenant") || "";
  const socialLinks = _.get(settings, `${tenant}.social`, []);

  return (
    <ul className="home-nosAcompanhe col-md-3 col-sm-12">
      <li className="text-center">
        <h4>Nos acompanhe</h4>
      </li>
      <div className="redes-sociais border-sociais">
        <ul className="d-flex flex-row">
          {socialLinks.map(currentSocialLink => (
            <li data-tip={currentSocialLink.tooltip} className="col-dm-4">
              <a
                rel="noreferrer noopener"
                target="_blank"
                href={currentSocialLink.link}
              >
                <i
                  className={`fa fa-${currentSocialLink.name.toLowerCase()}`}
                  aria-hidden="true"
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="text-center home-nosAcompanhe-title">
        <h4>Baixe o Aplicativo</h4>
        <div className="appIcons">
          <div className="store">
            Já disponível na
            <a
              rel="noreferrer noopener"
              data-tip="Baixe nosso aplicativo na App Store"
              target="_blank"
              href="https://itunes.apple.com/br/app/lit/id1310754004"
            >
              <img
                src={ICON_APPLE}
                className="img-responsive"
                alt="Icon apple"
              />
            </a>
          </div>
          <div className="store">
            Já disponível na
            <a
              rel="noreferrer noopener"
              data-tip="Baixe nosso aplicativo no Google Play"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.saintpaulapp"
            >
              <img
                src={ICON_GOOGLE}
                className="img-responsive"
                alt="Icon google"
              />
            </a>
          </div>
        </div>
      </div>
    </ul>
  );
};

export default SocialLinks;
