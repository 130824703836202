import styled from "styled-components";
import defaultModal from "react-modal";
import { } from "../../../constants";

defaultModal.defaultStyles.overlay.position = "fixed";
defaultModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.75)";

export const Modal = styled(defaultModal)`
  right: -50%;
  top: 0;
  width: 50%;
  height: 100%;
  padding: 0;
  position: absolute;
  transition: all 1s ease;
  @media screen and (max-width: 579px) {
    right: -96%;
    width: 96%;
  }
`;

export const Content = styled.div.attrs({
  className: "modal-content"
})`
height: 100%;
border-radius: 0;
overflow: hidden;
overflow-y: auto;
right: 100%;
width: 50vw;
@media screen and (max-width: 579px) {
  width: 96vw;
}
`;
