import React, { useEffect } from "react";
import { APPEARANCE } from "../../../constants";
import Post from "../../basicComponents/Post";
import Accordion from "../../basicComponents/Accordion";

import {} from "./styles";

export default ({
  id,
  code,
  member,
  members,
  postGroup,
  postMember,
  postContent,
  postArchives,
  postComments,
  postDescription,
  isAdministrator,
  totalDislikes,
  updatePost,
  createComment,
  deleteCommunication,
  hasLike,
  isOwner,
  likes,
  reactions,
  postDate,
  hiddenCreatePost,
  interationsModalIsOpen,
  setInterationsModalIsOpen,
  loadCommunicationReactions,
  loadReactionPeople,
  reactionsTypes,
  updateCommunicationReactions,
  hasReaction,
  showTimer
}) => {
  const [videoTime, setVideoTime] = React.useState(null);
  const [content, setContent] = React.useState("");
  const [commentsList, setCommentsList] = React.useState(postComments);

  function showComments() {
    if (postComments && postComments.length) {
      return postComments.map(comment => {
        const preparedComment = prepareContent(comment.content.value);

        comment.content.formated = preparedComment.content;
        comment.videoTime = preparedComment.videoTime;

        return (
          <Accordion id={comment.id} appearance={APPEARANCE.POST}>
            <Post
              id={comment.id}
              code={comment.code}
              postGroup={comment.destination}
              postMember={comment.profile}
              postContent={comment.content.formated || ""}
              postDate={comment.content.date}
              members={comment.likePeople}
              postArchives={comment.files}
              updatePost={updatePost}
              isAdministrator={isAdministrator}
              totalLikes={comment.reactions}
              createComment={createComment}
              deleteCommunication={deleteCommunication}
              hiddenCreatePost={hiddenCreatePost}
              hasLike={comment.hasReaction}
              isOwner={comment.isOwner}
              likes={comment.likes}
              reactions={comment.reactions}
              hasReaction={comment.hasReaction}
              interationsModalIsOpen={interationsModalIsOpen}
              setInterationsModalIsOpen={setInterationsModalIsOpen}
              loadCommunicationReactions={loadCommunicationReactions}
              loadReactionPeople={loadReactionPeople}
              reactionsTypes={reactionsTypes}
              updateCommunicationReactions={updateCommunicationReactions}
              showTimer={comment.videoTime != null && showTimer ? true : false}
              videoTime={comment.videoTime}
            />
            {comment.children &&
              comment.children.map(secondLevelComment => {
                const preparedSecondLevelComment = prepareContent(
                  secondLevelComment.content.value
                );

                secondLevelComment.content.formated =
                  preparedSecondLevelComment.content;
                secondLevelComment.videoTime =
                  preparedSecondLevelComment.videoTime;

                return (
                  <Post
                    id={secondLevelComment.id}
                    code={secondLevelComment.code}
                    postGroup={secondLevelComment.destination}
                    postMember={secondLevelComment.profile}
                    postContent={secondLevelComment.content.formated || ""}
                    postDate={secondLevelComment.content.date}
                    postArchives={secondLevelComment.files}
                    members={secondLevelComment.likePeople}
                    updatePost={updatePost}
                    isAdministrator={isAdministrator}
                    totalLikes={secondLevelComment.reactions}
                    createComment={createComment}
                    deleteCommunication={deleteCommunication}
                    hiddenCreatePost={hiddenCreatePost}
                    hasLike={secondLevelComment.hasReaction}
                    isOwner={secondLevelComment.isOwner}
                    reactions={secondLevelComment.reactions}
                    likes={secondLevelComment.likes}
                    hasReaction={secondLevelComment.hasReaction}
                    interationsModalIsOpen={interationsModalIsOpen}
                    setInterationsModalIsOpen={setInterationsModalIsOpen}
                    loadCommunicationReactions={loadCommunicationReactions}
                    loadReactionPeople={loadReactionPeople}
                    reactionsTypes={reactionsTypes}
                    updateCommunicationReactions={updateCommunicationReactions}
                    hideReply={true}
                    videoTime={secondLevelComment.videoTime}
                    showTimer={
                      secondLevelComment.videoTime != null && showTimer
                        ? true
                        : false
                    }
                  />
                );
              })}
          </Accordion>
        );
      });
    }
  }

  function prepareContent(postContent) {
    let endIndex = null;

    if (postContent[0] === "<") {
      for (let i = 0; i < postContent.length; i++) {
        if (postContent[i] === ">") {
          endIndex = i;
          break;
        }
      }

      if (endIndex)
        return {
          videoTime: parseInt(postContent.slice(1, endIndex)),
          content: postContent.slice(endIndex + 1)
        };
    }

    return {
      videoTime: null,
      content: postContent
    };
  }

  useEffect(() => {
    const res = prepareContent(postContent);
    setContent(res.content);
    setVideoTime(res.videoTime);
  });

  return (
    <>
      <Accordion id={id} appearance={APPEARANCE.POST}>
        <Post
          id={id}
          code={code}
          member={member}
          members={members}
          postGroup={postGroup}
          postMember={postMember}
          postContent={content}
          postDate={postDate}
          postArchives={postArchives}
          postComments={postComments}
          postDescription={postDescription}
          isAdministrator={isAdministrator}
          totalLikes={likes}
          totalDislikes={totalDislikes}
          updatePost={updatePost}
          createComment={createComment}
          deleteCommunication={deleteCommunication}
          hiddenCreatePost={hiddenCreatePost}
          hasLike={hasLike}
          reactions={reactions}
          hasReaction={hasReaction}
          isOwner={isOwner}
          likes={likes}
          interationsModalIsOpen={interationsModalIsOpen}
          setInterationsModalIsOpen={setInterationsModalIsOpen}
          loadCommunicationReactions={loadCommunicationReactions}
          loadReactionPeople={loadReactionPeople}
          reactionsTypes={reactionsTypes}
          updateCommunicationReactions={updateCommunicationReactions}
          showTimer={videoTime != null && showTimer ? true : false}
          videoTime={videoTime}
        />

        {showComments()}
      </Accordion>
    </>
  );
};
