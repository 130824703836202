import { combineReducers } from "redux"
/**
 * Profile
 */
import profile from "./profile"
import { Creators as ProfileActions } from "./profile/actions"
import { Types as ProfileTypes } from "./profile/types"

/**
 * Personality Insight
 */
import personalityInsight from "./personalityInsight"
import { Creators as PIActions } from "./personalityInsight/actions"
import { Types as PITypes } from "./personalityInsight/types"

/**
 * Offices
 */
import office from "./office"
import { Creators as OfficesActions } from "./office/actions"
import { Types as OfficesTypes } from "./office/types"


/**
 * StudyGroup
 */
import studyGroup from "./studyGroup"
import { Creators as StudyGroupActions } from "./studyGroup/actions"
import { Types as StudyGroupTypes } from "./studyGroup/types"

const UserActions = {
  ProfileActions,
  PIActions,
  OfficesActions,
  StudyGroupActions
}

const UserTypes = {
  ProfileTypes,
  PITypes,
  OfficesTypes,
  StudyGroupTypes
}
export default combineReducers({
  profile,
  personalityInsight,
  office,
  studyGroup
})

export { UserActions, UserTypes }
