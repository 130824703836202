import styled, { keyframes } from "styled-components";
import { FaSpinner } from "react-icons/fa";

const rotateAnimation = keyframes`
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
`;

export const Loading = styled(FaSpinner)`
  animation: ${rotateAnimation} 2s infinite linear;
`;

export const BoxAutoPlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-bottom: 0;
`;

export const Container = styled.div`
  border-bottom: 1px solid #dfdfdf;
`;

export const Button = styled.button`
  background: linear-gradient(
    45deg,
    rgba(2, 196, 255, 1) 0%,
    rgba(142, 230, 123, 1) 100%
  );
  border-radius: 30px;
  width: 110px;
  height: 30px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #02c4fd;
    background: #02c4fd;
  }
`;

export const TextRefreshCourse = styled.p`
  margin: 0;
  padding: 0;
  a {
    padding: 0 5px !important;
    color: #02c4fd !important;
    text-decoration: underline !important;
  }
`;

export const ObjectDescription = styled.div`
  margin: 30px 0;
  background: white;
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  padding: 20px 15px;
`;
