import React, { useState, useRef } from 'react';

import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { FaSearch } from "react-icons/fa";
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';

import OfferDetails from './subcomponents/OfferDetails';
import QRCodeComponent from './subcomponents/QRCodeComponent';
import { gateway } from '../../../../utils/api';
import { toastErrorAndWarningOptions } from '../../../../utils/functions';
import Loading from '../Loading';
import TextPaymentDefault from '../TextPaymentDefault';
import PaymentTerm from '../PaymentTerm';

import * as Styles from './styles';
import * as GeneralStyles from '../../styles';
import Input from "../Input";


function PixForm({
  selectedPlan,
  onSubmit,
  paymentResponse,
  onPaymentSuccess,
  qrCode,
  captureId,
  userTerm,
  loading,
  handleGenerateTerm,
  showTerm,
  setShowTerm,
  generatingTerm
}) {
  const [loadingCep, setLoadingCep] = useState(false);
  const validationSchema = Yup.object().shape({
    shopperFirstName: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    shopperLastName: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    document: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    street: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    number: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    additional_details: Yup.string()
      .default(''),
    zipcode: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    neighborhood: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    city: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    state: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    country: Yup.string()
      .required('Campo obrigatório')
      .default(''),
  });

  const [paymentMethod] = useState('PIX');

  const formRefer = useRef(null);

  async function verifyPayment() {
    const response = await gateway.get(
      `/financialcapture/financialcapture/${captureId}`
    );
    const status = response.ox_specific.ox_situation.ox_current.wk_status;

    if (status === 'SUCCESS') {
      onPaymentSuccess({
        selectedPlan,
        paidValue:
          paymentResponse.captures[0].ox_specific.ox_capturevalues
            .ox_capturevalue.nf_value,
        paymentResult: {
          ...paymentResponse.paymentInfo,
          transactionCode: paymentResponse.transactionCode
        },
        paymentMethod,
        gatewayReference:
          paymentResponse.captures[0].ox_specific.rc_gatewaycapturereference ||
          'NONE',
        contractCode: _.get(
          paymentResponse,
          'contract.ox_standard.ox_identification.cr_code',
          ''
        )
      });

      localStorage.setItem('activeContract', true);
      localStorage.removeItem('pixData')
    } else if (response.status === 'INCORRECT_AMOUNT_PAID') {
      toast.warn(
        'Pagamento realizado com valor incorreto, favor entrar em contato com o SAC.',
        toastErrorAndWarningOptions
      );
    } else {
      toast.warn(
        'Seu PIX está sendo processado. Por favor aguarde 3 mins e clique novamente no botão "já paguei"',
        toastErrorAndWarningOptions
      );
    }
  }

  async function searchCEP({ values, setFieldValue }) {
    const { zipcode } = values;

    if (!zipcode) {
      return;
    }
    
    try {
      setLoadingCep(true);
      const response = await gateway.get(`location/${zipcode}`);

      setFieldValue('street', response.ws_address);
      setFieldValue('city', response.ws_city);
      setFieldValue('state', response.ws_uf);
      setFieldValue('neighborhood', response.ws_neighborhood)
      setFieldValue('country', 'BR');
    } catch (error) {
      console.log('ERRO', error)
      setFieldValue('street','');
      setFieldValue('city', '');
      setFieldValue('state', '');
      setFieldValue('neighborhood', '')
      setFieldValue('country', '');
    } finally {
      setLoadingCep(false);
    }
  }

  return (
    <Styles.Main>
      {qrCode && (
        <Styles.MainQrCode>
          <Styles.ContainerOfferDetails>
            <OfferDetails offer={selectedPlan} />
          </Styles.ContainerOfferDetails>
          <Styles.ContainerQrCode>
            <QRCodeComponent qrCode={qrCode} verifyPayment={verifyPayment} />
          </Styles.ContainerQrCode>
        </Styles.MainQrCode>
      )}

      {!qrCode && (
        <>
          <OfferDetails offer={selectedPlan} />

          <div className="row justify-content-md-center">
            <div className="col-12">
              <p>
                Para gerar o seu QR Code e/ou código de pagamento preencha:
              </p>
            </div>
          </div>

          <Formik
            ref={formRefer}
            validationSchema={validationSchema}
            initialValues={validationSchema.cast()}
            onSubmit={handleGenerateTerm}
            render={({
              values,
                       setFieldValue,
              touched,
              errors,
              handleChange,
              handleBlur,
              resetForm,
              handleSubmit,
              
            }) => (
              <React.Fragment>
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-12">
                        <p
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          Dados pessoais
                        </p>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <Styles.LabelField>Nome</Styles.LabelField>
                            <Input
                              name="shopperFirstName"
                              value={values.shopperFirstName}
                              onChange={handleChange('shopperFirstName')}
                              onBlur={handleBlur('shopperFirstName')}
                              placeholder="Nome"
                              className="form-control"
                              error={touched.shopperFirstName && errors.shopperFirstName}
                            />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <Styles.LabelField>Sobrenome</Styles.LabelField>
                            <Input
                              
                              placeholder="Sobrenome"
                              name="shopperLastName"
                              value={values.shopperLastName}
                              onChange={handleChange('shopperLastName')}
                              onBlur={handleBlur('shopperLastName')}
                              className="form-control"
                              error={touched.shopperLastName && errors.shopperLastName}
                            />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <Styles.LabelField>CPF</Styles.LabelField>
                            <InputMask
                              maskChar={null}
                              mask="999.999.999-99"
                              name="document"
                              value={values.document}
                              onChange={handleChange('document')}
                              onBlur={handleBlur('document')}
                            >
                              {inputProps => (
                                <Input
                                  {...inputProps}
                                  placeholder="CPF"
                                  error={touched.document && errors.document}
                                  className="form-control"
                                />
                              )}
                            </InputMask>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-12">
                        <p
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          Endereço Fiscal
                        </p>
                      </div>
                      <div className="col-md-2 col-12">
                        <div className="form-group">
                          <div className="input-group">
                            <InputMask
                              maskChar={null}
                              mask="99999-999"
                              name="zipcode"
                              value={values.zipcode}
                              onChange={handleChange}
                              onBlur={event => {
                                handleBlur(event);
                                searchCEP({values, setFieldValue});
                              }}
                            >
                              {inputProps => (
                                <Input
                                  withIcon
                                  {...inputProps}
                                  loading={loadingCep}
                                  label="CEP"
                                  placeholder="Digite o CEP"
                                  sufix={<FaSearch style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    cursor: 'pointer',
                                  }}
                                                   onClick={() => {
                                                     searchCEP({values, setFieldValue});
                                                   }}/>}
                                  className="form-control"
                                  error={
                                    touched.zipcode &&
                                    errors.zipcode
                                  }
                                  onKeyUp={event => {
                                    const enterKeyCode = 13;
                                    if (
                                      event.keyCode === enterKeyCode &&
                                      event.target.value
                                    ) {
                                      searchCEP({values, setFieldValue});
                                    }
                                  }}

                                />
                              )}

                            </InputMask>
                          </div>
                        </div>


                      </div>

                      <div className="col-md-2 col-12">
                        <div className="form-group">
                          <Styles.LabelField>Número</Styles.LabelField>
                            <InputMask
                              maskChar={null}
                              mask="999999"
                              name="number"
                              value={values.number}
                              onChange={handleChange('number')}
                              onBlur={handleBlur('number')}
                            >
                              {inputProps => (
                                <Input
                                  {...inputProps}
                                  placeholder="Número"
                                  error={touched.number && errors.number}
                                  className="form-control"
                                />
                              )}
                            </InputMask>

                        </div>

                      </div>

                      <div className="col-md-8">
                        <div className="form-group">
                          <Styles.LabelField>Complemento</Styles.LabelField>
                            <Input
                              placeholder="Complemento"
                              name="additional_details"
                              value={values.additional_details}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                        </div>
                      </div>
                      
                      <div className="col-md-12 col-12">
                        <div className="form-group">
                          <Styles.LabelField>Rua</Styles.LabelField>
                            <Input
                              placeholder="Rua"
                              name="street"
                              value={values.street}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.street && errors.street}
                              className="form-control"
                            />
                        </div>
                      </div>

                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <Styles.LabelField>Bairro</Styles.LabelField>
                            <Input
                              placeholder="Bairro"
                              name="neighborhood"
                              value={values.neighborhood}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.neighborhood && errors.neighborhood}
                              className="form-control"
                            />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <Styles.LabelField>Cidade</Styles.LabelField>
                            <Input
                              placeholder="Cidade"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.city && errors.city}
                              className="form-control"
                            />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <Styles.LabelField>Estado</Styles.LabelField>
                            <Input
                              placeholder="Estado"
                              name="state"
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.state && errors.state}
                              className="form-control"
                            />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <Styles.LabelField>País</Styles.LabelField>
                            <Input
                              placeholder="País"
                              name="country"
                              value={values.country}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.country && errors.country
                              }
                              className="form-control"
                            />
                        </div>

                      </div>

                     
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 text-center">
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: 10
                      }}
                    >
                      Seus dados de perfil serão atualizados
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-lg-7">
                  <GeneralStyles.TermWithCheckBoxContainer>
                    <TextPaymentDefault 
                      selectedPlan={selectedPlan} 
                      paymentMethod={paymentMethod}
                      textPayment="A cobrança desse plano será feita imediatamente após a confirmação do pagamento."
                    />
                  </GeneralStyles.TermWithCheckBoxContainer>
                  </div>
                  <div className="col-sm-12 col-lg-5">
                    <div
                      className="box-buttons"
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <GeneralStyles.CleanButton
                        visual="outline"
                        onClick={() => resetForm()}
                      >
                        Limpar
                      </GeneralStyles.CleanButton>

                      <GeneralStyles.ConfirmButton
                        handleSubmit
                        disabled={loading || generatingTerm}
                        onClick={() => handleSubmit()}
                      >
                        {loading || generatingTerm ? <Loading /> : 'Gerar QR Code'}
                      </GeneralStyles.ConfirmButton>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          />

          <PaymentTerm
            visible={showTerm}
            onBack={() => setShowTerm(false)}
            onAccept={() => onSubmit(formRefer.current.state.values)}
            onReject={() => setShowTerm(false)}
            termUrl={_.get(userTerm, 'ox_specific.wu_url')}
            processingAccept={loading}
          />

        </>
      )}
    </Styles.Main>
  );
}

export default PixForm;
