import React, { memo } from "react"

import AvatarUsuarioLogado from "../AvatarUsuarios/AvatarUsuarioLogado"

const Perfil = () => {
  return (
    <div id="perfil-infos" className="container-fluid">
      <div className="row bg-white-perfil rounded md-box">
        <div className="col-12 perfil-infos">
          <AvatarUsuarioLogado />
        </div>
      </div>
    </div>
  )
}

export default memo(Perfil)
