import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} children //receives the content that will be presented
**/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
}) => {
  return (
    <>
      <Screen
        child={children}
      />
    </>
  );
};
