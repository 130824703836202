import React from "react";
import Screen from "./screen";
import { COLOR } from "../../../constants";

/**
 * @param //without defined parameters
**/

export default ({
  color = COLOR.LOADING_BAR,
  loadingProgress = 0,
  height = 4
}) => {
  return (
    <>
      <Screen
        progress={loadingProgress}
        color={color}
        height={height}
      />
    </>
  );
};
