import _ from 'lodash';
import React, { useState } from 'react';
import swal from '@sweetalert/with-react';

import { gateway } from '../../../../../../utils/api';
import Loading from '../../../Loading';

import * as Styles from './styles';

function FullPercentDiscount({ selectedPlan, onPaymentSuccess, couponCode }) {
  const [loading, setLoading] = useState(false);

  async function handleSubmitPayment() {
    try {
      setLoading(true);

      const paymentRequestBody = {
        crCodeCoupon: couponCode,
        type: 'BILLED',
        offerReference: selectedPlan.code
      };

      const url = selectedPlan.alreadyPurchased
        ? '/webbff/lit_app_web/payment/updatePaymentMethod'
        : '/contract/contract/processSubscription';
      const method = selectedPlan.alreadyPurchased ? 'put' : 'post';

      const paymentResponse = await gateway[method](url, paymentRequestBody);

      onPaymentSuccess({
        selectedPlan,
        paymentResult: paymentResponse.paymentInfo,
        paymentMethod: 'BILLED',
        contractCode: _.get(
          paymentResponse,
          'contract.ox_standard.ox_identification.cr_code',
          ''
        )
      });
    } catch (error) {
      const message = _.get(
        error,
        'response.data.message',
        'Não foi possível processar o contrato.'
      );
      swal({
        title: `Ops, ocorreu um erro.`,
        text: message
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="row justify-content-md-center">
        <Styles.DescriptionText className="box-info-title text-left col-12">
          Você recebeu um cupom de 100% de desconto!
        </Styles.DescriptionText>
      </div>

      <div className="row justify-content-md-center">
        <div className="col-12">
          <p>
            Agora basta finalizar o processo que processaremos o seu contrato em
            alguns segundinhos. :) <br />
          </p>
        </div>
      </div>

      <Styles.ConfirmButton
        style={{ paddingLeft: '20px', paddingRight: '20px' }}
        onClick={() => handleSubmitPayment()}
      >
        {loading ? <Loading /> : 'Continuar'}
      </Styles.ConfirmButton>
    </>
  );
}

export default FullPercentDiscount;
