import React from "react";
import Line from "../../basicComponents/Line";
import { LeftBar, RightBar } from "./styles";

export default ({ left, right, alignItems }) => {
  return (
    <>
      <Line>
        <LeftBar alignItems={alignItems}>{left}</LeftBar>
        <RightBar alignItems={alignItems}>{right}</RightBar>
      </Line>
    </>
  );
};
