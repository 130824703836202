import React from "react";
import Screen from "./screen";

export default ({ idModal, message, group, action }) => {
  return (
    <>
      <Screen
        idModal={idModal}
        group={group}
        message={message}
        action={action}
      />
    </>
  );
};
