import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import { DivSearch, ButtonSearch } from './styles'

const Search = props => (
	<div className="row">
		<div className="col-3" style={{ paddingRight: 0 }}>
			<label>{props.label || 'Data'}</label>
			<DatePicker
				className="form-control datePicker"
				placeholderText="Selecione uma data"
				maxDate={props.maxDate}
				showMonthDropdown
				selected={props.selectedDate}
				onChange={props.onChange}
				todayButton={'Hoje'}
			/>
		</div>
		<DivSearch>
			<ButtonSearch
				onClick={props.onClickSearch}
				className="btn bg-verde border-left-0"
			>
				<i className="fa fa-search" />
			</ButtonSearch>
		</DivSearch>
	</div>
)

Search.propTypes = {
	label: PropTypes.string.isRequired,
	maxDate: PropTypes.object,
	selectedDate: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	onClickSearch: PropTypes.func.isRequired
}

export default Search
