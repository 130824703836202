import React from "react";

import { CircularProgress } from "@material-ui/core";
import * as Styles from "./styles";

export default function MeetingLoading() {
  return (
    <Styles.Wrapper>
      <Styles.LoadingContainer>
        <CircularProgress size={24} />
        <Styles.LoadingLabel>
          Carregando reunião...
        </Styles.LoadingLabel>
      </Styles.LoadingContainer>
    </Styles.Wrapper>
  );
}
