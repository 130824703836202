import React, { useState, useCallback } from "react";
import { NUMBER, ACTION } from "../../../constants";

import GroupedListing from "../../GroupedListing";
import OverflowContent from "../../basicComponents/OverflowContent";

import Modal from "../SecondaryModal";
import ProfileModal from "../ProfileModal";

import Search from "../../Inputs/Search";

import { ModalBody, Info } from "./styles";
import _ from "lodash";

export default ({ groupedList, searchMember, pageImage, member, callback }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal(item) {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <ModalBody>
      {/*
      <Info>
        <Search label={ACTION.SEARCH_MEMBER} onChange={onChange} />
      </Info>
      */}
      <OverflowContent>
        {groupedList.map(data => (
          <GroupedListing openExternalPage={false} callback={callback}  data={data} grid={NUMBER.THREE} action={openModal} />
        ))}

        <Modal isOpen={modalIsOpen} closeModal={closeModal}>
          <ProfileModal pageImage={pageImage} member={member} />
        </Modal>
      </OverflowContent>
    </ModalBody>
  );
};
