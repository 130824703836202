import React, { PureComponent } from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { UserActions } from "../../store/ducks/user";

import { LitButton } from '../shared/LitButton'

import logLit from "../../images/lit-azul.png";
import api from "../../utils/api";

const buttonStyles = {
  'font-size': '1rem',
  'margin-top': 'auto',
  'border-radius': '50px',
  'padding': '8px 25px',
}

class AlertRestrict extends PureComponent {
  state = {
    paymentMonthly: [],
    paymentYearly: [],

    monthlyPeriod: 0,
    totalValue: 0,
    discountPercent: 0,
    totalFinal: 0,
    description: ""
  };

  componentDidMount() {
    // this.getPaymentsValues();
  }

  onCloseModal = () => {
    const {
      toggleModalRestrict,
      toggleModalRestrictLitPass,
      location,
      history
    } = this.props;
    if (
      !(
        location.pathname.includes("home") ||
        location.pathname.includes("busca") ||
        location.pathname.includes("apresentacao-do-curso")
      )
    ) {
      history.push("/home");
    }
    toggleModalRestrict(false);
    toggleModalRestrictLitPass(false);
  };

  goToProfile = () => {
    const {
      history,
      setOpenDirectPayment,
      toggleModalRestrict,
      toggleModalRestrictLitPass
    } = this.props;
    toggleModalRestrict(false);
    toggleModalRestrictLitPass(false);

    setOpenDirectPayment(true);
    history.push("/perfilAluno");
  };

  getPaymentsValues = async () => {
    try {
      const url = "getPaymentTypes";
      const response = await api.get(url);
      this.setState({
        paymentMonthly: response[0],
        paymentYearly: response[1]
      });
      this.pagamentoAnual();
    } catch (error) {
      console.error(error.response);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  calcPLan(paymentMonthly) {
    const { totalFinal, description } = paymentMonthly;

    // Instanciando o objeto
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2
    });
    //  o valor no campo e transforma em float
    const valor = parseFloat(totalFinal);
    // FORMATA CONFORME CONFIGURAÇÕES DEFINIDAS NO formatter*/
    const formatado = formatter.format(valor);

    const Texto = (
      <p className="mb-0 text-danger">{`Plano ${description} de ${formatado}`}</p>
    );
    return Texto;
  }

  render() {
    const { openModal, modalRestrictType } = this.props;

    let type;

    switch (modalRestrictType) {
      case "book":
        type = "livro";
        break;
      case "group":
        type = "grupo";
        break;
      default:
        type = "curso";
        break;
    }

    return (
      <Modal
        open={openModal}
        onClose={this.onCloseModal}
        classNames={{ modal: "lit-modal-restrict" }}
        closeOnOverlayClick={false}
      >
        <div className="modal-content">
          <div className="modal-header justify-content-start">
            <img src={logLit} alt="logoLit" height={45} />
          </div>
          <div className="modal-body" style={{ padding: "1em 7em 0 7em" }}>
            <h2 className="font-weight-normal">
              APRENDA AINDA MAIS COM O <strong>LIT</strong>
            </h2>
            <p className="mt-4">
              Oi, você escolheu um {type} exclusivo para quem já é assinante
              pagante do LIT.
            </p>
            <p>
              Sabia que, por um único valor mensal, você se{" "}
              <span className="font-weight-bold">
                prepara para o mercado de trabalho
              </span>{" "}
              com{" "}
              <span className="font-weight-bold">
                acesso ilimitado a centenas de {type}s da Saint Paul Escola de
                Negócios
              </span>{" "}
              nas áreas de empreendedorismo, inovação, marketing, finanças,
              liderança e vendas,
            </p>
            
          
          </div>
          <div className="modal-footer justify-content-center"> 
            <LitButton
              style={{
                ...buttonStyles,  
              }}
              onClick={this.goToProfile}
            >
              <span>
                Comprar curso
              </span>
            </LitButton>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.user.profile.modalRestrict,
  modalRestrictType: state.user.profile.modalRestrictType
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions.ProfileActions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlertRestrict)
);
