import React from 'react'

import styled from 'styled-components'
import RightSideBar from './RightSideBar'
import background from '../../images/lit.png'

const RightSide = styled.div`
  padding: 4em;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  /* height: 100%; */
`

const Validador = () => {
  return (
    <div id="login" className="container-fluid">
      <div className="row">
        <div className="col-md-7 d-none d-md-flex bg-loginLeft big">
          <img src={background} className="logo" alt="LIT" />
        </div>
        <div className="col-md -7 d-md-none bg-loginLeft small">
          <img src={background} className="logo" alt="LIT" />
        </div>
        <div
          className="col-md-5 align-self-center"
          
        >
          <RightSide>
            <RightSideBar />
          </RightSide>
        </div>
      </div>
    </div>
  )
}

export default Validador
