import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  label {
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
  }

  & > div {
    height: 53px;
    width: 100%;
    border: 2px solid #27aae1;
    border-radius: 5px;
    padding-right: ${props => (props.withIcon ? '10px' : '0px')};
    background: ${({ disabled }) => (disabled ? '#f8f8f8' : '#fff')};

    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      border: none;
      width: 100%;
      height: 100%;
      font-size: 16px;
      padding: 0rem 1rem 0rem;
      background: ${({ disabled }) => (disabled ? '#f8f8f8' : '#fff')};

      &:-webkit-input-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 19.9rem;
        height: 3.2rem;
        border: none;
        border-radius: 5px;
        font-size: 19px;
      }

      &:focus::-webkit-input-placeholder {
        color: transparent;
      }

      &::before {
        background: linear-gradient(
          45deg,
          rgba(2, 196, 255, 1) 0%,
          rgba(142, 230, 123, 1) 100%
        );
      }

      &:focus {
        outline: none;
      }
    }
  }

  & > p {
    color: red;
  }

  &:focus ~ label {
    visibility: visible;
  }
`;
