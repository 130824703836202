import { Types } from "./types"
import { backgrounds } from "polished"
/**
 * Reducers
 */
const INITIAL_STATE = {
  show: false,
  message: null,
  endpoints: [],
  type: null,
  trialData: {},
  showSmartBar: false,
  openPlans: false,  
}

export default function notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_ALERT:
      return {
        ...state,
        show: true,
        message: action.payload.message,
        endpoints: action.payload.endpoints,
        type: action.payload.type
      }
    case Types.REMOVE_ALERT:
      return {
        ...state,
        show: false,
        message: null,
        endpoints: [],
        type: null
      }
    case Types.SET_SMART_BAR:
      return {
        ...state,
        trialData: action.payload,
        showSmartBar: true
      }
    case Types.REMOVE_SMART_BAR:
      return{
        ...state,
        trialData: {},
        showSmartBar: false
      }
    case Types.SET_OPEN_PLANS:
      return {
        ...state,
        openPlans: true
      }
    case Types.REMOVE_OPEN_PLANS:
      return{
        ...state,
        openPlans: false
      }
    default:
      return state
  }
}
