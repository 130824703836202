/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"



class CancelarAcesso extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody} = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                9. Como posso cancelar meu acesso ao LIT?
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                 <p>
                    Para cancelar sua assinatura você deve ir no menu da plataforma e clicar na opção Perfil. 
                    Na nova tela, na sessão Minha Assinatura, clique no botão Cancelar. Ao clicar, pronto, o seu plano está cancelado. 
                    Isso significa que sua próxima cobrança recorrente não irá ocorrer e seu acesso ficará ativo até o prazo de término do período atual contratado. 
                    Caso tenha dúvidas, entre em contato no <a href="mailto:sac@saintpaul.com.br" className="text-primary">sac@saintpaul.com.br</a>.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default CancelarAcesso;
