import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { SocialActions } from "../../store/ducks/social"

import Loading from "../Loading/Loading"
import AvatarUsuarioGroupDetail from "./AvatarUsuarioGroupDetail"

class AvatarUsuarioGroup extends Component {
  componentDidMount() {
    const { getMyGroupsRequest, idProfile } = this.props
    getMyGroupsRequest(idProfile)
  }

  render() {
    const { myGroups, idProfile } = this.props
    if (myGroups.Loading) {
      return <Loading width={50} />
    }

    return (
      <ul className="list-group">
        {myGroups.data.map(group => {
          return (
            <AvatarUsuarioGroupDetail
              key={group.idGroup}
              idGroup={group.idGroup}
              idProfile={idProfile}
              photo={group.linkPhotoGroup}
              name={group.groupName}
              privacity={group.privacity}
            />
          )
        })}
      </ul>
    )
  }
}

const mapStateToProps = state => ({
  myGroups: state.social.myGroups,
})

const mapDispatchToProps = dispatch => bindActionCreators(SocialActions.myGroupsActions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarUsuarioGroup)
