import React, { useState, useEffect } from "react"
import styled from "styled-components"

import api from "../../utils/api"
import { renewToken } from '../../utils/functions';

const Alert = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: whitesmoke;
  text-align: center;
`

function AlertConfirmEmail() {
  const [emailValidate, setEmailValidate] = useState(true)

  async function getVerificationEmail() {
    try {
      const idProfile = localStorage.getItem("idProfile");
      const response = await api.get(`validateemailconfirmation/${idProfile}`);
     
      localStorage.setItem('emailValidate', response.emailValidate);
      setEmailValidate(response.emailValidate);

      const urlOriginParam = localStorage.getItem('urlOriginParam');
      const firstLogin = localStorage.getItem('firstLogin');

      if (urlOriginParam && firstLogin) {
        renewToken(); 
      }
    } catch (error) {
      console.log(error.response)
    }
  }

  useEffect(() => {
    getVerificationEmail()
  }, [])
  
  if (emailValidate) {
    return null
  }
  return (
    <Alert>
      <h5>
        Notamos que ainda não confirmou seu e-mail, favor confirme agora para aproveitar a experiência
        completa que o LIT tem a oferecer.
      </h5>
    </Alert>
  )
}

export default AlertConfirmEmail
