import React from 'react';
import PropTypes from 'prop-types';
import { Line } from "rc-progress";

import * as Styles from './styles';

const EVALUATION_STATUS = {
  "DONE": 'DONE',
  "DONE_REPROVED": "DONE_REPROVED",
  "IN PROGRESS": "IN PROGRESS",
  "NOT_STARTED":  "NOT_STARTED"
}

function Screen({evaluation}) {
  return (
    <Styles.Container>
      <p className="highlight">{evaluation.name}</p>
     
      <Styles.Grade status={evaluation.status}>
        {!(evaluation.status === EVALUATION_STATUS.NOT_STARTED || evaluation.status === EVALUATION_STATUS['IN PROGRESS']) ? (
          evaluation.grade
        ) : 'SN' }
      </Styles.Grade>
     
      <Line
        percent={(
          evaluation.status === EVALUATION_STATUS['IN PROGRESS'] ||
          evaluation.status === EVALUATION_STATUS.NOT_STARTED
        ) ? 0 : 100}
        strokeWidth="4"
        trailWidth="4"
        strokeColor="#27aae1"
        trailColor="#f5f5f5"
      />
    </Styles.Container>
  )
}

Screen.propTypes = {
  evaluation: PropTypes.object.isRequired,
};


export default Screen;
