import React, { Component } from "react";
import _ from "lodash";
import Modal from "react-responsive-modal";
import ImageLoader from "react-load-image";
import Pdfjs from "@devlit/react-pdfjs";
import Loading from "../Loading/Loading";
import { API_URL } from "../../utils/constants";
import api, { gateway } from "../../utils/api";

import ShareCertificate from "../shared/ShareCertificate";
import { ContainerButtonCertificate } from "./styles";

import CertifiedCard from "./components/CertifiedCard";
import Pagination from "./components/PaginationCertified";

class CursosConcluidos extends Component {
  state = {
    courses: [],
    loadingCourses: false,
    modalCertificado: false,
    urlCertificado: "",
    certificateUrl: "",
    currentPage: 1,
    itemsPerPage: 16,
  };

  componentDidMount() {
    try {
      document.getElementById("popoverCertificate").popover();
    } catch (error) {
      console.log("erro");
    }
    this.getMediaByProfile();
  }

  closeModalCertificado = () => {
    this.setState({
      urlCertificado: "",
      certificateUrl: "",
      modalCertificado: false,
    });
  };

  getMediaByProfile = async () => {
    this.setState({ loadingCourses: true });

    try {
      const getAllCertificates = await gateway.get(
        `/webbff/lit_app_web/user/certificate`
      );

      const certificatesMonolith = getAllCertificates.certificatesFromMonolith;
      const certificatesServices = getAllCertificates.newCertificate;

      certificatesMonolith.map((item) =>
        _.set(item, "newCourse", false)
      );
      certificatesServices.map((item) =>
        _.set(item, "newCourse", true)
      );

      const courses = certificatesMonolith.concat(certificatesServices);
      this.setState({ courses, loadingCourses: false });
    } catch (error) {
      console.log(error.response);
      this.setState({ loadingCourses: false });
    }
  };

  fetchCertificateUrl = async (idCourse) => {
    const idProfile = parseInt(localStorage.getItem("idProfile"), 10);
    try {
      const certificateUrl = await api.get(
        `/certificate/${idCourse}/${idProfile}`
      );
      this.setState({
        certificateUrl,
      });
    } catch (error) {
      console.log(error);
    }
  };

  abrirCertificadoNovo = async (url) => {
    this.setState({
      urlCertificado: url,
      certificateUrl: url,
      modalCertificado: true,
      isPdf: true,
    });
  };

  abrirCertificado = async (data) => {
    const idProfile = parseInt(localStorage.getItem("idProfile"), 10);
    let idCourse;

    let url;
    if (data.id) {
      url = `${API_URL}generateDisciplineCertificate/${idProfile}/${data.id}`;
      idCourse = data.id;
    } else {
      url = `${API_URL}generateCertificate/${idProfile}/${data.totvsId}`;
      idCourse = data.totvsId;
    }

    this.fetchCertificateUrl(idCourse);

    this.setState({
      urlCertificado: url,
      modalCertificado: true,
      isPdf: false,
    });
  };

  imprimirCertificado = () => {
    const { urlCertificado, isPdf } = this.state;

    if (isPdf) {
      window.open(urlCertificado, "_blank");
    } else {
      const width = window.window.innerWidth * 0.9;
      const height = window.window.innerHeight * 0.9;

      const content = `<!DOCTYPE html>
          <html>
            <head><title>Certificado</title><style>@page { size: landscape; margin: 0; }</style></head>
            <body onload="window.focus(); window.print(); window.close(); ">
              <img src="${urlCertificado}" style="width: 100%; height: 200mm; margin: auto;" />
            </body>
        </html>`;
      const options = `toolbar=no,location=no,directories=no,menubar=no,scrollbars=yes,width=${width},height=${height}`;
      const printWindow = window.open("", "print", options);
      printWindow.document.open();
      printWindow.document.write(content);
      printWindow.document.closetIsPopoverOpense();
      printWindow.focus();
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  render() {
    const {
      loadingCourses,
      courses,
      modalCertificado,
      urlCertificado,
      isPdf = false,
      certificateUrl,
      currentPage,
      itemsPerPage,
    } = this.state;

    const indexOfLastCourse = currentPage * itemsPerPage;
    const indexOfFirstCourse = indexOfLastCourse - itemsPerPage;
    const currentCourses = courses.slice(
      indexOfFirstCourse,
      indexOfLastCourse
    );

    const totalPages = Math.ceil(courses.length / itemsPerPage);

    return (
      <div className=" bg-white-perfil" id="cursos-concluidos">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <h5>Cursos e trilhas concluídos</h5>
            </div>
          </div>
          <div className="row">
            {loadingCourses && <div><p>Carregando...</p></div>}
            {!loadingCourses && courses.length === 0 && (
              <div>
                <p>Nenhum curso e trilha concluído até o momento</p>
              </div>
            )}
            {!loadingCourses &&
              currentCourses.map((course, index) => (
                <CertifiedCard
                  key={index}
                  course={course}
                  index={index}
                  abrirCertificado={this.abrirCertificado}
                  abrirCertificadoNovo={this.abrirCertificadoNovo}
                />
              ))}
          </div>
          
          <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', paddingTop:'30px'}}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={this.handlePageChange}
            />
          </div>
    

        </div>
        <Modal
          open={modalCertificado}
          onClose={this.closeModalCertificado}
          styles={{ modal: { marginTop: "70px" } }}
          classNames={{ modal: "lit-perfil-form" }}
          closeOnOverlayClick={false}
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                justifyContent: "center",
              }}
            >
              <h5
                className="modal-title"
                style={{
                  color: "#27aae1",
                }}
              >
                Certificado
              </h5>
            </div>
            <div
              className="modal-body"
              style={{
                background: "white",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isPdf && <Pdfjs file={urlCertificado} scale={0.5} />}

              {!isPdf && (
                <ImageLoader
                  src={urlCertificado}
                  style={{
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="certificado"
                    style={{
                      width: "90%",
                      height: "auto",
                    }}
                  />
                  <div>Não foi possível carregar o certificado!</div>
                  <Loading width={50} />
                </ImageLoader>
              )}
              <ContainerButtonCertificate
                style={{ marginTop: isPdf ? "10px" : "0px" }}
              >
                <button
                  type="button"
                  className="btn btn-certicado btn-FontWeight6 "
                  onClick={this.imprimirCertificado}
                >
                  <a>Imprimir certificado</a>
                </button>
                <ShareCertificate url={certificateUrl} />
              </ContainerButtonCertificate>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CursosConcluidos;
