import React from "react";
import Screen from "./screen";

export default ({ children, textHeader, isOpen, closeModal }) => {
  return (
    <>
      <Screen
        child={children}
        textHeader={textHeader}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
};
