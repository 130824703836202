import styled from "styled-components";

export const Container = styled.div`
  color: ${props => props.available ? '#27aae1 ' : ''} !important;
  cursor: pointer !important;
`
export const ContentItem = styled.a`
    font-size: 14;
    padding: 5px 15px;
`
export const ContentItemChild = styled.div`
  cursor: pointer !important;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -8px;

  .circle-with-grade{
    font-size: 18px;
    color: white;
    padding: 14px 16px !important;
    border-radius: 27% !important;
    margin-left: -8px;
  }
  
  .bg-icon-green{
    background-color: #3cd99e !important;
    border-color: #3cd99e !important;
  }
`
export const ContainerIcon = styled.div`
    padding: 6px 13px;
    color: ${props => props.isApproved ? "#3cd99e" : "#27AAE1"};
    font-size: 18px;
`