import React from 'react';

import { Container, ContainerVideo } from './styles';
import Play from './Play'

function Video({id, path, autoplay}) {
  return (
    <Container id={id}>
      <ContainerVideo>
        <Play url={path} autoplay={autoplay} />
      </ContainerVideo>
    </Container>
  );
}

export default Video;
