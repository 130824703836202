import React, { Component } from "react"
import moment from "moment"
import { toast } from 'react-toastify'
import { getIconNotifications, toastErrorAndWarningOptions, toastDefaultOptions } from '../../utils/functions'
import Tooltip from "../Tooltip"
import api from "../../utils/api"

export default class NotificacaoItem extends Component {
  onChangeRead = async idHistory => {
    const idProfile = localStorage.getItem("idProfile")
    const { countNotif, updateList } = this.props
    try {
      const response = await api.get(`saveReadNotification/${idHistory}/${idProfile}`)
      if (response.status === 200) {
        toast(response.message, {
          ...toastDefaultOptions,
          autoClose: true,
          closeButton: true
        })
        countNotif()
        updateList()
      }
    } catch (err) {
      console.log(err)
      toast.error(err.message, {
        ...toastErrorAndWarningOptions,
        autoClose: true,
        closeButton: true
      })
    }
  }

  render() {
    const { item, first } = this.props
    return (
      <div className={`notificacao-notificacoes ${!first && "notFirstChild"}`}>
        <Tooltip />
        <div className=" d-flex align-items-center icon-notificacao">
          <i className={getIconNotifications(item.tipoEnvio)} aria-hidden="true" />
        </div>
        <div className="col no-gutters  d-flex align-items-center description-notificacao">
          <label data-tip={item.descricao} className="text-notification">
            {item.texto}
          </label>
        </div>
        <div className="d-flex justify-content-end align-items-center date-notificacao">
          <label>
            <strong style={{ whiteSpace: "nowrap" }}>{moment(item.dataExecucao).toNow()}</strong>
          </label>
        </div>
        <div
          className="d-flex justify-content-end align-items-center date-notificacao"
          style={{ padding: "0 5px" }}
        >
          {item.readMessage ? (
            <label className="cursor-pointer">
              <i className="fa fa-circle-thin" aria-hidden="true" />
            </label>
          ) : (
            <label
              data-tip="Marcar como lida"
              onClick={() => this.onChangeRead(item.idHistory)}
              className="mark-read"
            >
              <i className="fa fa-circle" aria-hidden="true" />
            </label>
          )}
        </div>
      </div>
    )
  }
}
