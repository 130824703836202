import React from "react";
import SocialFacebookButton from "./SocialFacebookButton";

function SocialFacebook({ handleSocialLogin, handleSocialError, children }) {
  function responseFacebook(user) {
    const { _profile, _token } = user;
    const data = {
      idSocial: _profile.id,
      socialToken: _token.accessToken,
      platform: "FACEBOOK",
      profile: {
        firstName: _profile.firstName,
        lastName: _profile.lastName,
        linkPhoto: _profile.profilePicURL,
        login: {
          username: _profile.email,
          password: _profile.id,
          enabled: true,
          role: {
            idRole: 2
          }
        }
      }
    };
    handleSocialLogin(data);
  }

  return (
    <SocialFacebookButton
      provider="facebook"
      appId="240288562216479"
      onLoginSuccess={responseFacebook}
      onLoginFailure={handleSocialError}
    >
      {children}
    </SocialFacebookButton>
  );
}

export default SocialFacebook;
