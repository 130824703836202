import React, { Component } from "react"
import Disciplina from "./Disciplina"
import Loading from "../../Loading/Loading"

import api from "../../../utils/api"

class Curso extends Component {
  state = {
    countObjects: 0,
    countFinishedObjects: 0,
    disciplinas: [],
    loading: true,
  }

  componentDidMount() {
    this.getProgress()
    this.getDisciplinas()
  }

  getProgress = async () => {
    try {
      const { idCourse } = this.props
      const ra = localStorage.getItem("ra")
      const res = await api.get(`getGetCountingObjects/${ra}/${idCourse}/`)
      this.setState({
        countObjects: res.countObjects,
        countFinishedObjects: res.countFinishedObjects,
        loading: false,
      })
    } catch (error) {
      console.log(error)
    }
  }

  getDisciplinas = async () => {
    const { idCourse } = this.props
    const ra = localStorage.getItem("ra")
    try {
      const res = await api.get(`descriptionCourseBlackboard/${ra}/${idCourse}/`)
      this.setState({ disciplinas: res })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { idCourse, curso } = this.props
    const { countObjects, countFinishedObjects, disciplinas, loading } = this.state

    return (
      <div>
        <div className="card">
          <div className="card-header">
            <div
              style={{ cursor: "pointer" }}
              className="row collapsed d-flex align-items-center justify-content-around"
              data-toggle="collapse"
              data-target={`#collapseTrilha${idCourse}`}
              aria-expanded="true"
              aria-controls={`collapseTrilha${idCourse}`}
            >
              <div className="col-7 d-flex">
                <h5 className="m-0">
                   {curso}
                </h5>
                <i style={{marginLeft: '4px', marginTop: '2px'}} className="fa fa-sort-down" /> 
              </div>

              <div className="col-1 no-gutters d-flex justify-content-around align-items-center">
                <span>
                  {countFinishedObjects}/{countObjects}
                </span>
              </div>
              <div className="col-3 justify-content-around align-items-center">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${(countFinishedObjects * 100) / countObjects}%` }}
                    aria-valuenow={countFinishedObjects}
                    aria-valuemin="0"
                    aria-valuemax={countObjects}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id={`collapseTrilha${idCourse}`}
          className="collapse"
          role="tabpanel"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div id="accordion2" className="card-body col-12 discipline-progress">
            {loading ? (
              <Loading width={20} />
            ) : (
              disciplinas.map(disciplina => {
                return <Disciplina {...disciplina} key={disciplina.idDiscipline} />
              })
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Curso
