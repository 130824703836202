import React from 'react'
import Modal from 'react-responsive-modal'

export default function ModalTermos ({ visible, onClose, children }) {
		return (
			<Modal
				open={visible}
				onClose={onClose}
				closeOnOverlayClick={false}
				classNames={{ modal: 'lit-perfil-form' }}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Detalhe</h5>
					</div>
					<div className="modal-body">
						<div className="col">
							Lista de endpoints bloqueados
						</div>
            <div className="col">
              {children}
            </div>
					</div>
				

					<div className="modal-footer">
						<button type="button" className="btn btn-blue" onClick={onClose}>
							Fechar
						</button>
					</div>
				</div>
			</Modal>
		)
}
