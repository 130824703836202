import React from "react";
import {
  ICON,
  SIZE,
  PAGE_INFORMATION,
  TRANSFORM,
  FONT_TYPE,
  COLOR
} from "../../../constants";

import NavTabsContent from "../NavTabContent";
import Container from "../../../../LitAtomicComponents/containers/PageContainer";

import Text from "../../../../LitAtomicComponents/basicComponents/Text";

import Row70x30 from "../../../../LitAtomicComponents/defaultAreas/Row_70-30";

import { Description } from "./styles";

export default ({
  member,
  members,
  group,
  postItem,
  savePost,
  updatePost,
  createComment,
  deletePost,
  interationsModalIsOpen,
  setInterationsModalIsOpen
}) => {
  return (
    <>
      <Container>
        <Row70x30
          left={[
            <Description>
              <Text
                text={PAGE_INFORMATION.CONTENT}
                size={SIZE.LARGE}
                transform={TRANSFORM.UPPERCASE}
                fontType={FONT_TYPE.BOLD}
                color={COLOR.DARK}
              />
            </Description>
          ]}
          right={
            <NavTabsContent
              member={member}
              members={members}
              group={group}
              postItem={postItem}
              savePost={savePost}
              updatePost={updatePost}
              createComment={createComment}
              deletePost={deletePost}
              interationsModalIsOpen={interationsModalIsOpen}
              setInterationsModalIsOpen={setInterationsModalIsOpen}
            />
          }
        />
      </Container>
    </>
  );
};
