import React, { Component } from "react";

import { Button, Container } from "./styles";

class Coursetabs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { toggleTab, activeTab } = this.props;
    return (
      <Container>
        <div id="subnavbar-curso">
          <nav
            className="container-fluid justify-content-center"
            style={{
              display: "flex",
              padding: ".2rem 1.3rem",
              background: "#fff"
            }}
          >
            <div>
              <Button
                isActive={activeTab === "progress"}
                type="button"
                onClick={() => toggleTab("progress")}
              >
                Progresso
              </Button>
            </div>
            <div>
              <Button
                isActive={activeTab === "course"}
                type="button"
                onClick={() => toggleTab("course")}
              >
                Curso
              </Button>
            </div>
            {/* <div>
              <Button
                isActive={activeTab === "about"}
                type="button"
                onClick={() => toggleTab("about")}
              >
                Sobre
              </Button>
            </div> */}
          </nav>
        </div>
      </Container>
    );
  }
}

export default Coursetabs;
