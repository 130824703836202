import React, { Component } from "react"
import Unidade from "./Unidade"
import Loading from "../../Loading/Loading"

import api from "../../../utils/api"

class Disciplina extends Component {
  state = {
    nota: 0.0,
    ucs: [],
    loading: true,
  }

  componentDidMount() {
    this.getNota()
    this.getUcs()
  }

  getNota = async () => {
    const ra = localStorage.getItem("ra")
    const { idDiscipline } = this.props
    try {
      const res = await api.get(`getMediaByStudent/${ra}/${idDiscipline}/`)
      let nota = parseFloat(res.message)
      if (Number.isNaN(nota)) nota = 0.0
      this.setState({
        nota,
      })
    } catch (error) {
      console.log(error)
    }
  }

  getUcs = async () => {
    const ra = localStorage.getItem("ra")
    const { idDiscipline } = this.props
    try {
      const res = await api.get(`getDetailsOfUC/${ra}/${idDiscipline}/`)
      this.setState({
        ucs: res,
        loading: false,
      })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { name, countItens, countProgress } = this.props
    const { nota, ucs, loading } = this.state

    const disciplinaRandomKey = Math.random()
      .toString(36)
      .substring(7)

    return (
      <div>
        <div className="card">
          <div
            className="card-header"
            style={{
              background: "#fff",
            }}
          >
            <div
              style={{ cursor: "pointer" }}
              className="row collapsed d-flex align-items-center justify-content-around"
              data-toggle="collapse"
              data-target={`#collapse${disciplinaRandomKey}`}
              aria-expanded="true"
              aria-controls={`collapse${disciplinaRandomKey}`}
            >
              <h6 className="m-0" style={{display: 'flex', alignItems: 'center'}}>
                {name}
                <i style={{marginLeft: '4px', marginTop: '-5px'}} className="fa fa-sort-down" />
              </h6>

              <div className="col-1 no-gutters">
                <span>
                  {countProgress}/{countItens}
                </span>
              </div>
              <div className="col-3">
                <span>
                  {!!nota || nota >= 0 ? `Média Final: ${Math.round(parseFloat(nota) * 2) / 2}` : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          id={`collapse${disciplinaRandomKey}`}
          className="collapse uc-progress"
          role="tabpanel"
          aria-labelledby="headingOne"
          data-parent="#accordion2"
        >
          {loading ? (
            <Loading width={20} />
          ) : (
            ucs.map(uc => {
              return <Unidade key={uc.nameUc} {...uc} />
            })
          )}
        </div>
      </div>
    )
  }
}

export default Disciplina
