import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} image //path (url) of the image displayed in component
 * @param {String} title //title displayed in the component - first line
 * @param {String} subtitle//title displayed in the component - second line
 * @param {String} orientation //{HORIZONTAL} provision of information within the component
 * @param {String} imageSize //size of avatar image
 * @param {String} size //font size (texts) within the component
 * @param {String} fontType //font type (texts) within the component
 * @param {String} textAlign //alignment of text within the block
**/

export default ({
  image = DEFAULT_VALUES.STRING,
  title = DEFAULT_VALUES.STRING,
  subtitle = DEFAULT_VALUES.STRING,
  orientation = DEFAULT_VALUES.STRING,
  imageSize = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING,
  fontType = DEFAULT_VALUES.STRING,
  textAlign = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        image={image}
        title={title}
        subtitle={subtitle}
        orientation={orientation}
        imageSize={imageSize}
        size={size}
        fontType={fontType}
        textAlign={textAlign}
      />
    </>
  );
};
