import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import CursosConcluidos from './CursosConcluidos';
import CursosHabilitados from './CursosHabilitados';
import Especialidades from './Especialidades';
import SobreUsuario from './SobreUsuario';
import IndicacaoPlano from './IndicacaoPlano';
import AntigasIndicacoes from './Indicacao/AntigasIndicacoes';
import Planos from './Planos';
import Recibos from './Recibos';
import Termos from './Termos';
import { Creators as ProfileActions } from '../../store/ducks/user/profile/actions';
import { Creators as OnboardingActions } from '../../store/ducks/onboarding/actions';
import { ButtonContainer, ButtonConfirm } from './styles';
import api, { gateway } from '../../utils/api';
import {
  toastErrorAndWarningOptions,
  toastDefaultOptions
} from '../../utils/functions';
import { ONBOARDING_PAGES } from '../../utils/constants';
import OnboardingPageFormationsExperiences from '../Onboarding/components/OnboardingPageFormationsExperiences';
import OnboardingPageListbox from '../Onboarding/components/OnboardingPageListBox';
import ContainerCollapse from './components/ContainerCollapse';
import OnboardingPageDynamic from '../Onboarding/components/OnboardingPageDynamic';

const Sobre = ({ setOnboardingPages }) => {
  const [sendList, setSendList] = useState([]);
  const [endOfMonth, setEndOfMonth] = useState('');
  const [
    onboardingDataFormationsExperiences,
    setOnboardingDataFormationsExperiences
  ] = useState('');
  const [onboardingDataListbox, setOnboardingDataListbox] = useState('');
  const [onboardingDataGoals, setOnboardingDataGoals] = useState('');
  const [onboardingDataInterests, setOnboardingDataInterests] = useState('');
  const currentPageRef = React.createRef();
  const tenant = localStorage.getItem('tenant');
  const settings = JSON.parse(localStorage.getItem('settings'))[tenant];

  async function getListSendsasync() {
    const idProfile = localStorage.getItem('idProfile');
    const url = `mgmVoucherList/${idProfile}`;
    try {
      const response = await api.get(url);
      setSendList(response.mgmList);
    } catch (error) {
      console.log(error.response);
    }
  }

  async function fetchOnboardingPages() {
    try {
      const response = await gateway.get('/onboarding/steps');
      const pages = response.map(page => {
        if (!page.bf_concluded) {
          return {
            ...page,
            bf_concluded: false
          };
        }
        return page;
      });
      const formationsExperiences = pages.find(
        page => page.wx_page === ONBOARDING_PAGES.FORMATIONS_EXPERIENCES
      );
      const listbox = pages.find(
        page => page.wx_page === ONBOARDING_PAGES.LISTBOX
      );
      const goals = pages.find(page => page.wx_page === ONBOARDING_PAGES.GOALS);
      const interests = pages.find(page => page.wx_page === ONBOARDING_PAGES.INTERESTS);
      const interestIndex = pages.findIndex(page => page.wx_page === ONBOARDING_PAGES.INTERESTS);
      setOnboardingDataFormationsExperiences(formationsExperiences);
      setOnboardingDataListbox(listbox);
      setOnboardingDataGoals(goals);
      setOnboardingPages(pages);
      setOnboardingDataInterests({...interests, index: interestIndex})
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error, toastErrorAndWarningOptions);
      } else {
        toast.error('Erro', toastErrorAndWarningOptions);
      }
    }
  }

  async function advancePage(currentPageIndex) {
    if (currentPageIndex === -1) {
      return;
    }
    if (currentPageRef.current) {
      try {
        await currentPageRef.current.saveForm();
        toast.success(
          'Informações atualizadas com sucesso',
          toastDefaultOptions
        );
      } catch (e) {
        if (e.response && e.response.data) {
          const errorMessage = e.response.data.error;
          if (typeof currentPageRef.current.showErrors === 'function') {
            currentPageRef.current.showErrors(e.response.data.error);
          }
          toast.warn(errorMessage, toastErrorAndWarningOptions);
        }
      }
    }
  }

  async function savePageDynamic() {
    try {
      await advancePage(3);
    } catch (e) {
      if (e.response && e.response.data) {
        const errorMessage = e.response.data.error;

        toast.warn(errorMessage, toastErrorAndWarningOptions);
      }
    }
  }

  async function savePageFormationsExperiences() {
    try {
      await advancePage(2);
    } catch (e) {
      if (e.response && e.response.data) {
        const errorMessage = e.response.data.error;

        toast.warn(errorMessage, toastErrorAndWarningOptions);
      }
    }
  }

  async function savePageListbox() {
    try {
      await advancePage(3);
    } catch (e) {
      if (e.response && e.response.data) {
        const errorMessage = e.response.data.error;

        toast.warn(errorMessage, toastErrorAndWarningOptions);
      }
    }
  }

  useEffect(() => {
    getListSendsasync();
    fetchOnboardingPages();
  }, []);


  return (
    <>
      <CursosConcluidos />
      <CursosHabilitados />
      <Especialidades 
        interests={onboardingDataInterests} 
        refetchOnboarding={fetchOnboardingPages} 
      />
      
      <SobreUsuario />
      {/* <CargosUsuario /> */}
      {onboardingDataFormationsExperiences && (
        <ContainerCollapse
          title="Sua Formação e Experiência"
          emptyTitle="Nenhuma formação e experiência cadastrada"
          content={
            <>
              <OnboardingPageFormationsExperiences
                index={2}
                data={onboardingDataFormationsExperiences}
                ref={currentPageRef}
                isVisibleTitle={false}
              />
              <ButtonContainer>
                <ButtonConfirm
                  onClick={savePageFormationsExperiences}
                  type="button"
                >
                  Salvar
                </ButtonConfirm>
              </ButtonContainer>
            </>
          }
        />
      )}

      {onboardingDataListbox && (
        <ContainerCollapse
          title="Preferências de Estudo"
          emptyTitle="Nenhuma preferência cadastrada"
          content={
            <>
              <OnboardingPageListbox
                index={3}
                data={onboardingDataListbox}
                ref={currentPageRef}
                isVisibleTitle={false}
                savePageListbox={savePageListbox}
              />
              <ButtonContainer>
                <ButtonConfirm onClick={savePageListbox} type="button">
                  Salvar
                </ButtonConfirm>
              </ButtonContainer>
            </>
          }
        />
      )}

      {onboardingDataGoals && (
        <>
          <ContainerCollapse
            title="Suas Metas de Estudos"
            emptyTitle="Nenhuma meta e estudo cadastrado"
            content={
              <OnboardingPageDynamic
                index={4}
                data={onboardingDataGoals}
                questions={onboardingDataGoals.qx_dynamicquestions}
                previousAnswers={onboardingDataGoals.qx_dynamicquestions}
                ref={currentPageRef}
                isVisibleTitle={false}
                savePageDynamic={savePageDynamic}
              />
            }
          />
        </>
      )}

      {/* {<FormacoesAcademicas />} */}

      {_.get(settings, 'profile.showDiscountIndicating', 'true') === 'true' && (
        <>
          {sendList.length > 0 && <AntigasIndicacoes />}
          <IndicacaoPlano />
        </>
      )}
      {_.get(settings, 'profile.showSignature', 'true') === 'true' && (
        <Planos setEndOfMonth={value => setEndOfMonth(value)} />
      )}
      {_.get(settings, 'profile.showPayments', 'true') === 'true' && (
        <Recibos endOfMonth={endOfMonth} />
      )}
      <Termos />
    </>
  );
};

const mapStateToProps = state => ({
  data: state.onboarding.onboardingData,
  previousAnswersGoals: state.onboarding.previousAnswersGoals,
  previousAnswersIterests: state.onboarding.previousAnswersIterests
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ProfileActions,
      ...OnboardingActions
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sobre);
