import React, { Component } from "react"
import PropType from "prop-types"
import Modal from "react-responsive-modal"
import styled from "styled-components"
import Video from "./Video"
import { headerConfig } from "../../utils/constants"
import api from "../../utils/api"

const ModalTitle = styled.h5`
  color: #27aae1;
`

class ModalVideo extends Component {
  static defaultProps = {
    modal: false,
    body: false,
  }

  static propTypes = {
    indexTutorial: PropType.number.isRequired,
    titleModal: PropType.string.isRequired,
    modal: PropType.bool,
    body: PropType.bool,
  }

  state = {
    urlVideo: "",
    watched: false,
  }

  componentDidMount() {
    this.getCourseByIndex()
  }

  getCourseByIndex = async () => {
    const idProfile = localStorage.getItem("idProfile")
    const { indexTutorial, modal, body } = this.props
    const url = `/getTutorialByUserIndex/${idProfile}/${indexTutorial}/`
    try {
      const response = await api.get(url, { headers: headerConfig.headers })

      if (!response.watched || body) {
        this.setState({ urlVideo: response.url, watched: response.watched })
        if (modal) {
          this.setState({ watched: !response.watched })
        }
      }
    } catch (error) {
      console.log(error.response)
    }
  }

  modalToggle = () => {
    const { watched } = this.state
    this.setState({ watched: !watched })
  }

  render() {
    const { urlVideo, watched } = this.state
    const { titleModal, modal, indexTutorial, children } = this.props

    if (modal) {
      return (
        <div>
          <Modal
            open={watched}
            onClose={this.modalToggle}
            classNames={{ modal: "lit-perfil-form", overlay: "modal-force-overlay" }}
            closeOnOverlayClick={false}
          >
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <ModalTitle className="modal-title">{titleModal}</ModalTitle>
              </div>
              <div className="modal-body">
                <Video indexSubtitle={indexTutorial} url={urlVideo} />
              </div>
            </div>
          </Modal>
        </div>
      )
    }

    if (watched) {
      return children || <div />
    }

    return (
      <div className="modal-body">
        <Video indexSubtitle={indexTutorial} url={urlVideo} />
      </div>
    )
  }
}

export default ModalVideo
