import styled from "styled-components";
import { } from "../../constants";

export const Archives = styled.div`
  clear: both;
  padding: 10px 0;
  margin: 0;
  width: 90%;
  text-align: left;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const Agrupable = styled.span`
  display: inline-block;
  width: 90%;
  text-transform: lowercase;
`;
