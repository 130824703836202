import React from "react";
import { LinkTo } from "./styles";

export default ({ onClick, appearance, child, onEnter, onLeave }) => {
  return (
    <LinkTo 
      onClick={onClick} 
      onMouseEnter={onEnter} 
      onMouseLeave={onLeave}
      appearance={appearance}
    >
      {child}
    </LinkTo>
  );
};
