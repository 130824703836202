import React from "react";

export default React.memo(function MeetingChatMessage({ message }) {
  return (
    <>
      <span>{message}</span>
      <br />
    </>
  );
});
