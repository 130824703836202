import React from 'react'

import {Container} from './styles'

export default function GenericAlert({
  children,
  color, 
  bgColor
}) {
  return (
    <Container color={color} bgColor={bgColor}>
      {children}
    </Container>
  )
}