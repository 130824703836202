/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"


class OndeVejoCursos extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody } = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                4. Onde consigo ver todos os cursos do LIT?
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                 <p>
                    Temos vários cursos disponíveis nas áreas de gestão, marketing, negócios, finanças, inovação, entre 
                    <br />
                    outros. Você pode conhecer todos os cursos, trilhas e MBAs do LIT (navegando por Áreas de 
                    <br />
                    Conhecimento) <a className="link-destaque" href="https://lit.com.br/cursos-e-trilhas/" target="_blanck">clicando aqui.</a>

                  </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default OndeVejoCursos
