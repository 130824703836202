import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DisabledButton = styled.button`
  background-color: #d3d3d3;
  color: #a9a9a9;
  cursor: not-allowed;
  border: 1px solid #ccc;
  padding: 0.25rem 2rem;
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;
