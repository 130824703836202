import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Navbar from "./Navbar"
import { fetchUseAndPolicyTerms } from "../../business/auth";
import { toastErrorAndWarningOptions } from "../../utils/functions";

function Privacidade() {

  const [formattedUseTerm, setFormattedUseTerm] = useState('');

  async function fetchTerms() {
    try {
      const { useTerm } = await fetchUseAndPolicyTerms();
      const useTermText = useTerm.termText;
      setFormattedUseTerm(useTermText);
    } catch (error) {
      console.log(error)
      toast.error('Erro ao buscar termo!', toastErrorAndWarningOptions)
    }
  };

  useEffect(() => {
    fetchTerms();
  }, [])

  return (
    <div>
      <Navbar bgCollor="rgb(2,196,255)" />
      <div className="row justify-content-center">
        <div className="col-6">
          <div dangerouslySetInnerHTML={{ __html: formattedUseTerm }} />
        </div>
      </div>
    </div>
  )
}

export default Privacidade 
