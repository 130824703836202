import React from "react";

import {
  PAGE_INFORMATION,
  COLOR,
  NUMBER,
  ORIENTATION
} from "../../../constants";

import CardsGroup from "../../../../LitAtomicComponents/Contents/CardsGroup";
import Block from "../../../../LitAtomicComponents/basicComponents/Block";

import {} from "./styles";

export default ({ groups }) => {
  return (
    <>
      <Block color={COLOR.BLOCK_LIGHT} orientation={ORIENTATION.AFASTABLE}>
        <CardsGroup
          title={PAGE_INFORMATION.GROUPS}
          arrayOfCards={groups.allGroups}
          perLine={NUMBER.FOUR}
        />
      </Block>
    </>
  );
};
