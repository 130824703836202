import React, {useMemo} from 'react';
import PropTypes from "prop-types";

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";
import Header from "../LitAtomicComponents/Header";
import Actions from "../LitAtomicComponents/Actions";

import PageHome from './pages/Home';
import PageCalendar from './pages/Calendar';
import PageTimeline from './pages/Timeline';

import * as Styles from './styles';
import imageDefault from '../../images/default-image.png';

import {
  SIZE,
  APPEARANCE,
} from "../NewSocial/constants";

const PAGES = {
  HOME: 'HOME',
  CALENDAR: 'CALENDAR',
  TIMELINE: 'TIMELINE'
}

function Screen({turma, activePage, selectPage}) {

  const renderPage = useMemo(() => {
    switch (activePage) {
      case PAGES.HOME:
        return <PageHome turma={turma} />;
      case PAGES.CALENDAR:
        return <PageCalendar turma={turma} />;
      case PAGES.TIMELINE:
        return <PageTimeline turma={turma} />;
      default:
        return null;
    }
  }, [activePage, turma]);

  return (
   <>
    <NavBarSuperior />
    <Styles.Container>
      <Header
        hasActionTop={false}
        imagePage={turma.cover || imageDefault}
        textHeader={turma.name}
        size={SIZE.LARGE}
        bottomRight={
          <Actions
            itemsToRender={[
              {
                type: "button",
                action: () => selectPage(PAGES.CALENDAR),
                appearance: APPEARANCE.LINK,
                size: SIZE.SMALL,
                buttonText: 'Calendário',
                color: activePage === PAGES.CALENDAR ? '#333' : '#fff'
              },
              {
                type: "button",
                action: () => selectPage(PAGES.HOME),
                appearance: APPEARANCE.LINK,
                size: SIZE.SMALL,
                buttonText: 'Feed da Turma',
                color: activePage === PAGES.HOME ? '#333' : '#fff'
              },
             
              // {
              //   type: "button",
              //   action: () => selectPage(PAGES.TIMELINE),
              //   appearance: APPEARANCE.LINK,
              //   size: SIZE.SMALL,
              //   buttonText: 'Timeline',
              //   color:  activePage === PAGES.TIMELINE ? '#333' : '#fff'
              // }
            ]}
          />
        }
      />
  
      <Styles.Content>
        {renderPage}
      </Styles.Content>
     
    </Styles.Container>
   </>
  );
}

Screen.propTypes = {
  turma: PropTypes.object.isRequired,
  activePage: PropTypes.string.isRequired,
  selectPage: PropTypes.func.isRequired
};

export default Screen;
