import React, { memo } from "react";
import imageDefault from '../../images/default-image.png';

export default memo(({index, cover, grade, onOpenCertificate }) => {

  return (
    <div key={index} className="col-3 d-inline-block " style={{ padding: "0 5px" }}>
      <div>
        <img
          alt="Capa curso"
          height={100}
          className="img-course img-fluid"
          src={`${cover || imageDefault}`}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <span>
            <i style={{ fontSize: 16 }} className="fa fa-star" aria-hidden="true" />{" "}
            {Math.round((parseFloat(grade) || 0) * 2) / 2}
          </span>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={() => onOpenCertificate()}
          >
            <i
              style={{ fontSize: 18 }}
              className="fa fa-graduation-cap"
              aria-hidden="true"
            />{" "}
            Certificado
          </button>
        </div>
      </div>
    </div>
  );
});
