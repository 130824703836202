import React, { useState } from "react";
import Screen from "./screen";
import * as Yup from "yup";
import _ from "lodash";

export default ({ group, visibilityOptions, areasOfInterest, action, actionHandleImage, changeImage, handleIsOpen }) => {
  async function updateGroup(formValues) {
    
    const newGroupInfo = {
      name: formValues.name,
      image: imageCode,
      code: _.get(group, 'code', ''),
      visibility: _.get(formValues,'visibility.value', ''),
      privacy: _.get(formValues,'privacy.value', ''),
      longDescription: formValues.longDescription,
      toInsert: [],
      toDelete: []
    };

    if(!formValues.areasofinterest)
      formValues.areasofinterest = [];

    formValues.areasofinterest.forEach(selectedArea => {
      if(!group.areasOfInterest.find(area => area.cr_code === selectedArea.value)){
        newGroupInfo.toInsert.push({ code: selectedArea.value });
      }
    });

    group.areasOfInterest.forEach(area => {
      if(!formValues.areasofinterest.find(selectedArea => selectedArea.value === area.cr_code)) {
        newGroupInfo.toDelete.push({ _id: area._id });
      }
    });

    handleIsOpen(false);
    action(newGroupInfo);
  }

  const [imageCode, setimageCode] = useState(group.imageCode);
  async function setValue(value) {
    setimageCode(value);
  }
  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Campo muito curto!")
      .required("Nome obrigatório"),
    visibility: Yup.string().required("Campo Obrigatório"),
    privacy: Yup.string().required("Campo Obrigatório"),
    longDescription: Yup.string()
      .min(5, "Campo muito curto!")
      .required("Descrição obrigatória")
  });
  return (
    <>
      <Screen
        visibilityOptions={visibilityOptions}
        areasOfInterest={areasOfInterest}
        group={group}
        changeImage={changeImage}
        handleImageChange={actionHandleImage}
        updateGroup={updateGroup}
        setValue={setValue}
        validationSchema={ValidationSchema}
      />
    </>
  );
};
