import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
// import { connect } from "react-redux"
import { Bar } from "react-chartjs-2";
import Tooltip from "../../../Tooltip";

function Assessment({ assessment = [], showModal, closeModal }) {
  const [arrayData, setArrayData] = useState([]);
  const [arrayLabels, setArrayLabels] = useState([]);
  const [mensagemErro, setMensagemErro] = useState('');
  const [tabVisiblePersonalizar, setTabVisiblePersonalizar] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const GREAT = 80.0;

  function addEventListenerVisibility() {
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden && !tabVisiblePersonalizar) {
        setTabVisiblePersonalizar(!tabVisiblePersonalizar);
      }
    });
  };

  function openAssessment() {
    addEventListenerVisibility();
  };

  useEffect(() => {
    const arrayLabels = assessment.map(info => {
      return info.UC_NAME;
    });

    const arrayData = assessment.map(info => {
      return parseFloat(info.COMPLETED.toFixed(2));
    });

    const aux = [];
    assessment.forEach(uc => {
      const porcentagem = parseFloat(uc.COMPLETED);

      if (porcentagem >= GREAT) {
        aux.push(uc.UC_NAME);
      }
    });
    let mensagem = "";

    if (aux.length > 0) {
      mensagem = `Parabéns! Você demonstrou ter conhecimentos suficientes nos assuntos: ${aux.map(
        item => ` ${item}`
      )}`;
    } else {
      mensagem =
        "Conforme sua análise, você deve cursar todos os objetos de aprendizado deste curso para cumprir os seus objetivos, bons estudos!";
    }

    setArrayData(arrayData);
    setArrayLabels(arrayLabels);
    setMensagemErro("");
    setMensagem(mensagem);
  }, []);

  function removeEventListenerVisibility() {
    document.removeEventListener("visibilitychange", () => {
      setTabVisiblePersonalizar(!tabVisiblePersonalizar);
    });
  };

  function closeModalPersonalizar() {
    removeEventListenerVisibility();
    closeModal();
  };

  function renderModalBody() {
    const newLabels = arrayLabels.map((value, index) => {
      return `UC${index + 1}`;
    });
    const data = canvas => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(200, 0, 400, 0);
      gradient.addColorStop(0, "#27aae1");
      gradient.addColorStop(1, "#fcee1e");

      return {
        labels: newLabels,
        datasets: [
          {
            label: "Rendimento",
            backgroundColor: gradient,
            borderColor: gradient,
            borderWidth: 1,
            hoverBackgroundColor: "rgba(0, 160, 235, 1)",
            hoverBorderColor: gradient,
            data: arrayData
          }
        ]
      };
    };

    const options = {
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
              max: 100,
              callback(value) {
                return `${value}%`;
              }
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, labelData) {
            const label =
              labelData.datasets[tooltipItem.datasetIndex].label || "";

            return `${label} ${tooltipItem.yLabel} %`;
          }
        }
      }
    };

    if (mensagemErro) {
      return (
        <div
          style={{
            width: "70%",
            textAlign: "center"
          }}
        >
          <p>{mensagemErro}</p>
          <p>Tente novamente mais tarde </p>
        </div>
      );
    }
    return (
      <div>
        <Bar data={data} options={options} />

        <div style={{ marginTop: 2, marginBottom: 10 }}>
          <b style={{ fontSize: 13 }}>Legendas:</b>
          {newLabels.map((newLabel, index) => {
            return (
              <div style={{ fontSize: 13 }} key={newLabel}>
                <b>{newLabel}</b>
                <p
                  style={{ display: "inline" }}
                >{`: ${arrayLabels[index]}`}</p>
              </div>
            );
          })}
        </div>
        <h5 style={{ textAlign: "center" }}>{mensagem}</h5>
      </div>
    );
  };

  return (
    <>
      <div className="col-12 text-center teste-nivelamento pt-1">
        <Tooltip id="assessment" />
        <button
          type="button"
          data-for="assessment"
          data-tip="Personalize o curso para eliminar tópicos que já sabe"
          className="btn btn-nivelamento btn-FontWeight6"
          onClick={openAssessment}
        >
          <a>Personalizar o que aprender</a>
        </button>
      </div>

      <Modal
        open={showModal}
        onClose={closeModalPersonalizar}
        classNames={{ modal: "lit-perfil-form" }}
        closeOnOverlayClick={false}
      >
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              justifyContent: "center"
            }}
          >
            <h5
              className="modal-title"
              style={{
                color: "#27aae1"
              }}
            >
              Personalize o que aprender
            </h5>
          </div>
          <div
            className="modal-body"
            style={{
              background: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {renderModalBody()}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Assessment;
