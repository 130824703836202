/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ReactHover from 'react-hover'
import { FaInfo } from 'react-icons/fa'

import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions"
import { saveOnboardingSteps } from '../../../../business/onboarding'
import AcademicExperience from '../../../shared/AcademicExperience'
import ProfessionalExperience from '../../../shared/ProfessionalExperience'

import {
  Container,
  Title,
  BoxSections,
  Section,
  SectionTitle,
  TextError,
  ButtonInfo,
  TextInfo
} from './styles'

const optionsCursorTrueWithMargin = {
  followCursor: true,
  shiftX: -320,
  shiftY: -90
}


class OnboardingPageFormationsExperiences extends Component {

  constructor(props) {
    super(props)
    const { data, isVisibleTitle } = props

    this.state = {
      professionalErrors: {},
      academicErrors: {},
      academicErrorMessage: null,
      professionalErrorMessage: null,
      withoutAcademicTraining: data.qx_useracademicexperience.length > 0
        ? data.qx_useracademicexperience.find(item => item.wx_formationtype === "SEM FORMAÇÃO")
        : false,
      withoutProfessionalExperience: data.qx_userprofessionalexperience.length > 0
        ? data.qx_userprofessionalexperience.find(item => item.wx_office === "SEM EXPERIÊNCIA")
        : false,
      qx_useracademicexperience: data.qx_useracademicexperience.length > 0
        ? data.qx_useracademicexperience
          .filter(item => item.wx_formationtype !== "SEM FORMAÇÃO")
          .map((item, index) => ({
            ...item,
            id: index + 1
          }))
        : [],
      qx_userprofessionalexperience: data.qx_userprofessionalexperience.length > 0
        ? data.qx_userprofessionalexperience
          .filter(item => item.wx_office !== "SEM EXPERIÊNCIA")
          .map((item, index) => ({
            ...item,
            id: index + 1
          }))
        : [],
      wu_linkedin_url: data.wu_linkedin_url || '',
      isVisibleTitle
    };
  }

  saveForm = async () => {
    const {
      qx_useracademicexperience,
      qx_userprofessionalexperience,
      withoutAcademicTraining,
      withoutProfessionalExperience,
      wu_linkedin_url
    } = this.state

    const { index, onboardingData } = this.props;
    const dataActiveStep = Object.assign({}, onboardingData[index]);
    let academicexperience = qx_useracademicexperience
    let userprofessionalexperience = qx_userprofessionalexperience

    if (withoutAcademicTraining) {
      academicexperience = [
        {
          wx_institution: "",
          wx_course: "",
          wx_formationtype: "SEM FORMAÇÃO",
          dx_dateinit: "",
          dx_dateend: "",
          bf_postgradute: null,
          bf_inprogress: null
        }
      ]
    }

    if (withoutProfessionalExperience) {
      userprofessionalexperience = [
        {
          wx_office: "SEM EXPERIÊNCIA",
          wx_company: "",
          wx_description: "",
          dx_dateinit: "",
          dx_dateend: "",
          bf_mainjob: null,
          bf_currentjob: null
        }
      ]
    }

    if (!academicexperience.length) {
      this.setState({ academicErrorMessage: 'Preencha informações acadêmicas' })
      throw new Error('Preencha informações acadêmicas')
    }

    if (!userprofessionalexperience.length) {
      this.setState({ professionalErrorMessage: 'Preencha informações profissionais' })
      throw new Error('Preencha informações profissionais.')
    }

    dataActiveStep.qx_useracademicexperience = academicexperience
    dataActiveStep.qx_useracademicexperience = userprofessionalexperience

    this.saveDataInState(true)
    const data = {
      wx_step: "FORMATIONS_EXPERIENCES",
      ox_formdata: {
        qx_academicexperience: academicexperience,
        qx_professionalexperience: userprofessionalexperience,
        wu_linkedin_url
      }
    }
    await saveOnboardingSteps(data)

  };

  saveDataInState = (concluded = false) => {
    const {
      qx_useracademicexperience,
      qx_userprofessionalexperience,
      withoutAcademicTraining,
      withoutProfessionalExperience,
      wu_linkedin_url
    } = this.state

    const { index, onboardingData, setOnbordingPageConcluded } = this.props;
    const dataActiveStep = Object.assign({}, onboardingData[index]);

    let academicexperience = qx_useracademicexperience
    let userprofessionalexperience = qx_userprofessionalexperience

    if (withoutAcademicTraining) {
      academicexperience = [
        {
          wx_institution: "",
          wx_course: "",
          wx_formationtype: "SEM FORMAÇÃO",
          dx_dateinit: "",
          dx_dateend: "",
          bf_postgradute: null,
          bf_inprogress: null,
        }
      ]
    }

    if (withoutProfessionalExperience) {
      userprofessionalexperience = [
        {
          wx_office: "SEM EXPERIÊNCIA",
          wx_company: "",
          wx_description: "",
          dx_dateinit: "",
          dx_dateend: "",
          bf_mainjob: null,
          bf_currentjob: null
        }
      ]
    }

    dataActiveStep.qx_useracademicexperience = academicexperience
    dataActiveStep.qx_userprofessionalexperience = userprofessionalexperience
    dataActiveStep.wu_linkedin_url = wu_linkedin_url
    setOnbordingPageConcluded(index, dataActiveStep, onboardingData.length > 0 && onboardingData[index].bf_concluded || concluded)
  }

  handleUpdateAcademicExperience = (data, errors) => {
    this.setState({
      qx_useracademicexperience: [...data],
      academicErrors: errors
    }, () => {
      const { qx_useracademicexperience } = this.state
      if (qx_useracademicexperience.length > 0) {
        this.setState({ academicErrorMessage: null })
      }
    })
    if (this.props.savePageFormationsExperiences) {
      this.props.savePageFormationsExperiences()
    }
  }

  handleUpdateProfessionalExperience = (data, errors) => {
    this.setState({
      qx_userprofessionalexperience: [...data],
      professionalErrors: errors
    }, () => {
      const { qx_userprofessionalexperience } = this.state
      if (qx_userprofessionalexperience.length > 0) {
        this.setState({ professionalErrorMessage: null })
      }
    })
    if (this.props.savePageFormationsExperiences) {
      this.props.savePageFormationsExperiences()
    }
  }

  toggleWithoutAcademicTraining = () => {
    const { withoutAcademicTraining } = this.state
    this.setState({ withoutAcademicTraining: !withoutAcademicTraining, academicErrorMessage: null })
  }

  toggleWithoutProfessionalExperience = () => {
    const { withoutProfessionalExperience } = this.state
    this.setState({ withoutProfessionalExperience: !withoutProfessionalExperience, professionalErrorMessage: null })
  }

  onChangeLinkedinUrl = (value) => {
    this.setState({ wu_linkedin_url: value })
  }

  showErrors = (errors) => {
    const academicErrors = {}
    const professionalErrors = {}

    Object.keys(errors).forEach(key => {
      const index = key.substring(key.indexOf('[') + 1, key.lastIndexOf(']'))
      if (key.indexOf('qx_academicexperience') > -1) {
        academicErrors[index] = 'O registro contem dados inválidos'
      }

      if (key.indexOf('qx_professionalexperience') > -1) {
        professionalErrors[index] = 'O registro contem dados inválidos'
      }

    })
    this.setState({ academicErrors, professionalErrors })

  }

  render() {
    const { index } = this.props
    const {
      qx_useracademicexperience,
      qx_userprofessionalexperience,
      withoutAcademicTraining,
      withoutProfessionalExperience,
      academicErrorMessage,
      professionalErrorMessage,
      wu_linkedin_url,
      academicErrors,
      professionalErrors,
      isVisibleTitle
    } = this.state
    return (
      <Container className="container">
        {isVisibleTitle && <Title> {index + 1}. Sua Formação e Experiência</Title>}
        <BoxSections>
          <Section>
            <SectionTitle>
              Formações
              <ReactHover
                options={optionsCursorTrueWithMargin}
              >
                <ReactHover.Trigger type='trigger'>
                  <ButtonInfo>
                    <FaInfo size="14px" />
                  </ButtonInfo>
                </ReactHover.Trigger>
                <ReactHover.Hover type='hover'>
                  <TextInfo>
                    Importante! Para conquistar seu certificado MBA da Saint Paul Escola de Negócios você precisa ter
                    graduação completa (exigência para qualquer programa de especialização conforme determinação do Ministério da Educação - MEC).
                  </TextInfo>
                </ReactHover.Hover>
              </ReactHover>
            </SectionTitle>
            <AcademicExperience
              errors={academicErrors}
              withoutAcademicTraining={withoutAcademicTraining}
              toggleWithoutAcademicTraining={this.toggleWithoutAcademicTraining}
              data={qx_useracademicexperience}
              showNotHaveWithoutAcademicTraining={!qx_useracademicexperience.length}
              updateData={this.handleUpdateAcademicExperience} />
            {academicErrorMessage && <TextError>{academicErrorMessage}</TextError>}
          </Section>
          <Section>
            <SectionTitle>Profissional</SectionTitle>
            <ProfessionalExperience
              errors={professionalErrors}
              withoutProfessionalExperience={withoutProfessionalExperience}
              toggleWithoutProfessionalExperience={this.toggleWithoutProfessionalExperience}
              data={qx_userprofessionalexperience}
              onChangeLinkedinUrl={this.onChangeLinkedinUrl}
              showNotHaveWithoutProfessionalExperience={!qx_userprofessionalexperience.length}
              updateData={this.handleUpdateProfessionalExperience}
              linkedURL={wu_linkedin_url}
            />
            {professionalErrorMessage && <TextError>{professionalErrorMessage}</TextError>}
          </Section>
        </BoxSections>

      </Container>
    )
  }
}


const mapStateToProps = state => ({
  onboardingData: state.onboarding.onboardingData,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...OnboardingActions
}, dispatch)


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(OnboardingPageFormationsExperiences)