import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import "moment/locale/pt-br"

import Img from "react-image"
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import ItemsCarousel from 'react-items-carousel';
import CardImgDefault from "../../Home/CardImgDefault";
import Loading from "../../Loading/Loading"
import history from '../../../utils/history'

import { gateway } from '../../../utils/api'

import * as S from './styles'

export default function MyCoursesLitPass({redirect, redirectMyCourse}){
  const [courses, setCourses] = useState([])
  const [activeItemIndex, setActiveIndex] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getLitpassCourses() {
      try {
        const url = "webbff/lit_app_web/home/lane/type/LANE_IN_PROGRESS";
        const response = await gateway.get(url);
        let filteredCourses = _.get(response, 'docs')
        filteredCourses = filteredCourses.filter(course => !course.lockedCourse)
        setLoading(false)
        setCourses(filteredCourses)
        
      } catch (error) {
        console.log(error.response)
        setLoading(false)
      }
    }
    getLitpassCourses()
  }, [])

  const renderCourseImage = (course) => {   
    const fallback =  <CardImgDefault
      key={course.idCourse}
      onClick={() => redirect(course)}
      course={course}/>
    
    return (
     <S.CourseItem style={{cursor: "pointer" }} onClick={() => history.push({ pathname: `/curso-novo/${course.idCourse}`}) }>
      {course.courseImageUrl ? (
        <Img
          key={course.idCourse}
          src={course.courseImageUrl}
          onClick={() => redirect(course)}
          style={{ width: "100%" }}
          loader={<Loading width={30} img />}
          unloader={fallback}
        />
      ) : fallback}
      
      <bold>
        Nova escolha será liberada em {course.dateEnd}
      </bold>
    </S.CourseItem> 
    )
  }

  
  return (
    loading ? (
      <p>Carregando...</p>
    ) : (
       courses.length > 0 && (
        <>
          <h3 style={{fontSize: '18px'}}>{!redirectMyCourse ? "Cursos selecionados" : "Cursos liberados"}</h3>
          <div style={{"padding":"0 20px", "maxWidth":800, "margin":"20px auto"}}>
              <ItemsCarousel
                infiniteLoop={false}
                gutter={12}
                activePosition='center'
                chevronWidth={40}
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={2}
                slidesToScroll={1}
                outsideChevron
                rightChevron={<MdChevronRight size={70} />}
                leftChevron={<MdChevronLeft size={70} />}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={activeItemIndex}
                requestToChangeActive={value => setActiveIndex(value)}
                
              >
                {courses.map(course => (
                  renderCourseImage(course)
                ))}
            </ItemsCarousel>
            </div>
          </>
       )
        
      
    )
   
  )
}
