/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Lane from '../../../../../shared/Lane';
import CardWarning from '../CardWarning';
import * as Styles from './styles';

function Screen({ warnings, title, getMoreWarnings, onClick, errorWarningsMessage }) {

  return (
    <Styles.Container>
      <Lane title={title} paginationHook={getMoreWarnings}>
        {!warnings.length && (
          <Styles.EmptyListText>
            {errorWarningsMessage ? errorWarningsMessage : 'Sem avisos'}
          </Styles.EmptyListText>
        )}
        {warnings.map(warning => {
          return (
            <Styles.Wrapper key={`${warning.id}`}>
              <CardWarning 
                warning={warning} 
                onClick={(warning) => onClick(warning)}
              />
            </Styles.Wrapper>
          );
        })}
      </Lane>
    </Styles.Container>
  );
}

export default Screen;
