import _ from 'lodash';

export default (
  installmentCurrentValue = 0,
  selectedPlan,
  paymentMethod,
  couponData
) => {
  const paymentMethodsCurrent = _.get(selectedPlan, 'paymentMethods', []);
  const findItemForCredit = paymentMethodsCurrent.find(
    installmentItem => installmentItem.type === paymentMethod
  );
  const installmentsFromCurrentItem = _.get(
    findItemForCredit,
    'installments',
    []
  );
  const maxInstallment = _.get(findItemForCredit, 'maxInstallment', 0);
  const currentInstallmentValue = installmentCurrentValue > 0 ? installmentCurrentValue : maxInstallment;
  const findDiscountByItem = installmentsFromCurrentItem.find(
    item => item.number === Number(currentInstallmentValue)
  );
  const priceFromInstallment = _.get(findDiscountByItem, 'totalPrice', 0);

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });

  let priceWithDiscount;

  if (couponData && couponData.type && couponData.value) {
    const tmpPrice =
      couponData.type === 'PERCENT'
        ? priceFromInstallment - priceFromInstallment * (couponData.value / 100)
        : priceFromInstallment - couponData.value;

    if (tmpPrice < 0) priceWithDiscount = 0;
    else priceWithDiscount = tmpPrice;
  }
  const basePrice = selectedPlan ? selectedPlan.basePrice : 0
  const hideTotalPriceLabel = basePrice === priceFromInstallment;
  const price =
    priceWithDiscount || priceWithDiscount === 0
      ? parseFloat(priceWithDiscount)
      : parseFloat(priceFromInstallment);
  const totalPriceFromInstallment = formatter.format(price);
  return {
    active: Number(currentInstallmentValue) > 0,
    installments: Number(currentInstallmentValue),
    totalPriceFromInstallment,
    price,
    hideTotalPriceLabel
  };
};
