import styled from 'styled-components';
import { LitButton } from '../../../../../shared/LitButton';

export const DescriptionText = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ConfirmButton = styled(LitButton)`
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  margin-top: 0;
  max-width: 200px;
  padding-right: -320px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;
