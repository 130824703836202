/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"



class ProblemaDeAcesso extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody} = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                2. O que fazer quando tenho problemas de acesso?
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                 <p>
                    Que chato que está tendo um problema técnico! O primeiro passo é limpar o cache do seu
                    <br />
                    navegador e entrar novamente no LIT, usando preferencialmente o Google Chrome no desktop ou 
                    <br />
                    os aplicativos em seu celular Android ou iOS.
                </p>
                <p>
                    Para limpar cache utilizando o Google Chrome:
                </p>
                <ol>
                    <li>Abra o Chrome.</li>
                    <br />
                    <li>Na barra de ferramentas do navegador, clique em Mais &gt; Mais ferramentas &gt; Limpar dados de navegação.</li>
                    <br />
                    <li>Na caixa "Limpar dados de navegação", clique nas caixas de verificação para Cookies e outros dados do site e Imagens e ficheiros em cache.</li>
                    <br />
                    <li>Utilize o menu na parte superior para selecionar a quantidade de dados que pretende eleminar. Escolha desde sempre para eliminar tudo.</li>
                    <br />
                    <li>Clique em Limpar Dados de Navegação.</li>
                </ol>
                <br/>
                <p>
                    Pedimos que antes de abrir um chamado no Help Desk, aguarde um prazo de duas horas, pois muitas vezes esses são problemas pontuais.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ProblemaDeAcesso;
