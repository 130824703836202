import React, { memo } from "react";
import { Link } from "react-router-dom";
import { TextDescription } from "../../utils/styles";

const NovaConta = ({ litpass, isVocacao, urlOriginParam }) => {
  return (
    <div className="criar-conta">
      <TextDescription className="mt-3">
        <p className="font-weight-bold mb-1">Ainda não tem conta?</p>
        <span>
          <span style={{ color: "#58595b" }}>
            Crie uma conta para acessar o{" "}
          </span>
          {litpass && (
            <Link to={"/cadastroPerfilLitPass" + urlOriginParam}>LIT</Link>
          )}
          {isVocacao && (
            <Link to={"/cadastroPerfilVocacao" + urlOriginParam}>LIT</Link>
          )}
          {!litpass && !isVocacao && (
            <Link to={"/cadastroPerfil" + urlOriginParam}>LIT</Link>
          )}
        </span>
        <br />
      </TextDescription>
    </div>
  );
};

export default memo(NovaConta);
