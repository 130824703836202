import React, { useState } from "react";

import { isUserAuthorized } from "../../../utils/functions";

export const MeetingContext = React.createContext();

export const MeetingProvider = ({ children }) => {
  const [meeting, setMeeting] = useState(null);
  const [meetingLoading, setMeetingLoading] = useState(true);
  const [isExitingFromMeeting, setExitingFromMeeting] = useState(false);
  const [meetingEnded, setMeetingEnded] = useState(false);
  const [isMeetingFullscreen, setMeetingFullscreen] = useState(false);
  const [localStream, setLocalStream] = useState(null);
  const [muteLocalCam, setMuteLocalCam] = useState(false);
  const [muteShareScreen, setMuteShareScreen] = useState(true);

  const hasHostPermissions = isUserAuthorized("PROFESSOR");

  return (
    <MeetingContext.Provider
      value={{
        meeting,
        meetingLoading,
        meetingEnded,
        muteLocalCam,
        muteShareScreen,
        isExitingFromMeeting,
        isMeetingFullscreen,
        localStream,
        setMeeting,
        setMeetingLoading,
        setMeetingEnded,
        setMeetingFullscreen,
        setMuteLocalCam,
        setMuteShareScreen,
        setLocalStream,
        setExitingFromMeeting,
        hasHostPermissions
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
};
