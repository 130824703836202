import { Types } from './types';
/**
 * Reducers
 */

const INITIAL_STATE = {
  type: '',
  value: undefined
};

export default function coupon(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_COUPON_DATA:
      return {
        ...state,
        type: action.payload.data.type,
        value: action.payload.data.value
      };
    default:
      return state;
  }
}
