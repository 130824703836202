import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Array} content //list of slider components
*/

export default ({
  content = DEFAULT_VALUES.LIST
}) => {
  return (
    <>
      <Screen
        content={content}
      />
    </>
  );
};
