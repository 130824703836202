import React, {PureComponent} from 'react';
import {MdClose} from 'react-icons/md'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { Creators as AlertActions } from "../../store/ducks/alert/actions"

import ModalDetalhe from '../Modals/ModalDetalhe';

class AlertTimeout extends PureComponent {

  state = {
    modalIsOpen: false,
  }

  viewDeatils = () => {
    this.setState({
      modalIsOpen: true,
    })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    })
  }

  render() {
    const {show, message, endpoints, removeAlert, type} = this.props;
    const {modalIsOpen} = this.state;

    if(type === 'warn') {
      setTimeout(() => {
        removeAlert();
      }, 19000)
    }
    
    const bg = type === 'warn' ? '#ebc934' : 'red';
    const cor = type === 'warn' ? '#000' : '#fff';
   
    if (modalIsOpen) {
      return (
        <ModalDetalhe
          visible={modalIsOpen}
          onClose={this.closeModal}
          classNames={{ modal: "lit-perfil-form pagamento" }}
        >
          <ol style={{padding: '0 10px'}}>
            {endpoints.map(item => (
              <li key={item.completeUrl}>{item.completeUrl}</li>
            ))}
          </ol>
        </ModalDetalhe>
      )
    }
    if(show) {
      return (
        <p
          style={{
            background: bg,
            color: cor,
            textAlign: "center",
            position: 'fixed',
            margin: 0,
            width: "100%",
            fontSize: '16px',
            zIndex: 9999,
            fontWeight: "bold",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <span style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {message}
          </span>
          <span style={{padding: '2px 5px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {endpoints.length > 0 && (
                <a href="#" onClick={this.viewDeatils}>Ver detalhes</a>
            )}
          </span>
          <span style={{padding: '2px 5px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <MdClose style={{cursor: 'pointer'}} size="20" onClick={() => removeAlert()} />
          </span> 
        </p>
      )
    }

    return ''
  }
}

const mapStateToProps = store => {
  return {
    show: store.alert.show,
    message: store.alert.message,
    endpoints: store.alert.endpoints,
    type: store.alert.type
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    ...AlertActions
   }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertTimeout)
