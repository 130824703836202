import axios from 'axios';
import _ from 'lodash';
import creditCardType from 'credit-card-type';
import { VINDI_API_KEY } from './constants';
import { gateway } from "./api";

export async function encryptCreditCard(data) {
  const token = VINDI_API_KEY;
  const url = `${process.env.REACT_APP_URL_VINDI}/public/payment_profiles`;
  const cardType = creditCardType(data.card_number)[0];
  const body = {
    holder_name: data.holder_name,
    card_expiration: data.card_expiration,
    card_number: data.card_number,
    card_cvv: data.card_cvv,
    payment_method_code: "credit_card_cielo",
    payment_company_code: cardType ? cardType.type : 'Desconhecido',
  }

  const response = await axios.post(url, body, {
    headers: {
      Authorization: `Basic ${token}`
    }
  });

  return _.get(response, 'data.payment_profile.gateway_token', null)
}

export async function createVindiCustomer(data) {
  try {
    return gateway.post('/contract/contract/createCustomer', data);
  } catch (error) {
    return null;
  }
}
