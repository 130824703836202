import React from 'react';
import SideBarMenuItemCourse from '../../SideBarMenuItemCourse';

function AboutTrail({ aboutTitle, handleSelectedItem, ...rest }) {
  return <SideBarMenuItemCourse
            handleSelectedItem={handleSelectedItem}
            title={aboutTitle}
            iconNumeric={0}
            data-toggle="collapse"
            showProgress={false}
            icon="list"
            {...rest}
            />;
}

export default AboutTrail;