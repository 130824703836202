import styled, { css } from "styled-components";
import { ProgressBar } from "react-bootstrap";
import { STATUS, POSITION } from "../../../constants";

export const Progress = styled(ProgressBar)`

  ${props => (props.statusProgress === STATUS.IN_PROGRESS) && css`
    position: absolute;
    bottom: 18px;
    left: 5px;
    height: 5px;
    width: calc(100% - 70px);
  `};

  ${props => (props.statusProgress === STATUS.COMPLETED) && css`
    div {
      padding: 0;
    }
    position: absolute;
    bottom: 18px;
    left: 5px;
    height: 15px;
    width: 30px;
    color: var(--white);
    font-weight: bold;
  `};

  ${props => (props.position === POSITION.TOP_JUSTIFY) && css`
    position: relative;
    bottom: -5px;
    left: initial;
    height: 5px;
    width: 100%;
  `};
  
`;
