import React, { memo } from "react"

const PagamentoMensal = ({ value }) => {
  const valueFormat = `${value}`.split(".")
  return (
    <div className="valor-desc">
      <span className="unidade">R$ {valueFormat[0]}</span>
      <sup>
        ,{valueFormat[1]}
        {valueFormat[1] < 10 ? "0" : ""}
      </sup>
      <span className="mes">Mês</span>
    </div>
  )
}

export default memo(PagamentoMensal)
