/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import SliderWrap from '../Slider';
import history from '../../../utils/history'
import _ from 'lodash';
import { LitButton } from '../LitButton';

import * as Styles from './styles';

const Lane = ({ title, children, paginationHook, fullButton = false, linkForAll = false }) => {

  const settings = JSON.parse(localStorage.getItem('settings'));
  const tenant = localStorage.getItem('tenant') || '';

  const { headerBackgroundColor, headerBackgroundGradient } = _.get(
      settings,
      `${tenant}`,
      {}
    );

    const renderBackgroundNav = () =>
    headerBackgroundColor ? headerBackgroundColor : '#27AAE1';

  return (
    <Styles.Container className="cursos col-12">
      <Styles.Row>  
        <h6>{title}</h6>
        {linkForAll ? <Styles.Button 
        type="button" 
        style={{ background: renderBackgroundNav() }}
        onClick={() => history.push(linkForAll)}>Ver todos
        </Styles.Button> 
        : <></>}
      </Styles.Row>
      <div>
        <SliderWrap fullButton={fullButton} paginationHook={paginationHook}>{children}</SliderWrap>
      </div>
    </Styles.Container>
  );
};

export default memo(Lane);
