import React, { useState, useEffect, useMemo } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { toast } from 'react-toastify';
import qs from 'query-string';

import * as Styles from './styles';
import { gateway } from '../../../../utils/api';
import { toastErrorAndWarningOptions } from '../../../../utils/functions';
import history from '../../../../utils/history';
import Loading from '../../../Loading/Loading';
import PlanCard from '../PlanCard';
import PromoPlanCard from '../PromoPlanCard'
import Warning, { WarningText } from '../Warning';

import { LitButton } from '../../../shared/LitButton';

const buttonStyles = {
  'font-size': '1rem',
  'font-weight': 'bold',
  'font-family': 'Lato',
  'margin-top': 'auto'
};

const IMG_LIT_SAINTPAUL = require('../../../../images/sp_lit.png');

function PlanList({
  numberOfCards = 4,
  slidesToScroll = 1,
  showHeader,
  onSelectPlan
}) {
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [plans, setPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [hasOpenInvoice, setHasOpenInvoice] = useState(false);
  const certificates = localStorage.getItem('certificates');
  const [
    hasExpiredOrNearExpirationInvoice,
    setHasExpiredOrNearExpirationInvoice
  ] = useState(false);

  const renderPlansCards = useMemo(() => {
    const plansToRender = [];

    plans.forEach(plan => {
      plansToRender.push(plan);

      if (plan && plan.campaigns && plan.campaigns.length > 0) {
        plan.campaigns.forEach(campaing => {
          if (campaing && campaing.showNewCard) {
            plansToRender.push(campaing);
          }
          if (campaing && campaing.hideOriginalCard) {
            const index = plansToRender.indexOf(plan);

            plansToRender.splice(index, 1);
          }
        });
      }
    });

    return plansToRender.map((plan) => plan.promo ? <PromoPlanCard key={plan.code} plan={plan} onSelectPlan={onSelectPlan} promo /> : <PlanCard key={plan.code} plan={plan} onSelectPlan={onSelectPlan} />);
  }, [plans]);

  function redirectToHistoryPayment() {
    history.push('invoicePaymentView', {
      nextPageParams: {
        fromPayment: true
      }
    });
  }

  function logout() {
    history.push('/logout');
  }

  function redirect() {
    history.push('/certificados');
  }

  useEffect(() => {
    async function loadPlans() {
      try {
        const urlOriginParam = localStorage.getItem('urlOriginParam');
        const loginTenant = localStorage.getItem('loginTenant');

        const queryParams = {};

        if (loginTenant) {
          queryParams.loginTenant = loginTenant;
        }

        if (urlOriginParam) {
          queryParams.origin = urlOriginParam;
        }

        const response = await gateway.get(
          `/webbff/lit_app_web/payment/plan?${qs.stringify(queryParams)}`
        );

        const { plans = [] } = response;
        setPlans(plans);

        if (plans.length === 1 && plans[0].alreadyPurchased === false) {
          onSelectPlan(plans[0]);
        }

        const pixDataStr = localStorage.getItem('pixData');

        if (pixDataStr) {
          const pixData = JSON.parse(pixDataStr);

          const pixOffer = response.plans.find(p => p.code === pixData.offerId);

          if (pixOffer) {
            onSelectPlan(pixOffer);
          }
        }
      } catch (error) {
        toast.error(
          'Ops, ocorreu um erro e não possível buscar os planos',
          toastErrorAndWarningOptions
        );
      } finally {
        setLoadingPlans(false);
      }
    }

    loadPlans();
  }, []);

  useEffect(() => {
    async function getOpenInvoices() {
      try {
        const response = await gateway.get(
          `/webbff/lit_app_web/payment/invoice/isOpenInvoices`
        );
        setHasOpenInvoice(response);
      } catch (error) {
        console.log(error);
      }
    }

    getOpenInvoices();
  }, []);

  useEffect(() => {
    async function consultUserInvoiceHistory() {
      try {
        const response = await gateway(
          '/webbff/lit_app_web/payment/invoice/history?detailedHistory=false'
        );

        setHasExpiredOrNearExpirationInvoice(
          response.hasExpiredOrNearExpirationInvoice
        );
      } catch (error) {
        console.log(error);
      }
    }

    consultUserInvoiceHistory();
  }, []);

  return (
    <Styles.Container>
      {showHeader && (
        <Styles.NavLit className="navbar navbar-lit navbar-expand-lg bg-gradient-lit">
          <a className="navbar-brand">
            <img src={IMG_LIT_SAINTPAUL} alt="logo_lit" />
          </a>
          <button type="button" onClick={() => logout()}>
            SAIR
          </button>
        </Styles.NavLit>
      )}
      <Styles.Content>
        {(hasOpenInvoice || hasExpiredOrNearExpirationInvoice) && (
          <Warning onClick={redirectToHistoryPayment}>
            <WarningText size={16}>
              Identificamos que você possuí boletos em aberto, vencidos ou
              próximo do vencimento. Caso já tenha executado o pagamento esse
              aviso pode ser ignorado, se deseja visualizar os boletos em aberto{' '}
              <WarningText bold>clique aqui</WarningText>.
            </WarningText>
          </Warning>
        )}
        {loadingPlans && <Loading width="30" />}
        {!loadingPlans && (
          <Styles.Cards itemsQuantity={plans.length} centerMode={plans.length <= 3}>
            <Styles.ContainerSlider>
              <Styles.Title>Escolha a melhor opção para você.</Styles.Title>

              <Styles.CustomItemsCarousel
                numberOfPlaceholderItems={1}
                numberOfCards={window.screen.width <= 600 ? 1 : numberOfCards}
                slidesToScroll={slidesToScroll}
                showSlither
                freeScrolling={false}
                chevronWidth={40}
                outsideChevron
                rightChevron={<MdChevronRight size={70} />}
                leftChevron={<MdChevronLeft size={70} />}
                requestToChangeActive={newIndex => {
                  setActiveCarouselIndex(newIndex);
                }}
                activeItemIndex={activeCarouselIndex}
                closeModal
              >
                {renderPlansCards}
              </Styles.CustomItemsCarousel>
            </Styles.ContainerSlider>
            {!loadingPlans && certificates && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LitButton
                  style={{
                    ...buttonStyles,
                    padding: '5px 20px',
                    'font-size': '16px',
                    'margin-bottom': '10px'
                  }}
                  onClick={redirect}
                >
                  VEJA SEUS CERTIFICADOS DISPONÍVEIS
                </LitButton>
              </div>
            )}
          </Styles.Cards>
        )}
      </Styles.Content>
    </Styles.Container>
  );
}

export default PlanList;
