import React from "react";
import { InfiniteScroll } from "./styles";

export default ({ child = <></>, dataLength = 0, loadAction = () => {}, scrollableTarget = 'scrollableDiv', hasMore = true }) => { 
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={loadAction}
      hasMore={hasMore}
      scrollableTarget={scrollableTarget}
      scrollThreshold={0.5}
      >
      { child }     
    </InfiniteScroll>
  );
};