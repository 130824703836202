import React from 'react'

import MyCoursesLitPass from '../MyCoursesLitPass'

export default function ContentModalLitPass({ redirect }) {
  return (
    <div style={{ textAlign: "left" }}>
      <h2>ATENÇÃO</h2>
      {/* <p>Ao finalizar o próximo item do curso, o curso entrara na sua biblioteca. Deseja continuar?</p> */}

      <p>Este curso será considerado selecionado a partir do próximo micromomento que você completar. Quando tiver escolhido 2 cursos, a sua navegação ficará limitada a eles.</p>

      <p>No primeiro dia do proximo mês, você volta a acessar novamente todos os cursos e poderá selecionar novos ou continuar os que já começou.</p>

      <p>Seu progresso é sempre salvo, mesmo que mude de curso no mês seguinte!</p>

      <MyCoursesLitPass redirect={redirect} />
    </div>
  )
}