import React, { memo } from "react"

import styled from "styled-components"
import RightSideBar from "./RightSideBar"
import LitOnlearning from "../../images/lit.png"

const LoginBox = styled.div`
  padding: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`

const EsqueciSenha = () => {
  return (
    <div id="login" className="container-fluid">
      <div className="row">
        <div className="col-md-7 d-none d-md-flex bg-loginLeft big">
          <img src={LitOnlearning} className="logo" alt="LIT" />
        </div>
        <div
          className="col-md-5"
          style={{
            background: "#fff",
          }}
        >
          <LoginBox>
            <RightSideBar />
          </LoginBox>
        </div>
      </div>
    </div>
  )
}

export default memo(EsqueciSenha)
