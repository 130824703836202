import React, { memo } from 'react';

import Tooltip from '../Tooltip';
import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';

import LaneNewCoursesInProgress from './LaneNewCoursesInProgress';

// import PaulBar from '../Home/PaulBar';
import FooterHome from '../Home/FooterHome';
import SubFooter from '../Home/SubFooter';

const Cursos = () => {
  // const showPaul = JSON.parse(localStorage.getItem('showPaul'), false);

  return (
    <>
      <Tooltip id="home" />
      <NavBarSuperior home courses reviewSmartBar />

      <LaneNewCoursesInProgress />

      {/* {showPaul && <PaulBar />} */}
      <FooterHome />
      <SubFooter />
    </>
  );
};

export default memo(Cursos);
