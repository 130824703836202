import React, { Component } from "react"
import styled from "styled-components"
import api from "../../utils/api"
import Pagination from "../Pagination"
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import CardMeuCurso from "./CardMeuCurso"

const WrapMyCourses = styled.div`
  overflow-x: hidden;
  padding-top: 60px;
`
const MyCoursesTitle = styled.h6`
  font-size: 1.65em;
  padding: 10px;
`

const MyCouses = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 10px;
`

class AllCourses extends Component {
  state = {
    courses: [],
    page: 1,
    size: 20,
    totalPages: 1,
  }

  componentDidMount() {
    this.getCourses()
  }

  getCourses = async () => {
    const { page, size } = this.state
    const url = `courses?page=${page - 1}&size=${size}`

    try {
      const response = await api.get(url)
      this.setState({ courses: response.returnObject, totalPages: response.totalPages })
    } catch (error) {
      console.log(error.response)
    }
  }

  onPageChanged = data => {
    const { page } = this.state
    const newPage = data.currentPage
    if (newPage !== page) {
      this.setState({ page: newPage }, this.getCourses)
    }
  }

  renderPagination = (totalPages, page) => {
    return (
      <div className="d-flex flex-row align-items-center justify-content-end">
        <Pagination
          noWidth
          totalRecords={totalPages}
          pageLimit={10}
          pageNeighbours={1}
          page={page}
          onPageChanged={this.onPageChanged}
        />
      </div>
    )
  }

  render() {
    const { courses, totalPages, page } = this.state
    return (
      <div>
        <NavBarSuperior />
        <WrapMyCourses>
          <MyCoursesTitle>Todos os Cursos e Trilhas</MyCoursesTitle>
          {courses.length > 0 && this.renderPagination(totalPages, page)}
          <MyCouses>
            {!courses && <h5>Nenhum curso encontrado para exibição</h5>}

            {courses.length
              ? courses.map(curso => {
                  return (
                    <CardMeuCurso
                      key={`todos-cursos-${curso.idCourse}`}
                      id={curso.idCourse}
                      nome={curso.curso}
                      freeCourse={curso.freeCourse}
                      notProgress
                    />
                  )
                })
              : null}
          </MyCouses>
          {courses.length > 0 && this.renderPagination(totalPages, page)}
        </WrapMyCourses>
      </div>
    )
  }
}

export default AllCourses
