import { Modal } from 'antd';
import _ from 'lodash';

import { GATEWAY_URL } from '../../../utils/constants';

class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.url = `${GATEWAY_URL}/media/media/upload`;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    const token = localStorage.getItem('@Lit/token');
    xhr.open('POST', this.url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const { xhr } = this;
    const { loader } = this;

    xhr.addEventListener('error', () => {
      Modal.error({
        title: `Atenção.`,
        content: 'Não foi possível fazer o upload da imagem.',
        onOk: () => {},
      });
      return reject();
    });
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const { response, status } = xhr;
      if (!response || response.error || status === 400 || status === 401) {
        Modal.error({
          title: `Atenção.`,
          content: 'Não foi possível fazer o upload da imagem.',
          onOk: () => {},
        });
        return reject();
      }
      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: _.get(response, 'ox_specific.wu_url', null),
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  async _sendRequest() {
    const data = new FormData();
    const file = await this.loader.file;
    data.append('file', file);
    data.append('mediaType', 'IMAGE');
    this.xhr.send(data);
  }
}

export default UploadAdapter;
