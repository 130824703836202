import React, { Component } from "react"
import Img from "react-image"
import PropTypes from "prop-types"
import placeholder from "../../images/placeholder-group.png"
import { API_URL_IMAGES } from "../../utils/constants"
import Tooltip from "../Tooltip"
import api from "../../utils/api"

class AvatarUsuarioGroupDetail extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  constructor(props) {
    super(props)

    this.state = {
      detail: [],
    }
  }

  componentDidMount = () => {
    this.fetchDetailGroup()
  }

  fetchDetailGroup = async () => {
    const { idProfile, idGroup } = this.props
    try {
      const response = await api.get(`getNumberMembersOfGroup/${idProfile}/${idGroup}`)
      this.setState({ detail: response })
    } catch (error) {
      console.log(error)
    }
  }

  enterGroup = id => {
    const { router } = this.context
    router.history.push(`/grupo/${id}`)
  }

  render() {
    const { name, idGroup, privacity } = this.props
    const { detail } = this.state

    let textMembers
    if (detail.qtdMembers > 1) {
      textMembers = `${detail.qtdMembers} membros`
    } else if (detail.qtdMembers === 0) {
      textMembers = "Sem membros"
    } else {
      textMembers = "1 membro"
    }

    return (
      <li className="list-group-item" style={{ border: "none", display: "flex", alignItems: "center" }}>
        <div role="button" onClick={() => this.enterGroup(idGroup)}>
          <a data-for="avatarGroupOwner" data-tip={name}>
            <Img
              className="img-thumbnail img-fluid mr-2 cover-fit"
              style={{ width: 70, height: 70 }}
              src={[`${API_URL_IMAGES}images/groups/${idGroup}.jpg`, placeholder]}
            />
          </a>
        </div>
        <Tooltip id="avatarGroupOwner" />
        <div className="group-desc">
          <div role="button" onClick={() => this.enterGroup(idGroup)}>
            <a
              style={{
                color: "#27aae1",
                fontWeight: 700,
                fontSize: "14px",
              }}
              data-for="avatarGroupOwner"
              data-tip={name}
            >
              {name}
              {privacity && (
                <i
                  data-for="avatarGroupOwner"
                  data-tip="Grupo privado"
                  className="fa fa-lock"
                  style={{ color: "red", marginLeft: 5 }}
                />
              )}
            </a>
          </div>
          <div className="experiences networking">
            <span className="padding-0">Administrador</span>
            <span className="padding-0">{textMembers}</span>
          </div>
        </div>
      </li>
    )
  }
}

export default AvatarUsuarioGroupDetail
