import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import ReactCodeInput from 'react-code-input';
import { withRouter } from 'react-router-dom';
import {
  formatEmailSameLength,
  setSEOIndexOff,
  toastDefaultOptions
} from '../../utils/functions';
import adsTracking from '../../utils/adsTracking';
import api, { gateway } from '../../utils/api';
import RetornoLogin from './RetornoLogin';
import { LitTitle } from '../shared/LitTitle';
import { LitText } from '../shared/LitText';
import { LitButton } from '../shared/LitButton';

const LoginHeader = styled(LitTitle)`
  font-family: 'Dosis', sans-serif;
  font-size: 2.2em;
  margin-bottom: 10px !important;
  font-stretch: condensed;
  color: #27aae1;
`;

const Wrapper = styled.div`
  max-width: 100%;
`;

class RightSideBar extends Component {
  state = {
    code: '',
    error: '',
    loading: false,
    isRender: true,
    hasOrigin: false
  };

  componentDidMount() {
    const path = window.location.pathname.split('/');
    const code = path[2];

    if (code !== 'confirmarEmail') {
      this.setState({ code }, this.confirmEmail);
    }
    this.setState({ isRender: false });
    setSEOIndexOff();

    this.getUrlParams();
  }

  getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    const plans = params.get('plans');
    if (plans) {
      localStorage.setItem('urlPlansParam', plans);
    }

    const origemParam = params.get('o');
    const loginTenant = params.get('tenant');
    if (loginTenant) {
      localStorage.setItem('loginTenant', loginTenant);
    }
    if (origemParam) {
      localStorage.setItem('urlOriginParam', origemParam);
      localStorage.setItem('urlRedirect', origemParam);
      this.setState({ hasOrigin: true });
    }
  };

  setItensLocalStorage = dados => {
    localStorage.setItem('ra', dados.ra);
    localStorage.setItem('idProfile', dados.idProfile);
    localStorage.setItem('voucherAtivo', dados.voucherAtivo);
    localStorage.setItem('finishOnBoarding', false);
    localStorage.setItem('57e0854551b1dca003c67c384c37a346', false);
    localStorage.setItem('firstName', dados.firstName);
    localStorage.setItem('lastName', dados.lastName);
    localStorage.setItem('idMember', dados.groupMember);
    localStorage.setItem('idOwner', dados.ownerGroup);
    localStorage.setItem('idRA', dados.idRA);
    localStorage.setItem('idLogin', dados.idLogin);
    localStorage.setItem('freeUser', dados.freeUser);
    localStorage.setItem('libraryUser', dados.libraryUser);
    localStorage.setItem('socialUser', dados.socialUser);
    localStorage.setItem('completedPersonality', dados.completedPersonality);
    localStorage.setItem('modalNotas', dados.messagesCount > 0);
    localStorage.setItem('showPaul', dados.showPaul);
    localStorage.setItem(
      'dateLogin',
      `${new Date().getDate()}/${new Date().getMonth()}`
    );
  };

  confirmEmail = async e => {
    if (e) {
      e.preventDefault();
    }
    const { code, hasOrigin } = this.state;
    const { history } = this.props;
    if (!code) {
      this.notify('Digite o código');
      return;
    }
    this.setState({ loading: true });

    const email = localStorage.getItem('ra');
    const url = `/webbff/lit_app_web/auth/receiveConfirmation/${code}${hasOrigin ? '?hasOrigin=true' : ''}`;
    try {
      const response = await gateway.post(url);
      if (!email) {
        const dados = await api.get('/me');
        this.setItensLocalStorage(dados);
      }
      const firstLogin = JSON.parse(localStorage.getItem('firstLogin'));
      const voucherAtivo = JSON.parse(localStorage.getItem('voucherAtivo'));

      let activeContract = false;
      if (localStorage.getItem('activeContract') !== 'undefined') {
        activeContract = JSON.parse(localStorage.getItem('activeContract'));
      }
      this.notify(response.message);
      if (response.status === 200) {
        adsTracking.sendGoogleAnalyticsEcommerceEvent({
          step: adsTracking.STEP2_EMAIL_CONFIRMATION
        });
        if (response.email) {
          if (!activeContract) {
            history.push('/consultaCpf');
          } else if (firstLogin) {
            history.push('/onboarding');
          } else {
            history.push('/home');
          }
        } else {
          setTimeout(() => {
            history.push('/');
          }, 1000);
        }
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      this.notify(error.response.data.message);
    }
  };

  notify = (text, timeClose = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: timeClose
    });
  };

  render() {
    const ra = localStorage.getItem('ra');
    const email = ra ? formatEmailSameLength(ra) : null;

    const { code, loading, error, isRender } = this.state;
    return (
      <Fragment>
        <LoginHeader>Confirmação de email</LoginHeader>
        <Wrapper>
          <div className="criar-conta">
            <LitText>
              Por favor, verifique sua caixa de entrada ou spam para confirmar
              seu endereço de email. Clique no link no email ou cole abaixo o
              código de confirmação.
            </LitText>
            {email && <LitText>Email enviado para: {email}</LitText>}
          </div>
          <form style={{ marginTop: '2em' }} onSubmit={this.confirmEmail}>
            <div className="row justify-content-center align-items-center">
              <label htmlFor="inputcodigoEmail">
                <LitText>Código de confirmação</LitText>
              </label>
            </div>
            <div className="row justify-content-center align-items-center">
              {!isRender && (
                <ReactCodeInput
                  forceUppercase
                  id="inputcodigoEmail"
                  onChange={evt => this.setState({ code: evt })}
                  type="text"
                  value={code}
                  fields={8}
                />
              )}
            </div>

            <div className="row">
              <div className="col">
                <LitText style={{ color: '#e50000' }}>{error}</LitText>
              </div>
            </div>

            <div className="row justify-content-center">
              <LitButton
                type="submit"
                disabled={loading}
                style={{
                  textTransform: 'uppercase'
                }}
                className={`${loading && ' btn-loading'}`}
              >
                Confirmar
              </LitButton>
            </div>
          </form>
          <RetornoLogin notif={this.notify} />
        </Wrapper>
      </Fragment>
    );
  }
}

export default withRouter(RightSideBar);
