import { Types } from "./types"

/**
 * Actions
 */
export const Creators = {
  setSelectedPlan: data => ({
    type: Types.SET_SELECTED_PLAN,
    payload: { data },
  }),
  updateInstallmentSelectedPlan: data => ({
    type: Types.UPDATE_INSTALLMENTS_SELECTED_PLAN,
    payload: { data },
  }),
}
