/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import React, {Component} from 'react'
import { connect } from "react-redux"
import Switch from "react-switch"

import { bindActionCreators } from "redux"
import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions"
import {saveOnboardingSteps} from '../../../../business/onboarding'


import {
  Container, 
  Title, 
  Description, 
  ContainerForm, 
  BoxQuestions,
  BoxButtons,
  Button
} from './styles'

class OnboardingPagePrivacy extends Component {

  constructor(props) {
    super(props)

    const {data: {ox_userprivacy : privacyData}} = props
    this.state = {
      bf_address: privacyData && privacyData.bf_address ? privacyData.bf_address : false,
      bf_neighborhood: privacyData && privacyData.bf_neighborhood ? privacyData.bf_neighborhood : false,
      bf_city: privacyData && privacyData.bf_city ? privacyData.bf_city : false,
      bf_country: privacyData && privacyData.bf_country ? privacyData.bf_country : false,
      bf_zipcode: privacyData && privacyData.bf_zipcode ? privacyData.bf_zipcode : false,
      bf_about: privacyData && privacyData.bf_about ? privacyData.bf_about : false,
      bf_academic: privacyData && privacyData.bf_academic ? privacyData.bf_academic : false,
      bf_professional: privacyData && privacyData.bf_professional ? privacyData.bf_professional : false,
      bf_office: privacyData && privacyData.bf_office ? privacyData.bf_office : false,
      bf_groupsowner: privacyData && privacyData.bf_groupsowner ? privacyData.bf_groupsowner : false,
      bf_groupsmember: privacyData && privacyData.bf_groupsmember ? privacyData.bf_groupsmember : false,
      showAbout: false,
      showAddress: false
    };
  }

  saveForm = async () => {
    
    const {
      bf_academic,
      bf_professional,
      bf_office,
    } = this.state

    const data = {
      wx_step: "PRIVACY",
      ox_formdata: {
        bf_address: false,
        bf_neighborhood: false,
        bf_city: false,
        bf_country: false,
        bf_zipcode: false,
        bf_about: false,
        bf_academic,
        bf_professional,
        bf_office
      }
    }

    this.saveDataInState(true)
    await saveOnboardingSteps(data)

  };

  saveDataInState = (concluded = false) => {
    const {
      bf_academic,
      bf_professional,
      bf_office
    } = this.state
    const {index, onboardingData, setOnbordingPageConcluded} = this.props;
    const dataActiveStep = Object.assign({}, onboardingData[index]);
    dataActiveStep.ox_userprivacy = {
      bf_address: false,
      bf_neighborhood: false,
      bf_city: false,
      bf_country: false,
      bf_zipcode: false,
      bf_about: false,
      bf_academic,
      bf_professional,
      bf_office,
    }
    setOnbordingPageConcluded(index, dataActiveStep, onboardingData[index].bf_concluded || concluded)
  }



  render() {
    const {
      bf_address,
    
      bf_about,
      bf_academic,
      bf_professional,
      bf_office,
      showAbout,
      showAddress
      
    } = this.state
    const {index} = this.props
    return(
      <Container className="container">
        <Title> {index + 1}. Privacidade</Title>
        <Description>
          Escolha quais campos os demais alunos podem visualizar quando interagem com você!
        </Description>
        <ContainerForm>
          <div className="row">
            <div className="col-md-12">
              {showAbout && (
                <BoxQuestions first>
                  <span>Sobre:</span>
                  <BoxButtons>
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <Button 
                        type="button" 
                        className="btn"
                        id='button-component-not-about'
                        active={!bf_about}
                        onClick={() => {
                          this.setState({bf_about: false})
                        }}
                      >
                          Privado
                      </Button>
                      <Button 
                        id='button-component-yes-about'
                        type="button" 
                        className="btn"
                        active={!!bf_about}
                        onClick={() => {
                          this.setState({bf_about: true})
                        }}
                      >
                        Público
                      </Button>
                    </div>
                  </BoxButtons>
                </BoxQuestions>
              )}
            
              <BoxQuestions first>
                <span>Acadêmico:</span>
                <BoxButtons>
                  <div className="btn-group" role="group">
                    <Button 
                      type="button" 
                      className="btn"
                      id='button-component-not-academic'
                      active={!bf_academic}
                      onClick={() => {
                        this.setState({bf_academic: false})
                      }}
                    >
                        Privado
                    </Button>
                    <Button 
                      id='button-component-yes-academic'
                      type="button" 
                      className="btn"
                      active={!!bf_academic}
                      onClick={() => {
                        this.setState({bf_academic: true})
                      }}
                    >
                      Público
                    </Button>
                  </div>
                </BoxButtons>
              </BoxQuestions>  
              <BoxQuestions>
                <span>Perfil profissional:</span>
                <BoxButtons>
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <Button 
                      type="button" 
                      className="btn"
                      id='button-component-not-professional'
                      active={!bf_professional}
                      onClick={() => {
                        this.setState({bf_professional: false})
                      }}
                    >
                        Privado
                    </Button>
                    <Button 
                      id='button-component-yes-professional'
                      type="button" 
                      className="btn"
                      active={!!bf_professional}
                      onClick={() => {
                        this.setState({bf_professional: true})
                      }}
                    >
                      Público
                    </Button>
                  </div>
                </BoxButtons>
              </BoxQuestions> 
              <BoxQuestions>
                <span>Cargo:</span>
                <BoxButtons>
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <Button 
                      type="button" 
                      className="btn"
                      id='button-component-not-bf_office'
                      active={!bf_office}
                      onClick={() => {
                        this.setState({bf_office: false})
                      }}
                    >
                        Privado
                    </Button>
                    <Button 
                      id='button-component-yes-bf_office'
                      type="button" 
                      className="btn"
                      active={!!bf_office}
                      onClick={() => {
                        this.setState({bf_office: true})
                      }}
                    >
                      Público
                    </Button>
                  </div>
                </BoxButtons>
              </BoxQuestions>
              {showAddress && (
                 <BoxQuestions>
                    <span>Endereço:</span>
                    <BoxButtons>
                      <div className="btn-group" role="group" aria-label="Basic example">
                        <Button 
                          type="button" 
                          className="btn"
                          id='button-component-not-address'
                          active={!bf_address}
                          onClick={() => {
                            this.setState({
                              bf_address: false,
                              bf_neighborhood: false,
                              bf_city: false,
                              bf_country: false,
                              bf_zipcode: false,
                            })
                          }}
                        >
                            Privado
                        </Button>
                        <Button 
                          id='button-component-yes-address'
                          type="button" 
                          className="btn"
                          active={!!bf_address}
                          onClick={() => {
                            this.setState({
                              bf_address: true,
                              bf_neighborhood: true,
                              bf_city: true,
                              bf_country: true,
                              bf_zipcode: true,
                            })
                          }}
                        >
                          Público
                        </Button>
                      </div>
                    </BoxButtons>
                 </BoxQuestions> 
              )} 
            </div>
          </div>
        </ContainerForm>
      </Container>
    )
  }
}


const mapStateToProps = state => ({
  onboardingData: state.onboarding.onboardingData,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...OnboardingActions
}, dispatch)


export default connect(
    mapStateToProps, 
    mapDispatchToProps, 
    null, 
    {
      forwardRef: true
    }
)(OnboardingPagePrivacy)
