import React from "react"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { UserActions } from "../../../store/ducks/user"
import { SocialActions } from "../../../store/ducks/social"
import NavBarSuperior from "../../NavBarSuperior/NavBarSuperior"
import Perfil from "../Perfil"
import Sugestoes from "./Sugestoes"
import MeusGrupos from "./MeusGrupos"
import Solicitacoes from "./Solicitacoes"

import ModalVideo from "../../ModalVideo"

const IMG_SUGGESTION_OFF = require("../../../images/sugestao_off.png")

const naRealUser = JSON.parse(localStorage.getItem('naRealUser'));

class Timeline extends React.Component {
  componentDidMount() {
    const socialUser = JSON.parse(localStorage.getItem("socialUser"))
    const { toggleModalRestrict, toggleModalRestrictLitPass } = this.props
    if (socialUser) {
      this.fetchAll()
    } else if (naRealUser) {
      toggleModalRestrict(true);
    } else {
      toggleModalRestrictLitPass(true);
    }
  }

  fetchAll = () => {
    const { getMyGroupsRequest, getPendingInvitationsRequest, getRecommendedGroupsRequest } = this.props
    const idProfile = localStorage.getItem("idProfile")
    getMyGroupsRequest(idProfile)
    getPendingInvitationsRequest(idProfile)
    getRecommendedGroupsRequest(idProfile)
    // this.props.dispatch(fetchRecommendedGroups(localStorage.getItem("idProfile")))
  }

  render() {
    const { myGroups, pendingInvitations, recommendedGroups } = this.props;
    const tenant = localStorage.getItem("tenant");
    return (
      <div id="container">
        <NavBarSuperior />
        <div className="container-fluid" id="social">
          <div className="row">
            <div className="col-xl-2 col-md-3">
              <Perfil />
            </div>
            <div className="col-md-7">
              {pendingInvitations.data.length > 0 && <Solicitacoes openInvites={pendingInvitations} />}
              <MeusGrupos grupos={myGroups} />
            </div>
            <div className="col-md-2 col-xl-3">
              <div className="card">
                <div className="card-header with-flex">
                  <span className="titulo" data-tip="Grupos que possam ser do seu interesse">
                    <img
                      alt="Sugestoes de grupo"
                      src={IMG_SUGGESTION_OFF}
                      width={25}
                      style={{ marginRight: 5 }}
                    />
                    Sugestões de grupo
                  </span>
                </div>
                <div className="card-body" style={{ padding: 0 }}>
                  <Sugestoes grupos={recommendedGroups} updateAll={this.fetchAll} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {tenant === 'LIT_BUSINESS' && <ModalVideo indexTutorial={5} modal titleModal="Grupos de discussão / Comunidades de práticas" />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    myGroups: state.social.myGroups,
    pendingInvitations: state.social.pendingInvitations,
    recommendedGroups: state.social.recommendedGroups,
  }
}

const mergeActions = {
  ...SocialActions.myGroupsActions,
  ...SocialActions.pendingInvitationsActions,
  ...SocialActions.recommendedGroupsActions,
  ...UserActions.ProfileActions,
}

const mapDispatchToProps = dispatch => bindActionCreators(mergeActions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timeline)
