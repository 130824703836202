import { Types } from "./types"
/**
 * Reducers
 */

const INITIAL_STATE = {
  active: false,
  installments: 0,
  totalPriceFromInstallment: 0
}

export default function installment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_INSTALLMENT_VALUE:
      return {
        ...state,
        active: action.payload.data.active,
        installments: action.payload.data.installments,
        totalPriceFromInstallment: action.payload.data.totalPriceFromInstallment,
        price: action.payload.data.price
      }
    default:
      return state
  }
}
