import styled from "styled-components";

export const CouponContainer = styled.div`
  width: 100%;
  text-align: center;
`
export const ContainerError = styled.div`
  text-align: center;
  p {
    font-size: 16px;
  }
`

export const RecommendationsTitleBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
`

export const TitleRecommendations = styled.p`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight:bold;
`
export const ButtonCollapse = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  text-decoration: none;
  padding-left: 0;
  &:hover {
    text-decoration:none;
  }
  &:active, &:focus {
    box-shadow: none;
    outline: none;
    text-decoration:none;
  }

  div span {
    font-weight: bold;
  }

  i {
    color: #27aae1;
  }
`
