import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} useful //defines whether the content has been marked as useful
**/

export default ({
  useful = DEFAULT_VALUES.BOOLEAN
}) => {
  return (
    <>
      <Screen
        useful={useful}
      />
    </>
  );
};
