import styled from "styled-components";
import { } from "../../../constants";

export const ModalBody = styled.div.attrs({
  className: "modal-body"
})`
  padding: 2rem;
  margin: 1rem 0;
  position: relative;
`;

export const Info = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  margin: 0 0 2rem;
  p {
    line-height: 1;
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 1060px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
