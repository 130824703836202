import React, {Component} from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions"


import history from '../../../../utils/history'


import {
  Container, 
  Title,
  ContainerButtonPersonalize,
  PersonalizeButton
} from './styles'

class OnboardingPagePersonalize extends Component {

  constructor(props) {
    super(props)
 
    this.state = {
      
    }
  }

  saveForm = async () => {
   
  };

  saveDataInState = (concluded = false) => {
    console.log('Save')
  }

  navigate = (url) => {
    history.push(url)
  }

  render() { 
    return(
      <Container className="container">
        <Title>Personalize como aprender melhor</Title>
        <div className="row">
          <div className="col-md-12 objetivos-personalizar--desc-usuario">
           
            <h5 style={{ color: "#27aae1" }}>Esse é último e mais importante passo da sua personalização inicial no LIT.</h5>
        
            <p>
              Queremos te ajudar a aprender mais, mais rápido e melhor! Para adaptar sua experiência de aprendizagem no LIT, 
              é fundamental conhecermos você melhor.
            </p>
         
            <p>
              Você só precisa fazer essa análise uma vez! Após esse passo você terá três resultados incríveis:
            </p>

            <ol>
              <li>Um relatório completo do seu perfil</li>
              <li>Relatório com estratégias de aprendizagem mais efetivas para você</li>
              <li>Ordenação das pílulas de aprendizagem da plataforma de acordo com o seu perfil, por exemplo, se você aprende melhor ao assistir a um vídeo ou ao ler um texto</li>
            </ol>

            <p>
              
            </p>
           
            <p>
              A forma que traçamos seu perfil de aprendizagem é por meio da análise de textos de sua autoria, aplicados em inteligência artificial. Quanto mais textos você colocar, melhor será o resultado da análise. Podem ser textos que você já escreveu, como e-mails, whatsapp e posts, com conteúdos pessoais e profissionais. Estimamos que você demore 10 minutos para finalizar esse passo e com isso o LIT já estará personalizado para você!
            </p>
           
            <p style={{ fontSize: "14px" }}>
            Importante: as informações fornecidas nesta análise não serão usadas para outras finalidades, além dessa personalização.
            </p>
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  onboardingData: state.onboarding.onboardingData,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...OnboardingActions
}, dispatch)


export default connect(
    mapStateToProps, 
    mapDispatchToProps, 
    null, 
    {
      forwardRef: true
    }
)(OnboardingPagePersonalize)
