import React, { Component } from "react"

import Loading from "../Loading/Loading"
import AvatarNetworkRightSideBar from "../AvatarUsuarios/AvatarNetworkRightSideBar"
import AvatarUsuarioGroup from "../AvatarGrupos/AvatarUsuarioGroup"

import { randomNumber, headerConfig } from "../../utils/constants"
import api from "../../utils/api"

const IMG_INTERACOES = require("../../images/interacao_off.png")
const IMG_GRUPOS = require("../../images/grupo_off.png")

export default class RightSideBar extends Component {
  state = {
    networkingList: {},
    fetching: false,
  }

  componentDidMount() {
    const { idProfile } = this.props
    const id = idProfile || localStorage.getItem("idProfile")
    this.fetchNetworking(id)
  }

  fetchNetworking = async idProfile => {
    this.setState({ fetching: true })
    try {
      const response = await api.get(`getSocialNetworkingByProfile/${idProfile}`, {
        headers: headerConfig.headers,
      })
      this.setState({ networkingList: response, fetching: false })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { fetching, networkingList } = this.state
    const { idProfile } = this.props
    if (fetching) {
      return <Loading width={50} />
    }

    return (
      <div className="col-md-3">
        <div className="card">
          <div className="card-header with-flex">
            <span className="titulo">
              <img src={IMG_INTERACOES} width={25} alt="off" style={{ marginRight: 5 }} />
              Últimas interações
            </span>
          </div>
          <div className="card-body" style={{ padding: 0 }}>
            <ul className="list-group">
              {networkingList.length > 0 &&
                networkingList.map((perfil, index) => {
                  if (index > 3) return null
                  return <AvatarNetworkRightSideBar key={randomNumber()} perfil={perfil} />
                })}
            </ul>
          </div>
        </div>
        <div className="card mt-3 mb-3">
          <div className="card-header with-flex">
            <span className="titulo">
              <img src={IMG_GRUPOS} width={25} alt="off" style={{ marginRight: 5 }} />
              Grupos
            </span>
          </div>
          <div className="card-body" style={{ padding: 0, maxHeight: 425, overflowY: "auto" }}>
            <AvatarUsuarioGroup idProfile={idProfile || localStorage.getItem("idProfile")} />
          </div>
        </div>
      </div>
    )
  }
}
