import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/** 
 * @param {Component} children //components that will be rendered on the page
 * @param {String} id //key that triggers the toggle on the component
 * @param {String} appearance //{POST} defines the appearance of the component
*/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
  id = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        child={children}
        id={id}
        appearance={appearance}
      />
    </>
  );
};
