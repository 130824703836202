import React, { useCallback } from "react";
import { SIZE, APPEARANCE, ACTION, NUMBER, STATUS } from "../../../constants";

import OverflowContent from "../../basicComponents/OverflowContent";
import ListRenderer from "../../ListRenderer";
import Spacing from "../../basicComponents/Spacing";
import Actions from "../../Actions";
import InviteMember from "../../Info/DataInfo";
import Search from "../../Inputs/Search";
import { DEFAULT_VALUES } from "../../../../utils/constants";
import _ from "lodash";

import { ModalBody, Info, List } from "./styles";

export default ({
  loadMore = DEFAULT_VALUES.FUNCTION,
  members = DEFAULT_VALUES.LIST,
  action = DEFAULT_VALUES.FUNCTION,
  actionSearch = DEFAULT_VALUES.FUNCTION
}) => {
  const [inviteds, setInviteds] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");

  function sendQuery(query) {
    if (query) actionSearch(query);
  }

  const delayedQuery = useCallback(
    _.debounce(q => sendQuery(q), 1000),
    []
  );
  const onChange = e => {
    delayedQuery(e.target.value);
    setSearchString(e.target.value);
  };

  return (
    <ModalBody>
      <Info>
        <Search label={ACTION.SEARCH_MEMBER} onChange={onChange} />
      </Info>

      <Spacing size={SIZE.MEDIUM} />
      <OverflowContent onBottom={() => loadMore(searchString)}>
        <List>
          <ListRenderer
            grid={NUMBER.ONE}
            componentToRender={members.map(member => {
              const canInvite = inviteds.find(code => code === member.cr_code)
                ? false
                : true;

              return (
                <InviteMember
                  id={member.id}
                  name={member.name}
                  img={member.img}
                  link={`/beta/social/externalgroup/${member.cr_code}`}
                  listButtons={STATUS.TRUE}
                  actions={
                    <Actions
                      itemsToRender={[
                        {
                          type: "button",
                          action: canInvite
                            ? () => {
                                setInviteds([...inviteds, member.cr_code]);
                                action(member.cr_code);
                              }
                            : null,
                          buttonText: canInvite ? ACTION.INVITE : ACTION.GUEST,
                          appearance: canInvite
                            ? APPEARANCE.PRIMARY
                            : APPEARANCE.OUTLINE_PRIMARY,
                          size: SIZE.SMALL
                        }
                      ]}
                    />
                  }
                />
              );
            })}
          ></ListRenderer>
        </List>
      </OverflowContent>
    </ModalBody>
  );
};
