import React from "react";
import { Limit } from "./styles";

export default ({ child, orientation, size, onBottom = () => {} }) => {
  return (
    <Limit onScroll={(event) => {
      const target = event.target;

      if(target.scrollHeight - target.scrollTop == target.clientHeight){
        onBottom();
      }
      
    }} orientation={orientation} size={size}> {child} </Limit>
  );
};
