import styled from 'styled-components';

export const Container = styled.div`

    background-color: ${props => props.isActive ? '#d7f0fc' : 'transparent'} !important;
    color: ${props => props.isActive ? '#27aae1' : 'black'} !important;
    cursor: pointer;
    
`
export const ContentItem = styled.li`
    font-size: 14;
    padding: 5px 15px;
`
export const ContentItemChild = styled.div`
    cursor: pointer;
`
export const ContainerIcon = styled.div`
    padding: 6px 20px;
    color: #27AAE1;
    font-size: 18px;
`