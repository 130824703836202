import React, { useState, useEffect } from "react";
import { ICON, COLOR, SIZE, APPEARANCE, BORDER_TYPE } from "../../../constants";
import TextArea from "../TextArea";
import Button from "../../basicComponents/Button";
import Image from "../../basicComponents/Image";
import getFiles from "../../../../utils/getFiles";
import Icon from "../../basicComponents/Icon";
import Archives from "../../Archives";
import { CommentBox, Agrupable } from "./styles";
import Loading from "../../../../Components/Loading/Loading";

export default ({
  image,
  placeholder,
  size,
  sendAction,
  isEdit = false,
  attachs = [],
  appearance,
  onCloseCommentBox,
  isLoading = false
}) => {
  const [newCommentContent, setNewCommentContent] = useState();
  const [files, setFiles] = useState([]);
  const [currentFiles, setCurrentFiles] = useState(attachs);
  const [removedFiles, setRemovedFiles] = useState([]);

  async function getPostFiles() {
    const files = await getFiles(true);
    setFiles(files);
  }

  function removeAttach(file) {
    const removeds = removedFiles;
    removeds.push({ id: file.id });
    setRemovedFiles(removeds);
    setCurrentFiles(currentFiles.filter(itemFile => itemFile.id != file.id));
  }

  function removeNewFile(file) {
    setFiles(files.filter(itemFile => itemFile.name != file.name));
  }

  return (
    <>
      <Agrupable appearance={appearance}>
        <CommentBox>
          {image && (
            <Image
              src={image}
              appearance={APPEARANCE.AVATAR}
              border={BORDER_TYPE.BORDER}
            />
          )}
          <TextArea
            value={newCommentContent}
            onChange={e => setNewCommentContent(e.target.value)}
            handleChange={e => setNewCommentContent(e.target.value)}
            placeholder={placeholder}
            size={size}
          ></TextArea>
          <Icon
            icon={ICON.PAPER_CLIP}
            color={COLOR.TEXT_PRIMARY}
            size={SIZE.MEDIUM}
            onClick={getPostFiles}
          />
          {isLoading 
            ? <div style={{ right: -35, position: 'absolute' }}>
                <Loading img width={20} height={20} />
              </div>
            : <Button
                icon={ICON.SEND}
                color={COLOR.TEXT_PRIMARY}
                appearance={APPEARANCE.LINK}
                onClick={() => {
                  if (!newCommentContent && !removedFiles.length && !files.length)
                    return;

                  if (isEdit)
                    sendAction(
                      newCommentContent ? newCommentContent : placeholder,
                      files,
                      removedFiles
                    );
                  else
                    sendAction(
                      newCommentContent ? newCommentContent : placeholder,
                      files
                    );

                  setFiles([]);
                  setRemovedFiles([]);
                  setNewCommentContent("");
                }}
              />
          }
          {!isLoading && onCloseCommentBox && (
            <button className="closeComment" type="button" onClick={onCloseCommentBox}>
              <i className="fa fa-close"></i>
            </button>
          )}
        </CommentBox>
        {files && files.length ? (
          <Archives
            archives={files}
            color={COLOR.BLOCK_ATTACHMENT_CREATE}
            removeAction={removeNewFile}
          />
        ) : (
          <></>
        )}
        {currentFiles && currentFiles.length ? (
          <Archives
            archives={currentFiles}
            color={COLOR.BLOCK_ATTACHMENT_CREATE}
            removeAction={removeAttach}
          />
        ) : (
          <></>
        )}
      </Agrupable>
    </>
  );
};
