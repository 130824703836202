import React, { PureComponent } from "react"
import styled from "styled-components"
import { setSEOIndexOff } from "../../utils/functions"
import NovaSenha from "./NovaSenha"

const LoginHeader = styled.h3`
  font-family: "Dosis", sans-serif;
  font-size: 2.2em;
  font-weight: lighter;
  margin-bottom: 10px !important;
  font-stretch: condensed;
  color: #27aae1;
`

const Wrapper = styled.div`
  max-width: 100%;
`

class RightSideBar extends PureComponent {
  state = {
    email: "",
    error: "",
    emailEnviado: false,
    loading: false,
  }

  componentDidMount() {
    setSEOIndexOff()
  }

  render() {    
    return (
      <React.Fragment>
        <LoginHeader>Cadastrar Senha</LoginHeader>
        <Wrapper>
          <div className="criar-conta">
            <p style={{ fontSize: "14px" }}>
              Digite-o no campo abaixo juntamente com sua nova senha e
              volte a ter acesso a plataforma LIT.
            </p>
          </div>
          <NovaSenha />
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default RightSideBar
