import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} itemsToRender //components that will be rendered within the side space
**/

export default ({
  itemsToRender = DEFAULT_VALUES.COMPONENT
}) => {
  return (
    <>
      <Screen
        itemsToRender={itemsToRender}
      />
    </>
  );
};
