import React from "react";
import _ from "lodash";
import Screen from "./screen";

export default ({ pageImage, members, group, member, callback }) => {
  return (
    <>
      <Screen group={group} callback={callback} groupedList={members} pageImage={pageImage} member={member} />
    </>
  );
};
