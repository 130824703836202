import styled from "styled-components";
import FlatList from "flatlist-react";
import { } from "../../constants";

export const UnorderedList = styled.ul``;

export const List = styled(FlatList)``;

export const MessageNoDataDiv = styled.div`
  padding: 15px 0;
`;
