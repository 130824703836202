import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Simplert from 'react-simplert';
import { toast } from 'react-toastify';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { UserActions } from '../../store/ducks/user';
import { Creators as AlertActions } from '../../store/ducks/alert/actions';
import UpdateSubscription from './UpdateSubscription';
import api, { gateway } from '../../utils/api';
import { toastDefaultOptions } from '../../utils/functions';
import ContractData from './components/ContractData';

import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';
import history from '../../utils/history';
import Loading from '../Loading/Loading';

const styles = {
  subtitle: {
    fontWeight: 'bold'
  },
  modalHeader: {
    width: 'auto',
    height: '50px',
    background: 'transparent',
    boxShadow: 'none',
    marginTop: '60px'
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Planos extends Component {
  static contextTypes = {
    router: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.state = {
      userSubscriptions: [],
      showCancel: true,
      modalIsOpen: false,
      protocolo: null,
      modalCancelamento: false,
      inTrial: false,
      dataPayments: {},
      renderComponent: true,
      showForm: false,
      paymentsValues: [],
      maxWidth: 'xl',
      isVisibled: 'none',
      hasPaymentError: false,
      loading: true
    };
    this.sampleStore = {
      planSelected: {}
    };
  }

  async componentDidMount() {
    const { openDirectPayment } = this.props;
    await this.fetchUserContract();
    this.getPaymentsValues();

    if (history.location.state) {
      const { openModal } = history.location.state;
      if (openModal) {
        this.startEdit();
      }
    }

    const planByUrl = localStorage.getItem('planByUrl');
    const hasPaymentError =
      localStorage.getItem('57e0854551b1dca003c67c384c37a346') === 'true';

    if (planByUrl) {
      this.startEdit();
    }

    if (openDirectPayment) {
      this.startEdit();
    }

    if (hasPaymentError) {
      this.setState({
        hasPaymentError: true
      });
      this.startEdit();
    }
  }

  componentWillUnmount() {
    const { removeOpenPlans } = this.props;
    removeOpenPlans();
  }

  getStore() {
    return this.sampleStore;
  }

  startEdit = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      showForm: false
    });
  };

  closeScreen = () => {
    const { setOpenDirectPayment } = this.props;
    setOpenDirectPayment(false);
    this.setState({
      showForm: false
    });
  };

  notify = text => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000
    });
  };

  notifyError = (text, type = 'error') => {
    toast(text, {
      type,
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 4000
    });
  };

  calculateTime = () => {
    const { dataPayments, showCancel } = this.state;
    const { setEndOfMonth } = this.props;
    const dataFinal = moment(dataPayments.dataCobranca, 'DD/MM/YYYY');
    const text = 'Sua próxima cobrança será em';
    dataFinal.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    const duration = moment.duration(moment(dataFinal).diff(moment()));

    if (
      dataPayments.dataCobranca &&
      showCancel &&
      dataPayments.paymentType !== 'FOLHA'
    ) {
      const endOfMonth = `${text} ${parseInt(
        duration.asDays() < 0 ? 0 : duration.asDays(),
        10
      )} dias`;
      setEndOfMonth(endOfMonth);
      this.setState({
        endOfMonth
      });
    } else if (dataPayments.paymentType === 'FOLHA') {
      this.setState({ endOfMonth: 'Desconto em folha' });
      setEndOfMonth('Desconto em folha');
    } else {
      this.setState({ endOfMonth: '' });
      setEndOfMonth('');
    }
  };

  fetchUserContract = async () => {
    try {
      const response = await gateway.get(
        'webbff/lit_app_web/contract/getUserContract'
      );

      this.setState(
        {
          userSubscriptions: response,
          dataPayments: response,
          loading: false
        },
        () => this.calculateTime()
      );

      this.checkAppleIapUser(response);
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  };

  getPaymentsValues = async () => {
    try {
      const response = await api.get('getPaymentTypes');
      this.setState({ paymentsValues: response });
    } catch (error) {}
  };

  checkAppleIapUser = response => {
    if (response && response.appleIapUser) {
      this.setState({ appleIapUser: response.appleIapUser });
    }
  };

  confirmCancelTrial = () => {
    this.setState({ inTrial: false, modalCancelamento: true });
  };

  closeModalCancelamento = () => {
    this.setState({ modalCancelamento: false });
  };

  completedPayment = () => {
    this.sampleStore = {
      planSelected: {}
    };
    localStorage.removeItem('data-card');
    this.closeModal();
  };

  updateStore(update) {
    if (update.freePlan) {
      this.closeModal();
      this.setState({ renderComponent: true });
      this.fetchUserContract();
      return;
    }

    this.sampleStore = {
      planSelected: { ...update.planSelected }
    };

    if (Object.keys(this.sampleStore).length) {
      this.setState({ showForm: true });
    }
  }

  render() {
    const {
      dataPayments,
      modalIsOpen,
      inTrial,
      renderComponent,
      showForm,
      maxWidth,
      loading,
      userSubscriptions
    } = this.state;

    const updateSubscriptionComponent = (
      <UpdateSubscription close={this.closeModal} />
    );
    if (!renderComponent) {
      return (
        <Dialog
          fullScreen
          maxWidth={maxWidth}
          open={modalIsOpen}
          onClose={this.closeModal}
          TransitionComponent={Transition}
          scroll="body"
          aria-labelledby="max-width-dialog-title"
        >
          <AppBar style={styles.modalHeader}>
            <Toolbar
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Icon
                style={{ cursor: 'pointer' }}
                edge="start"
                color="inherit"
                onClick={this.closeModal}
                aria-label="close"
              >
                <CloseIcon />
              </Icon>
            </Toolbar>
          </AppBar>

          <NavBarSuperior noPosition />

          <div className="modal-content" id="pagamento">
            {/* <br /> */}
            <div
              id="modal-plan"
              className="modal-body"
              style={{
                overflow: 'auto',
                marginTop: '30px',
                padding: 0,
                background: 'yellow'
              }}
            >
              <div style={{ height: '100%' }}>
                {showForm ? (
                  updateSubscriptionComponent
                ) : (
                  <div>
                    <UpdateSubscription close={this.closeModal} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      );
    }

    return (
      <main id="planos">
        <div className=" bg-white-perfil">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-12"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <h5
                  className="d-inline-block"
                  style={{ fontSize: '1.5em' }}
                  data-tip="Aqui você pode escolher a forma de pagamento, adicionar voucher de desconto ou cancelar a sua assinatura"
                >
                  Minha assinatura
                </h5>
                <button
                  type="button"
                  className="btn btn-icon ml-auto"
                  onClick={this.startEdit}
                >
                  <i
                    className="fa fa-pencil"
                    aria-hidden="true"
                    style={{ color: '#27aae1' }}
                  />
                </button>
              </div>
            </div>
            {dataPayments && !loading && (
              <div className="row">
                <div className="col-12">
                  <ContractData
                    userSubscriptions={userSubscriptions}
                    refetchContract={this.fetchUserContract}
                  />
                </div>
              </div>
            )}
            {!dataPayments && (
              <>
                {loading ? (
                  <Loading width={40} />
                ) : (
                  <p>Nenhuma assinatura encontrada no sistema.</p>
                )}
              </>
            )}
          </div>
        </div>

        <Simplert
          showSimplert={inTrial}
          type="warning"
          title="Conta em período trial"
          message="Você esta em período de TRIAL, caso decida cancelar agora o TRIAL será perdido, deseja prosseguir com o cancelamento?"
          useConfirmBtn
          onConfirm={this.confirmCancelTrial}
          customConfirmBtnText="Sim"
          customCloseBtnText="Não"
          customConfirmBtnClass="btn btn-blue"
          customCloseBtnClass="btn btn-danger"
        />

        <Dialog
          fullScreen
          maxWidth={maxWidth}
          open={modalIsOpen}
          onClose={this.closeModal}
          TransitionComponent={Transition}
          scroll="body"
          aria-labelledby="max-width-dialog-title"
        >
          <AppBar style={styles.modalHeader}>
            <Toolbar
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Icon
                style={{ cursor: 'pointer' }}
                edge="start"
                color="black"
                onClick={this.closeModal}
                aria-label="close"
              >
                <CloseIcon
                  style={{
                    color: 'black'
                  }}
                />
              </Icon>
            </Toolbar>
          </AppBar>

          {<NavBarSuperior />}

          <div
            className="modal-content"
            id="pagamento"
            style={{ overflow: 'hidden' }}
          >
            <div
              id="modal-plan"
              className="modal-body"
              style={{ overflow: 'hidden', marginTop: '62px', padding: 0 }}
            >
              <div style={{ height: '100%' }}>
                {showForm ? (
                  updateSubscriptionComponent
                ) : (
                  <>
                    <UpdateSubscription
                      close={this.closeModal}
                      action="update"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  openDirectPayment: state.user.profile.openDirectPayment,
  openAnnualPlans: state.alert.openPlans
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UserActions.ProfileActions,
      ...AlertActions
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Planos);
