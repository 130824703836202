import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { gateway } from "../../../../utils/api";
import Screen from "./screen";
import { ACTION, SIZE } from "../../../constants";
import Image from "../../basicComponents/Image";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {} //component does not receive parameters
**/

export default ({
  setValue = DEFAULT_VALUES.FUNCTION
}) => {

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, acceptMultipleFile = false } = useDropzone({
    accept: "image/*",
    multiple: acceptMultipleFile,
    onDrop: async acceptedFiles => {
      const [file] = acceptedFiles;

      if (file) {
        try {
          const formData = new FormData();

          formData.append("file", file);
          formData.append("mediaType", "GROUP_IMAGE");

          // console.log(
          //   _.get(response, "ox_standard.ox_identification.cr_code", null)
          // );
          const response = await gateway.post("/media/media/upload", formData);

          setValue(
            _.get(response, "ox_standard.ox_identification.cr_code", null)
          );
        } catch (error) {
          console.log(error, "Aconteceu um erro");
        }
      }
      setFiles(
        acceptedFiles.map(acceptedFile =>
          Object.assign(acceptedFile, {
            preview: URL.createObjectURL(acceptedFile)
          })
        )
      );
    }
  });
  const thumbs = files.map(file => (
    <div key={file.name}>
      <Image src={file.preview} alt={ACTION.IMAGE_ALT} size={SIZE.MEDIUM} />
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <>
      <Screen
        thumbs={thumbs}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
      />
    </>
  );
};
