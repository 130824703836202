import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  @media(max-width: 740px) {
    flex-direction: column;
    /* padding: 10px; */
    align-items: center;
  }
`;

export const Sidebar = styled.div`
  width: 250px;
  margin-right: 20px;
  @media(max-width: 740px) {
    margin-bottom: 20px;
  }
`

export const BoxCalendar = styled.div`
  flex: 1;
  padding-bottom: 30px;
  min-height: 500px;
  .rbc-event-content {
    display: flex;
    align-items: center;
  }
`
