import React, { useState, useEffect, useRef, useMemo } from "react";
import { FaSpinner } from "react-icons/fa";

import * as Icons from '@material-ui/icons';

import avatar from "../../../../images/placeholder-avatar.png";

import { Container, Content, LoadingWrapper, ButtonClose } from "./styles";

function Feedbacks({ feedbacks, onSendMessage, handleCloseFeedbacks }) {
  const [message, setMessage] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);

  const idProfile = useMemo(() => {
    return localStorage.getItem("idProfile");
  }, []);

  const endedSubject = useMemo(() => {
    const foundItem = feedbacks.find(item => item.endedSubject);
    return !!foundItem;
  }, [feedbacks]);

  const feedbacksRender = useMemo(() => {
    return (
      feedbacks &&
      feedbacks
        .filter(feedback => !!feedback.feedbackMessage.trim())
        .map(feedback => {
          return (
            <li
              className={
                feedback.authorReference === idProfile ? "sent" : "received"
              }
            >
              <img src={avatar} alt="user" />

              <span>
                {feedback.grade && (
                  <small>Referente a nota {feedback.grade}</small>
                )}
                {feedback.feedbackMessage}
              </span>
            </li>
          );
        })
    );
  }, [feedbacks, idProfile]);

  const listRef = useRef(null);

  function handleChange(event) {
    setMessage(event.target.value);
  }

  async function handleSendMessage() {
    try {
      setSendingMessage(true);
      await onSendMessage(message);
      setMessage("");

      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSendingMessage(false);
    }
  }

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, []);

  return (
    <Container>
      <Content>
      <ButtonClose type="button" onClick={() => handleCloseFeedbacks()}>
          <Icons.Close style={{
            fontSize: 17,
            verticalAlign: 'text-top',
          }} /> Fechar
        </ButtonClose>
        <ul ref={listRef}>{feedbacksRender}</ul>

        {!endedSubject && (
          <div>
            <textarea value={message} onChange={e => handleChange(e)} />
            <button type="button" onClick={handleSendMessage}>
              {sendingMessage ? (
                <LoadingWrapper>
                  <FaSpinner size={20} color="#1890ff" />
                </LoadingWrapper>
              ) : (
                "Enviar"
              )}
            </button>
          </div>
        )}

        {endedSubject && (
          <p>O chat sobre este feedback foi encerrado pelo tutor</p>
        )}
      </Content>
    </Container>
  );
}

export default Feedbacks;
