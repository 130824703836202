import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} size //{SMALL, MEDIUM, LARGE} field size
 * @param {String} name //field name
 * @param {String} label //text displayed above the field
 * @param {String} placeholder //text displayed above the fieldtext displayed above the field
 * @param {String} value //default field value
 * @param {Function} onChange //action taken when clicking on the component
 * @param {Function} onBlur //action taken when clicking outside the component
**/

export default ({
  size = DEFAULT_VALUES.STRING,
  name = DEFAULT_VALUES.STRING,
  label = DEFAULT_VALUES.STRING,
  placeholder = DEFAULT_VALUES.STRING,
  value = DEFAULT_VALUES.STRING,
  onChange = DEFAULT_VALUES.FUNCTION,
  onBlur = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <Screen
        size={size}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};
