import styled from "styled-components";
import defaultModal from "react-modal";
import { } from "../../../../constants";

defaultModal.defaultStyles.overlay.position = "fixed";
defaultModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.75)";

export const ModalHeader = styled.div.attrs({
  className: "modal-header"
})`
  background: var(--transparent);
  height: 80px;
  color: var(--modal-text);
  padding-left: 20px;
  padding-top: 30px;
  button {
    margin: 0;
  }
`;
