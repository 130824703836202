import React, { useCallback} from 'react';
import PropTypes from "prop-types";
import { isSameDay, set as dateFnsSet } from "date-fns";
import Loading from "../../../Loading/Loading";
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';

import * as Styles from './styles';

const localizer = momentLocalizer(moment);

function Screen({
  events, 
  selectedDate, 
  onChangeDate, 
  handleActiveStartDateChange,
  loading
}) {

  const eventStyleGetter = useCallback((event)  => {
    const backgroundColor = `#${event.hexColor}`;
    const style = {
        backgroundColor,
        borderRadius: '2px',
        cursor: event.available ? 'pointer' : 'not-allowed',
        // opacity: 0.8,
        color: '#fff',
        border: '1px solid #333',
        paddingTop: '0px',
        display: 'flex',
        alignItems: 'center'
    };
    return {
        style
    };
  }, []);

  const formats = {
    eventTimeRangeFormat: () => { 
      return "";
    },
  }
  
  return (
    <Styles.Container>
      <Styles.Sidebar>
        <Calendar
          onChange={onChangeDate}
          value={selectedDate}
          onActiveStartDateChange={handleActiveStartDateChange}
        />
      </Styles.Sidebar>
      <Styles.BoxCalendar>
        {loading
          ? <Loading img width={30} height={30} />
          : <BigCalendar
              formats={formats}
              scrollToTime={isSameDay(new Date(), selectedDate) ? new Date() : dateFnsSet(selectedDate, {hours: 7, minutes: 0, seconds: 0, milliseconds: 0})}
              localizer={localizer}
              events={events}
              onSelectEvent={event => event.cta()}
              style={{ height: 500 }}
              eventPropGetter={eventStyleGetter}    
              startAccessor="start"
              endAccessor="end"
              defaultView={Views.DAY}
              defaultDate={new Date()}
              onNavigate={onChangeDate}
              date={selectedDate}
              messages={{
                day: 'Dia',
                week: 'Semana',
                today: 'Hoje',
                previous: '<',
                next: '>'
              }}  
              views={{ day: true, week: true }}
            />
        }
      </Styles.BoxCalendar>
    </Styles.Container>
  );
}

Screen.propTypes = {
  events: PropTypes.arrayOf.isRequired,
  selectedDate: PropTypes.string.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  handleActiveStartDateChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};


export default Screen;
