import styled from "styled-components";
import { } from "../../../constants";

export const FilterBlock = styled.div`
  display: inline-block;
  width: 100%;
  margin: 2rem 0 0!important;
  text-align: left;
`;

export const Selection = styled.span`
  padding: 5px;
  display: inline-block;
  width: 25%;
  position: relative;
`;

export const SimpleBlock = styled.div`
  position: relative;
  text-align: left;
  display: block;
  margin-bottom: 1rem!important;
`;
