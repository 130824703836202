import React, { Component } from "react";
import {
  DragAndDropContainer,
  DragAndDropContent,
  DragAndDropText
} from "./styles";

class DragAndDrop extends Component {
  dropRef = React.createRef();

  constructor(props) {
    super(props);

    this.dragCounter = 0;

    this.state = {
      drag: false
    };
  }

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { drag } = this.state;
    const { children } = this.props;

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
       
        }}
        ref={this.dropRef}
      >
        {drag && (
          <DragAndDropContent>
            <div>
              <DragAndDropText>Solte o arquivo aqui.</DragAndDropText>
            </div>
          </DragAndDropContent>
        )}
        {children}
      </div>
    );
  }
}
export default DragAndDrop;
