import React from 'react'
import styled from 'styled-components'
import ContentLoader from "react-content-loader"

import LaneHoc from './LaneHoc'

import {StyleLane} from './styles'

const Loader = () => (
  <ContentLoader 
    speed={2}
    width='100%'
    height={132}
    viewBox="0 0 400 132"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="26" rx="3" ry="3" width="200" height="6" /> 
    <rect x="0" y="56" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="100%" height="6" /> 
    <rect x="0" y="88" rx="3" ry="3" width="100%" height="6" /> 
    <rect x="0" y="104" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="120" rx="3" ry="3" width="100%" height="6" />  
  </ContentLoader>
)

const ContainerLoader = styled.div`
  background: rgba(255,255,255, .3);
  padding: 10px;
`

export default function CustomContentLoader({tooltipText, title}){
  return(
    <LaneHoc tooltipText={tooltipText} title={title}>
        {Array(11)
          .fill()
          .map((item) => {
            return (
              <StyleLane key={`${item}`}>
                <ContainerLoader>
                  <Loader />
                </ContainerLoader>
              </StyleLane>
            )
          })}
    </LaneHoc>
  )
}
