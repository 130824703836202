import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { bindActionCreators } from 'redux';

import { Creators as ProfileActions } from '../../store/ducks/user/profile/actions';
import api, { gateway } from '../../utils/api';

class SobreUsuario extends Component {
  state = {
    modalIsOpen: false,
    loading: false,
    about: ''
  };

  componentDidMount() {
    const { perfil } = this.props;
    this.setState({ about: perfil.about });
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  onSubmit = async () => {
    this.setState({ loading: true });
    const { perfil, getProfileRequest } = this.props;
    const { about } = this.state;
    const dataToFetch = {
      ...perfil,
      about
    };
    try {
      await gateway.put(
        `/webbff/lit_app_web/profile/updateProfileTO`,
        dataToFetch
      );
      getProfileRequest(perfil.idProfile);
      this.setState({ modalIsOpen: false, loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { about, loading, modalIsOpen } = this.state;
    return (
      <div className="cursos bg-white-perfil" id="sobre-usuario">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12"
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <h5
                className="d-inline-block"
                style={{ fontSize: '1.5em', paddingTop: '15px' }}
                data-tip="Adicione informações sobre você, pode ser profissional, pessoal ou ambas. Esse resumo ficará disponível para todos os usuários do LIT"
              >
                Sobre mim
              </h5>
              <button
                type="button"
                className="btn btn-icon ml-auto"
                onClick={this.openModal}
              >
                <i
                  className="fa fa-pencil"
                  aria-hidden="true"
                  style={{ color: '#27aae1' }}
                />
              </button>
            </div>
          </div>
          <hr style={{ marginTop: '0.5em' }} />
          <div className="row">
            <div className="col">
              {about ? (
                <p style={{ whiteSpace: 'pre-line' }}>{about}</p>
              ) : (
                <p>Nenhuma descrição adicionada</p>
              )}
            </div>
          </div>
        </div>
        <Modal
          open={modalIsOpen}
          onClose={this.closeModal}
          classNames={{ modal: 'lit-perfil-form' }}
          closeOnOverlayClick={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Alterar resumo sobre você
              </h5>
            </div>
            <div className="modal-body">
              <div className="input-pessoal perfilEdit-field">
                <div className="row">
                  <div className="col">
                    <h6>Sobre mim</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <textarea
                      className="form-control"
                      placeholder="Resumo sobre você"
                      rows="10"
                      maxLength={1024}
                      value={about}
                      onChange={evt => {
                        this.setState({
                          about: evt.target.value
                        });
                      }}
                    />
                    <p style={{ float: 'right' }}>
                      {about ? about.length : 0} / 1024
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-modal__cancel"
                type="button"
                onClick={this.closeModal}
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loading}
                className={`btn btn-modal__approve ${loading &&
                  ' btn-loading'}`}
                onClick={this.onSubmit}
              >
                Salvar
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    perfil: state.user.profile.data
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(ProfileActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SobreUsuario);
