import styled from "styled-components";
import { } from "../../../../constants";

export const Info = styled.div`
  display: block;
  text-align: left;
  margin: 5px;
  width: 260px;
  position: absolute;
  left: 40%;
  height: 40px;
  i {
    top: 12px;
  }
  div {
    height: 96%;
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 1060px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const Agrupable = styled.span`
  display: inline-block;
  float: left;
`;
