import React, { useState, useEffect, useMemo } from "react";
import swal from "@sweetalert/with-react";
import _ from "lodash";
import { gateway } from "../../utils/api";

import SurveyView from "./subcomponents/SurveyView";
import ActionFooter from "./subcomponents/ActionFooter";
import Feedbacks from "./subcomponents/Feedbacks";
import NavBar from "./subcomponents/Navbar";
import Header from './subcomponents/Header';
import spinner from "../../images/paul_loading.gif";

import { Container, Loading, Content } from "./styles";

function AssessmentView({ location }) {
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingFeedbacks, setLoadingFeedbacks] = useState(false);
  const [currentSurveyIndex, setCurrentSurveyIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [responseCode, setResponseCode] = useState("");
  const [feedbacksArray, setFeedbacksArray] = useState([]);
  const [feedbacksToggle, setFeedbacksToggle] = useState(false);

  const currentSurvey = useMemo(() => {
    return {
      data: _.get(
        assessmentData,
        `surveys.${currentSurveyIndex}`,
        null
      ),
      answers: _.get(answers, `${currentSurveyIndex}.alternatives`, null)
    };
  }, [assessmentData, currentSurveyIndex, answers]);

  const numberOfSurveys = useMemo(() => {
    return assessmentData ? _.size(assessmentData.surveys || []) : 0;
  }, [assessmentData]);

  async function getFeedbacks() {
    setLoadingFeedbacks(true);
    try {
      const { code } = currentSurvey.data;
      const url = `webbff/lit_app_web/evaluation/response/${responseCode}/feedback/${code}`;
      const response = await gateway.get(url);
      setFeedbacksArray(response);
      setFeedbacksToggle(true);
      setLoadingFeedbacks(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function sendMessage(message) {
    try {
      const { feedback } = currentSurvey.data;
      const url = `/webbff/lit_app_web/evaluation/response/feedback/${feedback}/reply`;

      await gateway.post(url, { message });

      getFeedbacks(feedback);
    } catch (error) {
      console.log(error);
    }
  }

  function onCloseChat() {
    setFeedbacksToggle(false);
  }

  function handleErrorAlert() {
    swal({
      title: `Ops, ocorreu um erro.`,
      text: `Não foi possível buscar os dados dessa avaliação.`,
      buttons: {
        confirm: {
          text: "Continuar",
          value: true,
          visible: true,
          className: "btn-alert btn-alert-confirm-onboarding"
        }
      }
    }).then(() => {
      window.close();
    });
  }

  function handleSetCurrentIndex(index){
    setCurrentSurveyIndex(index);
    setFeedbacksToggle(false);
  }

  function handleGoBack() {
    setFeedbacksToggle(false);
    setCurrentSurveyIndex(prevState => {
      return prevState > 0 ? prevState - 1 : prevState;
    });
  }

  function handleGoNext() {
    setFeedbacksToggle(false);
    setCurrentSurveyIndex(prevState => {
      return prevState < numberOfSurveys ? prevState + 1 : prevState;
    });
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const assessmentCode = params.get("assessmentCode");
    const responseCrCode = params.get("responseCode");

    async function loadAssessmentView() {
      try {
        const url = `/webbff/lit_app_web/evaluation/response/${assessmentCode}/${responseCrCode}`;
        const response = await gateway.get(url);
        const surveys = _.get(response, 'surveys', []);
        setAnswers(surveys);
        setAssessmentData({
          ...response,
        });
        setLoading(false);
      } catch (error) {
        handleErrorAlert();

        setLoading(false);
      }
    }

    loadAssessmentView();
    setResponseCode(responseCrCode);
  }, []);

  const allowassessmentresponse = useMemo(() => {
    return _.get(assessmentData, 'allowassessmentresponse', true)
  },[assessmentData])

  return (
    <>
      {loading && (
        <Loading>
          <img
            alt="loading"
            style={{
              maxHeight: "85%",
              animation: "loading-spin infinite 10s linear"
            }}
            src={spinner}
          />
        </Loading>
      )}

      {!loading && assessmentData && (
        <>
           <NavBar style={{ marginBottom: 0 }} />
           <Container allowassessmentresponse={allowassessmentresponse}>
              <Header
                assessmentData={assessmentData}
                numberOfQuestions={numberOfSurveys}
                limitTime={assessmentData.timeLimit}
                currentValue={currentSurveyIndex}
                onChange={handleSetCurrentIndex}
                allowassessmentresponse={allowassessmentresponse}
              />

              {allowassessmentresponse && <Content>
                <main>
                  {currentSurveyIndex !== numberOfSurveys && (
                    <SurveyView
                      numberQuestion={currentSurveyIndex + 1}
                      surveyData={currentSurvey.data}
                      surveyAnswers={currentSurvey.answers}
                      responseCode={responseCode}
                      getFeedbacks={getFeedbacks}
                      loading={loadingFeedbacks}
                    />
                  )}
                </main>
              </Content>}

              {feedbacksToggle && (
                <Feedbacks
                  numberQuestion={currentSurveyIndex + 1}
                  feedbacks={feedbacksArray}
                  sendMessage={sendMessage}
                  onCloseChat={onCloseChat}
                />
              )}

              <footer>
                <ActionFooter
                  onExit={() => window.close()}
                  messenger={feedbacksToggle}
                  onGoBack={handleGoBack}
                  onGoNext={handleGoNext}
                  disableNextButton={currentSurveyIndex === (numberOfSurveys - 1)} 
                  allowassessmentresponse={allowassessmentresponse}
                />
              </footer>
            </Container>
        </>   
      )}
    </>
  );
}

export default AssessmentView;
