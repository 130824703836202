import React, { useEffect } from "react";

export default function OAuthPlurall() {
  useEffect(() => {
    redirectToPlurall();
  });

  const oauthInfo = {
    test: {
      domain: "https://sso.s.plurall.net",
      clientId: "NjAzN2Y4Njc2NWYyZTZl",
      redirectURI: "http://lit.local.plurall.net/oauth-plurall"
    },
    development: {
      domain: "https://sso.s.plurall.net",
      clientId: "NjAzN2Y5MWRkMDhhMmRm",
      redirectURI: "https://tst.lit.com.br/oauth-plurall"
    },
    production: {
      domain: 'https://sso.plurall.net/',
      clientId: 'NjAzNDJkOTdkNDkyNTYy',
      redirectURI: 'https://app.lit.com.br/oauth-plurall',
    }
  };

  if (process.env.REACT_APP_API_URL.includes("bet.lit.com.br")) {
    oauthInfo.development = {
      domain: "https://sso.s.plurall.net",
      clientId: "NjAzN2Y5M2FiNzkyOTY3",
      redirectURI: "https://bet.lit.com.br/oauth-plurall"
    };
  }

  const redirectToPlurall = () => {
    const env = process.env.REACT_APP_MY_ENV;
    // PLURALL-LOCAL-TEST: Uncomment next line to use plurall local variables instead
    // const env = 'test';

    const { domain, clientId, redirectURI } = oauthInfo[env];
    const url = `${domain}/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}`;
    window.location.replace(url);
  };

  return (
    <>
      <h5 style={{ textAlign: "center", marginTop: "20%" }}>
        Se você não for redirecionado automaticamente,{" "}
        <a
          style={{ color: "blue", textDecoration: "underline" }}
          onClick={redirectToPlurall}
        >
          clique aqui
        </a>
        .
      </h5>
    </>
  );
}
