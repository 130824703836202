import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import * as Styles from './styles';

function SideBarMenuItemUC({
    tip,
    title,
    handleSelectedItem,
    icon,
    iconNumeric = false,
    hasCustomList = false,
    ...rest
}) {

  return <Styles.TooltipContainer>
            <Styles.Container 
                onClick={() => {
                    if(typeof handleSelectedItem === 'function'){
                        handleSelectedItem();
                    }
                }}
                data-icon-numeric={iconNumeric || hasCustomList}
                {...rest}
                >
                    <Styles.ContainerIcon>
                        {iconNumeric ? <span>{iconNumeric}</span> : <i className={`fa fa-${icon}`}></i>}
                    </Styles.ContainerIcon>
                    <Styles.ItemDescription>
                        {title.length > 35 
                            ? <Tooltip title={title} aria-label="add" placement="top">
                                    <span>{title.substring(0,30)} ...</span>
                                </Tooltip>
                            : title
                        }
                    </Styles.ItemDescription>
                </Styles.Container>
            </Styles.TooltipContainer>
}

export default SideBarMenuItemUC;