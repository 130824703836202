import React from "react";

import ModalHeader from "../CommomComponents/ModalHeader";

import { Modal, Content } from "./styles";

export default ({ child, textHeader, isOpen, closeModal, pageImage }) => {
  return (
    <>
      <Modal isOpen={isOpen}>
        <Content>
          <ModalHeader
            textHeader={textHeader}
            closeModal={closeModal}
            pageImage={pageImage}
          />
          {child}
        </Content>
      </Modal>
    </>
  );
};
