/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"
import "moment/locale/pt-br"



class MBASaintPaul extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody} = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                10. Gostaria de saber se é possível contratar apenas um curso no LIT. Além disso, qual seria o período de acesso? E como funciona o acesso à biblioteca virtual nesses casos?
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col-12">
                <p>
                  Sim, você pode adquirir um único curso no LIT. Para cursos com duração de até 20 horas, o acesso é concedido por 3 meses, enquanto para as trilhas de aprendizado, o acesso se estende por 9 meses. No entanto, é importante observar que para ter acesso à biblioteca virtual, é necessário ter um plano anual ou bianual.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default MBASaintPaul;
