import React, {useState} from "react"
import styled from 'styled-components'

import ContentLoader from "react-content-loader"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import Img from "react-image"
import { UserActions } from "../../store/ducks/user"
import PrivateCard from "./PrivateCard"
import Loading from "../Loading/Loading";
import {gateway} from '../../utils/api'

import * as Styles from './styles'

const Loader = () => (
  <ContentLoader 
    speed={2}
    width='100%'
    height={240}
    viewBox="0 0 400 240"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
  
    <rect x="0" y="26" rx="3" ry="3" width="200" height="6" /> 
    <rect x="0" y="56" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="100%" height="6" /> 
    <rect x="0" y="88" rx="3" ry="3" width="100%" height="6" /> 
    <rect x="0" y="104" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="120" rx="3" ry="3" width="100%" height="6" />  
    
  
  </ContentLoader>
)

const ContainerLoader = styled.div`
  background: rgba(255,255,255, .3);
  padding: 10px;
`

const CardBook = ({ book, toggleModalRestrict, toggleModalRestrictLitPass }) => {
  // const libraryUser = false
  const libraryUser = JSON.parse(localStorage.getItem("libraryUser"))
  const naRealUser = JSON.parse(localStorage.getItem('naRealUser'))
  const [loading, setLoading] = useState(false);

  const getBook = async () => {
    setLoading(true);
    if (libraryUser && !naRealUser) {
      try {
        const url = `webbff/lit_app_web/home/library/${book.cr_code}`
        const response = await gateway.get(url)
        window.open(`${response.url}`, "_blank")
        setLoading(false);
      } catch (error) {
        console.log(error)
        setLoading(false);
      }
    } else {
      setLoading(false);
      if (naRealUser) {
        toggleModalRestrict(true, 'book')
        return
      }
      toggleModalRestrictLitPass(true, 'book')
    }

  }

  let color
  switch (book.category) {
    case "Estatística":
      color = "#02b16c"
      break
    case "Processos/Projetos":
      color = "#92770c"
      break
    case "Estratégia":
      color = "#404797"
      break
    case "Marketing/Vendas":
      color = ""
      break
    default:
      break
  }

  return (
   <React.Fragment>
     {book.wu_url && book.wu_url.length ? (
        <div>
          <div
            role="button"
            className="slider_media"
            style={{
              borderBottom: `5px solid ${color}`,
              maxWidth: "100%",
              minWidth: "200px",
              maxHeight: "300px",
            }}
          >
            <Img
              src={[book.wu_url]}
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
              }}
              loader={
                <ContainerLoader>
                  <Loader />
                </ContainerLoader>
              }
            />
          </div>
          <div role="button" className="slider_info" onClick={() => getBook()}>    
            {loading && (
              <div style={{margin: 'auto'}}>
                <Loading width={60} />
              </div>
            )}        
            {!libraryUser || naRealUser ? (
              <div
                className="slider_title d-flex justify-content-center align-items-center"
                style={{ flex: 1 }}
              >
                <PrivateCard />
              </div>
            ) : (
                <div className="slider_title ">
                  <span style={{ fontSize: ".5em" }}>Autores: {book.author}</span>
                </div>
              )}
          </div>
        </div>
     ) : (
        <Styles.NoImagemBook>
          <div className="info">
            <div role="button" className="slider_info" onClick={() => getBook()}>
              {loading && (
                <div style={{margin: 'auto'}}>
                  <Loading width={60} />
                </div>
              )}
              {!libraryUser || naRealUser ? (
                <div
                  className="slider_title d-flex justify-content-center align-items-center"
                  style={{ flex: 1 }}
                >
                  <PrivateCard />
                </div>
              ) : (
                  <div className="slider_title ">
                    <span style={{ fontSize: ".5em" }}>Autores: {book.author}</span>
                  </div>
                )}
            </div>
            <h3>
              {book.ws_name}
            </h3>
          </div>
        
          {book.author && <p id="author">Autor: {book.author}</p>}
          
        </Styles.NoImagemBook>
     )}
    
   </React.Fragment>
   
  )
}

const mapDispatchToProps = dispatch => bindActionCreators(UserActions.ProfileActions, dispatch)

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(CardBook)
)
