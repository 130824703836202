import { Types } from "./types"

/**
 * Actions
 */

export const Creators = {
  getOfficesRequest: idProfile => ({
    type: Types.GET_OFFICES_REQUEST,
    payload: { idProfile },
  }),
  getOfficesSuccess: data => ({
    type: Types.GET_OFFICES_SUCCESS,
    payload: { data },
  }),
  getOfficesFailure: error => ({
    type: Types.GET_OFFICES_FAILURE,
    payload: { error },
  }),
  
  getLastOfficeRequest: idProfile => ({
    type: Types.GET_LAST_OFFICE_REQUEST,
    payload: { idProfile },
  }),
  getLastOfficeSuccess: data => ({
    type: Types.GET_LAST_OFFICE_SUCCESS,
    payload: { data },
  }),
  getLastOfficeFailure: error => ({
    type: Types.GET_LAST_OFFICE_FAILURE,
    payload: { error },
  }),
}
