import React from "react";
import { DEFAULT_VALUES } from "../../constants";

import PageHeader from "./components/PageHeader";
import PageContent from "./components/PageContent";
import LoadingBar from "../../LitAtomicComponents/basicComponents/LoadingBar";

import {} from "./styles";

export default ({
  logoPage = DEFAULT_VALUES.STRING,
  menuNavigation = DEFAULT_VALUES.LIST,
  feedItems = DEFAULT_VALUES.LIST,
  notifications = DEFAULT_VALUES.LIST,
  userProfile = DEFAULT_VALUES.OBJECT,
  currentCourse = DEFAULT_VALUES.LIST,
  progress = DEFAULT_VALUES.NUMBER,
  hasNextPage = DEFAULT_VALUES.BOOLEAN,
  nextPage = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <LoadingBar loadingProgress={progress} />
      <PageHeader logoPage={logoPage} menuNavigation={menuNavigation} />
      <PageContent
        feedItems={feedItems}
        notifications={notifications}
        userProfile={userProfile}
        currentCourse={currentCourse}
        hasNextPage={hasNextPage}
        nextPage={nextPage}
      />
    </>
  );
};
