import { Types } from "./types"

/**
 * Actions
 */
export const Creators = {
  getMyGroupsRequest: idProfile => ({
    type: Types.GET_MYGROUPS_REQUEST,
    payload: { idProfile },
  }),
  getMyGroupsSuccess: data => ({
    type: Types.GET_MYGROUPS_SUCCESS,
    payload: { data },
  }),
  getMyGroupsFailure: error => ({
    type: Types.GET_MYGROUPS_FAILURE,
    payload: { error },
  }),
}
