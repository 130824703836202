import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    animation: ${rotate360} 1s linear infinite;
  }
  padding: 0;
  margin: 0;
`;
