import React, { Component } from "react"
import { connect } from "react-redux"
import { Creators as PaulChatActions } from "../../../store/ducks/paulChat/actions"

class FeedbackButton extends Component {
  state = {
    negativeSelected: false,
    positiveSelected: false,
  }

  render() {
    const { negativeSelected, positiveSelected } = this.state
    const { dispatch } = this.props
    return (
      <li className="respostaWatson d-flex justify-content-start">
        <span>
          <div className="col row no-gutters d-flex align-items-center justify-content-end watson_avaliacao">
            <label className="watson_avaliar-mensagem">
              Avalie as respostas acima. Elas lhe foram úteis?
            </label>
            <button
              type="button"
              className={`fa fa-thumbs-up watson_opcao-avaliacao--positiva ${
                positiveSelected ? "watson_opcao-avaliacao--positiva-selected" : ""
              }`}
              onClick={() => {
                this.setState({
                  negativeSelected: false,
                  positiveSelected: true,
                })
                dispatch(PaulChatActions.removeOldFeedbackButton())
                dispatch(PaulChatActions.sendFeedback(true))
                dispatch(PaulChatActions.resetSubjectCounter())
              }}
            />
            <button
              type="button"
              className={`fa fa-thumbs-down watson_opcao-avaliacao--negativa ${
                negativeSelected ? "watson_opcao-avaliacao--negativa-selected" : ""
              }`}
              onClick={() => {
                this.setState({
                  negativeSelected: true,
                  positiveSelected: false,
                })
                dispatch(PaulChatActions.removeOldFeedbackButton())
                dispatch(PaulChatActions.sendFeedback(false))
                dispatch(PaulChatActions.resetSubjectCounter())
              }}
            />
          </div>
        </span>
      </li>
    )
  }
}

export default connect()(FeedbackButton)
