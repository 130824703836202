import React from "react";
import {
  SIZE,
  APPEARANCE,
  ACTION,
  NUMBER,
  POSITION,
  FONT_TYPE,
  STATUS
} from "../../../constants";

import OverflowContent from "../../basicComponents/OverflowContent";
import ListRenderer from "../../ListRenderer";
import Actions from "../../Actions";
import Solicitation from "../../Info/DataInfo";
import Text from "../../basicComponents/Text";
import InfiniteScroll from "../../basicComponents/InfiniteScroll";

import { ModalBody, List } from "./styles";
import _ from "lodash";

export default ({
  solicitations,
  declineJoinSolicitation,
  acceptJoinSolicitation,
  loadMoreSolicitations,
  hasMoreSolicitations
}) => {
  function showSolicitatiions() {
    if (!solicitations || !solicitations.length) {
      return (
        <Text
          text={"Nenhum item encontrado"}
          fontType={FONT_TYPE.BOLD}
          textAlign={POSITION.CENTER}
        />
      );
    } else {
      return (
        <List>
          <InfiniteScroll
            loadAction={loadMoreSolicitations}
            dataLength={solicitations.length}
            hasMore={hasMoreSolicitations}
          >
            <ListRenderer
              grid={NUMBER.ONE}
              componentToRender={solicitations.map(solicitation => (
                <Solicitation
                  id={solicitation.solicitationCode}
                  solicitationDate={solicitation.solicitationDate}
                  name={solicitation.user.name}
                  img={solicitation.user.img}
                  link={`/beta/social/externalgroup/${solicitation.user.cr_code}`}
                  listButtons={STATUS.TRUE}
                  actions={
                    <Actions
                      itemsToRender={[
                        {
                          type: "button",
                          action: () => {
                            declineJoinSolicitation(
                              solicitation.solicitationCode
                            );
                          },
                          buttonText: ACTION.DECLINE,
                          appearance: APPEARANCE.OUTLINE_PRIMARY,
                          size: SIZE.SMALL
                        },
                        {
                          type: "button",
                          action: () => {
                            acceptJoinSolicitation(
                              solicitation.solicitationCode,
                              solicitation.user.cr_code
                            );
                          },
                          buttonText: ACTION.ACCEPT,
                          appearance: APPEARANCE.PRIMARY,
                          size: SIZE.SMALL
                        }
                      ]}
                    />
                  }
                />
              ))}
            ></ListRenderer>
          </InfiniteScroll>
        </List>
      );
    }
  }

  return (
    <ModalBody>
      <OverflowContent>{showSolicitatiions()}</OverflowContent>
    </ModalBody>
  );
};
