import styled from "styled-components";

export const LocalVideo = styled.div`
  display: flex;
  justify-content: center;
  max-width: 270px;
  height: 160px;
  padding: 12px 0 12px 0;
`;

export const UserBox = styled.div`
  &.fullscreen {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 270px;
    padding: 12px;
  }
`;
