import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { } from "../../../constants";

export const DropdownItem = styled(Dropdown.Item)`
  &:hover {
    cursor: pointer;
    color: var(--dropdown-primary-hover);
  }
`;
