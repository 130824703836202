import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as PlanActions } from '../../store/ducks/payments/plan/actions';

import PlanList from './subcomponents/PlanList';
import PaymentForm from './subcomponents/PaymentForm';
import { gateway } from '../../utils/api';

const STEPS = {
  PLAN_SELECTION: 0,
  PAYMENT_FORM: 1
};

function NewPayment({ showHeader = true, close }) {
  const [currentStep, setCurrentStep] = useState(STEPS.PLAN_SELECTION);
  const selectedPlan = useSelector(({ plan }) => plan.selectedPlan);
  const [recommendation, setRecommendation] = useState(null);

  const dispatch = useDispatch();

  const handleSelectPlan = useCallback(plan => {
    setCurrentStep(STEPS.PAYMENT_FORM);
    dispatch(PlanActions.setSelectedPlan(plan));
  }, []);

  const handleGoBack = useCallback(() => {
    setCurrentStep(STEPS.PLAN_SELECTION);
    dispatch(PlanActions.setSelectedPlan(null));
  }, []);

  useEffect(() => {
    async function verifyIfUserHasRecommendation() {
      try {
        const email = localStorage.getItem('ra');
        const response = await gateway.get(
          `/webbff/lit_app_web/recommendation/offers/received?email=${email}`
        );

        if (response) {
          setRecommendation(response);

          setCurrentStep(STEPS.PAYMENT_FORM);
          dispatch(PlanActions.setSelectedPlan(response.offer));
        }
      } catch (error) {
        console.log(error);
      }
    }

    verifyIfUserHasRecommendation();
  }, []);
  return currentStep === STEPS.PLAN_SELECTION ? (
    <PlanList onSelectPlan={handleSelectPlan} showHeader={showHeader} />
  ) : (
    <PaymentForm
      onBack={handleGoBack}
      selectedPlan={selectedPlan}
      setSelectedPlan={(plan) => {
        console.log('plan ->', plan)
        dispatch(PlanActions.setSelectedPlan(plan))
      }}
      recommendation={recommendation}
      showHeader={showHeader}
      closeModal={close}
    />
  );
}

export default NewPayment;
