import React from "react"
import { connect } from "react-redux"

const IMG_PERSONALIZAR = require("../../../images/logoPersonalAzul.png")

const RespostaWatson = ({ children }) => {
  return (
    <li className="respostaWatson d-flex justify-content-start">
      <img className="respostaWatson__logo" src={IMG_PERSONALIZAR} alt="Watson logo" />
      <span>{children}</span>
    </li>
  )
}

export default connect()(RespostaWatson)
