import styled from 'styled-components'

export const Button = styled.button`
  padding: 4px 10px;
  border-radius: 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: #47b1de;
  border: 2px solid #47b1de;
  &:hover {
    color: white;
    background: #47b1de;
  }

  span {
    display: inline-block;
    margin: 0 5px;
    flex: 1;
  }
`

export const BoxInfo = styled.div`
  max-width: 500px;
  width: 100%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  #parcelas {
    display: flex;
    align-items: center;
    width: 50px;
  }
`

export const ButtonCollapse = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  &:active, &:focus {
    box-shadow: none;
    outline: none;
  }

  div span {
    font-weight: bold;
  }
`
