import styled from "styled-components";

export const VideoBox = styled.div`
  position: relative;
  height: 100vh;
  width: calc(100vw - 270px);
`;

export const VideoContainer = styled.div`
  height: 100%;
  padding: 12px 0;

  @media (min-width: 1440px) {
    height: calc(100vh - 75px);
  }

  &.fullscreen {
    height: 100%;
  }
`;

export const LogoBar = styled.div`
  position: absolute;
  height: 75px;
  padding: 12px;
  
  &.fullscreen {
    display: none;  
  }

  img {
    max-height: 100%;
  }

  @media (min-width: 1440px) {
    position: relative;
  }
`;
