import { API_URL, headerConfig } from "./constants";
import { getInfosPortal, deviceHash } from "../utils/functions";
import axios from "axios";
import { gateway } from "../utils/api";

export default class NotifyService {
  constructor() {
    this.OneSignal = window.OneSignal || null;
  }

  async isPushEnabled() {
    return await new Promise((resolve, reject) => {
      this.OneSignal.isPushNotificationsEnabled(resolve);
    });
  }

  async getUserId() {
    if (process.env.NODE_ENV !== "development") {
      return await this.OneSignal.getUserId();
    }
  }
  async getNewUserId() {
    try {
      return await this.OneSignal.getUserId();
    } catch (error) {}
  }

  async getNewPermission() {
    return await this.OneSignal.getNotificationPermission();
  }
  async getPermission() {
    if (process.env.NODE_ENV !== "development") {
      return await this.OneSignal.getNotificationPermission();
    }
  }

  isPushSupported() {
    if (process.env.NODE_ENV !== "development") {
      return this.OneSignal.isPushNotificationsSupported();
    }
  }

  onDisplay(callback) {
    if (this.OneSignal && this.OneSignal.on) {
      this.OneSignal.on("notificationDisplay", callback);
    }
  }

  listenerOpened(callback) {
    if (this.OneSignal && this.OneSignal.addListenerForNotificationOpened) {
      this.OneSignal.addListenerForNotificationOpened(callback);
    }
  }
  async syndIdOneSignalMongo() {
    try {
      const infoPortal = await getInfosPortal();

      const playerid = await this.getNewUserId();
      const notificationpermission = await this.getNewPermission();
      const devicehash = await deviceHash();

      const body = {
        loginData: {
          device: {
            deviceinfo: {
              devicehash,
              os: `${infoPortal.os} ${infoPortal.browserName}`
            },
            userid: localStorage.getItem("idProfile")
          },
          integrations: [
            {
              type: "OneSignal",
              playerid,
              notificationpermission
            }
          ]
        }
      };
      await gateway.post(`/device/device/savedevice`, body);
    } catch (error) {
      console.log(error);
    }
  }
  async syncIdOneSignal(idProfile) {
    try {
      const idOneSignal = await this.getUserId();
      if (idOneSignal) {
        const url = `${API_URL}salvarIdOneSignal`;
        const data = {
          idProfile,
          idOneSignal,
          typeDivice: 1 // 1 - Portal e 2 - App
        };
        const response = await axios.post(url, data, {
          method: "POST",
          headers: headerConfig.headers
        });
        if (response.status === 200) {
          localStorage.setItem("savedOneSignal", true);
        } else {
          localStorage.setItem("savedOneSignal", false);
        }
      }
    } catch (error) {
      localStorage.setItem("savedOneSignal", false);
      console.error(error);
    }
  }
}
