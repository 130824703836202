/* eslint-disable no-script-url */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable import/imports-first */
/* eslint-disable import/order */
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { gateway } from '../../../utils/api';
import { TOKEN_KEY } from '../../../utils/auth';
import { toastErrorAndWarningOptions } from '../../../utils/functions';
import Loading from '../../Loading/Loading';
import _ from 'lodash';
import { toast } from 'react-toastify';
import NavBarContext from '../../../context/navBar/NavBarContext'

import { Creators as CourseActions } from '../../../store/ducks/newCourse/actions';

import history from '../../../utils/history';

import {
  TitleModal,
  TenancyListContainer,
  TenancyItem,
  TenancyIcon,
  ProfileContainer,
  ProfileList,
  ProfileDescription,
  ProfileLink,
  ProfileItem
} from './styles';

function ModalTenancy({ tenancy, settings, tenant, tenantName }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tenantSelected, setTenantSelected] = useState();
  const onCloseModal = () => setOpen(false);
  const { setTenancyName } = useContext(NavBarContext)

  const dispatch = useDispatch();

  const redirectToTenantData = useSelector(
    state => state.newCourse.redirectToTenantData
  );

  const handleSelectTenantRedirect = item => {
    const profileTypes = _.get(item, 'profileTypes', []);
    setOpen(true);
    setLoading(true);
    if (profileTypes.length === 0) {
      localStorage.setItem('tenant', _.get(item, 'tenancy'));
      localStorage.removeItem('selectedClassName');
      localStorage.removeItem('selectedClassCrCode');
      localStorage.removeItem('homeCrCode');
      history.push(
        {
          pathname: `/`
        },
        {
          reloadPage: true
        }
      );
    } else {
      const perfil = item.profileTypes[0];
      setTenantSelected(item);
      renewToken(perfil, true);
    }

  };

  useEffect(() => {

      setTenancyName()

  }, [handleSelectTenantRedirect, tenancy])

  const handleSelectTenant = item => {
    const profileTypes = _.get(item, 'profileTypes', []);
    if (profileTypes.length === 0) {
      setLoading(true);
      localStorage.setItem('tenant', _.get(item, 'tenancy'));
      history.push(
        {
          pathname: `/`
        },
        {
          reloadPage: true
        }
      );
    } else {
      let selected = _.get(tenantSelected, 'tenancy', '');
      if (item.tenancy === selected) {
        setTenantSelected('');
      } else if (profileTypes.length === 1) {
        const firstProfile = _.get(profileTypes, '0', null);
        handleSelectProfile(firstProfile);
      } else {
        let selected = _.get(tenantSelected, 'tenancy', '');
        if (item.tenancy === selected) {
          setTenantSelected('');
          setLoading(false);
        } else {
          setTenantSelected(item);
          setLoading(false);
        }
      }
    }

  };

  const handleSelectProfile = item => {
    renewToken(item);
  };


  const renewToken = async (item, isRedirect = false) => {
    let response;
    setLoading(true);
    try {
      if (_.get(item, 'crCode') !== localStorage.getItem('crCodeProfile')) {
        const crCodeItem = _.get(item, 'crCode', null);

        const data = {
          crCodeProfile: _.get(item, 'crCode'),
          searchType: 'crCode'
        };
        response = await gateway.post('/gateway/token/renew', data);

        localStorage.setItem('tenant', _.get(item, 'tenant'));
        localStorage.setItem(TOKEN_KEY, _.get(response, 'token'));

        localStorage.removeItem('selectedClassName');
        localStorage.removeItem('selectedClassCrCode');
        localStorage.removeItem('homeCrCode');

        if (crCodeItem) {
          localStorage.setItem('crCodeProfile', _.get(item, 'crCode'));
        } else {
          localStorage.removeItem('crCodeProfile');
        }

        if (isRedirect) {
          dispatch(
            CourseActions.redirectToTenant({
              ...redirectToTenantData,
              isSelectedTenant: true
            })
          );
        }

        history.push(
          {
            pathname: `/`
          },
          {
            reloadPage: true
          }
        );
      } else {
        setLoading(false);
        setTenantSelected('');
        onCloseModal();
      }
    } catch (error) {
      setLoading(false);
      onCloseModal();
      notifyError('Não foi possível realizar a troca de perfil.');
      throw error;
    }
  };

  const checkBackgroundTenant = item => {
    return settings &&
      settings[item.tenancy] &&
      settings[item.tenancy].headerBackgroundColor
      ? {
          background: `${settings[item.tenancy].headerBackgroundColor}`,
          borderColor: item.tenancy === tenant ? '#FFFFFF' : '#eeeeee'
        }
      : { color: '#FFFFFF' };
  };

  const notifyError = (text, duration = 5000) => {
    toast.error(text, {
      ...toastErrorAndWarningOptions,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration
    });
  };

  useEffect(() => {
    setTenantSelected('');
  }, [open]);



  useEffect(() => {
    if (redirectToTenantData && !redirectToTenantData.isSelectedTenant) {
      const { tenant } = redirectToTenantData;
      handleSelectTenantRedirect(tenant);
    }

  }, []);

  const tenantLimitedList = useMemo(() => {
    return tenancy && tenancy.slice(0, 3);
  }, [tenancy]);

  return (
    <>
      {(tenantLimitedList || []).map((item, index) => (
        <a
          key={`${item.name}-${index}`}
          className="dropdown-item"
          role="button"
          onClick={() => {
            setOpen(true);
            setLoading(true);
            setTimeout(() => {
              handleSelectTenant(item);
            }, 1000);
          }}
        >
          {item.name === tenantName ? <strong>{item.name}</strong> : item.name}
        </a>
      ))}

      {tenancy.length > 3 && (
        <a
          className="dropdown-item"
          role="button"
          onClick={() => setOpen(true)}
        >
          mais ...
        </a>
      )}

      <Modal
        open={open}
        closeOnOverlayClick={false}
        onClose={onCloseModal}
        classNames={{
          overlay: 'tenancyModalOverlay',
          modal: 'tenancyModal'
        }}
        center
      >
        {!loading && <TitleModal>Qual perfil deseja selecionar ?</TitleModal>}
        {!loading && tenancy.length > 0 && tenantSelected === '' && (
          <TenancyListContainer className="scroll-tenant-modal">
            {tenancy.map((item, index) => (
              <TenancyItem
                key={index}
                onClick={() => handleSelectTenant(item)}
                data-tip="Tenancy"
              >
                <TenancyIcon style={checkBackgroundTenant(item)}>
                  {settings &&
                  settings[item.tenancy] &&
                  settings[item.tenancy].logoUrl ? (
                    <img
                      id="tenant-logo"
                      alt={item.name}
                      src={settings[item.tenancy].logoUrl}
                      style={{ height: 40 }}
                    />
                  ) : (
                    item.name
                  )}
                </TenancyIcon>
              </TenancyItem>
            ))}
          </TenancyListContainer>
        )}
        {loading && <Loading message="" width={50} />}
        {tenantSelected && !loading && (
          <ProfileContainer>
            <TenancyIcon style={checkBackgroundTenant(tenantSelected)}>
              {settings &&
              settings[tenantSelected.tenancy] &&
              settings[tenantSelected.tenancy].logoUrl ? (
                <img
                  id="tenant-logo"
                  alt={settings[tenantSelected.tenancy].logoUrl}
                  src={settings[tenantSelected.tenancy].logoUrl}
                  style={{ height: 40 }}
                />
              ) : (
                tenantSelected.name
              )}
            </TenancyIcon>
            <ProfileDescription>
              {tenantSelected && tenantSelected.profileTypes && (
                <ProfileList>
                  {tenantSelected.profileTypes.map(item => (
                    <ProfileItem key={item._id}>
                      <ProfileLink
                        href="javascript:void(0)"
                        onClick={() => handleSelectProfile(item)}
                      >
                        {item.typeLabel ? item.typeLabel : 'ALUNO'}
                      </ProfileLink>
                    </ProfileItem>
                  ))}
                </ProfileList>
              )}
            </ProfileDescription>
          </ProfileContainer>
        )}
      </Modal>
    </>
  );
}

export default ModalTenancy;
