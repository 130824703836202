import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import EfetuarCobranca from "./EfetuarCobranca"
import ConsultarCobranca from "./ConsultarCobranca"

const Faturamento = ({ allowViewVoucher }) => {
  return (
    <div>
      <NavBarSuperior />
      {allowViewVoucher ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="container titleVoucher-voucherRoteTable">
                <h1>Faturamento</h1>
              </div>
              <div className="container" id="voucherRoteTable">
                <div className="row justify-content-md-center">
                  <div className="col-12 divContainer-voucherTable">
                    <EfetuarCobranca />
                    <hr />
                    <ConsultarCobranca />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container" id="perfil">
          <div className="alert alert-warning">
            <h4 className="alert-heading">
              <i className="fa fa-warning" /> Você não tem permissão para acessar essa tela.
            </h4>
            <p style={{ color: "#856404" }}>
              Caso essa mensagem seja um erro, e você deveria ter acesso a essa tela, contacte um
              administrador.
            </p>
            <h4 />
            <Link className="alert-link" to="/home">
              Voltar para a página inicial
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    allowViewVoucher: state.voucher.allowViewVoucher,
  }
}

export default connect(mapStateToProps)(Faturamento)
