import { gateway } from './api';

export const hasActiveSubscriptions = async () => {
  const isActive = sessionStorage.getItem('hasActiveSubscription') || undefined;

  if (isActive && isActive === 'true') {
    return true;
  }
  const userEmail = localStorage.getItem('ra');

  try {
    const hasActive = await gateway.get(
      `webbff/lit_app_web/contract/hasActiveSubscription`,
      {
        headers: {
          'x-owner': userEmail
        }
      }
    );

    if (hasActive) {
      sessionStorage.setItem('hasActiveSubscription', true);
      return true;
    }
  } catch (err) {
    sessionStorage.setItem('hasActiveSubscription', false);
    return false;
  }

  sessionStorage.setItem('hasActiveSubscription', false);
  return false;
};
