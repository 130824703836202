import React, { useEffect, useState } from 'react';
import Modal from "react-responsive-modal"
import { InputLabel, ButtonRegister, InputWrap, InputText, InputPhone } from "../../Styles/FormStyle"
import InputMask from "react-input-mask";
import { Formik } from "formik"
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Campo muito curto!")
      .max(50, "Campo muito longo!")
      .required("Nome obrigatório"),
    email: Yup.string()
      .email("email inválido")
      .required("Email obrigatório"),
    cpf: Yup.string()
      .required("CPF é obrigatório")
  })

const ModalResponsible = ({ openModal, onCloseModal, setResponsible }) => {

const getResponsibleData = (values, actions) => {
    setResponsible(values);
    setTimeout(() => {
        actions.setSubmitting(false)
        onCloseModal();
    }, 1500);
}

return <Modal 
        open={openModal} 
        onClose={onCloseModal}  
        classNames={{
            modal: 'modal-responsible-form',
        }}
        center>
            <h2 style={{ marginBottom: 30 }}>Informações do responsável</h2>
            <Formik
            initialValues={{
                name: "",
                email: "",
                cpf: ""
            }}
            validationSchema={ValidationSchema}
            onSubmit={getResponsibleData}
            render={({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
            }) => (
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <InputWrap error={errors.name && touched.name}>
                        <InputText
                        placeholder="Nome do responsável"
                        className="form-control "
                        id="inputName"
                        value={values.name}
                        name="name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        />
                        <InputLabel htmlFor="inputName">Nome</InputLabel>
                    </InputWrap>
                </div>
                <div className="form-group">
                    <InputWrap error={errors.email && touched.email}>
                        <InputText
                        type="email"
                        placeholder="E-mail"
                        className="form-control"
                        id="inputEmail"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        required
                        />
                        <InputLabel htmlFor="inputEmail">E-mail</InputLabel>
                    </InputWrap>
                    
                    </div>
                <div className="form-group">
                    <InputWrap error={errors.cpf && touched.cpf}>
                            <InputMask
                                mask="999.999.999-99"
                                type="text"
                                className="form-control"
                                placeholder="CPF"
                                name="cpf"
                                value={values.cpf}
                                maskChar={null}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="cpf"
                                style={{ width: '100%', height: 51, borderRadius: 10, borderWidth: 0 }}
                                />
                        <InputLabel htmlFor="cpf">CPF</InputLabel>
                    </InputWrap>
                </div>
                <div className="d-flex align-items-center mt-1 justify-content-center">
                    <ButtonRegister
                        id="btn-cadastro"
                        className={`btn ${isSubmitting && "btn-loading"}`}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        SALVAR
                    </ButtonRegister>
                </div>
            </form>
            )}
        />
        </Modal>;
}

export default ModalResponsible;