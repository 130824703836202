/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"



class PlanoAnualVencimento extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody} = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                8. Tenho um plano anual do LIT próximo do vencimento, mas quero continuar sendo aluno.
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                 <p>
                    Adquirindo uma assinatura no LIT as suas cobranças são recorrentes. No <b>plano anual</b> a cobrança ocorre todos os anos na data da sua assinatura.
                    <br/>
                    No plano bianual a cobrança ocorre a cada dois anos. Caso queira continuar sendo nosso aluno ou aluna, basta manter os dados de pagamento atualizados e sua cobrança ocorrerá automaticamente na data de validade do seu plano. 
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default PlanoAnualVencimento;
