import React from "react";
import { Carousel, Limit } from "./styles";

export default ({ infinite, child }) => {
  const responsive = {
    0: { items: 1 },
    767: { items: 2 },
    1200: { items: 3 },
    1440: { items: 4 }
  };
  return (
    <>
      <Limit>
        <Carousel infinite={infinite} responsive={responsive} items={child} />
      </Limit>
    </>
  );
};
