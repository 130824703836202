import React, { Component } from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import Select from "react-select"
import "react-datepicker/dist/react-datepicker.css"

import moment from "moment"
import "moment/locale/pt-br"
import { positions } from "../../utils/constants"

import NavBar from "../Termos/Navbar"
import WatsonBadgeFooter from "./WatsonBadgeFooter"

import api from "../../utils/api"

const GIF_LIT = require("../../images/animacao_paul_standby.gif")

class ExperienciaProfissional extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    profession: "",
    company: "",
    aboutProfession: "",
    dateInit: null,
    dateEnd: null,
    cargoAtual: false,
    loading: false,
    errors: "",
  }

  get validationForm() {
    const { profession, company, aboutProfession } = this.state
    return profession === "" || company === "" || aboutProfession === ""
  }

  get onBoardIncomplete() {
    const onBoardIncomplete = JSON.parse(localStorage.getItem('onBoardIncomplete'))
    return onBoardIncomplete;
  }

  handleChangeInit = date => {
    this.setState({
      dateInit: date,
    })
  }

  handleChangeEnd = date => {
    this.setState({
      dateEnd: date,
    })
  }

  saveForm = async () => {
    try {
      this.setState({ loading: true, errors: "" })
      await this.saveCargo()

      this.setState({ loading: false })
      this.nextStep()
    } catch (error) {
      this.setState({
        errors: "Não foi possivel enviar os dados, tente novamente mais tarde",
        loading: false,
      })
    }
  }

  nextStep = () => {
    const { router } = this.context
    router.history.push("/endereco")
  }
  
  saveCargo = () => {
    const { profession, company, aboutProfession, dateInit, cargoAtual, dateEnd } = this.state
    const newOffice = {
      profession,
      company,
      aboutProfession,
      dateInit: moment(dateInit).format("DD/MM/YYYY"),
      dateEnd: !cargoAtual ? moment(dateEnd).format("DD/MM/YYYY") : null,
      idProfile: parseInt(localStorage.getItem("idProfile"), 10),
    }

    return api.post("newOffice", newOffice)
  }

  render() {
    const {
      profession,
      company,
      aboutProfession,
      dateInit,
      cargoAtual,
      dateEnd,
      loading,
      errors,
    } = this.state
    return (
      <div id="interesses">
        <NavBar />

        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row">
            <div className="col-12">
              <div className="container" id="objetivos-interesses">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="container" id="card-interesses">
                      <div>
                        <div className="row justify-content-md-center">
                          <div className="col-6 text-center" id="objetivos-interesses--desc-usuario">
                            <img
                              alt="gif_lit"
                              className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                              src={GIF_LIT}
                            />
                          </div>

                          <div className="col-6 text-center" id="objetivos-interesses--desc-usuario">
                            <h5 style={{ color: "#27aae1" }} className="text-center">
                              Qual o seu momento profissional?
                            </h5>
                            <form onSubmit={this.createExperience}>
                              <div className="modalBody-container">
                                <div className="modal-body">
                                  <div className="form-group">
                                    <label>Posição atual ou última experiência</label>
                                    <Select
                                      name="profession"
                                      style={{ textAlign: "left" }}
                                      id="select-office"
                                      onChange={({ value }) => this.setState({ profession: value })}
                                      value={positions.filter(({ value }) => value === profession)}
                                      options={positions}
                                      placeholder="Cargo"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Nome da Empresa</label>
                                    <input
                                      className="form-control"
                                      placeholder="Nome da empresa"
                                      value={company}
                                      id="company-name"
                                      required
                                      onChange={evt =>
                                        this.setState({
                                          company: evt.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label>Data de início</label>
                                      <DatePicker
                                        className="form-control datePicker"
                                        placeholderText="Selecione a data de inicio"
                                        maxDate={moment()}
                                        selected={dateInit}
                                        id="start-date"
                                        onChange={this.handleChangeInit}
                                        required
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        todayButton="Hoje"
                                        selectsStart
                                        startDate={dateInit}
                                        endDate={dateEnd}
                                      />
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label>Data de término</label>
                                      {cargoAtual ? (
                                        <h6 className="blue">Até o momento</h6>
                                      ) : (
                                        <DatePicker
                                          className="form-control datePicker"
                                          placeholderText="Selecione a data de término"
                                          selected={dateEnd}
                                          onChange={this.handleChangeEnd}
                                          todayButton="Hoje"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          selectsEnd
                                          id="end-date"
                                          startDate={dateInit}
                                          endDate={dateEnd}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="form-check-label">
                                      <input
                                        className="form-check-input"
                                        checked={cargoAtual}
                                        type="checkbox"
                                        id="input-check"
                                        onChange={() =>
                                          this.setState({
                                            cargoAtual: !cargoAtual,
                                          })
                                        }
                                      />{" "}
                                      Este é meu cargo atual
                                    </label>
                                  </div>
                                  <div className="form-group">
                                    <label>Sobre a profissão</label>
                                    <textarea
                                      className="form-control"
                                      placeholder="Sobre essa experiência"
                                      rows="4"
                                      maxLength="200"
                                      value={aboutProfession}
                                      id="about-profession"
                                      onChange={evt =>
                                        this.setState({
                                          aboutProfession: evt.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="row justify-content-center" id="selecionar-personalizar">
                          <div className="d-flex justify-content-center col-2">
                            <button
                              id="btn-next"
                              type="button"
                              disabled={this.validationForm || loading}
                              onClick={this.saveForm}
                              className={`btn btn-blue btn-FontWeight6${loading && " btn-loading"}`}
                            >
                              Próximo passo
                            </button>
                          </div>
                          {this.onBoardIncomplete ? (
                            <div>
                              <button
                                id="btn-skip"
                                type='button'
                                onClick={this.nextStep}
                                className={`btn btn-blue btn-FontWeight6${loading && " btn-loading"}`}>
                                Pular passo
                              </button>
                            </div>
                          ) : '' }
                        </div>
                        <div>
                          <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <WatsonBadgeFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ExperienciaProfissional
