import styled from "styled-components";

export const UserLabel = styled.span`
  font-weight: bold;
  margin-right: 12px;
`;

export const TimeLabel = styled.span`
  color: #5f6368;
`;

export const MessageBlock = styled.div`
  margin: 16px 0;
`;
