import React from "react";
import Screen from "./screen";

export default ({
  groups,
  selectSearchOptions
}) => {
  return (
    <>
      <Screen
        groups={groups}
        selectSearchOptions={selectSearchOptions}
      />
    </>
  );
};
