import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  width: 240px;
  cursor: ${props => (props.available ? 'pointer' : 'not-allowed')} !important;
  background: white;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  margin: 10px 0;

  ${props =>
    props.highlights &&
    css`
      &:hover {
        background: ${lighten(0.4, `#${props.background}`)};
      }
    `}
`;

export const Content = styled.div`
  padding: 14px 14px 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const WrapperBoxDate = styled.div`
  background-size: cover;
  position: relative;
  height: 100px;
  background: #dfdfdf;

  background: ${props => {
    if (props.cover) {
      return `url("${props.cover}")`;
    }

    return `#${props.background}`;
  }};

  background-size: cover;
  background-position: center;
`;

export const BoxDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 10px 14px 0;

  width: 100%;
  height: ${props => (props.cover ? '30%' : '100%')};
  position: absolute;
  background: ${props => {
    if (props.cover) {
      return `linear-gradient(to bottom, rgba(248,248,248,0), rgba(248,248,248,1))`;
    }

    return `rgba(0,0,0,0.5)`;
  }};
  bottom: 0px;

  color: #fff;
`;

export const CardDate = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Day = styled.h4`
  border-radius: 50%;
  margin: 1px;
  padding: 1px;
  width: 20px;
  height: 25px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: ${props =>
    props.fontColor && !props.cover
      ? `#${props.fontColor}`
      : '#444 !important'};
  font-weight: bold;

  background: ${props => {
    switch (props.status) {
      case 'DONE':
        return 'green';
      case 'UP_TO_DATE':
        return '#27AAE1';
      case 'LATE':
        return '#de1626';
      default:
        return '';
    }
  }};
`;

export const ComplementDay = styled.p`
  font-size: 13px;
  color: ${props =>
    props.fontColor && !props.cover
      ? `#${props.fontColor}`
      : '#444 !important'};
  font-weight: bold;
  margin-bottom: 2px;
`;

export const ComplementEventName = styled.p`
  margin-top: 5px;
  font-size: 16px;
  color: ${props =>
    props.fontColor && !props.cover
      ? `#${props.fontColor}`
      : '#444 !important'};
  font-weight: 500;
  line-height: 1.35;
`;

export const Hours = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  flex: 1;
  margin: 0;
  margin-top: 0px;
`;

export const Message = styled.p`
  margin: 0;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 5px;
  text-align: left;
`;

export const ContainerPeriod = styled.div`
  display: flex;
  align-items: center;
  // visibility: ${props => (props.cover ? 'visible' : 'hidden')}
`;
