import React, { useEffect, useState } from 'react';
import Modal from "react-responsive-modal";
import logLit from "../../images/lit-azul.png";
import ViewEditor from '../shared/ViewEditor';
import Loading from "../Loading/Loading";
import _ from 'lodash';

const LOGO_HOME = require('../../images/logo_paul_idle_white.png');

function ModalWarningView({ 
    openModal, 
    onCloseModal,
    onClick,
    element,
    onOrganization
}) {

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [headerGradient, setHeaderGradient] = useState(true);

    const settings = JSON.parse(localStorage.getItem('settings'));
    const tenant = localStorage.getItem('tenant') || '';

    const { headerBackgroundColor, headerBackgroundGradient } = _.get(
        settings,
        `${tenant}`,
        {}
      );
  
      const renderBackgroundNav = () =>
        headerGradient ? headerBackgroundGradient || headerBackgroundColor : 'rgb(123, 197, 149)';
    
    return <Modal
            center
            open={openModal}
            onClose={onCloseModal}
            classNames={{ modal: "lit-modal-restrict modal-close-light" }}
            closeOnOverlayClick={false}>
                <div className="modal-content">
                    <div className="modal-header justify-content-start" 
                    style={{ background: renderBackgroundNav() }}>

                    <img src={LOGO_HOME} alt="HOME" />

                    {settings &&
                            settings[tenant] &&
                            settings[tenant].logoUrl ? (
                            <img
                                id="tenant-logo"
                                alt={settings[tenant].logoUrl}
                                src={settings[tenant].logoUrl}
                                style={{ height: 45 }}
                            />
                        ) : (
                            <img src={logLit} alt="logoLit" height={45} />
                        )}

                    </div>
                    <div className="modal-body" style={{ padding: "1em 7em 0 7em", height: 400, overflowX: 'auto' }}>
                    
                    <div class="mb-4 mt-4">
                        {!editorLoaded && !onOrganization
                        ? <Loading width={60} />
                        : <h2 className="font-weight-normal">
                            {element.name}
                        </h2>}
                        <ViewEditor data={element.message} onLoad={() => setEditorLoaded(true)} />
                    </div>

                    <div className="d-flex justify-content-between"></div>
                    </div>

                    <div className="modal-footer justify-content-center">
                        {editorLoaded 
                            && element.cta 
                                && <button
                                    className="btn btn-alert btn-alert-confirm"
                                    type="button"
                                    onClick={() => onClick(element.cta)}
                                    >
                                    Acessar link
                                    </button>}
                        <button
                        className="btn-alert"
                        type="button"
                        onClick={onCloseModal}
                        >
                        Fechar
                        </button>
                    </div>

                </div>
        </Modal>;
}

export default ModalWarningView;