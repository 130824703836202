import React from "react";
import {
  ICON,
  APPEARANCE,
  ORIENTATION,
  COLOR,
  ACTION,
  PAGE_INFORMATION,
  FONT_TYPE,
  SIZE,
  TYPES
} from "../../../constants";
import Notifications from "../../Sliders/NotificationSlider";
import Banners from "../../Sliders/BannerSlider";
import Members from "../../Sliders/MemberSlider";
import Evaluations from "../../Sliders/EvaluationSlider";
import NotificationCard from "../../basicComponents/NotificationCard";
import InfiniteScroll from "../../basicComponents/InfiniteScroll";
import Text from "../../basicComponents/Text";
import Block from "../../basicComponents/Block";
import Dropdown from "../../Inputs/Dropdown";
import { Filter, Cards } from "./styles";

export default ({
  perLine,
  feedItems,
  notifications,
  hasNextPage,
  nextPage,
  orderFilter
}) => {
  function showComponent(value) {
    switch (value.type) {
      case TYPES.CONTENT_SUGESTION:
        return (
          <NotificationCard
            type={value.type}
            title={value.title}
            contentCard={value.metadata.content}
            locked={value.metadata.locked}
            useful={value.metadata.useful}
            favorite={value.metadata.favorite}
            appearance={APPEARANCE.CARD}
          />
        );
      case TYPES.GROUP_SUGESTION:
        return (
          <NotificationCard
            type={value.type}
            title={value.title}
            contentCard={value.metadata.organization}
            locked={value.metadata.locked}
            useful={value.metadata.useful}
            favorite={value.metadata.favorite}
            appearance={APPEARANCE.CARD}
          />
        );
      case TYPES.COMMUNICATION:
        return (
          <NotificationCard
            type={value.type}
            title={value.title}
            contentCard={value.metadata.communication}
            locked={value.metadata.locked}
            useful={value.metadata.useful}
            favorite={value.metadata.favorite}
            context={value.metadata.context}
            appearance={APPEARANCE.CARD}
          />
        );
      case TYPES.LANE:
        return (
          <Banners
            title={value.title}
            buttonText={ACTION.VIEW_ALL}
            content={value.metadata.items}
            infinite={false}
          />
        );
      case TYPES.PEOPLE_SUGESTION:
        return <Members title={value.title} content={value.metadata.items} />;
      case TYPES.EVALUATION:
        return <Evaluations content={value.metadata.items} />;
      default:
        return (
          <Text
            text={PAGE_INFORMATION.FEED}
            fontType={FONT_TYPE.BOLD}
            size={SIZE.EXTRA_LARGE}
          />
        );
    }
  }

  return (
    <>
      <Block color={COLOR.BLOCK_LIGHT} orientation={ORIENTATION.AFASTABLE}>
        {notifications > 0 && (
          <Notifications
            buttonText={ACTION.MORE_NOTIFICATIONS}
            notification={notifications}
          />
        )}
        {feedItems.length !== 0 && [
          <Filter>
            <Dropdown
              text={ACTION.RECENTS}
              icon={ICON.DOWN_ARROW}
              options={orderFilter}
              appearance={APPEARANCE.FILTER}
            />
          </Filter>,
          <InfiniteScroll
            loadAction={nextPage}
            dataLength={feedItems.length}
            hasMore={hasNextPage}
          >
            <Cards perLine={perLine}>
              {feedItems.map(content => showComponent(content))}
            </Cards>
          </InfiniteScroll>
        ]}
      </Block>
    </>
  );
};
