import React, {useMemo} from 'react';

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";
import Header from "../LitAtomicComponents/Header";

import PageCalendar from './components/Calendar';

import * as Styles from './styles';
import imageDefault from '../../images/default-image.png';

import {
  SIZE,
  APPEARANCE,
} from "../NewSocial/constants";

const PAGES = {
  HOME: 'HOME',
  CALENDAR: 'CALENDAR',
  TIMELINE: 'TIMELINE'
}

function Eventos({turma, activePage, selectPage}) {

  return (
   <>
    <NavBarSuperior />
    <Styles.Container>
      <Header
        hasActionTop={false}
        textHeader="Calendário de eventos"
        size={SIZE.LARGE}
      />
  
      <Styles.Content>
        <PageCalendar />
      </Styles.Content>
     
    </Styles.Container>
   </>
  );
}

export default Eventos;
