import React from "react";
import { Hr } from "./styles";

export default ({ color }) => {
  return (
    <>
      <Hr color={color} />
    </>
  );
};
