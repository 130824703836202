import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import history from '../../../../utils/history'
import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions"
import { Container, Title } from './styles'
import {saveOnboardingSteps} from "../../../../business/onboarding";
import RecommendationBox from "../../../Recommendations/components/RecommendationBox";

class OnboardingPageRecommendations extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  saveForm = async () => {
    const { setOnbordingPageConcluded, index, onboardingData } = this.props
    
    /* await saveOnboardingSteps({
      "wx_step": "MGM"
    }); */

    setOnbordingPageConcluded(index, onboardingData[index]);
  };

  saveDataInState = () => {
    
  }

  navigate = (url) => {
    history.push(url);
  }

  render() { 
    return(
      <Container className="container">
        <Title>Indique seus amigos</Title>
        <div className="row">
          <div className="col-md-12">
           <RecommendationBox />
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  onboardingData: state.onboarding.onboardingData,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...OnboardingActions
}, dispatch)


export default connect(
    mapStateToProps, 
    mapDispatchToProps, 
    null, 
    {
      forwardRef: true
    }
)(OnboardingPageRecommendations)
