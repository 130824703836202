import React, { useEffect, useState } from 'react';
import ModalTermsPaymentSaintPaul from '../../../Modals/ModalTermsPaymentSaintPaul';
import { gateway } from '../../../../utils/api';
import * as Styles from './styles';
import ModalTermos from '../../../Modals/ModalTermos';

const textDefault = `A cobrança desse plano será feita imediatamente após a confirmação do
pagamento. No caso do plano de 1 ano e plano de 2 ano, pelo presente,
você autoriza o LIT a cobrar de você com recorrência automática no
vencimento até que você cancele a sua inscrição.`;

function TextPaymentDefault({ selectedPlan, paymentMethod, textPayment = textDefault }) {
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [openModalDefault, setOpenModalDefault] = useState(false);
  const [loadingTerm, setLoadingTerm] = useState(false);
  const [termPdfUrl, setTermPdfUrl] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const localStorageLoginTenant = localStorage.getItem('loginTenant');
  const saintPaulTenantText = 'A cobrança será feita imediatamente após a confirmação do pagamento.'
  
  const getTerm = async () => {
    try {
      setLoadingTerm(true);
      const { code } = selectedPlan;
      const findMethodPayment = selectedPlan.paymentMethods.find(
        item => item.type === paymentMethod
      );
      const body = {
        termTemplateCode: findMethodPayment.termTemplateReference,
        offerCode: code
      };
      const term = await gateway.post(
        `/webbff/lit_app_web/term/generatePaymentTerm`,
        body
      );
      setTermPdfUrl(term.ox_specific.wu_url);
    } catch (error) {
      setUserEmail(localStorage.getItem('ra'));
    } finally {
      setLoadingTerm(false);
    }
  };

  const checkModalTerms = () => {
    if (termPdfUrl || loadingTerm) {
      setShowModalTerms(true);
    } else {
      setOpenModalDefault(true);
    }
  };

  useEffect(() => {
    getTerm();
  }, []);

  return (
    <div>
      <Styles.TextCenter>
        Declaro que li e aceito os{' '}
        <Styles.LinkBlue href="javascript:void(0)" onClick={checkModalTerms}>
          termos e condições disponíveis aqui
        </Styles.LinkBlue>
        . {localStorageLoginTenant === 'SAINTPAUL' ? saintPaulTenantText : textPayment}
      </Styles.TextCenter>
      <ModalTermsPaymentSaintPaul
        termPdfUrl={termPdfUrl}
        visible={showModalTerms}
        loadingTerm={loadingTerm}
        onClose={() => setShowModalTerms(false)}
      />
      <ModalTermos
        visible={openModalDefault && !loadingTerm}
        userEmail={userEmail}
        onClose={() => setOpenModalDefault(false)}
        fullScreen={false}
        maxWidth="md"
        style={{ background: 'transparent', backgroundColor: 'transparent' }}
      />
    </div>
  );
}

export default TextPaymentDefault;
