/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import memoize from 'memoize-one';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import swal from '@sweetalert/with-react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import spinner from '../../images/paul_loading.gif';
import { Creators as CourseActions } from '../../store/ducks/newCourse/actions';
import { UserActions } from '../../store/ducks/user';
import history from '../../utils/history';
import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';
import WithSidebar from '../WithSidebar';
import ContentModalLitPass from '../shared/ContentModalLitPass';
import CourseAssessmentItem from './CourseAssessmentItem';
import CourseTree from './CourseTree';
import Disciplina from './Disciplina';
import Main from './Main';
import Progress from './Progress';
import ProgressMenuItem from './ProgressMenuItem';
import Sidebar from './Sidebar';
import SubNav from './SubNav';
import * as Styles from './styles';

import { gateway } from "../../utils/api";
import { ModalMessageCourse } from './ModalMessageCourse';

import NotaMediaFinal from './NotaMediaFinal';

const Loading = styled.div`
  z-index: 99;
  top: 66px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(0px);
  background: white;
`;

const SideMenuContainer = styled.div`

  .list-group, .item-disciplina{
    margin-bottom: 10px !important;
    border-radius: 5px !important;
    background: white;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  }

  .list-group-item{
    border: none !important;
    background-color: transparent;
    padding: 0 10px;
    margin-bottom: 10px !important;
  }

  .list-group-item_sub2{
    background-color: transparent !important;
  }
`;

const mediaQueryList = window.matchMedia('(min-width: 1000px)');

class Curso extends Component {
  state = {
    session: null,
    mql: mediaQueryList,
    open: false,
    docked: false,
    showRefreshButton: false,
    company: localStorage.getItem('company') || ' ',
    showModalMessageCourse: false,
    idCourseParam: null
  };

  selfAssessmentFilter = memoize(assessments =>
    (assessments || []).find(item => item.type === 'SELF_ASSESSMENT')
  );

  assessmentsFilter = memoize(assessments =>
    (assessments || []).filter(item => {
      return (
        item.type !== 'SELF_ASSESSMENT' && item.type !== 'EVALUATION_BOARD'
      );
    })
  );

  async componentDidMount() {
    const {
      match: {
        params: { idCurso }
      },
      getCourseRequest,
    } = this.props;
    const idProfile = localStorage.getItem('idProfile');

    getCourseRequest(idCurso, idProfile);
    this.setShowRefreshButton();

    mediaQueryList.addListener(this.eventScreenSize);
    this.setState({ mql: mediaQueryList, idCourseParam: idCurso });
    this.toggleSidebar();
  }

  saveProgress = async () => {
    const { course } = this.props;

    const idProfile = localStorage.getItem('idProfile');
    const courseSession = localStorage.getItem('session');

    let { code, courseCode, name, gradeAverage, courseGradeStatus, id } = course

    if (!courseCode) {
      courseCode = code
    }

    const data = {
      id,
      courseCode,
      name,
      gradeAverage,
      courseGradeStatus,
      action: 'EXIT',
      idProfile,
      courseSession
    }

    gateway.post('/webbff/lit_app_web/course_progress/', data);

    localStorage.removeItem('session');
  }

  componentWillUnmount() {
    const { resetCourse, setShowModal } = this.props;

    this.saveProgress()

    resetCourse();
    setShowModal(false);
    localStorage.removeItem('session');
    localStorage.removeItem('automaticRefresh');
    localStorage.removeItem('courseAccessed');
  }

  componentDidUpdate(prevProps) {
    const { refreshCourse, selectedCourseToShowProgress, course } = this.props;
    if (course !== prevProps.course) {
      if (course.showModalCourse) {
        this.handleShowModal();
      }
    }

    if (refreshCourse && prevProps.refreshCourse !== refreshCourse) {
      const {
        match: {
          params: { idCurso }
        },
        getCourseRequest
      } = this.props;
      const idProfile = localStorage.getItem('idProfile');
      getCourseRequest(idCurso, idProfile, !!selectedCourseToShowProgress);
      this.handleCloseProgress();
    }
  }

  handleShowModal = () => {
    this.setState({ showModalMessageCourse: true })
  }

  eventScreenSize = () => {
    const { mql, docked, open } = this.state;
    if (mql.matches) {
      if (docked || open) {
        this.setState({ docked: true, open: false });
      } else {
        this.setState({ docked: false, open: false });
      }
    } else if (docked || open) {
      this.setState({ docked: false, open: true });
    } else {
      this.setState({ docked: false, open: false });
    }
  };

  toggleSidebar = () => {
    const { mql, docked, open } = this.state;
    if (mql.matches) {
      this.setState({ docked: !docked });
    } else {
      this.setState({ open: !open });
    }
  };

  toggleProgress = () => {
    const { toggleProgress } = this.state;
    this.setState({ toggleProgress: !toggleProgress });
  };

  reloadCourseData = () => {
    const {
      match: {
        params: { idCurso }
      },
      reloadCourse
    } = this.props;
    const idProfile = localStorage.getItem('idProfile');

    reloadCourse(idCurso, idProfile);
  };

  openSelfAssessment = selfAssessment => {
    const {
      match: {
        params: { idCurso }
      }
    } = this.props;

    const crCode = _.get(selfAssessment, 'cr_code', '');
    const redirectRoute = `${window.location.origin}/assessment-waiting-room?type=${selfAssessment.type}&course=${idCurso}&code=${crCode}&courseCrCode=${idCurso}`;
    window.open(redirectRoute, '__blank');
  };

  onRedirect = course => {
    const id = course.idCourse || course.attach.id;
    swal.close();
    history.push({ pathname: `/curso/${id}` });
  };

  handleOpenProgress = course => {
    const { setCourseToShowProgress } = this.props;
    setCourseToShowProgress(course);
  };

  handleCloseProgress = () => {
    const { setCourseToShowProgress } = this.props;
    setCourseToShowProgress(null);
  };

  navigateContent = direction => {
    const {
      navigationContent,
      setCourseSelected,
      course,
      progress,
      warningDisplayed,
      setWarningDisplayed
    } = this.props;

    switch (direction) {
      case 'prev':
        if (navigationContent.prev) {
          setCourseSelected(navigationContent.prev);
        }
        break;
      case 'next':
        if (navigationContent.next) {
          if (
            !course.activeCourseSeason &&
            course.mustRequestCourseActivation
          ) {
            if (
              course.countFinishedObjects >= 2 &&
              !progress.countFinishedObjects &&
              !warningDisplayed
            ) {
              setWarningDisplayed(true);
              swal({
                content: <ContentModalLitPass redirect={this.onRedirect} />,
                buttons: {
                  cancel: {
                    text: 'Cancelar',
                    value: false,
                    visible: true,
                    className: 'btn-alert'
                  },
                  confirm: {
                    text: 'Confirmar',
                    value: true,
                    visible: true,
                    className: 'btn-alert btn-alert-confirm'
                  }
                }
              }).then(value => {
                if (value) {
                  setCourseSelected(navigationContent.next);
                }
              });
            } else if (
              progress.countFinishedObjects >= 2 &&
              !warningDisplayed
            ) {
              setWarningDisplayed(true);
              swal({
                content: <ContentModalLitPass redirect={this.onRedirect} />,
                buttons: {
                  cancel: {
                    text: 'Cancelar',
                    value: false,
                    visible: true,
                    className: 'btn-alert'
                  },
                  confirm: {
                    text: 'Confirmar',
                    value: true,
                    visible: true,
                    className: 'btn-alert btn-alert-confirm'
                  }
                }
              }).then(value => {
                if (value) {
                  setCourseSelected(navigationContent.next);
                }
              });
            } else {
              // setWarningDisplayed(true)
              setCourseSelected(navigationContent.next);
            }
          } else {
            setCourseSelected(navigationContent.next);
          }
        }
        break;
      default:
        break;
    }
  };

  setShowRefreshButton = () => {
    setTimeout(() => {
      this.setState({ showRefreshButton: true });
    }, 10000);
  };

  handleRefreshCourse = () => {
    window.location.reload();
  };

  handleCloseModalMessageCourse = () => {
    const { setShowModal } = this.props
    this.setState({ showModalMessageCourse: false })
    setShowModal(true)
  }

  render() {
    const { open, docked, mql, showRefreshButton, showModalMessageCourse, idCourseParam } = this.state;

    const {
      course,
      location,
      selected,
      courseLoading,
      courseError,
      navigationContent,
      resetErrorCourse,
      selectedCourseToShowProgress,
    } = this.props;

    let objectsList = [];
    if (course.type === 'COURSE') {
      const children = _.get(course, 'children', []);
      objectsList = children.reduce((acc, current) => {
        return [...acc, ...current.children];
      }, []);
    }

    const assessments = this.assessmentsFilter(course && course.assessments);
    if ((courseLoading || course.length === 0) && !courseError) {
      return (
        <div>
          <NavBarSuperior courses />
          <Loading>
            <img
              alt="loading"
              style={{
                maxHeight: '80%',
                animation: 'loading-spin infinite 10s linear'
              }}
              src={spinner}
            />
            <div style={{ textAlign: 'center', maxWidth: '800px', marginTop: -40, marginBottom: 40 }}>
              <p style={{ fontWeight: 'bold' }} className="no-margin">
                MONTANDO SEU CURSO
              </p>
              {showRefreshButton && (
                <Styles.TextRefreshCourse>
                  Está demorando muito para carregar a página?
                  <a onClick={this.handleRefreshCourse}>
                    Clique aqui para recarregar.
                  </a>
                </Styles.TextRefreshCourse>
              )}
              <p className="no-margin">
                Respire fundo 5 vezes e prepare sua mente para a aprendizagem!{' '}
              </p>
              <p className="no-margin">
                Com o mundo inteiro super conectado à internet, podemos
                enfrentar
              </p>
              <p className="no-margin">
                lentidão no carregamento, mantenha-se conectado.
              </p>
              <p className="no-margin">Bons Estudos!</p>
              <p className="no-margin">Time LIT</p>
            </div>
          </Loading>
        </div>
      );
    }
    if (courseError) {
      swal({
        content: (
          <div>
            <h1>Problema ao buscar o curso</h1>
            <p>Status: {courseError.status}</p>
            <p>Mensagem: {courseError.error}</p>
          </div>
        ),
        icon: 'warning'
      }).then(value => {
        if (value) {
          resetErrorCourse();
          history.push('/home');
        }
      });
      return (
        <div>
          <NavBarSuperior courses />
        </div>
      );
    }

    const propsSidebar = {
      docked,
      open,
      talks: location.state ? location.state.talks : false,
      sidebar: (
        <Sidebar
          idCourse={course && course.id}
          isTrails={false}
          selectSobre={this.selectSobre}
          tipo={selected && selected.typeContent}
        >

          <SideMenuContainer
            className="accordion"
            id="accordionExample">

            {course.type === "COURSE" && (
              <Disciplina id={course.id} data={{ ...course, index: 0 }} first={false} isTrail={course.type === "TRAIL"}>
                <div className="accordion" id="accordinUC">
                  <CourseTree
                    course={course}
                    data={course.children}
                    objectsList={objectsList}
                    assessmentsStatus={course.assessmentsStatus}
                    level={0}
                    openCourseProgress={this.handleOpenProgress}
                    closeCourseProgress={this.handleCloseProgress}
                    aboutProcess
                    idCourseParam={idCourseParam}
                  />
                </div>

                {course.type === 'COURSE' &&
                  !course.courseCertificationIsProgress && (
                    <ProgressMenuItem
                      data={course}
                      openCourseProgress={this.handleOpenProgress}
                    />
                  )}
              </Disciplina>
            )}

            {course.type === "TRAIL"
              && <><div className="accordion" id="accordinUC">
                <CourseTree
                  course={course}
                  data={course.children}
                  objectsList={objectsList}
                  assessmentsStatus={course.assessmentsStatus}
                  level={0}
                  openCourseProgress={this.handleOpenProgress}
                  closeCourseProgress={this.handleCloseProgress}
                  aboutProcess
                  idCourseParam={idCourseParam}
                />
              </div>

                {course.type === 'COURSE' &&
                  !course.courseCertificationIsProgress && (
                    <ProgressMenuItem
                      data={course}
                      openCourseProgress={this.handleOpenProgress}
                    />
                  )}

                {assessments &&
                  assessments.map(item => {
                    const {
                      match: {
                        params: { idCurso }
                      }
                    } = this.props;

                    return (
                      <CourseAssessmentItem
                        data={item}
                        courseCrCode={idCurso}
                        assessmentsStatus={course.assessmentsStatus}
                      />
                    );
                  })}</>}

            {course.gradeAverage !== null && (
              <NotaMediaFinal
                course={course}
              />
            )}

          </SideMenuContainer>
        </Sidebar>
      ),
      navbar: <NavBarSuperior courses reviewSmartBar />,
      subnav: (
        <SubNav
          sidebarOpened={open || docked}
          toggleSidebar={this.toggleSidebar}
          selected={selected}
          navigateContent={this.navigateContent}
          navigationContent={navigationContent}
        />
      ),
      shadow: false,
      loading: false
    };
    return (
      <WithSidebar
        isNewCourse
        hasPaul={false}
        hasAssessment={false}
        {...propsSidebar}
        course={course}
        reloadCourseData={this.reloadCourseData}
      >
        <div
          className={mql.matches ? 'container-fluid' : 'container'}
          style={{ backgroundColor: '#F2F4F7' }}
        >
          {selectedCourseToShowProgress && (
            <Progress course={selectedCourseToShowProgress} />
          )}

          {!selectedCourseToShowProgress && (
            <div className="row">
              <Main
                tags={selected.tags}
                course={course}
                selected={selected} />
            </div>
          )}

          {showModalMessageCourse && (
            <ModalMessageCourse
              courseCode={course.code}
              showModal={showModalMessageCourse}
              closeModal={this.handleCloseModalMessageCourse}
            />
          )}
        </div>
      </WithSidebar>
    );
  }
}

const mapStateToProps = state => ({
  course: state.newCourse.data,
  progress: state.newCourse.progress,
  courseLoading: state.newCourse.loading,
  courseError: state.newCourse.error,
  selected: state.newCourse.selected,
  navigationContent: state.newCourse.navigationContent,
  warningDisplayed: state.newCourse.warningDisplayed,
  refreshCourse: state.newCourse.refreshCourse,
  selectedCourseToShowProgress: state.newCourse.selectedCourseToShowProgress,
  notShowModal: state.newCourse.notShowModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...CourseActions, ...UserActions.ProfileActions },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Curso);
