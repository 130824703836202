import React from "react";
import Dropdown from "../../basicComponents/DropdownBlock";
import DropdownHeader from "../../basicComponents/DropdownToggle";
import DropdownContent from "../../basicComponents/DropdownContainer";
import DropdownOption from "../../basicComponents/DropdownItem";
import Clickable from "../../basicComponents/Clickable";
import {} from "./styles";

export default ({ icon, text, options, color, appearance }) => {
  return (
    <>
      <Dropdown color={color} appearance={appearance}>
        <DropdownHeader text={text} icon={icon} />
        <DropdownContent>
          {options.map(item => (
            <Clickable onClick={item.action}>
              <DropdownOption option={item.description} />
            </Clickable>
          ))}
        </DropdownContent>
      </Dropdown>
    </>
  );
};
