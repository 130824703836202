import styled, { css } from 'styled-components'
import Select from "react-select"

/* GENIAL-KRXJ-TTFK */
// FLEURY-F6EW-9TU3

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  height: 100%;
  background: #fff;
`

export const Cards = styled.div`
  width: 100%;
  height: auto;
  /* display: flex; */

  .geRExE {
    height: 370px;
  }

  
  .chJasC {
    margin-left: 0;
  }

  .buYiar .sc-bxivh {
    width: auto;
    background: red;
  }

  .sc-bxivhb {
    width: auto;
    padding: 6px;
    box-sizing: border-box;
    margin: 0;
    & + div {
      margin-left: 10px;
    }
  }

  .bQHzTl {
    width: auto;
  }

  .sc-htpNat {
    display: flex;
    justify-content: ${props => props.qtdItems <= 3 ? 'center' : ''}
  }

  .evoJai {
    width: auto;
  }

  .dsPhXN {
    margin-left: 0;
  }
`

export const SelectTypePlans = styled.select`
  background: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #f1f1f1 !important;
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
`

export const ContainerPlanTypes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 430px;

  @media (max-width: 673px) {
    width: 100%;
    flex-direction: column;
  }

`

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const ButtonAction = styled.button`
  padding: 4px 10px;
  border-radius: 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid transparent;
`

export const ButtonConfirm = styled(ButtonAction)`
  background: white;
  color: #27aae1;
  border: 2px solid #27aae1;
  &:hover {
    /* background-image: linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%); */
    background: #27aae1;
    color: white;
  }
`

export const ButtonPlan = styled.div`
  background: ${props => props.disabled ? '#c4c0c0' : 'linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%)'};
  border-radius: 30px;
  /* width: 10rem; */
  height: 44px;
  width: 200px;
  border: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: 2px;

  &:focus {
    outline: none;
    border: none;
  }

  /* &:hover {
    background-color: #02c4fd;
    background: #02c4fd;
  } */

  &:disabled {
    background: #c4c0c0;
  }

  button {
    width: 100%;
    border: none;
    border-radius: 30px;
    background: ${props => props.active ? 'rgba(142, 230, 123, 1)' : '#fff'};
    border-radius: 30px;
    flex: 1;
    cursor: pointer;

    &:hover {
      background: rgba(142, 230, 123, 1);
      span {
        text-transform: uppercase;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    span {
      font-size: 16px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      background: ${props => props.active ? '#fff' : '-webkit-linear-gradient(0deg,rgba(2,196,255,1),rgba(142,230,123,1))'};
      background: ${props => props.active ? '#fff' : 'linear-gradient(0deg,rgba(2,196,255,1),rgba(142,230,123,1))'};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }

  }
`

export const ButtonNext = styled.div`
  background: ${props => props.disabled ? '#c4c0c0' : 'linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%)'};
  border-radius: 30px;
  /* width: 10rem; */
  height: 3.8rem;
  width: ${props => props.width ? `${props.width}` : '80%'};
  border: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: 2px;
  position: ${props => props.noposition ? 'none' : 'absolute'};
  bottom: ${props => props.promo ? '30px' : '20px'};

  &:focus {
    outline: none;
    border: none;
  }

  /* &:hover {
    background-color: #02c4fd;
    background: #02c4fd;
  } */

  &:disabled {
    background: #c4c0c0;
  }

  button {
    width: 100%;
    border: none;
    border-radius: 30px;
    background: ${props => props.disabled ? '#c4c0c0' : '#fff'};
    border-radius: 30px;
    flex: 1;
    cursor: pointer;

    &:hover {
      background: ${props => props.disabled ? '#c4c0c0' : 'linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%)'};
      span {
        text-transform: uppercase;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    span {
      font-size: 16px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      background: ${props => props.disabled ? '#fff' : '-webkit-linear-gradient(0deg,rgba(2,196,255,1),rgba(142,230,123,1))'};
      background: ${props => props.disabled ? '#fff' : 'linear-gradient(0deg,rgba(2,196,255,1),rgba(142,230,123,1))'};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }

  }
`


export const PlanItem = styled.div`

  width: ${props => props.promo ? '320px' : '300px'};
  min-height: ${props => props.promo ? '460px' : '440px'};
  margin-top: ${props => props.promo ? '0' : '10px'};
  /* margin-right: 10px; */
  position: relative;
  /* overflow: auto; */
  /* display: flex;
  justify-content: space-between; */
  /* align-items: center; */
  /* flex-direction: column; */
  box-shadow: 0 0 6px rgba(0,0,0,.1);
  background: ${props => props.selected ? 'rgba(2,196,255,1)' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#000'};
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 40px 30px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  input {
    width: 100%;
    outline: 0;
  }
  

  ${props =>
    props.voucher &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .box-loading {
        height: 270px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        font-size: 14px;
        margin-top: 10px;
        line-height: 1.3;
        color: ${props.selected ? '#fff' : 'inherit'} !important;
      }
    
      input[type="text"] {
        color: ${props.selected ? '#fff' : 'inherit'} !important;
        margin-bottom: 5px;
      }

      button {
        background: ${props.selected && '#fff'} !important; 
      }
    `
  }

/* hr {
  margin: 0;
} */

${props =>
    props.selected &&
    css`
      hr {
        border-color: #fff
      }
    `
  }

  &::-webkit-scrollbar {
    background-color:#fff;
    width:16px;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-track {
    background-color:#fff;
  }

 &::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
      background-color:#babac0;
      border-radius: 16px;
      border:5px solid #fff
  }
  &::-webkit-scrollbar-thumb:hover {
      background-color:#a0a0a5;
      border:4px solid #f4f4f4
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {display:none}

  &:hover {
    border: 1px solid ${props => props.selected ? '#fff' : 'rgba(2,196,255,1)'};
  }

  h2 {
    font-size: 14px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    b {
      display: block;
      text-transform: capitalize;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
    }
    span {
      text-transform: uppercase;
    }
  }

  h3 {
    width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 0;
    border-bottom: 1px solid #e3e5eb;
    padding-bottom: 15px; 
    
    span {
      display: flex;

      justify-content: center;
      b {
        font-size: 40px;
        font-weight: 400;
      }
      small {
        font-size: 11px;
        font-weight: 400;
        display: flex;
        /* background: red; */
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 7px;
        padding-left: 2px;
      }

      & + span {
        margin-top: 5px;
      }

      
    }
   
    span {
      font-size: 11px;
    }

    small {
      font-size: 12px;
    } 
  }

  ul {
    margin-top: 10px;
    padding: 0;
    li {
      font-size: 14px;
      svg {
        color: ${props => props.selected ? '#fff' : 'rgba(2,196,255,1)'};
      }
      & + li {
        margin-top: 5px;
      }
    }
  }

  span.text-card {
    color: #fff;
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 14px;
  }
`


export const ContainerButtonCertificate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin-right: 10px;
  }
`