import React, { useState, memo, useEffect } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import screenfull from "screenfull"
import Tooltip from "../Tooltip"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`

const PdfViewer = ({ onPageChange, url, initialPage = 1 }) => {
  
  const [page, setPage] = useState(initialPage)
  const [pages, setPages] = useState(null)
  const [error, setError] = useState(false)
  const [scale, setScale] = useState(1.5)

  useEffect(() => {
    setPage(initialPage)
  }, [initialPage])

  const changePage = offset => {
    let endOfFile = false
    const newPage = page + offset
    setPage(newPage)
    if (newPage === pages) {
      endOfFile = true
    }
    onPageChange(newPage, endOfFile)
  }

  const markUp = async () => {
    // screenfull
    const pdf = document.getElementById("pdf-div-id")
    screenfull.onchange(() => {
      const canvas = document.getElementsByTagName("canvas")
      const pagination = document.getElementById("navPagination")
      const pdfPage = document.getElementsByClassName("react-pdf__Document")
      const childrenPage = pdfPage[0].children[0]

      if (screenfull.isFullscreen) {
        canvas[0].style.width = null
        childrenPage.classList.remove('PDFPage')
        pagination.style.width = "100%"
      } else {
        pagination.removeAttribute("style")
        childrenPage.classList.add('PDFPage')
        canvas[0].style.width = "100%"
      }
    })
    screenfull.request(pdf)
    if (screenfull.isFullscreen) {
      screenfull.off("change", () => {})
      screenfull.exit()
    }
  }

  const zoomIn = () => {
    const pagination = document.getElementById("navPagination")
    const canvas = document.getElementsByTagName("canvas")
    canvas[0].width = pagination.clientWidth
    setScale(scale + 0.5)
  }

  const zoomOut = () => {
    const pagination = document.getElementById("navPagination")
    const canvas = document.getElementsByTagName("canvas")
    canvas[0].width = pagination.clientWidth
    setScale(scale - 0.5)
  }

  const renderPagination = (nextPage, nextPages) => {
    let previousButton = (
      <li data-for="documento" data-tip="Retornar" className="page-item">
        <a className="page-link" role="button" onClick={() => changePage(-1)}>
          <i className="fa fa-caret-left" aria-hidden="true" />{" "}
        </a>
      </li>
    )

    if (nextPage === 1) {
      previousButton = (
        <li data-for="documento" data-tip="Retornar" className="page-item disabled">
          <a className="page-link " role="button">
            <i className="fa fa-caret-left" aria-hidden="true" />{" "}
          </a>
        </li>
      )
    }
    let nextButton = (
      <li data-for="documento" data-tip="Adiantar" className="page-item">
        <a className="page-link " role="button" onClick={() => changePage(1)}>
          {" "}
          <i className="fa fa-caret-right" aria-hidden="true" />
        </a>
      </li>
    )
    if (nextPage === nextPages) {
      nextButton = (
        <li data-for="documento" data-tip="Adiantar" className="page-item disabled">
          <a className="page-link" role="button">
            {" "}
            <i className="fa fa-caret-right" aria-hidden="true" />
          </a>
        </li>
      )
    }
    const fullScreen = (
      <a
        data-for="documento"
        data-tip="Expandir para tela inteira"
        role="button"
        className="markUp align-self-center"
        onClick={markUp}
      >
        {" "}
        <i className="fa fa-arrows-alt" aria-hidden="true" />
      </a>
    )

    const downloadDoc = (
      <a
        data-for="documento"
        data-tip="Download PDF"
        role="button"
        className="markUp align-self-center"
        href={url}
        download
        rel="noopener noreferrer"
        target="_blank"
      >
        {" "}
        <i className="fa fa-download" aria-hidden="true" />
      </a>
    )

    const quantity = (
      <p className="align-self-center">
        {page} de {pages}
      </p>
    )

    return (
      <nav id="navPagination" className="bg-dark-grey">
        <Tooltip id="documento" />
        <ul className="pagination justify-content-center">
          {previousButton}
          {quantity}
          {nextButton}
          <div className="btn-group">
            <button type="button" className="btn btn-pdf" onClick={() => zoomOut()}>
              <i className="fa fa-search-minus" aria-hidden="true" />
            </button>
            <button type="button" className="btn btn-pdf" onClick={() => zoomIn()}>
              <i className="fa fa-search-plus" aria-hidden="true" />
            </button>
          </div>
          {downloadDoc}
          {fullScreen}
        </ul>
      </nav>
    )
  }

  const onDocumentComplete = ({ numPages }) => {
    setPages(numPages)
  }

  const onLoadError = () => {
    setError(true)
  }

  useEffect(() => {
    if (page === pages) {
      onPageChange(page, true)
    }
  }, [onPageChange, page, pages])

  return (
    <div>
      <Document
        file={url}
        error="Não foi possível carregar o PDF no momento."
        onLoadSuccess={onDocumentComplete}
        onLoadError={onLoadError}
      >
        <Page className="PDFPage" pageNumber={page} scale={scale} />
      </Document>
      {!error && renderPagination(page, pages)}
    </div>
  )
}

PdfViewer.defaultProps = {
  onPageChange: () => {},
}

export default memo(PdfViewer)
