import styled, { css } from "styled-components";
import { } from "../../../constants";

export const Span = styled.span`
  clear: both;
  display: block;
  max-width: 100%;
  white-space: break-spaces;
  label:first-of-type {
    -webkit-line-clamp: 2;
    min-height: 30px;
    margin: 0 0 -5px;
    padding-top: 5px;
  }
  label:last-of-type {
    -webkit-line-clamp: 2;
    min-height: 30px;
    margin: 0 0 -5px;
    padding-top: 8px;
    line-height: 1;
  }
`;

export const ButtonAtBottom = styled.span`
  position: absolute;
  bottom: 0;
  width: 90%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
