/* eslint-disable react/no-array-index-key */
import React, { useCallback, useRef } from "react";
import Timer from "react-compound-timer";

import * as Styles from "./styles";
import Slider from "../Slider";

function Header({
  onChange,
  onFinish,
  currentValue,
  concludes,
  notCompleted,
  limitTime,
  numberOfQuestions,
  allowChange
}) {
  const formatValue = useCallback(text => {
    return text.toString().length > 1 ? text : `0${text}`;
  }, []);

  const renderHint = useCallback(
    index => {
      let text = "";
      if (concludes.includes(index)) {
        text = "Pergunta respondida";
      }
      if (notCompleted.includes(index)) {
        text = "Pergunta não respondida";
      }
      return text;
    },
    [concludes, notCompleted]
  );

  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.BoxTimerWithNumberQuestions>
          <Timer
            initialTime={limitTime * 1000}
            direction="backward"
            checkpoints={[
              {
                time: 0,
                callback: onFinish
              }
            ]}
          >
            {() => (
              !!limitTime &&
              <Styles.TextTimer>
                <span>Tempo Restante</span>
                <Timer.Hours formatValue={formatValue} />:
                <Timer.Minutes formatValue={formatValue} />:
                <Timer.Seconds formatValue={formatValue} />
              </Styles.TextTimer>
            )}
          </Timer>

          <Styles.NumberOfQuestions>
            <span>Questões respondidas</span>
            {concludes.length}/{numberOfQuestions}
          </Styles.NumberOfQuestions>
        </Styles.BoxTimerWithNumberQuestions>

        <div className="parent">
          <Slider currentValue={currentValue}>
            {Array(numberOfQuestions)
              .fill()
              .map((item, index) => (
                <Styles.Button
                  title={renderHint(index)}
                  disabled={!allowChange}
                  onClick={() => {
                    if (allowChange) {
                      onChange(index);
                    }
                  }}
                  key={index}
                  innerRef={useRef()}
                  current={currentValue === index}
                  concluded={concludes.includes(index)}
                  notCompleted={notCompleted.includes(index)}
                >
                  {index + 1}
                </Styles.Button>
              ))}
          </Slider>
        </div>
      </Styles.Content>
    </Styles.Container>
  );
}

export default Header;
