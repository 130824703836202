import React from 'react';

// import { Container } from './styles';

import * as Styles from './styles'


function Warning({children, onClick, ...rest}) {
  return (
    <Styles.WarningContent onClick={onClick} {...rest}>
      {children}
    </Styles.WarningContent>
  )
}

export const WarningText = Styles.WarningText
export default Warning;
