import React, { useState } from "react";

import notify from '../notify';
import { gateway } from "../../../utils/api";
import { CouponContainer } from "./styles";

export default ({ coupon, onCouponCreated }) => {
  const [loading, setLoading] = useState(false);
  
  const generateCupom = async () => {
    setLoading(true);
    
    try {
      const response = await gateway.post('recommendation/mgm')
      const { ox_recommendedreference: { rk_reference: cupom } } = response
      
      onCouponCreated(cupom);
      
      setLoading(false);
      notify('Cupom gerado com sucesso!');
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }
  
  return <>
    {!coupon && <CouponContainer>
      <button
        type="button"
        onClick={generateCupom}
        disabled={coupon}
        className={`btn btn-blue ${loading && "btn-loading"}`}
      >
        Gerar cupom
      </button>
    </CouponContainer>}
  </>
}
