import React from "react";
import { ICON, COLOR, SIZE, ORIENTATION } from "../constants";

import Header from "../../LitAtomicComponents/Header";
import PageContent from "./components/PageContent";
import Actions from "../../LitAtomicComponents/Actions";
import Menu from "../../LitAtomicComponents/Menu";

import {} from "./styles";

export default ({
  logo,
  menuNavigation,
  groups,
  selectSearchOptions,
  openSearch,
  setOpenSearch
}) => {
  return (
    <>
      <Header
        logoPage={logo}
        size={SIZE.SMALL}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        topRight={[
          <Actions
            itemsToRender={[
              {
                type: "icon",
                icon: ICON.HEART,
                action: null,
                size: SIZE.MEDIUM
              },
              {
                type: "icon",
                icon: ICON.BELL,
                action: null,
                size: SIZE.MEDIUM
              },
              {
                type: "icon",
                icon: ICON.SEARCH,
                openSearch: openSearch,
                action: () => {
                  setOpenSearch(true);
                },
                size: SIZE.MEDIUM
              }
            ]}
          />
        ]}
        topLeft={[
          <Actions
            itemsToRender={[
              {
                type: "icon",
                icon: ICON.MENU_BARS,
                action: null,
                size: SIZE.MEDIUM
              }
            ]}
          />
        ]}
        topMenu={[
          <Menu
            orientation={ORIENTATION.HORIZONTAL}
            color={COLOR.LIGHT}
            options={menuNavigation}
            size={SIZE.SMALL}
          />
        ]}
      />
      <PageContent groups={groups} selectSearchOptions={selectSearchOptions} />
    </>
  );
};
