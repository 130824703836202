import styled from 'styled-components';

export const TooltipContainer = styled.div`
    div[aria-expanded="false"]::after {
        content: " \f107";
        font-family: FontAwesome;
        display: flex;
        text-align: center;
        padding-left: 16px;
        right: 15px;
        position: relative;
        align-items: center;
        color: #27AAE1;
        font-size: 30px;
    }
    
    div[aria-expanded="true"]::after {
        content: " \f106";
        font-family: FontAwesome;
        display: flex;
        text-align: center;
        padding-left: 16px;
        right: 15px;
        position: relative;
        align-items: center;
        color: #27AAE1;
        font-size: 30px;
    }

    div[data-icon-numeric="false"]::after {
        content: " \f105";
        font-family: FontAwesome;
        display: flex;
        text-align: center;
        padding-left: 16px;
        right: 15px;
        position: relative;
        align-items: center;
        color: #27AAE1;
        font-size: 30px;
    }
`

export const Container = styled.div`
    padding: 0px;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    background: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, .1);
    border-radius: 5px;
    margin-bottom: 15px;
`

export const ContainerIcon = styled.div`
    padding: 10px;
    background-color: #27AAE1;
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-right: 10px;
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-basis: 15%;
    justify-content: center;
`

export const ItemDescription = styled.div`
    padding: 20px 0;
    flex-basis: 85%;
    display: flex;
    color: #4A5057;
    flex-direction: column;
`

