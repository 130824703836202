/* eslint-disable camelcase */
import React from 'react'

import {Container, Text} from './styles'

export default function Info({
     wx_company,
     wx_office,
     wx_description,
     dx_dateinit, 
     dx_dateend, 
     bf_mainjob
  }){
  return(
    <Container>
      <Text mainJob={bf_mainjob}>{wx_office} em {wx_company}</Text>
      <Text>{wx_description}</Text>
      <Text>De {dx_dateinit} a {dx_dateend || 'Até o momento'}</Text>  
    </Container>
  )
}