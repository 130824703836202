import React from "react";

import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import { makeStyles } from '@material-ui/core/styles';

import useMute from '../hooks/useMute'
import ActionButton from "../ActionButton";

export default React.memo(() => {
  const [muted, toggleMuted] = useMute('audio')

  const classes = makeStyles(() => {
    return {
      successIcon: {
        color: '#4caf50',
      },
      errorIcon: {
        color: '#F44336',
      },
    }
  })();

  return (
    <ActionButton
      edge="start"
      aria-label={muted ? "Ligar microfone" : "Desligar microfone"}
      title={muted ? "Ligar microfone" : "Desligar microfone"}
      onClick={toggleMuted}>
      {muted ? <MicOffIcon className={classes.errorIcon}/> : <MicIcon className={classes.successIcon}/>}
    </ActionButton>
  );
})
