import React, { memo, useState, useEffect } from "react";
import Img from "react-image";
import { Link } from "react-router-dom";

import placeholder from "../../../images/placeholder-avatar.png";

import { randomNumber, API_URL_IMAGES } from "../../../utils/constants";
import NoImage from "./NoImage";

import api from "../../../utils/api";

const MembroSmall = ({ user, idGroup }) => {
  const [detailUser, setDetailUser] = useState({});

  const fetchDetails = async () => {
    const { idProfile } = user;
    try {
      const response = await api.get(
        `getDatailOfProfile/${idProfile}/${idGroup}`
      );
      setDetailUser(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const imgUrl = user.linkPhoto
    ? `${API_URL_IMAGES}images/profile/${user.idProfile}.jpg`
    : placeholder;
  return (
    <li
      key={randomNumber()}
      className="list-group-item"
      style={{
        border: "none",
        display: "flex",
        alignItems: "center",
        paddingRight: 0
      }}
    >
      <Link
        to={`/${detailUser.teacher ? "perfilProfessor" : "perfilPublico"}/${
          user.idProfile
        }`}
      >
        <Img
          src={imgUrl}
          unloader={
            <NoImage
              tip={user.name}
              style={{
                maxWidth: "70px",
                minHeight: "70px",
                maxHeight: "70px",
                minWidth: "70px"
              }}
              className="rounded-circle img-fluid mr-2"
            />
          }
          className="rounded-circle img-fluid mr-2"
          data-tip={user.name}
          style={{
            maxWidth: "70px",
            minHeight: "70px",
            maxHeight: "70px",
            minWidth: "70px"
          }}
        />
      </Link>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column"
        }}
      >
        <Link
          className="member"
          style={{ textAlign: "left" }}
          to={`/${detailUser.teacher ? "perfilProfessor" : "perfilPublico"}/${
            user.idProfile
          }`}
        >
          {user.name}

          {detailUser && (
            <div
              style={{
                fontSize: "75%",
                color: "black"
              }}
            >
              {detailUser.typeMember === 1 ? "Admin" : "Membro"} -{" "}
              {detailUser.teacher ? "Professor" : "Aluno"}
              <br />
              {detailUser.company}
              <br />
              {detailUser.office}
            </div>
          )}
        </Link>
      </div>
    </li>
  );
};

export default memo(MembroSmall);
