import styled, { css } from "styled-components";
import { Card } from "react-bootstrap";
import { APPEARANCE } from "../../../constants";

export const CardContent = styled(Card.Header)`

  ${props => (props.appearance === APPEARANCE.POST) && css`
    background: var(--timeline-card-header);
    color: var(--post-text);
    border: 0;
    padding: 15px 0;
    border-radius: 7px;
    position: relative;
    &:hover, &:focus { background: var(--timeline-card-header); }
    button {
      background: var(--timeline-card-header);
      border: 0;
      padding: 0 0 0 15px;
      width: 90%;
      text-align: left;
      float: left;
      a { display: inline-block; max-width: 50px; float: left; }
      li > span { width: calc(100% - 60px); }
      label { 
        vertical-align: top;
        width: 100%;
        min-height: 12px;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: left;
        margin: 0;
      }
      &:hover,
      &:focus {
        background: var(--timeline-card-header);
        border: 0;
        outline: none;
        box-shadow: none;
      }
      @media screen and (max-width: 991px) {
        padding: 0 0 1rem 1rem;
      }
      @media screen and (max-width: 579px) {
        width: 80%;
      }
    }
    .dropdown-menu span {
      &:hover a { color: var(--dropdown-primary-hover)!important; }
      a { pointer-events: none!important; }
    }
    .dropdown i {
      width: 100%;
      text-align: center;
    }
  `}
  
`;
