export const sendWhats = (voucher, name, phone) => {
  const phoneNumber = phone ? phone.replace(/\D/g, "") : ""
  const text = `
  Oi ${name}, tudo bem? Com esse código exclusivo: ${voucher}, você terá até 35% de desconto nos cursos online da Saint Paul na Plataforma LIT. Eu estou fazendo diversos cursos e recomendo!
  Inscreva-se pelo site https://app.lit.com.br?${voucher} e teste por 7 dias grátis.`

  const width = window.window.innerWidth * 0.9
  const height = window.window.innerHeight
  /* eslint-disable no-mixed-operators */
  const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2
  const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2
  /* eslint-enable no-mixed-operators */
  const config = {
    height,
    width,
    left,
    top,
    location: "no",
    toolbar: "no",
    status: "no",
    directories: "no",
    menubar: "no",
    scrollbars: "yes",
    resizable: "no",
    centerscreen: "yes",
    chrome: "yes",
  }
  const url = `https://api.whatsapp.com/send?phone=55${phoneNumber}&text=${window.encodeURIComponent(
    text
  )}`
  window.open(
    url,
    "",
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(", ")
  )
}
