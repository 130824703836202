import React, { memo } from "react"

import PrivateCard from "../Home/PrivateCard"

import * as Styles from './styles'


const CardBook = ({ result: book, goToBook }) => {
  const libraryUser = JSON.parse(localStorage.getItem("libraryUser"))
  const naRealUser = JSON.parse(localStorage.getItem('naRealUser'))

  return book ? (
    <React.Fragment>
      {book.wu_url && book.wu_url.length ? (
        <div role="button" style={{width: '100%'}} className="card-wrapper-p" onClick={() => goToBook(libraryUser, book)}>
          <div className="card">
          
            {book.wu_url && (
              <img src={book.wu_url} className="img-fluid" alt="book" style={{
                width: "100%",
                height: "300px",
              }} />
            )}
            
            <div className="card-body" style={{ padding: ".6rem 1rem" }}>
              <h5 className="card-subtitle mb-2 text-muted" style={{width: '100%'}}>
                {(!libraryUser || naRealUser) && <PrivateCard fontSize={20} />} {book.ws_name}
              </h5>

              <p className="card-text" style={{ margin: 'auto'}}>
                {" "}
                {book.editor} {book.publishedDate ? ` - ${book.publishedDate}` : ""}
              </p>
              {book.author && <p className="card-text">Autor: {book.author}</p>}
            </div>
          </div>
        </div>
      ): (
        <div role="button" style={{width: '100%'}} className="card-wrapper-p" onClick={() => goToBook(libraryUser, book)}>
          <Styles.NoImagemBook className="card">
            <div className="info">
              <h5 className="card-subtitle mb-2 text-muted" style={{width: '100%'}}>
                {book.ws_name}
              </h5>
            </div>
            <div id="author">
              {(!libraryUser || naRealUser) && <PrivateCard fontSize={20} />} 
              <p className="card-text" style={{ margin: 'auto'}}>
                {" "}
                {book.editor} {book.publishedDate ? ` - ${book.publishedDate}` : ""}
              </p>
              {book.author && <p className="card-text">Autor: {book.author}</p>}
            </div>
           
          </Styles.NoImagemBook>
        </div>
        
      )}
      
    </React.Fragment>
  ) : (
    null
  )
}

export default memo(CardBook)
