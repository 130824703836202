import styled, { css } from "styled-components";
import { ORIENTATION, COLOR } from "../../../constants";

export const ListItem = styled.li.attrs({})`

  & + li {
    margin-top: 10px;
  }
  list-style-type: none; 
  clear: both; 
  page-break-inside: avoid;
  img {
    margin-right: 10px;
  }

  ${props => (props.orientation === ORIENTATION.HORIZONTAL) && css`
    display: inline-flex;
    align-items: center;
  `}

  ${props => (props.orientation === ORIENTATION.VERTICAL) && css`
    display: inline-block;
  `}

  ${props => (props.hiddenContent) && css`
    display: none!important;
  `}
  
`;

export const Span = styled.span.attrs({
  className: 'adjustable'
})`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;

  ${props => (props.color === COLOR.LIGHT) && css`
    * { color: var(--white); }
  `}

  ${props => (props.listButtons) && css`
    width: calc(100% - 290px);
  `}

`;

export const ButtonsDiv = styled.div`
  float: right;
  button {
    margin-left: 5px;
  }
`;

export const Inline = styled.div`
  display: inline-block;
  label { 
    margin: 0 3px;
  }
`;
