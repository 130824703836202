import React, { useState, useEffect, Suspense, lazy, useMemo } from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

import _ from 'lodash';
import { gateway } from '../../../utils/api';
import Loading from '../../Loading/Loading';
import { API_URL_IMAGES } from '../../../utils/constants';

import showToast from '../../../utils/showToast';
import Timeline from '../../LitAtomicComponents/Contents/Timeline';
import LoadingBar from '../../LitAtomicComponents/basicComponents/LoadingBar';

const Duvidas = lazy(() => import('../../Duvidas/index'));

const Tabs = styled.ul`
  padding: 0;
`;

const TabContent = styled.div`
  background: transparent;
  border-top: none;

  #timeline {
    background-color: transparent !important;
    height: auto !important;
  }

  #comentarios span.block-light {
    padding-top: 20px !important;
  }
`;

const Infinite = styled.div`
  .infinite-scroll-component {
    max-height: 60vh;
  }
`;

function Extra({ selected }) {
  const [logoCompany, setLogoCompany] = useState(null);
  const [isKnowMore, setKnowMore] = useState(false);
  const [isSocialUser, setSocialUser] = useState(true);
  const [isDoubts, setDoubts] = useState(false);
  const [communications, setCommunications] = useState([]);
  const [communicationsPage, setCommunicationsPage] = useState(1);
  const [interationsModalIsOpen, setInterationsModalIsOpen] = useState(false);
  const [reactionsTypes] = useState([]);
  const [hasMoreCommunications, setHasMoreCommunications] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contentCode, setContentCode] = useState(null);
  const newCourse = useSelector(state => state.newCourse);

  async function loadMoreCommunications(page = communicationsPage) {
    try {
      setContentCode(selected.code);
      setLoadingProgress(50);
      setIsLoading(true);

      const response = await gateway.get(
        `/webbff/lit_app_web/social/getCommunications/${selected.code}?page=${page}&limit=10`
      );

      setCommunicationsPage(communicationsPage + 1);

      setHasMoreCommunications(_.get(response, 'hasNextPage', false));

      if (page <= 1) setCommunications(_.get(response, 'docs', []));
      else
        setCommunications([...communications, ..._.get(response, 'docs', [])]);

      setLoadingProgress(100);
      setIsLoading(false);
    } catch (error) {
      setLoadingProgress(100);
      setIsLoading(false);
    }
  }

  async function deleteCommunication(communicationId) {
    try {
      setLoadingProgress(50);
      setIsLoading(true);

      const body = {
        communicationId,
        organizationCode: selected.code
      };

      const response = await gateway.post(
        `/webbff/lit_app_web/social/deleteCommunication`,
        body
      );

      setCommunications(_.get(response, 'docs', []));

      setLoadingProgress(100);
      setIsLoading(false);
      showToast(response);
    } catch (error) {
      setLoadingProgress(100);
      setIsLoading(false);
      showToast(error, true);
    }
  }


  async function createPost(content, files) {
    try {
      if (!selected.code)
        return showToast(
          { message: 'Selecione um conteúdo para fazer o comentário' },
          true
        );

      setLoadingProgress(50);
      setIsLoading(true);

      if (
        newCourse.selected &&
        newCourse.selected.type === 'VIDEO' &&
        newCourse.selected.typeContent === 'VIDEO'
      ) {
        const videoPlayer = document.getElementsByTagName('video')[0];
        const playerPosition = Math.round(videoPlayer.currentTime);

        content = `<${playerPosition}>${content}`;
      }

      const attachs = [];

      for (let i = 0; i < files.length; i++) {
        const savedFileCode = await saveFile(files[i]);
        if (savedFileCode) attachs.push({ code: savedFileCode });
      }

      const body = {
        destination: 'CONTENT',
        content,
        attachs,
        code: selected.code
      };

      const response = await gateway.post(
        `/webbff/lit_app_web/social/createCommunicationFromCourse`,
        body
      );

      setCommunications(_.get(response, 'docs', []));
      showToast(response);
      setLoadingProgress(100);
      setIsLoading(false);
    } catch (error) {
      setLoadingProgress(100);
      setIsLoading(false);
      showToast(error, true);
    }
  }

  async function createComment(content, postCode, files) {
    try {
      setLoadingProgress(50);
      setIsLoading(true);

      if (
        newCourse.selected &&
        newCourse.selected.type === 'VIDEO' &&
        newCourse.selected.typeContent === 'VIDEO'
      ) {
        const videoPlayer = document.getElementsByTagName('video')[0];
        const playerPosition = Math.round(videoPlayer.currentTime);

        content = `<${playerPosition}>${content}`;
      }

      const attachs = [];

      for (let i = 0; i < files.length; i++) {
        const savedFileCode = await saveFile(files[i]);
        if (savedFileCode) attachs.push({ code: savedFileCode });
      }

      const body = {
        destination: 'CONTENT',
        content,
        attachs,
        parentId: postCode,
        code: selected.code
      };

      const response = await gateway.post(
        `/webbff/lit_app_web/social/createCommunicationFromCourse`,
        body
      );

      setCommunications(_.get(response, 'docs', []));
      showToast(response);
      setLoadingProgress(100);
      setIsLoading(false);
    } catch (error) {
      setLoadingProgress(100);
      setIsLoading(false);
      showToast(error, true);
    }
  }

  async function saveFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mediaType', file.type.toUpperCase());
    const response = await gateway.post('/media/media/upload', formData);
    return _.get(response, 'ox_standard.ox_identification.cr_code', null);
  }

  async function loadCommunicationReactions(communicationCode) {
    try {
      setLoadingProgress(50);
      setIsLoading(true);
      const response = await gateway.get(
        `/webbff/lit_app_web/social/getCommunicationReactions/${communicationCode}`
      );
      setLoadingProgress(100);
      setIsLoading(false);
      return response;
    } catch (error) {
      setLoadingProgress(100);
      setIsLoading(false);
    }
  }

  async function loadReactionPeople(
    communicationCode,
    reactionType,
    page = 1,
    limit = 10
  ) {
    try {
      setLoadingProgress(50);
      setIsLoading(true);
      const response = await gateway.get(
        `/webbff/lit_app_web/social/getReactionPeople/${communicationCode}/${reactionType}?page=${page}&page=${limit}`
      );
      setLoadingProgress(100);
      setIsLoading(false);
      return response;
    } catch (error) {
      setLoadingProgress(100);
      setIsLoading(false);
    }
  }

  const company = useMemo(() => {
    return localStorage.getItem('company') || ' ';
  }, []);

  const isAbout = useMemo(() => {
    if (selected.tags.length > 0) {
      return selected.tags.includes('ABOUT');
    }
    return false;
  }, [selected]);

  const doubtNoVisible = useMemo(() => {
    return _.get(newCourse.data, 'hideDoubt', false);
  }, [newCourse]);

  useEffect(() => {
    const socialUser = JSON.parse(localStorage.getItem('socialUser'));
    if (!socialUser) {
      setSocialUser(false);
    }
  }, []);

  useEffect(() => {
    if (contentCode != selected.code) {
      loadMoreCommunications(1);
    }
  });

  function setActiveComment() {
    setDoubts(false);
  }

  function setActiveDoubts() {
    setDoubts(true);
    setKnowMore(false);
  }


  return (
    <div className="col-lg-4">
      <LoadingBar loadingProgress={loadingProgress} />
      {logoCompany && (
        <div className="d-flex justify-content-center mb-2 ">
          <img
            height={60}
            src={`${API_URL_IMAGES}images/logo/${logoCompany}`}
            alt="Banner Company"
          />
        </div>
      )}
      <Tabs
        className="nav nav-tabs border-0 nav-fill"
        role="tablist"
        style={{ padding: 0 }}
      >
        <li style={{ flex: '1 1 ' }} className="nav-item">
          <a
            onClick={setActiveComment}
            href="#comentarios"
            className="nav-link border-0 active block-sobre"
            role="tab"
            data-toggle="tab"
          >
            <i className="fa fa-comment" />
            <br />
            <span className="white-space-pre"> Comentários</span>
          </a>
        </li>

        {/* <li
          style={{ flex: "1 1 " }}
          data-tip="Se quiser se aprofundar no tema do curso, veja os materiais que separamos para você"
          className="nav-item"
        >
          <a
            onClick={setActiveKnowMore}
            href="#extra"
            className="nav-link block-sobre"
            role="tab"
            data-toggle="tab"
          >
            <i className="fa fa-paperclip" />
            <br />
            <span className="white-space-pre"> Saiba mais</span>
          </a>
        </li> */}

        {isSocialUser && company.toUpperCase() !== 'ANGLO' && !doubtNoVisible && (
          <li
            style={{ flex: '1 1 ' }}
            data-tip="Nesse campo, poste apenas as dúvidas relacionadas ao conteúdo do curso que está cursando."
            className="nav-item"
          >
            <a
              onClick={setActiveDoubts}
              href="#duvidas"
              className="nav-link border-0 block-sobre"
              role="tab"
              data-toggle="tab"
            >
              <i className="fa fa-question" />
              <br />
              <span className="white-space-pre"> Dúvidas</span>
            </a>
          </li>
        )}
      </Tabs>

      <TabContent className="tab-content">
        <Suspense fallback={<Loading img width={20} height={20} />}>
          <div className="tab-pane active" id="comentarios" role="tabpanel">
            <Infinite>
              <Timeline
                group={{
                  name: '',
                  code: ''
                }}
                isContentPage
                communications={communications}
                interationsModalIsOpen={interationsModalIsOpen}
                setInterationsModalIsOpen={setInterationsModalIsOpen}
                loadMoreCommunications={loadMoreCommunications}
                loadCommunicationReactions={loadCommunicationReactions}
                loadReactionPeople={loadReactionPeople}
                updatePost={() => console.log('updatePost')}
                createComment={createComment}
                createPost={createPost}
                reactionsTypes={reactionsTypes}
                updateCommunicationReactions={() => console.log('updateCommunicationReactions')}
                deleteCommunication={deleteCommunication}
                hasMoreCommunications={hasMoreCommunications}
                isLoading={isLoading}
                showTimer={
                  selected &&
                  selected.type == 'VIDEO' &&
                  selected.typeContent == 'VIDEO'
                }
              />
            </Infinite>
            {isAbout && (
              <div style={{ padding: '15px' }}>
                <p style={{ fontSize: 15, textAlign: 'center' }}>
                  Nenhuma disciplina selecionada!
                </p>
              </div>
            )}
          </div>
          {/* <div className="tab-pane" id="extra" role="tabpanel">
            {isKnowMore && <SaibaMais />}
          </div> */}
          {isSocialUser && (
            <div className="tab-pane bg-white" id="duvidas" role="tabpanel">
              {isAbout && (
                <div style={{ padding: '15px' }}>
                  <p style={{ fontSize: 15, textAlign: 'center' }}>
                    Nenhuma disciplina selecionada!
                  </p>
                </div>
              )}

              {!isAbout && !doubtNoVisible && (
                <Duvidas
                  isVisible={isDoubts}
                  id={selected.code}
                  idUC={selected.parentCode}
                />
              )}
            </div>
          )}
        </Suspense>
      </TabContent>
    </div>
  );
}

const mapStateToProps = state => ({
  selected: state.newCourse.selected
});

export default connect(mapStateToProps)(Extra);
