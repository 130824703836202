import React from "react";
import { ACTION, POSITION } from "../../../constants";
import Text from "../../basicComponents/Text";
import { Dashed, DropContent, Drop } from "./styles";

export default ({ getRootProps, getInputProps, thumbs }) => {
  return (
    <>
      <Dashed>
        <DropContent {...getRootProps()}>
          <input {...getInputProps()} />
          <Text text={ACTION.DROP_ARCHIVE} position={POSITION.CENTER} />
        </DropContent>
        <Drop>{thumbs}</Drop>
      </Dashed>
    </>
  );
};
