import styled, { css } from "styled-components";
import { POSITION, SIZE } from "../../../constants";

export const Icon = styled.i`
  
  ${props =>
  (props.color ? `
     color: ${props.color}; 
     position: relative;
    ` : `
     color: var(--icon-default); 
     position: relative;
    `)
  }

  ${props => (props.badgeValue > 0) && css`
    ::after {
      content: "${props.badgeValue}";
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: var(--badge-danger);
      color: var(--white);
      display: block;
      position: absolute;
      top: -6px;
      right: -7px;
      box-shadow: -2px 2px 3px var(--badge-shadow);
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
    }
  `}

  ${props => (props.size === SIZE.SMALL) && css`
    font-size: 12px;
    margin-right: 5px;
  `}

  ${props => (props.size === SIZE.MEDIUM) && css`
    font-size: 20px; 
    margin-right: 5px;
    @media (max-width: 580px) {
      font-size: 14px;
    }
  `}

  ${props => (props.size === SIZE.LARGE) && css`
    margin-right: 5px;
    font-size: 40px;
  `}

  ${props => (props.size === SIZE.EXTRA_LARGE) && css`
    margin-right: 5px;
    font-size: 54px;
  `}

  ${props => (props.iconPosition === POSITION.LEFT) && css`
    display: inline-block;
  `}

  ${props => (props.iconPosition === POSITION.RIGHT) && css`
    display: inline-block;
    float: right;
  `}

`;
