import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { toast } from 'react-toastify'
import { bindActionCreators } from "redux"
import { Creators as NotificationsActions } from "../../store/ducks/notifications/actions"
import NotificacaoItem from "./NotificacaoItem"
import { toastErrorAndWarningOptions, toastDefaultOptions } from '../../utils/functions'
import api from '../../utils/api';

class Notificacao extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notificationsAsNotRead: [],
    }
    this.notification = null
    this.clickOutside = this.clickOutside.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
  }

  componentDidMount() {
    const { getNotificationRequest } = this.props
    getNotificationRequest("navbar")
    document.addEventListener("mousedown", this.clickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  clickOutside = event => {
    const { hide } = this.props
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      hide()
    }
  }

  updateList = () => {
    const { getNotificationRequest } = this.props
    getNotificationRequest("navbar")
  }

  markReads = async () => {
    const { countNotif } = this.props
    try {
      const response = await api.get('readallnotificationsuser')
      toast(response.message, {
        ...toastDefaultOptions,
        autoClose: true,
        closeButton: true
      })
      countNotif()
      this.updateList()

    } catch (error) {
      console.log(error)
      toast.error(error.message, {
        ...toastErrorAndWarningOptions,
        autoClose: true,
        closeButton: true
      })
    }
  }


  countReadNotif = idNotification => {
    const { notificationsAsNotRead } = this.state
    this.setState({
      notificationsAsNotRead: [...notificationsAsNotRead, idNotification],
    })
  }

  render() {
    const { notification, showNotes, countNotif } = this.props

    return (
      <div id="notificacoes-navBarSuperior" style={showNotes} ref={this.setWrapperRef}>
        <div className="notificacoesContainer-notificacoes">
          {/* <div id="triangle-up" /> */}
          <div className="title-notificacoes px-3 d-flex justify-content-between align-items-center">
            <h5>Notificações</h5>
            <div>
              <span
                style={{ fontSize: "14px" }}
                className="btn btn-outline-primary btn-small mr-2"
                role="button"
                onClick={this.markReads}
              >
                Marcar todos como lidas
              </span>
              <Link
                className="btn btn-outline-primary btn-small"
                style={{ fontSize: "14px" }}
                to="/notificacoes"
              >
                Ver todos <i className="fa fa-angle-right" aria-hidden="true" />
              </Link>
            </div>
          </div>
          <div className="content-notificacoes">
            <div
              className="text-right mr-3 mb-1"
              style={{
                borderBottomWidth: "1px",
                borderBottomColor: "#cdcdcd",
                borderBottomStyle: "solid",
              }}
            />
            {notification.length > 0 ? (
              notification.map((item, index) => {
                return (
                  <NotificacaoItem
                    key={item.idHistory}
                    ref={not => {
                      this.notification = not
                    }}
                    item={item}
                    first={index === 0}
                    countNotif={countNotif}
                    updateList={this.updateList}
                    countMarkRead={this.countReadNotif}
                  />
                )
              })
            ) : (
              <p style={{ textAlign: "center", marginBottom: 0, padding: 10, fontSize: "1.2rem" }}>
                Você não possui notificações no momento.
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    notification: store.notifications.data,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(NotificationsActions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notificacao)
