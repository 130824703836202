import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";
/**
 * @param {Component} children //content that will be rendered on the scroll bar
 * @param {String} orientation //{VERTICAL, HORIZONTAL} scroll bar direction
 * @param {String} size //{EXTRA_LARGE, MEDIUM} scroll bar size
 * @param {Function} onBottom //
**/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
  orientation = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING,
  onBottom = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <Screen
        child={children}
        orientation={orientation}
        onBottom={onBottom}
        size={size}
      />
    </>
  );
};
