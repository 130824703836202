import React from "react";
import { AccordionContent } from "./styles";

export default ({ child, id }) => {
  return (
    <>
      <AccordionContent eventKey={id}>{child}</AccordionContent>
    </>
  );
};
