import styled from "styled-components";
import { } from "../../../constants";

export const Content = styled.div`
  padding: 1rem 0;
  display: block;
  width: 100%;
  overflow: hidden;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  @media (max-width: 991px) {
    button {
      height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      margin: 3px 0;
      padding: 0;
      text-align: right;
      white-space: nowrap;
      width: 132px;
    }
  }
`;
