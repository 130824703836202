import React from "react";
import {
  COLOR,
  APPEARANCE,
  PAGE_INFORMATION,
  TRANSFORM,
  FONT_TYPE,
  SIZE
} from "../../../constants";
import Text from "../Text";
import Clickable from "../Clickable";
import Icon from "../Icon";
import { Div } from "./styles";

export default ({ content, icon, iconPosition, orientation, textAlign }) => {
  return (
    <>
      <Div orientation={orientation}>
        {content.map(social => [
          <Text
            text={`${PAGE_INFORMATION.PROFILE_OF} ${social.type}`}
            size={SIZE.MEDIUM}
            transform={TRANSFORM.UPPERCASE}
            fontType={FONT_TYPE.BOLD}
            color={COLOR.DARK}
          />,
          <Clickable appearance={APPEARANCE.LINK}>
            <Text size={SIZE.MEDIUM} text={social.link} textAlign={textAlign} />
            <Icon icon={icon} iconPosition={iconPosition} />
          </Clickable>
        ])}
      </Div>
    </>
  );
};
