import React from "react"
import CursosConcluidos from "./CursosConcluidos"
import Especialidades from "../PerfilProfessor/Especialidades"
import SobreUsuario from "./SobreUsuario"
import CargosUsuario from "./CargosUsuario"
import FormacoesAcademicas from "./Formacao/FormacoesAcademicas"
import Trilhas from "./Trilhas"

const Sobre = ({ profile }) => {
  return (
    <div>
      <CursosConcluidos idProfile={profile.idProfile} />
      <Especialidades idProfile={profile.idProfile} />
      <SobreUsuario about={profile.about} />
      <CargosUsuario idProfile={profile.idProfile} />
      <FormacoesAcademicas idProfile={profile.idProfile} />
      <Trilhas profile={profile} />
    </div>
  )
}

export default Sobre
