import styled from 'styled-components';

export const Container = styled.div`
  width: 230px;
  height: 127px;
  overflow: hidden;
  position: relative;

  border: 5px solid ${props => props.selected ? '#27AAE1' : 'transparent'};

  & + div {
    margin-left: 10px;
  }
  .barra-progress{
    width: 80%;
    justify-content: space-between;
  }
  .slider_info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    line-height: normal;
    padding: 4%;
    z-index: 99;
    opacity: 0;
    cursor: pointer;
    text-align: left;
    font-size: 50%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    transition: 450ms opacity;
    color: white;
  }
  &:hover .slider_info {
    opacity: 1;
  }
`;

export const Grade = styled.p`
  position: absolute;
  margin: 0;
  background: #fff;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 4px;
  right: 4px;
  z-index: 9999;

  color: ${props => {
    switch (props.status) {
      case 'DONE':
        return "#27AAE1";
      case 'DONE_REPROVED':
        return "#de1626";
      default:
        return "";
    }
  }};
 
`

export const Progress = styled.progress`
  margin-right: 10px;
`

