import React, { memo } from "react";
import SocialLinkedin from "../SocialLinkedin";
import SocialFacebook from "../SocialFacebook";
import SocialGoogle from "../SocialGoogle";

import googleIcon from "../../images/googleIcon.svg";

const SocialLogin = ({ handleSocialLogin, handleSocialError }) => {
  const showSocialLogin = true;

  return (
    <div className="social-buttons">
      {showSocialLogin && (
        <>
          <SocialFacebook
            handleSocialLogin={handleSocialLogin}
            handleSocialError={handleSocialError}
          >
            <button
              type="button"
              className="btn btn-rounded"
              style={{ backgroundColor: "#1877f2" }}
            >
              <i
                className="fa fa-facebook"
                aria-hidden="true"
                style={{ fontSize: "1.5em", color: "#fff" }}
              />
            </button>
          </SocialFacebook>

          <SocialGoogle
            handleSocialLogin={handleSocialLogin}
            handleSocialError={handleSocialError}
          >
            <button
              className="btn btn-rounded"
              type="button"
              style={{ backgroundColor: "transparent", padding: 0 }}
            >
              <img src={googleIcon} alt="iconGoogle" />
              {/* <i
                className="fa fa-google"
                aria-hidden="true"
                style={{ fontSize: "1.5em", color: "white" }}
              /> */}
            </button>
          </SocialGoogle>

          <SocialLinkedin
            onLoginSuccess={handleSocialLogin}
            onLoginFailure={handleSocialError}
          />
        </>
      )}
    </div>
  );
};
export default memo(SocialLogin);
