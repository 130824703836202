import React from "react";
import { Icon } from "./styles";

export default ({
  icon,
  color,
  size,
  iconPosition,
  badgeValue,
  onClick,
  validateType
}) => {
  return (
    <Icon
      onClick={onClick}
      className={validateType(icon)}
      color={color}
      size={size}
      iconPosition={iconPosition}
      badgeValue={badgeValue}
    ></Icon>
  );
};
