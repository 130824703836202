import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { UserActions } from '../../store/ducks/user';
import { gateway } from '../../utils/api';

const FooterHome = ({
  history,
  toggleModalRestrict,
  toggleModalRestrictLitPass,
  setLoading
}) => {
  const settings = JSON.parse(localStorage.getItem('settings')) || null;
  const tenant = localStorage.getItem('tenant') || '';
  const footerSections = [];

  const openLibrary = async () => {
    const naRealUser = JSON.parse(localStorage.getItem('naRealUser'));
    const libraryUser = JSON.parse(localStorage.getItem('libraryUser'));

    if (libraryUser && !naRealUser) {
      setLoading(true);
      try {
        const response = await gateway.get(
          `webbff/lit_app_web/home/v2/library`
        );
        setLoading(false);

        const newWindow = window.open(`${response.url}`, '_blank');
        newWindow.open();
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      if (naRealUser) {
        toggleModalRestrict(true, 'book');
        return;
      }
      toggleModalRestrictLitPass(true, 'book');
    }
  };

  const enterSocial = () =>
    JSON.parse(localStorage.getItem('socialUser'))
      ? history.push(`/timeline`)
      : toggleModalRestrict(true);

  const actions = { enterSocial, openLibrary };

  _.get(settings, `${tenant}.footerSections`, []).forEach(
    currentSection => (footerSections[currentSection.index] = currentSection)
  );

  return footerSections.map(currentSection => {
    if (typeof currentSection !== 'object')
      return <div className="col-md-2 col-sm-6"></div>;

    return (
      <ul
        className={`home-${currentSection.title} col-md-2 col-sm-6 footer-${currentSection.title}`}
      >
        <li>
          <h6>{currentSection.title}</h6>
        </li>
        {currentSection.links.map(
          ({
            tooltip = '',
            link = null,
            target = '_blank',
            title = null,
            action = null
          }) => (
            <li>
              <a
                data-tip={tooltip}
                href={link}
                target={target}
                onClick={actions[action]}
              >
                {title}
              </a>
            </li>
          )
        )}
      </ul>
    );
  });
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions.ProfileActions, dispatch);

export default withRouter(
  connect(state => state, mapDispatchToProps)(FooterHome)
);
