import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { Document, Page } from 'react-pdf';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background:
      'linear-gradient(45deg, rgba(2, 196, 255, 1) 25%, rgba(142, 230, 123, 1) 100%)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTermsPaymentSaintPaul({
  visible,
  termPdfUrl,
  onClose,
  loadingTerm = false
}) {
  const [pdfTotalPages, setPdfTotalPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setPdfTotalPages(numPages);
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Dialog
        open={visible}
        onClose={onClose}
        TransitionComponent={Transition}
        maxWidth="lg"
        style={{ background: 'transparent', backgroundColor: 'transparent' }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            ></IconButton>
            <Typography variant="h6" className={classes.title}></Typography>
            {!loadingTerm ? (
              <Button autoFocus color="inherit" onClick={onClose}>
                Fechar
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>
        <div className="modal-content">
          {loadingTerm ? (
            <div className="modal-body d-flex justify-content-center">
              <p>Carregando PDF...</p>
            </div>
          ) : (
            <div className="modal-body d-flex justify-content-center">
              <Document file={termPdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array(...Array(pdfTotalPages))
                  .map((x, i) => i + 1)
                  .map(page => (
                    <Page
                      pageNumber={page}
                      width={
                        window.innerWidth >= 992
                          ? window.innerWidth * 0.55
                          : window.innerWidth * 0.9
                      }
                    />
                  ))}
              </Document>
            </div>
          )}
          <div className="modal-footer">
            <button type="button" className="btn btn-blue" onClick={onClose}>
              Fechar
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
