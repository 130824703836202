import { Types } from "./types"

const INITIAL_STATE = {
  user: "usuarioLit",
  chatMessageContext: {},
  chatMessages: [],
  lastSubject: "",
  lastSubjectCounter: 0,
  lastSubjectChanged: false,
  messagesChatHistory: [],
  sendingMessage: false,
}

export default function paulChat(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_SENDING_MESSAGE:
      return {
        ...state,
        sendingMessage: action.payload.status,
      }
    case Types.ADD_CHAT_MESSAGE:
      return {
        ...state,
        chatMessages: [...state.chatMessages, action.payload],
      }
    case Types.ADD_MESSAGE_TO_CHAT_HISTORY:
      return {
        ...state,
        messagesChatHistory: [...state.messagesChatHistory, action.payload.data],
      }
    case Types.INCREASE_WATSON_CHAT_SUBJECT_COUNTER:
      return {
        ...state,
        lastSubjectCounter: state.lastSubjectCounter + 1,
      }
    case Types.REMOVE_OLD_FEEDBACK_BUTTON:
      return {
        ...state,
        chatMessages: [...action.payload.messages],
      }
    case Types.SET_CHAT_MESSAGE_CONTEXT:
      return {
        ...state,
        chatMessageContext: action.payload.context,
      }
    case Types.UPDATE_WATSON_CHAT_SUBJECT:
      return {
        ...state,
        lastSubject: action.payload.subject,
        lastSubjectChanged: action.payload.changed,
      }
    case Types.SET_USER_SESSION:
      return {
        ...state,
        user: action.payload.user,
      }
    case Types.SEND_FEEDBACK_ON_LEAVING:
      return {
        ...state,
        chatMessageContext: {},
        chatMessages: [],
        lastSubject: "",
        lastSubjectCounter: 0,
        lastSubjectChanged: false,
        messagesChatHistory: [],
      }
    case Types.RESET_CHAT_HISTORY:
      return {
        ...state,
        messagesChatHistory: [],
      }
    default:
      return state
  }
}
