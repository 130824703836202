import { Types } from "./types";

/**
 * Actions
 */

export const Creators = {
  getProfiles: data => ({
    type: Types.GET_ALL_PROFILES,
    payload: { data }
  })
};
