import React from "react";
import { COLOR } from "../../../constants";
import { Progress } from "./styles";

export default ({ progress, label, statusProgress, position }) => {
  return (
    <>
      <Progress
        now={progress}
        label={label}
        statusProgress={statusProgress}
        position={position}
        variant={COLOR.PRIMARY}
      />
    </>
  );
};
