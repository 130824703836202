/**
 * Types
 */

export const Types = {
  GET_OFFICES_REQUEST: "user/GET_OFFICES_REQUEST",
  GET_OFFICES_SUCCESS: "user/GET_OFFICES_SUCCESS",
  GET_OFFICES_FAILURE: "user/GET_OFFICES_FAILURE",
  GET_LAST_OFFICE_REQUEST: "user/GET_LAST_OFFICE_REQUEST",
  GET_LAST_OFFICE_SUCCESS: "user/GET_LAST_OFFICE_SUCCESS",
  GET_LAST_OFFICE_FAILURE: "user/GET_LAST_OFFICE_FAILURE",
}
