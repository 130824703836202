import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Array} contentCard //set of information that will be displayed on the card
 * @param {String} perLine //{ONE, TWO, THREE, FOUR} number of blocks per line
 * @param {String} textAlign //{LEFT, RIGHT, CENTER} position of the text within the block
**/

export default ({
  contentCard = DEFAULT_VALUES.LIST,
  perLine = DEFAULT_VALUES.STRING,
  textAlign = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        contentCard={contentCard}
        perLine={perLine}
        textAlign={textAlign}
      />
    </>
  );
};
