import React, { Component, useEffect, useState, createRef } from "react"
import _, { set } from "lodash"
import { toast } from 'react-toastify';
import Modal from "react-responsive-modal"
import { fi } from "date-fns/locale";
import ToggleButton from "../Carreira/ToggleButton"
import api from "../../utils/api"

import OnboardingPageInterests from '../Onboarding/components/OnboardingPageInterests';

import {
  toastErrorAndWarningOptions,
  toastDefaultOptions
} from '../../utils/functions';

export default function Especialidades({ interests, refetchOnboarding }) {
  const [selectedList, setSelectedList] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const currentPageRef = createRef();
  const [interestData, setInterestData] = useState(null)

  useEffect(() =>{
    if(interests) {
      setInterestData(interests)
      const items = _.get(interests, 'qx_userinterests', [])
      const formattedItem = items.filter(item => item.nu_size).map(item => ({
        grau: item.nu_size,
        id: item.rk_typesuggestion,
        name: item.wx_namesuggestion
      }))
      setSelectedList(formattedItem)
    }
  }, [interests])

  function openModal(){
    setModalIsOpen(true)
  }

  function closeModal() {
    setModalIsOpen(false)
  }

  async function advancePage(currentPageIndex) {
    setLoading(true)
    if (currentPageIndex === -1) {
      return;
    }
    if (currentPageRef.current) {
      try {
        await currentPageRef.current.saveForm();
        toast.success(
          'Informações atualizadas com sucesso',
          toastDefaultOptions
        );
        setModalIsOpen(false);
        refetchOnboarding();
      } catch (e) {
        if (e.response && e.response.data) {
          const errorMessage = e.response.data.error;
          if (typeof currentPageRef.current.showErrors === 'function') {
            currentPageRef.current.showErrors(e.response.data.error);
          }
          toast.warn(errorMessage, toastErrorAndWarningOptions);
        }
      } finally {
        setLoading(false);
      }
    }
  }

  async function savePageInterest() {
    try {
      await advancePage(interestData.index);
    } catch (e) {
      if (e.response && e.response.data) {
        const errorMessage = e.response.data.error;

        toast.warn(errorMessage, toastErrorAndWarningOptions);
      }
    }
  }

  return(
    <div className="cursos bg-white-perfil" id="especialidades">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-6">
            <h5
              className="d-inline-block"
              data-tip="As suas áreas de interesse são usadas para personalizar as recomendações de cursos e trilhas. Você pode editar este campo sempre que quiser"
            >
              Áreas de interesse
            </h5>
          </div>
          <button type="button" className="btn btn-icon" onClick={openModal}>
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
        </div>
        <div className="row">
          <div className="col">
            {selectedList && selectedList.length === 0 && <p>Nenhuma área de interesse selecionada</p>}
            {selectedList && selectedList.length > 0 && (
              <div data-toggle="buttons">
                {selectedList.map(suggestion => {
                  return (
                    <label className="btn btn-grey interesses-space" key={suggestion.id}>
                      <input type="checkbox" />
                      <i
                        className="fa fa-star-o blue-tooltip"
                        aria-hidden="true"
                        data-placement="bottom"
                        data-tip="151 XP"
                      />{" "}
                      {suggestion.name}
                    </label>
                  )
                })}
              </div>
            )}  
          </div>
        </div>
      </div>

      {/* Modal para edicao de especialidades */}
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        classNames={{ modal: "lit-modal-especialidade" }}
      >
        <div className="modal-content" id="modalGroup">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar áreas de interesse
            </h5>
          </div>
          <div className="modal-body">
            {interestData && (
              <OnboardingPageInterests
                index={interestData.index}
                data={interestData}
                isVisibleTitle={false}
                ref={currentPageRef}
              />
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-modal__cancel"
              disabled={loading}
              onClick={closeModal}
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={savePageInterest}
              disabled={loading}
              className={`btn btn-modal__approve ${loading && " btn-loading"}`}
            >
              Salvar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

class EspecialidadesOOLD extends Component {
  state = {
    modalIsOpen: false,
    trackSuggestions: [],
    listaSelecionados: [],
    loading: false,
  }

  componentDidMount() {
    this._getTrackSuggestion()
    this.fetchUserSuggestions()
  }

  fetchUserSuggestions = async () => {
    const idProfile = localStorage.getItem("idProfile")
    try {
      const response = await api.get(`trackSuggestionByStudent/${idProfile}`)
      const selected = []
      response.forEach(item => {
        const obj = {
          grau: item.size,
          id: item.suggestion.idSuggestion,
          name: item.suggestion.nameSuggestion,
        }
        selected.push(obj)
      })
      this.setState({
        listaSelecionados: selected,
      })
    } catch (error) {
      console.log(error)
    }
  }

  _getTrackSuggestion = async () => {
    try {
      const response = await api.get("trackSuggestion")
      const trackSuggestions = response.filter(
        suggestion => suggestion.idSuggestion !== 16 && suggestion.idSuggestion !== 18
      )
      this.setState({ trackSuggestions })
    } catch (error) {
      console.log(error)
    }
  }

  _getGrau = idSuggestion => {
    const { listaSelecionados } = this.state
    const index = listaSelecionados.findIndex(suggestion => {
      return suggestion.id === idSuggestion
    })
    if (index !== -1) {
      return `${index + 1}`
    }
    return ""
  }

  handleSelect = idSuggestion => {
    const { listaSelecionados } = this.state
    const index = listaSelecionados.findIndex(suggestion => {
      return suggestion.id === idSuggestion
    })
    if (index !== -1) {
      this.setState({
        listaSelecionados: listaSelecionados.filter((sug, i) => i !== index),
      })
    } else {
      if(listaSelecionados.length >= 5) {
        return;
      }
      this.setState({
        listaSelecionados: [
          ...listaSelecionados,
          { id: idSuggestion, grau: listaSelecionados.length + 1 },
        ],
      })
    }
  }

  _sendSuggestions = async () => {
    const idProfile = localStorage.getItem("idProfile")
    const { listaSelecionados } = this.state
    this.setState({ loading: true })

    const suggestionsArray = listaSelecionados.map((suggestion, i) => {
      return {
        idProfile,
        typeSuggestion: suggestion.id,
        size: i + 1,
      }
    })
    try {
      await api.post("setStudentSuggestion", suggestionsArray)
      this.setState({
        modalIsOpen: false,
        listaSelecionados: [],
        loading: false,
      })
      this.fetchUserSuggestions()
    } catch (error) {
      console.log(error)
      this.setState({ loading: false })
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false, listaSelecionados: [] })
    this.fetchUserSuggestions()
  }

  render() {
    const { listaSelecionados, trackSuggestions, modalIsOpen, loading } = this.state
    return (
      <div className="cursos bg-white-perfil" id="especialidades">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-6">
              <h5
                className="d-inline-block"
                data-tip="As suas áreas de interesse são usadas para personalizar as recomendações de cursos e trilhas. Você pode editar este campo sempre que quiser"
              >
                Áreas de interesse
              </h5>
            </div>
            <button type="button" className="btn btn-icon" onClick={this.openModal}>
              <i className="fa fa-pencil" aria-hidden="true" />
            </button>
          </div>
          <div className="row">
            <div className="col">
              <div data-toggle="buttons">
                {listaSelecionados.map(suggestion => {
                  return (
                    <label className="btn btn-grey interesses-space" key={suggestion.id}>
                      <input type="checkbox" />
                      <i
                        className="fa fa-star-o blue-tooltip"
                        aria-hidden="true"
                        data-placement="bottom"
                        data-tip="151 XP"
                      />{" "}
                      {suggestion.name}
                    </label>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Modal para edicao de especialidades */}
        <Modal
          open={modalIsOpen}
          onClose={this.closeModal}
          classNames={{ modal: "lit-modal-especialidade" }}
        >
          <div className="modal-content" id="modalGroup">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Editar áreas de interesse
              </h5>
            </div>
            <div className="modal-body">
              <span style={{padding: '0 8px'}}>Selecione até 5 áreas:</span>
              <div data-toggle="buttons" className="grupo-interesse">
                {trackSuggestions.map(suggestion => {
                  return (
                    <ToggleButton
                      key={suggestion.typeSuggestion}
                      idTrackSuggestion={suggestion.typeSuggestion}
                      name={suggestion.nameSuggestion}
                      handleSelect={this.handleSelect}
                      grau={this._getGrau(suggestion.typeSuggestion)}
                    />
                  )
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-modal__cancel"
                disabled={loading}
                onClick={this.closeModal}
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={this._sendSuggestions}
                disabled={loading}
                className={`btn btn-modal__approve ${loading && " btn-loading"}`}
              >
                Salvar
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

// export default Especialidades
