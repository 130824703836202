import styled from 'styled-components';

export const WarningContent = styled.button`
  padding: 10px;
  background: #fff3cd;
  border: 0px solid #e6d5a3;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const WarningText = styled.span`
  color: #876607;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 16px;
`;
