import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const UlPagination = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */

  li.page-item.active a.page-link {
    color: #445565 !important;
    background-color: #e3e7eb !important;
    border-color: #ced4da !important;
  }

  a.page-link {
    padding: 0.5rem 1rem;
    min-width: ${({ noWidth }) => (noWidth ? 0 : "3.3rem")};
    text-align: center;
    box-shadow: none !important;
    border-color: #ced4da !important;
    color: #6b88a4;
    font-weight: 900;
    font-size: 1rem;
  }
  a.page-link:hover {
    background-color: #f4f4f4;
  }
`

const LEFT_PAGE = "LEFT"
const RIGHT_PAGE = "RIGHT"

const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

class Pagination extends Component {
  constructor(props) {
    super(props)
    const { totalRecords = null, pageLimit = 30, pageNeighbours = 0, page } = props

    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0

    this.pageNeighbours =
      typeof pageNeighbours === "number" ? Math.max(0, Math.min(pageNeighbours, 2)) : 0

    this.totalPages = Math.ceil(this.totalRecords)

    this.state = { currentPage: page }
  }

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props

    const currentPage = Math.max(0, Math.min(page, this.totalPages))

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords,
    }

    this.setState({ currentPage }, () => onPageChanged(paginationData))
  }

  handleClick = (page, evt) => {
    evt.preventDefault()
    this.gotoPage(page)
  }

  handleMoveLeft = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1)
  }

  handleMoveRight = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1)
  }

  fetchPageNumbers = () => {
    const totalPages = this.totalPages
    const currentPage = this.state.currentPage
    const pageNeighbours = this.pageNeighbours

    const totalNumbers = this.pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      let pages = []

      const leftBound = currentPage - pageNeighbours
      const rightBound = currentPage + pageNeighbours
      const beforeLastPage = totalPages - 1

      const startPage = leftBound > 2 ? leftBound : 2
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage

      pages = range(startPage, endPage)

      const pagesCount = pages.length
      const singleSpillOffset = totalNumbers - pagesCount - 1

      const leftSpill = startPage > 2
      const rightSpill = endPage < beforeLastPage

      const leftSpillPage = LEFT_PAGE
      const rightSpillPage = RIGHT_PAGE

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1)
        pages = [leftSpillPage, ...extraPages, ...pages]
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset)
        pages = [...pages, ...extraPages, rightSpillPage]
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage]
      }

      return [1, ...pages, totalPages]
    }

    return range(1, totalPages)
  }

  render() {
    if (!this.totalRecords) return null

    if (this.totalPages === 1) return null

    const { currentPage } = this.state
    const pages = this.fetchPageNumbers()
    const { noWidth } = this.props
    return (
      <Fragment>
        <nav aria-label="Pagination">
          <UlPagination noWidth={noWidth} className="pagination">
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <li key={index} className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={this.handleMoveLeft}
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                )

              if (page === RIGHT_PAGE)
                return (
                  <li key={index} className="page-item">
                    <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                )

              return (
                <li key={index} className={`page-item${currentPage === page ? " active" : ""}`}>
                  <a className="page-link" href="#" onClick={e => this.handleClick(page, e)}>
                    {page}
                  </a>
                </li>
              )
            })}
          </UlPagination>
        </nav>
      </Fragment>
    )
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  pageNeighbours: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
}

export default Pagination
