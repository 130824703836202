import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} icon //icon displayed next to the text
 * @param {String} text //text displayed for filter
 * @param {Array} options //list of texts for selection
 * @param {String} color //{LIGHT} text color
 * @param {String} appearance //{FILTER} appearance field
**/

export default ({
  icon = DEFAULT_VALUES.STRING,
  text = DEFAULT_VALUES.STRING,
  options = DEFAULT_VALUES.LIST,
  color = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        icon={icon}
        text={text}
        options={options}
        color={color}
        appearance={appearance}
      />
    </>
  );
};
