export const sendRecommendationPlanWhats = (name, phone) => {
  const phoneNumber = phone ? phone.replace(/\D/g, "") : ""
  const baseUrl = process.env.REACT_APP_API_URL.replace(':8443/api/', '');
  const text = `
  Olá!
  Você foi incluído no plano Família de ${name}. Acesse agora o LIT clicando no link para fazer o seu cadastro e iniciar os seus estudos!
  ${baseUrl}
  Bons estudos! 
  Paul`

  const width = window.window.innerWidth * 0.9
  const height = window.window.innerHeight
  /* eslint-disable no-mixed-operators */
  const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2
  const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2
  /* eslint-enable no-mixed-operators */
  const config = {
    height,
    width,
    left,
    top,
    location: "no",
    toolbar: "no",
    status: "no",
    directories: "no",
    menubar: "no",
    scrollbars: "yes",
    resizable: "no",
    centerscreen: "yes",
    chrome: "yes",
  }
  const url = `https://api.whatsapp.com/send?phone=55${phoneNumber}&text=${window.encodeURIComponent(
    text
  )}`
  window.open(
    url,
    "",
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(", ")
  )
}
