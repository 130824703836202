import styled, { css } from "styled-components";
import { Accordion } from "react-bootstrap";
import { APPEARANCE } from "../../../constants";

export const AccordionContainer = styled(Accordion)`

  ${props => (props.appearance === APPEARANCE.POST) && css`
    
    text-align: left;
    background: transparent;
    margin: 0 0 20px 0;
    position: relative;
    
    .card{
      box-shadow: 0px 5px 10px rgba(0,0,0,.1);
    }

    .card:before{
      content:"";
      width: 2px;
      height: 100%;
      background-color: #ddd;
      position: absolute;
      left: -22px;
      top: 0;
    }
    
    > span { display:none }
    > .accordion > .card { border-left: 2px solid var(--timeline-border); }
    > .accordion { margin-left: 33px; border-left: 2px solid var(--white); }
    > .accordion > .card:last-of-type { border-left: 2px solid var(--white); }
    > .accordion:last-child > .card:before { display: none !important; }
    > .card:last-of-type { margin-left: -2px; border-left: 2px solid white; }
    > .accordion:last-child { margin-left: -2px; border-left: 2px solid var(--white); padding-left: 35px; }
    .accordion > .card:last-of-type .card-header:before { left: -22px; }
    .card:not(:first-child) { padding-left: 37px; }

    .accordion .card-header:before {
      content: "";
      width: 20px;
      height: 40px;
      border-bottom: 2px solid var(--timeline-border);
      border-left: 2px solid var(--timeline-border);
      position: absolute;
      display: block;
      left: -19px;
      top: -20px;
      border-radius: 0 0 0 25px;
      &:last-child { border-left: 2px solid var(--white); }
    }

    .accordion .card:nth-of-type(n+2) .card-body > span > div:nth-of-type(2) > div > span:first-child {
      display: none!important;
    }
    
    .accordion{
      margin-top: 20px;
    }

  `}
  
`;
