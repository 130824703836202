import swal from "@sweetalert/with-react";

const MESSAGES = {
  ALREADY_PURCHASED: 'Esse plano já está ativo no seu perfil, dessa forma, ao continuar você estará apenas alterando a forma de pagamento, deseja continuar?',
  ALREADY_PURCHASED_WITH_SAME_TYPE: 'Você possuí um outro plano ativo no seu perfil do mesmo tipo desse plano selecionado, dessa forma, ao continuar você estará optando por realizar a troca entre os planos, deseja continuar?',
}


const generateAlert = ({ message, successCallback }) => {
  swal({
    title: `Atenção!`,
    text: message,
    buttons: {
      cancel: {
        text: "Cancelar",
        value: false,
        visible: true,
        className: "btn-alert btn-alert-cancel-assessment"
      },
      confirm: {
        text: "Continuar",
        value: true,
        visible: true,
        className: "btn-alert btn-alert-finish-assessment"
      }
    }
  }).then(value => {
    if (value) {
      successCallback()
    }
  });
};

const handleFormSubmit = ({
  selectedPlan,
  processPaymentFunction,
  formValues
}) => {
  if (selectedPlan.alreadyPurchased) {
    generateAlert({
      message:
        MESSAGES.ALREADY_PURCHASED,
      successCallback: () => processPaymentFunction(formValues)
    });

    return;
  }

  if (selectedPlan.alreadyPurchasedOtherWithSameType) {
    generateAlert({
      message:
        MESSAGES.ALREADY_PURCHASED_WITH_SAME_TYPE,
      successCallback: () => processPaymentFunction(formValues)
    });
    return;
  }

  processPaymentFunction(formValues);
};

export default handleFormSubmit;
