import React, {useState, useMemo, useCallback} from 'react';
import _ from "lodash";
import { parseISO, formatDistance } from "date-fns";
import pt from "date-fns/locale/pt";

import { Container } from './styles';

import ViewEditor from '../../../shared/LitEditor/ViewEditor';

const STATEMENT_TEXT_LIMIT = 200;

function UserMessage({item, goToMessage}) {
  const [showMoreText, setShowMoreText] = useState(false);

  const rawStatement = useMemo(() => {
    return _.get(item, "message", "");
  }, [item]);

  const statement = useMemo(() => {
    if (rawStatement.length > STATEMENT_TEXT_LIMIT && !showMoreText) {
      return rawStatement.slice(0, STATEMENT_TEXT_LIMIT);
    }

    return rawStatement;
  }, [item, showMoreText]);

  const renderDate = useCallback(date => {
    let time = "sem data";

    if (date) {
      const parsedDate = parseISO(date);
      time = formatDistance(parsedDate, Date.now(), {
        locale: pt,
        addSuffix: true
      });
    }
    return time;
  }, []);


  return (
    <Container>

      <div>
        <span
          style={{ marginBottom: "5px", display: "inline-block" }}
        >
          <b style={{ fontWeight: "bold", marginRight: "2px" }}>
            Resposta{" "}
          </b>
          {renderDate(item.createAt)}
        </span>
        <button type="button" onClick={() => goToMessage(item)}>
          {/* <span>{item.message}</span> */}
          <ViewEditor data={statement} />
          {rawStatement.length > STATEMENT_TEXT_LIMIT && (
            <button
              className="showMore"
              type="button"
              style={{margin: 0, padding: 0}}
              onClick={(e) => {
                e.stopPropagation()
                setShowMoreText(!showMoreText)
              } }
            >
              {showMoreText ? "Ver menos" : "Ver mais"}
            </button>
          )}
        </button>
      </div>
    </Container>
  );
}

export default UserMessage;
