import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';
import { Creators as ProfileActions } from '../../store/ducks/user/profile/actions';
import NotifyService from '../../utils/NotifyService';
import {
  setItensLocalStorage,
  identifyLogin,
  setSEOIndexOff,
  getLoginTenantURL,
  formatPhoneNumber
} from '../../utils/functions';
import api, { gateway } from '../../utils/api';
import LoadingPaul from '../LoadingPaul';
import adsTracking from '../../utils/adsTracking';

import LIT from './LIT';
import Partners from './Partners';

class CadastroPerfil extends Component {
  notif = null;

  state = {
    isPartner: null,
    userDetails: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      voucher: {
        voucherNumber: ''
      }
    },
    loading: true,
    promo: true,
    litpass: false,
    isVocacao: false
  };

  componentDidMount() {
    localStorage.removeItem('urlOriginParam');
    this.notif = new NotifyService();
    this.getUrlParams();
    setSEOIndexOff();
  }

  getUrlParams = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const voucher = params.get('cupom');

    const planByUrl = params.get('plan');
    const typeByUrl = params.get('type') || null;

    if (planByUrl) {
      localStorage.setItem('planByUrl', planByUrl);
    }

    localStorage.setItem('typeByUrl', typeByUrl);

    if (voucher && voucher !== '') {
      this.setState({ isPartner: true });
    } else {
      this.setState({ isPartner: false, loading: false });
    }
    // fazer aqui
    const plans = params.get('plans');
    if (plans) {
      localStorage.setItem('urlPlansParam', plans);
    }

    const origemParam = params.get('o');
    let origin = 'default';
    if (origemParam) {
      origin = origemParam;
      localStorage.setItem('urlOriginParam', origemParam);
      localStorage.setItem('urlRedirect', origemParam);
    }
    this.setState({ origin });

    const sellerCode = params.get('s');
    const couponCode = params.get('c');
    const sellerLinkId = params.get('sli');
  
    if(sellerCode){
      localStorage.setItem('sellerCode', sellerCode);
    }

    if(couponCode){
      localStorage.setItem('couponCode', couponCode);
    }

    if(sellerLinkId) {
      localStorage.setItem('sellerLinkId', sellerLinkId);
    }

    if (location.pathname.includes('cadastroPerfilLitPass')) {
      /*
      this.setState({ promo: false, litpass: true }); 
      if there is no promotional image for litpass
      include this setState with promo: false.
      */
      this.setState({ litpass: true, isVocacao: false });
    } else {
      this.setState({ litpass: false });
    }

    if (location.pathname.includes('cadastroPerfilVocacao')) {
      this.setState({ isVocacao: true, litpass: false });
      localStorage.setItem('urlOriginParam', 'vocacao');
    }

    const cuponDesconto = params.get('cupom-desconto');

    if (cuponDesconto) {
      localStorage.setItem('cuponDesconto', cuponDesconto);
    }

    const firstName = params.get('nome');
    const lastName = params.get('sobrenome');
    const email = params.get('email');
    const phoneNumber = params.get('telefone');
    const userDetails = {
      firstName,
      lastName,
      email,
      phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber) : undefined,
      voucher: {
        voucherNumber: ''
      },
      origin
    };

    this.setState({ userDetails });
  };

  sendEmail = async email => {
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get('o');
    const tenant = getLoginTenantURL(window.location.href);

    let url = `/notification/notification/sendConfirmation/${email}`;
    if (origin) {
      url += `?o=${origin}`;
    }
    if (tenant) {
      if (origin) {
        url += `&tenant=${tenant}`;
      } else {
        url += `?tenant=${tenant}`;
      }
    }
    try {
      await gateway.post(url);
    } catch (error) {
      console.log('Failure to send email', error.response);
    }
  };

  handleRegisterSuccess = async (userInfos, toPayment = false) => {
    const { isPartner } = this.state;
    const { getProfileRequest, history } = this.props;
    let historyAddress = '';

    if (userInfos.idProfile) {
      setItensLocalStorage(userInfos);
      identifyLogin(userInfos.idLogin);
      this.notif.syncIdOneSignal(userInfos.idProfile);
      getProfileRequest(userInfos.idProfile);

      if (isPartner && !toPayment) {
        historyAddress = '/onboarding';
      } else if (isPartner && toPayment) {
        historyAddress = {
          pathname: `consultaCpf`,
          state: { voucher: toPayment }
        };
      } else if (userInfos.emailVerified) {
        localStorage.setItem('newUser', true);
        historyAddress = '/consultaCpf';
      } else if (!userInfos.emailVerified) {
        this.sendEmail(userInfos.ra);
        historyAddress = '/confirmarEmail';
      } else {
        historyAddress = '/consultaCpf';
      }

      adsTracking.sendGoogleAnalyticsEcommerceEvent({
        step: adsTracking.STEP1_REGISTER_PROFILE,
        callback: () => history.push(historyAddress)
      });
    }
  };

  handleRegisterFailure = (error, email) => {
    identifyLogin(null, email);
    let err = '';
    if (error.response) {
      err = error.response.data.message;
    } else {
      err = error.message;
    }

    if (err.includes('cadastrado')) {
      err = `Seu e-mail já está registrado, e você foi redirecionado automaticamente para a tela de login. Utilize sua senha para entrar. Se esqueceu a senha, clique em "Esqueceu sua senha?" para redefini-la.`;
      const currentPathname = this.props.location.pathname;
      const currentSearch = this.props.location.search;
      if (currentPathname.startsWith('/cadastroPerfil')) {
        const newPathname = currentPathname.replace('/cadastroPerfil', '');
        localStorage.setItem('redirectByCadastroPerfil', newPathname);
        this.props.history.push({
          pathname: newPathname || '/',
          search: currentSearch
        });
      }
    }

    this.toast(err, 'error');
  };

  toast = (text, type) => {
    toast(text, {
      autoClose: false,
      type
    });
  };

  resetErrors = () => {
    toast.dismiss();
  };

  handleSocialSuccess = async social => {
    try {
      const data = {
        ...social,
        origin: localStorage.getItem('urlRedirect') || ''
      };
      const url = `/webbff/lit_app_web/login/socialSignin`;
      const response = await gateway.post(url, data);
      this.handleRegisterSuccess(response);
    } catch (error) {
      this.handleRegisterFailure(error);
    }
  };

  handleSocialFailure = data => {
    console.log('Erro \n', data);
  };

  render() {
    const {
      isPartner,
      userDetails,
      loading,
      origin,
      promo,
      litpass,
      isVocacao
    } = this.state;

    if (loading) {
      return <LoadingPaul />;
    }

    return (
      <div id="login" className="container-fluid">
        {isPartner ? (
          <Partners
            promo={promo}
            userDetails={userDetails}
            resetErrors={this.resetErrors}
            handleRegisterSuccess={this.handleRegisterSuccess}
            handleRegisterFailure={this.handleRegisterFailure}
            handleSocialSuccess={this.handleSocialSuccess}
            handleSocialFailure={this.handleSocialFailure}
          />
        ) : (
          <LIT
            userDetails={userDetails}
            promo={promo}
            litpass={litpass}
            isVocacao={isVocacao}
            handleRegisterSuccess={this.handleRegisterSuccess}
            handleRegisterFailure={this.handleRegisterFailure}
            handleSocialSuccess={this.handleSocialSuccess}
            handleSocialFailure={this.handleSocialFailure}
            origin={origin}
          />
        )}
        <input type="hidden" value={origin} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(ProfileActions, dispatch);

export default connect(null, mapDispatchToProps)(CadastroPerfil);
