import React from "react";

import Loading from "../Loading/Loading";
import Pagination from "../Pagination";

export default function WrapCard({
                                   loading,
                                   doneSearch,
                                   name,
                                   onPageChanged,
                                   resultLength,
                                   children,
                                   totalPages,
                                   page,
                                   pageLimit = 10
                                 }) {
  if (!doneSearch) {
    return <div />;
  }


  const formatName = (name) => {
    if (name.includes("Online")) {
      return (
        <span>
          {name.split("Online").map((part, index) => (
            <React.Fragment key={index}>
              {part}
              {index < name.split("Online").length - 1 && <em>Online</em>}
            </React.Fragment>
          ))}
        </span>
      );
    }
    return name;
  };

  return (
    <div className="my-3">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <h1>{formatName(name)}</h1>
        <Pagination
          noWidth
          totalRecords={totalPages}
          pageLimit={pageLimit}
          pageNeighbours={1}
          page={page}
          onPageChanged={onPageChanged}
        />
      </div>
      {resultLength > 0 ? (
        <>
          {loading && (
            <div style={{ position: "absolute", zIndex: 1, left: "45%", marginTop: "5em" }}>
              <Loading img width={70} height={70} />
            </div>
          )}
          {children}
        </>
      ) : (
        <p>Sem {name.toLowerCase()} para exibir</p>
      )}
    </div>
  );
}
