import React from "react";
import {
  SIZE,
  APPEARANCE,
  ACTION,
  PAGE_INFORMATION,
  VISIBILITY_OPTIONS_SELECT,
  PRIVIVACY_OPTIONS_SELECT
} from "../../../constants";
import { Form, Formik, ErrorMessage } from "formik";
import Select from "react-select";
import ButtonModal from "../../basicComponents/ButtonModal";
import Spacing from "../../basicComponents/Spacing";
import InputText from "../../Inputs/Input";
import TextArea from "../../Inputs/TextArea";
import TwoColumns from "../../defaultAreas/Column_2x2";
import DropZone from "../../Inputs/DropZone";
import Text from "../../basicComponents/Text";

import { ModalBody, ButtonAtBottom, Block } from "./styles";

export default ({ validationSchema, areasOfInterest, action, setValue }) => {
  return (
    <ModalBody>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{
          name: "",
          longDescription: "",
          visibility: "",
          privacy: "",
          areasofinterest: []
        }}
        onSubmit={formValues => {
          action(formValues);
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched
        }) => (
          <Form>
            <Block>
              <DropZone acceptMultipleFile={false} setValue={setValue} />

              <Spacing size={SIZE.SMALL} />

              <InputText
                placeholder={ACTION.PLACEHOLDER_TITLE}
                name="name"
                label={PAGE_INFORMATION.GROUP_NAME}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />

              <ErrorMessage
                name="name"
                component="span"
                className="invalid-input"
              />

              <TwoColumns
                left={
                  <>
                    <Text text={PAGE_INFORMATION.VISIBILITY} />
                    <Select
                      placeholder={ACTION.SELECT}
                      options={VISIBILITY_OPTIONS_SELECT}
                      label={PAGE_INFORMATION.VISIBILITY}
                      value={values.visibility}
                      onChange={value => setFieldValue("visibility", value)}
                      onBlur={() => setFieldTouched("visibility", true)}
                      error={errors.state}
                      touched={touched.state}
                      name="visibility"
                      isMulti={false}
                    />

                    <ErrorMessage
                      name="visibility"
                      component="span"
                      className="invalid-input"
                    />
                  </>
                }
                right={
                  <>
                    <Text text={PAGE_INFORMATION.PRIVACY} />
                    <Select
                      placeholder={ACTION.SELECT}
                      options={PRIVIVACY_OPTIONS_SELECT}
                      label={PAGE_INFORMATION.PRIVACY}
                      value={values.privacy}
                      onChange={value => setFieldValue("privacy", value)}
                      onBlur={() => setFieldTouched("privacy", true)}
                      error={errors.state}
                      touched={touched.state}
                      name="privacy"
                      isMulti={false}
                    />

                    <ErrorMessage
                      name="privacy"
                      component="span"
                      className="invalid-input"
                    />
                  </>
                }
              />

              <Text text={PAGE_INFORMATION.AREAS_OF_INTEREST} />
              <Select
                placeholder={ACTION.SELECT}
                options={areasOfInterest}
                value={values.areasofinterest}
                label={PAGE_INFORMATION.AREAS_OF_INTEREST}
                onChange={values => setFieldValue("areasofinterest", values)}
                name="areasofinterest"
                isMulti
              />

              <TextArea
                size={SIZE.MEDIUM}
                label={PAGE_INFORMATION.GROUP_DESCRIPTION}
                value={values.longDescription}
                name="longDescription"
                onChange={handleChange}
                onBlur={handleBlur}
              ></TextArea>
              <ErrorMessage
                name="longDescription"
                component="span"
                className="invalid-input"
              />
            </Block>

            <ButtonAtBottom>
              <ButtonModal
                text={ACTION.SAVE}
                onConfirm={handleSubmit}
                appearance={APPEARANCE.PRIMARY}
              />
            </ButtonAtBottom>
          </Form>
        )}
      />
    </ModalBody>
  );
};
