/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from "prop-types";

import Screen from './screen'

function CardEvent({event, onClick}) {
  return (
    <Screen event={event} onClick={onClick} />
  );
}

CardEvent.propTypes = {
  event: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

CardEvent.defaultProps = {
  onClick: () => {}
}

export default CardEvent;
