import styled, { css } from "styled-components";
import { ORIENTATION, POSITION } from "../../../constants";

export const SimpleRow = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  clear: both;
  @media screen and (max-width: 991px) {
    display: block;
    margin: 1rem 0 0;
  }

  ${props => (props.orientation === ORIENTATION.AJUSTABLE) && css`
    @media screen and (max-width: 991px) {
      display: inherit;
      margin: 0;
    }
  `}

  ${props => (props.alignItems === POSITION.TOP_CENTER) && css`
    align-items: center;
    flex-direction: column;
  `}

  ${props => (props.alignItems === POSITION.TOP_LEFT_CENTER) && css`
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  `}

  ${props => (props.alignItems === POSITION.LEFT) && css`
    position: relative;
  `}
 

  ${props => (props.alignItems === POSITION.CENTER) && css`
    justify-content: center;
  `}

  ${props => (props.alignItems === POSITION.JUSTIFY) && css`
    justify-content: space-between;
    align-items: center;
  `}

  ${props => (props.alignItems === POSITION.TOP_JUSTIFY) && css`
    height: 100%;
    justify-content: space-between;
    align-items: center;
  `}

  ${props => (props.hidden) && css`
    display: none;
  `}

`;
