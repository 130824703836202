import styled from "styled-components";
import { } from "../../../constants";

export const ModalBody = styled.div.attrs({
  className: "modal-body"
})`
  padding: 2rem;
  margin: 1rem 0;
  text-align: center;
  position: relative;
`;

export const ButtonAtBottom = styled.div``;
