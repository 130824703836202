import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} appearance //{AVATAR, LOGO, CARD, BANNER_BLOCK, MODAL_HEADER} appearance of the image to be displayed
 * @param {String} size //{SMALL, MEDIUM, LARGE} size of the image to be displayed
 * @param {String} border //{BORDER, NO_BORDER, BORDER_LIGHT} type of border to be displayed in the image
 * @param {String} format //{ROUNDED} image border format
 * @param {String} position //{BOTTOM_RIGHT, ABOVE_RIGHT} image position
 * @param {String} alt //description of the image to be displayed
 * @param {String} src //path (url) of the image to be displayed
**/

export default ({
  appearance = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING,
  border = DEFAULT_VALUES.STRING,
  format = DEFAULT_VALUES.STRING,
  position = DEFAULT_VALUES.STRING,
  alt = DEFAULT_VALUES.STRING,
  src = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        appearance={appearance}
        size={size}
        border={border}
        format={format}
        position={position}
        alt={alt}
        src={src}
      />
    </>
  );
};
