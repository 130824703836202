import { Types } from "./types"

/**
 * Actions
 */
export const Creators = {
  setPaymentValue: data => ({
    type: Types.SET_PAYMENT_VALUE,
    payload: { data },
  }),
}
