import styled from "styled-components";
import { } from "../../../constants";

export const LeftBar = styled.div`
  display: inline-block;
  text-align: left;
  width: 50%;
  padding-right: 3px;
`;

export const RightBar = styled.div`
  display: inline-block;
  text-align: right;
  padding-left: 3px;
  width: 50%;
`;
