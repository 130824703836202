import React, { Fragment, useState } from "react"
import IntlTelInput from 'react-intl-tel-input'
import { Formik } from "formik"
import * as Yup from "yup"
import 'react-intl-tel-input/dist/main.css';
import { ErrorMessage, ButtonLinks } from "../styles"
import api from "../../../utils/api"
import {generateSecurePassword, renewToken} from '../../../utils/functions'
import ModalPolitica from "../../Modals/ModalPolitica"
import ModalTermos from "../../Modals/ModalTermos"

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Campo muito curto!")
    .max(50, "Campo muito longo!")
    .required("Nome obrigatório"),
  lastName: Yup.string()
    .min(2, "Campo muito curto!")
    .max(50, "Campo muito longo!")
    .required("Sobrenome obrigatório"),
  email: Yup.string()
    .email("email inválido")
    .required("Email obrigatório"),
  phoneNumber: Yup.string()
    .required('Telefone obrigatório'), 
  password: Yup.string().required("Senha obrigatório"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas não coincidem")
    .required("Confirmação de senha obrigatório"),
  terms: Yup.boolean()
    .oneOf([true], "É necessário aceitar os termos de uso!")
    .required("É necessário aceitar os termos de uso!"),
  voucher: Yup.string().required("Cupom obrigatório"),
})

const RegisterPartnerForm = ({
  userData,
  doRegisterSuccess,
  doRegisterFailure,
  resetErrors,
  bradesco,
}) => {

  const [disableButton, setDisableButton] = useState(false)
  const [phoneNumberError, setPhonenumberError] = useState(true)
  const [showPhoneLabel, setShowPhoneLabel] = useState(false)

  const loginToken = async (username, password) => {
    const data = {
      username,
      password,
    }
    await api.post("login", data)
  }

  const registerUser = async (values, actions) => {
    resetErrors()
    const data = {
      firstName: values.name,
      lastName: values.lastName,
      linkPhoto: "",
      phoneNumber: values.phoneNumber,
      login: {
        username: values.email,
        password: generateSecurePassword(values.password),
        enabled: true,
        role: {
          idRole: 2,
        },
      },
    }

    try {
      const response = await api.post("newProfile", data)
      // await loginToken(values.email, values.password)

      if (parseInt(userData.voucher.discount, 10) === 100) {
        const aux = {
          ...response,
          voucherAtivo: true,
          freeUser: false,
          libraryUser: false,
          socialUser: false,
          showPaul: false,
        }

        await renewToken();

        doRegisterSuccess(aux)
      } else {
        doRegisterSuccess(response, values.voucher)
      }
    } catch (error) {
      doRegisterFailure(error, values.email)
      actions.setSubmitting(false)
    }
  }

  const formatPhoneNumberOutput = (
    isValid,
    newNumber,
    countryData,
    fullNumber
  ) => {
    
    if(!newNumber) {
      setDisableButton(true)
      setPhonenumberError(true)
      setShowPhoneLabel(false)
    } else {
      setDisableButton(false)
      setPhonenumberError(false)
    }
    if (isValid && fullNumber) {
      return fullNumber.replace(/(\s|-)/g, '');
    }
    setDisableButton(true)
   
    return ''; // caught by validator
  }

  return (
    <Fragment>
      <Formik
        initialValues={{
          name: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          password: "",
          passwordConfirm: "",
          terms: false,
          modalTerms: false,
          modalPolitics: false,
          voucher: userData.voucher.voucherNumber,
        }}
        validationSchema={ValidationSchema}
        onSubmit={registerUser}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched
        }) => (
          <form className="w-100 mt-3" onSubmit={handleSubmit}>
           
            
            <div className="form-group field-float-label-pattern">
              <div>
                <input
                  placeholder="Nome"
                  className="form-control input-not-yellow"
                  id="inputName"
                  value={values.name}
                  name="name"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="inputName">Nome</label>
                {errors.name && touched.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              </div>
            </div>
          
      
            <div className="form-group field-float-label-pattern" style={{marginBottom: 0}}>
             {showPhoneLabel ? (
               <div style={{marginBottom: '.9em'}}>
                <input
                  placeholder="Sobrenome"
                  className="form-control  input-not-yellow"
                  id="inputLastName"
                  value={values.lastName}
                  name="lastName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="inputLastName">Sobrenome</label>
                {errors.lastName && touched.lastName && (
                  <ErrorMessage>{errors.lastName}</ErrorMessage>
                )}
              </div>
             ) : (
                 <div>
                  <input
                    placeholder="Sobrenome"
                    className="form-control  input-not-yellow"
                    id="inputLastName"
                    value={values.lastName}
                    name="lastName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="inputLastName">Sobrenome</label>
                  {errors.lastName && touched.lastName && (
                    <ErrorMessage>{errors.lastName}</ErrorMessage>
                  )}
                </div>
             )}
            </div>
        

        
            <div className="form-group field-float-label-pattern">
                 
                   <div style={{marginBottom: 0}} id="phone">
                  {showPhoneLabel && <span className="label-fone">Telefone</span>}

                   <IntlTelInput
                    defaultCountry="br"
                    disabled={isSubmitting}
                    preferredCountries={['br']}
                    style={{width: '100%'}}
                    customPlaceholder={(e) => e.replace(/[0-9]/g, 9)}
                    inputClassName="form-control input-not-yellow"
                    fieldId="phoneNumber"
                    fieldName="phoneNumber"
                    onPhoneNumberFocus={() => setShowPhoneLabel(true)}
                    onPhoneNumberBlur={() => {
                      setFieldTouched("phoneNumber", true);
                      
                    }}
                    onPhoneNumberChange={(...args) => {
                      setFieldValue("phoneNumber", formatPhoneNumberOutput(...args));
                    }}
                    id="inputPhoneNumber"
                    name="phoneNumber"
                    format
                    required
                  />
                   
                  </div>   
                   {errors.phoneNumber && touched.phoneNumber && <ErrorMessage>{errors.phoneNumber}</ErrorMessage>}
                </div>
            

            

            <div className="form-group field-float-label-pattern">
              <div>
                <input
                  type="password"
                  placeholder="Senha"
                  className="form-control input-not-yellow"
                  id="inputPassword"
                  value={values.password}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="inputPassword">Senha</label>
                {errors.password && touched.password && <ErrorMessage>{errors.password}</ErrorMessage>}
              </div>
            </div>

            <div className="form-group field-float-label-pattern">
              <div>
                <input
                  type="password"
                  placeholder="Confirmar senha"
                  className="form-control input-not-yellow"
                  id="inputPasswordConfirm"
                  value={values.passwordConfirm}
                  name="passwordConfirm"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="inputPasswordConfirm">Confirmar senha</label>
                {errors.passwordConfirm && touched.passwordConfirm && (
                  <ErrorMessage>{errors.passwordConfirm}</ErrorMessage>
                )}
              </div>
            </div>

            <div className="form-group field-float-label-pattern">
              <div>
                <input
                  type="text"
                  placeholder="Cupom Desconto"
                  className="form-control input-not-yellow"
                  id="inputVoucher"
                  value={values.voucher}
                  name="voucher"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="inputVoucher">Cupom Desconto</label>
                {errors.voucher && touched.voucher && <ErrorMessage>{errors.voucher}</ErrorMessage>}
              </div>
            </div>

            <div className="row ">
              <div className="col">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div id="termos" style={{ marginRight: "10px" }}>
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          value={values.terms}
                          name="terms"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="cr">
                          <i className="cr-icon fa fa-check" />
                        </span>
                        Aceito os{" "}
                        <ButtonLinks type="button" onClick={() => setFieldValue("modalTerms", true)}>
                          termos
                        </ButtonLinks>
                        e condições de uso e a
                        <ButtonLinks type="button" onClick={() => setFieldValue("modalPolitics", true)}>
                          política de privacidade
                        </ButtonLinks>
                      </label>
                    </div>
                    {errors.terms && touched.terms && <ErrorMessage>{errors.terms}</ErrorMessage>}
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={`btn ${bradesco ? "btn-bradesco" : "btn-login"} ${isSubmitting &&
                      "btn-loading"}`}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Cadastrar
                  </button>
                  <ModalTermos
                    visible={values.modalTerms}
                    onClose={() => setFieldValue("modalTerms", false)}
                  />
                  <ModalPolitica
                    visible={values.modalPolitics}
                    onClose={() => setFieldValue("modalPolitics", false)}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </Fragment>
  )
}

export default RegisterPartnerForm
