/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { Link } from "react-router-dom"
import Img from "react-image"

import placeholder from "../../images/placeholder-avatar.png"
import { API_URL_IMAGES } from "../../utils/constants"
import api from "../../utils/api"

class CardUser extends React.Component {
  state = {
    experiences: false,
  }

  componentDidMount() {
    this.fetchOffices()
  }

  fetchOffices = () => {
    const { result } = this.props
    api.get(`getOfficesByProfile/${result.id}`).then(res => {
      const experiencias = res.sort((a, b) => {
        if (a.dateEnd < b.dateEnd) return 1
        return -1
      })

      this.setState({
        experiences: experiencias,
      })
    })
  }

  render() {
    const { result: user, isRedirect, goToProfile } = this.props
    const { experiences } = this.state
    const isPhoto = user.linkPhoto ? `${API_URL_IMAGES}images/profile/${user.id}.jpg` : placeholder

    const linkProfile = user.role.includes("Aluno")
      ? `/perfilPublico/${user.id}`
      : `/perfilProfessor/${user.id}`

    if(isRedirect) {
      return (
        <div className="card-wrapper-p">
            <div className="card" onClick={() => goToProfile(user)}>
              <div className="d-flex justify-content-center" style={{ width: "250px" }}>
                <Img
                  className="img-fluid rounded-circle"
                  style={{ width: 130, height: 130, border: "1px solid rgba(0, 0, 0, 0.6)" }}
                  src={[isPhoto]}
                />
              </div>
              <div className="card-body" style={{ padding: ".6rem 1rem" }}>
                <h5 className="card-subtitle my-2 text-muted">{user.name}</h5>

                <p className="card-text">{user.role}</p>
                {experiences && experiences.length !== 0 ? (
                  <p className="card-text">
                    <span>
                      <strong>Cargo:</strong> {experiences[0].profession}
                    </span>{" "}
                    <br />
                    <span>
                      <strong>Empresa:</strong> {experiences[0].company}
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
        </div>
      )
    }
    
    return (
      <div className="card-wrapper-p">
        <Link to={linkProfile}>
          <div className="card">
            <div className="d-flex justify-content-center" style={{ width: "250px" }}>
              <Img
                className="img-fluid rounded-circle"
                style={{ width: 130, height: 130, border: "1px solid rgba(0, 0, 0, 0.6)" }}
                src={[isPhoto]}
              />
            </div>
            <div className="card-body" style={{ padding: ".6rem 1rem" }}>
              <h5 className="card-subtitle my-2 text-muted">{user.name}</h5>

              <p className="card-text">{user.role}</p>
              {experiences && experiences.length !== 0 ? (
                <p className="card-text">
                  <span>
                    <strong>Cargo:</strong> {experiences[0].profession}
                  </span>{" "}
                  <br />
                  <span>
                    <strong>Empresa:</strong> {experiences[0].company}
                  </span>
                </p>
              ) : null}
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

export default CardUser
