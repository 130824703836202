import styled from "styled-components";

export const Container = styled.div`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding-right: 10px;
`

export const BarProgress = styled.progress`
    width: 100% !important;
    height: 5px;
    margin-left: 5px;
    margin-right: 2px;
    border: 0 none;
    background: #bdbdbd !important;

    ::-webkit-progress-bar {
        background: rgb(189, 189, 189) !important;
    }
    ::-webkit-progress-value {
        background: #27aae1 !important;
    }
`