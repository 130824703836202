import React, { memo } from "react"
import PrivateCard from "../Home/PrivateCard"

const Card = ({ infos, goMicromomento }) => {
  const icon = type => {
    switch (type) {
      case "VOD":
        return <i className="fa fa-file-video-o" />
      case "PDF":
        return <i className="fa fa-file-pdf-o" />
      case "VMP":
        return <i className="fa fa-clipboard" />
      case "FOR":
        return <i className="fa fa-list-alt" />
      case "BIB":
        return <i className="fa fa-book" />
      case "QIZ":
        return <i className="fa fa-align-left" />
      default:
        return null
    }
  }

  const freeUser = JSON.parse(localStorage.getItem("freeUser"))
  const showLock = freeUser && !infos.freeCourse

  return (
    <div role="button" onClick={() => goMicromomento(infos)} className="card-wrapper-p">
      <div className="card">
        <img className="card-img-top" src={infos.capa} alt="course cape" />
        <div className="card-body" style={{ padding: ".6rem 1rem" }}>
          <h4 className="d-inline-block card-title">
            {infos.name} {showLock && <PrivateCard />}
          </h4>

          <h5 className="card-subtitle mb-2 text-muted">
            {icon(infos.type)} {infos.type === "VOD" && "Video"}
            {infos.type === "PDF" && "Documento PDF"}
          </h5>
          <p className="card-text">{infos.time}</p>
        </div>
      </div>
    </div>
  )
}

export default memo(Card)
