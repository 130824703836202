import { Types } from "./types"

/**
 * Actions
 */

export const Creators = {
  setOnboardingPages: (data) => ({
    type: Types.SET_ONBOARDING_PAGES,
    payload: data,
  }),
  setOnbordingPageConcluded: (currentIndex, data, concluded = true) => ({
    type: Types.SET_ONBOARDING_PAGE_CONCLUDED,
    payload: {index: currentIndex, data, concluded},
  }),
  setDynamicQuestions: (data) => ({
    type: Types.SET_DYNAMIC_QUESTIONS,
    payload: data
  }),
  setAnswersInterest: (data) => ({
    type: Types.SET_ANSWERS_INTEREST,
    payload: data
  })
}
