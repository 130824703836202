import React, { useState } from "react";
import { useSelector } from "react-redux";
import Screen from "./screen";
import { MENU_NAVIGATION, SELECT_OPTIONS } from "../constants";

export default () => {
  const [openSearch, setOpenSearch] = React.useState(false);
  const [interationsModalIsOpen, setInterationsModalIsOpen] = React.useState(false);
  const groups = useSelector(state => state.social.groups);
  const [logo] = useState("https://lit.com.br/site/wp-content/themes/lit/images/logo-branco.png");

  const [menuNavigation] = useState([
    {
      link: '#',
      target: '',
      description: MENU_NAVIGATION.PROFILE,
      initialStatus: ""
    },
    {
      link: '#',
      target: '',
      description: MENU_NAVIGATION.ACCOUNT,
      initialStatus: ""
    }
  ]);

  const [selectSearchOptions] = useState([
    {
      value: SELECT_OPTIONS.OPTION_MANAGEMENT_PEOPLE,
      label: SELECT_OPTIONS.MANAGEMENT_PEOPLE
    },
    {
      value: SELECT_OPTIONS.OPTION_PERSONAL_FINANCES,
      label: SELECT_OPTIONS.PERSONAL_FINANCES
    },
    {
      value: SELECT_OPTIONS.OPTION_VIRTUAL_MARKET,
      label: SELECT_OPTIONS.VIRTUAL_MARKET
    },
    {
      value: SELECT_OPTIONS.OPTION_CRYPTCOINS,
      label: SELECT_OPTIONS.CRYPTCOINS
    },
    {
      value: SELECT_OPTIONS.OPTION_BITCOINS,
      label: SELECT_OPTIONS.BITCOINS
    },
    {
      value: SELECT_OPTIONS.OPTION_ACCOUNTING,
      label: SELECT_OPTIONS.ACCOUNTING
    },
    {
      value: SELECT_OPTIONS.OPTION_TAXES,
      label: SELECT_OPTIONS.TAXES
    },
    {
      value: SELECT_OPTIONS.OPTION_ABUSIVE_INTEREST,
      label: SELECT_OPTIONS.ABUSIVE_INTEREST
    }
  ]);

  return (
    <>
      <Screen
        logo={logo}
        groups={groups}
        menuNavigation={menuNavigation}
        selectSearchOptions={selectSearchOptions}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
      />
    </>
  );
};
