import React from 'react';
import moment from 'moment';
import styled from "styled-components"

const Content = styled.div`
    @import url('https://fonts.googleapis.com/css?family=Lato');

    * {
        box-sizing: border-box;
    }

    body {
        font-family: 'Lato', sans-serif;
    }

    h1 {
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
    }

    .countdown-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .countdown-item {
        color: #484848;
        font-size: 18   px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 15px;
        margin: 5px;
        padding-top: 0px;
        position: relative;
        width: 70px;
        height: 70px;
    }

    .countdown-item span {
        color: #484848;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .countdown-svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
    }
`

class Countdown extends React.Component {
    state = {
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    };

    componentDidMount() {
        moment.locale('pt-br');
        this.interval = setInterval(() => {
            const { timeTillDate, timeFormat } = this.props;
            const then = moment(timeTillDate, timeFormat);
            
            //const now = moment();            
            //const countdown = moment(then - now);
            const countdown = moment.duration(moment(then).diff(moment()))

            const days = parseInt(countdown.asDays(), 10);
            const hours = countdown.hours();
            const minutes = countdown.minutes();
            const seconds = "0";
            
            /* const days = countdown.format('D');
            const hours = countdown.format('HH');
            const minutes = countdown.format('mm');
            const seconds = countdown.format('ss'); */

            this.setState({ days, hours, minutes, seconds });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { days, hours, minutes, seconds } = this.state;
        const { background, textColor } = this.props;

        // Mapping the date values to radius values
        const daysRadius = mapNumber(days, 30, 0, 0, 360);
        const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
        const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
        const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

        if (!seconds) {
            return null;
        }

        return (<Content background={background} textColor={textColor}>
            <div>
                <div className="countdown-wrapper">
                    {days && (
                        <div className="countdown-item">
                            <SVGCircle radius={daysRadius} textColor="#484848" />
                            {days}
                            <span>DIAS</span>
                        </div>
                    )}
                    {hours && (
                        <div className="countdown-item">
                            <SVGCircle radius={hoursRadius} textColor="#484848" />
                            {hours}
                            <span>HORAS</span>
                        </div>
                    )}
                    {minutes && (
                        <div className="countdown-item">
                            <SVGCircle radius={minutesRadius} textColor="#484848" />
                            {minutes}
                            <span>MINUTOS</span>
                        </div>
                    )}
                    {/* {seconds && (
                        <div className="countdown-item">
                            <SVGCircle radius={secondsRadius} textColor={textColor} />
                            {seconds}
                            <span>SEGUNDOS</span>
                        </div>
                    )} */}
                </div>
            </div>
        </Content>);
    }
}

const SVGCircle = ({ radius, textColor }) => (
    <svg className="countdown-svg">
        <path
            fill="none"
            stroke= "#484848"
            /* stroke= {"#27AAE1"}   */          
            strokeWidth="4"
            d={describeArc(35, 35, 32, 0, radius)}
        />
    </svg>
);

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
}

function describeArc(x, y, radius, startAngle, endAngle) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
    ].join(' ');

    return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
    return (
        ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
}

export default Countdown