import styled, { css } from "styled-components";
import { COLOR, ORIENTATION } from "../../../constants";

export const Span = styled.span``;

export const Info = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  padding: 10px 0;  
  i { 
    margin: 0 0 5px!important; 
  }

  ${props => (props.color === COLOR.LIGHT) && css`
    * { 
      color: var(--white); 
      justify-content: center; 
      text-align: center; 
      text-transform: initial;
    }
  `}

  ${props => (props.orientation === ORIENTATION.HORIZONTAL) && css`
    flex-direction: row;
    align-items: flex-start;
    i {
      font-size: 100%;
      top: 3px;
    }
    span {
      margin-left: 8px;
      display: flex;
      flex-direction: column-reverse;
      label {
        display: block;
        &:last-child {
          font-size: 120%;
          text-align: left!important;
        }
      } 
    }
    @media (max-width: 480px) {
      span {
        label {
          &:last-child {
            font-size: 84%;
          }
        }
      }
    }
  `}
  
`;
