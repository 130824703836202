import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} left //receives the content that will be displayed in the left area
 * @param {Component} right //receives the content that will be displayed in the right area
**/

export default ({
  left = DEFAULT_VALUES.COMPONENT,
  right = DEFAULT_VALUES.COMPONENT
}) => {
  return (
    <>
      <Screen
        left={left}
        right={right}
      />
    </>
  );
};
