import React from "react";
import {
  ICON,
  USER_ROLE,
  NUMBER,
  SIZE,
  VISIBILITY_OPTIONS
} from "../../../constants";

import Text from "../../../../LitAtomicComponents/basicComponents/Text";
import GroupedListing from "../../../../LitAtomicComponents/GroupedListing";
import IconInfo from "../../../../LitAtomicComponents/Info/IconInfo";

import Container from "../../../../LitAtomicComponents/containers/PageContainer";
import Row30x70 from "../../../../LitAtomicComponents/defaultAreas/Row_30-70";
import Row40x60 from "../../../../LitAtomicComponents/defaultAreas/Row_40-60";

import ProfileModal from "../../../../LitAtomicComponents/Modals/ProfileModal";
import SecondaryModal from "../../../../LitAtomicComponents/Modals/SecondaryModal";
import GroupPageSeeAllMembers from "../../../../LitAtomicComponents/CallModal/GroupPageSeeAllMembers";

import PageLeftInfo from "../../../../LitAtomicComponents/containers/PageLeftInfo";
import Timeline from "../../../../LitAtomicComponents/Contents/Timeline";

import { Description, AllMembers } from "./styles";

export default ({
  isAdministrator,
  isMember,
  communications,
  members,
  groupRoles,
  group,
  member,
  infoPage,
  totalMembers,
  removeMember,
  updatePost,
  createComment,
  createPost,
  deleteCommunication,
  transferGroupPossession,
  alterMemberRole,
  setProfileUser,
  user,
  visibility,
  interationsModalIsOpen,
  loadCommunicationReactions,
  loadReactionPeople,
  setInterationsModalIsOpen,
  loadMoreCommunications,
  reactionsTypes,
  updateCommunicationReactions,
  hasMoreCommunications
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [secondaryModalIsOpen, setSecondaryModalIsOpen] = React.useState(false);

  function openModal(item) {
    setIsOpen(true);
    setProfileUser(item);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeSecondaryModal() {
    setSecondaryModalIsOpen(false);
  }

  async function removeMemberAndClosemodals() {
    await removeMember(user.cr_code);
    closeModal();
    closeSecondaryModal();
  }

  async function alterMemberRoleAndClosemodals(alterRole) {
    await alterMemberRole(user.cr_code, alterRole);
    closeModal();
    closeSecondaryModal();
  }

  async function transferGroupPossessionAndClosemodals() {
    await transferGroupPossession(user.cr_code);
    closeModal();
    closeSecondaryModal();
  }

  function showMembersGroupedList() {
    if (isMember || visibility === VISIBILITY_OPTIONS.public.value) {
      return (
        <>
          {members.map(data => (
            <GroupedListing
              data={data}
              grid={NUMBER.TWO}
              callback={openModal}
            />
          ))}
        </>
      );
    }
  }

  function showAllMembers() {
    if (isMember || visibility === VISIBILITY_OPTIONS.public.value) {
      return (
        <AllMembers>
          <GroupPageSeeAllMembers
            pageImage={infoPage.img}
            members={members}
            group={group}
            member={member}
            callback={openModal}
          />
        </AllMembers>
      );
    }
  }

  function showMembersProfileModal() {
    if (isMember || visibility === VISIBILITY_OPTIONS.public.value) {
      return (
        <SecondaryModal isOpen={modalIsOpen} closeModal={closeModal}>
          <ProfileModal
            isAdministrator={isAdministrator}
            member={user}
            pageImage={infoPage.img}
            groupRoles={groupRoles}
            isOpen={setIsOpen}
            user={member}
            closeModal={closeSecondaryModal}
            removeMember={removeMemberAndClosemodals}
            alterMemberRole={alterMemberRoleAndClosemodals}
            transferGroupPossession={transferGroupPossessionAndClosemodals}
          />
        </SecondaryModal>
      );
    }
  }

  function showTimeline() {
    if (isMember || visibility === VISIBILITY_OPTIONS.public.value) {
      return (
        <Timeline
          isAdministrator={isAdministrator}
          communications={communications}
          group={group}
          member={member}
          updatePost={updatePost}
          createComment={createComment}
          createPost={createPost}
          deleteCommunication={deleteCommunication}
          interationsModalIsOpen={interationsModalIsOpen}
          setInterationsModalIsOpen={setInterationsModalIsOpen}
          hiddenCreatePost={!isMember}
          loadCommunicationReactions={loadCommunicationReactions}
          loadReactionPeople={loadReactionPeople}
          loadMoreCommunications={loadMoreCommunications}
          reactionsTypes={reactionsTypes}
          updateCommunicationReactions={updateCommunicationReactions}
          hasMoreCommunications={hasMoreCommunications}
        />
      );
    }
  }

  return (
    <>
      <Container>
        <Row40x60
          left={[
            <PageLeftInfo
              itemsToRender={[
                <Row30x70
                  left={
                    <IconInfo
                      icon={ICON.USERS}
                      title={USER_ROLE.MEMBERS}
                      subtitle={totalMembers}
                    />
                  }
                  right={
                    <IconInfo
                      icon={ICON.USERS}
                      title={USER_ROLE.ADMINISTRATOR}
                      subtitle={
                        group.administrator && group.administrator.name
                          ? group.administrator.name
                          : USER_ROLE.ADMINISTRATOR
                      }
                    />
                  }
                />,

                <Description>
                  <Text text={group.description} size={SIZE.MEDIUM} />
                </Description>,

                showMembersGroupedList(),
                showAllMembers(),
                showMembersProfileModal()
              ]}
            />
          ]}
          right={[showTimeline()]}
        />
      </Container>
    </>
  );
};
