import styled from 'styled-components';

export const Container = styled.div`

    background-color: ${props => props.isActive ? '#d7f0fc' : 'transparent'} !important;
    color: ${props => props.isActive ? '#27aae1' : 'black'} !important;
    cursor: pointer;
    
`
export const ContentItem = styled.li`
    font-size: 14;
    padding: 5px 15px;
`
export const ContentItemChild = styled.div`
  cursor: ${props => props.available ? 'pointer' : 'not-allowed'} !important;

  .circle-with-grade{
    font-size: 18px;
    color: white;
    padding: 14px 16px !important;
    border-radius: 27% !important;
    margin-left: -8px;
  }
`
export const ContainerIcon = styled.div`
    padding: 6px 13px;
    color: #27AAE1;
    font-size: 18px;
`

export const GradeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;