function formatPrice(int) {
  const parsedValue = parseFloat(int);
  if (Number.isNaN(parsedValue)) return '';
  return parsedValue
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.');
}

module.exports = {
  formatPrice
};
