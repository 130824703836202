import styled from 'styled-components'
import { LitButton } from '../../shared/LitButton';

export const Button = styled(LitButton)`
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BoxInfo = styled.div`
  max-width: 500px;
  width: 100%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  color: #FCEE1E;

  #parcelas {
    display: flex;
    align-items: center;
    width: 50px;
    justify-content: space-between;
    color: #fff;
  }
`

export const ButtonCollapse = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  &:active, &:focus {
    box-shadow: none;
    outline: none;
  }

  div span {
    font-weight: bold;
  }
`
