import React, { memo, useMemo } from "react";

import Media from "./Media";
import Extra from "./Extra";
import Sobre from './Sobre'
import SaibaMais from "./Extra/SaibaMais";

const Main = ({ tags = [], selected }) => {

  const isAbout = useMemo(() => {
    if(tags.length > 0) {
      return tags.includes("ABOUT");
    }
    return false;
  }, [tags]);

  let main;
  
  if (isAbout && !selected.showKnowMore) {
    main = <Sobre />;
  } else if(selected.showKnowMore) {
    main = <SaibaMais />;
  } else {
    main = <Media />;
  }

  return (
    <React.Fragment>
      {main}
      <Extra />
    </React.Fragment>
  );
};

export default memo(Main);
