import styled from 'styled-components'

export const Container = styled.div`
  background: white;
  padding: 1em 2em;
  max-width: 550px;
  margin: 20px auto;
  border-radius: 12px;

  h2 {
    margin-bottom: 10px;
  }
`