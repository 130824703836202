import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Recaptcha from 'react-recaptcha';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import * as Styles from './styles';
import * as GeneralStyles from '../../styles';
import Input from '../Input';
import Loading from '../Loading';
import Select from '../SelectInput';
import PaymentTerm from '../PaymentTerm';
import TextPaymentDefault from '../TextPaymentDefault';
import { RECAPTCHA_KEY } from '../../../../utils/constants';
import IMG_VISA from '../../../../images/pagamento/visa.png';
import IMG_MASTER from '../../../../images/pagamento/master.png';
import IMG_DINNERS from '../../../../images/pagamento/diners.png';
import IMG_ELO from '../../../../images/pagamento/elo.png';
import IMG_AMERICAN from '../../../../images/pagamento/american.png';

import { RecurrencyList } from './components/RecurrencyList'

import generateInstallmentsOptions from '../../helpers/generateInstallmentsOptions';
import generateYearsOptions from '../../helpers/generateYearsOptions';
import generateMonthsOptions from '../../helpers/generateMonthsOptions';
import getTotalPriceByInstallmentsDiscount from '../../helpers/getTotalPriceByInstallmentsDiscount';

import { Creators as InstallmentsActions } from '../../../../store/ducks/payments/installments/actions';

const isProductionEnvironment = process.env.REACT_APP_MY_ENV === 'production';

function CreditCardForm({
  selectedPlan,
  onSubmit,
  userTerm,
  loading,
  handleGenerateTerm,
  showTerm,
  setShowTerm,
  generatingTerm
}) {

  const formRefer = useRef(null);
  const validationSchema = Yup.object().shape({
    holderName: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    number: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    cvc: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    expiryMonth: Yup.number()
      .typeError('Campo obrigatório')
      .required('Campo obrigatório')
      .default(''),
    expiryYear: Yup.number()
      .typeError('Campo obrigatório')
      .required('Campo obrigatório')
      .default(''),
    document: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    dateOfBirth: Yup.string()
      .required('Campo obrigatório')
      .default(''),
    installments: Yup.number()
      .nullable()
      .default(0)
  });

  const installmentData = useSelector(
    installments => installments.installments
  );

  const [, setFocused] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(
    !isProductionEnvironment
  );
  const [paymentMethod] = useState('CREDIT');

  const couponData = useSelector(coupon => coupon.coupon);
  const showCaptcha = useMemo(() => {
    return isProductionEnvironment;
  }, []);

  const installmentsOptions = useMemo(
    () => generateInstallmentsOptions(selectedPlan, paymentMethod),
    [selectedPlan]
  );

  const yearsOptions = useMemo(generateYearsOptions, []);

  const monthsOptions = useMemo(generateMonthsOptions, []);

  const dispatch = useDispatch();

  const handleInstallmentSelected = (value, selectedPlan) => {
    const installmentValue = value.target.value;
    const installmentData = getTotalPriceByInstallmentsDiscount(
      installmentValue,
      selectedPlan,
      paymentMethod,
      couponData
    );
    dispatch(InstallmentsActions.setInstallmentValue(installmentData));
  };

  useEffect(() => {
    if (formRefer) {
      const { installments } = installmentData;
      formRefer.current.setFieldValue('installments', installments);
    }
  }, [installmentData]);

  return (
    <>
      <Formik
        ref={formRefer}
        validationSchema={validationSchema}
        initialValues={validationSchema.cast()}
        onSubmit={handleGenerateTerm}
        render={({
          values,
          touched,
          errors,
          setFieldValue,
          handleChange,
          handleBlur,
          resetForm,
          handleSubmit
        }) => (
          <Styles.Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4 col-lg-4 col-12 mt-4 acceptedCardsWrapper">
                <div className="acceptedCards">
                  <Styles.LabelField>Cartões aceitos</Styles.LabelField>

                  <div>
                    <img src={IMG_VISA} alt="visaCard" />
                    <img src={IMG_MASTER} alt="masterCard" />
                    <img src={IMG_DINNERS} alt="dinersClub" />
                    <img src={IMG_AMERICAN} alt="amexCard" />
                    <img src={IMG_ELO} alt="eloCard" />
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-8 col-12 mt-4">
                <div className="form-group">
                  <Select
                    name="installments"
                    label="Número de parcelas"
                    options={installmentsOptions}
                    value={values.installments}
                    onChange={value => {
                      handleChange(value);
                      handleInstallmentSelected(value, selectedPlan);
                    }}
                    onBlur={handleBlur}
                    style={{ backgroundColor: 'white' }}
                    error={touched.installments && errors.installments}
                  />
                </div>
              </div>
            </div>

            {selectedPlan.recurrencyQuantity > 1 && (
              <div className="row">
                <div className="col-12">
                  <RecurrencyList offerData={{
                    recurrencyPeriodQuantity: selectedPlan.recurrencyPeriodQuantity,
                    recurrencyPeriodType: selectedPlan.recurrencyPeriodType,
                    recurrencyQuantity: selectedPlan.recurrencyQuantity,
                    installments: installmentData.installments,
                    price: installmentData.price
                  }} />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <InputMask
                    maskChar={null}
                    name="number"
                    mask="9999 9999 9999 9999"
                    value={values.number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => setFocused('number')}
                  >
                    {inputProps => (
                      <Input
                        {...inputProps}
                        label="Número do cartão do titular"
                        placeholder="9999 9999 9999 9999"
                        error={touched.number && errors.number}
                        className="form-control"
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <Input
                    onFocus={() => setFocused('holderName')}
                    name="holderName"
                    value={values.holderName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Nome como está no cartão"
                    placeholder="Nome completo do titular"
                    error={touched.holderName && errors.holderName}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3 col-6">
                <div className="form-group">
                  <Select
                    options={monthsOptions}
                    onFocus={() => setFocused('expiry')}
                    name="expiryMonth"
                    label="Vencimento do cartão"
                    value={values.expiryMonth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.expiryMonth && errors.expiryMonth}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-6">
                <div className="form-group">
                  <Styles.LabelField style={{ visibility: 'hidden' }}>
                    Ano
                  </Styles.LabelField>

                  <Select
                    onFocus={() => setFocused('expiry')}
                    name="expiryYear"
                    value={values.expiryYear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={yearsOptions}
                    error={touched.expiryYear && errors.expiryYear}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg">
                <div className="form-group">
                  <Styles.LabelField>CVV</Styles.LabelField>

                  <InputMask
                    maskChar={null}
                    mask="9999"
                    onFocus={() => setFocused('cvc')}
                    name="cvc"
                    value={values.cvc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {inputProps => (
                      <Input
                        {...inputProps}
                        placeholder="000"
                        error={touched.cvc && errors.cvc}
                        className="form-control"
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg">
                <div className="form-group">
                  <Styles.LabelField>CPF do titular</Styles.LabelField>

                  <InputMask
                    maskChar={null}
                    mask="999.999.999-99"
                    name="document"
                    value={values.document}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {inputProps => (
                      <Input
                        {...inputProps}
                        placeholder="000.000.000-00"
                        error={touched.document && errors.document}
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg">
                <div className="form-group">
                  <Styles.LabelField>Data de nascimento</Styles.LabelField>

                  <DatePicker
                    name="dateOfBirth"
                    selected={values.dateOfBirth}
                    onChange={date => {
                      setFieldValue('dateOfBirth', date);
                    }}
                    onBlur={handleBlur}
                    customInput={
                      <InputMask
                        mask="99/99/9999"
                        value={values.dateOfBirth}
                        onChange={event => {
                          const { value } = event.target;
                          setFieldValue('dateOfBirth', value);
                        }}
                      >
                        {inputProps => (
                          <Input
                            {...inputProps}
                            placeholder="00/00/0000"
                            error={touched.dateOfBirth && errors.dateOfBirth}
                            className="form-control"
                          />
                        )}
                      </InputMask>
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                {showCaptcha && (
                  <Recaptcha
                    hl="pt"
                    elementID="captcha-cartao"
                    sitekey={RECAPTCHA_KEY}
                    type="none"
                    render="explicit"
                    verifyCallback={() => setCaptchaVerified(true)}
                  />
                )}
              </div>
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-sm-12 col-lg-8">
                <GeneralStyles.TermWithCheckBoxContainer>
                  <TextPaymentDefault
                    selectedPlan={selectedPlan}
                    paymentMethod={paymentMethod}
                  />
                </GeneralStyles.TermWithCheckBoxContainer>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div
                  className="box-buttons"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <GeneralStyles.CleanButton
                    visual="outline"
                    onClick={() => resetForm()}
                  >
                    Limpar
                  </GeneralStyles.CleanButton>
                  <GeneralStyles.ConfirmButton
                    disabled={!captchaVerified || loading || generatingTerm}
                  >
                    {loading || generatingTerm ? <Loading /> : 'Enviar'}
                  </GeneralStyles.ConfirmButton>
                </div>
              </div>
            </div>
          </Styles.Form>
        )}
      />

      <PaymentTerm
        visible={showTerm}
        onBack={() => setShowTerm(false)}
        onAccept={() => onSubmit(formRefer.current.state.values)}
        onReject={() => setShowTerm(false)}
        termUrl={_.get(userTerm, 'ox_specific.wu_url')}
        processingAccept={loading}
      />
    </>
  );
}

export default CreditCardForm;
