import React, { useEffect, useState, useCallback, useMemo } from "react";
import fileDownload from "js-file-download";
import swal from "@sweetalert/with-react";
import { useDropzone } from "react-dropzone";
import Axios from 'axios';
import {
  FaSpinner,
  FaRegComments,
  FaRegCommentDots,
  FaRegComment,
  FaPaperclip
} from "react-icons/fa";
import mimeTypes from "mime-types";
import _ from "lodash";
import mathlive from "mathlive";
import DragAndDrop from "../../utils/DragAndDrop";

import { renderUploadThumb } from "../../utils/functions";

import Feedbacks from "./subcomponents/Feedbacks";
import Navbar from "./subcomponents/Navbar";
import { gateway } from "../../utils/api";
import avatar from "../../images/placeholder-avatar.png";

import ForumView from "./subcomponents/ForumView";
import UserMessage from './subcomponents/UserMessage'

import ViewEditor from '../shared/ViewEditor';
import LitEditor from '../shared/LitEditor';

import {
  Container,
  Header,
  Statement,
  Content,
  InputWrapper,
  LoadingWrapper,
  Thumb,
  ThumbInner,
  ThumbsContainer,
  DropZoneClip,
  Spacing,
  MediaTitle,
  MediaButton,
  WrapperMedias,
  BoxInfo,
  BackgroundGray,
  BoxWhite,
  ButtonBlue,
  WrapperDetails,
  BoxDetail,
  ItemDetail,
  CommentButtonsActions,
  WrapperButtons,
  ButtonTransparent
} from "./styles";

const PAGE_LIMIT = 50;
const STATEMENT_TEXT_LIMIT = 350;

function AssessmentForum({ location }) {
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sendingMessagge, setSendingMessage] = useState(false);
  const [userMessages, setUserMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [message, setMessage] = useState("");
  const [showMoreText, setShowMoreText] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [feedbacks, setFeedbacks] = useState([]);
  const [headerImagePath, setheaderImagePath] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [thumbs, setThumbs] = useState(null);
  const [medias, setMedias] = useState([])
  const [answering, setAnswering] = useState(false)

  const code = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("code");
  }, [location]);

  const rawStatement = useMemo(() => {
    return _.get(assessmentData, "surveys.0.statement", "");
  }, [assessmentData]);

  const statement = useMemo(() => {
    if (rawStatement.length > STATEMENT_TEXT_LIMIT && !showMoreText) {
      return rawStatement.slice(0, STATEMENT_TEXT_LIMIT);
    }

    return rawStatement;
  }, [assessmentData, showMoreText]);

  function handleErrorAlert() {
    swal({
      title: `Ops, ocorreu um erro.`,
      text: `Não foi possível buscar os dados dessa avaliação.`,
      buttons: {
        confirm: {
          text: "Continuar",
          value: true,
          visible: true,
          className: "btn-alert btn-alert-confirm-onboarding"
        }
      }
    }).then(() => {
      window.close();
    });
  }

  function renderText(text) {
    // Pattern to identify latex expression like {\infty}
    const latexPattern = /\\\w+({.*})?/g;
    const hasLatex = latexPattern.test(text);
    if (hasLatex) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: mathlive.latexToMarkup(text)
          }}
        />
      );
    }

    return <ViewEditor data={text} />
  }

  const loadMessages = useCallback(async () => {
    try {
      setLoading(true);
      const url = `/webbff/lit_app_web/evaluation/${code}/forum/messages?page=${page}&pageSize=${PAGE_LIMIT}`;
      const response = await gateway.get(url);
      setMessages(response.docs);
      setPage(response.page);
      setTotalPages(response.totalDocs > 0 ? response.totalPages : 0);
      setHasPrevPage(response.hasPrevPage);
      setHasNextPage(response.hasNextPage);
      setLoading(false);
    } catch (error) {
      console.log(error);
      swal({
        title: `Ops, ocorreu um erro.`,
        text: `Não foi possível buscar mensagens`
      });
      setLoading(false);
    }
  }, [page, code]);

  async function goToMessage(messageData) {
    try {
      setLoading(true);
      const response = await gateway.get(
        `/webbff/lit_app_web/evaluation/${code}/forum/messages?page=${page}&pageSize=${PAGE_LIMIT}&dateForPageIndex=${messageData.createAt}`
      );

      setMessages(response.docs);
      setPage(response.page);
      setTotalPages(response.totalDocs > 0 ? response.totalPages : 0);
      setHasPrevPage(response.hasPrevPage);
      setHasNextPage(response.hasNextPage);
      setRedirectTo(messageData.code);
    } catch (error) {
      swal({
        title: `Ops, ocorreu um erro.`,
        text: `Não foi possível carregar mensagem selecionada`
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSendMessage() {
    if (message) {
      try {
        setSendingMessage(true);

        const newMessage = await gateway.post(
          `/webbff/lit_app_web/evaluation/${code}/forum/messages`,
          {
            message,
            responseReference: assessmentData.responseCode,
            mediasReferences: attachments
          }
        );

        setAttachments([]);
        goToMessage(newMessage);
        setMessage("");
        setFiles([]);
        setThumbs(null);
        setUserMessages(prevState => [...prevState, newMessage]);
      } catch (error) {
        console.log(error);
      } finally {
        setSendingMessage(false);
      }
    }
  }

  async function handleCommentAnswer(
    answerdMessage,
    messageText,
    path,
    attachmentsComment
  ) {
    if (answerdMessage && messageText) {
      try {
        const newMessage = await gateway.post(
          `/webbff/lit_app_web/evaluation/${code}/forum/messages`,
          {
            message: messageText,
            responseReference: assessmentData.responseCode,
            repliedMessageReference: answerdMessage.code,
            mediasReferences: attachmentsComment
          }
        );

        setFiles([]);
        setThumbs(null);
        setAttachments([]);

        const clonedMessages = [...messages];

        const oldAnsweredMessageData = _.get(clonedMessages, path);

        const newAnsweredMessageData = {
          ...oldAnsweredMessageData,
          answers: [...oldAnsweredMessageData.answers, newMessage]
        };

        const newMessages = _.set(clonedMessages, path, newAnsweredMessageData);

        setMessages(newMessages);
      } catch (error) {
        console.log(error);
      } finally {
        setSendingMessage(false);
      }
    }
  }

  function handleExit() {
    swal({
      title: "Atenção",
      text: "Você está tentando sair dessa avaliação, deseja continuar?",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
          className: "btn-alert"
        },
        confirm: {
          text: "Continuar",
          value: true,
          visible: true,
          className: "btn-alert btn-alert"
        }
      }
    }).then(value => {
      if (value) {
        window.close();
      }
    });
  }

  const getFeedbacks = useCallback(async () => {
    try {
      const url = `/webbff/admin_app_web/evaluation/response/${assessmentData.responseCode}/feedback`;

      const response = await gateway.get(url);

      setFeedbacks(response);
    } catch (error) {
      console.log(error);
    }
  }, [assessmentData, code]);

  async function handleSendFeedback(newMessage) {
    try {
      const foundItem = feedbacks
        .reverse()
        .find(item => !Number.isNaN(parseInt(item.grade, 10)));

      const url = `/webbff/lit_app_web/evaluation/response/feedback/${foundItem.feedbackCode}/reply`;

      await gateway.post(url, { message: newMessage });

      await getFeedbacks();
    } catch (error) {
      console.log(error);
    }
  }

  function handleCloseTheFeedbackChat() {
    setShowFeedback(false);
  }

  const handleAttachment = attachment => {
    attachments.push(attachment);
    setAttachments(attachments);
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      const [file] = acceptedFiles;
      files.push(file);
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setFiles(files);

      const thumbImg = files.map(file => {
        let extension = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );

        if (!extension) {
          extension = mimeTypes.extension(file.type) || "ND";
        }

        return (
          <Thumb key={file.name}>
            <ThumbInner>{renderUploadThumb(extension, file)}</ThumbInner>
          </Thumb>
        );
      });

      setThumbs(thumbImg);

      if (file) {
        try {
          setLoadingAttachment(true);

          const formData = new FormData();
          let mediaType = "";

          if (file.type !== "") {
            mediaType = _.get(file, "type", null);
          } else {
            mediaType = file.name.slice(
              ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
            );
          }

          formData.append("file", file);
          formData.append("mediaType", mediaType);

          const response = await gateway.post("/media/media/upload", formData);
          handleAttachment(
            _.get(response, "ox_standard.ox_identification.cr_code", null)
          );
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingAttachment(false);
        }
      }
    },
    [thumbs]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, event) => onDrop(acceptedFiles, event),
    disabled: loading,
    multiple: false
  });

  function downloadMedia(e, media) {
    if (media.type !== "VIDEO") {
      e.preventDefault();
    }
    const url = media.path;
    const filename = media.name;
    const extension = media.path.slice(
      ((media.path.lastIndexOf(".") - 1) >>> 0) + 2
    );

    Axios.get(url, {
      responseType: "blob"
    }).then(res => {
      fileDownload(res.data, `${filename}.${extension}`);
    });
  }

  useEffect(() => {
    async function loadAssessment() {
      try {
        const url = `/webbff/lit_app_web/evaluation/${code}`;

        const response = await gateway.get(url);
        setAssessmentData(response);

        const surveysMedias = response.surveys.filter(survey => survey.media);
        setMedias(surveysMedias);


        setUserMessages(response.userForumMessages);
        setLoading(false);
      } catch (error) {
        handleErrorAlert();

        setLoading(false);
      }
    }

    if (code) {
      loadAssessment();
    } else {
      handleErrorAlert();
    }
  }, []);

  useEffect(() => {
    if (assessmentData) {
      const headerImagePath = _.get(assessmentData, "surveys.0.media.path");
      setheaderImagePath(headerImagePath);
      loadMessages();
      getFeedbacks();
    }
  }, [loadMessages, assessmentData, getFeedbacks]);

  return (
    <BackgroundGray>
      <Navbar
        title={
          assessmentData &&
          `FÓRUM - ${_.get(assessmentData, "surveys.0.title", "")}`
        }
        handleExit={handleExit}
      />
      <Container>
        {(!loading || assessmentData) && (
          <>
            <Header>
              <Statement>
                {renderText(statement)}
                {showMoreText && !_.isEmpty(headerImagePath) && (
                  <p>
                    <img alt="" src={headerImagePath} />
                  </p>
                )}
                {rawStatement.length > STATEMENT_TEXT_LIMIT && (
                  <button
                    type="button"
                    style={{margin: 0, padding: 0, color: '#26ace7'}}
                    onClick={() => setShowMoreText(!showMoreText)}
                  >
                    {showMoreText ? "Ver menos" : "Ver mais"}
                  </button>
                )}

                {/*responder*/}
                {answering &&
                  <>
                  <DragAndDrop handleDrop={onDrop}>
                  
                      <LitEditor
                        style={{ width: '100%', marginTop: 20}}
                        data={message}
                        showToolbar={true}
                        onChange={data => setMessage(data)}
                      />

                    </DragAndDrop>
                    <WrapperButtons>
                      <DropZoneClip {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!loadingAttachment && <><FaPaperclip /> Anexar arquivo</>}
                          </DropZoneClip>
                          {loadingAttachment && (
                            <LoadingWrapper>
                              <FaSpinner size={20} color="#0000ff" />
                            </LoadingWrapper>
                          )}
                    </WrapperButtons>
                  </>
                }

                {medias.length > 0 ? (
                  <Spacing>
                    <MediaTitle>Mídias</MediaTitle>
                    <WrapperMedias>
                      {medias.map(media => {
                        return (
                          <BoxInfo width={230}>
                            <p>{media.name}</p>
                            <MediaButton onClick={e => downloadMedia(e, media)}>
                              <a href={media.path} download target="_blank">
                                {" "}
                                Baixar
                              </a>
                            </MediaButton>
                          </BoxInfo>
                        );
                      })}
                    </WrapperMedias>
                  </Spacing>
                ) : null}

                <CommentButtonsActions>
                  <WrapperButtons>
                    {!loadingAttachment && (
                      !answering ? <ButtonBlue
                        onClick={() => setAnswering(currentState => !currentState)}
                        >Responder</ButtonBlue> 
                        : <>
                          <ButtonTransparent
                            onClick={() => setAnswering(currentState => !currentState)}
                            >Cancelar</ButtonTransparent>
                          <ButtonBlue
                            onClick={handleSendMessage}
                            >{sendingMessagge ? (
                              <LoadingWrapper>
                                <FaSpinner size={20} color="#0000ff" />
                              </LoadingWrapper>
                            ) : (
                              "Publicar"
                            )}</ButtonBlue>
                        </>
                          )}
                  </WrapperButtons>
                </CommentButtonsActions>

                {answering && <ThumbsContainer>{thumbs}</ThumbsContainer>}
                {/*final responder*/}
              </Statement>
            </Header>
            <WrapperDetails>
              <BoxDetail>
                <span><FaRegComments color="#26ace7" /> </span>
                <ItemDetail>Postagens nesta discussão<br /><strong>{messages.length}</strong></ItemDetail>
              </BoxDetail>
              <BoxDetail>
                <span><FaRegComment color="#26ace7" /></span>
                <ItemDetail>Minhas respostas aos outros<br /><strong>{userMessages.length}</strong></ItemDetail>
              </BoxDetail>
              <BoxDetail>
                <span><FaRegCommentDots color="#26ace7" /></span>
                <ItemDetail>Respostas para mim<br /><strong>0</strong></ItemDetail>
              </BoxDetail>
            </WrapperDetails>
          </>
        )}

        {loading && (
          <LoadingWrapper>
            <FaSpinner size={20} color="#1890ff" />
          </LoadingWrapper>
        )}

        {!loading && (
          <Content>
            <main>
              <ForumView
                loading={loading}
                data={messages}
                page={page}
                pageLimit={PAGE_LIMIT}
                totalPages={totalPages}
                onPageChange={setPage}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                onAnswer={handleCommentAnswer}
                redirectTo={redirectTo}
                getRootProps={getRootProps}
              />
            </main>

            {/* <sidebar>
              <p>
                <span>
                  <FaCommentDots />
                  Postagens no forum
                  <b>{messages.length}</b>
                </span>
              </p>
              <p>
                <span>
                  <FaRegComments />
                  Minhas respostas
                  <b>{userMessages.length}</b>
                </span>
              </p>
              <div>
                <p>MINHAS RESPOSTAS E COMENTÁRIOS</p>
                {userMessages.map(item => {
                  return (
                    <UserMessage item={item} goToMessage={() => goToMessage(item)}/>
                  );
                })}
              </div>

              {feedbacks && feedbacks.length > 0 && (
                <button
                  className="feedbackButton"
                  type="button"
                  onClick={() => {
                    setShowFeedback(!showFeedback);
                  }}
                >
                  {showFeedback ? "Esconder feedbacks" : "Ver Feedbacks"}
                </button>
              )}

              {showFeedback && (
                <Feedbacks
                  feedbacks={feedbacks}
                  onSendMessage={handleSendFeedback}
                  handleCloseFeedbacks={handleCloseTheFeedbackChat}
                />
              )}
            </sidebar> */}
          </Content>
        )}
      </Container>
    </BackgroundGray>
    
  );
}

export default AssessmentForum;
