import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Boolean} isEdit //informs if the field is being presented for content editing
 * @param {String} image //image of the user posting
 * @param {String} placeholder //component markup text
 * @param {String} attachmentIcon //icon displayed inside the field
 * @param {String} sendIcon //icon displayed outside the field
 * @param {String} size //{SMALL, MEDIUM, LARGE} field size
 * @param {Function} sendAction //action taken when sending post or comment
 * @param {Array} attachs //list of attached content
 * @param {String} appearance //{SIMPLE_ANSWER} appearance field
**/

export default ({
  isEdit,
  image,
  placeholder,
  attachmentIcon,
  sendIcon,
  size,
  sendAction,
  attachs,
  appearance,
  action,
  onCloseCommentBox,
  isLoading
}) => {
  return (
    <>
      <Screen
        isEdit={isEdit}
        attachs={attachs}
        image={image}
        placeholder={placeholder}
        attachmentIcon={attachmentIcon}
        sendIcon={sendIcon}
        size={size}
        action={action}
        sendAction={sendAction}
        appearance={appearance}
        onCloseCommentBox={onCloseCommentBox}
        isLoading={isLoading}
      />
    </>
  );
};
