import React from "react";
import Screen from "./screen";

export default ({ isOpen, closeModal, children, member }) => {
  return (
    <>
      <Screen
        member={member}
        isOpen={isOpen}
        closeModal={closeModal}
        child={children}
      />
    </>
  );
};
