import React from "react"
import { Link } from "react-router-dom"
import Img from "react-image"
import Modal from "react-responsive-modal"
import Simplert from "react-simplert"
import Linkify from "react-linkify"

import moment from "moment"
import "moment/locale/pt-br"

import placeholder from "../../../images/placeholder-avatar.png"

import Attachment from "./Attachment"
import EditComment from "./Forms/EditComments"
import { API_URL } from "../../../utils/constants"

import api from "../../../utils/api"

class Resposta extends React.Component {
  state = {
    modalOpen: false,
    alert: false,
    willReport: null,
    hasLike: false,
    contadorLike: 0,
    reason: "",
    extra: "",
    openEditarResposta: false,
  }

  componentWillMount() {
    this._fetchLikes()
  }

  deleteAnswer = () => {
    this.setState({
      alert: true,
    })
  }

  confirmDelete = async id => {
    try {
      const { findAnswer } = this.props
      await api.put(`deletingAnswerComments/${id}`)
      findAnswer()
    } catch (error) {
      console.log(error)
    }
  }

  reportComment = async () => {
    const { willReport, reason, extra } = this.state
    if (reason === "") {
      alert("Escolha uma razão")
      return
    }
    try {
      const data = {
        memberReporting: {
          idGroupMember: localStorage.getItem("idMember"),
        },
        answerReported: {
          idAnswer: willReport,
        },
        report: reason,
        reportComment: extra,
      }
      await api.post("newReportAnswer", data)
      alert("Denuncia registrada, obrigado.")
      this.setState({
        modalOpen: false,
        willReport: null,
        reason: "",
        extra: "",
      })
    } catch (error) {
      console.log(error)
    }
  }

  openReport = id => {
    this.setState({ willReport: id, modalOpen: true })
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true })
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false })
  }

  _fetchLikes = async () => {
    try {
      const { commentReply } = this.props
      const idProfile = localStorage.getItem('idProfile')
      const response = await api.get(`findLikesOfAnswer/${commentReply.idAnswer}/${idProfile}`)
      
      this.setState({ hasLike: response.liked, contadorLike: response.size })
    } catch (error) {
      console.log(error)
    }
  }

  _createLike = async () => {
    this.btnLike.setAttribute("disabled", true)
    try {
      const { commentReply } = this.props
      const { hasLike } = this.state
      const data = {
        answer: {
          idAnswer: commentReply.idAnswer,
        },
        memberLike: {
          idGroupMember: localStorage.getItem("idMember"),
        },
        likeAnswer: true,
      }
      await api.post("newLikeAnswer", data)
      this.setState({ hasLike: !hasLike })
      this._fetchLikes()
      this.btnLike.removeAttribute("disabled")
    } catch (error) {
      console.log(error)
    }
  }

  editComentario = () => {
    this.setState({ openEditarResposta: true })
  }

  cancelEdit = () => {
    this.setState({ openEditarResposta: false })
  }

  render() {
    const {
      openEditarResposta,
      hasLike,
      contadorLike,
      modalOpen,
      reason,
      extra,
      loading,
      alert,
    } = this.state

    const { commentReply, groupOwner, comment, findAnswer } = this.props

    const idProfile = parseInt(localStorage.getItem("idProfile"), 10)
    const idOwner = parseInt(localStorage.getItem("idOwner"), 10)
    let $attachment = null

    if (commentReply.linkMedia) {
      if (commentReply.linkMedia.endsWith("jpg")) {
        $attachment = <Attachment url={commentReply.linkMedia} ext="jpg" />
      } else if (commentReply.linkMedia.endsWith("mp4")) {
        $attachment = <Attachment url={commentReply.linkMedia} ext="mp4" />
      } else if (commentReply.linkMedia.endsWith("mp3")) {
        $attachment = <Attachment url={commentReply.linkMedia} ext="mp3" />
      } else if (commentReply.linkMedia.endsWith("pdf")) {
        $attachment = <Attachment url={commentReply.linkMedia} ext="pdf" />
      } else {
        $attachment = (
          <a href={commentReply.linkMedia} style={{ color: "#27aae1" }}>
            <i className="fa fa-download" /> Baixar anexo
          </a>
        )
      }
    } else {
      $attachment = <div />
    }

    return (
      <div className="row comentarios comentarios-reply">
        <div className="col-12">
          <div className="row avatar">
            <Link
              to={`/${
                commentReply.memberAnswer.profiles.login.role.idRole === 3
                  ? "perfilProfessor"
                  : "perfilPublico"
              }/${commentReply.memberAnswer.profiles.idProfile}`}
            >
              <Img
                className="rounded-circle"
                style={{ width: 70, height: 70 }}
                src={[
                  commentReply.memberAnswer.profiles.linkPhoto,
                  `${API_URL}downdImg/${commentReply.memberAnswer.profiles.idProfile}`,
                  placeholder,
                ]}
              />
            </Link>
            <div className="avatar-social avatar-body">
              <h6>
                {commentReply.memberAnswer.profiles.firstName}{" "}
                {commentReply.memberAnswer.profiles.lastName}
              </h6>
              <span>
                <em>{moment(new Date(commentReply.dateAnswer)).fromNow()}</em>
              </span>
            </div>
            <div className="dropdown ml-auto">
              <a
                className="ml-auto"
                role="button"
                id="dropdownOptions"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-caret-down" aria-hidden="true" />
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownOptions">
                <a
                  className="dropdown-item"
                  onClick={() => {
                    this.openReport(commentReply.idAnswer)
                  }}
                  role="button"
                >
                  {" "}
                  Denunciar
                </a>
                {commentReply.memberAnswer.profiles.idProfile === idProfile && (
                  <a
                    data-tip="Fez um comentário e escreveu algo errado? Pode editar clicando aqui"
                    className="dropdown-item"
                    onClick={this.editComentario}
                    role="button"
                  >
                    Editar
                  </a>
                )}
                {groupOwner === idOwner || commentReply.memberAnswer.profiles.idProfile === idProfile ? (
                  <a role="button" className="dropdown-item" onClick={this.deleteAnswer}>
                    Excluir
                  </a>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="quote">
                <b>Em resposta à: </b> <br />
                <b>
                  {`${comment.memberComment.profiles.firstName} ${
                    comment.memberComment.profiles.lastName
                  }`}
                </b>
                <p>{comment.comment}</p>
              </div>
            </div>
            <div className="col-11 answer">
              <div className="content-answer">
                {openEditarResposta ? (
                  <EditComment
                    from="resposta"
                    refreshPosts={findAnswer}
                    cancelEdit={this.cancelEdit}
                    post={commentReply}
                  />
                ) : (
                  <Linkify
                    properties={{
                      target: "_blank",
                      style: { color: "#27aae1", fontWeight: "bold" },
                    }}
                  >
                    <p
                      style={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {commentReply.answer.trim()}
                    </p>
                  </Linkify>
                )}
                {!openEditarResposta && (
                  <div className="row">
                    <div className="col-12 ml-2">{$attachment}</div>
                  </div>
                )}
                <button
                  className="btn btn-icon"
                  type="button"
                  onClick={() => (hasLike ? this._updateLike() : this._createLike())}
                >
                  {!hasLike ? (
                    <i className="fa fa-heart-o" style={{ color: "#27AAE1" }} />
                  ) : (
                    <i className="fa fa-heart" style={{ color: "#27AAE1" }} />
                  )}

                  {!hasLike ? " Curtir " : " Descurtir "}

                  <span className="badge badge-primary" style={{ backgroundColor: "#27AAE1" }}>
                    {contadorLike}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal open={modalOpen} onClose={this.onCloseModal} classNames={{ modal: "lit-modal-form" }}>
          <div className="modal-content" id="modalGroup">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Denunciar publicação
              </h5>
            </div>
            <div className="modalBody-container">
              <div className="modal-body">
                <div className="form-group">
                  <label>Motivo da denúncia</label>
                  <select
                    className="form-control"
                    value={reason}
                    onChange={evt => this.setState({ reason: evt.target.value })}
                  >
                    <option defaultValue>Selecione o motivo</option>
                    <option value="nudez">Conteúdo impróprio com apelo sexual</option>
                    <option value="ofensa">Conteúdo ofensivo</option>
                    <option value="violencia">Conteúdo proibido ou violento</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Outras informações</label>
                  <textarea
                    type="text"
                    className="form-control"
                    rows="5"
                    id="inputGroupName"
                    value={extra}
                    onChange={evt =>
                      this.setState({
                        extra: evt.target.value,
                      })
                    }
                    required
                    placeholder="Utilize este espaço caso deseje incrementar a denúncia"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-modal__cancel" onClick={this.onCloseModal}>
                Cancelar
              </button>
              <button
                type="submit"
                disabled={loading}
                onClick={this.reportComment}
                className={`btn btn-modal__approve ${loading && " btn-loading"}`}
              >
                Enviar
              </button>
            </div>
          </div>
        </Modal>
        <Simplert
          showSimplert={alert}
          type="error"
          title="Deseja excluir essa publicação?"
          message="Esta ação não pode ser revertida"
          useConfirmBtn
          onConfirm={() => this.confirmDelete(commentReply.idAnswer)}
          customConfirmBtnText="Confirmar"
          customCloseBtnText="Cancelar"
          customConfirmBtnClass="btn btn-blue"
          customCloseBtnClass="btn btn-danger"
        />
      </div>
    )
  }
}

export default Resposta
