import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { Creators as CourseActions } from "../../../store/ducks/course/actions";
import { gateway } from "../../../utils/api";

class Scorm extends Component {
  state = {
    html: "",
    modal: false
  };

  componentDidMount() {
    this.getScorm();
  }

  getScorm = async () => {
    try {
      this.finishAttachment();
    } catch (error) {
      console.log(error.response);
    }
  };

  finishAttachment = () => {
    const {
      selected,
      incrementCourseProgress,
      setDisciplineProgress
    } = this.props;

    const progress = {
      idProfile: parseInt(localStorage.getItem("idProfile"), 10),
      hierarchy: selected.hierarchy,
      contentCode: selected.code,
      courseCode: selected.courseCode,
      position: 0,
      status: "DONE"
    };

    const objectStatusProgress = _.get(
      selected,
      "contentProgress.ox_status.wk_status",
      "IN_PROGRESS"
    );

    gateway
      .post("/webbff/lit_app_web/progress/saveProgress", progress)
      .then(() => {
        document
          .getElementById(`course-object-${selected.code}`)
          .classList.add("active");
        if (objectStatusProgress !== "DONE") {
          incrementCourseProgress();
        }
        setDisciplineProgress(selected.idDiscipline);
      })
      .catch(err => console.log(err));
  };

  render() {
    const { selected } = this.props;
    return (
      <iframe
        style={{
          width: "100%",
          height: "auto",
          minHeight: 450,
          border: "none",
          background: "#fff"
        }}
        src={selected.url}
        title={selected.name}
      />
    );
  }
}

const mapStateToProps = state => ({
  course: state.newCourse.data,
  selected: state.newCourse.selected,
  idCourse: state.newCourse.data.idCourse
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Scorm);
