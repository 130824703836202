import React, { Component, Fragment } from "react"
import AliceCarousel from "react-alice-carousel"
import { Link } from "react-router-dom"
import "react-alice-carousel/lib/alice-carousel.css"
import styled from "styled-components"
import { API_URL_IMAGES } from "../../utils/constants"
import api from "../../utils/api"

const ButtonWrap = styled.button`
  cursor: pointer;
  position: absolute;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 1.3em;
  outline: transparent;

  &:focus {
    outline: transparent;
  }
`

const ButtonAfter = styled(ButtonWrap)`
  left: 0;
`
const ButtonNext = styled(ButtonWrap)`
  right: 0;
`
class CursosConcluidos extends Component {
  state = {
    courses: [],
    loadingCourses: false,
  }

  responsive = {
    0: { items: 1 },
    1024: { items: 3 },
    2048: { items: 4 },
  }

  componentDidMount() {
    this.getMediaByProfile()
  }

  getMediaByProfile = async () => {
    this.setState({ loadingCourses: true })
    const { idProfile } = this.props
    const url = `getMediasbyProfile/${idProfile}`
    try {
      const response = await api.get(url)
      this.setState({
        courses: response,
        loadingCourses: false,
      })
    } catch (error) {
      console.log(error.response)
      this.setState({ loadingCourses: false })
    }
  }

  renderItensCourses = () => {
    const { courses } = this.state
    return courses.map(course => {
      return (
        <Link key={`${course.totvsId}`} to={`/curso/${course.totvsId}`}>
          <div className="col-3 d-inline-block " style={{ padding: "0 5px" }}>
            <img
              alt="Capa curso"
              height={100}
              className="img-course img-fluid"
              src={`${API_URL_IMAGES}images/courses/${course.totvsId}.jpg`}
            />
          </div>
        </Link>
      )
    })
  }

  render() {
    const { loadingCourses, courses } = this.state

    return (
      <div className=" bg-white-perfil" id="cursos-concluidos">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <h5>Cursos e trilhas concluídos</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12" id="search-grupo">
              {loadingCourses && (
                <div>
                  <p>Carregando...</p>
                </div>
              )}
              {!loadingCourses && courses.length === 0 && (
                <div>
                  <p>Não há cursos ministrados</p>
                </div>
              )}
              {!loadingCourses && courses.length > 0 && (
                <div className="d-flex">
                  <AliceCarousel
                    items={this.renderItensCourses()}
                    responsive={this.responsive}
                    dotsDisabled
                    buttonsDisabled
                    mouseDragEnabled
                    ref={el => {
                      this.Carousel = el
                    }}
                  />
                  {courses.length > 3 ? (
                    <Fragment>
                      <ButtonAfter type="button" onClick={() => this.Carousel.slidePrev()}>
                        <i className="fa fa-angle-double-left" aria-hidden="true" />
                      </ButtonAfter>
                      <ButtonNext type="button" onClick={() => this.Carousel.slideNext()}>
                        <i className="fa fa-angle-double-right" aria-hidden="true" />
                      </ButtonNext>
                    </Fragment>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CursosConcluidos
