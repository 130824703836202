import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 70px 20px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  background-image: linear-gradient(45deg, rgba(2, 196, 255, 1) 25%, rgba(142, 230, 123, 1) 100%);
`

export const NavLit = styled.nav`
  background-image: transparent;
`

export const ContianerNavBar = styled.div`
   position: fixed;
   width: 100%;
   top: 0;
`

export const BoxInfo = styled.div`
  background: #fff;
  padding: 20px;
  width: 100%;
  max-width:  1000px;
  height: 100%;
  margin-top: 70px;

  -webkit-box-shadow: 0px 1px 5px 0px rgba(217,208,217,1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(217,208,217,1);
  box-shadow: 0px 1px 5px 0px rgba(217,208,217,1);
  border-radius: 12px;

  .card-body {
    /* padding-left: 0;
    padding-right: 0; */
  }

  .btn-link {
    color: #47b1de;
  }

  td {
    font-size: 12px;
    vertical-align: middle; 
  }
`
export const Text = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
`




