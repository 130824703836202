import React, { useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';

import { TextTitle, ButtonAfter, ButtonNext, TextP } from './styles';

const items = [
  {
    title: 'Análise do comportamento do consumidor'
  },
  {
    title: 'Construindo equipes de alta performance'
  },
  {
    title:
      'Business Intelligence - estratégias, ferramentas, big data e analytics'
  },
  {
    title: 'Branding - a nova gestão de marcas'
  }
];

const NewCourses = ({ title, courses }) => {
  const [titleState, setTitleState] = React.useState('Novos cursos LIT');
  const [coursesState, setCoursesState] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  let carouselRef;

  const onSlideChanged = e => {
    setCurrentIndex(e.item);
  };
  const slideTo = i => {
    setCurrentIndex(i);
  };
  const thumbItem = (item, i) => {
    return (
      <div
        style={{
          width: 50,
          height: 5,
          borderRadius: 40,
          // backgroundColor: "#FFFFFF",
          backgroundColor: currentIndex === i ? '#FFFFFF' : '#5FD9EE',
          cursor: 'pointer',
          marginRight: 10,
          display: 'inline-block'
        }}
        role="button"
        key={i}
        onClick={() => {
          slideTo(i);
        }}
      />
    );
  };

  const renderItems = () => {
    if (courses) {
      return courses.map(item => {
        return (
          <div
            key={item.title}
            style={{ width: 350, border: '1px solid #fff', marginLeft: '25%' }}
            className="card-body"
          >
            <TextP>{item.title}</TextP>
          </div>
        );
      });
    }

    return items.map(item => {
      return (
        <div
          key={item.title}
          style={{ width: 350, border: '1px solid #fff', marginLeft: '25%' }}
          className="card-body"
        >
          <TextP>{item.title}</TextP>
        </div>
      );
    });
  };

  useEffect(() => {
    setTitleState(title);
    setCoursesState(renderItems());
  }, [title, courses]);

  return (
    <div className="w-100 d-flex flex-wrap align-items-center justify-content-center ">
      <TextTitle>{titleState}</TextTitle>
      <AliceCarousel
        items={coursesState}
        mouseDragEnabled
        buttonsDisabled
        dotsDisabled
        startIndex={0}
        slideToIndex={currentIndex}
        onSlideChanged={onSlideChanged}
        ref={el => (carouselRef = el)}
      />
      <nav>{coursesState.map(thumbItem)}</nav>

      <ButtonAfter type="button" onClick={() => carouselRef.slidePrev()}>
        <i
          style={{ color: '#17c9ed' }}
          className="fa fa-arrow-left"
          aria-hidden="true"
        />
      </ButtonAfter>
      <ButtonNext type="button" onClick={() => carouselRef.slideNext()}>
        <i
          style={{ color: '#7ae190' }}
          className="fa fa-arrow-right"
          aria-hidden="true"
        />
      </ButtonNext>
    </div>
  );
};

export default NewCourses;
