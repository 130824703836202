import styled from "styled-components";
import { } from "../../../constants";

export const ModalBody = styled.div.attrs({
  className: "modal-body"
})`
  overflow: hidden;
  overflow-y: auto;
  padding: 2rem;
  margin: 1rem 0;
  text-align: left;
  position: relative;
  div {
    text-align: left;
    line-height: 1;
  }
  textarea {
    margin: 0;
    padding: 10px;
  }
  label {
    text-align: left;
    margin: 10px 0 5px;
    width: 100%;
    @media (max-width: 991px) {
      margin: 0 0 5px;;
    }
  }
`;

export const Block = styled.div`
  width: 100%;
  margin: 0;
  text-align: left;
  display: table;
  min-height: calc(100% - 90px);
`;

export const ButtonAtBottom = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  clear: both;
  margin: 0;
  position: relative;
  bottom: 0;
  div {
    padding: 2rem 0 0;
  }
`;
