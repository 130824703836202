import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

import Screen from "./screen";

import { MeetingContext } from "../../MeetingContext";

export default function MeetingMembers() {
  const [members, setMembers] = useState([]);
  const { meeting } = useContext(MeetingContext);

  function checkParticipants({ full }) {
    const _members = full;

    const membersArray = Object.keys(_members).map(key => _members[key]);
    const filteredMembers = membersArray.filter(member => {
      return member.status === "IN_MEETING";
    });
    const formattedMembers = filteredMembers.map(
      ({ participant, isUser, isHost, isSelf, isAudioMuted }) => {
        const name =
          participant.person.name || participant.person.primaryDisplayString;

        return {
          name: name.split("@")[0],
          isAudioMuted,
          isUser,
          isSelf,
          isHost
        };
      }
    );

    setMembers(
      _.orderBy(formattedMembers, ["isSelf", "isHost"], ["desc", "desc"])
    );
  }

  function bindMemberEvents() {
    checkParticipants({ full: meeting.members.membersCollection.members });

    meeting.members.on("members:update", object => {
      checkParticipants(object);
    });
  }

  useEffect(() => {
    if (meeting) {
      bindMemberEvents();
    }
  }, [meeting]);

  // const members = [
  //   "development",
  //   "luanmtrs",
  //   "isla",
  //   "luanmtrs",
  //   "isla",
  //   "luanmtrs",
  //   "isla",
  //   "luanmtrs",
  //   "isla",
  //   "luanmtrs",
  //   "isla",
  //   "luanmtrs",
  //   "isla",
  //   "luanmtrs",
  //   "isla",
  //   "luanmtrs",
  //   "isla"
  // ];

  return <Screen members={members} />;
}
