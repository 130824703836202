import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { bindActionCreators } from "redux"
import api from "../../../utils/api"
import { Creators as ProfileActions } from "../../../store/ducks/user/profile/actions"
import Tooltip from "../../Tooltip"
import ListaIndicacoes from "./ListaIndicacoes"
import { sendWhats } from "../../Recommendations/utilSendWhats"
import { toastDefaultOptions } from '../../../utils/functions'

class AntigasIndicacoes extends Component {
  state = {
    visibleBody: false,
    sendList: [],
    name: "",
    email: "",
    phone: "",
    renderComponent: true,
  }

  componentDidMount() {
    this.getListSends()
    const { perfil } = this.props;
    if (perfil && perfil.idProfile) {
      this.mountValuePlan(this.props)
    } else {
      const { getProfileRequest } = this.props
      const idProfile = localStorage.getItem('idProfile');
      getProfileRequest(Number(idProfile))
    }

    this.verifyRenderComponent()
  }

  componentWillReceiveProps(nextProps) {
    const { perfil: prevPerfil } = this.props;
    const { perfil } = nextProps;
    if (perfil.idProfile !== prevPerfil.idProfile) {
      this.mountValuePlan(nextProps);
    }
  }

  verifyRenderComponent = () => {
    const freeUser = JSON.parse(localStorage.getItem('freeUser'))
    if (freeUser) {
      // Não exibir painel de assinatura para Free Users (Bradesco)
      this.setState({
        renderComponent: !freeUser,
      })
    }
  }

  getListSends = async () => {
    const idProfile = localStorage.getItem("idProfile")
    const url = `mgmVoucherList/${idProfile}`
    try {
      const response = await api.get(url)
      console.log(response)
      this.setState({ sendList: response })
    } catch (error) {
      console.log(error.response)
    }
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  shareVoucher = async () => {
    const { email, name, phone } = this.state
    if (!name) {
      this.notify("Nome obrigatório")
      return
    }
    this.setState({ loading: true })
    const phoneNumber = phone ? phone.replace(/\D/g, "") : ""

    const {
      location: { host },
    } = window
    const idProfile = localStorage.getItem("idProfile")
    const url = `generateMGMVoucher`
    const data = {
      email,
      host,
      idProfile,
      name,
      phone: phoneNumber,
    }
    try {
      const response = await api.post(url, data)
      const { emailSend } = response
      if (phone && emailSend) {
        sendWhats(response.mgmVoucher.voucherNumber, name, phone)
        this.notify("Compartilhado com Email e WhatsApp")
      }
      if (phone && !emailSend) {
        sendWhats(response.mgmVoucher.voucherNumber, name, phone)
        this.notify("Compartilhado com WhatsApp")
      }
      if (emailSend && !phone) {
        this.notify("Email enviado com sucesso")
      }
      this.setState({ name: "", email: "", phone: "", loading: false })
      this.getListSends()
    } catch (error) {
      this.setState({ loading: false })
      console.log(error.response)
    }
  }

  notify = (text, duration = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: duration,
    })
  }

  mountValuePlan = (props) => {
    const {
      perfil: { activeValue },
    } = props
    const value = `${activeValue.totalFinal}`.split(".")
    const valuePlan = value[1] ? `${value[0]},${value[1]}` : `${value[0]},00`
    const valueDiscount = activeValue.totalFinal * 0.05
    const discount = `${valueDiscount}`.replace('.', ',')
    this.setState({ valuePlan, discount })
  }

  render() {
    const { visibleBody, sendList, renderComponent } = this.state

    if (!renderComponent) {
      return null;
    }

    return (
      <Fragment>
        <div className="cursos bg-white-perfil" id="indicacao-usuario">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="d-inline-block" style={{ fontSize: "1.5em" }}>
                  Amigos indicados anteriormente
                </h5>
                <button type="button" className="btn btn-icon ml-auto" onClick={this.changeVisible}>
                  {visibleBody ? (
                    <i className="fa fa-chevron-up" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-chevron-down" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>
            {visibleBody && (
              <div className="row">
                <Tooltip id="indicacao" place="bottom" />
                <div className="col">
                  <p style={{ lineHeight: "normal", fontSize: "1em", marginBottom: "2em" }}>
                    Lista de amigos indicados por você na antiga mecânica de indicações do LIT.
                  </p>


                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="badge badge-info badge-lit-info" style={{ width: "100px" }}>
                        Disponíveis:
                      </span>{" "}
                      <span className="align-text-top font-weight-bold">{sendList.countUnused}</span>
                    </div>
                    <div>
                      <span className="badge badge-success badge-lit-success" style={{ width: "100px" }}>
                        Usados:
                      </span>{" "}
                      <span className="align-text-top font-weight-bold">{sendList.countUsed}</span>
                    </div>
                    <div>
                      <span className="badge badge-warning badge-lit-warning" style={{ width: "100px" }}>
                        Em Trial:
                      </span>{" "}
                      <span className="align-text-top font-weight-bold">{sendList.countTrial}</span>
                    </div>
                    <div>
                      <span className="badge badge-danger" style={{ width: "100px" }}>
                        Cancelados:
                      </span>{" "}
                      <span className="align-text-top font-weight-bold">{sendList.countCancelled}</span>
                    </div>
                    <div>
                      <span className="font-weight-bold">
                        Total de desconto: <b>{sendList.totalDiscountPercent}%</b>
                      </span>
                    </div>
                  </div>
                  {sendList.mgmList.length ? (
                    <ListaIndicacoes
                      indicacoes={sendList.mgmList}
                      refreshList={this.getListSends}
                      notify={this.notify}
                    />
                  ) : (
                    <p>Sem envios para exibir</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    perfil: state.user.profile.data,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProfileActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AntigasIndicacoes)
