import styled from "styled-components";

export default styled.p`
  color: ${({ color }) => color || "#777777"};
  font-size: ${({ size }) => size || 17}px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  text-align: ${({ align }) => align || "left"};
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
`;
