import React, { Component } from "react"
import styled from "styled-components"
import { Link, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import ReactCodeInput from "react-code-input"
import LeftSidePartner from "../../PreCadastro/LeftSidePartner"
import { TextDescription } from "../../../utils/styles"
import logoNaReal from "../../../images/serieNaRealLogo.jpg"
import { changeFavIconNaReal } from "../../../utils/functions"

import api, { gateway } from "../../../utils/api"

const LoginBox = styled.div`
  padding: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`
const Wrapper = styled.div`
  max-width: 100%;
`
const LoginHeader = styled.h5`
  font-family: "Dosis", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  color: #000;
`

const TextHelper = styled.h5`
  font-weight: lighter;
  margin-bottom: 10px;
  font-stretch: condensed;
  color: #000;
`

const ButtonBradesco = styled.button`
  background: #fb154c;
  border: #fb154c;
  border-radius: 50px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  transition: all 0.15s ease-in-out;
`

const ButtonResend = styled(ButtonBradesco)`
  background: #27aae1;
  border-color: #27aae1;
`

class NaReal extends Component {
  state = {
    code: "",
    email: "",
    loading: false,
    resend: false,
    isRender: true,
  }

  componentDidMount() {
    changeFavIconNaReal()
    const { location } = this.props
    const path = location.pathname.split("/")
    const code = path[path.length - 1]
    if (code !== "confirmarEmail") {
      this.setState({ code }, this.sendConfirmation)
    }
    this.setState({ isRender: false })
  }

  notify = (text, type = "success") => {
    toast(text, {
      type,
      closeButton: false,
    })
  }

  sendConfirmation = async () => {
    const { code } = this.state
    const { history } = this.props
    this.setState({ loading: true })
    const url = `/webbff/lit_app_web/auth/receiveConfirmation/${code}`
    try {
      const response = await gateway.post(url);
      this.notify(response.message)
      if (response.status === 200) {
        history.push("/onboarding")
      } else {
        this.setState({ loading: false })
      }
    } catch (error) {
      this.setState({ loading: false })
      this.notify(error.response.data.message, "warning")
    }
  }

  resendEmail = async () => {
    const { email } = this.state
    const url = `resentemailvalidation/${email}/nareal`
    this.setState({ loading: true, code: "" })
    try {
      const response = await api.get(url)
      this.notify(response.message)
      this.setState({ resend: false, loading: false })
    } catch (error) {
      this.notify(error.response.data.message)
      this.setState({ resend: false, loading: false })
    }
  }

  handleResend = () => {
    const { resend } = this.state
    this.setState({ resend: !resend })
  }

  render() {
    const { code, email, resend, loading, isRender } = this.state
    const idProfile = localStorage.getItem("idProfile")
    return (
      <div className='row' style={{ height: "100vh" }}>
        <LeftSidePartner />
        <div
          className=' col-12 col-md-5 order-1 order-md-2 order-ipad-1'
          style={{
            background: "#fff",
          }}
        >
          <LoginBox>
            <div className='d-flex flex-column justify-content-center align-items-center mb-5'>
              <LoginHeader>BEM-VINDO AO</LoginHeader>
              <img src={logoNaReal} alt='Logo Na Real' width={200} />
            </div>
            <Wrapper>
              <TextHelper>
                <strong>Cadastro realizado com sucesso</strong> <br />
                Entre no seu email para confirmar seu cadastro (verifique na pasta de spam).
              </TextHelper>
              <div className='d-flex flex-column py-4'>
                {resend ? (
                  <div className='d-flex flex-column align-items-center'>
                    <div className='form-group w-100 '>
                      <input
                        type='EMAIL'
                        placeholder='E-mail'
                        className='form-control input-bradesco'
                        id='inputEmail'
                        name='email'
                        onChange={e => this.setState({ email: e.target.value })}
                        value={email}
                      />
                    </div>

                    <ButtonBradesco
                      onClick={this.resendEmail}
                      className={` my-3 ${loading && "btn-loading"}`}
                      disabled={loading}
                      type='button'
                    >
                      Confirmar
                    </ButtonBradesco>
                  </div>
                ) : (
                  <div className='d-flex flex-column align-items-center'>
                    {!isRender && (
                      <ReactCodeInput
                        forceUppercase
                        id='inputcodigoEmail'
                        onChange={evt => this.setState({ code: evt })}
                        type='text'
                        value={code}
                        fields={8}
                      />
                    )}
                    <ButtonBradesco
                      onClick={this.sendConfirmation}
                      className={` my-3 ${loading && "btn-loading"}`}
                      disabled={loading}
                      type='button'
                    >
                      Confirmar
                    </ButtonBradesco>
                  </div>
                )}
                <div className='row justify-content-center align-items-baseline'>
                  <div className='col-6'>
                    <ButtonResend onClick={this.handleResend} type='button'>
                      Reenviar email
                    </ButtonResend>
                  </div>
                  <div className='col-6'>
                    <TextDescription className='mt-3'>
                      <Link to={idProfile ? "/onboarding" : "/login"}>PULAR CONFIRMAÇÃO</Link>
                    </TextDescription>
                  </div>
                </div>
              </div>
            </Wrapper>
          </LoginBox>
        </div>
      </div>
    )
  }
}

export default withRouter(NaReal)
