import React, { Component } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import FormRegister from "./FormRegister";
import { gateway } from "../../utils/api";
import { TextDescription, BradescoStyle } from "../../utils/styles";
import {
  setItensLocalStorage,
  identifyLogin,
  changeFavIconNaReal,
  setSEOIndexOff,
  generateSecurePassword,
  logCreateUser
} from "../../utils/functions";
import LeftSidePartner from "./LeftSidePartner";
import logoNaReal from "../../images/serieNaRealLogo.jpg";

const LoginBox = styled.div`
  padding: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const LoginHeader = styled.h5`
  font-family: "Dosis", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  color: #000;
`;

const Wrapper = styled.div`
  max-width: 100%;
`;

const TextHelper = styled.h5`
  font-weight: lighter;
  margin-bottom: 10px;
  font-stretch: condensed;
  color: #000;
`;

class PreCadastro extends Component {
  state = { 
    origin: null,
    email: "",
    firstName: "",
    lastName: "",
    cpfError: false
  };

  componentWillMount() {
    this.getUrlParams();
  }

  componentDidMount() {
    document.title = "Na Real - Criar Conta";
    setSEOIndexOff();
    changeFavIconNaReal();
  }

  getUrlParams = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    const plans = params.get("plans");
    if (plans) {
      localStorage.setItem("urlPlansParam", plans);
    }
    
    const origemParam = params.get("o");
    let origin = null;
    if (origemParam) {
      origin = origemParam;
      localStorage.setItem("urlOriginParam", origemParam || "");
    }

    this.setState({ origin });

    const firstName = params.get("nome");
    const lastName = params.get("sobrenome");
    const email = params.get("email");

    this.setState({ firstName, lastName, email });
  };

  notify = (text, type = "success") => {
    toast(text, {
      type,
      closeButton: false
    });
  };

  registerUser = async (values, actions) => {
    let status;
    const data = {
      name: values.name,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      password: generateSecurePassword(values.password),
      origin: this.state.origin,
      companyName: "Bradesco"
    };
    try {
      const { history } = this.props;
      const response = await gateway.post(
        "/webbff/lit_app_web/login/signup/nareal",
        data
      );
      setItensLocalStorage(response);
      identifyLogin(response.idLogin);
      status = {
        message: "Criado com sucesso",
        status: 201,
        dateRequest: Date.now(),
        urlCalled: this.state.origin
      };
      this.notify("Cadastro realizado com sucesso", "success");
      actions.setSubmitting(false);
      history.push("/confirmarEmail?origem=nareal");
    } catch (error) {
      if (error.response) {
        const { email } = this.state;
        identifyLogin(null, email);
        status = {
          message: error.response.data.message,
          status: error.response.status,
          dateRequest: error.response.data.timestamp,
          urlCalled: this.state.origin
        };
        this.notify(error.response.data.message, "warning");
        actions.setSubmitting(false);
      }
    }
    logCreateUser(data, status);
    this.setState({ loading: false });
  };

  render() {
    const { email, firstName, lastName, cpfError, loading } = this.state;
    return (
      <div id="login" className="container-fluid">
        <div className="row" style={{ height: "100vh" }}>
          <LeftSidePartner />
          <div
            className=" col-12 col-md-5 order-1 order-md-2 order-ipad-1"
            style={{
              background: "#fff"
            }}
          >
            <LoginBox>
              <div className="d-flex flex-column justify-content-center align-items-center mb-5">
                <LoginHeader>BEM-VINDO AO</LoginHeader>
                <img src={logoNaReal} alt="Logo Na Real" width={200} />
              </div>
              <Wrapper>
                <TextHelper>
                  <BradescoStyle>
                    Cadastre-se. É gratuito e sempre será,{" "}
                  </BradescoStyle>
                  sem anúncios e você pode continuar de onde parou a qualquer
                  momento.
                </TextHelper>
                <FormRegister
                  user={{ email, firstName, lastName }}
                  cpfError={cpfError}
                  loading={loading}
                  registerForm={this.registerUser}
                />
                <div className="row justify-content-end">
                  <div className="col-12">
                    <TextDescription className="mt-3">
                      <Link to="/">JÁ SOU CADASTRADO</Link>
                    </TextDescription>
                  </div>
                </div>
              </Wrapper>
            </LoginBox>
          </div>
        </div>
      </div>
    );
  }
}

export default PreCadastro;
