import React from "react";
import Screen from "./screen";
import moment from "moment";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Number} evaluation //receives the value of the final average to be presented
 * @param {Date} finalDate //receives the final date of the content to be presented
**/

export default ({
  evaluation = DEFAULT_VALUES.NUMBER,
  finalDate = DEFAULT_VALUES.DATE
}) => {

  const timeLeft = moment(finalDate).fromNow().toString().replace("em", "");

  return (
    <>
      <Screen
        evaluation={evaluation}
        timeLeft={timeLeft}
      />
    </>
  );
};
