import styled from "styled-components";
import ItemsCarousel from "react-items-carousel";

export const Container = styled.div`
  background-color: #e8ebef;
  border-radius: 5px;
`;

export const HeaderCampaign = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
`;

export const ContentDiscount = styled.div`
  background-color: orange;
  padding: 15px;
  color: white;
  align-items: center;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const ContentDiscountText = styled.div`
  font-weight: bold;
  padding: 15px;
  color: #444
`;

export const ContentOffer = styled.div`
  padding: 15px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0,0,0,.1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
`;

export const CampaignButton = styled.button`
  
`;

export const ContainerSlider = styled.div`
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  @media (max-width: 768px) {
    overflow-x: hidden;
  }
`;

export const Cards = styled.div`
  width: 100%;
  height: auto;

  .customCarouselWrapper {
  }

  .customCarouselItemsWrapper {
  }

  .customCarouselItemsInnerWrapper {
    padding: 0 0 20px 0;
    justify-content: flex-start;
    @media (max-width: 768px) {
      background: yellow !important;
    }
  }

  .customCarouselItemWrapper {
    margin: 10px 15px 0 0;
    padding: 0;
    width: auto;
    display: flex;
    @media (max-width: 768px) {
      margin: 10px;
      background: green !important;
    }
  }

  .customCarouselRightChev {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .customCarouselLeftChev {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .tooltipClassPayroll {
    width: 270px;
    background: rgba(2, 196, 255, 1);
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  .tooltipClass {
    width: 170px;
    background: rgba(2, 196, 255, 1);
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`;

export const CustomItemsCarousel = styled(ItemsCarousel).attrs({
  classes: {
    wrapper: "customCarouselWrapper",
    itemsWrapper: "customCarouselItemsWrapper",
    itemsInnerWrapper: "customCarouselItemsInnerWrapper",
    itemWrapper: "customCarouselItemWrapper",
    rightChevronWrapper: "customCarouselRightChev",
    leftChevronWrapper: "customCarouselLeftChev"
  }
})``;
