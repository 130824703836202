import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../../utils/constants";

export default ({ loadMore = DEFAULT_VALUES.FUNCTION, members = DEFAULT_VALUES.LIST, action = DEFAULT_VALUES.FUNCTION, actionSearch = DEFAULT_VALUES.FUNCTION }) => {
  return (
    <>
      <Screen
        members={members}
        action={action}
        actionSearch={actionSearch}
        loadMore={loadMore}
      />
    </>
  );
};
