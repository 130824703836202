import React from 'react';
import imagens from "../../utils/imagens";

function LinkToCertificate({ certificateUrl }) {
  return (
    <a href={certificateUrl} target="_blank">
      <img alt="Certificado" src={imagens.certificateIcon} height={35} />
    </a>
  )
}

export default LinkToCertificate;
