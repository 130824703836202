import styled from "styled-components";
import defaultModal from "react-modal";
import { } from "../../../constants";

defaultModal.defaultStyles.overlay.position = "fixed";
defaultModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.75)";
defaultModal.defaultStyles.overlay.zIndex = 9999;

export const Modal = styled(defaultModal)`
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 9999;
  padding: 0;
  @media (max-width: 767px) {
    width: 80%;
  }
  @media (max-width: 579px) {
    width: 96%;
  }
`;

export const Content = styled.div.attrs({
  className: "modal-content"
})`
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 0px;
  @media screen and (min-width: 767px) {
    overflow-y: hidden;
  }
`;
