import React, { Component, Fragment } from 'react';
import ModalViewTerm from '../../Modals/ModalViewTerm';

export default class ListaTermo extends Component {
  state = {
    modalOpen: false,
    modalData: null
  };

  closeModal = () => {
    this.setState({ modalOpen: false, modalData: null });
  };

  openTerm = term => {
    const { id, termType } = term;

    if (termType === 'PLAN') {
      this.setState({
        modalData: {
          termId: id,
          termType,
          ...term
        }
      });
    } else {
      this.setState({
        modalData: {
          termId: id
        }
      });
    }
    this.setState({ modalOpen: true });
  };

  render() {
    const { terms } = this.props;
    const { modalOpen, modalData } = this.state;
    return (
      <Fragment>
        <table className="table table-borderless table-sm">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Plano</th>
              <th scope="col">Termo</th>
              <th scope="col"> </th>
            </tr>
          </thead>
          <tbody>
            {terms.map(term => (
              <tr key={term.id}>
                <td>{term.signDate}</td>
                <td>{term.planDescription}</td>
                <td>{term.description}</td>
                <td>
                  <i
                    hidden={!term.id}
                    style={{ color: '#27aae1', cursor: 'pointer' }}
                    onClick={() => this.openTerm(term)}
                    className="fa fa-eye"
                    aria-hidden="true"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ModalViewTerm
          visible={modalOpen}
          {...modalData}
          onClose={this.closeModal}
        />
      </Fragment>
    );
  }
}
