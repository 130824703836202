import React from "react";
import { DropdownContainer } from "./styles";

export default ({ child }) => {
  return (
    <>
      <DropdownContainer>{child}</DropdownContainer>
    </>
  );
};
