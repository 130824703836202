import React, { Fragment, useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {
  InputLabel,
  ButtonRegister,
  InputWrap,
  InputText
} from '../Styles/FormStyle';
import { isDomainValid } from '../../utils/functions';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email obrigatório'),
  password: Yup.string().required('Senha obrigatório')
});

const ValidationSchemaDomain = Yup.object().shape({
  email: Yup.string().required('Email obrigatório')
});

const LoginForm = ({
  doLoginSuccess,
  routerParams,
  dataLogin,
  loginWithoutPassword
}) => {
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [erroLogin, setErroLogin] = useState(false);
  const [disablePassword, setDisablePassword] = useState(true);

  const handleChangeEmail = e => {
    // eslint-disable-next-line no-useless-escape
    const patternEmail = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;
    // eslint-disable-next-line no-useless-escape
    const patterDominio = /\w+\@\w+$/;

    setDisablePassword(isDomainValid(e.target.value));

    if (
      !patterDominio.exec(e.target.value) &&
      !patternEmail.exec(e.target.value)
    ) {
      setErroLogin(true);
    } else {
      setErroLogin(false);
    }
  };

  useEffect(() => {
    // getStatusServer()
    if (localStorage.getItem('g_email') && !dataLogin && !initialValues.email) {
      const emailFromLocalStorage = localStorage.getItem('g_email');
      setInitialValues({
        ...initialValues,
        email: emailFromLocalStorage
      });
      setDisablePassword(isDomainValid(emailFromLocalStorage));
    }

    localStorage.removeItem('g_email');
  }, [dataLogin, initialValues.email]);

  const login = async values => {
    // await getStatusServer();
    setLoading(true);
    const data = {
      username: values.email,
      password: values.password
    };

    setLoading(false);

    doLoginSuccess(data);
  };

  useEffect(() => {
    if (routerParams && routerParams.loginWithoutPassword) {
      if (JSON.parse(routerParams.loginWithoutPassword)) {
        const values = {
          email: routerParams.user,
          studyGroup: routerParams.studyGroup,
          tenant: routerParams.tenant
        };

        setInitialValues(values);
        loginWithoutPassword(values);
      } else {
        const values = {
          email: routerParams.user,
          password: routerParams.pass
        };

        setInitialValues(values);
        login(values);
      }
    }
  }, [routerParams]);

  return (
    <Fragment>
      {disablePassword ? (
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchemaDomain}
          enableReinitialize
          onSubmit={login}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group" style={{ marginBottom: '.9rem' }}>
                <InputWrap error={(errors.email && touched.email) || erroLogin}>
                  <InputText
                    type="email"
                    placeholder="E-mail"
                    className="form-control"
                    id="inputEmail"
                    name="email"
                    onChange={e => {
                      handleChange(e);
                      handleChangeEmail(e);
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    required
                  />
                  <InputLabel htmlFor="inputEmail">E-mail</InputLabel>
                </InputWrap>
              </div>

              <div className="form-group">
                <InputWrap error={errors.password && touched.password}>
                  <InputText
                    type="password"
                    placeholder="Senha"
                    className="form-control"
                    id="inputPassword"
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disablePassword}
                    autoComplete="off"
                    required
                  />
                  <InputLabel htmlFor="inputPassword">Senha</InputLabel>
                </InputWrap>
              </div>

              <div className="d-flex align-items-center mt-2">
                <ButtonRegister
                  className={`btn ${loading && 'btn-loading'}`}
                  type="submit"
                  id="btn-login"
                  disabled={loading}
                >
                  ENTRAR
                </ButtonRegister>
                <Link
                  style={{ textDecoration: 'underline', marginLeft: 10 }}
                  to="/esqueciSenha"
                >
                  Esqueceu sua senha?
                </Link>
              </div>
            </form>
          )}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          enableReinitialize
          onSubmit={login}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group" style={{ marginBottom: '.9rem' }}>
                <InputWrap error={(errors.email && touched.email) || erroLogin}>
                  <InputText
                    type="email"
                    placeholder="E-mail"
                    className="form-control"
                    id="inputEmail"
                    name="email"
                    onChange={e => {
                      handleChange(e);
                      handleChangeEmail(e);
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    required
                  />
                  <InputLabel htmlFor="inputEmail">E-mail</InputLabel>
                </InputWrap>
              </div>

              <div className="form-group">
                <InputWrap
                  error={errors.password && touched.password}
                  style={{ marginBottom: '.9rem' }}
                >
                  <InputText
                    type="password"
                    placeholder="Senha"
                    className="form-control"
                    id="inputPassword"
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disablePassword}
                    autoComplete="off"
                    required
                  />
                  <InputLabel htmlFor="inputPassword">Senha</InputLabel>
                </InputWrap>
              </div>

              <div className="d-flex align-items-center mt-2">
                <ButtonRegister
                  className={`btn ${loading && 'btn-loading'}`}
                  type="submit"
                  id="btn-login"
                  disabled={loading}
                >
                  ENTRAR
                </ButtonRegister>
                <Link
                  style={{ textDecoration: 'underline', marginLeft: 10 }}
                  to="/esqueciSenha"
                >
                  Esqueceu sua senha?
                </Link>
              </div>
            </form>
          )}
        />
      )}
    </Fragment>
  );
};

export default LoginForm;
