import React from "react";
import { FONT_TYPE, SIZE } from "../../../constants";
import Text from "../Text";
import { Div } from "./styles";

export default ({ title, subtitle, description, start, end }) => {
  return (
    <>
      <Div>
        <Text fontType={FONT_TYPE.BOLD} text={title} />
        <Text size={SIZE.MEDIUM} text={subtitle} />
        {description && <Text size={SIZE.MEDIUM} text={subtitle} />}
        <Text
          size={SIZE.SMALL}
          fontType={FONT_TYPE.BOLD}
          text={`${start} - ${end}`}
        />
      </Div>
    </>
  );
};
