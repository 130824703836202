import React, { Component, Fragment } from "react"
import moment from "moment"
import "moment/locale/pt-br"
import { TinyPagination } from "tiny-custom-pagination"

import api from "../../utils/api"

const styles = {
  button: {
    active: {
      background: "#43dba2",
      color: "white",
      border: "1px solid #43dba2",
      borderRadius: "10px",
    },
    grey: {
      background: "rgb(217, 217, 217)",
      color: "#6d6e71",
      border: "1px solid rgb(217, 217, 217)",
      borderRadius: "10px",
    },
  },
}

class Historico extends Component {
  state = {
    history: [],
    selectedPageId: 1,
    called: false,
  }

  componentDidUpdate(prevProps) {
    const { conquista } = this.props
    const { called } = this.state
    if (prevProps.conquista !== conquista && !called) {
      this.fetchHistoryByUser()
    }
  }

  fetchHistoryByUser = async () => {
    const idMember = localStorage.getItem("idMember")
    try {
      const response = await api.get(`getParticipation/${idMember}`)
      const history = response.reverse()
      this.setState({ history, called: true })
    } catch (error) {
      console.log(error)
    }
  }

  changePage = id => {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedPageId: id,
      }
    })
  }

  buttonPageClick = id => {
    const { selectedPageId } = this.state
    switch (id) {
      case "PRE":
        this.changePage(selectedPageId - 1)
        break
      case "NEXT":
        this.changePage(selectedPageId + 1)
        break
      default:
        this.changePage(id)
        break
    }
  }

  renderBtnNumber = id => {
    const { selectedPageId } = this.state
    return (
      <button
        type="button"
        onClick={() => this.buttonPageClick(id)}
        key={id}
        style={selectedPageId === id ? styles.button.active : styles.button.grey}
        className="btn"
      >
        {id}
      </button>
    )
  }

  render() {
    const { history, selectedPageId } = this.state
    const itemPerPage = 12
    const maxBtnNumbers = 6
    let listShow = [...history]
    listShow = listShow.splice((selectedPageId - 1) * itemPerPage, itemPerPage)
    return (
      <main>
        <div className=" bg-white-perfil" id="historico">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12">
                <h5>Veja aqui as suas últimas ações</h5>
              </div>
            </div>
            <div className="row" id="historico">
              {listShow.length >= 1 ? (
                <Fragment>
                  <table className="table table-striped">
                    <tbody>
                      {listShow.map(historyItem => {
                        return (
                          <tr key={historyItem.idParticipationHistory}>
                            <td>1xp</td>
                            <td>{historyItem.participationMessage}</td>
                            <td> {moment(new Date(historyItem.participationDateTime)).fromNow()}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <TinyPagination
                    total={history.length}
                    selectedPageId={selectedPageId}
                    itemPerPage={itemPerPage}
                    renderBtnNumber={this.renderBtnNumber}
                    maxBtnNumbers={maxBtnNumbers}
                    preKey="<<"
                    nextKey=">>"
                    wrapStyle={{
                      backgroundColor: "transparent",
                      marginBottom: "2em",
                      marginTop: "1.5em",
                      width: "100%",
                    }}
                    wrapClass="page-container"
                    btnsClass="btns-container"
                    counterClass="counter-container"
                    counterStyle={{ color: "gray" }}
                    spreadClass="spread-container"
                    spreadStyle={{ padding: "0 5px" }}
                  />
                </Fragment>
              ) : (
                <div className="col-12 trilhas-perfil">
                  <p>Você não possui nada no histórico até o momento.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default Historico
