import React from "react"

import IniciativaNaReal from "../../images/serieNaRealIniciativa.jpg"
import CuradoriaNaReal from "../../images/serieNaRealCuradoria.jpg"

import VideoPlayer from "./VideoPlayer"

export default function LeftSidePartner() {
  return (
    <div className=" col-12 col-md-7 order-2 order-md-1 order-ipad-2  d-flex flex-column justify-content-around align-items-center bg-white py-3">
      <div
        style={{ flex: 1 }}
        className="col-9  d-flex flex-column justify-content-around align-items-center"
      >
        <p className="mb-0">
          Aprenda a manter uma <strong className="bold">relação saudável com seu dinheiro</strong> com os
          professores de uma das melhores escolas de finanças do mundo e o apoio de uma grande
          instituição financeira.
        </p>
        <VideoPlayer url="https://cdnapisec.kaltura.com/p/1772821/sp/177282100/playManifest/entryId/1_yn9ixdk0/format/url/protocol/https" />

        <p>
          Ao final de cada episódio, você recebe um <strong className="bold">certificado</strong>{" "}
          mostrando que está evoluindo com suas habilidades financeiras.
        </p>

        <picture className="d-flex w-100 flex-wrap justify-content-between">
          <div>
            <h6 className="font-weight-bold mt-3">INICIATIVA</h6>
            <img width={200} src={IniciativaNaReal} alt="iniciativa na real" />
          </div>
          <div>
            <h6 className="font-weight-bold mt-3">CURADORIA E TECNOLOGIA</h6>
            <img width={200} src={CuradoriaNaReal} alt="curadoria na real" />
          </div>
        </picture>
      </div>
    </div>
  )
}
