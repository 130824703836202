import React, { PureComponent } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import Img from "react-image";
import axios from "axios";
import PropTypes from "prop-types";

import _ from "lodash";

import * as Styles from './styles';

import { API_URL_IMAGES } from "../../../../../../utils/constants";

import CardImgDefault from "../../../../components/CardImgDefault";

const { CancelToken } = axios;
const ReqSource = CancelToken.source();

const Loader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={132}
    viewBox="0 0 400 132"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="26" rx="3" ry="3" width="200" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="104" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="120" rx="3" ry="3" width="100%" height="6" />
  </ContentLoader>
);

const ContainerLoader = styled.div`
  background: rgba(255, 255, 255, 0.3);
  padding: 10px;
`;

class CardCourse extends PureComponent {
  static contextTypes = {
    router: PropTypes.shape()
  };

  state = {
    countObjects: 0,
    countFinishedObjects: 0,
    description: ""
  };

  componentWillMount() {
    this.checkImgExists();
  }

  componentDidMount = () => {
    this.getDescriptionCourse();
  };

  componentWillUnmount() {
    ReqSource.cancel("requisição cancelada");
  }

  getDescriptionCourse = () => {
    const { course } = this.props;
    let description = _.get(course, 'longDescription', '')
    if (description.length === 0) {
      description = _.get(course, 'shortDescription', '')
    }
    if (description.length > 0) {
      this.setState({ description });
      return
    }
  };

  cuttingTextDescription = description => {
    const maxLength = 300;

    const hasSpace = description.slice(0, maxLength).lastIndexOf(" ") !== -1;

    if (description.length > maxLength) {
      if (hasSpace) {
        return `${description.slice(
          0,
          description.slice(0, maxLength).lastIndexOf(" ")
        )}`;
      }
      return `${description.slice(0, maxLength)}`;
    }
    return description;
  };

  redirect = e => {
    const {course, onClick} = this.props;
    onClick(course);
  };

  checkImage = (path, onerror) => {
    const img = new Image();
    img.src = path;
    img.onerror = onerror;
  };

  checkImgExists = () => {
    const { course } = this.props;

    const media = _.get(course, 'media');
    const imageType = _.get(media, 'type', "");
    const imagePath = _.get(media, 'path', "");

    const urlImg = imageType === 'IMAGE' ? imagePath : "";
    this.setState({ urlImg });

    this.checkImage(urlImg, this.errorImgExists);
  };

  errorImgExists = () => {
    this.setState({ urlImg: null });
  };

  render() {
    const { course, progresso, key, selected} = this.props;
    // course.locked = true
    const {
      description,
   
      urlImg
    } = this.state;
  
    return (
      <Styles.Container key={key} selected={selected}>
        
        <div className="slider_media">
          {urlImg ? (
            <Img
              src={urlImg}
              onClick={this.redirect}
              style={{ width: "100%" }}
              loader={
                <ContainerLoader>
                  <Loader />
                </ContainerLoader>
              }
            />
          ) : (
            <CardImgDefault redirect={this.redirect} course={course} />
          )}
        </div>

        <div
          role="presentation"
          className="slider_info"
          onClick={this.redirect}
        >
          <div
            className={`d-flex align-items-center justify-content-between`}
          >
            {progresso && (
              <span role="button" onClick={this.toogleLane}>
                
              </span>
            )}
          </div>
          <div
            role="presentation"
            className="slider_title"
            onClick={this.redirect}
          >
            <p className="description-course-card">
              {this.cuttingTextDescription(description)}
            </p>
            {progresso && (
              <div className="barra-progress">
                <Styles.Progress
                  min="0"
                  value={(course.countFinishedObjects * 100) / course.countObjects || 0}
                  max={100}
                />
                <span>
                  {course.countFinishedObjects}/{course.countObjects}
                </span>
                {course.courseGradeStatus !== "IN PROGRESS" && (
                  <Styles.Grade status={course.courseGradeStatus}>
                    {course.gradeAverage}
                  </Styles.Grade>
                )}
              </div>
            )}
          </div>
        </div>
      </Styles.Container>
    );
  }
}

export default CardCourse;
