import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Grupo from "./Grupo"

import Loading from "../../Loading/Loading"

import GroupForm from "./GroupForm"

const IMG_GROUP_OFF = require("../../../images/grupo_off.png")

const styles = {
  row: {
    marginRight: 0,
    marginLeft: 0,
  },
}

class MeusGrupos extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    modalStatus: false,
  }

  onOpenModal = () => {
    this.setState({ modalStatus: true })
  }

  onCloseModal = () => {
    this.setState({ modalStatus: false })
  }

  onSaveGroup = idGroup => {
    const { router } = this.context
    this.setState({ modalStatus: false })
    router.history.push(`/grupo/${idGroup}`)
  }

  render() {
    const { grupos } = this.props
    const { modalStatus } = this.state

    return (
      <div className="card mb-3">
        <div className="card-header with-flex">
          <span
            className="titulo"
            data-class="tooltip-inner"
            data-tip="Veja todos os grupos que está participando"
          >
            <img
              alt="meus grupos de pratica"
              src={IMG_GROUP_OFF}
              width={25}
              style={{ marginRight: 5 }}
            />
            Meus grupos de prática
          </span>
          <button
            type="button"
            className="btn btn-blue ml-3"
            id="btn-grupo-pratica"
            onClick={this.onOpenModal}
            data-tip="Quer conversar sobre um assunto mas não localizou um grupo? Crie um grupo e convide outros onlearners"
          >
            Criar grupo de prática
          </button>
        </div>
        {grupos.loading && (
          <div className="card-body">
            <Loading width={40} />
          </div>
        )}
        {grupos.data.length === 0 && (
          <div className="card-body">
            <p>Você não participa de nenhum grupo</p>
          </div>
        )}
        {grupos.data.length > 0 && (
          <div className="row" style={styles.row}>
            {grupos.data.map(grupo => (
              <Grupo grupo={grupo} key={grupo.idGroup} />
            ))}
          </div>
        )}
        <GroupForm
          modalOpen={modalStatus}
          closeModal={this.onCloseModal}
          onSaveGroup={this.onSaveGroup}
        />
      </div>
    )
  }
}

export default connect()(MeusGrupos)
