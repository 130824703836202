import React from "react";
import {
  ICON,
  PROGRESS_INFORMATION,
  SIZE,
  ACTION,
  ORIENTATION,
  APPEARANCE,
  PAGE_INFORMATION,
  TRANSFORM,
  FONT_TYPE,
  COLOR,
  POSITION
} from "../../../constants";

import NavTabsContent from "../NavTabContent";
import PageLeftInfo from "../../../../LitAtomicComponents/containers/PageLeftInfo";
import Container from "../../../../LitAtomicComponents/containers/PageContainer";

import Text from "../../../../LitAtomicComponents/basicComponents/Text";
import Divisor from "../../../../LitAtomicComponents/basicComponents/Divisor";
import Clickable from "../../../../LitAtomicComponents/basicComponents/Clickable";
import SocialNetwork from "../../../../LitAtomicComponents/basicComponents/SocialNetwork";
import Tag from "../../../../LitAtomicComponents/basicComponents/Tag";
import Experience from "../../../../LitAtomicComponents/basicComponents/TitleDescriptionComplement";
import SpacingBlock from "../../../../LitAtomicComponents/basicComponents/SpacingBlock";
import Table from "../../../../LitAtomicComponents/basicComponents/Table";
import MemberInfo from "../../../../LitAtomicComponents/Info/DataInfo";
import IconInfo from "../../../../LitAtomicComponents/Info/IconInfo";

import Row30x70 from "../../../../LitAtomicComponents/defaultAreas/Row_30-70";
import Column3x3 from "../../../../LitAtomicComponents/defaultAreas/Column_3x3";
import formatDate from "../../../../../utils/formatDate";

import { Description } from "./styles";

export default ({
  imageDefault,
  group,
  groups,
  member,
  solicitations,
  recommended,
  invitations,
  postItem,
  isInternal,
  action,
  saveGroup,
  visibilityOptions,
  changeImage,
  areasOfInterest,
  handleImageChange,
  createGroupModalIsOpen,
  createGroupModalHandleOpen,
  manageInvitesModalIsOpen,
  manageInvitesModalHandleOpen,
  inviteJoinSolicitation,
  cancelJoinSolicitation,
  declineInvitation,
  hiddenCreatePost,
  loadMoreGroups,
  loadMoreCommunications,
  loadMoreInvites,
  hiddenExternalComponents,
  hasMoreCommunications,
  hasMoreGroups,
  hasMoreInvites,
  loadMoreSolicitations,
  hasMoreSolicitations,
  manageSolicitationsModalIsOpen,
  manageSolicitationsModalHandleOpen
}) => {
  function showMemberExpertises() {
    if (member.expertise && member.expertise.length) {
      return (
        <>
          <Description>
            <Text
              text={PAGE_INFORMATION.EXPERTISE}
              size={SIZE.MEDIUM}
              transform={TRANSFORM.UPPERCASE}
              fontType={FONT_TYPE.BOLD}
              color={COLOR.DARK}
            />
            {member.expertise.map(expertise => (
              <Tag
                text={expertise}
                color={COLOR.LIGHT_GRAY}
                position={POSITION.LEFT}
              />
            ))}
          </Description>
        </>
      );
    }
  }

  function showMemberFormations() {
    if (member.formation && member.formation.length) {
      return (
        <>
          <Description>
            <Text
              text={PAGE_INFORMATION.SCHOOL_GRADE}
              size={SIZE.MEDIUM}
              transform={TRANSFORM.UPPERCASE}
              fontType={FONT_TYPE.BOLD}
              color={COLOR.DARK}
            />
            {member.formation.map(formation => (
              <Experience
                title={formation.title}
                subtitle={formation.institution}
                start={formatDate(formation.start)}
                end={formatDate(formation.end)}
              />
            ))}
          </Description>
        </>
      );
    }
  }

  function showMemberExperiences() {
    if (member.experience && member.experience.length) {
      return (
        <>
          <Description>
            <Text
              text={PAGE_INFORMATION.PROFESSIONAL_EXPERIENCE}
              size={SIZE.MEDIUM}
              transform={TRANSFORM.UPPERCASE}
              fontType={FONT_TYPE.BOLD}
              color={COLOR.DARK}
            />
            {member.experience.map(experience => (
              <Experience
                title={experience.title}
                subtitle={experience.company}
                start={formatDate(experience.start)}
                end={formatDate(experience.end)}
              />
            ))}
          </Description>
        </>
      );
    }
  }

  function showMemberSocials() {
    if (member.socials && member.socials.length) {
      return (
        <>
          <Description>
            <SocialNetwork
              content={member.socials}
              orientation={ORIENTATION.HORIZONTAL}
              icon={ICON.SHARE}
              textAlign={POSITION.LEFT}
            />
          </Description>
        </>
      );
    }
  }

  function showMemberAwards() {
    if (member.awards && member.awards.length) {
      return (
        <>
          <Description>
            <Text
              text={PAGE_INFORMATION.AWARDS}
              size={SIZE.MEDIUM}
              transform={TRANSFORM.UPPERCASE}
              fontType={FONT_TYPE.BOLD}
              color={COLOR.DARK}
            />
            <Table appearance={APPEARANCE.STRIPED} content={member.awards} />
          </Description>
        </>
      );
    }
  }

  function checkProfileType() {
    if (isInternal) {
      return (
        <>
          <MemberInfo
            id={member.id}
            name={member.name}
            img={member.img}
            description={member.memberSince}
            role={member.role}
            size={SIZE.MEDIUM}
            orientation={ORIENTATION.VERTICAL}
          />

          <Column3x3
            left={
              <IconInfo
                icon={ICON.TROPHY}
                title={PROGRESS_INFORMATION.COURSES_COMPLETED}
                subtitle={member.coursesCompleted}
                color={COLOR.DARK}
              />
            }
            middle={
              <IconInfo
                icon={ICON.PLAY}
                title={PROGRESS_INFORMATION.COURSES_IN_PROGRESS}
                subtitle={member.coursesInProgress}
                color={COLOR.DARK}
              />
            }
            right={
              <IconInfo
                icon={ICON.USERS}
                title={PROGRESS_INFORMATION.GROUPS}
                subtitle={member.groups}
                color={COLOR.DARK}
              />
            }
          />

          <Clickable appearance={APPEARANCE.LINK}>
            <Text
              icon={ICON.SLIDERS}
              text={ACTION.CUSTOMIZE_STUDIES}
              size={SIZE.MEDIUM}
              transform={TRANSFORM.UPPERCASE}
              fontType={FONT_TYPE.BOLD}
              color={COLOR.DARK}
            />
          </Clickable>

          <Divisor color={COLOR.GRAY} />

          <Clickable appearance={APPEARANCE.LINK}>
            <Text
              icon={ICON.PENCIL}
              text={ACTION.EDIT_PROFILE}
              size={SIZE.MEDIUM}
              transform={TRANSFORM.UPPERCASE}
              fontType={FONT_TYPE.BOLD}
              color={COLOR.DARK}
            />
          </Clickable>
        </>
      );
    }
  }

  return (
    <>
      <Container>
        <Row30x70
          left={[
            <PageLeftInfo
              itemsToRender={[
                <SpacingBlock size={SIZE.MEDIUM}>
                  {checkProfileType()}

                  <Description>
                    <Text
                      text={PAGE_INFORMATION.ABOUT_ME}
                      size={SIZE.MEDIUM}
                      transform={TRANSFORM.UPPERCASE}
                      fontType={FONT_TYPE.BOLD}
                      color={COLOR.DARK}
                    />
                    <Text
                      text={member.about || ""}
                      size={SIZE.MEDIUM}
                      color={COLOR.TEXT_PRIMARY}
                    />
                  </Description>

                  {showMemberExpertises()}
                  {showMemberFormations()}
                  {showMemberExperiences()}
                  {showMemberSocials()}
                  {showMemberAwards()}
                </SpacingBlock>
              ]}
            />
          ]}
          right={
            <NavTabsContent
              isInternal={isInternal}
              imageDefault={imageDefault}
              group={group}
              groups={groups}
              solicitations={solicitations}
              recommended={recommended}
              invitations={invitations}
              member={member}
              postItem={postItem}
              action={action}
              saveGroup={saveGroup}
              visibilityOptions={visibilityOptions}
              changeImage={changeImage}
              areasOfInterest={areasOfInterest}
              handleImageChange={handleImageChange}
              createGroupModalIsOpen={createGroupModalIsOpen}
              createGroupModalHandleOpen={createGroupModalHandleOpen}
              manageInvitesModalIsOpen={manageInvitesModalIsOpen}
              manageInvitesModalHandleOpen={manageInvitesModalHandleOpen}
              inviteJoinSolicitation={inviteJoinSolicitation}
              cancelJoinSolicitation={cancelJoinSolicitation}
              declineInvitation={declineInvitation}
              hiddenCreatePost={hiddenCreatePost}
              hiddenExternalComponents={hiddenExternalComponents}
              loadMoreCommunications={loadMoreCommunications}
              loadMoreGroups={loadMoreGroups}
              loadMoreInvites={loadMoreInvites}
              hasMoreCommunications={hasMoreCommunications}
              hasMoreGroups={hasMoreGroups}
              hasMoreInvites={hasMoreInvites}
              loadMoreSolicitations={loadMoreSolicitations}
              hasMoreSolicitations={hasMoreSolicitations}
              manageSolicitationsModalIsOpen={manageSolicitationsModalIsOpen}
              manageSolicitationsModalHandleOpen={
                manageSolicitationsModalHandleOpen
              }
            />
          }
        />
      </Container>
    </>
  );
};
