import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Array} header //content to be displayed at the top of the navigation (tabs)
 * @param {Array} content //content to be displayed in the body of the navigation tabs
 * @param {String} id //identification that links the top to the content
 * @param {String} appearance //{FEED} appearance of the links displayed at the top of the navigation tabs
**/

export default ({
  header = DEFAULT_VALUES.LIST,
  content = DEFAULT_VALUES.LIST,
  id = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        header={header}
        content={content}
        id={id}
        appearance={appearance}
      />
    </>
  );
};
