import React from "react";
import Icon from "../Icon";
import { Link } from "./styles";

export default ({ color, size, icon, text, href, target }) => {
  return (
    <>
      <Link color={color} href={href} target={target} size={size}>
        {icon && <Icon icon={icon} color={color} size={size} />}
        {text}
      </Link>
    </>
  );
};
