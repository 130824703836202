import styled, { css } from "styled-components";
import { POSITION, ORIENTATION } from "../../../constants";

export const Checkbox = styled.div`
  
  ${props => (props.orientation === ORIENTATION.HORIZONTAL) && css`
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin: 10px 0;
    &:hover input ~ .check {
      background-color: var(--checkbox-primary);
    }
    input:checked ~ .check {
      background-color: var(--checkbox-primary);
      &:after {
        display: block;
      }
    }
    .check:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid var(--white);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    label {
      text-align: left;
      line-height: 1.2;
      padding-left: 30px;
      display: block;
      z-index: 1;
      top: 0;
      left: 0;
    }
  `}

  ${props => (props.orientation === ORIENTATION.VERTICAL) && css`
    position: relative;
    cursor: pointer;
    user-select: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:hover input ~ .check {
      background-color: var(--checkbox-primary);
    }
    input:checked ~ .check {
      background-color: var(--checkbox-primary);
      &:after {
        display: block;
      }
    }
    .check:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid var(--white);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    label {
      font-size: 13px;
      text-align: center;
      line-height: 1.2;
      padding-top: 30px;
      display: block;
      z-index: 1;
      top: 0;
    }
  `}

  ${props => (props.position === POSITION.LEFT) && css`
    float: left;
  `}
  ${props => (props.position === POSITION.RIGHT) && css`
    float: right;
  `}
  ${props => (props.position === POSITION.CENTER) && css`
    margin: 10px auto;
  `}

`;

export const Check = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Label = styled.label`
  cursor: pointer;
  color: var(--checkbox-primary-text);
`;

export const Mark = styled.span.attrs({
  className: 'check'
})`
  
  ${props => (props.orientation === ORIENTATION.HORIZONTAL) && css`
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: var(--checkbox-primary);
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  `}

  ${props => (props.orientation === ORIENTATION.VERTICAL) && css`
    position: absolute;
    top: 0;
    left: calc(50% - 11px);
    height: 22px!important;
    width: 22px!important;
    background-color: var(--checkbox-primary);
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  `}
  
`;
