import styled from "styled-components";

export const Container = styled.div`
  color: ${props => props.available ? '#27aae1 ' : ''} !important;
  cursor: ${props => props.available ? 'pointer' : 'not-allowed'} !important;
`
export const ContentItem = styled.a`
    font-size: 14;
    padding: 5px 15px;
`
export const ContentItemChild = styled.button`
  cursor: ${props => props.available ? 'pointer' : 'not-allowed'} !important;

  border: none !important;
  background: white !important;
  padding: 0px !important;

  .circle-with-grade{
    font-size: 20px;
    color: white;
    padding: 15px 17px !important;
    border-radius: 27% !important;
  }
  
  .bg-icon-green{
    background-color: #3cd99e !important;
    border-color: #3cd99e !important;
  }
`
export const ContainerIcon = styled.div`
    padding: 6px 13px;
    color: ${props => props.isApproved && props.status === "DONE" ? "#3cd99e" : "#27AAE1"};
    font-size: 18px;
`