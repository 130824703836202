import styled from 'styled-components';

import { LitButton } from '../shared/LitButton';

export const NavLit = styled.nav`
  background-image: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    /* padding: 0; */
    margin: 0;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Main = styled.div`
  width: 100%;
  padding: 20px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 0px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
`;


export const ContianerNavBar = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
`;

export const BoxInfo = styled.div`
  background: #fff;
  padding: 20px;
  width: 100%;
  max-width: 1240px;
  height: 100%;
  margin-top: 70px;
  margin-inline: auto;

  -webkit-box-shadow: 0px 1px 5px 0px rgba(217, 208, 217, 1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(217, 208, 217, 1);
  box-shadow: 0px 1px 5px 0px rgba(217, 208, 217, 1);
  border-radius: 12px;

  .card-body {
    /* padding-left: 0;
    padding-right: 0; */
  }

  .btn-link {
    color: #47b1de;
  }

  td {
    font-size: 12px;
    vertical-align: middle;
  }
`;

export const BoxActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1240px;
  height: 100%;
  margin-top: 70px;
  margin-inline: auto;

  .card-body {
    /* padding-left: 0;
    padding-right: 0; */
  }

  .btn-link {
    color: #47b1de;
  }

  td {
    font-size: 12px;
    vertical-align: middle;
  }

  @media(max-width: 710px) {
    flex-direction: column;
  }
`;

export const Text = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
`;

export const NextButton = styled(LitButton)`
  width: 100%;
  max-width: 300px;
  & + button {
    margin-left: 10px;
  }
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  @media(max-width: 710px) {
    & + button {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
`;
