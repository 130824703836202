import React, {useState} from "react"
import styled from "styled-components"
import IntlTelInput from 'react-intl-tel-input'
import { Formik, Form } from "formik"
import * as Yup from "yup"
import 'react-intl-tel-input/dist/main.css';

import { verifyObject, isDomainValid } from "../../utils/functions"

import ModalPolitica from "../Modals/ModalPolitica"
import ModalTermos from "../Modals/ModalTermos"

export const ErrorMessage = styled.span`
  color: #e50000;
`

export const ButtonLinks = styled.button`
  color: #27aae1;
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Campo muito curto!")
    .max(50, "Campo muito longo!")
    .required("Nome obrigatório"),
  lastName: Yup.string()
    .min(2, "Campo muito curto!")
    .max(50, "Campo muito longo!")
    .required("Sobrenome obrigatório"),
  phoneNumber: Yup.string()
    .required('Telefone obrigatório'), 
  email: Yup.string()
    .email("E-mail inválido")
    .required("E-mail obrigatório"),
  emailConfirm: Yup.string()
    .email("E-mail inválido")
    .oneOf([Yup.ref("email"), null], "Os emails não coincidem"),
  password: Yup.string()
    .required("Senha obrigatório")
    .min(6, "A senha deve conter 6 ou mais caracteres."),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas não coincidem")
    .required("Confirmação de senha obrigatório"),
  terms: Yup.boolean()
    .oneOf([true], "É necessário aceitar os termos de uso!")
    .required("É necessário aceitar os termos de uso!"),
})

const FormRegister = ({ user, registerForm }) => {

  const [disableButton, setDisableButton] = useState(false)
  const [phoneNumberError, setPhonenumberError] = useState(true)
  
  const handleChangeEmail = (e) => {
    setDisableButton(isDomainValid(e.target.value))
  }

  const formatPhoneNumberOutput = (
    isValid,
    newNumber,
    countryData,
    fullNumber
  ) => {
    
    if(!newNumber) {
      setDisableButton(true)
      setPhonenumberError(true)
    } else {
      setDisableButton(false)
      setPhonenumberError(false)
    }
    if (isValid && fullNumber) {
      return fullNumber.replace(/(\s|-)/g, '');
    }
    setDisableButton(true)
    return ''; // caught by validator
  }

  return (
    <Formik
      initialValues={{
        name: user.firstName ? user.firstName : "",
        lastName: user.lastName ? user.lastName : "",
        phoneNumber: "",
        email: user.email ? user.email : "",
        emailConfirm: "",
        password: "",
        passwordConfirm: "",
        terms: false,
        modalTerms: false,
        modalPolitics: false,
      }}
      // enableReinitialize
      validationSchema={ValidationSchema}
      onSubmit={registerForm}
      render={({ values, errors, touched, handleBlur, handleChange, isSubmitting, setFieldValue, setFieldTouched }) => (
        <Form className='mt-3 w-100'>
          <div className='row justify-content-center'>
            <div className='col-10 d-flex justify-content-between'>
              <div className='form-group w-100'>
                <input
                  placeholder='NOME'
                  className='form-control input-bradesco'
                  id='inputName'
                  value={values.name}
                  name='name'
                  type='text'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.name && touched.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              </div>
              <div className='form-group w-100 ml-3'>
                <input
                  placeholder='SOBRENOME'
                  className='form-control input-bradesco'
                  id='inputLastName'
                  value={values.lastName}
                  name='lastName'
                  type='text'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.lastName && touched.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-10'>
              <div className='form-group'>
              <IntlTelInput
                  defaultCountry="br"
                  disabled={isSubmitting}
                  preferredCountries={['br']}
                  style={{width: '100%'}}
                  customPlaceholder={(e) => e.replace(/[0-9]/g, 9)}
                  inputClassName="form-control input-bradesco"
                  fieldId="phoneNumber"
                  fieldName="phoneNumber"
                  onPhoneNumberBlur={() => {
                    setFieldTouched("phoneNumber", true);
                  }}
                  onPhoneNumberChange={(...args) => {
                    setFieldValue("phoneNumber", formatPhoneNumberOutput(...args));
                  }}
                  id="inputPhoneNumber"
                  name="phoneNumber"
                  format
                  required
                />
                {errors.phoneNumber && touched.phoneNumber && <ErrorMessage>{errors.phoneNumber}</ErrorMessage>}
              
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-10'>
              <div className='form-group '>
                <input
                  type='EMAIL'
                  placeholder='E-MAIL'
                  className='form-control input-bradesco'
                  id='inputEmail'
                  name='email'
                  onChange={e => {
                    handleChange(e)
                    handleChangeEmail(e)
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-10'>
              <div className='form-group '>
                <input
                  type='EMAIL'
                  placeholder='CONFIRMAR E-MAIL'
                  className='form-control input-bradesco'
                  id='inputEmailConfirm'
                  name='emailConfirm'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.emailConfirm}
                />
                {errors.emailConfirm && touched.emailConfirm && (
                  <ErrorMessage>{errors.emailConfirm}</ErrorMessage>
                )}
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-10 d-flex justify-content-between'>
              <div className='form-group w-100'>
                <input
                  placeholder='SENHA'
                  className='form-control input-bradesco'
                  id='inputPassword'
                  value={values.password}
                  name='password'
                  type='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.password && touched.password && <ErrorMessage>{errors.password}</ErrorMessage>}
              </div>
              <div className='form-group w-100 ml-3'>
                <input
                  placeholder='CONFIRMAR SENHA'
                  className='form-control input-bradesco'
                  id='inputPassworldConfirm'
                  value={values.passwordConfirm}
                  name='passwordConfirm'
                  type='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.passwordConfirm && touched.passwordConfirm && (
                  <ErrorMessage>{errors.passwordConfirm}</ErrorMessage>
                )}
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-10'>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div id='termos' style={{ marginRight: "10px" }}>
                  <div className='checkbox'>
                    <label>
                      <input
                        type='checkbox'
                        value={values.terms}
                        name='terms'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='cr'>
                        <i className='cr-icon fa fa-check' />
                      </span>
                      Aceito os{" "}
                      <ButtonLinks type='button' onClick={() => setFieldValue("modalTerms", true)}>
                        termos
                      </ButtonLinks>{" "}
                      e condições de uso e a{" "}
                      <ButtonLinks type='button' onClick={() => setFieldValue("modalPolitics", true)}>
                        política de privacidade
                      </ButtonLinks>
                    </label>
                  </div>
                  {errors.terms && touched.terms && <ErrorMessage>{errors.terms}</ErrorMessage>}
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-8'>
              <div className='d-flex justify-content-center align-items-center'>
                <button
                  className={`btn btn-bradesco ${isSubmitting && "btn-loading"}`}
                  type='submit'
                  disabled={(!verifyObject(errors) && !verifyObject(touched)) || isSubmitting || disableButton}
                >
                  Cadastrar
                </button>
              </div>
              <ModalTermos
                visible={values.modalTerms}
                onClose={() => setFieldValue("modalTerms", false)}
              />
              <ModalPolitica
                visible={values.modalPolitics}
                onClose={() => setFieldValue("modalPolitics", false)}
              />
            </div>
          </div>
        </Form>
      )}
    />
  )
}

export default FormRegister
