import styled from 'styled-components'

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  margin: 0;
`

export const Button = styled.button.attrs({
  type: "button"
})`
  width: 100%;
  margin-top: -20px;
`;
