import React, { memo } from "react"

const PagamentoAnual = ({ value, percentage }) => {
  const valueFormat = `${value.toFixed(2)}`.split(".")
  return (
    <div>
      <div className="valor-desc">
        <span className="unidade">R$ {valueFormat[0]}</span>
        <sup>
          ,{valueFormat[1]}
          {valueFormat[1] < 10 ? "0" : ""}
        </sup>
        <span className="ano">
          <span className="anual">Anual</span>
        </span>
      </div>
      <p className="economize" style={{ color: "#fff" }}>
        Economize {`${percentage}`.replace(".", ",")}%
      </p>
    </div>
  )
}

export default memo(PagamentoAnual)
