import React from "react";
import { ICON, COLOR, SIZE, ORIENTATION } from "../constants";

import Header from "../../LitAtomicComponents/Header";
import PageContent from "./components/PageContent";
import Actions from "../../LitAtomicComponents/Actions";
import Menu from "../../LitAtomicComponents/Menu";

import {} from "./styles";

export default ({
  logo,
  member,
  members,
  group,
  postItem,
  menuNavigation,

  savePost,
  updatePost,
  createComment,
  deletePost,
  interationsModalIsOpen,
  setInterationsModalIsOpen,
  openSearch,
  setOpenSearch
}) => {
  return (
    <>
      <Header
        logoPage={logo}
        size={SIZE.SMALL}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        topRight={[
          <Actions
            orientation={ORIENTATION.DEFAULT}
            itemsToRender={[
              {
                type: "icon",
                icon: ICON.HEART,
                action: null,
                size: SIZE.MEDIUM
              },
              {
                type: "icon",
                icon: ICON.BELL,
                action: null,
                size: SIZE.MEDIUM
              },
              {
                type: "icon",
                icon: ICON.SEARCH,
                action: () => {
                  setOpenSearch(true);
                },
                size: SIZE.MEDIUM
              }
            ]}
          />
        ]}
        topLeft={[
          <Actions
            orientation={ORIENTATION.DEFAULT}
            itemsToRender={[
              {
                type: "icon",
                icon: ICON.MENU_BARS,
                action: null,
                size: SIZE.MEDIUM
              }
            ]}
          />
        ]}
        topMenu={[
          <Menu
            orientation={ORIENTATION.HORIZONTAL}
            color={COLOR.LIGHT}
            options={menuNavigation}
            size={SIZE.SMALL}
          />
        ]}
      />
      <PageContent
        member={member}
        members={members}
        group={group}
        postItem={postItem}
        savePost={savePost}
        updatePost={updatePost}
        createComment={createComment}
        deletePost={deletePost}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
      />
    </>
  );
};
