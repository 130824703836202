import React, { PureComponent } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { Creators as CourseActions } from "../../../store/ducks/newCourse/actions";
import * as Styles from './styles'

class Conteudo extends PureComponent {
  state = {};

  openAttachment = () => {
    const { setCourseSelected, data, closeCourseProgress } = this.props;
    
    setCourseSelected(data);

    if (closeCourseProgress) {
      closeCourseProgress();
    }
  };

  generateIcon = () => {
    const { data } = this.props;

    const type = _.get(data, "media.type", "");
    switch (type) {
      case "VIDEO":
      case "VOD":
        return "play";
      case "PDF":
        return "align-center";
      case "VMP":
        return "book";
      case "HTML":
      case "SCORM":
      case "TEL":
        return "television";
      case "FOR":
        return "comments-o";
      case "BIB":
        return "book";
      case "QIZ":
        return "align-center";
      case "EXE":
        return "file-excel-o";
      case "DOC":
        return "file-word-o";
      case "PODCAST":
        return "podcast";
      default:
        return "align-center";
    }
  };

  renderCircle = () => {
    const { data } = this.props;

    const currentStatus = _.get(
      data,
      "contentProgress.ox_status.wk_status",
      ""
    );

    let classIcon = "circle";

    if (currentStatus === "DONE_SELF_ASSESSMENT") {
      classIcon = "circle assessment";
    }
    if (currentStatus === "DONE") {
      classIcon = "circle active";
    }

    return (
      <div id={`course-object-${data.code}`} className={classIcon}>
        <i className="fa fa-check" />
      </div>
    );
  };

  setPrevNextContent = () => {
    const { data, setNavigationContent, allObjects = [] } = this.props;
    const { listContent } = data;
    const index = allObjects.findIndex(obj => data.id === obj.id);
  
    const content = {
      prev: _.get(allObjects, `${index - 1}`, null),
      next: _.get(allObjects, `${index + 1}`, null)
    };
    setNavigationContent(content);
  };

  render() {
    const { data, selected, ...rest } = this.props;

    const isActive = selected.id === data.id;
    if (isActive) {
      this.setPrevNextContent();
    }

    const classLi = `list-group-item list-group-item_sub2 ${
      isActive ? "active" : ""
    }`;

    return (
      <Styles.Container isActive={isActive} {...rest}>
        <Styles.ContentItem
          {...rest}
          className={classLi}>
          <Styles.ContentItemChild
            className="list-child"
            role="button"
            onClick={this.openAttachment}
          >
            {this.renderCircle()}
            <Styles.ContainerIcon>
                <i className={`fa fa-${this.generateIcon()}`} />
            </Styles.ContainerIcon>
            <span className="info">{data.name}</span>
          </Styles.ContentItemChild>
        </Styles.ContentItem>
      </Styles.Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    selected: state.newCourse.selected
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conteudo);
