import styled from "styled-components";
import {} from "../../../constants";

export const Info = styled.span`
  width: 100%;
  display: inline-block;
  text-align: left;
  overflow-x: hidden;
  padding-top: 20px;
`;

export const Container = styled.div`
  background-color: transparent;
`;

export const TimelineContainer = styled.div`
  background-color: transparent;
`;

