import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} title //card group block title
 * @param {Array} arrayOfCards //list of items to be rendered
 * @param {Component} groupButtonAction //list of buttons that will be rendered next to the title
 * @param {Number} perLine //number of items that will be rendered per line
**/

export default ({
  title = DEFAULT_VALUES.STRING,
  arrayOfCards = DEFAULT_VALUES.LIST,
  groupButtonAction = DEFAULT_VALUES.COMPONENT,
  perLine = DEFAULT_VALUES.NUMBER
}) => {
  return (
    <>
      <Screen
        title={title}
        arrayOfCards={arrayOfCards}
        groupButtonAction={groupButtonAction}
        perLine={perLine}
      />
    </>
  );
};
