import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import Screen from './screen';
import {gateway} from '../../../../utils/api'

function Timeline({turma}) {
  // const [loadingCourses, setLoadingCourses] = useState(true);
  useEffect(() => {
    if(turma) {
      // loadCourses(turma.contentCode);
      // loadWarnings(turma.organizationCode);
    }
  },[turma]);

  // async function loadCourses(code) {
  //   try {
  //     console.log('GET CURSOS', code);
  //     const url = `webbff/lit_app_web/classes/${code}/courses`
  //     const courses = await gateway.get(url);
  //     console.log(courses);
  //     setLoadingCourses(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoadingCourses(false);
  //   }
  // }

  // async function loadWarnings(code) {
  //   console.log('GET AVISOS', code);
  // }

  return (
    // <>
    //   {loadingCourses && (
    //     <p>Carregando</p>
    //   )}
    //   {!loadingCourses && (
    //      <Screen />
    //   )}
    // </>
    <Screen />
  );
}

Timeline.propTypes = {
  turma: PropTypes.object.isRequired,
};


export default Timeline;
