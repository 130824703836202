import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 39px;
`

export const Summary = styled.p`
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`

export const BoxActions = styled.div`
  display: flex;
`

export const EditButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;
  color: #c1c1c1;
  display: flex;
  align-items: center;
  padding: 7px;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    color: #787777;
  }
`

export const RemoveButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;
  color: #c1c1c1;
  display: flex;
  align-items: center;
  padding: 7px;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    color: #cf6153;
  }
`

export const TextError = styled.p`
  margin: 0;
  padding: 0;
  color: red;
  font-size: 12px;
`