import React from "react";
import {
  ICON,
  COLOR,
  SIZE,
  APPEARANCE,
  ACTION,
  CONFIRMATION,
  PAGE_INFORMATION,
  ORIENTATION
} from "../constants";

import imageDefault from "../../../images/lit-default.jpg";

import Actions from "../../LitAtomicComponents/Actions";
import Header from "../../LitAtomicComponents/Header";
import PageContent from "./components/PageContent";
import Modal from "../../LitAtomicComponents/Modals/GenericModal";
import JoinGroupModal from "../../LitAtomicComponents/Modals/JoinGroup";
import ModalCall from "../../LitAtomicComponents/CallModal/GenericModalCall";
import InviteMemberModal from "../../LitAtomicComponents/Modals/InviteMembersModal";
import ManageMembersModal from "../../LitAtomicComponents/Modals/ManageJoinSolicitations";
import Loading from "../../LitAtomicComponents/basicComponents/LoadingContent";
import LoadingBar from "../../LitAtomicComponents/basicComponents/LoadingBar";
import EditGroupModal from "../../LitAtomicComponents/Modals/FormEditSocialGroup";
import Dropdown from "../../LitAtomicComponents/Inputs/Dropdown";

import {} from "./styles";

export default ({
  loadMoreCommunications,
  groupInformation,
  infoPage,
  group,
  communications,
  members,
  member,
  optionsPage,
  successImage,
  visibility,
  isAdministrator,
  leaveGroupAdministrator,
  isMember,
  solicitations,
  isSolicitationPending,
  visibilityOptions,
  openSearch,
  setOpenSearch,

  changeImage,
  areasOfInterest,

  leaveGroup,
  joinGroup,

  profiles,
  totalMembers,
  groupRoles,

  updatePost,
  createComment,
  createPost,
  toggleLike,
  deleteCommunication,

  inviteMember,
  searchMembers,
  removeMember,
  alterMemberRole,
  transferGroupPossession,
  handleImageChange,
  updateGroup,
  acceptJoinSolicitation,
  declineJoinSolicitation,

  editGroupModalIsOpen,
  editGroupModalHandleOpen,
  inviteMemberModalIsOpen,
  inviteMemberModalHandleOpen,
  manageMembersModalIsOpen,
  manageMembersModalHandleOpen,

  progress,
  closeJoinModal,
  modalJoinIsOpen,
  cancelSolicitation,
  interationsModalIsOpen,
  setInterationsModalIsOpen,
  loadCommunicationReactions,
  loadReactionPeople,
  loadMoreSolicitations,
  reactionsTypes,
  updateCommunicationReactions,
  hasMoreSolicitaions,
  hasMoreCommunications,
  searchMoreMembers
}) => {
  function showOptionsPage() {
    if (isMember) {
      return (
        <Dropdown
          icon={ICON.ELLIPSIS}
          options={optionsPage}
          color={COLOR.LIGHT}
        />
      );
    }
  }

  function navBack() {
    window.history.back();
  }

  function createEntryGroupButton() {
    const btn = {
      type: "button",
      appearance: APPEARANCE.OUTLINE,
      size: SIZE.MEDIUM,
      action: joinGroup,
      buttonText: ACTION.JOIN_GROUP
    };

    if (isAdministrator) {
      btn.action = leaveGroupAdministrator;
      btn.buttonText = ACTION.LEAVE_GROUP;
      return btn;
    }

    if (isSolicitationPending) {
      btn.action = cancelSolicitation;
      btn.buttonText = ACTION.CANCEL_SOLICITATION;
      return btn;
    }

    if (isMember) {
      btn.action = leaveGroup;
      btn.buttonText = ACTION.LEAVE_GROUP;
      return btn;
    }

    return btn;
  }

  return (
    <>
      <LoadingBar loadingProgress={progress} />
      <Header
        logoPage={infoPage.logo}
        imagePage={group.image || imageDefault}
        textHeader={group.name}
        size={SIZE.LARGE}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        bottomLeft={
          <Actions
            itemsToRender={[
              {
                type: "icon",
                icon: ICON.BACK_ARROW,
                action: navBack,
                size: SIZE.EXTRA_LARGE
              }
            ]}
          />
        }
        topRight={[
          <Actions
            itemsToRender={[
              {
                type: "icon",
                icon: ICON.HEART,
                action: null,
                size: SIZE.MEDIUM
              },
              {
                type: "icon",
                icon: ICON.BELL,
                action: null,
                size: SIZE.MEDIUM
              },
              {
                type: "icon",
                icon: ICON.SEARCH,
                action: () => {
                  setOpenSearch(true);
                },
                size: SIZE.MEDIUM
              }
            ]}
          />,
          showOptionsPage()
        ]}
        topLeft={
          <Actions
            itemsToRender={[
              {
                type: "icon",
                icon: ICON.MENU_BARS,
                action: null,
                size: SIZE.MEDIUM
              }
            ]}
          />
        }
        bottomRight={
          groupInformation ? (
            <Actions itemsToRender={[createEntryGroupButton()]} />
          ) : (
            <></>
          )
        }
      />
      <PageContent
        isMember={isMember}
        isAdministrator={isAdministrator}
        groupInformation={groupInformation}
        groupRoles={groupRoles}
        communications={communications}
        members={members}
        member={member}
        totalMembers={totalMembers}
        group={group}
        infoPage={infoPage}
        successImage={successImage}
        updatePost={updatePost}
        createComment={createComment}
        createPost={createPost}
        toggleLike={toggleLike}
        deleteCommunication={deleteCommunication}
        removeMember={removeMember}
        alterMemberRole={alterMemberRole}
        transferGroupPossession={transferGroupPossession}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
        visibility={visibility}
        loadCommunicationReactions={loadCommunicationReactions}
        loadReactionPeople={loadReactionPeople}
        loadMoreCommunications={loadMoreCommunications}
        reactionsTypes={reactionsTypes}
        updateCommunicationReactions={updateCommunicationReactions}
        hasMoreCommunications={hasMoreCommunications}
      />

      <Modal
        isOpen={modalJoinIsOpen}
        closeModal={closeJoinModal}
        pageImage={infoPage.img}
        textHeader={ACTION.JOIN_GROUP}
      >
        <JoinGroupModal
          message={CONFIRMATION.CONFIRM_JOIN_GROUP}
          group={group}
          action={joinGroup}
        />
      </Modal>

      <ModalCall
        modalHeaderText={ACTION.INVITE_MEMBER}
        pageImage={infoPage.img}
        appearance={APPEARANCE.MODAL}
        isOpen={inviteMemberModalIsOpen}
        handleIsOpen={inviteMemberModalHandleOpen}
      >
        <InviteMemberModal
         members={profiles}
         action={inviteMember}
         actionSearch={searchMembers}
         loadMore={searchMoreMembers}
        />
      </ModalCall>

      <ModalCall
        modalHeaderText={ACTION.EDIT_GROUP}
        pageImage={infoPage.img}
        appearance={APPEARANCE.MODAL}
        isOpen={editGroupModalIsOpen}
        handleIsOpen={editGroupModalHandleOpen}
      >
        <EditGroupModal
          group={group}
          handleIsOpen={editGroupModalHandleOpen}
          actionHandleImage={handleImageChange}
          action={updateGroup}
          visibilityOptions={visibilityOptions}
          changeImage={changeImage}
          areasOfInterest={areasOfInterest}
        />
      </ModalCall>

      <ModalCall
        modalHeaderText={PAGE_INFORMATION.PENDING_REQUESTS}
        pageImage={infoPage.img}
        appearance={APPEARANCE.MODAL}
        isOpen={manageMembersModalIsOpen}
        handleIsOpen={manageMembersModalHandleOpen}
      >
        <ManageMembersModal
          solicitations={solicitations}
          group={group}
          loadMoreSolicitations={loadMoreSolicitations}
          action={acceptJoinSolicitation}
          acceptJoinSolicitation={acceptJoinSolicitation}
          actionDecline={declineJoinSolicitation}
          hasMoreSolicitaions={hasMoreSolicitaions}
        />
      </ModalCall>
    </>
  );
};
