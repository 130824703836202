import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 12px;
  width: 300px;
  padding: 5px 10px;
`

export const Text = styled.span`
  margin: 0;
  padding: 0;
  font-size: 14px;
  display: block;
`