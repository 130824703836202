import React, { memo, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const ImgPaul = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 100px;
  background: white;
  display: block;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: 2px 5px 11px -5px rgba(26,25,26,1);
`
const Paul = styled.div`
  z-index: 99;
  margin: 0;
  position: fixed;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
`
const Bubble = styled.div`
  position: relative;
  max-width: 50%;
  bottom: 80px;
  padding: 0.5em;
  background: rgb(166,206,57);
  color: #FFF;
  border-radius: 0.4em;
  -webkit-box-shadow: -1px 2px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 2px 10px 0px rgba(0,0,0,0.75);
  box-shadow: -1px 2px 10px 0px rgba(0,0,0,0.75);
  display: inline-block;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 55%;
    width: 0;
    height: 0;
    border: 24px solid transparent;
    border-top-color: rgb(166,206,57);
    border-bottom: 0;
    border-right: 0;
    margin-left: -12px;
    margin-bottom: -15px;
  }
`
const CloseButton = styled.span`
  position: absolute;
  cursor: pointer;
  top: -5px;
  right: -5px;
  font-weight: bold;
  color: #FFF;
  font-size: .9em;
  border-radius: 50%;
  background:  rgb(166,206,57);
 
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const WrapImg = styled.div`
  position: absolute;
  bottom: 0;
  right: 30px;
`

const imgPaulReq = require("../../images/animacao_paul_standby-nova.gif")

function PaulBar() {
  const [showBubblePaul, setBubblePaul] = useState(true)

  function hideBubblePaul() {
    setBubblePaul(false)
  }

  if(showBubblePaul) {

    return <></>;

    // return (
    //   <Paul>
    //     <Bubble>
    //       <Link to="/chatPaul">Posso te ensinar sobre Análise Financeira?</Link>
    //       <CloseButton onClick={() => hideBubblePaul()}>X</CloseButton>
    //     </Bubble>
   
    //     <WrapImg
    //       data-tip="O Paul, inteligência artificial do LIT, pode te ensinar conteúdos de criatividade e empreendedorismo, contabilidade e análise de demonstrações, além de ajudar com as dúvidas sobre como usar o LIT."
    //       data-place="left"
    //       id="paul-info"
    //     >
    //       <Link to="/chatPaul">
    //         <ImgPaul src={imgPaulReq} alt="Logo LIT" />
    //       </Link>
    //     </WrapImg>
    //   </Paul>
    // )
  }

  return null;
}

export default memo(PaulBar)
