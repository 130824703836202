import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import history from '../../utils/history';
import { gateway } from '../../utils/api';
import { toastErrorAndWarningOptions } from '../../utils/functions';
import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';
import Loading from '../Loading/Loading';
import { Container, BoxInfo, ContianerNavBar, NavLit, Text, NextButton, BoxActions, Main } from './styles';

import OrderItem from './OrderItem';

const IMG_LIT = require('../../images/lit.png');

export default function PaymentHistory({ subscriptionsStatus = 'ACTIVE', handleContinue, buttonTitle, goToOnboarding }) {
  const {
    location: { state }
  } = history;

  const [invoicesPerSubscription, setInvoicesPerSubscription] = useState([]);
  const [activerOrder, setActiveOrder] = useState(0);
  const [fetchLoading, setFetchLoading] = useState(true);

  async function fetchInvoices() {
    try {
      const email = localStorage.getItem('ra');
      const response = await gateway.post(
        '/webbff/lit_app_web/payment/invoice/getUserInvoices',
        {
          ownerEmail: email,
        }
      );

      if (response && response.length > 0) {
        setInvoicesPerSubscription(response);
      }

      setFetchLoading(false);
    } catch (error) {
      setFetchLoading(false);
      toast.error(
        'Ops, Erro ao buscar os boletos!',
        toastErrorAndWarningOptions
      );
    }
  }

  useEffect(() => {
    fetchInvoices();
  }, []);

  function logout() {
    history.push('/logout');
  }

  return (
    <Container>
      <ContianerNavBar>
        {state &&
        !state.nextPageParams.editingPayment &&
        state.nextPageParams.fromPayment ? (
          <NavLit className="navbar navbar-lit navbar-expand-lg bg-gradient-lit">
            <a className="navbar-brand">
              <img src={IMG_LIT} alt="logo_lit" style={{ width: '100px' }} />
            </a>
            <button type="button" onClick={() => logout()}>
              SAIR
            </button>
          </NavLit>
        ) : (
          <NavBarSuperior />
        )}
      </ContianerNavBar>
      <Main>
        {fetchLoading && <Loading width={45} />}
        {!fetchLoading &&
          invoicesPerSubscription.length > 0 &&
          invoicesPerSubscription.map((subsciption, index) => {
            return (
              <BoxInfo key={index}>
                {!invoicesPerSubscription.length === 0 && !fetchLoading && (
                  <Text>Nehuma transação realizada</Text>
                )}
                <div>
                  <OrderItem
                    key={`order-${0}`}
                    order={subsciption}
                    index={index}
                    activerOrder={activerOrder}
                    setActiveOrder={i => setActiveOrder(i)}
                  />
                </div>
              </BoxInfo>
            );
          })}
          {subscriptionsStatus !== "ACTIVE" && 
          !fetchLoading && (
            <BoxActions style={{ marginTop: 30 }}>
              <NextButton type="button" onClick={() => {
                if(typeof handleContinue === 'function'){
                  handleContinue();
                }
              }}>
                {buttonTitle}
              </NextButton>
              {typeof goToOnboarding === 'function' && (
                <NextButton type="button" onClick={() => {
                  goToOnboarding();
                }}>
                  Preencher onboarding
                </NextButton>
              )}
          </BoxActions>
        )}
      </Main>
      
    </Container>
  );
}
