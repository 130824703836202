import React from "react";
import Screen from "./screen";

export default ({ group, acceptJoinSolicitation , solicitations, action, actionDecline, loadMoreSolicitations, hasMoreSolicitations }) => {
  return (
    <>
      <Screen
        group={group}
        action={action}
        loadMoreSolicitations={loadMoreSolicitations}
        solicitations={solicitations}
        acceptJoinSolicitation={acceptJoinSolicitation}
        declineJoinSolicitation={actionDecline}
        hasMoreSolicitations={hasMoreSolicitations}
      />
    </>
  );
};
