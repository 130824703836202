import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 0;
  max-width: 700px;
  margin: 0 auto;
`

export const Img = styled.img`
  width: 150px;
  margin-bottom: 10px;
`
export const Title = styled.h2`
  margin-bottom: 10px;
`

export const ContainerText = styled.div`
  text-align: justify;
  p {
    /* margin-bottom: 10px; */
  }

`
