import React from "react";
import { Container } from "./styles";

export default ({ child }) => {
  return (
    <>
      <Container>{child}</Container>
    </>
  );
};
