/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import React, { Component } from "react";
import { connect } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import Select from "react-select";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import moment from "moment";
import { bindActionCreators } from "redux";

import { isPhoneValid, validateZIPCode, validateNumber } from "../../../../utils/functions";
import { estados } from "../../../../utils/constants";
import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions";
import { saveOnboardingSteps } from "../../../../business/onboarding";
import { gateway } from "../../../../utils/api";

import {
  Container,
  Title,
  Description,
  ContainerForm,
  InputPhone,
  Label,
  InputCPF,
  InputCEP,
  TextError
} from "./styles";

class OnboardingPagePersonalData extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    const {
      data: { ox_personaldata: personalData, bf_skipaddress = false }
    } = props;
    const datePattern = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
    let formatedDate;

    if (personalData && personalData.dx_birthdate) {
      if (datePattern.test(personalData.dx_birthdate)) {
        formatedDate = moment(personalData.dx_birthdate, "DD/MM/YYYY");
      } else {
        const verifyDate = moment(personalData.dx_birthdate);
        formatedDate = verifyDate.isValid() ? verifyDate : null;
      }
    } else {
      formatedDate = null;
    }
    this.state = {
      wx_phonenumber:
        personalData && personalData.wx_phonenumber
          ? personalData.wx_phonenumber
          : "",
      wx_nationality:
        personalData && personalData.wx_nationality
          ? personalData.wx_nationality
          : "",
      wx_gender:
        personalData && personalData.wx_gender
          ? personalData.wx_gender.toLowerCase()
          : "",
      cf_cpf: personalData && personalData.cf_cpf ? personalData.cf_cpf : "",

      dx_birthdate:
        personalData && personalData.dx_birthdate ? formatedDate : null,
      wx_zipcode:
        personalData && personalData.wx_zipcode ? personalData.wx_zipcode : "",
      wx_address:
        personalData && personalData.wx_address ? personalData.wx_address : "",
      wx_addressnumber:
        personalData && personalData.wx_addressnumber
          ? personalData.wx_addressnumber
          : "",
      wx_addresscomplement:
        personalData && personalData.wx_addresscomplement
          ? personalData.wx_addresscomplement
          : "",
      wx_addressneighborhood:
        personalData && personalData.wx_addressneighborhood
          ? personalData.wx_addressneighborhood
          : "",
      wx_city: personalData && personalData.wx_city ? personalData.wx_city : "",
      wx_state:
        personalData && personalData.wx_state ? personalData.wx_state : "",
      wx_country:
        personalData && personalData.wx_country ? personalData.wx_country : "",
      wx_about:
        personalData && personalData.wx_about ? personalData.wx_about : "",
      isCpfFromPersonalData: (personalData && personalData.cf_cpf) || false,
      errors: {},
      loadingCEP: false,
      validCEP: personalData && personalData.wx_zipcode ? true : false,
      enableAddress: true,
      enableNeighborhood: true,
      enableCity: true,

      showAbout: false,
      showAddress: !bf_skipaddress
    };
  }

  saveForm = async () => {
    const {
      wx_phonenumber,
      wx_nationality,
      wx_gender,
      cf_cpf,
      dx_birthdate,
      wx_zipcode,
      wx_address,
      wx_addressnumber,
      wx_addresscomplement,
      wx_addressneighborhood,
      wx_city,
      wx_state,
      wx_country,
      wx_about,
      validCEP
    } = this.state;
    const validationResult = this.validateForm();
    if (!validationResult.success) {
      this.setState({
        errors: validationResult.errors
      });

      throw new Error("Erro de validação.");
    }

    const data = {
      wx_step: "PERSONAL_DATA",
      ox_formdata: {
        wx_phonenumber,
        wx_nationality,
        wx_gender,
        cf_cpf: cf_cpf.replace(/\.|-/g, ""),
        dx_birthdate: dx_birthdate.format("DD/MM/YYYY"),
        wx_zipcode: wx_zipcode.replace(/\.|-/g, ""),
        wx_address,
        wx_addressnumber,
        wx_addresscomplement,
        wx_addressneighborhood,
        wx_city,
        wx_state,
        wx_country,
        wx_about
      }
    };

    if (!validCEP) {
      const dataAddress = {
        wk_zipcode: wx_zipcode,
        ws_address: wx_address,
        ws_neighborhood: wx_addressneighborhood,
        ws_city: wx_city,
        ws_uf: wx_state
      };
      gateway.post(`location`, dataAddress);
    }

    this.saveDataInState(true);
    await saveOnboardingSteps(data);
  };

  saveDataInState = (concluded = false) => {
    const {
      wx_phonenumber,
      wx_nationality,
      wx_gender,
      cf_cpf,
      dx_birthdate,
      wx_zipcode,
      wx_address,
      wx_addressnumber,
      wx_addresscomplement,
      wx_addressneighborhood,
      wx_city,
      wx_state,
      wx_country,
      wx_about
    } = this.state;
    const { index, onboardingData, setOnbordingPageConcluded } = this.props;
    const dataActiveStep = Object.assign({}, onboardingData[index]);
    dataActiveStep.ox_personaldata = {
      wx_phonenumber,
      wx_nationality,
      wx_gender,
      cf_cpf,
      dx_birthdate: dx_birthdate ? dx_birthdate.format("DD/MM/YYYY") : null,
      wx_zipcode,
      wx_address,
      wx_addressnumber,
      wx_addresscomplement,
      wx_addressneighborhood,
      wx_city,
      wx_state,
      wx_country,
      wx_about
    };
    setOnbordingPageConcluded(
      index,
      dataActiveStep,
      onboardingData[index].bf_concluded || concluded
    );
  };

  validateForm = () => {
    const {
      wx_phonenumber,
      wx_nationality,
      wx_gender,
      cf_cpf,
      dx_birthdate,
      wx_zipcode,
      wx_address,
      wx_addressnumber,
      wx_addressneighborhood,
      wx_city,
      wx_state,
      wx_country,
      showAddress
    } = this.state;


    const fieldsAddess = [
      { value: wx_zipcode, name: "CEP", key: "wx_zipcode" },
      { value: wx_address, name: "endereço", key: "wx_address" },
      { value: wx_addressnumber, name: "número", key: "wx_addressnumber" },
      {
        value: wx_addressneighborhood,
        name: "rua",
        key: "wx_addressneighborhood"
      },
      { value: wx_city, name: "cidade", key: "wx_city" },
      { value: wx_state, name: "estado", key: "wx_state" },
      { value: wx_country, name: "país", key: "wx_country" }
    ]

    let fields = [
      { value: wx_phonenumber, name: "telefone", key: "wx_phonenumber" },
      { value: wx_nationality, name: "nacionalidade", key: "wx_nationality" },
      { value: wx_gender, name: "gênero", key: "wx_gender" },
      { value: cf_cpf, name: "CPF", key: "cf_cpf" },
      { value: dx_birthdate, name: "Data de nascimento", key: "dx_birthdate" },
    ];

    if (showAddress) {
      fields = [...fields, ...fieldsAddess]
    }

    const errors = {};
    // Go through each field validating it
    for (let i = 0; i < fields.length; i += 1) {
      const field = fields[i];
      // If the field is empty, remember the error message and stop
      if (!field.value || field.value === "") {
        errors[field.key] = `Campo obrigatório.`;
      }
    }
    // If there was an error message, return it. Otherwise, validation is ok.
    if (Object.keys(errors).length > 0) {
      return {
        success: false,
        errors
      };
    }

    if (wx_addressnumber) {
      const isValidNumber = validateNumber(wx_addressnumber);
      if (!isValidNumber) {
        errors.wx_addressnumber = "Somente números"
        return {
          success: false,
          errors
        };
      }
    }

    if (showAddress) {
      const isValidZipCode = validateZIPCode(wx_zipcode);
      if (!isValidZipCode) {
        errors.wx_zipcode_not_found = "CEP inválido: por favor, insira um cep válido"
        return {
          success: false,
          errors
        };
      }
    }

    return {
      success: true
    };
  };

  validatePhone = e => {
    const { errors } = this.state;
    const newErrors = {
      ...errors
    };

    if (!e.target.value) {
      newErrors.wx_phonenumber = "Campo telefone é obrigatório";
      this.setState({ errors: newErrors });
    } else {
      const isValid = isPhoneValid(e.target.value);
      if (isValid === false) {
        newErrors.wx_phonenumber = "Telefone inválido";
        this.setState({ errors: newErrors });
      } else {
        newErrors.wx_phonenumber = null;
        this.setState({ errors: newErrors });
      }
    }
  };

  showErrors = errors => {
    this.setState({ errors });
  };

  removeError = e => {
    const { errors } = this.state;
    if (e.target.value) {
      const activeErrors = { ...errors };
      activeErrors[e.target.name] = null;
      this.setState({ errors: activeErrors });
    }
  };

  changeGender = e => {
    if (!e.target.value) {
      const { errors } = this.state;
      const activeErrors = { ...errors };
      activeErrors[e.target.name] = `Campo gênero é obrigatório`;
      this.setState({ errors: activeErrors });
    } else {
      this.removeError(e);
    }
    this.setState({ wx_gender: e.target.value });
  };

  handleChangeCPF = e => {
    this.setState({ cf_cpf: e.target.value });
  };

  handleChangeState = value => {
    const { errors } = this.state;
    const activeErrors = { ...errors };
    activeErrors.wx_state = "";
    this.setState({ wx_state: value, errors: activeErrors });
  };

  changeBirthdate = date => {
    const { errors } = this.state;
    const activeErrors = { ...errors };
    activeErrors.dx_birthdate = null;
    this.setState({ dx_birthdate: date, errors: activeErrors });
  };

  searchCEP = async () => {
    const select = this.selectRef.current;
    try {
      const {
        wx_zipcode,
        wx_addressnumber,
        wx_addresscomplement,
        errors
      } = this.state;
      if (wx_zipcode) {
        const isValidZipCode = validateZIPCode(wx_zipcode);
        if (!isValidZipCode) {
          this.setZipCodeError("CEP inválido: por favor, insira um cep válido");
          return;
        }
        this.setState({ loadingCEP: true });
        const response = await gateway.get(`location/${wx_zipcode}`);
        const activeErrors = { ...errors };
        activeErrors.wx_zipcode = null;
        activeErrors.wx_address = null;
        activeErrors.wx_addressneighborhood = null;
        activeErrors.wx_state = null;
        activeErrors.wx_country = null;
        activeErrors.wx_city = null;
        activeErrors.wx_zipcode_not_found = null;
        let enableNeighborhood = true;
        let enableAddress = true;
        let enableCity = true;

        if (response.ws_address) {
          enableAddress = false;
        }

        if (response.ws_neighborhood) {
          enableNeighborhood = false;
        }

        if (response.ws_city) {
          enableCity = false;
        }
        this.setState({
          loadingCEP: false,
          errors: activeErrors,
          wx_city: `${response.ws_city}` || "",
          wx_address: `${response.ws_address}` || "",
          wx_addressneighborhood: `${response.ws_neighborhood}` || "",
          wx_addressnumber,
          wx_addresscomplement,
          wx_state: response.ws_uf,
          wx_country: "Brasil",
          validCEP: true,
          enableAddress,
          enableNeighborhood,
          enableCity
        });
        select.onMenuClose();
        this.inputNumero.focus();
        this.inputNumero.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      this.setZipCodeError("CEP não encontrado: por favor, insira o endereço manualmente.");
    }
  };

  setZipCodeError = (errorMessage) => {
    const {
      errors,
      wx_city,
      wx_address,
      wx_addressneighborhood,
      wx_state,
      wx_country
    } = this.state;
    const activeErrors = { ...errors };
    activeErrors.wx_zipcode = null;
    activeErrors.wx_zipcode_not_found = errorMessage
    this.setState({
      errors: activeErrors,
      loadingCEP: false,
      validCEP: false,
      wx_city: wx_city || "",
      wx_address: wx_address || "",
      wx_addressneighborhood: wx_addressneighborhood || "",
      wx_state: wx_state || "",
      wx_country: wx_country || "",
      enableNeighborhood: true,
      enableAddress: true,
      enableCity: true
    });
  }

  searchEnterCEP = e => {
    if (e.keyCode === 13 && e.target.value) {
      this.searchCEP();
    }
  };

  render() {
    const {
      wx_phonenumber,
      wx_nationality,
      wx_gender,
      cf_cpf,
      dx_birthdate,
      wx_zipcode,
      wx_address,
      wx_addressnumber,
      wx_addresscomplement,
      wx_addressneighborhood,
      wx_city,
      wx_state,
      wx_country,
      wx_about,
      errors,
      loadingCEP,
      validCEP,
      isCpfFromPersonalData,
      enableAddress,
      enableNeighborhood,
      enableCity,
      showAbout,
      showAddress
    } = this.state;
    const { index } = this.props;
    return (
      <Container className="container">
        <Title> {index + 1}. Seus dados pessoais</Title>
        <Description>
          Para ajudá-lo no uso da plataforma e para a emissão de certificados,
          precisamos de algumas informações de contato. Esses dados são privados
          para uso interno da plataforma!
        </Description>
        <ContainerForm autocomplete="off">
          <div className="row">
            <div className="col-md-12">
              <p className="text-center" style={{ fontWeight: "bold" }}>
                Informações pessoais
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Label>Telefone *</Label>
                <InputPhone
                  className="form-control"
                  mask="(99) 99999-9999"
                  placeholder="(99) 99999-9999"
                  maskChar={null}
                  onChange={e =>
                    this.setState({ wx_phonenumber: e.target.value })
                  }
                  onBlur={this.validatePhone}
                  value={wx_phonenumber.replace("+55", "")}
                  id="phone"
                />

                {errors.wx_phonenumber && (
                  <TextError>{errors.wx_phonenumber}</TextError>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <Label>Nacionalidade *</Label>
                <input
                  type="text"
                  placeholder="Ex: Brasileiro"
                  className="form-control"
                  id="nationality"
                  name="wx_nationality"
                  value={wx_nationality}
                  onChange={e =>
                    this.setState({ wx_nationality: e.target.value })
                  }
                  onBlur={this.removeError}
                />
                {errors.wx_nationality && (
                  <TextError>{errors.wx_nationality}</TextError>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <Label>Gênero *</Label>
                <select
                  className="form-control"
                  required
                  id="gender"
                  onChange={this.changeGender}
                  onBlur={this.removeError}
                  value={wx_gender}
                  name="wx_gender"
                >
                  <option value="" readOnly>
                    Selecionar
                  </option>
                  <option value="feminino">Feminino</option>
                  <option value="masculino">Masculino</option>
                  <option value="outro">Outro</option>
                </select>
                {errors.wx_gender && <TextError>{errors.wx_gender}</TextError>}
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <Label>Documento *</Label>
                <InputCPF
                  className="form-control"
                  // mask="999.999.999-99"
                  maskChar={null}
                  // placeholder="999.999.999-99"
                  name="cf_cpf"
                  type="text"
                  id="cpf"
                  disabled={isCpfFromPersonalData}
                  value={cf_cpf}
                  onChange={this.handleChangeCPF}
                // onBlur={this.validateCPF}
                />
                {errors.cf_cpf && <TextError>{errors.cf_cpf}</TextError>}
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <Label>Data do Nascimento *</Label>
                <DatePicker
                  autoComplete="off"
                  className="form-control"
                  placeholderText="DD/MM/YYYY"
                  customInput={<InputMask mask="99/99/9999" maskChar={null} />}
                  selected={dx_birthdate}
                  maxDate={moment()}
                  onChange={date => this.changeBirthdate(date)}
                  todayButton="Hoje"
                  id="birthday"
                />
                {errors.dx_birthdate && (
                  <TextError>{errors.dx_birthdate}</TextError>
                )}
              </div>
            </div>
          </div>

          {showAbout && (
            <div className="row">
              <div className="col-md-12">
                <Label>Sobre</Label>
                <textarea
                  id="wx_about"
                  name="wx_about"
                  className="form-control"
                  placeholder="Sobre você"
                  rows="5"
                  onChange={e => this.setState({ wx_about: e.target.value })}
                  value={wx_about}
                ></textarea>
              </div>
            </div>
          )}

          {showAddress && (
            <>
              <div className="row mt-3">
                <div className="col-md-12">
                  <p className="text-center" style={{ fontWeight: "bold" }}>
                    Endereço
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  {errors.wx_zipcode_not_found && (
                    <div
                      className="alert alert-warning alert-dismissible fade show"
                      role="alert"
                    >
                      {errors.wx_zipcode_not_found}.
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}
                </div>
                <div className="form-group col-sm-6">
                  <Label>CEP *</Label>
                  <InputCEP
                    className="form-control"
                    mask="99999-999"
                    maskChar={null}
                    placeholder="99999-999"
                    required
                    id="cep"
                    name="wx_zipcode"
                    onBlur={this.searchCEP}
                    onKeyUp={this.searchEnterCEP}
                    value={wx_zipcode}
                    onChange={evt =>
                      this.setState({
                        wx_zipcode: evt.target.value
                      })
                    }
                  />
                  {errors.wx_zipcode && <TextError>{errors.wx_zipcode}</TextError>}
                </div>
                <div className="form-group col-sm-2">
                  <Label>&nbsp;</Label>
                  <button
                    style={{ borderRadius: "30px" }}
                    type="button"
                    className="btn btn-block btn-blue"
                    id="btn-searchCEP"
                    disabled={!wx_zipcode}
                    onClick={this.searchCEP}
                  >
                    {loadingCEP ? (
                      <FaSpinner className="animated" />
                    ) : (
                      <i className="fa fa-search" />
                    )}
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-9">
                  <div className="form-group">
                    <Label>Endereço *</Label>
                    <input
                      className="form-control"
                      placeholder="Ex: Rua da Hora"
                      required
                      type="text"
                      id="address"
                      value={wx_address}
                      name="wx_address"
                      onBlur={this.removeError}
                      disabled={validCEP && !enableAddress}
                      onChange={evt =>
                        this.setState({
                          wx_address: evt.target.value
                        })
                      }
                    />
                    {errors.wx_address && (
                      <TextError>{errors.wx_address}</TextError>
                    )}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <Label>Número *</Label>
                    <input
                      className="form-control"
                      placeholder="Ex: 99"
                      required
                      id="addressnumber"
                      ref={input => {
                        this.inputNumero = input;
                      }}
                      name="wx_addressnumber"
                      type="text"
                      value={wx_addressnumber}
                      onBlur={this.removeError}
                      onChange={evt =>
                        this.setState({
                          wx_addressnumber: evt.target.value
                        })
                      }
                      maxLength="8"
                    />
                    {errors.wx_addressnumber && (
                      <TextError>{errors.wx_addressnumber}</TextError>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-6">
                  <Label>Complemento</Label>
                  <input
                    className="form-control"
                    placeholder="Ex: Ap 105"
                    required
                    id="input-complement"
                    type="text"
                    name="wx_addresscomplement"
                    value={wx_addresscomplement}
                    onBlur={this.removeError}
                    onChange={e =>
                      this.setState({
                        wx_addresscomplement: e.target.value
                      })
                    }
                  />
                  {errors.wx_addresscomplement && (
                    <TextError>{errors.wx_addresscomplement}</TextError>
                  )}
                </div>
                <div className="form-group col-sm-6">
                  <Label>Bairro *</Label>
                  <input
                    className="form-control"
                    placeholder="Ex: Jardim América"
                    required
                    type="text"
                    id="neighborhood"
                    name="wx_addressneighborhood"
                    onBlur={this.removeError}
                    value={wx_addressneighborhood}
                    disabled={validCEP && !enableNeighborhood}
                    onChange={e =>
                      this.setState({
                        wx_addressneighborhood: e.target.value
                      })
                    }
                  />
                  {errors.wx_addressneighborhood && (
                    <TextError>{errors.wx_addressneighborhood}</TextError>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6">
                  <Label>Cidade *</Label>
                  <input
                    className="form-control"
                    placeholder="Ex: São Paulo"
                    required
                    type="text"
                    id="city"
                    name="wx_city"
                    value={wx_city}
                    onBlur={this.removeError}
                    disabled={validCEP && !enableCity}
                    onChange={evt =>
                      this.setState({
                        wx_city: evt.target.value
                      })
                    }
                  />
                  {errors.wx_city && <TextError>{errors.wx_city}</TextError>}
                </div>
                <div className="form-group col-sm-3">
                  <Label>Estado *</Label>
                  <Select
                    onChange={({ value }) => {
                      this.handleChangeState(value);
                    }}
                    ref={this.selectRef}
                    isDisabled={validCEP && wx_state}
                    id="states"
                    name="wx_state"
                    value={estados.filter(({ value }) => value === wx_state)}
                    options={estados}
                    placeholder="Selecionar"
                  />
                  {errors.wx_state && <TextError>{errors.wx_state}</TextError>}
                </div>
                <div className="form-group col-sm-3">
                  <Label>País *</Label>
                  <input
                    className="form-control"
                    placeholder="Ex: Brasil"
                    required
                    id="country"
                    value={wx_country}
                    name="wx_country"
                    disabled={validCEP && wx_country}
                    onBlur={this.removeError}
                    onChange={evt =>
                      this.setState({
                        wx_country: evt.target.value
                      })
                    }
                  />
                  {errors.wx_country && <TextError>{errors.wx_country}</TextError>}
                </div>
              </div>
            </>
          )}


        </ContainerForm>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  onboardingData: state.onboarding.onboardingData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OnboardingActions
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(OnboardingPagePersonalData);
