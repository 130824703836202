import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #eeeeee;
`;

export const Container = styled.div`
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
`;
