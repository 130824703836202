import styled from "styled-components";
import defaultModal from "react-modal";
import { } from "../../../constants";

defaultModal.defaultStyles.overlay.position = "fixed";
defaultModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.45)";

export const ModalBody = styled.div.attrs({
  className: "modal-body"
})`
  padding: 2rem;
  margin: 1rem 0;
  position: relative;
`;
