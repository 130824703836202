import React from "react";
import { Spacing } from "./styles";

export default ({ size }) => {
  return (
    <>
      <Spacing size={size} />
    </>
  );
};
