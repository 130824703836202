import React, { Component } from "react"
import "url-search-params-polyfill"
import api from "../../utils/api"

class RedirectAds extends Component {
  componentDidMount() {
    this.getRedirectParams()
  }

  getRedirectParams = async () => {
    const {
      location: { search },
    } = this.props
    const params = new URLSearchParams(search)
    const redirectParams = params.get("redirect")
    console.log(redirectParams)
    localStorage.setItem("redirectParam", redirectParams)
    const url = `sendRedirectionParameters/${redirectParams}`
    try {
      const response = await api.get(url)
      console.log(response)
    } catch (error) {
      console.log(error.response)
    }
    document.location.href = "https://www.lit.com.br/"
  }

  render() {
    return <div />
  }
}

export default RedirectAds
