import styled, { css } from "styled-components";
import { Card } from "react-bootstrap";
import { APPEARANCE } from "../../../constants";

export const CardContent = styled(Card)`

  ${props => (props.appearance === APPEARANCE.POST) && css`
    
    border-radius: 7px;
    
    .collapse,
    .collapse.show {
      display: block!important;
    }

  `}

`;
