import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from 'lodash';
import ContentLoader from "react-content-loader";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import Slide from "./Slide";

const ButtonWrap = styled.button`
  cursor: pointer;
  position: absolute;
  height: 500px;
  border: none;
  padding: 0 15px;
  background: transparent;
  font-size: 1.3em;
  outline: transparent;
  transition: background 1s;

  &:focus {
    outline: transparent;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`

const ButtonAfter = styled(ButtonWrap)`
  left: 0;
  height: 100%
`
const ButtonNext = styled(ButtonWrap)`
  right: 0;
  height: 100%
`

const Loader = () => (

  <ContentLoader

  >
    {/* <rect x="0" y="0" rx="3" ry="3" width="100%" height="500" />  */}
  </ContentLoader>
)

const ContainerLoader = styled.div`
  background: rgba(255,255,255, .3);
  width: 100%;
  /* padding: 10px; */
`

class InfoHome extends Component {
  state = {
    banners: [],
    loading: true,
  }

  componentDidMount() {
    this.getBanners();
  }

  componentDidUpdate(prevProps) {
    const { showcaseBanners } = this.props;

    if (prevProps.showcaseBanners !== showcaseBanners) {
      this.mountBanner(showcaseBanners);
    }

  }

  getBanners = async () => {
    try {
      this.setState({ banners: this.props.showcaseBanners });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  mountBanner = (myClasses) => {
    this.setState({ loading: false });
    let banners = [];
    if (myClasses.length > 0) {
      banners = myClasses.map(item => ({
        id: _.get(item, 'idDisplay'),
        name: _.get(item, 'name', ''),
        description: _.get(item, 'longDescription', ''),
        fileName: _.get(item, 'displayImageUrl', ''),
        links: _.get(item, 'actions', [])
      }))
    } else {
      banners = [
        {
          id: 1,
          name: 'name',
          description: 'description',
          fileName: '',
          links: []
        }
      ]
    }
    this.setState({ banners, loading: false });
  }

  renderSlide = () => {
    const { banners } = this.state;
    return banners.map((banner, index) => <Slide fullImagePath key={banner.idCourse} banner={banner} active={index === 0} />)
  }

  render() {
    const { loading } = this.state
    if (loading) {
      return (
        <ContainerLoader>
          <Loader />
        </ContainerLoader>
      )
    }
    return (
      <section id="infos-home">
        <div className="d-flex flex-wrap">
          <AliceCarousel
            items={this.renderSlide()}
            // autoPlayInterval={6000}
            buttonsDisabled
            // autoPlay
            dotsDisabled
            ref={el => {
              this.Carousel = el
            }}
          />
          <ButtonAfter type="button" onClick={() => this.Carousel.slidePrev()}>
            <i className="fa fa-angle-double-left" aria-hidden="true" />
          </ButtonAfter>
          <ButtonNext type="button" onClick={() => this.Carousel.slideNext()}>
            <i className="fa fa-angle-double-right" aria-hidden="true" />
          </ButtonNext>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => (state.user.studyGroup && {
  myClasses: state.user.studyGroup.myClasses,
});

export default connect(mapStateToProps)(InfoHome)
