import React, { Component } from "react"
import Img from "react-image"
import Loading from "../../../Loading/Loading"
import { API_URL_IMAGES } from "../../../../utils/constants"
import Tooltip from "../../../Tooltip"
import placeholder from "../../../../images/placeholder-group.png"
import api from "../../../../utils/api"

class GroupCard extends Component {
  state = {
    detail: "",
    mouseHoverStatus: {
      maxLength: 50,
      descriptionShown: "",
      mouseHover: undefined,
    },
  }

  componentWillMount() {
    const {
      mouseHoverStatus: { maxLength },
    } = this.state
    const {
      grupo: { description },
    } = this.props
    const hasSpace = description.slice(0, maxLength).lastIndexOf(" ") !== -1

    if (description.length > maxLength) {
      if (hasSpace) {
        this.setState({
          mouseHoverStatus: {
            descriptionShown: `${description.slice(
              0,
              description.slice(0, maxLength).lastIndexOf(" ")
            )}...`,
            maxLength,
            mouseHover: false,
          },
        })
      } else {
        this.setState({
          mouseHoverStatus: {
            descriptionShown: `${description.slice(0, maxLength)}...`,
            maxLength,
            mouseHover: false,
          },
        })
      }
    } else {
      this.setState({
        mouseHoverStatus: {
          descriptionShown: description,
          maxLength,
        },
      })
    }
  }

  componentDidMount() {
    this.fetchDetailGroup()
  }

  fetchDetailGroup = async () => {
    const {
      grupo: { idGroup },
    } = this.props

    const idProfile = localStorage.getItem("idProfile")
    try {
      const response = await api.get(`getNumberMembersOfGroup/${idProfile}/${idGroup}`)
      this.setState({ detail: response })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const {
      grupo: { linkPhotoGroup, idGroup, groupName, privacity },
      enterGroup,
    } = this.props
    const {
      mouseHoverStatus: { descriptionShown },
    } = this.state
    const { detail } = this.state
    let qtdMembers
    if (detail.qtdMembers > 1) {
      qtdMembers = `${detail.qtdMembers} membros`
    } else if (detail.qtdMembers === 0) {
      qtdMembers = "Sem membros"
    } else {
      qtdMembers = "1 membro"
    }

    const urlImg = linkPhotoGroup ? `${API_URL_IMAGES}images/groups/${idGroup}.jpg` : placeholder

    return (
      <li className="list-group-item" style={{ border: "none", display: "flex", alignItems: "center" }}>
        <a role="button" onClick={() => enterGroup(idGroup)} data-for="sugestoes" data-tip={groupName}>
          <Img
            className="img-thumbnail img-fluid cover-fit mr-2"
            style={{ width: 90, height: 90 }}
            src={[urlImg]}
            loader={<Loading width={45} img />}
          />
          <Tooltip id="sugestoes" />
        </a>
        <div className="group-desc">
          <a
            role="button"
            style={{
              color: "#27aae1",
              fontWeight: 700,
              fontSize: "14px",
            }}
            onClick={() => enterGroup(idGroup)}
          >
            {groupName}{" "}
            {privacity ? (
              <i
                data-for="sugestoes"
                data-tip="Grupo privado"
                className="fa fa-lock"
                style={{ color: "red" }}
              />
            ) : null}
          </a>
          <div className="experiences networking">
            <span className="padding-0">{qtdMembers}</span>
            <span className="padding-0 mt-2">{descriptionShown}</span>
          </div>
        </div>
      </li>
    )
  }
}

export default GroupCard
