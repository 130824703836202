import React from "react";
import { DropdownBlock } from "./styles";

export default ({ color, child, appearance }) => {
  return (
    <>
      <DropdownBlock color={color} appearance={appearance}>
        {child}
      </DropdownBlock>
    </>
  );
};
