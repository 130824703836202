import styled from "styled-components";
import {shade} from 'polished'

export const Container = styled.div`
  button {
    border: 0;
    background: transparent;
    color: #00ace4;
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
      color: ${shade(0.1, '#00ace4')}
    }
  }
`;
