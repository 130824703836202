import React, { Component } from "react";
import { toast } from "react-toastify"
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, Text } from "./styles";
import { Creators as CourseActions } from "../../../store/ducks/newCourse/actions";
import { gateway } from "../../../utils/api";
import { toastErrorAndWarningOptions } from '../../../utils/functions'

class RedirectMessage extends Component {
  state = {
    idProfile: parseInt(localStorage.getItem("idProfile"), 10)
  };

  redirectPage = () => {
    const { selected } = this.props;
    const url = _.get(selected, "url", "")
    console.log('url -->', url)
    if (url) {
      this.saveProgress();
      window.open(url, "_blank");
    } else {
      toast("Erro ao redirecionar para o link externo", {
        ...toastErrorAndWarningOptions,
        type: "warning",
      })
    }
  };

  saveProgress = () => {
    const { idProfile } = this.state;
    const {
      selected,
      setDisciplineProgress,
      incrementCourseProgress
    } = this.props;

    if (selected.typeContent === "about") {
      return;
    }
    const progress = {
      idProfile,
      hierarchy: selected.hierarchy,
      courseCode: selected.courseCode,
      contentCode: selected.code,
      position: 1,
      status: "DONE"
    };

    const objectStatusProgress = _.get(selected, 'contentProgress.ox_status.wk_status', 'IN_PROGRESS');

    gateway
      .post("/webbff/lit_app_web/progress/saveProgress", progress)
      .then(res => {
        document
          .getElementById(`course-object-${selected.code}`)
          .classList.add("active");
        if (objectStatusProgress !== "DONE") {
          incrementCourseProgress();
        }
        setDisciplineProgress(selected.idDiscipline);
        console.log("RESPOSTA endOfFile", res.data);
      })
      .catch(err => console.log(err));
  };

  render() {
    const { selected } = this.props;

    if (!selected.url) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <Card className="card">
            <div className="card-body">
              <h5 className="card-title">Redirecionamento para link externo</h5>
              <Text>
                O item que você selecionou encontra-se em outro local,
                por isso iremos lhe encaminhar para outro site em uma nova aba.
                Clique no botão prosseguir para avançar.
              </Text>
            </div>
            <button
              type="button"
              style={{ margin: 15, alignSelf: "center" }}
              className="btn btn-blue btn-FontWeight6"
              onClick={() => this.redirectPage()}
            >
              Prosseguir
            </button>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selected: state.newCourse.selected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RedirectMessage);
