import React from "react";
import { Checkbox, Check, Mark, Label } from "./styles";

export default ({ text, position, orientation, checked }) => {
  return (
    <>
      <Checkbox position={position} orientation={orientation}>
        <Check type="checkbox" checked={checked} id={text} />
        <Label htmlFor={text}>{text}</Label>
        <Mark orientation={orientation} />
      </Checkbox>
    </>
  );
};
