import styled from 'styled-components'
import InputMask from 'react-input-mask';

export const Form = styled.form`
  padding-bottom: 20px;
  border-bottom : 1px solid #c1c1c1;
  position: relative;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const HeaderBoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  /* background-image: linear-gradient(0deg,rgba(2,196,255,1),rgba(142,230,123,1)); */
  background: #27aae1;
  border-radius: 50%;
  border: 0;
  color: #fff;
  margin-right: 10px;
`

export const HeaderText = styled.p`
  cursor: pointer;
  font-weight: bold;
  margin: 0;
  padding: 0;
`

export const Title = styled.p`
  font-weight: bold;
`

export const TextErro = styled.p`
  color: red;
  margin: 0;
  padding: 0;
`

export const InputMaskDate = styled(InputMask)``

const ButtonAction = styled.button`
  padding: 4px 10px;
  border-radius: 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid  transparent;
`

export const ButtonCancel = styled(ButtonAction)`
  margin-right: 5px;
`

export const Label = styled.label`
  font-weight: bold;
  color: #58595b;
`

export const ContainerDates = styled.div`
 
`

export const ButtonConfirm = styled(ButtonAction)`
  background: white;
  color: #27aae1;
  border: 2px solid #27aae1;
  &:hover {
    color: white;
    background: #27aae1;
  }
`
