import styled from 'styled-components';

const GeneralButton = styled.button`
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato;

  width: 10rem;
  font-size: 1rem;
  padding: 5px 20px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const ButtonDefault = styled(GeneralButton)`
  background: #27AAE1;
  border: 1px solid transparent;
  color: #fff;
  transition: all 0.5s;
  &:not([disabled]):hover {
    background: #4ab0e4;
    border: 1px solid #4ab0e4;
  }
`

export const ButtonOutline = styled(GeneralButton)`
  background: #fff;
  border: 1px solid #27AAE1;
  color: #27AAE1;
  transition: all 0.5s;
  &:not([disabled]):hover {
    background: #27AAE1;
    color: #fff;
  }
`
 