import React, { Component } from "react"
import ReactPlayer from "react-player"
import screenfull from "screenfull"

import Duration from "../CursoNovo/Media/Duration"

class VideoPlayer extends Component {
  state = {
    playing: false,
    volume: 1,
    muted: false,
    played: 0,
    duration: 0,
    seeking: false,
  }

  pad = string => {
    return `0 ${string}`.slice(-2)
  }

  onPlay = () => {
    this.setState({ playing: true })
  }

  onPause = () => {
    this.setState({ playing: false })
  }

  playPause = () => {
    const { playing } = this.state
    this.setState({ playing: !playing })
  }

  toggleMuted = () => {
    const { muted } = this.state
    this.setState({ muted: !muted })
  }

  onSeekMouseDown = () => {
    this.setState({ seeking: true })
  }

  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  onSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  onProgress = state => {
    const { seeking } = this.state
    if (!seeking) {
      this.setState(state)
    }
  }

  _handleContextMenu = e => {
    e.preventDefault()
  }

  onClickFullscreen = () => {
    screenfull.request(this.player)
  }

  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) })
  }

  ref = player => {
    this.player = player
  }

  _handleContextMenu = e => {
    e.preventDefault()
  }

  render() {
    const { playing, volume, muted, played, duration } = this.state
    const { url } = this.props
    return (
      <div
        className="d-flex justify-content-center align-items-center my-3 mx-5 my-md-5"
        onContextMenu={e => this._handleContextMenu(e)}
      >
        <div className="d-flex flex-column">
          <div className="player-wrapper" onContextMenu={e => this._handleContextMenu(e)}>
            <ReactPlayer
              url={url}
              ref={this.ref}
              onReady={() => console.log("onReady")}
              onStart={() => console.log("onStart")}
              onPlay={this.onPlay}
              onPause={this.onPause}
              onDuration={e => this.setState({ duration: e })}
              onBuffer={() => console.log("onBuffer")}
              onSeek={e => console.log("oi", e)}
              onEnded={this.onEnded}
              onError={e => console.log("onError", e)}
              onProgress={this.onProgress}
              muted={muted}
              playing={playing}
              volume={volume}
              width="100%"
              height="100%"
            />
          </div>
          <input
            type="range"
            min={0}
            max={1}
            step="any"
            value={played}
            onMouseDown={this.onSeekMouseDown}
            onChange={this.onSeekChange}
            onMouseUp={this.onSeekMouseUp}
          />
          <div className="video-control d-flex align-items-center" style={{ height: "35px" }}>
            <a role="button" className="btn" onClick={this.playPause}>
              {playing ? (
                <i className="fa fa-pause" aria-hidden="true" />
              ) : (
                <i className="fa fa-play" aria-hidden="true" />
              )}
            </a>
            <div className="volume d-flex align-items-center">
              <a role="button" className="btn" onClick={this.toggleMuted}>
                {muted ? (
                  <i className="fa fa-volume-off" aria-hidden="true" />
                ) : (
                  <i className="fa fa-volume-up" aria-hidden="true" />
                )}
              </a>
              <div className="volume-range">
                <input
                  id="volume-range"
                  type="range"
                  min={0}
                  max={1}
                  step="any"
                  value={volume}
                  onChange={this.setVolume}
                />
              </div>
            </div>
            <Duration seconds={duration * played} /> / <Duration seconds={duration} />
            <div className="d-flex align-items-center ml-auto p-2">
              <a role="button" className="btn" onClick={this.onClickFullscreen}>
                <i className="fa fa-expand" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VideoPlayer
