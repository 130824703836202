import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaSpinner, FaCheck, FaCamera } from "react-icons/fa";
import { AiOutlineUpload } from "react-icons/ai";
import _ from "lodash";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { gateway } from "../../../../../../utils/api";
import { toastDefaultOptions } from '../../../../../../utils/functions'
import DragAndDrop from "../../../../../../utils/DragAndDrop";
import {
  Container,
  DropZone,
  LoadingWrapper,
  SuccessWrapper,
  ContainerImage,
  MediaContainer,
  ThumbInnerDrop,
  ThumbDrop,
  CameraBtn,
  BoxCamera
} from "./styles";
import { getExtension, renderUploadThumb } from "../../../../../../utils/functions";
import WebcamCapture from "./subcomponents/WebcamCapture"

function Upload({
  setAnswerAtIndex,
  answers,
  fileConfig
}) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [extension, setExtension] = useState("");

  const onOpenModal = () => setShowModal(true);
  const onCloseModal = () => setShowModal(false);

  function setAnswer(newAnswer) {
    setAnswerAtIndex(newAnswer);
  }

  function downloadMedia(e, url) {
    Axios.get(url, {
      responseType: "blob"
    }).then(res => {
      fileDownload(res.data, `"file".${extension}`);
    });
  };

  function handleAttachmentOpen(e, url) {
    if (!url.includes("pdf")) {
      e.preventDefault();
      downloadMedia(e, url);
      return;
    }

    const mediaUrl = url;
    window.open(mediaUrl, "_blank");
  };

  function notify(text, duration = 4000) {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration,
      type: "warning"
    });
  };

  useEffect(() => {
    if (answers) {
      setExtension(getExtension(answers))
    }
  }, [answers]);

  const onDrop = useCallback(async acceptedFiles => {
    const [file] = acceptedFiles;
    if (file) {
      if (fileConfig) {
        const fileName = _.get(file, "name");
        const fileSize = _.get(file, "size");
        const extensionFile = getExtension(fileName);
        const admittedFiles = _.get(fileConfig, "admittedFiles", []);
        const maxFileSize = _.get(fileConfig, "maxFileSize");
        if (admittedFiles.length && !admittedFiles.includes(extensionFile.toUpperCase())) {
          notify("Tipo de arquivo inválido.");
          return;
        }
        if (fileSize > maxFileSize) {
          notify("Esse arquivo é muito grande.");
          return;
        }
      }

      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("mediaType", "FILE_ONBOARDING_RESPONSE");
        const response = await gateway.post("/media/media/upload", formData);

        const urlFile = _.get(response, "ox_specific.wu_url");
        setAnswer(
          urlFile
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: loading,
    multiple: false
  });

  return (
    <>
      <Container>
        <DragAndDrop handleDrop={onDrop}>
          <DropZone {...getRootProps()}>
            <input {...getInputProps()} />

            {loading && (
              <LoadingWrapper>
                <FaSpinner size={20} color="#1890ff" />
                <span>Realizando upload do seu arquivo ...</span>
              </LoadingWrapper>
            )}

            {!loading && !answers && (
              <p id="text-upload">
                Arraste e solte um arquivo aqui ou clique para selecionar.
                <AiOutlineUpload />
              </p>
            )}

            {!loading && answers && (
              <SuccessWrapper>
                <FaCheck size={20} color="#1890ff" />
                <p>Upload realizado com sucesso</p>
                <small>
                  Arraste um arquivo aqui ou clique para selecionar um arquivo.
                </small>
              </SuccessWrapper>
            )}
          </DropZone>

          <BoxCamera>
            <CameraBtn onClick={onOpenModal}>
              <FaCamera />
            </CameraBtn>
          </BoxCamera>
          <Modal open={showModal} onClose={onCloseModal} center>
            <div>
              <h2>Câmera</h2>
              <WebcamCapture setAnswer={setAnswer} onCloseModal={onCloseModal} />
            </div>
          </Modal>
        </DragAndDrop>

        {answers && extension !== 'pdf' && (
          <ContainerImage>
            <div className="img_wrap">
              <img className="img_size" src={answers} alt="documento" />
            </div>
          </ContainerImage>
        )}

        {answers && extension === 'pdf' && (
          <MediaContainer>
            <button type="button" onClick={(e) => handleAttachmentOpen(e, answers)}>
              <ThumbDrop key={answers}>
                <ThumbInnerDrop>
                  {renderUploadThumb(extension, "arquivo")}
                </ThumbInnerDrop>
              </ThumbDrop>
            </button>
          </MediaContainer>
        )}
      </Container>
    </>
  );
}

export default Upload;
