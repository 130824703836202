import styled from 'styled-components'
import { darken } from "polished"

export const Container = styled.div`
  padding: 10px 0;
  max-width: 700px;
  margin: 0 auto;

  .rc-slider-mark-text {
    min-width: 100px;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    .rc-slider-mark {
      display: none
    }
  }
`
export const Title = styled.h2`
  margin-bottom: 10px;
`

export const ContainerForm = styled.div`
  width: 100%;
  margin-top: 20px;
`
export const TextError = styled.p`
  margin: 0;
  color: red;
`

export const TextRanger = styled.span`
  text-align: center;
  display: none;
  @media (max-width: 768px) {
    display: inline
  }
`

export const BoxButtons = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
`
export const ContainerSlider = styled.div`
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 0px !important;
  }
`

export const Button = styled.button`
  background: ${props => props.active ? '#27aee1' : '#c1c1c1'};
  color: #fff;
  outline: 0;
  &:active, &:focus {
    box-shadow: none;
  }
  &:hover {
    background: #27aee1;
  }
`

