import React from "react";
import * as Styles from "./styles";

export default function MeetingExit() {
  return (
    <Styles.Wrapper>
      <h5
        style={{
          textAlign: "center",
          paddingTop: "20%",
          marginBottom: "-20%"
        }}
      >
        Você saiu da sala.{" "}
        <a
          style={{ color: "blue", textDecoration: "underline" }}
          role="link"
          onClick={() =>
            window.location.replace(`${window.location.origin}/home`)
          }
        >
          Clique aqui
        </a>{" "}
        para voltar ao início.
      </h5>
    </Styles.Wrapper>
  );
}
