import {gateway} from '../utils/api'


export const fetchOnboardingPages = async () => {
  let response;
  try {
    response = await gateway.get('/onboarding/steps')
  } catch (error) {
    throw error
  }

  return response
}

export const saveOnboardingSteps = async (data) => {
  let response;
  try {
    const url = "/onboarding/steps"
    response = await gateway.post(url, data)
  } catch (error) {
    throw error
  }

  return response
}

export const fetchSelectType = async (group, text = '') => {
  let response;
  try {
    response = await gateway.get(`onboarding/select-options?group=${group}&text=${text}`)
  } catch (error) {
    throw error
  }

  return response
}