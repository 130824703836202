import React from 'react';

function OfferDetails() {
  return (
    <>      
      <div className="row justify-content-md-center mt-4">
        <div className="col-12">
          <p>
            O pagamento por PIX é instantâneo e prático. <br />
          </p>
        </div>
      </div>
    </>
  );
}

export default OfferDetails;
