import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  label {
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 53px !important;
    border:none !important;
    
    

    select {
      height: 53px !important;
      border: 2px solid #27aae1;
      border-radius: 5px;
      background: white;
      padding: 15px;
      flex: 1;
    }
  }

  & > p {
    color: red;
  }
`;
