import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class TesteNivelamento extends Component {
  entendido() {
    window.location = "/home"; //Temporário, mudar para history depois. Obrigado!
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light justify-content-between">
            <p>
              {" "}
              <a className="navbar-brand" role="button">
                Logo
              </a>&nbsp;Teste de nivelamento
            </p>

            <div>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/Curso" className="nav-link">
                      Sair
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div className="container">
          <div className="row justify-content-between margin-top-bottom-40">
            <div className="">
              <h5>Contabilidade Fundamental</h5>
            </div>
            <div className="">
              <button className="btn btn-light">
                Salvar todas as respostas
              </button>
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Salvar e Enviar
              </button>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Parabéns
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h6 className="text-center">Você acabou de eliminar</h6>
                  <h5 className="text-center">30% do curso</h5>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "30%" }}
                      aria-valuenow="30"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <p className="text-center">
                    Sugerimos que você veja mais alguns conteúdos do curso antes
                    de iniciar as provas
                  </p>
                </div>
                <div className="modal-footer">
                  <div className="row justify-content-center">
                    <div className="col">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.entendido()}
                        data-dismiss="modal"
                      >
                        Entendido
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container margin-top-bottom-40">
            <div className="row justify-content-between divisoria">
              <div className="">
                <h5>Pergunta 1</h5>
              </div>
              <div className="">
                <p className="d-inline">0 pontos</p>
                <button className="btn btn-light">Salvar e Enviar</button>
              </div>
            </div>
            <div>
              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;a.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;b.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;c.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;d.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>
            </div>
          </div>

          <div className="container margin-top-bottom-40">
            <div className="row justify-content-between divisoria">
              <div className="">
                <h5>Pergunta 2</h5>
              </div>
              <div className="">
                <p className="d-inline">0 pontos</p>
                <button className="btn btn-light">Salvar e Enviar</button>
              </div>
            </div>
            <div>
              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;a.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;b.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;c.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;d.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>
            </div>
          </div>

          <div className="container margin-top-bottom-40">
            <div className="row justify-content-between divisoria">
              <div className="">
                <h5>Pergunta 3</h5>
              </div>
              <div className="">
                <p className="d-inline">0 pontos</p>
                <button className="btn btn-light">Salvar e Enviar</button>
              </div>
            </div>
            <div>
              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;a.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;b.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;c.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;d.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>
            </div>
          </div>

          <div className="container margin-top-bottom-40">
            <div className="row justify-content-between divisoria">
              <div className="">
                <h5>Pergunta 4</h5>
              </div>
              <div className="">
                <p className="d-inline">0 pontos</p>
                <button className="btn btn-light">Salvar e Enviar</button>
              </div>
            </div>
            <div>
              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;a.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;b.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;c.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;d.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>
            </div>
          </div>

          <div className="container margin-top-bottom-40">
            <div className="row justify-content-between divisoria">
              <div className="">
                <h5>Pergunta 5</h5>
              </div>
              <div className="">
                <p className="d-inline">0 pontos</p>
                <button className="btn btn-light">Salvar e Enviar</button>
              </div>
            </div>
            <div>
              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;a.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;b.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;c.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;d.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>
            </div>
          </div>

          <div className="container margin-top-bottom-40">
            <div className="row justify-content-between divisoria">
              <div className="">
                <h5>Pergunta 6</h5>
              </div>
              <div className="">
                <p className="d-inline">0 pontos</p>
                <button className="btn btn-light">Salvar e Enviar</button>
              </div>
            </div>
            <div>
              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;a.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;b.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;c.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" value="" />
                  &nbsp;d.&nbsp;Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit.
                </label>
              </div>
            </div>
          </div>

          <div className="row justify-content-between ">
            <div className="">
              <p>
                Clique em Salvar e Enviar para salvar e enviar. Clique em Salvar
                todas as respostas para salvar todas as respostas
              </p>
            </div>
            <div className="">
              <button className="btn btn-light">
                Salvar todas as respostas
              </button>
              <button className="btn btn-danger">Salvar e Enviar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
