import React from "react"

const Unidade = ({ nameUc, cmptItem, allItem }) => {
  const ucRandomKey = Math.random()
    .toString(36)
    .substring(7)

  return (
    <div
      key={`${ucRandomKey}`}
      className="card-body col-12 d-flex justify-content-between discipline_uc-progress"
    >
      <div className="col">
        <span className="discipline-progress_content">{nameUc}</span>{" "}
      </div>
      <div className="col-2">
        <span className="discipline-progress_content">
          {cmptItem}/{allItem}
        </span>
      </div>
    </div>
  )
}

export default Unidade
