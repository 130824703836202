import { gateway } from "../../../utils/api";

export default ({ termTemplateCode, offerCode }) => {
  try {
    return gateway.post("/webbff/lit_app_web/term/generatePaymentTerm", {
      termTemplateCode,
      offerCode
    });
  } catch (error) {
    console.log(error);
  }
};
