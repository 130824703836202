import React from "react";
import { TabContent } from "./styles";

export default ({ child, id }) => {
  return (
    <>
      <TabContent eventKey={id}>{child}</TabContent>
    </>
  );
};
