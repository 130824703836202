import styled from "styled-components";

export default styled.button`
  height: 45px;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  text-align: center;

  background-color: ${({ backgroundColor }) => backgroundColor || "#02c4fd"};
  color: ${({ textColor }) => textColor || "#fff"};
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  font-size: 0.8rem;
`;
