import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} href //path that the link should navigate after clicking
 * @param {String} icon //icon that can be displayed next to the text
 * @param {String} text //link display text
 * @param {String} target //window where the link can be opened after clicking
 * @param {String} color //color of the text displayed on the link
 * @param {String} size //{SMALL, MEDIUM, LARGE} size of the text displayed on the link
**/

export default ({
  href = DEFAULT_VALUES.STRING,
  icon = DEFAULT_VALUES.STRING,
  text = DEFAULT_VALUES.STRING,
  target = DEFAULT_VALUES.STRING,
  color = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        href={href}
        icon={icon}
        text={text}
        target={target}
        color={color}
        size={size}
      />
    </>
  );
};
