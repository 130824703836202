import React, { useState } from "react";
import MeetingChatMessageBlock from "../MeetingChatMessageBlock";
import {
  ChatContainer,
  ChatInput,
  ChatInputContainer,
  ChatMessagesContainer,
  SendMessageButton,
  SendMessageIcon
} from "./styles";

export default React.memo(function MeetingChat({ chat, onSentMessageClick }) {
  const [message, setMessage] = useState("");

  return (
    <ChatContainer>
      <ChatMessagesContainer>
        {chat.map(entry => (
          <MeetingChatMessageBlock entry={entry} />
        ))}
      </ChatMessagesContainer>

      <ChatInputContainer>
        <ChatInput
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Enviar mensagem"
        />

        <SendMessageButton
          size="small"
          edge="start"
          color="inherit"
          onClick={() => onSentMessageClick(message)}
          title="Enviar mensagem"
          aria-label="Enviar mensagem"
        >
          <SendMessageIcon />
        </SendMessageButton>
      </ChatInputContainer>
    </ChatContainer>
  );
});
