import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES, NOTIFICATION_TYPE } from "../../../constants";

/**
 * @param {String} title //title displayed above the scrollbar notifications
 * @param {String} buttonText //button text displayed above notifications on the scroll bar
 * @param {Array} notification //list of notifications displayed on the scroll bar
**/

export default ({
  title = DEFAULT_VALUES.STRING,
  buttonText = DEFAULT_VALUES.STRING,
  notification = DEFAULT_VALUES.LIST
}) => {

  return (
    <>
      <Screen
        title={title}
        buttonText={buttonText}
        notification={notification}
      />
    </>
  );
};
