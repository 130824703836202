import React, {useState} from 'react';

import {Container} from './styles';
import Modal from '../Modal';
import PdfViewer from '../../../../../PdfViewer'

export default function SurveyPdfComponent({id, path, name}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <Container id={id} >
      <button type="button" onClick={() => setShowModal(true)}>Visualizar PDF</button>
      <Modal
        visible={showModal}
        title={name}
        onClose={() => setShowModal(false)}
      >
         <div id="pdf" className="row">
          <div className="col-md-12">
            <div id="pdf-div-id" className="pdf-wrapper">
              <PdfViewer url={path} />
            </div>
          </div>
        </div>
      </Modal>
    </Container>
  );
}
