import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ValidCpfRoutes = ({ component: Component, ...rest }) => {
  const {
    loading,
    data: { cpf }
  } = useSelector(state => state.user.profile);
  const storageCPF = localStorage.getItem('hasCPF');

  const hasCPF = storageCPF && storageCPF !== 'undefined';
  let renderComponent = null;

  if (!loading && cpf) {
    renderComponent = props => <Component {...props} />;
  } else if (!loading && !cpf && !hasCPF) {
    renderComponent = props => (
      <Redirect
        to={{ pathname: '/consultaCpf', state: { from: props.location } }}
      />
    );
  }

  return <Route {...rest} render={renderComponent} />;
};

export default ValidCpfRoutes;
