import React, { Component } from 'react'
import { connect } from "react-redux"
import { toast } from 'react-toastify'
import OnboardingPageWelcome from '../OnboardingPageWelcome'
import OnboardingBottomStepper from '../OnboardingBottomStepper'
import OnboardingPagePersonalData from '../OnboardingPagePersonalData'
import OnboardingPageFormationsExperiences from '../OnboardingPageFormationsExperiences'
import OnboardingPageListBox from '../OnboardingPageListBox'
import OnboardingPageDynamic from '../OnboardingPageDynamic'
import OnboardingPageInterests from '../OnboardingPageInterests'
import OnboardingPagePersonalize from '../OnboardingPagePersonalize'
import OnboardingPagePrivacy from '../OnboardingPagePrivacy'
import OnboardingPageRecommendations from '../OnboardingPageRecommendations'

import { ONBOARDING_PAGES } from '../../../../utils/constants'
import { toastErrorAndWarningOptions } from '../../../../utils/functions';
import history from '../../../../utils/history'
import { gateway } from '../../../../utils/api'

function setIndex(onboardingData) {
  let index = -1;
  const findIndex = onboardingData.findIndex(item => !item.bf_concluded)

  if (findIndex > -1) {
    index = findIndex
  } else {
    index = onboardingData.length - 1
  }

  return index;
}

class OnboardingContainer extends Component {

  currentPageRef = React.createRef();

  constructor(props) {
    super(props)
    const index = props.onboardingData.length > 0 ?
      setIndex(props.onboardingData)
      : -1
    this.state = {
      currentPageIndex: index,
      isLoading: false,
    };
  }

  skipPage = () => {
    const { currentPageIndex } = this.state
    const { onboardingData } = this.props
    if (currentPageIndex === -1) {
      return;
    }
    if (currentPageIndex === onboardingData.length - 1) {
      history.push('/home')
    } else {
      this.setState((prevState) => ({
        currentPageIndex: prevState.currentPageIndex + 1,
      }));
    }
  }

  advancePage = async () => {
    const { currentPageIndex } = this.state;
    const { onboardingData } = this.props;

    if (currentPageIndex === -1) {
      return;
    }

    if (this.currentPageRef.current) {
      try {
        await this.currentPageRef.current.saveForm();
      } catch (e) {
        if (e.response && e.response.data) {
          const errorMessage = e.response.data.error;
          if (typeof this.currentPageRef.current.showErrors === 'function') {
            this.currentPageRef.current.showErrors(e.response.data.error);
          }
          toast.warn(errorMessage, toastErrorAndWarningOptions);
        }
        return;
      }
    }

    if (currentPageIndex === onboardingData.length - 1) {
      gateway.post('/webbff/shared/hubspot/saveOnboardingData', {});

      const courseCodeLTI = localStorage.getItem('courseCodeLTI');

      if (courseCodeLTI && courseCodeLTI.length > 0) {
        return history.push(`/curso-novo/${courseCodeLTI}`);
      }

      if (onboardingData[currentPageIndex].wx_page === ONBOARDING_PAGES.PERSONALIZE) {
        history.push('/personalizar');
      } else {
        history.push('/home');
      }

    } else {
      this.setState((prevState) => ({
        currentPageIndex: prevState.currentPageIndex + 1,
      }));
    }
  }


  goBackAPage = () => {
    const { currentPageIndex } = this.state
    if (currentPageIndex <= 0) {
      console.log('FIRST PAGE, CANNOT RETURN.');
    } else {
      this.setState((prevState) => ({
        currentPageIndex: prevState.currentPageIndex - 1,
      }));
      if (this.currentPageRef.current) {
        this.currentPageRef.current.saveDataInState();
      }
    }
  }

  render() {
    const { data, previousAnswersGoals, previousAnswersIterests } = this.props

    if (data.length > 0) {
      const { isLoading, currentPageIndex } = this.state
      const currentPage = data[currentPageIndex];
      let pageComponent = <p>Error!</p>;

      if (!currentPage) {
        return pageComponent;
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.WELCOME) {
        pageComponent = <OnboardingPageWelcome index={currentPageIndex} data={currentPage} ref={this.currentPageRef} />;
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.PERSONAL_DATA) {
        pageComponent = <OnboardingPagePersonalData index={currentPageIndex} data={currentPage} ref={this.currentPageRef} />;
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.FORMATIONS_EXPERIENCES) {
        pageComponent = <OnboardingPageFormationsExperiences index={currentPageIndex} data={currentPage} ref={this.currentPageRef} isVisibleTitle />;
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.LISTBOX) {
        pageComponent = <OnboardingPageListBox index={currentPageIndex} data={currentPage} ref={this.currentPageRef} isVisibleTitle />;
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.GOALS) {
        pageComponent = (
          <OnboardingPageDynamic
            key={currentPage._id}
            index={currentPageIndex}
            data={currentPage}
            questions={currentPage.qx_dynamicquestions}
            previousAnswers={previousAnswersGoals}
            ref={this.currentPageRef}
            isVisibleTitle />
        )
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.INTERESTS) {
        pageComponent = (
          <OnboardingPageInterests
            index={currentPageIndex}
            data={currentPage}
            previousAnswer={previousAnswersIterests}
            ref={this.currentPageRef}
          />
        )
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.PRIVACY) {
        pageComponent = <OnboardingPagePrivacy data={currentPage} index={currentPageIndex} ref={this.currentPageRef} />
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.PERSONALIZE) {
        pageComponent = <OnboardingPagePersonalize index={currentPageIndex} ref={this.currentPageRef} />
      }

      if (currentPage.wx_page === ONBOARDING_PAGES.MGM) {
        pageComponent = <OnboardingPageRecommendations index={currentPageIndex} ref={this.currentPageRef} />
      }

      return (
        <>
          {pageComponent}
          <OnboardingBottomStepper
            isLoading={isLoading}
            currentPage={currentPageIndex}
            numberOfPages={data.length}
            concluded={currentPage.bf_concluded}
            skipStep={!currentPage.bf_required}
            onSkipPage={this.skipPage}
            onNextPage={() => {
              this.setState(
                {
                  isLoading: true,
                },
                () => {
                  this.advancePage().finally(() => {
                    this.setState({
                      isLoading: false,
                    });
                  });
                }
              );
            }}
            onPreviousPage={() => {
              this.goBackAPage();
            }}
          />
        </>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  data: state.onboarding.onboardingData,
  previousAnswersGoals: state.onboarding.previousAnswersGoals,
  previousAnswersIterests: state.onboarding.previousAnswersIterests
})


export default connect(mapStateToProps)(OnboardingContainer)
