import React from "react";

import ModalHeader from "../CommomComponents/SearchModalHeader";

import { Modal, Content } from "./styles";

export default ({ child, textHeader, isOpen, closeModal }) => {
  return (
    <>
      <Modal isOpen={isOpen}>
        <Content>
          <ModalHeader textHeader={textHeader} closeModal={closeModal} />
          {child}
        </Content>
      </Modal>
    </>
  );
};
