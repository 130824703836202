import React, { memo, useEffect } from "react"

import _ from "lodash";
import CardCurso from "./CardCurso";
import LaneHoc from "./LaneHoc";

import { StyleLane } from './styles';


const LaneList = ({ data }) => {
  const docs = _.get(data, 'docs', [])
  
  if (!docs.length) return null;

  const lanesWithCourses = docs.filter(
    item => item.courses.length > 0
  )

  return (<>
    {lanesWithCourses.map((item) => 
      <LaneHoc tooltipText={item.name} title={item.name}>
          {item.courses.map(course => {
            return (
              <StyleLane key={`${course.id}`}>
                <CardCurso course={course} url="curso-novo" />
              </StyleLane>
            );
          })}
      </LaneHoc>
    )}
  </>)
}

export default memo(LaneList)
