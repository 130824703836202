import React, { useState } from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} pageImage //path (url) of the image that will be displayed at the top of the modal
 * @param {Array} group //details of the group that will be presented
 * @param {Array} members //details of the members to be presented
 * @param {Array} member //details of the member to be presented
 * @param {Function} callback //action that will be performed when clicking on list item
**/

export default ({
  pageImage = DEFAULT_VALUES.STRING,
  group = DEFAULT_VALUES.LIST,
  members = DEFAULT_VALUES.LIST,
  member = DEFAULT_VALUES.LIST,
  callback = DEFAULT_VALUES.FUNCTION
}) => {

  const [user, setUser] = useState(null);

  async function setProfileUser(user) {
    setUser(user);
  }
  return (
    <>
      <Screen
        pageImage={pageImage}
        group={group}
        members={members}
        member={member}
        callback={callback}
        setProfileUser={setProfileUser}
        user={user}
      />
    </>
  );
};
