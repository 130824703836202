import { Types } from "./types"
/**
 * Reducers
 */

const INITIAL_STATE = {
  total: 0,
  period: 1,
  amount: 0,
}

export default function payment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PAYMENT_VALUE:
      return {
        ...state,
        total: action.payload.data.total,
        period: action.payload.data.period,
        amount: action.payload.data.amount,
      }
    default:
      return state
  }
}
