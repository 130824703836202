import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;


  @media(max-width: 450px) {
     flex-direction: column;
    }
  div#checkbox {
    margin: 0;
    padding: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    label {
      margin-bottom: 0;

    }
    @media(max-width: 450px) {
      width: 100%;
      /* margin-bottom: 10px; */
    }
    /* padding-top: 5px; */
  }
`;

export const UserAnswer = styled.p`
  margin: 0;
  padding: 0;
  margin-left: ${props => props.noMargin ? '0px' : '20px'};
  position: relative;
 
  label {
    /* position: relative; */
    line-height: 20px;
    display: inline-block;
    color: #666;
    margin-right: 10px;
  }
  
  @media(max-width: 450px) {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  svg {
    font-size: 18px;
    position: absolute;
    top: 6px;
    right: -2tpx;

    color: ${props => {
     switch (props.status) {
        case 'INCORRECT':
          return '#e03b38';
        case 'CORRECT':
          return '#6ac259';
        default:
          return '#E0E0E0';
      }
    }}
  }
`
