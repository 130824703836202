import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Function} onConfirm //button click action
 * @param {String} text //text displayed on the button
**/

export default ({
  text = DEFAULT_VALUES.STRING,
  onConfirm = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <Screen
        text={text}
        onConfirm={onConfirm}
      />
    </>
  );
};
