import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import Text from "../../basicComponents/Text";

export default function({
  options,
  name,
  label,
  placeholder,
  control,
  isMulti,
  onChange,
  fontType,
  color
}) {
  return (
    <>
      {label && <Text text={label} color={color} fontType={fontType} />}

      <Controller
        as={Select}
        name={name}
        options={options}
        isClearable
        placeholder={placeholder}
        control={control}
        isMulti={isMulti}
        onChange={onChange}
      />
    </>
  );
}
