import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import imagens from '../../../utils/imagens'

class ModalRecibo extends Component {
  imprimirRecibo = () => {
    const width = window.window.innerWidth * 0.9
    const height = window.window.innerHeight
    const { recibo } = this.props
    const content = `
<!DOCTYPE html>
<html>
    <head>
        <title>Recibo</title>
        <link href="//netdna.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
        <script src="//netdna.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js"></script>
        <script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
        <style>                                
            .invoice-title h2, .invoice-title h3 {
                display: inline-block;
            }
            .table > tbody > tr > .no-line {
                border-top: none;
            }
            .table > thead > tr > .no-line {
                border-bottom: none;
            }
            .table > tbody > tr > .thick-line {
                border-top: 1px solid #999;
            }
        </style>
    </head>
    <body onload="window.focus(); window.print(); window.close();">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <img src=${imagens.saintPaulLit} width={200} />
                            <address>
                                <strong>SAINT PAUL EDUCACIONAL LTDA.</strong><br />
                                R PAMPLONA, 1616, PORTÃO 3 <br />
                                JARDIM PAULISTA - São Paulo - SP - CEP 01.405-002 <br />
                                CNPJ 06.893.786/0001-08
                            </address>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <address>
                                <strong>Cliente</strong><br />
                                <strong>Nome: </strong>${recibo.nome}<br />
                                <strong>E-mail: </strong>${recibo.email}<br />
                                <strong>CPF: </strong>${recibo.cpf ? recibo.cpf : ''}
                            </address>
                        </div>
                        <div class="col-6">
                            <address>
                                <strong>Dados do Pagador</strong><br />
                                <strong>Nome: </strong>${recibo.shopper.fullName}<br />
                                <strong>CPF: </strong>${recibo.shopper.cpf}
                            </address>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div>
                        <h5><strong>No. Fatura: ${recibo.payment.autorization.split(':')[1]
      }</strong></h5>
                        <div>
                            <div class="table-responsive" style="border: 1px solid #999" >
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Data</th>
                                            <th scope="col">Período</th>
                                            <th scope="col">Parcela</th>
                                            <th scope="col" class="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>${recibo.payment.reference}</td>
                                            <td>${`${recibo.payment.start} - ${recibo.payment.end}`}</td>
                                            <td>${recibo.payment.installments}</td>
                                            <td class="text-right">R$ ${recibo.payment.value}</td>
                                        </tr>
                                        <tr style="height: 100px">
                                            <td class="no-line" />
                                        </tr>

                                        <tr>
                                            <td class="thick-line" />
                                            <td class="thick-line" />
                                            <td class="thick-line text-center"><strong>Total</strong></td>
                                            <td class="thick-line text-right">R$ ${recibo.payment.value
      }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;margin-top: 5em">
                        <p>São paulo, ${moment().format('LL')} </p>
                    </div>
                </div>
            </div>
        </div>
    </body>
</html>`
    const options = `toolbar=no,location=no,directories=no,menubar=no,scrollbars=yes,width=${width},height=${height}`
    const printWindow = window.open('', 'print', options)
    printWindow.document.open()
    printWindow.document.write(content)
    printWindow.document.close()
    printWindow.focus()
  }

  render() {
    const { modalOpen, modalClose, recibo } = this.props
    return (
      <Modal
        open={modalOpen}
        onClose={modalClose}
        classNames={{ modal: 'lit-modal-especialidade' }}
        closeOnOverlayClick={false}
      >
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h5 className="modal-title">Recibo</h5>
          </div>
          <div className="modal-body">
            <button
              className="btn"
              style={{ background: '#00a0eb', color: '#FFF', borderRadius: 10, marginLeft: '21em' }}
              onClick={this.imprimirRecibo}
            >
              IMPRIMIR
            </button>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <img src={imagens.saintPaulLit} width={200} />
                      <address>
                        <strong>SAINT PAUL EDUCACIONAL LTDA.</strong>
                        <br />R PAMPLONA, 1616, PORTÃO 3<br />
                        JARDIM PAULISTA - São Paulo - SP - CEP 01.405-002
                        <br />
                        CNPJ 06.893.786/0001-08
                      </address>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <address>
                        <strong style={{ fontSize: '18px' }}>Cliente</strong>
                        <br />
                        <strong>Nome: </strong>{recibo.nome}
                        <br />
                        <strong>E-mail: </strong>{recibo.email}
                        <br />
                        <strong>CPF: </strong>{recibo.cpf}
                      </address>
                    </div>
                    <div className="col-6">
                      {recibo.shopper && (
                        <address>
                          <strong style={{ fontSize: '18px' }}>Dados do Pagador</strong>
                          <br />
                          <strong>Nome: </strong>{recibo.shopper.fullName}
                          <br />
                          <strong>CPF: </strong>{recibo.shopper.cpf}
                        </address>

                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  {recibo.payment && (
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h6 className="panel-title">
                          <strong>No. Fatura: {recibo.payment.autorization.split(':')[1]}</strong>
                        </h6>
                      </div>
                      <div className="panel-body">
                        <div className="table-responsive" style={{ border: '1px solid #999' }}>
                          <table className="table table-sm mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Data</th>
                                <th scope="col">Período</th>
                                <th scope="col">Parcela</th>
                                <th scope="col" className="text-right">
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{recibo.payment.reference}</td>
                                <td>{`${recibo.payment.start} - ${recibo.payment.end}`}</td>
                                <td>{recibo.payment.installments}</td>
                                <td className="text-right">R$ {recibo.payment.value}</td>
                              </tr>

                              <tr style={{ height: '100px' }}>
                                <td className="no-line" />
                              </tr>

                              <tr>
                                <td className="thick-line" />
                                <td className="thick-line" />
                                <td className="thick-line text-center">
                                  <strong>Total</strong>
                                </td>
                                <td className="thick-line text-right">R$ {recibo.payment.value}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  <div style={{ textAlign: 'center', marginTop: '5em' }}>
                    <p className="mb-0">São paulo, {moment().format('LL')} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default ModalRecibo
