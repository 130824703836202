import styled from "styled-components";
import { } from "../../../constants";

export const Description = styled.span`
  padding: 1rem 0;
  display: table;
  width: 100%;
  * {
    width: 100%;
    text-align: left;
    margin: 5px 0;
  }
`;
