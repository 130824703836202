import styled from "styled-components";
import { } from "../../../constants";

export const Content = styled.div`
  padding: 1rem 0;
  display: block;
  width: 100%;
  overflow: hidden;
  margin: 2rem 0;
  column-span: all;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  padding-left: 5px;
  button { margin: 5px; }
  @media (max-width: 991px) {
    button { margin:0; }
    label { padding-bottom: 10px; }
  }
  @media (max-width: 480px) {
    button { margin-bottom: -5px; }
    label { font-size: 18px; }
  }
`;
