/* eslint-disable react/no-array-index-key */
import React, {useState, useEffect} from 'react'
import Typical from 'react-typical'

import Loading from '../Loading/Loading'
import {Container, ContainerNavBar, BoxInfo, NavLit, ComeBack, Title} from './styles'
import FullLoading from '../shared/FullLoading'
import CardCursoConcluido from '../shared/CardCursoConcluido'

import { gateway } from '../../utils/api'

const IMG_LIT = require("../../images/lit.png")

const TEXTS = [
  'Verificando cursos', 4000,
  'Checando progressos', 4000,
  'Consultando avaliações', 4000,
  'Analisando notas', 4000,
  'Calculando médias', 4000,
  'Montando gráficos', 4000,
  'Estabelecendo certificados', 4000
]

function Certificados() {
  const [courses, setCoursers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingNotas, setLoadingNotas] = useState(false)
  const [showText, setShowText] = useState(false)

  async function getMediaByProfile() {
    const url = `/webbff/lit_app_web/user/allCertificates`
    try {
      const response = await gateway.get(url)
      if(response.length === 0) {
        setShowText(true)
      }
      setCoursers(response)
      setLoading(false)
    } catch (error) {
      setShowText(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMediaByProfile()
  }, [])

  const setNotasLoading = () => {
    setLoadingNotas(true)
  }

  const removeNotasLoading = () => {
    setLoadingNotas(false)
  }

  return (
    <Container>
      <ContainerNavBar>
        <NavLit className="navbar navbar-lit navbar-expand-lg">
            <a className="navbar-brand">
              <img src={IMG_LIT} alt="logo_lit" style={{width: '230px'}} />
            </a>
        </NavLit> 
      </ContainerNavBar>
      {loading ? (
        <FullLoading>
          <p style={{fontWeight: 'bold'}} className="no-margin">AGUARDE</p>
          <Typical
            steps={TEXTS}
            loop={Infinity}
            wrapper="p"
          />
        </FullLoading>
      ) : (
        <>
          <BoxInfo>
            <ComeBack to="/planos">Voltar</ComeBack>
            <Title>Certificados disponíveis</Title>
            {loadingNotas && <Loading width="30px" /> }

            {courses.length > 0 && 
              courses.map(course => (
                <CardCursoConcluido 
                  key={course.courseCode || course.totvsId} 
                  course={course} 
                  setLoadingNotas={setNotasLoading}
                  removeLoadingNotas={removeNotasLoading} 
                />
              ))
            }

            {showText && (
              <p>
                Você não possui nenhum certificado disponível no momento.
                Lembre-se que o prazo de correção das avaliações é de até 10 dias úteis. Volte em breve para verificar.<br />
                Para continuar aprendendo no LIT, temos diversos planos para você acessar 100% do conteúdo!
              </p>
            )}
          </BoxInfo>
        </>
      )}     
    </Container>
  )
}

export default Certificados
