import React from "react";
import Screen from "./screen";

export default ({
  imageDefault,
  group,
  groups,
  solicitations,
  recommended,
  invitations,
  member,
  postItem,
  isInternal,
  action,
  visibilityOptions,
  saveGroup,
  control,
  changeImage,
  areasOfInterest,
  handleImageChange,
  createGroupModalIsOpen,
  createGroupModalHandleOpen,
  manageInvitesModalIsOpen,
  manageInvitesModalHandleOpen,
  inviteJoinSolicitation,
  cancelJoinSolicitation,
  declineInvitation,
  hiddenCreatePost,
  hiddenExternalComponents,
  loadMoreCommunications,
  loadMoreGroups,
  loadMoreInvites,
  hasMoreCommunications,
  hasMoreGroups,
  hasMoreInvites,
  loadMoreSolicitations,
  hasMoreSolicitations,
  manageSolicitationsModalIsOpen,
  manageSolicitationsModalHandleOpen,
}) => {
  return (
    <>
      <Screen
        isInternal={isInternal}
        imageDefault={imageDefault}
        group={group}
        groups={groups}
        saveGroup={saveGroup}
        solicitations={solicitations}
        recommended={recommended}
        invitations={invitations}
        member={member}
        postItem={postItem}
        action={action}
        visibilityOptions={visibilityOptions}
        control={control}
        changeImage={changeImage}
        areasOfInterest={areasOfInterest}
        handleImageChange={handleImageChange}
        createGroupModalIsOpen={createGroupModalIsOpen}
        createGroupModalHandleOpen={createGroupModalHandleOpen}
        manageInvitesModalIsOpen={manageInvitesModalIsOpen}
        manageInvitesModalHandleOpen={manageInvitesModalHandleOpen}
        inviteJoinSolicitation={inviteJoinSolicitation}
        cancelJoinSolicitation={cancelJoinSolicitation}
        declineInvitation={declineInvitation}
        hiddenCreatePost={hiddenCreatePost}
        hiddenExternalComponents={hiddenExternalComponents}
        loadMoreCommunications={loadMoreCommunications}
        loadMoreGroups={loadMoreGroups}
        loadMoreInvites={loadMoreInvites}
        hasMoreCommunications={hasMoreCommunications}
        hasMoreGroups={hasMoreGroups}
        hasMoreInvites={hasMoreInvites}
        loadMoreSolicitations={loadMoreSolicitations}
        hasMoreSolicitations={hasMoreSolicitations}
        manageSolicitationsModalIsOpen={manageSolicitationsModalIsOpen}
        manageSolicitationsModalHandleOpen={manageSolicitationsModalHandleOpen}
      />
    </>
  );
};
