import React, { useCallback, useState, useEffect } from 'react';
import Timer from 'react-compound-timer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { FaCheck, FaCopy } from 'react-icons/fa';
import { differenceInSeconds } from 'date-fns';
import { toastDefaultOptions } from '../../../../../../utils/functions'
import * as Styles from '../../styles';

const TIME_LIMITE_PAYMENT_PIX = 900;

function QRCodeComponent({ qrCode, verifyPayment }) {
  const [diffInSecondsPixPayment, setDiffInSecondsPixPayment] = useState(0);
  const [showQrCode, setShowQrCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const datePix = qrCode.date;
    if (datePix) {
      const today = new Date();
      setDiffInSecondsPixPayment(differenceInSeconds(today, new Date(datePix)));
      setShowQrCode(true);
    }
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    toast('Código copiado', {
      ...toastDefaultOptions,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000
    });
  }, []);

  const formatValue = useCallback(text => {
    return text.toString().length > 1 ? text : `0${text}`;
  }, []);

  return (
    <div>
      {showQrCode && (
        <Styles.Container>
          <Styles.QrCodeImage>
            <object
              type="image/svg+xml"
              data={qrCode.data}
              width="270"
              height="300"
            >
            </object>
          </Styles.QrCodeImage>
          <Timer
            initialTime={
              (TIME_LIMITE_PAYMENT_PIX - diffInSecondsPixPayment) * 1000
            }
            direction="backward"
            checkpoints={[
              {
                time: 0,
                callback: () => {
                  verifyPayment();
                  localStorage.removeItem('pixData');
                  window.location.reload();
                }
              }
            ]}
          >
            {() => (
              <Styles.TextTimer>
                <span>Tempo Restante</span>
                <Timer.Minutes formatValue={formatValue} />:
                <Timer.Seconds formatValue={formatValue} />
              </Styles.TextTimer>
            )}
          </Timer>
          <div style={{ display: 'flex', marginTop: '30px' }}>
            <CopyToClipboard text={qrCode.data} onCopy={handleCopyToClipboard}>
              <Styles.Button>
                <span>Copiar código</span>
                <FaCopy />
              </Styles.Button>
            </CopyToClipboard>
            <Styles.Button
              onClick={() => {
                setIsLoading(true);
                verifyPayment();
                setIsLoading(false);
              }}
              disabled={isLoading}
            >
              <span>Já paguei</span>
              <FaCheck />
            </Styles.Button>
          </div>
        </Styles.Container>
      )}
    </div>
  );
}

export default QRCodeComponent;
