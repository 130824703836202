import styled from 'styled-components'


export const BoxTitle = styled.div`
  
   flex: 1; 
   display: flex;
   align-items: center;
   justify-content: space-between;
   h5 {
     margin-bottom: 0 !important;
     font-size: 1.5em;
   }
`

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  p {
    font-size: 16px;
    b {
      font-weight: bold;
    }
  }
`
