import styled from "styled-components";
import { } from "../../../constants";

export const Container = styled.div`
  display: block;
  width: 80vw;
  margin: 0 auto;
  position: relative;
  max-width: 92%;
  .content-button { 
    display: inline-block; 
  }
`;
