/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { FaPlus, FaTimes, FaCheck } from 'react-icons/fa'
import swal from "@sweetalert/with-react"
import { toast } from 'react-toastify'
import { SELECT_TYPES } from '../../../utils/constants'
import { fetchSelectType } from '../../../business/onboarding'
import ProfessionalExperienceForm from './ProfessionalExperienceForm'
import ProfessionalExperienceItem from './ProfessionalExperienceItem'
import { toastErrorAndWarningOptions } from '../../../utils/functions'
import {
  Container,
  AddExperienceBox,
  Experience,
  AddButton,
  AddText,
  NotHaveBox,
  NotHaveButton,
  NotHaveText,
  ItemList,
  Label
} from './styles'

export default function ProfessionalExperience({
  data,
  updateData,
  withoutProfessionalExperience,
  showNotHaveWithoutProfessionalExperience,
  toggleWithoutProfessionalExperience,
  onChangeLinkedinUrl,
  linkedURL,
  errors = ''
}) {
  const [selectedItem, setSelectedItem] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [offices, setOffices] = useState([])
  const [wu_linkedin_url, setLinkedinUrl] = useState(linkedURL)

  async function fetchOffices() {
    try {
      const response = await fetchSelectType(SELECT_TYPES.OFFICES)
      setOffices(response)
    } catch (error) {
      toast.error('Erro ao buscar cursos de graduação', toastErrorAndWarningOptions)
    }
  }

  useEffect(() => {
    fetchOffices()
  }, [])

  function addExperience() {
    setShowForm(true)
  }

  function closeForm() {
    setShowForm(false)
    setSelectedItem(null)
  }

  function handleSelectItem(item) {
    setSelectedItem(item)
    addExperience()
  }

  function handleRemoveItem(item) {
    swal({
      title: `Deseja realmente remover esta experiência `,
      text: `Ao confirmar você perdera todos os dados referente a esta experiência. Deseja continuar?`,
      // buttons: ["Cancelar", "Confirmar"],
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
          className: "btn-alert"
        },
        confirm: {
          text: "Continuar",
          value: true,
          visible: true,
          className: "btn-alert btn-alert-confirm-onboarding",
        },
      }
    }).then(value => {
      if (value) {
        const newData = data.filter(i => i.id !== item.id)
        updateData(newData)
      }
    })
  }

  function handleSubmitFormProfessionalExperience(dados) {
    const newErros = errors;
    let newData = [...data]
    const index = newData
      .findIndex(i => i.id === dados.id)
    if (index > -1) {
      newData[index] = dados
    } else {
      newData = [...newData, dados]
    }
    newErros[index] = null
    updateData(newData, newErros)
    setShowForm(false)
  }

  function handleChangeLinkedinUrl(e) {
    setLinkedinUrl(e.target.value)
    onChangeLinkedinUrl(e.target.value)
  }

  return (

    <Container>
      {!withoutProfessionalExperience && (
        <Experience>
          {!showForm && (
            <AddExperienceBox onClick={addExperience}>
              <AddButton id="add-profession" type="button"><FaPlus size="14px" /></AddButton>
              <AddText>Adicionar Experiência</AddText>
            </AddExperienceBox>
          )}
          {showForm && (
            <ProfessionalExperienceForm
              item={selectedItem}
              options={offices}
              submitForm={handleSubmitFormProfessionalExperience}
              cancel={closeForm}
            />
          )}
          <ItemList>
            {data.length > 0 && data.map((item, index) => (
              <ProfessionalExperienceItem
                error={errors[index]}
                key={item.id}
                item={item}
                selectItem={() => handleSelectItem(item)}
                removeItem={() => handleRemoveItem(item)}
              />
            ))
            }
          </ItemList>
        </Experience>
      )}

      {showNotHaveWithoutProfessionalExperience && (
        <Experience>
          <NotHaveBox >
            <NotHaveButton id="not-experience" onClick={toggleWithoutProfessionalExperience} withoutAcademicTraining={withoutProfessionalExperience} type="button">
              {withoutProfessionalExperience ? (
                <FaCheck size="14px" />
              ) : (
                <FaTimes size="14px" />
              )}
            </NotHaveButton>
            <NotHaveText onClick={toggleWithoutProfessionalExperience}>Não possuo experiência profissional</NotHaveText>
          </NotHaveBox>
        </Experience>
      )}

      <div className="row" style={{ marginTop: '20px' }}>
        <div className="col-md-9">
          <div className="form-group">
            <Label>Link do linkedin</Label>
            <input
              type="text"
              className="form-control"
              value={wu_linkedin_url}
              placeholder="Digite aqui seu perfil do Linkedin. Ex:https://www.linkedin.com/in/usuário/"
              onChange={handleChangeLinkedinUrl}
            />
          </div>
        </div>
      </div>
    </Container>

  )
}