import React from 'react'
import Template from '../Pagamento/_template'

import { Container } from './styles'

export default function ConfirmCharge({
  onSubmit,
  onCancel
}) {

  return (
    <Template showHeader>
      <Container>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <h4 className="box-info-title text-left">
              <br />
              Conforme plano selecionado haverá cobrança neste momento, deseja continuar?
              <br />
            </h4>
          </div>
        </div><br />
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <div className="box-buttons">
              <button
                type="button"
                style={{ marginTop: '0px', maxWidth: "130px", paddingRight: "-320px", marginRight: "10px" }}
                className="btn btn-blue btn-lg btn-block"
                onClick={() => onCancel()}
                id="btn-cancel"
              >
                Voltar
              </button>
              <button
                type="button"
                style={{ marginTop: '0px', maxWidth: "130px", paddingRight: "-320px" }}
                className="btn btn-blue btn-lg btn-block"
                onClick={() => onSubmit()}
                id="btn-confirm"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </Container>
    </Template>
  )
}
