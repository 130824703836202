import React, { useState } from "react";
import Screen from "./screen";
import * as Yup from "yup";
import _ from "lodash";

export default ({ visibilityOptions, control, areasOfInterest, action, handleIsOpen }) => {
  const [imageCode, setimageCode] = useState('');
  
  async function createGroup(formValues) {

    const newGroupInfo = {
      name: formValues.name,
      image: imageCode,
      longDescription: formValues.longDescription,
      visibility: _.get(formValues,'visibility.value', ''),
      privacy: _.get(formValues,'privacy.value', ''),
      areasofinterest: []
    };

    if(!formValues.areasofinterest)
      formValues.areasofinterest = [];

    if(formValues.areasofinterest.length){
      formValues.areasofinterest.forEach(area => {
        newGroupInfo.areasofinterest.push({ code: _.get(area, 'value', '') });
      });
    }

    handleIsOpen(false);
    action(newGroupInfo);
  }

  async function setValue(value) {
    await setimageCode(value);
  }

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Campo muito curto!")
      .required("Nome obrigatório"),
    visibility: Yup.string().required("Campo Obrigatório"),
    privacy: Yup.string().required("Campo Obrigatório"),
    longDescription: Yup.string()
      .min(5, "Campo muito curto!")
      .required("Descrição obrigatória")
  });

  return (
    <>
      <Screen
        control={control}
        visibilityOptions={visibilityOptions}
        areasOfInterest={areasOfInterest}
        setValue={setValue}
        action={createGroup}
        validationSchema={ValidationSchema}
      />
    </>
  );
};
