import React, { memo, useState } from "react"
import LinkedinBlockShape from "./LinkedinBlockShape"
import LinkedinCircleShape from "./LinkedinCircleShape"
import { gateway } from "../../utils/api"

const SocialLinkedin = ({ onLoginSuccess, onLoginFailure, shape }) => {
  const [loading, setLoading] = useState(false)

  const handleSuccess = async data => {
    setLoading(true)
    const body = {
      code: data,
      redirect_uri: `${window.location.origin}/linkedin`,
    }

    try {
      const response = await gateway.post(
        "/sociallogin/linkedin",
        body,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      const jsonLogin = {
        idSocial: response.sub,
        socialToken: response.accessToken,
        platform: "LINKEDIN",
        profile: {
          firstName: response.given_name,
          lastName: response.family_name,
          linkPhoto: response.picture,
          login: {
            username: response.email,
            password: response.sub,
            enabled: true,
            role: {
              idRole: 2,
            },
          },
        },
      }

      setLoading(false)
      onLoginSuccess(jsonLogin)
    } catch (err) {
      console.log(err)
      setLoading(false)
      onLoginFailure(err)
    }
  }

  const handleFailure = error => {
    setLoading(false)
    onLoginFailure(error)
  }

  if (shape === "block") {
    return (
      <LinkedinBlockShape
        handleFailure={handleFailure}
        handleSuccess={handleSuccess}
        loading={loading}
      />
    )
  }

  return (
    <LinkedinCircleShape handleFailure={handleFailure} handleSuccess={handleSuccess} loading={loading} />
  )
}

export default memo(SocialLinkedin)
