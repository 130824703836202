import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaSpinner } from 'react-icons/fa';
import _ from 'lodash';

import history from '../../utils/history';
import Tooltip from '../Tooltip';
import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';

import InfoHome from './InfoHome';
import LaneNewGroups from './LaneNewGroups';

import LaneBooks from './LaneBooks';
import LaneCourses from './LaneCourses';
import LaneEvents from './LaneEvents';
import LaneWarnings from './LaneWarnings';
import LaneList from './LaneList';

import CustomContentLoader from './CustomContentLoader';
import FooterHome from './FooterHome';
import SubFooter from './SubFooter';

import ModalIE from './ModalIE';
import { gateway } from '../../utils/api';
import { WrapperInfiniteScroll, LoadingWrapper } from './styles';

import { Creators as CourseActions } from '../../store/ducks/newCourse/actions';

const Home = () => {
  const [lanesComponents, setLanesComponents] = useState([]);
  const [showcaseBanners, setShowcaseBanners] = useState([]);
  const [items, setItems] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loader, setLoader] = useState(null);
  // const showPaul = JSON.parse(localStorage.getItem('showPaul'), false);

  localStorage.removeItem('certificates');

  const dispatch = useDispatch();

  const redirectToTenantData = useSelector(
    state => state.newCourse.redirectToTenantData
  );

  const getAdditionalLanes = () => {
    // These values are based on the html elements height
    const marginTop = 500;
    const marginBottom = 245;
    const lanesMinHeight = 124;

    // This calculation is made to know how many additional lanes is necessary to fulfill the empty space on the page
    const remainingSpace = window.innerHeight - marginTop - marginBottom;
    const additionalLanes = Math.trunc(remainingSpace / lanesMinHeight);

    return additionalLanes;
  };

  const lanesComponentsDictionary = {
    LANE_COURSES: LaneCourses,
    LANE_IN_PROGRESS: LaneCourses,
    LANE_LAST_VIEWED: LaneCourses,
    LANE_BOOKS: LaneBooks,
    LANE_BOOKS_DEFAULT: LaneBooks,
    LANE_SOCIAL_GROUPS: LaneNewGroups,
    LANE_EVENTS: LaneEvents,
    LANE_WARNINGS: LaneWarnings,
    LANE_CLASS: LaneList,
    LANE_TOPICS_BY_INTEREST: LaneList,
    LANE_TOP_COURSES_WATCHED: LaneCourses
  };

  const getLaneFromObj = obj => {
    const type = _.get(obj, 'lane.type', null);

    if (type === 'VITRINE_DISPLAY') {
      const filtredDocsToWeb = obj.docs.filter(doc => doc.tags.includes('WEB'));

      setShowcaseBanners(filtredDocsToWeb);
    }

    const component = lanesComponentsDictionary[type];
    // Fallback of not finding a matching component
    if (!component) {
      return {};
    }
    const laneItem = { component, show: true, data: obj };
    return laneItem;
  };

  const lanes = useMemo(() => {
    const lanesItems = Object.assign([], lanesComponents);
    // Add additional lanes in case the window height is above 960px. Without additional lanes, infinite scroll doesn't trigger fetchMoreData function.
    setItems(lanesItems.slice(0, 6 + getAdditionalLanes()));
    return lanesItems;
  }, [lanesComponents]);

  const getHomeData = async () => {
    try {
      const homeCrCode = localStorage.getItem('homeCrCode');
      let url = 'webbff/lit_app_web/home';
      if (homeCrCode) {
        url = `webbff/lit_app_web/home/?homeCrCode=${homeCrCode}`;
      }
      const response = await gateway.get(url);
      setLanesComponents(response.map(getLaneFromObj));
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
    }
  };

  const getLoader = () => {
    if (loadingData) {
      const LOADING_LANES = 2;
      const array = new Array(LOADING_LANES).fill();
      return (
        <>
          {array.map(() => {
            return (
              <CustomContentLoader
                tooltipText="Carregando descrição"
                title="Carregando Lista"
              />
            );
          })}
        </>
      );
    }

    if (items.length !== lanes.length) {
      return (
        <LoadingWrapper>
          <FaSpinner size={20} color="#27aae1" />
        </LoadingWrapper>
      );
    }
    return null;
  };

  const fetchMoreData = useCallback(() => {
    const moreItems = lanes.slice(items.length, items.length + 3);

    if (moreItems.length > 0) {
      setTimeout(() => {
        setItems(prevState => prevState.concat(moreItems));
        setLoader(getLoader());
      }, 1500);
    } else {
      setItems(prevState => prevState.concat(items.length));
    }
  }, [items]);

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const homeCrCode = urlParams.get('homeCrCode');

    getHomeData(homeCrCode);
  }, []);

  useEffect(() => {
    setLoader(getLoader());
  }, [loadingData]);

  useEffect(() => {
    if (redirectToTenantData) {
      const { course, object, isSelectedTenant } = redirectToTenantData;
      if (isSelectedTenant && course) {
        dispatch(CourseActions.redirectToTenant(null));
        history.push(
          {
            pathname: `/curso-novo/${course}`
          },
          {
            object
          }
        );
      }
    }
  }, [redirectToTenantData]);

  return (
    <div id="home">
      <Tooltip id="home" />
      <NavBarSuperior home reviewSmartBar />

      <div
        style={{
          overflowX: 'hidden',
          'min-height': '100vh',
          marginBottom: 50
        }}
      >
        <InfoHome showcaseBanners={showcaseBanners} />
        <WrapperInfiniteScroll>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore
            loader={loader}
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.5}
          >
            {!loadingData &&
              items.map(item => {
                const Component = item.component;
                if (!Component || !item.show) return null;
                return <Component loading={loadingData} data={item.data} />;
              })}
          </InfiniteScroll>
        </WrapperInfiniteScroll>
      </div>
      <FooterHome />
      <SubFooter />
      <ModalIE />
    </div>
  );
};

export default memo(Home);
