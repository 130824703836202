import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} text //value of the text that is displayed inside the label
 * @param {String} color //{LIGHT_GRAY} tag background color
 * @param {String} position //{LEFT} posição (alinhamento) da etiqueta
**/

export default ({
  text = DEFAULT_VALUES.STRING,
  color = DEFAULT_VALUES.STRING,
  position = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        text={text}
        color={color}
        position={position}
      />
    </>
  );
};
