import styled, { keyframes } from "styled-components";
import { darken } from "polished";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: transparent !important;
  font-family: "Open Sans", sans-serif;
`;

export const BoxWhite = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,.1)
`;

export const Header = styled.header`
  padding: 15px;
`;

export const Title = styled.p`
  font-size: 17px;
  color: #42b3c9;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Statement = styled.div`
  background: #fff;
  margin: 0;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  padding: 15px;
  border-radius: 5px;
  
  ::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  button {
    background-color: transparent;
    margin-left: 5px;
    border: none;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      opacity: 0.8;
    }
  }

  .ck.ck-editor__editable_inline{
    min-height: 120px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  padding: 15px;
  overflow-y: hidden;
  font-size: 12px;
  main {
    flex: 1;
    overflow-x: hidden;
    margin-bottom: 10px;
    ::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  }

  sidebar {
    width: 270px;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    max-height: 100%;

    margin-left: 10px;
    font-size: 12px;

    & > p {
      margin: 0;
      padding: 5px;
      width: 100%;
      border: 1px solid #c0c0c0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      border-radius: 5px;

      span {
        text-transform: uppercase;
        min-width: 160px;
        display: flex;
        align-items: center;

        svg {
          font-size: 16px;
          margin-right: 10px;
        }

        b {
          margin-left: 5px;
          font-weight: bold;
        }
      }
    }

    & > div {
      padding: 10px;
      flex: 1;
      
      overflow-y: auto;
      margin-bottom: 5px;
      background: #f3f3f3;
      border-radius: 5px;

      p {
        font-size: 12px;
        color: #666666;
        text-align: center;
        margin-bottom: 5px;
        font-weight: bold;
      }

      & > button {
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        text-align: left;
        padding: 5px;
        margin-bottom: 10px;
        background-color: #fff;
        border: 0px solid ${darken(0.12, "#dae8fc")};
        cursor: pointer;
        word-break: break-word;
        font-size: 12px;

        &:hover {
          background-color: ${darken(0.15, "#dae8fc")};
        }

        span {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }

    & > button {
      display: block;
      background: #e25457;
      padding: 5px;
      border-radius: 5px;
      color: #fff;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
    }

    .feedbackButton {
      background: #42b3c9;
      color: #fff;
      margin-bottom: 5px;
    }
  }

  @media (max-width: 750px) {
    overflow: auto;

    sidebar {
      width: 100%;
    }
  }
`;

export const InputWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  margin: 15px;
  margin-top: 20px;

  ::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  img {
    height: 40px;
    width: 40px;
    border-radius: 25px;
  }

  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${darken(0.2, "#f3f3f3")};
    border-radius: 10px;
    height: 100px;
    padding: 5px;
    margin-left: 10px;
    ::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    textarea {
      border: none;
      height: 100%;
    }

    button {
      background-color: transparent;
      color: #42b3c9;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Button = styled.button`
  width: 80px;
  height: 45px;
  background: ${({ color }) => color || "transparent"};
  border: 1px solid ${({ color }) => (color ? darken(0.1, color) : "#000")};
  max-width: 250px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.3 : 0.5)};
  }
`;

export const LoadingWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    animation: ${rotate360} 1s linear infinite;
    margin-right: 5px;
  }
`;

export const DropZone = styled.div`
  width: 100%;
  text-align: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;

  padding: 15px 0;

  &:hover {
    border-color: #1890ff;
  }

  p {
    margin: 0;
  }
`;

export const DropZoneClip = styled.div`
  text-align: center;
  cursor: pointer;
  color: #42b3c9;
  border: 1px solid #26ace7;
  border-radius: 7px;

  padding: 15px 25px;

  &:hover {
    border-color: #1890ff;
  }

  p {
    margin: 0;
  }
`;

export const ThumbsContainer = styled.div`
  padding-left: 65px;
  margin-bottom: 15px;
`;

export const Thumb = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

export const Img = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

// ----------------

export const Spacing = styled.div`
  margin-top: 20px;
  padding: 15px;
`;

export const MediaTitle = styled.h3`
  width: 100%;
  text-align: center;
`;

export const WrapperMedias = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: center;
`;

export const BoxInfo = styled.div`
  background: #fff;
  width: ${props => (props.width ? `${props.width}px` : "250px")};
  height: 100px;
  padding: 7px 5px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 673px) {
    width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  p {
    text-transform: uppercase;
    font-size: 14px;
    margin: auto;
  }

  p.highlight {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const MediaButton = styled.button`
  background: linear-gradient(
    45deg,
    rgba(2, 196, 255, 1) 0%,
    rgba(142, 230, 123, 1) 100%
  );
  border-radius: 30px;
  width: ${props => (props.width ? `${props.width}px` : "110px")};
  height: ${props => (props.height ? `${props.height}px` : "30px")};
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #02c4fd;
    background: #02c4fd;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const BackgroundGray = styled.div`
  background: #f2f4f7;
`;

export const ButtonBlue = styled.button`
  background: #26ace7 !important;
  box-shadow: 0 5px 10px rgb(38 172 231 / 10%);
  width: 100%;
  border-radius: 7px;
  padding: 10px 20px;
  margin-top: 20px;
  color: white !important;
`;

export const ButtonTransparent = styled.button`
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  color: #444 !important;
`;

export const WrapperDetails = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 30px;
`;

export const BoxDetail = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, .15);
  width: 100%;
  margin-right: 15px;
  border-radius: 7px;
  padding: 10px 20px;
  font-size: 12px;
  color: #999;

  strong{
    font-size: 16px;
    font-weight: bold;
  }

  &:nth-child(3){
    margin-right: 0px !important;
  }
`;

export const ItemDetail = styled.span`
  margin-left: 10px;
`;

export const CommentButtonsActions = styled.div`
  margin-top: 20px;
`;

export const WrapperButtons = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-top: 20px;
`;
