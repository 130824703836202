import produce from 'immer';


import { Types } from "./types"

const INITIAL_STATE = {
  onboardingData: [],
  previousAnswersGoals: [],
  previousAnswersIterests: []
}

export default function course(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_ONBOARDING_PAGES:
      return { ...state, onboardingData: action.payload }
    case Types.SET_DYNAMIC_QUESTIONS:
      return { ...state, previousAnswersGoals: mergeAnswer(action, state) }
    case Types.SET_ANSWERS_INTEREST:
      return { ...state, previousAnswersIterests: action.payload }
    case Types.SET_ONBOARDING_PAGE_CONCLUDED:
      return produce(state, draft => {
        const {index, data} = action.payload
        // eslint-disable-next-line no-param-reassign
        draft.onboardingData[index] = {
          ...data,
          bf_concluded: action.payload.concluded
        }
      });

    default:
      return state
  }
}
function mergeAnswer(action, state) {

  const stateAnswers = [...state.previousAnswersGoals].reduce((acc,curr)=>{
    acc[curr.id] = curr;
    return acc;
  },{});

  const incommingAnswers = [...action.payload];
  incommingAnswers.map(incommingAnswer=>{
    stateAnswers[incommingAnswer.id] = incommingAnswer;
  });
  
  return Object.values(stateAnswers);
}

