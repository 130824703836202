import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} title //title displayed above the slider
 * @param {String} buttonText //actions button text above the slider
 * @param {Boolean} infinite //define infinite scroll
 * @param {Array} content //list of slider components
 * @param {Function} actionButton //button action above the slider
*/

export default ({
  title = DEFAULT_VALUES.STRING,
  buttonText = DEFAULT_VALUES.STRING,
  infinite = DEFAULT_VALUES.BOOLEAN,
  content = DEFAULT_VALUES.LIST,
  actionButton = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <Screen
        title={title}
        buttonText={buttonText}
        infinite={infinite}
        content={content}
        actionButton={actionButton}
      />
    </>
  );
};
