import styled from 'styled-components'

export const MessageContainer =  styled.div`
    background: white;
    /* display: flex;
    justifyContent: center;
    flexDirection: column;
    alignItems: center; */

    p {
      font-size: 14px;
    }
`

export const ContainerAction = styled.div`
  display: flex;
  alignItems: center;
  label {
    margin: 0 5px;
  }
`
