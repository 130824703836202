import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import styled from 'styled-components'

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import Avatar from "./Avatar"
import Networking from "./Networking"
import Ministrados from "./Ministrados"
import Sobre from "./Sobre"
import Especialidades from "./Especialidades"
import Livros from "./Livros"
import spinner from "../../images/paul_loading.gif"
import api from "../../utils/api"

const Loading = styled.div`
  position: fixed;
  z-index: 201;
  top: 66px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(0px);
  background: transparent;
  height: calc((100% - 66px) - 75px);
`

class PerfilProfessor extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    id: null,
    carregando: true,
    professor: null,
    email: "",
  }

  componentDidMount() {
    const { match, location } = this.props
    const { router } = this.context

    if (!match.params.id) {
      router.history.push("/home")
    }

    if(!location.state){
      const {id} = match.params
      this.setState({ id }, this.getTeacherById)
      return;
    }

    if (location.state && location.state.email) {
      this.setState({ email: location.state.email }, this.getTeacher)
    } else {
      this.setState({ professor: false, carregando: false })
    }
   
  }

  getTeacher = async () => {
    try {
      this.setState({ carregando: true })
      const { email } = this.state
      const response = await api.get(`findProfessorProfile/${email}/`)
      this.setState({ professor: response, carregando: false })
      this.setLoading()
    } catch (error) {
      this.setState({ carregando: false })
      console.log(error)
    }
  }

  getTeacherById = async () => {
    try {
      this.setState({ carregando: true })
      const { id } = this.state
      const response = await api.get(`findProfessorProfile/profile/${id}`)
      this.setState({ professor: response, carregando: false })
      this.setLoading()
    } catch (error) {
      this.setState({ carregando: false })
      console.log(error)
    }
  }


  render() {
    const { carregando, professor, email, id } = this.state

    if (carregando) {
      return (
        <div>
          <NavBarSuperior />
          <Loading>
            <img
              alt="loading"
              style={{
                maxHeight: "100%",
                animation: "loading-spin infinite 10s linear",
              }}
              src={spinner}
            />
            <p>Carregando Perfil...</p>
          </Loading>
        </div>
      )
    }

    if (!professor) {
      return (
        <div>
          <NavBarSuperior />
          <div className="container" id="perfil">
            <div className="alert alert-warning">
              <h4 className="alert-heading">
                <i className="fa fa-warning" /> Professor não encontrado!
              </h4>
              <p style={{ color: "#856404" }}>Não existe nenhum professor cadastrado com esse id.</p>

              <Link className="alert-link" to="/perfilAluno">
                Voltar ao meu perfil
              </Link>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <NavBarSuperior />
        <div className="container-fluid space-navbar" id="perfil">
          <div className="row">
            <div className="col-md-2">
              <Avatar nome={`${professor.name}`} id={professor.idProfile} />
            </div>
            <div className="col-md-7">
              <Ministrados idProfile={professor.idProfile} />
              <Sobre sobre={professor.about} />
              <Especialidades idProfile={professor.idProfile} />
              <Livros idProfile={professor.idProfile} email={email} />
            </div>
            <div className="col-md-3">
              <Networking idProfile={professor.idProfile} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PerfilProfessor
