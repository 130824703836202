import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Array} content //social network content that will be displayed (link and text)
 * @param {String} icon //social network icon
 * @param {String} iconPosition //icon display position
 * @param {String} orientation //{VERTICAL, HORIZONTAL} direction of display of content
 * @param {String} textAlign //alignment of text within the component
**/

export default ({
  content = DEFAULT_VALUES.LIST,
  icon = DEFAULT_VALUES.STRING,
  iconPosition = DEFAULT_VALUES.STRING,
  orientation = DEFAULT_VALUES.STRING,
  textAlign = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        content={content}
        icon={icon}
        iconPosition={iconPosition}
        orientation={orientation}
        textAlign={textAlign}
      />
    </>
  );
};
