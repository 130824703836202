import React from "react"
import styled from "styled-components"

const SpanRequire = styled.span`
  color: red;
`

const RequiredSpan = () => <SpanRequire>*</SpanRequire>

export default RequiredSpan
