/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react"
import { toast } from 'react-toastify'
import history from '../../utils/history'
import { gateway } from '../../utils/api'
import { toastErrorAndWarningOptions } from '../../utils/functions'
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import Loading from '../Loading/Loading'

import {
  Container,
  BoxInfo,
  ContianerNavBar,
  NavLit,
  Text
} from './styles'

import HistoryItem from './HistoryItem'

const IMG_LIT = require("../../images/lit.png")

export default function InvoicePaymentHistory() {
  const { location: { state } } = history

  const [historyList, setHistoryList] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [fetchLoading, setFetchLoading] = useState(true)

  async function fecthPaymentHistory() {
    try {
      const response = await gateway('/webbff/lit_app_web/payment/invoice/history')
      setFetchLoading(false)
      setHistoryList(response.history || [])
    } catch (error) {
      setFetchLoading(false)
      toast.error('Ops, Erro ao buscar histórico de pagamento!', toastErrorAndWarningOptions)
    }
  }

  useEffect(() => {
    fecthPaymentHistory()
  }, [])


  return (
    <Container>
      <ContianerNavBar>
        {
          state && !state.nextPageParams.editingPayment && state.nextPageParams.fromPayment ? (
            <NavLit className="navbar navbar-lit navbar-expand-lg">
              <a className="navbar-brand">
                <img src={IMG_LIT} alt="logo_lit" style={{ width: '230px' }} />
              </a>
            </NavLit>
          ) : (
            <NavBarSuperior />
          )
        }
      </ContianerNavBar>
      {fetchLoading && (
        <Loading />
      )}
      {!fetchLoading && (
        <BoxInfo>
          {!historyList.length && !fetchLoading && (
            <Text>Nehuma histórico a ser exibido</Text>
          )}
          <div>
            {historyList.map((historyItem, index) => (
              <HistoryItem
                key={`order-${index}`}
                historyItem={historyItem}
                index={index}
                activeItemIndex={activeItemIndex}
                onSelectItem={(newActiveItemIndex) => setActiveItemIndex(newActiveItemIndex)}
              />
            ))}
          </div>
        </BoxInfo>
      )}
    </Container>
  )
}
