import React, { useContext } from "react";

import AudioButton from "./Buttons/AudioButton";
import VideoButton from "./Buttons/VideoButton";
import ShareButton from "./Buttons/ShareButton";
import HangupButton from "./Buttons/HangupButton";

import * as Styles from "./styles";

import { MeetingContext } from '../../MeetingContext'

export default function MeetingActions() {
  const { hasHostPermissions } = useContext(MeetingContext);
  return (
    <Styles.Container>
      <AudioButton/>
      <VideoButton />
      { hasHostPermissions && <ShareButton /> }
      <HangupButton />
    </Styles.Container>
  );
}
