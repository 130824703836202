import React from "react";
import {
  APPEARANCE,
  FONT_TYPE,
  SIZE,
  ICON,
  POSITION,
  COLOR,
  ACTION,
  TYPES
} from "../../../constants";
import Text from "../Text";
import Background from "../ContainerWithTopBackground";
import Image from "../Image";
import Block from "../Block";
import Line from "../Line";
import InfoTime from "../InfoTime";
import Reactions from "../Reactions";
import ViewMore from "../ViewMore";
import Actions from "../../Actions";
import TitleIconPrivacy from "../TitleIconPrivacy";
import CommentBox from "../../Inputs/CommentBox";
import Row70x30 from "../../defaultAreas/Row_70-30";
import {
  Span,
  ButtonsAtBottom,
  Title,
  InfoGroup,
  Banner,
  Content,
  Evaluation,
  TitleEvaluation,
  Subtitle,
  Divisor
} from "./styles";

export default ({
  type,
  title,
  contentCard,
  textAlign = POSITION.LEFT,
  hiddenAnswer,
  setHiddenAnswer,
  appearance,
  notificationType,
  buttonType,
  subtitleType,
  color,
  perLine,
  locked,
  useful,
  favorite,
  context,
  sendMessage
}) => {
  function showActionButton(value, content) {
    const retryButton = [];
    retryButton.push({
      type: "button",
      buttonText: buttonType(value, content.evaluation),
      size: SIZE.SMALL,
      appearance: APPEARANCE.OUTLINE_PRIMARY,
      position: POSITION.RIGHT,
      action: () => {
        setHiddenAnswer(!hiddenAnswer);
        {
          !hiddenAnswer && sendMessage();
        }
      }
    });
    return retryButton;
  }

  function showReactionsButtons(value, content, useful) {
    switch (value) {
      case TYPES.EVALUATION_ITEM:
        return (
          <InfoTime
            evaluation={content.evaluation}
            finalDate={content.finalDate}
          />
        );
      default:
        return <Reactions useful={useful} />;
    }
  }

  function showComponent(type, title, value) {
    switch (type) {
      case TYPES.GROUP_SUGESTION:
        return (
          <Content>
            <Title>
              <Background size={SIZE.MEDIUM} appearance={APPEARANCE.CARD_TITLE}>
                <Text
                  text={title}
                  size={SIZE.LARGE}
                  color={COLOR.LIGHT}
                  fontType={FONT_TYPE.BOLD}
                  appearance={APPEARANCE.TITLE}
                />
              </Background>
            </Title>
            <ViewMore
              type={type}
              content={value.description}
              totalMembers={value.totalMembers}
              administrator={value.administrator}
              favorite={favorite}
              useful={useful}
            />
          </Content>
        );
      case TYPES.CONTENT_SUGESTION:
        return (
          <Content>
            <Banner>
              <Image
                src={value.picture}
                alt={title}
                appearance={APPEARANCE.BANNER_BLOCK}
              />
            </Banner>
            <ViewMore
              type={type}
              content={value.description}
              totalMembers={value.totalMembers}
              totalHours={value.totalHours}
              totalModules={value.totalModules}
              administrator={value.administrator}
              favorite={favorite}
              useful={useful}
            />
          </Content>
        );
      case TYPES.COMMUNICATION:
        return (
          <Content>
            <InfoGroup>
              <TitleIconPrivacy
                text={value.mentionType}
                description={value.mentionIn}
                icon={ICON.GLOBE}
                size={SIZE.SMALL}
                color={color}
              />
            </InfoGroup>
            <ViewMore
              type={type}
              content={value.description}
              favorite={favorite}
              useful={useful}
            />
          </Content>
        );
      case TYPES.EVALUATION_ITEM:
        return (
          <Evaluation>
            <TitleEvaluation>
              <TitleIconPrivacy
                text={value.title}
                description={value.time}
                icon={notificationType(type, context)}
                borderColor={COLOR.LIGHT}
                size={SIZE.MEDIUM}
                color={COLOR.LIGHT}
              />
            </TitleEvaluation>
            <Subtitle>
              <TitleIconPrivacy
                text={value.contentType}
                description={value.contentIn}
                icon={ICON.GLOBE}
                size={SIZE.SMALL}
                color={COLOR.LIGHT}
              />
            </Subtitle>
          </Evaluation>
        );
    }
  }

  return (
    <>
      <Span textAlign={textAlign} perLine={perLine}>
        <Block appearance={appearance}>
          {title && (
            <TitleIconPrivacy
              text={title}
              description={subtitleType(type, contentCard)}
              icon={notificationType(type, context)}
              borderColor={COLOR.GRAY}
              locked={locked}
              showLocked={true}
              size={SIZE.MEDIUM}
              color={COLOR.GRAY}
            />
          )}

          {showComponent(type, title, contentCard)}

          <ButtonsAtBottom>
            <Row70x30
              left={[showReactionsButtons(type, contentCard, useful)]}
              right={[
                <Actions
                  position={POSITION.LEFT}
                  itemsToRender={showActionButton(type, contentCard)}
                />
              ]}
            />
            {type === TYPES.COMMUNICATION && (
              <Line hidden={hiddenAnswer}>
                <Divisor>
                  <CommentBox
                    placeholder={ACTION.RETRY_COMMENT}
                    size={SIZE.SMALL}
                    sendAction={sendMessage}
                    appearance={APPEARANCE.SIMPLE_ANSWER}
                  />
                </Divisor>
              </Line>
            )}
          </ButtonsAtBottom>
        </Block>
      </Span>
    </>
  );
};
