import styled from "styled-components";
import { } from "../../../constants";

export const InputText = styled.input.attrs({
  className: "form-control"
})`
  :focus {
    border: 1px solid var(--border-input-focus);
  }
`;
