import styled from 'styled-components';

export const ContainerError = styled.div`
  text-align: center;
  p {
    font-size: 16px;
  }
`

export const TextErro = styled.p`
  margin: 0;
  padding: 0;
  color: red;
  font-size: 12px;
`
