import React, { useState, useCallback } from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import { useSelector, useDispatch } from "react-redux";

import { Creators as CourseActions } from "../../../store/ducks/newCourse/actions";

import {
  getStatusName
} from "../../../utils/functions";

import Tooltip from '../../Tooltip';
import LinkToCertificate from "../LinkToCertificate";

import Loading from "../../Loading/Loading";

import * as Styles from './styles';

function NotaMediaFinal({ 
  course,
  fontSize
}) {

  const loadingCertificate = useSelector(state => state.newCourse.loadingCertificate);
  const certificates = useSelector(state => state.newCourse.certificates);
  const [selectedCourseCode, setSelectedCourseCode] = useState(null);
  const dispatch = useDispatch();

  const onGetCertificate = useCallback(() => {
    setSelectedCourseCode(course.code);
    dispatch(CourseActions.getCourseCertificateRequest(course.code));
  }, [course]);

  const status = certificates[course.code] ? certificates[course.code].courseGradeStatus : 'NOT STARTED'

  const certificate = certificates[course.code] ? certificates[course.code].certificate : null;

  const nota = certificates[course.code] ? certificates[course.code].gradeAverage : null;

  const {type, courseCertificationIsProgress} = course;

  return (
    <Styles.Container>
        <Styles.ContentItem className="list-child">
        {(courseCertificationIsProgress || type === 'TRAIL') && (
        <Styles.ContentItemChild>

            <Styles.ContainerIcon status={status}>
              <i className="fa fa-graduation-cap" />
            </Styles.ContainerIcon>

            <Styles.Text status={status}>
                Certificado
            </Styles.Text>
            <Styles.GradeContainer>
                {status === "DONE" && certificate && (
                <div
                    data-tip={getStatusName(status)}
                    style={{ marginTop: 5, padding: 0 }}
                >
                    <Tooltip style={{ padding: 0 }} />
                    <LinkToCertificate certificateUrl={certificate.url} />
                </div>
                )}

                {status !== "DONE" && !certificate && (
                <Styles.ActionBox>
                    {!loadingCertificate && (
                    <FaRedoAlt onClick={onGetCertificate} />
                    )}

                    {loadingCertificate && course.code !== selectedCourseCode && (
                    <FaRedoAlt onClick={onGetCertificate} />
                    )}

                    {loadingCertificate && course.code === selectedCourseCode && (
                    <Loading img width={15} height={15} />
                    )}
                </Styles.ActionBox>
                )}
            
            </Styles.GradeContainer>
        </Styles.ContentItemChild>
        )}

        {!courseCertificationIsProgress && type !== 'TRAIL' && (
        <Styles.ContentItemChild>

            <Styles.ContainerIcon status={status}>
                <Styles.Grade id={`course-object-${course.code}`} 
                    status={status}
                    className="circle circle-with-grade">
                    {nota}
                </Styles.Grade>
              </Styles.ContainerIcon>

            <Styles.Text status={status} fontSize={fontSize}>
                Nota {status === "IN PROGRESS" ? "parcial" : "final"} 
            </Styles.Text>
            
            <Styles.GradeContainer>
                {certificate && (
                <div
                    data-tip={getStatusName(status)}
                    style={{ marginTop: 5, padding: 0 }}
                >
                    <Tooltip style={{ padding: 0 }} />
                    <LinkToCertificate certificateUrl={certificate.url} />
                </div>
                )}

                {!certificate && (
                <Styles.ActionBox>
                    {!loadingCertificate && (
                    <FaRedoAlt onClick={onGetCertificate} />
                    )}

                    {loadingCertificate && course.code !== selectedCourseCode && (
                    <FaRedoAlt onClick={onGetCertificate} />
                    )}

                    {loadingCertificate && course.code === selectedCourseCode && (
                    <Loading img width={15} height={15} />
                    )}
                </Styles.ActionBox>
                )}
            </Styles.GradeContainer>
        </Styles.ContentItemChild>
        )}
      </Styles.ContentItem>
    </Styles.Container>
  )
};

export default NotaMediaFinal;
