import React from "react";
import Tooltip from "../../Tooltip";

export default () => (
  <div className="row">
    <Tooltip id="recommendation-help-tooltip" place="bottom" />
    
    <div className="col">
      <p style={{ lineHeight: "normal", fontSize: "1em", textAlign: 'justify'}}>
        Quer ter um desconto na sua mensalidade?
        Envie para seus amigos um cupom de desconto para estudar no LIT e ganhe desconto para cada amigo
        que se inscrever e se mantiver na plataforma.<br />

        Mande quantos vouchers quiser e quanto mais amigos tiver no LIT, isso significa mais
        desconto para você.
      </p>
    </div>
  </div>
)
