/* eslint-disable react/no-unused-state */
import swal from "@sweetalert/with-react";
import adyen from "adyen-cse-js";
import { Checkbox } from "antd";
import _ from "lodash";
import Payment from "payment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Recaptcha from "react-recaptcha";
import { toast } from "react-toastify";
import { findPaymentTerm } from "../../../../business/payment";
import api, { gateway } from "../../../../utils/api";
import { RECAPTCHA_KEY } from "../../../../utils/constants";
import {
  maskNascimento,
  setSEOIndexOff,
  toastDefaultOptions,
  toastErrorAndWarningOptions,
  validarCpf,
  validateDate3
} from "../../../../utils/functions";
import ModalTermos from "../../../Modals/ModalTermos";
import ModalViewTerm from "../../../Modals/ModalViewTerm";
import { InputText, ItemFormField, ItemFormFieldSelect, LabelField } from "../../../Styles/FormStyle";
import PaymentMethodType from "../../paymentMethodType";
import TrialText from "../TrialText";
import { ButtonLinks, CardError, TextError } from "./styles";

const IMG_VISA = require("../../../../images/pagamento/visa.png");
const IMG_MASTER = require("../../../../images/pagamento/master.png");
const IMG_DINNERS = require("../../../../images/pagamento/diners.png");
const IMG_ELO = require("../../../../images/pagamento/elo.png");
const IMG_AMERICAN = require("../../../../images/pagamento/american.png");

class CreditCardPayment extends Component {
  static contextTypes = {
    router: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    const { plan } = this.props;

    const termId =
      plan.payments && findPaymentTerm(plan, PaymentMethodType.CREDITO);
    const paymentImmediate = _.get(plan, "config.paymentImmediate", null);
    const idTotvsCourses = _.get(plan, "config.idTotvsCourses", []);

    const hasPaymentError = localStorage.getItem("57e0854551b1dca003c67c384c37a346") === 'true';

    this.state = {
      isVerified: false,
      termId,
      term: null,
      showCaptcha: process.env.REACT_APP_MY_ENV === "development",
      nomeCompleto: "",
      cpf: "",
      dataNascimento: "",
      mesVencimento: "",
      anoVencimento: "",
      numeroCartao: "",
      ccv: "",
      cupom: "",
      paymentError: "",
      cupomError: "",
      focused: "",
      cse: null,
      sendPayment: false,
      invalidCpf: null,
      installments: 1,
      termData: null,
      isOpeningTerms: false,
      acceptedTerm: false,
      showModal: false,
      modalData: null,
      modalTerms: false,
      paymentImmediate,
      idTotvsCourses,
      cardNumberError: false,
      cardNameError: false,
      cpfError: false,
      birthDateError: false,
      dueDateMonthError: false,
      dueDateYearError: false,
      cvvError: false,
      hasPaymentError
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.textMessageCPF !== this.props.textMessageCPF) {
      this.setState({ textMessageCPF: nextProps.textMessageCPF });
      this.setState({
        messagePartOne: this.props.textMessageCPF.substring(0, 138)
      });
    }
  }

  componentDidMount() {
    const dataCard = JSON.parse(localStorage.getItem("data-card"));
    if (dataCard) {
      if (dataCard.numero) this.setState({ numeroCartao: dataCard.numero });
      if (dataCard.nome) this.setState({ nomeCompleto: dataCard.nome });
      if (dataCard.mes) this.setState({ mesVencimento: dataCard.mes });
      if (dataCard.ano) this.setState({ anoVencimento: dataCard.ano });
      if (dataCard.cvc) this.setState({ ccv: dataCard.cvc });
      if (dataCard.cpf) this.setState({ cpf: dataCard.cpf });
      if (dataCard.dataNascimento)
        this.setState({ dataNascimento: dataCard.dataNascimento });
    }
    setSEOIndexOff();
    const { location } = this.props;
    Payment.formatCardNumber(document.querySelector('[name="CCnumber"]'));
    Payment.formatCardCVC(document.querySelector('[name="cvc"]'));
    this.getCardHash();
    if (location && location.state && location.state.voucher) {
      this.setState({ cupom: location.state.voucher });
    }
  }

  redirectPi = event => {
    event.preventDefault();
    const { handleRedirectPi } = this.props;
    this.setState({ sendPayment: true });
    handleRedirectPi();
  };

  handleInputFocus = ({ target }) => {
    const { resetPaymentError } = this.props;
    resetPaymentError();
    this.setState({
      focused: target.name
    });
  };

  getServerTime = async () => {
    try {
      const response = await api.get("getServerTime");
      return response;
    } catch (error) {
      console.log(error.response);
      return null;
    }
  };

  encryptMyData = async () => {
    const {
      numeroCartao,
      ccv,
      nomeCompleto,
      mesVencimento,
      anoVencimento,
      cse
    } = this.state;
    const postData = {};

    const cardData = {
      number: numeroCartao,
      cvc: ccv,
      holderName: nomeCompleto,
      expiryMonth: mesVencimento,
      expiryYear: anoVencimento,
      generationtime: await this.getServerTime()
    };

    // Validação
    if (mesVencimento === "Mês") {
      this.setState({
        paymentError: "Mês de vencimento invalido!",
        sendPayment: false
      });
      return;
    }

    if (anoVencimento === "Ano") {
      this.setState({
        paymentError: "Ano de vencimento invalido!",
        sendPayment: false
      });
      return;
    }

    // eslint-disable-next-line no-multi-assign
    const resultCardDataEcrypted = (postData[
      "adyen-encrypted-data"
    ] = cse.encrypt(cardData));

    if (resultCardDataEcrypted !== "") {
      return resultCardDataEcrypted;
    }
    return null;
  };

  _gerenateYearPayment = () => {
    const currentYear = parseInt(new Date().getFullYear(), 10);
    const lastYear = currentYear + 20;

    const array = [];

    for (let year = currentYear; year <= lastYear; year += 1) {
      array.push(`${year}`);
    }

    const options = array.map(value => {
      return (
        <option value={value} key={value}>
          {value}
        </option>
      );
    });

    return options;
  };

  _gerenateMonthDay = () => {
    const array = [];

    for (let day = 1; day <= 31; day += 1) {
      array.push(`${day}`);
    }

    const options = array.map(value => {
      return (
        <option value={value} key={value}>
          {value}
        </option>
      );
    });

    return options;
  };

  _setDataCardStorage = (label, data) => {
    let dataCard = JSON.parse(localStorage.getItem("data-card"));
    dataCard = {
      ...dataCard,
      [label]: data
    };
    localStorage.setItem("data-card", JSON.stringify(dataCard));
  };

  _handleChangeInputNumCartao = evento => {
    const { resetPaymentError } = this.props;
    const numeroCartao = evento.target.value;

    if (numeroCartao.toString().length === 19) {
      this._fetchTypeCard(numeroCartao.replace(/\s/g, "").substring(0, 6));
    }
    this.setState({ numeroCartao, cardNumberError: false });
    resetPaymentError();
  };

  handleChangeMes = event => {
    const { resetPaymentError } = this.props;
    this.setState({
      mesVencimento: event.target.value,
      dueDateMonthError: false
    });
    this._setDataCardStorage("mes", event.target.value);
    resetPaymentError();
  };

  handleChangeAno = event => {
    const { resetPaymentError } = this.props;
    this.setState({
      anoVencimento: event.target.value,
      dueDateYearError: false
    });
    this._setDataCardStorage("ano", event.target.value);
    resetPaymentError();
  };

  _fetchTypeCard = async cardNumber => {
    try {
      const url = `isCardPromocional/${cardNumber}/`;
      const response = await api.get(url);
      if (response) {
        const { plan: plano } = this.state;
        let valorDesconto = response;
        if (plano.formOfPayment === 1) {
          valorDesconto = response - 10;
        }
        const desconto = (plano.valorTotal * valorDesconto) / 100;
        plano.desconto = desconto;
        this.setState({ plan: plano });
        this.notify(
          `Parabéns, conseguiu ${valorDesconto}% de desconto na assinatura!`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  verifyCpf = e => {
    e.preventDefault();
    const { value } = e.target;
    const result = validarCpf(value);
    if (!result) {
      this.setState({
        invalidCpf: "CPF inválido, favor inserir um CPF válido!"
      });
    } else {
      this.setState({ invalidCpf: null });
    }
    this._setDataCardStorage("cpf", e.target.value);
  };

  notify = (text, duration = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: duration
    });
  };

  verifyCallback = () => {
    this.setState({ isVerified: true });
  };

  pay = async evento => {
    const { resetPaymentError } = this.props;
    evento.preventDefault();
    resetPaymentError();

    const {
      plan,
      onSubmit,
      installments,
      setInstallmentsError,
      claimId,
      handleTrial,
      showMessageCPF,
      isEdit
    } = this.props;

    let planId = null;
    let campaignId = null;
    let desconto = null;
    let total = 1;
    if (plan.isCampaign) {
      campaignId = plan.id;
    } else if (plan.isVoucher) {
      desconto = plan.voucher;
    } else if (plan.id) {
      planId = plan.id;
    }

    if (plan.formOfPayment === 1) {
      total = 12;
      if (!installments) {
        setInstallmentsError();
        return;
      }
    }
    const { termId, acceptedTerm, term } = this.state;

    if (termId && !acceptedTerm) {
      this.setState({
        paymentError: "Você precisa aceitar os termos e condições",
        sendPayment: false
      });
      return;
    }
    const hash = await this.encryptMyData();
    const {
      numeroCartao,
      nomeCompleto,
      cpf,
      dataNascimento,
      mesVencimento,
      anoVencimento,
      ccv,
      paymentImmediate,
    } = this.state;


    const ultimosDigitosCartao = numeroCartao.substr(numeroCartao.length - 4);

    const idProfile = parseInt(localStorage.getItem("idProfile"), 10);
    const email = localStorage.getItem("ra");
    // Validações
    const isValidDate = validateDate3(dataNascimento);

    if (!numeroCartao) {
      this.setState({
        cardNumberError: true
      });
    }

    if (!nomeCompleto) {
      this.setState({
        cardNameError: true
      });
    }

    if (!cpf) {
      this.setState({
        cpfError: true
      });
    }

    if (!dataNascimento) {
      this.setState({
        birthDateError: true
      });
    }

    if (!mesVencimento) {
      this.setState({
        dueDateMonthError: true
      });
    }

    if (!anoVencimento) {
      this.setState({
        dueDateYearError: true
      });
    }

    if (!ccv) {
      this.setState({
        cvvError: true
      });
    }

    this.setState({ sendPayment: true });

    if (!isValidDate) {
      this.setState({
        paymentError: "Data de nascimento invalida!",
        sendPayment: false
      });
      return;
    }
    this.setState({
      paymentError: "",
      sendPayment: true
    });

    if (hash !== "false") {
      this.setState({ sendPayment: false });
      const pagamento = {
        idProfile,
        nomeCompleto,
        cpf: cpf.replace(/\D/g, ""),
        email,
        term,
        dataNascimento,
        numero: ultimosDigitosCartao, // 4 ultimos digitos
        binCard: numeroCartao.replace(/\s/g, "").substring(0, 6),
        hashCartao: hash,
        total,
        desconto,
        campaignId,
        planId,
        installments: installments || 1,
        claimId: claimId || null
      };

      if (showMessageCPF && !isEdit) {
        swal({
          title: `Ops, esse CPF já está sendo utilizado!`,
          text: `Olá, como você já usufruiu do período de trial anteriormente, a cobrança do seu plano será feita imediatamente após a confirmação.`,
          buttons: {
            cancel: {
              text: "Cancelar",
              value: false,
              visible: true,
              className: "btn-alert"
            },
            confirm: {
              text: "Continuar",
              value: true,
              visible: true,
              className: "btn-alert btn-alert-confirm-onboarding"
            }
          }
        }).then(value => {
          if (value) {
            this.setState({ sendPayment: false });
            onSubmit(pagamento);
          }
        });
      } else {
        this.setState({ sendPayment: false });
        if (plan.trial || !paymentImmediate) {
          onSubmit(pagamento);
        } else {
          handleTrial(() => onSubmit(pagamento));
        }
      }
    } else {
      this.setState({
        paymentError: "Erro ao criptografar os dados do cartão",
        sendPayment: false
      });
    }
  };

  acceptTerm = () => {
    const { acceptedTerm } = this.state;
    this.setState({ acceptedTerm: !acceptedTerm, paymentError: false });
  };

  goToTerm = () => {
    const { termId, acceptedTerm } = this.state;
    if (!acceptedTerm) {
      this.setState({ isOpeningTerms: true });
      gateway
        .get(`/term/${termId}`)
        .then(() => {
          // The endpoint works, go to the new modal
          const { plan } = this.props;
          this.setState({
            modalData: {
              termType: "PLAN",
              idPlan: plan.id,
              termId,
              planType: plan.planType,
              fromPayment: true
            },
            showModal: true
          });
        })
        .catch(error => {
          console.log(error);
          toast.error("Erro ao buscar termo!", toastErrorAndWarningOptions);
        })
        .finally(() => {
          this.setState({ isOpeningTerms: false });
        });
    }
  };

  openModal = () => {
    this.setState({ modalTerms: true });
  };

  render() {
    const {
      numeroCartao,
      nomeCompleto,
      mesVencimento,
      anoVencimento,
      ccv,
      cpf,
      invalidCpf,
      dataNascimento,
      cupomError,
      paymentError,
      sendPayment,
      showCaptcha,
      isVerified,
      showModal,
      termId,
      modalData,
      isOpeningTerms,
      cardNumberError,
      cardNameError,
      cpfError,
      birthDateError,
      dueDateMonthError,
      dueDateYearError,
      cvvError,
      hasPaymentError
    } = this.state;

    const { plan, back, loading, error } = this.props;

    return (
      <div>
        <form style={{ width: "100%", padding: "0px", margin: "0px" }}>
          <div className="row justify-content-md-center">
            <div className="col-md-8 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="accepted-cards mb-4">
                    <p>Cartões aceitos:</p>
                    <div>
                      <img src={IMG_VISA} alt="visaCard" />
                      <img src={IMG_MASTER} alt="masterCard" />
                      <img src={IMG_DINNERS} alt="dinersClub" />
                      <img src={IMG_AMERICAN} alt="amexCard" />
                      <img src={IMG_ELO} alt="eloCard" />
                    </div>
                  </div>
                  <div className="form-group">
                    <LabelField>Cartão de crédito</LabelField>
                    <ItemFormField>
                      <InputText
                        type="text"
                        className="form-control"
                        name="CCnumber"
                        autoComplete="off"
                        placeholder="Número do cartão"
                        required
                        onFocus={this.handleInputFocus}
                        value={numeroCartao}
                        onChange={this._handleChangeInputNumCartao}
                        onBlur={event =>
                          this._setDataCardStorage("numero", event.target.value)
                        }
                      />
                    </ItemFormField>
                  </div>
                  {cardNumberError && (
                    <CardError>Informe um número de cartão válido</CardError>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <LabelField>Nome como está no cartão</LabelField>
                    <ItemFormField>
                      <InputText
                        type="text"
                        className="form-control"
                        name="CCname"
                        placeholder="Ex.: Marcos S Santos"
                        required
                        onFocus={this.handleInputFocus}
                        value={nomeCompleto}
                        onChange={evt => {
                          const tempState = {
                            nomeCompleto: evt.target.value
                          };
                          if (cardNameError) {
                            tempState.cardNameError = false;
                          }
                          this.setState(tempState);
                        }}
                        onBlur={event =>
                          this._setDataCardStorage("nome", event.target.value)
                        }
                      />
                    </ItemFormField>
                  </div>
                  {cardNameError && (
                    <CardError>Informe o nome completo</CardError>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <LabelField>CPF</LabelField>
                    <ItemFormField>
                      <InputText
                        type="text"
                        className="form-control"
                        placeholder="Documento do titular"
                        name="cpf"
                        maxLength="14"
                        required
                        // onBlur={this.verifyCpf}
                        onFocus={this.handleInputFocus}
                        value={cpf}
                        onChange={evt => {
                          const tempState = {
                            cpf:
                              //  maskCpf(
                              evt.target.value,
                            cpfError: false
                            //  )
                          };
                          if (cpfError) {
                            tempState.cpfError = false;
                          }
                          this.setState(tempState);
                        }}
                      />
                    </ItemFormField>
                    {invalidCpf && <p style={{ color: "red" }}>{invalidCpf}</p>}
                  </div>
                  {cpfError && <CardError>Informe o cpf</CardError>}
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <LabelField>Data de nascimento</LabelField>
                    <ItemFormField>
                      <InputText
                        type="text"
                        className="form-control"
                        placeholder="DD/MM/YYYY"
                        name="nascimento"
                        maxLength="10"
                        required
                        onFocus={this.handleInputFocus}
                        value={dataNascimento}
                        onChange={evt => {
                          const tempState = {
                            dataNascimento: maskNascimento(evt.target.value)
                          };
                          if (birthDateError) {
                            tempState.birthDateError = false;
                          }
                          this.setState(tempState);
                        }}
                        onBlur={event =>
                          this._setDataCardStorage(
                            "dataNascimento",
                            event.target.value
                          )
                        }
                      />
                    </ItemFormField>
                  </div>
                  {birthDateError && (
                    <CardError>Informe a data de nascimento</CardError>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <LabelField>Vencimento</LabelField>
                    <ItemFormFieldSelect>
                      <select
                        className="form-control"
                        required
                        value={mesVencimento}
                        name="mesvencimento"
                        onFocus={this.handleInputFocus}
                        onChange={this.handleChangeMes}
                      >
                        <option>Mês</option>
                        <option value="01">Janeiro</option>
                        <option value="02">Fevereiro</option>
                        <option value="03">Março</option>
                        <option value="04">Abril</option>
                        <option value="05">Maio</option>
                        <option value="06">Junho</option>
                        <option value="07">Julho</option>
                        <option value="08">Agosto</option>
                        <option value="09">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                      </select>
                    </ItemFormFieldSelect>
                  </div>
                  {dueDateMonthError && <CardError>Informe o mês</CardError>}
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <LabelField style={{ visibility: 'hidden' }}>Ano</LabelField>
                    <ItemFormFieldSelect>
                      <select
                        className="form-control"
                        required
                        name="anovencimento"
                        value={anoVencimento}
                        onFocus={this.handleInputFocus}
                        onChange={this.handleChangeAno}
                      >
                        <option value="">Ano</option>
                        {this._gerenateYearPayment()}
                      </select>
                    </ItemFormFieldSelect>
                  </div>
                  {dueDateYearError && <CardError>Informe o ano</CardError>}
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <LabelField>CVV</LabelField>
                    <ItemFormField>
                      <div className="input-group">
                        <InputText
                          type="text"
                          className="form-control"
                          name="cvc"
                          placeholder="CVV"
                          onFocus={this.handleInputFocus}
                          required
                          value={ccv}
                          onChange={evt => {
                            const tempState = {
                              ccv: evt.target.value
                            };
                            if (cvvError) {
                              tempState.cvvError = false;
                            }
                            this.setState(tempState);
                          }}
                          onBlur={event =>
                            this._setDataCardStorage("cvc", event.target.value)
                          }
                          style={{ width: '100%' }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fa fa-question-circle-o" />
                          </span>
                        </div>

                      </div>
                    </ItemFormField>
                    {cvvError && (
                      <p style={{ fontSize: "13px", color: "red" }}>
                        Informe o CVV
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {termId && (
                    <div>
                      <Checkbox
                        id="acceptTerms"
                        onChange={this.acceptTerm}
                        onClick={this.acceptTerm}
                      >
                        <div style={{ marginLeft: "20px", marginTop: "-23px" }}>
                          <span>Aceito os</span>
                          <ButtonLinks type="button" onClick={this.goToTerm}>
                            termos
                          </ButtonLinks>
                          <span>e condições</span>
                        </div>
                      </Checkbox>
                      {isOpeningTerms && <p>Carregando termos...</p>}
                    </div>
                  )}

                  {cupomError && (
                    <div className="row">
                      <div className="col">
                        <p style={{ color: "red" }}>{cupomError}</p>
                      </div>
                    </div>
                  )}
                  {error && <TextError>{error || paymentError}</TextError>}

                  {!showCaptcha && (
                    <div className="row" style={{ margin: "20px 0 " }}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Recaptcha
                          hl="pt"
                          elementID="captcha-cartao"
                          sitekey={RECAPTCHA_KEY}
                          type="none"
                          render="explicit"
                          verifyCallback={this.verifyCallback}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <TrialText plan={this.props.plan} selectedPaymentMethod={this.props.selectedPaymentMethod} openModal={this.openModal} />
            <ModalTermos
              visible={this.state.modalTerms}
              onClose={() => this.setState({ modalTerms: false })}
            />

            <div className="col-md-12">
              <div className="box-buttons">
                {/* <div className="infos" /> */}
                {!hasPaymentError && <button
                  id="btnBack"
                  className="btn btn-lg"
                  type="button"
                  onClick={back}
                >
                  Voltar
                </button>}

                {plan && plan.price <= 0 ? (
                  <>
                    {!showCaptcha ? (
                      <button
                        type="button"
                        disabled={
                          sendPayment || invalidCpf || !isVerified || loading
                        }
                        style={{
                          marginTop: "0px",
                          maxWidth: "200px",
                          paddingRight: "-320px"
                        }}
                        className={`btn btn-blue btn-lg btn-block${(sendPayment ||
                          loading) &&
                          " btn-loading"}`}
                        onClick={this.redirectPi}
                        id="btn-confirm-1"
                      >
                        Confirmar
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled={sendPayment || invalidCpf || loading}
                        style={{
                          marginTop: "0px",
                          maxWidth: "200px",
                          paddingRight: "-320px"
                        }}
                        className={`btn btn-blue btn-lg btn-block${(sendPayment ||
                          loading) &&
                          " btn-loading"}`}
                        onClick={this.redirectPi}
                        id="btn-confirm-1"
                      >
                        Confirmar
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    {!showCaptcha ? (
                      <button
                        disabled={
                          sendPayment || invalidCpf || !isVerified || loading
                        }
                        style={{
                          marginTop: "0px",
                          maxWidth: "200px",
                          marginLeft: "20px"
                        }}
                        className={`btn btn-blue btn-lg btn-block${(sendPayment ||
                          loading) &&
                          " btn-loading"}`}
                        type="button"
                        onClick={this.pay}
                        id="btn-confirm-2"
                      >
                        Confirmar
                      </button>
                    ) : (
                      <button
                        disabled={sendPayment || invalidCpf || loading}
                        style={{
                          marginTop: "0px",
                          maxWidth: "200px",
                          marginLeft: "20px"
                        }}
                        className={`btn btn-blue btn-lg btn-block${(sendPayment ||
                          loading) &&
                          " btn-loading"}`}
                        type="button"
                        onClick={this.pay}
                        id="btn-confirm-2"
                      >
                        Confirmar
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </form>

        <ModalViewTerm
          visible={showModal}
          {...modalData}
          onSetTerm={term => this.setState({ term })}
          onClose={() => this.setState({ showModal: false, paymentError: "" })}
        />
      </div>
    );
  }
}

export default CreditCardPayment;
