import styled from 'styled-components';
import ItemsCarousel from 'react-items-carousel';

export const Container = styled.div`
  background: #f8f8f8;
  height: 100vh;
  padding-bottom: 20px;
  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  padding: 10px;
  max-width: 1300px;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column-reverse;
    padding: 0;
  }
`;

export const Nav = styled.nav`
  background-image: transparent;
`;

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: 300;
  color: #999;
  margin-top: 0px;
  @media(max-width: 710px) {
    font-size: 26px;
    margin-top: 10px;
  }

`;

export const ContainerSlider = styled.div`
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  @media (max-width: 768px) {
    overflow-x: hidden;
  }
`;

export const Cards = styled.div`
  width: 100%;
  height: auto;

  .customCarouselWrapper {
  }

  .customCarouselItemsWrapper {
  }

  .customCarouselItemsInnerWrapper {
    padding: 0 40px;
    padding-bottom: 30px;
    justify-content: ${props => props.centerMode ? "center" : "flex-start"};
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
    }
  }

  .customCarouselItemWrapper {
    margin: 10px 15px 0 0;
    padding: 0;
    width: auto;
    display: flex;
    @media (max-width: 768px) {
      margin: 10px auto;
    }
  }

  .customCarouselRightChev {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .customCarouselLeftChev {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .tooltipClassPayroll {
    width: 270px;
    background: rgba(2, 196, 255, 1);
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  .tooltipClass {
    width: 170px;
    background: rgba(2, 196, 255, 1);
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`;

export const CustomItemsCarousel = styled(ItemsCarousel).attrs({
  classes: {
    wrapper: 'customCarouselWrapper',
    itemsWrapper: 'customCarouselItemsWrapper',
    itemsInnerWrapper: 'customCarouselItemsInnerWrapper',
    itemWrapper: 'customCarouselItemWrapper',
    rightChevronWrapper: 'customCarouselRightChev',
    leftChevronWrapper: 'customCarouselLeftChev'
  }
})`
  display: flex !important;
`;

export const NavLit = styled.nav`
  background-image: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    /* padding: 0; */
    margin: 0;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;
