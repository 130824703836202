import { call, put } from "redux-saga/effects"
import _ from "lodash"
import api, {gateway} from "../../utils/api"
import { UserActions } from "../ducks/user"

export function* getSocialName(privateProfileResponse) {
  try {
    const response = yield call(gateway.get, `/webbff/lit_app_web/person/getSocialNameByEmail?email=${privateProfileResponse.login.username}`);
    return response;
  } catch (err) {
    console.error('Erro ao obter o nome social', err);
    return {}; // ou algum valor padrão
  }
}

export function* getPersonData() {
  try {
    const response = yield call(gateway.get, `/person/person/getPersonData`);
    return response;
  } catch (err) {
    console.error('Erro ao obter dados da pessoa', err);
    return {}; // ou algum valor padrão
  }
}

export function* getProfile(action) {
  try {
    const privateProfileResponse = yield call(gateway.get, `/webbff/lit_app_web/profile/findPrivateProfile/${action.payload.idProfile}`);
    const socialNameResponse = yield call(getSocialName, privateProfileResponse);
    const personDataResponse = yield call(getPersonData);
    const personUrlImage = _.get(personDataResponse, 'personUrlImage', '');
    const linkPhoto = personUrlImage 
    ? `${personUrlImage}?timestamp=${new Date().getTime()}`
    : '';
    const combinedResponse = {
      ...privateProfileResponse,
      ...socialNameResponse,
      linkPhoto
    };
    yield put(UserActions.ProfileActions.getProfileSuccess(combinedResponse));
  } catch (err) {
    yield put(UserActions.ProfileActions.getProfileFailure(err.response))
  }
}

export function* getOffices(action) {
  try {
    const response = yield call(api.get, `findOfficeProfile/${action.payload.idProfile}`)

    yield put(UserActions.OfficesActions.getOfficesSuccess(response))
  } catch (error) {
    yield put(UserActions.OfficesActions.getOfficesFailure(error.response))
  }
}

export function* sendNPS({ payload }) {
  try {
    const data = {
      idProfile: payload.idProfile,
      rating: payload.rating,
    }
    const response = yield call(api.post, "", data)
    console.log(response)
    yield put(UserActions.ProfileActions.sendNPSSuccess())
  } catch (error) {
    if (error.response) {
      yield put(UserActions.ProfileActions.sendNPSFailure(error.response.data.message))
    }
  }
}
