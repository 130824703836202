import React, {useState} from "react";
import _ from "lodash";

import ArvoreDeCursos from "../CourseTree";
import Disciplina from "../Disciplina";
import Unidade from "../Unidade";
import SobreMenuItem from "../SobreMenuItem";
import Conteudo from "../Conteudo";
import ProgressMenuItem from "../ProgressMenuItem";
import AssessmentItem from "../UcAssessmentItem";
import NotaMediaFinal from '../NotaMediaFinal';

const TreeItem = ({
  data,
  openCourseProgress,
  closeCourseProgress,
  level,
  objectsList = [],
  course,
  idCourseParam
}) => {
  
  const [company] = useState(localStorage.getItem('company') || ' ');
  const renderItem = React.useMemo(() => {
    const type = _.get(data, "type", "");
    const tags = _.get(data, "tags", []);
    const children = _.get(data, "children", []);
    const id = _.get(data, "code", "");
    const courseType = _.get(course, 'type');

    if (type === "ABOUT") {
      return (
        <SobreMenuItem
          id={id}
          data={data}
          closeCourseProgress={closeCourseProgress}
          objectsList={objectsList}
          course={course}
          courseType={courseType}
          idCourseParam={idCourseParam}
        />
      );
    }

    if (type === "OBJECT") {
      if (tags.includes("KNOW_MORE") || tags.includes("ABOUT")) {
        return null;
      }
      return (
        <Conteudo
          id={id}
          allObjects={objectsList}
          data={data}
          closeCourseProgress={closeCourseProgress}
        />
      );
    }

    const dataChildren =
      children.length > 0 ? (
        <ArvoreDeCursos
          data={data.contentsAndAssessment || data.children}
          level={level + 1}
          openCourseProgress={openCourseProgress}
          closeCourseProgress={closeCourseProgress}
          objectsList={objectsList}
          course={course}
        />
      ) : null;

    if (type === "COURSE") {
      return (
        <Disciplina 
          id={id} 
          data={data} 
          isTrail={courseType === "TRAIL"}
          first={data.index === 0}>
            
          {company.toUpperCase() !== 'ANGLO' &&
            (data.gradeAverage !== null || data.courseCertificationIsProgress) && (
              <NotaMediaFinal
                course={data}
                fontSize={18}
              />
            )}

          {dataChildren}

          {data.type === "COURSE" && !data.courseCertificationIsProgress && (
            <ProgressMenuItem
              data={data}
              openCourseProgress={openCourseProgress}
              closeCourseProgress={closeCourseProgress}
            />
          )}
        </Disciplina>
      );
    }

    if (type === "UC") {
      return (
        <Unidade 
          id={id} 
          data={data} 
          first={data.index === 0}
          courseType={courseType}>
          {dataChildren}
        </Unidade>
      );
    }

  
    if(type === "FORUM" || type === "EXAM") {
      return <AssessmentItem data={data} />;
    }

    return null;
  }, []);
  

  return renderItem;
};

export default TreeItem;
