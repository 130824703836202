import { Types } from './types';

/**
 * Actions
 */
export const Creators = {
  setCouponData: data => ({
    type: Types.SET_COUPON_DATA,
    payload: { data }
  })
};
