import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 30px 0 30px;
  padding-top: 10px;
  .parent {
    width: 85%;
    position: relative;
    overflow-x: hidden;
    margin: auto;
  }

  @media(max-width: 710px) {
    flex-direction: column;
    .parent {
      width: 100%;
    }
  }

  .slider-container {
  /* if you want to have a scrollbar, you can add overflow here */ 
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem 2rem;
    padding: 3px;
    scroll-behavior: smooth;
  }

  .slider-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .btn {
    position: absolute;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn svg {
    font-size: 30px;
    color: #27aae1;
  }

  .prev {
    left: 0rem;
  }
  .next {
    right: 0rem;
  }
  .disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  .child {
    padding: 5px 20px;
    background: #59eb97;
    text-align: center;
    width: 100%;
    margin-right: 10px;
    border-radius: 21px;
  }
`

export const Content = styled.div`
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  @media(max-width: 710px) {
    flex-direction: column;
    .parent {
      width: 100%;
    }
  }
`

export const BoxTimerWithNumberQuestions = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.allowassessmentresponse ? 'auto' : '100%'};
  @media(max-width: 710px) {
    padding: 10px 45px;
  }
`

const Box = styled.div`
  padding: 4px;
  text-align: center;
  background-color: #27aae1;
  font-size: 20px;
  color: #fff;
  width: ${props => props.allowassessmentresponse ? '180px' : '100%'};
  
  & + div {
    margin-left: 10px;
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

export const TextTimer = styled(Box)`
  
`

export const NumberOfQuestions = styled(Box)`
  span {
    color: yellow;
    font-weight: bold;
    font-size: 16px;
  }
`

export const Button = styled.div`
  
  padding: 12px 35px;
  border: 1px solid ${props => props.current ? '#27aae1' : 'transparent'};
  box-sizing: border-box;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
  border-radius: 5px;
  position: relative;
  & + button {
    border-top: 0;
  }
 
  &:disabled {
    cursor: not-allowed;
  }

  background: ${props => {
    if(props.current) {
      return '#fff';
    }

    if(props.emptySurvey) {
      return '#e03b38';
    }

    if(props.status) {
      return '#27aae1';
    }
   
    return '#E0E0E0';
  }};

  color: ${props => {
    if(props.current) {
      return '#27aae1'
    }

    if(props.status) {
      return '#fff';
    }

    return ''
  }};

  svg.status-icon {
    position: absolute;
    top: -4px;
    right: -8px;
    padding: 0;
    margin: 0;
    z-index: 99999;
 
    margin: 0;
    padding: 0;
    /* font-size: 22px; */

    background: #fff;
    clip-path: circle(48%);
    color: ${props => {
      if(props.status.toUpperCase() === 'CORRECT') {
        return '#6ac259 !important'
      }

      if(props.status.toUpperCase() === 'PARTIALLY_CORRECT') {
        return '#efe835 !important'
      }

      if(props.status.toUpperCase() === 'INCORRECT') {
        return '#e03b38 !important'
      }
      return ''
    }}
  }
  
  svg.feedback {
    position: absolute;
    bottom: 2px;
    right: 4px;
    color: ${props => props.current ? '#27aae1' : '#fff'};
  }
  
`

