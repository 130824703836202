import React from "react";
import { SIZE } from "../../../constants";
import Text from "../Text";
import { Tag } from "./styles";

export default ({ text, color, position }) => {
  return (
    <>
      <Tag color={color} position={position}>
        <Text text={text} size={SIZE.SMALL} />
      </Tag>
    </>
  );
};
