/* eslint-disable camelcase */
import React from 'react'
import {FaPencilAlt, FaTrashAlt} from 'react-icons/fa'
import ReactHover from 'react-hover'

import Info from './Info'

import {Container, EditButton, Summary, BoxActions, RemoveButton, TextError} from './styles'

const optionsCursorTrueWithMargin = {
  followCursor: true,
  shiftX: 20,
  shiftY: -90
}

export default function AcademicExperienceItem({item, selectItem, removeItem, error}) {
  const {
    wx_institution, 
    wx_course,
  } = item
  // 
  return (
    <Container>
      <ReactHover
        options={optionsCursorTrueWithMargin}
      >
        <ReactHover.Trigger type='trigger'>
          <Summary onClick={selectItem}>
            {wx_institution}, {wx_course}
            {error && <TextError>{error}</TextError>}
          </Summary>
        </ReactHover.Trigger>
        <ReactHover.Hover type='hover'>
          <Info {...item} />
        </ReactHover.Hover>
      </ReactHover>
     
      <BoxActions>
        <EditButton id="academic-edit-button" onClick={selectItem}>
          <FaPencilAlt />
        </EditButton>
        <RemoveButton id="acdemic-remove-button" onClick={removeItem}>
          <FaTrashAlt />
        </RemoveButton>
      </BoxActions>
    </Container>
  )
}