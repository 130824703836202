import React from "react";
import { APPEARANCE, ACTION, PAGE_INFORMATION } from "../../../constants";
import Modal from "../../Modals/GenericModal";
import MembersModal from "../../Modals/MembersModal";
import ButtonModal from "../../basicComponents/Button";

export default ({ pageImage, members, group, member, callback }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <ButtonModal
        appearance={APPEARANCE.MODAL}
        onClick={openModal}
        text={ACTION.VIEW_ALL}
      />
      <Modal
        textHeader={PAGE_INFORMATION.GROUP_MEMBERS}
        isOpen={modalIsOpen}
        closeModal={closeModal}
        pageImage={pageImage}
      >
        <MembersModal
          callback={callback}
          pageImage={pageImage}
          group={group}
          members={members}
          member={member}
        />
      </Modal>
    </>
  );
};
