import React, { Component, Fragment } from "react"
import "moment/locale/pt-br"

class ContainerCollapse extends Component {

  constructor(props) {
    super(props)
    this.state = {
      visibleBody: false,
      title: props.title,
      emptyTitle: props.emptyTitle,
      content: props.content,
    }
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody, title, emptyTitle, content } = this.state

    return (
      <div className="cursos bg-white-perfil" id="cargo-usuario">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12" style={{ display: "flex", justifyContent: "space-between" }}>
              <h5
                className="d-inline-block"
                style={{ fontSize: "1.5em" }}
                data-tip="Adicione informações sobre a sua formação acadêmica"
              >
                {title}
              </h5>
              <button type="button" className="btn btn-icon" onClick={this.changeVisible}>
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                    <i className="fa fa-chevron-down" aria-hidden="true" />
                  )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <Fragment>
              <hr style={{ marginTop: "0.5em" }} />
              <div className="row">
                <div className="col">
                  {!content ? (
                    <p>{emptyTitle}</p>
                  ) : (
                      <>{content}</>
                    )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default ContainerCollapse
