/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import { FaAngleDown, FaAngleUp, FaRegMoneyBillAlt } from "react-icons/fa";

import history from "../../../utils/history";
import { gateway } from "../../../utils/api";

import Progress from "../../shared/Progress";

import { Button, ButtonCollapse, BoxInfo, Info } from "./styles";

const mappedStatus = {
  OPEN: "Em aberto",
  SUCCESS: "Pago",
  CANCELED: "Cancelado",
  FAILED: "Falhou"
};

class HistoryItem extends Component {
  constructor(props) {
    super(props);

    const { historyItem, activeItemIndex } = props;
    const transactions = historyItem.transactions || [];

    const firstOpenTransactionIndex = transactions.findIndex(
      transaction => transaction.status === "OPEN"
    );

    this.state = {
      firstOpenTransactionIndex,
      open: props.index === activeItemIndex
    };
  }

  formatedNumber = value => {
    const number = Number(value);
    if (number <= 9) {
      return `0${number}`;
    }
    return number;
  };

  handleGenerateAndViewInvoice = async transaction => {
    if (transaction.invoiceData) {
      history.push("invoicePaymentView", {
        nextPageParams: {
          invoiceData: transaction.invoiceData
        }
      });
      return;
    }

    try {
      const response = await gateway.post(
        "/webbff/lit_app_web/payment/invoice/generate",
        {
          transactionCode: transaction.code
        }
      );

      history.push("invoicePaymentView", {
        nextPageParams: {
          invoiceData: response
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleClick = index => {
    const { open } = this.state;
    const { onSelectItem } = this.props;
    onSelectItem(index);
    this.setState({ open: !open });
  };

  render() {
    const { index, historyItem } = this.props;
    const { firstOpenTransactionIndex, open } = this.state;
    const percent =
      (historyItem.numberOfPaidTransactions /
        historyItem.numberOfTransactions) *
      100;
    return (
      <div key={`card-${index}`}>
        <div className="card-header" id={`heading${index}`}>
          <h5 className="mb-0">
            <ButtonCollapse
              onClick={() => this.handleClick(index)}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="button"
              className="btn"
              data-toggle="collapse"
              data-target={`#collapse${index}`}
              aria-expanded="true"
              aria-controls={`collapse${index}`}
            >
              <BoxInfo>
                <Info>
                  <span>
                    {String(historyItem.offerName).toUpperCase()} - {historyItem.offerValue}
                  </span>
                  <span id="parcelas">
                    {`${this.formatedNumber(
                      historyItem.numberOfPaidTransactions
                    )}/${this.formatedNumber(
                      historyItem.numberOfTransactions
                    )}`}
                  </span>
                </Info>
                <Progress
                  progress={percent}
                  height="8px"
                  width="100%"
                  showProgress={false}
                />
              </BoxInfo>
              {open && <FaAngleUp />}
              {!open && <FaAngleDown />}
            </ButtonCollapse>
          </h5>
        </div>

        <div
          id={`collapse${index}`}
          className={`collapse ${index + 1 === 1 && "show"}`}
          aria-labelledby={`heading${index}`}
          data-parent="#accordion"
        >
          <div className="card-body">
            <h5>Todas as parcelas</h5>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {historyItem.transactions &&
                    historyItem.transactions.map((transaction, i) => (
                      <tr key={`list-${i}`}>
                        <td>
                          {i + 1 === 1 ? "ENTRADA" : this.formatedNumber(i + 1)}
                        </td>
                        <td>VENCIMENTO &nbsp; {transaction.dueDate}</td>
                        <td>
                          STATUS &nbsp; {mappedStatus[transaction.status]}
                        </td>
                        <td>VALOR TOTAL &nbsp; {transaction.value}</td>

                        <td>
                          {firstOpenTransactionIndex === i &&
                            transaction.status == "OPEN" && (
                              <Button
                                onClick={() =>
                                  this.handleGenerateAndViewInvoice(transaction)
                                }
                              >
                                <span>
                                  {transaction.invoiceData
                                    ? "Visualizar Boleto"
                                    : "Emitir Boleto"}
                                </span>
                              </Button>
                            )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HistoryItem;
