/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import * as Styles from './styles';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevDisable: true,
      nextDisable: !!(
        this.refs && this.refs.offsetWidth >= this.refs.scrollWidth
      ),
    };
  }

  componentDidMount() {
    this.checkButtons(this.refs.offsetWidth, this.refs.scrollWidth);
  }

  handleNext = () => {
    this.setState({ prevDisable: false });
    const offsetWidthValue = this.refs.offsetWidth;
    const scrollWidthValue = this.refs.scrollWidth;

    this.refs.scrollLeft += offsetWidthValue / 2;
    this.checkButtons(offsetWidthValue, scrollWidthValue);

    const { paginationHook } = this.props;
    const hasPagination = typeof paginationHook === 'function';
    if (!hasPagination) return;
    // These calculations are made to guarantee that the user is near the edge of the carousel.

    paginationHook();
  };

  checkButtons = (offsetWidthValue, scrollWidthValue) => {
    const { prevDisable } = this.state;
    if (!prevDisable && this.refs.scrollLeft <= 0) {
      this.setState({ prevDisable: true });
    }
    this.setState({
      nextDisable: this.refs.scrollLeft + offsetWidthValue >= scrollWidthValue,
    });
  };

  render() {
    const offsetWidthValue = this.refs.offsetWidth;

    const scrollWidthValue = this.refs.scrollWidth;
    const { prevDisable, nextDisable } = this.state;
    const { children, fullButton = false } = this.props;
    
    return (
      <Styles.Container fullButton={fullButton}>
        <div
          className="slider-container"
          ref={el => {
            this.refs = el;
          }}
        >
          <div className="slider-wrapper">{children}</div>
          <div
            className="btn prev"
            onClick={() => {
              this.refs.scrollLeft -= offsetWidthValue / 2;
              this.checkButtons(offsetWidthValue, scrollWidthValue);
            }}
          >
            <MdChevronLeft />
          </div>

          <div className="btn next" onClick={this.handleNext}>
            <MdChevronRight />
          </div>
        </div>
      </Styles.Container>
    );
  }
}

export default Slider;
