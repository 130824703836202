import React from 'react'

const TabelaConsulta = props => (
	<div style={{ width: '20%', display: 'inline-block', margin: 20 }}>
		<h4 style={{ textAlign: 'center' }}>{props.label}</h4>
		<table className="table table-striped">
			<thead className="tableHead-voucherTable">
				<tr>
					<th className="timeline-center">Id Login</th>
				</tr>
			</thead>
			<tbody className="tableBody-voucherTable">
				{props.list.map((item, index) => (
					<tr key={index}>
						<td className="timeline-center voucher-table-item">{item}</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
)

export default TabelaConsulta
