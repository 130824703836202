import styled from 'styled-components'

export const Container = styled.div`
  background: white;
  padding: 1em 2em;
  max-width: 550px;
  margin: 20px auto;
  border-radius: 12px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    color: #000 !important;
    font-size: 16px !important;
    margin: 0 !important;
  }

  .first {
    margin-bottom: 20px !important;
  }

  .box-buttons {
    padding-bottom: 30px !important;
    border-bottom: 1px solid #ccc;
  }

  #cpf {
    padding: 8px 12px !important;
    border: 1px solid #27AAE1 !important;
  }

  #country-selector {
    border-color: #27AAE1 !important;
  }

  #list {
    padding: 5x 15px !important;
  }

  .option-list {
    span {
      display: inline-block;
      margin-right: 5px;
    }
  }
`
