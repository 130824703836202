/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from "react"

import ModalRecibo from "./ModalRecibo"
import ModalViewTerm from "../../Modals/ModalViewTerm"

export default class ListaRecibo extends Component {
  state = {
    modalOpen: false,
    showModalTerm: false,
    modalData: null,
    recibo: [],
  }

  toggleModal = () => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen })
  }

  closeModalTerm = () => {
    this.setState({ showModalTerm: false, modalData: null })
  }

  abrirRecibo = payment => {
    const { userData, shopper } = this.props
    this.setState({
      recibo: {
        ...userData,
        ...shopper, payment
      }
    })
    this.toggleModal()
  }

  openTerm = payment => {
    if (payment.term) {
      const modalData = {
        termType: 'PLAN',
        termId: payment.term.rk_term
      }
      this.setState({ showModalTerm: true, modalData })
    }
  }

  render() {
    const { payments } = this.props
    const { modalOpen, recibo, showModalTerm, modalData } = this.state
    return (
      <Fragment>
        <table className="table table-borderless table-sm">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Período</th>
              <th scope="col">Descrição</th>
              <th scope="col">Total</th>
              <th scope="col"> </th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={index}>
                <td>{payment.reference}</td>
                <td>{`${payment.start} - ${payment.end}`}</td>
                <td>{payment.autorization}</td>
                <td>{payment.value}</td>
                <td>
                  {payment.formOfPayment !== "FOLHA" && (
                    <i
                      style={{ color: "#27aae1", cursor: "pointer" }}
                      onClick={() => this.abrirRecibo(payment)}
                      className="fa fa-print"
                      aria-hidden="true"
                    />
                  )}
                  {payment.formOfPayment === "FOLHA" && (
                    <i
                      style={{ color: "#27aae1", cursor: "pointer" }}
                      onClick={() => this.openTerm(payment)}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ModalViewTerm
          visible={showModalTerm}
          {...modalData}
          onClose={this.closeModalTerm}
        />
        <ModalRecibo modalOpen={modalOpen} modalClose={this.toggleModal} recibo={recibo} />
      </Fragment>
    )
  }
}
