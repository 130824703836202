import React from "react";
import PropTypes from "prop-types";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../constants";

/**
 * @param {Boolean} hasActionTop //check if there is action at the top
 * @param {Component} topRight //set of items to be displayed at the top of the header - right position
 * @param {Component} bottomRight //set of items to be displayed at the bottom of the header- right position
 * @param {Component} topLeft //set of items to be displayed at the top of the header - left position
 * @param {String} imagePage //path (url) of the image displayed in top bar
 * @param {String} logoPage //path (url) of the logo displayed in top bar
 * @param {String} textHeader//title displayed on the site top - first line
 * @param {String} textDescription //title displayed on the site top - second line
 * @param {String} textInformation //title displayed on the site top - third line
 * @param {Component} bottomLeft //set of items to be displayed at the bottom of the header- left position
 * @param {Component} topMenu //menu displayed at the top of the site
 * @param {Boolean} openSearch //defines whether the search option is active
 * @param {Boolean} setOpenSearch //defines whether the search option is displayed
 * @param {String} size //sets the size of the top image
 * @param {String} appearance //{SMALL, MEDIUM, LARGE, MEDIUM_LARGE} sets the size of the top bar
**/

export default ({
  topRight = DEFAULT_VALUES.COMPONENT,
  bottomRight = DEFAULT_VALUES.COMPONENT,
  topLeft = DEFAULT_VALUES.COMPONENT,
  imagePage = DEFAULT_VALUES.STRING,
  logoPage = DEFAULT_VALUES.STRING,
  textHeader = DEFAULT_VALUES.STRING,
  textDescription = DEFAULT_VALUES.STRING,
  textInformation = DEFAULT_VALUES.STRING,
  bottomLeft = DEFAULT_VALUES.COMPONENT,
  topMenu = DEFAULT_VALUES.COMPONENT,
  openSearch = DEFAULT_VALUES.BOOLEAN,
  setOpenSearch = DEFAULT_VALUES.BOOLEAN,
  size = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING,
  hasActionTop
}) => {
  return (
    <>
      <Screen
        hasActionTop={hasActionTop}
        textHeader={textHeader}
        textInformation={textInformation}
        textDescription={textDescription}
        imagePage={imagePage}
        topRight={topRight}
        bottomRight={bottomRight}
        topLeft={topLeft}
        bottomLeft={bottomLeft}
        logoPage={logoPage}
        topMenu={topMenu}
        size={size}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        appearance={appearance}
      />
    </>
  );
};

Screen.propTypes = {
  turma: PropTypes.object.isRequired,
  hasActionTop: PropTypes.bool
};

Screen.defaultProps = {
  hasActionTop: true
}
