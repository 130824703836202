import React from "react";
import { APPEARANCE, SIZE, ORIENTATION, BORDER_TYPE } from "../../../constants";
import Button from "../Button";
import { ActionLine } from "./styles";

export default ({ onConfirm, text }) => {
  return (
    <>
      <ActionLine>
        <Button
          appearance={APPEARANCE.MODAL_CONFIRM}
          size={SIZE.MEDIUM}
          orientation={ORIENTATION.FULL_WIDTH}
          text={text}
          border={BORDER_TYPE.NO_BORDER}
          onClick={() => {
            onConfirm();
          }}
        />
      </ActionLine>
    </>
  );
};
