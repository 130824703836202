import React, { useEffect, useCallback, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import { useDispatch } from "react-redux";
import { toastErrorAndWarningOptions } from '../../utils/functions';
import { gateway } from '../../utils/api';
import Loading from '../Loading/Loading';
import history from '../../utils/history';
import { LoadingContainer } from './styles';
import NavBarContext from '../../context/navBar/NavBarContext';

import { Creators as ProfileActions } from '../../store/ducks/user/profile/actions';

const WHITELIST_USERS_TO_CREATE_PASSWORD_MOBILE = [
  "testelit3@teste.com.br",
  "daniel.castro@brb.com.br",
  "alexey.silva@brb.com.br",
  "samuel.pereira@brb.com.br",
  "danielle.delmasso@brb.com.br",
  "hildene.sousa@brb.com.br",
  "iasminy.oliveira@brb.com.br",
  "camila.pereira@brb.com.br",
  "livia.guerra@brb.com.br",
  "renata.czykiel@brb.com.br",
  "alexandre.santos@brb.com.br"
];
function LtiExample(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { openModalRegisterMobilePassword } = useContext(NavBarContext);

  const { search } = props.location;
  const token = new URLSearchParams(search).get('token');
  
  const dispatch = useDispatch()

  const authenticateSuccess = responseSuccess => {
    const {
      activeContract,
      onboardIncomplete,
      idProfile,
      email,
      idLogin,
      groupMember,
      ownerGroup,
      countDown,
      voucherAtivo,
      certificates,
      firstLogin,
      litpass,
      onBoardIncomplete,
      firstName,
      lastName,
      idRA,
      freeUser,
      libraryUser,
      socialUser,
      completedPersonality,
      messagesCount,
      showPaul,
      paymentInfo,
      mensagemErroPagamento,
      hasCPF,
      hasPasswordMobile = false,
      courseCode
    } = responseSuccess;

    localStorage.setItem('ra', email);
    localStorage.setItem('idOwner', ownerGroup);
    localStorage.setItem('idMember', groupMember);
    localStorage.setItem('idLogin', idLogin);
    localStorage.setItem('idProfile', idProfile);
    localStorage.setItem('hasCPF', hasCPF);
    
    dispatch(ProfileActions.getProfileRequest(idProfile))
    
    if (countDown && Object.keys(countDown).length > 0) {
      localStorage.setItem('countDown', JSON.stringify(countDown));
    }

    if (certificates && !firstLogin && !voucherAtivo) {
      localStorage.setItem('certificates', certificates);
    }

    localStorage.setItem('activeContract', activeContract);
    localStorage.setItem('litpass', litpass);
    localStorage.setItem('finishOnBoarding', !onBoardIncomplete);
    localStorage.setItem('57e0854551b1dca003c67c384c37a346', false);
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('idRA', idRA);
    localStorage.setItem('voucherAtivo', voucherAtivo);
    localStorage.setItem('freeUser', freeUser);
    localStorage.setItem('libraryUser', libraryUser);
    localStorage.setItem('socialUser', socialUser);
    localStorage.setItem('firstLogin', firstLogin);
    localStorage.setItem('completedPersonality', completedPersonality);
    localStorage.setItem('modalNotas', messagesCount > 0);
    localStorage.setItem('showPaul', showPaul);
    localStorage.setItem(
      'dateLogin',
      `${new Date().getDate()}/${new Date().getMonth()}`
    );
    
    if(WHITELIST_USERS_TO_CREATE_PASSWORD_MOBILE.some(item => item === email)) {
      localStorage.setItem('hasPasswordMobile', JSON.stringify(hasPasswordMobile));

      if(!hasPasswordMobile) {
        openModalRegisterMobilePassword()
      }
    }
    
    if (paymentInfo.valorDesconto) {
      localStorage.setItem('paymentInfo', JSON.stringify(paymentInfo));
    }
    if (mensagemErroPagamento !== null) {
      localStorage.setItem('57e0854551b1dca003c67c384c37a346', true);
    }

    if (courseCode !== null) {
      localStorage.setItem('courseCodeLTI', courseCode);
    }

    if (!hasCPF) {
      history.push('/consultaCpf');
    } else if (!activeContract) {
      history.push('/payment');
    } else if(courseCode) {
      history.push(`/curso-novo/${courseCode}`);
    } else if (onboardIncomplete) {
      history.push('/onboarding');
    } else {
      history.push('/home');
    } 
  };

  const notifyError = (text, duration = 5000) => {
    toast.error(text, {
      ...toastErrorAndWarningOptions,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration
    });
  };

  const authenticate = useCallback(async token => {
    const url = `/gateway/lti/authenticateLti`;
    try {
      const response = await gateway.post(url, { token });
      setIsLoading(false);
      authenticateSuccess({
        ...response, 
        courseCode: jwt_decode(token).courseCode
      });
    } catch (error) {
      setIsLoading(false);
      notifyError('Erro inesperado. Contate o administrador do sistema.');
    }
  }, []);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      authenticate(token);
    }
  }, [token, authenticate]);

  return (
    <>
      <LoadingContainer>
        {isLoading && <Loading width="30" message="Preparando ambiente..." />}
      </LoadingContainer>
    </>
  );
}

export default LtiExample;
