import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import LaneCourses from '../../../../Home/LaneCourses';
import { ModalApresentacaoCurso } from '../ModalPresentationCourse';
import CardMyCourse from '../../../../Cursos/CardMyCourse';

function CourseRequirements({ requirements }) {

    const [prerequirements, setPrerequirements] = useState([]);
    const [postrequirements, setPostrequirements] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [courseData, setCourseData] = useState(null);

    const openModalCourse = (courseCrCode, lockedCourse) => {
        setCourseData({
            courseCrCode,
            lockedCourse
        })
        setOpenModal(true)
    }

    useEffect(() => {

        const pre = _.get(requirements, 'prerequirements', [])
        const pos = _.get(requirements, 'postrequirements', [])
        
        const preItems = pre.map(item => {
            return {
                ...item,
                curso: item.name,
                idCourse: item.code,
                courseImageUrl: item.cover,
            }
        });
        
        const posItems = pos.map(item => {
            return {
                ...item,
                curso: item.name,
                idCourse: item.code,
                courseImageUrl: item.cover,
            }
        });

        setPrerequirements(preItems)
        setPostrequirements(posItems)
    }, [requirements]);

    
    return <>
    {prerequirements && prerequirements.length > 0 && (<div className='row mt-5'>
        <div className='col pl-0'><h3>Pré-requisitos</h3></div>
    </div>)}
    {prerequirements && prerequirements.length > 0 && (<div className="row">
        {prerequirements.map(course => <CardMyCourse
                key={`meu-curso-${course.idCourse}`}
                id={course.idCourse}
                nome={course.curso}
                course={course}
                fromPresentationCourse
                progress={course.progress}
                handlePress={() => openModalCourse(course.idCourse, course.lockedCourse)}
                />)}
    </div>)}
    {postrequirements && postrequirements.length > 0 && (<div className='row'>
        <div className='col pl-0'><h3>Continue sua jornada com estes cursos</h3></div>
    </div>)}
    {postrequirements && postrequirements.length > 0 && (<div className="row mb-4">
    {postrequirements.map(course => <CardMyCourse
                key={`meu-curso-${course.idCourse}`}
                id={course.idCourse}
                nome={course.curso}
                course={course}
                fromPresentationCourse
                progress={course.progress}
                handlePress={() => openModalCourse(course.idCourse, course.lockedCourse)}
                lockedCourse={course.lockedCourse}
                />)}
    </div>)}
    <ModalApresentacaoCurso visible={openModal} courseData={courseData} onClose={() => setOpenModal(!openModal)} />
    </>;
}

export default CourseRequirements;