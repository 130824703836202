import React from "react";
import PropTypes from "prop-types";
import * as Styles from "./styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Styles.Panel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Styles.Panel>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

TabPanel.defaultProps = {
  children: null
};

export default TabPanel;
