import { useEffect } from "react";
import { gateway } from "../../../utils/api";

export default function OAuthWebex({ location }) {
  useEffect(() => {
    async function getWebexAccessToken() {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");

      const url = `webbff/lit_app_web/login/oauth?authServer=${"webex"}&code=${code}`;
      const { accessToken } = await gateway.post(url);

      localStorage.setItem("webex_access_token", accessToken);

      window.location.replace(`${window.location.origin}/webex/meeting`);
    }
    getWebexAccessToken();
  }, []);
  return null;
}
