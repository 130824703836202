import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import RequiredSpan from '../RequiredSpan';

const valuesOptions = [
  { value: 'Estou sem tempo para me dedicar' },
  { value: 'Não encontrei o curso que quero fazer' },
  { value: 'Não gostei do conteúdo disponível' },
  { value: 'Me cadastrei por curiosidade' },
  { value: 'Achei difícil usar' }
];

class ModalCancelamento extends Component {
  state = {
    reason: '',
    reasonDescription: '',
    loading: false,
    evaluate: null
  };

  changeReason = event => {
    this.setState({ reason: event.target.value });
  };

  changeEvaluate = event => {
    this.setState({ evaluate: parseInt(event.target.value, 10) });
  };

  sendReasons = async () => {
    this.setState({ loading: true });

    const { reason, reasonDescription, evaluate } = this.state;
    const { onClose, onSave, idToCancel } = this.props;
    const data = {
      idProfile: parseInt(localStorage.getItem('idProfile'), 10),
      reasonChecked: reason,
      anotherReason: reasonDescription,
      date: new Date().toISOString(),
      evaluation: evaluate,
      idSubscription: idToCancel
    };
    try {
      onClose();
      onSave(data);
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { openModal, onClose } = this.props;
    const { reason, reasonDescription, loading, evaluate } = this.state;
    const name = `${localStorage.getItem('firstName')} ${localStorage.getItem(
      'lastName'
    )}`;

    return (
      <Modal
        open={openModal}
        onClose={onClose}
        classNames={{ modal: 'lit-modal-especialidade' }}
        closeOnOverlayClick={false}
      >
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h5 className="modal-title">Feedback de Cancelamento</h5>
          </div>
          <div className="modal-body">
            <h4>Olá, {name}</h4>
            <p>
              Ficamos tristes em saber que gostaria de cancelar sua assinatura
              do LIT. Por esse motivo, gostaríamos de saber o que levou você a
              cancelar sua assinatura. O seu feedback é fundamental para que o
              LIT se desenvolva cada vez mais.
              <span className="font-weight-bold">
                {' '}
                Caso você seja aluno ou aluna Saint Paul e queira cancelar seu
                curso, o pedido deve ser feito exclusivamente pelo{' '}
                <a href="mailto:sac@saintpaul.com.br" className="text-primary">
                  sac@saintpaul.com.br
                </a>
                . Neste local, o cancelamento é exclusivo para alunos LIT, onde
                será cancelada a próxima cobrança recorrente.
              </span>
            </p>
            <fieldset>
              <p className="col-form-label pt-0">
                Por que você está cancelando sua assinatura? <RequiredSpan />
              </p>
              <ul className="pt-0">
                {valuesOptions.map(item => (
                  <li key={item.value}>
                    <label>
                      <input
                        className="form-check-input"
                        style={{ marginLeft: 0 }}
                        type="radio"
                        value={item.value}
                        checked={reason === item.value}
                        onChange={this.changeReason}
                      />{' '}
                      {item.value}
                    </label>
                  </li>
                ))}
              </ul>
            </fieldset>
            <fieldset>
              <p className="col-form-label pt-0">
                Poderíamos tentar pensar em outras razões pelas quais você está
                cancelando sua assinatura, mas preferimos saber a sua. Nos conte
                um pouco mais sobre sua experiência: <RequiredSpan />
              </p>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder=""
                  rows="6"
                  value={reasonDescription}
                  onChange={evt =>
                    this.setState({ reasonDescription: evt.target.value })
                  }
                />
              </div>
            </fieldset>
            <fieldset>
              <p className="col-form-label pt-0">
                Avalie de 0 a 10 o quanto você recomendaria o lit para outras
                pessoas: <RequiredSpan />
              </p>
              <div className="form-group mb-0">
                <ul className="pt-0">
                  {Array(11)
                    .fill()
                    .map((item, index) => {
                      return (
                        <li
                          style={{ display: 'inline-block', marginRight: 10 }}
                          key={index}
                        >
                          <label>
                            <input
                              className="form-check-input"
                              style={{ margin: 0 }}
                              type="radio"
                              value={index}
                              checked={evaluate === index}
                              onChange={this.changeEvaluate}
                            />{' '}
                            {index}
                          </label>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </fieldset>
            <p>
              Mas, se você mudou de ideia e quer continuar sua jornada de
              aprendizagem, será um prazer contar com sua presença novamente.
              Quando estiver pronto, basta, reativar sua assinatura.
            </p>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-blue btn-modal__approve"
              type="button"
              onClick={onClose}
            >
              Continuar assinatura
            </button>
            <button
              type="button"
              disabled={
                reason === '' ||
                reasonDescription === '' ||
                evaluate === null ||
                loading
              }
              className={`btn btn-modal__cancel ${loading && ' btn-loading'}`}
              onClick={this.sendReasons}
            >
              Cancelar assinatura
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalCancelamento;
