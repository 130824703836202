import { Types } from "./types"

/**
 * Actions
 */

export const Creators = {
  getNotificationRequest: locale => ({
    type: Types.GET_NOTIFICATION_REQUEST,
    payload: { locale },
  }),
  getNotificationSuccess: data => ({
    type: Types.GET_NOTIFICATION_SUCCESS,
    payload: { data },
  }),
  getNotificationFailure: error => ({
    type: Types.GET_NOTIFICATION_FAILURE,
    payload: { error },
  }),
  countNotificationsNotReadRequest: () => ({
    type: Types.GET_COUNT_NOTIFICATIONS_REQUEST,
    payload: {},
  }),
  countNotificationsNotReadSuccess: qtdNotRead => ({
    type: Types.GET_COUNT_NOTIFICATIONS_SUCCESS,
    payload: { qtdNotRead },
  }),
  countNotificationsNotReadFailure: error => ({
    type: Types.GET_COUNT_NOTIFICATIONS_FAILURE,
    payload: { error },
  }),
}
