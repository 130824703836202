import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import RetornoLogin from "./RetornoLogin"
import { gateway } from "../../utils/api"

import {generateSecurePassword} from '../../utils/functions';
import { LitButton } from "../shared/LitButton"

class NovaSenha extends PureComponent {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    code: "",
    password: "",
    confirmPassword: "",
    error: "",
    isMobilePassword: null
  }

  componentDidMount() {
    const { router } = this.context
    const { pathname = '' } = router.history.location
    if(pathname.includes('cadastrar-senha')) {
      this.setState({ isMobilePassword: true })
    }
    const queryParams = new URLSearchParams(router.history.location.search);
    const code = queryParams.get('code') || '';
    console.log(code)
    this.setState({ code });
  }

  novaSenha = async evt => {
    evt.preventDefault()
    const { code, password, isMobilePassword } = this.state
    const { router } = this.context

    const body = {
      code: code.trim(),
      password: generateSecurePassword(password),
    }

    if(isMobilePassword) {
      body.isMobilePassword = isMobilePassword
    }
    
    try {
      const response = await gateway.post("/webbff/lit_app_web/login/createEmailPassword", body)
      this.notify(response.message, "success")
      router.history.push('/')
    } catch (error) {
      console.log(error)
      this.setState({ error: error.response.data.message })
    }
  }

  notify = (text, type = "success") => {
    toast(text, {
      type,
      closeButton: false,
    })
  }

  validatePassword = () => {
    const { password, confirmPassword } = this.state
    if((password || confirmPassword) && password !== confirmPassword) {
      return false
    }
    return true
  }

  render() {
    const { code, password, confirmPassword, error } = this.state
    return (
      <React.Fragment>
        <form style={{ marginTop: "2em" }} onSubmit={this.novaSenha}>
          <div className="field-float-label-pattern">
            <div>
              <input
                placeholder="Código enviado no email"
                className="form-control input-not-yellow"
                id="inputCode"
                value={code}
                onChange={evt => this.setState({ code: evt.target.value })}
                required
              />
              <label htmlFor="inputCode">Código</label>
            </div>
            <div>
              <input
                type="password"
                placeholder="Nova senha"
                className="form-control input-not-yellow"
                id="inputPassword"
                value={password}
                onChange={evt => this.setState({ password: evt.target.value })}
                required
              />
              <label htmlFor="inputPassword">Nova senha</label>
            </div>
            <div>
              <input
                type="password"
                placeholder="Confirmar nova senha"
                className="form-control input-not-yellow"
                id="inputConfirmPassword"
                value={confirmPassword}
                onChange={evt => this.setState({ confirmPassword: evt.target.value })}
                required
              />
              <label htmlFor="inputConfirmPassword">Nova senha</label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {error && (<p style={{ color: "#e50000" }}>{error}</p>)}
              {!this.validatePassword() ? (
                <p style={{ color: "#e50000" }}>As senhas não coincidem</p>
              ) : null}
            </div>
          </div>

          <div className="row justify-content-center">
            <LitButton style={{ width: "150px" }} type="submit" disabled={!this.validatePassword()}>
              ENVIAR
            </LitButton>
          </div>
        </form>
        <RetornoLogin />
      </React.Fragment>
    )
  }
}

export default NovaSenha
