import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} size //{SMALL, MEDIUM, LARGE, MEDIUM_LARGE} bar size (top background or gradient bar)
 * @param {Component} children //rendered content inside the background
 * @param {String} appearance //{CARD_TITLE, MODAL_HEADER, MODAL_SEARCH_HEADER} component appearance
**/

export default ({
  size = DEFAULT_VALUES.STRING,
  children = DEFAULT_VALUES.COMPONENT,
  appearance = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        size={size}
        child={children}
        appearance={appearance}
      />
    </>
  );
};
