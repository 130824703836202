import { Types } from "./types"

/**
 * Reducers
 */

const INITIAL_STATE = {
  loading: false,
  called: false,
  error: null,
  data: {
    personality: [],
    warnings: null,
  },
}

export default function personalityInsight(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PERSONALITY_INSIGHT:
      return {
        ...state,
        data: action.payload.personalityInsight,
      }

    case Types.GET_PERSONALITY_INSIGHT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case Types.GET_PERSONALITY_INSIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        called: true,
        data: action.payload.data,
      }

    case Types.GET_PERSONALITY_INSIGHT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }

    default:
      return state
  }
}
