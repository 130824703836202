import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ContentLoader from "react-content-loader"
import Img from "react-image"
import { UserActions } from "../../store/ducks/user"
import PrivateCard from "./PrivateCard"
import { API_URL_IMAGES } from "../../utils/constants"
import api from "../../utils/api"

const MyLoader = () => (
  <ContentLoader height={180} width={325} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
    <rect x="0" y="0" rx="5" ry="5" width="400" height="400" />
  </ContentLoader>
)
class CardMeuCurso extends React.PureComponent {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    countObjects: 0,
    countFinishedObjects: 0,
    value: 0,
  }

  componentDidMount = () => {
    const { notProgress } = this.props
    if (!notProgress) {
      this.getProgress()
    }
  }

  getProgress = async () => {
    const { id } = this.props
    try {
      const url = `getGetCountingObjects/${localStorage.getItem("ra")}/${id}/`
      const response = await api.get(url)
      this.setState({
        countObjects: response.countObjects,
        countFinishedObjects: response.countFinishedObjects,
        value: (response.countFinishedObjects * 100) / response.countObjects,
      })
    } catch (error) {
      console.log(error)
    }
  }

  redirect = e => {
    const { router } = this.context
    const { id, freeCourse, toggleModalRestrict, toggleModalRestrictLitPass } = this.props
    const classe = e.target.className.split(" ")
    const freeUser = JSON.parse(localStorage.getItem("freeUser"))
    const showLock = freeUser && !freeCourse
    const naRealUser = JSON.parse(localStorage.getItem('naRealUser'));
    if (showLock) {
      if(naRealUser){
        toggleModalRestrict(true);
      } else {
        toggleModalRestrictLitPass(true);
      }
      return
    }
    if (!classe.includes("fa-thumbs-down") && !classe.includes("fa-thumbs-up")) {
      router.history.push(`/curso/${id}`)
    }
  }

  render() {
    const { countObjects, countFinishedObjects, value } = this.state
    const { id, nome, notProgress, freeCourse } = this.props

    const freeUser = JSON.parse(localStorage.getItem("freeUser"))
    const showLock = freeUser && !freeCourse
    const img = `${API_URL_IMAGES}images/courses/${id}.jpg`

    return (
      <div
        role="button"
        onClick={this.redirect}
        className="card-wrapper-p "
        style={{
          display: "inline-block",
        }}
      >
        <div className="card">
          <Img
            src={img}
            onClick={this.redirect}
            alt="ImageCourse"
            className="card-img-top"
            loader={<MyLoader />}
          />
          <div
            className="card-body"
            style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
          >
            <h5 className="card-title">{nome}</h5>
            {showLock && <PrivateCard />}
            {!notProgress && (
              <div className="slider_title progressoCardMeuCurso">
                <p style={{ padding: 0, margin: 0 }}>
                  {countFinishedObjects}/{countObjects}
                </p>
                <progress min="0" value={parseInt(value, 10) || 0} max={100} />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(UserActions.ProfileActions, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(CardMeuCurso)
