import styled, { css } from "styled-components";
import { POSITION, NUMBER } from "../../../constants";

export const Span = styled.span`
  width: 100%;
  position: relative;
  line-height: 1.2;
  margin-bottom: 15px;
  page-break-inside: avoid;
  display: inline-block;
  padding: 8px;
  img { width: 100%; margin-bottom: 10px; }

  ${props => (props.perLine === NUMBER.FOUR) && css`
    margin-right: 20px;
    margin-bottom: 20px;
    float: left;
    width: calc(25% - 15px); 
    position: relative;
    line-height: 1.2;
    img {
      width: 100%;
      margin-bottom: 10px;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    @media screen and (max-width: 1060px) {
      width: calc(33% - 15px); 
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 991px) {
      width: calc(50% - 15px); 
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 579px) {
      width: calc(100%); 
      margin: 15px;
    }
  `}

  ${props => (props.perLine === NUMBER.THREE) && css`
    float: left;
    width: calc(33% - 12px); 
    position: relative;
    line-height: 1.2;
    margin: 0 20px 15px 0;
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }
    &:nth-of-type(3n) {
      margin-right: 0!important;
    } 
    @media screen and (max-width: 1440px) {
      width: 100%; 
      margin: 0 0 15px;
      &:nth-of-type(2n) { }
    }
    @media screen and (max-width: 767px) {
      width: 100%; 
      margin: 15px;
    }
    @media screen and (max-width: 480px) {
      span label:last-child { font-size: 80%; }
    }
  `}
  
  ${props => (props.perLine === NUMBER.TWO) && css`
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
    width: calc(50% - 5px); 
    position: relative;
    text-align: left;
    line-height: 1.2;
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    } 
    @media screen and (max-width: 579px) {
      width: calc(100%); 
      margin: 15px;
    }
  `}

  ${props => (props.perLine === NUMBER.ONE) && css`
    margin-right: 0;
    margin-bottom: 10px;
    float: left;
    width: 100%; 
    position: relative;
    line-height: 1.2;
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }
  `}

  ${props => (props.textAlign === POSITION.LEFT) && css`
    text-align: left;
  `}

  ${props => (props.textAlign === POSITION.RIGHT) && css`
    text-align: right;
  `}

  ${props => (props.textAlign === POSITION.CENTER) && css`
    text-align: center;
  `}

`;

export const ButtonsAtBottom = styled.span`
  position: absolute;
  width: calc(90% - 15px);
  bottom: 30px;
  div {
    padding: 0;
  }
  textarea {
    margin: 5px;
    padding: 6px 8px;
  }
  label:nth-of-type(2) {
    line-height: 1.1;
  }
  @media (max-width: 991px) { 
    div { width: auto; }
    > div { display: flex; justify-content: space-between; }
  }
  @media (max-width: 480px) { 
    width: calc(90% - 25px);
  }
`;

export const InfoGroup = styled.span`
  display: block;
  margin: 10px 0;
  width: 100%;
  height: 45px;
  overflow: hidden;
  i {
    font-size: 100%;
    margin: 5px 1px;
  }
  label {
    position: relative;
    width: calc(100% + 70px);
    margin-bottom: -5px;
  }
  span {
    left: -5px;
    padding-left: 0;
  }
  @media (max-width: 579px) {

  }
`;

export const Title = styled.span`
  display: table;
  width: calc(100% + 33px);
  position: relative;
  left: -16px;
  div { display: flex; align-items: flex-end; min-height: 120px; }
  label { line-height: 1 }
  @media (max-width: 579px) {
    padding: 10px;
    div { min-height: 100px; }
    label { font-size: 18px }
  }
`;

export const Banner = styled.span`
  display: table;
  width: calc(100% + 16px);
  position: relative;
  left: -8px;
  margin: 1rem 0;
  img { height: 180px; }
  span { padding-left: 10px; }
  @media (max-width: 991px) {
    margin-bottom: 0;
  }
  @media (max-width: 480px) {
    img { height: 100px; }
  }
`;

export const Content = styled.span`
  display: table;
  width: 100%
  position: relative;
`;

export const Divisor = styled.span`
  width: 100%;
  border-top: 1px solid var(--divisor-message);
  margin-top: 1rem;
  padding-top: 1rem;
  @media (max-width: 991px) {
    span:not(.content-button) { padding: 0; margin-left: -5px; width: 94%; }
  }
  @media (max-width: 767px) {
    span:not(.content-button) { width: 94%; }
  }
  @media (max-width: 579px) {
    span:not(.content-button) { width: 84%; }
  }
`;

export const Evaluation = styled.span`
  display: block;
  width: 100%
  position: relative;
  margin: 0;
`;

export const TitleEvaluation = styled.span`
  position: relative;
  left: -16px;
  width: 100%;
  text-align: left;
  height: auto;
  display: block;
  white-space: break-spaces;
  span { left: -1px; }
  label { margin-left: 15px; width: calc(100% + 60px); }
  @media (max-width: 991px) {
    label { margin-left: 50px!important; width: calc(100% - 50px); }
  }
`;

export const Subtitle = styled.span`
  display: block;
  margin: 10px 0;
  width: 100%;
  height: 43px;
  overflow: hidden;
  i {
    font-size: 100%;
    margin: 5px 1px;
  }
  span {
    left: -5px;
    padding-left: 0;
  }
  label {
    position: relative;
    line-height: 1;
    margin-bottom: -5px;
    width: calc(100% + 40px);
    text-align: left;    
    height: auto;
    display: block;
    white-space: break-spaces;
    margin-left: -3px;
  }
`;
