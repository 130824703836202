import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
`;

export const LoadingWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    animation: ${rotate360} 1s linear infinite;
    margin-right: 5px;
  }
`;
