import React from "react"
import _ from "lodash";
import styled from "styled-components"

const NavLit = styled.nav``

const LOGO_LIT = require("../../images/logo_home2.png")

const Navbar = ({bgCollor, ...rest}) => {

  const tenantOnboarding = localStorage.getItem("tenantOnboarding");
  const settings = JSON.parse(localStorage.getItem('settings'));

  const { headerBackgroundColor } = _.get(
    settings,
    `${tenantOnboarding}`,
    {}
  );

  return (
    <NavLit style={{backgroundColor: bgCollor || headerBackgroundColor}} className='navbar' {...rest}>
      <div className='container'>
        <div className='navbar-brand'>
          <a className='navbar-brand'>
            <img src={LOGO_LIT} alt='Logo LIT' />
          </a>
        </div>
        {/* <UpdateDate>Data Atualização: 02/10/2019</UpdateDate> */}
      </div>
    </NavLit>
  )
}

export default Navbar
