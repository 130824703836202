/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from'react';

class CertificadoLit extends Component {
    state = {
        visibleBody: false,
      }

    componentDidMount() {}

    changeVisible = () => {
        const { visibleBody } = this.state
        this.setState({ visibleBody: !visibleBody })
    };

    render() {
        const { visibleBody } = this.state
        return (
            <div className="box-tabs">
            <div>
                <div className="row cursor-pointer" onClick={this.changeVisible}>
                <div className="col-12 headerTab">
                    <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                        1. Como posso obter um certificado no LIT?
                    </h5>
                    <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                        {visibleBody ? (
                        <i className="fa fa-chevron-up" aria-hidden="true" />
                        ) : (
                        <i className="fa fa-chevron-down" aria-hidden="true" />
                        )}
                    </button>
                </div>
                </div>
                {visibleBody && (
                    <div className="row">
                    <div className="col">
                        <p>
                            Os cursos no LIT tem uma duração média de aproximadamente 20 horas, mas temos também os nossos LIT Talks | Cursos Rápidos,
                            <br />
                            que são cursos curtos, com duração de aproximadamente 2 horas.
                        </p>
                        <p>
                            Para obter os certificados de participação nos LIT Talks, basta você assistir todos os vídeos até o final.    
                        </p>
                        <p>
                            Os cursos de 20 horas possuem certificados de conclusão. Para obter o certificado é preciso fazer a Avaliação Final.
                            <br />
                            Caso sua nota final nesta avaliação seja maior ou igual a 7,00 (sete), você conquistará o certificado.
                        </p>
                        <p>
                            Ao concluir um curso no LIT você terá direito a um certificado de cursos livres, emitido pela Saint Paul Escola de Negócios,
                            <br />
                             conforme a legislação em vigor Lei  n. 9.394, de 20 de dezembro de 1996, para cada curso concluído.                             
                        </p>
                        <p>
                            Você também pode acumular certificados e utilizá-los como créditos para um programa de MBA na Saint Paul Escola de Negócios. 
                            <br />
                            Caso tenha interesse, entre em contato pelo <a className='link-destaque' href='mailto:sac@lit.com.br' >sac@lit.com.br</a> que te encaminharemos mais informações sobre este tema.
                        </p>
                        <p>
                            Todos os cursos no LIT possuem certificado, que você pode realizar o download e compartilhar nas redes que quiser.
                        </p>
                    </div>
                    </div>
                )}
            </div>
            </div>
        );
    }

};

export default CertificadoLit;
