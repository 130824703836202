import styled, { css } from "styled-components";
import { SIZE, APPEARANCE } from "../../../constants";


export const TopBar = styled.div`

  i { min-width: 18px}

  ${props => (props.size === SIZE.SMALL) && css`
    width: 100%;
    ${props => props.bg ? `background: linear-gradient(
      60deg,
      ${props.bg} 0%,
      ${props.bg} 100%
    );` : `
    background: linear-gradient(
      60deg,
      var(--background-gradient-primary) 0%,
      var(--background-gradient-secondary) 100%
    );
    `}   
    background-size: 100% 60px;
    background-position: top left;
    background-repeat-y: no-repeat;
    margin-bottom: 1rem;
    i {
      margin-right: 10px !important;
    }
    @media screen and (max-width: 767px) {
      background-size: 100% 60px;
      margin-bottom: 10px;
      position: sticky;
      top: 0;
      z-index: 10;
      border-bottom: 2px solid var(--white);
    }
  `}

  ${props => (props.size === SIZE.MEDIUM) && css`
    width: 100%;
    ${props => props.bg ? `background: linear-gradient(
      60deg,
      ${props.bg} 0%,
      ${props.bg} 100%
    );` : `
    background: linear-gradient(
      60deg,
      var(--background-gradient-primary) 0%,
      var(--background-gradient-secondary) 100%
    );
    `} 
    background-size: 100% 180px;
    background-position: top left;
    background-repeat-y: no-repeat;
    margin-bottom: 2rem;
    i {
      margin-right: 12px !important;
    }
    @media screen and (max-width: 767px) {
      background-size: 100% 210px;
      margin-bottom: 10px;
    }
  `}

  ${props => (props.size === SIZE.LARGE) && css`
    width: 100%;
    ${props => props.bg ? `background: linear-gradient(
      60deg,
      ${props.bg} 0%,
      ${props.bg} 100%
    );` : `
    background: linear-gradient(
      60deg,
      var(--background-gradient-primary) 0%,
      var(--background-gradient-secondary) 100%
    );
    `} 
    background-color: ${props => props.bg} 
    background-size: 100% 280px;
    background-position: top left;
    background-repeat-y: no-repeat;
    margin-bottom: 3rem;
    i {
      margin-right: 15px !important;
    }
    label {
      width: initial;
      max-width: 100%;
    }
    @media screen and (max-width: 767px) {
      background-size: 100% 360px;
      margin-bottom: 10px;
    }
  `}

  ${props => (props.size === SIZE.MEDIUM_LARGE) && css`
    width: 100%;
    ${props => props.bg ? `background: linear-gradient(
      60deg,
      ${props.bg} 0%,
      ${props.bg} 100%
    );` : `
    background: linear-gradient(
      60deg,
      var(--background-gradient-primary) 0%,
      var(--background-gradient-secondary) 100%
    );
    `} 
    background-size: 100% 180px;
    background-position: top left;
    background-repeat-y: no-repeat;
    margin-bottom: 3rem;
    i {
      margin-right: 15px !important;
    }
    @media screen and (max-width: 767px) {
      background-size: 100% 360px;
      margin-bottom: 10px;
    }
  `}

  ${props => (props.appearance === APPEARANCE.CARD_TITLE) && css`
    margin: 1rem 0;
    label {
      padding: 20px;
    }
  `}

  ${props => (props.appearance === APPEARANCE.MODAL_HEADER) && css`
    label {
      padding-left: 25px;
      padding-top: 40px;
    }
    .content-button {
      position: absolute;
      left: -10px;
      top: 5px;
    }
    img {
      top: 45px;
      left: 0;
      position: relative;
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.MODAL_SEARCH_HEADER) && css`
    height: 80px;
    background-size: 100% 80px;
    padding: 10px 0;
    img {
      display: none;
    }
    .content-button {
      position: absolute;
      left: 0;
      top: 5px;
    }
    label {
      margin-left: 40px;
      min-height: 50px;
    }
  `}
  
`;
