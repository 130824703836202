import styled from "styled-components";
import { } from "../../../constants";

export const Info = styled.span.attrs({})``;

export const Description = styled.p.attrs({})`
  padding: 1rem 0;
  width: 96%;
`;

export const AllMembers = styled.div.attrs({})`
 text-align: center;
`;
