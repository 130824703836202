import React, { Component } from 'react';
import 'moment/locale/pt-br';
import { gateway } from '../../../utils/api';

import ListaTermo from './ListaTermo';

class Termos extends Component {
  state = {
    visibleBody: false,
    terms: []
  };

  componentDidMount() {
    this.loadTerms();
  }

  loadTerms = async () => {
    const idProfile = localStorage.getItem('idProfile');
    const userEmail = localStorage.getItem('ra');

    try {
      const response = await gateway.get(
        `webbff/shared/term/getUserTerms?userEmail=${userEmail}&idProfile=${idProfile}`
      );

      const { payrollTerms, policyTerms, useTerms } = response;

      this.setState({ terms: [...payrollTerms, ...policyTerms, ...useTerms] });
    } catch (err) {}
  };

  changeVisible = () => {
    const { visibleBody } = this.state;
    this.setState({ visibleBody: !visibleBody });
  };

  render() {
    const { visibleBody, terms } = this.state;
    return (
      <div className="cursos bg-white-perfil" id="recibos-usuario">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <h5 className="d-inline-block" style={{ fontSize: '1.5em' }}>
                Termos assinados
              </h5>
              <button
                type="button"
                className="btn btn-icon ml-auto"
                onClick={this.changeVisible}
              >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                {terms.length ? (
                  <ListaTermo terms={terms} />
                ) : (
                  <p>Sem termos para exibir</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Termos;
