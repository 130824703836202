import styled from "styled-components";
import { } from "../../../constants";

export const ModalBody = styled.div.attrs({
  className: "modal-body"
})`
  padding: 2rem;
  margin: 1rem 0;
  position: relative;
`;

export const Info = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  margin: 0 0 2rem;
  p {
    line-height: 1;
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 1060px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const List = styled.div.attrs({})`
  display: flex;
  flex-direction: column;
  button {
    margin-right: 5px;
  }
  @media (max-width: 844px) {
    li {
      padding-left: 20%;
      display: inline-grid;
      text-align-last: center;
      padding-bottom: 16px;
      button {
        margin-right: 0px;
      }
    }
    img {
      margin-left: 55px !important;
    }
  }
  @media (max-width: 583px) {
    li {
      padding-left: 8% !important;
    }
    img {
      margin-left: 55px !important;
    }
  }

  @media (max-width: 505px) {
    button {
      align-items: center;
      display: block;
      margin-left: 20px;
      width: 100px;
    }
    img {
      margin-left: 45px !important;
    }
  }

  @media (max-width: 425px) {
    li {
      padding-left: 0% !important;
    }
  }
`;
