import React, { Component } from "react"
import Img from "react-image"
import api from "../../utils/api"
import placeholder from "../../images/placeholder-avatar.png"

class AvatarPerfilPublico extends Component {
  state = {
    experiences: false,
  }

  componentDidMount() {
    this.fetchOffices()
  }

  fetchOffices = async () => {
    try {
      const {
        profile: { idProfile },
      } = this.props
      const idProfileLogged = localStorage.getItem("idProfile")
      const response = await api.get(`getLastOffice/${idProfile}/${idProfileLogged}`)
      this.setState({ experiences: response })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { experiences } = this.state
    const { profile } = this.props
    return (
      <div>
        <div className="avatar-photo-perfil ">
          <div className="row justify-content-center">
            <Img className="rounded-circle" src={[profile.linkPhoto, placeholder]} />
          </div>
        </div>
        <div className="row text-center marginb-10 d-flex align-items-center justify-content-center">
          <div className="col-12">
            <h6>
              {profile.firstName} {profile.lastName}
            </h6>
          </div>

          {experiences && experiences.length !== 0 ? (
            <div>
              <div className="col-12">
                <h5 className="perfil-empresa">{experiences.company}</h5>
              </div>
              <div className="col-12">
                <span className="spanUserRole-perfil">{experiences.profession}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default AvatarPerfilPublico
