import _ from 'lodash'
import { Types } from "./types"
/**
 * Reducers
 */

const INITIAL_STATE = {
  selectedPlan: null
}

function formatReal(int) {
  const parsedValue = parseFloat(int);
  if (Number.isNaN(parsedValue)) return '';
  return parsedValue
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.');
}

const _formatPaymentMethod = (paymentMethods, couponData, basePrice) => {
  const paymentMethodsType = paymentMethods.map(
    (paymentItem) => paymentItem.type
  );

  const paymentMethodsTypeUnique = [...new Set(paymentMethodsType)];

  const formatedPaymentMethod = paymentMethodsTypeUnique.map(
    (paymentMethodItem) => {
      const paymentMethodFiltered = paymentMethods.filter(
        (item) => item.type === paymentMethodItem
      );

      let maxInstallment = 0;
      const installmentSettings = []
      const formattedInstallment = [];
      const installments = [];

      paymentMethodFiltered.forEach((paymentMethod) => {
        maxInstallment =
              maxInstallment > paymentMethod.maxInstallment
                ? maxInstallment
                : paymentMethod.maxInstallment;

        paymentMethod.installmentSettings.forEach(installment => {
          installmentSettings.push({
            ...installment
          })
        })

        paymentMethod.installments.forEach((installment) => {
          const item = installment;
          installments.push(item)
        })

        paymentMethod.installments.forEach(installment => {
          if (couponData && couponData.type && couponData.value) {
            let priceWithDiscount
            const tmpPrice =
              couponData.type === 'PERCENT'
                ? installment.totalPrice - installment.totalPrice * (couponData.value / 100)
                : installment.totalPrice - couponData.value;

            if (tmpPrice < 0) {
              priceWithDiscount = 0
            } else {
              priceWithDiscount = tmpPrice;
            }
            const totalDiscount = basePrice - priceWithDiscount
            const phraseDiscount = totalDiscount
            ? `(Com desconto de R$ ${formatReal(totalDiscount)})`
            : `(Sem desconto)`;

            const item = {
              value: installment.number,
              label: `${installment.number} x R$ ${formatReal(
                (priceWithDiscount) / installment.number
              )} ${phraseDiscount}`,
            }
            formattedInstallment.push(item)
          } else {
            const totalDiscount = basePrice - installment.totalPrice
            const phraseDiscount = totalDiscount
            ? `(Com desconto de R$ ${formatReal(totalDiscount)})`
            : `(Sem desconto)`;

            const item = {
              value: installment.number,
              label: `${installment.number} x R$ ${formatReal(
                (installment.totalPrice) / installment.number
              )} ${phraseDiscount}`,
            }
            formattedInstallment.push(item)
          }
          
        })
      });

      const newFormattedInstallment = formattedInstallment.sort(
        (a, b) => a.value - b.value
      );

      return {
        type: paymentMethodItem,
        installmentSettings,
        maxInstallment,
        formattedInstallment: newFormattedInstallment,
        installments,
        termTemplateReference: _.get(
          paymentMethodFiltered,
          '0.ox_term.rk_reference',
          null
        ),
      };
    }
  );
  return formatedPaymentMethod;
}

export default function plan(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_SELECTED_PLAN: {
      return {
        ...state,
        selectedPlan: action.payload.data,
      }
    }
    case Types.UPDATE_INSTALLMENTS_SELECTED_PLAN: {
      const { paymentMethods, basePrice } = state.selectedPlan
      const { couponData } = action.payload.data
      const updatedPaymentsMethods = _formatPaymentMethod(paymentMethods, couponData, basePrice)
      const updatedSelectedPlan = {...state.selectedPlan}
      updatedSelectedPlan.paymentMethods = updatedPaymentsMethods
      return {
        ...state,
        selectedPlan: updatedSelectedPlan
      }
    }
    default:
      return state
  }
}
