import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} color //{DARK, TEXT_PRIMARY, LIGHT, PRIMARY, DANGER} font color
 * @param {String} size //{EXTRA_SMALL, SMALL, MEDIUM, LARGE, EXTRA_LARGE} font size
 * @param {String} fontType //{BOLD, BOLDER, ITALIC, MEDIUM_BOLD} font style
 * @param {String} transform //{UPPERCASE, LOWERCASE, CAPITALIZE, FIRST_LETTER} font transform
 * @param {String} icon //text icon
 * @param {String} text //content
 * @param {String} iconPosition //{LEFT, RIGHT} text icon position
 * @param {String} textAlign //{LEFT, RIGHT, CENTER} text align
 * @param {String} appearance //{UNDERLINE, TITLE, CARD_TITLE, CARD_DESCRIPTION} text appearance
**/

export default ({
  color = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING,
  fontType = DEFAULT_VALUES.STRING,
  transform = DEFAULT_VALUES.STRING,
  icon = DEFAULT_VALUES.STRING,
  text = DEFAULT_VALUES.STRING,
  iconPosition = DEFAULT_VALUES.STRING,
  textAlign = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING,
  ...rest
}) => {
  return (
    <>
      <Screen
        {...rest}
        color={color}
        size={size}
        fontType={fontType}
        transform={transform}
        icon={icon} text={text}
        iconPosition={iconPosition}
        textAlign={textAlign}
        appearance={appearance}
      />
    </>
  );
};
