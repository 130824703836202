import React from 'react';

import { Container } from './styles';

function SurveyImageComponent({id, path, name}) {
  return(
    <Container id={id}>
      <img src={path} alt={name} />
    </Container>
  )
}

export default SurveyImageComponent;
