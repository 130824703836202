import React from "react"
import Lightbox from "react-image-lightbox"
import Img from "react-image"
import ReactPlayer from "react-player"
import screenfull from "screenfull"

// import api from '../../../utils/api'
import { API_URL } from "../../../utils/constants"
import Duration from "../../CursoNovo/Media/Duration"
import { isURL } from "../../../utils/functions"

class Attachment extends React.Component {
  state = {
    isOpen: false,
    playing: false,
    volume: 1,
    muted: false,
    played: 0,
    duration: 0,
    seeking: false,
  }

  // async componentDidMount() {
  //   const { url, ext } = this.props
  //   if (url.includes("downloadAttachment") && ext === "VOD") {
  //     try {
  //       const response = await api.get(url.slice(0, -3))
  //       this.setState({ url: response.message })
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }

  pad = string => {
    return `0 ${string}`.slice(-2)
  }

  onPlay = () => {
    this.setState({ playing: true })
  }

  onPause = () => {
    this.setState({ playing: false })
  }

  playPause = () => {
    const { playing } = this.state
    this.setState({ playing: !playing })
  }

  toggleMuted = () => {
    const { muted } = this.state
    this.setState({ muted: !muted })
  }

  onSeekMouseDown = () => {
    this.setState({ seeking: true })
  }

  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  onSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  onProgress = state => {
    const { seeking } = this.state
    if (!seeking) {
      this.setState(state)
    }
  }

  _handleContextMenu = e => {
    e.preventDefault()
  }

  onClickFullscreen = () => {
    screenfull.request(this.player)
  }

  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) })
  }

  ref = player => {
    this.player = player
  }

  _handleContextMenu = e => {
    e.preventDefault()
  }

  render() {
    const { ext, url } = this.props
    const { playing, volume, muted, played, duration, isOpen } = this.state

    const urlMedia = isURL(url) ? url : `${API_URL}${url}`

    switch (ext) {
      case "jpg":
        return (
          <div className="d-flex justify-content-center">
            <Img
              src={urlMedia}
              className="img-fluid"
              style={{ width: "auto", height: "250px" }}
              onClick={() => {
                this.setState({ isOpen: true })
              }}
            />
            {isOpen && (
              <Lightbox
                mainSrc={urlMedia}
                onCloseRequest={() => {
                  this.setState({ isOpen: false })
                }}
              />
            )}
          </div>
        )
      case "mp4":
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            onContextMenu={e => this._handleContextMenu(e)}
          >
            <div className="d-flex flex-column" style={{ width: "350px" }}>
              <div className="player-wrapper" onContextMenu={e => this._handleContextMenu(e)}>
                <ReactPlayer
                  url={urlMedia}
                  className="react-player"
                  ref={this.ref}
                  onReady={() => console.log("onReady")}
                  onStart={() => console.log("onStart")}
                  onPlay={this.onPlay}
                  onPause={this.onPause}
                  onDuration={e => this.setState({ duration: e })}
                  onBuffer={() => console.log("onBuffer")}
                  onSeek={e => console.log("oi", e)}
                  onEnded={this.onEnded}
                  onError={e => console.log("onError", e)}
                  onProgress={this.onProgress}
                  muted={muted}
                  playing={playing}
                  volume={volume}
                />
              </div>
              <input
                type="range"
                min={0}
                max={1}
                step="any"
                value={played}
                onMouseDown={this.onSeekMouseDown}
                onChange={this.onSeekChange}
                onMouseUp={this.onSeekMouseUp}
              />
              <div className="video-control d-flex align-items-center" style={{ height: "35px" }}>
                <a role="button" className="btn" onClick={this.playPause}>
                  {playing ? (
                    <i className="fa fa-pause" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-play" aria-hidden="true" />
                  )}
                </a>
                <div className="volume d-flex align-items-center">
                  <a role="button" className="btn" onClick={this.toggleMuted}>
                    {muted ? (
                      <i className="fa fa-volume-off" aria-hidden="true" />
                    ) : (
                      <i className="fa fa-volume-up" aria-hidden="true" />
                    )}
                  </a>
                  <div className="volume-range">
                    <input
                      id="volume-range"
                      type="range"
                      min={0}
                      max={1}
                      step="any"
                      value={volume}
                      onChange={this.setVolume}
                    />
                  </div>
                </div>
                <Duration seconds={duration * played} /> / <Duration seconds={duration} />
                <div className="d-flex align-items-center ml-auto p-2">
                  <a role="button" className="btn" onClick={this.onClickFullscreen}>
                    <i className="fa fa-expand" aria-hidden="true" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      case "mp3":
      case "VOD":
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            onContextMenu={e => this._handleContextMenu(e)}
          >
            <div className="d-flex flex-column" style={{ width: "350px" }}>
              <div className="player-wrapper" onContextMenu={e => this._handleContextMenu(e)}>
                <ReactPlayer
                  url={urlMedia}
                  className={`${ext === "VOD" ? "react-player" : "mp3-player"}`}
                  ref={this.ref}
                  onPlay={this.onPlay}
                  onPause={this.onPause}
                  onDuration={e => this.setState({ duration: e })}
                  onEnded={this.onEnded}
                  onProgress={this.onProgress}
                  muted={muted}
                  playing={playing}
                  volume={volume}
                />
              </div>
              <input
                type="range"
                min={0}
                max={1}
                step="any"
                value={played}
                onMouseDown={this.onSeekMouseDown}
                onChange={this.onSeekChange}
                onMouseUp={this.onSeekMouseUp}
              />
              <div className="video-control d-flex align-items-center" style={{ height: "35px" }}>
                <a role="button" className="btn" onClick={this.playPause}>
                  {playing ? (
                    <i className="fa fa-pause" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-play" aria-hidden="true" />
                  )}
                </a>
                <div className="volume d-flex align-items-center">
                  <a className="btn" onClick={this.toggleMuted} role="button">
                    {muted ? (
                      <i className="fa fa-volume-off" aria-hidden="true" />
                    ) : (
                      <i className="fa fa-volume-up" aria-hidden="true" />
                    )}
                  </a>
                  <div className="volume-range">
                    <input
                      id="volume-range"
                      type="range"
                      min={0}
                      max={1}
                      step="any"
                      value={volume}
                      onChange={this.setVolume}
                    />
                  </div>
                </div>
                <Duration seconds={duration * played} /> / <Duration seconds={duration} />
                <div className="d-flex align-items-center ml-auto p-2">
                  <a role="button" className="btn" onClick={this.onClickFullscreen}>
                    <i className="fa fa-expand" aria-hidden="true" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      case "pdf":
      case "PDF":
        return (
          <a href={urlMedia} style={{ color: "#27aae1" }}>
            <i className="fa fa-download" /> Baixar anexo
          </a>
        )

      // case "VOD":
      //   return (
      //     <div
      //       className="d-flex justify-content-center align-items-center"
      //       onContextMenu={e => this._handleContextMenu(e)}
      //     >
      //       <div className="d-flex flex-column" style={{ width: "350px" }}>
      //         <div className="player-wrapper" onContextMenu={e => this._handleContextMenu(e)}>
      //           <ReactPlayer
      //             url={urlMedia}
      //             className="react-player"
      //             ref={this.ref}
      //             onPlay={this.onPlay}
      //             onPause={this.onPause}
      //             onDuration={e => this.setState({ duration: e })}
      //             onEnded={this.onEnded}
      //             onProgress={this.onProgress}
      //             muted={muted}
      //             playing={playing}
      //             volume={volume}
      //           />
      //         </div>
      //         <input
      //           type="range"
      //           min={0}
      //           max={1}
      //           step="any"
      //           value={played}
      //           onMouseDown={this.onSeekMouseDown}
      //           onChange={this.onSeekChange}
      //           onMouseUp={this.onSeekMouseUp}
      //         />
      //         <div className="video-control d-flex align-items-center" style={{ height: "35px" }}>
      //           <a role="button" className="btn" onClick={this.playPause}>
      //             {playing ? (
      //               <i className="fa fa-pause" aria-hidden="true" />
      //             ) : (
      //               <i className="fa fa-play" aria-hidden="true" />
      //             )}
      //           </a>
      //           <div className="volume d-flex align-items-center">
      //             <a role="button" className="btn" onClick={this.toggleMuted}>
      //               {muted ? (
      //                 <i className="fa fa-volume-off" aria-hidden="true" />
      //               ) : (
      //                 <i className="fa fa-volume-up" aria-hidden="true" />
      //               )}
      //             </a>
      //             <div className="volume-range">
      //               <input
      //                 id="volume-range"
      //                 type="range"
      //                 min={0}
      //                 max={1}
      //                 step="any"
      //                 value={volume}
      //                 onChange={this.setVolume}
      //               />
      //             </div>
      //           </div>
      //           <Duration seconds={duration * played} /> / <Duration seconds={duration} />
      //           <div className="d-flex align-items-center ml-auto p-2">
      //             <a role="button" className="btn" onClick={this.onClickFullscreen}>
      //               <i className="fa fa-expand" aria-hidden="true" />
      //             </a>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   )
      default:
        return null
    }
  }
}

export default Attachment
