import styled from 'styled-components'
import {lighten} from 'polished'

export const Container = styled.div`

`
export const Experience = styled.div``


export const AddExperienceBox = styled.div`
  display: flex;
  align-items: center;
`

export const AddButton = styled.button`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  /* background-image: linear-gradient(0deg,rgba(2,196,255,1),rgba(142,230,123,1)); */
  background: ${props => props.disabled ? lighten(.2, '#27aae1') : '#27aae1'};
  border-radius: 50%;
  border: 0;
  color: #fff;
  margin-right: 10px;
  &:hover + span {
    text-decoration: underline
  }
`

export const AddText = styled.p`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? lighten(.4, '#212529') : '#212529'};
  font-weight: bold;
  margin: 0;
  padding: 0;
  &:hover {
    text-decoration: ${props => props.disabled ? 'none' : 'underline'};
  }
`

export const NotHaveBox = styled.div`
  display: flex;
  align-items: center;
`

export const NotHaveButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  /* background-image: linear-gradient(0deg,rgba(2,196,255,1),rgba(142,230,123,1)); */
  background: ${props => props.withoutAcademicTraining ? '#27aae1' : '#c1c1c1'} ;
  border-radius: 50%;
  border: 0;
  color: #fff;
  margin-right: 10px;
  &:hover + span {
    text-decoration: underline
  }
`

export const NotHaveText = styled.p`
  cursor: pointer;
  font-weight: bold;
  margin: 0;
  padding: 0;
  &:hover {
    text-decoration: underline
  }
`

export const ItemList = styled.div`
  padding: 10px 0;
  width: 100%;
  margin: 0 auto;
`

export const TextError = styled.p`
  margin: 0;
  padding: 0;
  color: red;
  font-size: 12px;
`