import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import { Creators as NotificationsActions } from "../../store/ducks/notifications/actions"
import api, { gateway } from "../../utils/api"
import TableBody from "./TableBody"

class Notificacoes extends Component {
  state = {
    notifications: [],
  }

  notification = null

  componentDidMount() {
    this.fetchAllNotifcations()
  }

  fetchAllNotifcations = async () => {
    const idProfile = localStorage.getItem("idProfile")
    gateway
      .get(`/webbff/lit_app_web/notification/consultNotifications/0/${idProfile}`)
      .then(response => {
        this.setState({ notifications: response })
      })
      .catch(err => {
        console.log(err)
      })
  }

  getCountNotRead = () => {
    const { countNotificationsNotReadRequest } = this.props
    countNotificationsNotReadRequest()
  }

  markAllRead = listNotRead => {
    console.log(listNotRead)

    listNotRead.forEach(id => {
      this.notification.onChangeRead(id)
    })
  }

  render() {
    const { notifications } = this.state
    const resd = []
    return (
      <div>
        <NavBarSuperior />
        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row justify-content-md-center">
            <div className="col-10">
              <div className="container" id="objetivos-personalizar">
                <div className="text-body-pi">
                  <h4 style={{ color: "#27aae1" }} className="text-center">
                    Notificações
                  </h4>

                  <div className="row justify-content-md-center mt-2">
                    <div className="col-7">
                      <div className="d-flex justify-content-end my-2">
                        <button
                          type="button"
                          onClick={() => this.markAllRead(resd)}
                          className="btn btn-sm btn-outline-primary"
                        >
                          Marcar todas como lidas
                        </button>
                      </div>
                      {notifications.length > 0 ? (
                        <table className="table">
                          <tbody>
                            {notifications.map(item => {
                              if (!item.readMessage) {
                                resd.push(item.idHistory)
                              }
                              return (
                                <TableBody
                                  ref={notif => {
                                    this.notification = notif
                                  }}
                                  key={item.idHistory}
                                  updateList={this.fetchAllNotifcations}
                                  updateCount={this.getCountNotRead}
                                  item={item}
                                />
                              )
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p>Você não possui notificações no momento.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(NotificationsActions, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(Notificacoes)
