import React, { memo, useEffect, useState } from 'react';

import styled from 'styled-components';
import RightSideBar from './RightSideBar';
import background from '../../images/lit.png';

import history from '../../utils/history';

import NaReal from './NaReal';

const LoginBox = styled.div`
  padding: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  @media(max-width: 710px) {
    padding: 4em 0;
  }
`;

const NavLit = styled.nav`
  width: 100%;
  background-image: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    /* padding: 0; */
    margin: 0;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

function ConfirmarEmail({ location }) {
  const [isPartner, setIsPartner] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    const isMobile = window.innerWidth <= 833;
    setIsMobile(isMobile);
  };

  function getParams() {
    const params = new URLSearchParams(location.search);
    const origem = params.get('origem');
    const loginTenant = params.get('tenant');
    if (loginTenant) {
      localStorage.setItem('loginTenant', loginTenant);
    }
    if (origem && origem !== '') {
      setIsPartner(true);
    }
  }

  function logout() {
    history.push('/logout');
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Calcular o valor inicial de isMobile

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getParams();
  }, [getParams]);

  return (
    <div id="login" className="container-fluid">
      {isPartner ? (
        <NaReal />
      ) : (
        <div className="row" id="confirm-email-screen">
          {isMobile && (
            <NavLit className="navbar navbar-lit navbar-expand-lg bg-gradient-lit">
              <a className="navbar-brand">
                <img src={background} alt="logo_lit" style={{ width: '100px' }} />
              </a>
            </NavLit>
          )}
          {!isMobile && (
            <div className="col-md-7 hide-in-mobile d-none d-md-flex bg-loginLeft big ">
              <img src={background} className="logo" alt="LIT" />
            </div>
          )}
          <div
            className="col-md-5"
            style={{
              background: '#f3f2f1',
              minHeight: '100vh'
            }}
          >
            <LoginBox>
              <RightSideBar />
            </LoginBox>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(ConfirmarEmail);
