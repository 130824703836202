import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { bindActionCreators } from "redux"
import { UserActions } from "../../store/ducks/user"
import api from "../../utils/api"
import { toastErrorAndWarningOptions, toastDefaultOptions } from '../../utils/functions'

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import Analisando from "./Analisando"
import WatsonBadgeFooter from "./WatsonBadgeFooter"

const GIF_LIT = require("../../images/animacao_paul_standby.gif")

class PersonalizarTexto extends Component {
  state = {
    aboutUser: "",
    aboutProfessional: "",
    personaliteInsightProgress: true,
    contagemTextoPessoal: 0,
    contagemTextoProfissional: 0,
    resultadoQuantidadeDePalavras: 0,
    salvo: true,
    loading: false,
  }

  _contadorDePalavras = texto => {
    const { contagemTextoPessoal, contagemTextoProfissional } = this.state
    const text = texto.trim()
    if (text === "") {
      return 0
    }
    this.setState({
      resultadoQuantidadeDePalavras: contagemTextoPessoal + contagemTextoProfissional,
    })
    return text.split(/\s+/).length
  }

  onAnalisar = () => {
    this.onPersonaliteInsight()
  }

  getResposta = async () => {
    try {
      const url = `findDescription/${localStorage.getItem("idProfile")}`
      const response = await api.get(url)
      if (response.aboutUser) {
        this.setState({
          aboutUser: response.aboutUser,
          aboutProfessional: response.aboutProfessional,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  saveRespostas = async () => {
    const { aboutProfessional, aboutUser } = this.state
    this.setState({ loading: true })
    try {
      const data = {
        aboutUser,
        aboutProfessional,
        idProfile: localStorage.getItem("idProfile"),
      }
      await api.post("saveDescription", data)
      this.setState({ loading: false, salvo: true })
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  onPersonaliteInsight = () => {
    const { aboutProfessional, aboutUser } = this.state
    const { setPersonalityInsight } = this.props
    const idProfile = localStorage.getItem("idProfile")

    const personalityInsights = {
      aboutUser: aboutUser.trim(), // .replace(/[^A-Za-z0-9\s!?\u00C0-\u00FF\u0082:]/g, ""),
      aboutProfessional: aboutProfessional.trim(),
      // .replace(/[^A-Za-z0-9\s!?\u00C0-\u00FF\u0082:]/g, ""),
      idProfile: parseInt(idProfile, 10),
    }

    if (!personalityInsights.aboutProfessional) {
      this.notify("Por favor preencha o segundo campo de texto")
      return
    }
    if (!personalityInsights.aboutUser) {
      this.notify("Por favor preencha o primeiro campo de texto")
      return
    }
    if (this._contadorDePalavras(personalityInsights.aboutProfessional) < 400) {
      this.notify("Por favor insira um texto com mais que 400 palavras no segundo campo")
      return
    }
    if (this._contadorDePalavras(personalityInsights.aboutUser) < 400) {
      this.notify("Por favor insira um texto com mais que 400 palavras no primeiro campo")
      return
    }

    this.setState({ personaliteInsightProgress: false })
    api
      .post("personalityInsights", personalityInsights, {
        headers: { "Content-Type": "application/json" },
      })
      .then(res => {
        console.log(res)
        localStorage.setItem("completedPersonality", true)
        setPersonalityInsight(res)
      })
      .catch(error => {
        this.setState({ personaliteInsightProgress: true })
        if(error.response && error.response.data) {
          toast.error(error.response.data.message, {
            ...toastErrorAndWarningOptions,
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        }
      
        console.log(error.response.data.message)
      })
  }

  componentWillMount = () => {
    this.getResposta()
  }

  notify = (text, duration = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: duration,
    })
  }

  render() {
    const {
      personaliteInsightProgress,
      aboutProfessional,
      aboutUser,
      contagemTextoPessoal,
      contagemTextoProfissional,
      resultadoQuantidadeDePalavras,
      salvo,
      loading,
    } = this.state
    return (
      <div id="interesses">
        <NavBarSuperior />
        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row">
            <div className="col-12">
              <div className="container" id="objetivos-interesses">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="container" id="card-interesses">
                      {personaliteInsightProgress ? (
                        <div>
                          <div>
                            <div className="row">
                              <div className="col-12">
                                <div className="container" id="objetivos-personalizar mZero">
                                  <div className="row justify-content-md-center">
                                    <div className="col-10 objetivos-personalizar--desc-usuario text-center">
                                      <img
                                        alt=""
                                        className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                                        src={GIF_LIT}
                                      />
                                      <h5 className="text-center">
                                        Personalize como você aprende melhor!
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="row justify-content-md-center mt-3">
                                    <div className="col-10 ">
                                      <div className="row">
                                        <div className="col-12 textareaTitle">
                                          <span>
                                            Copie e cole aqui textos que você tenha escrito em e-mails,
                                            mensagens de WhatsApp, posts que refletem como você escreve
                                            no dia a dia.
                                          </span>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-group col-12 textareaField">
                                          <textarea
                                            className="form-control"
                                            placeholder="O texto deve conter o mínimo de 600 palavras para que sua análise seja satisfatória.*"
                                            rows="7"
                                            maxLength="5000"
                                            value={aboutUser}
                                            onChange={evt =>
                                              this.setState({
                                                aboutUser: evt.target.value,
                                                contagemTextoPessoal: this._contadorDePalavras(
                                                  evt.target.value
                                                ),
                                                salvo: false,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="row justify-content-end">
                                          <p>
                                            <span className="blue">{contagemTextoPessoal}</span> palavras
                                            escritas
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row justify-content-md-center">
                                    <div className="col-10 ">
                                      <div className="row">
                                        <div className="col-12 textareaTitle">
                                          <span>
                                            Copie e cole aqui textos que você tenha escrito em e-mails,
                                            mensagens de WhatsApp, posts que refletem como você escreve
                                            em situações profissionais.
                                          </span>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-group col-12 textareaField">
                                          <textarea
                                            className="form-control"
                                            placeholder="O texto deve conter o mínimo de 600 palavras para que sua análise seja satisfatória.*"
                                            rows="7"
                                            maxLength="5000"
                                            value={aboutProfessional}
                                            onChange={evt =>
                                              this.setState({
                                                aboutProfessional: evt.target.value,
                                                contagemTextoProfissional: this._contadorDePalavras(
                                                  evt.target.value
                                                ),
                                                salvo: false,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="row justify-content-end">
                                          <p>
                                            <span className="blue">{contagemTextoProfissional}</span>{" "}
                                            palavras escritas
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row justify-content-md-center">
                                    <div className="col-10 ">
                                      <div className="row">
                                        <div className="col-12">
                                          <p id="instrucao-personalizar">
                                            * Se você encontrar problemas para prosseguir para a próxima
                                            tela, copie e cole seu texto no bloco de notas e insira ele
                                            novamente nos campos acima.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row justify-content-center"
                                    id="selecionar-personalizar"
                                  >
                                    <div className="col-10">
                                      <div className="row justify-content-between">
                                        <div className="col-6">
                                          <p>
                                            Para uma boa análise, o texto deve conter no mínimo 600
                                            palavras.
                                          </p>
                                          <p>
                                            Total de{" "}
                                            <span className="blue">
                                              {resultadoQuantidadeDePalavras} palavras
                                            </span>{" "}
                                            escritas
                                          </p>
                                          {/* <span className="analysis">{this.state.analise}</span> */}
                                        </div>
                                        <div className="col row align-items-center justify-content-end interest-saveSend">
                                          <button
                                            type="button"
                                            disabled={loading || salvo}
                                            onClick={this.saveRespostas}
                                            className="btn btn-blue btnSave-interest btn-FontWeight6"
                                          >
                                            {salvo ? "Salvo" : "Salvar respostas"}
                                          </button>
                                          <button
                                            type="button"
                                            disabled={loading}
                                            className="btn btn-blue btnSave-interest btn-FontWeight6"
                                            onClick={() => this.onAnalisar()}
                                          >
                                            Personalizar agora
                                          </button>
                                          <Link to="/home">
                                            <button
                                              type="button"
                                              className="btn btn-lightblue btn-FontWeight6 mr-3"
                                            >
                                              Personalizar depois
                                            </button>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <WatsonBadgeFooter />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Analisando />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(UserActions.PIActions, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(PersonalizarTexto)
