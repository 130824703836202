import React, { memo } from "react";

export default memo(({ data, goMicromoment }) => {
  const icon = type => {
    switch (type) {
      case "VIDEO":
        return <i className="fa fa-file-video-o" />;
      case "PDF":
        return <i className="fa fa-file-pdf-o" />;
      case "ASSESSMENT":
        return <i className="fa fa-memo" />;
      default:
        return null;
    }
  };
  
  const description = (type) => {
    switch (type) {
      case "VIDEO":
        return "Video";
      case "PDF":
        return "Documento PDF";
      case "ASSESSMENT":
        return "Avaliação";
      default:
        return null;
    }
  }

  return (
    <div
      role="button"
      onClick={() => goMicromoment(data)}
      className="card-wrapper-p"
    >
      <div className="card">
        <img className="card-img-top" src={data.cover} alt="course cape" />
        <div className="card-body" style={{ padding: ".6rem 1rem" }}>
          <h4 className="d-inline-block card-title">{data.description}</h4>

          <h5 className="card-subtitle mb-2 text-muted">
            {icon(data.type)}
            {description(data.type)}
          </h5>
          { data.time && <p className="card-text">{`${data.time} min`}</p> }
        </div>
      </div>
    </div>
  );
});
