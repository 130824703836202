import styled, { css } from "styled-components";
import { Card } from "react-bootstrap";
import { APPEARANCE } from "../../../constants";

export const CardContent = styled(Card.Body)`
  
  ${props => (props.appearance === APPEARANCE.POST) && css`
    padding-top: 0 !important;
    padding-left: 15px;

    > div > div {
      padding: 5px;
      background: yellow;
    }
    
    .content-button button {
      padding-left: 0;
      margin-right: 2px;
    }
    
  `}
  
`;
