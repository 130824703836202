import React from "react";
import { CardContent } from "./styles";

export default ({ child, appearance }) => {
  return (
    <>
      <CardContent appearance={appearance}>{child}</CardContent>
    </>
  );
};
