import React, { useContext } from 'react'

import CallEndIcon from "@material-ui/icons/CallEnd";

import { MeetingContext } from "../../../MeetingContext";
import ActionButton from "../ActionButton";

export default React.memo(() => {
  const { meeting, isExitingFromMeeting, setExitingFromMeeting } = useContext(MeetingContext);

  const handleLeaveMeeting = () => {
    // Prevent user from trying to leave multiple times
    if (isExitingFromMeeting) return;
    setExitingFromMeeting(true);

    meeting.leave().then(() => {
      meeting.webex.meetings.unregister().then();
    });
  };

  return (
    <ActionButton 
      edge="end" 
      title="Sair da reunião"
      aria-label="Sair da reunião"
      onClick={handleLeaveMeeting}>
      <CallEndIcon />
    </ActionButton>
  )
})
