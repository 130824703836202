import React from "react";
import { POSITION, SIZE, FONT_TYPE, TRANSFORM } from "../../../constants";
import Line from "../../basicComponents/Line";
import Text from "../../basicComponents/Text";
import ImageCard from "../../basicComponents/ImageCard";
import { Cards, AlignButtons } from "./styles";

export default ({ title, groupButtonAction, perLine, arrayOfCards }) => {
  return (
    <>
      <Line alignItems={POSITION.JUSTIFY}>
        <Text
          text={title}
          transform={TRANSFORM.UPPERCASE}
          fontType={FONT_TYPE.BOLD}
          size={SIZE.MEDIUM}
        />
        <AlignButtons>{groupButtonAction}</AlignButtons>
      </Line>
      <Cards>
        <ImageCard
          contentCard={arrayOfCards}
          perLine={perLine}
          textAlign={POSITION.LEFT}
        />
      </Cards>
    </>
  );
};
