
import styled from "styled-components";
import { } from "../../../constants";

export const Span = styled.span`
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  display: block;
  &:before {
    content: "";
    background: linear-gradient(90deg, var(--overflow-banner-primary) 0%, var(--overflow-banner-secondary) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
  }
`;

export const Description = styled.span`
  top: 0;
  left: 0;
  position: absolute;
  width: 65%;
  display: block;
  padding: 5px 15px 0;
  overflow: hidden;
  white-space: break-spaces;
  z-index: 1;
`;

export const ContentAtBottom = styled.span`
  position: absolute;
  left: 15px;
  bottom: 5px;
  width: calc(100% - 30px);
  label {
    min-height: auto;
    margin: 0;
  }
`;
