import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Function} action //action triggered when clicking on content
 * @param {String} option //text that will be displayed within the component option
**/

export default ({
  action = DEFAULT_VALUES.FUNCTION,
  option = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        action={action}
        option={option}
      />
    </>
  );
};
