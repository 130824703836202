/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { FaPlus, FaTimes, FaCheck } from 'react-icons/fa'
import swal from "@sweetalert/with-react"
import { toast } from 'react-toastify'
import { SELECT_TYPES } from '../../../utils/constants'
import { toastErrorAndWarningOptions } from '../../../utils/functions'
import { fetchSelectType } from '../../../business/onboarding'
import AcademicExperienceItem from './AcademicExperienceItem'
import AcademicExperienceForm from './AcademicExperienceForm'

import {
  Container,
  AddExperienceBox,
  Experience,
  AddButton,
  AddText,
  NotHaveBox,
  NotHaveButton,
  NotHaveText,
  ItemList,
} from './styles'

export default function AcademicExperience({
  data,
  updateData,
  withoutAcademicTraining,
  toggleWithoutAcademicTraining,
  showNotHaveWithoutAcademicTraining,
  errors = {}
}) {
  const [selectedItem, setSelectedItem] = useState(null)
  const [showFormGratuate, setShowFormGraduate] = useState(false)
  const [showFormPosGratuate, setShowFormPosGratuate] = useState(false)
  const [showFormCourse, setShowFormCourse] = useState(false)
  const [courses, setCourses] = useState([])
  const [graduatiosTypes, setGraduatiosTypes] = useState([])
  const [posGraduatiosTypes, setposGraduatiosTypes] = useState([])
  const [teachingInstitutions, setTeachingInstitutions] = useState([])
  const [loadingInstitutions, setLoadingInstitutions] = useState(false)
  const [loadingCourses, setLoadingCourses] = useState(false)

  async function fetchGraduatiosTypes() {
    try {
      const response = await fetchSelectType(SELECT_TYPES.GRADUATIONS_TYPES)
      setGraduatiosTypes(response)
    } catch (error) {
      toast.error('Erro ao buscar tipos de graduação', toastErrorAndWarningOptions)
    }
  }

  async function fetchPosGraduatiosTypes() {
    try {
      const response = await fetchSelectType(SELECT_TYPES.POSTGRADUATIONS_TYPES)
      setposGraduatiosTypes(response)
    } catch (error) {
      toast.error('Erro ao buscar tipos de graduação', toastErrorAndWarningOptions)
    }
  }

  async function fetchCourses(type, value) {
    try {
      setLoadingCourses(true)
      const response = await fetchSelectType(type, value)
      setCourses(response.map(item => item.wx_optionvalue))
      setLoadingCourses(false)
    } catch (error) {
      toast.error('Erro ao buscar cursos de graduação', toastErrorAndWarningOptions)
    }
  }


  async function fetchTeachingInstitutions(value) {
    try {
      setLoadingInstitutions(true)
      const response = await fetchSelectType(SELECT_TYPES.TEACHING_INSTITUTIONS, value)
      setTeachingInstitutions(response.map(item => item.wx_optionvalue))
      setLoadingInstitutions(false)
    } catch (error) {
      toast.error('Erro ao buscar instituições de ensino', toastErrorAndWarningOptions)
      setLoadingInstitutions(false)
    }
  }

  useEffect(() => {
    fetchGraduatiosTypes()
    fetchPosGraduatiosTypes()
  }, [])

  function addExperience(type, isSelectedItem) {

    const graduates = data.filter(item => !item.bf_postgraduate);

    switch (type) {
      case 'graduate':
        setShowFormGraduate(true)
        setShowFormPosGratuate(false)
        break;
      case 'course':
        setShowFormCourse(true)
        setShowFormGraduate(false)
        setShowFormPosGratuate(false)
        break;
      default:

        if (!graduates.length) {
          return
        }
        setShowFormPosGratuate(true)
        setShowFormGraduate(false)
        setShowFormCourse(false);
        break;
    }

    if (!isSelectedItem) {
      setSelectedItem(null)
    }
  }

  function closeForm(type) {
    switch (type) {
      case 'graduate':
        setShowFormGraduate(false)
        break;
      case 'course':
        setShowFormCourse(false)
        break;
      default:
        setShowFormPosGratuate(false)
        break;
    }
    setSelectedItem(null)
  }

  function handleSelectItem(item, type) {
    setSelectedItem(item)
    addExperience(type, true)
  }

  function handleRemoveItem(item, type) {

    const findElement = data.find(element => element.bf_postgraduate)
    const totalGraduate = data.filter(element => !element.bf_postgraduate)
    if (findElement && type === 'graduação' && totalGraduate.length === 1) {
      swal({
        title: 'Ops, não entendemos sua solicitação',
        text: 'Como você possui uma pós-graduação, necessitamos saber qual a sua graduação',
        // buttons: ["Cancelar", "Confirmar"],
        buttons: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "btn-alert btn-alert-confirm-onboarding",
          },
        }
      })
      return
    }

    swal({
      title: `Deseja realmente remover esta ${type}?`,
      text: `Ao confirmar você perdera todos os dados referente a esta ${type}. Deseja continuar?`,
      // buttons: ["Cancelar", "Confirmar"],
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
          className: "btn-alert"
        },
        confirm: {
          text: "Continuar",
          value: true,
          visible: true,
          className: "btn-alert btn-alert-confirm-onboarding",
        },
      }
    }).then(value => {
      if (value) {
        const newData = data.filter(i => i.id !== item.id)
        updateData(newData)
      }
    })
  }

  function handleSubmitFormAcademicExperience(dados) {

    const newErros = errors;
    let newData = [...data]
    const index = newData
      .findIndex(i => i.id === dados.id)
    if (index > -1) {
      newData[index] = dados
    } else {
      newData = [...newData, dados]
    }

    newErros[index] = null

    updateData(newData, newErros)
    setShowFormGraduate(false)
    setShowFormPosGratuate(false)
    setShowFormCourse(false);
  }

  function disabledPosGraduate() {
    let result = true

    const findElement = data.find(element => !element.bf_postgraduate)
    if (findElement) {
      result = false
    }
    return result
  }

  async function handleSearch(value, type) {
    switch (type) {
      case 'institutions':
        fetchTeachingInstitutions(value)
        break;
      case 'posgraduate-courses':
        fetchCourses(SELECT_TYPES.POSTGRADUATIONS_COURSES, value)
        break;
      case 'graduate-courses':
        fetchCourses(SELECT_TYPES.GRADUATIONS_COURSES, value)
        break
      default:
        break;
    }
  }

  return (
    <Container>
      {!withoutAcademicTraining && (
        <>
          <Experience>
            {!showFormGratuate && (
              <AddExperienceBox>
                <AddButton id="add-graduate-button" onClick={() => addExperience('graduate')} type="button"><FaPlus size="14px" /></AddButton>
                <AddText onClick={() => addExperience('graduate')}>Adicionar Graduação</AddText>
              </AddExperienceBox>
            )}

            {showFormGratuate && (
              <AcademicExperienceForm
                item={selectedItem}
                options={graduatiosTypes}
                courses={courses}
                institutions={teachingInstitutions}
                submitForm={handleSubmitFormAcademicExperience}
                onSearch={handleSearch}
                loadingInstitutions={loadingInstitutions}
                loadingCourses={loadingCourses}
                cancel={() => closeForm('graduate')}
              />
            )}

            <ItemList>
              {data
                .filter(item => !item.bf_postgraduate && item.wx_formationtype !== 'Curso')
                .map((item) => (
                  <AcademicExperienceItem
                    error={errors[`${data.map(d => d.id).indexOf(item.id)}`]}
                    item={item}
                    key={item.id}
                    selectItem={() => handleSelectItem(item, 'graduate')}
                    removeItem={() => handleRemoveItem(item, 'graduação')}
                  />
                ))
              }
            </ItemList>
          </Experience>
          <Experience>
            {!showFormPosGratuate && (
              <AddExperienceBox>
                <AddButton id="add-posgraduate-button" disabled={disabledPosGraduate()} onClick={() => addExperience('posgraduate')} type="button"><FaPlus size="14px" /></AddButton>
                <AddText disabled={disabledPosGraduate()} onClick={() => addExperience('posgraduate')}>Adicionar Pós-Graduação</AddText>
              </AddExperienceBox>
            )}
            {showFormPosGratuate && (
              <AcademicExperienceForm
                item={selectedItem}
                options={posGraduatiosTypes}
                courses={courses}
                institutions={teachingInstitutions}
                isPosGraduate
                submitForm={handleSubmitFormAcademicExperience}
                onSearch={handleSearch}
                loadingInstitutions={loadingInstitutions}
                loadingCourses={loadingCourses}
                cancel={() => closeForm('posgraduate')}
              />
            )}
            <ItemList>
              {data
                .filter(item => item.bf_postgraduate)
                .map((item, index) => (
                  <AcademicExperienceItem
                    error={errors[`${data.map(d => d.id).indexOf(item.id)}`]}
                    item={item}
                    key={index}
                    selectItem={() => handleSelectItem(item, 'posgraduate')}
                    removeItem={() => handleRemoveItem(item, 'pós-graduação')}
                  />
                ))
              }
            </ItemList>
          </Experience>
          <Experience>
            {!showFormCourse && (
              <AddExperienceBox>
                <AddButton id="add-graduate-button" onClick={() => addExperience('course')} type="button"><FaPlus size="14px" /></AddButton>
                <AddText onClick={() => addExperience('course')}>Adicionar Cursos e Treinamentos</AddText>
              </AddExperienceBox>
            )}

            {showFormCourse && (
              <AcademicExperienceForm
                item={selectedItem}
                options={graduatiosTypes}
                courses={courses}
                institutions={teachingInstitutions}
                submitForm={handleSubmitFormAcademicExperience}
                isCourse
                onSearch={handleSearch}
                loadingInstitutions={loadingInstitutions}
                loadingCourses={loadingCourses}
                cancel={() => closeForm('course')}
              />
            )}

            <ItemList>
              {data
                .filter(item => item.wx_formationtype === 'Curso')
                .map((item) => (
                  <AcademicExperienceItem
                    error={errors[`${data.map(d => d.id).indexOf(item.id)}`]}
                    item={item}
                    key={item.id}
                    selectItem={() => handleSelectItem(item, 'course')}
                    removeItem={() => handleRemoveItem(item, 'course')}
                  />
                ))
              }
            </ItemList>
          </Experience>
        </>
      )}

      {showNotHaveWithoutAcademicTraining && (
        <Experience>
          <NotHaveBox >
            <NotHaveButton id="not-academic" onClick={toggleWithoutAcademicTraining} withoutAcademicTraining={withoutAcademicTraining} type="button">
              {withoutAcademicTraining ? (
                <FaCheck size="14px" />
              ) : (
                <FaTimes size="14px" />
              )}
            </NotHaveButton>
            <NotHaveText onClick={toggleWithoutAcademicTraining}>Não possuo formação acadêmica</NotHaveText>
          </NotHaveBox>
        </Experience>
      )}
    </Container>
  )
}