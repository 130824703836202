import { momentLocalizer } from "react-big-calendar";
import { Types } from "./types";

const INITIAL_STATE = {
  loading: false,
  called: false,
  error: null,
  allMembers: [
    {
      type: "Administrador",
      items: [
        {
          id: 82,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        }
      ]
    },
    {
      type: "Moderadores",
      items: [
        {
          id: 8,
          name: "Dolores Sanchez",
          role: { id: 1, name: "Moderador" },
          img: "https://picsum.photos/id/1010/40/40",
          memberSince: "09/01/2009"
        },
        {
          id: 1,
          name: "Denis Kannenberg",
          role: { id: 1, name: "Moderador" },
          img: "https://picsum.photos/id/1010/40/40",
          memberSince: "09/01/2009"
        }
      ]
    },
    {
      type: "Alunos",
      items: [
        {
          id: 293,
          name: "Márcio Guedes",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1011/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 7,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 214,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        },
        {
          id: 294,
          name: "Márcio Guedes",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1011/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 171427,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 224,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        },
        {
          id: 9875,
          name: "Márcio Guedes",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1011/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 2,
          name: "Oliver Noronha",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 2204,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        },
        {
          id: 290,
          name: "Márcio Guedes",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1011/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 202,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 5,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        },

        {
          id: 170,
          name: "Eduardo Baeremeker",
          role: { id: 2, name: "Aluno" },
          img: "https://picsum.photos/id/1025/40/40",
          memberSince: "09/01/2009"
        }
      ]
    }
  ],
  memberDetail: {
    id: 0,
    name: "Marcos Sanchez",
    memberSince: "12/12/12",
    role: [
      {
        id: 1,
        name: "Analista de Mercado",
        company: "Quantic Code"
      }
    ],
    img: "https://i.pravatar.cc/300?img=8",
    totalCompleted: 9,
    totalInProgress: 12,
    totalParticipate: 19,
    totalSolicitations: 3,
    totalInvites: 5,
    totalNotifications: 8,
    notifications: [
      {
        id: 0,
        type: "FINANCIAL_PENDENCY",
        title: "Pendências Financeiras",
        totalNotifications: 2,
      },
      {
        id: 1,
        type: "EVALUATION_PENDING",
        title: "Avaliações Pendentes",
        totalNotifications: 5,
      },
      {
        id: 2,
        type: "",
        title: "Solicitações Pendentes",
        totalNotifications: 3,
      },
      {
        id: 3,
        type: "EVALUATION_RESULT",
        title: "Resultado de Avaliação",
        totalNotifications: 28,
      },
      {
        id: 4,
        type: "PROFILE_CHANGES",
        title: "Novas Alterações",
        totalNotifications: 1238,
      },
      {
        id: 5,
        type: "NOTIFICATION",
        title: "Novas Notificações",
        totalNotifications: 8,
      },
      {
        id: 6,
        type: "FINANCIAL_PENDENCY",
        title: "Pendências Financeiras",
        totalNotifications: 21,
      }
    ],
    about: "Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Adipiscing tristique risus nec feugiat in. Augue neque gravida in fermentum et. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
    expertises: [
      {
        id: 0,
        name: "Finanças Pessoais",
        link: "#"
      },
      {
        id: 1,
        name: "Gestão de Pessoas",
        link: "#"
      },
      {
        id: 2,
        name: "Gestão de Seguros",
        link: "#"
      },
      {
        id: 3,
        name: "Marketing e Inteligência de Mercado",
        link: "#"
      }
    ],
    formations: [
      {
        id: 1,
        title: "Economia e Gestão Empresarial (Técnico)",
        institution: "Fundação Getúlio Vargas",
        start: "01/2015",
        end: "12/2018"
      },
      {
        id: 2,
        title: "Economia e Gestão Empresarial (Bacharelado)",
        institution: "Fundação Getúlio Vargas",
        start: "01/2015",
        end: "12/2018"
      },
      {
        id: 3,
        title: "Economia e Gestão Empresarial (Técnico)",
        institution: "Fundação Getúlio Vargas",
        start: "01/2015",
        end: "12/2018"
      }
    ],
    experiences: [
      {
        id: 1,
        title: "Diretor Comercial (Atual)",
        company: "Quantic Code",
        start: "01/2015",
        end: "01/2021"
      },
      {
        id: 2,
        title: "Desenvolvedor Pleno",
        company: "Quantic Code",
        start: "01/2015",
        end: "01/2021"
      }
    ],
    socials: [
      {
        id: 1,
        type: "Linkedin",
        link: "www.linkedin.com/in/usuario"
      }
    ],
    course: {
      id: 1,
      company: "Mercer",
      title: "Criptomoedas e Mercado Bitcoin",
      moduleProgress: 3,
      totalModules: 35,
      totalProgress: 22,
      start: "09 Mar 2021",
      end: ""
    },
    trail: {
      id: 1,
      title: "Moedas e Mercado",
      totalProgress: "100",
      rating: "10.0",
      dateConclusion: "01 Janeiro 2020"
    },
    group: [
      {
        id: 1,
        title: "Criptomoedas e Mercado Bitcoin",
        totalProgress: 70
      }
    ],
    awardsTitle: [
      {
        title: "Conquista"
      },
      {
        title: "Descrição"
      },
      {
        title: "Tempo"
      }
    ],
    awards: [
      {
        conquest: "1XP",
        description: "A cras semper auctor neque vitae.",
        time: "Há 2 horas"
      },
      {
        conquest: "1XP",
        description: "Semper auctor neque vitae.",
        time: "Há 2 horas"
      },
      {
        conquest: "1XP",
        description: "Diam cras semper auctor neque vitae.",
        time: "Há 2 horas"
      },
      {
        conquest: "1XP",
        description: "A cras semper auctor neque vitae.",
        time: "Há 2 horas"
      }
    ],
    history: [
      {
        type: "notification",
        title: "O professor respondeu a sua dúvida",
        subtitle: "Há 2 horas",
        privacy: "Público",
        mentionType: "Módulo Trabalho em Trabalho em Equipe do",
        mentionIn: "Curso de Relacionamento Interpessoal e Gestão de Conflitos",
        description: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi",
        favorited: null
      },
      {
        type: "group-sugestion",
        title: "Quid ex ea commodi consequatur quis quid ex",
        subtitle: "Sugestão de Grupo",
        privacy: "Privado",
        mentionType: "",
        mentionIn: "",
        description: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi",
        totalMembers: 139,
        administrator: "Prof. Dr. Adriano Mussa Sobrenome",
        favorited: null
      },
      {
        type: "course-sugestion",
        title: "Business Intelligence Nostrumcerce quis",
        subtitle: "Sugestão de Curso",
        privacy: "Público",
        mentionType: "",
        mentionIn: "",
        description: "Cerce cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi",
        image: "https://lit.com.br/site/wp-content/uploads/2020/07/Banners_site_LIT-atualizacao-300x148.jpg",
        totalMembers: 2600,
        totalModules: 45,
        totalHours: 150,
        administrator: "Prof. Dr. Adriano Mussa Sobrenome",
        favorited: true
      },
      {
        type: "course-sugestion",
        title: "Intelligence Nostrumcerce quis Business mi",
        subtitle: "Sugestão de Curso",
        privacy: "Público",
        mentionType: "",
        mentionIn: "",
        description: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi",
        image: "https://lit.com.br/site/wp-content/uploads/2020/08/Home-Site-LIT-3.png",
        totalMembers: 1200,
        totalModules: 2,
        totalHours: 10,
        administrator: "Prof. Dr. Nome Sobrenome",
        favorited: true
      },
      {
        type: "course-sugestion",
        title: "Intelligence Nostrumcerce Quis Business",
        subtitle: "Sugestão de Curso",
        privacy: "Privado",
        mentionType: "",
        mentionIn: "",
        description: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi",
        image: "https://static.freemake.com/blog/wp-content/uploads/2015/10/how-to-embed-video-1000x600.jpg",
        video: "https://lit.com.br/site/wp-content/uploads/2020/07/Lit_HeaderMP4_2.mp4",
        totalMembers: 800,
        totalModules: 4,
        totalHours: 15,
        administrator: "Prof. Dr. Mussa Sobrenome",
        favorited: true
      },
      {
        type: "mention",
        title: "Seu post recebeu uma resposta",
        subtitle: "Há 16 minutos",
        privacy: "Privado",
        mentionType: "Grupo de Discussão",
        mentionIn: "Morbi tristique senectus et netus et malesuada fames ac turpis egestas",
        description: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi, pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras lacus mi, maximus vitae posuere eu, congue malesuada nisl. Morbi tristique senectus et netus et malesuada fames ac turpis egestas cras lacus mi",
        favorited: null
      },
    ],
    evaluation: [
      {
        type: "evaluation",
        title: "AVALIAÇÃO - Trabalho Individual",
        subtitle: "Seu prazo está acabando",
        privacy: "",
        mentionType: "AVALIAÇÃO - Módulo Trabalho em Equipe do",
        mentionIn: "Curso de Relacionamento Interpessoal e Gestão de Conflitos",
        description: "",
        start: "08/03/2021",
        end: "20/03/2021",
        timeLeft: "5 dias",
        media: ""
      },
      {
        type: "evaluation",
        title: "AVALIAÇÃO - Trabalho Individual",
        subtitle: "Sua Nota final foi divulgada",
        privacy: "",
        mentionType: "AVALIAÇÃO - Módulo Trabalho em Equipe do",
        mentionIn: "Curso de Relacionamento Interpessoal e Gestão de Conflitos",
        description: "",
        start: "08/03/2021",
        end: "15/03/2021",
        timeLeft: "",
        media: "9,5"
      },
      {
        type: "evaluation",
        title: "AVALIAÇÃO - Trabalho Individual",
        subtitle: "Seu prazo está acabando",
        privacy: "",
        mentionType: "AVALIAÇÃO - Módulo Trabalho em Equipe do",
        mentionIn: "Curso de Gestão de Conflitos",
        description: "",
        start: "08/03/2021",
        end: "18/03/2021",
        timeLeft: "3 dias",
        media: ""
      }
    ],
    members: [
      {
        name: "Eduardo Baeremeker Sanchez",
        role: "Aluno",
        img: "https://i.pravatar.cc/150?img=1"
      },
      {
        name: "Dolores Sanchez Sanchez",
        role: "Moderador",
        img: "https://i.pravatar.cc/150?img=2"
      },
      {
        name: "Denis Kannenberg Sanchez",
        role: "Moderador",
        img: "https://i.pravatar.cc/150?img=3"
      },
      {
        name: "Márcio Guedes Sanchez",
        role: "Aluno",
        img: "https://i.pravatar.cc/150?img=4"
      },
      {
        name: "Eduardo Baeremeker Sanchez",
        role: "Aluno",
        img: "https://i.pravatar.cc/150?img=5"
      },
      {
        name: "Dolores Sanchez Sanchez",
        role: "Moderador",
        img: "https://i.pravatar.cc/150?img=6"
      },
      {
        name: "Denis Kannenberg Sanchez",
        role: "Moderador",
        img: "https://i.pravatar.cc/150?img=7"
      },
      {
        name: "Márcio Guedes Sanchez",
        role: "Aluno",
        img: "https://i.pravatar.cc/150?img=8"
      },
      {
        name: "Eduardo Baeremeker Sanchez",
        role: "Aluno",
        img: "https://i.pravatar.cc/150?img=9"
      },
      {
        name: "Dolores Sanchez Sanchez",
        role: "Moderador",
        img: "https://i.pravatar.cc/150?img=10"
      },
      {
        name: "Denis Kannenberg Sanchez",
        role: "Moderador",
        img: "https://i.pravatar.cc/150?img=11"
      },
      {
        name: "Márcio Guedes Sanchez",
        role: "Aluno",
        img: "https://i.pravatar.cc/150?img=12"
      }
    ]
  }
};

export default function members(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PENDING_INVITATIONS_REQUEST:
      return {
        ...state,
        loading: false,
        called: true,
        data: action.payload.data
      };

    default:
      return state;
  }
}
