/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"


class AcessarCertificado extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody } = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                3. Posso acessar minhas notas e certificado após o final da minha assinatura?
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                 <p>
                    Fique tranquilo&#40;a&#41;! Mesmo que não seja mais aluno ou aluna do LIT, você poderá acessar a nota e o 
                    <br />
                    certificado do curso concluído com seu login e senha. Basta clicar na opção Ver meus Certificados.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default AcessarCertificado;
