import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p#text-upload {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 5px;
    
    justify-content: center;

    svg {
      margin-left: 10px;
      font-size: 30px;
    }
  }
`;

export const DropZone = styled.div`
  width: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;

  padding: 15px 0;

  &:hover {
    border-color: #1890ff;
  }

  p {
    margin: 0;
  }
`;

export const LoadingWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    animation: ${rotate360} 1s linear infinite;
    margin-right: 5px;
  }
`;

export const SuccessWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    margin-right: 5px;
  }
`;
