import styled, { css } from "styled-components";
import { SIZE } from "../../../constants";

export const Link = styled.a`
  
  ${props => (props.size === SIZE.SMALL) && css`
    font-size: 12px;
    cursor: pointer;
    transition: ease .3s all;
    outline: none;
    color: var(--white)!important;
    &: hover {
      cursor: pointer;
      text - decoration: none;
    }
  `}

  ${props => (props.size === SIZE.MEDIUM) && css`
    font-size: 16px;
    cursor: pointer;
    transition: ease .3s all;
    outline: none;
    color: ${props.color};
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
    @media (max-width: 579px) {
      font-size: 14px;
    }
  `}

  ${props => (props.size === SIZE.LARGE) && css`
    font-size: 18px;
    cursor: pointer;
    transition: ease .3s all;
    outline: none;
    color: ${props.color};
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
    @media (max-width: 579px) {
      font-size: 16px;
    }
  `}
  
`;
