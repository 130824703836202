import styled, {css} from 'styled-components';

export const Container = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  margin-right: 20px;
  margin-top: ${props => props.viewMode ? '6px' : 0};
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: ${props => {
        if(props.textPosition === 'right'){
          return 'none';
        }
        return '28px';
      }};
    
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      ${props => {
        if(props.textPosition === 'right'){
          return (
            css`
              right: -26px;
            `
          );
        }
        return (
          css`
            left: 0;
         `
        );
      }}

      top: 50%;
      margin-top: -8px;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${props => props.color ? props.color : '#99d278'};
      
      position: absolute;
      top: 50%;
      margin-top: -5px;
      ${props => {
        if(props.textPosition === 'right'){
          return (
            css`
              right: -23px;
            `
          );
        }
        return (
          css`
            left: 3px;
         `
        );
      }}
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
`
