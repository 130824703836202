import React from "react";
import { FONT_TYPE, SIZE } from "../../../constants";
import Text from "../Text";
import formatDate from "../../../../utils/formatDate";
import {
  Table,
  TableHeader,
  HeaderContent,
  TableBody,
  BodyContent,
  TableRow
} from "./styles";

export default ({ header, content, appearance }) => {
  return (
    <>
      <Table>
        {header && (
          <TableHeader>
            <TableRow appearance={appearance}>
              {header.map(th => (
                <HeaderContent>
                  <Text
                    text={th.title}
                    fontType={FONT_TYPE.BOLD}
                    size={SIZE.SMALL}
                  />
                </HeaderContent>
              ))}
            </TableRow>
          </TableHeader>
        )}
        <TableBody>
          {content.map(td => (
            <TableRow appearance={appearance}>
              <BodyContent>
                <Text
                  text={td.conquest}
                  fontType={FONT_TYPE.BOLD}
                  size={SIZE.SMALL}
                />
              </BodyContent>
              <BodyContent>
                <Text text={td.description} size={SIZE.SMALL} />
              </BodyContent>
              <BodyContent>
                <Text
                  text={formatDate(td.time)}
                  fontType={FONT_TYPE.ITALIC}
                  size={SIZE.SMALL}
                />
              </BodyContent>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
