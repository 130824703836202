import styled from "styled-components";
import { } from "../../../constants";

export const Content = styled.div`
  padding: 1rem 0;
  display: block;
  width: 100%;
  overflow: hidden;
  margin: 2rem 0;
  column-span: all;
`;

export const Title = styled.div`
  margin-bottom: 2rem;
  position: relative;
  span {
    display: flex;
    align-items: center;
    line-height: 1;
  }
  button {
    padding-right: 20px;
    position: relative;
    margin: 15px 0 0;
    i {
      transition: all .3s linear;
      position: absolute;
      right: 5px;
      top: -1px;
    }
    &:hover i {
      right: 0;
    }
  }
  @media (max-width: 991px) {
    label { margin-left: 50px!important; }
  }
  @media (max-width: 767px) {
    span { margin-top: 10px; }
  }
  @media (max-width: 579px) {
    .content-button { position: absolute; top: 0; right: 0; }
  }
`;

export const Icon = styled.div`
  > span {
    position: relative;
    top: 7px;
  }
  label {
    margin-left: 20px;
    margin-bottom: -5px;    
    width: 100%;
    text-align: left;
    padding: 0;
  }
  @media (max-width: 1280px) {
    label { margin-left: 60px!important; }
  }
`;

