import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import Tooltip from "../Tooltip"
import DetectIE from "./DetectIE"
import NotifyService from "../../utils/NotifyService"
import { Creators as NotificationsActions } from "../../store/ducks/notifications/actions"
import AlertRestrict from "../Home/AlertRestrict"
import AlertRestrictLitPass from "../Home/AlertRestrictLitPass"
import AlertConfirmEmaill from "../AlertConfirmEmail"
import api from "../../utils/api"

const { FB } = window

export default WrappedComponent => {
  class RequireAuth extends PureComponent {
    static contextTypes = {
      router: PropTypes.shape(),
    }

    componentWillMount() {
      const notif = new NotifyService()
      const { router } = this.context
      const { getNotificationRequest } = this.props
      const idProfile = localStorage.getItem("idProfile")
      const savedOneSignal = JSON.parse(localStorage.getItem("savedOneSignal"))
      const voucher = localStorage.getItem("voucherAtivo")

      if (process.env.NODE_ENV !== "development") {
        // if (voucher !== "true") {
        //   router.history.push("/pagamento")
        //   return
        // }

        if (FB && FB.AppEvents) {
          FB.AppEvents.logPageView()
        }
      }

      // this.isOnline()

      if (!savedOneSignal) {
        notif.syncIdOneSignal(parseInt(idProfile, 10))
      }

      notif.onDisplay(notify => {
        console.log("OneSignal notification displayed:", notify)
        getNotificationRequest("navbar")
      })

      notif.listenerOpened(notify => {
        console.log("Received NotificationOpened:")
        console.log(notify)
        getNotificationRequest("navbar")
        const { origem, id } = notify.data
        switch (origem) {
          case "socialPost":
          case "socialComentario":
          case "socialResposta":
            router.history.push(`/grupo/${id}`)
            break
          case "cursoNovo":
            router.history.push(`/curso/${id}`)
            break
          default:
            break
        }
      })
    }

    isOnline = async () => {
      api.get("onplataform", {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
    }

    render() {
      return (
        <React.Fragment>
          <Tooltip />
          <AlertConfirmEmaill />
          {!document.location.pathname.includes("curso") && <DetectIE />}
          <AlertRestrict />
          <AlertRestrictLitPass />
          <WrappedComponent {...this.props} />
        </React.Fragment>
      )
    }
  }

  const mapDispatchToProps = dispatch => bindActionCreators(NotificationsActions, dispatch)

  return connect(
    null,
    mapDispatchToProps
  )(RequireAuth)
}
