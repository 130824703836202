import React from "react";
import { Range } from "./styles";

export default ({ getAriaValueText, defaultValue, marks, step }) => {
  return (
    <>
      <Range
        getAriaValueText={getAriaValueText}
        defaultValue={defaultValue}
        marks={marks}
        step={step}
      />
    </>
  );
};
