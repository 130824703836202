import styled from "styled-components";

export const TextError = styled.p`
  color: red;
  margin: 0;
  font-weight: bold;
`;
export const CardError = styled.p`
  font-size: 13px;
  color: red;
  margin-top: -1rem;
`;
export const Bold = styled.span`
  font-weight: bold;
`;
export const ButtonLinks = styled.button`
  color: #27aae1;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const CpfContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`;

export const LinkBlue = styled.a`
  color: #27aae1;
  text-decoration: none;
`;
