import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  margin-top: 50px;

  a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid #ddd;
  }

  a.active {
    background-color: #42b3c9;
    color: white;
    border: 1px solid #42b3c9;
  }

  a:hover:not(.active) {
    background-color: #ddd;
  }

  a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  a:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  a.disabled {
    background: #d9d9d9;
    cursor: not-allowed;
  }
`;
