/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */

import React from "react";
import PropTypes from "prop-types";

import LitCustomEditor from "ckeditor5-custom-build";
import { Container } from "./styles";

import UploadAdapter from "./UploadAdapter";

function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function(loader) {
    return new UploadAdapter(loader);
  };
}

class LitEditor extends React.Component {
  editorContainer = React.createRef();

  toolbarContainer = React.createRef();

  editor = undefined;

  componentDidMount() {
    LitCustomEditor.create(this.editorContainer.current, {
      extraPlugins: [CustomUploadAdapterPlugin]
    })
      .then(newEditor => {
        const { data } = this.props;

        this.editor = newEditor;

        this.editor.setData(String(data));

        this.toolbarContainer.current.appendChild(
          this.editor.ui.view.toolbar.element
        );

        this.editor.model.document.on("change:data", this.handleChange);
      })
      .catch(error => {
        console.log("There was a problem initializing the editor.", error);
      });
  }

  componentDidUpdate() {
    const { data } = this.props;
    if (this.editor && data !== this.editor.getData()) {
      this.editor.setData(data);
    }
  }

  handleChange = () => {
    const { onChange } = this.props;
    const newData = this.editor.getData();
    onChange(newData);
  };

  render() {
    const { 
      onBlur, 
      border, 
      showToolbar = true, 
      minHeight, 
      ...rest 
    } = this.props;
    return (
      <Container onBlur={onBlur} {...rest}>
        <div 
          hidden={!showToolbar}
          ref={this.toolbarContainer} 
        />
        <div
          style={{
            border: `${border ? 1 : 0}px solid #c4c4c4`,
            borderTop: 0,
            backgroundColor: "#fff",
            minHeight: `${minHeight || 80}px`
          }}
          ref={this.editorContainer}
        />
      </Container>
    );
  }
}

LitEditor.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

LitEditor.defaultProps = {
  data: "",
  onChange: () => {},
  onBlur: () => {}
};

export default LitEditor;
