import React, {useState} from 'react'
import { TitleRecommendations, RecommendationsTitleBox, ButtonCollapse } from './styles'

export default function RecommendationsList({recommendations, totalDiscount}) {

  const [open, setOpen] = useState(false)

  function toggleOpen() {
    setOpen((state) => !state)
  }

  return (
    <>
      {(recommendations && recommendations.length > 0) && <div className="accordion" id="accordionRecommendation">
        <div id="headingOne">
          <h5 className="mb-0">
            <ButtonCollapse
              onClick={toggleOpen}
              className="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <RecommendationsTitleBox>
                <TitleRecommendations>Total de desconto: <b>{totalDiscount}%</b></TitleRecommendations>
              </RecommendationsTitleBox>
              {open && (
                <i className="fa fa-chevron-up" aria-hidden="true" />
              )}
              {!open && (
                <i className="fa fa-chevron-down" aria-hidden="true" />
              )}
            </ButtonCollapse>
          </h5>
        </div>

        <div id="collapseTwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordionRecommendation">

          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
              <tr>
                <th width="20%">Nome</th>
                <th width="30%">Email</th>
                <th width="30%">Data</th>
                <th>Desconto</th>
              </tr>
              </thead>
              <tbody>
              {recommendations.map((recomendation, index) => (
                <tr key={index}>
                  <td>{recomendation.name}</td>
                  <td>{recomendation.email}</td>
                  <td>{recomendation.date}</td>
                  <td>{recomendation.discount}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>

        </div>

      </div>}
    </>
  )
}
