import React, { useContext } from "react";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import { makeStyles } from "@material-ui/core/styles";
import * as Styles from "./styles";
import MuteMemberButton from "./Buttons/MuteMemberButton";
import MuteAllMembersButton from "./Buttons/MuteAllMembersButton";

import { MeetingContext } from "../../MeetingContext";

const StyledList = Styles.List(List);
const StyledInnerMembersBox = Styles.InnerMembersBox(Box);

export default React.memo(function MeetingMembers({ members }) {
  const { hasHostPermissions } = useContext(MeetingContext);

  const classes = makeStyles(() => {
    return {
      successIcon: {
        color: "#4caf50"
      },
      errorIcon: {
        color: "#F44336"
      }
    };
  })();

  return (
    <>
      <Divider />
      <StyledInnerMembersBox flexGrow={1}>
        <StyledList aria-label="participants">
          {members.map(({ name, isSelf, isAudioMuted }, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index} divider style={{ padding: "4px 16px" }}>
                <ListItemText primary={`${name}${isSelf ? " (Eu)" : ""}`} />
                {hasHostPermissions && !isSelf && <MuteMemberButton />}
                {isAudioMuted ? (
                  <MicOffIcon fontSize="small" className={classes.errorIcon} />
                ) : (
                  <MicIcon fontSize="small" className={classes.successIcon} />
                )}
              </ListItem>
            );
          })}
        </StyledList>
      </StyledInnerMembersBox>
      {hasHostPermissions && <Divider /> && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={60}
        >
          <MuteAllMembersButton />
        </Box>
      )}
    </>
  );
});
