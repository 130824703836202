import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PaulBar from '../Home/PaulBar';
import BarraPi from '../CursoNovo/BarraPi';
import spinner from '../../images/paul_loading.gif';
import CourseTabs from '../CursoNovo/CourseTabs';
import Progress from '../CursoNovo/Progress';

import { Creators as CourseActions } from '../../store/ducks/newCourse/actions';

const styles = {
  main: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  navbar: {
    position: 'fixed',
    top: 0,
    zIndex: 205,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all .3s ease-out'
  },
  sidebar: {
    position: 'fixed',
    zIndex: 1020,
    left: 0,
    top: 0,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '400px',
    transition: 'all .3s ease-out',
    backgroundColor: '#eeeeee',
    borderRadius: 10,
    padding: '0 15px 20%'
  },
  content: {
    position: 'relative',
    top: 52,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    transition: 'all .3s ease-out'
  },
  loading: {
    position: 'fixed',
    zIndex: 201,
    top: '66px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    filter: 'blur(0px)',
    background: 'transparent',
    height: 'calc((100% - 66px) - 75px)'
  },
  erroCobranca: {
    background: 'crimson',
    display: 'flex',
    color: 'white',
    fontWeight: 'bold',
    justifyContent: 'center',
    padding: '0.5em'
  }
};

class WithSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      defaultSidebarWidth,
      defaultNavbarHeight,
      isNewCourse
    } = this.props;
    this.state = {
      // o tamanho da sidebar em pixels
      sidebarWidth: defaultSidebarWidth,
      navbarHeight: defaultNavbarHeight,
      barraPi: true,
      selectedTab: 'course',
      isNewCourse
    };
  }

  componentWillMount() {
    const { navbarHeight } = this.state;
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => {
      if (this.navbar && this.navbar.offsetHeight) {
        const height = this.navbar.offsetHeight;

        if (height !== navbarHeight) {
          this.setState({ navbarHeight: height });
        }
      }
    }, 200);
  }

  componentDidMount() {
    const { selectedTab } = this.state;

    if (selectedTab === 'course') {
      this.saveSidebarWidth();
      this.saveNavbarHeight();
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   this.saveSidebarWidth()
  //     this.saveNavbarHeight()
  //   console.log('prevState', prevState)
  // }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.saveSidebarWidth();
    this.saveNavbarHeight();
  };

  saveSidebarWidth = () => {
    const width = this.sidebar.offsetWidth;
    const { sidebarWidth } = this.state;

    if (width !== sidebarWidth) {
      this.setState({ sidebarWidth: width });
    }
  };

  saveNavbarHeight = () => {
    const height = this.navbar.offsetHeight;
    const { navbarHeight } = this.state;

    if (height !== navbarHeight) {
      this.setState({ navbarHeight: height });
    }
  };

  saveSidebarRef = node => {
    this.sidebar = node;
  };

  saveNavbarRef = node => {
    this.navbar = node;
  };

  closeBarraPi = () => {
    this.setState({ barraPi: false });
    const { navbarHeight } = this.state;
    setTimeout(() => {
      const height = this.navbar.offsetHeight;

      if (height !== navbarHeight) {
        this.setState({ navbarHeight: height });
      }
    }, 200);
  };

  handleTab = value => {
    const { reloadCourseData, selected, setActionPlayVideo } = this.props;
    this.setState({ selectedTab: value });
    if (value !== 'course') {
      this.setState({ sidebarWidth: undefined });
      if (selected.type === 'VIDEO') {
        setActionPlayVideo('PAUSE');
      }
    } else {
      this.setState({ sidebarWidth: 300 });
      if (selected.type === 'VIDEO') {
        setActionPlayVideo('PLAY');
      }
    }

    if (value === 'progress' && reloadCourseData) {
      reloadCourseData();
    }
  };

  render() {
    const {
      rootClassName,
      loading,
      docked,
      open,
      erroPagamento,
      navbar,
      talks,
      subnav,
      sidebar,
      children,
      hasPaul,
      hasAssessment,
      course
    } = this.props;

    const {
      navbarHeight,
      sidebarWidth,
      barraPi,
      selectedTab,
      isNewCourse
    } = this.state;

    const navbarStyle = { ...styles.navbar };
    const sidebarStyle = { ...styles.sidebar };
    const contentStyle = { ...styles.content };
    const rootProps = {
      className: rootClassName,
      style: { ...styles.main },
      role: 'navigation'
    };
    const showPaul = JSON.parse(localStorage.getItem('showPaul'), false);
    navbarStyle.filter = `blur(${loading ? '1px' : '0px'})`;

    sidebarStyle.left = 0;
    sidebarStyle.transform = 'translateX(-100%)';
    sidebarStyle.WebkitTransform = 'translateX(-100%)';
    sidebarStyle.top = `${navbarHeight}px`;
    sidebarStyle.height = `100%`;
    // contentStyle.marginBottom = 75;
    contentStyle.top = `${navbarHeight}px`;

    if (docked) {
      // mostrar barra lateral
      if (sidebarWidth !== 0) {
        sidebarStyle.transform = `translateX(0%)`;
        sidebarStyle.WebkitTransform = `translateX(0%)`;
      }

      // empurra o conteudo pro lado e deixa o conteudo cobrir o resto da tela
      contentStyle.left = `${sidebarWidth}px`;
      contentStyle.width = `calc(100% - ${sidebarWidth}px)`;
    } else if (open) {
      // desliza o bixo
      sidebarStyle.transform = `translateX(0%)`;
      sidebarStyle.WebkitTransform = `translateX(0%)`;
    }

    return (
      <div {...rootProps}>
        <div id="header-conteudo" style={navbarStyle} ref={this.saveNavbarRef}>
          {erroPagamento ||
            (localStorage.getItem('57e0854551b1dca003c67c384c37a346') ===
              'true' && (
              <div style={styles.erroCobranca}>
                Houve um erro ao efetuar a cobrança do seu plano, favor revisar
                ou atualizar os dados do seu cartão!
              </div>
            ))}
          {navbar}
          {selectedTab === 'course' && (
            <>
              {barraPi &&
              localStorage.getItem('completedPersonality') !== 'true' &&
              !talks &&
              hasAssessment ? (
                <BarraPi fechar={this.closeBarraPi} />
              ) : null}
              {subnav}
            </>
          )}
        </div>

        <div
          id="body-conteudo"
          style={{
            display: `${selectedTab === 'course' ? 'block' : 'none'}`,
            backgroundColor: '#F2F4F7',
            height: '100%'
          }}
        >
          <div
            className="disciplinaSidebar"
            style={{ ...sidebarStyle, backgroundColor: '#f2f4f7' }}
            ref={this.saveSidebarRef}
          >
            {sidebar}
          </div>

          <div style={contentStyle}>{children}</div>

          {loading && (
            <div style={styles.loading}>
              <img
                alt="loading"
                style={{
                  maxHeight: '100%',
                  animation: 'loading-spin infinite 10s linear'
                }}
                src={spinner}
              />
            </div>
          )}

          {/* {hasPaul && showPaul && <PaulBar />} */}
        </div>

        {selectedTab === 'progress' && (
          <div style={contentStyle}>
            <Progress course={course} callBackError={this.handleTab} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selected: state.newCourse.selected,
  course: state.newCourse.data
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WithSidebar);
