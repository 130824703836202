import React from "react"
import { findDOMNode } from "react-dom"
import screenfull from "screenfull"

import ReactPlayer from "react-player"
import Duration from "../CursoNovo/Media/Duration"
import Loading from "../Loading/Loading"
import { API_URL } from "../../utils/constants"

const style = {
  video: {
    width: "100%",
    height: "100%",
  },
}

const speeds = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]

class Video extends React.Component {
  state = {
    playing: false,
    volume: 1,
    muted: false,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    counterTime: 0,
    loading: true,
  }

  onReady = () => {
    this.setState({ loading: false, playing: true })
  }

  onPlay = () => {
    this.setState({ playing: true })
  }

  onPause = () => {
    this.setState({ playing: false })
  }

  onEnded = () => {
    const { loop } = this.state
    this.setState({ playing: loop })
  }

  onProgress = state => {
    const { seeking, counterTime: counterTimeState } = this.state
    if (!seeking) {
      this.setState(state)
    }

    const counterTime = counterTimeState + 1
    this.setState({ counterTime })
  }

  playPause = () => {
    const { playing } = this.state
    this.setState({ playing: !playing })
  }

  toggleMuted = () => {
    const { muted } = this.state
    this.setState({ muted: !muted })
  }

  onSeekMouseDown = () => {
    this.setState({ seeking: true })
  }

  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  onSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  onClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player))
  }

  onStart = () => {
    const { startFrom } = this.state
    this.player.seekTo(startFrom || 0)
  }

  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) })
  }

  ref = player => {
    this.player = player
  }

  _handleContextMenu = e => {
    e.preventDefault()
  }

  render() {
    const { playing, volume, muted, played, duration, playbackRate, loading } = this.state
    const { indexSubtitle, url } = this.props

    return (
      <div className="d-flex flex-column" onContextMenu={this._handleContextMenu}>
        <div className="player-wrapper">
          {loading && (
            <div style={{ position: "absolute", top: "35%", left: "45%" }}>
              <Loading img width={60} height={60} />
            </div>
          )}
          <ReactPlayer
            url={url}
            className="react-player"
            style={style.video}
            ref={this.ref}
            onStart={this.onStart}
            onReady={this.onReady}
            onPlay={this.onPlay}
            onPause={this.onPause}
            playbackRate={playbackRate}
            onDuration={durat => this.setState({ duration: durat })}
            onEnded={this.onEnded}
            onProgress={this.onProgress}
            muted={muted}
            playing={playing}
            volume={volume}
            config={{
              file: {
                attributes: {
                  crossOrigin: "true",
                },
                tracks: [
                  {
                    kind: "subtitles",
                    src: `${API_URL}getCloseCaption/${indexSubtitle}`,
                    srcLang: "pt-br",
                    default: true,
                  },
                ],
              },
            }}
          />
        </div>
        {!loading && (
          <>
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={played}
              onMouseDown={this.onSeekMouseDown}
              onChange={this.onSeekChange}
              onMouseUp={this.onSeekMouseUp}
            />
            <div className="video-control d-flex align-items-center">
              <a data-tip="Play" role="button" className="btn" onClick={this.playPause}>
                {playing ? (
                  <i className="fa fa-pause" aria-hidden="true" />
                ) : (
                  <i className="fa fa-play" aria-hidden="true" />
                )}
              </a>
              <div
                data-tip="Liga, aumenta e diminui som e desliga"
                className="volume d-flex align-items-center"
              >
                <a role="button" className="btn" onClick={this.toggleMuted}>
                  {muted ? (
                    <i className="fa fa-volume-off" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-volume-up" aria-hidden="true" />
                  )}
                </a>
                <div className="volume-range">
                  <input
                    id="volume-range"
                    type="range"
                    min={0}
                    max={1}
                    step="any"
                    value={volume}
                    onChange={this.setVolume}
                  />
                </div>
              </div>
              <Duration seconds={duration * played} /> / <Duration seconds={duration} />
              <div className="d-flex align-items-center ml-auto p-2">
                <a
                  data-tip="Expandir vídeo em tela inteira"
                  role="button"
                  className="btn"
                  onClick={this.onClickFullscreen}
                >
                  <i className="fa fa-expand" aria-hidden="true" />
                </a>

                <div className="btn-group dropup">
                  <button
                    type="button"
                    data-tip="Ajuste a velocidade do vídeo"
                    className="btn"
                    style={{ color: "white", background: "transparent", borderRadius: ".25rem" }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {playbackRate}x <i className="fa fa-clock-o" />
                  </button>
                  <div className="dropdown-menu video-control">
                    {speeds.map(x => {
                      return (
                        <button
                          key={x}
                          className="dropdown-item"
                          onClick={() => this.setState({ playbackRate: x })}
                          type="button"
                        >
                          {x === playbackRate && "✓"} {x === 1 ? "Normal" : x}
                        </button>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Video
