import React from "react";
import { TRANSFORM, FONT_TYPE, PAGE_INFORMATION } from "../../constants";
import Text from "../basicComponents/Text";
import ListRenderer from "../ListRenderer";
import Member from "../Info/DataInfo";
import Clickable from "../basicComponents/Clickable";
import { MessageNoDataDiv } from "./styles";
import formatDate from "../../../utils/formatDate";

export default ({ data, openExternalPage, dataLength, grid, action = () => {}, callback = () => {}, actions = [] }) => {
  return (
    <>
      <>
        {/* validar se existe data.items lodash */}
        <Text
          fontType={FONT_TYPE.BOLD}
          transform={TRANSFORM.UPPERCASE}
          text={`${data.type} (${
            dataLength != undefined ? dataLength : data.items.length
          })`}
        />
        {data.items.length > 0 ? (
        <ListRenderer
          grid={grid}
          componentToRender={data.items.map(item => (
            <Clickable onClick={() => callback(item)}>
              {
                openExternalPage ? 
                <Member
                  id={item.id}
                  name={item.name}
                  img={item.img}
                  link={`/beta/social/externalgroup/${item.cr_code}`}
                  description={`${PAGE_INFORMATION.MEMBER_SINCE} ${formatDate(item.memberSince ? item.memberSince : new Date())}`}
                  actions={actions}
                /> :
                <Member
                  id={item.id}
                  name={item.name}
                  img={item.img}
                  description={`${PAGE_INFORMATION.MEMBER_SINCE} ${formatDate(item.memberSince ? item.memberSince : new Date())}`}
                  actions={actions}
                />
              }
            </Clickable>
          ))}
        ></ListRenderer>
        ) : (
          <MessageNoDataDiv>
            <Text text="Não há itens para serem exibidos" />
          </MessageNoDataDiv>
        )}
      </>
    </>
  );
};
