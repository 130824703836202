import React from "react";

export default (props) => {
  const { onClicked } = props;
  
  return (
    <button
      onClick={onClicked}
      style={{marginTop: '-20px', width: '100%'}}
      type="button"
      className={`btn btn-blue ${props.loading && "btn-loading"}`}
    >
      Enviar
    </button>
  )
}
