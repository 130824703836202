import React from "react";
import Screen from "./screen";

export default ({
  member,
  members,
  group,
  postItem,
  savePost,
  updatePost,
  createComment,
  deletePost,
  interationsModalIsOpen,
  setInterationsModalIsOpen
}) => {
  return (
    <>
      <Screen
        member={member}
        members={members}
        group={group}
        postItem={postItem}
        savePost={savePost}
        updatePost={updatePost}
        createComment={createComment}
        deletePost={deletePost}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
      />
    </>
  );
};
