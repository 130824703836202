import styled from 'styled-components'


export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  @media (max-width: 768px) {
    height: auto;
  }
`

export const Title = styled.h2`

`

export const Text = styled.p`
  text-align: justify;
  padding: 0;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 14px;
  margin: 20px auto;
`

export const ContainerProgressBar = styled.div`
  width: 100%;
  max-width: 105px;
  margin: 30px auto;
`

export const ContainerWatsonBage = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10px;
  margin-bottom: 20px;
`

