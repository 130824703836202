import React from "react";
import Screen from "./screen";
import { ICON, NOTIFICATION_TYPE, DEFAULT_VALUES, TYPES } from "../../../constants";

/**
 * @param {String} type //type of notification to display
 * @param {Number} totalNotifications //display number of the type entered
**/

export default ({
  type = DEFAULT_VALUES.STRING,
  totalNotifications = DEFAULT_VALUES.NUMBER
}) => {

  const [hiddenBlock, setHiddenBlock] = React.useState(false);

  function iconType(value) {
    switch (value) {
      case TYPES.EVALUATION_RESULT:
        return ICON.RIBBON;
      case TYPES.EVALUATION_PENDING:
        return ICON.CALENDAR;
      case TYPES.PROFILE_CHANGES:
        return ICON.COGS;
      case TYPES.FINANCIAL_PENDENCY:
        return ICON.MONEY;
      case TYPES.NOTIFICATION:
        return ICON.BELL;
      default:
        return ICON.NOTIFICATION;
    }
  }

  function notificationType(value) {
    switch (value) {
      case TYPES.EVALUATION_RESULT:
        return NOTIFICATION_TYPE.EVALUATION_RESULT;
      case TYPES.EVALUATION_PENDING:
        return NOTIFICATION_TYPE.EVALUATION_PENDING;
      case TYPES.PROFILE_CHANGES:
        return NOTIFICATION_TYPE.PROFILE_CHANGES;
      case TYPES.FINANCIAL_PENDENCY:
        return NOTIFICATION_TYPE.FINANCIAL_PENDENCY;
      case TYPES.NOTIFICATION:
        return NOTIFICATION_TYPE.NOTIFICATION;
      default:
        return NOTIFICATION_TYPE.NOTIFICATION;
    }
  }

  return (
    <>
      <Screen
        type={type}
        totalNotifications={totalNotifications}
        iconType={iconType}
        notificationType={notificationType}
        hiddenBlock={hiddenBlock}
        setHiddenBlock={setHiddenBlock}
      />
    </>
  );
};
