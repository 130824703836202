import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import DetectIE from "./DetectIE"

export default WrappedComponent => {
  class VerifyIE extends PureComponent {
    static contextTypes = {
      router: PropTypes.shape(),
    }

    state = {
      isOnline: true,
    }

    componentDidMount() {
      window.addEventListener("online", this.checkNet)
      window.addEventListener("offline", this.checkNet)
      this.saveRedirect()
    }

    saveRedirect = () => {
      const redi = localStorage.getItem("urlRedirect")
      let urlRedi
      if (!redi) {
        urlRedi = document.referrer.includes(document.location.host) ? null : document.referrer
      }
      console.log(redi)
      console.log(urlRedi)
      if (urlRedi && !redi) {
        urlRedi = urlRedi.split("/").reverse()
        console.log(urlRedi)
        if (urlRedi.includes("?")) {
          urlRedi = urlRedi.split("?")[0]
        }
        // urlRedi = urlRedi.split("/").reverse()
        if (
          !urlRedi.includes("cadastroPerfil") ||
          !urlRedi.includes("validador") ||
          !urlRedi.includes("esqueciSenha") ||
          !urlRedi.includes("confirmarEmail") ||
          !urlRedi.includes("pagamento")
        ) {
          localStorage.setItem("urlRedirect", urlRedi[0])
        }
      }
    }

    checkNet = e => {
      console.log(e)
      if (e.type === "online") {
        this.setState({ isOnline: true })
      }
      if (e.type === "offline") {
        this.setState({ isOnline: false })
      }
    }

    render() {
      const { isOnline } = this.state
      return (
        <React.Fragment>
          <DetectIE />
          {!isOnline && (
            <p
              style={{
                background: "red",
                color: "white",
                textAlign: "center",
                margin: 0,
                width: "100%",
                position: "fixed",
                zIndex: 9999,
                fontWeight: "bold",
              }}
            >
              Você está offline
            </p>
          )}

          <WrappedComponent {...this.props} />
        </React.Fragment>
      )
    }
  }

  return VerifyIE
}
