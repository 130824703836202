import styled, { css } from "styled-components";
import { Dropdown } from "react-bootstrap";
import { COLOR, APPEARANCE } from "../../../constants";

export const DropdownBlock = styled(Dropdown)`

  float: right;
  width: 10%;
  height: 100%;
  i { padding: 2px; }
  button { text-align: right!important; padding: 0!important; }
  button, a {
    background-color: var(--dropdown-primary)!important;
    color: var(--dropdown-primary-text)!important;
    cursor: pointer!important;
    :active, :focus { outline: none; box-shadow: none; }
    :hover { color: var(--dropdown-primary-text-hover)!important; }
  }
  
  ${props => (props.color === COLOR.LIGHT) && css`
    margin: 8px 0;
    width: auto;
    i {
      color: var(--white);
      margin: 0 0 0 15px!important;
      float: right;
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.FILTER) && css`
    width: auto;
    float: initial;
    i {
      vertical-align: inherit;
      font-size: 26px;
      margin-left: 2px;
    }
  `}

`;
