/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Checkbox } from 'antd';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isAfter, addMinutes } from 'date-fns';

import { ButtonLinks } from '../CreditCardPayment/styles';

import PlanType from '../../planType';
import ModalTermos from '../../../Modals/ModalTermos';

import {
  validarCpf,
  toastErrorAndWarningOptions
} from '../../../../utils/functions';
import api, { gateway } from '../../../../utils/api';
import history from '../../../../utils/history';
import { findPaymentTerm } from '../../../../business/payment';
import QRCodeComponent from './subcomponents/QRCodeComponent';
import PlanDetails from './subcomponents/PlanDetails';
import PaymentMethodType from '../../paymentMethodType';
import TrialText from '../TrialText';
import {
  LabelField,
  ItemFormField,
  InputText
} from '../../../Styles/FormStyle';

class PIXPayment extends Component {
  constructor(props) {
    super(props);
    const { plan } = this.props;
    const termId =
      plan.payments && findPaymentTerm(plan, PaymentMethodType.BOLETO); // colocar termos do pix

    const pixDataStr = localStorage.getItem('pixData');
    let pixData = pixDataStr ? JSON.parse(pixDataStr) : null;

    if (pixData) {
      const datePix = new Date(pixData.date);
      const expiredPix = isAfter(new Date(), addMinutes(datePix, 15));
      if (expiredPix) {
        pixData = null;
      }
    }

    this.state = {
      termId,
      term: null,
      paymentError: '',
      isOpeningTerms: false,
      acceptedTerm: false,
      showModal: false,
      modalTerms: false,
      modalData: null,
      loading: false,
      validationSchema: Yup.object().shape({
        wl_shopperfirstname: Yup.string()
          .required('Campo obrigatório')
          .default(''),
        wl_shopperlastname: Yup.string()
          .required('Campo obrigatório')
          .default(''),
        cf_shoopercpf: Yup.string()
          .test('is-cpf', 'CPF inválido', validarCpf)
          .default('')
      }),
      qrCode: pixData
        ? { data: pixData.codigo, url: pixData.url, date: pixData.date }
        : null,
      idCapture: pixData ? pixData.idCapture : null,
      pixData
    };
  }

  acceptTerm = () => {
    const { acceptedTerm } = this.state;
    this.setState({
      acceptedTerm: !acceptedTerm,
      paymentError: false
    });
  };

  verifyPayment = async () => {
    const { idCapture } = this.state;
    const response = await gateway.get(`capture/status/${idCapture}`);
    if (response.status === 'SUCCESS') {
      const userData = await api.get('/me');

      localStorage.setItem('activeContract', true);
      localStorage.setItem('voucherAtivo', true);
      localStorage.setItem('freeUser', userData.freeUser);
      localStorage.setItem('libraryUser', userData.libraryUser);
      localStorage.setItem('socialUser', userData.socialUser);
      localStorage.setItem('showPaul', userData.showPaul);
      localStorage.removeItem('paymentInfo');

      let redirectPath = '';
      if (userData.redirectMyCourse) {
        redirectPath = '/cursos';
      } else if (userData.onBoardIncomplete) {
        redirectPath = '/sucesso_litpass';
      } else {
        redirectPath = '/sucesso_litpass?onboardIsCompleted=true';
      }
      history.push(redirectPath);
    } else if (response.status === 'INCORRECT_AMOUNT_PAID') {
      toast.warn(
        'Pagamento realizado com valor incorreto, favor entrar em contato com o SAC.',
        toastErrorAndWarningOptions
      );
    } else {
      toast.warn(
        'Seu PIX está sendo processado. Por favor aguarde 3 mins e clique novamente no botão "já paguei"',
        toastErrorAndWarningOptions
      );
    }
  };

  goToTerm = () => {
    const { termId, acceptedTerm } = this.state;
    if (!acceptedTerm) {
      this.setState({
        isOpeningTerms: true
      });
      gateway
        .get(`/term/${termId}`)
        .then(() => {
          // The endpoint works, go to the new modal
          const { plan } = this.props;
          this.setState({
            modalData: {
              termType: 'PLAN',
              idPlan: plan.id,
              termId,
              planType: plan.planType,
              fromPayment: true
            },
            showModal: true
          });
        })
        .catch(error => {
          console.log(error);
          toast.error('Erro ao buscar termo!', toastErrorAndWarningOptions);
        })
        .finally(() => {
          this.setState({
            isOpeningTerms: false
          });
        });
    }
  };

  updateProfileData = async formValues => {
    const { profile } = this.props;
    const newProfile = {
      ...profile,
      firstName: formValues.wl_shopperfirstname,
      lastName: formValues.wl_shopperlastname,
      cpf: formValues.cf_shoopercpf.replace(/\D/g, '')
    };
    return api.put('updateProfileTO', newProfile);
  };

  formatValue = text => {
    return text.toString().length > 1 ? text : `0${text}`;
  };

  handleSubmitForm = async formValues => {
    const { plan, profile } = this.props;

    const body = {
      ox_orderreference: {
        rk_userprofile: profile.idProfile,
        rk_paymentreference: plan.id,
        ws_paymentreferencetype: 'PLAN'
      },
      ws_recurrence: plan.planType === PlanType.Monthly ? 'MENSAL' : 'ANUAL',
      ox_operationvalues: {
        ox_basevalue: {
          wx_currency: 'BRL',
          nf_value: plan.price.toFixed(2)
        },
        ox_discountvalue: {
          wx_currency: 'BRL',
          nf_value: plan.discountValue.toFixed(2)
        }
      },
      ox_paymentdata: {
        wl_shopperfirstname: formValues.wl_shopperfirstname,
        wl_shopperlastname: formValues.wl_shopperlastname,
        cf_shoopercpf: formValues.cf_shoopercpf
      }
    };

    const response = await gateway.post('/payment/pix', body);

    const today = new Date();

    this.setState({
      qrCode: { ...response.qrCode, date: today },
      idCapture: response.idCapture
    });

    const pixData = {
      url: _.get(response, 'qrCode.url'),
      codigo: _.get(response, 'qrCode.data'),
      idCapture: _.get(response, 'idCapture'),
      date: today
    };

    localStorage.setItem('pixData', JSON.stringify(pixData));
  };

  openModal = () => {
    this.setState({
      modalTerms: true
    });
  };

  render() {
    const {
      termId,
      isOpeningTerms,
      paymentError,
      loading,
      validationSchema,
      qrCode
    } = this.state;
    const { profile, back, error, plan } = this.props;

    return (
      <>
        {qrCode && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ maxWidth: '50%' }}>
              <PlanDetails plan={plan} />
            </div>
            <div style={{ width: '50%' }}>
              <QRCodeComponent
                qrCode={qrCode}
                verifyPayment={this.verifyPayment}
              />
            </div>
          </div>
        )}
        {!qrCode && (
          <>
            <PlanDetails plan={plan} />

            <div className="row justify-content-md-center">
              <div className="col-md-8 col-12">
                <p>
                  Para gerar o seu QR Code e/ou código de pagamento preencha:
                </p>
              </div>
            </div>

            <Formik
              initialValues={{
                wl_shopperfirstname: profile.firstName,
                wl_shopperlastname: profile.lastName,
                cf_shoopercpf: profile.cpf || ''
              }}
              validationSchema={validationSchema}
              onSubmit={(formValues, actions) => {
                this.handleSubmitForm(formValues, actions);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                handleSubmit
              }) => (
                <React.Fragment>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-12">
                          <p
                            style={{
                              fontWeight: 'bold'
                            }}
                          >
                            Dados pessoais
                          </p>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <LabelField>Nome</LabelField>
                            <ItemFormField>
                              <InputText
                                type="text"
                                className="form-control"
                                placeholder="Nome"
                                name="wl_shopperfirstname"
                                value={values.wl_shopperfirstname}
                                onChange={handleChange('wl_shopperfirstname')}
                                onBlur={handleBlur('wl_shopperfirstname')}
                              />
                            </ItemFormField>
                            {errors.wl_shopperfirstname &&
                              touched.wl_shopperfirstname && (
                                <p
                                  style={{
                                    color: 'red'
                                  }}
                                >
                                  {errors.wl_shopperfirstname}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <LabelField>Sobrenome</LabelField>
                            <ItemFormField>
                              <InputText
                                type="text"
                                className="form-control"
                                placeholder="Sobrenome"
                                name="wl_shopperlastname"
                                value={values.wl_shopperlastname}
                                onChange={handleChange('wl_shopperlastname')}
                                onBlur={handleBlur('wl_shopperlastname')}
                              />
                            </ItemFormField>
                            {errors.wl_shopperlastname &&
                              touched.wl_shopperlastname && (
                                <p style={{ color: 'red' }}>
                                  {errors.wl_shopperlastname}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <LabelField>CPF</LabelField>
                            <ItemFormField>
                              <InputMask
                                mask="999.999.999-99"
                                type="text"
                                className="form-control"
                                placeholder="CPF"
                                name="cf_shoopercpf"
                                value={values.cf_shoopercpf}
                                maskChar={null}
                                onChange={handleChange('cf_shoopercpf')}
                                onBlur={handleBlur('cf_shoopercpf')}
                              />
                            </ItemFormField>
                            {errors.cf_shoopercpf && touched.cf_shoopercpf && (
                              <p style={{ color: 'red' }}>
                                {errors.cf_shoopercpf}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-12">
                      {termId && (
                        <div
                          style={{
                            margin: '0 auto'
                          }}
                        >
                          <Checkbox
                            id="acceptTerms"
                            onChange={this.acceptTerm}
                            onClick={this.acceptTerm}
                          >
                            <div
                              style={{ marginLeft: '20px', marginTop: '-23px' }}
                            >
                              <span> Aceito os </span>
                              <ButtonLinks
                                type="button"
                                onClick={this.goToTerm}
                              >
                                termos
                              </ButtonLinks>
                              <span> e condições </span>
                            </div>
                          </Checkbox>
                          {isOpeningTerms && <p> Carregando termos... </p>}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    {error ||
                      (paymentError && (
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <p style={{ color: 'red' }}>
                            {error || paymentError}
                          </p>
                        </div>
                      ))}
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: 10
                      }}
                    >
                      Seus dados de perfil serão atualizados
                    </div>
                  </div>
                  <TrialText
                    plan={this.props.plan}
                    selectedPaymentMethod={this.props.selectedPaymentMethod}
                  />
                  <ModalTermos
                    visible={this.state.modalTerms}
                    onClose={() =>
                      this.setState({
                        modalTerms: false
                      })
                    }
                  />
                  <div
                    className="box-buttons"
                    style={{
                      marginTop: '20px'
                    }}
                  >
                    <button
                      style={{
                        marginRight: '15px'
                      }}
                      id="btnBack"
                      className="btn btn-lg"
                      type="button"
                      onClick={back}
                    >
                      Voltar
                    </button>
                    <button
                      type="button"
                      disabled={isSubmitting}
                      style={{
                        marginTop: '0px',
                        maxWidth: '200px',
                        paddingRight: '-320px'
                      }}
                      className={`btn btn-blue btn-lg btn-block${(isSubmitting ||
                        loading) &&
                        ' btn-loading'}`}
                      onClick={handleSubmit}
                      id="btn-confirm-1"
                    >
                      Gerar QR Code
                    </button>
                  </div>
                </React.Fragment>
              )}
            </Formik>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.user.profile.data
  };
};

export default connect(mapStateToProps)(PIXPayment);
