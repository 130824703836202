// https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${props => {
    if(props.checked) {
      return props.color ? props.color : '#99d278';
    }

    return 'tranparent'
  }};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid #E0E0E0;

  ${HiddenCheckbox}:focus + & {
    /* box-shadow: 0 0 0 3px #99d278; */
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`
