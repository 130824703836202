import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  margin: 30px 0;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`

export const ContainerChart = styled.div`
  margin: 20px auto;
  max-width: 450px;
`;
