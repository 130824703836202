import { call, put } from "redux-saga/effects"

import api from "../../utils/api"
import { UserActions } from "../ducks/user"

export function* getPersonalityInsight(action) {
  try {
    const response = yield call(api.get, `findPIByUser/${action.payload.idProfile}`)

    yield put(UserActions.PIActions.getPersonalityInsightSuccess(response))
  } catch (err) {
    yield put(UserActions.PIActions.getPersonalityInsightFailure(err.response))
  }
}
