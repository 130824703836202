import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Number} id //identification code of the contents of the component
 * @param {String} name //title displayed in the component - first line
 * @param {String} img//path (url) of the image displayed in component
 * @param {String} description //path (url) of the image displayed in component
 * @param {Date} solicitationDate //when you have it - date displayed after the description
 * @param {String} role //when own - user position
 * @param {String} link //path (url) for action taken by clicking on the information
 * @param {String} size //content size
 * @param {String} color //{LIGHT} content color
 * @param {Component} actions //buttons displayed in the component
 * @param {String} orientation //{HORIZONTAL, VERTICAL} direction of items within the component
 * @param {String} secondImage //path (url) of the member who indicated the group - when it exists
 * @param {Boolean} listButtons //defines if it presents a list of buttons
 * @param {Function} action //content click action
 * @param {String} appearance //items appearance
**/

export default ({
  id,
  name,
  img,
  description,
  solicitationDate,
  role,
  link,
  size,
  color,
  actions,
  orientation,
  secondImage,
  listButtons,
  action,
  children = <></>,
  style,
}) => {
  return (
    <>
      <Screen
        id={id}
        name={name}
        img={img}
        link={link}
        description={description}
        solicitationDate={solicitationDate}
        role={role}
        size={size}
        color={color}
        orientation={orientation}
        secondImage={secondImage}
        listButtons={listButtons}
        actions={actions}
        action={action}
        child={children}
        style={style}
      />
    </>
  );
};
