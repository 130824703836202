import React from "react"
import Modal from "react-responsive-modal";
import logLit from "../../../../../images/lit-azul.png";
import ApresentacaoCurso from "../..";

export function ModalApresentacaoCurso({ visible, onClose, courseData }) {
  return (
    <Modal
        open={visible}
        onClose={onClose}
        classNames={{ modal: "lit-modal-restrict modal-large-lit" }}
        closeOnOverlayClick={false}
      >
        {courseData !== null && (
            <div className="modal-content">
                <div className="modal-header justify-content-start">
                <img src={logLit} alt="logoLit" height={45} />
                </div>
                <div className="modal-body" style={{ padding: "1em 7em 0 7em", minWidth: 1200 }}>
                  <ApresentacaoCurso alreadyCourseCrCode={courseData.courseCrCode} alreadyLockedCourse={courseData.lockedCourse} resumeView />
                </div>
            </div>
        )}
      </Modal>
  )
}
