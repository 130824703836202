import styled from "styled-components";

import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";

export const ChatContainer = styled.div`
  width: 100%;
  font-size: 14px;
  border-bottom: solid 3px #cccccc;
`;

export const ChatMessagesContainer = styled.div`
  padding: 0 16px;
  overflow-y: scroll;
  height: calc(100% - 64px);
`;

export const ChatInputContainer = styled.div`
  display: flex;
  height: 48px;
  min-height: 48px;
  color: #202124;
  font-size: 14px;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #f1f3f4;
  border-radius: 16px;
  margin: 6px 6px 0 6px;
`;

export const ChatInput = styled.textarea`
  background: none;
  padding: 12px;
  border: none;
  outline: none;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 24px;
`;

export const SendMessageButton = styled(IconButton)`
  width: 48px;
`;

export const SendMessageIcon = styled(SendIcon)`
  color: rgba(0, 0, 0, 0.54);
`;
