import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} left //receives the content that will be displayed in the left area
 * @param {Component} right //receives the content that will be displayed in the right area
 * @param {String} orientation //{AJUSTABLE} defines the orientation of the line within the space
**/

export default ({
  left = DEFAULT_VALUES.COMPONENT,
  right = DEFAULT_VALUES.COMPONENT,
  orientation = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        left={left}
        right={right}
        orientation={orientation}
      />
    </>
  );
};
