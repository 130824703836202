import styled, { css } from "styled-components";
import { } from "../../../constants";

export const Span = styled.span`
  display: inline-block;
  width: 100%;
  padding: 0px 20px;
  > div > div { width: 100%; padding-top: 0; }
  > div > div > span { display: inline-block; }

  ${props => (props.hidden) && css`
    display: none;
  `}

`;

export const ButtonText = styled.button` 
  color: #777777;    
  padding: 0 !important;
  margin: 0;
  line-height: 1.2;
  i {
    margin-right: 5px;
    color: #27aae1;
  }
  cursor: pointer;
  background: var(--transparent);
  transition: ease .3s all;
  &:hover {
    cursor: pointer;
    color: var(--button-link-hover-text);
    background: var(--transparent);
  }
`;

export const Container = styled.div`
  
`;

export const ReactionsContainer = styled.div `
  border-radius: 10px;
  padding: 0px 5px 0px 15px;
  position: absolute;
  z-index: 10;
  border: 1px solid var(--text-primary);
  background-color: white;
  width: auto !important;
  transition: opacity 0.35s linear;
  display: inline-block;
  opacity: ${props => props.show ? '1' : '0'};
`;
