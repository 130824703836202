import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import placeholder from '../../../../images/placeholder-group.png'
import { API_URL } from '../../../../utils/constants'

import api from '../../../../utils/api'

class CropPictureForm extends React.Component {
  state = {
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    width: 800,
    height: 480,
    loading: false,
    image: '',
  }

  componentDidMount() {
    const { dados } = this.props
    this.setState({ image: dados.linkPhotoGroup })
  }

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] })
  }

  handleSave = async () => {
    const img = this.editor.getImageScaledToCanvas().toDataURL()
    const { dados } = this.props
    this.setState({ loading: true })

    try {
      await api.post(`uploadImgGroup/${dados.idGroup}`, this.dataURLtoBlob(img))
      this.updateGroup()
    } catch (error) {
      console.log(error)
    }
  }

  dataURLtoBlob = dataurl => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n !== 0) {
      u8arr[n] = bstr.charCodeAt(n)
      n -= n
    }
    return new Blob([ u8arr ], { type: mime })
  }

  updateGroup = async () => {
    const { dados, finishUpdate, closeModal } = this.props

    const linkPhoto = `${API_URL}downdImgGroup/${dados.idGroup}`
    const data = {
      idGroup: dados.idGroup,
      linkPhoto,
    }
    try {
      await api.post('updateLinkPhoto', data)
      this.setState({
        image: null,
        loading: false,
      })
      finishUpdate()
      closeModal()
    } catch (error) {
      console.log(error)
    }
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }


  rotateLeft = e => {
    e.preventDefault()
    const { rotate } = this.state
    this.setState({
      rotate: rotate - 90,
    })
  }

  rotateRight = e => {
    e.preventDefault()
    const { rotate } = this.state
    this.setState({
      rotate: rotate + 90,
    })
  }


  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  handlePositionChange = position => {
    this.setState({ position })
  }

  render() {
    const { width, height, position, rotate, borderRadius, image, loading, scale, allowZoomOut } = this.state
    const { closeModal } = this.props
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Editar capa do grupo</h5>
        </div>
        <div className="modal-body">
          <div className="avatarCrop group">
            <AvatarEditor
              ref={this.setEditorRef}
              scale={parseFloat(scale)}
              width={width}
              height={height}
              position={position}
              onPositionChange={this.handlePositionChange}
              rotate={parseFloat(rotate)}
              borderRadius={borderRadius}
              onSave={this.handleSave}
              image={image || placeholder}
            />
            <p>*O tamanho minimo recomendado é de 800x480</p>
            <label className={`btn btn-blue mt-2 ${loading ? ' btn-disabled' : ''}`}>
              Escolher imagem
              <input
                type="file"
                accept="image/jpeg"
                id="selectedFile"
                disabled={loading}
                hidden
                onChange={this.handleNewImage}
              />
            </label>
            <div className="row avatarEdit-buttons">
              <div className="col text-center">
                <h6 className="blue">Zoom</h6>
                <input
                  name="scale"
                  type="range"
                  disabled={loading}
                  style={{ width: 200 }}
                  onChange={this.handleScale}
                  min={allowZoomOut ? '0.1' : '1'}
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
                <span>{parseInt(scale * 100, 10)}%</span>
              </div>
              <div className="col text-center">
                <h6 className="blue">Girar foto</h6>
                <button type="button" className="btn btn-blue" disabled={loading} onClick={this.rotateLeft}>
                  Esquerda
                </button>
                <button
                  type="button"
                  className="btn btn-blue ml-2"
                  disabled={loading}
                  onClick={this.rotateRight}
                >
                  Direita
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-danger"
            disabled={loading}
            style={{ borderRadius: '10px' }}
            type="button"
            onClick={closeModal}
          >
            Cancelar
          </button>
          <button
            disabled={loading || !image}
            className={`btn btn-blue btn-FontWeight6 ${loading && 'btn-loading'}`}
            type="button"
            onClick={this.handleSave}
          >
            Salvar
          </button>
        </div>
      </div>
    )
  }
}

export default CropPictureForm
