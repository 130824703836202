import styled from "styled-components";
import { } from "../../../constants";

export const LeftBar = styled.div`
  display: inline-block;
  flex: 1;
  padding-right: 3px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const MiddleBar = styled.div`
  display: inline-block;
  flex: 1;
  padding-right: 3px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const RightBar = styled.div`
  display: inline-block;
  text-align: right;
  padding-left: 3px;
  flex: 1;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
