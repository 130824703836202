import React from "react";
import Checkbox from '../../../../../shared/Checkbox';

function MultipleChoice({ renderLabel, value, id, index, onAnswerChange }) {
  return (
    <div>
      <label>
        <Checkbox
          id={`item-${id}-${index}`}
          checked={value}
          onChange={event => {
            if (onAnswerChange) {
              onAnswerChange(index, event.target.checked);
            }
          }}
        />
        <span style={{ marginLeft: 8 }}> 
          {renderLabel && renderLabel()}
        </span>
      </label>
    </div>
  );
}

export default MultipleChoice;
