import React, { useState } from 'react';

function Accordion({ modules }) {

  const [cardActived, setCardActived] = useState(-1);

  const handleSeeMore = (index) => {
    if(index === cardActived){
        setCardActived(-1)
    } else {
        setCardActived(index)
    }
  }

  return (
    <div id="accordion" className="acordion-lit">
            {modules && modules.length > 0 && modules.map((item, index) => {
                const refer = Math.round(Math.random() * (index+1) * 12);
                return (
                    <div className="card">
                        <div className="card-header">
                            <a className="card-link" data-toggle="collapse" href={`#collapse${refer}`}>
                            <strong>{item.name}</strong><br />
                            <span>Módulo {index+1} · {item.children.length} pílula{item.children.length !== 1 && 's'} de aprendizagem</span>
                            </a>
                        </div>
                        <div id={`collapse${refer}`} className={`collapse ${index === 0 ? 'show' : ''}`} data-parent="#accordion">
                            <div className="card-body">
                            <p>{item.description}</p>
                            
                            <p className='gray-lit'>O que está incluído</p>
                            <ul className="list-inline">
                                <li className="list-inline-item mr-3 green-lit">
                                    <i className="fa fa-play pr-1"></i>
                                    <span>{item.mediaTypeCounts.VIDEO} vídeo{item.mediaTypeCounts.VIDEO !== 1 && 's'}</span>
                                </li>
                                <li className="list-inline-item mr-3 green-lit">
                                    <i className="fa fa-book pr-1"></i>
                                    <span>{item.mediaTypeCounts.PDF} leitura{item.mediaTypeCounts.PDF !== 1 && 's'}</span>
                                </li>
                                <li className="list-inline-item green-lit">
                                    <i className="fa fa-certificate pr-1"></i>
                                    <span>{item.totalAssessmentsForModule} exercício{item.totalAssessmentsForModule !== 1 && 's'}</span>
                                </li>
                            </ul>

                            <p className="blue-p-a">
                                <i className={`fa ${cardActived === index ? 'fa-angle-left' : 'fa-angle-right'}`}></i> 
                                <a href="javascript:void(0)" onClick={() => {
                                    handleSeeMore(index)
                                }}>{cardActived === index ? ' Mostrar menos' : ' Mostrar informações sobre o conteúdo do módulo'}
                                </a>
                            </p>
                            {cardActived === index && item.children && item.children.length > 0 && (<div className="blue-p-a">
                                <ul className="list-group">
                                    {item.children.map(child => <li className='list-group-item'>{child.name}</li>)}
                                </ul>
                            </div>)}
                            </div>
                        </div>
                    </div>
                )
            }
            )}
        </div>
  );
}

export default Accordion;