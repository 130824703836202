import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import moment from 'moment'
import "moment/locale/pt-br"
import Attachment from '../Attachment'
import { API_URL } from '../../../../utils/constants'

import api from '../../../../utils/api'

export default class EditComments extends Component {
  constructor(props) {
    super(props)
    const { post } = this.props
    this.state = {
      editarComentario: post.content || post.comment || post.answer,
      linkMedia: post.linkMedia,
      file: null,
      imagePreviewUrl: null,
      ext: null,
    }
  }

  _handleImageChange = (e) => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    if (file === undefined) return

    if (
      ![ 'jpg', 'mp4', 'mp3', 'pdf', 'xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx' ].includes(
        file.name.split('.')[1],
      )
    ) {
      alert('Extensão de arquivo não suportada!')
      return
    }

    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
        ext: file.name.split('.')[1],
      })
    }

    reader.readAsDataURL(file)
  }

  setAttachament = () => {
    const { linkMedia } = this.state

    if (linkMedia.endsWith('jpg')) {
      return <Attachment url={linkMedia} ext="jpg"/>
    }
    if (linkMedia.endsWith('mp4')) {
      return <Attachment url={linkMedia} ext="mp4"/>
    }
    if (linkMedia.endsWith('mp3')) {
      return <Attachment url={linkMedia} ext="mp3"/>
    }
    if (linkMedia.endsWith('pdf')) {
      return <Attachment url={linkMedia} ext="pdf"/>
    }
    if (linkMedia.includes('kaltura')) {
      return <Attachment url={linkMedia} ext={linkMedia.slice(-3)} curso/>
    }
    if (linkMedia.includes('downloadAttachment')) {
      return <Attachment url={linkMedia} ext={linkMedia.slice(-3)} curso/>
    }

    return (
      <a href={linkMedia} style={{ color: '#27aae1' }}>
        <i className="fa fa-download"/> Baixar anexo
      </a>
    )

  }

  setImagePreview = () => {
    const { imagePreviewUrl, ext, file, previewAttachment } = this.state
    if (imagePreviewUrl && ext === 'jpg') {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <div role="button" onClick={() => this.setState({ previewAttachment: true })}>
            <img
              className="img-thumbnail"
              alt="Anexo"
              style={{ width: 'auto', height: '250px' }}
              src={imagePreviewUrl}
            />
          </div>
          {previewAttachment && (
            <Lightbox
              mainSrc={imagePreviewUrl}
              onCloseRequest={() => {
                this.setState({ previewAttachment: false })
              }}
            />
          )}
        </div>
      )
    }
    if (imagePreviewUrl) {
      return <p>{file.name}</p>
    }

    return <div className="previewText">*Extensões de anexo: pdf, mp4, jpg e mp3</div>

  }

  removeAttachment = () => {
    this.setState({
      linkMedia: null,
      file: null,
      imagePreviewUrl: null,
      ext: null,
    })
  }

  sendFile = (id, ext) => {
    let url
    const { from } = this.props
    const { file } = this.state

    switch (from) {
      case 'post':
        url = `uploadImgPost/${id}/${ext}`
        break
      case 'comentario':
        url = `uploadImgComment/${id}/${ext}`
        break
      case 'resposta':
        url = `uploadImgAnswer/${id}/${ext}`
        break
      default:
        break
    }

    return api.post(url, file)
  }

  upadteComment = async () => {
    const { ext, linkMedia, editarComentario, imagePreviewUrl } = this.state
    const { post, from, refreshPosts, cancelEdit } = this.props
    const id = post.idTimeLine || post.idComment || post.idAnswer
    const date = moment.utc()
    let url
    let body

    if (from === 'post') {
      url = `updatePost`
      body = JSON.stringify({
        ...post,
        content: editarComentario,
        datePost: date,
        linkMedia: imagePreviewUrl ? `${API_URL}downdImgPost/${id}/${ext}` : linkMedia,
      })
    }
    if (from === 'comentario') {
      url = `updateComment`
      body = JSON.stringify({
        ...post,
        comment: editarComentario,
        date,
        linkMedia: imagePreviewUrl ? `${API_URL}downdImgComment/${id}/${ext}` : linkMedia,
      })
    }
    if (from === 'resposta') {
      url = `updateAnswerComment`
      body = JSON.stringify({
        ...post,
        answer: editarComentario,
        dateAnswer: date,
        linkMedia: imagePreviewUrl ? `${API_URL}downdImgAnswer/${id}/${ext}` : linkMedia,
      })
    }

    if (imagePreviewUrl) {
      const updateAttachment = await this.sendFile(id, ext)
      if (!updateAttachment.ok) {
        alert('Não foi possivel fazer o upload do anexo, tente novamente mais tarde')
        return
      }
    }

    api.put(url, body).then(response => {
      console.log('att com arquivo', response)
      refreshPosts()
      cancelEdit()
    })
      .catch(error => console.log(error))
  }

  render() {
    const { editarComentario, linkMedia, imagePreviewUrl } = this.state
    const { cancelEdit } = this.props
    return (
      <div>
        <div className="form-group">
          <label htmlFor="publicacao">
            <i className="fa fa-pencil"/> Editar publicação
          </label>
          <textarea
            name="publicacao"
            id="publicacao"
            rows="7"
            autoFocus
            onFocus={evt => {
              const tempValue = evt.target.value
              this.setState({ editarComentario: tempValue })
            }}
            value={editarComentario}
            onChange={evt => this.setState({ editarComentario: evt.target.value })}
            className="form-control"
          />

          <button
            className="btn btn-blue mr-3"
            type="button"
            onClick={this.upadteComment}
            disabled={editarComentario === '' || editarComentario.trim() === ''}
          >
            Salvar
          </button>
          <label className="btn btn-blue mt-2">
            Anexar arquivo
            <input
              type="file"
              id="exampleInputFile"
              accept="image/jpeg, application/pdf, audio/mpeg, audio/mp3, video/mp4, .xlsx, .xls , .doc, .docx, .ppt, .pptx"
              hidden
              onChange={e => this._handleImageChange(e)}
            />
          </label>
          <button
            type="button"
            className="btn btn-default ml-3"
            style={{ borderRadius: 10 }}
            onClick={cancelEdit}
          >
            Cancelar
          </button>
          <div className="">
            {(linkMedia || imagePreviewUrl) && (
              <React.Fragment>
                <div className="d-flex justify-content-center">
                  <h6>Preview anexo</h6>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-default mr-3"
                    style={{ borderRadius: 10 }}
                    onClick={this.removeAttachment}
                  >
                    Remover anexo
                  </button>
                </div>
              </React.Fragment>
            )}
            {linkMedia && <div className="imgPreview">{this.setAttachament()}</div>}
            {imagePreviewUrl && this.setImagePreview()}
          </div>
        </div>
      </div>
    )
  }
}
