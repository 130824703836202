import React from "react"


import * as Styles from './styles'


const LOGO_LIT = require("../../../../images/lit.png")

const Navbar = ({...rest}) => {
  return (
    <Styles.Nav className='navbar' {...rest}>
      <Styles.Content>
        <a className='navbar-brand'>
          <img src={LOGO_LIT} alt='Logo LIT' width="120" />
        </a>
      </Styles.Content>
    </Styles.Nav>
  )
}

export default Navbar
