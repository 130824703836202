import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { } from "../../../constants";

export const DropdownToggle = styled(Dropdown.Toggle)`

  font-weight: 600;
  color: var(--dropdown-primary-text);
  border: 0; 
  margin: 10px 0;
  padding: 0;
  background: var(--dropdown-primary);
  outline: none;
  box-shadow: none;
  &:after {
    display: none;
  }
  &:hover {
    background: var(--dropdown-primary-hover);
    color: var(--dropdown-primary-text-hover);
    cursor: pointer;
  }
  
`;
