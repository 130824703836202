import { Types } from "./types";
import { API_URL, API_URL_IMAGES } from "../../../utils/constants";
/**
 * Actions
 */
export const Creators = {
  getCourseRequest: (idCourse, idProfile) => ({
    type: Types.GET_COURSE_REQUEST,
    payload: { idCourse, idProfile }
  }),
  getCourseSuccess: data => ({
    type: Types.GET_COURSE_SUCCESS,
    payload: { data }
  }),
  getCourseFailure: error => ({
    type: Types.GET_COURSE_FAILURE,
    payload: { error }
  }),

  setCourseSelected: (data, typeContent = "content") => {
    const email = localStorage.getItem("ra");
    let selected = {...data};
    selected.type = selected.type ? selected.type : selected.typeAttachment;

    const {
      type,
      idAttachment,
      idDiscipline,
      idCourse,
      idContent,
      idChildren,
      path
    } = selected;

    selected.typeContent = typeContent;
    switch (type) {
      case "VOD":
        selected.url = `https://cdnapisec.kaltura.com/p/1772821/sp/177282100/playManifest/entryId/${idAttachment}/format/url/protocol/https`;
        break;
      case "TEL":
        selected.url = `${API_URL}getSCORM/${email}/${idDiscipline ||
          idCourse}/${idContent}/${idChildren}/${idAttachment}/`;
        break;
      case "PDF":
      case "EXE":
        selected.url = path
          ? `${API_URL_IMAGES}attachment/${path}`
          : `${API_URL}downloadAttachment/${idDiscipline ||
              idCourse}/${idContent}/${idChildren}/${idAttachment}/`;

        // selected.url =
        break;
      case "VMP":
        selected.url = `${API_URL}getAtividade/${email}/LAUNCH_ASSESSMENT/${idDiscipline}/${idChildren}`;
        break;
      case "FOR":
        selected.url = `getAtividade/${email}/FORUM/${idDiscipline}/${idChildren}`;
        break;
      default:
        selected.url = null;
        break;
    }

    return {
      type: Types.SET_COURSE_SELECTED,
      payload: selected
    };
  },

  setLastSyncedGradeDiscipline: idDiscipline => ({
    type: Types.SET_LAST_SYNCED_GRADE_DISCIPLINE,
    payload: idDiscipline
  }),

  setDisciplineSelected: data => ({
    type: Types.SET_DISCIPLINE_SELECTED,
    payload: { data }
  }),

  syncGradeByDiscipline: idDiscipline => ({
    type: Types.SYNC_GRADE_BY_DISCIPLINE,
    payload: idDiscipline
  }),

  forceSyncGradeByDiscipline: idDiscipline => ({
    type: Types.FORCE_SYNC,
    payload: idDiscipline
  }),

  setForceSyncDiscipline: payload => ({
    type: Types.SET_VALUE_FORCE_SYNC,
    payload
  }),

  defineDisciplineAsSynchronizedAction: payload => ({
    type: Types.DEFINE_DISCIPLINE_AS_SYNCHRONIZED,
    payload
  }),

  setNavigationContent: data => ({
    type: Types.SET_NAVIGATION_CONTENT,
    payload: { data }
  }),

  setPlayerPosition: data => ({
    type: Types.SET_PLAYER_POSITION,
    payload: { data }
  }),

  getCourseProgressRequest: idCourse => ({
    type: Types.GET_COURSE_PROGRESS_REQUEST,
    payload: { idCourse }
  }),

  getCourseProgressSuccess: ({ countObjects, countFinishedObjects }) => ({
    type: Types.GET_COURSE_PROGRESS_SUCCESS,
    payload: { countObjects, countFinishedObjects }
  }),

  setDisciplineProgress: (idDiscipline, idCourse = null) => ({
    type: Types.SET_DISCIPLINE_PROGRESS,
    payload: { idDiscipline, idCourse }
  }),

  setDisciplineSucess: discipline => ({
    type: Types.SET_DISCIPLINE_SUCCESS,
    payload: { discipline }
  }),

  setAutoplayVideos: autoplay => {
    return {
      type: Types.SET_AUTOPLAY_VIDEOS,
      payload: { autoplay }
    };
  },

  setWarningDisplayed: warningDisplayed => {
    return {
      type: Types.SET_WANING_DISPLAYED,
      payload: { warningDisplayed }
    };
  },

  resetCourse: () => ({
    type: Types.RESET_COURSE
  }),

  fetchSimulatedGradeAction: (
    idProfile,
    idDiscipline,
    idContent,
    idChildren
  ) => ({
    type: Types.FETCH_SIMULATED_GRADES_BY_UC,
    payload: {
      idProfile,
      idDiscipline,
      idContent,
      idChildren
      // request: { url: `/getgradesimuc/${idContent}/${idProfile}`, method: 'GET' },
    }
  }),

  fetchFinalGradeByDisciplineAction: (ra, idDiscipline) => ({
    type: "@COURSE/FETCH_FINAL_MEDIA_BY_DISCIPLINE",
    payload: {
      ra,
      idDiscipline
    }
  })
};
