import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import WatsonBadgeFooter from "./WatsonBadgeFooter"

const GIF_PAUL = require("../../images/animacao_paul_pensando.gif")

class Analisando extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  componentWillReceiveProps(nextProps) {
    const { personalityInsight } = this.props
    const { router } = this.context
    console.log(nextProps)
    if (personalityInsight) {
      localStorage.setItem("completedPersonality", true)
      router.history.push("/resultados")
    } else {
      console.log("erro")
    }
  }

  render() {
    return (
      <div>
        <div className="row justify-content-md-center analisando-container__no-watson-badge">
          <div className="col-10 objetivos-personalizar--desc-usuario text-center">
            <img
              style={{ height: "400", width: "500" }}
              alt="Curso"
              className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
              src={GIF_PAUL}
            />
          </div>
        </div>
        <WatsonBadgeFooter />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    personalityInsight: state.user.personalityInsight.data,
  }
}

export default connect(mapStateToProps)(Analisando)
