import React from "react";
import _ from "lodash";
import placeholder from "../../images/placeholder-group.png";
import PrivateCard from "../Home/PrivateCard";

function CardNewGroup({ result: group, goToNewGroup }) {
  if (!group) return null;
  const socialUser = JSON.parse(localStorage.getItem("socialUser"));
  const img = group.image || placeholder;

  return (
    <div
      role="button"
      id={`grupo-${group.groupCode}`}
      onClick={() => goToNewGroup(socialUser, group)}
      className="card-wrapper-p"
    >
      <div className="card">
        <img
          className="card-img-top"
          style={{ maxHeight: 160 }}
          src={img}
          alt="course cape"
        />
        <div className="card-body" style={{ padding: ".6rem 1rem" }}>
          <h5 className="card-subtitle mb-2 text-muted">
            {!socialUser && <PrivateCard fontSize={20} />} {group.name}
          </h5>
          <small>{_.get(group, "privacy", "")}</small>
          <p className="card-text">{group.longDescription}</p>
        </div>
      </div>
    </div>
  );
}

export default CardNewGroup;
