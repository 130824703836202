import React, { memo, useState, useEffect } from "react"
import Img from "react-image"
import { Link } from "react-router-dom"

import placeholder from "../../../images/placeholder-avatar.png"
import NoImage from './NoImage'

import api from "../../../utils/api"
import { API_URL_IMAGES } from "../../../utils/constants"

const styles = {
  column: {
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
  },
  row: {
    marginRight: 0,
    marginLeft: 0,
  },
  card: {
    borderRadius: 0,
    border: "1px solid rgba(0,0,0,.125)",
    borderTop: "none",
    height: "100%",
  },
  image: {
    height: 100,
    width: 100,
  },
  titulo: {
    color: "rgb(39, 170, 225)",
  },
}

const Membro = ({ membro, idGroup }) => {
  const [detailUser, setDetailUser] = useState({})

  const fetchDetails = async () => {
    const { idProfile } = membro
    try {
      const response = await api.get(`getDatailOfProfile/${idProfile}/${idGroup}`)
      setDetailUser(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  const imgUrl = membro.linkPhoto
    ? `${API_URL_IMAGES}images/profile/${membro.idProfile}.jpg`
    : placeholder

  return (
    <div className="col-md-4" style={styles.column}>
      <div className="card text-center" style={styles.card}>
        <div className="card-body">
        <Img className="rounded-circle img-fluid mb-1" 
              style={styles.image} 
              src={imgUrl}
              unloader={
                <NoImage 
                  className="rounded-circle img-fluid mb-1"
                  style={styles.image} 
                />}
          />


          <Link to={`/${detailUser.teacher ? "perfilProfessor" : "perfilPublico"}/${membro.idProfile}`}>
            <h5 className="card-title" style={styles.titulo}>
              {membro.name}
            </h5>
          </Link>
          {detailUser && (
            <div>
              <p className="card-text" style={{ marginBottom: "5px" }}>
                {detailUser.typeMember === 1 ? "Admin" : "Membro"} -{" "}
                {detailUser.teacher ? "Professor" : "Aluno"}
              </p>
              <h6 className="card-subtitle text-muted">{detailUser.company}</h6>
              <p className="card-text">{detailUser.office}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Membro)
