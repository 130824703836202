import styled from "styled-components";

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  margin: 0;
`

export const ErrorLabel = styled.p`
  margin: 0;
  padding: 0;
  color: red;
  font-size: 12px;
`
