import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import styled from 'styled-components';
import NotaMediaFinal from './NotaMediaFinal';

import ShareCertificate from '../shared/ShareCertificate';

const Avaliacao = styled.div`
  background: #d7f0fc;
  padding: 5px 18px 5px 20px;
  border-top: 1px solid #eee;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  margin-bottom: 10px;
`;

const ContainerButtonCertificate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin-right: 10px;
  }
`;

class Sidebar extends Component {
  state = {
    showCertificate: false,
    grade: 0,
    urlCertificado: '',
    certificateUrl: '',
    modalCertificado: false
  };

  componentDidMount() {
    const { isTrails } = this.props;
    if (isTrails) {
      this.verifyCertificate();
    }
  }

  render() {
    const {
      showCertificate,
      modalCertificado,
      urlCertificado,
      certificateUrl
    } = this.state;
    const { children, isTrails } = this.props;
    return (
      <nav id="sidebar" style={{ width: '100%' }}>
        {isTrails && !!showCertificate ? (
          <div className="list-group panel">
            <Avaliacao>
              <a
                role="button"
                data-for="assessment"
                data-tip="Nota final da Trilha"
                style={{ color: '#27aae1', flex: 2, fontSize: '1.2em' }}
              >
                Nota final Trilha:
              </a>
              <NotaMediaFinal able nota={20} />
            </Avaliacao>
            <button
              type="button"
              className="btn btn-certicado ml-1 btn-FontWeight6 "
              onClick={this.abrirCertificado}
            >
              <a>Gerar certificado da Trilha</a>
            </button>
          </div>
        ) : null}
        {children}
        <Modal
          open={modalCertificado}
          onClose={this.closeModalCertificado}
          classNames={{ modal: 'lit-perfil-form' }}
          closeOnOverlayClick={false}
        >
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5
                className="modal-title"
                style={{
                  color: '#27aae1'
                }}
              >
                Certificado
              </h5>
            </div>
            <div
              className="modal-body"
              style={{
                background: 'white',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <img
                src={urlCertificado}
                alt="Certificado"
                style={{
                  width: '90%',
                  height: 'auto'
                }}
              />
              <ContainerButtonCertificate>
                <button
                  type="button"
                  className="btn btn-certicado btn-FontWeight6 "
                  onClick={this.imprimirCertificado}
                >
                  <a>Imprimir certificado</a>
                </button>
                <ShareCertificate url={certificateUrl} />
              </ContainerButtonCertificate>
            </div>
          </div>
        </Modal>
      </nav>
    );
  }
}

export default Sidebar;
