import React from "react"

import Modal from "react-responsive-modal"
import Membro from "./Membro"
import MembroSmall from "./MembroSmall"

import { randomNumber } from "../../../utils/constants"

import api from "../../../utils/api"

const styles = {
  column: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  row: {
    marginRight: 0,
    marginLeft: 0,
  },
  card: {
    borderRadius: 0,
    border: "1px solid rgba(0,0,0,.125)",
    borderTop: "none",
    height: "100%",
  },
  image: {
    height: 100,
    width: 100,
  },
  titulo: {
    color: "rgb(39, 170, 225)",
  },
  botao: {
    lineHeight: 1,
  },
}

export default class Membros extends React.Component {
  state = {
    modalOpen: false,
    allMembers: {},
    topMembers: {},
  }

  componentDidMount() {
    this.fetchAll()
  }

  componentDidUpdate(prevProps) {
    const { refresh } = this.props
    if (refresh !== prevProps.refresh) {
      this.fetchAll()
    }
  }

  fetchAll = () => {
    this.getMemberList(0)
    this.getMemberList(1)
  }

  getMemberList = async (flag = 0) => {
    const { idGroup } = this.props
    try {
      const response = await api.get(`getProfilesMembersOfGrup/${idGroup}/${flag}`)
      if (flag === 0) {
        this.setState({ allMembers: response })
      } else if (flag === 1) {
        this.setState({ topMembers: response })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true })
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false })
  }

  render() {
    const { topMembers, allMembers, modalOpen } = this.state
    const { idGroup } = this.props

    if (!allMembers.length || !topMembers.length) {
      return (
        <div className="card mt-2 mb-3">
          <div className="card-header sidebar with-flex">Carregando Membros</div>
        </div>
      )
    }

    return (
      <div className="card mt-2 mb-3">
        <div className="card-header sidebar with-flex">
          <span className="titulo">{allMembers.length} Membros</span>
          <button type="button" className="btn btn-blue" style={styles.botao} onClick={this.onOpenModal}>
            Ver todos
          </button>
        </div>
        <div className="card-body" style={{ padding: 0 }}>
          <ul className="list-group">
            {topMembers.map(membro => {
              return <MembroSmall idGroup={idGroup} user={membro} key={randomNumber()} />
            })}
          </ul>
        </div>
        <Modal
          open={modalOpen}
          onClose={this.onCloseModal}
          closeOnOverlayClick={false}
          classNames={{ modal: "lit-modal-members" }}
        >
          <div className="modal-content" id="modalGroup">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Todos os membros
              </h5>
            </div>
            <div className="modal-body">
              <div className="row no-gutters">
                {allMembers.map(membro => {
                  return <Membro idGroup={idGroup} membro={membro} key={randomNumber()} />
                })}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
