const styles = {
  buttonFacebook: {
    backgroundColor: "#FFF",
    border: "none",
    paddingRight: 0,
    margin: 0
  }
};

export default styles;
