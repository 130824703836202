import React, { useState } from "react";
import Screen from "./screen";
import moment from "moment";
import showToast from "../../../../utils/showToast";
import { ICON, ACTION, DEFAULT_VALUES, NOTIFICATION_TYPE, TYPES } from "../../../constants";

/**
 * @param {Array} contentCard //content to be displayed 
 * @param {String} title //block title
 * @param {String} borderColor //{PRIMARY, SECONDARY, SUCCESS, LIGHT, DARK, GRAY} border color displayed in the title
 * @param {String} textAlign //{LEFT, RIGHT, CENTER} alignment of text within the block
 * @param {Function} loadNotifications //function triggered when sending response within the block
 * @param {String} appearance //block appearance
 * @param {String} color //text color displayed within the block
 * @param {String} buttonAppearance //button appearance displayed within the block
 * @param {Number} perLine //number of blocks that are displayed per line
 * @param {String} locked //defines whether the component should be fixed
 * @param {String} useful //defines whether the content has been marked as useful
 * @param {String} favorite //defines whether the content has been marked as a favorite
 * @param {String} context //{DOUBT, ANSWARE, MENTION]} when communicating content, defines what type of icon is displayed
**/

export default ({
  contentCard = DEFAULT_VALUES.LIST,
  title = DEFAULT_VALUES.STRING,
  borderColor = DEFAULT_VALUES.STRING,
  textAlign = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING,
  color = DEFAULT_VALUES.STRING,
  buttonAppearance = DEFAULT_VALUES.STRING,
  perLine = DEFAULT_VALUES.NUMBER,
  locked = DEFAULT_VALUES.BOOLEAN,
  useful = DEFAULT_VALUES.BOOLEAN,
  favorite = DEFAULT_VALUES.BOOLEAN,
  type = DEFAULT_VALUES.STRING,
  context = DEFAULT_VALUES.STRING,
  loadContent = DEFAULT_VALUES.FUNCTION
}) => {

  const [hiddenAnswer, setHiddenAnswer] = React.useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  function notificationType(value, context) {
    switch (value) {
      case TYPES.CONTENT_SUGESTION:
        return ICON.RIBBON;
      case TYPES.GROUP_SUGESTION:
        return ICON.USERS;
      case TYPES.COMMUNICATION:
        switch (context) {
          case TYPES.COMMUNICATION_DOUBT:
            return ICON.QUEST;
          case TYPES.COMMUNICATION_ANSWARE:
            return ICON.MESSAGE;
          case TYPES.COMMUNICATION_MENTION:
            return ICON.CITE;
        }
        break;
      case TYPES.EVALUATION_ITEM:
        return ICON.TIME;
      default:
        return ICON.MESSAGE;
    }
  }

  function buttonType(value, content) {
    switch (value) {
      case TYPES.COMMUNICATION:
        return (hiddenAnswer ? ACTION.RETRY : ACTION.HIDE);
      case TYPES.EVALUATION_ITEM:
        return (content ? ACTION.ACCESS : ACTION.SEND_NOW);
      case TYPES.CONTENT_SUGESTION:
        return ACTION.ACCESS;
      case TYPES.GROUP_SUGESTION:
        return ACTION.JOIN;
      default:
        return ACTION.ACCESS;
    }
  }

  function subtitleType(value, time) {
    switch (value) {
      case TYPES.GROUP_SUGESTION:
        return NOTIFICATION_TYPE.GROUP_SUGESTION;
      case TYPES.CONTENT_SUGESTION:
        return NOTIFICATION_TYPE.CONTENT_SUGESTION;
      case TYPES.COMMUNICATION:
        return moment(time.postDate)
          .fromNow()
          .toString()
          .replace("em", "");
      case TYPES.EVALUATION_ITEM:
        return time;
    }
  }

  async function sendMessage() {
    //temporary function
    setLoadingProgress(100);
    showToast("", true);
  }

  return (
    <>
      <Screen
        type={type}
        title={title}
        contentCard={contentCard}
        textAlign={textAlign}
        appearance={appearance}
        borderColor={borderColor}
        color={color}
        buttonAppearance={buttonAppearance}
        perLine={perLine}
        locked={locked}
        useful={useful}
        favorite={favorite}
        context={context}
        hiddenAnswer={hiddenAnswer}
        setHiddenAnswer={setHiddenAnswer}
        notificationType={notificationType}
        buttonType={buttonType}
        subtitleType={subtitleType}
        sendMessage={sendMessage}
      />
    </>
  );
};
