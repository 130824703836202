import React from 'react'

import api from '../../../../utils/api'

export default class EditGroupForm extends React.Component {
  state = {
    loading: false,
    categorias: {},
    groupName: '',
    groupDesc: '',
    privacity: '',
    category: '',
  }

  componentDidMount() {
    const { dados } = this.props
    this.setState({
      groupName: dados.groupName,
      groupDesc: dados.description,
      privacity: dados.privacity,
      category: dados.category,
    })
    this.getSuggestions()
  }

  getSuggestions = async () => {
    try {
      const response = await api.get('trackSuggestion')
      this.setState({ categorias: response })
    } catch (error) {
      console.log(error)
    }
  }

  updateGroup = async e => {
    e.preventDefault()

    const { dados } = this.props
    const { groupName, groupDesc, privacity, category } = this.state
    this.setState({ loading: true })

    const data = {
      idGroup: dados.idGroup,
      nameGroup: groupName,
      description: groupDesc,
      privacity,
      category,
    }
    try {
      await api.post('newUpdateGroup', data)
      this.clearState()
    } catch (error) {
      console.log(error)
    }

  }

  clearState = () => {
    this.setState({ loading: false })
    const { onCloseModal, updateRelevantData } = this.props
    onCloseModal()
    updateRelevantData()
  }

  render() {
    const { onCloseModal } = this.props
    const { groupName, groupDesc, privacity, loading, category, categorias } = this.state
    return (
      <div className="modal-content" id="modalGroup">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Editar grupo
          </h5>
        </div>
        <form onSubmit={this.updateGroup}>
          <div className="modalBody-container">
            <div className="modal-body">
              <div className="form-group">
                <label>Nome</label>
                <input
                  type="text"
                  className="form-control"
                  value={groupName}
                  onChange={(event => this.setState({ groupName: event.target.value }))}
                  required
                />
              </div>
              <div className="form-group">
                <label>Privacidade</label>
                <select
                  value={privacity}
                  onChange={(event => this.setState({ privacity: event.target.value }))}
                  className="form-control"
                  required
                >
                  <option readOnly>Selecione a privacidade</option>
                  <option value="false">Público</option>
                  <option value="true">Privado</option>
                </select>
              </div>
              <div className="form-group">
                <label>Categoria</label>
                <select value={category}
                        onChange={(event => this.setState({ category: event.target.value }))}
                        className="form-control"
                        required>
                  <option readOnly>Selecione a categoria</option>
                  {categorias.length > 0 &&
                  categorias.map((cat) => {
                    return (
                      <option key={cat.idSuggestion} value={cat.nameSuggestion}>
                        {cat.nameSuggestion}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="form-group">
                <label>Descrição</label>
                <textarea
                  className="form-control"
                  rows="8"
                  value={groupDesc}
                  onChange={(event => this.setState({ groupDesc: event.target.value }))}
                  required
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-FontWeight6"
              disabled={loading}
              onClick={onCloseModal}
            >
              Cancelar
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`btn btn-blue btn-FontWeight6 ${loading && 'btn-loading'}`}
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    )
  }
}
