/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import MathJax from 'react-mathjax-preview';
import { Content } from './styles';

export default function ViewEditor({ data }) {
  return (
    <Content>
      <div className="ck-content">
        <MathJax msDelayDisplay={0} math={data} />
      </div>
    </Content>
  );
}

ViewEditor.propTypes = {
  data: PropTypes.string,
};

ViewEditor.defaultProps = {
  data: '',
};
