import styled, { css } from "styled-components";
import { } from "../../../constants";

export const InfoTime = styled.span`
  display: table;
  margin: 0 0 0 38px;
  width: 100%;
  text-align: left;
  @media (max-width: 991px) {
    margin-left: 28px;
    div { margin-top: 0; }
  }
  @media (max-width: 767px) {
    margin: 0 0 0 5px;
  }
`;

export const Time = styled.span`
  display: flex;
  padding: 0 15px;
  width: 100%;
  @media (max-width: 991px) {
    padding: 0;
    text-align: left;
  }
`;
