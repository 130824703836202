import { Types } from "./types"

/**
 * Reducers
 */

const INITIAL_STATE = {
  offices: {
    loading: false,
    called: false,
    error: null,
    data: [],
  },
  lastOffice: {
    loading: false,
    called: false,
    error: null,
    data: [],
  },
}

export default function office(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_OFFICES_REQUEST:
      return {
        ...state,
        offices: {
          loading: true,
          ...state.offices,
        },
      }

    case Types.GET_OFFICES_SUCCESS:
      return {
        ...state,
        offices: {
          loading: false,
          called: true,
          error: false,
          data: action.payload.data,
        },
      }

    case Types.GET_OFFICES_FAILURE:
      return {
        ...state,
        offices: {
          loading: false,
          error: true,
          ...state.offices,
        },
      }

    case Types.GET_LAST_OFFICE_REQUEST:
      return {
        ...state,
        lastOffice: {
          loading: true,
          ...state.lastOffice,
        },
      }

    case Types.GET_LAST_OFFICE_SUCCESS:
      return {
        ...state,
        lastOffice: {
          loading: false,
          called: true,
          error: false,
          data: action.payload.data,
        },
      }

    case Types.GET_LAST_OFFICE_FAILURE:
      return {
        ...state,
        lastOffice: {
          loading: false,
          error: true,
          ...state.lastOffice,
        },
      }

    default:
      return state
  }
}
