import React, { useState, useEffect } from "react";
import Screen from "./screen";
import { gateway } from "../../../utils/api";
import litLogo from "../../../images/lit-white.png";
import imageDefault from "../../../images/lit-default.jpg";
import showToast from "../../../utils/showToast";
import _ from "lodash"; 
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { MENU_NAVIGATION } from "../constants";

export default () => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  const [profile, setProfile] = useState({
    name:"",
    img: "",
    about:""
  });

  const [isInternal, setIsInternal] = useState(false);
  const [profileCode, setProfileCode] = useState('');

  const [communications, setCommunications] = useState([]);
  const [solicitations, setSolicitations] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [recommended, setRecommended] = useState([]);

  const [communicationPage, setCommunicationPage] = useState(2);
  const [groupsPage, setGroupsPage] = useState(2);
  const [invitesPage, setInvitesPage] = useState(2);
  const [solicitationsPage, setSolicitatonsPage] = useState(2);

  const [areasOfInterest, setAreasOfInterest] = useState([]);
  const [groups, setGroups] = useState([]);

  const [changeImage, setChangeImage] = useState(null);
  const [createGroupModalIsOpen, setCreateGroupModalIsOpen] = React.useState(false);
  const [manageInvitesModalIsOpen, setManageInvitesModalIsOpen] = React.useState(false);
  const [manageSolicitationsModalIsOpen, setManageSolicitationsModalIsOpen] = React.useState(false);
  const [hasMoreCommunications, setHasMoreCommunications] = useState(true);
  const [hasMoreGroups, setHasMoreGroups] = useState(true);
  const [hasMoreInvites, setHasMoreInvites] = useState(true);
  const [hasMoreSolicitations, setHasMoreSolicitations] = useState(true);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [url, setURL] = useState(window.location.pathname.split("/"));
  
  const [menuNavigation] = useState([
    {
      link: '#',
      target: '',
      description: MENU_NAVIGATION.PROFILE,
      initialStatus: "active"
    },
    {
      link: '#',
      target: '',
      description: MENU_NAVIGATION.ACCOUNT,
      initialStatus: ""
    }
  ]);


  function handleImageChange(event) {
    setChangeImage(event.target.files[0]);
  }


  async function loadProfile() {
    try{
      setLoadingProgress(50);

      let code = '';

      if(!isInternal) {
        code = window.location.pathname.split("/")[4];
      }

      const response = await gateway.get(`/webbff/lit_app_web/social/getSocialProfileInfo/${code}`);

      let profile = _.get(response, 'profile', null);
      
      setProfile(profile);
      setSolicitations(_.get(response,'pendingSolicitations',[]));
      setInvitations(_.get(response,'pendingInvitations', []));

      setGroups(_.get(response,'myGroups', []));
      setCommunications(_.get(response,'communications', []));

      setSolicitatonsPage(2);
      setInvitesPage(2);
      setGroupsPage(2);
      setCommunicationPage(2);
      setHasMoreInvites(true);
      setHasMoreSolicitations(true);

      setLoadingProgress(100);
    }
    catch(error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  } 

  async function loadMoreCommunications() {
    try{
      setLoadingProgress(50);

      let code = '';

      if(!isInternal) {
        code = "/" + window.location.pathname.split("/")[4];
      }
      
      const response = await gateway.get(`/webbff/lit_app_web/social/getCommunicationsByMember${code}?page=${communicationPage}&limit=10`)

      setCommunicationPage(communicationPage + 1);

      setCommunications([...communications, ..._.get(response, 'docs', [])]);
      setHasMoreCommunications(_.get(response, 'hasNextPage', false));
      setLoadingProgress(100);
    }
    catch(error) {
      setLoadingProgress(100);
    }
  }

  async function loadMoreGroups(page = groupsPage) {
    try{
      setLoadingProgress(50);

      let code = '';

      if(!isInternal) {
        code = "/" + window.location.pathname.split("/")[4];
      }
      
      const response = await gateway.get(`/webbff/lit_app_web/social/getGroupsByMember${code}?page=${page}&limit=10`)

      setGroupsPage(page + 1);
      setGroups([...groups, ..._.get(response, 'docs', [])]);
      setHasMoreGroups(_.get(response, 'hasNextPage', false));

      setLoadingProgress(100);
    }
    catch(error) {
      setLoadingProgress(100);
    }
  }

  async function loadMoreInvites() {
    try{
      if(!hasMoreInvites) return;

      setLoadingProgress(50);
      
      const response = await gateway.get(`/webbff/lit_app_web/social/getPendingInvites?page=${invitesPage}&limit=10`)

      setInvitesPage(invitesPage + 1);

      setInvitations([...invitations, ..._.get(response, 'docs', [])]);
      setHasMoreInvites(_.get(response, 'hasNextPage', false));
      setLoadingProgress(100);
    }
    catch(error) {
      setLoadingProgress(100);
    }
  }

  async function loadMoreSolicitations() {
    try{
      if(!hasMoreSolicitations) return;

      setLoadingProgress(50);
      
      const response = await gateway.get(`/webbff/lit_app_web/social/getPendingSolicitationsByMember?page=${solicitationsPage}&limit=10`)

      setSolicitatonsPage(solicitationsPage + 1);

      setSolicitations([...solicitations, ..._.get(response, 'docs', [])]);
      setHasMoreSolicitations(_.get(response, 'hasNextPage', false));
      setLoadingProgress(100);
    }
    catch(error) {
      setLoadingProgress(100);
    }
  }

  async function loadAreasOfInterest() {
    try{
      const response = await gateway.get(`/webbff/lit_app_web/social/getAllAreasOfInterest`);

      setAreasOfInterest(_.get(response, 'roles' , []));
    }
    catch(error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function acceptInvitatiion(groupCode) {
    try{
      setLoadingProgress(50);

      const body = {
        groupCode,
        action: 'ACCEPT'
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/manageJoinInvitation`, body);

      await loadProfile();

      setLoadingProgress(100);
      setHasMoreGroups(true);
      showToast(response);
    }
    catch(error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  } 

  async function declineInvitation(groupCode) {
    try{
      setLoadingProgress(50);

      const body = {
        groupCode,
        action: 'REFUSE'
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/manageJoinInvitation`, body);

      await loadProfile();

      setLoadingProgress(100);
      showToast(response);
    }
    catch(error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }
  
  async function cancelSolicitation(groupCode) {
    try{
      setLoadingProgress(50);

      const body = {
        groupCode,
        action: 'REFUSE'
      };

      const response = await gateway.post(`/webbff/lit_app_web/social/manageJoinSolicitation`, body);

      await loadProfile();

      setLoadingProgress(100);
      showToast(response);
    }
    catch(error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function createGroup(groupInfo) {
    try{
      setLoadingProgress(50);

      const response = await gateway.post(`/webbff/lit_app_web/social/createGroup`, groupInfo);

      showToast(response);
      await loadProfile();

      setLoadingProgress(100);
      setHasMoreGroups(true);
    }
    catch(error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  useBottomScrollListener(() => { 
    if(hasMoreGroups) {
      loadMoreGroups();
    }
  }, { offset: 500 });

  useEffect(() => {
    if(url[3] === 'internalgroup'){ 
      setIsInternal(true);
      setProfileCode('');
      loadProfile();
      loadAreasOfInterest();
    }
    else {
     setIsInternal(false);
     setProfileCode(url[4]);
     loadProfile();
     loadAreasOfInterest();
    }
  }, [isInternal, profileCode]);

  return (
    <>
      <Screen
        progress={loadingProgress}
        logo={litLogo}
        imageDefault={imageDefault}
        groups={groups}
        solicitations={solicitations}
        recommended={recommended}
        invitations={invitations}
        member={profile}
        postItem={communications}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        changeImage={changeImage}
        areasOfInterest={areasOfInterest}
        handleImageChange={handleImageChange}
        saveGroup={createGroup}
        inviteJoinSolicitation={acceptInvitatiion}
        cancelJoinSolicitation={cancelSolicitation}
        declineInvitation={declineInvitation}
        createGroupModalIsOpen={createGroupModalIsOpen}
        createGroupModalHandleOpen={setCreateGroupModalIsOpen}
        manageInvitesModalIsOpen={manageInvitesModalIsOpen}
        manageInvitesModalHandleOpen={setManageInvitesModalIsOpen}
        menuNavigation={menuNavigation}
        hiddenCreatePost={true}
        hiddenExternalComponents={!isInternal}
        loadMoreCommunications={loadMoreCommunications}
        loadMoreGroups={() => {}}
        loadMoreInvites={loadMoreInvites}
        isInternal={isInternal}
        hasMoreCommunications={hasMoreCommunications}
        hasMoreGroups={hasMoreGroups}
        hasMoreInvites={hasMoreInvites}
        loadMoreSolicitations={loadMoreSolicitations}
        hasMoreSolicitations={hasMoreSolicitations}
        manageSolicitationsModalIsOpen={manageSolicitationsModalIsOpen}
        manageSolicitationsModalHandleOpen={setManageSolicitationsModalIsOpen}
      />
    </>
  );
};
