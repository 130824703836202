import styled from "styled-components";

const TextDescription = styled.div`
  text-align: center;
`;
const LITStyle = styled.span`
  color: #27aae1;
  font-weight: 700;
`;
const BradescoStyle = styled.span`
  color: #fb154c !important;
  font-weight: 700;
`;

export const DragAndDropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const DragAndDropContent = styled.div`
  border: dashed grey 4px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DragAndDropText = styled.span`
  bottom: 50%;
  right: 0;
  left: 0;
  color: grey;
  font-size: 36px;
`;

export const Img = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

export { TextDescription, LITStyle, BradescoStyle };
