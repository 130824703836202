import React from "react";
import {
  APPEARANCE,
  FONT_TYPE,
  SIZE,
  ICON,
  POSITION,
  COLOR,
  USER_ROLE,
  ORIENTATION,
  PAGE_INFORMATION
} from "../../../constants";
import Text from "../Text";
import Clickable from "../Clickable";
import OverflowContent from "../OverflowContent";
import IconInfo from "../../Info/IconInfo";
import Row30x70 from "../../defaultAreas/Row_30-70";
import Column3x3 from "../../defaultAreas/Column_3x3";
import { Description, Favorite, Author, Info, Limit, Blur } from "./styles";

export default ({
  type,
  content,
  maxCharacteres,
  totalMembers,
  administrator,
  showMore,
  setShowMore,
  totalModules,
  totalHours,
  favorite,
  formatNumber
}) => {
  function validateType(value) {
    switch (value) {
      case "GROUP-SUGESTION":
        return (
          <Info>
            <Row30x70
              alignItems={POSITION.TOP_CENTER}
              left={
                <IconInfo
                  icon={ICON.USERS}
                  title={USER_ROLE.MEMBERS}
                  subtitle={totalMembers}
                  size={SIZE.MEDIUM}
                />
              }
              right={
                <IconInfo
                  icon={ICON.USER}
                  title={USER_ROLE.ADMINISTRATOR}
                  subtitle={administrator}
                  size={SIZE.MEDIUM}
                />
              }
            />
          </Info>
        );
      case "CONTENT-SUGESTION":
        return (
          <Info>
            <Column3x3
              alignItems={POSITION.TOP_CENTER}
              left={
                <IconInfo
                  icon={ICON.FILES}
                  title={PAGE_INFORMATION.MODULES}
                  subtitle={totalModules}
                  size={SIZE.MEDIUM}
                  orientation={ORIENTATION.HORIZONTAL}
                />
              }
              middle={
                <IconInfo
                  icon={ICON.TIME}
                  title={PAGE_INFORMATION.HOURS}
                  subtitle={totalHours}
                  size={SIZE.MEDIUM}
                  orientation={ORIENTATION.HORIZONTAL}
                />
              }
              right={
                <IconInfo
                  icon={ICON.USERS}
                  title={PAGE_INFORMATION.STUDENTS}
                  subtitle={formatNumber(totalMembers)}
                  size={SIZE.MEDIUM}
                  orientation={ORIENTATION.HORIZONTAL}
                />
              }
            />
          </Info>
        );
    }
  }

  function showContent(type) {
    switch (type) {
      case "COMMUNICATION":
        return SIZE.LARGE;
      case "CONTENT-SUGESTION":
        return SIZE.SMALL;
      case "GROUP-SUGESTION":
        return SIZE.MEDIUM;
      default:
        return SIZE.SMALL;
    }
  }

  return (
    <>
      <OverflowContent
        orientation={ORIENTATION.VERTICAL}
        size={showContent(type)}
      >
        <Description>
          {validateType(type)}
          {favorite && (
            <Favorite>
              <Clickable appearance={APPEARANCE.LINK}>
                <IconInfo
                  icon={ICON.HEART}
                  title={PAGE_INFORMATION.FAVORITE}
                  size={SIZE.MEDIUM}
                  orientation={ORIENTATION.VERTICAL}
                />
              </Clickable>
            </Favorite>
          )}

          <Limit visible={content.lenght < maxCharacteres}>
            <Text
              size={SIZE.MEDIUM}
              text={content}
              color={COLOR.TEXT_PRIMARY}
            />
          </Limit>

          <Author>
            <Text
              size={SIZE.MEDIUM}
              text={administrator}
              color={COLOR.TEXT_PRIMARY}
              fontType={FONT_TYPE.BOLD}
            />
          </Author>
        </Description>
      </OverflowContent>
      <Blur />
    </>
  );
};
