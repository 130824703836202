import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';

import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';
// import WatsonRecomendacoes from "./WatsonRecomendacoes";
import WatsonChat from './WatsonChat';
import ModalVideo from '../ModalVideo';
import { Creators as PaulChatActions } from '../../store/ducks/paulChat/actions';
import history from '../../utils/history';

import adsTracking from '../../utils/adsTracking';

class WatsonChatPage extends Component {
  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidMount() {
    const firstName = localStorage.getItem('firstName');
    const idProfile = localStorage.getItem('idProfile');
    const { setUserSession } = this.props;
    const user =
      firstName !== '' && idProfile !== ''
        ? `${firstName}${idProfile}`
        : 'usuarioLit';
    if (user !== 'usuarioLit') {
      setUserSession(user);
    }

    setTimeout(() => {
      history.push('home');
    }, 5000);
  }

  componentWillUnmount() {
    const { sendFeedbackRequest, resetOnLeaving } = this.props;
    sendFeedbackRequest();
    resetOnLeaving();
  }

  render() {
    const { location } = this.props;
    return (
      <div id="watsonChatPage" style={{ background: '#fff' }}>
        <ModalVideo indexTutorial={11} modal titleModal="PAUL" />
        <NavBarSuperior />
        <div
          className="container-fluid"
          style={
            {
              height: '86.7vh',
              paddingTop: 60,
              backgroundColor: 'white'
            } /* Remover quando recomendações estiver pronto */
          }
        >
          <h1 style={{ marginTop: 60 }}>Temporariamente desativado.</h1>
          <p>Você será redirecionado ...</p>
          <div className="row" style={{ background: '#fff', height: '100%' }}>
            {/* <WatsonChat fromNavbar={location.state ? location.state.fromNavBar : undefined} /> */}
            {/* <WatsonRecomendacoes /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  litUser: state.paulChat.user,
  didTheyChat: !(state.paulChat.chatMessages.length <= 1)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(PaulChatActions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WatsonChatPage)
);
