import React, { useContext } from "react";

import { ActionButton } from "./styles";
import { MeetingContext } from "../../../MeetingContext";

export default React.memo(props => {
  const { isMeetingFullscreen, isExitingFromMeeting } = useContext(
    MeetingContext
  );

  return (
    <ActionButton
      {...props}
      title={isExitingFromMeeting ? "Saindo da reunião..." : props.title}
      disabled={isExitingFromMeeting}
      className={isMeetingFullscreen ? "fullscreen" : ""}
    >
      {props.children}
    </ActionButton>
  );
});
