import React, { memo } from "react"
import PropTypes from "prop-types"

import GroupCard from "./GroupCard"

import Loading from "../../../Loading/Loading"

const Sugestoes = ({ grupos }, { router }) => {
  const enterGroup = id => {
    router.history.push(`/grupo/${id}`)
  }

  if (grupos.fetching) {
    return <Loading width={40} />
  }
  if (grupos.data.length === 0) {
    return <p className="text-center pt-3">Nenhum grupo encontrado</p>
  }

  return (
    <ul className="list-group">
      {grupos.data.map((grupo, i) => {
        if (i >= 5) return null
        return (
          <GroupCard grupo={grupo} key={grupo.idGroup} enterGroup={idGroup => enterGroup(idGroup)} />
        )
      })}
    </ul>
  )
}

Sugestoes.contextTypes = {
  router: PropTypes.shape(),
}

export default memo(Sugestoes)
