import React, { useState, useRef } from "react";
import moment from "moment"
import "moment/locale/pt-br"

import {
  TIMELINE_ACTIONS,
  APPEARANCE,
  ICON,
  COLOR,
  SIZE,
  CONFIRMATION,
  PAGE_INFORMATION,
  POSITION,
  REACTIONS
} from "../../../constants";
import AccordionHeader from "../AccordionToggle";
import AccordionBody from "../AccordionContent";
import Text from "../Text";
import Dropdown from "../../Inputs/Dropdown";
import CommentBox from "../../Inputs/CommentBox";
import MemberInfo from "../../Info/DataInfo";
import Archives from "../../Archives";
import Line from "../Line";
import Actions from "../../Actions";
import Card from "../Card";
import CardHeader from "../CardHeader";
import CardBody from "../CardBody";
import ModalCall from "../../CallModal/InterationsModalCall";
import InterationsModal from "../../Modals/InterationsModal";
import { Span, ButtonText, Container, ReactionsContainer } from "./styles";
import ModalMessage from "../../Modals/GenericModalMessage";

export default ({
  id,
  member,
  members,
  postMember,
  postContent,
  postArchives,
  postGroup,
  isAdministrator,
  hiddenAnswer,
  postDate,
  setHiddenAnswer,
  savePostEdit,
  saveNewComment,
  isOwner,
  hiddenCreatePost,
  interationsModalIsOpen,
  setInterationsModalIsOpen,
  reactions,
  code,
  loadCommunicationReactions,
  loadReactionPeople,
  reactionsTypes,
  hasReaction,
  updateCommunicationReactions,
  hideReplay,
  deleteCommunication,
  videoTime,
  setVideoTime,
  showTimer
}) => {
  const [hiddenEditComment, setHiddenEditComment] = useState(true);
  const [showDeletePostModal, setShowDeletePostModal] = useState(false);
  const [showReactionOptions, setShowReactionOptions] = useState(false);
  const [, setSelectingReaction] = useState(false);
  const timeoutId = useRef(null);

  function showReactionsControls() {
    const buttons = [];

    if (!hideReplay) {
      buttons.push({
        type: "button",
        icon: ICON.MESSAGE,
        buttonText: TIMELINE_ACTIONS.RETRY,
        color: COLOR.TEXT_PRIMARY,
        appearance: APPEARANCE.LINK,
        action: () => {
          setHiddenAnswer(!hiddenAnswer);
        }
      });
    }

    // buttons.push({
    //   type: "button",
    //   icon: showReactionOptions ? ICON.UP_ARROW : ICON.DOWN_ARROW,
    //   color: COLOR.TEXT_PRIMARY,
    //   appearance: APPEARANCE.LINK,
    //   buttonText: TIMELINE_ACTIONS.REACT,
    //   action: () => {
    //     setShowReactionOptions(!showReactionOptions);
    //   },
    //   onEnter: async () => {
    //     setShowReactionOptions(true);
    //   },
    //   onLeave: () => {
    //     timeoutId.current = setTimeout(() => {
    //       setShowReactionOptions(false)
    //     }, 200);
    //   }
    // });

    // if (reactions) {
    //   buttons.push({
    //     type: "button",
    //     appearance: APPEARANCE.LINK,
    //     buttonText: `${TIMELINE_ACTIONS.LIST_REACTIONS} (${reactions})`,
    //     color: COLOR.TEXT_PRIMARY,
    //     action: () => {
    //       setInterationsModalIsOpen(true);
    //     }
    //   });
    // }

    return buttons;
  }
  
  const showReactionsButtons = React.useCallback(() => {
    const reactionsButtons = [];
    const currentReaction = hasReaction
      ? reactionsTypes.find(reaction => reaction.value === hasReaction.type)
      : false;

    reactionsTypes.forEach(type => {
      const button = {
        type: "button",
        icon:  REACTIONS[type.value] ? REACTIONS[type.value].ICON : ICON.CIRCLE,
        color: COLOR.TEXT_PRIMARY,
        buttonText: REACTIONS[type.value] ? REACTIONS[type.value].LABEL : '',
        appearance: APPEARANCE.LINK,
        action: () => {
          updateCommunicationReactions(
            code,
            type.value,
            hasReaction ? currentReaction.value : undefined
          )
          setShowReactionOptions(false);
        }
      };

      if (hasReaction && currentReaction.value === type.value) {
        button.appearance = APPEARANCE.LINK_ICON_ACTIVE;
      }

      reactionsButtons.push(button);
    });

    return reactionsButtons;
  }, []);

  function showTimerButton() {
    if (showTimer) {
      let minutes = Math.trunc(videoTime / 60);
      let seconds = Math.trunc(videoTime % 60);

      if (minutes < 10) minutes = `0${  minutes}`;

      if (seconds < 10) seconds = `0${  seconds}`;

      return (
        <ButtonText
          text={`Tempo do video ${minutes}:${seconds}`}
          onClick={setVideoTime}
        >
          {`Tempo do video ${minutes}:${seconds}`}
        </ButtonText>
      );
    }
  }

  function getMonthsPost(postDate) {
    const d1 = new Date(postDate);
    const diffDates = moment(d1).fromNow();
    return diffDates;
  }

  return (
    <>
      <Card appearance={APPEARANCE.POST}>
        <CardHeader appearance={APPEARANCE.POST}>
          <AccordionHeader id={id}>
            <Container>
              <MemberInfo
                style={{ backgroudColor: "white" }}
                id={postMember.cr_code}
                name={postMember.name}
                img={postMember.img}
                // link={`/beta/social/externalgroup/${postMember.cr_code}`}
                description={`${getMonthsPost(postDate)}`}
                // description={`${PAGE_INFORMATION.PUBLISHED_WHEN} ${formatDate(
                //   postDate
                // )} ${PAGE_INFORMATION.IN} ${postGroup ? postGroup.name : ""} `}
              >
                {showTimerButton()}
              </MemberInfo>
            </Container>
          </AccordionHeader>
          {!hiddenCreatePost && (isOwner || isAdministrator) && (
            <Dropdown
              icon={ICON.ELLIPSIS}
              options={[
                {
                  action: () => {
                    setHiddenEditComment(false);
                  },
                  description: TIMELINE_ACTIONS.EDIT_POST
                },
                {
                  action: () => {
                    setShowDeletePostModal(true);
                  },
                  description: TIMELINE_ACTIONS.REMOVE_POST
                }
              ]}
            />
          )}
        </CardHeader>
        <AccordionBody>
          <CardBody appearance={APPEARANCE.POST}>
            <Span hidden={!hiddenEditComment}>
              {postContent && (
                <Text
                  text={postContent}
                  size={SIZE.MEDIUM}
                  color={COLOR.TEXT_PRIMARY}
                />
              )}
              {postArchives && (
                <Archives archives={postArchives} color={COLOR.LIGHT} />
              )}
              {!hiddenCreatePost && (
                <Line alignItems={POSITION.LEFT}>
                  <Actions
                    position={POSITION.LEFT}
                    itemsToRender={showReactionsControls()}
                  />
                </Line>
              )}
              {!hiddenCreatePost && (
                <Line alignItems={POSITION.LEFT}>
                  <ReactionsContainer 
                    show={showReactionOptions}
                    onMouseEnter={() => {
                      if(timeoutId.current) {
                        clearTimeout(timeoutId.current);
                      }
                      setShowReactionOptions(true);
                      setSelectingReaction(true);
                    }}
                    onMouseLeave={() => {
                      setShowReactionOptions(false);
                      setSelectingReaction(false);
                      setShowReactionOptions(false);
                    }}
                  >
                    <Actions
                      position={POSITION.LEFT}
                      itemsToRender={showReactionsButtons()}
                    />
                  </ReactionsContainer>
                </Line>
              )}
              <Line hidden={hiddenAnswer}>
                <CommentBox
                  onCloseCommentBox={() => setHiddenAnswer((prevState => !prevState))}
                  appearance={APPEARANCE.SIMPLE_ANSWER}
                  image={postMember.img}
                  placeholder={TIMELINE_ACTIONS.RETRY_COMMENT}
                  size={SIZE.SMALL}
                  sendAction={(content, files) => {
                    saveNewComment(content, id, files);
                    setHiddenAnswer(true);
                  }}
                />
              </Line>
            </Span>
            <Line hidden={hiddenEditComment}>
              <CommentBox
                image={postMember.img}
                placeholder={postContent}
                onCloseCommentBox={() => setHiddenEditComment((prevState => !prevState))}
                size={SIZE.MEDIUM}
                isEdit
                attachs={postArchives}
                sendAction={(content, files, removedFiles) => {
                  savePostEdit(content, files, removedFiles);
                  setHiddenEditComment(true);
                }}
              />
            </Line>
          </CardBody>
        </AccordionBody>
      </Card>
      <ModalCall
        modalHeaderText={PAGE_INFORMATION.WHO_INTERACTED}
        isOpen={interationsModalIsOpen}
        handleIsOpen={setInterationsModalIsOpen}
      >
        <InterationsModal
          code={code}
          members={members}
          member={member}
          loadCommunicationReactions={loadCommunicationReactions}
          loadReactionPeople={loadReactionPeople}
        />
      </ModalCall>
      <ModalCall
        modalHeaderText={PAGE_INFORMATION.DELETE_POST}
        isOpen={showDeletePostModal}
        handleIsOpen={setShowDeletePostModal}
      >
        <ModalMessage
          action={() => {
            setShowDeletePostModal(false);
            deleteCommunication(id);
          }}
          message={CONFIRMATION.CONFIRM_REMOVE_POST}
        />
      </ModalCall>
    </>
  );
};
