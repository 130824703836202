import styled from "styled-components";
import { } from "../../../constants";

export const Div = styled.div`
  
  margin: 1rem 0 2rem!important;
  display: block;
  width: 100%;
  text-align: left;
  label { display: block; }
  
`;
