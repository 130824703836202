import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import swal from "@sweetalert/with-react";
import _ from "lodash";

import Text from "../Text";
import Header from "../Header";
import Button from "../Button";
import Loading from "../Loading";

import { gateway } from "../../../../utils/api";

import * as Styles from "./styles";

function PaymentTerm({
  visible,
  onBack,
  onReject,
  onAccept,
  termUrl,
  showButtons = true,
  processingAccept
}) {
  const [pdfTotalPages, setPdfTotalPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setPdfTotalPages(numPages);
  }

  return (
    <>
      <Styles.Container visible={visible}>
        <div className="align-self-center">
          <Header onBack={onBack} />

          <Styles.Content>
            {termUrl && (
              <Document file={termUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array(...Array(pdfTotalPages))
                  .map((x, i) => i + 1)
                  .map(page => (
                    <Page
                      pageNumber={page}
                      width={
                        window.innerWidth >= 992
                          ? window.innerWidth * 0.55
                          : window.innerWidth * 0.9
                      }
                    />
                  ))}
              </Document>
            )}
          </Styles.Content>

          {showButtons && (
            <div className="row mt-2">
              <div className="col-6">
                <Button
                  backgroundColor="transparent"
                  textColor="black"
                  onClick={onReject}
                >
                  REJEITAR
                </Button>
              </div>
              <div className="col-6">
                <Button onClick={onAccept} disabled={processingAccept}>
                  {processingAccept ? <Loading /> : "ACEITAR"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Styles.Container>
      <Styles.Overlay visible={visible} />
    </>
  );
}

export default PaymentTerm;
