import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Creators as PaymentActions } from "../../store/ducks/payments/actions"
import PagamentoAnual from "./PagamentoAnual"
import PagamentoMensal from "./PagamentoMensal"

import api from "../../utils/api"

class Informacoes extends Component {
  state = {
    pagamento: true,
    mensal: {
      totalFinal: 0,
    },
    anual: {
      totalFinal: 0,
      discountPercent: 0,
    },
    isPartner: false,
  }

  componentDidMount() {
    this.getPaymentsValues()
    this.getPartner()
  }

  getPartner = () => {
    const local = localStorage.getItem("urlRedirect")
    /* const isRedirect = partnerGenial.find(org => org === local)
    if (isRedirect) { */
    this.setState({ isPartner: true })
    /* } */
  }

  getPaymentsValues = async () => {
    try {
      const url = "getPaymentTypes"
      const response = await api.get(url)
      this.setState({ mensal: response[0], anual: response[1] })

      this.pagamentoMensal()
    } catch (error) {
      console.log(error.response)
    }
  }

  pagamentoMensal = () => {
    const { mensal } = this.state
    const { setPaymentValue } = this.props
    this.setState({ pagamento: true })
    const data = {
      total: mensal.totalFinal,
      amount: mensal.totalValue,
      period: mensal.monthlyPeriod,
    }
    setPaymentValue(data)
  }

  pagamentoAnual = () => {
    const { anual } = this.state
    const { setPaymentValue } = this.props
    this.setState({ pagamento: false })
    const data = {
      total: anual.totalFinal,
      amount: anual.totalValue,
      period: anual.monthlyPeriod,
    }
    setPaymentValue(data)
  }

  render() {
    const { mensal, anual, pagamento, isPartner } = this.state
    return (
      <div className="row informacoes">
        <div className="col-10">
          <div id="pagamento--assine-lit" className="row">
            {/* <h1 style={{width: '100%', textAlign: 'center'}}>Escolha o melhor plano para você.</h1> */}
            {/* <p>
              Acesse tudo, sim, todo conteúdo mesmo! São mais de 20 mil horas de conteúdo da Saint Paul
              Escola de Negócios, centenas de cursos, 1.500 exercícios e casos com resoluções passo a
              passo, trilhas de 10 programas de MBAs, além de uma biblioteca com mais de 8 mil livros
              disponíveis.
            </p> */}
          </div>
          {/* !isPartner && (
            <div className="row">
              <div id="pagamento--comece-estudar">
                Comece a estudar agora com <span>07 dias</span> grátis
              </div>
            </div>
          ) */}
          {/* {pagamento ? (
            ""
          ) : (
              <div className="row">
                <div id="pagamento--comece-estudar">
                  Você será cobrado no ato do cadastro para aproveitar a promoção!
                  </div>
              </div>
            )} */}
          {/* <div className="row">
            <h4>Escolha seu plano</h4>
          </div> */}
          {/* <div className="row">
            <button
              type="button"
              className={`btn btn-white pagamento ${pagamento ? "active" : null}`}
              onClick={this.pagamentoMensal}
            >
              Pagamento mensal
            </button>
            <button
              type="button"
              className={`btn btn-white pagamento ${!pagamento ? "active" : null}`}
              onClick={this.pagamentoAnual}
            >
              Pagamento anual
            </button>
          </div> */}
          {/* <div id="pagamento--resultado-pagamento" className="row">
            <div className="row">
              {pagamento ? (
                <PagamentoMensal value={mensal.totalValue} />
              ) : (
                  <PagamentoAnual value={anual.totalFinal} percentage={anual.discountPercent} />
                )}
            </div>
          </div> */}

        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(PaymentActions, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(Informacoes)
