import React, { useState } from "react";
import Screen from "./screen";

export default ({
  members,
  group,
  member,
  visibility,
  communications,
  infoPage,
  successImage,
  isMember,
  isAdministrator,
  totalMembers,
  groupRoles,
  updatePost,
  createComment,
  createPost,
  toggleLike,
  deleteCommunication,
  removeMember,
  alterMemberRole,
  transferGroupPossession,
  interationsModalIsOpen,
  setInterationsModalIsOpen,
  loadCommunicationReactions,
  loadReactionPeople,
  loadMoreCommunications,
  reactionsTypes,
  updateCommunicationReactions,
  hasMoreCommunications,
}) => {
  const [user, setUser] = useState(null);

  async function setProfileUser(user) {
    setUser(user);
  }

  return (
    <>
      <Screen
       isMember={isMember}
       isAdministrator={isAdministrator}
       interationsModalIsOpen={interationsModalIsOpen}
       setInterationsModalIsOpen={setInterationsModalIsOpen}
       totalMembers={totalMembers}
       groupRoles={groupRoles}
       members={members}
       group={group}
       member={member}
       communications={communications}
       infoPage={infoPage}
       successImage={successImage}
       updatePost={updatePost}
       createComment={createComment}
       createPost={createPost}
       toggleLike={toggleLike}
       deleteCommunication={deleteCommunication}
       removeMember={removeMember}
       alterMemberRole={alterMemberRole}
       setProfileUser={setProfileUser}
       transferGroupPossession={transferGroupPossession}
       visibility={visibility}
       user={user}
       loadCommunicationReactions={loadCommunicationReactions}
       loadReactionPeople={loadReactionPeople}
       loadMoreCommunications={loadMoreCommunications}
       reactionsTypes={reactionsTypes}
       updateCommunicationReactions={updateCommunicationReactions}
       hasMoreCommunications={hasMoreCommunications}
      />
    </>
  );
};
