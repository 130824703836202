import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import Img from "react-image"
import NavBar from "../Termos/Navbar"
import WatsonBadgeFooter from "./WatsonBadgeFooter"
import { Creators as ProfileActions } from "../../store/ducks/user/profile/actions"

import api from "../../utils/api"
import { API_URL_IMAGES } from "../../utils/constants"
import Loading from "../Loading/Loading"

const GIF_LIT = require("../../images/animacao_paul_standby.gif")

class Carreira extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    userFirstName: "Aluno",
    isPartner: false,
    idCompany: "",
    nameCompany: "",
    description: "",
    title: "",
    finishedCalls: false,
    routeNext: '/interesses',
    forceSuggestions: false
  }

  componentDidMount() {
    this.getDataCompany()
    const idProfile = localStorage.getItem("idProfile")
    const { getProfileRequest } = this.props
    const userFirstName = localStorage.getItem("firstName")
    getProfileRequest(idProfile)
    this.setState({ userFirstName })
  }

  getDataCompany = async () => {
    try {
      const email = localStorage.getItem("ra")

      const response = await api.get(`onboard/${email}/`)
      
      this.setState({
        isPartner: true,
        finishedCalls: true,
        idCompany: response.company ? response.company.idCompany : '',
        nameCompany: response.company ? response.company.company : '',
        description: response.description,
        title: response.title,
      })

      const freeUser = JSON.parse(localStorage.getItem('freeUser'))

      const onBoardIncomplete = JSON.parse(localStorage.getItem('onBoardIncomplete'))

      if(onBoardIncomplete && !freeUser) {
        const next = '/formacao'
        this.setState({
          routeNext: next
        })
      }

      if(onBoardIncomplete === false && !freeUser) {
        const next = '/home'
        this.setState({
          routeNext: next
        })
      }
      
      if (!response.defaultOnboard 
        && (response.company && response.company.idCompany === 7)
        && freeUser
        || !response.askCustomize
        || response.forceSuggestions
      ) {
        // Validações da empresa Bradesco
        const next = response.forceSuggestions ? '/interesses' : '/home'
        this.setState({
          routeNext: next,
          nameCompany: '',
          forceSuggestions: response.forceSuggestions,
        })
      }
    } catch (error) {
      this.setState({ finishedCalls: true })
      console.log(error)
    }
  }

  nextStep = () => {
    const { router } = this.context
    const {routeNext, forceSuggestions} = this.state;
    router.history.push({pathname: routeNext, state: {forceSuggestions}})
  }

  render() {
    const {
      userFirstName,
      idCompany,
      isPartner,
      nameCompany,
      description,
      title,
      finishedCalls,
    } = this.state
   
    return (
      <div id="personalizar">
        <NavBar />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {finishedCalls ? (
                <div className="container" id="objetivos-personalizar">
                  {isPartner ? (
                    <div>
                      <div className="row justify-content-md-center">
                        <div className="col-6 text-center">
                          <Img
                            alt="Curso"
                            className="rounded-0 img-course img-responsive img-carreira"
                            src={`${API_URL_IMAGES}images/logo/${idCompany}.jpg`}
                            unloader={<div />}
                          />
                          <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: nameCompany }} />
                        </div>
                      </div>
                      <div className="row justify-content-md-center text-center">
                        <div className="col-10 objetivos-personalizar--desc-usuario text-body-pi">
                         
                          {/* <h5>Olá, {userFirstName}!</h5> */}
                          {(title && title.length > 0) ? ( <h5 dangerouslySetInnerHTML={{ __html: title }} />) : ( <h5>Olá, {userFirstName}!</h5>)}
                          <div dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                      </div>

                      <div className="row justify-content-center" id="selecionar-personalizar">
                        <div className="d-flex justify-content-center col-2">
                          <button
                            type="button"
                            onClick={this.nextStep}
                            className="btn btn-blue btn-FontWeight6"
                          >
                            Iniciar
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="row justify-content-md-center">
                        <div className="col-6 objetivos-personalizar--desc-usuario text-center">
                          <img
                            alt="Curso"
                            className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                            src={GIF_LIT}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-md-center text-center">
                        <div className="col-10 objetivos-personalizar--desc-usuario text-body-pi">
                         
                          {(title && title.length > 0) ? ( <h5>{title}</h5>) : ( <h5>Olá, {userFirstName}!</h5>)}

                          {description ? (
                              <div dangerouslySetInnerHTML={{ __html: description }} />
                          ) : (
                            <>
                               <p>
                                Aqui quem fala é o Paul, a inteligência artificial do LIT. Estamos muito
                                felizes com o seu cadastro e com seu interesse em fazer parte de uma
                                verdadeira revolução disruptiva da aprendizagem!
                              </p>
                              <p>
                                Você tem acesso a todo conteúdo LIT! São centenas de cursos da Saint Paul
                                Escola de Negócios,mais de 20 mil horas de conteúdo, 1.500 exercícios e casos
                                com resolução passo a passo, trilhas para 10 programas de MBAs, além de uma
                                biblioteca com mais de 8 mil livros disponíveis.
                              </p>
                              <p>
                                Adapte sua experiência de aprendizado ao seu perfil: no LIT, cada onlearner
                                tem um caminho personalizado.
                              </p>
                            </>
                          )}
                         
                        </div>
                      </div>

                      <div className="row justify-content-center" id="selecionar-personalizar">
                        <div className="d-flex justify-content-center col-2">
                          <button
                            type="button"
                            onClick={this.nextStep}
                            className="btn btn-blue btn-FontWeight6"
                            id="btn-start-customize"
                          >
                            Começar Personalização
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <WatsonBadgeFooter />
                </div>
              ) : (
                <Loading img width={60} height={60} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(ProfileActions, dispatch)

export default connect(null, mapDispatchToProps)(Carreira)
