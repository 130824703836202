import React, { PureComponent } from "react"
import Modal from "react-responsive-modal"
import logLit from "../../images/lit-azul.png"

class ModalIE extends PureComponent {
  state = {
    openModal: false,
  }

  componentDidMount() {
    this.isIE()
  }

  onCloseModal = () => {
    this.setState({ openModal: false })
  }

  isIE = () => {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf("MSIE ")
    if (msie > 0) {
      this.setState({ openModal: true })
    }
    const trident = ua.indexOf("Trident/")
    if (trident > 0) {
      this.setState({ openModal: true })
    }
  }

  render() {
    const { openModal } = this.state
    return (
      <Modal
        open={openModal}
        onClose={this.onCloseModal}
        classNames={{ modal: "lit-modal-restrict" }}
        closeOnOverlayClick={false}
      >
        <div className="modal-content">
          <div className="modal-header justify-content-start">
            <img src={logLit} alt="logoLit" height={45} />
          </div>
          <div className="modal-body" style={{ padding: "1em 7em 0 7em" }}>
            <p>
              Olá, para assistir a série NA REAL a qualquer hora na palma da sua mão, baixe o APP do LIT para 
              <a href='https://play.google.com/store/apps/details?id=com.saintpaulapp' target='_blank' rel="noopener noreferrer"> <strong>Android</strong> </a>
              ou <a href='https://apps.apple.com/br/app/lit/id1310754004' target='_blank' rel="noopener noreferrer"> <strong>IOS</strong> </a>.
            </p>
            <p>
              Para uma melhor experiência no desktop, recomendamos utilizar os navegadores 
              <a href='https://www.google.com/chrome/' target='_blank' rel="noopener noreferrer"> <strong>Chrome</strong> </a>
              ou <a href='https://support.apple.com/pt_BR/downloads/safari' target='_blank' rel="noopener noreferrer"> <strong>Safari</strong> </a>.
            </p>
          </div>
          <div className="modal-footer justify-content-center" />
        </div>
      </Modal>
    )
  }
}

export default ModalIE
