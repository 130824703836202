import React from "react"
import Img from "react-image"

import { API_URL_IMAGES } from "../../utils/constants"
import placeholder from "../../images/placeholder-professor.png"

const Avatar = ({ nome, id }) => {
  return (
    <div className="container-fluid" id="perfil-infos">
      <div className="perfil-infos d-flex flex-column align-items-center">
        <Img
          className="rounded-circle"
          src={[`${API_URL_IMAGES}images/profile/${id}.jpg`, placeholder]}
        />
        <h6 style={{ fontSize: "1.5em", fontWeight: 500, textAlign: "center" }}>{nome}</h6>
        <h5 style={{ fontSize: "1.2em" }}>Professor LIT</h5>
      </div>
    </div>
  )
}

export default Avatar
