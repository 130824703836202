import styled from "styled-components"
import IntlTelInput from 'react-intl-tel-input'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { LitButton } from "../shared/LitButton"

const InputWrap = styled.div`
  /* width: 20.1rem; */
  height: 53px;
  background: ${props =>
    props.error ? "#ff0000" : "linear-gradient(45deg, rgb(2, 196, 255) 0%, rgb(142, 230, 123) 100%)"};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 0.96px;
  padding-right: 0.96px;
  margin: 0.8px;

  .intl-tel-input {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 19.9rem; */
    height: 51px;
    border: none;
    /* background-color: #fff !important; */
    border-radius: 10px !important;
    font-size: 16px;
    
    width: 100%;
    background: red;

    input {
      background: yellow;
      width: 100% !important;
      padding: 0rem 1rem 0rem;
      height: 51px;
      border: none;
      background-color: #fff !important;
      border-radius: 10px !important;
    }
  }
`

const InputText = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 19.9rem; */
  height: 51px;
  border: none;
  background-color: #fff !important;
  border-radius: 10px !important;
  font-size: 16px;
  padding: 0rem 1rem 0rem;

  &:-webkit-input-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19.9rem;
    height: 3.2rem;
    border: none;
    border-radius: 10px;
    font-size: 19px;
    padding: 0rem 1rem 0rem;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus {
    outline: none;
    padding: 0rem 1rem 0rem;
  }

  &::before {
    background: linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%);
  }

  &:focus ~ label {
    visibility: visible;
  }
`

const InputPhone = styled(IntlTelInput)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 19.9rem; */
  height: 51px;
  border: none;
  background-color: #fff !important;
  border-radius: 10px !important;
  font-size: 16px;
  padding: 0rem 1rem 0rem;
`

export const InputPhone2 = styled(PhoneInput)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 19.9rem; */
  height: 51px;
  border: none;
  background-color: #fff !important;
  border-radius: 10px !important;
  font-size: 16px;
  padding: 0rem 1rem 0rem;

  .PhoneInputInput {
    border: 0;
  }

  .PhoneInputInput:focus {
    border: 0;
  }
`

const InputLabel = styled.label`
  position: absolute;
  visibility: hidden;
  margin-top: -1rem;
  padding-left: 1rem;
  font-size: 13px;
  color: #000;
  font-weight: 800;
  display: flex;
  width: 100%;
`

const ButtonRegister = styled(LitButton)`
  /* width: 10rem;
  font-size: 1rem;
  padding: 5px 20px;

  &:disabled {
    background: #c4c0c0;
    cursor: not-allowed;
  } */
`

const ItemFormField = styled.div`
  border: 1px solid #a9b7c4;
  padding: 0 20px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
`
const ItemFormFieldDisabled = styled.div`
  border: 1px solid #a9b7c4;
  padding: 0 20px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: #e9ecef;

  input{
    background-color: #e9ecef !important;
  }
`
const ItemFormFieldSelect = styled.div`
  border: 1px solid #a9b7c4;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  select{
    border:none !important;
    height: 51px !important;
  }
`

const LabelField = styled.label`
  font-weight: bold;
  color: #444;
  margin-bottom: 10px;
`

export { 
  InputWrap, 
  InputText, 
  InputLabel, 
  ButtonRegister, 
  InputPhone, 
  ItemFormField, 
  LabelField,
  ItemFormFieldSelect,
  ItemFormFieldDisabled }
