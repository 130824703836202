import React, { memo } from "react"
import { Link } from "react-router-dom"

const RetornoLogin = () => {
  return (
    <div className="row justify-content-around criar-conta">
      <p>
        Não deseja redefinir sua senha?
        <span>
          <Link to="/"> Faça Login agora</Link>
        </span>
      </p>
    </div>
  )
}

export default memo(RetornoLogin)
