import React, { useEffect, useState } from "react"
import _ from 'lodash';
import NavBarSuperior from "../../NavBarSuperior/NavBarSuperior"
import ProgressBar from "../../LitAtomicComponents/basicComponents/ProgressBar";
import * as Styles from './styles';
import { gateway } from "../../../utils/api";
import LaneCourses from "../../Home/LaneCourses";
import { getInitialsTeacher } from "../../../utils/functions";
import { BLUE_LIT } from "../../../utils/colors";

function Teacher(props) {

  const [teacherData, setTeacherData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showCompletedText, setShowCompletedText] = useState(false)

  const tenant = localStorage.getItem('tenant') || ''
  const settings = JSON.parse(localStorage.getItem('settings')) || null

  const { headerBackgroundColor } = _.get(
    settings,
    `${tenant}`,
    {}
  );

  const getTeacherData = async teacherCrCode => {
    try {
      const response = await gateway.get(`/content/content/${teacherCrCode}/professor`);     

      if(!teacherData) return;
      const courses = _.get(response, 'courses', [])
      const resposeWithImgUrl = courses.map(item => {
        return {
          ...item,
          curso: item.name,
          idCourse: item.code,
          courseImageUrl: item.cover,
        }
      });

      const reponseWithCourses = {
        ...response,
        courses: resposeWithImgUrl
      };

      setTeacherData(reponseWithCourses) 
      setLoading(false) 

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const {
      match: {
        params: { teacherCrCode }
      },
    } = props;
    getTeacherData(teacherCrCode)
  }, []);

  return (
    <>
    <NavBarSuperior courses reviewSmartBar />
     <Styles.Container shadow>
        <Styles.IconContainer>          
        </Styles.IconContainer>
        <Styles.NavSection isTrail={false}>
              <Styles.NavButton 
                onClick={() => console.log('prev')}>
                <></>
            </Styles.NavButton>
            <Styles.CourseTitle>
                <strong>{teacherData.name}</strong>
                <Styles.DescriptionUC>
                  Visão geral
                </Styles.DescriptionUC>
            </Styles.CourseTitle>
            <Styles.NavButton 
                onClick={() => console.log('next')}>
                <></>
            </Styles.NavButton>
        </Styles.NavSection>
        <Styles.ProgressContainer isTrail={false}>
            <ProgressBar data={65} />
        </Styles.ProgressContainer>
      </Styles.Container>    
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-lg-2 col-md-2 col-sm-12 ml-3">
            <Styles.BoxTeacher>
              {teacherData.image ? <Styles.TeacherThumbnail src={teacherData.image} width={150} height={150} alt="" className="img-rounded mr-3" style={{ borderRadius: 100 }} /> 
              : <Styles.ImageDefault style={{ background: headerBackgroundColor }}>
                <Styles.LetterImageDefault href="#">{getInitialsTeacher(teacherData)}</Styles.LetterImageDefault>
                </Styles.ImageDefault>
                }
            </Styles.BoxTeacher>      
          </div>
          <div className="col pr-2">
                <h3 className="mb-4">{loading ? 'Carregando ...' : teacherData.name}</h3>
                {teacherData && teacherData.about && <>
                {showCompletedText ? 
                <p>{teacherData.about} <a onClick={() => setShowCompletedText(prevState => !prevState)} href="#" style={{ color: BLUE_LIT }}>Ver menos</a></p>
                : <p>{(teacherData.about).substring(0, 450)} ... <a onClick={() => setShowCompletedText(prevState => !prevState)} href="#" style={{ color: BLUE_LIT }}>Ler mais</a></p>}
                </>}
          </div>
        </div>
        <div className="row">
            <LaneCourses data={{
              docs: teacherData.courses,
              lane: {
                name: `Cursos ministrados pelo professor ${teacherData.name}`
              }
            }} />
        </div>
      </div>
      </>
  )
}

export default Teacher 
