import React from 'react';

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";
import RecommendationBox from "./components/RecommendationBox";

import { Content } from './styles';
import RecommendationPageTitle from "./components/RecommendationPageTitle";

function Screen() {
  return (
    <div>
      <NavBarSuperior />

      <div className="container-fluid">
        <Content>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <RecommendationPageTitle />
              <RecommendationBox />
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
}

Screen.propTypes = {};

export default Screen;
