import styled, { css } from "styled-components";
import { APPEARANCE } from "../../../constants";

export const CommentBox = styled.div`
  width: 90%;
  border: 2px solid #F2F4F7;
  color: var(--post-create-text);
  background-color: #F2F4F7;
  border-radius: 50px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 1rem 0;
  textarea, 
  textarea:focus {
    border: 0;
    line-height: 1.2;
    font-size: 14px;
    font-style: italic;
    margin-left: 15px;
    max-width: 96%;
    border-color: var(--transparent);
  }
  i {
    margin-right: 15px;
  }

  .closeComment {
    position: absolute;
    padding:0;
    margin: 0;
    right: -8px;
    cursor: pointer;
    top: -13px;
    background: none;
    border: 0;
    background: #777777;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }
  }
  
  .content-button {
    position: absolute;
    right: -35px;
    i {
      margin-right: initial;
    }
  }
  @media screen and (max-width: 579px) {
    line-height: 1;
    left: 5px;
    i {
      margin: 0;
    }
    img {
      width: 30px;
      height: 30px;
    }
    .content-button {
      right: -32px;
    }
    
  }
`;

export const Agrupable = styled.span`
  display: inline-block;
  width: 100%;
  text-transform: lowercase;

  ${props => (props.appearance === APPEARANCE.SIMPLE_ANSWER) && css`
    width: 100%;
    padding: 5px;
    > div {
      margin: 0;
    }
    textarea {
      max-height: 30px;
      padding-top: 5px;
      margin-left: 15px;
    }
  `}
  
`;
