import React from "react";
import { COLOR, SIZE, ACTION, APPEARANCE } from "../../../constants";
import Line from "../../basicComponents/Line";
import Text from "../../basicComponents/Text";
import Divisor from "../../basicComponents/Divisor";
import CommentBox from "../../Inputs/CommentBox";
import PostItem from "../PostItem";
import Block from "../../basicComponents/Block";
import InfiniteScroll from "../../basicComponents/InfiniteScroll";
import { Info, Container, TimelineContainer } from "./styles";

export default ({
  communications,
  isAdministrator,
  group,
  member,
  createPost,
  deleteCommunication,
  updatePost,
  createComment,
  hiddenCreatePost,
  interationsModalIsOpen,
  setInterationsModalIsOpen,
  loadCommunicationReactions,
  loadReactionPeople,
  loadMoreCommunications,
  reactionsTypes,
  updateCommunicationReactions,
  hasMoreCommunications,
  showTimer,
  isContentPage,
  isLoading
}) => {
  return (
    <TimelineContainer>
      {hiddenCreatePost !== true && (
        <Block color={COLOR.BLOCK_LIGHT} appearance={APPEARANCE.NEW_POST}>
          <Info key={group.code + group.name}>
            <Text
              size={SIZE.MEDIUM}
              text={`${isContentPage
                ? ACTION.NEW_CONTENT_POST
                : ACTION.NEW_GROUP_CONVERSATION
                } ${group.name}`}
            />
          </Info>
          <Line>
            <CommentBox
              image={member ? member.img : ""}
              placeholder={ACTION.WRITE_POST}
              size={SIZE.SMALL}
              sendAction={createPost}
              isLoading={isLoading}
            />
          </Line>
        </Block>
      )}

      <Container id={"timeline"}>
        <InfiniteScroll
          loadAction={loadMoreCommunications}
          dataLength={communications.length}
          hasMoreCommunications={hasMoreCommunications}
        >
          {communications.map(item => (
            <PostItem
              id={item.id}
              code={item.code}
              hasLike={item.hasReaction}
              isOwner={item.isOwner}
              likes={item.reactions}
              reactions={item.reactions}
              postGroup={item.destination}
              postDate={item.content.date}
              postMember={item.profile}
              postContent={item.content.value}
              postArchives={item.files}
              postComments={item.children}
              totalLikes={item.reactions}
              totalDislikes={item.totalDislikes}
              postDescription={item.vinculatedGroup}
              isAdministrator={isAdministrator}
              member={member}
              members={item.likePeople}
              updatePost={updatePost}
              createComment={createComment}
              deleteCommunication={deleteCommunication}
              hiddenCreatePost={hiddenCreatePost}
              reactionsTypes={reactionsTypes}
              updateCommunicationReactions={updateCommunicationReactions}
              interationsModalIsOpen={interationsModalIsOpen}
              setInterationsModalIsOpen={setInterationsModalIsOpen}
              loadCommunicationReactions={loadCommunicationReactions}
              loadReactionPeople={loadReactionPeople}
              hasReaction={item.hasReaction}
              showTimer={showTimer}
            />
          ))}
        </InfiniteScroll>
      </Container>
    </TimelineContainer>
  );
};
