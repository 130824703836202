import React from "react";
import { useForm } from "react-hook-form";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Array} options //list of displayed options
 * @param {String} label //text displayed above the selection component
 * @param {String} placeholder //component markup text
 * @param {String} fontType //label font type
 * @param {String} color //label font color
 * @param {String} name //item attribute for selection
 * @param {Boolean} isMulti //tells whether the component allows the selection of multiple lines
 * @param {Function} onChange //action when selecting item
**/

export default ({
  options = DEFAULT_VALUES.LIST,
  label = DEFAULT_VALUES.STRING,
  placeholder = DEFAULT_VALUES.STRING,
  fontType = DEFAULT_VALUES.STRING,
  color = DEFAULT_VALUES.STRING,
  name = DEFAULT_VALUES.STRING,
  isMulti = DEFAULT_VALUES.BOOLEAN,
  onChange = DEFAULT_VALUES.FUNCTION
}) => {

  const defaultValues = { Select: "" };
  const { control } = useForm({ defaultValues });

  return (
    <>
      <Screen
        options={options}
        placeholder={placeholder}
        label={label}
        control={control}
        name={name}
        isMulti={isMulti}
        onChange={onChange}
        fontType={fontType}
        color={color}
      />
    </>
  );
};

