import styled, { css } from "styled-components";
import { ORIENTATION, FORMAT, POSITION } from "../../constants";

export const PaddingDiv = styled.div`
  padding: 1rem 0;
  display: inline-block;
  i { margin-right: 5px; }
  
  ${props => (props.orientation === ORIENTATION.DEFAULT) && css`
    padding: 1rem 0;
    display: inline-block;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.orientation === ORIENTATION.FULL_WIDTH) && css`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 0 2rem;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.format === FORMAT.BUTTON_1x3) && css`
    padding: 1rem 0;
    display: inline-block;
    i {
      margin-right: 5px;
    }
    > button:first-of-type {
      flex: 1
    }
    > button:last-of-type {
      flex: 3
    }
  `}

  ${props => (props.position === POSITION.LEFT) && css`
    float: left;
    /* padding: 1rem 0; */
    padding: 0;
    display: inline-block;
    i {
      margin-right: 5px;
    }
  `}
  
`;
