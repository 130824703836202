import React from "react";
import { ICON, COLOR, SIZE, ORIENTATION, PROGRESS_INFORMATION, } from "../constants";

import { Container } from './styles';

import Header from "../../LitAtomicComponents/Header";
import PageContent from "./components/PageContent";
import Actions from "../../LitAtomicComponents/Actions";
import Menu from "../../LitAtomicComponents/Menu";
import LoadingBar from "../../LitAtomicComponents/basicComponents/LoadingBar";

import MemberInfo from "../../LitAtomicComponents/Info/DataInfo";
import IconInfo from "../../LitAtomicComponents/Info/IconInfo";
import Column3x3 from "../../LitAtomicComponents/defaultAreas/Column_3x3";


import {} from "./styles";

export default ({
  progress,
  logo,
  imageDefault,
  group,
  groups,
  solicitations,
  recommended,
  invitations,
  member,
  postItem,
  menuNavigation,

  openSearch,
  setOpenSearch,
  visibilityOptions,
  control,
  changeImage,
  areasOfInterest,
  handleImageChange,
  saveGroup,
  createGroupModalIsOpen,
  createGroupModalHandleOpen,
  manageInvitesModalIsOpen,
  manageInvitesModalHandleOpen,
  inviteJoinSolicitation,
  cancelJoinSolicitation,
  declineInvitation,
  loadMoreCommunications,
  hiddenCreatePost,
  loadMoreGroups,
  loadMoreInvites,
  isInternal,
  hiddenExternalComponents,
  hasMoreCommunications,
  hasMoreGroups,
  hasMoreInvites,
  loadMoreSolicitations,
  hasMoreSolicitations,
  manageSolicitationsModalIsOpen,
  manageSolicitationsModalHandleOpen,
}) => {

  function checkProfileType() {
    if(isInternal) {
      return <Header
      logoPage={logo}
      size={SIZE.SMALL}
      openSearch={openSearch}
      setOpenSearch={setOpenSearch}
      topRight={[
        <Actions
          itemsToRender={[
            {
              type: "icon",
              icon: ICON.HEART,
              action: null,
              size: SIZE.MEDIUM
            },
            {
              type: "icon",
              icon: ICON.BELL,
              action: null,
              size: SIZE.MEDIUM,
              badgeValue: member.totalNotifications
            },
            {
              type: "icon",
              icon: ICON.SEARCH,
              action: () => {
                setOpenSearch(true);
              },
              size: SIZE.MEDIUM
            }
          ]}
        />
      ]}
      topLeft={[
        <Actions
          itemsToRender={[
            {
              type: "icon",
              icon: ICON.MENU_BARS,
              action: null,
              size: SIZE.MEDIUM
            }
          ]}
        />
      ]}
      topMenu={[
        <Menu
          orientation={ORIENTATION.HORIZONTAL}
          color={COLOR.LIGHT}
          options={menuNavigation}
          size={SIZE.SMALL}
        />
      ]}
    />
    }else {
      return <Header
      logoPage={logo}
      size={SIZE.LARGE}
      openSearch={openSearch}
      setOpenSearch={setOpenSearch}
      topRight={[
        <Actions
          itemsToRender={[
            {
              type: "icon",
              icon: ICON.HEART,
              action: null,
              size: SIZE.MEDIUM
            },
            {
              type: "icon",
              icon: ICON.BELL,
              action: null,
              size: SIZE.MEDIUM,
              badgeValue: member.totalNotifications
            },
            {
              type: "icon",
              icon: ICON.SEARCH,
              action: () => {
                setOpenSearch(true);
              },
              size: SIZE.MEDIUM
            }
          ]}
        />
      ]}
      topLeft={[
        <Actions
          itemsToRender={[
            {
              type: "icon",
              icon: ICON.MENU_BARS,
              action: null,
              size: SIZE.MEDIUM
            }
          ]}
        />
      ]}
      bottomLeft={
        <MemberInfo
          id={member.code}
          name={member.name}
          img={member.img}
          role={member.role}
          size={SIZE.MEDIUM}
          orientation={ORIENTATION.HORIZONTAL}
          color={COLOR.LIGHT}
        />
      }
      bottomRight={
        <Column3x3
          orientation={ORIENTATION.AJUSTABLE}
          left={
            <IconInfo
              icon={ICON.TROPHY}
              title={PROGRESS_INFORMATION.COURSES_COMPLETED}
              subtitle={member.coursesCompleted}
              color={COLOR.LIGHT}
            />
          }
          middle={
            <IconInfo
              icon={ICON.PLAY}
              title={PROGRESS_INFORMATION.COURSES_IN_PROGRESS}
              subtitle={member.coursesInProgress}
              color={COLOR.LIGHT}
            />
          }
          right={
            <IconInfo
              icon={ICON.USERS}
              title={PROGRESS_INFORMATION.GROUPS}
              subtitle={member.groups}
              color={COLOR.LIGHT}
            />
          }
        />
      }
    />
    }
  }

  return (
    <Container id='profileContainer'>
      <LoadingBar loadingProgress={progress}/>
      
      { checkProfileType() }

      <PageContent
        isInternal={isInternal}
        imageDefault={imageDefault}
        group={group}
        groups={groups}
        solicitations={solicitations}
        recommended={recommended}
        invitations={invitations}
        member={member}
        postItem={postItem}
        saveGroup={saveGroup}
        visibilityOptions={visibilityOptions}
        control={control}
        changeImage={changeImage}
        areasOfInterest={areasOfInterest}
        handleImageChange={handleImageChange}
        createGroupModalIsOpen={createGroupModalIsOpen}
        createGroupModalHandleOpen={createGroupModalHandleOpen}
        manageInvitesModalIsOpen={manageInvitesModalIsOpen}
        manageInvitesModalHandleOpen={manageInvitesModalHandleOpen}
        inviteJoinSolicitation={inviteJoinSolicitation}
        cancelJoinSolicitation={cancelJoinSolicitation}
        declineInvitation={declineInvitation}
        hiddenCreatePost={hiddenCreatePost}
        hiddenExternalComponents={hiddenExternalComponents}
        loadMoreCommunications={loadMoreCommunications}
        loadMoreGroups={loadMoreGroups}
        loadMoreInvites={loadMoreInvites}
        hasMoreCommunications={hasMoreCommunications}
        hasMoreGroups={hasMoreGroups}
        hasMoreInvites={hasMoreInvites}
        loadMoreSolicitations={loadMoreSolicitations}
        hasMoreSolicitations={hasMoreSolicitations}
        manageSolicitationsModalIsOpen={manageSolicitationsModalIsOpen}
        manageSolicitationsModalHandleOpen={manageSolicitationsModalHandleOpen}
      />
    </Container>
  );
};
