import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserActions } from "../../store/ducks/user";
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";
import RadarChart from "./RadarChart";
import CircularProgressbarchart from "./CircularProgressbarchart";
import WatsonBadgeFooter from "./WatsonBadgeFooter";

const IMG_PAUL = require("../../images/paul2.png");
const GIF_PAUL = require("../../images/animacao_paul_pensando.gif");

class ResultadoWatson extends Component {
  static contextTypes = {
    router: PropTypes.shape()
  };

  componentWillMount() {
    const { personalityInsight, getPersonalityInsightRequest } = this.props;

    if (Object.keys(personalityInsight.personality).length === 0) {
      const idProfile = localStorage.getItem("idProfile");
      getPersonalityInsightRequest(idProfile);
    }
  }

  _generateText = () => {
    const { personalityInsight } = this.props;
    let o;
    let c;
    let e;
    let a;
    let n;
    if (Object.keys(personalityInsight.personality).length) {
      o = Math.floor(personalityInsight.personality[0].percentile * 100);
      c = Math.floor(personalityInsight.personality[1].percentile * 100);
      e = Math.floor(personalityInsight.personality[2].percentile * 100);
      a = Math.floor(personalityInsight.personality[3].percentile * 100);
      n = Math.floor(personalityInsight.personality[4].percentile * 100);
    }
    // let o = 60
    // let c = 21
    // let e = 0
    // let a = 13
    // let n = 80
    if (o < 70 && c < 70 && e < 70 && a < 70 && n < 90) {
      const values = [o, c, e, a, n];
      let maiorNumeroA = 0;
      let maiorNumeroB = 0;
      let positionA = -1;
      let positionB = -1;
      values.forEach(value => {
        if (value > maiorNumeroA) {
          maiorNumeroB = maiorNumeroA;
          maiorNumeroA = value;
          positionB = positionA;
          positionA = parseInt(value, 10);
        } else if (value > maiorNumeroB) {
          maiorNumeroB = value;
          positionB = parseInt(value, 10);
        }
      });
      switch (positionA) {
        case 0:
          o = 99;
          break;
        case 1:
          c = 99;
          break;
        case 2:
          e = 99;
          break;
        case 3:
          a = 99;
          break;
        case 4:
          n = 99;
          break;
        default:
          break;
      }

      switch (positionB) {
        case 0:
          o = 99;
          break;
        case 1:
          c = 99;
          break;
        case 2:
          e = 99;
          break;
        case 3:
          a = 99;
          break;
        case 4:
          n = 99;
          break;
        default:
          break;
      }
    }

    return (
      <div>
        <h5 style={{ color: "#0cb2e3" }}>Insights de como você aprende</h5>
        <div className="box-insights">
          <p>
            Como seres únicos, cada um de nós aprende de uma forma diferente e
            existem formas mais e menos eficientes de aprendizado para o seu
            perfil de personalidade? A essas formas de aprendizagem damos o nome
            de{" "}
            <strong style={{ color: "#000" }}>
              Estratégias de Aprendizagem
            </strong>
            . Uma vasta gama de estudos científicos internacionais indicam que
            pessoas com os seus traços de personalidade tendem a:
          </p>
        </div>
        {o > 70 && (
          <div>
            <ul className="list-result-PI">
              <li>
                <p>
                  Ter a capacidade de conectar e aplicar as novas ideias e novos
                  conhecimentos aprendidos aos conhecimentos já existentes e às
                  suas experiências pessoais.
                </p>
                <p>
                  No LIT com os estudos de caso você tem a possibilidade de
                  aprender e simular para assim aplicar em suas experiências
                  profissionais.
                </p>
              </li>
              <li>
                <p>
                  Preferir pela simulação prática e discussões em pequenos
                  grupos.
                </p>
                <p>
                  Com o LIT você pode explorar ao máximo os grupos de práticas
                  disponíveis. Neles você poderá criar ou participar de grupos
                  de temas específicos e discussões em grupos
                  multidisciplinares, que compartilham dos mesmos interesses,
                  mas com experiências das mais diversas.
                </p>
              </li>
              <li>
                <p>
                  Ter o desejo constante de incrementar e aperfeiçoar as suas
                  competências.
                </p>
                <p>
                  No LIT o seu aprendizado é personalizado e a plataforma lhe
                  auxilia a identificar os seus conhecimentos atuais e lacunas
                  nos temas escolhidos, para que você se dedique aos tópicos que
                  realmente precisam ser trabalhados, evitando repetições
                  desnecessárias e otimizando o seu desenvolvimento e seu tempo.
                </p>
              </li>
              <li>
                <p>
                  Buscar novas experiências educacionais. O novo lhe atrai no
                  processo de aprendizagem.
                </p>
                <p>
                  O LIT faz amplo uso da Inteligência Artificial como ferramenta
                  de aprendizado. Conheça o Paul e surpreenda-se com essa nova e
                  disruptiva forma de apreender.
                </p>
              </li>
            </ul>
          </div>
        )}
        {c > 70 && (
          <div>
            <ul className="list-result-PI">
              <li>
                <p>
                  Apresentar uma relação profunda com o aprendizado e buscar
                  compreender o real significado do que está sendo aprendido. Em
                  outras palavras, você não se identifica com estudos
                  superficiais.
                </p>
                <p>
                  O LIT apresenta disciplinas com diferentes níveis de
                  complexidade, que estão organizadas em formato de trilhas de
                  conhecimento, para que você possa desenvolver o seu
                  aprendizado de forma profunda e, ao mesmo tempo, gradual e
                  contínua (lifelong-learning!). Além disso, no LIT você terá
                  inúmeras formas de aprender, como as sessões de livros
                  indicados em cada disciplina e disponíveis na plataforma,
                  formam uma fonte inesgotável de aprofundamento do aprendizado,
                  os grupos de estudos e o Paul que sempre estará pronto para te
                  auxiliar.
                </p>
              </li>
              <li>
                <p>
                  Gostar do exercício de pensar, ser persistente nos estudos e
                  orientar-se para resultados.
                </p>
                <p>
                  No LIT você tem a sua disposição feedbacks estruturados
                  constantes de seu desempenho, facilitando a sua busca pela
                  excelência nos diferentes temas.
                </p>
              </li>
              <li>
                <p>
                  Ser sintético-analíticos, que se expressa no processamento e
                  organização de informações e conhecimentos, formação de
                  categorias e hierarquização das categorias.
                </p>
              </li>
              <li>
                <p>
                  Ser estudantes atentos ao cumprimento das atividades
                  propostas.
                </p>
              </li>
              <li>
                <p>
                  Conseguir um bom desempenho acadêmico, buscar com frequência
                  as melhores notas, ser hard-working, organizados e com uma
                  natureza ambiciosa.
                </p>
              </li>
              <li>
                <p>
                  Se auto-avaliarem com bastante frequência para validarem o seu
                  processo de aprendizagem.
                </p>
                <p>
                  O LIT dispõe de sessões de Vamos Praticar? em todas as
                  disciplinas. Procure realizar está atividade com frequência
                  para potencializar o seu desenvolvimento, participe de grupos
                  de discussões e interaja neles.
                </p>
              </li>
            </ul>
          </div>
        )}
        {e > 70 && (
          <div>
            <ul className="list-result-PI">
              <li>
                <p>
                  Ser focados no aprendizado como fator chave para o
                  desenvolvimento da carreira.
                </p>
                <p>
                  No LIT você terá todo o suporte para o seu aprendizado, onde
                  poderá trabalhar com conteúdos que caibam nos momentos que
                  você tem, o LIT te oferece material para estudo de acordo com
                  o tempo disponível que você tem para aprender, uma plataforma
                  que está com você nos micro momentos e te desenvolvendo
                  constantemente.
                </p>
              </li>
              <li>
                <p>
                  Gostar de estar em contato com colegas para discussões e
                  influência.
                </p>
                <p>
                  Explore ao máximo as redes sociais do LIT para exponenciar o
                  seu desenvolvimento. Nelas você poderá participar de fóruns de
                  discussão especializados, discutir e comentar os principais
                  momentos de seu aprendizado, trabalhando o networking de forma
                  natural.
                </p>
              </li>
              <li>
                <p>
                  Concentrar-se em experiências concretas (sentimentos) e ativar
                  a experimentação (ação), usando os resultados de testes como
                  base para um novo aprendizado.
                </p>
              </li>
              <li>
                <p>Ter altas habilidades cognitivas comportamentais.</p>
              </li>
              <li>
                <p>
                  Ter pouco foco na memorização de fatos e detalhes
                  (informações, datas, nomes, fórmulas).
                </p>
                <p>
                  Não se preocupa, no LIT temos o Paul que poderá te ajudar com
                  as dúvidas sempre que elas surgirem.
                </p>
              </li>
            </ul>
          </div>
        )}
        {a > 70 && (
          <div>
            <ul className="list-result-PI">
              <li>
                <p>
                  Ter o gerenciamento do tempo para estudo e a concentração como
                  um desafio.
                </p>
                <p>
                  O LIT foi pensado para que você possa desenvolver a sua
                  jornada em micro momentos. Para tal, as disciplinas estão
                  formadas por pequenas unidades de conhecimento, as quais
                  contém diferentes objetos de estudo e aprendizagem, com
                  diferentes durações e formatos, para que você possa estudar a
                  qualquer momento, em qualquer lugar, otimizando o seu tempo.
                </p>
              </li>
              <li>
                <p>Ter pouco foco na competição acadêmica.</p>
                <p>
                  No LIT você estuda em seu tempo, ele te mostra a melhor forma
                  de aprender com o Personalizar Aprendizado, sem foco na
                  competição acadêmica,
                </p>
              </li>
            </ul>
          </div>
        )}
        {n > 90 && (
          <div>
            <ul className="list-result-PI">
              <li>
                <p>
                  Ser mais suscetíveis a apresentarem instabilidade decorrentes
                  de elevados níveis de stress ou pressão, prejudicando o
                  processo de aprendizagem.
                </p>
                <p>
                  Procure estudar nos momentos de maior tranquilidade e
                  estabilidade emocional para obter os melhores resultados. No
                  LIT os professores-tutores estão disponíveis para te ajudar
                  com suas dúvidas, eles podem lhe auxiliar muito a organizar o
                  seu processo aprendizado.
                </p>
              </li>
            </ul>
          </div>
        )}
        <p>
          Procure ter essas estratégias em mente ao longo de toda a sua jornada
          no LIT. Bons estudos!
        </p>
        <p>Paul</p>
      </div>
    );
  };

  _generateTitleCircular = (value, index) => {
    let title = "";
    let maisAlto = "";
    let maisBaixo = "";
    let aux = "";
    let aux2 = "";
    switch (value.name) {
      case "Abertura":
        title = `${value.name.toUpperCase()}`;
        maisAlto =
          "pessoa intelectualmente curiosa, emocionalmente consciente, sensível à beleza e disposta a experimentar o novo.";
        maisBaixo =
          "prefere o planejamento, a sinceridade e o óbvio, acima do complexo, ambíguo e sutil.";
        break;
      case "Extroversão":
        title = `${value.name.toUpperCase()} / INTROVERSÃO`;
        maisAlto =
          "pessoa mais enérgica, impõe-se às questões do mundo externo. Gosta de visibilidade dentro de grupos, de falar e de se impor.";
        maisBaixo =
          "precisa de menos estímulo de grupos e círculos sociais, é mais independente, não significa que seja obrigatoriamente tímida ou antissocial.";
        break;
      case "Faixa emocional":
        title = `${value.name.toUpperCase()} (NEUROCITISMO)`;
        maisAlto =
          "pessoa mais disposta a ter emoções negativas ou ficar chateada, isto pode significar que está passando por um momento difícil.";
        maisBaixo = `pessoa mais calma e menos disposta a ficar chateada, não significa que seja uma pessoa obrigatoriamente positiva ou feliz.`;
        aux =
          "(Neurocitismo pode ter um significado clínico específico, o serviço apresenta tais introspecções sob o título mais geralmente aplicável em faixa emocional.)";
        aux2 = "Este estudo não diagnostica doenças mentais.";
        break;
      case "Amabilidade":
        title = `${value.name.toUpperCase()}`;
        maisAlto =
          "dá mais valor ao bom relacionamento com outras pessoas. Tem uma visão mais otimista da natureza humana.";
        maisBaixo =
          "dá mais valor aos seus interesses. É uma pessoa mais cética quanto ao interesse dos outros.";
        break;
      case "Escrupulosidade":
        title = `CONSCIÊNCIA`;
        maisAlto =
          "pessoa mais autodisciplinada e zeloza, almeja conquistas diante de medidas ou expectativas externas.";
        maisBaixo =
          "pessoa mais pré-disposta a agir espontaneamente do que agir de forma planejada.";
        break;
      case "Openness":
        title = `${value.name.toUpperCase()}`;
        maisAlto =
          "pessoa intelectualmente curiosa, emocionalmente consciente, sensível à beleza e disposta a experimentar o novo.";
        maisBaixo =
          "prefere o planejamento, a sinceridade e o óbvio, acima do complexo, ambíguo e sutil.";
        break;
      case "Extraversion":
        title = `${value.name.toUpperCase()} / INTROVERSÃO`;
        maisAlto =
          "pessoa mais enérgica, impõe-se às questões do mundo externo. Gosta de visibilidade dentro de grupos, de falar e de se impor.";
        maisBaixo =
          "precisa de menos estímulo de grupos e círculos sociais, é mais independente, não significa que seja obrigatoriamente tímida ou antissocial.";
        break;
      case "Emotional range":
        title = `${value.name.toUpperCase()} (NEUROCITISMO)`;
        maisAlto =
          "pessoa mais disposta a ter emoções negativas ou ficar chateada, isto pode significar que está passando por um momento difícil.";
        maisBaixo = `pessoa mais calma e menos disposta a ficar chateada, não significa que seja uma pessoa obrigatoriamente positiva ou feliz.`;
        aux =
          "(Neurocitismo pode ter um significado clínico específico, o serviço apresenta tais introspecções sob o título mais geralmente aplicável em faixa emocional.)";
        aux2 = "Este estudo não diagnostica doenças mentais.";
        break;
      case "Conscientiousness":
        title = `${value.name.toUpperCase()}`;
        maisAlto =
          "dá mais valor ao bom relacionamento com outras pessoas. Tem uma visão mais otimista da natureza humana.";
        maisBaixo =
          "dá mais valor aos seus interesses. É uma pessoa mais cética quanto ao interesse dos outros.";
        break;
      case "Agreeableness":
        title = `CONSCIÊNCIA`;
        maisAlto =
          "pessoa mais autodisciplinada e zeloza, almeja conquistas diante de medidas ou expectativas externas.";
        maisBaixo =
          "pessoa mais pré-disposta a agir espontaneamente do que agir de forma planejada.";
        break;
      default:
        break;
    }

    return (
      <div className="row">
        <div className="col-2 align-self-center text-center px-0">
          <b>{title}</b>
          <CircularProgressbarchart
            percentage={Math.ceil(value.percentile * 100)}
            key={index}
            index={index}
            style={{
              width: "75%"
            }}
          />
        </div>
        <div className="col-10" style={{ alignSelf: "center" }}>
          <div className="row">
            <span
              className="span-saiba-mais-pi"
              style={{ marginBottom: "0.6em" }}
            >
              <b>Quanto mais alto:</b> {maisAlto}
            </span>
          </div>
          <div className="row">
            <span className="span-saiba-mais-pi">
              <b>Quanto mais baixo:</b> {maisBaixo}
            </span>
          </div>
          {aux && (
            <div className="row">
              <span className="span-saiba-mais-pi">
                {aux} <b>{aux2}</b>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  toCarreira = () => {
    const { router } = this.context;
    localStorage.setItem("completedPersonality", false);
    router.history.push("/interesses");
  };

  render() {
    const { personalityInsight, personalityInsightLoading } = this.props;
    return (
      <div id="interesses">
        <NavBarSuperior />
        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row">
            <div className="col-12">
              <div className="container" id="objetivos-interesses">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="container" id="card-interesses">
                      {!personalityInsightLoading ? (
                        <div>
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="container mZero"
                                id="objetivos-personalizar"
                              >
                                <div className="row justify-content-md-center">
                                  <div className="col-10 text-center">
                                    <img
                                      style={{ height: 130, width: 130 }}
                                      alt="Logo Paul"
                                      className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                                      src={IMG_PAUL}
                                    />
                                    <h4 className="h-interesses">
                                      Análise concluída
                                    </h4>
                                  </div>
                                </div>

                                <div className="row justify-content-center">
                                  <div className="col-11">
                                    <div className="row text-center">
                                      <div
                                        className="col-12"
                                        style={{ paddingRight: 0 }}
                                      >
                                        <div className="row justify-content-center">
                                          <div className="col-12">
                                            <h3 className="marginb-10">
                                              Personalidade (Big Five)
                                            </h3>
                                          </div>
                                          <div className="col-7">
                                            <RadarChart />
                                          </div>
                                        </div>
                                        <div className="row text-center">
                                          <h6 className="span-saiba-mais-pi text-justify margint-10">
                                            BIG FIVE é um dos modelos de
                                            assessment de traços de
                                            personalidade mais estudado no
                                            mundo. Ele foi criado e desenvolvido
                                            por psicólogos de grande relevância
                                            (Costa e McCrae, 1992 e Norman,
                                            1963). É o modelo mais amplamente
                                            utilizado para descrever como uma
                                            pessoa geralmente se envolve com o
                                            mundo, em seus diferentes aspectos.
                                            As 5 dimensões do modelo são:
                                            Abertura, Extroversão/Introversão,
                                            Faixa Emocional, Amabilidade e
                                            Consciência.
                                          </h6>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div
                                        className="col-12 titlePercent"
                                        style={{ margin: "10px 0" }}
                                      >
                                        {personalityInsight.personality.map(
                                          (value, index) => {
                                            return (
                                              <React.Fragment
                                                key={value.idPersonalityPi}
                                              >
                                                {this._generateTitleCircular(
                                                  value,
                                                  index
                                                )}
                                                <hr className="hr-md-margin" />
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {personalityInsight.warnings !== null ? (
                                  <div className="row justify-content-center">
                                    <div className="col-10">
                                      <div>
                                        {personalityInsight.warnings.map(
                                          warning => {
                                            return (
                                              <p
                                                style={{
                                                  color: "#d44950"
                                                }}
                                              >
                                                Aviso: {warning.message}
                                              </p>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="row justify-content-center">
                                  <div className="col-10">
                                    {this._generateText()}
                                  </div>
                                </div>

                                <div
                                  className="row justify-content-center mt-3"
                                  id="selecionar-personalizar"
                                >
                                  <div className="mx-2">
                                    <Link
                                      to="/home"
                                      className="btn btn-null btn-blue btn-FontWeight6"
                                    >
                                      Ir para os estudos
                                    </Link>
                                  </div>
                                  <div className="mx-2">
                                    <button
                                      type="button"
                                      onClick={this.toCarreira}
                                      className="btn btn-lightblue btn-FontWeight6"
                                    >
                                      Refazer a análise
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="row justify-content-md-center analisando-container__no-watson-badge">
                            <div className="col-10 objetivos-personalizar--desc-usuario text-center">
                              <img
                                style={{ height: "400", width: "500" }}
                                alt="Curso"
                                className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                                src={GIF_PAUL}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <WatsonBadgeFooter />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    personalityInsight: state.user.personalityInsight.data,
    personalityInsightLoading: state.user.personalityInsight.loading
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions.PIActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResultadoWatson);
