import React, { useRef, useContext, useEffect } from "react";

import MeetingActions from "../MeetingActions";

import * as Styles from "./styles";

import { MeetingContext } from "../../MeetingContext";

export default function MeetingControls() {
  const {
    meeting,
    muteLocalCam,
    meetingLoading,
    isMeetingFullscreen,
    localStream
  } = useContext(MeetingContext);
  const localVideo = useRef(null);

  useEffect(() => {
    if (meeting && localVideo && localVideo.current) {
      localVideo.current.srcObject = localStream;
    }
  }, [meeting, localStream]);

  return (
    <Styles.UserBox className={isMeetingFullscreen ? "fullscreen" : ""}>
      <Styles.LocalVideo>
        <video
          muted
          autoPlay
          playsInline
          ref={localVideo}
          style={{
            display: muteLocalCam ? "none" : "inherit",
            width: muteLocalCam ? "0px" : "inherit"
          }}
        />
        <div
          style={{
            display: !muteLocalCam ? "none" : "flex",
            width: !muteLocalCam ? "0px" : "250px",
            backgroundColor: "rgb(86, 86, 86)",
            color: "white",
            justifyContent: "center",
            borderRadius: isMeetingFullscreen ? "8px" : "0"
          }}
        >
          <span style={{ alignSelf: "center" }}>Câmera desligada</span>
        </div>
      </Styles.LocalVideo>
      {!meetingLoading && <MeetingActions />}
    </Styles.UserBox>
  );
}
