import produce from "immer";

import { Types } from "./types";
/**
 * Reducer
 */

const INITIAL_STATE = {
  loading: false,
  data: [],
  selected: {
    idAttachment: null,
    idChildren: null,
    idContent: null,
    idDiscipline: null,
    idCourse: null,
    type: "",
    typeContent: "about",
    url: ""
  },
  navigationContent: {
    prev: null,
    next: null
  },
  progress: {
    countObjects: null,
    countFinishedObjects: null,
    loading: false
  },
  idDisciplineProgress: null,
  playerPosition: "",
  autoplayNextVideos: true,
  lastSyncedGradeDiscipline: "",
  error: null,
  called: false,
  selectedDiscipline: "",
  warningDisplayed: false,
  forceSync: false
};

export default function course(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_COURSE:
      return { ...INITIAL_STATE, autoplayNextVideos: state.autoplayNextVideos };
    case Types.GET_COURSE_REQUEST:
      return { ...state, loading: true };
    case Types.GET_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        called: true,
        data: action.payload.data,
        error: null,
        warningDisplayed: false
      };
    case Types.GET_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: action.payload.error.status,
          error: action.payload.error.data.message
        }
      };
    case Types.SET_DISCIPLINE_SELECTED:
      return {
        ...state,
        selectedDiscipline: action.payload.data
      };

    case Types.DEFINE_DISCIPLINE_AS_SYNCHRONIZED:
      return produce(state, draft => {
        const idDiscipline = action.payload;

        const disciplineIndex = draft.data.disciplines.findIndex(
          item => item.idDiscipline === idDiscipline
        );
        // eslint-disable-next-line no-param-reassign
        draft.data.disciplines[disciplineIndex].synchronized = true;
      });

    case Types.SET_LAST_SYNCED_GRADE_DISCIPLINE:
      return {
        ...state,
        lastSyncedGradeDiscipline: action.payload
      };
    case Types.SET_COURSE_SELECTED:
      return {
        ...state,
        selected: {
          idAttachment: action.payload.idAttachment,
          idChildren: action.payload.idChildren,
          description: action.payload.description,
          idContent: action.payload.idContent,
          idCourse: action.payload.idCourse,
          idDiscipline: action.payload.idDiscipline || action.payload.idCourse,
          attachmentName: action.payload.name,
          typeContent: action.payload.typeContent,
          type: action.payload.type,
          url: action.payload.url,
          warningDisplayed: false,
          posicao: action.payload.currentPosition
        }
      };
    case Types.SET_NAVIGATION_CONTENT:
      return {
        ...state,
        navigationContent: action.payload.data
      };
    case Types.SET_PLAYER_POSITION:
      return {
        ...state,
        playerPosition: action.payload.data
      };
    case Types.SET_VALUE_FORCE_SYNC:
      return {
        ...state,
        forceSync: action.payload
      };
    case Types.GET_COURSE_PROGRESS_REQUEST:
      return {
        ...state,
        progress: {
          ...state.progress,
          loading: true
        }
      };
    case Types.GET_COURSE_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: {
          loading: false,
          countObjects: action.payload.countObjects,
          countFinishedObjects: action.payload.countFinishedObjects
        }
      };

    case Types.SET_AUTOPLAY_VIDEOS:
      return {
        ...state,
        autoplayNextVideos: action.payload.autoplay
      };

    case Types.SET_DISCIPLINE_PROGRESS:
      return {
        ...state,
        idDisciplineProgress: action.payload.idDiscipline
      };
    case Types.SET_DISCIPLINE_SUCCESS:
      return produce(state, draft => {
        const discipline = action.payload.discipline
        const disciplineIndex = draft.data.disciplines.findIndex(
          ({ idDiscipline }) => idDiscipline === discipline.idDiscipline
        );

        draft.data.disciplines[disciplineIndex].countFinishedObjects = discipline.countFinishedObjects;
      })
    case Types.SET_WANING_DISPLAYED:
      return {
        ...state,
        warningDisplayed: action.payload.warningDisplayed
      };

    default:
      return state;
  }
}
