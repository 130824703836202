import styled from "styled-components";
import { } from "../../constants";

export const Agrupable = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  background: linear-gradient(60deg, var(--topsearch-gradient-primary) 0%, var(--topsearch-gradient-secondary) 100%);
  background-size: 100% 60px;
  background-position: top left;
  background-blend-mode: inherit;
  background-repeat-y: no-repeat;
  > div {
    flex: 3;
    height: 40px;
    i {
      top: 12px;
    }
  } 
  > span {
    flex: 1;
  }
`;
