import React from "react";

import {
  PAGE_INFORMATION,
  COLOR,
  SIZE,
  TRANSFORM,
  FONT_TYPE,
  POSITION
} from "../../../constants";
import Text from "../../../../LitAtomicComponents/basicComponents/Text";
import Block from "../../../../LitAtomicComponents/basicComponents/Block";
import Tab from "../../../../LitAtomicComponents/basicComponents/Tab";
import NavTab from "../../../../LitAtomicComponents/basicComponents/NavTab";
import Timeline from "../../../../LitAtomicComponents/Contents/Timeline";
import { Content } from "./styles";

export default ({
  member,
  members,
  group,
  postItem,
  savePost,
  updatePost,
  createComment,
  deletePost,
  interationsModalIsOpen,
  setInterationsModalIsOpen
}) => {
  return (
    <>
      <Block color={COLOR.BLOCK_LIGHT}>
        <Tab id={PAGE_INFORMATION.COMMENTS}>
          <NavTab
            header={[
              {
                id: PAGE_INFORMATION.COMMENTS,
                component: (
                  <Text
                    text={PAGE_INFORMATION.COMMENTS}
                    transform={TRANSFORM.UPPERCASE}
                    size={SIZE.SMALL}
                  />
                )
              },
              {
                id: PAGE_INFORMATION.MORE,
                component: (
                  <Text
                    text={PAGE_INFORMATION.MORE}
                    transform={TRANSFORM.UPPERCASE}
                    size={SIZE.SMALL}
                  />
                )
              }
            ]}
            content={[
              {
                id: PAGE_INFORMATION.COMMENTS,
                component: (
                  <Content>
                    <Timeline
                      group={group}
                      postItem={postItem}
                      member={member}
                      members={members}
                      savePost={savePost}
                      updatePost={updatePost}
                      createComment={createComment}
                      deletePost={deletePost}
                      interationsModalIsOpen={interationsModalIsOpen}
                      setInterationsModalIsOpen={setInterationsModalIsOpen}
                    />
                  </Content>
                )
              },
              {
                id: PAGE_INFORMATION.MORE,
                component: (
                  <Content>
                    <Text
                      text={PAGE_INFORMATION.ABOUT}
                      size={SIZE.MEDIUM}
                      fontType={FONT_TYPE.BOLD}
                      transform={TRANSFORM.UPPERCASE}
                      textAlign={POSITION.LEFT}
                    />
                    <Text
                      text={group.description}
                      size={SIZE.MEDIUM}
                      textAlign={POSITION.LEFT}
                    />
                  </Content>
                )
              }
            ]}
          />
        </Tab>
      </Block>
    </>
  );
};
