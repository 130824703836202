import React, { useEffect } from "react"
import api from "../../utils/api"

function DetectIE() {
  async function isOnline() {
    if (window.location.pathname !== "/manutencao") {
      api.get("onplataform", {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
    }
  }

  useEffect(() => {
    // isOnline()
  }, [])

  function isIE() {
    const ua = window.navigator.userAgent

    const msie = ua.indexOf("MSIE ")
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)
    }
    const trident = ua.indexOf("Trident/")
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf("rv:")
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
    }

    // other browser
    return false
  }

  function showMsgBar() {
    // Por enquanto, deixar o navBar desabilitado
    return false
  }

  function getMessageToBradesco() {
    return `
        Para assistir a série NA REAL a qualquer hora na palma da sua mão, baixe o APP do LIT para 
        <a href='https://play.google.com/store/apps/details?id=com.saintpaulapp' target='_blank' rel="noopener noreferrer"> <strong>Android</strong> </a>
        ou <a href='https://apps.apple.com/br/app/lit/id1310754004' target='_blank' rel="noopener noreferrer"> <strong>IOS</strong> </a>.
        <br/>
        Para uma melhor experiência no desktop, recomendamos utilizar os navegadores 
        <a href='https://www.google.com/chrome/' target='_blank' rel="noopener noreferrer"> <strong>Chrome</strong> </a>
        ou <a href='https://support.apple.com/pt_BR/downloads/safari' target='_blank' rel="noopener noreferrer"> <strong>Safari</strong> </a>.
      `
  }

  if (isIE() && showMsgBar()) {
    return (
      <div
        id="message-ie"
        style={{
          background: "#d80000",
          color: "white",
          textAlign: "center",
          margin: 0,
        }}
      >
        <br />
        <h5>
          <strong>Olá, o LIT não é compatível com o Internet Explorer.</strong>
        </h5>
        <h5 dangerouslySetInnerHTML={{ __html: getMessageToBradesco() }} />
        <br />
      </div>
    )
  }
  return null
}

export default DetectIE
