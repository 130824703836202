import styled from 'styled-components';
import { darken } from "polished";

export const Container = styled.div`
  div {
      /* padding: 10px; */
      /* flex: 1; */
      height: 100%;
      overflow-y: auto;
      margin-bottom: 5px;
      
      border-radius: 5px;

      & > button {
        
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        text-align: left;
        padding: 5px;
        margin-bottom: 10px;
        background-color: #fff;
        border: 0px solid ${darken(0.12, "#dae8fc")};
        cursor: pointer;
        word-break: break-word;
        font-size: 12px;

        button.showMore {
          margin-top: 10px !important;
          
          background-color: transparent;
          color: #42b3c9;
          margin-left: 5px;
          border: none;
          cursor: pointer;
          font-size: 12px;

          &:hover {
            opacity: 0.8;
          }
        }

        &:hover {
          background-color: ${darken(0.15, "#dae8fc")};
          button.showMore {
            color: #fff;
          }
        }

        span {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

       
      }
    }

    & > button {
      display: block;
      background: #e25457;
      padding: 5px;
      border-radius: 5px;
      color: #fff;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
    }
`
