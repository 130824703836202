import React from 'react'
import Img from "react-image"
import PropTypes from 'prop-types'

import placeholder from "../../../images/placeholder-avatar.png"

export default function NoImage({className, tip, style}) {
  return(
    <Img 
        src={placeholder}
        className={className}
        data-tip={tip}
        style={style}
      />
  )
}

NoImage.propTypes = {
  className: PropTypes.string,
  tip: PropTypes.string,
  style: PropTypes.shape
};

NoImage.defaultProps = {
  style: {
    maxWidth: "70px",
    minHeight: "70px",
    maxHeight: "70px",
    minWidth: "70px",
  },
  tip: '',
  className: ''
};
