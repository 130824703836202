/* eslint-disable react/no-array-index-key */
import React, {useCallback} from 'react';
import _ from 'lodash';
import {FaCheckCircle, FaTimesCircle, FaRegComments} from 'react-icons/fa';

import * as Styles from './styles';
import Slider from '../Slider'

function Header({
  onChange,
  currentValue,
  assessmentData,
  allowassessmentresponse
}) {
  const renderStatus = useCallback((status) => {
    if(status) {
      return status
    }
    return null
  }, []);

  const renderIcon = useCallback((status) => {
    if(status) {
      switch (status.toLowerCase()) {
        case 'incorrect':
          return <FaTimesCircle className="status-icon" />
        default:
          return <FaCheckCircle className="status-icon" />
      }
    }

    return status;
  }, []);

  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.BoxTimerWithNumberQuestions allowassessmentresponse={allowassessmentresponse}>
          <Styles.NumberOfQuestions allowassessmentresponse={allowassessmentresponse}>
            <span>NOTA</span>
            {assessmentData.finalGrade}
          </Styles.NumberOfQuestions>
        </Styles.BoxTimerWithNumberQuestions>
        
        {allowassessmentresponse && <div className="parent">
          <Slider>
            {
              assessmentData.surveys
              .map((item, index) => (
                <Styles.Button
                  onClick={() => onChange(index)}
                  key={index}
                  current={currentValue === index}
                  emptySurvey={item.emptySurvey}
                  status={renderStatus(_.get(item, 'surveyStatus', null))} 
                >
                  {index + 1}
                  {renderIcon(_.get(item, 'surveyStatus', null))}
                  {item.feedback && (
                    <FaRegComments className="feedback" />
                  )} 
                </Styles.Button>
            ))}
          </Slider>    
        </div>}
      </Styles.Content>
    </Styles.Container>
  );
}

export default Header;
