import React, { useRef } from 'react'
import LoadingPaul from '../LoadingPaul'

export default function Library(props) {

    const [origemParamLogin, setLogin] = React.useState("");
    const [origemParamISBN, setISBN] = React.useState("");
    const [origemParamPage, setPage] = React.useState("");
    const [origemParamToken, setToken] = React.useState("");
    const [origemParamRedirect, setRedirect] = React.useState("");
    const formRef = useRef(null);

    React.useEffect(() => {
        /* Implementation redirect */
        const { location } = props
        const params = new URLSearchParams(location.search)

        const isbn = params.get("isbn") || ""
        const page = params.get("page") || "1"
        const login = localStorage.getItem('ra') || ""
        const token = params.get("token") || ""
        const redirect = params.get("redirect") || ""
        setISBN(isbn);
        setLogin(login);
        setToken(token);
        setPage(page);
        setRedirect(redirect);
    }, []);

    React.useEffect(() => {
      if (origemParamRedirect)
          formRef.current.submit();
    }, [origemParamRedirect])

    return (<>
        {origemParamRedirect && (
            <form ref={formRef} action={origemParamRedirect} method="post" className="hide">
                <input name="isbn" type="hidden" value={origemParamISBN} className="hide" />
                <input name="page" type="hidden" value={origemParamPage} className="hide" />
                <input name="login" type="hidden" value={origemParamLogin} className="hide" />
                <input name="token" type="hidden" value={origemParamToken} className="hide" />
                <input id="commit" type="submit" value="ACESSAR" style={{ display: "none" }} />
            </form>
        )}
        {!origemParamRedirect && (
            <div style={{ margin: '0 auto' }}>
                <LoadingPaul />
            </div>
        )}
    </>
    )
}
