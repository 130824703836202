import "@devlit/react-pdfjs/dist/index.css";

import _ from "lodash";
import React, { Component } from "react";

import Pdfjs from "@devlit/react-pdfjs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as CourseActions } from "../../../store/ducks/newCourse/actions";
import { gateway } from "../../../utils/api";
import ViewEditor from '../../shared/ViewEditor';

import * as Styles from './styles';


// import PdfViewer from "../../PdfViewer";
class Documento extends Component {
  state = {
    page: 0,
    codeObject: null,
    fullScreen: false,
    idProfile: parseInt(localStorage.getItem("idProfile"), 10)
  };


  componentWillUnmount() {
    this.saveProgress('EXIT');
  }

  // setPage = newPage => {
  //   if (newPage) {
  //     const { setPlayerPosition } = this.props;
  //     this.setState({ page: newPage });
  //     setPlayerPosition(newPage);
  //   }
  // };

  setFullScreen = newFullScreen => {
    this.setState({ fullScreen: newFullScreen });
  };

  sendObjectProgress = (selected, idProfile, action, status) => {
    const { courseSession } = this.props;
    const sessionCourse = localStorage.getItem('session') || courseSession;
    const { code, courseCode } = selected;
    gateway.post("/webbff/lit_app_web/course_progress/course_progress_object/", {
      selected: { code, courseCode },
      idProfile,
      courseSession: sessionCourse,
      action,
      status,
      type: "PDF",
      position: _.get(selected, "contentProgress.ox_status.nx_value", 1) || 1 
    });
  }

  saveProgress = (action) => {
    const { idProfile } = this.state;
    const { selected } = this.props;

    if (selected.typeContent === "about") {
      return;
    }

    const progress = {
      idProfile,
      hierarchy: selected.hierarchy,
      contentCode: selected.code,
      courseCode: selected.courseCode,
      position: _.get(selected, "contentProgress.ox_status.nx_value", 1),
      status: "IN PROGRESS"
    };
    
    gateway
      .post("/webbff/lit_app_web/progress/saveProgress", progress)
      .then(res => {
        console.log("RESPOSTA endOfFile", res.data);
      })
      .catch(err => console.log(err));
         
    this.sendObjectProgress(selected, idProfile, action, 'IN PROGRESS');
  };

  endOfFile = async () => {
    const { idProfile } = this.state;
    const {
      selected,
      setDisciplineProgress,
      incrementCourseProgress
    } = this.props;

    const progress = {
      idProfile,
      hierarchy: selected.hierarchy,
      courseCode: selected.courseCode,
      contentCode: selected.code,
      position: 1,
      status: "DONE"
    };

    const objectStatusProgress = _.get(selected, 'contentProgress.ox_status.wk_status', 'IN_PROGRESS');
    
    
    gateway
    .post("/webbff/lit_app_web/progress/saveProgress", progress)
    .then((response) => {
      document
      .getElementById(`course-object-${selected.code}`)
      .classList.add("active");
      if(objectStatusProgress !== "DONE") {
        const progress = _.get(response, 'progress', []);      
        const foundObject = progress.find(p => p.rk_reference === selected.code);
        const status = _.get(foundObject, 'wk_status');
        incrementCourseProgress(status);
      }
      setDisciplineProgress(selected.idDiscipline);
    })
    .catch(err => console.log(err));
    
    this.sendObjectProgress(selected, idProfile, 'DONE', 'DONE');
  };

  onPageComplete = async ({ page, fullScreen, EOF }) => {
    const { page: pageState, codeObject } = this.state;
    const { setPlayerPosition, selected } = this.props;

    if (selected.typeContent === "about" || selected.tags.includes("ABOUT")) {
      return;
    }

    setPlayerPosition(page);

    if(pageState !== page || codeObject !== selected.code) {    
      this.setState({ page, codeObject: selected.code });
      if(!EOF) {
        this.saveProgress('CHANGE PAGE');
      }
    }

    if (EOF) {     
      this.endOfFile();
    }


    /**
     * Código responsável por desabilitar overflow do container da aplicação
     */
    if (this.state.fullScreen !== fullScreen) {
      this.setFullScreen(fullScreen);
      document.getElementById("body-conteudo").style.overflow = fullScreen
        ? "hidden"
        : "";
      document.querySelector(
        'div[role="navigation"]'
      ).style.overflow = fullScreen ? "hidden" : "auto";
    }
  };

  downloadAnexo = ({ path }) => {
    if (path) {
      const linkDownload = document.createElement('a');
      linkDownload.setAttribute('download', '');
      linkDownload.setAttribute('href', path);
      linkDownload.setAttribute('target', '_blank');
      linkDownload.click();
    }
  };

  render() {
    const { selected } = this.props;
    const page = _.get(selected, "contentProgress.ox_status.nx_value", 1);
    if (!selected.url) {
      return null;
    }
    const knowMoreList = this.props.selected.knowMore;

    return (
      <>
        <div id="pdf" className="row">
          <div className="col-md-12">
            <div id="pdf-div-id" className="pdf-wrapper">
              <Pdfjs
                onDownload
                onScrollMode
                onFullScreen
                page={page}
                file={selected.url}
                onUpdate={this.onPageComplete}
              />
          
            </div>
            {knowMoreList.length > 0 && <h4>SAIBA MAIS - Arquivos</h4>}
            {knowMoreList.length > 0 
              && knowMoreList.map((item, index) => {

                    const {media} = item;
                    
                    return <Styles.ItemContainer key={media.id}>
                              <Styles.ContainerIcon>
                                  {media.type === 'BOOK'
                                    ? media.type
                                    : _.get(media, 'type', '?')}
                              </Styles.ContainerIcon>
                              <Styles.ItemDescription>
                                {_.get(media, 'name', '?')}
                              </Styles.ItemDescription>
                              {_.get(media, 'path', false) && (
                                <Styles.BtnDownload
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => this.downloadAnexo({ path: media.path })}
                                >
                                  <i className="fa fa-download" aria-hidden="true" />
                                  <a target="" />
                                </Styles.BtnDownload>
                              )}
                            </Styles.ItemContainer>
                            }
          )}
          </div>
        </div>
        <Styles.ObjectDescription>
          <ViewEditor data={selected.shortDescription || selected.longDescription} />
        </Styles.ObjectDescription>

        </>
    );
  }
}

const mapStateToProps = state => ({
  selected: state.newCourse.selected,
  course: state.newCourse.data,
  idCourse: state.newCourse.data.idCourse,
  playerPosition: state.newCourse.playerPosition,
  courseSession: state.newCourse.courseSession
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Documento);
