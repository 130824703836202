import React, { Component } from "react"

import moment from "moment"

class Formacao extends Component {
  constructor(props) {
    super(props)

    const {
      idAcademicExperience,
      idProfile,
      university,
      dateInit,
      dateEnd,
      course,
      graduate,
      textAbourCourse,
    } = this.props.experience

    this.state = {
      idAcademicExperience: idAcademicExperience,
      idProfile: idProfile,
      university: university,
      dateInit: moment(dateInit, "MM/YYYY"),
      dateEnd: moment(dateEnd, "MM/YYYY"),
      course: course,
      graduate: graduate,
      textAbourCourse: textAbourCourse,
    }
  }

  render() {
    const {
      idAcademicExperience,
      idProfile,
      university,
      dateInit,
      dateEnd,
      course,
      graduate,
      textAbourCourse,
    } = this.props.experience

    return (
      <div>
        <div className="row">
          <div className="col-10">
            <h5>{course}</h5>
            <span>{university}</span>
            <p>
              {dateInit} - {dateEnd}
            </p>
          </div>
        </div>
        <div className="row">
          <div className={`col-12 cargo-usuario--container ${this.props.lastClass}`}>
            {textAbourCourse ? <p>{textAbourCourse}</p> : <p>Nenhuma descrição adicionada</p>}
          </div>
        </div>
      </div>
    )
  }
}

export default Formacao
