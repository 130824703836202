/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { gateway } from "../../../../../../utils/api";

import Screen from './screen';

function LaneCourses({ data, contentCode, onClick, errorCoursesMessage }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    if (data) {
      const docs = _.get(data, 'docs', []);
      const page = _.get(data, 'page', 1);
      const limit = _.get(data, 'limit', 10);
      setLimit(limit);
      setCourses(docs);
      setCurrentPage(page + 1);
    }
  }, [data]);

  const getMoreCourses = useCallback(async () => {
    if (currentPage) {
      try {
        const query = `?limit=${limit}&page=${currentPage}`;
        const url = `webbff/lit_app_web/classes/${contentCode}/content${query}`;
        const response = await gateway.get(url);
        const hasNextPage = _.get(response, 'hasNextPage', false);
        const newCourses = _.get(response, 'docs', []);

        setCurrentPage(hasNextPage ? currentPage + 1 : null);
        setCourses(prevCourses => [...prevCourses, ...newCourses]);
      } catch (error) {
        console.log(error);
      }
    }
  }, [currentPage, limit, contentCode]);


  return (
    <Screen errorCoursesMessage={errorCoursesMessage} courses={courses} getMoreCourses={getMoreCourses} onClick={onClick} />
  );
}

export default LaneCourses;
