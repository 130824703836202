import React from "react";
import {
  NUMBER,
  SIZE,
  ICON,
  PAGE_INFORMATION,
  COLOR,
  ORIENTATION
} from "../../../constants";

import GroupedListing from "../../GroupedListing";
import OverflowContent from "../../basicComponents/OverflowContent";
import Tab from "../../basicComponents/Tab";
import NavTab from "../../basicComponents/NavTab";
import IconInfo from "../../Info/IconInfo";
import Block from "../../basicComponents/Block";
import InfiniteScroll from "../../basicComponents/InfiniteScroll";

import { ModalBody } from "./styles";

export default ({ reactionsList = [], loadMoreReactions }) => {
  const reactionsMap = {
    ACT_LIKE: {
      icon: ICON.LIKE,
      title: PAGE_INFORMATION.LIKED
    },
    ACT_GRR: {
      icon: ICON.SAD,
      title: PAGE_INFORMATION.GRR
    }
  };

  function showTabs() {
    let headers = [];
    let contents = [];

    if (!reactionsList.length) return;

    reactionsList.forEach(reaction => {
      headers.push({
        id: reaction.type,
        component: (
          <IconInfo
            icon={reactionsMap[reaction.type].icon}
            title={reactionsMap[reaction.type].title}
          />
        )
      });

      contents.push({
        id: reaction.type,
        component: (
          <Block color={COLOR.BLOCK_LIGHT} orientation={ORIENTATION.AFASTABLE}>
            <OverflowContent size={SIZE.EXTRA_LARGE}>
              <InfiniteScroll
                loadAction={() => loadMoreReactions(reaction.type)}
                dataLength={reaction.people.length}
                hasMore={reaction.quantity > reaction.people.length}
              >
                <GroupedListing
                  openExternalPage={true}
                  dataLength={reaction.quantity}
                  data={{
                    items: reaction.people,
                    type: PAGE_INFORMATION.GROUP_MEMBERS
                  }}
                  grid={NUMBER.ONE}
                />
              </InfiniteScroll>
            </OverflowContent>
          </Block>
        )
      });
    });

    return (
      <Tab id={headers[0].id}>
        <NavTab header={headers} content={contents} />
      </Tab>
    );
  }

  return <ModalBody>{showTabs()}</ModalBody>;
};
