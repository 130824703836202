import React from "react";
import {
  SIZE,
  APPEARANCE,
  ACTION,
  NUMBER,
  PAGE_INFORMATION,
  POSITION,
  FONT_TYPE,
  STATUS
} from "../../../constants";

import OverflowContent from "../../basicComponents/OverflowContent";
import ListRenderer from "../../ListRenderer";
import Actions from "../../Actions";
import Solicitation from "../../Info/DataInfo";
import Text from "../../basicComponents/Text";
import formatDate from "../../../../utils/formatDate";

import { ModalBody, List } from "./styles";

export default ({ groups, cancelJoinSolicitation, loadMoreSolicitations }) => {
  function showSolicitatiions() {
    if (!groups || !groups.length) {
      return (
        <Text
          text={"Nenhum item encontrado"}
          fontType={FONT_TYPE.BOLD}
          textAlign={POSITION.CENTER}
        />
      );
    } else {
      return groups.map(group => (
        <Solicitation
          id={group.code}
          name={group.name}
          img={group.image}
          link={`/beta/social/socialgroup/${group.code}`}
          description={`${group.totalMembers} ${
            group.totalMembers === 1
              ? PAGE_INFORMATION.MEMBER
              : PAGE_INFORMATION.MEMBERS
          } - ${PAGE_INFORMATION.SOLICITATION_DATE} ${formatDate(
            group.solicitationDate
          )}`}
          listButtons={STATUS.TRUE}
          actions={
            <Actions
              itemsToRender={[
                {
                  type: "button",
                  action: () => {
                    cancelJoinSolicitation(group.code);
                  },
                  buttonText: ACTION.CANCEL,
                  appearance: APPEARANCE.OUTLINE_PRIMARY,
                  size: SIZE.SMALL
                }
              ]}
            />
          }
        />
      ));
    }
  }

  return (
    <ModalBody>
      <OverflowContent onBottom={loadMoreSolicitations} >
        <List>
          <ListRenderer
            grid={NUMBER.ONE}
            componentToRender={showSolicitatiions()}
          ></ListRenderer>
        </List>
      </OverflowContent>
    </ModalBody>
  );
};
