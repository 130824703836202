import styled from 'styled-components'

export const Container = styled.div`
  .progress {
    background-color: #d8d8d8;
    border-radius: 20px;
    position: relative;
    margin: 5px 0;
    height: ${props => props.height ? props.height : '30px'};
    width: ${props => props.width ? props.width : '300px'};
  }
  
  .progress-done {
    background: ${props => props.background ? props.background : '#27aae1'};
    box-shadow: 0 3px 3px -5px ${props => props.background ? props.background : '#27aae1'}, 0 2px 5px ${props => props.background ? props.background : '#27aae1'};
    border-radius: 12px;
    color: ${props => props.color ? props.color : '#fff'};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
  }
`
