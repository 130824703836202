import React, { useState, useEffect } from "react"

import Slider from "../shared/Slider";

const SliderWrap = ({ children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(children.length){
      setShow(true)
    }
  }, [children])
  return (
    <>
      {show && <Slider>{children}</Slider>}
    </>
  )
}

export default SliderWrap
