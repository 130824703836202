import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Function} onClick //button click action
 * @param {Function} onEnter //action triggered when mouse hover
 * @param {Function} onLeave //action triggered when mouse leave 
 * @param {String} border //{BORDER, NO_BORDER} appearance of the border displayed on the button
 * @param {String} appearance //{PRIMARY, SECONDARY, OUTLINE, OUTLINE_PRIMARY, CLOSE, GRADIENT, LINK, CARD_REACTION, LINK_ICON_ACTIVE, PAGINATION, MODAL, MODAL_CONFIRM, INVISIBLE } button appearance
 * @param {String} color //{LIGHT_GRAY} button color
 * @param {String} mode //{DISABLED} button status appearance
 * @param {String} size //{SMALL, MEDIUM, LARGE} button size
 * @param {String} orientation //{FULL_WIDTH} button display orientation
 * @param {String} position //{LEFT, RIGHT, TOP_RIGHT} buttons alignment on the grid
 * @param {String} icon //icon displayed on the button
 * @param {String} iconPosition //{LEFT, RIGHT} position of the icon displayed on the button
 * @param {String} text //text displayed on the button
 * @param {Number} valueInteractions //{(x)} value displayed on the interactions button
 * @param {Function} actionValueInteractions //interactions button action
 * @param {Number} badgeValue //notification button alert value
**/

export default ({
  onClick = DEFAULT_VALUES.FUNCTION,
  onEnter = DEFAULT_VALUES.FUNCTION,
  onLeave = DEFAULT_VALUES.FUNCTION,
  border = DEFAULT_VALUES.STRING,
  appearance = DEFAULT_VALUES.STRING,
  color = DEFAULT_VALUES.STRING,
  mode = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING,
  orientation = DEFAULT_VALUES.STRING,
  position = DEFAULT_VALUES.STRING,
  icon = DEFAULT_VALUES.STRING,
  iconPosition = DEFAULT_VALUES.STRING,
  text = DEFAULT_VALUES.STRING,
  valueInteractions = DEFAULT_VALUES.NUMBER,
  actionValueInterations = DEFAULT_VALUES.FUNCTION,
  badgeValue = DEFAULT_VALUES.NUMBER
}) => {
  return (
    <>
      <Screen
        border={border}
        appearance={appearance}
        color={color}
        mode={mode}
        size={size}
        orientation={orientation}
        position={position}
        onClick={onClick}
        onEnter={onEnter}
        onLeave={onLeave}
        icon={icon}
        iconPosition={iconPosition}
        text={text}
        valueInteractions={valueInteractions}
        actionValueInterations={actionValueInterations}
        badgeValue={badgeValue}
      />
    </>
  );
};
