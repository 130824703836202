import _ from "lodash";

import { useState, useCallback } from "react";

export default () => {
  const [muted, _setMute] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleMute = useCallback(
    _.throttle(
      () => {
        setLoading(true);
        _setMute(prevState => !prevState);
        setLoading(false);
      },
      1000,
      { trailing: false }
    ),
    [_setMute]
  );

  return [muted, toggleMute, loading];
};
