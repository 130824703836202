import React from "react";
import {
  ICON,
  COLOR,
  SIZE,
  APPEARANCE,
  BORDER_TYPE
} from "../../../../constants";
import Button from "../../../basicComponents/Button";
import Header from "../../../Header";
import {} from "./styles";

export default ({ textHeader, pageImage, closeModal }) => {
  return (
    <Header
      size={SIZE.MEDIUM}
      imagePage={pageImage}
      textDescription={textHeader}
      appearance={APPEARANCE.MODAL_HEADER}
      bottomLeft={[
        <Button
          appearance={APPEARANCE.LINK}
          size={SIZE.LARGE}
          icon={ICON.BACK_ARROW}
          onClick={closeModal}
          color={COLOR.LIGHT}
          border={BORDER_TYPE.NO_BORDER}
        />
      ]}
    />
  );
};
