import styled, { css } from "styled-components";
import ItemsCarousel from "react-items-carousel";
import { Link } from "react-router-dom";
import { GiTicket } from "react-icons/gi";

export const Main = styled.div``;

export const CupomIcon = styled(GiTicket)`
  position: absolute;
  top: 10px;
  left: 10px;
  color: rgba(2, 196, 255, 1);
  font-size: 25px;
`;
export const CupomTooltip = styled.span`
  width: 200px;
  position: absolute;
  background: rgba(2, 196, 255, 1);
  color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  top: -20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 9990;
`;

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  max-width: 1024px;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column-reverse;
    padding: 0;
  }
`;

export const ContainerSlider = styled.div`
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  @media (max-width: 768px) {
    overflow-x: hidden;
  }
`;

export const CustomItemsCarousel = styled(ItemsCarousel).attrs({
  classes: {
    wrapper: "customCarouselWrapper",
    itemsWrapper: "customCarouselItemsWrapper",
    itemsInnerWrapper: "customCarouselItemsInnerWrapper",
    itemWrapper: "customCarouselItemWrapper",
    rightChevronWrapper: "customCarouselRightChev",
    leftChevronWrapper: "customCarouselLeftChev"
  }
})``;

export const Cards = styled.div`
  width: 100%;
  height: auto;

  .customCarouselWrapper {
  }

  .customCarouselItemsWrapper {
  }

  .customCarouselItemsInnerWrapper {
    justify-content: ${props => props.qtdItems <= 3 ? 'center' : 'flex-start'};
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .customCarouselItemWrapper {
    margin: 10px 15px 0 0;
    padding: 0;
    width: auto;
    @media (max-width: 768px) {
      margin: 10px;
    }
  }

  .customCarouselRightChev {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .customCarouselLeftChev {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .tooltipClassPayroll {
    width: 270px;
    background: rgba(2, 196, 255, 1);
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  .tooltipClass {
    width: 170px;
    background: rgba(2, 196, 255, 1);
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`;

export const SelectTypePlans = styled.select`
  background: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #f1f1f1 !important;
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
`;

export const ContainerVoucherCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 26px 0;
  height: 500px;
`;

export const ContainerPlanTypes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  width: 700px;

  @media (max-width: 673px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const FeatureItem = styled.li`
  font-size: 14px;
  color: ${props => (props.color ? props.color : "#000")};
  font-weight: ${props => (props.color ? "bold" : "normal")};

  svg {
    color: ${props => (props.selected ? "#fff" : "rgba(2,196,255,1)")};
  }
  & + li {
    margin-top: 5px;
  }
`;

export const ButtonPlan = styled.div`
  margin-top: ${props => (props.link ? "20px" : 0)};
  height: 44px;
  min-width: 220px;
  border: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: ${props => (props.link ? "2px" : "2px")};

  &:focus {
    outline: none;
    border: none;
  }

  /* &:hover {
    background-color: #02c4fd;
    background: #02c4fd;
  } */

  &:disabled {
    background: #c4c0c0;
  }

  button {
    width: 100%;
    border: none;
    background: ${props => (props.active ? "rgba(25,133,252,1)" : "transparent")};
    padding: ${props => (props.link ? "2px 14px" : "2px")};
    border-radius: 5px;
    flex: 1;
    cursor: pointer;

    &:hover {
      background: rgb(25,133,252,1);
      span {
        text-transform: uppercase;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #fff;
      }
    }

    span {
      font-size: 16px;
      font-family: "Lato", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      background: ${props =>
    props.active
      ? "#fff"
      : "#444"};
      background: ${props =>
    props.active
      ? "#fff"
      : "#444"};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }
  }
`;

export const ButtonNext = styled.div`
  border: 1px solid #1985fc;
  color: #1985fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: 2px;
  text-align: center;
  border-radius: 5px;

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    background: rgba(25,133,252,1);
    color: #fff;
  } 

  &:disabled {
    background: #c4c0c0;
  }

  button {
    width: 100%;
    border: none;
    flex: 1;
    cursor: pointer;
    background: transparent;
    text-align: center;
    display:flex-inline;

    &:hover {
      span {
        text-transform: uppercase;
        background: rgba(25,133,252,1);
        color: #fff;
      }
    }

    span {
      font-size: ${props => (props.actualplan ? "12px" : "16px")};
      font-family: "Lato", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      -webkit-background-clip: text;
      cursor: pointer;
      color: #1985fc
    }
  }
`;
export const ButtonNextSpotLight = styled.div`
  button {
    width: 100%;
    border: none;
    border-radius: 30px;
    background: ${props => (props.disabled ? "#c4c0c0" : "#fff")};
    border-radius: 30px;
    flex: 1;
    cursor: pointer;

    &:hover {
      background: ${props =>
    props.disabled
      ? "#c4c0c0"
      : "linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%)"};
      span {
        text-transform: uppercase;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    span {
      font-size: ${props => (props.actualplan ? "12px" : "16px")};
      font-family: "Lato", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      background: ${props =>
    props.disabled
      ? "#fff"
      : "-webkit-linear-gradient(0deg,rgba(2,196,255,1),rgba(142,230,123,1))"};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }
  }
`;

export const PlanItem = styled.div`
  width: ${props => (props.cardWidth ? `${props.cardWidth}px` : "400px")};
  min-height: 300px;
  margin-top: 22px;
  border-top: 3px solid #1985fc;
  /* margin-right: 10px; */
  position: relative;
  /* overflow: auto; */
  /* display: flex;
  justify-content: space-between; */
  /* align-items: center; */
  /* flex-direction: column; */
  box-shadow: 0 0 6px rgba(0,0,0,.1);
  background: ${props => (props.selected ? "#f1f1f1" : "#fff")};
  color: #000;
  box-sizing: border-box;
  padding: 40px 30px;
  border-radius: 3px;
  position: relative;
  input {
    width: 100%;
    outline: 0;
  }

  &:hover {
    /* background-color: #02c4fd;
    background: #02c4fd; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  ${props =>
    props.voucher &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;      

      .box-loading {
        height: 270px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        font-size: 14px;
        margin-top: 10px;
        line-height: 1.3;
        color: ${props.selected ? "#fff" : "inherit"} !important;
      }
    
      input[type="text"] {
        color: ${props.selected ? "#fff" : "inherit"} !important;
        margin-bottom: 5px;
      }

      button {
        background: ${props.selected && "#fff"} !important; 
      }



      @media (max-width: 768px) {
        width: 100%
        margin: 10px 0;
        
      }
    `}

/* hr {
  margin: 0;
} */

${props =>
    props.selected &&
    css`
    hr {
      border-color: #fff;
    }
  `}

  &::-webkit-scrollbar {
    background-color:#fff;
    width:16px;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-track {
    background-color:#fff;
  }

 &::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
      background-color:#babac0;
      border-radius: 16px;
      border:5px solid #fff
  }
  &::-webkit-scrollbar-thumb:hover {
      background-color:#a0a0a5;
      border:4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {display:none}

 
  h2 {
    font-size: 14px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    b {
      display: block;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  h3 {
    width: 100%;
    position: relative;
    margin-bottom: 0;
    
    span {
      display: flex;

      justify-content: center;
      b {
        font-size: 40px;
        font-weight: 400;
      }
      small {
        font-size: 11px;
        font-weight: 400;
        display: flex;
        /* background: red; */
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 7px;
        padding-left: 2px;
      }

      & + span {
        margin-top: 5px;
      }

      
    }
   
    span {
      font-size: 11px;
    }

    small {
      font-size: 14px;
    } 
  }

  ul {
    margin-top: 10px;
    padding: 0;
    li {
      font-size: 14px;
      svg {
        color: rgba(2,196,255,1);
      }
      & + li {
        margin-top: 5px;
      }
    }
  }

  span.text-card {
    color: #fff;
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 14px;
  }
`;

export const WarningContent = styled.button`
  padding: 10px;
  background: #fff3cd;
  border: 0px solid #e6d5a3;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const WarningText = styled.span`
  color: #876607;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 16px;
`;

export const BfButton = styled.span`
  padding-left: 10px;
  padding-right: 10px;
`;

export const CerticateLink = styled(Link)`
  /* position: absolute; */
  background: #fff;
  cursor: pointer;
  padding: 10px;
  margin-top: 20px;
  color: #47b1de;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #fff;
    background: #47b1de;
  }
`;

export const PriceDescription = styled.div`
  color: #999;
  font-size:18px;
`;

export const PlanPrice = styled.h4`
  color: black;
  font-size:30px;
  font-weight:bold;
  margin-top: 20px;
  text-align: center;
`;

export const InstallmentsDescription = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

export const FooterPlanDescription = styled.div`
  text-align: center;
`;

export const SeeMoreButton = styled.button`
  background: transparent;
  padding: 10px;
  position: relative !important;
  border: none;
  margin-top: 0px !important;
  cursor: pointer;

  span{
    color: gray !important;
  }
`;

export const PlanTypeView = styled.div`
  background-color: rgba(25,133,252,.5);
  color: white;
  width: 90%;
  padding: 5px 5px 5px 30px;
  margin-left: -31px;
  margin-top: 10px;
  font-size: 12px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;
