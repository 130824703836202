import React from 'react';

import * as Styles from '../../styles';
import { formatReal } from '../../../../../../utils/functions';

function PlanDetails({ plan }) {
  const { idTotvsCourses } = plan.config || {}

  return (
    <>
      <div className="row justify-content-md-center">
        <Styles.DescriptionText className="box-info-title text-left col-md-8 col-12">
          {plan.description}
        </Styles.DescriptionText>
      </div>

      <div className="row justify-content-md-center" style={{ marginBottom: '20px' }}>
        <div className="total-pagamento col-md-8 col-12">
          {
            plan.discountValue > 0 &&
            (!idTotvsCourses || idTotvsCourses.length === 0) &&
            (
              <Styles.BoxPrice className="infos">
                <Styles.DiscountText className="title">
                  Desconto
                </Styles.DiscountText>
                <span className="value" style={{ marginLeft: 0 }}>
                  R$ {plan && plan.discountValue && formatReal(plan.discountValue)}
                </span>
              </Styles.BoxPrice>
            )
          }
          <Styles.BoxPrice className="infos">
            <Styles.ValueText className="title">Valor</Styles.ValueText>
            <span className="value">
              R$ {plan && plan.price && formatReal(plan.price)}
            </span>
          </Styles.BoxPrice>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-md-8 col-12">
          <p>
            O pagamento por PIX é instantâneo e prático. <br />
          </p>
        </div>
      </div>
    </>
  );
}

export default PlanDetails;
