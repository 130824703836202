import React from "react";
import { FaSpinner } from "react-icons/fa";

import * as Styles from "./styles";
function Loading() {
  return (
    <Styles.Container>
      <FaSpinner size={20} />
    </Styles.Container>
  );
}

export default Loading;
