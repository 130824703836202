import styled from 'styled-components'

const DivSearch = styled.div`
	height: 40px;
	margin-top: 30px;
	margin-left: -1px;
`
const ButtonSearch = styled.button`
	border-radius: 0px 10px 10px 0px;
	color: rgba(255, 255, 255, 0.8);
`

const DivTable = styled.div`
	margin-top: 30px;
`
const DivAlert = styled.div`
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
`
const DivAlertSuccess = DivAlert.extend`
	background-color: #d4edda;
	border-color: #c3e6cb;
`
const DivAlertError = DivAlert.extend`
	background-color: #f8d7da;
	border-color: #f5c6cb;
`
const TextAlert = styled.p`
	font-weight: bold;
	margin: 0;
`
const TextSuccess = TextAlert.extend`
	color: #155724;
`
const TextError = TextAlert.extend`
	color: #721c24;
`
export {
	DivSearch,
	ButtonSearch,
	DivTable,
	DivAlertSuccess,
	DivAlertError,
	TextSuccess,
	TextError
}
