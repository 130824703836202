import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from "react-redux";
import _ from "lodash";
import ProgressBar from '../ProgressBar';

import * as Styles from './styles';

function SubNav({ selected, navigateContent, navigationContent, toggleSidebar, sidebarOpened }) {

    const course = useSelector(state => state.newCourse.data);
    const progress = useSelector(state => state.newCourse.progress)

    const checkIsTrailAbout = useMemo(() => {
        const children = _.get(course, 'children', []);
        const courseType = _.get(course, 'type');
     
        const objectFinded = children.find(item => item.tags && item.tags.includes("ABOUT"));
        return objectFinded 
                && objectFinded.id === selected.id 
                && courseType === "TRAIL";
    }, [selected]);

    const parentNameSelected = useMemo(() => {
        return _.get(selected, 'parentName', '');
    }, [selected]);

    return (
      <Styles.Container>
        <Styles.IconContainer>
          <Styles.MenuIcon onClick={toggleSidebar} sidebarOpened={sidebarOpened}>
            <i className={sidebarOpened ? "fa fa-angle-left" : "fa fa-list-ul"} />
          </Styles.MenuIcon> 
        </Styles.IconContainer>
        <Styles.NavSection isTrail={checkIsTrailAbout}>
            {!checkIsTrailAbout &&<Styles.NavButton 
                onClick={() => navigateContent('prev')}
                disabled={!navigationContent.prev}>
                <i className="fa fa-angle-left" />
            </Styles.NavButton>}
            <Styles.CourseTitle>
                {course.name && (
                  <>
                    {course.name.length > 150
                      ? (
                        <Tooltip title={course.name} aria-label="add" placement="top">
                          <span>{course.name.substring(0,150)} ...</span>
                        </Tooltip>
                      )
                      : course.name
                    }
                  </>
                )}
                
                <strong>
                  {parentNameSelected && (parentNameSelected || '').length > 90
                    ? (
                      <Tooltip title={parentNameSelected} aria-label="add" placement="top">
                        <span>{parentNameSelected.substring(0,90)} ...</span>
                      </Tooltip>
                    )
                    : parentNameSelected
                  }
                </strong>
                <Styles.DescriptionUC>
                  {selected.name && (parentNameSelected || '').length > 150
                    ? (
                      <Tooltip title={selected.name} aria-label="add" placement="top">
                        <span>{selected.name.substring(0,150)} ...</span>
                      </Tooltip>
                    )
                    : selected.name
                  }
                </Styles.DescriptionUC>
            </Styles.CourseTitle>
            {!checkIsTrailAbout && <Styles.NavButton 
                onClick={() => navigateContent('next')}
                disabled={!navigationContent.next}>
                <i className="fa fa-angle-right" />
            </Styles.NavButton>}
        </Styles.NavSection>
        <Styles.ProgressContainer isTrail={course.type === "TRAIL"}>
            <ProgressBar data={progress} />
        </Styles.ProgressContainer>
      </Styles.Container>
    )
}

export default SubNav;
