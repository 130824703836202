import styled from 'styled-components'
import InputMask from 'react-input-mask';

export const Container = styled.div`
  padding: 10px 0;
  max-width: 700px;
  margin: 0 auto;
`

export const Title = styled.h2`
  margin-bottom: 10px;
`

export const Description = styled.p`
  font-size: 16px;
`

export const Label = styled.label`
  font-weight: bold;
  color: #58595b;
`

export const ContainerForm = styled.form`
  width: 100%;
`

export const TextError = styled.p`
  margin: 0;
  color: red;
`

export const InputPhone = styled(InputMask)`
  
`

export const InputCPF = styled(InputMask)`
  
`

export const InputCEP = styled(InputMask)`
  
`
