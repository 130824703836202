import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import Select from "react-select"
import { estados } from "../../utils/constants"
import { maskPhone, maskCpf, validarCpf } from "../../utils/functions"
import NavBar from "../Termos/Navbar"
import WatsonBadgeFooter from "./WatsonBadgeFooter"
import api from "../../utils/api"

const GIF_LIT = require("../../images/animacao_paul_standby.gif")

class Endereco extends Component {


  constructor(props) {
    super(props)
    const { profile } = this.props
    this.state = {
      telefoneContato: profile.phoneNumber || "",
      zipCode: profile.zipCode || "",
      address: profile.address || "",
      numero: profile.addressNumber || "",
      complemento: profile.addressAddOn || "",
      neighborhood: profile.neighborhood || "",
      cpf: profile.cpf || "",
      city: profile.city || "",
      uf: profile.uf || "",
      country: profile.country || "",
      gender: profile.gender || "",
      nacionality: profile.citizenship || "",
      loading: false,
      errors: "",
      erroCep: false,
    }
  }

  searchCEP = async () => {
    try {
      const { zipCode, numero, complemento } = this.state
      const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`)

      this.setState({
        neighborhood: response.data.bairro,
        city: response.data.localidade,
        address: `${response.data.logradouro}`,
        numero,
        complemento,
        uf: response.data.uf,
        country: "Brasil",
        erroCep: false,
      })
      this.inputNumero.focus()
    } catch (error) {
      this.setState({ erroCep: true })
      window.alert("CEP não encontrado")
    }
  }

  searchEnterCEP = e => {
    if (e.keyCode === 13 && e.target.value) {
      this.searchCEP()
    }
  }

  handleChangeCPF = (e) => {
    this.setState({cpf: maskCpf(e.target.value)})
  }


  validationForm = () => {
    const { erroCep, zipCode, address, neighborhood, city, uf, country, cpf, numero, telefoneContato, gender, nacionality } = this.state
    if (erroCep) return "Cep incorreto, preencha um CEP válido"
    if (zipCode === "") return "Por favor preencha o campo CEP"
    if (address === "") return "Por favor preencha o campo endereço"
    if (neighborhood === "") return "Por favor preencha o campo bairro"
    if (city === "") return "Por favor preencha o campo cidade"
    if (cpf === "") return "Por favor preencha o CPF"
    if (uf === "") return "Por favor preencha o campo estado"
    if (country === "") return "Por favor preencha o campo país"
    if (numero === "") return "Por favor preencha o campo número"
    if (telefoneContato === '') return "Por favor preencha o campo de telefone"
    if (gender === '') return "Por favor preencha o campo Gênero"
    if (nacionality === '') return "Por favor preencha o campo Nacionalidade"
    const isValid = validarCpf(cpf)
    if(!isValid) {
      return "CPF inválido!"
    }
    return null
  }

  saveForm = async () => {
    const { history } = this.props
    this.setState({ loading: true, errors: "" })
    const erro = this.validationForm()
    if (erro) {
      this.setState({ errors: erro, loading: false })
      return
    }
    try {
      await this.savePerfil()
      this.setState({ loading: false })
      history.push("/informacoesGerais")
    } catch (error) {
      this.setState({
        errors: "Não foi possivel enviar os dados, tente novamente mais tarde",
        loading: false,
      })
    }
  }

  savePerfil = () => {
    const {
      telefoneContato,
      zipCode,
      address,
      numero,
      complemento,
      neighborhood,
      city,
      cpf,
      country,
      uf,
      gender,
      nacionality,
    } = this.state
    const { profile } = this.props
    const newProfile = {
      ...profile,
      phoneNumber: telefoneContato,
      zipCode: zipCode.replace(/\.|-/g, ""),
      address,
      addressNumber: numero,
      addressAddOn: complemento,
      neighborhood,
      city,
      cpf: cpf.replace(/\.|-/g, ""),
      country,
      uf,
      gender,
      citizenship: nacionality,
    }
    return api.put("updateProfileTO", newProfile)
  }

  changeGender = event => {
    this.setState({ gender: event.target.value })
  }

  render() {
    const {
      telefoneContato,
      zipCode,
      address,
      numero,
      complemento,
      neighborhood,
      city,
      country,
      cpf,
      uf,
      gender,
      nacionality,
      loading,
      errors,
    } = this.state
    return (
      <div id="interesses">
        <NavBar />

        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row">
            <div className="col-12">
              <div className="container" id="objetivos-interesses">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="container" id="card-interesses">
                      <div>
                        <div className="row justify-content-md-center">
                          <div className="col-6 text-center">
                            <img
                              alt="gif_lit"
                              className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                              src={GIF_LIT}
                            />
                            <h5 style={{ color: "#27aae1" }} className="text-center">
                              Dados Pessoais
                            </h5>
                            <p className="text-justify">
                              Para ajudá-lo no uso da plataforma e para a emissão de certificados,
                              precisamos de algumas informações de contato. Esses dados são privados para
                              uso interno da plataforma!
                            </p>
                            <div className="col-12 text-center">
                              <div className="row">
                                <div className="form-group col">
                                  <label style={{ color: "red" }}>
                                    {this.validationForm() !== null ? 'Por favor, preencha todos os campos corretamente antes de prosseguir' : ''}
                                  </label>
                                </div>
                              </div>
                              <h6 className="text-center">Endereço</h6>
                              <div className="row">
                                <div className="form-group col-6">
                                  <input
                                    className="form-control"
                                    placeholder="CEP"
                                    required
                                    id="input-cep"
                                    onBlur={this.searchCEP}
                                    onKeyUp={this.searchEnterCEP}
                                    value={zipCode}
                                    onChange={evt =>
                                      this.setState({
                                        zipCode: evt.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="form-group col-2">
                                  <button
                                    type="button"
                                    className="btn btn-block btn-blue"
                                    id="btn-searchCEP"
                                    onClick={this.searchCEP}
                                  >
                                    <i className="fa fa-search" />
                                  </button>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-9">
                                  <input
                                    className="form-control"
                                    placeholder="Endereço"
                                    required
                                    type="text"
                                    id="input-address"
                                    value={address}
                                    onChange={evt =>
                                      this.setState({
                                        address: evt.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="form-group col-3 pl-0">
                                  <input
                                    className="form-control"
                                    placeholder="Número"
                                    required
                                    id="input-number"
                                    ref={input => {
                                      this.inputNumero = input
                                    }}
                                    type="text"
                                    value={numero}
                                    onChange={evt =>
                                      this.setState({
                                        numero: evt.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6">
                                  <input
                                    className="form-control"
                                    placeholder="Complemento"
                                    required
                                    id="input-complement"
                                    type="text"
                                    value={complemento}
                                    onChange={evt =>
                                      this.setState({
                                        complemento: evt.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <input
                                    className="form-control"
                                    placeholder="Bairro"
                                    required
                                    type="text"
                                    id="input-neighborhood"
                                    value={neighborhood}
                                    onChange={evt =>
                                      this.setState({
                                        neighborhood: evt.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-6">
                                  <input
                                    className="form-control"
                                    placeholder="Cidade"
                                    required
                                    type="text"
                                    id="input-city"
                                    value={city}
                                    onChange={evt =>
                                      this.setState({
                                        city: evt.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="form-group col-3 pr-2">
                                  <Select
                                    name="states"
                                    onChange={({ value }) => {
                                      this.setState({ uf: value })
                                    }}
                                    id="select-states"
                                    value={estados.filter(({ value }) => value === uf)}
                                    options={estados}
                                    placeholder="Estado"
                                  />
                                </div>
                                <div className="form-group col-3 pl-2">
                                  <input
                                    className="form-control"
                                    placeholder="País"
                                    required
                                    id="input-country"
                                    value={country}
                                    onChange={evt =>
                                      this.setState({
                                        country: evt.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row" />
                            </div>
                            <div className="col-12 text-center mt-2">
                              <h6 className="text-center">Informações Pessoais</h6>
                              <div className="row flex-center">
                                <div className="form-group col-6">
                                  <input
                                    className="form-control"
                                    placeholder="Telefone"
                                    id="input-phone"
                                    required
                                    value={maskPhone(telefoneContato)}
                                    onChange={evt =>
                                      this.setState({
                                        telefoneContato: evt.target.value.slice(0, 15),
                                      })
                                    }
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <input
                                    className="form-control"
                                    placeholder="Nacionalidade"
                                    required
                                    id="input-nacionality"
                                    value={nacionality}
                                    onChange={evt => this.setState({ nacionality: evt.target.value })}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <select
                                    className="form-control"
                                    required
                                    id="input-gender"
                                    onChange={this.changeGender}
                                    value={gender}
                                    name="genero"
                                  >
                                    <option value="" readOnly>Gênero</option>
                                    <option value="feminino">Feminino</option>
                                    <option value="masculino">Masculino</option>
                                    <option value="outro">Outro</option>
                                  </select>
                                </div>
                                <div className="col-6">
                                  <input
                                      className="form-control"
                                      placeholder="CPF"
                                      required
                                      type="text"
                                      id="input-cpf"
                                      value={cpf}
                                      onChange={this.handleChangeCPF}
                                    />
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-center" id="selecionar-personalizar">
                          <div>
                            <button
                              type="button"
                              disabled={loading}
                              onClick={this.saveForm}
                              id="btn-next-step"
                              className={`btn btn-blue btn-FontWeight6${loading && " btn-loading"}`}
                            >
                              Próximo passo
                            </button>
                          </div>
                        </div>
                        <div>
                          <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <WatsonBadgeFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    profile: state.user.profile.data,
  }
}

export default connect(mapStateToProps)(Endereco)
