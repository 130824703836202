import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import 'react-circular-progressbar/dist/styles.css';
import "react-sweet-progress/lib/style.css";


import React, {useEffect} from "react"
import ReactDOM from "react-dom"
import { ToastContainer } from "react-toastify"
import { Provider } from "react-redux"

import { PersistGate } from 'redux-persist/integration/react'
import 'card-react/lib/card.css'
import 'moment/locale/pt-br'

import 'react-toastify/dist/ReactToastify.css';
import "flag-icons/css/flag-icons.min.css";

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"

import "./css/style.css"
import "./css/colors.css"
import "./css/font-awesome-4.7.0/css/font-awesome.min.css"
import "./config/ReactotronConfig";

import Routes from './routes'
import './css/Calendar.css';
import { store, persistor } from './store'
import CacheBuster from './CacheBuster'
import AlertTimeout from './Components/AlertTimeout'
import * as serviceWorker from './serviceWorker'
import NavBarProvider from "./context/navBar/NavBarProvider";


library.add(fab, fas, far)

const cameFromTheAdmin = JSON.parse(localStorage.getItem("cameFromTheAdmin"));

function App() {

  useEffect(() => {
    const onUnload = () => {
      if(cameFromTheAdmin) {
        localStorage.clear();
      }
    };
    window.addEventListener("beforeunload", onUnload);
    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload()
        }

        return (
          <Provider store={store}>

              <PersistGate loading={null} persistor={persistor}>
                <AlertTimeout />
                <ToastContainer bodyClassName="toastBodyId" progressStyle={{colorProgressDefault: "linear-gradient(to right, #27aae1 0%, #fcee1e 100%)"}} />
                <NavBarProvider>
                <Routes />
                </NavBarProvider>
              </PersistGate>

          </Provider>
        )
      }}
    </CacheBuster>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
