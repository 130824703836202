import React, { useEffect } from 'react';
import _ from 'lodash';
import history from '../../../../utils/history';
import adsTracking from '../../../../utils/adsTracking';
import PaymentHistory from '../../../PaymentHistory';

export default function InvoicePaymentView() {
  const {
    location: { state }
  } = history;

  const eCommerceEvent = (plan, paidValue, contractCode) => {
    const tenant = localStorage.getItem('tenant');
    if (tenant && (tenant === 'LIT_BUSINESS' || tenant === 'SAINTPAUL')) {
      const eventName =
        tenant === 'LIT_BUSINESS' ? 'purchase_LIT' : 'purchase_SAINTPAUL';

      adsTracking.sendGoogleAnalyticsEcommerceEvent({
        eventName,
        step: {
          id: contractCode,
          revenue: paidValue,
          /** validar se esta é a melhor forma de pegar o cupom de desconto  */
          coupon: _.get(plan, 'claims.0', '')
        },
        products: [
          {
            name: plan.name,
            id: plan.code,
            price: paidValue,
            brand: 'LIT',
            // category: adsTracking.CATEGORY_DEFAULT,
            quantity: 1
          }
        ]
      });
    }
    console.log('---------FIM-----------');
  };

  useEffect(() => {
    const paidPlan = _.get(state, 'nextPageParams.paidPlan');
    const paidValue = _.get(state, 'nextPageParams.invoiceData.value');
    const contractCode = _.get(state, 'nextPageParams.contractCode');
    if (paidPlan) {
      eCommerceEvent(paidPlan, paidValue, contractCode);
    }
  }, []);

  return (
    <PaymentHistory
      subscriptionsStatus="PENDING_PAYMENT"
      buttonTitle="Trocar forma de pagamento"
      handleContinue={() => {
        history.push('/planos');
      }}
      goToOnboarding={() => {
        history.push('/onboarding');
      }}
    />
  );
}
