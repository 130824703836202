import { Types } from "./types"
/**
 * Reducers
 */
const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
  qtdNotRead: 0,
}

export default function notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case Types.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      }
    case Types.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data,
      }
    case Types.GET_COUNT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case Types.GET_COUNT_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case Types.GET_COUNT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        qtdNotRead: action.payload.qtdNotRead,
      }
    default:
      return state
  }
}
