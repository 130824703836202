import React from "react";

import {
  PAGE_INFORMATION,
  ICON,
  COLOR,
  ACTION,
  APPEARANCE,
  SIZE,
  POSITION,
  NUMBER,
  ORIENTATION
} from "../../../constants";

import IconInfo from "../../../../LitAtomicComponents/Info/IconInfo";
import Tab from "../../../../LitAtomicComponents/basicComponents/Tab";
import NavTab from "../../../../LitAtomicComponents/basicComponents/NavTab";
import CardsGroup from "../../../../LitAtomicComponents/Contents/CardsGroup";
import Timeline from "../../../../LitAtomicComponents/Contents/Timeline";
import Notifications from "../../../../LitAtomicComponents/Sliders/NotificationSlider";
import Block from "../../../../LitAtomicComponents/basicComponents/Block";
import Text from "../../../../LitAtomicComponents/basicComponents/Text";
import InfiniteScroll from "../../../../LitAtomicComponents/basicComponents/InfiniteScroll";
import ModalCall from "../../../../LitAtomicComponents/CallModal/GenericModalCall";
import CreateGroupModal from "../../../../LitAtomicComponents/Modals/CreateGroupModal";
import ManageInvitesModal from "../../../../LitAtomicComponents/Modals/ManageInvitesModal";
import ManageSolicitationsModal from "../../../../LitAtomicComponents/Modals/ManageSolicitationsModal";

import {} from "./styles";

export default ({
  isInternal,
  member,
  group,
  groups,
  solicitations,
  recommended,
  invitations,
  imageDefault,
  postItem,
  saveGroup,
  visibilityOptions,
  control,
  changeImage,
  areasOfInterest,
  handleImageChange,
  createGroupModalIsOpen,
  createGroupModalHandleOpen,
  manageSolicitationsModalIsOpen,
  manageSolicitationsModalHandleOpen,
  manageInvitesModalIsOpen,
  manageInvitesModalHandleOpen,
  inviteJoinSolicitation,
  cancelJoinSolicitation,
  declineInvitation,
  hiddenCreatePost,
  hiddenExternalComponents,
  loadMoreCommunications,
  loadMoreGroups,
  loadMoreInvites,
  hasMoreCommunications,
  hasMoreGroups,
  hasMoreInvites,
  loadMoreSolicitations,
  hasMoreSolicitations
}) => {
  return (
    <>
      <Block color={COLOR.BLOCK_LIGHT} orientation={ORIENTATION.AFASTABLE}>
        <Tab id={PAGE_INFORMATION.GROUPS}>
          <NavTab
            header={[
              {
                id: PAGE_INFORMATION.GROUPS,
                component: (
                  <IconInfo icon={ICON.USERS} title={PAGE_INFORMATION.GROUPS} />
                )
              },
              {
                id: PAGE_INFORMATION.POSTS,
                component: (
                  <IconInfo
                    icon={ICON.MESSAGE}
                    title={PAGE_INFORMATION.POSTS}
                  />
                )
              },
              {
                id: PAGE_INFORMATION.PROFILE,
                component: (
                  <IconInfo
                    icon={ICON.CIRCLE}
                    title={PAGE_INFORMATION.PROFILE}
                  />
                )
              },
              {
                id: PAGE_INFORMATION.COURSES,
                component: (
                  <IconInfo
                    icon={ICON.CALENDAR}
                    title={PAGE_INFORMATION.COURSES}
                  />
                )
              },
              {
                id: PAGE_INFORMATION.CONTACTS,
                component: (
                  <IconInfo
                    icon={ICON.USERS}
                    title={PAGE_INFORMATION.CONTACTS}
                  />
                )
              }
            ]}
            content={[
              {
                id: PAGE_INFORMATION.GROUPS,
                component: (
                  /*
                  <Block
                    color={COLOR.BLOCK_LIGHT}
                    orientation={ORIENTATION.AFASTABLE}
                  >
                  <NavTabBlock
                    notificationsContent={member.notifications}
                    arrayOfCards={groups}
                    perLine={NUMBER.FOUR}
                    title={PAGE_INFORMATION.MY_GROUPS}
                    groups={groups.allGroups}
                    member={member}
                    imageDefault={imageDefault}
                    privacyOptions={privacyOptions}
                    control={control}
                    changeImage={changeImage}
                    areasOfInterest={areasOfInterest}
                    action={action}
                    handleImageChange={handleImageChange}
                    inviteJoinSolicitation={inviteJoinSolicitation}
                    cancelJoinSolicitation={cancelJoinSolicitation}
                    createGroupModalIsOpen={createGroupModalIsOpen}
                    createGroupModalHandleOpen={createGroupModalHandleOpen}
                    manageInvitesModalIsOpen={manageInvitesModalIsOpen}
                    manageInvitesModalHandleOpen={manageInvitesModalHandleOpen}
                    createGroupModalHandleOpen={createGroupModalHandleOpen}
                    manageSolicitationsModalIsOpen={
                      manageSolicitationsModalIsOpen
                    }
                    manageSolicitationsModalHandleOpen={
                      manageSolicitationsModalHandleOpen
                    }
                    hiddenExternalComponents={hiddenExternalComponents}
                  />
                  </Block> 
                  */

                  <Block
                    color={COLOR.BLOCK_LIGHT}
                    orientation={ORIENTATION.AFASTABLE}
                  >
                    {member.notifications && !hiddenExternalComponents && (
                      <Notifications
                        buttonText={ACTION.MORE_NOTIFICATIONS}
                        notification={member.notifications}
                      />
                    )}
                    {!hiddenExternalComponents
                      ? [
                          <InfiniteScroll
                            hasMore={hasMoreGroups}
                            loadAction={loadMoreGroups}
                            dataLength={groups.length}
                          >
                            <CardsGroup
                              title={PAGE_INFORMATION.MY_GROUPS}
                              arrayOfCards={groups}
                              perLine={NUMBER.FOUR}
                              groupButtonAction={
                                !hiddenExternalComponents
                                  ? [
                                      <ModalCall
                                        modalHeaderText={ACTION.CREATE_GROUP}
                                        pageImage={imageDefault}
                                        isOpen={createGroupModalIsOpen}
                                        handleIsOpen={
                                          createGroupModalHandleOpen
                                        }
                                        buttonText={ACTION.CREATE_GROUP}
                                        appearance={APPEARANCE.PRIMARY}
                                        position={POSITION.RIGHT}
                                        size={SIZE.MEDIUM}
                                      >
                                        <CreateGroupModal
                                          handleIsOpen={
                                            createGroupModalHandleOpen
                                          }
                                          actionHandleImage={handleImageChange}
                                          action={saveGroup}
                                          visibilityOptions={visibilityOptions}
                                          control={control}
                                          changeImage={changeImage}
                                          areasOfInterest={areasOfInterest}
                                        />
                                      </ModalCall>,
                                      <ModalCall
                                        modalHeaderText={
                                          PAGE_INFORMATION.MY_SOLICITATIONS
                                        }
                                        pageImage={
                                          member.img.length > 0
                                            ? member.img
                                            : imageDefault
                                        }
                                        isOpen={manageSolicitationsModalIsOpen}
                                        handleIsOpen={
                                          manageSolicitationsModalHandleOpen
                                        }
                                        buttonText={
                                          PAGE_INFORMATION.MY_SOLICITATIONS
                                        }
                                        appearance={APPEARANCE.OUTLINE_PRIMARY}
                                        position={POSITION.RIGHT}
                                        size={SIZE.MEDIUM}
                                        badgeValue={member.totalSolicitations}
                                      >
                                        <ManageSolicitationsModal
                                          groups={solicitations}
                                          actionDecline={cancelJoinSolicitation}
                                          loadMoreSolicitations={
                                            loadMoreSolicitations
                                          }
                                          hasMoreSolicitations={
                                            hasMoreSolicitations
                                          }
                                        />
                                      </ModalCall>,
                                      <ModalCall
                                        modalHeaderText={
                                          PAGE_INFORMATION.MY_INVITES
                                        }
                                        pageImage={
                                          member.img.length > 0
                                            ? member.img
                                            : imageDefault
                                        }
                                        isOpen={manageInvitesModalIsOpen}
                                        handleIsOpen={
                                          manageInvitesModalHandleOpen
                                        }
                                        buttonText={PAGE_INFORMATION.MY_INVITES}
                                        appearance={APPEARANCE.OUTLINE_PRIMARY}
                                        position={POSITION.RIGHT}
                                        size={SIZE.MEDIUM}
                                        badgeValue={member.totalInvites}
                                      >
                                        <ManageInvitesModal
                                          member={member}
                                          groups={invitations}
                                          hasMoreInvites={hasMoreInvites}
                                          action={inviteJoinSolicitation}
                                          actionDecline={declineInvitation}
                                          loadMoreInvites={loadMoreInvites}
                                        />
                                      </ModalCall>
                                    ]
                                  : []
                              }
                            />
                            ,
                            <CardsGroup
                              title={
                                recommended && recommended.length
                                  ? PAGE_INFORMATION.RECOMENDED_GROUPS
                                  : ""
                              }
                              arrayOfCards={recommended}
                              perLine={NUMBER.FOUR}
                            />
                          </InfiniteScroll>
                        ]
                      : [
                          <InfiniteScroll
                            loadAction={loadMoreGroups}
                            dataLength={groups.length}
                            hasMore={hasMoreGroups}
                          >
                            <CardsGroup
                              title={
                                groups && groups.length
                                  ? PAGE_INFORMATION.GROUPS
                                  : ""
                              }
                              arrayOfCards={groups}
                              perLine={NUMBER.FOUR}
                            />
                          </InfiniteScroll>
                        ]}
                  </Block>
                )
              },
              {
                id: PAGE_INFORMATION.POSTS,
                component: (
                  <Block
                    color={COLOR.BLOCK_LIGHT}
                    orientation={ORIENTATION.AFASTABLE}
                  >
                    <Timeline
                      communications={postItem}
                      group={group}
                      member={member}
                      loadMoreCommunications={loadMoreCommunications}
                      hiddenCreatePost={hiddenCreatePost}
                      hasMoreCommunications={hasMoreCommunications}
                    />
                  </Block>
                )
              },
              {
                id: PAGE_INFORMATION.PROFILE,
                component: <Text text={"Perfil"} />
              },
              {
                id: PAGE_INFORMATION.COURSES,
                component: <Text text={"Cursos"} />
              },
              {
                id: PAGE_INFORMATION.CONTACTS,
                component: <Text text={"Contatos"} />
              }
            ]}
          />
        </Tab>
      </Block>
    </>
  );
};
