import React from "react";
import { APPEARANCE, ACTION, SIZE } from "../../../constants";

import Text from "../../basicComponents/Text";
import ButtonModal from "../../basicComponents/ButtonModal";
import Spacing from "../../basicComponents/Spacing";
import { ModalBody, ButtonAtBottom } from "./styles";

export default ({ message, action }) => {
  return (
    <>
      <ModalBody>
        <Spacing size={SIZE.MEDIUM} />
        <Spacing size={SIZE.MEDIUM} />
        <Text size={SIZE.MEDIUM} text={message} />
      </ModalBody>
      <ButtonAtBottom>
        <ButtonModal
          text={ACTION.CONFIRM}
          onConfirm={action}
          appearance={APPEARANCE.PRIMARY}
        />
      </ButtonAtBottom>
    </>
  );
};
