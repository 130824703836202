import React from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import Img from "react-image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserActions } from "../../store/ducks/user";

import placeholder from "../../images/placeholder-group.png";
import PrivateCard from "./PrivateCard";
import { API_URL_IMAGES } from "../../utils/constants";

const Loader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={149}
    viewBox="0 0 400 149"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="26" rx="3" ry="3" width="200" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="104" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="120" rx="3" ry="3" width="100%" height="6" />
  </ContentLoader>
);

const ContainerLoader = styled.div`
  background: rgba(255, 255, 255, 0.3);
  padding: 10px;
`;

const CardNewGroup = ({
  group,
  history,
  toggleModalRestrict,
  toggleModalRestrictLitPass
}) => {
  const socialUser = JSON.parse(localStorage.getItem("socialUser"));
  const naRealUser = JSON.parse(localStorage.getItem("naRealUser"));

  const enterGroup = () => {
    if (socialUser) {
      history.push(`/beta/social/socialGroup/${group.groupCode}`);
    } else {
      if (naRealUser) {
        toggleModalRestrict(true, "group");
        return;
      }
      toggleModalRestrictLitPass(true, "group");
    }
  };

  const cuttingTextDescription = description => {
    const maxLength = 150;

    const hasSpace = description.slice(0, maxLength).lastIndexOf(" ") !== -1;

    if (description.length > maxLength) {
      if (hasSpace) {
        return `${description.slice(
          0,
          description.slice(0, maxLength).lastIndexOf(" ")
        )} `;
      }
      return `${description.slice(0, maxLength)} `;
    }
    return description;
  };
  const img = group.image || placeholder;
  return (
    <>
      <div className="slider_media">
        <Img
          src={img}
          onClick={() => enterGroup()}
          style={{ height: 160, width: "100%" }}
          loader={
            <ContainerLoader>
              <Loader />
            </ContainerLoader>
          }
        />
      </div>

      <div
        id={`grupo-${group.groupCode}`}
        role="presentation"
        className="slider_info"
        onClick={() => enterGroup()}
      >
        <div
          role="presentation"
          className="slider_title"
          onClick={() => enterGroup()}
        >
          <div className="overlay-cursos" style={{ width: 242, height: 122 }}>
            <div className="text-curso-overlay" style={{ top: "50%" }}>
              <p>
                {!socialUser && <PrivateCard />}
                {group.privacy && (
                  <i
                    data-tip="Grupo privado"
                    className="fa fa-lock"
                    style={{ color: "red", background: "transparent" }}
                  />
                )}
                {group.name.slice(0, 30)}
              </p>
              <p style={{ fontSize: 10 }}>
                {cuttingTextDescription(group.longDescription)}
              </p>
              <p style={{ fontSize: 8 }}>Ver Mais</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions.ProfileActions, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(CardNewGroup));
