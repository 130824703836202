import { version } from '../../package.json';

export const API_URL = process.env.REACT_APP_API_URL;
export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const AWS_URL = process.env.REACT_APP_URL_AWS;
export const ONESIGNAL_ID = process.env.REACT_APP_ONESIGNAL_ID;
export const SECRET = process.env.REACT_APP_KALTURA_SECRET;
export const KALTURA_USER_ID = process.env.REACT_APP_KALTURA_USER_ID;
export const KALTURA_SESSION_TYPE = process.env.REACT_APP_KALTURA_SESSION_TYPE;
export const KALTURA_PARTNER_ID = process.env.REACT_APP_KALTURA_PARTNER_ID;
export const KALTURA_EXPIRY = process.env.REACT_APP_KALTURA_EXPIRY;
export const KALTURA_PRIVILEGES = process.env.REACT_APP_KALTURA_PRIVILE;
export const KALTURA_SERVICE_URL = process.env.REACT_APP_KALTURA_SERVICE_URL;
export const VINDI_API_KEY = process.env.REACT_APP_VINDI_API_KEY;

export const API_URL_IMAGES = process.env.REACT_APP_API_URL.replace(
  ':8443',
  ''
);

export function addTextToBody() {
  return 'diogo';
}

export const VERSION = version;

export const headerConfig = {
  headers: new Headers({
    Accept: 'application/json',
    'Accept-Language': 'pt-br',
    'Content-Type': 'application/json'
  })
};

export const WEBEX_ENABLE_SSO = true;

export const WEBEX_CLIENT_ID =
  process.env.REACT_APP_MY_ENV === 'development'
    ? 'C0c5ef81d0165fc5e6e93ec4f1ccf76bedfcce59da7cd0dd34311695db7bad394'
    : 'C615d320188b83450ab56e4c3c520c285330907c4f6b85b4c08aca8913e06fefe';

export function randomNumber() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);
}

/* export const WATSON_URL =
  process.env.REACT_APP_ENV == "homolog"
    ? "https://saintpaul-orquestrador.mybluemix.net/"
    : "https://dev-saintpaul-orquestrador.mybluemix.net/";
 */

export const WATSON_URL = 'https://prod-saintpaul-orquestrador.mybluemix.net/';

// user: saintpaul
// pass: 633b850d-40a5-4ec8-a26a-3f593c59e2cb
export const watsonHeaderConfig = {
  Authorization:
    'Basic c2FpbnRwYXVsOjYzM2I4NTBkLTQwYTUtNGVjOC1hMjZhLTNmNTkzYzU5ZTJjYg==',
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

export const PAYMENT_KEY =
  '10001|CFBB0B512C589BCCFAA8CBB1996DEB46FEC773ADE082E4A48C93B76C8DB68711EEE89712B4DC01B35D04F6323D4658FD760B03C5C38FE866765A0E0880D13874B133F069029494F75FA8AE4F7B93B140FDE90A904D75AB825AD138CD096FB3BE46D1F361FE033700B223E536BEC30AC24E2D23F9DCCB95CBA2A3881E6F67237B61F8AAC3157EF6CC6D3FC9EADFD118EE1BEF043B22EF86C67945467AA8F3F001487FC281804654F40F6477306995865EDBE6DD43572A352B8FDB5B737D919324E648CDA7D04D2E39C8191FC5CEBA95C7C960CEA63AE6A1B151E6F2EA3354BCA97072DD144E1E2C85EABD7C0CE80B4650C48A0C9DDFF0C5EDFD3B7A8C680F4C99';

export const RECAPTCHA_KEY = '6LcEiawpAAAAAE2CiE8KZeaX0Dn9m1iMyZGlwiWu';

export const partnerGenial = [
  'MMCPLD',
  'MMCPLT',
  'MMCGLD',
  'MMCSIL',
  'SIMPRESSLITPASSSIL',
  'SIMPRESSLITPASSGLD',
  'SIMPRESSLITPASSPLT',
  'lastcallcvd'
];

export const positions = [
  { value: 'Advogado', label: 'Advogado' },
  { value: 'Analista', label: 'Analista' },
  { value: 'Analista Junior', label: 'Analista Junior' },
  { value: 'Analista Pleno', label: 'Analista Pleno' },
  { value: 'Analista Senior', label: 'Analista Senior' },
  { value: 'Assessor', label: 'Assessor' },
  { value: 'Assistente', label: 'Assistente' },
  { value: 'Auditor', label: 'Auditor' },
  { value: 'Auxiliar', label: 'Auxiliar' },
  { value: 'CEO', label: 'CEO' },
  {
    value: 'C-Level: CFO, CMO, CIO, CTO ou outro',
    label: 'C-Level: CFO, CMO, CIO, CTO ou outro'
  },
  { value: 'Comprador', label: 'Comprador' },
  { value: 'Conselheiro (a)', label: 'Conselheiro (a)' },
  { value: 'Consultor', label: 'Consultor' },
  { value: 'Controller', label: 'Controller' },
  { value: 'Coordenador', label: 'Coordenador' },
  { value: 'Diretor', label: 'Diretor' },
  { value: 'Economista', label: 'Economista' },
  { value: 'Empreendedor (a)', label: 'Empreendedor (a)' },
  { value: 'Engenheiro', label: 'Engenheiro' },
  { value: 'Especialista', label: 'Especialista' },
  { value: 'Estagiário', label: 'Estagiário' },
  { value: 'Gerente', label: 'Gerente' },
  {
    value: 'Head de unidades de negócio',
    label: 'Head de unidades de negócio'
  },
  {
    value: 'Herdeiro (a) / Sucessor (a)',
    label: 'Herdeiro (a) / Sucessor (a)'
  },
  { value: 'Operador', label: 'Operador' },
  { value: 'Operador Junior', label: 'Operador Junior' },
  { value: 'Operador Pleno', label: 'Operador Pleno' },
  { value: 'Operador Senior', label: 'Operador Senior' },
  { value: 'Outros', label: 'Outros' },
  { value: 'Presidente', label: 'Presidente' },
  { value: 'Secretária (o)', label: 'Secretária (o)' },
  { value: 'Sócio/ Dono / Proprietário', label: 'Sócio/ Dono / Proprietário' },
  { value: 'Superintendente', label: 'Superintendente' },
  { value: 'Supervisor', label: 'Supervisor' },
  { value: 'Tesoureiro', label: 'Tesoureiro' },
  { value: 'Trainee', label: 'Trainee' },
  { value: 'Vendedor', label: 'Vendedor' },
  { value: 'Vice-presidente', label: 'Vice-presidente' }
];

export const graduationsTypes = [
  { value: 'Bacharelado', label: 'Bacharelado' },
  { value: 'Tecnólogo', label: 'Tecnólogo' },
  { value: 'Licenciatura', label: 'Licenciatura' }
];

export const posGraduationsTypes = [
  { value: 'Especialização', label: 'Especialização' },
  { value: 'Aperfeiçoamento', label: 'Aperfeiçoamento' },
  { value: 'MBA', label: 'MBA' },
  { value: 'Mestrado Acadêmico', label: 'Mestrado Acadêmico' },
  { value: 'Mestrado Profissional', label: 'Mestrado Profissional' },
  { value: 'Doutorado', label: 'Doutorado' },
  { value: 'PHD', label: 'PHD' }
];

export const graduacao = [
  { value: 'Administração de Empresas', label: 'Administração de Empresas' },
  { value: 'Arquitetura', label: 'Arquitetura' },
  { value: 'Atuárias', label: 'Atuárias' },
  { value: 'Biologia', label: 'Biologia' },
  { value: 'Ciência da Computação', label: 'Ciência da Computação' },
  {
    value: 'Ciências Contábeis e Atuárias',
    label: 'Ciências Contábeis e Atuárias'
  },
  { value: 'Ciências Econômicas', label: 'Ciências Econômicas' },
  { value: 'Comércio Exterior', label: 'Comércio Exterior' },
  { value: 'Comunicação Social', label: 'Comunicação Social' },
  { value: 'Desenho Industrial', label: 'Desenho Industrial' },
  { value: 'Design de Interiores', label: 'Design de Interiores' },
  { value: 'Designer de Moda', label: 'Designer de Moda' },
  { value: 'Design Gráfico', label: 'Design Gráfico' },
  { value: 'Direito', label: 'Direito' },
  { value: 'Economia', label: 'Economia' },
  { value: 'Engenharias', label: 'Engenharias' },
  { value: 'Ensino Médio', label: 'Ensino Médio' },
  { value: 'Estatística', label: 'Estatística' },
  { value: 'Física', label: 'Física' },
  { value: 'Fisioterapia', label: 'Fisioterapia' },
  { value: 'Gastronomia', label: 'Gastronomia' },
  {
    value: 'Gerenciamento de Pequenas e Médias empresas',
    label: 'Gerenciamento de Pequenas e Médias empresas'
  },
  { value: 'Gestão Bancária', label: 'Gestão Bancária' },
  {
    value: 'Gestão de Negócios e Finanças',
    label: 'Gestão de Negócios e Finanças'
  },
  { value: 'Gestão de Recursos Humanos', label: 'Gestão de Recursos Humanos' },
  { value: 'Gestão Financeira', label: 'Gestão Financeira' },
  { value: 'Hotelaria', label: 'Hotelaria' },
  { value: 'Informática', label: 'Informática' },
  { value: 'Jornalismo', label: 'Jornalismo' },
  { value: 'Letras', label: 'Letras' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Matemática', label: 'Matemática' },
  { value: 'Medicina', label: 'Medicina' },
  { value: 'Odontologia', label: 'Odontologia' },
  { value: 'Outros', label: 'Outros' },
  { value: 'Psicologia', label: 'Psicologia' },
  { value: 'Publicidade e Propaganda', label: 'Publicidade e Propaganda' },
  { value: 'Relações Internacionais', label: 'Relações Internacionais' },
  { value: 'Relações Públicas', label: 'Relações Públicas' },
  { value: 'Secretariado', label: 'Secretariado' },
  { value: 'Serviço Social', label: 'Serviço Social' },
  { value: 'Sistemas', label: 'Sistemas' },
  { value: 'Tecnólogo', label: 'Tecnólogo' },
  { value: 'Turismo', label: 'Turismo' },
  { value: 'Zootecnia', label: 'Zootecnia' }
];

export const estados = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' }
];

export const ONBOARDING_PAGES = {
  WELCOME: 'WELCOME',
  PERSONAL_DATA: 'PERSONAL_DATA',
  FORMATIONS_EXPERIENCES: 'FORMATIONS_EXPERIENCES',
  GOALS: 'GOALS',
  INTERESTS: 'INTERESTS',
  PRIVACY: 'PRIVACY',
  PERSONALIZE: 'PERSONALIZE',
  LISTBOX: 'LISTBOX',
  MGM: 'MGM'
};

export const ONBOARDING_BUTTON_PAGES = {
  WELCOME: 'Boas vindas',
  PERSONAL_DATA: 'Dados Pessoais',
  FORMATIONS_EXPERIENCES: 'Formação e Experiência',
  GOALS: 'Metas de Estudos',
  INTERESTS: 'Interesses',
  PRIVACY: 'Privacidade',
  PERSONALIZE: 'Personalizar',
  LISTBOX: 'LISTBOX'
};

export const DYNAMIC_QUESTION_TYPE = {
  TEXT: 'TEXT',
  RANGE: 'RANGE',
  TOGGLE: 'TOGGLE',
  SELECT: 'SELECT',
  FILE: 'FILE'
};

export const SELECT_TYPES = {
  OFFICES: 'OFFICES',
  TEACHING_INSTITUTIONS: 'TEACHING_INSTITUTIONS',
  GRADUATIONS_COURSES: 'GRADUATIONS_COURSES',
  POSTGRADUATIONS_COURSES: 'POSTGRADUATIONS_COURSES',
  GRADUATIONS_TYPES: 'GRADUATIONS_TYPES',
  POSTGRADUATIONS_TYPES: 'POSTGRADUATIONS_TYPES'
};

export const DEFAULT_VALUES = {
  LIST: [],
  FUNCTION: () => { },
  STRING: '',
  NUMBER: 0
};
