import React, { useState, useEffect, memo } from "react";
import Img from "react-image";
import PrivateCard from "../Home/PrivateCard";
import CardImgDefault from "./CardImgDefault";

const CardNovoCurso = ({ result, goToCourse }) => {
  const freeUser = JSON.parse(localStorage.getItem("freeUser"));

  const showLock = (freeUser && !result.freeCourse) || result.lockedCourse;

  let modalRestrictLitPass = false;
  if (result.lockedCourse && !freeUser) {
    modalRestrictLitPass = true;
  }
  const [urlImg, setUrlImg] = useState(null);

  const imgUrl = result.courseImageUrl;

  const checkImage = (path, onerror) => {
    const img = new Image();
    img.src = path;
    img.onerror = onerror;
  };

  const errorImgExists = () => {
    setUrlImg(null);
  };

  useEffect(() => {
    function checkImg() {
      const urlImgAux = result.courseImageUrl;
      setUrlImg(urlImgAux);

      checkImage(urlImgAux, errorImgExists);
    }
    checkImg();
  }, [result.idCourse]);

  return (
    <div
      role="button"
      id={`curso-${result.idCourse}`}
      onClick={() =>
        goToCourse(showLock, result, modalRestrictLitPass)
      }
      className="card-wrapper-p"
    >
      <div className="card">
        {urlImg ? (
          <Img className="card-img-top" src={imgUrl} alt="course cape" />
        ) : (
          <CardImgDefault courseName={result.curso} />
        )}
        <div className="card-body" style={{ padding: ".6rem 1rem" }}>
          <h5 className="card-subtitle mb-2 text-muted">
            {showLock && <PrivateCard fontSize={20} />} {result.curso}
          </h5>

          <p className="card-text">{result.newCourse ? "Curso" : ""}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(CardNovoCurso);
