import React from "react";
import {
  PAGE_INFORMATION,
  COLOR,
  APPEARANCE,
  ORIENTATION,
  FONT_TYPE,
  SIZE,
  NUMBER
} from "../../../../constants";
import IconInfo from "../../../../LitAtomicComponents/Info/IconInfo";
import Tab from "../../../../LitAtomicComponents/basicComponents/Tab";
import NavTab from "../../../../LitAtomicComponents/basicComponents/NavTab";
import Block from "../../../../LitAtomicComponents/basicComponents/Block";
import Text from "../../../../LitAtomicComponents/basicComponents/Text";
import FeedGroup from "../../../../LitAtomicComponents/Contents/FeedGroup";
import {} from "./styles";

export default ({ notifications, feedItems, hasNextPage, nextPage }) => {
  return (
    <>
      <Block color={COLOR.BLOCK_LIGHT} orientation={ORIENTATION.AFASTABLE}>
        <Tab id={PAGE_INFORMATION.FEED}>
          <NavTab
            appearance={APPEARANCE.FEED}
            header={[
              {
                id: PAGE_INFORMATION.FEED,
                component: <IconInfo title={PAGE_INFORMATION.FEED} />
              },
              {
                id: PAGE_INFORMATION.COURSES,
                component: <IconInfo title={PAGE_INFORMATION.COURSES} />
              }
            ]}
            content={[
              {
                id: PAGE_INFORMATION.FEED,
                component: (
                  <FeedGroup
                    feedItems={feedItems}
                    notifications={notifications}
                    hasNextPage={hasNextPage}
                    nextPage={nextPage}
                    perLine={NUMBER.THREE}
                  />
                )
              },
              {
                id: PAGE_INFORMATION.COURSES,
                component: (
                  <Text
                    text={PAGE_INFORMATION.COURSES}
                    fontType={FONT_TYPE.BOLD}
                    size={SIZE.EXTRA_LARGE}
                  />
                )
              }
            ]}
          />
        </Tab>
      </Block>
    </>
  );
};
