import React, {useMemo} from "react";
import _ from 'lodash'
import { TopBar } from "./styles";

export default ({ size, child, appearance }) => {

  const bg = useMemo(() => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    const tenant = localStorage.getItem('tenant');
    const { headerBackgroundColor } = _.get(
      settings,
      `${tenant}`,
      {}
    );

    return headerBackgroundColor;
  }, [localStorage])


  return (
    <>
      <TopBar bg={bg} size={size} appearance={appearance}>
        {child}
      </TopBar>
    </>
  );
};
