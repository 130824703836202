import React from "react"


import * as Styles from './styles'


const LOGO_LIT = require("../../../../images/lit.png")

const Navbar = ({...rest}) => {
  return (
    <Styles.Nav className='navbar' {...rest}>
      <a className='navbar-brand'>
        <img src={LOGO_LIT} alt='Logo LIT' width="120" />
      </a>
    </Styles.Nav>
  )
}

export default Navbar
