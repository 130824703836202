import React from "react";
import Screen from "./screen";

export default function MeetingTabPanel() {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const onSelectedTabChanged = (event, tabIndex) =>
    setSelectedTabIndex(tabIndex);

  return (
    <Screen
      selectedTabIndex={selectedTabIndex}
      onSelectedTabChanged={onSelectedTabChanged}
    />
  );
}
