import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ChatIcon from "@material-ui/icons/Chat";
import Divider from "@material-ui/core/Divider";

import TabPanel from "../TabPanel";
import MeetingChat from "../MeetingChat";
import MeetingMembers from "../MeetingMembers";
import { TabPanelContainer } from "./styles";

export default React.memo(function MeetingTabPanel({
  selectedTabIndex,
  onSelectedTabChanged
}) {
  const classes = makeStyles(theme => {
    return {
      root: {
        backgroundColor: theme.palette.background.paper,
        width: 270
      }
    };
  })();

  return (
    <TabPanelContainer
      display="flex"
      flexDirection="column"
      style={{ background: "white" }}
      className={classes.root}
    >
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTabIndex}
          onChange={onSelectedTabChanged}
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab icon={<PersonIcon />} style={{ minWidth: 50 }} />
          <Tab icon={<ChatIcon />} style={{ minWidth: 50 }} />
        </Tabs>
      </AppBar>

      <Divider />

      <TabPanel value={selectedTabIndex} index={0}>
        <MeetingMembers />
      </TabPanel>

      <TabPanel value={selectedTabIndex} index={1}>
        <MeetingChat />
      </TabPanel>
    </TabPanelContainer>
  );
});
