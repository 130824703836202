import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Tooltip from "../Tooltip";

const DivBugReport = styled.div`
  min-height: 51px;
  width: 100%;
  background-color: #414042;
`;

const DivBarraGeral = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
`;

const IconClose = styled.i`
  padding-right: 10px;
  cursor: pointer;
`;

const DivBtnGroup = styled.div`
  padding: 10px;
`;

const BtnLater = styled.button`
  margin-right: 15px;
`;

const BarraPi = ({ fechar }) => (
  <DivBugReport>
    <Tooltip id="barraPI" />
    <div className="container-fluid">
      <DivBarraGeral>
        <div className="d-flex align-items-center">
          <Text
            data-for="barraPI"
            data-tip="Ainda não personalizou como aprender? Faça agora"
          >
            <IconClose className="fa fa-times" onClick={fechar} /> Quer
            configurar esse curso de acordo com seu estilo de aprendizagem?
          </Text>
        </div>

        <DivBtnGroup>
          <BtnLater className="btn btn-white-outline btn-sm" onClick={fechar}>
            Perguntar mais tarde
          </BtnLater>

          <Link to="/onboarding" className="btn btn-blue btn-sm">
            Sim, quero
          </Link>
        </DivBtnGroup>
      </DivBarraGeral>
    </div>
  </DivBugReport>
);

export default BarraPi;
