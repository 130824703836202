import React from "react"
import { connect } from "react-redux"
import Select from "react-select"
import PropTypes from "prop-types"
import moment from "moment"
import "moment/locale/pt-br"

import api from "../../../utils/api"

class ModalShare extends React.Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    idGroup: "",
    sharedPlace: "doubts",
    sharedText: "",
    groups: [],
  }

  componentDidMount() {
    this.getGroupsOwner()
  }

  getGroupsOwner = async () => {
    const idProfile = localStorage.getItem("idProfile")
    try {
      const response = await api.get(`showGroupOwner/${idProfile}`)
      this.setState({ groups: response })
    } catch (error) {
      console.log(error)
    }
  }

  sendPost = () => {
    const { selected, close } = this.props
    const { sharedText, idGroup, sharedPlace } = this.state
    const { router } = this.context
    const isDoubts = sharedPlace === "doubts"
    const url = isDoubts ? `newDoubtsPost` : `newPost`
    const date = moment.utc()
    const data = {
      content: sharedText,
      memberPosting: {
        idGroupMember: localStorage.getItem("idMember"),
      },
      removed: false,
      linkMedia: selected.url,
    }
    const body = isDoubts
      ? {
          ...data,
          datePostDoubts: date,
          disciplineCode: selected.idDiscipline,
        }
      : {
          ...data,
          datePost: date,
          group: {
            idGroup,
          },
        }

    api
      .post(url, body)
      .then(() => {
        if (isDoubts) {
          document.querySelector("a[href='#duvidas']").click()
          close()
        } else {
          router.history.push(`/grupo/${idGroup}`)
        }
      })
      .catch(error => console.log(error))
  }

  render() {
    const { close } = this.props
    const { idGroup, sharedText, sharedPlace, groups } = this.state
    return (
      <div className="modal-content" id="modalGroup">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Compartilhar anexo
          </h5>
        </div>
        <div className="modalBody-container">
          <div className="modal-body" id="modalShare">
            <div className="form-group d-flex justify-content-around">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  checked={sharedPlace === "doubts"}
                  value="doubts"
                  type="radio"
                  onChange={e => this.setState({ sharedPlace: e.target.value })}
                />{" "}
                Compartilhar nas dúvidas
              </label>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  value="group"
                  checked={sharedPlace === "group"}
                  type="radio"
                  onChange={e => this.setState({ sharedPlace: e.target.value })}
                />{" "}
                Compartilhar nos grupos
              </label>
            </div>
            {sharedPlace === "group" && (
              <div className="form-group">
                <label>Selecione o grupo em que deseja compartilhar o anexo</label>
                <Select
                  name="groups"
                  onChange={option => this.setState({ idGroup: option.idGroup })}
                  value={groups.filter(group => group.idGroup === idGroup)}
                  autoFocus
                  getOptionLabel={opt => opt.groupName}
                  getOptionValue={opt => opt.idGroup}
                  options={groups}
                  placeholder="Escolha um grupo"
                />
              </div>
            )}
            <div className="form-group">
              <label>Descrição compartilhamento</label>
              <textarea
                type="text"
                className="form-control"
                rows="5"
                id="inputGroupName"
                value={sharedText}
                onChange={evt => this.setState({ sharedText: evt.target.value })}
                maxLength="250"
                required
                placeholder="Insira um texto para ser enviado junto com o anexo"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-modal__cancel "
            style={{ width: 141.41, fontWeight: 600 }}
            onClick={close}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-blue"
            style={{ width: 141.41, fontWeight: 600 }}
            onClick={this.sendPost}
          >
            Compartilhar
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selected: state.course.selected,
})

export default connect(mapStateToProps)(ModalShare)
