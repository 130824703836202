import styled, { css } from "styled-components";
import { Nav } from "react-bootstrap";
import { APPEARANCE } from "../../../constants";

export const TabLink = styled(Nav.Item)`

  flex: 1;
  
  ${props => (props.appearance === APPEARANCE.FEED) && css`
    flex: initial;
    min-width: 200px;
    @media screen and (max-width: 767px) {
      min-width: 50%;
    }
  `}
  

`;

export const AreaLink = styled(Nav.Link)`
  
  border-bottom: 5px solid var(--navtab-border);
  label {
    font-size: 12px;
    font-weight: 500;
  }
  &.active {
    border-bottom: 5px solid var(--navtab-active-border);
  }
  
`;
