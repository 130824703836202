import React from "react";
import { UnorderedList } from "./styles";

export default ({ grid, componentToRender, ref }) => {
  return (
    <>
      <UnorderedList ref={ref} grid={grid}>{componentToRender}</UnorderedList>
    </>
  );
};
