/* eslint-disable react/no-array-index-key */
import React, { useMemo } from "react";
import _ from "lodash";

import TreeItem from "../TreeItem";

import { Container } from "./styles";

function CourseTree({ level, openCourseProgress, closeCourseProgress, data, objectsList, course, aboutProcess = false, idCourseParam = null }) {

  const objectsWithAbout = useMemo(() => {

    if(!data || !data.length){
      return [];
    }

    if(!aboutProcess){
      return data;
    }

    const courseType = _.get(course, 'type');

    if(courseType === "TRAIL"){
      // insert about trail as object
      const aboutTrail = _.get(course, 'about.0', null);
      data.unshift({
        ...aboutTrail, 
        type: "ABOUT"
      });
      const objects = data.map(item => {
        const type = _.get(item, 'type', null)
        const about = _.get(item, 'about.0', null);

          // insert children about
          const childrenCourse = _.get(item, 'children', []);
          // removing about from children
          const childrenWithoutAbout = childrenCourse.filter(child => !child.tags.includes("ABOUT") && child.type !== "KNOW_MORE");
          if(type === "COURSE"){
            const itemWithAbout = {
              ...about, 
              type: "ABOUT",
            }
            childrenWithoutAbout.unshift(itemWithAbout);
          }
    
          return {
            ...item,
            children: childrenWithoutAbout,
          }
      });
      const firstAbout = objects.find(o => o.type === "ABOUT"|| o.tags.includes("ABOUT"))
      const objectsWithAbout = objects.filter(o => o.type !== "ABOUT" || (o.tags && !o.tags.includes("ABOUT")))
      return [firstAbout, ...objectsWithAbout];
    } 

    // removing about from children
    const childrenWithoutAbout = data.filter(item => !item.tags.includes("ABOUT") && item.type !== "KNOW_MORE");
  
    // adding about as object for single course
    const about = _.get(course, 'about.0', null);
    childrenWithoutAbout.unshift({
      ...about, 
      type: "ABOUT",
    });

    return childrenWithoutAbout;
    
  }, [data, course, aboutProcess]);

  return (
    <Container level={level}>
      {/* TODO: The children need to know prev and next sibilings. We need to do that using store and not passing as props. */}
      {objectsWithAbout && objectsWithAbout.length > 0 && objectsWithAbout.map((child, i, array) => {
        return (
          <TreeItem
            data={{ ...child, index: i, listContent: array }}
            level={level}
            key={i}
            openCourseProgress={openCourseProgress}
            closeCourseProgress={closeCourseProgress}
            objectsList={objectsList}
            course={course}
            idCourseParam={idCourseParam}
          />
        )
      })}
    </Container>
  );
}

export default CourseTree;
