import React from "react"
import ContentLoader from "react-content-loader"

export function LoaderPost({ size }) {
  return Array(size)
    .fill()
    .map(() => {
      return (
        <div key={Math.random()} className="post-card" style={{ padding: "1em", marginBottom: "10px" }}>
          <ContentLoader
            height={130}
            width={400}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#bbb9b9"
          >
            <rect x="70" y="15" rx="4" ry="4" width="117" height="3" />
            <rect x="70" y="35" rx="3" ry="3" width="85" height="3" />
            <rect x="0" y="80" rx="3" ry="3" width="350" height="3" />
            <rect x="0" y="100" rx="3" ry="3" width="380" height="3" />
            <rect x="0" y="120" rx="3" ry="3" width="201" height="3" />
            <circle cx="39" cy="30" r="22" />
          </ContentLoader>
        </div>
      )
    })
}
