/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react"


class ParticiparAulaOnline extends Component {
  state = {
    visibleBody: false,
  }

  componentDidMount() {
  }

  changeVisible = () => {
    const { visibleBody } = this.state
    this.setState({ visibleBody: !visibleBody })
  }

  render() {
    const { visibleBody } = this.state
    return (
      <div className="box-tabs">
        <div>
          <div className="row cursor-pointer" onClick={this.changeVisible}>
            <div className="col-12 headerTab">
              <h5 className="d-inline-block" style={{ fontSize: "1.3em" }}>
                6. Como posso participar da Aula Online Ao Vivo?
              </h5>
              <button id="btn-tab" type="button" className="btn btn-icon ml-auto" >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <div className="row">
              <div className="col">
                 <p>
                    Ficamos felizes que queira participar da nossa aula ao vivo exclusiva para alunos e alunas do LIT!! 
                    <br />
                    Para obter mais informações e marcar na sua agenda as próximas aulas, <a href="https://hub.lit.com.br/participe-da-nossa-aula" className="link-destaque">clique aqui</a>.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ParticiparAulaOnline;
