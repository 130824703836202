import styled from "styled-components";

export const Card = styled.div`
  font-weight: bold;
  font-size: 1.1em;
  margin: .7rem 0rem;
  border: 1px solid #ddd;
`;

export const Text = styled.p`
  white-space: pre-line;
`;

export const FloatingMsg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 86.2%;
  width: 97.1%;
  background-color: #eee;
  position: absolute;
  opacity: 0.9;
  z-index: 100;
`;

export const Objeto = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;

export const ObjectDescription = styled.div`
  margin: 30px 0;
  background: white;
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  padding: 20px 15px;
`;

export const Container = styled.div`
    margin: 40px 0;

    h4{
        margin-bottom: 40px;
    }
`

export const ItemContainer = styled.div`
    padding: 0px;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    background: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, .1);
    border-radius: 5px;
    margin-bottom: 15px;
`

export const ContainerIcon = styled.div`
    padding: 10px;
    background-color: #27AAE1;
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-right: 10px;
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-basis: 8%;
    justify-content: center;
`

export const ItemDescription = styled.div`
    padding: 20px 0;
    flex-basis: 85%;
    display: flex;
    color: #4A5057;
`

export const BtnDownload = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    i{
        color: #27AAE1
    }
`