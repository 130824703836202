import React, { memo, useState } from "react"
import styled from "styled-components"
import ModalPolitica from "../Modals/ModalPolitica"
import ModalTermos from "../Modals/ModalTermos"

import {VERSION} from '../../utils/constants'

const Versao = styled.div`
  text-align: left;
  padding-left: 10px;
  margin-top: 13px;
`

const TextWhite = styled.p`
  color: #fff;
`

const SubFooter = () => {
  const [modalPolitica, setModalPolitica] = useState(false)
  const [modalTermos, setModalTermos] = useState(false)

  return (
    <aside className="container-fluid copyright">
      <div className="row justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col">
              <Versao id="versao">
                <TextWhite>v{VERSION}</TextWhite>
              </Versao>
            </div>
            <div className="col">
              <TextWhite>Direitos de Cópia &copy; 2017 Lit, Inc.</TextWhite>
            </div>
            <div className="col">
              <a
                role="button"
                data-tip="Acesse os Termos e Condições do LIT"
                onClick={() => setModalTermos(true)}
              >
                <TextWhite>Termos</TextWhite>
              </a>
            </div>
            <div className="col">
              <a
                role="button"
                data-tip="Conheça nossa Politica e Privacidade"
                onClick={() => setModalPolitica(true)}
              >
                <TextWhite>Política de Privacidade</TextWhite>
              </a>
            </div>
            <div className="col">
              <TextWhite>Propriedade Intelectual</TextWhite>
            </div>
          </div>
        </div>
      </div>
      <ModalPolitica visible={modalPolitica} onClose={() => setModalPolitica(false)} />
      <ModalTermos visible={modalTermos} onClose={() => setModalTermos(false)} />
    </aside>
  )
}
export default memo(SubFooter)
