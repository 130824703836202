import React, { useState, memo } from "react"

const ToggleButton = ({ idTrackSuggestion, handleSelect, grau, name, id }) => {
  const [selected, setSelected] = useState(false)

  const _handleOnPress = () => {
    setSelected(!selected)
    handleSelect(idTrackSuggestion, !selected)
  }

  if(!grau) {
    return (
      <label
        id={id} 
        className={`btn-limit btn-grey interesses-space ${grau !== "" && "active"}`}
        onClick={() => _handleOnPress()}
      >
        {name}
      </label>
    )
  }

  return (
    <label
      style={{borderRadius: '.25rem'}}
      className={`btn btn-grey interesses-space ${grau !== "" && "active"}`}
      id={id}
      onClick={() => _handleOnPress()}
    >
      <input type="checkbox" /> {name} <span className="badge">{grau}</span>
    </label>
  )
}

export default memo(ToggleButton)
