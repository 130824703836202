import React, { useState, useEffect } from "react";
import _ from "lodash";
import Screen from "./screen";

export default ({
  pageImage,
  members,
  group,
  member,
  code,
  modalHeaderText,
  isOpen,
  closeModal,
  loadCommunicationReactions,
  loadReactionPeople,
}) => {
  const [reactionsList, setReactionsList] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  async function loadReactions() {
    try{
      const reactions = await loadCommunicationReactions(code);

      reactions.forEach(reaction => {
        reaction.page = 1;
      });

      setReactionsList(reactions);
    }
    catch(error) {}
  }

  async function loadMoreReactions(reactionType) {
    try{
      const reactionSelected = reactionsList.find(reaction => reaction.type === reactionType);

      reactionSelected.page += 1; 
      
      const list = await loadReactionPeople(code, reactionType, reactionSelected.page);

      reactionSelected.people = [...reactionSelected.people, ...list];

      const reactionsListUpdated = reactionsList;

      reactionsListUpdated.forEach(reaction => {
        if(reaction.type === reactionType)
            reaction = reactionSelected;
      });

      setReactionsList(reactionsListUpdated);
      forceUpdate();
    }
    catch(error) {}
  }

  useEffect(() => {
   loadReactions();
  }, []);

  return (
    <>
      <Screen
        code={code}
        group={group}
        groupedList={members}
        pageImage={pageImage}
        member={member}
        modalHeaderText={modalHeaderText}
        isOpen={isOpen}
        closeModal={closeModal}
        reactionsList={reactionsList}
        loadMoreReactions={loadMoreReactions}
      />
    </>
  );
};
