import React from "react"
import PropTypes from "prop-types"
import { toast } from 'react-toastify'
import Simplert from "react-simplert"
import Lightbox from "react-image-lightbox"
import Modal from "react-responsive-modal"
import EditGroupForm from "./Forms/EditGroupForm"
import CropPictureForm from "./Forms/CropPictureForm"
import Tooltip from "../../Tooltip"
import placeholder from "../../../images/placeholder-group.png"
import { API_URL } from "../../../utils/constants"
import api from "../../../utils/api"
import { toastErrorAndWarningOptions, toastDefaultOptions } from '../../../utils/functions'

class Main extends React.Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  state = {
    groupDetail: {},
    modalOpen: false,
    cropModal: false,
    post: "",
    lightboxIsOpen: false,

    file: "",
    imagePreviewUrl: "",
    ext: "",
    alert: false,
    cutDescription: "",
    maxLength: 150,
    showDescription: false,
    hasSpaceDescription: false,
  }

  componentDidMount() {
    this._fetchGroupDetail()
  }

  _fetchGroupDetail = async () => {
    const { idGroup } = this.props
    try {
      const response = await api.get(`getGroupById/${idGroup}`)
      this.setState({ groupDetail: response })
      this.cuttingTextDescription(response.description)
    } catch (error) {
      console.log(error)
    }
  }

  joinGroup = async () => {
    const idProfile = localStorage.getItem("idProfile")
    const { idGroup, groupStatus, onJoinPublic } = this.props
    try {
      await api.get(`joinGrupWithIdProfile/${idProfile}/${idGroup}`)
      if (groupStatus.privacity) {
        this.setState({ alert: true })
      } else {
        onJoinPublic()
      }
    } catch (error) {
      console.log(error)
    }
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true })
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false })
  }

  openCrop = () => {
    this.setState({ cropModal: true })
  }

  closeCrop = () => {
    this.setState({ cropModal: false })
  }

  removeFromGroup = async idProfile => {
    try {
      const { idGroup } = this.props
      const { router } = this.context
      await api.get(`newRecuseInvite/${idGroup}/${idProfile}`)
      router.history.push("/timeline")
    } catch (error) {
      console.log(error)
    }
  }

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    })
  }

  createPost = () => {
    const { post, file, ext } = this.state
    const { groupStatus, idGroup, getPosts } = this.props
    const idGroupMember =
      groupStatus.typeMember === 1 ? localStorage.getItem("idOwner") : localStorage.getItem("idMember")

    const currentDate = new Date()

    const data = {
      content: post,
      datePost: currentDate,
      removed: 0,
      memberPosting: {
        idGroupMember,
      },
      group: {
        idGroup,
      },
    }
    api
      .post("newPost", data)
      .then(response => {
        console.log(response)
        if (file !== null && file !== "") {
          this.sendFile(response.idTimeLine, ext)
        } else {
          this.setState({ post: "" })
          getPosts(idGroup)
          toast('Publicação criada com sucesso.', { ...toastDefaultOptions, closeButton: false });
        }
      })
      .catch(error => {
        console.log(error)
        toast.error(error.response.data.message, { ...toastErrorAndWarningOptions, closeButton: false });
      })
  }

  generateMimeTypeHeader = () => {
    const { file } = this.state
    const { type } = file
    console.log(type)
    return {
      headers: {
        "Content-Type": type,
      },
    }
  }

  sendFile = async (idPost, ext) => {
    try {
      const { file } = this.state
      const options = this.generateMimeTypeHeader()
      const response = await api.post(`uploadImgPost/${idPost}/${ext}`, file, options)
      console.log("Arquivo enviado: ", response)
      this.setState({
        file: null,
        imagePreviewUrl: null,
        ext: "",
      })
      this.updateWithAttachment(idPost, ext)
    } catch (error) {
      console.log(error)
    }
  }

  updateWithAttachment = async (idPost, ext) => {
    const { post } = this.state
    const { groupStatus, idGroup, getPosts } = this.props
    const idGroupMember =
      groupStatus.typeMember === 1 ? localStorage.getItem("idOwner") : localStorage.getItem("idMember")
    const currentDate = new Date()

    try {
      const data = {
        content: post,
        idTimeLine: idPost,
        datePost: currentDate,
        linkMedia: `${API_URL}downdImgPost/${idPost}/${ext}`,
        memberPosting: {
          idGroupMember,
        },
        group: {
          idGroup,
        },
      }
      await api.put("updatePost", data)
      console.log("att com arquivo")
      getPosts()
      this.setState({
        post: "",
      })
    } catch (error) {
      console.log(error)
    }
  }

  _handleImageChange = e => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    if (file === undefined) return
    console.log(file.type)
    if (
      !["jpg", "mp4", "mp3", "pdf", "xlsx", "xls", "doc", "docx", "ppt", "pptx"].includes(
        file.type.split("/")[1]
      )
    ) {
      alert("Extensão de arquivo não suportada!")
      return
    }

    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
        ext: file.type.split("/")[1],
      })
    }

    reader.readAsDataURL(file)
  }

  cuttingTextDescription = description => {
    if (!description) {
      this.setState({
        cutDescription: description,
        hasSpaceDescription: false,
      })
      return
    }
    const { maxLength } = this.state

    const hasSpace = description.slice(0, maxLength).lastIndexOf(" ") !== -1

    if (description.length > maxLength) {
      if (hasSpace) {
        this.setState({
          cutDescription: `${description.slice(0, description.slice(0, maxLength).lastIndexOf(" "))} `,
          hasSpaceDescription: true,
        })
      } else {
        this.setState({
          cutDescription: `${description.slice(0, maxLength)} `,
          hasSpaceDescription: true,
        })
      }
    } else {
      this.setState({
        cutDescription: description,
        hasSpaceDescription: false,
      })
    }
  }

  updateRelevantData = () => {
    this._fetchGroupDetail()
  }

  render() {
    const {
      imagePreviewUrl,
      ext,
      modalOpen,
      lightboxIsOpen,
      file,
      showDescription,
      cutDescription,
      hasSpaceDescription,
      post,
      cropModal,
      alert,
      groupDetail,
    } = this.state
    const { groupStatus } = this.props
    const { router } = this.context
    let $imagePreview = null

    if (imagePreviewUrl && ext === "jpg") {
      $imagePreview = (
        <div>
          <div
            onClick={() => {
              this.setState({ lightboxIsOpen: true })
            }}
            role="button"
          >
            <img
              className="img-thumbnail"
              alt="Avatar"
              style={{ width: 75, height: 75 }}
              src={imagePreviewUrl}
            />
          </div>
          {lightboxIsOpen && <Lightbox mainSrc={imagePreviewUrl} onCloseRequest={this.closeLightbox} />}
        </div>
      )
    } else if (imagePreviewUrl) {
      $imagePreview = <p>{file.name}</p>
    } else {
      $imagePreview = <div className="previewText">*Extensões de anexo: pdf, mp4, jpg e mp3</div>
    }

    return (
      <div className="card mb-2">
        <div className="card-header with-flex">
          <span className="titulo">
            {groupStatus.privacity && (
              <i
                data-tip="Grupo privado"
                data-for="gruposId"
                className="fa fa-lock"
                style={{ color: "red", background: "transparent" }}
              />
            )}
            <Tooltip id="gruposId" /> {groupDetail.groupName}
          </span>
          {(groupStatus.typeMember === 2 || groupStatus.typeMember === 1) && (
            <div className="dropdown">
              <button
                className="btn btn-blue dropdown-toggle"
                type="button"
                style={{ lineHeight: 0.75 }}
                id="dropdownMenu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-gear" />
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu">
                {groupStatus.typeMember === 1 && (
                  <a role="button" onClick={this.onOpenModal} className="dropdown-item">
                    Editar informações do grupo
                  </a>
                )}
                {groupStatus.typeMember === 1 && (
                  <a role="button" onClick={this.openCrop} className="dropdown-item">
                    Alterar capa do grupo
                  </a>
                )}
                {groupStatus.typeMember !== 1 && (
                  <a
                    role="button"
                    onClick={() => this.removeFromGroup(localStorage.getItem("idProfile"))}
                    className="dropdown-item"
                  >
                    Sair do grupo
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className="card-img-top group"
          style={{
            backgroundImage: `url(${groupDetail.linkPhotoGroup ? `${groupDetail.linkPhotoGroup}` : placeholder
              })`,
          }}
        />
        <div className="card-body">
          <p style={{ margin: 0, whiteSpace: "pre-line" }}>
            {showDescription ? groupDetail.description : cutDescription}
            {hasSpaceDescription && (
              <a
                role="button"
                onClick={() => this.setState({ showDescription: !showDescription })}
                style={{ color: "rgb(39, 170, 225)" }}
              >
                {showDescription ? " Veja Menos -" : " Veja Mais +"}
              </a>
            )}
          </p>
        </div>
        <div className="card-body">
          {(groupStatus.typeMember === 1 || groupStatus.typeMember === 2) && (
            <div className="form-group">
              <label htmlFor="publicacao">
                <i className="fa fa-pencil" /> Criar publicação
              </label>
              <textarea
                name="publicacao"
                id="publicacao"
                rows="7"
                value={post}
                onChange={evt => this.setState({ post: evt.target.value })}
                className="form-control"
              />
              <button
                type="button"
                className="btn btn-blue mr-3"
                onClick={this.createPost}
                id="btn-postar"
                disabled={post === "" || post.trim() === ""}
              >
                Postar
              </button>
              <label className="btn btn-blue mt-2">
                Anexar arquivo
                <input
                  type="file"
                  id="exampleInputFile"
                  accept="image/jpeg, application/pdf, audio/mpeg, audio/mp3, video/mp4, .xlsx, .xls , .doc, .docx, .ppt, .pptx"
                  hidden
                  onChange={e => this._handleImageChange(e)}
                />
              </label>
              <div className="">
                <div className="imgPreview">{$imagePreview}</div>
              </div>
            </div>
          )}
          {groupStatus.typeMember === 3 && (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <p>
                Solicitação para particiar do grupo enviada! <br /> Aguarde aprovação para postar no
                grupo
              </p>
              <button
                type="button"
                className="btn btn-blue"
                onClick={() => this.removeFromGroup(localStorage.getItem("idProfile"))}
              >
                Cancelar pedido
              </button>
            </div>
          )}
          {groupStatus.typeMember === 0 && (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <p>Para postar no grupo você deve fazer parte dele</p>
              <button type="button" className="btn btn-blue" onClick={this.joinGroup}>
                Desejo participar
              </button>
            </div>
          )}
        </div>
        <Modal open={modalOpen} onClose={this.onCloseModal} classNames={{ modal: "lit-modal-form" }}>
          <EditGroupForm
            onCloseModal={this.onCloseModal}
            updateRelevantData={this.updateRelevantData}
            dados={groupDetail}
          />
        </Modal>
        <Modal open={cropModal} onClose={this.closeCrop} classNames={{ modal: "lit-perfil-form" }}>
          <CropPictureForm
            closeModal={this.closeCrop}
            finishUpdate={this.updateRelevantData}
            dados={groupDetail}
          />
        </Modal>
        <Simplert
          showSimplert={alert}
          type="success"
          title="Solicitação enviada com sucesso!"
          message="Aguarde resposta dos administradores do grupo"
          customCloseBtnClass="btn-blue"
          customCloseBtnText="OK"
          onClose={() => router.history.push("/timeline")}
        />
      </div>
    )
  }
}

export default Main
