import styled from "styled-components";
import { LitButton } from '../shared/LitButton';

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  min-height: 100vh;
  background: #f3f4f7;
`;

export const ContentImg = styled.div`
  text-align: center;
  margin-bottom: 30px;

  svg {
    font-size: 100px;
    margin: 0 auto;
    font-weight: lighter;
    margin-bottom: 20px;
    color: #7ae190;
  }
`;

export const PaidPlanInfo = styled.p`
  margin: 0px;
  padding: 0px;
`

export const NavLit = styled.nav`
  background-image: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    /* padding: 0; */
    margin: 0;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Button = styled(LitButton)`
  width: 100%;
`
