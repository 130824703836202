/**
 * Types
 */
export const Types = {
  GET_COURSE_REQUEST: "new-course/GET_COURSE_REQUEST",
  RELOAD_COURSE_DATA: "new-course/RELOAD_COURSE_DATA",
  GET_COURSE_SUCCESS: "new-course/GET_COURSE_SUCCESS",
  GET_COURSE_FAILURE: "new-course/GET_COURSE_FAILURE",
  GET_COURSE_PROGRESS_REQUEST: "new-course/GET_COURSE_PROGRESS_REQUEST",
  GET_COURSE_PROGRESS_SUCCESS: "new-course/GET_COURSE_PROGRESS_SUCCESS",

  SET_DISCIPLINE_SELECTED: "new-course/SET_DISCIPLINE_SELECTED",
  SET_COURSE_SELECTED: "new-course/SET_COURSE_SELECTED",
  SET_NAVIGATION_CONTENT: "new-course/SET_NAVIGATION_CONTENT",
  SET_PLAYER_POSITION: "new-course/SET_PLAYER_POSITION",
  SET_DISCIPLINE_PROGRESS: "new-course/SET_DISCIPLINE_PROGRESS",
  SET_AUTOPLAY_VIDEOS: "new-course/SET_AUTOPLAY_VIDEOS",
  SET_VIDEO_MUTED: "new-course/SET_VIDEO_MUTED",
  SET_WANING_DISPLAYED: "new-course/SET_WANING_DISPLAYED",

  SET_USER_REQUEST_SEEK_PLAYER: "new-course/SET_USER_REQUEST_SEEK_PLAYER",

  SET_ACTION_PLAY_VIDEO: "course/SET_ACTION_PLAY_VIDEO",

  SET_LAST_SYNCED_GRADE_DISCIPLINE:
    "new-course/SET_LAST_SYNCED_GRADE_DISCIPLINE",

  FETCH_SIMULATED_GRADES_BY_UC: "new-course/FETCH_SIMULATED_GRADES_BY_UC",

  SYNC_GRADE_BY_DISCIPLINE: "new-course/SYNC_GRADE_BY_DISCIPLINE",
  DEFINE_DISCIPLINE_AS_SYNCHRONIZED:
    "new-course/DEFINE_DISCIPLINE_AS_SYNCHRONIZED",

  FETCH_EVALUATION_GRADE: "new-course/FETCH_EVALUATION_GRADE",
  FETCH_FORUM_GRADES_BY_UC: "new-course/FETCH_FORUM_GRADES_BY_UC",

  RESET_COURSE: "new-course/RESET_COURSE",
  RESET_ERROR_COURSE: "new-course/RESET_ERROR_COURSE",
  REFRESH_COURSE: "new-course/REFRESH_COURSE",

  INCREMENT_COURSE_PROGRESS: "new-course/INCREMENT_COURSE_PROGRESS",

  SET_COURSE_TO_SHOW_PROGRESS: "new-course/SET_COURSE_TO_SHOW_PROGRESS",

  SET_DEFAULT_SELECTED_COURSE: "new-course/SET_DEFAULT_SELECTED_COURSE",

  GET_COURSE_CERTIFICATE_REQUEST: "new-course/GET_COURSE_CERTIFICATE_REQUEST",
  GET_COURSE_CERTIFICATE_SUCCESS: "new-course/GET_COURSE_CERTIFICATE_SUCCESS",
  GET_COURSE_CERTIFICATE_FAILURE: "new-course/GET_COURSE_CERTIFICATE_FAILURE",

  REDIRECT_TO_TENANT: 'new-course/SET_REDIRECT_TO_TENANT',

  FETCH_SEARCH_LOG_REQUEST: 'new-course/FETCH_SEARCH_LOG_REQUEST',
  SET_SHOW_MODAL: 'new-course/SET_SHOW_MODAL',

  SET_COURSE_SESSION: "new-course/SET_COURSE_SESSION",
};
