import styled from "styled-components";
import { } from "../../../constants";

export const Search = styled.div`
  display: block;
  clear: both;
  position: relative;
  label {
    margin: 5px 0;
  }
  i {
    position: absolute;
    top: 42px;
    left: 12px;
    opacity: 0.65;
  }
`;

export const InputSearch = styled.input`
  border-radius: 50px;
  width: 100%;
  height: 100%;
  padding: 0.75rem 2.35rem;
  border: 1px solid var(--search-border);
  &:focus {
    border-color: var(--search-border-focus);
    color: var(--search-text-focus);
  }
`;

