import styled, { css } from "styled-components";
import { } from "../../../constants";

export const Filter = styled.span`
  display: table;
  width: 100%;
  margin: 0;
  button { margin-bottom: 0; padding-bottom: 0; }
  @media screen and (max-width: 991px) {
    margin: 10px auto;
  }
`;

export const Cards = styled.div`
  margin: 1rem 0;
  display: inline-block;
  width: 100%;

  ${props => (props.perLine) && css`
    column-count: ${props.perLine};
    column-gap: 15px;
    @media screen and (max-width: 1280px) {
      column-count: ${props.perLine - 1};
    }
    @media screen and (max-width: 767px) {
      column-count: ${props.perLine - 2};
    }
  `}

`;

export const Feed = styled.div`
  margin: 0 auto;
  display: block;
  width: 75%;
  @media (max-width: 767px) {
    width: 85%;
  }
`;
