import React from "react";
import { TabHeader } from "./styles";

export default ({ child }) => {
  return (
    <>
      <TabHeader>{child}</TabHeader>
    </>
  );
};
