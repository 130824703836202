import React from "react";
import Screen from "./screen";

export default ({
  groups
}) => {
  return (
    <>
      <Screen
        groups={groups}
      />
    </>
  );
};
