import React, { useState, useMemo, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import history from '../../utils/history';

import Screen from './screen';

export default function Turma({ location }) {

  const [activePage, setActivePage] = useState('HOME');

  const turma = useMemo(() => {
    return _.get(location, 'state.turma', null);
  }, [location]);

  const handleSelectPage = useCallback((page) => {
    setActivePage(page);
  }, []);

  if (!turma) {
    return (
      <Redirect to={"/home"} />
    )
  }

  return (
    turma && (
      <Screen
        turma={turma}
        selectPage={handleSelectPage}
        activePage={activePage}
      />
    )
  )
}
