import React from "react";

import Screen from "./screen";

export default ({ isOpen, closeModal, pageImage, member, user, removeMember, alterMemberRole, transferGroupPossession, isAdministrator, groupRoles }) => {
  return (
    <>
      <Screen
        user={user}
        member={member}
        isOpen={isOpen}
        groupRoles={groupRoles}
        closeModal={closeModal}
        removeMember={removeMember}
        alterMemberRole={alterMemberRole}
        isAdministrator={isAdministrator}
        transferGroupPossession={transferGroupPossession}
        pageImage={pageImage}
      />
    </>
  );
};
