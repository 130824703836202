import React, { useCallback, useState } from "react";
import Modal from "react-responsive-modal";

import { gateway } from '../../../utils/api'

import { MessageContainer, ContainerAction } from './styles'

export function ModalMessageCourse({ showModal, closeModal, courseCode }) {

  const [checked, setChecked] = useState(false)

  const handleCloseAndSetModalCourseToFalse = useCallback(() => {
    if(checked) {
      const url = `/webbff/lit_app_web/course/${courseCode}/setNotShowModalCourse`
      gateway.put(url)
    }
    closeModal();
  }, [checked])

  const handleChecked = useCallback(() => {
    setChecked(prevState => !prevState)
  }, [])

  return (
    <Modal
      open={showModal}
      onClose={handleCloseAndSetModalCourseToFalse}
      classNames={{ modal: "lit-perfil-form" }}
      closeOnOverlayClick={false}
    >
      <div className="modal-content">
        <div
          className="modal-body"
          style={{
            background: "white",
          }}
        >
          <MessageContainer>
            <p>
              Queridos alunos e alunas,
            </p>
            <p>
              Conforme nossos comunicados encaminhados por email, desde 01/03/2024 o formato do MBA da Saint Paul no LIT não está mais disponível para novas aquisições.            </p>
            <p>
              Temos outros formatos de cursos que poderão contribuir para sua carreira e seu desenvolvimento! Caso queira conhecer as opções, por favor, entre em contato com nosso time pelo sac@lit.com.br.
            </p>
            <p>
              <span style={{display: 'block'}}>Muito obrigado,</span>
              <span style={{display: 'block'}}>Time Saint Paul | LIT</span>
            </p>
            <ContainerAction style={{display: 'flex', align: 'center'}}>
              <input htmlFor="textClose" type="checkbox" value={checked} onChange={handleChecked}/> 
              <label id="textClose">Não exibir novamente.</label> 
            </ContainerAction>
          </MessageContainer>
        </div>
      </div>
    </Modal>
  );
}
