import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import NewPayment from '../NewPayment';

class UpdateSubscription extends Component {
  static contextTypes = {
    router: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <NewPayment showHeader={false} close={this.props.close} />;
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(UpdateSubscription);
