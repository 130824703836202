import React from "react";
import {
  SIZE,
  APPEARANCE,
  ACTION,
  NUMBER,
  POSITION,
  FONT_TYPE,
  PAGE_INFORMATION,
  STATUS
} from "../../../constants";

import OverflowContent from "../../basicComponents/OverflowContent";
import Text from "../../basicComponents/Text";
import ListRenderer from "../../ListRenderer";
import Actions from "../../Actions";
import Solicitation from "../../Info/DataInfo";

import { ModalBody, List } from "./styles";

export default ({
  groups,
  declineJoinSolicitation,
  acceptJoinSolicitation,
  loadMoreInvites
}) => {
  function showInvites() {
    if(!groups || !groups.length){
      return <Text text={"Nenhum item encontrado"} fontType={FONT_TYPE.BOLD} textAlign={POSITION.CENTER}/>
    }else {
      return <>
        {
        groups.map(group =>
        <Solicitation
          id={group.code}
          name={group.name}
          img={group.image}
          link={`/beta/social/socialgroup/${group.code}`}
          description={`${group.totalMembers} ${group.totalMembers === 1 ? PAGE_INFORMATION.MEMBER 
            : PAGE_INFORMATION.MEMBERS} - ${PAGE_INFORMATION.INVITED_BY} ${group.inviterUser ? group.inviterUser.name : ''}`}
          listButtons={STATUS.TRUE}
          actions={
            <Actions
              itemsToRender={[
                {
                  type: "button",
                  action: () => {
                    declineJoinSolicitation(group.code);
                  },
                  buttonText: ACTION.DECLINE,
                  appearance: APPEARANCE.OUTLINE_PRIMARY,
                  size: SIZE.SMALL
                },
                {
                  type: "button",
                  action: () => {
                    acceptJoinSolicitation(group.code);
                  },
                  buttonText: ACTION.ACCEPT,
                  appearance: APPEARANCE.PRIMARY,
                  size: SIZE.SMALL
                }
              ]}
            />
          }
        />
      ) 
      }
      </>
    }
  }

  return (
    <ModalBody>
      <OverflowContent onBottom={loadMoreInvites}>
        <List>
          <ListRenderer
            grid={NUMBER.ONE}
            componentToRender={showInvites()}
          ></ListRenderer>
        </List>
      </OverflowContent>
    </ModalBody>
  );
};
