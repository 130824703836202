import { call, put } from "redux-saga/effects"

import api from "../../utils/api"
import { SocialActions } from "../ducks/social"

export function* getMyGroups(action) {
  try {
    const response = yield call(api.get, `getGroupsByIdProfile/${action.payload.idProfile}`)

    yield put(SocialActions.myGroupsActions.getMyGroupsSuccess(response))
  } catch (err) {
    yield put(SocialActions.myGroupsActions.getMyGroupsFailure(err.response))
  }
}

export function* getPendingInvitations(action) {
  try {
    const response = yield call(api.get, `getOpenIvites/${action.payload.idProfile}`)

    yield put(SocialActions.pendingInvitationsActions.getPendingInvitationsSuccess(response))
  } catch (error) {
    yield put(SocialActions.pendingInvitationsActions.getPendingInvitationsFailure(error.response))
  }
}

export function* getRecommendedGroups(action) {
  try {
    const response = yield call(api.get, `getRecommendedGroup/${action.payload.idProfile}`)

    yield put(SocialActions.recommendedGroupsActions.getRecommendedGroupsSuccess(response))
  } catch (error) {
    yield put(SocialActions.recommendedGroupsActions.getRecommendedGroupsFailure(error.response))
  }
}
