import React from "react";
import ViewEditor from '../../../../../shared/LitEditor/ViewEditor'

function Descriptive({ renderLabel, value, id, index, onAnswerChange }) {
  return (
    <div style={{margin: 0, padding: '5px', borderRadius: '5px'}} className="form-group w-100">
      <ViewEditor data={value} style={{background: 'transparent'}} />
    </div>
  );
}

export default Descriptive;
