import { Types } from "./types"
/**
 * Reducer
 */

const INITIAL_STATE = {
  loading: false,
  data: [],
  openDirectPayment: false,
  error: null,
  called: false,
  nps: {
    show: false,
    origin: "",
    send: false,
    error: false,
    loading: false,
  },
  modalRestrict: false,
  modalRestrictType: 'course',
  modalRestrictLitPass: false,
  modalRestrictLitPassType: 'course'
}

export default function profile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PROFILE_REQUEST:
      return { ...state, loading: true }
    case Types.GET_PROFILE_SUCCESS:
      return { ...state, loading: false, called: true, data: action.payload.data }
    case Types.GET_PROFILE_FAILURE:
      return { ...state, loading: false, error: true }
    case Types.SET_DIRECT_OPEN_PAYMENT:
      return { ...state, openDirectPayment: action.payload.data }

    case Types.TOGGLE_VIEW_NPS:
      return {
        ...state,
        nps: {
          show: action.payload.data.show,
          origin: action.payload.data.origin,
        },
      }

    case Types.SEND_NPS_REQUEST:
      return {
        ...state,
        nps: {
          loading: true,
        },
      }
    case Types.SEND_NPS_SUCCESS:
      return {
        ...state,
        nps: {
          loading: false,
          send: true,
          error: false,
        },
      }
    case Types.SEND_NPS_FAILURE:
      return {
        ...state,
        nps: {
          loading: false,
          send: true,
          error: action.payload.error,
        },
      }

    case Types.TOGGLE_MODAL_RESTRICT:
      return {
        ...state,
        modalRestrict: action.payload.status,
        modalRestrictType: action.payload.type
      }
    case Types.TOGGLE_MODAL_RESTRICT_LITPASS:
      return {
        ...state,
        modalRestrictLitPass: action.payload.status,
        modalRestrictLitPassType: action.payload.type
      }  
    default:
      return state
  }
}
