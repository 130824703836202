import React, { PureComponent } from 'react';
import { gateway } from '../../utils/api';
import { getLoginTenantURL } from '../../utils/functions';
import { LitText } from '../shared/LitText';

class RetornoLogin extends PureComponent {
  state = {
    loading: false,
    showInput: false,
    email: ''
  };

  resendEmail = async () => {
    try {
      const { email } = this.state;
      const { notif } = this.props;
      const emailData = localStorage.getItem('ra') || email;
      if (!emailData) {
        this.setState({ showInput: true });
        return;
      }

      this.setState({
        loading: true
      });

      const urlParams = new URLSearchParams(window.location.search);
      const origin = urlParams.get('o');
      const tenant = getLoginTenantURL(window.location.search);

      let url = `/notification/notification/sendConfirmation/${emailData}`;

      if (origin) {
        url += `?o=${origin}`;
      }
      if (tenant) {
        if (origin) {
          url += `&tenant=${tenant}`;
        } else {
          url += `?tenant=${tenant}`;
        }
      }

      const response = await gateway.post(url);

      this.setState({
        loading: false,
        showInput: false,
        email: ''
      });
      notif(response.message);
    } catch (error) {
      this.setState({
        loading: false
      });
      const { notif } = this.props;
      notif(error.response.data.message);
    }
  };

  render() {
    const { loading, showInput, email } = this.state;
    return (
      <div className="row flex-column criar-conta">
        <LitText className="text-center">Não recebeu o email de confirmação?</LitText>
        {showInput && (
          <div className="field-float-label-pattern">
            <div>
              <input
                type="email"
                placeholder="Email"
                className="form-control input-not-yellow"
                id="inputEmail"
                value={email}
                onChange={evt => this.setState({ email: evt.target.value })}
                required
              />
              <label htmlFor="inputEmail">Email</label>
            </div>
          </div>
        )}
        <button
          type="button"
          onClick={this.resendEmail}
          disabled={loading}
          className={`btn font-lato btn-default align-self-center btn-FontWeight6${loading &&
            ' btn-loading'}`}
        >
          Reenviar email
        </button>
      </div>
    );
  }
}

export default RetornoLogin;
