import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';

import _ from 'lodash';

import { useDispatch } from 'react-redux';

import swal from '@sweetalert/with-react';
import Template from '../Pagamento/_template';
import { maskDocs, validateDocs } from '../../utils/functions';
import { handleCpfConsultation } from '../../business/payment';
import history from '../../utils/history';
import adsTracking from '../../utils/adsTracking';
import { Creators as ProfileActions } from '../../store/ducks/user/profile/actions';
import { Container } from './styles';
import { gateway } from '../../utils/api';

import { LitButton } from '../shared/LitButton';
import { Text } from '../shared/LitText/styles';

export default function ConsultaCPF() {
  const [invalidCpf, setInvalidCpf] = useState(null);
  const [cpf, setCPF] = useState('');
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState('IDBRASIL');
  const [documentPlaceholder, setDocumentPlaceholder] = useState('CPF');
  const OptionLabel = ({ iconClassName, label }) => (
    <div className="option-list">
      {iconClassName && <span className={iconClassName}></span>}
      <span>{label}</span>
    </div>
  );
  const [documentOptions] = useState([
    {
      id: 'IDBRASIL',
      label: 'Brasil',
      value: 'IDBRASIL',
      icon: 'fi fi-br'
    },
    {
      id: 'IDANGOLA',
      label: 'Angola',
      value: 'IDANGOLA',
      icon: 'fi fi-ao'
    },
    {
      id: 'IDPORTUGAL',
      label: 'Portugal',
      value: 'IDPORTUGAL',
      icon: 'fi fi-pt'
    },
    {
      id: 'IDAMERICALATINA',
      label: 'América Latina',
      value: 'IDAMERICALATINA',
      icon: ''
    }
  ]);
  const dispatch = useDispatch();

  const selectRef = useRef(null);

  function verifyCpf(e) {
    e.preventDefault();
    const { value } = e.target;
    const result = validateDocs(value, documentType);
    if (!result) {
      switch (documentType) {
        case 'IDPORTUGAL':
          setInvalidCpf('NIF inválido, favor inserir um NIF válido!');
          break;
        case 'IDANGOLA':
          setInvalidCpf('CC inválido, favor inserir um CC válido!');
          break;
        case 'IDAMERICALATINA':
          setInvalidCpf(
            'Número do documento inválido, favor inserir um número válido!'
          );
          break;
        default:
          setInvalidCpf('CPF inválido, favor inserir um CPF válido!');
          break;
      }
    } else {
      setInvalidCpf(null);
    }
  }

  function onChangeCPF(e) {
    setInvalidCpf('');
    setCPF(maskDocs(e.target.value, documentType));
  }

  async function onSubmitForm() {
    try {
      setLoading(true);
      await gateway.post('/person/person/addCpf', {
        cpf
      });

      await handleCpfConsultation(cpf.replace(/\D/g, ''), documentType);
      const idProfile = localStorage.getItem('idProfile');

      let activeContract = false;
      if (localStorage.getItem('activeContract') !== 'undefined') {
        activeContract = JSON.parse(localStorage.getItem('activeContract'));
      }
      adsTracking.sendGoogleAnalyticsEcommerceEvent({
        step: adsTracking.STEP3_VERIFY_CPF
      });
      dispatch(ProfileActions.getProfileRequest(idProfile));
      if (activeContract) {
        history.push('/home');
      } else {
        history.push('/planos');
      }
      setLoading(false);
    } catch (error) {
      const errorMessage =
        _.get(error, 'response.data.message', undefined) ||
        'Ops, Erro ao consultar documento';

      swal({
        icon: 'warning',
        width: 400,
        title: `Atenção!`,
        content: <p>{errorMessage}</p>
      });
      setLoading(false);
    }
  }

  function handleDocType(e) {
    setDocumentType(e);
    setInvalidCpf('');
    switch (e) {
      case 'IDPORTUGAL':
        return setDocumentPlaceholder('NIF');
      case 'IDANGOLA':
        return setDocumentPlaceholder('CC');
      case 'IDAMERICALATINA':
        return setDocumentPlaceholder('Número do documento');
      default:
        return setDocumentPlaceholder('CPF');
    }
  }

  useEffect(() => {
    // Força a atualização do componente Select após 50ms
    const timeoutId = setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.select.setValue(documentOptions[0]);
      }
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [documentOptions]);

  return (
    <Template showHeader>
      <Container>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <Text className="text-left">
              Antes de iniciar o seu curso, precisamos de algumas informações:
            </Text>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <Text className="box-info-title text-left first">
              Escolha o seu país:
            </Text>
          </div>
          <div className="col-md-12 form-group">
            <Select
              ref={selectRef}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  borderColor: '#27AAE1',
                  padding: '2px 8px'
                })
              }}
              options={documentOptions.map(option => ({
                value: option.value,
                label: (
                  <OptionLabel
                    iconClassName={option.icon}
                    label={option.label}
                  />
                )
              }))}
              onChange={item => handleDocType(item.value)}
              components={{
                SingleValue: ({ children, data }) => (
                  <div className="option-list">
                    {data.icon && <span className={data.icon}></span>}
                    <span>{children}</span>
                  </div>
                )
              }}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 form-group">
            <input
              id="cpf"
              type="text"
              className="form-control"
              placeholder={documentPlaceholder}
              name="cpf"
              maxLength="20"
              onBlur={verifyCpf}
              value={cpf}
              onChange={onChangeCPF}
            />
            {invalidCpf && <p style={{ color: 'red' }}>{invalidCpf}</p>}
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <div className="box-buttons">
              <LitButton
                disabled={invalidCpf || loading || !cpf}
                style={{
                  marginTop: '0px',
                  textTransform: 'uppercase'
                }}
                onClick={onSubmitForm}
                className={`${loading && ' btn-loading'}`}
              >
                Confirmar
              </LitButton>
            </div>
          </div>
        </div>
        <div
          className="row d-flex justify-content-flex-start"
          style={{ marginTop: 20 }}
        >
          <div className="col-md-12">
            <Text className="text-left">
              Por que precisamos do seu documento?
            </Text>
            <section style={{ marginTop: 10 }}>
              <Text
                className="box-info-title"
                style={{ marginBottom: 0, fontWeight: 300 }}
              >
                Uma identificação única é necessária para:
              </Text>
              <ul style={{ listStyleType: 'circle', padding: '5px 15px' }}>
                <li>
                  Garantir que seus certificados sejam emitidos de forma correta
                </li>
                <li>
                  Recuperar seus progressos anteriores, caso seja ex-aluno LIT
                </li>
                <li>
                  Caso seja colaborador de uma empresa parceira, você terá
                  acesso a planos especiais
                </li>
              </ul>
            </section>
          </div>
        </div>
      </Container>
    </Template>
  );
}
