import React, { memo } from "react"

import _ from "lodash";
import CardCurso from "./CardCurso";
import LaneHoc from "./LaneHoc";

import { StyleLane } from './styles';


const LaneCourses = ({ data }) => {
  const docs = _.get(data, 'docs', [])
  const lane = _.get(data, 'lane', {})

  if (!docs.length) return null

  // delete obj.docs
  // delete docs.lane
  // const otherProperties = docs

  return (
    <LaneHoc tooltipText={lane.name} title={lane.name}>
      {docs.map(course => {
        return (
          <StyleLane key={`${course.id}`}>
            <CardCurso course={course} url="curso-novo" />
          </StyleLane>
        );
      })}
    </LaneHoc>
  )
}

export default memo(LaneCourses)
