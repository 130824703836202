import React, {useEffect, useState} from 'react';
import { format, parseISO } from "date-fns";

import * as Styles from './styles';
import Coupon from "../Coupon";
import CouponShare from "../CouponShare";
import InvitationsList from "../InvitationsList";
import RecommendationsList from "../RecommendationsList";
import RecommendationHelpText from "../RecommendationHelpText";
import GenerateCouponContainer from "../GenerateCouponContainer";
import GetRecommendationsError from "../GetRecommendationsError";
import CreateRecommendationForm from "../CreateRecommendationForm";

import { gateway } from "../../../../utils/api";

const getRecommendations = async () => {
  const {
    ox_recommendedreference: {rk_reference: cupom},
    qx_recommendations,
    nx_totaldiscount: totalDiscount,
  } = await gateway.get('recommendation/mgm/recommendations')

  const recommendations = qx_recommendations.map(recommendation => ({
    name: recommendation.wp_name,
    email: recommendation.we_email,
    date: format(parseISO(recommendation.dx_claimdate), 'dd/MM/yyyy'),
    discount: `${recommendation.nx_discount}%`
  }));

  return {
    recommendations,
    cupom,
    totalDiscount
  }
}

const getInvitations = async () => {
  const response = await gateway.get('recommendation/mgm/invitations')

  return response.map(item => ({
    id: item._id,
    date: format(new Date(Number(item.key)), 'dd/MM/yyyy'),
    name: item.value.name,
    email: item.value.email,
    phone: item.value.phoneNumber
  }))
}


function RecommendationBox() {
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [couponLink, setCouponLink] = useState("");
  const [invitations, setInvitations] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const [error, setError] = useState("");

  const updateInvitations = async () => {
    const invitationsList = await getInvitations();
    setInvitations(invitationsList);
  }

  const updateRecommendations = async () => {
    const result = await getRecommendations();

    setCoupon(result.cupom);
    setRecommendations(result.recommendations);
    setTotalDiscount(result.totalDiscount);
  };

  async function fetchRecommmendations() {
    setLoading(true);

    try {
      await Promise.all([
        updateRecommendations(),
        updateInvitations()
      ]);
    } catch (error) {
      console.log(error.response)
      if (error.response.status === 500) {
        setError('Ops, não conseguimos obter os dados de suas recomendações, tente novamente mais tarde.');
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const baseUrl = `${process.env.REACT_APP_API_URL.replace(':8443/api/', '')}?cupom-desconto=`;
    const link = `${baseUrl}${coupon}`;

    setCouponLink(link);
  }, [coupon])

  useEffect(() => {
    fetchRecommmendations();
  }, []);

  return (
    <>
      <RecommendationHelpText />

      { loading && <span>Carregando...</span> }

      { !loading && <GenerateCouponContainer coupon={coupon} onCouponCreated={(createdCoupon) => setCoupon(createdCoupon)} /> }

      <GetRecommendationsError error={error}/>

      {!loading && coupon && (
        <Styles.Container>
          <Coupon coupon={coupon}/>
          <CouponShare couponLink={couponLink}/>

          <CreateRecommendationForm coupon={coupon} couponLink={couponLink} onRecommendationAdded={() => updateInvitations()} />

          <InvitationsList invitations={invitations}/>

          <RecommendationsList totalDiscount={totalDiscount} recommendations={recommendations} />
        </Styles.Container>
      )}
    </>
  );
}

RecommendationBox.propTypes = {};

export default RecommendationBox;
