import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} text //text linked to checkbox
 * @param {String} position //{LEFT,RIGHT, CENTER} position of the text in the box
 * @param {String} orientation //{HORIZONTAL, VERTICAL} direction of items within the component
 * @param {Boolean} checked //tells whether the content appears selected or not
**/

export default ({
  text = DEFAULT_VALUES.STRING,
  position = DEFAULT_VALUES.STRING,
  orientation = DEFAULT_VALUES.STRING,
  checked = DEFAULT_VALUES.BOOLEAN
}) => {
  return (
    <>
      <Screen
        text={text}
        position={position}
        orientation={orientation}
        checked={checked}
      />
    </>
  );
};
