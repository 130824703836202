import { Types } from "./types"

/**
 * Actions
 */

export const Creators = {

  setAlert: (message, type = 'error', endpoints = []) => ({
    type: Types.SET_ALERT,
    payload: { message, type, endpoints },
  }),
  removeAlert: () => ({
    type: Types.REMOVE_ALERT
  }),
  setSmartBar: (data) => ({
    type: Types.SET_SMART_BAR,
    payload: data,
  }),
  removeSmartBar: () => ({
    type: Types.REMOVE_SMART_BAR
  }),
  setOpenPlans: () => ({
    type: Types.SET_OPEN_PLANS,
  }),
  removeOpenPlans: () => ({
    type: Types.REMOVE_OPEN_PLANS
  })

}
