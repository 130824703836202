import React from "react";
import ModalHeader from "./Header";

import { Modal, Content } from "./styles";

export default ({ textHeader, isOpen, closeModal, child }) => {
  return (
    <>
      <Modal isOpen={isOpen}>
        <Content>
          <ModalHeader textHeader={textHeader} closeModal={closeModal} />
          {child}
        </Content>
      </Modal>
    </>
  );
};
