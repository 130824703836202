import React from "react";

export default () => (
  <div className="row">
    <div className="col-12">
      <h2>Ganhe desconto indicando amigos</h2>
      <hr />
    </div>
  </div>
)
