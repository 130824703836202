import { Types } from "./types"

/**
 * Actions
 */
export const Creators = {
  getPendingInvitationsRequest: idProfile => ({
    type: Types.GET_PENDING_INVITATIONS_REQUEST,
    payload: { idProfile },
  }),
  getPendingInvitationsSuccess: data => ({
    type: Types.GET_PENDING_INVITATIONS_SUCCESS,
    payload: { data },
  }),
  getPendingInvitationsFailure: error => ({
    type: Types.GET_PENDING_INVITATIONS_FAILURE,
    payload: { error },
  }),
}
