import React, { Component } from "react"
import Img from "react-image"
import Loading from "../Loading/Loading"
import Pagination from "../Pagination"
import api from "../../utils/api"

class Livros extends Component {
  state = {
    fetching: false,
    livros: [],
    page: 1,
    size: 8,
    totalPage: 0,
    urlFetchLivros: "",
    authCarregada: false,
  }

  componentDidMount() {
    this.minhaBibliotecaLogin()
  }

  minhaBibliotecaLogin = async () => {
    try {
      const url = `library/oauth/${localStorage.getItem("idProfile")}`
      const response = await api.get(url)
      if (response.status === 200) {
        this.setState({ urlFetchLivros: response.message })
        this.getLivros()
      }
    } catch (error) {
      console.log(error.response)
    }
  }

  getLivros = async () => {
    try {
      this.loading(true)
      const { idProfile } = this.props
      const { page, size } = this.state
      const response = await api.get(`booksprofessor/${idProfile}?page=${page - 1}&size=${size}`)
      this.setState({ livros: response.books, totalPage: response.totalPages })
      this.loading(false)
    } catch (error) {
      this.loading(false)
      console.log(error)
    }
  }

  loading = value => {
    this.setState({ fetching: value })
  }

  onPageChanged = data => {
    const { page } = this.state
    const newPage = data.currentPage
    if (newPage !== page) {
      console.log(page)
      console.log(newPage)

      this.setState({ page: newPage }, this.getLivros)
    }
  }

  selectBook = async id => {
    if (id) {
      try {
        const response = await api.get(`library/${id}`, { responseType: "text" })
        window.open(`${response}`)
      } catch (error) {
        console.log(error)
      }
    }
  }

  render() {
    const { fetching, livros, totalPage, page, authCarregada, urlFetchLivros } = this.state
    if (fetching) {
      return <Loading width={40} />
    }

    return (
      <div className="bg-white-perfil" id="sobre-usuario">
        <div className="container-fluid">
          <div className="row">
            <iframe
              title="bliblioteca"
              src={urlFetchLivros}
              style={{ height: 0, width: 0, border: "none" }}
              onLoad={() => {
                this.setState({ authCarregada: true })
              }}
            />
            <div className="col-6">
              <h5>Livros recomendados</h5>
            </div>
          </div>
          {authCarregada && (
            <div className="row">
              <div className="col-12" id="search-grupo">
                {livros.length > 0 ? (
                  <div className="d-flex flex-wrap">
                    {livros.map(livro => {
                      return (
                        <div
                          key={livro.idBook}
                          role="button"
                          onClick={() => this.selectBook(livro.idLibrary)}
                          className="livro-container"
                        >
                          <Img src={`${livro.url}`} />
                          <span className="book-title blue">{livro.title}</span>
                          <span className="book-autor">por {livro.author}</span>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <p>Não há livros recomendados</p>
                )}
                {livros.length > 0 && (
                  <div className="d-flex flex-row py-4 align-items-center justify-content-end">
                    <Pagination
                      totalRecords={totalPage}
                      pageLimit={10}
                      pageNeighbours={1}
                      page={page}
                      onPageChanged={this.onPageChanged}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Livros
