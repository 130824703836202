import React, { useMemo } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import SideBarMenuItemUC from './SideBarMenuItemUC'

const Unidade = ({ data, children, first, selected, courseType, ...rest }) => {
  const collapse = useMemo(() => {
    let shouldSelectObject = first;
    
    if (selected.id) {
      const dataChildren = _.get(data, "children", []);
      
      shouldSelectObject = dataChildren.some(
        child => child.id === selected.id
      );
    }

    return shouldSelectObject ? "show" : "";
  }, [selected]);

  const courseTypeToClass = useMemo(() => {
    return courseType ? courseType.toLowerCase() : "";
  }, [courseType]);

  return (
    <div id={`headingUC${data.code}${data.index}`}>
      
      <SideBarMenuItemUC
        title={data.name}
        iconNumeric={data.index}
        data-toggle='collapse'
        data-target={`#collapse${data.code}${data.index}`}
        aria-expanded={!!collapse}
        aria-controls={`collapse${data.code}${data.index}`}
        {...rest}
       />

      <div
        id={`collapse${data.code}${data.index}`}
        className={`collapse ${collapse}`}
        data-course-type={courseTypeToClass}
        aria-labelledby={`headingUC${data.code}${data.index}`}
        data-parent="#accordinUC"
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  selected: state.newCourse.selected
});

export default connect(mapStateToProps)(Unidade);
