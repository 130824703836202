import styled from 'styled-components'

export const Main = styled.main`
   max-width: 1224px;
   padding: 0 10px;
   margin: 0 auto;
   margin-bottom: 30px;
`

export const Container = styled.div`
  background: #fff;
  display: flex;
  width: 100%;
  min-height: 500px;
  padding: 20px 10px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }

`

export const ContainerSidebar = styled.aside`
  max-width: 100%;
  width: 400px;
  border-right: 1px solid #ccc;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 0;
    border-right: 0px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
`

export const ContainerLoad = styled.div`
  display: flex;
  flex: 1;
  padding-left: 10px;
  justify-content: center;
`

export const ContainerWizard = styled.section`
  flex: 1;
  padding-left: 10px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`

export const NavLit = styled.nav`
  background-image: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px !important;

  button {
    background: none;
    /* padding: 0; */
    margin: 0;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;
