import React, { memo } from "react"

const IMG_WATSON = require("../../images/withWatsonBadge/badge_primary_black.png")

const WatsonBadgeFooter = () => (
  <div className="d-flex justify-content-center">
    <div className="col-10 no-gutters">
      <div className="d-flex justify-content-end">
        <img src={IMG_WATSON} alt="Imagem" className="img-responsive watsonBadge__interesses" />
      </div>
    </div>
  </div>
)

export default memo(WatsonBadgeFooter)
