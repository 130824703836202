import styled from 'styled-components'

export const ContainerButtonCertificate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin-right: 10px;
  }
`