import styled from "styled-components";
import { } from "../../../constants";

export const LeftBar = styled.div`
  display: inline-block;
  text-align: left;
  width: 40%;
  @media screen and (max-width: 991px) {
    vertical-align: top;
  }
  @media screen and (max-width: 579px) {
     width: 100%;
  }
`;

export const RightBar = styled.div`
  display: inline-block;
  text-align: right;
  width: 60%;
  @media screen and (max-width: 579px) {
    width: 100%;
  }
`;
