import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} id //content tab activation key
 * @param {Component} children //content displayed within the tab
**/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
  id = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        child={children}
        id={id}
      />
    </>
  );
};
