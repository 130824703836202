import React, { useState, useEffect } from "react";
import Screen from "./screen";
import showToast from "../../../utils/showToast";
import litLogo from "../../../images/lit-white.png";
import { gateway } from "../../../utils/api";
import { MENU_NAVIGATION } from "../../constants";

export default () => {
  const [feed, setFeed] = React.useState([]);
  const [notifications, setNotifications] = React.useState([]);
  const [userProfile, setProfile] = React.useState([]);
  const [currentCourse, setCurrentCourse] = React.useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [page, setPage] = React.useState(2);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const menuNavigation = [
    {
      link: "/beta/social/internalgroup",
      target: "_parent",
      description: MENU_NAVIGATION.PROFILE,
      initialStatus: ""
    },
    {
      link: "#",
      target: "",
      description: MENU_NAVIGATION.ACCOUNT,
      initialStatus: ""
    },
    {
      link: "/beta/feed",
      target: "_parent",
      description: MENU_NAVIGATION.FEED,
      initialStatus: "active"
    }
  ];

  async function loadContent() {
    try {
      const data = await gateway.get(`/webbff/lit_app_web/feed/getFeedBootstrap`);
      setLoadingProgress(30);
      setFeed(data.feedItems);
      setProfile(data.userInfo);
      setNotifications(data.news);
      setCurrentCourse(data.lastProgressedItem);
      setLoadingProgress(100);
    }
    catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  async function nextPage() {
    try {
      setLoadingProgress(30);
      const result = await gateway.get(`/webbff/lit_app_web/feed/getFeedItems?page=${page}&limit=10`);
      setPage(page + 1);
      setFeed([...feed, ...result.docs]);
      setHasNextPage(result.hasNextPage);
      setLoadingProgress(100);
    } catch (error) {
      setLoadingProgress(100);
      showToast(error, true);
    }
  }

  useEffect(() => { loadContent(); }, []);

  return (
    <>
      <Screen
        logoPage={litLogo}
        menuNavigation={menuNavigation}
        feedItems={feed}
        notifications={notifications}
        userProfile={userProfile}
        currentCourse={currentCourse}
        progress={loadingProgress}
        hasNextPage={hasNextPage}
        nextPage={nextPage}
      />
    </>
  );
};
