import React, { Component } from "react"
import Lightbox from "react-image-lightbox"

class TagImg extends Component {
  state = {
    lightboxPreview: false,
  }

  render() {
    const { src } = this.props
    const { lightboxPreview } = this.state
    return (
      <div className="d-flex align-items-center">
        <div
          role="button"
          onClick={() => {
            this.setState({ lightboxPreview: true })
          }}
        >
          <img
            className="img-thumbnail"
            alt="Anexo"
            style={{ width: "auto", height: "250px" }}
            src={`/images/corpus/${src}`}
          />
        </div>
        {lightboxPreview && (
          <Lightbox
            mainSrc={`/images/corpus/${src}`}
            onCloseRequest={() => {
              this.setState({ lightboxPreview: false })
            }}
          />
        )}
      </div>
    )
  }
}

export default TagImg
