import React from "react"
import styled from "styled-components"
import spinner from "../../images/paul_loading.gif"

const Loading = styled.div`
  position: fixed;
  z-index: 201;
  top: 66px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(0px);
  background: transparent;
  height: calc((100% - 66px) - 75px);
`

export default function LoadingPaul() {
  return (
    <Loading>
      <img
        alt="loading"
        style={{
          maxHeight: "100%",
          animation: "loading-spin infinite 10s linear",
        }}
        src={spinner}
      />
      <p>Carregando...</p>
    </Loading>
  )
}
