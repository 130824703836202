import styled from 'styled-components'
import { darken } from "polished"

export const Container = styled.div`
  padding: 10px 0;
  max-width: 700px;
  margin: 0 auto;
`

export const Title = styled.h2`
  margin-bottom: 10px;
`

export const Description = styled.p`
  font-size: 16px;
`

export const BoxQuestions = styled.div`
  display: flex;
  align-items: center;
  span {
    /* font-weight: 700; */
    display: inline-flex;
    margin-right: 10px;
    min-width: 150px;
  }

  padding: 20px 0;
  padding-top: ${props => props.first && '0px'};
  border-bottom: 1px solid #f1f1f1;
`

export const Label = styled.label`
  font-weight: bold;
  color: #58595b;
`

export const ContainerForm = styled.form`
  width: 100%;
`

export const TextError = styled.p`
  margin: 0;
  color: red;
`

export const BoxButtons = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
`

export const Button = styled.button`
  background: ${props => props.active ? '#27aee1' : '#c1c1c1'};
  color: #fff;
  outline: 0;
  &:active, &:focus {
    box-shadow: none;
  }
  &:hover {
    background: #27aee1;
  }
`
