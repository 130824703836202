import { Types } from "./types"

/**
 * Actions
 */
export const Creators = {
  getRecommendedGroupsRequest: idProfile => ({
    type: Types.GET_RECOMMENDED_GROUPS_REQUEST,
    payload: { idProfile },
  }),
  getRecommendedGroupsSuccess: data => ({
    type: Types.GET_RECOMMENDED_GROUPS_SUCCESS,
    payload: { data },
  }),
  getRecommendedGroupsFailure: error => ({
    type: Types.GET_RECOMMENDED_GROUPS_FAILURE,
    payload: { error },
  }),
}
