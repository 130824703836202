import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import { Creators as CourseActions } from '../../../store/ducks/newCourse/actions';

import * as Styles from './styles';

class Conteudo extends PureComponent {
  state = {};

  openAttachment = () => {
    const { data, selected } = this.props;
    if (!data.available) {
      return;
    }

    const crCode = _.get(data, 'cr_code', '');
    const type = _.get(data, 'type', '');
    const courseCode = _.get(selected, 'courseCode', '');
    const redirectRoute = `${window.location.origin}/assessment-waiting-room?code=${crCode}&type=${type}&courseCrCode=${courseCode}`;
    window.open(redirectRoute, '__blank');
  };

  generateIcon() {
    return "graduation-cap";
  }

  renderCircle = () => {
    const { data } = this.props;

    const currentStatus = _.get(data, 'status', '');

    let classIcon = 'circle circle-with-grade';

    if (currentStatus === 'DONE_SELF_ASSESSMENT') {
      classIcon = 'circle circle-with-grade assessment';
    }
    if (currentStatus === 'DONE') {
      classIcon = 'circle circle-with-grade active';
    }

    if(data && data.grade === null || data.grade === 'null') {
      return (
        <div 
          id={`course-object-${data.code}`} 
          style={{border: 0, background: 'transparent', marginRight: '3px'}} 
          className='circle circle-with-grade'
        >
        </div>
      )
    }

    return (
      <div id={`course-object-${data.code}`} className={classIcon}>
        {currentStatus === "DONE" 
          ? data.grade && data.grade.toFixed(1) 
          : "S/N"}
      </div>
    );
  };

  render() {
    const { data, selected } = this.props;

    const code = _.get(data, 'cr_code', '');
    const available = _.get(data, 'available', true);

    const isActive = selected.id === data.id;

    const classLi = `list-group-item list-group-item_sub2 ${
      isActive ? 'active' : ''
    }`;

    return (
      <Styles.Container>
        <Styles.ContentItem
          id={code}
          className={classLi}
        >
          <Styles.ContentItemChild
            className="list-child"
            role="button"
            available={available}
            onClick={this.openAttachment}
            title={data.availabilityDescription}
          >

          {this.renderCircle()}

          {/* {(available || data.status == 'DONE') && this.renderCircle()} */}

          {/* {data.progressIcon !== 'default' && (
              <>
                {(available || data.status === 'DONE') && (
                  <>
                    <span
                      style={{
                        fontSize: '1.5em',
                        color:
                          isApproved && data.status === 'DONE'
                            ? '#3cd99e'
                            : !isApproved && data.status === 'DONE'
                            ? '#de1626'
                            : '#838383'
                      }}
                    >
                      {data.status === 'NOT STARTED' ||
                      data.status === 'IN PROGRESS'
                        ? 'SN'
                        : (
                          data.grade && data.grade.toFixed(1)
                        )}
                    </span>
                    {isApproved && data.status === 'DONE' && (
                      <div
                        style={{
                          width: 18,
                          height: 18,
                          backgroundColor: '#3cd99e',
                          marginLeft: 10,
                          borderRadius: 18 / 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '0.24em'
                        }}
                      >
                        <FaStar color="white" size={11} />
                      </div>
                    )}
                    {!isApproved && data.status === 'DONE' && (
                      <div
                        style={{
                          width: 18,
                          height: 18,
                          backgroundColor: '#de1626',
                          borderRadius: '50%',
                          marginLeft: 10,
                          borderRadius: 18 / 2,
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: '0.24em',
                          justifyContent: 'center'
                        }}
                      >
                        <FaStar color="white" size={11} />
                      </div>
                    )}
                  </>
                )}
                {!available &&
                  data.status !== 'DONE' &&
                  renderGradeIcon('UNAVAILABLE')}
              </>
            )}
            {data.progressIcon === 'default' && (
              <>
                {!available &&
                  data.status !== 'DONE' &&
                  renderGradeIcon('UNAVAILABLE')}
              </>
            )} */}

            <Styles.ContainerIcon>
                <i className={`fa fa-${this.generateIcon()}`} />
            </Styles.ContainerIcon>

            <span className="info">{data.name}</span>

            <Styles.GradeContainer>
              <i className="fa fa-play" />
            </Styles.GradeContainer>

            
          </Styles.ContentItemChild>
        </Styles.ContentItem>
      </Styles.Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    selected: state.newCourse.selected
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conteudo);
