import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import Img from "react-image"
import styled from "styled-components"

import placeholder from "../../../images/placeholder-group.png"

import api from "../../../utils/api"
import { API_URL_IMAGES } from "../../../utils/constants"

const DivColumn = styled.div`
  padding-left: 0;
  padding-right: 0;
`
const DivCard = styled.div`
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  height: 100%;
`
const H6Title = styled.h6`
  color: rgb(39, 170, 225);
`
export default class Grupo extends PureComponent {
  state = {
    detail: "",
    mouseHoverStatus: {
      maxLength: 100,
      descriptionShown: "",
      mouseHover: undefined,
    },
  }

  componentWillMount() {
    const {
      mouseHoverStatus: { maxLength },
    } = this.state
    const {
      grupo: { description },
    } = this.props
    const hasSpace = description.slice(0, maxLength).lastIndexOf(" ") !== -1

    if (description.length > maxLength) {
      if (hasSpace) {
        this.setState({
          mouseHoverStatus: {
            descriptionShown: `${description.slice(
              0,
              description.slice(0, maxLength).lastIndexOf(" ")
            )}...`,
            maxLength,
            mouseHover: false,
          },
        })
      } else {
        this.setState({
          mouseHoverStatus: {
            descriptionShown: `${description.slice(0, maxLength)}...`,
            maxLength,
            mouseHover: false,
          },
        })
      }
    } else {
      this.setState({
        mouseHoverStatus: {
          descriptionShown: description,
          maxLength,
        },
      })
    }
  }

  componentDidMount() {
    this.fetchDetailGroup()
  }

  fetchDetailGroup = async () => {
    const {
      grupo: { idGroup },
    } = this.props
    const idProfile = localStorage.getItem("idProfile")

    try {
      const response = await api.get(`getNumberMembersOfGroup/${idProfile}/${idGroup}`)
      this.setState({ detail: response })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const {
      grupo: { idGroup, groupName },
      pending,
    } = this.props
    const {
      mouseHoverStatus: { descriptionShown },
    } = this.state
    const { detail } = this.state

    let statusGroup

    if (pending) {
      statusGroup = "Pendente"
    } else if (detail.owner) {
      statusGroup = "Admin"
    } else {
      statusGroup = "Membro"
    }

    let qtdMembers
    if (detail.qtdMembers > 1) {
      qtdMembers = `${detail.qtdMembers} membros`
    } else if (detail.qtdMembers === 0) {
      qtdMembers = "Sem membros"
    } else {
      qtdMembers = "1 membro"
    }

    const urlImg = `${API_URL_IMAGES}images/groups/${idGroup}.jpg`

    return (
      <DivColumn className="col-md-4">
        <DivCard className="card text-center">
          <div className="card-body">
            <Link to={`/grupo/${idGroup}`}>
              <Img style={{ width: '200px', height: '120px' }} className="img-thumbnail mb-2 cover-fit" src={[urlImg, placeholder]} />
              <H6Title className="card-title">{groupName}</H6Title>
            </Link>
            <h6  id={`grupo-${idGroup}`} style={{ fontSize: "15px" }} className="card-subtitle mb-2 text-muted">
              {`${statusGroup} - ${qtdMembers}`}
            </h6>
            <p style={{ fontSize: "14px" }}>{descriptionShown}</p>
          </div>
        </DivCard>
      </DivColumn>
    )
  }
}
