import { call, put, select } from "redux-saga/effects"
import axios from "axios"

import { Creators as PaulchatActions } from "../ducks/paulChat/actions"
import { WATSON_URL, watsonHeaderConfig } from "../../utils/constants"

const getStore = state => state.paulChat

export function* didSubjectChanged(action) {
  const paulChatSTore = yield select(getStore)
  const { lastSubject } = paulChatSTore
  const {
    payload: { newSubject },
  } = action

  if (lastSubject !== "" && !!lastSubject) {
    if (newSubject) {
      if (lastSubject === newSubject) {
        yield put(PaulchatActions.increaseWatsonChatSubjectCounter())
      } else {
        yield put(PaulchatActions.updateWatsonChatSubject(newSubject, true))
      }
    }
  } else {
    yield put(PaulchatActions.updateWatsonChatSubject(newSubject, false))
  }
}

export function* addButtonFeedback() {
  const paulChatSTore = yield select(getStore)

  const { chatMessages, lastSubjectCounter, lastSubjectChanged } = paulChatSTore

  if (lastSubjectCounter > 4 || (lastSubjectCounter === 0 && lastSubjectChanged)) {
    const thereIsAButton = chatMessages.findIndex(item => item.sender === "button")

    if (thereIsAButton === -1) {
      yield put(PaulchatActions.addChatMessage({ sender: "button" }))
    } else {
      const messages = chatMessages.filter(message => message.sender !== "button")
      yield put(PaulchatActions.removeFeedbackButton(messages))
      yield put(PaulchatActions.addChatMessage({ sender: "button" }))
    }
  }
}

export function* sendFeedback(action) {
  const paulChatSTore = yield select(getStore)

  const { user, messagesChatHistory } = paulChatSTore
  try {
    const feedbackToFetch = {
      _id: new Date().getTime().toString(),
      userID: user,
      platform: "web",
      desc: "Avaliação de resposta Watson através do portal",
      chatHistory: messagesChatHistory,
      feedbackDate: new Date().toISOString(),
      type: "feedback",
      feedbackStatus: action.payload.avaliation,
      userCount: "STRING",
      locale: "sp",
    }
    yield call(axios.post, `${WATSON_URL}feedbacks`, feedbackToFetch, { headers: watsonHeaderConfig })

    yield put(PaulchatActions.sendFeedbackSuccess())
  } catch (error) {
    yield put(PaulchatActions.sendFeedbackFailure())
  }
}
