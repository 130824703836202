/**
 * Types
 */

export const Types = {
  ADD_CHAT_MESSAGE: "paulChat/ADD_CHAT_MESSAGE",
  ADD_MESSAGE_TO_CHAT_HISTORY: "paulChat/ADD_MESSAGE_TO_CHAT_HISTORY",
  ADD_BUTTON_FEEDBACK: "paulChat/ADD_BUTTON_FEEDBACK",
  SET_CHAT_MESSAGE_CONTEXT: "paulChat/SET_CHAT_MESSAGE_CONTEXT",
  SET_SENDING_MESSAGE: "paulChat/SET_SENDING_MESSAGE",
  SET_USER_SESSION: "paulChat/SET_USER_SESSION",
  SEND_FEEDBACK_ON_LEAVING: "paulChat/SEND_FEEDBACK_ON_LEAVING",

  SEND_FEEDBACK_REQUEST: "paulChat/SEND_FEEDBACK_REQUEST",
  SEND_FEEDBACK_SUCCESS: "paulChat/SEND_FEEDBACK_SUCCESS",
  SEND_FEEDBACK_FAILURE: "paulChat/SEND_FEEDBACK_FAILURE",

  REMOVE_OLD_FEEDBACK_BUTTON: "paulChat/REMOVE_OLD_FEEDBACK_BUTTON",

  UPDATE_WATSON_CHAT_SUBJECT: "paulChat/UPDATE_WATSON_CHAT_SUBJECT",
  INCREASE_WATSON_CHAT_SUBJECT_COUNTER: "paulChat/INCREASE_WATSON_CHAT_SUBJECT_COUNTER",
  ON_SUBJECT_CHANGED: "paulChat/ON_SUBJECT_CHANGED",
}
