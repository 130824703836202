import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} text //text displayed next to the component
 * @param {String} transform //{UPPERCASE, LOWERCASE} transformation of the text source
**/

export default ({
  text = DEFAULT_VALUES.STRING,
  transform = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        text={text}
        transform={transform}
      />
    </>
  );
};
