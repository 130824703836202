import React from "react";
import SocialLogin from "react-social-login";
import styles from "../styles";

class SocialFacebook extends React.Component {
  render() {
    return (
      <button
        style={styles.buttonFacebook}
        onClick={this.props.triggerLogin}
        {...this.props}
      >
        {this.props.children}
      </button>
    );
  }
}

export default SocialLogin(SocialFacebook);
