import React from 'react';

import * as Styles from './styles'

export function LitButton({visual, children, ...rest}){
  switch (visual) {
    case 'outline':
      return (
        <Styles.ButtonOutline {...rest}>
          {children}
        </Styles.ButtonOutline>
      )
    default:
      return (
        <Styles.ButtonDefault {...rest}>
          {children}
        </Styles.ButtonDefault>
      )
  }
}
