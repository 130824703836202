/**
 * Types
 */

export const Types = {
  GET_NOTIFICATION_REQUEST: "notifications/GET_NOTIFICATION_REQUEST",
  GET_NOTIFICATION_SUCCESS: "notifications/GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "notifications/GET_NOTIFICATION_FAILURE",

  GET_COUNT_NOTIFICATIONS_REQUEST: "notifications/GET_COUNT_NOTIFICATIONS_REQUEST",
  GET_COUNT_NOTIFICATIONS_SUCCESS: "notifications/GET_COUNT_NOTIFICATIONS_SUCCESS",
  GET_COUNT_NOTIFICATIONS_FAILURE: "notifications/GET_COUNT_NOTIFICATIONS_FAILURE",
}
