import styled, { css } from "styled-components";
import { ORIENTATION } from "../../../constants";

export const Div = styled.div`
  
  ${props => (props.orientation === ORIENTATION.HORIZONTAL) && css`
    display: inline-block;
  `}

  ${props => (props.orientation === ORIENTATION.VERTICAL) && css`
    margin-top: 5px;
    display: block;
    width: 100%;
    label {
      display: block;
    }
  `}
  
`;
