import styled from "styled-components";

import { LitButton } from '../../../shared/LitButton';

export const Main = styled.div`
  .box-buttons {
    margin-top: 7px;
    @media(max-width: 991px) {
      margin-top: 0 !important
    }
  }
`

export const Container = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
`;

export const DiscountText = styled.span`
  margin-left: 0;
  display: inline-block;
  margin-bottom: 12px;
`;

export const DescriptionText = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Box = styled.div`
  padding: 4px;
  text-align: center;
  background-color: #27AAE1;
  font-size: 20px;
  width: 100%;

  & + div {
    display: flex;
    align-items: space-between;
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
    color: #FCEE1E;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #FCEE1E;
`;

export const TextTimer = styled(Box)`
`;

export const Form = styled.form``;

export const Button = styled.button`
  padding: 4px 10px;
  border-radius: 5px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 15px;

  background: white;
  color: #27aae1;
  border: 2px solid #27aae1;
  &:hover {
    color: white;
    background: #27aae1;
  }

  span {
    display: inline-block;
    margin: 0 5px;
    flex: 1;
  }
`;

export const QrCodeImage = styled.div`
  margin-top: 20px;
  width: 300px;
  height: 300px;
`;

export const ItemFormField = styled.div`
  border: 2px solid #27aae1;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
`
export const ItemFormFieldDisabled = styled.div`
  border: 2px solid #27aae1;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: #e9ecef;

  input{
    background-color: #e9ecef !important;
  }
`
export const ItemFormFieldSelect = styled.div`
  border: 2px solid #27aae1;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
  min-height: 53px !important;

  div{
    border:none !important;
  }

  select{
    border:none !important;
    height: 53px !important;
    background: transparent !important;
    padding: 15px;
  }
`

export const TermText = styled.label`
  p {
    text-align: left !important;
    font-size:12px;
  }
`

export const LabelField = styled.label`
  font-weight: bold;
  color: #444;
  margin-bottom: 10px;
`

export const InputText = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 19.9rem; */
  height: 51px;
  border: none;
  background-color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px;
  padding: 0rem 1rem 0rem;

  &:-webkit-input-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19.9rem;
    height: 3.2rem;
    border: none;
    border-radius: 5px;
    font-size: 19px;
    padding: 0rem 1rem 0rem;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus {
    outline: none;
    padding: 0rem 1rem 0rem;
  }

  &::before {
    background: linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%);
  }

  &:focus ~ label {
    visibility: visible;
  }
`;

export const MainQrCode = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const CustomButton = styled(LitButton)`
  padding: 5px 20px;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  
  & + button {
    margin-left: 15px;
  }

  span {
    display: inline-block;
    margin: 0 5px;
    flex: 1;
  }

  @media (max-width: 768px) {
    width: 100%;
    & + button {
      margin-left: 0px;
      margin-top: 15px;
    }
  }
`

export const ContainerOfferDetails = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ContainerQrCode = styled.div`
  width: 50%;
  max-width: 540px;
  @media (max-width: 768px) {
    width: 100%;
  }
`
