import React, {Component} from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { Creators as OnboardingActions } from "../../../../store/ducks/onboarding/actions"
import {saveOnboardingSteps} from '../../../../business/onboarding'

import ToggleButton from "../../../Carreira/ToggleButton"

import {
  Container, 
  Title,
  ContainerForm,
  ContainerButtons, 
  TextError,
} from './styles'

class OnboardingPageInterests extends Component {

  constructor(props) {
    super(props)
    const {data, previousAnswer, isVisibleTitle} = props
    const currentAnswer = previousAnswer || []
    if (currentAnswer.length === 0) {
      for (let i = 0; i < data.qx_userinterests.length; i += 1) {
        const interestWithWeight = data.qx_userinterests.find(it => it.nu_size === i);
        if (interestWithWeight) {
          currentAnswer.push(interestWithWeight.rk_typesuggestion);
        }
      }
    }
    this.state = {
      currentSelection: currentAnswer,
      errorMessage: null,
      isVisibleTitle
    }
  }

  toggleInterest = (type) => {
    const {currentSelection} = this.state
    const index = currentSelection.findIndex(it => it === type);

    if (index === -1) {
      this.setState(
        (previousState) => ({
          currentSelection: previousState.currentSelection.concat(type),
        }),
        this.validateSelection
      );
    } else {
      this.setState(
        (previousState) => ({
          currentSelection: previousState.currentSelection.filter(
            (item, i) => i !== index
          ),
        }),
        this.validateSelection
      );
    }
  };

  getSizeFromType = (type) => {
    const {currentSelection} = this.state
    const index = currentSelection && currentSelection.findIndex(it => it === type);
    if (index === -1) return null;
    return index + 1;
  }

  saveForm = async () => {
    const possibleError = this.validateSelection();
    if (possibleError) {
      throw new Error(possibleError);
    }
    const {currentSelection} = this.state
   
    const formData = {
      wx_step: "INTERESTS",
      ox_formdata: {
        qx_interests: currentSelection.map((type, i) => ({
          rk_typesuggestion: type,
          nu_size: i + 1,
        })),
      }
    }
    this.saveDataInState(true) 
    await saveOnboardingSteps(formData)
  };

  saveDataInState = (concluded = false) => {
    const {currentSelection} = this.state
    const {index, setOnbordingPageConcluded, onboardingData, setAnswersInterest} = this.props;
    const dataActiveStep = Object.assign({}, onboardingData[index]);
    setAnswersInterest(currentSelection)
    setOnbordingPageConcluded(index, dataActiveStep, onboardingData[index].bf_concluded || concluded)
  }

  validateSelection = () => {
    let result = null;
    const {currentSelection} = this.state
    if (currentSelection.length === 0) {
      result = 'Selecione ao menos uma opção.';
    }
    if (currentSelection.length > 5) {
      result = 'Por favor, selecione no máximo 5 itens.';
    }
    this.setState({errorMessage: result})
    return result;
  };

  render() {
    const {index, data, isVisibleTitle = true} = this.props
    const {errorMessage} = this.state
    return(
      <Container className="container">
        {isVisibleTitle && <Title> {index + 1}. Seus Interesses</Title>}
        <ContainerForm>
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className="row justify-content-md-center">
                  <div className="col-md-12 text-center text-body-pi">
                    <h5 style={{ color: "#27aae1" }} className="text-center">
                      Quais áreas você gostaria de aprimorar?
                    </h5>
                    <p className="text-justify">
                      Selecione suas principais áreas de interesse, para que possamos te conhecer um pouco mais. 
                      Esta seleção irá nos ajudar a recomendar os melhores cursos para você, personalizados de acordo com seus objetivos.
                    </p>
                  </div>
                  <ContainerButtons>
                    <div
                        style={{ display: "flex", flexWrap: "wrap"}}
                        className="grupo-interesse"
                        data-toggle="buttons"
                      >
                        {data.qx_userinterests.map((interesse) => (
                          <ToggleButton
                            key={interesse.rk_typesuggestion}
                            id={`btn-${interesse.rk_typesuggestion}`}
                            idTrackSuggestion={interesse.rk_typesuggestion}
                            name={interesse.wx_namesuggestion}
                            handleSelect={() =>
                              this.toggleInterest(interesse.rk_typesuggestion)
                            }
                            grau={this.getSizeFromType(interesse.rk_typesuggestion)}
                          />  
                        ))}
                    </div>
                    {errorMessage ? (
                      <TextError>
                        {errorMessage}
                      </TextError>
                    ) : (
                      <TextError>&nbsp;</TextError>
                    )}
                  </ContainerButtons>
                </div>
              </div>
            </div>
          </div>   
        </ContainerForm>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  onboardingData: state.onboarding.onboardingData,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...OnboardingActions
}, dispatch)


export default connect(
    mapStateToProps, 
    mapDispatchToProps, 
    null, 
    {
      forwardRef: true
    }
)(OnboardingPageInterests)
