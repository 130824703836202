import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { Container } from "./styles";

function ExitAlert() {
  return (
    <Container>
      <h3>Sair da avaliação?</h3>
      <h4>Você está perto de sair da sua avaliação.</h4>
      <p>
        Você já iniciou esta avaliação e uma tentativa será contada. Confirma
        que deseja sair sem finalizar?
      </p>
      <AiOutlineCloseCircle color="#555" />
    </Container>
  );
}

export default ExitAlert;
