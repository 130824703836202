import React, { Component } from "react"

import AvatarNetworkRightSideBar from "../AvatarUsuarios/AvatarNetworkRightSideBar"

import api from "../../utils/api"

class Networking extends Component {
  state = {
    networkingList: {},
  }

  componentDidMount() {
    this.fetchNetworking()
  }

  fetchNetworking = async () => {
    const { idProfile } = this.props
    try {
      const response = await api.get(`getSocialNetworkingByProfile/${idProfile}`)
      this.setState({ networkingList: response })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { networkingList } = this.state
    return (
      <div className="card">
        <div className="card-header d-flex">
          <h5>
            <i className="fa fa-sitemap" /> Últimas interações
          </h5>
        </div>
        <div className="card-body" style={{ padding: 0 }}>
          <ul className="list-group">
            {networkingList.length > 0 &&
              networkingList.map((perfil, index) => {
                if (index > 4) return null
                return <AvatarNetworkRightSideBar key={perfil.idProfile} perfil={perfil} />
              })}
          </ul>
        </div>
      </div>
    )
  }
}

export default Networking
