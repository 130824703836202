import React from "react"

const Cargo = ({ experience, lastClass }) => {
  return (
    <div>
      <div className="row">
        <div className="col-10">
          <h5>{experience.profession}</h5>
          <span>{experience.company}</span>
          <p>
            {experience.dateInit} - {experience.dateEnd ? experience.dateEnd : "Até o momento"}
          </p>
        </div>
      </div>
      <div className="row">
        <div className={`col-12 cargo-usuario--container ${lastClass}`}>
          {experience.aboutProfession ? (
            <p>{experience.aboutProfession}</p>
          ) : (
            <p>Nenhuma descrição adicionada</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Cargo
