import styled from "styled-components";
import { } from "../../../constants";

export const ModalBody = styled.div.attrs({
  className: "modal-body"
})`
  padding: 2rem;
  margin: 1rem 0;
  position: relative;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-right: 5px;
  }

  @media (max-width: 675px) {
    li {
      padding-left: 20%;
      display: inline-grid;
      text-align-last: center;
      padding-bottom: 16px;
    }
    img {
      margin-left: 45px;
    }
  }

  @media (max-width: 484px) {
    li {
      padding-left: 7% !important;
    }
    img {
      margin-left: 28% !important;
    }
  }
`;

export const Info = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  margin: 0 0 2rem;
  float: right;
  p {
    line-height: 1;
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 1060px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
