import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import SocialLogin from "../../SocialLogin";
import RegisterForm from "./RegisterForm";
import { Wrapper, RightSide, LoginBox } from "../styles";
import { getStatusServer } from "../../../utils/functions";

const TextH3Header = styled.h4`
  color: #02c4fd;
  margin-bottom: 0;
  text-align: justify;
  width: 100%;
`;
const TextH4Header = styled.h4`
  color: #02c4fd;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
`;

const LinkLogin = styled(Link)`
  color: #02c4fd;
  text-decoration: underline;
`;

export default function LIT({
  handleRegisterSuccess,
  handleRegisterFailure,
  handleSocialSuccess,
  handleSocialFailure,
  userDetails,
  promo,
  origin,
  litpass,
  isVocacao
}) {

  const [goToLoginURL, setGoToLoginURL] = useState('');

  useEffect(() => {
    // getStatusServer();
    let url = '/';
    const loginTenant = localStorage.getItem('loginTenant');

    if(litpass){
      url+=`litpass/`
    } else if(isVocacao){
      url+=`vocacao/`
    }

    if(loginTenant){
      url+=`${loginTenant.toLowerCase()}`
    }

    if(origin && origin !== "default"){
      url+=`?o=${origin}`
    }

    setGoToLoginURL(url);
  }, []);

  return (
    <div className="row">
      <LeftSideBar
        promo={promo}
        origin={origin}
        litpass={litpass}
        isVocacao={isVocacao}
      />
      <RightSide className="col-md-5">
        <LoginBox origin={origin}>
          {litpass && (
            <TextH3Header>
              Inscreva-se agora com seu e-mail corporativo
            </TextH3Header>
          )}
          {isVocacao && (
            <TextH3Header>
              Inscreva-se agora com seu e-mail corporativo
            </TextH3Header>
          )}
          {!litpass && !isVocacao && (
            <TextH4Header>Inscreva-se agora</TextH4Header>
          )}

          <Wrapper>
            <div className="criar-conta">
              <p>
                Já é aluno?{" "}
                <LinkLogin to={goToLoginURL}>Faça seu login.</LinkLogin>
              </p>
            </div>
            <RegisterForm
              userDetails={userDetails}
              litpass={litpass}
              isVocacao={isVocacao}
              doRegisterSuccess={handleRegisterSuccess}
              doRegisterFailure={handleRegisterFailure}
            />
          </Wrapper>
          {!litpass && !isVocacao && (
            <div className="mt-1 align-self-center">
              <p>Ou faça login pelas redes sociais </p>
              <SocialLogin
                handleSocialLogin={handleSocialSuccess}
                handleSocialError={handleSocialFailure}
              />
            </div>
          )}
        </LoginBox>
      </RightSide>
    </div>
  );
}
