import styled, {keyframes} from "styled-components";
import { darken } from "polished";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media(max-width: 710px) {
    flex-direction: column-reverse;
  }

  aside {
    width: 150px;
    margin-right: 50px;
    @media(max-width: 710px) {
      width: 100%;
      margin-right: 0;
    }
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media(max-width: 710px) {
      flex-direction: column-reverse;
    }
  }
`;

export const NavigationContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;


  button {
    margin-right: 10px;
  }

  @media(max-width: 710px) {
    margin: 10px 0;
    justify-content: space-between;
    button {
      margin-right: 0px;
      & + button {
        margin-left: 10px;
      }
    }
  }
`

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: ${({ color }) => color || "transparent"};
  border: 0px solid ${({ color }) => (color ? darken(0.1, color) : "#212529")};
  max-width: 250px;
  cursor: pointer;
  font-weight: 500;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: #fff;
  &#finishButton{
    color: #fff;
  } 

  @media(max-width: 710px) {
    &#exitButton {
      width: 100% ;
      max-width: 100%;
      margin-bottom: 10px;
    }
    &#finishButton {
      width: 100%;
      max-width: 100%;
    }
  }

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.3 : 0.5)};
  }

  svg {
    margin-right:10px;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    animation: ${rotate360} 1s linear infinite;
  }
  padding: 0;
  margin: 0;
`;

