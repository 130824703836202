import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  /* background: #f5f5f5; */
  padding: 10px;
  margin: 30px 0;
`;

export const Title = styled.h3`
  width: 100%;
  text-align: center;
`;

export const WrapperInfo = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;

  @media (max-width: 673px) {
    flex-wrap: wrap;
  }
`;

export const Spacing = styled.div`
  margin-top: 20px;
`;

export const WrapperMedias = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const BoxInfo = styled.div`
  background: #fff;
  width: ${props => (props.width ? `${props.width}px` : "250px")};
  height: 100px;
  padding: 7px 5px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 673px) {
    width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  p {
    text-transform: uppercase;
    font-size: 14px;
    margin: auto;
  }

  p.highlight {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const Button = styled.button`
  background: #27AAE1;
  border-radius: 5px;
  width: ${props => (props.width ? `${props.width}px` : "110px")};
  height: ${props => (props.height ? `${props.height}px` : "30px")};
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 5px 10px;
  cursor: pointer;
  font-family: Lato;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const BoxVideo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
