import styled from 'styled-components';

export const TooltipContainer = styled.div`

    padding: 0 15px;

    div[aria-expanded="false"]::after {
        content: " \f107";
        font-family: FontAwesome;
        display: flex;
        text-align: center;
        padding-left: 16px;
        right: 15px;
        position: relative;
        align-items: center;
        color: #27AAE1;
        font-size: 30px;
    }
    
    div[aria-expanded="true"]::after {
        content: " \f106";
        font-family: FontAwesome;
        display: flex;
        text-align: center;
        padding-left: 16px;
        right: 15px;
        position: relative;
        align-items: center;
        color: #27AAE1;
        font-size: 30px;
    }

    div[data-icon-numeric="false"]::after {
        content: " \f105";
        font-family: FontAwesome;
        display: flex;
        text-align: center;
        padding-left: 16px;
        right: 15px;
        position: relative;
        align-items: center;
        color: #27AAE1;
        font-size: 30px;
    }
`

export const Container = styled.div`
    padding: 0px;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
`

export const ContainerIcon = styled.div`
    background-color: #27AAE1;
    color: white;
    margin-right: 10px;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 55px;
    height: 48px;
    border-radius: 100px;
`

export const ItemDescription = styled.div`
    padding: 20px 0;
    flex-basis: 85%;
    display: flex;
    color: #4A5057;
`

