import React from "react";
import CardCursoNovoConcluido from "../../CardCursoNovoConcluido";
import CardCursoConcluido from "../../CardCursoConcluido";


const CertifiedCard = ({ course, index, abrirCertificado, abrirCertificadoNovo }) => {
  return course.newCourse ? (
    <CardCursoNovoConcluido
      index={index}
      cover={course.coverUrl}
      grade={course.gradeAverage}
      onOpenCertificate={() => abrirCertificadoNovo(course.certificateUrl)}
    />
  ) : (
    <CardCursoConcluido
      index={index}
      id={course.totvsId}
      grade={course.grade}
      course={course}
      onOpenCertificate={() => abrirCertificado(course)}
    />
  );
};

export default CertifiedCard;
