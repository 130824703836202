import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  /* padding: 0 20px;  */

  @media (max-width: 420px) {
    flex-wrap: wrap;
    flex-direction: column
  }
`

export const ActionButton = styled.button`
  padding: 4px 10px;
  border-radius: 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid  transparent;
  display: flex;
  align-items: center;

  background: white;
  color: #27aae1;
  border: 2px solid #27aae1;
  &:hover {
    color: white;
    background: #27aae1;
  }

  span {
    display: inline-block;
    margin: 0 5px;
    flex: 1;
  }

`

export const PreviousButton = styled(ActionButton)`
  visibility: ${props => props.hide ? 'hidden' : 'visible'};
`

export const NextButton = styled(ActionButton)`
  visibility: ${props => props.hide ? 'hidden' : 'visible'};
`

export const Steps = styled.div`
  flex: 1;
  margin: 0 20px;
  display: flex;
  align-items: center;
  width: auto;

  justify-content: center;
`

export const Step = styled.span`
  display: inline-block;
  background: ${props => !props.active ? '#c1c1c1' : '#27aae1'};
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  & + span {
    margin-left: 10px;
  }
`

export const StepConcluded = styled.span`
  display: inline-block;
  background: ${props => props.active ? '#27aae1' : '#51d7b5'};
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  & + span {
    margin-left: 10px;
  }
`

export const SkipStepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SkipStepButton = styled.button`
  padding: 10px;
  border-radius: 30px;
  min-width: 120px;
  text-transform: uppercase;
  background: transparent;
  font-size: 14px;
  cursor: pointer;
  border: 0;
  color: #27aae1;
  /* background-image: linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%); */
  /* background: #27aae1; */
`