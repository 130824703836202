/* eslint-disable no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { API_URL, GATEWAY_URL } from './constants';
import { getToken, login, logout, TOKEN_KEY } from './auth';

const endpointsTimeout = {};
let timeout;

const api = axios.create({
  baseURL: API_URL
});

const verify = axios.create({
  baseURL: API_URL
});

const gateway = axios.create({
  baseURL: GATEWAY_URL
});

const refreshAuthLogic = failedRequest => {
  if (failedRequest.response.config.headers.Authorization) {
    gateway
      .post(`gateway/token/refresh`)
      .then(tokenRefreshResponse => {
        localStorage.setItem(TOKEN_KEY, tokenRefreshResponse.token);
        document.location.reload(true);
        failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.token}`;
        return Promise.resolve();
      })
      .catch((err) => {
        console.log('refreshAuthLogic', err);  
        localStorage.clear();
        window.location.href = window.location.origin;
      });
  }
};

createAuthRefreshInterceptor(api, refreshAuthLogic);
createAuthRefreshInterceptor(gateway, refreshAuthLogic);

api.interceptors.request.use(async config => {
  const token = getToken();

  const { baseURL, url } = config;

  const completeUrl = `${baseURL}${url}`;
  const local = { completeUrl, time: new Date().getTime() };
  endpointsTimeout[completeUrl] = local;

  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

gateway.interceptors.request.use(async config => {
  const token = getToken();
  const tenant = localStorage.getItem('tenant');
  const profileCode = localStorage.getItem('crCodeProfile');
  if (token) {
    config.headers.Authorization = token;
  }
  if (tenant) {
    config.headers.Tenant = tenant;
  }

  if (profileCode && tenant !== 'ALL') {
    config.headers.ProfileCode = profileCode;
  }

  if (profileCode && tenant === 'ALL') {
    config.headers.ProfileCode = localStorage.getItem('tokenCrCode');
  }

  return config;
});

// const requestInterceptor = config => config;
const responseSuccessInterceptor = response => {
  delete endpointsTimeout[response.config.url];
  clearTimeout(timeout);
  const token = response.headers.authorization;
  if (token) {
    login(token);
  }

  return response.data;
};

const responseFailureInterceptor = async error => {
  error.response = {
    data: {
      message: 'Solicitação cancelada'
    },
    status: 499,
    ...error.response
  };

  try {
    const {
      response: { status }
    } = error;

    if (status === 403) {
      logout();
      localStorage.setItem(
        'redirectExpired',
        'Acesso expirado, faça login novamente'
      );
      window.location.href = window.location.origin;
    }
    return Promise.reject(error);
  } catch (e) {
    console.log('erro sem response', e);
    try {
      const response = await verify.get('health');
      if (response) {
        if (response.data && response.data.status === 'UP') {
          return Promise.reject(e);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
};

// Add a response interceptor
api.interceptors.response.use(
  responseSuccessInterceptor,
  responseFailureInterceptor
);
gateway.interceptors.response.use(
  responseSuccessInterceptor,
  responseFailureInterceptor
);

export default api;
export { gateway };
