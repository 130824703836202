/**
 * Types
 */

export const Types = {
  SET_PERSONALITY_INSIGHT: "user/SET_PERSONALITY_INSIGHT",

  GET_PERSONALITY_INSIGHT_REQUEST: "user/GET_PERSONALITY_INSIGHT_REQUEST",
  GET_PERSONALITY_INSIGHT_SUCCESS: "user/GET_PERSONALITY_INSIGHT_SUCCESS",
  GET_PERSONALITY_INSIGHT_FAILURE: "user/GET_PERSONALITY_INSIGHT_FAILURE",
}
