import React from "react";
import { TabLink, AreaLink } from "./styles";

export default ({ child, id, appearance }) => {
  return (
    <>
      <TabLink appearance={appearance}>
        <AreaLink eventKey={id}>{child}</AreaLink>
      </TabLink>
    </>
  );
};
