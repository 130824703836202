import React from "react"

const SobreUsuario = ({ about }) => {
  return (
    <div className="cursos bg-white-perfil" id="sobre-usuario">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <h5>Sobre</h5>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {about ? (
              <p style={{ whiteSpace: "pre-line" }}>{about}</p>
            ) : (
              <p>Nenhuma descrição adicionada</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SobreUsuario
