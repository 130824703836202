import styled, { css } from "styled-components";
import { ORIENTATION, SIZE } from "../../../constants";

export const Limit = styled.div`

  width: 100%;
  clear: both;
  display: block;
  height: calc(100vh - 400px);
  overflow-y: auto;
  margin: 2rem 0;
  padding-right: 1rem;
  @media (max-width: 480px) {
    height: calc(100vh - 200px);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(var(--overflow-bar-shadow), 0.3);
    background-color: var(--overflow-track);
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: var(--overflow-track);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--overflow-bar-shadow);
    border: 2px solid var(--overflow-bar);
    border-radius: 10px;
  }

  ${props => (props.orientation === ORIENTATION.HORIZONTAL) && css`
    width: 100%;
    clear: both;
    display: flex;
    height: auto;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding: 1rem 0 2rem;
    @media (max-width: 480px) {
      height: auto;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(var(--overflow-bar-shadow), 0.3);
      background-color: var(--overflow-track);
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      height: 8px;
      background-color: var(--overflow-track);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--overflow-bar-shadow);
      border: 2px solid var(--overflow-bar);
      border-radius: 10px;
    }
  `}

  ${props => (props.orientation === ORIENTATION.VERTICAL) && css`
    width: 100%;
    clear: both;
    display: block;
    height: calc(100vh - 400px);
    overflow-y: auto;
    margin: 2rem 0;
    padding-right: 1rem;
    @media (max-width: 480px) {
      height: calc(100vh - 200px);
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(var(--overflow-bar-shadow), 0.3);
      background-color: var(--overflow-track);
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      background-color: var(--overflow-track);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--overflow-bar-shadow);
      border: 2px solid var(--overflow-bar);
      border-radius: 10px;
    }
  `}

  ${props => (props.orientation === ORIENTATION.VERTICAL && props.size === SIZE.EXTRA_LARGE) && css`
    height: calc(100vh - 150px);
    margin: 0;
  `}
  ${props => (props.orientation === ORIENTATION.VERTICAL && props.size === SIZE.SMALL) && css`
    height: 150px!important;
    margin: 0 0 2rem;
    @media (max-width: 480px) {
      height: 240px!important;
    }
  `}
  ${props => (props.orientation === ORIENTATION.VERTICAL && props.size === SIZE.MEDIUM) && css`
    height: 220px!important;
    margin: 0 0 2rem;
  `}
  ${props => (props.orientation === ORIENTATION.VERTICAL && props.size === SIZE.LARGE) && css`
    height: 250px!important;
    margin: 0 0 2rem;
    @media (max-width: 480px) {
      height: 230px!important;
    }
  `}
  
  ${props => (props.orientation === ORIENTATION.HORIZONTAL && props.size === SIZE.MEDIUM) && css`
    margin: 1rem 0;
    padding: 0;
  `}
  
  ${props => (props.size === SIZE.EXTRA_LARGE) && css`
    height: calc(100vh - 150px);
    margin: 0;
  `}

`;
