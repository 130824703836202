import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Boolean} hasCurrentCourse //check if there is a course in progress
 * @param {Array} infoProgress //content displayed in the block
**/

export default ({
  hasCurrentCourse = DEFAULT_VALUES.BOOLEAN,
  infoProgress = DEFAULT_VALUES.LIST
}) => {
  return (
    <>
      <Screen
        hasCurrentCourse={hasCurrentCourse}
        infoProgress={infoProgress}
      />
    </>
  );
};
