/* eslint-disable camelcase */
import React from 'react'

import {Container, Text} from './styles'

export default function Info(
    {wx_institution,
     dx_dateinit,
     wx_course, 
     wx_formationtype, 
     dx_dateend
  }){
  return(
    <Container>
      <Text>{wx_formationtype} em {wx_course}</Text>
      <Text>{wx_institution}</Text>
      {dx_dateend ? (
        <Text>De {dx_dateinit} a {dx_dateend}</Text>
      ) : (
        <Text>Em andamento</Text>
      )}
    </Container>
  )
}