import styled, { css } from "styled-components";
import { COLOR } from "../../../constants";

export const Hr = styled.hr`
  
  ${props => (props.color === COLOR.PRIMARY) && css`
    background: var(--divisor-primary);
  `}

  ${props => (props.color === COLOR.TEXT_PRIMARY) && css`
    background: var(--divisor-gray);
  `}
  
  ${props => (props.color === COLOR.SECONDARY) && css`
    background: var(--divisor-secondary);
  `}
  
  ${props => (props.color === COLOR.LIGHT_GRAY) && css`
    background: var(--divisor-light-gray);
  `}
  
  ${props => (props.color === COLOR.LIGHT) && css`
    background: var(--divisor-light);
  `}

`;
