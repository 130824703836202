import styled from 'styled-components';

export const Container = styled.div`
  width: 230px;
  height: 245px;
  overflow-x:hidden;
  overflow-y: auto;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;


  img {
    width: 100%;
  }

  .card-body {
    width: 100%;
    img {
      width: 100%;
    }
  }

  position: relative;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

`;

export const ProgressIndicator = styled.div`
  position: absolute;
  margin: 0;
  padding: 0;
  background: #27aae1;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  top: 6px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    font-size: 14px;
  }
`
