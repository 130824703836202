import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import notify from '../../notify'
import { CouponLabel, Button, Title } from "./styles";

export default function Coupon ({ coupon }) {
  const handleCopyToClipboard = (type) => {
    if (type === 'link') {
      notify(`Copiado link de desconto`)
    } else {
      notify('Copiado cupom de desconto')
    }
  }
  
  return (
    <div className="row" id="compartilhar-cupom">
      <div className="col-md-12">
        <Title>Cupom de desconto</Title>
      </div>
      
      <div className="col-md-9">
        <CouponLabel>{coupon}</CouponLabel>
      </div>
      
      <div className="col-md-3">
        <CopyToClipboard text={coupon} onCopy={() => handleCopyToClipboard('cupom')}>
          <Button className="btn btn-blue">
            Copiar
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  )
}
