import styled from "styled-components";
import { } from "../../../../constants";

export const Menu = styled.div`
  @media (max-width: 991px) {
    margin: auto;
    display: table;
    span {
      display: inline-block;
      width: auto;
      margin: 4px 4px 4px 8px;
    }
  }
`;
