import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { FiHelpCircle } from "react-icons/fi";
import ReactFlagsSelect from "react-flags-select";

import * as Styles from "./styles";

function Header({ onBack }) {
  return (
    <Styles.Container>
      <button type="button" className="backButton" onClick={onBack}>
        <FaChevronLeft />
        Voltar
      </button>

      <div>
        <div className="help">
          <FiHelpCircle />
          OBTER AJUDA
        </div>

        <ReactFlagsSelect
          countries={["BR"]}
          selected="BR"
          customLabels={{
            US: " ",
            BR: " "
          }}
          onSelect={code => console.log(code)}
        />
      </div>
    </Styles.Container>
  );
}

export default Header;
