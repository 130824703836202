/**
 * Types
 */
export const Types = {
  GET_PROFILE_REQUEST: "user/GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "user/GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILURE: "user/GET_PROFILE_FAILURE",

  SET_DIRECT_OPEN_PAYMENT: "user/SET_DIRECT_OPEN_PAYMENT",

  TOGGLE_MODAL_RESTRICT: "user/TOGGLE_MODAL_RESTRICT",
  TOGGLE_MODAL_RESTRICT_LITPASS: "user/TOGGLE_MODAL_RESTRICT_LITPASS",
  TOGGLE_VIEW_NPS: "user/TOGGLE_VIEW_NPS",
  SEND_NPS_REQUEST: "user/SEND_NPS_REQUEST",
  SEND_NPS_SUCCESS: "user/SEND_NPS_SUCCESS",
  SEND_NPS_FAILURE: "user/SEND_NPS_FAILURE",
}
