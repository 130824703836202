import React, { memo } from "react"
import SliderWrap from "./SliderWrap"

const StyleNovidades = {
  background: "#FFF",
  marginLeft: "-15px",
  width: "102%",
  padding: "10px 10px 10px 20px",
}

const LaneHoc = ({ novidades, tooltipText, title, children, paginationHook }) => {
  return (
    <div className="cursos col-12" style={{ zIndex: 26 }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h6 className="d-inline-block" data-for="home" data-tip={tooltipText}>
              {title}
            </h6>
          </div>
        </div>
      </div>
      <div className="container-fluid curso-slider" style={novidades && StyleNovidades}>
        <SliderWrap paginationHook={paginationHook}>{children}</SliderWrap>
      </div>
    </div>
  )
}

export default memo(LaneHoc)
