/* eslint-disable react/no-danger */
import React from "react";
import mathlive from "mathlive";

import MultipleChoice from "./subcomponents/MultipleChoice";
import SingleChoice from "./subcomponents/SingleChoice";
import Descriptive from "./subcomponents/Descriptive";
import TrueOrFalse from "./subcomponents/TrueOrFalse";
import Upload from "./subcomponents/Upload";

import SurveyPdfComponent from './subcomponents/SurveyPdfComponent';
import SurveyImageComponent from './subcomponents/SurveyImageComponent';
import SurveyVideoComponent from './subcomponents/SurveyVideoComponent';

import { Container, Title, EnumCard, ContainerAlternative, Alternative } from "./styles";

import ViewEditor from '../../../shared/ViewEditor';

const mappedComponents = {
  MULTIPLE_CHOICE: MultipleChoice,
  SINGLE_CHOICE: SingleChoice,
  DESCRIPTIVE: Descriptive,
  TRUE_OR_FALSE: TrueOrFalse,
  PROGRESSIVE: SingleChoice,
  UPLOAD: Upload
};

const mappedMidiaTypeComponents = {
  VIDEO: SurveyVideoComponent,
  PDF: SurveyPdfComponent,
  IMAGE: SurveyImageComponent,
};


function SurveyView({
  onDisableActions,
  surveyData,
  surveyAnswers,
  onAnswerChange,
  numberQuestion,
  onUserComment,
  userComment
}) {
  
  function renderText(text) {
    // Pattern to identify latex expression like {\infty}
    const latexPattern = /\\\w+({.*})?/g;
    const hasLatex = latexPattern.test(text);
    if (hasLatex) {
      return (
        <span
          style={{ marginLeft: 5 }}
          dangerouslySetInnerHTML={{
            __html: mathlive.latexToMarkup(text)
          }}
        />
      );
    }

    const prepareText = text.replaceAll(/\$/g, "<span>$</span>");
    const formattedData = `<p>${prepareText || 'Sem descrição'}</p>`;
    return <ViewEditor data={formattedData} />;
  }

  function renderMedia({id, type, path, name}) {
    const MediaComponent = Object.keys(mappedMidiaTypeComponents).includes(type) &&
    mappedMidiaTypeComponents[type];

    if(!MediaComponent){
      return null; 
    }

    return <MediaComponent id={id} path={path} name={name} />
  }

  const AnswerComponent =
    surveyData &&
    Object.keys(mappedComponents).includes(surveyData.type) &&
    mappedComponents[surveyData.type];

  return (
    surveyData &&
    surveyAnswers && (
      <Container>
        <Title>Questão {numberQuestion < 10 ? `0${numberQuestion}` : numberQuestion}</Title>

        <EnumCard>
          {renderText(surveyData.statement)}
          {surveyData.media && renderMedia(surveyData.media)}
        </EnumCard>

        {surveyData.alternatives &&
          surveyData.alternatives.map((alternative, index) => {
            return (
              <ContainerAlternative
                key={alternative.id}
                noBorder={surveyData.type === 'DESCRIPTIVE' || surveyData.type === 'UPLOAD'}>
                <Alternative key={alternative.id}>
                {AnswerComponent && (
                    <AnswerComponent
                      id={alternative.id}
                      index={index}
                      renderLabel={() => (
                        <>
                          {renderText(alternative.statement)}
                        </>
                      )}
                      value={surveyAnswers[index].value}
                      onAnswerChange={onAnswerChange}
                      onDisableActions={onDisableActions}
                      onUserComment={onUserComment}
                      userComment={userComment}
                    />
                )}
              </Alternative>
               {alternative.media && renderMedia(alternative.media)}
              </ContainerAlternative>
            );
          })}
      </Container>
    )
  );
}

export default SurveyView;
