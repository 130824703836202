import React from 'react';
import PropTypes from "prop-types";
import { Line } from "rc-progress";
import {FaStar} from 'react-icons/fa';

import { COURSE_STATUS } from '../../constants';

import CardEvaluation from '../../components/CardEvaluation';
import CardLearningObject from '../../components/CardLearningObject';
import CardEvents from '../../components/CardEvent';
import Slider from '../../../../components/Slider';

import * as Styles from './styles';

function Screen({
    course,
    evaluations,
    events,
    learningObjects, 
    courseProgress, 
    redirectToCourse,
    goToLearningObjectCourse,
    viewCertificate,
  }) {

  return (
    <Styles.Container>
      <Styles.BoxInfo>
        <Styles.Progress>
          <Styles.ProgressBarContainer>
            <p>Meu progresso</p>
            <Line
              percent={courseProgress}
              strokeWidth="4"
              trailWidth="4"
              strokeColor="#27aae1"
              trailColor="#f5f5f5"
            />
          </Styles.ProgressBarContainer>
        </Styles.Progress>

        <div style={{display: 'flex'}}>
          {course.courseGradeStatus !== COURSE_STATUS['IN PROGRESS'] && (
            <Styles.Button title="Ver certificado" disabled={!course.certificate} onClick={() => viewCertificate(course.certificate)} type="button">
              <FaStar /> Média final: {course.gradeAverage}
            </Styles.Button>
          )}
         
          <Styles.Button onClick={() => redirectToCourse(course.code)} type="button">
            Continuar 
          </Styles.Button>
        </div>
        
      </Styles.BoxInfo>
     
      <div className="parent">
        <Slider>
          {learningObjects.map((learningObject, index) => {
            return (
              <Styles.StyleLane key={index}>
                <CardLearningObject goToLearningObjectCourse={goToLearningObjectCourse} learningObject={learningObject} />
              </Styles.StyleLane>
            )
          })}
        </Slider>    
      </div>        

      <div className="parent">
        <Slider>
          {evaluations.map((evaluation, index) => {
            return (
              <Styles.StyleLane key={index}>
                <CardEvaluation onClick={(el) => console.log(el)} evaluation={evaluation} />
              </Styles.StyleLane>
            )
          })}
        </Slider>    
      </div> 

      <div className="parent">
        <Slider>
          {events.map((event, index) => {
            return (
              <Styles.StyleLane key={index}>
                <CardEvents onClick={(el) => console.log(el)} event={event} />
              </Styles.StyleLane>
            )
          })}
        </Slider>    
      </div> 
    </Styles.Container>
  )
}

Screen.propTypes = {
  course: PropTypes.object.isRequired,
  courseProgress: PropTypes.number.isRequired,
  redirectToCourse: PropTypes.func.isRequired,
  viewCertificate: PropTypes.func.isRequired,
  evaluations: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  learningObjects: PropTypes.array.isRequired,
  goToLearningObjectCourse: PropTypes.func.isRequired
};


export default Screen;
