import React from "react";
import Screen from "./screen";

export default ({ groups, actionDecline, loadMoreSolicitations, hasMoreSolicitations }) => {
  return (
    <>
      <Screen
        groups={groups}
        cancelJoinSolicitation={actionDecline}
        loadMoreSolicitations={loadMoreSolicitations}
        hasMoreSolicitations={hasMoreSolicitations}
      />
    </>
  );
};
