/**
 * Select file(s).
 * @param {String} contentType The content type of files you wish to select. For instance "image/*" to select all kinds of images.
 * @param {Boolean} multiple Indicates if the user can select multiples file.
 * @returns {Promise<File|File[]>} A promise of a file or array of files in case the multiple parameter is true.
 */
function getFiles (multiple, contentType){
    return new Promise(resolve => {
        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = multiple;

        if(contentType)
            input.accept = contentType;

        input.onchange = _ => {
            let files = Array.from(input.files);
            if (multiple)
                resolve(files);
            else
                resolve(files[0]);
        };

        input.click();
    });
}

export default getFiles;