import _ from "lodash";

import { useState, useCallback } from "react";

export default () => {
  const [membersMuted, setMembersMuted] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleMembersMuted = useCallback(
    _.throttle(
      () => {
        setLoading(true);

        // TODO: Perform request to mute all users
        setMembersMuted(prevState => !prevState);

        setLoading(false);
      },
      1000,
      { trailing: false }
    ),
    [setMembersMuted]
  );

  return [membersMuted, toggleMembersMuted, loading];
};
