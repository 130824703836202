import styled from 'styled-components';

export const Container = styled.div`

  z-index: 26px;
  overflow: hidden;

  h6 {
    font-size: 1.65em;;
    margin-bottom: 10px;
    padding: 5px 7px;
  }

`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Button = styled.button`
  background: gray;
  border: none;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  
  &:hover {
    opacity:.8
  }
`
