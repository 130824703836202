import swal from '@sweetalert/with-react';
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api, { gateway } from "../../utils/api";
import { setSEOIndexOff } from "../../utils/functions";
import NovaSenha from "./NovaSenha";
import RetornoLogin from "./RetornoLogin";

const LoginHeader = styled.h3`
  font-family: "Dosis", sans-serif;
  font-size: 2.2em;
  font-weight: lighter;
  margin-bottom: 10px !important;
  font-stretch: condensed;
  color: #27aae1;
`

const Wrapper = styled.div`
  max-width: 100%;
`

class RightSideBar extends PureComponent {
  state = {
    email: "",
    error: "",
    emailEnviado: false,
    loading: false,
  }

  componentDidMount() {
    setSEOIndexOff()
  }

  recoveryPassword = async evt => {
    evt.preventDefault()
    const { email } = this.state
    this.setState({ loading: true })
    try {
      const response = await gateway.post('/webbff/lit_app_web/login/request-change-password', {
        email
      })
      if (response.status === 200) {
        this.setState({ emailEnviado: true })
        this.notify(response.message, "success")
      } else if(response.status === 202) {
        swal({
          icon: 'info',
          width: 200,
          title: `Informação!`,
          text: response.message,
          buttons: {
            cancel: {
              text: 'Não',
              value: false,
              visible: true,
            },
            confirm: {
              text: 'Sim',
              value: true,
              visible: true,
            }
          }
        });
        
      } else {
        this.setState({ error: response.message })
      }
    } catch (error) {
      console.log(error)
      this.setState({ error: error.response.data.message })
    } finally {
      this.setState({ loading: false })
    }
  }

  sendEmail = async (email) => {
    try {
      if(email) {
        this.setState({ loading: true })
        const response = await api.get(`requestCreatePassword/${email}/`)
        this.notify(response.message, "success")
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.setState({ loading: false })
    }
  
  }

  notify = (text, type = "success") => {
    toast(text, {
      type,
      closeButton: false,
    })
  }

  render() {
    const { emailEnviado, email, error, loading } = this.state

    if (emailEnviado) {
      return (
        <React.Fragment>
          <LoginHeader>Esqueci a senha</LoginHeader>
          <Wrapper>
            <div className="criar-conta">
              <p style={{ fontSize: "14px" }}>
                Um código foi enviado por email. Digite-o no campo abaixo juntamente com sua nova senha e
                volte a ter acesso a plataforma LIT.
              </p>
            </div>
            <NovaSenha />
          </Wrapper>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <LoginHeader>Esqueci a senha</LoginHeader>
        <Wrapper>
          <div className="criar-conta">
            <p style={{ fontSize: "14px" }}>
              Esqueceu sua senha? Digite seu e-mail para que possamos enviar um código para definir uma
              nova senha.
            </p>
          </div>
          <form style={{ marginTop: "2em" }} onSubmit={this.recoveryPassword}>
            <div className="field-float-label-pattern">
              <div>
                <input
                  type="email"
                  placeholder="E-mail"
                  className="form-control input-not-yellow"
                  id="inputEmailLogin"
                  value={email}
                  onChange={evt => this.setState({ email: evt.target.value })}
                  required
                />
                <label htmlFor="inputCadastroNome">Email</label>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p style={{ color: "#e50000" }}>{error}</p>
              </div>
            </div>

            <div className="row justify-content-center">
              <button
                type="submit"
                disabled={loading}
                className={`btn btn-login btn-FontWeight6${loading && " btn-loading"}`}
              >
                Redefinir
              </button>
            </div>
          </form>
          <RetornoLogin />
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default RightSideBar
