import styled from "styled-components";
import { } from "../../../constants";

export const Content = styled.div`
  padding: 1rem 0;
  display: block;
  width: 100%;
  overflow: hidden;
  margin: 2rem 0;
  column-span: all;
`;
