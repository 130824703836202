import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  /* width: 265px; */
  /* margin: 10px; */
  padding: 0 5px;
  & + div {
    /* margin-left: 10px; */
  }
  position: relative;
  text-align: center;
  font-size: 26px;
  min-width: 300px;
`

export const EmptyListText = styled.p`
  margin: 0;
  padding: 0;
`
