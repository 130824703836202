import styled, { css } from "styled-components";
import { } from "../../../constants";

export const Description = styled.span`
  width: 100%;
  margin: 0 0 2rem;
  display: block;
  padding: 0;
  @media (max-width: 991px) {
    margin: 0;
    div {
      margin: 0;
    }
  }
`;

export const Favorite = styled.span` 
  width: 20%;
  margin: 1rem;
  display: block;
  float: right;
`;

export const Author = styled.span`
  width: 100%;
  margin: 1rem 0;
  display: block;
`;

export const Info = styled.span`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1440px) {
    div > div {
      min-width: 30%;
      width: auto;
      i { float: left; }
      span { margin-left: 2px; }
      span label:last-child { -webkit-line-clamp: 1; overflow: hidden; text-overflow: ellipsis; display: -webkit-box;-webkit-box-orient: vertical; }
    }
  }
`;

export const Limit = styled.div`
  height: auto;
  ${props => (props.visible) && css`
    height: ${`${props.visible}px`};
    overflow-y: hidden;
    display: block;
  `}
`;

export const Blur = styled.div`
  background: transparent;
  border: 0;
  position: relative;
  height: 0;
  bottom: 0;
  width: calc(100% - 6px);
  &:before {
    content: "";
    background: white;
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: -10px;
    z-index: 0;
    -webkit-filter: blur(10px);
    filter: blur(10px);
  }
`;
