export default () => {
  const currentYear = parseInt(new Date().getFullYear(), 10);
  const lastYear = currentYear + 20;

  const array = [
    {
      label: "Ano",
      value: ""
    }
  ];

  for (let year = currentYear; year <= lastYear; year += 1) {
    array.push({
      value: `${year}`,
      label: `${year}`
    });
  }

  return array;
};
