import React from "react";

import IconButton from "@material-ui/core/IconButton";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

import Tooltip from '@material-ui/core/Tooltip';
import useMuteMember from '../hooks/useMuteMember';

export default React.memo(() => {
  const [muted, toggleMuted] = useMuteMember()
  return (
    <Tooltip title={muted ? "Dessilenciar Participante" : "Silenciar Participante"} aria-label="add" placement="bottom-end">
      <IconButton
        size="small"
        edge="start"
        color="inherit"
        onClick={toggleMuted}
        aria-label="mute member"
      >
        { muted ? <MicOffIcon /> : <MicIcon /> }
      </IconButton>
    </Tooltip>
  );
})
