/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";

import _ from "lodash";

import IndividualChart from "../IndividualChart";
import ProgressChart from "../ProgressChart";
import WeightsTable from "../WeightsTable";

import { Container } from "./styles";

function GeneralGraphics({ graphics, forumsData, weights }) {
  const [showCharts, setShowCharts] = useState(false);
  const [charts, setCharts] = useState([]);
  useEffect(() => {
    const forumCharts = _.get(forumsData, "graphics", []);
    if ((graphics && graphics.length > 0) || forumCharts.length > 0) {
      const foundFinalExam = (graphics || []).find(
        item => item.type === "FINAL_EXAM"
      );
      const newCharts = (graphics || []).filter(
        item => item.type !== "FINAL_EXAM"
      );
      const data = {
        performanceGraphic: foundFinalExam
          ? foundFinalExam.performanceGraphic
          : [],
        forumData: forumCharts,
        type: "FINAL_EXAMS"
      };
      const formattedCharts = [data, ...newCharts];
      setCharts(formattedCharts);
      setShowCharts(true);
    }
  }, [graphics, forumsData]);

  return (
      <Container>
        <div className="row">
          <div className="col-md-6">
            {showCharts ? (
              charts.map((chart, index) => (
                <div key={index}>
                  {chart.type === "FINAL_EXAMS" && (
                    <IndividualChart
                      data={chart.performanceGraphic}
                      forumData={[]}
                    />
                  )}
                </div>
              ))
            ) : (
              <p>Nenhum gráfico a ser exibido.</p>
            )}
          </div>
          <div className="col-md-6">
            {showCharts && (
              charts.map((chart, index) => (
                <div key={index}>
                  {chart.type === "EVALUATION_BOARD" && (
                    <ProgressChart
                      data={chart.progressGraphic}
                    />
                  )}
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <WeightsTable data={weights} />
        </div>
      </Container>
  );
}

export default GeneralGraphics;
