import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createEncryptor from "redux-persist-transform-encrypt";

import reducer from "./ducks";
import sagas from "./sagas";

const encryptor = createEncryptor({
  secretKey: "617ae98420579d264d61bc033653d875"
});

const persistConfig = {
  key: "rootLit",
  transforms: [encryptor],
  storage,
  whitelist: ["user"]
};

const persistedReducer = persistReducer(persistConfig, reducer);

const stateSyncConfig = {
  whitelist: ["new-course/REFRESH_COURSE"],
};

const sagaMiddleware = createSagaMiddleware();
const syncStateMiddleware = createStateSyncMiddleware(stateSyncConfig);

const middlewares = [sagaMiddleware, syncStateMiddleware];

const store = createStore(
  persistedReducer,
  console.tron
    ? compose(applyMiddleware(...middlewares), console.tron.createEnhancer())
    : applyMiddleware(...middlewares)
);

sagaMiddleware.run(sagas);
const persistor = persistStore(store);
initMessageListener(store);

export { store, persistor };
