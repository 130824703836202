import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 10;

  background-color: #fff;
  box-shadow: 0px 0px 26px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  height: 100vh
  transition: all 0.3s ease-out;

  left: 0;
  right: 0;
  bottom: ${({ visible }) => (visible ? "0vw" : "-150vh")};
  border-radius: 10px 20px 0 0;

  overflow-y: hidden;


  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    left: auto;
    right: ${({ visible }) => (visible ? "0vw" : "-150vw")};
    top: 0;
    border-radius:0;
    width: 75vw;
    
      
    & > div {
      width: 80%;
    
    }
  }


`;

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: ${({ visible }) => (visible ? "block" : "none")};
  z-index: 5;
`;
