import React from "react";
import { ICON, PAGE_INFORMATION, USER_ROLE, ACTION } from "../../../constants";

import ButtonModal from "../../basicComponents/ButtonModal";
import Text from "../../basicComponents/Text";
import IconInfo from "../../Info/IconInfo";

import { ModalBody, Info, Block, TextDiv, Confirm } from "./styles";

export default ({ group, action, message }) => {
  return (
    <ModalBody>
      <Block>
        <Info>
          <IconInfo
            icon={ICON.USERS}
            title={PAGE_INFORMATION.MEMBERS}
            subtitle={group.totalMembers}
          />

          <IconInfo
            icon={ICON.USERS}
            title={USER_ROLE.ADMINISTRATOR}
            subtitle={group.administrator}
          />
        </Info>
        <TextDiv>
          <Text text={group.description} />
        </TextDiv>
      </Block>
      <Confirm>
        <Text text={message} />
        <ButtonModal text={ACTION.CONFIRM} onConfirm={action} />
      </Confirm>
    </ModalBody>
  );
};
