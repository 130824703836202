import React from "react";
import Radio from '../../../../../shared/Radio';

function SingleChoice({ renderLabel, value, id, index, onAnswerChange }) {
  return (
    <Radio
      disabled
      id={`item-${id}-${index}`}
      type="radio" 
      checked={value}
      name="singlechoiceradio" 
      labelText={renderLabel && renderLabel()}
      onChange={event => {
        if (onAnswerChange) {
          onAnswerChange(index, event.target.checked);
        }
      }} 
    />
  );
}

export default SingleChoice;
