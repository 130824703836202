import styled, { css } from "styled-components";
import { NUMBER } from "../../constants";

export const UnorderedList = styled.ul` 
  
  ${props => (props.grid === NUMBER.THREE) && css` 
    padding: 15px 0;
    li {
      display: inline-block;
      width: 33%;
    }
  
    @media screen and (max-width: 880px) {
      li {
        width: 100%;
      }
    }

    @media screen and (max-width: 1439px) {
      li {
        width: 50%;
      }
    }
  `}

  ${props => (props.grid === NUMBER.TWO) && css` 
    padding: 15px 0;
    li {
      display: inline-block;
      width: 50%;
    }
    @media screen and (max-width: 880px) {
      li {
        width: 100%;
      }
    }
  `}
    
  ${props => (props.grid === NUMBER.ONE) && css`
    padding: 15px 0;
    li {
      display: inline-block;
      width: 100%;
    }
  `}
  
`;
