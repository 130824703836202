import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} children //content that will be rendered
**/

export default ({
  children = DEFAULT_VALUES.COMPONENT
}) => {
  return (
    <>
      <Screen
        child={children}
      />
    </>
  );
};
