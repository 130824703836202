import React from "react";
import {
  SIZE,
  ORIENTATION,
  PAGE_INFORMATION,
  FONT_TYPE,
  POSITION,
  APPEARANCE,
  ICON,
  COLOR,
  DEFAULT_VALUES
} from "../../../../constants";

import NavTabsContent from "../NavTabContent";
import Container from "../../../../LitAtomicComponents/containers/PageContainer";
import Column3x3 from "../../../../LitAtomicComponents/defaultAreas/Column_3x3";
import ImageInfo from "../../../../LitAtomicComponents/Info/ImageInfo";
import IconInfo from "../../../../LitAtomicComponents/Info/IconInfo";
import Block from "../../../../LitAtomicComponents/basicComponents/Block";
import ContinueBlock from "../../../../LitAtomicComponents/basicComponents/ContinueBlock";
import Line from "../../../../LitAtomicComponents/basicComponents/Line";
import Clickable from "../../../../LitAtomicComponents/basicComponents/Clickable";

import { Menu } from "./styles";

export default ({
  userProfile,
  currentCourse,
  notifications,
  feedItems,
  hasNextPage,
  nextPage
}) => {
  function validateInfo(value) {
    switch (value) {
      case undefined:
        return `${PAGE_INFORMATION.HELLO}!`;
      default:
        return `${PAGE_INFORMATION.HELLO}, ${value}!`;
    }
  }
  return (
    <>
      <Container>
        <Block color={COLOR.BLOCK_LIGHT} orientation={ORIENTATION.AFASTABLE}>
          <Column3x3
            left={[
              <ImageInfo
                image={userProfile.img}
                title={validateInfo(userProfile.name)}
                subtitle={PAGE_INFORMATION.WELCOME_AGAIN}
                imageSize={SIZE.SMALL}
                size={SIZE.LARGE}
                orientation={ORIENTATION.HORIZONTAL}
                fontType={FONT_TYPE.BOLD}
                textAlign={POSITION.LEFT}
              />
            ]}
            middle={[
              <Menu>
                <Line alignItems={POSITION.TOP_JUSTIFY}>
                  <Clickable appearance={APPEARANCE.LINK}>
                    <IconInfo
                      icon={ICON.USER}
                      title={PAGE_INFORMATION.PROFILE}
                      color={COLOR.DARK}
                    />
                  </Clickable>
                  <Clickable appearance={APPEARANCE.LINK}>
                    <IconInfo
                      icon={ICON.GRADUATION}
                      title={PAGE_INFORMATION.COURSES}
                      color={COLOR.DARK}
                    />
                  </Clickable>
                  <Clickable appearance={APPEARANCE.LINK}>
                    <IconInfo
                      icon={ICON.USERS}
                      title={PAGE_INFORMATION.GROUPS}
                      color={COLOR.DARK}
                    />
                  </Clickable>
                  <Clickable appearance={APPEARANCE.LINK}>
                    <IconInfo
                      icon={ICON.CONTACTS}
                      title={PAGE_INFORMATION.CONTACTS}
                      color={COLOR.DARK}
                    />
                  </Clickable>
                </Line>
              </Menu>
            ]}
            right={[
              <ContinueBlock
                hasCurrentCourse={userProfile.hasCurrentCourse}
                infoProgress={currentCourse.content}
              />
            ]}
          />
        </Block>
        <NavTabsContent
          notifications={notifications}
          feedItems={feedItems}
          hasNextPage={hasNextPage}
          nextPage={nextPage}
        />
      </Container>
    </>
  );
};
