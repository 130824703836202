import React from "react"

const TagA = ({ href, onSend, children }) => {
  const shouldSendMessage = href === "javascript:void(0);"

  return (
    <a
      href={href}
      style={{ color: "#fcee1e" }}
      target={shouldSendMessage ? null : "_blank"}
      onClick={shouldSendMessage ? onSend : null}
    >
      {" "}
      {children}{" "}
    </a>
  )
}

export default TagA
