import React from "react";
import { SIZE } from "../../../constants";

import Icon from "../Icon";
import { DropdownToggle } from "./styles";

export default ({ text, icon }) => {
  return (
    <>
      <DropdownToggle>
        {text}
        <Icon icon={icon} size={SIZE.MEDIUM} />
      </DropdownToggle>
    </>
  );
};
