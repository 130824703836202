import { gateway } from './api'

const STEP1_REGISTER_PROFILE = 1;
const STEP2_EMAIL_CONFIRMATION = 2;
const STEP3_VERIFY_CPF = 3;
const STEP4_PICK_PLAN = 4;
const STEP5_ACCESS_PAYMENT_PAGE = 5;
const CATEGORY_DEFAULT = 'Categoria';

const eventFunctionList = {
  checkout: (step, products) => ({
    checkout: {
      currencyCode: 'BRL',
      actionField: { step },
      products
    }
  }),
  purchase: (step, products, transactionCode) => ({
    purchase: {
      transaction_id: transactionCode,
      currencyCode: 'BRL',
      actionField: step,
      products
    }
  }),
  purchase_LIT: (step, products, transactionCode) => ({
    purchase: {
      transaction_id: transactionCode,
      currencyCode: 'BRL',
      actionField: step,
      products
    }
  }),

  purchase_SAINTPAUL: (step, products, transactionCode) => ({
    purchase: {
      transaction_id: transactionCode,
      currencyCode: 'BRL',
      actionField: step,
      products
    }
  }),
};

const returnAppropriateECommercePayload = (
  event,
  step,
  products = [],
  transactionCode
) => {
  return eventFunctionList[event](step, products, transactionCode);
};

const sendGoogleAnalyticsEcommerceEvent = ({
  step,
  eventName = 'checkout',
  products = [],
  callback = null,
  transactionCode = null
}) => {
  if (process.env.REACT_APP_MY_ENV === 'production') {
    const ecommercePayload = returnAppropriateECommercePayload(
      eventName,
      step,
      products,
      transactionCode
    );
    const { dataLayer } = window;
    if (dataLayer && eventName && ecommercePayload) {
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: eventName,
        ecommerce: ecommercePayload,
        ...(callback ? { eventCallback: callback } : {})
      });
    }
  } else if (callback) {
    callback();
  }
};

const sendGoogleAnalyticsEvent = ({
  eventName,
  eventCategory,
  eventLabel,
  eventValue,
  extraData,
  callback
}) => {
  console.log('process.env.REACT_APP_MY_ENV', process.env.REACT_APP_MY_ENV)
  if (process.env.REACT_APP_MY_ENV === 'production') {
    const { dataLayer } = window;
    if (eventName) {
      dataLayer.push({
        event: eventName,
        eventCategory,
        eventLabel,
        eventValue,
        eventCallback: callback,
        ...extraData
      });
    }
  } else if (callback) {
    callback();
  }
};

const sendGraphApiFacebook = async (event, data = {}) => {
  try {
    // if (!process.env.react_app_my_env === 'production') {
    //   return;
    // }
    await gateway.post(
      `/webbff/lit_app_web/facebook/facebook`,
      { ...data, eventName: event }
    );
  } catch (error) {
    console.log(error);
  }

};


const adsTracking = {
  sendGoogleAnalyticsEcommerceEvent,
  sendGoogleAnalyticsEvent,
  returnAppropriateECommercePayload,
  sendGraphApiFacebook,
  STEP1_REGISTER_PROFILE,
  STEP2_EMAIL_CONFIRMATION,
  STEP3_VERIFY_CPF,
  STEP4_PICK_PLAN,
  STEP5_ACCESS_PAYMENT_PAGE,
  CATEGORY_DEFAULT
};
export default adsTracking;
