// implemented from the site:  https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd
import React from 'react';

import {CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon} from './styles'

const Checkbox = ({ className, checked, color, styleContainer, ...props }) => (
  <CheckboxContainer className={className} style={styleContainer}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} color={color}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox
