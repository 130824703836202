import styled, { css } from "styled-components";
import FormGroup from '@material-ui/core/FormGroup';
import ControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { TRANSFORM } from "../../../constants";

export const SwitchLabel = styled(ControlLabel)`
  
  ${props => (props.transform === TRANSFORM.UPPERCASE) && css`
    text-transform: uppercase;
    margin-left: 5px;
    span {
      font-family: "Dosis", sans-serif!important;
      font-weight: 500;
    }
  `}

  ${props => (props.transform === TRANSFORM.LOWERCASE) && css`
    text-transform: lowercase;
    margin-left: 5px;
    span {
      font-family: "Dosis", sans-serif!important;
      font-weight: 500;
    }
  `}
  
`;

export const Toggle = styled(Switch)`
`;

export const SwitchBlock = styled(FormGroup)``;
