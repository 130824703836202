import React, { Component } from "react"
import Cargo from "./Cargo"

import { ordenarCargosPorData } from "../../utils/functions"
import api from "../../utils/api"

class CargosUsuario extends Component {
  state = {
    offices: [],
  }

  componentDidMount() {
    this.getOffices()
  }

  getOffices = async () => {
    try {
      const { idProfile } = this.props
      const idProfileLogged = localStorage.getItem("idProfile")
      const response = await api.get(`getOfficesByProfile/${idProfile}/${idProfileLogged}`)
      this.setState({ offices: response })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { offices } = this.state
    const lastExperiencePos = offices.length - 1
    return (
      <div className="cursos bg-white-perfil" id="cargo-usuario">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h5 style={{ fontSize: "1.5em" }}>Experiência Profissional</h5>
            </div>
          </div>
          <hr style={{ marginTop: "0.5em" }} />
          <div className="row">
            <div className="col">
              {offices.message && <p>Informação oculta</p>}
              {offices.length === 0 && <p>Nenhuma experiência profissional cadastrada</p>}
              {!offices.message &&
                offices.sort(ordenarCargosPorData).map((experience, index) => {
                  const lastExperience = lastExperiencePos === index ? "lastExperience-cargo" : ""
                  return (
                    <Cargo
                      key={experience.idOffice}
                      experience={experience}
                      lastClass={lastExperience}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CargosUsuario
