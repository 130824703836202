import styled from 'styled-components';

export const Nav = styled.nav`
  background: linear-gradient( 
    45deg
    , rgba(2, 196, 255, 1) 25%, rgba(142, 230, 123, 1) 100% );
`

export const Content = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  padding: 60px 5px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    color: #fff;
    font-weight: bold;
  }
`

export const ButtonExit = styled.a`
  background: red;
  color: white !important;
  padding: 10px 20px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: -30px;
  margin-bottom: 30px;
  background: transparent !important;
  justify-content: center;
`

export const ContentTitle = styled.div`
  width: 50%;
  border-radius: 5px;
  padding: 20px 40px;
  background: #fff !important;
  display: flex;
  min-height: 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .15);
`

export const Title = styled.h3`
  margin-bottom: 0px;
`
