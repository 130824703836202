import React, { useState } from 'react'

import { maskPhone } from "../../../../utils/functions"
import { ButtonCollapse, TitleRecommendations } from './styles'


export default function Index({ invitations }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen((state) => !state)
  }

  return (
    <div className="accordion" id="accordionExample">
      <div id="headingOne">
        <h5 className="mb-0">
          <ButtonCollapse
              onClick={toggleOpen}
              className="btn btn-link" 
              type="button" 
              data-toggle="collapse" 
              data-target="#collapseOne" 
              aria-expanded="true" 
              aria-controls="collapseOne"
          >
            <TitleRecommendations>Total de recomendações: {invitations.length}</TitleRecommendations>
            {open && (
              <i className="fa fa-chevron-up" aria-hidden="true" />
            )}
            {!open && (
              <i className="fa fa-chevron-down" aria-hidden="true" />
            )}
          </ButtonCollapse>
        </h5>
      </div>
  
      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th width="20%">Nome</th>
                  <th width="30%">Email</th>
                  <th width="30%">WhatsApp</th>
                  <th>Data</th>
                </tr>
              </thead>
                <tbody>
                  {invitations.map(invitation => (
                    <tr key={invitation.id}>
                      <td>{invitation.name}</td>
                      <td>{invitation.email}</td>
                      <td>{maskPhone(invitation.phone)}</td>
                      <td>{invitation.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
   
  )
}
