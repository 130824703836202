import { Types } from "./types";

const INITIAL_STATE = {
  loading: false,
  called: false,
  error: null,
  data: [
    {
      id: 0,
      name: "Marcos Sanchez",
      role: [{ id: 1, name: "Administrador" }],
      img: "https://picsum.photos/id/1005/40/40",
      memberSince: "09/01/2009"
    },
    {
      id: 8,
      name: "Dolores Sanchez",
      role: [{ id: 1, name: "Moderador" }],
      img: "https://picsum.photos/id/1010/40/40",
      memberSince: "09/01/2009"
    },

    {
      id: 2,
      name: "Oliver Noronha",
      role: [{ id: 1, name: "Aluno" }],
      img: "https://picsum.photos/id/1/40/40",
      memberSince: "09/01/2009"
    }
  ]
};

export default function members(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_ALL_PROFILES:
      return {
        ...state,
        loading: false,
        called: true,
        data: action.payload.data
      };

    default:
      return state;
  }
}
