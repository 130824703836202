import styled from "styled-components";
import defaultModal from "react-modal";
import { } from "../../../constants";

defaultModal.defaultStyles.overlay.position = "fixed";
defaultModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.75)";

export const ModalBody = styled.div.attrs({
  className: "modal-body"
})`
  padding: 2rem;
  margin: 1rem 0;
  text-align: center;
  position: relative;
  li {
    display: block;
  }
  img {
    max-width: initial;
    float: none;
    margin: 0;
    width: 120px;
    height: 120px;
  }
  .adjustable, 
  .adjustable label {
    clear: both;
    display: block;
  }
`;

export const ButtonAtBottom = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

