import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} color //{PRIMARY, TEXT_PRIMARY, SECONDARY, LIGHT_GRAY, LIGHT} dividing line color
**/

export default ({
  color = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        color={color}
      />
    </>
  );
};
