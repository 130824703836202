import { all, takeLatest, take } from "redux-saga/effects";

import { REHYDRATE } from "redux-persist/lib/constants";
/**
 * Types
 */
import { UserTypes } from "../ducks/user";
import { Types as NotificationsTypes } from "../ducks/notifications/types";
import { SocialTypes } from "../ducks/social";
import { Types as PaulChatTypes } from "../ducks/paulChat/types";
import { Types as CourseTypes } from "../ducks/course/types";
import { Types as NewCourseTypes } from "../ducks/newCourse/types";

/**
 * Sagas
 */
import { getProfile, getOffices, sendNPS } from "./user";
import { getNotification, countNotificationsNotRead } from "./notifications";
import {
  getMyGroups,
  getPendingInvitations,
  getRecommendedGroups
} from "./social";
import { getPersonalityInsight } from "./personalityInsight";
import { addButtonFeedback, didSubjectChanged, sendFeedback } from "./paulChat";
import {
  getCourse,
  getCourseProgress,
  getDisciplineProgress,
  syncUserGradeByDiscipline,
  forceSyncUserGradeByDiscipline
} from "./course";
import {
  getCourse as getNewCourse,
  reloadCourse as reloadNewCourse,
  getCourseCertificate
} from "./newCourse";

export default function* rootSaga() {
  yield take(REHYDRATE); // Wait for rehydrate to prevent sagas from running with empty store

  yield all([
    takeLatest(UserTypes.ProfileTypes.GET_PROFILE_REQUEST, getProfile),
    takeLatest(
      UserTypes.PITypes.GET_PERSONALITY_INSIGHT_REQUEST,
      getPersonalityInsight
    ),
    takeLatest(UserTypes.OfficesTypes.GET_OFFICES_REQUEST, getOffices),
    takeLatest(UserTypes.ProfileTypes.SEND_NPS_REQUEST, sendNPS),

    takeLatest(NotificationsTypes.GET_NOTIFICATION_REQUEST, getNotification),
    takeLatest(
      NotificationsTypes.GET_COUNT_NOTIFICATIONS_REQUEST,
      countNotificationsNotRead
    ),
    takeLatest(SocialTypes.myGroupsTypes.GET_MYGROUPS_REQUEST, getMyGroups),
    takeLatest(
      SocialTypes.pendingInvitationsTypes.GET_PENDING_INVITATIONS_REQUEST,
      getPendingInvitations
    ),
    takeLatest(
      SocialTypes.recommendedGroupsTypes.GET_RECOMMENDED_GROUPS_REQUEST,
      getRecommendedGroups
    ),

    takeLatest(PaulChatTypes.ADD_BUTTON_FEEDBACK, addButtonFeedback),
    takeLatest(PaulChatTypes.ON_SUBJECT_CHANGED, didSubjectChanged),
    takeLatest(PaulChatTypes.SEND_FEEDBACK_REQUEST, sendFeedback),
    takeLatest(CourseTypes.GET_COURSE_REQUEST, getCourse),
    takeLatest(CourseTypes.GET_COURSE_PROGRESS_REQUEST, getCourseProgress),
    takeLatest(CourseTypes.SET_DISCIPLINE_PROGRESS, getDisciplineProgress),
    takeLatest(CourseTypes.SYNC_GRADE_BY_DISCIPLINE, syncUserGradeByDiscipline),
    takeLatest(CourseTypes.FORCE_SYNC, forceSyncUserGradeByDiscipline),

    takeLatest(NewCourseTypes.GET_COURSE_REQUEST, getNewCourse),
    takeLatest(NewCourseTypes.RELOAD_COURSE_DATA, reloadNewCourse),
    takeLatest(NewCourseTypes.GET_COURSE_CERTIFICATE_REQUEST, getCourseCertificate)
  ]);
}
