import { Types } from "./types"
/**
 * Reducer
 */

const INITIAL_STATE = {
  myClasses: [],
}

export default function profile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_MY_CLASSES: 
      return { ...state, myClasses: action.payload }
    default:
      return state
  }
}
