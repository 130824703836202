import React, { PureComponent } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/pt-br'
import { API_URL, headerConfig } from '../../utils/constants'
import Search from './Search'
import {
	DivAlertSuccess,
	DivAlertError,
	TextSuccess,
	TextError
} from './styles'
import TabelaConsulta from './TabelaConsulta'

const dataFake = {
	listIdLoginSucessoCliente: [28, 29, 20, 212, 421],
	listIdLoginErroCliente: [28, 29, 20, 421],
	listIdLoginSucessoOrdemVenda: [28, 29],
	listIdLoginErroOrdemVenda: [28, 29, 421]
}

class ConsultarCobranca extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			dateConsult: moment(),
			listErroCliente: [],
			listErroOrdemVenda: [],
			listSucessoCliente: [],
			listsucessoOrdemVenda: [],
			searchComplete: false
		}
	}

	search = () => {
		this.setState({ searchComplete: false })
		const date = moment(this.state.dateConsult).format('YYYY-MM-DD')
		const url = `${API_URL}sendPaymentsOfTheDay`
		const body = {
			dataPagamento: date,
			idLogin: ''
		}
		fetch(url, {
			method: 'POST',
			headers: headerConfig.headers,
			body: JSON.stringify(body)
		})
			.then(response => response.json())
			.then(response => {
				this.setState({
					listErroCliente: response.listIdLoginErroCliente,
					listErroOrdemVenda: response.listIdLoginErroOrdemVenda,
					listSucessoCliente: response.listIdLoginSucessoCliente,
					listsucessoOrdemVenda: response.listIdLoginSucessoOrdemVenda,
					searchComplete: true
				})
				console.log(response)
			})
			.catch(err => {
				console.log(err)
			})
	}

	render() {
		const {
			dateConsult,
			listErroCliente,
			listErroOrdemVenda,
			listSucessoCliente,
			listsucessoOrdemVenda,
			searchComplete
		} = this.state
		return (
			<div>
				<h2>Consultar Cobrança</h2>
				<Search
					label="Data da consulta"
					maxDate={moment()}
					selectedDate={dateConsult}
					onChange={date => {
						this.setState({
							dateConsult: date
						})
					}}
					onClickSearch={this.search}
				/>
				<div>
					{listErroCliente.length > 0 ||
					listErroOrdemVenda.length > 0 ||
					listSucessoCliente.length > 0 ||
					listsucessoOrdemVenda.length > 0 ? (
						<div>
							<TabelaConsulta label="Erro Faturamento" list={listErroCliente} />
							<TabelaConsulta
								label="Erro ordem venda"
								list={listErroOrdemVenda}
							/>
							<TabelaConsulta
								label="Sucesso Faturamento"
								list={listSucessoCliente}
							/>
							<TabelaConsulta
								label="Sucesso ordem venda"
								list={listsucessoOrdemVenda}
							/>
						</div>
					) : searchComplete ? (
						<div style={{ padding: '10px 0 0 20px' }}>
							<h6>Não foi encontrado dados com essa pesquisa</h6>
							<h6>Refaça a pesquisa com outra data!</h6>
						</div>
					) : null}
				</div>
			</div>
		)
	}
}

export default ConsultarCobranca
