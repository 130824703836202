import React from "react"

import Bradesco from "./Bradesco"
import Generic from "./Generic"

export default function Partners(props) {
  const {
    userDetails: { origin },
  } = props
  if (origin) {
    const {
      userDetails: {
        origin: {
          company: { company },
        },
      },
    } = props
    if (origin && company.toLowerCase() === "bradesco") {
      return <Bradesco {...props} />
    }
  }

  return <Generic {...props} />
}
