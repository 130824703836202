import React from "react";
import Icon from "../Icon";
import { CustomText } from "./styles";

export default ({
  color,
  size,
  fontType,
  transform,
  icon,
  text,
  iconPosition,
  textAlign,
  appearance,
  ...rest
}) => {
  return (
    <>
      <CustomText
        {...rest}
        size={size}
        color={color}
        fontType={fontType}
        transform={transform}
        textAlign={textAlign}
        appearance={appearance}
      >
        {icon && <Icon icon={icon} color={color} iconPosition={iconPosition} />}
        {text}
      </CustomText>
    </>
  );
};
