import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES, ICON } from "../../../constants";

/**
 * @param {String} size //{SMALL, MEDIUM, LARGE, EXTRA_LARGE} icon size
 * @param {String} iconPosition //{LEFT, RIGHT} position of the icon in relation to the text
 * @param {String} color //icon font color
 * @param {String} icon //{MENU_BARS, TRACKS, BACK_ARROW, NEXT_ARROW, FIRST_ARROW, LAST_ARROW, DOWN_ARROW, UP_ARROW, USER, USERS, CIRCLE, PAPER_CLIP, CALENDAR, CLOSE, HEART, BELL, SEARCH, TROPHY, PLAY, SHARE, SEND, CLOSE_MODAL, MESSAGE, CITE, LIKE, SAD, DISLIKE, FILE, PDF, IMAGE, JPG, VIDEO, ELLIPSIS, REMOVE, SLIDERS, PENCIL, NOTIFICATION, GRADUATION, CONTACTS, LOCK, UNLOCK, QUEST, GLOBE, TIME, FILES, RIBBON, COGS, MONEY, USEFUL, USELESS, MARKED_USEFUL, MARKED_USELESS} description of the icon to be displayed
 * @param {Number} badgeValue //number to be displayed next to icon when it looks like notifications
 * @param {Function} onClick //click action
**/

export default ({
  size = DEFAULT_VALUES.STRING,
  iconPosition = DEFAULT_VALUES.STRING,
  color = DEFAULT_VALUES.STRING,
  icon = DEFAULT_VALUES.STRING,
  badgeValue = DEFAULT_VALUES.NUMBER,
  onClick = DEFAULT_VALUES.FUNCTION
}) => {

  function validateType(value) {
    switch (value) {
      case ICON.MENU_BARS:
        return "fa fa-bars";
      case ICON.TRACKS:
        return "fa fa-bars fa-rotate-90 pt-2";
      case ICON.BACK_ARROW:
        return "fa fa-angle-left";
      case ICON.NEXT_ARROW:
        return "fa fa-angle-right";
      case ICON.FIRST_ARROW:
        return "fa fa-angle-double-left";
      case ICON.LAST_ARROW:
        return "fa fa-angle-double-right";
      case ICON.DOWN_ARROW:
        return "fa fa-angle-down";
      case ICON.UP_ARROW:
        return "fa fa-angle-up";
      case ICON.USER:
        return "fa fa-user";
      case ICON.USERS:
        return "fa fa-users";
      case ICON.CIRCLE:
        return "fa fa-dot-circle-o";
      case ICON.PAPER_CLIP:
        return "fa fa-paperclip";
      case ICON.CALENDAR:
        return "fa fa-calendar";
      case ICON.CLOSE:
        return "fa fa-close";
      case ICON.HEART:
        return "fa fa-heart";
      case ICON.BELL:
        return "fa fa-bell";
      case ICON.SEARCH:
        return "fa fa-search";
      case ICON.TROPHY:
        return "fa fa-trophy";
      case ICON.PLAY:
        return "fa fa-play";
      case ICON.SHARE:
        return "fa fa-share";
      case ICON.SEND:
        return "fa fa-send";
      case ICON.CLOSE_MODAL:
        return "fa fa-close fa-2x";
      case ICON.MESSAGE:
        return "fa fa-comments-o";
      case ICON.CITE:
        return "fa fa-commenting-o";
      case ICON.LIKE:
        return "fa fa-heart";
      case ICON.SAD:
        return "fa fa-frown-o";
      case ICON.DISLIKE:
        return "fa fa-thumbs-down";
      case ICON.FILE:
        return "fa fa-file-o";
      case ICON.PDF:
        return "fa fa-file-pdf-o";
      case ICON.IMAGE:
        return "fa fa-picture-o";
      case ICON.JPG:
        return "fa fa-picture-o";
      case ICON.VIDEO:
        return "fa fa-youtube-play";
      case ICON.ELLIPSIS:
        return "fa fa-ellipsis-v";
      case ICON.REMOVE:
        return "fa fa-trash";
      case ICON.SLIDERS:
        return "fa fa-sliders";
      case ICON.PENCIL:
        return "fa fa-pencil";
      case ICON.NOTIFICATION:
        return "fa fa-bullhorn";
      case ICON.GRADUATION:
        return "fa fa-graduation-cap";
      case ICON.CONTACTS:
        return "fa fa-vcard-o";
      case ICON.LOCK:
        return "fa fa-lock";
      case ICON.UNLOCK:
        return "fa fa-unlock";
      case ICON.QUEST:
        return "fa fa-question-circle-o";
      case ICON.GLOBE:
        return "fa fa-globe";
      case ICON.TIME:
        return "fa fa-clock-o";
      case ICON.FILES:
        return "fa fa-files-o";
      case ICON.RIBBON:
        return "fa fa-bookmark-o";
      case ICON.COGS:
        return "fa fa-cogs";
      case ICON.MONEY:
        return "fa fa-usd";
      case ICON.USEFUL:
        return "fa fa-thumbs-o-up";
      case ICON.USELESS:
        return "fa fa-thumbs-o-up fa-rotate-180";
      case ICON.MARKED_USEFUL:
        return "fa fa-thumbs-up";
      case ICON.MARKED_USELESS:
        return "fa fa-thumbs-up fa-rotate-180";
      case ICON.ENVELOPE:
        return "fa fa-envelope-o";
      default:
        return;
    }
  }

  return (
    <>
      <Screen
        size={size}
        iconPosition={iconPosition}
        onClick={onClick}
        color={color}
        icon={icon}
        badgeValue={badgeValue}
        validateType={validateType}
      />
    </>
  );
};
