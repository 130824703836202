import React, { Component } from 'react'

const TableItens = props => {
	return (
		<tr>
			<td className="timeline-center voucher-table-item">
				<input
					onChange={event => {
						props.handleSelectedUsers(
							false,
							true,
							event.target.name,
							event.target.checked
						)
					}}
					checked={props.value}
					type="checkbox"
					name={props.user.idLogin}
				/>
			</td>
			<td className="timeline-center voucher-table-item">
				{props.user.idLogin}
			</td>
			<td className="timeline-center voucher-table-item">{props.user.nome}</td>
			<td className="timeline-center voucher-table-item">{props.user.email}</td>
			<td className="timeline-center voucher-table-item">
				R$ {props.user.valor}
			</td>
			<td className="timeline-center voucher-table-item">
				{props.user.desconto} %
			</td>
			<td className="timeline-center voucher-table-item">
				R$ {props.user.valorCobrado}
			</td>
			<td className="timeline-center voucher-table-item">
				{props.user.dataRegistro}
			</td>
			<td className="timeline-center voucher-table-item">
				{props.user.dataCobranca}
			</td>
			<td className="timeline-center voucher-table-item">
				{props.user.status ? 'Ativo' : 'Trial'}
			</td>
		</tr>
	)
}

export default TableItens
