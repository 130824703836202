import { createContext } from "react";

const NavBarContext = createContext({
    showLinkBoletos: false,
    showRecommendationPlanAlert: false,
    hasExpiredOrNearExpirationInvoice: false,
    showMyInvoicesButton: false,
    tenantName: '',
    tenancy: [],
    showGenericAlert: false,
    showModalRegisterMobilePassword: false,
    openModalRegisterMobilePassword: () => {},
    closeModalRegisterMobilePassword: () => {},
    setTenancyName: () => {}
})

export default NavBarContext
