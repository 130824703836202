import styled, { css } from "styled-components";
import { APPEARANCE, COLOR, SIZE, BORDER_TYPE, STATUS, ORIENTATION, POSITION } from "../../../constants";

export const Agrupable = styled.span.attrs({
  className: "content-button"
})`
  position: relative;
  display: inherit;
  span {
    width: auto;
    height: 28px;
    clear: none;
    position: absolute;
    left: 93%;
    bottom: 0;
    top: 1px;
    color: var(--button-link-text);
    label {
      margin: 0;
    }
  }

  i{
    color: #27aae1 !important;
  }


  ${props => (props.position === POSITION.LEFT) && css`
    float: left;
  `}

  ${props => (props.position === POSITION.RIGHT) && css`
    float: right;
  `}

  ${props => (props.position === POSITION.TOP_RIGHT) && css`
    position: absolute;
    top: 4px;
    right: 4px;
  `}

`;

export const Button = styled.button`
${props => (props.badgeValue > 0) && css`
    ::after {
      content: "${props.badgeValue}";
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: var(--badge-danger);
      color: var(--badge-danger-text);
      display: block;
      position: absolute;
      top: -6px;
      right: -3px;
      box-shadow: -1px 1px 1px var(--badge-shadow);
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      font-family: 'Dosis',sans-serif;
      font-weight: 600;
    }
  `}
  
  ${props => (props.border === BORDER_TYPE.NO_BORDER) && css`
    border-color: transparent!important;
  `}

  ${props => (props.border === BORDER_TYPE.BORDER) && css`
    border: 1px solid var(--border-gray);
  `}

  ${props => (props.appearance === APPEARANCE.PRIMARY) && css` 
    background: var(--button-primary);
    color: var(--button-primary-text);
    display: inline-block;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid var(--button-primary-border); 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
      background: var(--button-primary-hover);
    } 
  `}

  ${props => (props.appearance === APPEARANCE.SECONDARY) && css` 
    background: var(--button-secondary);
    color: var(--button-secondary-text);
    display: inline-block;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid var(--button-secondary-border); 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
      background: var(--button-secondary-hover);
      color: var(--button-secondary-hover-text);
    } 
  `}

  ${props => (props.appearance === APPEARANCE.OUTLINE) && css`
    background: var(--transparent);
    border: 1px solid var(--button-outline-light);
    color: var(--button-outline-light-text);
    white-space: nowrap;
    text-transform: initial;
    padding: 10px;
    display: inline-block;
    border-radius: 5px; 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
      color: var(--button-outline-light-hover-text);
      background: var(--button-outline-light-hover);
    }
  `}

  ${props => (props.appearance === APPEARANCE.OUTLINE_PRIMARY) && css`
    background: var(--transparent);
    border: 1px solid var(--button-outline-primary); 
    color: var(--button-outline-primary-text);
    white-space: nowrap;
    text-transform: initial;
    padding: 10px;
    display: inline-block;
    border-radius: 5px; 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
      color: var(--button-outline-primary-hover-text);
      background: var(--button-outline-primary-hover);
    }
  `}

  ${props => (props.appearance === APPEARANCE.WHITE_BUTTON) && css`
    background: var(--white);
    border: 1px solid var(--text-primary); 
    color: var(--text-primary);
    white-space: nowrap;
    text-transform: initial;
    padding: 5px 15px;
    font-weight: 500;
    letter-spacing: .35px;
    display: inline-block;
    border-radius: 5px; 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
      color: var(--white)!important;
      background: var(--text-primary)!important;
    }
  `}

  ${props => (props.appearance === APPEARANCE.CLOSE) && css`
    border: 2px solid var(--button-close-border); 
    color: var(--button-close-text);
    background: var(--button-close);
    white-space: nowrap;
    text-transform: initial;
    width: 22px;
    height: 22px;
    line-height: 0.7;
    display: inline-block;
    border-radius: 50%; 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    padding: 1px;
    i {
      color: var(--button-close-text);
    }
    &:hover {
      cursor: pointer;
      background: var(--button-close-hover);
    }
  `}

  ${props => (props.appearance === APPEARANCE.GRADIENT) && css`
    background: linear-gradient(60deg, var(--button-gradient-primary) 0%, var(--button-gradient-secondary) 100%);
    border: 1px solid var(--button-gradient-border);
    color: var(--white);
    white-space: nowrap;
    text-transform: initial;
    padding: 10px;
    display: inline-block;
    border-radius: 5px; 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
      color: var(--button-gradient-hover);
      background: var(--button-gradient-hover-text);
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.LINK) && css`
    color: ${props => props.color ? props.color : 'var(--button-link-text)'};
    background: var(--transparent);
    text-transform: initial;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid var(--transparent); 
    cursor: pointer;
    text-align: center;
    right: 0;
    transition: ease .3s all;
    position: relative;
    i {
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
      color: var(--button-link-hover-text);
      background: var(--transparent);
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.CARD_REACTION) && css`
    color: var(--button-link-text);
    background: var(--transparent);
    text-transform: initial;
    display: inline-block;
    border: 1px solid var(--transparent); 
    cursor: pointer;
    text-align: left;
    right: 0;
    padding: 0 3px;
    transition: ease .3s all;
    position: relative;
    font-weight: 500;
    i {
      margin-right: 12px!important;
      &.fa-rotate-180 {
        top: -2px;
        left: 0;
        margin: 0 5px 0 0!important;
      }
    }
    &:hover {
      cursor: pointer;
      color: var(--button-link-hover-text);
      background: var(--transparent);
    }
    @media (max-width: 767px) {
      &:not(i) {
        font-size: 12px;
      }
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.LINK_ICON_ACTIVE) && css`
    color: var(--button-link-active-text);
    background: var(--transparent);
    text-transform: initial;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid var(--transparent); 
    cursor: pointer;
    text-align: center;
    right: 0;
    transition: ease .3s all;
    position: relative;
    i {
      margin-right: 5px;
      color: var(--button-link-active-icon);
    }
    &:hover {
      cursor: pointer;
      color: var(--button-link-active-hover-text);
      background: var(--transparent);
    }
  `}

  ${props => (props.appearance === APPEARANCE.PAGINATION) && css`
    background: var(--button-pagination);    
    color: var(--button-pagination-text);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin: 2px;
    padding: 0;
    float: left;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid var(--button-pagination-border); 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    i {
      margin: 0;
    }
    &:hover {
      cursor: pointer;
      background: var(--button-pagination-hover);
      border: 1px solid var(--button-pagination-hover-border);
      color: var(--button-pagination-hover-text);
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.MODAL) && css`
    border: 0;
    background: var(--button-modal);
    color: var(--button-modal-text);
    text-transform: uppercase;
    font-weight: 700;
    margin: 10px auto;
    display: table;
    float: none;
    text-decoration: none;
    transition: all 0.3s ease;
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: var(--button-modal-hover-underline);
      background: var(--button-modal-hover);
    }
    i {
      font-size: 20px;
      color: var(--button-modal-icon);
      margin-right: 3px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  `}

  ${props => (props.appearance === APPEARANCE.MODAL_CONFIRM) && css`
    background: var(--button-modal-confirm);
    border-radius: 5px;
    color: var(--button-modal-confirm-text); 
    text-transform: uppercase;
    width: 100%;
    border: 0;
    padding: 15px 0;
    &:hover,
    &:focus {
      background-color: var(--button-modal-confirm-hover);
    }
  `}
  
  ${props => (props.appearance === APPEARANCE.INVISIBLE) && css`
    border: 0;
    background: var(--transparent);
    color: var(--transparent);
    width: 0;
    height: 0;
    * {
      display:none;
    }
  `}

  ${props => (props.color === COLOR.LIGHT_GRAY) && css`
    background: var(--button-light-gray);
    border: 1px solid var(--button-light-gray-border);
    color: var(--button-light-gray-text);
    text-transform: initial;
    font-size: 40px;
    line-height: 1;
    padding: 0;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
      color: var(--button-light-gray-hover-text);
      background: var(--button-light-gray-hover);
    }
  `}
  
  ${props => (props.size === SIZE.SMALL) && css`
    font-size: 14px;
    padding: 4px 10px;
    text-transform: initial;
    letter-spacing: 1px;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  `}
  
  ${props => (props.size === SIZE.MEDIUM) && css`
    font-size: 16px;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    min-width: 150px;
    width: auto;
    padding: 10px;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  `}
  
  ${props => (props.size === SIZE.LARGE) && css`
    font-size: 24px;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 579px) {
      font-size: 20px;
    }
  `}
  
  ${props => (props.orientation === ORIENTATION.FULL_WIDTH) && css`
    width: 100%;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  `}

  ${props => (props.orientation === ORIENTATION.FULL_WIDTH && props.size === SIZE.MEDIUM) && css`
    font-size: 18px!important;
    padding: 15px 0!important;
  `}

  ${props => (props.orientation === ORIENTATION.FULL_WIDTH && props.size === SIZE.LARGE) && css`
    font-size: 20px!important;
    padding: 25px 0!important;
  `}

  ${props => (props.mode === STATUS.DISABLED) && css`
    opacity: .65;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid var(--button-disabled-border); 
    cursor: pointer;
    text-align: center;
    transition: ease .3s all;
    cursor: not-allowed!important;
    position: relative;
  `}

`;
