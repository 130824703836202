import React from "react";
import MeetingChatMessage from "../MeetingChatMessage";
import { MessageBlock, UserLabel, TimeLabel } from "./styles";

export default React.memo(function MeetingChatMessageBlock({ entry }) {
  return (
    <MessageBlock>
      <div>
        <UserLabel>{entry.user}</UserLabel>
        <TimeLabel>{entry.time}</TimeLabel>
      </div>

      {entry.messages.map(message => (
        <MeetingChatMessage message={message} />
      ))}
    </MessageBlock>
  );
});
