import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import Img from 'react-image';
import {FaCheck} from  'react-icons/fa';

import * as Styles from './styles';

const PROGRESS_TYPES = {
  'DONE': 'DONE'
}

const MyLoader = () => (
  <ContentLoader height={180} width={325} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
    <rect x="0" y="0" rx="5" ry="5" width="400" height="400" />
  </ContentLoader>
)

function Screen({learningObject, goToLearningObjectCourse}) {
  return (
    <Styles.Container onClick={() => goToLearningObjectCourse(learningObject)} title={learningObject.name}>
      {learningObject.progress === PROGRESS_TYPES.DONE && (
        <Styles.ProgressIndicator>
          <FaCheck />
        </Styles.ProgressIndicator>
      )}
      <Img
        src={learningObject.cover}
        alt="ImageCourse"
        className="card-img-top"
        loader={<MyLoader />}
      />
      <div
        className="card-body"
        style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
      >
        <h5>{learningObject.name}</h5>
        
      </div>
    </Styles.Container>
  );
}

Screen.propTypes = {
  learningObject: PropTypes.object.isRequired,
  goToLearningObjectCourse: PropTypes.func.isRequired
}

export default Screen;
