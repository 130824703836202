import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { gateway } from '../../../../utils/api';
import { formatReal, toastDefaultOptions } from '../../../../utils/functions';
import { PaymentData } from './paymentData';
import { SubscriptionData } from './subscriptionData';
import { Row, DisabledButton } from './styles';
import ModalCancelamento from '../../../Modals/ModalCancelamento';

const styles = {
  subtitle: {
    fontWeight: 'bold',
    'width:': '30px !important',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    marginRight: '5px !important'
  },
  modalHeader: {
    width: 'auto',
    height: '50px',
    background: 'transparent',
    boxShadow: 'none',
    marginTop: '60px'
  },
  boxText: {
    'width:': '40px',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    paddingRight: '10px'
  }
};

const ContractData = ({ userSubscriptions, refetchContract }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToCancel, setIdToCancel] = useState(undefined);

  const closeModal = () => {
    setIsModalOpen(false);
    setIdToCancel(undefined);
  };

  const notifySuccess = text => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000
    });
  };

  const notifyError = (text, type = 'error') => {
    toast(text, {
      type,
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 4000
    });
  };

  const cancelSubscription = async (data) => {
    try {
      const tenant = localStorage.getItem('tenant');
      const idProfile = localStorage.getItem('idProfile');
      const userEmail = localStorage.getItem('ra');

      if (idToCancel) {
        const response = await gateway.post(
          `/webbff/lit_app_web/contract/vindi/cancelRenewal`,
          { ...data, id: idToCancel },
          {
            headers: {
              'x-owner-tenant': tenant,
              'x-owner-idprofile': idProfile,
              'x-owner': userEmail
            }
          }
        );

        if (response) {
          notifySuccess('Assinatura cancelada!');
          closeModal();
          refetchContract();
        }
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  const onClickCancel = id => {
    setIdToCancel(id);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h5 style={{ fontSize: '1.2em', color: '#27aae1' }}>
            Dados do Plano
          </h5>
          <hr style={{ marginTop: '0.1em' }} />
          <Row>
            <div className="col-sm-2 p-0">
              <h6 style={styles.subtitle}>Nome do plano</h6>
            </div>
            <div className="col-sm-2 p-0">
              <h6 style={styles.subtitle}>Valor</h6>
            </div>
            <div className="col-sm-2 p-0">
              <h6 style={styles.subtitle} title="Valor com desconto">
                Valor com desconto
              </h6>
            </div>
            <div className="col-sm-2 p-0">
              <h6 style={styles.subtitle} title="Data último acesso">
                Data de cancelamento
              </h6>
            </div>
            <div className="col-sm-3 p-0">
              <h6 style={styles.subtitle} title="Protocolo de cancelamento">
                Protocolo de cancelamento
              </h6>
            </div>
            <div className="col-sm-1"></div>
          </Row>
          {userSubscriptions.map(subscription => {
            return (
              <Row key={subscription.nomePlano}>
                <div className="col-sm-2 p-0 overflow-auto">
                  <p style={styles.boxText} title={subscription.nomePlano}>
                    {subscription.nomePlano}
                  </p>
                </div>
                <div className="col-sm-2 p-0">
                  <p>R$ {formatReal(subscription.valorPlano)}</p>
                </div>
                <div className="col-sm-2 p-0">
                  <p>R$ {formatReal(subscription.valorComDesconto)}</p>
                </div>
                <div className="col-sm-2 p-0">
                  {subscription.dataCancelamento}
                </div>
                <div className="col-sm-2 p-0 overflow-auto">
                  <p
                    style={styles.boxText}
                    title={subscription.protocoloCancelamento}
                  >
                    {subscription.protocoloCancelamento}
                  </p>
                </div>
                {subscription.status !== 'CANCELED' &&
                  subscription.planType !== 'B2C - Extensão' && (
                    <div className="col-sm-2">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm btnEdit-perfilAluno btn-FontWeight6"
                        onClick={
                          subscription.appleIapUser
                            ? undefined
                            : () => {
                              onClickCancel(subscription.id);
                            }
                        }
                        data-tip={
                          subscription.appleIapUser
                            ? ' Para cancelar sua assinatura, utilize o painel do iTunes.'
                            : ''
                        }
                        data-for="cancelTip"
                      >
                        Cancelar
                      </button>
                      <ReactTooltip id="cancelTip" />
                    </div>
                  )}
                {subscription.status === 'CANCELED' &&
                  subscription.recisionInvoiceUrl && (
                    <div className="col-sm-1">
                      <a
                        className="btn btn-icon mb-2 pt-0 pl-0 pb-1 pr-2 ml-auto"
                        href={subscription.recisionInvoiceUrl}
                        data-tip="Fazer download do boleto de cancelamento."
                        data-for="invoiceTip"
                      >
                        <i
                          className="fa fa-file-text"
                          aria-hidden="true"
                          style={{ color: '#27aae1' }}
                        />
                      </a>
                      <ReactTooltip id="invoiceTip" />
                    </div>
                  )}
                {subscription.status === 'CANCELED' &&
                  !subscription.recisionInvoiceUrl && (
                    <div className="col-sm-1">
                      <button
                        type="button"
                        className="btn btn-icon mb-2 pt-0 pl-0 pb-1 pr-2 ml-auto"
                        data-tip="Plano cancelado. Isso significa que sua próxima cobrança recorrente não irá ocorrer e seu acesso ficará ativo até o prazo de término do período atual contratado. Caso tenha dúvidas, entre em contato no sac@lit.com.br"
                        data-for="cardTip"
                      >
                        <i
                          className="fa fa-credit-card"
                          aria-hidden="true"
                          style={{ color: '#27aae1' }}
                        ></i>
                      </button>
                      <ReactTooltip id="cardTip" />
                    </div>
                  )}
                {subscription.planType === 'B2C - Extensão' && (
                  <div className="col-sm-2">
                    <DisabledButton
                      type="button"
                      data-tip="Não é possível cancelar um plano de extensão, para mais informações sobre como cancelar seu plano entre em contato com o SAC."
                      data-for="cardTip"
                    >
                      Cancelar
                    </DisabledButton>
                    <ReactTooltip id="cardTip" />
                  </div>
                )}
              </Row>
            );
          })}
        </div>
      </div>
      <PaymentData userSubscriptions={userSubscriptions} />
      <SubscriptionData userSubscriptions={userSubscriptions} />

      {idToCancel && isModalOpen && (
        <ModalCancelamento
          idToCancel={idToCancel}
          openModal={isModalOpen}
          onClose={closeModal}
          onSave={cancelSubscription}
        />
      )}
    </>
  );
};

export default ContractData;
