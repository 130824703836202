import React from "react";
import {
  COLOR,
  APPEARANCE,
  SIZE,
  FONT_TYPE,
  STATUS,
  ICON,
  PROGRESS_INFORMATION,
  POSITION,
  ACTION,
  TRANSFORM
} from "../../../constants";
import Text from "../Text";
import Block from "../Block";
import Icon from "../Icon";
import Clickable from "../Clickable";
import ProgressBar from "../ProgressBar";
import Row70x30 from "../../defaultAreas/Row_70-30";
import Row60x40 from "../../defaultAreas/Row_60-40";
import {
  StatusLine,
  Description,
  ContentAtBottom,
  Ajustable,
  Span
} from "./styles";

export default ({ hasCurrentCourse, infoProgress }) => {
  return (
    <>
      {hasCurrentCourse && (
        <Span>
          <Block color={COLOR.BLOCK_GRADIENT}>
            <Clickable>
              <Description>
                <Text
                  size={SIZE.EXTRA_SMALL}
                  text={ACTION.CONTINUE_COURSE}
                  color={COLOR.LIGHT}
                  transform={TRANSFORM.UPPERCASE}
                />
                <Text
                  size={SIZE.MEDIUM}
                  text={infoProgress.courseTitle}
                  fontType={FONT_TYPE.BOLD}
                  color={COLOR.LIGHT}
                  appearance={APPEARANCE.TITLE}
                />
              </Description>
              <Row70x30
                left={[
                  <ContentAtBottom>
                    <StatusLine>
                      <ProgressBar
                        progress={infoProgress.totalProgress}
                        statusProgress={STATUS.IN_PROGRESS}
                        position={POSITION.TOP_JUSTIFY}
                      />
                    </StatusLine>
                    <Row60x40
                      left={[
                        <Text
                          size={SIZE.EXTRA_SMALL}
                          text={`${STATUS.START_DATE} ${infoProgress.initialDate}`}
                          color={COLOR.LIGHT}
                        />
                      ]}
                      right={[
                        <Text
                          size={SIZE.EXTRA_SMALL}
                          text={`${PROGRESS_INFORMATION.MODULE} ${infoProgress.currentModule}`}
                          color={COLOR.LIGHT}
                          fontType={FONT_TYPE.BOLD}
                        />,
                        <Text
                          size={SIZE.EXTRA_SMALL}
                          text={`${PROGRESS_INFORMATION.MODULE_OF} ${infoProgress.totalModules}`}
                          color={COLOR.LIGHT}
                        />
                      ]}
                    />
                  </ContentAtBottom>
                ]}
                right={[
                  <Ajustable>
                    <Icon icon={ICON.PLAY} color={COLOR.LIGHT} />
                  </Ajustable>
                ]}
              />
            </Clickable>
          </Block>
        </Span>
      )}
    </>
  );
};
