import styled, { css } from "styled-components";
import { NUMBER, POSITION } from "../../../constants";

export const Span = styled.span`
  
  height: 260px;

  ${props => (props.grid === NUMBER.FOUR) && css`
    margin-right: 20px;
    margin-bottom: 20px;
    float: left;
    width: calc(25% - 15px); 
    position: relative;
    line-height: 1.2;
    img {
      width: 100%;
      margin-bottom: 10px;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    @media screen and (max-width: 1060px) {
      width: calc(33% - 15px); 
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 991px) {
      width: calc(50% - 15px); 
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 579px) {
      width: calc(100%); 
      margin: 15px;
    }
  `}

  ${props => (props.grid === NUMBER.THREE) && css`
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
    width: calc(33% - 10px); 
    position: relative;
    line-height: 1.2;
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }
    &:nth-of-type(3n) {
      margin-right: 0;
    } 
    @media screen and (max-width: 991px) {
      width: calc(50% - 15px); 
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 579px) {
      width: calc(100%); 
      margin: 15px;
    }
  `}
  
  ${props => (props.grid === NUMBER.TWO) && css`
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
    width: calc(50% - 5px); 
    position: relative;
    text-align: left;
    line-height: 1.2;
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    } 
    @media screen and (max-width: 579px) {
      width: calc(100%); 
      margin: 15px;
    }
  `}

  ${props => (props.grid === NUMBER.ONE) && css`
    margin-right: 0;
    margin-bottom: 10px;
    float: left;
    width: 100%; 
    position: relative;
    line-height: 1.2;
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }
  `}

  ${props => (props.textAlign === POSITION.LEFT) && css`
    text-align: left;
  `}

  ${props => (props.textAlign === POSITION.RIGHT) && css`
    text-align: right;
  `}

  ${props => (props.textAlign === POSITION.CENTER) && css`
    text-align: center;
  `}

`;

export const Frame = styled.span`
  position: relative;
  display: table;
  width: 100%;
  button { padding: 0; }
`;

export const ButtonsAtBottom = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 98%;
`;
