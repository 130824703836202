import React from "react";

import Loading from "../Loading";
import * as Styles from "./styles";

function Input({
  label,
  error,
  disabled,
  containerProps,
  prefix,
  sufix,
  withIcon,
  loading,
  ...rest
}) {
  return (
    <Styles.Container withIcon={withIcon} disabled={disabled} style={{ ...(containerProps || {}) }}>
      {label && <label>{label}</label>}
      <div>
        {prefix}
        <input disabled={disabled} {...rest} />

        {loading ? <Loading /> : sufix}
      </div>

      {error && <p>{error}</p>}
    </Styles.Container>
  );
}

export default Input;
