import React from "react";
import { APPEARANCE, FONT_TYPE, POSITION, SIZE } from "../../../constants";
import Lane from "../../basicComponents/Lane";
import Text from "../../basicComponents/Text";
import Button from "../../basicComponents/Button";
import BannerBlock from "../../basicComponents/BannerBlock";
import Row60x40 from "../../defaultAreas/Row_60-40";
import { Title, Content } from "./styles";

export default ({ title, buttonText, infinite, content, actionButton }) => {
  return (
    <>
      <Content>
        <Title>
          <Row60x40
            position={POSITION.TOP_CENTER}
            left={[
              <Text
                text={title}
                fontType={FONT_TYPE.BOLD}
                position={POSITION.LEFT}
                size={SIZE.LARGE}
              />
            ]}
            right={[
              <Button
                text={buttonText}
                appearance={APPEARANCE.MODAL}
                position={POSITION.RIGHT}
                actionButton={actionButton}
              />
            ]}
          />
        </Title>
        <Lane infinite={infinite}>
          {content.map(item => (
            <BannerBlock
              title={item.metadata.title}
              description={item.metadata.description}
              author={item.metadata.author}
              image={item.metadata.picture}
            />
          ))}
        </Lane>
      </Content>
    </>
  );
};
