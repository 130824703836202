import React from "react";
import {
  FONT_TYPE,
  POSITION,
  SIZE,
  TRANSFORM,
  APPEARANCE
} from "../../../constants";
import Icon from "../Icon";
import Text from "../Text";
import Row15x85 from "../../defaultAreas/Row_15-85";
import Row80x20 from "../../defaultAreas/Row_80-20";
import { BlockTitle, IconType, IconPrivacy } from "./styles";

export default ({
  text,
  description,
  icon,
  locked,
  showLocked,
  appearance,
  color,
  size,
  transform,
  borderColor,
  validateIcon
}) => {
  return (
    <>
      <BlockTitle appearance={appearance}>
        <Row80x20
          left={[
            <Row15x85
              left={[
                <IconType color={borderColor}>
                  <Icon icon={icon} size={size} color={color} />
                </IconType>
              ]}
              right={[
                <Text
                  fontType={borderColor && FONT_TYPE.BOLD}
                  text={text}
                  size={size}
                  transform={transform}
                  color={color}
                  appearance={APPEARANCE.TITLE}
                />,
                <Text
                  text={description}
                  size={borderColor ? SIZE.EXTRA_SMALL : SIZE.SMALL}
                  transform={borderColor && TRANSFORM.UPPERCASE}
                  fontType={FONT_TYPE.BOLD}
                  color={color}
                />
              ]}
            />
          ]}
          right={[
            showLocked && (
              <IconPrivacy>
                <Icon
                  icon={validateIcon(locked)}
                  iconPosition={POSITION.RIGHT}
                  size={SIZE.LARGE}
                />
              </IconPrivacy>
            )
          ]}
        />
      </BlockTitle>
    </>
  );
};
