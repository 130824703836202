import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../constants";

/**
 * @param {Array} itemsToRender //{ICON, BUTTON} components that will be rendered on the page
 * @param {String} orientation //{DEFAULT, FULL-WIDTH} component orientation on the screen
 * @param {String} format //{BUTTON_1X3} component display grid format
 * @param {String} position //{LEFT} component position on the screen
**/

export default ({
  itemsToRender = DEFAULT_VALUES.LIST,
  orientation = DEFAULT_VALUES.STRING,
  format = DEFAULT_VALUES.STRING,
  position = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        itemsToRender={itemsToRender}
        orientation={orientation}
        position={position}
        format={format}
      />
    </>
  );
};

