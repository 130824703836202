export default () => {
  const array = [
    {
      label: "Mês",
      value: ""
    }
  ];

  Array(12)
    .fill(0)
    .forEach((item, index) => {
      const month = index + 1;
      const formatted = month < 10 ? `0${month}` : `${month}`;
      array.push({
        value: formatted,
        label: formatted
      });
    });

  return array;
};
