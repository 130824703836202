import React from "react";
import Text from "../../basicComponents/Text";
import { InputText } from "./styles";

export default ({
  placeholder,
  name,
  required,
  label,
  value,
  onChange,
  onBlur
}) => {
  return (
    <>
      <Text text={label} />

      <InputText
        placeholder={placeholder}
        name={name}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
      ></InputText>
    </>
  );
};
