import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Number} progress //defines the total progress bar fill
 * @param {String} label //displays content on the progress bar
 * @param {String} statusProgress //{IN_PROGRESS, COMPLETED} toggles the appearance of the progress bar
 * @param {String} position //{TOP_JUSTIFY} sets the position of the progress bar
**/

export default ({
  progress = DEFAULT_VALUES.NUMBER,
  label = DEFAULT_VALUES.STRING,
  statusProgress = DEFAULT_VALUES.STRING,
  position = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        progress={progress}
        label={label}
        statusProgress={statusProgress}
        position={position}
      />
    </>
  );
};
