import React, { memo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Img from "react-image"

import placeholder from "../../../images/placeholder-group.png"

import api from "../../../utils/api"
import { API_URL_IMAGES } from "../../../utils/constants"

const MeusGrupos = ({ grupo }) => {
  const [detail, setDetail] = useState("")

  const fetchDetailGroup = async () => {
    const idProfile = localStorage.getItem("idProfile")

    try {
      const response = await api.get(`getNumberMembersOfGroup/${idProfile}/${grupo.idGroup}`)
      setDetail(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDetailGroup()
  }, [])

  return (
    <li className="list-group-item" style={{ border: "none", display: "flex", alignItems: "center" }}>
      <Link to={`/grupo/${grupo.idGroup}`} data-tip={grupo.groupName}>
        <Img
          className="img-thumbnail img-fluid mr-2"
          style={{ width: 70, height: 70 }}
          src={[`${API_URL_IMAGES}images/groups/${grupo.idGroup}.jpg`, placeholder]}
        />
      </Link>
      <div className="group-desc">
        <Link
          style={{
            color: "#27aae1",
            fontWeight: 700,
          }}
          to={`/grupo/${grupo.idGroup}`}
        >
          {grupo.groupName}
        </Link>

        <span className="padding-0">{detail.owner ? "Admin" : "Membro"}</span>
      </div>
    </li>
  )
}

export default memo(MeusGrupos)
