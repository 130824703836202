import { combineReducers } from "redux";

/**
 * MyGroups
 */
import myGroups from "./myGroups";
import { Creators as myGroupsActions } from "./myGroups/actions";
import { Types as myGroupsTypes } from "./myGroups/types";

/**
 * ReccomendedGroups
 */
import recommendedGroups from "./recommendedGroups";
import { Creators as recommendedGroupsActions } from "./recommendedGroups/actions";
import { Types as recommendedGroupsTypes } from "./recommendedGroups/types";

/**
 * Open Invites
 */
import pendingInvitations from "./pendingInvitations";
import { Creators as pendingInvitationsActions } from "./pendingInvitations/actions";
import { Types as pendingInvitationsTypes } from "./pendingInvitations/types";

/**
 * Group Members
 */
import members from "./Members";
import { Creators as membersActions } from "./Members/actions";
import { Types as membersTypes } from "./Members/types";

/**
 * Need to be realocated
 * All profiles actions
 */
import profiles from "./profiles";
import { Creators as profilesActions } from "./profiles/actions";
import { Types as profilesTypes } from "./profiles/types";

/**
 * All posts actions
 */
import posts from "./posts";
import { Creators as postsActions } from "./posts/actions";
import { Types as postsTypes } from "./posts/types";

/**
 * All group actions
 */
import groups from "./groups";
import { Creators as groupsActions } from "./groups/actions";
import { Types as groupsTypes } from "./groups/types";

const SocialActions = {
  myGroupsActions,
  membersActions,
  postsActions,
  groupsActions,
  profilesActions,
  recommendedGroupsActions,
  pendingInvitationsActions
};

const SocialTypes = {
  myGroupsTypes,
  membersTypes,
  profilesTypes,
  groupsTypes,
  postsTypes,
  recommendedGroupsTypes,
  pendingInvitationsTypes
};
export default combineReducers({
  myGroups,
  members,
  profiles,
  posts,
  groups,
  recommendedGroups,
  pendingInvitations
});

export { SocialActions, SocialTypes };
