import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import memoize from "memoize-one";
import _ from "lodash";
import CourseAssessmentItem from "./CourseAssessmentItem";
import { Creators as CourseActions } from "../../store/ducks/newCourse/actions";
import SideBarMenuItemCourse from './SideBarMenuItemCourse'

class Disciplina extends Component {
  selfAssessmentFilter = memoize(assessments =>
    (assessments || []).find(item => item.type === "SELF_ASSESSMENT")
  );

  assessmentsFilter = memoize(assessments =>
    (assessments || []).filter(item => {
      return (
        item.type !== "SELF_ASSESSMENT" && item.type !== "EVALUATION_BOARD"
      );
    })
  );

  openSelfAssessment = selfAssessment => {
    const { data, course } = this.props;
    const crCode = _.get(selfAssessment, "cr_code", "");
    const redirectRoute = `${window.location.origin}/assessment-waiting-room?type=${selfAssessment.type}&course=${data.code}&code=${crCode}&courseCrCode=${course.code}`;
    window.open(redirectRoute, "__blank");
  };

  handlerDisciplineClick = () => {
    const { idDiscipline, syncGradeByDiscipline } = this.props;
    syncGradeByDiscipline(idDiscipline);
  };

  render() {
    const { data, children, first, defaultSelectedCourse, progress, isTrail, course, ...rest } = this.props;
    const collapse = data.index === defaultSelectedCourse ? "show" : "";
    const isActive = data.index === defaultSelectedCourse
    const assessments = this.assessmentsFilter(data && data.assessments);
    let currentData = data;
    if(course.type === 'TRAIL') {
      const foundCourse = course.children.find(item => item.code === data.code)
      currentData = foundCourse || data
    }

    return (
      <div id={`heading${data.code}${data.index}`} 
      {...rest}>
        
        <SideBarMenuItemCourse
          title={data.name}
          iconNumeric={isTrail ? data.index : data.index+1}
          data-toggle="collapse"
          data-target={`#collapse${data.code}${data.index}`}
          aria-expanded={isActive}
          aria-controls={`collapse${data.code}${data.index}`}
          data-for="disciplina"
          data-tip="Minimize ou maximize o conteúdo e saiba o quanto do curso foi realizado"
          data={currentData}
          progress={progress}
        />

        <div
          id={`collapse${data.code}${data.index}`}
          className={`collapse ${collapse}`}
          aria-labelledby={`heading${data.code}${data.index}`}
          data-parent="#accordionExample"
        >
          <div className="conteudo-disciplina">{children}</div>

          {assessments &&
            assessments.map(item => {
              return <CourseAssessmentItem data={item} courseCrCode={data.code} />;
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  course: state.newCourse.data,
  defaultSelectedCourse: state.newCourse.defaultSelectedCourse,
  idDisciplineProgress: state.newCourse.idDisciplineProgress,
  selectedDiscipline: state.newCourse.selectedDiscipline,
  progress: state.newCourse.progress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CourseActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Disciplina);
