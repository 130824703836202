import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify"
import md5 from 'md5'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { toastErrorAndWarningOptions } from '../../utils/functions'
import { fetchUseAndPolicyTerms } from '../../business/auth'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: 'linear-gradient(45deg, rgba(2, 196, 255, 1) 25%, rgba(142, 230, 123, 1) 100%)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTermos({ 
  visible, 
  onClose, 
  userEmail, 
  fullScreen = true, 
  maxWidth = 'xl',
  ...extraProps
}) {

  const [formattedUseTerm, setFormattedUseTerm] = useState('')

  async function fetchTerms() {
    try {
      const { useTerm } = await fetchUseAndPolicyTerms();
      const useTermText = useTerm.termText;
      const useTermHash = userEmail ? md5(`${useTerm.id}-${userEmail}`) : '';
      setFormattedUseTerm(useTermText.replace('**assinatura**', useTermHash));
    } catch (error) {
      console.log(error)
      toast.error('Erro ao buscar termo!', toastErrorAndWarningOptions)
      onClose()
    }
  };


  useEffect(() => {
    if (visible) {
      fetchTerms()
    }
  }, [visible])


  const classes = useStyles();
  return (
    <div className={classes.container}>
      {formattedUseTerm.length > 0 ? (
        <Dialog fullScreen={fullScreen} maxWidth={maxWidth} open={visible} onClose={onClose} TransitionComponent={Transition} {...extraProps}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                {/* <CloseIcon /> */}
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Termos e Condições de Uso
              </Typography>
              <Button autoFocus color="inherit" onClick={onClose}>
                Fechar
              </Button>
            </Toolbar>
          </AppBar>
          <div className="modal-content">
            <div className="modal-body d-flex justify-content-center">
              <div dangerouslySetInnerHTML={{ __html: formattedUseTerm }} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-blue" onClick={onClose}>
                Fechar
              </button>
            </div>
          </div>
        </Dialog>
      ) : ''}
    </div>
  )
}