import { Types } from "./types"
/**
 * Actions
 */
export const Creators = {
  setMyClasses: data => ({
    type: Types.SET_MY_CLASSES,
    payload: data,
  })

}
