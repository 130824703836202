import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} color //{LIGHT} sets the font color of the component
 * @param {Component} children //content that will be rendered
 * @param {String} appearance //{FILTER} defines the appearance of the component
**/

export default ({
  color = DEFAULT_VALUES.STRING,
  children = DEFAULT_VALUES.COMPONENT,
  appearance = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        color={color}
        child={children}
        appearance={appearance}
      />
    </>
  );
};
