import { combineReducers } from 'redux';

import user from './user';
import voucher from './voucher';
import notifications from './notifications';
import social from './social';
import payments from './payments';
import paulChat from './paulChat';
import course from './course';
import newCourse from './newCourse';
import alert from './alert';
import onboarding from './onboarding';
import installments from './payments/installments';
import plan from './payments/plan'
import coupon from './payments/coupon';

export default combineReducers({
  user,
  voucher,
  notifications,
  social,
  payments,
  paulChat,
  course,
  alert,
  onboarding,
  newCourse,
  installments,
  coupon,
  plan
});
