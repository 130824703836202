import React from 'react'

import Button from '@material-ui/core/Button';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';

import * as Styles from '../styles';

import useMuteAllMembers from '../hooks/useMuteAllMembers';

const StyledButton = Styles.Button(Button)

export default React.memo(() => {
  const [membersMuted, toggleMuted] = useMuteAllMembers()

  return (
    <StyledButton
      variant='contained'
      color='primary'
      size='small'
      onClick={toggleMuted}
      startIcon={membersMuted ? <MicOffIcon /> : <MicIcon />}
    >
      {membersMuted ? "Dessilenciar todos" : "Silenciar todos"}
    </StyledButton>
  );
})
