import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 987px;
  margin: auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;

  & + div {
    margin-top: 20px;
  }
`
