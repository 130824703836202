/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from "react";
import {FaRegTimesCircle} from 'react-icons/fa'
import PropTypes from "prop-types";
import { debounce } from 'lodash'

import {Container} from './styles'

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
    placeholder: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    loading: PropTypes.bool,
    withRequestAPI: PropTypes.bool
  };

  static defaultProps = {
    suggestions: [],
    id: '',
    placeholder: '',
    value: '',
    name: '',
    loading: false,
    withRequestAPI: false
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSuggestion: 0,
      showSuggestions: false,
      filteredSuggestions: [],
      userInput: props.value || ""
    };

    this.onChangeDebounced = debounce(this.onChangeDebounced, 1000)
  }

  onChangeInputValue = (e) => {
    this.setState({ userInput: e.currentTarget.value })
    this.onChangeDebounced()
  }

  onChangeDebounced = () => {
    // Delayed logic goes here
    this.onChange()
  }

  onChange = () => {
    const { onHandleChange, onHandleSearch, suggestions, withRequestAPI } = this.props;
    const {userInput} = this.state;

    const filteredSuggestions = !withRequestAPI ? suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    ): [];

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
    });
    onHandleSearch(userInput)
    onHandleChange(userInput)
  };


  onClick = e => {
    const {onHandleClick} = this.props
    this.setState({
      activeSuggestion: 0,
      showSuggestions: false,
      filteredSuggestions: [],
      userInput: e.currentTarget.innerText
    });
    onHandleClick(e.currentTarget.innerText)
  };

  
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const {suggestions, withRequestAPI} = this.props

    const currentSuggestions = withRequestAPI ? suggestions : filteredSuggestions
  
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: currentSuggestions[activeSuggestion]
      });
    }
    
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
   
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === currentSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  closeSuggestion = () => {
    this.setState({showSuggestions: false})
  }

  render() {
    const {
      onChangeInputValue,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        showSuggestions,
        userInput,
        filteredSuggestions
      }
    } = this;

    const {placeholder, name, onHandleBlur, suggestions, loading, withRequestAPI, id} = this.props

    let suggestionsListComponent;

    if(loading) {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>Buscando termo informado...</em>
        </div>
      );
    }

    const currentSuggestions = withRequestAPI ? suggestions : filteredSuggestions

    if (showSuggestions && userInput && !loading) {
      if (currentSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {currentSuggestions.map((suggestion, index) => {
              let className;
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={index}
                  onClick={onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>Nenhum resultado encontrado</em>
            <FaRegTimesCircle onClick={this.closeSuggestion} title="Fechar sugestões" />
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
          id={id}
          autoComplete="off"
          className="form-control"
          placeholder={placeholder}
          type="text"
          onChange={onChangeInputValue}
          onKeyDown={onKeyDown}
          onBlur={onHandleBlur}
          value={userInput}
          name={name}
        />
        <Container>
        {suggestionsListComponent}
        </Container>
      </Fragment>
    );
  }
}

export default Autocomplete;