import React, {useState} from 'react'
import { maskPhone } from "../../../utils/functions"

import {ButtonCollapse, TitleRecommendations, Line } from './styles'

export default function RecommendationList({sendList}) {

  const [open, setOpen] = useState(false)

  function toggleOpen() {
    setOpen((state) => !state)
  }

  return (
    <div className="accordion" id="accordionExample">
      <div id="headingOne">
        <h5 className="mb-0">
          <ButtonCollapse
              onClick={toggleOpen}
              className="btn btn-link" 
              type="button" 
              data-toggle="collapse" 
              data-target="#collapseRecommendation" 
              aria-expanded="true" 
              aria-controls="collapseRecommendation"
          >
            <TitleRecommendations>Recomendações</TitleRecommendations>
            {open && (
              <i className="fa fa-chevron-up" aria-hidden="true" />
            )}
            {!open && (
              <i className="fa fa-chevron-down" aria-hidden="true" />
            )}
          </ButtonCollapse>
        </h5>
      </div>
  
      <div id="collapseRecommendation" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th width="20%">Plano</th>
                  <th width="15%">Nome</th>
                  <th width="25%">Email</th>
                  <th width="20%">WhatsApp</th>
                  <th>Data</th>
                </tr>
              </thead>
                <tbody>
                  {sendList.map(indic => (
                    <Line key={indic.id}>
                      <td>{indic.planName}</td>
                      <td>{indic.name}</td>
                      <td >{indic.email}</td>
                      <td>{maskPhone(indic.phoneNumber)}</td>
                      <td>{indic.date}</td>
                    </Line>
                  ))}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
   
  )
}
