import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES, ICON } from "../../constants";

/**
 * @param {Array} archives //list of files to be rendered [type, fileContent, name]
 * @param {String} color //color of blocks or list
 * @param {Function} removeAction //action that will be triggered when clicking remove item
**/

export default ({
  archives = DEFAULT_VALUES.LIST,
  color = DEFAULT_VALUES.STRING,
  removeAction = DEFAULT_VALUES.FUNCTION
}) => {
  function validateType(value) {
    switch (value) {
      case 'image/jpeg':
        return ICON.IMAGE;
      case 'image/png':
        return ICON.IMAGE;
      case 'document':
        return ICON.FILE;
      case 'application/pdf':
        return ICON.PDF;
      case 'video':
        return ICON.VIDEO;
      default:
        return ICON.FILE;
    }
  }
  return (
    <>
      <Screen
        archives={archives}
        color={color}
        removeAction={removeAction}
        validateType={validateType}
      />
    </>
  );
};
