import styled from "styled-components";

// export const Container = styled.div`
//   width: 100%;
//   padding: 5px;
//   background-color: #fff;
//   border: 1px solid #999;
//   margin-top: 10px;
//   margin-bottom: 10px;

//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
// `;

export const Title = styled.div`
  font-size: 28px;
  text-align: center;
  width: 100%;
  color: white;
  fontFamily: 'Lato';
  color: ${props => props.headerGradient ? 'white' : '#000'};
`;

export const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 673px) {
    flex-wrap: wrap;
  }
`;

export const BoxInfo = styled.div`
  background: ${props => props.headerGradient ? props.headerGradient : 'white'};
  width: 100%;
  height: 100px;
  padding: 7px 5px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 673px) {
    width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  & + div {
    margin-left: 5px;
  }

  p {
    text-transform: uppercase;
    font-size: 14px;
    margin: auto;
  }

  p.highlight {
    font-weight: bold;
    font-size: 20px;
  }
`;
