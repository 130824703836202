/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useMemo } from 'react'
import Modal from "react-responsive-modal"
import Pdfjs from "@devlit/react-pdfjs"

import ShareCertificate from '../ShareCertificate'

import { API_URL, API_URL_IMAGES, AWS_URL } from "../../../utils/constants"
import api from '../../../utils/api'

import { ContainerButtonCertificate } from './styles'

function CardCursoConcluido({ course }) {

  const [certificateUrl, setUrlCertificado] = useState('')
  const [modalCertificado, setModalCertificado] = useState(false)
  const fetchCertificateUrl = async (idCourse) => {
    const idProfile = parseInt(localStorage.getItem("idProfile"), 10)
    try {
      const url = await api.get(`/certificate/${idCourse}/${idProfile}`);
      setUrlCertificado(url)
    } catch (error) {
      console.log(error)
    }
  }

  const abrirCertificado = async (data) => {
    if (data.newCourse) {
      setUrlCertificado(data.urlCertificado)
      setModalCertificado(true)
    } else {
      const idProfile = parseInt(localStorage.getItem("idProfile"), 10)
      let url
      let idCourse
      if (data.course) {
        url = `${AWS_URL}/courses/certifications/${data.course.id}/${idProfile}/certificado.png`
        idCourse = data.course.id
      } else {
        url = `${API_URL}generateCertificate/${idProfile}/${data.totvsId}`
        idCourse = data.totvsId
      }
      await fetchCertificateUrl(idCourse)
      setUrlCertificado(url)
      setModalCertificado(true)
    }

  }

  const imprimirCertificado = () => {
    // Certifique-se de que certificateUrl está definido
    if (!certificateUrl) {
      console.error("certificateUrl não está definido");
      return;
    }

    if (course.newCourse) {
      window.open(course.certificateUrl, '_blank');
    } else {
      const width = window.innerWidth * 0.9;
      const height = window.innerHeight * 0.9;

      const content = `<!DOCTYPE html>
        <html>
          <head>
            <title>Certificado</title>
            <style>
              @page { size: landscape; margin: 0; }
              body { margin: 0; }
            </style>
          </head>
          <body onload="window.print(); setTimeout(() => window.close(), 1000);">
            <img src="${certificateUrl}" style="width: 100%; height: 200mm;" />
          </body>
        </html>`;

      const options = `toolbar=no,location=no,directories=no,menubar=no,scrollbars=yes,width=${width},height=${height}`;

      const printWindow = window.open("", "print", options);

      if (!printWindow) {
        console.error("A janela do navegador não pôde ser aberta. Verifique as configurações do navegador.");
        return;
      }

      printWindow.document.write(content);
      printWindow.document.close();
    }
  };


  const closeModalCertificado = () => {
    setUrlCertificado('')
    setModalCertificado(false)
  }

  return (
    <>
      {course.newCourse && (
        <div className="col-md-3 d-inline-block " style={{ padding: "0 5px", marginBottom: '20px' }}>
          <div>
            <img
              alt="Capa curso"
              height={100}
              className="img-course img-fluid"
              src={course.coverUrl}
            />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <span>
                <i style={{ fontSize: 16, color: '#27aae1' }} className="fa fa-star" aria-hidden="true" />{" "}
                {course.gradeAverage}
              </span>
            </div>
            <div>
              <button
                style={{ color: '#27aae1' }}
                type="button"
                className="btn btn-link p-0"
                onClick={() => abrirCertificado(course)}
              >
                <i
                  style={{ fontSize: 16, color: '#27aae1' }}
                  className="fa fa-graduation-cap"
                  aria-hidden="true"
                />{" "}
                Certificado
              </button>
            </div>
          </div>
        </div>
      )}
      {!course.newCourse && (
        <div className="col-md-3 d-inline-block " style={{ padding: "0 5px", marginBottom: '20px' }}>
          <div>
            <img
              alt="Capa curso"
              height={100}
              className="img-course img-fluid"
              src={`${AWS_URL}/courses/course_cover/${course.totvsId}.jpg`}
            />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <span>
                <i style={{ fontSize: 16, color: '#27aae1' }} className="fa fa-star" aria-hidden="true" />{" "}
                {Math.round((parseFloat(course.grade) || 0) * 2) / 2}
              </span>
            </div>
            <div>
              <button
                style={{ color: '#27aae1' }}
                type="button"
                className="btn btn-link p-0"
                onClick={() => abrirCertificado(course)}
              >
                <i
                  style={{ fontSize: 16, color: '#27aae1' }}
                  className="fa fa-graduation-cap"
                  aria-hidden="true"
                />{" "}
                Certificado
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={modalCertificado}
        onClose={closeModalCertificado}
        classNames={{ modal: "lit-perfil-form" }}
        closeOnOverlayClick={false}
      >
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              justifyContent: "center",
            }}
          >
            <h5
              className="modal-title"
              style={{
                color: "#27aae1",
              }}
            >
              Certificado
            </h5>
          </div>
          <div
            className="modal-body"
            style={{
              background: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {course.newCourse && (
              <Pdfjs file={course.certificateUrl} scale={0.5} />
            )}
            {!course.newCourse && (
              <>
                <img
                  alt="certificado"
                  src={certificateUrl}
                  style={{
                    width: "90%",
                    height: "auto",
                  }}
                />
              </>
            )}

            <ContainerButtonCertificate>
              <button
                type="button"
                className="btn btn-certicado btn-FontWeight6 "
                onClick={imprimirCertificado}
              >
                <a>Imprimir certificado</a>
              </button>
              <ShareCertificate
                url={course.newCourse ? course.certificateUrl : certificateUrl}
              />
            </ContainerButtonCertificate>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CardCursoConcluido
