import React, { useEffect, useContext } from "react";

import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";

import { makeStyles } from "@material-ui/core/styles";
import { MeetingContext } from "../../../MeetingContext";
import useMute from "../hooks/useMute";
import ActionButton from "../ActionButton/ActionButton";

export default React.memo(() => {
  const [muted, toggleMuted, loading] = useMute("video");
  const { setMuteLocalCam } = useContext(MeetingContext);

  const classes = makeStyles(() => {
    return {
      successIcon: {
        color: '#4caf50',
      },
      errorIcon: {
        color: '#F44336',
      },
    }
  })();

  useEffect(() => {
    if (!loading) {
      setMuteLocalCam(muted);
    }
  }, [loading]);

  return (
    <ActionButton 
        aria-label={muted ? "Ligar câmera" : "Desligar câmera"}
        title={muted ? "Ligar câmera" : "Desligar câmera"}
        onClick={toggleMuted}>
      {muted ? <VideocamOffIcon className={classes.errorIcon}/> : <VideocamIcon className={classes.successIcon}/>}
    </ActionButton>
  );
});
