import styled from "styled-components";
import { } from "../../../constants";

export const LeftBar = styled.div`
  display: inline-flex;
  align-items: flex-start;
  text-align: left;
  width: 70%;
  @media screen and (max-width: 991px) {
     float: left;
  }
  @media screen and (max-width: 767px) {
     width: 100%;
  }
`;

export const RightBar = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  width: 30%;
  @media screen and (max-width: 767px) { 
    width: 100%;
  }
`;
