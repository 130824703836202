import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../../constants";

/**
 * @param {String} logoPage //logo displayed at the top of the page
 * @param {Array} menuNavigation //navigation menu items displayed at the top of the page
**/

export default ({
  logoPage = DEFAULT_VALUES.STRING,
  menuNavigation = DEFAULT_VALUES.LIST
}) => {

  const [openSearch, setOpenSearch] = React.useState(false);
  const [hiddenMenu, setHiddenMenu] = React.useState(false);

  return (
    <>
      <Screen
        logoPage={logoPage}
        menuNavigation={menuNavigation}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        hiddenMenu={hiddenMenu}
        setHiddenMenu={setHiddenMenu}
      />
    </>
  );
};
