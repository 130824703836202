import _ from "lodash";

import { useState, useCallback, useContext } from "react";

import { MeetingContext } from "../../../MeetingContext";

export default channel => {
  const { meeting } = useContext(MeetingContext);

  const channelsArray = ["video", "audio"];
  if (!channelsArray.includes(channel)) {
    throw new Error("Invalid Channel");
  }

  const upperFirst = _.upperFirst(channel);

  const channelObject = {
    isMuted: () => meeting[`is${upperFirst}Muted`](),
    toggleMute: muted =>
      meeting[muted ? `unmute${upperFirst}` : `mute${upperFirst}`]()
  };

  const [muted, _setMute] = useState(channelObject.isMuted());
  const [reset, setReset] = useState(0);
  const [loading, setLoading] = useState(false);

  const toggleMute = useCallback(
    _.throttle(
      () => {
        setLoading(true);
        channelObject
          .toggleMute(muted)
          .then(() => {
            setReset(p => p + 1);
            setLoading(false);
          })
          .catch(console.log);
        _setMute(!muted);
      },
      10000,
      { trailing: false }
    ),
    [muted, _setMute, reset]
  );

  // Logic to display mic muted when professor mutes it
  meeting.on("meeting:self:mutedByOthers", () => {
    if (channel === "audio") {
      _setMute(true);
    }
  });

  return [muted, toggleMute, loading];
};
