import { Types } from "./types"

/**
 * Reducer
 */

const INITIAL_STATE = {
  loading: false,
  called: false,
  error: null,
  data: [],
}

export default function recommendGroups(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_RECOMMENDED_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.GET_RECOMMENDED_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        called: true,
        data: action.payload.data,
      }
    case Types.GET_RECOMMENDED_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}
