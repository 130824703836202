import React, { useState } from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../constants";

/**
 * @param {String} orientation //{HORIZONTAL, VERTICAL} direction of menu items
 * @param {Array} options //list of menu items
 * @param {String} color //font color
 * @param {String} size //font size
 * @param {Boolean} hiddenMenu //top-page menubars component click event
**/

export default ({
  orientation = DEFAULT_VALUES.STRING,
  options = DEFAULT_VALUES.LIST,
  color = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING,
  hiddenMenu = DEFAULT_VALUES.BOOLEAN
}) => {
  return (
    <>
      <Screen
        orientation={orientation}
        options={options}
        color={color}
        size={size}
        hiddenMenu={hiddenMenu}
      />
    </>
  );
};
