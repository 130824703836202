import React from "react"
import { GoogleLogin } from "react-google-login"

function SocialGoogle({ handleSocialLogin, handleSocialError, children }) {
  function responseGoogle(user) {
    const data = {
      idSocial: user.googleId,
      socialToken: user.accessToken,
      platform: "GOOGLE",
      profile: {
        firstName: user.profileObj.givenName,
        lastName: user.profileObj.familyName,
        linkPhoto: user.profileObj.imageUrl,
        login: {
          username: user.profileObj.email,
          password: user.googleId,
          enabled: true,
          role: {
            idRole: 2,
          },
        },
      },
    }
    handleSocialLogin(data)
  }

  return (
    <GoogleLogin
      clientId="138661968258-msujgofo0vhg3dfgbv29v4m6g16c4ocj.apps.googleusercontent.com"
      render={renderProps => (
        <div role="button" onClick={renderProps.onClick}>
          {children}
        </div>
      )}
      onSuccess={responseGoogle}
      onFailure={handleSocialError}
      cookiePolicy="single_host_origin"
    />
  )
}

export default SocialGoogle
