import styled, { css } from "styled-components";
import { POSITION, COLOR } from "../../../constants";

export const Tag = styled.span`
  
  ${props => (props.position === POSITION.LEFT) && css`
    border-radius: 5px;
    padding: 3px 9px;
    margin: 0 5px 5px 0!important;
    display: inline-block;
    width: auto!important;
    line-height: 1;
    float: left;
  `}

  ${props => (props.color === COLOR.LIGHT_GRAY) && css`
    background: var(--tag-primary);
    border: 1px solid var(--tag-primary-border);
    color: var(--tag-primary-text); 
  `}
  
`;
