import React from 'react';
import PropTypes from 'prop-types';

import Screen from './screen'

function CardLearningObject({learningObject, goToLearningObjectCourse}) {
  return (
    <Screen learningObject={learningObject} goToLearningObjectCourse={goToLearningObjectCourse} />
  );
}

CardLearningObject.propTypes = {
  learningObject: PropTypes.object.isRequired,
  goToLearningObjectCourse: PropTypes.func.isRequired
}

export default CardLearningObject;
