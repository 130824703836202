import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 0;
  max-width: 700px;
  margin: 0 auto;

  ol {
    padding-left: 10px;
    li {
      text-align: justify;
      width: 100%;
      color: #58595b;
    }
  }

  p {
    text-align: justify;
    margin-bottom: 10px;
  }
`
export const Title = styled.h2`
  margin-bottom: 10px;
`

export const ContainerButtonPersonalize = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Button = styled.button`
  padding: 10px;
  border-radius: 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  border: 0;
  color: white;
  /* background-image: linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%); */
  background: #27aae1;
`

