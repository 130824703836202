import styled from "styled-components";

export const InnerMembersBox = boxComponent => styled(boxComponent)`
  overflow-y: scroll;
  overflow-x: hidden;
  border-width: 2px;
  border-color: #cccccc;
  border-style: solid;
  border-left-width: 0;
`;

export const List = listComponent => styled(listComponent)`
  padding: 0 !important;
`;

export const Button = buttonComponent => styled(buttonComponent)`
  background: var(--button-primary);
  color: var(--button-primary-text);
`;
