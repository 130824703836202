/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import Lane from "../../../../../shared/Lane";
import CardCourse from "../CardCurso";
import * as Styles from "./styles";

function Screen({ courses, getMoreCourses, onClick, errorCoursesMessage }) {
  // quando for entregar o blended validar com tipo de turma
  if (!courses.length) {
    return null;
  }

  return (
    <Styles.Container>
      <Lane title="Disciplinas da turma" paginationHook={getMoreCourses}>
        {!courses.length && (
          <Styles.EmptyListText>
            {errorCoursesMessage ? errorCoursesMessage : "Sem disciplinas"}
          </Styles.EmptyListText>
        )}
        {courses.map(course => {
          return (
            <Styles.Wrapper key={`${course.id}`}>
              <CardCourse
                course={course}
                onClick={course => onClick(course)}
                progresso
              />
            </Styles.Wrapper>
          );
        })}
      </Lane>
    </Styles.Container>
  );
}

export default Screen;
