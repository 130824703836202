/* eslint-disable func-names */
import React, { useState } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import {
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";

export default function shareCertificate({url}){
  
  const [popoverOpen, setPopoverOpen] = useState(false);
  
  const onHoverLeave = () => {
    setPopoverOpen(false)
  }

  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div>
       <button
          type="button"
          id="Popover1"
          className="btn btn-certicado btn-FontWeight6" 
        >
        Compartilhar certificado
      </button>
    
      <Popover 
        placement="bottom" 
        isOpen={popoverOpen} 
        target="Popover1" 
        toggle={toggle}
        popperClassName='popover-lit'
      >
        <PopoverHeader>Compartilhar com:</PopoverHeader>
        <PopoverBody  onMouseLeave={onHoverLeave}>
          <LinkedinShareButton url={url} title="Linkedin">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </PopoverBody>
      </Popover>

    </div>
  )
}
