import _ from 'lodash';

export default (selectedPlan, paymentMethod) => {
  const formattedInstallment = _.get(selectedPlan, 'paymentMethods', []);
  const formattedInstallmentItem = formattedInstallment.find(
    installmentItem => installmentItem.type === paymentMethod
  );

  const formattedInstallmentList = _.get(formattedInstallmentItem, 'formattedInstallment', [])
  const installments = [{
    value: 0,
    label: 'Selecione a quantidade de parcelas'
  }, 
  ...formattedInstallmentList];

  return installments;
};
