import React, { Component } from "react"
import { Radar } from "react-chartjs-2"
import { connect } from "react-redux"

class RadarChart extends Component {
  state = {
    arrayLabels: [],
    arrayData: [],
  }

  componentDidMount() {
    const { personalityInsight } = this.props
    const arrayLabels = personalityInsight.personality.map(info => {
      return info.name === "Escrupulosidade" ? "Consciência" : info.name
    })

    const arrayData = personalityInsight.personality.map(info => {
      return Math.floor(info.percentile * 100)
    })

    this.setState({ arrayData, arrayLabels })
  }

  render() {
    const { arrayData, arrayLabels } = this.state
    const data = canvas => {
      const ctx = canvas.getContext("2d")
      const gradient = ctx.createLinearGradient(0, 50, 0, 180)
      gradient.addColorStop(0, "#1BA8D6")
      gradient.addColorStop(1, "#5FC096")

      return {
        labels: arrayLabels,
        datasets: [
          {
            label: "Minha personalidade",
            backgroundColor: gradient,
            pointBackgroundColor: gradient,
            pointBorderColor: gradient,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: gradient,
            data: arrayData,
          },
        ],
      }
    }

    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        displayColors: false,
        callbacks: {
          title(tooltipItem, dataTitle) {
            const label = dataTitle.labels[tooltipItem[0].index] || ""
            const valor = dataTitle.datasets[0].data[tooltipItem[0].index]
            return `${label}: ${valor}`
          },
          beforeLabel(tooltipItem, dataBeforeLabel) {
            const label = dataBeforeLabel.labels[tooltipItem.index] || ""
            switch (label) {
              case "Neuroticismo":
              case "Faixa emocional":
                return "O alcance emocional, também conhecido como "
              default:
                return ""
            }
          },
          label(tooltipItem, dataLabel) {
            const label = dataLabel.labels[tooltipItem.index] || ""
            switch (label) {
              case "Abertura":
                return "A abertura é o quanto uma pessoa está "
              case "Consciência":
                return "Consciência é a tendência de uma pessoa "
              case "Extroversão":
                return "A extroversão é a tendência de uma pessoa "
              case "Amabilidade":
                return "A amabilidade é a tendência de uma pessoa "
              case "Neuroticismo":
              case "Faixa emocional":
                return "neuroticismo ou reações naturais, é o quanto "
              default:
                return ""
            }
          },
          afterLabel(tooltipItem, dataAfterLabel) {
            const label = dataAfterLabel.labels[tooltipItem.index] || ""
            switch (label) {
              case "Abertura":
                return "aberta a experimentar atividades diferentes."
              case "Consciência":
                return "agir de maneira organizada ou pensativa."
              case "Extroversão":
                return "buscar estímulo na companhia de outras pessoas."
              case "Amabilidade":
                return "ser compassiva e cooperativa em relação às outras."
              case "Neuroticismo":
              case "Faixa emocional":
                return "as emoções de uma pessoa são sensíveis ao ambiente em que ela vive."
              default:
                return ""
            }
          },
        },
      },
    }

    return <Radar data={data} options={options} />
  }
}

const mapStateToProps = state => {
  return {
    personalityInsight: state.user.personalityInsight.data,
  }
}

export default connect(mapStateToProps)(RadarChart)
