import React from "react";
import { COLOR } from "../../constants";
import Icon from "../basicComponents/Icon";
import Clickable from "../basicComponents/Clickable";
import Button from "../basicComponents/Button";
import { PaddingDiv } from "./styles";

export default ({ itemsToRender, orientation, format, position }) => {
  return (
    <>
      <PaddingDiv orientation={orientation} format={format} position={position}>
        {itemsToRender.map(item => {
          return item.type === "icon" ? (
            <Clickable 
              onClick={item.action} 
              onLeave={item.onLeave} 
              onEnter={item.onEnter}
            >
              <Icon
                size={item.size}
                icon={item.icon}
                color={COLOR.LIGHT}
                badgeValue={item.badgeValue}
              />
            </Clickable>
          ) : item.type === "button" ? (
            !item.hiddenInterations && (
              <Button
                onClick={item.action}
                onLeave={item.onLeave} 
                onEnter={item.onEnter}
                text={item.buttonText}
                color={item.color}
                icon={item.icon}
                appearance={item.appearance}
                size={item.size}
                valueInteractions={item.valueInteractions}
                actionValueInterations={item.actionValueInterations}
              />
            )
          ) : null;
        })}
      </PaddingDiv>
    </>
  );
};
