import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} title //displays the component title
 * @param {String} description //displays the component description
 * @param {String} image //path (url) of the image to be displayed
**/

export default ({
  title = DEFAULT_VALUES.STRING,
  description = DEFAULT_VALUES.STRING,
  image = DEFAULT_VALUES.STRING
}) => {

  const [hiddenBlock, setHiddenBlock] = React.useState(false);

  return (
    <>
      <Screen
        title={title}
        description={description}
        image={image}
        hiddenBlock={hiddenBlock}
        setHiddenBlock={setHiddenBlock}
      />
    </>
  );
};
