import React, { Component } from 'react';
import 'moment/locale/pt-br';
import { gateway } from '../../../utils/api';

import ListaRecibo from './ListaRecibo';

import { Info, BoxTitle } from './styles';
import { formatReal } from '../../../utils/functions';
import ListInvoice from './ListInvoice';

class Recibos extends Component {
  state = {
    visibleBody: false,
    userData: [],
    shopper: {},
    payments: [],
    valueWithDiscount: null,
    discounts: null,
    invoiceList: []
  };

  componentDidMount() {
    this.getReceipt();
  }

  getHistoryPaymentListMonolith = async () => {
    const idProflie = localStorage.getItem('idProfile');
    const url = `webbff/lit_app_web/payment/getHistoryPaymentList/${idProflie}`;
    return gateway.get(url);
  };

  getInvoiceHistory = async () => {
    const email = localStorage.getItem('ra');
    const url = `webbff/lit_app_web/nfe/totvs?email=${email}`;
    return gateway.get(url);
  };

  getHistoryPayment = async () => {
    const url = 'webbff/lit_app_web/payment/getHistoryPayment';
    return gateway.get(url);
  };

  getSocialName = async () => {
    const email = localStorage.getItem('ra');
    const url = `webbff/lit_app_web/person/getSocialNameByEmail?email=${email}`;

    return gateway.get(url);
  };

  getReceipt = async () => {
    try {
      const [
        historyPaymentMonolith,
        historyPayment,
        invoiceHistory,
        getSocialName
      ] = await Promise.all([
        this.getHistoryPaymentListMonolith().catch(() => {}),
        this.getHistoryPayment().catch(() => {}),
        this.getInvoiceHistory().catch(() => {}),
        this.getSocialName().catch(() => {})
      ]);

      this.setState({
        invoiceList: invoiceHistory || []
      });

      const { enableSocialName, socialName } = getSocialName;

      if (historyPaymentMonolith) {
        const { payments } = this.state;
        const {
          cpf,
          email,
          nome,
          shopper,
          payments: paymentsMonolith,
          valueWithDiscount,
          discounts
        } = historyPaymentMonolith;
        const mergedPayments = [...payments, ...paymentsMonolith];
        this.setState({
          userData: { cpf, email, nome: enableSocialName ? socialName : nome },
          shopper: { shopper },
          valueWithDiscount,
          discounts,
          payments: mergedPayments
        });
      }

      if (historyPayment && historyPaymentMonolith) {
        const { payments: newPayments } = historyPayment;
        const { payments } = this.state;
        const mergedPayments = [...newPayments, ...payments];
        this.setState({
          payments: mergedPayments
        });
      }

      if (historyPayment && !historyPaymentMonolith) {
        const { cpf, email, nome, shopper, payments } = historyPayment;
        const mergedPayments = [...payments];
        this.setState({
          userData: { cpf, email, nome: enableSocialName ? socialName : nome },
          shopper: { shopper },
          payments: mergedPayments
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  changeVisible = () => {
    const { visibleBody } = this.state;
    this.setState({ visibleBody: !visibleBody });
  };

  render() {
    const {
      visibleBody,
      payments,
      userData,
      shopper,
      valueWithDiscount,
      discounts,
      invoiceList
    } = this.state;
    const { endOfMonth } = this.props;
    return (
      <div className="cursos bg-white-perfil" id="recibos-usuario">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <BoxTitle>
                <h5>Pagamentos</h5>
                <span style={{ fontSize: '16px', marginLeft: '10px' }}>
                  {endOfMonth}
                </span>
              </BoxTitle>

              <button
                type="button"
                className="btn btn-icon ml-auto"
                onClick={this.changeVisible}
              >
                {visibleBody ? (
                  <i className="fa fa-chevron-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {visibleBody && (
            <React.Fragment>
              {discounts && Object.keys(discounts).length > 0 && (
                <div className="row">
                  <div className="col-md-12">
                    <Info>
                      <div>
                        <b>Voucher:</b>{' '}
                        {discounts.voucher.valuePercent < 0 ? (
                          <span>3 anos + MBA</span>
                        ) : (
                          <span>
                            {' '}
                            {discounts.voucher.valuePercent.toFixed(2)} %{' '}
                          </span>
                        )}
                      </div>
                      <div>
                        <b>Cupom: </b>{' '}
                        {discounts.coupon.valuePercent.toFixed(2)}%
                      </div>
                      <div>
                        <b>MGM: </b> {discounts.mgm.valuePercent.toFixed(2)}%
                      </div>
                      <div>
                        <b>Valor com desconto:</b> R$
                        {formatReal(valueWithDiscount)}
                      </div>
                    </Info>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col">
                  {payments.length ? (
                    <ListaRecibo
                      payments={payments}
                      userData={userData}
                      shopper={shopper}
                    />
                  ) : (
                    <p>Sem recibos para exibir</p>
                  )}
                </div>
              </div>
              <div>
                <BoxTitle>
                  <h5>Notas Fiscais</h5>
                </BoxTitle>
                <div className="row" style={{ paddingTop: '10px' }}>
                  <div className="col">
                    {invoiceList.length ? (
                      <ListInvoice invoiceList={invoiceList} />
                    ) : (
                      <p>Sem Notas Fiscais para exibir</p>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Recibos;
