import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkedIn } from "react-linkedin-login-oauth2";
import { urlencode } from "../../utils/auth"

const SocialLinkedin = ({ handleFailure, handleSuccess, loading }) => (

  <LinkedIn
    clientId="7817bws2sdn30i"
    className={`btn btn-sm btn-block ${loading && " btn-loading"}`}
    onFailure={handleFailure}
    onSuccess={handleSuccess}
    scope="openid profile email"
    redirectUri={`${urlencode(window.location.origin)}/linkedin`} >
    {!loading && (
      <label style={{ cursor: "pointer" }} >
        <FontAwesomeIcon icon={['fab', 'linkedin']} 
          color="#0077B5"
          className="icon-space"
          size="lg"/>
        Vincular informações do Linkedin
      </label>
    )}
  </LinkedIn>

)
export default SocialLinkedin