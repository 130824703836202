import styled from 'styled-components';

export const Container = styled.div`
  .slider_info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    line-height: normal;
    padding: 4%;
    z-index: 9999;
    opacity: 0;
    cursor: pointer;
    text-align: left;
    font-size: 50%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.7) 100%
      );
    transition: 450ms opacity;
    color: white;
  }
  .slider_info:hover {
    opacity: 1;
  }
   .slider-container {
  /* if you want to have a scrollbar, you can add overflow here */ 
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem .1rem;
    padding: 0px;
    scroll-behavior: smooth;
    &:hover {
      .prev {
        opacity: 1;
        /* visibility: visible; */
      }
      .next {
        opacity: 1;
        /* visibility: visible; */
      }
    }
  }
  .slider-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .btn {
    height: ${props => props.fullButton ? '136px' : 'auto'};
    width: ${props => props.fullButton ? '39px' : 'auto'};
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    svg {
      display: none;
    }
    border-radius: 0;
  }

  .prev {
    z-index: 9999;
    left: 4px;
    font-family: FontAwesome !important;
    opacity: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    color: rgb(255, 255, 255) !important;
    padding: 25px 10px !important;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .next {
    z-index: 9999;
    right: 4px;
    font-family: FontAwesome !important;
    opacity: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    color: rgb(255, 255, 255) !important;
    padding: 25px 10px !important;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .prev:before {
    content: "\f053" !important;
    font-family: FontAwesome;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .next:before {
    content: "\f054" !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  .child {
    padding: 5px 20px;
    background: #59eb97;
    text-align: center;
    width: 100%;
    margin-right: 10px;
    border-radius: 21px;
  }  

`
