import styled from "styled-components";
import { Form } from "formik";

export const Container = styled.div`
  padding: 10px 0px;
  height: 350px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 14px;
    text-align: justify;
  }
`

export const ContentForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 20px !important;
  align-items: flex-end !important;
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label {
      color: #000 !important
    }

    input {
      padding: 10px;
    }

    span {
      color: red !important;
      font-size: 12px;
    }
  }

  button {
    margin: right;
  }
`
