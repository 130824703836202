import React, { memo } from "react"

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"
import LeftSideBar from "./LeftSideBar"
import Main from "./Main"
// import RightSideBar from "./RightSideBar"

const PerfilAluno = () => {
  const openPlansModal = window.location.search.includes('openPlanModal')

  return (
    <div>
      <NavBarSuperior />
      <div className="container-fluid" id="perfil">
        <div className="row">
          <div className="col-md-2" id="left-side-bar">
            <LeftSideBar />
          </div>
          <div className="col-md-10" id="main">
            <Main openPlansModal={openPlansModal} />
          </div>
          {/* <RightSideBar /> */}
        </div>
      </div>
    </div>
  )
}

export default memo(PerfilAluno)
