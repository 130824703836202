import React, { useContext } from "react";

import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";

import ActionButton from "../ActionButton";
import { MeetingContext } from '../../../MeetingContext'

export default React.memo(() => {
  const { meeting, muteShareScreen, setMuteShareScreen } = useContext(MeetingContext);

  const toggleScreenFn = () => {
    meeting[muteShareScreen ? "shareScreen" : "stopShare"]()
      .then(() => {
        setMuteShareScreen(!muteShareScreen);
      })
      .catch(console.log);
  };

  return (
    <ActionButton 
      aria-label={muteShareScreen ? "Compartilhar tela" : "Encerrar compartilhamento"}
      title={muteShareScreen ? "Compartilhar tela" : "Encerrar compartilhamento"}
      onClick={toggleScreenFn}>
      {muteShareScreen ? <StopScreenShareIcon /> : <ScreenShareIcon />}
    </ActionButton>
  );
})
