/* eslint-disable react/no-array-index-key */
import React, { memo } from "react"

import {Container} from './styles'

const RowTable = ({ name, weight, grade, media }) =>
  (
    <tr>
      <th className="">{name}</th>
      <th className="text-center">{weight}%</th>
      <th className="text-center">{grade}</th>
      <th className="text-center">{media}</th>
    </tr>
  )

  function isNumber(val){
    return typeof val === "number"
  }

const WeightsTable = ({ data }) => {
  
  return (
    <Container>
       <div className="col-md-12">
        <h5 className="text-center">Peso das avaliações</h5>
        {data && isNumber(data.media) ? (
          <div className="table-responsive">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Avaliação</th>
                  <th className="text-center">Peso</th>
                  <th className="text-center">Nota</th>
                  <th className="text-center">Média</th>
                </tr>
              </thead>
              <tbody>
                {data.assessments.map((item, index) => (
                  <RowTable 
                    key={index}
                    name={item.name} 
                    weight={item.weight && item.weight.toFixed(1)} 
                    grade={item.grade && item.grade.toFixed(1)} 
                    media={item.weightedGrade && item.weightedGrade.toFixed(1)} 
                  />
                ))}
               
                <tr>
                  <th className="text-right pr-2" colSpan={3}>
                    Média Final
                  </th>
                <th className="text-center">{data.media && data.media.toFixed(1)}</th>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
            <p className="text-center">Não possui dados de peso para serem exibidos</p>
          )}
      </div>
    </Container>
   
  )
}

export default memo(WeightsTable)
