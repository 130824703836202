import React from "react";
import {
  APPEARANCE,
  BORDER_TYPE,
  PAGE_INFORMATION,
  FONT_TYPE,
  SIZE
} from "../../../constants";
import { Link } from "react-router-dom";
import Text from "../Text";
import Image from "../Image";
import Column2x2 from "../../defaultAreas/Column_2x2";
import { Span, ButtonsAtBottom, Frame } from "./styles";

import imageDefault from "../../../../images/lit-default.jpg";

export default ({ contentCard, perLine, textAlign }) => {
  return (
    <>
      {contentCard.map(item => (
        <Span grid={perLine} textAlign={textAlign}>
          <Link to={`/beta/social/socialgroup/${item.code}`}>
            <Frame>
              <Image
                src={item.image || imageDefault}
                size={SIZE.MEDIUM}
                appearance={APPEARANCE.CARD}
                border={BORDER_TYPE.BORDER}
              />
            </Frame>
            <Text
              fontType={FONT_TYPE.BOLD}
              text={item.name}
              appearance={APPEARANCE.CARD_TITLE}
            />
            {item.description && (
              <Text
                size={SIZE.MEDIUM}
                text={item.description}
                appearance={APPEARANCE.CARD_DESCRIPTION}
              />
            )}
            <ButtonsAtBottom>
              <Column2x2
                left={
                  <Text
                    size={SIZE.SMALL}
                    fontType={FONT_TYPE.BOLD}
                    text={
                      item.visibility && item.visibility === "private"
                        ? "Privado"
                        : "Publico"
                    }
                  />
                }
                right={
                  <Text
                    size={SIZE.SMALL}
                    text={`${item.totalMembers} ${
                      item.totalMembers === 1
                        ? PAGE_INFORMATION.MEMBER
                        : PAGE_INFORMATION.MEMBERS
                    }`}
                  />
                }
              />
            </ButtonsAtBottom>
          </Link>
        </Span>
      ))}
    </>
  );
};
