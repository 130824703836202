import { Types } from "./types";

const INITIAL_STATE = {
  loading: false,
  called: false,
  error: null,
  allGroups: [
    {
      id: 5,
      displayType: "participate",
      name: "Quid ex ea commodi consequatur tempor id eu nisl nunc mi ipsum",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-17.png",
      totalMembers: 12,
      administrator: "Marcos Sanchez",
      description:
        "A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Privado"
    },
    {
      id: 1,
      displayType: "participate",
      name: "Quis autem quidam e nostris sed arcu non odio euismod lacinia at quis",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Home-Site-LIT-1.png",
      totalMembers: 19,
      administrator: "Dolores Swatz",
      description:
        "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Público"
    },
    {
      id: 4,
      displayType: "pendingSolicitations",
      name: "Tempor dignissim enim sit amet siam id eu nisl nunc mi ipsum ",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-16.png",
      totalMembers: 208,
      administrator: "Mark Willian",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet",
      status: "Público"
    },
    {
      id: 2,
      displayType: "recomendedGroups",
      name: "A cras semper auctor neque vitae dignissim enim sit amet siam sollicitudin",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Home-Site-LIT.png",
      totalMembers: 2,
      administrator: "Marcelo Freitas",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Público"
    },
    {
      id: 3,
      displayType: "invitedGroups",
      name: "Nulla pellentesque dignissim enim sit amet siam sollicitudin tempor id eu nisl",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-14.png",
      totalMembers: 11,
      administrator: "Selma Antunes",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet",
      status: "Privado"
    }
  ],
  pendingSolicitations: [
    {
      id: 0,
      name: "Quid ex ea commodi quidam e nostris",
      img: "https://app.lit.com.br/api/images/courses/1$_$1544.jpg",
      totalMembers: 1398,
      administrator: "Marcos Sanchez",
      description:
        "Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Adipiscing tristique risus nec feugiat in. Augue neque gravida in fermentum et. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Privado"
    },
    {
      id: 3,
      name: "Nulla pellentesque dignissim enim sit amet",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-14.png",
      totalMembers: 11,
      administrator: "Selma Antunes",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet",
      status: "Privado"
    },
    {
      id: 5,
      name: "Quid ex ea commodi consequatur",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-17.png",
      totalMembers: 12,
      administrator: "Marcos Sanchez",
      description:
        "A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Privado"
    },
  ],
  recomendedGroups: [
    {
      id: 2,
      name: "A cras semper auctor neque vitae",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Home-Site-LIT.png",
      totalMembers: 2,
      administrator: "Marcelo Freitas",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Público"
    },
    {
      id: 3,
      name: "Nulla pellentesque dignissim enim sit amet",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-14.png",
      totalMembers: 11,
      administrator: "Selma Antunes",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet",
      status: "Privado"
    },
  ],
  invitedGroups: [
    {
      id: 5,
      name: "Quid ex ea commodi consequatur tempor id eu nisl nunc mi ipsum",
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-17.png",
      totalMembers: 12,
      administrator: "Marcos Sanchez",
      description:
        "A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Privado",
      invitedBy: [
        {
          memberId: 1,
          name: "Dolores Alves",
          img: "https://i.pravatar.cc/50?img=1"
        }
      ]
    },
    {
      id: 1,
      name: "Quis autem quidam e nostris sed arcu non odio euismod lacinia at quis",
      role: [{ id: 1, name: "Administrador" }],
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Home-Site-LIT-1.png",
      totalMembers: 19,
      administrator: "Khatrina Swatz",
      description:
        "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Público",
      invitedBy: [
        {
          memberId: 802,
          name: "Thiago Smith",
          img: "https://i.pravatar.cc/150?img=54"
        }
      ]
    },
    {
      id: 4,
      name: "Tempor dignissim enim sit amet siam id eu nisl nunc mi ipsum ",
      role: [{ id: 1, name: "Administrador" }],
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-16.png",
      totalMembers: 208,
      administrator: "Mark Willian",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet",
      status: "Público",
      invitedBy: [
        {
          memberId: 10,
          name: "Smith Johson",
          img: "https://i.pravatar.cc/150?img=12"
        }
      ]
    },
    {
      id: 2,
      name: "A cras semper auctor neque vitae dignissim enim sit amet siam sollicitudin",
      role: [{ id: 1, name: "Administrador" }],
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Home-Site-LIT.png",
      totalMembers: 2,
      administrator: "Marcelo Freitas",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
      status: "Público",
      invitedBy: [
        {
          memberId: 1,
          name: "Dolores Alves",
          img: "https://i.pravatar.cc/50?img=1"
        }
      ]
    },
    {
      id: 3,
      name: "Nulla pellentesque dignissim enim sit amet siam sollicitudin tempor id eu nisl",
      role: [{ id: 1, name: "Administrador" }],
      img: "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://lit.com.br/site/wp-content/uploads/2021/01/Design-sem-nome-14.png",
      totalMembers: 11,
      administrator: "Selma Antunes",
      description:
        "Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet",
      status: "Privado",
      invitedBy: [
        {
          memberId: 13,
          name: "Maria Aparecida",
          img: "https://i.pravatar.cc/50?img=36"
        }
      ]
    }
  ],
  groupDetail: {
    id: 0,
    name: "Quid ex ea commodi consequatur quis autem quidam e nostris",
    role: [{ id: 1, name: "Administrador" }],
    img: "https://app.lit.com.br/api/images/courses/1$_$1544.jpg",
    totalMembers: 139,
    administrator: "Marcos Sanchez",
    description:
      "Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Adipiscing tristique risus nec feugiat in. Augue neque gravida in fermentum et. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Nulla pellentesque dignissim enim sit amet.",
    status: "Privado"
  }
};

export default function members(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_GROUP_INFORMATION:
      return {
        ...state,
        loading: false,
        called: true,
        data: action.payload.data
      };

    default:
      return state;
  }
}
