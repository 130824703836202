/* eslint-disable camelcase */
import React, { Component } from "react";
import { Checkbox } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { validarCpf, maskCpf, toastErrorAndWarningOptions } from "../../../../utils/functions";
import PaymentMethodType from "../../paymentMethodType";
import PlanType from "../../planType";
import api, { gateway } from "../../../../utils/api";
import { toastDefaultOptions } from '../../../../utils/functions'
import { findPaymentTerm } from "../../../../business/payment";
import { LabelField, ItemFormField, InputText, ItemFormFieldSelect } from "../../../../Components/Styles/FormStyle"

class Payroll extends Component {
  static contextTypes = {
    router: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    const {
      plan,
      profile: { cpf }
    } = this.props;

    const termId =
      plan.payments && findPaymentTerm(plan, PaymentMethodType.FOLHA);

    const hasPaymentError = localStorage.getItem("57e0854551b1dca003c67c384c37a346") === 'true';

    this.state = {
      termId,
      term: null,
      termText: null,
      cpfContract: maskCpf(cpf) || "",
      loadContract: false,
      sendPayment: false,
      invalidCpf: null,
      isAgreeTerms: true,
      isVisibledCSS: "none",
      isVisibledAgreeTermsCSS: "block",
      isDisabledButtonContract: false,
      hasPaymentError,
    };
  }

  componentDidMount() { }

  notify = text => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000
    });
  };

  fetchTerms = async () => {
    const { termId } = this.state;
    const { plan } = this.props;
    const idProfile = localStorage.getItem("idProfile");
    try {
      if (!termId) {
        toast.error(
          "Não foi possível encontrar o termo de contrato, tente novamente!",
          toastErrorAndWarningOptions
        );
      }

      const { wu_media_html, ox_term } = await gateway.get(
        `/subscription/termcreate?idProfile=${idProfile}&idPlan=${plan.id
        }&idTerm=${termId}&typePlan=${plan.planType === PlanType.Yearly ? "Anual" : "Mensal"
        }`
      );

      this.setState({
        termText: wu_media_html,
        term: ox_term,
        loadContract: false,
        isVisibledCSS: "block",
        isDisabledButtonContract: true,
        isVisibledAgreeTermsCSS: "none"
      });
    } catch (error) {
      this.setState({ loadContract: false });
      toast.error(
        "Não foi possível encontrar o termo de contrato, tente novamente!",
        toastErrorAndWarningOptions
      );
    }
  };

  payRoll = async evento => {
    evento.preventDefault();
    const { plan, onSubmit, handleTrial } = this.props;
    const { term } = this.state;
    const pagamento = {
      idPlan: plan.id,
      type: PaymentMethodType.FOLHA,
      term
    };
    if (plan.trial) {
      onSubmit(pagamento);
    } else {
      handleTrial(() => onSubmit(pagamento));
    }
  };

  verifyCpf = e => {
    e.preventDefault();
    const { value } = e.target;
    const result = validarCpf(value);
    if (!result) {
      this.setState({
        invalidCpf: "CPF inválido, favor inserir um CPF válido!"
      });
    } else {
      this.setState({ invalidCpf: null });
    }
  };

  /* Libera o botão de CONFIRMAR PLANO / Após ler o contrato E CLICAR NO CHECK  */
  onChangeAgreeTerms = () => {
    const { isAgreeTerms } = this.state;
    this.setState({ isAgreeTerms: !isAgreeTerms });
  };

  notify = (text, duration = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: duration
    });
  };

  /* Botão para continuar no PAGAMENTO POR FOLHA (Libera o contrato) */
  nextContract = () => {
    this.setState({
      isVisibledCSS: "block",
      isDisabledButtonContract: true,
      isVisibledAgreeTermsCSS: "none"
    });
  };

  handleContractorFormSubmit = async () => {
    const { cpfContract } = this.state;
    const { profile } = this.props;
    this.setState({ loadContract: true });
    try {
      const newProfile = {
        ...profile,
        cpf: cpfContract.replace(/\D/g, "")
      };
      await api.put("/updateProfileTO", newProfile);
      await this.fetchTerms();
    } catch (error) {
      console.log(error);
      this.setState({ loadContract: false });
    }
  };

  handleChangeCPF = evt => {
    this.setState({ cpfContract: maskCpf(evt.target.value) });
  };

  render() {
    const {
      invalidCpf,
      sendPayment,
      isAgreeTerms,
      isVisibledCSS,
      cpfContract,
      isDisabledButtonContract,
      isVisibledAgreeTermsCSS,
      loadContract,
      termText,
      hasPaymentError
    } = this.state;

    const { plan, back, loading, handleTrial } = this.props;

    return (
      <>
        <form
          onSubmit={this.payRoll}
          style={{ width: "100%", padding: "0px", margin: "0px" }}
        >
          {plan && (
            <>
              <div
                className="formulario"
                style={{ margin: "0px auto", padding: "0px", width: "100%" }}
              >
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8 col-12">
                    <LabelField>CPF</LabelField>
                    <ItemFormField>
                      <InputText
                      type="text"
                      className="form-control" 
                      placeholder="000.000.000-00"
                      name="cpfContract"
                      id="cpfContract"
                      maxLength="14"
                      required
                      onBlur={this.verifyCpf}
                      onFocus={this.handleInputFocus}
                      value={cpfContract || ""}
                      onChange={this.handleChangeCPF}
                      disabled={isDisabledButtonContract}
                    />
                    </ItemFormField>
                    {invalidCpf && <p style={{ color: "red" }}>{invalidCpf}</p>}
                  </div>
                  <div
                    className="col-md-12"
                    style={{
                      marginTop: "30px",
                      textAlign: "center",
                      display: isVisibledAgreeTermsCSS
                    }}
                  >
                  <button
                    id="btnPayCancel"
                    className="btn btn-modal__cancel"
                    type="button"
                    onClick={back}
                    style={{ marginRight: 10, borderRadius: 10, paddingTop: 13, paddingBottom: 13 }}
                  >
                    Voltar
                  </button>
                    <button
                      id="generateTerm"
                      className={`btn btn-blue btn-lg btn-block${sendPayment &&
                        " btn-loading"}`}
                      type="button"
                      disabled={!cpfContract || loadContract}
                      onClick={this.handleContractorFormSubmit}
                    >
                      {loadContract
                        ? "Gerando termo de autorização..."
                        : "Gerar termo de autorização"}
                    </button>
                  </div>
                </div>
                
              </div>
              <div
                className="row"
                style={{
                  maxWidth: "1050px",
                  margin: "0px auto",
                  marginLeft: "-13px",
                  display: isVisibledCSS
                }}
              >
                <div className="col-md-12">
                  <div dangerouslySetInnerHTML={{ __html: termText }} />
                </div>
                <div
                  className="col-md-6"
                  style={{ height: "auto", margin: "auto", paddingTop: "2%" }}
                >
                  <Checkbox
                    id="chkAgreeTermsScroll"
                    style={{ paddingLeft: "30px" }}
                    onChange={this.onChangeAgreeTerms}
                  >
                    <div style={{ marginLeft: "20px", marginTop: "-25px" }}>
                      Li e concordo com o Termo de Autorização de Débito em
                      Folha de Pagamento
                    </div>
                  </Checkbox>
                </div>
                <div className="col-md-12" style={{ marginTop: "50px" }}>
                  <div className="box-buttons">
                    <div className="infos" />
                    {!hasPaymentError && <button
                      id="btnBack"
                      className="btn btn-lg"
                      type="button"
                      onClick={back}
                    >
                      Voltar
                    </button>}
                    {plan && plan.price <= 0 ? (
                      <button
                        type="button"
                        disabled={
                          sendPayment || invalidCpf || isAgreeTerms || loading
                        }
                        style={{
                          marginTop: "0px",
                          maxWidth: "200px",
                          paddingRight: "-300px"
                        }}
                        className={`btn btn-blue btn-lg btn-block${sendPayment &&
                          " btn-loading"}`}
                        onClick={
                          !plan.trial
                            ? () => handleTrial(this.redirectPi)
                            : this.redirectPi
                        }
                        id="btn-confirm-1"
                      >
                        Confirmar
                      </button>
                    ) : (
                      // eslint-disable-next-line react/button-has-type
                      <button
                        disabled={
                          sendPayment || invalidCpf || isAgreeTerms || loading
                        }
                        style={{
                          marginTop: "0px",
                          maxWidth: "200px",
                          marginLeft: "20px"
                        }}
                        className={`btn btn-blue btn-lg btn-block${(sendPayment ||
                          loading) &&
                          " btn-loading"}`}
                        onClick={this.payRoll}
                        id="btn-confirm-2"
                      >
                        Confirmar
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.user.profile.data
  };
};

export default connect(mapStateToProps)(Payroll);
