import swal from '@sweetalert/with-react';
import _ from 'lodash';

export default error => {
  let message = _.get(
    error,
    'response.data.message',
    'Não foi possível processar o pagamento.'
  );
  if(message.includes('BusinessError')) {
    message = message.replace('BusinessError: ', '')
  }
  swal({
    title: `Ops, ocorreu um erro.`,
    text: message
  });
};
