import React from "react";
import { Span } from "./styles";

export default ({ child, color, appearance, hidden, orientation }) => {
  return (
    <>
      <Span
        color={color}
        appearance={appearance}
        hidden={hidden}
        orientation={orientation}
      >
        {child}
      </Span>
    </>
  );
};
