import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import api from "../../../utils/api";
import Screen from "./screen";

import { MENU_NAVIGATION } from "../constants";
import { Creators as PostsActions } from "../../../store/ducks/social/posts/actions";

export default () => {
  const dispatch = useDispatch();
  const [interationsModalIsOpen, setInterationsModalIsOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [groupInformation, setGroupInformation] = useState(null);
  const members = useSelector(state => state.social.members.allMembers);
  const memberDetail = useSelector(state => state.social.members.memberDetail);
  const posts = useSelector(state => state.social.posts.data);
  const groupDetail = useSelector(state => state.social.groups.groupDetail);
  const [logo] = useState("https://lit.com.br/site/wp-content/themes/lit/images/logo-branco.png");
  const [menuNavigation] = useState([
    {
      link: '#',
      target: '',
      description: MENU_NAVIGATION.PROFILE,
      initialStatus: ""
    },
    {
      link: '#',
      target: '',
      description: MENU_NAVIGATION.ACCOUNT,
      initialStatus: ""
    }
  ]);


  async function getGroupInformation() {
    const idGroup = 5;
    try {
      const response = await api.get(`getGroupById/${idGroup}`);
      setGroupInformation({ groupDetail: response });
    } catch (error) {
      console.log(error);
    }
  }

  async function updatePost(postContent, postId) {
    dispatch(PostsActions.updatePosts({ postContent, postId }));

    // try {
    //   const idMember = localStorage.getItem("idMember");
    //   const response = await api.post(`getGroupById/${idMember}`);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  async function savePost(postContent) {
    dispatch(PostsActions.createPosts(postContent));

    // try {
    //   const idMember = localStorage.getItem("idMember");
    //   const response = await api.post(`getGroupById/${idMember}`);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  async function createComment(commentContent, postId) {
    console.log({ commentContent, postId });
    dispatch(PostsActions.createComment({ commentContent, postId }));

    // try {
    //   const idMember = localStorage.getItem("idMember");
    //   const response = await api.post(`getGroupById/${idMember}`);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  async function deletePost(postId) {
    dispatch(PostsActions.removePost(postId));

    // try {
    //   const idMember = localStorage.getItem("idMember");
    //   const response = await api.post(`getGroupById/${idMember}`);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  useEffect(() => {
    getGroupInformation();
  }, []);

  return (
    <>
      <Screen
        logo={logo}
        menuNavigation={menuNavigation}
        group={groupDetail}
        member={memberDetail}
        members={members}
        postItem={posts}
        savePost={savePost}
        updatePost={updatePost}
        createComment={createComment}
        deletePost={deletePost}
        interationsModalIsOpen={interationsModalIsOpen}
        setInterationsModalIsOpen={setInterationsModalIsOpen}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
      />
    </>
  );
};
