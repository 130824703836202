import React from "react";
import _ from 'lodash';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InputRange from "react-input-range";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import history from "../../utils/history";
import { showOldCourses } from "../../utils/functions"
import Pagination from "../Pagination";
import { Creators as CourseActions } from "../../store/ducks/course/actions";
import { UserActions } from "../../store/ducks/user";
import Card from "./Card";
import NewCard from "./NewCard";
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";
import { API_URL_IMAGES } from "../../utils/constants";
import api, { gateway } from "../../utils/api";
import ModalVideo from "../ModalVideo";

const contentUcs = [
  { type: "PDF", name: "PDF" },
  { type: "VOD", name: "Vídeos" },
  { type: "TEL", name: "Scorms" }
];
const contentEvaluation = [
  { type: "AIE", name: "Avaliações Individuais" },
  { type: "AVF", name: "Avaliações Finais" },
  { type: "FOR", name: "Fóruns" },
  { type: "VMP", name: "Vamos Praticar" },
  { type: "SML", name: "Simulados" }
];
const contentMore = [
  { type: "BIB", name: "Livros" },
  { type: "PDF", name: "PDF" },
  { type: "EXE", name: "XLS" },
  { type: "TEL", name: "Scorms" },
  { type: "VOD", name: "Vídeos" }
];

const CheckBox = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    user-select: none;
    font-size: 16px;
    font-weight: 500;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: rgb(217, 217, 217);
  }

  &:hover + label:before {
    background: #27aae1;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: #27aae1;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: #eee;
    width: 2px;
    height: 2px;
    box-shadow: 1px 0 0 #eee, 4px 0 0 #eee, 4px -2px 0 #eee, 4px -4px 0 #eee,
      4px -6px 0 #eee, 4px -8px 0 #eee;
    transform: rotate(45deg);
  }
`;

const DivContainer = styled.div`
  margin: 0 1em;
  padding: 1em;
  background-color: #fff;
`;
const ButtonSubject = styled.button`
  background: ${({ ativo }) => (ativo ? "#43dba2" : "#d9d9d9")};
  color: ${({ ativo }) => (ativo ? "#fff" : "#6d6e71")};
  border: ${({ ativo }) => (ativo ? "1px solid #43dba2" : "1px solid #d9d9d9")};
  border-radius: 10px;
`;

class WhatNow extends React.PureComponent {
  static contextTypes = {
    router: PropTypes.shape()
  };

  state = {
    value: 10,
    suggestion: null,
    sugestoes: [],
    resposta: [],
    typeContent: 1,
    content: [],
    search: false,
    page: 1,
    size: 20,
    totalPages: 1,
    loading: false,
    handle: 1,
    authlivro: ""
  };

  async componentWillMount() {
    this.getSugestoes();
    this.shouldShowOldCourses = await showOldCourses()
  }

  goMicromomento = obj => {
    if (obj.isNewCourse) {
      this.goToNewCourse(obj);
    } else {
      const { typeContent } = this.state;
      if (typeContent === 3) {
        switch (obj.type) {
          case "PDF":
          case "EXE":
          case "TEL":
            this.downloadAnexo(obj);
            break;
          case "BIB":
            this.startBook(obj.path);
            break;

          default:
            break;
        }
      } else {
        this.goToCourse(obj);
      }
    }
  };

  goToNewCourse = course => {
    history.push(
      {
        pathname: `/curso-novo/${course.courseCode}`
      },
      {
        object: course.objectCode
      }
    );
  };

  goToCourse = obj => {
    const { setCourseSelected, toggleModalRestrict } = this.props;
    const { router } = this.context;
    const freeUser = JSON.parse(localStorage.getItem("freeUser"));
    const showLock = freeUser && !obj.freeCourse;
    if (showLock) {
      toggleModalRestrict(true);
      return;
    }
    setCourseSelected(obj);
    router.history.push(`/curso/${obj.id}`);
  };

  startBook = (path = "") => {
    const { handle } = this.state;
    if (handle === 2) {
      if (path !== "") {
        this.selectBook(path);
      }
    } else {
      const idProfile = localStorage.getItem("idProfile");
      api
        .get(`library/oauth/${idProfile}`)
        .then(response => {
          if (response.status === 200) {
            this.setState({
              authlivro: response.message,
              handle: handle + 1
            });
            if (path !== "") {
              this.selectBook(path);
            }
          }
        })
        .catch(error => console.log(error));
    }
  };

  selectBook = path => {
    window.open(`${path}`);
  };

  downloadAnexo = ({ path }) => {
    if (path) {
      const documentUrl = `${API_URL_IMAGES}attachment/${path}`;
      const linkDownload = document.createElement("a");
      linkDownload.setAttribute("download", "");
      linkDownload.setAttribute("href", documentUrl);
      linkDownload.setAttribute("target", "_blank");
      linkDownload.click();
    }
  };

  onPageChanged = data => {
    const { page } = this.state;
    const newPage = data.currentPage;
    if (newPage !== page) {
      this.setState({ page: newPage }, this.pesquisar);
    }
  };

  getSugestoes = () => {
    gateway
      .get("webbff/admin_app_web/information/track-suggestions")
      .then(response => {
        this.setState({ sugestoes: response });
      })
      .catch(error => console.log(error));
  };

  pesquisar = () => {
    const { value, suggestion, content, typeContent, page, size } = this.state;
    this.setState({ loading: true });
    const body = {
      time: value,
      idSuggestion: [_.get(suggestion, 'idSuggestion')],
      idTopic: suggestion.newCourses ? _.get(suggestion, 'idSuggestion') : null,
      typeOf: content.length > 0 ? content : null,
      typeContent,
      idProfile: parseInt(localStorage.getItem("idProfile"), 10)
    };

    const params = `page=${page}&size=${size}`;

    gateway
      .post(`webbff/lit_app_web/content/search/micro-moment?${params}`, body)
      .then(res => {
        this.setState({
          resposta: res.items,
          totalPages: res.totalPages,
          search: true,
          loading: false
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  selectAssunto = i => {
    this.setState({ suggestion: i });
  };

  toggleContent = content => {
    this.setState({ content: [], typeContent: content });
  };

  handleCheckContent = value => {
    const { content } = this.state;

    const contentToSet = content.includes(value) ? 
      content.filter(c => c !== value) : 
      [...content, value];
    
    this.setState({
      content: contentToSet
    });
  };

  render() {
    const {
      resposta,
      value,
      sugestoes,
      suggestion,
      typeContent,
      search,
      page,
      totalPages,
      loading,
      authlivro
    } = this.state;

    return (
      <div>
        <NavBarSuperior />
        <ModalVideo indexTutorial={3} modal titleModal="Meu Micro-momento" />
        <div className="container-fluid" style={{ paddingTop: "4em" }}>
          <DivContainer>
            <div className="row">
              <div className="col-12">
                <h2>O que ver agora?</h2>
                <hr />
              </div>
            </div>
            <div className="row" style={{ marginBottom: "2em" }}>
              <div className="col-12">
                <div className="howlong">
                  <span
                    className="titulo"
                    data-tip="Escolha quanto tempo você tem disponível para aprender"
                  >
                    Quanto tempo você tem?
                  </span>
                  <input
                    type="number"
                    className="numInput"
                    min="1"
                    max="60"
                    readOnly
                    value={value}
                  />
                  <span className="mins">min</span>
                  <InputRange
                    maxValue={60}
                    minValue={1}
                    formatLabel={label => `${label} min`}
                    value={value}
                    onChange={newValue => this.setState({ value: newValue })}
                    onChangeComplete={newValue => console.log(newValue)}
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "2em" }}>
              <div className="col-12">
                <div className="howlong" style={{ marginBottom: "1em" }}>
                  <span
                    className="titulo"
                    data-tip="Qual tema você deseja estudar de acordo com o tempo que escolheu"
                  >
                    Que tipo de assunto você deseja?
                  </span>
                </div>
                <div className="assuntos">
                  {sugestoes.map((item, index) => {
                    const ativo = item.idSuggestion === _.get(suggestion, 'idSuggestion');
                    return (
                      item.newCourses || this.shouldShowOldCourses
                    ) && (
                        <ButtonSubject
                          type="button"
                          ativo={ativo}
                          key={index}
                          className="btn"
                          onClick={() => this.selectAssunto(item)}
                        >
                          {item.nameSuggestion}
                        </ButtonSubject>
                      );
                  })}
                </div>
              </div>
            </div>
            {suggestion && (
                <div className="row" style={{ marginBottom: "2em"}}>
                <div className="col-12">
                  <div className="howlong" style={{ marginBottom: "1em" }}>
                    <span
                      className="titulo"
                      data-tip="Escolha o tipo de mídia que deseja utilizar para estudar no seu micro-momento"
                    >
                      Tipo de conteúdo
                    </span>
                  </div>
                  <div className="tipos">
                    <button
                      type="button"
                      onClick={() => this.toggleContent(1)}
                      className={`btn btn-outline-lit ${typeContent === 1 &&
                        "active"} `}
                    >
                      UCs
                    </button>
                    <button
                      type="button"
                      onClick={() => this.toggleContent(2)}
                      className={`btn btn-outline-lit ml-3 ${typeContent === 2 &&
                        "active"} `}
                    >
                      Avaliações
                    </button>
                    <button
                      type="button"
                      onClick={() => this.toggleContent(3)}
                      className={`btn btn-outline-lit mx-3 ${typeContent === 3 &&
                        "active"} `}
                    >
                      Saiba Mais
                    </button>
  
                    {typeContent === 1 && (
                      <div className="checkbox-group mt-4 ml-3 d-flex">
                        {contentUcs.map(uc => (
                          <div className="mr-3" key={uc.type}>
                            <CheckBox
                              id={`UC-${uc.type}`}
                              onChange={() => this.handleCheckContent(uc.type)}
                              type="checkbox"
                              value={uc.type}
                            />
                            <label htmlFor={`UC-${uc.type}`}>{uc.name}</label>
                          </div>
                        ))}
                      </div>
                    )}
  
                    {typeContent === 2 && (
                      <div className="checkbox-group mt-4 d-flex">
                        {contentEvaluation.map(evaluation => (
                          <div className="mr-3" key={evaluation.type}>
                            <CheckBox
                              id={`evaluation-${evaluation.type}`}
                              onChange={() =>
                                this.handleCheckContent(evaluation.type)
                              }
                              type="checkbox"
                              value={evaluation.type}
                            />
                            <label htmlFor={`evaluation-${evaluation.type}`}>
                              {evaluation.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
  
                    {typeContent === 3 && (
                      <div className="checkbox-group mt-4 d-flex">
                        {contentMore.map(more => (
                          <div className="mr-3" key={more.type}>
                            <CheckBox
                              id={`more-${more.type}`}
                              onChange={() => this.handleCheckContent(more.type)}
                              type="checkbox"
                              value={more.type}
                            />
                            <label htmlFor={`more-${more.type}`}>
                              {more.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          
            <div className="row">
              <div className="col-12">
                <div className="howlong" style={{ marginBottom: "1em" }}>
                  <button
                    disabled={!suggestion}
                    type="button"
                    className={`btn btn-blue mt-3 ${loading ? "btn-loading" : ""
                      }`}
                    onClick={this.pesquisar}
                  >
                    Concluir
                  </button>
                  <hr />
                </div>
              </div>
            </div>
          </DivContainer>
          <div
            className="row"
            style={{ margin: "0 1rem", backgroundColor: "#FFF" }}
          >
            {search && (
              <div className="col-12">
                <h3>Resultados</h3>
                {resposta && resposta.length > 0 && (
                  <div className="d-flex flex-row align-items-center justify-content-end">
                    <Pagination
                      noWidth
                      totalRecords={totalPages}
                      pageLimit={10}
                      pageNeighbours={1}
                      page={page}
                      onPageChanged={this.onPageChanged}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "stretch"
                  }}
                >
                  {resposta && resposta.length > 0 ? (
                    resposta.map((course, index) =>
                      course.isNewCourse ? (
                        <NewCard
                          key={index}
                          data={course}
                          goMicromoment={this.goMicromomento}
                        />
                      ) : (
                        <Card
                          key={`${index}`}
                          infos={{
                            id: course.idtotvs,
                            idAttachment: course.idattachment,
                            idContent: course.idcontent,
                            idCourse: course.iddiscipline,
                            idChildren: course.idchildren,
                            idDiscipline: course.iddiscipline,
                            type: course.type,
                            freecourse: course.freecourse,
                            time: course.time,
                            name: course.name,
                            path: course.path,
                            capa: `${API_URL_IMAGES}images/courses/${course.idtotvs}.jpg`
                          }}
                          goMicromomento={this.goMicromomento}
                        />
                      )
                    )
                  ) : (
                    <p>Nenhum objeto encontrado</p>
                  )}
                </div>
                {resposta && resposta.length > 0 && (
                  <div className="d-flex flex-row align-items-center justify-content-end">
                    <Pagination
                      noWidth
                      totalRecords={totalPages}
                      pageLimit={10}
                      pageNeighbours={1}
                      page={page}
                      onPageChanged={this.onPageChanged}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <iframe
            title="livros"
            src={authlivro}
            style={{
              position: "absolute",
              width: 10,
              height: 10,
              top: 0,
              zIndex: 1
            }}
            frameBorder="0"
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...CourseActions, ...UserActions.ProfileActions },
    dispatch
  );

export default connect(null, mapDispatchToProps)(WhatNow);
