import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const NavLit = styled.nav`
  background-image: transparent;
`

export const ContainerNavBar = styled.div`
   /* position: fixed; */
   width: 100%;
   /* top: 0; */
`

export const ComeBack = styled(Link)`
   position: absolute;
   background: #fff;
  cursor: pointer;
  
  padding: 10px 20px;
  right: 0;
  top: -3em;
  color: #47b1de;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #fff;
    background: #47b1de;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  align-items: center;
  /* justify-content: center; */
  background-image: linear-gradient(45deg, rgba(2, 196, 255, 1) 25%, rgba(142, 230, 123, 1) 100%);
`

export const Title = styled.h2`
  margin-bottom: 20px;
`

export const BoxInfo = styled.div`
  background: #fff;
  padding: 20px;
  width: 100%;
  margin-top: 30px;
  width:  100%;
  max-width: 1224px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(217,208,217,1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(217,208,217,1);
  box-shadow: 0px 1px 5px 0px rgba(217,208,217,1);
  border-radius: 12px;
  position: relative;
`

export const CodeWrapper = styled.div`
  flex-direction: row;
  align-items: center;

  margin: 20px 5px;
`;

export const Code = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
`;

export const CodeButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

`

export const ActionButton = styled.button`
  border: 0;
  cursor: pointer;
  background: #47b1de;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  & + button {
    margin-left: 5px;
  }
`

export const NextButton = styled.button`
  background: white;
 
  border: 2px solid  rgba(2, 196, 255, 1);
  border-radius: 25px;
  padding: 5px 20px;
  width: 100%;
  /* border: 0; */
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  p {
    font-size: 16px;
    font-weight: bold;
    color: rgba(2, 196, 255, 1);
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }
  &:hover {
    background-image: linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%);
    /* color: white; */
    p {
      color: white;
    }
  }
 
  
`;
