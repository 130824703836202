import styled, { css } from "styled-components";
import { APPEARANCE, COLOR, ORIENTATION } from "../../../constants";

export const Span = styled.span`

  box-shadow: 0px 5px 10px rgba(0,0,0,.1);
 
  ${props => (props.appearance === APPEARANCE.NEW_POST) && css`
    width: 100%;
    margin-left: 0;
    @media screen and (max-width: 579px) {
      width: 100%;
      margin-left: 0;
    }
  `}

  ${props => (props.appearance === APPEARANCE.NOTIFICATION) && css`
    width: 185px;
    min-width: 185px;
    float: left;
    display: flex!important;
    align-items: center;
    text-align: left;
    margin: 0 15px 0 0;
    position: relative;
    padding: 10px!important;
    label {
      width: 100%;
      text-align: left;
      white-space: break-spaces;
    }
  `}

  ${props => (props.appearance === APPEARANCE.BANNER_BLOCK) && css`
    width: 285px;
    min-width: 285px;
    height  145px;
    float: left;
    text-align: left;
    margin: 0 15px 0 0;
    position: relative;
    padding: 0!important;
    border-radius: 0;
    label {
      width: 100%;
      text-align: left;
      min-height: 50px;
      height: auto;
      line-height: 1;
      margin-bottom: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 1440px) {
      width: 96%;
      height: 180px;
    }
    @media (max-width: 1200px) {
      height: 150px;
      width: 96%;
      min-width: initial;
    }
  `}

  ${props => (props.appearance === APPEARANCE.CARD) && css`
    width: 100%;
    height: 520px;
    border: 1px solid var(--block-border-primary);
    box-shadow: 0 0 5px var(--block-feed-shadow);
    display: inline-block;
    padding: 1rem;
    label {
      width: 100%;
      text-align: left;
      height: auto;
      display: block;
    }
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 480px) {
      height: 490px;
    }
  `}

  ${props => (props.appearance === APPEARANCE.EVALUATION) && css`
    width: 375px;
    border-radius: 5px;
    border: 1px solid var(--block-evaluation-border);
    background: var(--block-evaluation);
    box-shadow: 0 0 5px var(--block-evaluation-shadow);
    display: inline-block;
    padding: 1rem;
    min-height: 160px;
    height: 185px;
    label {
      width: 100%;
      text-align: left;
      height: auto;
      display: block;
      white-space: break-spaces;
    }
    .content-button { 
      right: 5px;
    }
    button {
      background: var(--white);
      color: var(--text-primary);
      border-color: var(--text-primary);
      padding: 5px 10px;
      font-weight: 500;
      letter-spacing: .35px;
      &:hover {
        background: var(--text-primary);
        color: var(--white);
      }
    }
    &:last-of-type {
      margin-right: 0!important;
    }
    @media (max-width: 991px) {
      width: 320px;
      margin: 0 15px 15px;
      .content-button { right: 25px; }
    }
    @media (max-width: 579px) {
      width: 380px;
      margin: 0;
    }
  `}

  ${props => (props.appearance === APPEARANCE.MEMBER) && css`
    display: inline-block;
    padding: 0;
    margin: 0 25px 20px 0;
    width: 110px;
    min-width: 110px;
    height: 150px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
  `}

  ${props => (props.appearance === APPEARANCE.ARCHIVE_ITEM) && css`
    padding: 0 5px 5px 0;
    clear: both;
    display: inline-block;
    width: 100%;
    @media screen and (max-width: 800px) {
      text-align: center;
      display: inline-block;
      line-height: 1;
      .content-button {
        clear: both;
        display: block;
        margin: 5px auto!important;
        float: none!important;
        text-align: right;
      }
    } 
    .content-button {
      float: right;
      padding: 0!important;
      margin: 0;
      width: 10%;
      right: 2px;
      top: -1px;
      text-align: right;
    }
  `}

  ${props => (props.color === COLOR.BLOCK_LIGHT) && css`
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 10px;
    display: inline-block;
    max-width: 100%;
    background: var(--block-background-light); 
    position: relative;
  `}

  ${props => (props.color === COLOR.BLOCK_PRIMARY) && css`
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 10px;
    display: inline-block;
    max-width: 100%;
    border: 1px solid var(--block-border-primary);
    background: var(--block-background-primary); 
    position: relative;
  `}

  ${props => (props.color === COLOR.BLOCK_EDIT) && css`
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 10px;
    display: inline-block;
    max-width: 100%;
    border: 1px solid var(--block-border-edit);
    background: var(--block-background-edit); 
    position: relative;
  `}

  ${props => (props.color === COLOR.BLOCK_GRADIENT) && css`
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 0;
    display: inline-block;
    width: 92%;
    height: auto;
    border: 1px solid var(--block-gradient-border);
    background: linear-gradient(200deg, var(--block-gradient-primary) 0%, var(--block-gradient-secondary) 100%);
    position: relative;
  `}

  ${props => (props.orientation === ORIENTATION.AFASTABLE) && css`
    margin: 2rem 0;
    width: 100%;
    @media (max-width: 767px) {
      margin: 0 0 2rem;
      padding: 0;
    }
  `}

  ${props => (props.hidden) && css` 
    display: none!important; 
  `}

`;
