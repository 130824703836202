import React, { Component } from "react"
import { Link } from "react-router-dom"
import Img from "react-image"
import placeholder from "../../images/placeholder-avatar.png"
import Tooltip from "../Tooltip"
import NoImage from '../Social/Grupo/NoImage'
import api, { gateway } from "../../utils/api"

class AvatarNetworkRightSideBar extends Component {
  state = {
    experiences: false,
  }

  componentDidMount() {
    const {
      perfil: { idProfile },
    } = this.props
    this.fetchOffices(idProfile)
  }

  fetchOffices = async id => {
    try {
      const response = await gateway.get(`/webbff/lit_app_web/profile/findOfficeNetwork/${id}`)
      if (!response.message) {
        this.setState({
          experiences: response,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const {
      perfil: { name, idProfile, linkPhoto },
    } = this.props
    const { experiences } = this.state

    const urlImg = linkPhoto || placeholder
    return (
      <li className="list-group-item" style={{ border: "none", display: "flex", alignItems: "center" }}>
        <Link data-tip={`${name}`} data-for="networking" to={`/perfilPublico/${idProfile}`}>
          <Img
            className="rounded-circle img-fluid mr-2"
            style={{ width: 70, height: 70 }}
            src={urlImg}
            unloader={
              <NoImage
                className="rounded-circle img-fluid mr-2"
                style={{ width: 70, height: 70 }}
              />
            }
          />
          <Tooltip id="networking" />
        </Link>
        <div className="group-desc">
          <Link
            style={{
              color: "#27aae1",
              fontWeight: 700,
              fontSize: "14px",
            }}
            to={`/perfilPublico/${idProfile}`}
          >
            {`${name}`}
          </Link>
          {experiences && experiences.length !== 0 ? (
            <div className="experiences networking">
              <span className="padding-0">{experiences[0].company}</span>
              <span className="padding-0">{experiences[0].profession}</span>
            </div>
          ) : null}
        </div>
      </li>
    )
  }
}

export default AvatarNetworkRightSideBar
