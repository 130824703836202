import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    flex-direction: row;
    background: #fafafc;
    box-shadow: 10px 0 15px rgba(0, 0, 0, .2);
    padding: 10px;
    margin-bottom: 30px;
    justify-content: space-between;
    display: flex;
    align-items: center;
`

export const CourseTitle = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    margin: 0 50px;
`

export const DescriptionUC = styled.div`
    background: #e7ebef;
    border-radius: 40px;
    padding: 3px 20px;
    margin: 5px 0 -24px 0;
    font-size: 12px;
    border: 1px solid #dbdbdb;
`

export const NavSection = styled.div`
    flex-direction: row;
    justify-content: ${props => props.isTrail ? "center" : "space-between"};
    display: flex;
    align-items: center;
    flex-basis: 75%;
    i{
        font-size: 30px;
    }
`

export const IconContainer = styled.div`
    display: flex;
    flex-basis: 20%;
`

export const NavButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0 20px;
`

export const MenuIcon = styled.button`
    background-color: ${props => props.backgroundColor ? `${props.backgroundColor}` : "#26ace1"};
    padding: ${props => props.sidebarOpened ? '10px 19px;' : '10px 14px;'}
    border-radius: 100px;
    box-shadow: 5px 5px 10px rgba(38, 172, 255, .2);
    cursor: pointer;
    border: none;

    i{
        color: ${props => props.iconColor ? props.iconColor : 'white'};
    }
`

export const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    visibility: ${props => props.isTrail ? "visible" : "hidden"}
`
