
import styled from "styled-components";
import { } from "../../../constants";

export const Span = styled.span`
  > span {
    margin: auto;
    display: table;
  }
`;

export const StatusLine = styled.span``;

export const Description = styled.span`
  width: 80%;
  display: block;
  label {
    width: 100%;
    text-align: left;
    min-height: 30px;
    height: auto;
    margin-bottom: 0;
    padding: 0;
    -webkit-line-clamp: 2;
    &:first-child {
      min-height: auto;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: -1rem;
  }
`;

export const ContentAtBottom = styled.span`
  width: 90%;
  display: block;
  margin: 10px 0;
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: -1rem;
    div {
      margin: 0;
      position: relative;
    }
  }
  @media (max-width: 767px) {
    width: 96%;
  }
  label {
    line-height: 1;
    padding: 0;
    display: inline;
  }
`;

export const Ajustable = styled.span`
  width: 100%;
  display: block;
  text-align: left;
  padding: 10px 15px;
  @media (max-width: 1024px) {
    text-align: right;
  }
  @media (max-width: 767px) {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    text-align: left;
  }
`;
