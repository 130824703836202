import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} label //text displayed above the field
 * @param {String} onChange //action triggered when clicking on the component
 * @param {String} value //default field value
**/

export default ({
  label = DEFAULT_VALUES.STRING,
  onChange = DEFAULT_VALUES.FUNCTION,
  value
}) => {
  return (
    <>
      <Screen
        onChange={onChange}
        value={value}
        label={label}
      />
    </>
  );
};
