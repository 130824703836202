import React from "react";
import { ContainerError, TextErro } from "./styles";

export default ({ error }) => {
  return <>
    {error && (
      <ContainerError>
        <TextErro>{error}</TextErro>
      </ContainerError>
    )}
  </>
}
