import React from "react";
import ButtonModal from "../../basicComponents/Button";
import Modal from "../../Modals/GenericModal";

export default ({
  child,
  buttonText,
  modalHeaderText,
  pageImage,
  appearance,
  position,
  size,
  orientation,
  isOpen = undefined,
  badgeValue,
  handleIsOpen
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function toggleModal() {
    handleIsOpen && handleIsOpen(!isOpen);
    setIsOpen(!modalIsOpen);
  }

  return (
    <>
      <ButtonModal
        appearance={appearance}
        position={position}
        orientation={orientation}
        size={size}
        text={buttonText}
        badgeValue={badgeValue}
        onClick={toggleModal}
      />
      <Modal
        isOpen={isOpen !== undefined ? isOpen : modalIsOpen}
        closeModal={toggleModal}
        textHeader={modalHeaderText}
        pageImage={pageImage}
      >
        {child}
      </Modal>
    </>
  );
};
