import { Types } from "./types";

/**
 * Actions
 */
export const Creators = {
  getPosts: data => ({
    type: Types.GET_POSTS,
    payload: { data }
  }),
  createPosts: data => ({
    type: Types.CREATE_POST,
    payload: { data }
  }),
  createComment: data => ({
    type: Types.CREATE_COMMENT,
    payload: { data }
  }),
  updatePosts: data => ({
    type: Types.UPDATE_POSTS,
    payload: { data }
  }),
  removePost: data => ({
    type: Types.REMOVE_POST,
    payload: { data }
  })
};
