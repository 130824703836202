import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    width: 100%;
    text-align: left;
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }

  h4 {
    width: 100%;
    font-size: 16px;
    margin: 0;
    text-align: left;
  }

  p {
    width: 100%;
    text-align: left;
    font-size: 14px;
    margin: 20px 0;
  }

  svg {
    font-size: 80px;
    margin: 0 auto;
    font-weight: lighter;
  }
`
