import React from 'react';

import { maskCpf } from '../../../../utils/functions';
import { Row } from './styles';

const styles = {
  subtitle: {
    fontWeight: 'bold'
  },
  modalHeader: {
    width: 'auto',
    height: '50px',
    background: 'transparent',
    boxShadow: 'none',
    marginTop: '60px'
  }
};
export const PaymentData = ({ userSubscriptions }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h5 style={{ fontSize: '1.2em', color: '#27aae1' }}>
            Dados de pagamento
          </h5>
          <hr style={{ marginTop: '0.1em' }} />
          <Row>
            <div className="col-sm-2 p-0">
              <h6 style={styles.subtitle}>Nome do plano</h6>
            </div>
            <div className="col-md-2 p-0">
              <h6 style={styles.subtitle}>Nº parcelas</h6>
            </div>
            <div className="col-md-3 p-0">
              <h6 style={styles.subtitle}>Cartão de cobrança</h6>
            </div>
            <div className="col-md-3 p-0">
              <h6 style={styles.subtitle}>Titular do cartão</h6>
            </div>
            <div className="col-md-2 p-0">
              <h6 style={styles.subtitle}>CPF do titular</h6>
            </div>
          </Row>
          {userSubscriptions.map(subscription => {
            return (
              <Row key={subscription.nomePlano}>
                <div className="col-sm-2 p-0 overflow-auto">
                  <p>{subscription.nomePlano}</p>
                </div>
                <div className="col-md-2 p-0">
                  {subscription.installment && subscription.installment >= 1 ? (
                    <p>{subscription.installment}x</p>
                  ) : (
                    <p>-</p>
                  )}
                </div>
                <div className="col-md-3 p-0">
                  <p>
                    **** **** ****{' '}
                    {!subscription.ultimosDigitos
                      ? '****'
                      : subscription.ultimosDigitos}
                  </p>
                </div>
                <div className="col-md-3 p-0">
                  {subscription.nome ? <p>{subscription.nome}</p> : <p>-</p>}
                </div>
                <div className="col-md-2 p-0">
                  {subscription.cpf ? (
                    <p>{maskCpf(subscription.cpf)}</p>
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </Row>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PaymentData;
