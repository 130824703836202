import styled, { css } from "styled-components";
import { COLOR, FONT_TYPE, SIZE, TRANSFORM, POSITION, APPEARANCE } from "../../../constants";

export const CustomText = styled.label`
  
  color: var(--text-primary);

  ${props => (props.color === COLOR.DARK) && css`
    color: var(--title-primary);
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.color === COLOR.TEXT_PRIMARY) && css`
    color: var(--text-primary);
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.color === COLOR.LIGHT) && css`
    color: var(--text-light);
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.color === COLOR.PRIMARY) && css`
    color: var(--text-alert);
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.color === COLOR.DANGER) && css`
    color: var(--text-danger);
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.textAlign === POSITION.LEFT) && css`
    text-align: left!important;
    align-self: flex-start!important;
    width: 100%;
  `}

  ${props => (props.textAlign === POSITION.RIGHT) && css`
    text-align: right!important;
    align-self: flex-end!important;
    width: 100%;
  `}

  ${props => (props.textAlign === POSITION.CENTER) && css`
    text-align: center!important;
    align-self: center!important;
    width: 100%;
  `}

  ${props => (props.appearance === APPEARANCE.UNDERLINE) && css`
    text-decoration: underline;
  `}

  ${props => (props.appearance === APPEARANCE.TITLE) && css`
    display: inline-block;
    vertical-align: top;
    width: 100%;
    min-height: 100px;
    padding-top: 10px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: left;
    @media (max-width: 1060px) {
      min-height: 80px;
      line-height: 1;
    }
  `}

  ${props => (props.appearance === APPEARANCE.CARD_TITLE) && css`
    vertical-align: top;
    width: 100%;
    height: 35px;
    padding: 0;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  `}

  ${props => (props.appearance === APPEARANCE.CARD_DESCRIPTION) && css`
    vertical-align: top;
    width: 100%;
    height: 55px;
    padding: 5px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: left;
  `}

  ${props => (props.fontType === FONT_TYPE.BOLD) && css`
    font-weight: bold!important;
    margin: 0;
    line-height: 1.2;
    letter-spacing: .35px;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.fontType === FONT_TYPE.BOLDER) && css`
    font-weight: bolder!important;
    margin: 0;
    line-height: 1!important;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.fontType === FONT_TYPE.ITALIC) && css`
    font-style: italic!important;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.fontType === FONT_TYPE.MEDIUM_BOLD) && css`
    font-style: normal!important;
    font-weight: 600;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.transform === TRANSFORM.UPPERCASE) && css`
    text-transform: uppercase;
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.transform === TRANSFORM.LOWERCASE) && css`
    text-transform: lowercase;
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.transform === TRANSFORM.CAPITALIZE) && css`
    text-transform: capitalize;
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.transform === TRANSFORM.FIRST_LETTER) && css`
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.size === SIZE.EXTRA_SMALL) && css`
    font-size: 9px;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0.35px;
    line-height: 1.4;
    i {
      margin-right: 5px;
    }
  `}

  ${props => (props.size === SIZE.SMALL) && css`
    font-size: 12px;
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
  `}  

  ${props => (props.size === SIZE.MEDIUM) && css`
    font-size: 14px;
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
    @media (max-width: 579px) {
      font-size: 14px;
    }
  `}
  
  ${props => (props.size === SIZE.LARGE) && css`
    font-size: 24px;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
    @media (max-width: 579px) {
      font-size: 20px;
    }
  `}

  ${props => (props.size === SIZE.EXTRA_LARGE) && css`
    font-size: 32px;
    margin: 0;
    line-height: 1.2;
    i {
      margin-right: 5px;
    }
    @media screen and (max-width: 579px) {
      font-size: 24px;
    }
  `} 
  
`;
