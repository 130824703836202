/* eslint-disable no-plusplus */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AvatarEditor from 'react-avatar-editor';
import { toast } from 'react-toastify';
import { Creators as ProfileActions } from '../../store/ducks/user/profile/actions';
import placeholder from '../../images/placeholder-avatar.png';
import api, { gateway } from '../../utils/api';
import { toastDefaultOptions } from '../../utils/functions';

class EditAvatarModal extends React.Component {
  state = {
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 150,
    width: 240,
    height: 240,
    loading: false
  };

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] });
  };

  handleSave = async () => {
    const { image } = this.state;
    this.setState({ loading: true });
    try {
      const formData = new FormData();
      formData.append('image', image);
      await gateway.post(`person/person/uploadPersonImage`, formData, {
        headers: { 'Content-Type': 'image/jpeg' }
      });
      this.updateProfile();
    } catch (error) {
      toast('Não foi possível alterar a imagem.', {
        ...toastDefaultOptions,
        type: 'error'
      });
      this.setState({ loading: false });
      console.log(error.response);
    }
  };

  dataURLtoBlob = async dataurl => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  updateProfile = async () => {
    const { user, getProfileRequest, closeModal } = this.props;
    const idProfile = localStorage.getItem('idProfile');
    try {
      await gateway.put('/webbff/lit_app_web/profile/updateProfileTO', user);
      this.setState({
        image: null,
        loading: false
      });

      toast('Avatar editado com sucesso', {
        ...toastDefaultOptions,
        type: 'success'
      });
      getProfileRequest(idProfile);
      closeModal();
    } catch (error) {
      console.log(error.response);
    }
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateLeft = e => {
    e.preventDefault();
    const { rotate } = this.state;
    this.setState({ rotate: rotate - 90 });
  };

  rotateRight = e => {
    e.preventDefault();
    const { rotate } = this.state;
    this.setState({ rotate: rotate + 90 });
  };

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value, 10);
    this.setState({ borderRadius });
  };

  handleXPosition = e => {
    const x = parseFloat(e.target.value);
    const { position } = this.state;
    this.setState({ position: { ...position, x } });
  };

  handleYPosition = e => {
    const y = parseFloat(e.target.value);
    const { position } = this.state;
    this.setState({ position: { ...position, y } });
  };

  handleWidth = e => {
    const width = parseInt(e.target.value, 10);
    this.setState({ width });
  };

  handleHeight = e => {
    const height = parseInt(e.target.value, 10);
    this.setState({ height });
  };

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  render() {
    const {
      scale,
      width,
      height,
      position,
      rotate,
      borderRadius,
      image,
      loading,
      allowZoomOut
    } = this.state;
    const {
      closeModal,
      user: { linkPhoto }
    } = this.props;
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Editar imagem de perfil</h5>
        </div>
        <div className="modal-body">
          <div className="avatarCrop">
            <AvatarEditor
              ref={this.setEditorRef}
              scale={parseFloat(scale)}
              width={width}
              height={height}
              position={position}
              onPositionChange={this.handlePositionChange}
              rotate={parseFloat(rotate)}
              borderRadius={borderRadius}
              onSave={this.handleSave}
              image={image || linkPhoto || placeholder}
            />
            <label
              className={`btn btn-blue mt-2${loading ? ' btn-disabled' : ''}`}
            >
              Escolher imagem
              <input
                type="file"
                accept="image/jpeg"
                id="selectedFile"
                disabled={loading}
                hidden
                onChange={this.handleNewImage}
              />
            </label>
            <div className="row avatarEdit-buttons">
              <div className="col text-center">
                <h6 className="blue">Zoom</h6>
                <input
                  name="scale"
                  type="range"
                  disabled={loading}
                  style={{ width: 200 }}
                  onChange={this.handleScale}
                  min={allowZoomOut ? '0.1' : '1'}
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
              </div>
              <div className="col text-center">
                <h6 className="blue">Girar foto</h6>
                <button
                  type="button"
                  className="btn btn-blue"
                  disabled={loading}
                  onClick={this.rotateLeft}
                >
                  Esquerda
                </button>
                <button
                  type="button"
                  className="btn btn-blue ml-2"
                  disabled={loading}
                  onClick={this.rotateRight}
                >
                  Direita
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-modal__cancel"
            disabled={loading}
            type="button"
            onClick={closeModal}
          >
            Cancelar
          </button>

          <button
            disabled={loading || !image}
            className={`btn btn-modal__approve ${loading && ' btn-loading'}`}
            type="button"
            onClick={this.handleSave}
          >
            Salvar
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(ProfileActions, dispatch);

export default connect(null, mapDispatchToProps)(EditAvatarModal);
