import React from "react";
import { TabBody } from "./styles";

export default ({ child, id }) => {
  return (
    <>
      <TabBody eventKey={id}>{child}</TabBody>
    </>
  );
};
