import React from "react"
import Async from "react-select/async"
import { components } from "react-select"
import Simplert from "react-simplert"
import Modal from "react-responsive-modal"
import Img from "react-image"
import api from "../../../utils/api"
import { API_URL_IMAGES } from "../../../utils/constants"
import placeholder from "../../../images/placeholder-avatar.png"

class AddUsers extends React.Component {
  state = {
    value: [],
    modalOpen: false,
    alertFailed: false,
    alert: false,
  }

  sendInvites = () => {
    const idProfileOwner = localStorage.getItem("idProfile")
    const { idGroup, onAcceptInvite } = this.props
    const { value: users } = this.state
    if (users.length > 0) {
      Promise.all(
        users.map(user =>
          api.get(`sendInviteToNewMember/${idProfileOwner}/${user.idProfile}/${idGroup}`)
        )
      )
        .then(response => {
          const results = response.filter(res => res.message !== "true")
          if (results.length > 0) {
            this.setState({ alertFailed: true })
          } else {
            this.setState({ alert: true, value: "", modalOpen: false })
            onAcceptInvite()
          }
        })
        .catch(err => console.log(err))
    }
  }

  getUsers = input => {
    const { idGroup } = this.props
    const data = {
      tag: input,
      idGroup,
    }
    return api.post("searchProfileToInvite", data).then(res => {
      return res
    })
  }

  loadOptions = (inputValue, callback) => {
    if (!inputValue || inputValue.length < 3) {
      callback([])
    }
    setTimeout(() => {
      callback(this.getUsers(inputValue))
    }, 1000)
  }

  onChange = valueSelect => {
    const { value } = this.state
    const val = value
    val.push(valueSelect[0])
    console.log(val)
    this.setState({ value: val })
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true })
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false })
  }

  SelectOptions = props => {
    const { data } = props
    const urlImg = data.linkPhoto ? `${API_URL_IMAGES}images/profile/${data.idProfile}.jpg` : placeholder
    return (
      <components.Option className="d-flex align-items-center" {...props}>
        <Img src={urlImg} style={{ width: 50, height: 50, borderRadius: 10 }} decode={false} />
        <div style={{ marginLeft: 5 }}>
          <h5>{data.name}</h5>
          {data.cargo ? (
            <span>
              Cargo: {data.cargo} - Empresa: {data.empresa}
            </span>
          ) : null}
        </div>
      </components.Option>
    )
  }

  render() {
    const { alert, modalOpen, alertFailed, value } = this.state
    return (
      <div
        className="card-body"
        style={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <button type="button" className="btn btn-blue" onClick={this.onOpenModal}>
          Convidar para grupo
        </button>
        <Simplert
          showSimplert={alert}
          type="success"
          title="Solicitação enviada!"
          message="Aguarde a resposta do(s) usuário(s)"
          onClose={() => this.setState({ alert: false })}
          customCloseBtnText="Ok"
          customCloseBtnClass="btn btn-blue"
        />

        <Modal
          open={modalOpen}
          onClose={this.onCloseModal}
          classNames={{ modal: "lit-modal-covidar-grupo" }}
        >
          <div style={{ backgroundColor: "#FFF", padding: 20 }}>
            <Simplert
              showSimplert={alertFailed}
              type="warning"
              title="Algumas solicitações não puderam ser enviadas!"
              message="Tente enviar as solicitações individualmente"
              onClose={() => this.setState({ alertFailed: false })}
              customCloseBtnText="Ok"
              customCloseBtnClass="btn btn-blue"
            />
            <h5>Selecione os usuários para enviar convite</h5>
            <br />
            <Async
              isMulti
              defaultOptions
              value={value}
              onChange={this.onChange}
              getOptionLabel={opt => opt.name}
              getOptionValue={opt => opt.idProfile}
              name="users"
              placeholder="Escolha um ou mais usuários"
              noOptionsMessage={() => "Digite para pesquisar"}
              components={{ Option: this.SelectOptions }}
              loadOptions={this.getUsers}
            />
            <button type="button" className="btn btn-blue mt-3" onClick={this.sendInvites}>
              Convidar
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default AddUsers
