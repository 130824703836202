import styled from "styled-components";

export const TextCenter = styled.p`
  text-align: justify;
  font-size: 13px;
`;

export const LinkBlue = styled.a`
  color: #27aae1;
  text-decoration: none;
`;

export const ErrorMessage = styled.p`
  color: #e50000;
  font-size: 1.1em !important;
`
