import styled, { css } from 'styled-components';

export const PlanItem = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.cardWidth ? `${props.cardWidth}px` : '300px')};
  min-height: 600px;
  margin-top: 0px;
  border-top: 3px solid #27AAE1;
  position: relative;
  box-shadow: 0 0 6px rgba(0,0,0,.1);
  background: ${props => (props.selected ? '#f1f1f1' : '#fff')};
  color: #000;
  box-sizing: border-box;
  padding: 40px 30px;
  border-radius: 3px;
  position: relative;
  input {
    width: 100%;
    outline: 0;
  }
  .promo-banner {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    padding: 5px 10px;
    transform: translateY(-50%) rotate(-45deg);
  }

  ol {
    list-style: auto;
    list-style-position: inside;

    li {
      margin-bottom: 5px;
    }
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  ${props =>
    props.voucher &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;      

      .box-loading {
        height: 270px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        font-size: 14px;
        margin-top: 10px;
        line-height: 1.3;
        color: ${props.selected ? '#fff' : 'inherit'} !important;
      }
    
      input[type="text"] {
        color: ${props.selected ? '#fff' : 'inherit'} !important;
        margin-bottom: 5px;
      }

      button {
        background: ${props.selected && '#fff'} !important; 
      }



      @media (max-width: 768px) {
        width: 100%
        margin: 10px 0;
        
      }
    `}

/* hr {
  margin: 0;
} */

${props =>
    props.selected &&
    css`
    hr {
      border-color: #fff;
    }
  `}

  &::-webkit-scrollbar {
    background-color:#fff;
    width:16px;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-track {
    background-color:#fff;
  }

 &::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
      background-color:#babac0;
      border-radius: 16px;
      border:5px solid #fff
  }
  &::-webkit-scrollbar-thumb:hover {
      background-color:#a0a0a5;
      border:4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {display:none}

 
  h2 {
    font-size: 14px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    b {
      display: block;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  h3 {
    width: 100%;
    position: relative;
    margin-bottom: 0;
    background-image: linear-gradient(to right, #27AAE1, #D7DF23);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    
    span {
      display: flex;

      justify-content: center;
      b {
        font-size: 40px;
        font-weight: 400;
      }
      small {
        font-size: 11px;
        font-weight: 400;
        display: flex;
        /* background: red; */
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 7px;
        padding-left: 2px;
      }

      & + span {
        margin-top: 5px;
      }

      
    }
   
    span {
      font-size: 11px;
    }

    small {
      font-size: 14px;
    } 
  }

  ul {
    margin-top: 10px;
    list-style: inherit;
    padding: 0;
    li {
      font-size: 14px;
      svg {
        color: rgba(2,196,255,1);
      }
      & + li {
        margin-top: 5px;
      }
    }
  }

  span.text-card {
    color: #fff;
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 14px;
  }
`;

export const PromoBanner = styled.div`
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 0;
    background-image: linear-gradient(to right, #27AAE1, #D7DF23);
    color: white;
    padding: 10px 10px;
    transform: translateY(50%) rotate(+45deg);
`;


export const PlanTypeView = styled.div`
  background-color: #27AAE1;
  color: white;
  width: 90%;
  padding: 5px 5px 5px 30px;
  margin-left: -31px;
  margin-top: 10px;
  font-size: 12px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const PlanName = styled.div`
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  height: 60px;
  font-size: 1.75rem;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: inherit;
  padding-top: 3px;
`;

export const PriceDescription = styled.div`
  color: #000;
  font-size: 19px;
  margin-top: 20px;
`;

export const PlanPrice = styled.h4`
  background-image: linear-gradient(to right, #27AAE1, #D7DF23);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
`;


export const SecondDescription = styled.small`
  text-align: center;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

export const FirstDescription = styled.h4`
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  /* min-height: 55px; */
`;
