import React from "react";

import * as Styles from "./styles";

function Input({
  label,
  error,
  options,
  containerProps,
  prefix,
  sufix,
  ...rest
}) {
  return (
    <Styles.Container style={{ ...(containerProps || {}) }}>
      {label && <label>{label}</label>}
      <div>
        {prefix}
        <select {...rest}>
          {(options || []).map(item => (
            <option value={item.value}>{item.label}</option>
          ))}
        </select>
        {sufix}
      </div>

      {error && <p>{error}</p>}
    </Styles.Container>
  );
}

export default Input;
