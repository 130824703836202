import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  width: 100%;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .slick-track:hover {
    transition: none;
    transform: none;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:hover .slick-slide {
    transition: none;
    transform: none;
  }

  .slick-slide:hover ~ .slick-slide {
    transition: none;
    transform: none;
  }

  .parent {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: auto;
    & + div {
      margin-top: 20px;
    }
    /* padding: 1rem; */
  }
  .slider-container {
  /* if you want to have a scrollbar, you can add overflow here */ 
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem .1rem;
    padding: 0px;
    scroll-behavior: smooth;
    &:hover {
      .prev {
        opacity: 1;
        /* visibility: visible; */
      }
      .next {
        opacity: 1;
        /* visibility: visible; */
      }
    }
  }
  .slider-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      display: none;
    }
  }

  .prev {
    left: -0.2rem;
    font-family: FontAwesome !important;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6) !important;
    color: rgb(255, 255, 255) !important;
    padding: 25px 10px !important;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
  }

  .next {
    right: -0.2rem;
    font-family: FontAwesome !important;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6) !important;
    color: rgb(255, 255, 255) !important;
    padding: 25px 10px !important;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
  }

  .prev:before {
    content: "\f053" !important;
    font-family: FontAwesome;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .next:before {
    content: "\f054" !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  .child {
    padding: 5px 20px;
    background: #59eb97;
    text-align: center;
    width: 100%;
    margin-right: 10px;
    border-radius: 21px;
  }

`;


export const Button = styled.button`
  display: flex;
  background: #fff;
  padding: 8px 10px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 5px;
    color: ${props => props.disabled ? '' : '#27aae1'};
  }

`

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  p {
    margin-bottom: 5px;
    margin-top: 15px;
  }
`;

export const BoxInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Progress = styled.div`
  flex: 1;
  margin-right: 10px;
`;

export const StyleLane = styled.div`
  /* width: 265px; */
  /* margin: 10px; */
  padding: 0 5px;
  & + div {
    /* margin-left: 10px; */
  }
  position: relative;
  text-align: center;
  font-size: 26px;
`;
