import React from "react";
import {
  ICON,
  COLOR,
  SIZE,
  APPEARANCE,
  POSITION,
  BORDER_TYPE
} from "../../../../constants";
import Text from "../../../basicComponents/Text";
import Button from "../../../basicComponents/Button";
import Search from "../../../Inputs/Search";
import Header from "../../../Header";
import { Agrupable } from "./styles";

export default ({ textHeader, closeModal }) => {
  return (
    <Header
      size={SIZE.SMALL}
      appearance={APPEARANCE.MODAL_SEARCH_HEADER}
      topLeft={[
        <Agrupable>
          <Button
            appearance={APPEARANCE.LINK}
            size={SIZE.LARGE}
            icon={ICON.BACK_ARROW}
            onClick={closeModal}
            color={COLOR.LIGHT}
            border={BORDER_TYPE.NO_BORDER}
          />
          <Text
            size={SIZE.LARGE}
            color={COLOR.LIGHT}
            text={textHeader}
            position={POSITION.LEFT}
            appearance={APPEARANCE.TITLE}
          />
        </Agrupable>
      ]}
    />
  );
};
