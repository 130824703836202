import styled from "styled-components";

export const Container = styled.div`
  font-family: Lato;
  height: 100%;
  min-height: 353px;
  color: #000;
  @media(max-width: 710px) {
    flex-direction: column-reverse;
    min-height: auto;
  }

  ::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
`;

export const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
`;

export const EnumCard = styled.div`
  width: 100%;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #E0E0E0;
  display:flex;
  p {
    color: #000;
  }
`;

export const ContainerAlternative = styled.div`
  background-color: #fff;
  & + div {
    margin-top: 10px;
  }
 
  border: ${props => props.noBorder ? 'none' : '1px'} solid #E0E0E0;
  
  border-radius: 5px;
  padding: ${props => props.noBorder ? '0' : '5px'};
`;

export const Alternative = styled.div`
  background-color: #fff;

  display: flex;
  align-items: center;

  label {
    margin-bottom: 0 !important;
    display: flex;
  }

  .form-check {
    margin-left: 25px;
    margin-bottom: 0;
  }

  .form-check-label {
    text-align: left !important;
  }

  .form-check-input {
    margin-top: 0.35rem;

    input[type="checkbox"] {
      background-color: red;
      color: red;
    }

    input[type="checkbox"]:disabled {
      background-color: #bebe;
      color: red;
    }
  }
`;
