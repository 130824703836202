import React from 'react';
import { Redirect } from "react-router-dom";

import Screen from './screen';

export default function MemberGetMember() {
  const freeUser = JSON.parse(localStorage.getItem('freeUser'));

  if (freeUser) {
    return <Redirect to={{ pathname: "/" }} />
  }

  return <Screen />
}
