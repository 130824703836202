import styled from 'styled-components'

const Gradient = styled.div`
  background-color: #27aae1;
  filter: progid:DXImageTransform.Microsoft.gradient( GradientType=1, startColorstr=#27AAE1, endColorstr=#FCEE1E);
  background-image: -moz-linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: -webkit-linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: -ms-linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: -o-linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: -webkit-gradient(
    linear,
    color-stop(0%, #27aae1),
    color-stop(100%, #fcee1e)
  );
  cursor:pointer;
`

export const NoImagemBook = styled(Gradient)`
  width: 100%;
  height: 300px;
  /* background: #27aae1; */

  display: flex;  
  flex-direction: column;
  justify-content: space-between;

  .info {
    display: flex;
    padding: 20px 10px;
    min-height: 300px;
    align-items: center;
    justify-content: center;
    flex: 1;
    svg {
      color: #fff
    }
    h5 {
      font-size: 20px;
      width: 100%;
      text-align: center;
      color: #fff !important;
    }
  }

  #author {
    background: #fff;
    font-size: 14px;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    padding: 0 20px;
    height: 133px;
    margin: 0;
    position: relative;

    i {
      position: absolute;
      top: 8px;
      left: 20px;
    }

    p {
      width: 100%;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
`
