import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/pt-br";

import { gateway } from "../../../utils/api";
import { isUserStorage, getUserDecodedToken } from "../../../utils/functions";
import { WEBEX_ENABLE_SSO, WEBEX_CLIENT_ID } from "../../../utils/constants";

export default function SamlSSO({ location }) {
  const [authenticating, setAuthenticating] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const params = new URLSearchParams(location.search);
  const realizationArea = params.get("realizationarea");

  const getAuthHTML = (SAMLAssertion, acsUrl, RelayState) => `
    <html>
    <head>
      <title>Sign In - Webex</title>
    </head>
    <body>
      <form method="post" name="hiddenform"
        action="${acsUrl}">
        <input type="hidden"
                    name="SAMLResponse"
                    value="${SAMLAssertion}">
        <!-- input type="hidden" name="RelayState" value="${RelayState}"> -->
      </form>
      <script language="javascript" type="text/javascript">
        // Interval used to maintain connection with parent window
        // It stops sending new information to localstorage when the webex auth request is done
        let i = 0;
        setInterval(() => {
          localStorage.setItem('keep-window-opened', i);
          i++;
        }, 100);
        // Send request to webex immediately
        window.setTimeout(function(){document.forms[0].submit();}, 0);
      </script>
    </body>
    </html>
  `;

  const closeWindowWhenFinishAuth = childWindow => {
    return new Promise(resolve => {
      const newInterval = () =>
        setInterval(() => {
          // eslint-disable-next-line no-use-before-define
          clearInterval(closeWindowInterval);
          childWindow.close();
          resolve();
        }, 500);
      let closeWindowInterval = newInterval();
      window.addEventListener("storage", event => {
        if (event.key === "keep-window-opened") {
          clearInterval(closeWindowInterval);
          closeWindowInterval = newInterval();
        }
      });
    });
  };

  const completeWebexLogin = async (SAMLAssertion, acsUrl) => {
    const authHTML = getAuthHTML(SAMLAssertion, acsUrl);
    // TODO: Verify user popup policy, these window.open functions may not work.
    const authWindow = window.open(
      "",
      "Sign In - Webex",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,width=200,height=200"
    );
    authWindow.document.write(authHTML);
    await closeWindowWhenFinishAuth(authWindow);
    // Define webex auth expiration date to 15 minutes from now
    localStorage.setItem(
      "webex_auth_expires_in",
      moment()
        .add(15, "minutes")
        .format()
    );
  };

  const manualLoginWebex = async data => {
    setAuthenticating(true);
    const { SAMLAssertion, acsUrl } = await gateway.post(
      "/webbff/lit_app_web/webex/signin",
      data
    );
    await completeWebexLogin(SAMLAssertion, acsUrl);
    window.open(`https://admin.webex.com?email=${data.email}`, "_blank");
    setAuthenticating(false);
  };

  const createUserIfNotExists = async () => {
    await gateway.post("/webbff/lit_app_web/webex/user");
  };

  const createMeeting = async () => {
    // Create a meeting with 1h duration
    const meetingData = {
      title: "Portal creating meeting",
      start: moment()
        .add(5, "minutes")
        .format(),
      end: moment()
        .add(5, "minutes")
        .add(12, "hours")
        .format()
    };
    const meeting = await gateway.post(
      "/webbff/lit_app_web/webex/meetings",
      meetingData
    );

    const meetingLink = `${meeting.id}?${meeting.webLink.split("?")[1]}`;

    return `${meeting.sipAddress}:${meeting.hostKey}:${meetingLink}:${new Date(
      meetingData.end
    ).valueOf()}`;
  };

  const enterOnMeeting = meetingDial => {
    // Set meetingurl in localstoragel
    localStorage.setItem("webex_meeting_dial", meetingDial);

    const encodedRedirectURI = encodeURIComponent(
      `${window.location.origin}/oauth-webex`
    );

    const userCrCode = getUserDecodedToken().crCode;
    const userWebexEmail = encodeURIComponent(`${userCrCode}@lit.com.br`);

    // Redirect to webex authorization page
    const url = `https://webexapis.com/v1/authorize?email=${userWebexEmail}&client_id=${WEBEX_CLIENT_ID}&response_type=code&redirect_uri=${encodedRedirectURI}&scope=spark-admin%3Abroadworks_subscribers_write%20spark%3Aall%20analytics%3Aread_all%20meeting%3Aadmin_participants_read%20spark-admin%3Apeople_write%20spark-admin%3Aplaces_read%20spark-compliance%3Ateam_memberships_write%20spark-compliance%3Amessages_read%20spark-admin%3Adevices_write%20spark-admin%3Aworkspaces_write%20meeting%3Aadmin_schedule_write%20identity%3Aplaceonetimepassword_create%20spark-admin%3Acall_qualities_read%20spark-compliance%3Amessages_write%20spark%3Akms%20meeting%3Aparticipants_write%20spark-admin%3Apeople_read%20spark-compliance%3Amemberships_read%20spark-admin%3Aresource_groups_read%20meeting%3Arecordings_read%20meeting%3Aparticipants_read%20meeting%3Apreferences_write%20meeting%3Aadmin_recordings_read%20spark-admin%3Aorganizations_read%20meeting%3Aschedules_write%20spark-compliance%3Ateam_memberships_read%20spark-admin%3Adevices_read%20meeting%3Acontrols_read%20spark-admin%3Ahybrid_clusters_read%20meeting%3Aadmin_schedule_read%20meeting%3Aschedules_read%20spark-compliance%3Amemberships_write%20spark-admin%3Abroadworks_enterprises_read%20spark-admin%3Aroles_read%20meeting%3Arecordings_write%20meeting%3Apreferences_read%20spark-admin%3Aworkspaces_read%20spark-admin%3Aresource_group_memberships_read%20spark-compliance%3Aevents_read%20spark-admin%3Aresource_group_memberships_write%20spark-compliance%3Arooms_read%20spark-admin%3Abroadworks_subscribers_read%20meeting%3Acontrols_write%20meeting%3Aadmin_recordings_write%20spark-admin%3Ahybrid_connectors_read%20audit%3Aevents_read%20spark-compliance%3Ateams_read%20spark-admin%3Aplaces_write%20spark-admin%3Alicenses_read&state=state`;

    window.location.replace(url);
    // window.open(meetingUrl, '_blank')
  };

  const ssoInit = async () => {
    const { SAMLAssertion, acsUrl } = await gateway.post(
      "/webbff/lit_app_web/webex/sso"
    );
    await completeWebexLogin(SAMLAssertion, acsUrl);
  };

  const ssoIfNeeded = async () => {
    if (!WEBEX_ENABLE_SSO) return;
    const expireDate = localStorage.getItem("webex_auth_expires_in");
    // Check if sso auth process is required
    if (!expireDate || new Date(expireDate).valueOf() < new Date().valueOf()) {
      setAuthenticating(true);
      await createUserIfNotExists();
      await ssoInit();
      setAuthenticating(false);
    }
  };

  useEffect(() => {
    async function enterOnMeetingProcess() {
      await ssoIfNeeded();

      let meetingDial = null;

      /*
      Get meetingDial from facility metadata by realizationarea/crCode.
      When there is no meetingDial metadata in facility, the ms will return an error
      So we catch the error if it's a 404.
      */
      try {
        meetingDial = await gateway.get(
          `/facility/facility/metadata/${realizationArea}/webex_url/webex_classroom`
        );
      } catch (error) {
        // Throw error only if the error message is not a 404.
        if (!error.message.includes("404")) {
          throw error;
        }
      }

      // If the meeting was not found (not created in facility yet), or if it's expired
      let invalidMeeting = !meetingDial;
      if (meetingDial) {
        const expirationDate = meetingDial.split(":")[3];
        if (new Date().valueOf() > Number(expirationDate)) {
          invalidMeeting = true;
        }
      }

      if (invalidMeeting) {
        // Create a meeting
        meetingDial = await createMeeting();
        // Save meetingDial in facility
        await gateway.post(
          `/facility/facility/metadata/${realizationArea}/webex_url/webex_classroom`,
          { value: meetingDial }
        );
      }
      // Enter on meeting
      enterOnMeeting(meetingDial);
    }
    if (isUserStorage() && realizationArea) {
      // If the user is logged
      enterOnMeetingProcess();
    } else {
      // Show webex login form
      setShowForm(true);
    }
  }, []);

  if (authenticating) return "Fazendo autenticação com Webex";
  if (showForm) {
    return (
      <>
        <div style={{ margin: "0 auto", width: "300px", textAlign: "center" }}>
          <h1>Webex Login</h1>
          <Formik onSubmit={manualLoginWebex}>
            {props => (
              <form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
                onSubmit={props.handleSubmit}
              >
                <input
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.email}
                  placeholder="email"
                  name="email"
                  style={{ marginBottom: "8px" }}
                />
                <input
                  placeholder="senha"
                  type="password"
                  onChange={props.handleChange}
                  value={props.values.secret}
                  name="secret"
                  style={{ marginBottom: "8px" }}
                />
                <div>
                  <button
                    style={{ display: "block", margin: "0 auto" }}
                    disable={authenticating}
                    type="submit"
                  >
                    Entrar
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </>
    );
  }

  return "Carregando...";
}
