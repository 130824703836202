import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  height: calc(100vh - 70px);
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
  footer {
    width: 100%;
    padding: 10px 30px;
    height: 70px;
  }
  @media(max-width: 710px) {
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  }
`;

export const Content = styled.div`
  padding: 20px 30px;
  padding-bottom: 0px;
  height: calc(100% - 238px);
  
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  @media(max-width: 710px) {
    height: auto;
  }

  main {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 10px;
    ::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  }
`

export const Loading = styled.div`
  position: fixed;
  z-index: 201;
  top: 66px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(0px);
  background: transparent;
  height: calc((100% - 66px) - 75px);
`;

