import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Component} children //rendered content on the card
 * @param {String} appearance //{POST} card appearance
**/

export default ({
  children = DEFAULT_VALUES.COMPONENT,
  appearance = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        child={children}
        appearance={appearance}
      />
    </>
  );
};
