import styled from "styled-components";
import { } from "../../../constants";

export const LeftBar = styled.div`
  display: inline-block;
  text-align: left;
  width: 60%;
`;

export const RightBar = styled.div`
  display: inline-block;
  text-align: right;
  width: 40%;
`;
