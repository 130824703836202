import React from "react";
import {
  APPEARANCE,
  COLOR,
  NUMBER,
  ORIENTATION,
  SIZE
} from "../../../constants";
import OverflowContent from "../../basicComponents/OverflowContent";
import NotificationCard from "../../basicComponents/NotificationCard";
import { Content } from "./styles";

export default ({ content }) => {
  return (
    <>
      <Content>
        <OverflowContent
          orientation={ORIENTATION.HORIZONTAL}
          size={SIZE.MEDIUM}
        >
          {content.map(item => (
            <NotificationCard
              type={item.type}
              contentCard={item.metadata}
              appearance={APPEARANCE.EVALUATION}
              color={COLOR.LIGHT}
              perLine={NUMBER.THREE}
            />
          ))}
        </OverflowContent>
      </Content>
    </>
  );
};
