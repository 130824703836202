import React, { useEffect } from "react"
import { BLUE_LIT } from "../../utils/colors"
import { getStatusServer } from "../../utils/functions";
import history from "../../utils/history";

const LOGO_HOME = require('../../images/lit.png');

export default function Maintenance() {

  useEffect(() => {
    // getStatusServer()
    history.push('/login')
  }, []);

  return (
    <div style={{ background: BLUE_LIT, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%' }}>
      <div className="container">
      <div className="row">
        <div className="col mt-5 pt-5">
        <div
          style={{
            backgroundColor: BLUE_LIT,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-end',
            paddingBottom: 100,
            paddingHorizontal: 30,
          }}
        >
          <div>
            
            <img src={LOGO_HOME} alt="HOME" height={40} />
            <div
              style={{
                borderBottom: '1px solid white',
                paddingBottom: 10,
                marginBottom: 50,
                marginTop: 50
              }}
            >
              <h1
                style={{
                  fontSize: 40,
                  color: 'white',
                }}
              >
                Aviso de Manutenção Programada - LIT
              </h1>
            </div>
            <p style={{ fontSize: 20, color: 'white', marginBottom: 20 }}>
              Olá, aluno LIT!
            </p>
            <p style={{ fontSize: 20, color: 'white' }}>
              Estamos sempre buscando aprimorar sua experiência e, para isso, uma
              manutenção programada está sendo realizada. Durante este período,
              tanto o nosso Aplicativo quanto o Portal Web estarão temporariamente
              indisponíveis.
            </p>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
  )
}
