import styled from "styled-components";

export const Form = styled.form`

.box-buttons {
  margin-top: -30px;
  @media(max-width: 991px) {
    margin-top: 0 !important
  }
}

`;

export const ItemFormField = styled.div`
  border: 2px solid #27aae1;
  /* padding: 0 20px; */
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
`
export const ItemFormFieldDisabled = styled.div`
  border: 2px solid #27aae1;
  padding: 0 20px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: #e9ecef;

  input{
    background-color: #e9ecef !important;
  }
`
export const ItemFormFieldSelect = styled.div`
  border: 2px solid #27aae1;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
  min-height: 53px !important;

  div{
    border:none !important;
  }

  select{
    border:none !important;
    height: 53px !important;
    background: transparent !important;
    padding: 15px;
  }
`

export const LabelField = styled.label`
  font-weight: bold;
  color: #444;
  margin-bottom: 10px;
`

export const InputText = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 19.9rem; */
  height: 51px;
  border: none;
  background-color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px;
  padding: 0rem 1rem 0rem;

  &:-webkit-input-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19.9rem;
    height: 3.2rem;
    border: none;
    border-radius: 5px;
    font-size: 19px;
    padding: 0rem 1rem 0rem;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus {
    outline: none;
    padding: 0rem 1rem 0rem;
  }

  &::before {
    background: linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%);
  }

  &:focus ~ label {
    visibility: visible;
  }
`

export const TermText = styled.label`
  p{
    text-align: left !important;
    font-size:12px;
  }
`
export const TermWithCheckBoxContainer = styled.div`
  flex-direction: row;
  display: flex;
  p{
    text-align: left !important;
    font-size:13px;
  }
`;

export const CheckBoxContent = styled.div`
  margin-right: 10px;
  input {
    border-color: ${props => props.error ? 'red' : '#767676'}
  }
`;
