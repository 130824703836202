import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Select from "react-select"
import { graduacao } from "../../utils/constants"

import NavBar from "../Termos/Navbar"
import WatsonBadgeFooter from "./WatsonBadgeFooter"
import RequiredSpan from "../RequiredSpan"
import api from "../../utils/api"

const GIF_LIT = require("../../images/animacao_paul_standby.gif")

class Formacao extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  constructor(props) {
    super(props)
    const { perfil } = this.props
    this.state = {
      mba: (perfil.graduacao !== undefined ) ? perfil.graduacao : false,
      formacao: perfil.descGraduacao || "",
      anoConclusao: perfil.anoGraduacao ||"",
      loading: false,
      showMessageOnBoardIncomplete: JSON.parse(localStorage.getItem('onBoardIncomplete')),
      errors: "",
    }
  }

  get validationForm() {
    const { mba, anoConclusao, formacao } = this.state
    return mba && (anoConclusao === "" || formacao === '')
  }

  saveForm = async () => {
    try {
      const { router } = this.context
      this.setState({ loading: true, errors: "" })
      const savePerfil = await this.savePerfil()
      console.log(savePerfil)
      this.setState({ loading: false })
      router.history.push("/exProfissional")
    } catch (error) {
      console.log(error)
      this.setState({
        errors: "Não foi possivel enviar os dados, tente novamente mais tarde",
        loading: false,
      })
    }
  }

  savePerfil = () => {
    const { perfil } = this.props
    const { mba, anoConclusao, formacao } = this.state
    const url = `updateProfileTO`
    const newProfile = {
      ...perfil,
      graduacao: mba,
      anoGraduacao: anoConclusao,
      descGraduacao: formacao,
    }

    return api.put(url, newProfile)
  }

  render() {
    const { mba, anoConclusao, formacao, loading, errors, showMessageOnBoardIncomplete } = this.state
    let stylesButtonYes
    let stylesButtonNo

    if (mba === undefined) {
      stylesButtonYes = "btn-PI__cancel"
      stylesButtonNo = "btn-PI__cancel"
    } else if (mba) {
      stylesButtonYes = "btn-PI__approve"
      stylesButtonNo = "btn-PI__cancel"
    } else {
      stylesButtonNo = "btn-PI__approve"
      stylesButtonYes = "btn-PI__cancel"
    }

    return (
      <div id="interesses">
        <NavBar />

        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row">
            <div className="col-12">
              <div className="container" id="objetivos-interesses">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="container" id="card-interesses">
                      <div>
                        <div className="row justify-content-md-center">
                          <div className="col-10 text-center">

                            {showMessageOnBoardIncomplete ? (
                              <div className="text-body-pi" style={{ marginBottom: "40px" }}>
                                <p className="text-center">
                                  Olá novamente, <br />
                                  Notamos que existem algumas informações que não foram preenchidas no seu perfil. <br />
                                  Tome um segundo para respondê-las agora. Não demora nada.
                                </p>
                              </div>) 
                            : ''}

                            <img
                              alt="gif_lit"
                              className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                              src={GIF_LIT}
                            />
                            <h5 className="text-center">O que você já estudou até hoje?</h5>
                            <div className="text-body-pi" style={{ marginBottom: "40px" }}>
                              <p className="text-center">
                                Todos os cursos do LIT estão disponíveis para todos os onlearners.
                              </p>
                              <p className="text-center">
                                Para que você possa usar os cursos como crédito para as Trilhas de MBA da
                                Saint Paul no LIT é pré-requisito que você tenha concluído a graduação,
                                com a devida colação de grau, até a data de início de qualquer trilha de
                                MBA no LIT.
                              </p>
                              <p className="text-center">
                                Se não for graduado ainda, quando se formar você poderá atualizar sua
                                graduação no seu perfil e a partir dai todos os cursos realizados terão
                                validade para trilhas de MBA.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-md-center">
                          <div className="col-5 text-center">
                            <label style={{ color: "red" }}>
                              {this.validationForm ? 'Por favor, preencha todos os campos corretamente antes de prosseguir' : ''}
                            </label>
                          </div>
                        </div>
                        <div className="row justify-content-md-center">
                          <div className="col-5 text-center">
                            <h6 className="text-center">
                              Você concluiu uma graduação? <RequiredSpan />
                            </h6>
                            <div className="row justify-content-md-center">
                              <button
                                type="button"
                                className={`btn ${stylesButtonYes}`}
                                style={{ marginRight: "35px" }}
                                id="btn-yes"
                                onClick={() => this.setState({ mba: true })}
                              >
                                Sim
                              </button>
                              <button
                                type="button"
                                className={`btn ${stylesButtonNo} `}
                                onClick={() => this.setState({ mba: false })}
                                id="btn-not"
                              >
                                Não
                              </button>
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              <h6 className="text-center margin-top-20">Qual curso?</h6>
                              <Select
                                name="formation"
                                onChange={({ value }) => {
                                  this.setState({ formacao: value })
                                }}
                                id="select-course"
                                value={graduacao.filter(({ value }) => value === formacao)}
                                options={graduacao}
                                placeholder="Curso"
                              />

                              {mba !== undefined && (
                                <div className="margin-top-20">
                                  <h6 className="text-center">
                                    {mba ? "Ano de conclusão" : "Previsão de conclusão"}
                                  </h6>
                                  <div className="flex-center">
                                    <div className="form-group col-12" style={{ padding: 0 }}>
                                      <input
                                        className="form-control"
                                        placeholder="Ano"
                                        type="Number"
                                        required={mba}
                                        value={anoConclusao}
                                        id="input-conclusion-year"
                                        onChange={evt =>
                                          this.setState({
                                            anoConclusao: evt.target.value.slice(0, 4),
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <span>
                              * a veracidade dessa informação é de responsabilidade do usuário e a Saint
                              Paul se reserva ao direito de verificação desta informação.
                            </span>
                          </div>
                        </div>
                        <div className="row justify-content-center" id="selecionar-personalizar">
                          <div className="d-flex justify-content-center col-2">
                            <button
                              type="button"
                              disabled={this.validationForm || loading}
                              onClick={this.saveForm}
                              className={`btn btn-blue btn-FontWeight6${loading && " btn-loading"}`}
                              id="next-step"
                            >
                              Próximo passo
                            </button>
                          </div>
                        </div>
                        <div>
                          <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <WatsonBadgeFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  perfil: state.user.profile.data,
})

export default connect(mapStateToProps)(Formacao)
