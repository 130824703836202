module.exports = {
    ANUAL_PLAN: 'No caso do plano 12 meses e plano 2 anos, no vencimento, a recorrência é automática até que você cancele sua inscrição.',
    NO_TRIAL_BUYING_COURSE: 'Não há trial para compra de cursos, a cobrança será feita imediatamente após a confirmação do meio de pagamento.',
    IMMEDIATE_CHARGE: 'Este plano possui cobrança imediata, a cobrança será feita imediatamente após a confirmação do pagamento.',
    AUTHORIZATION_CHARGE: [`Pelo presente, você autoriza o LIT a cobrar de você`, ` todo `, ` automaticamente até que você cancele sua inscrição`],
    TRIAL_MESSAGE: ', após o período de teste,',
    NO_REFUND: 'Não podemos emitir reembolsos após a cobrança em seu cartão. Termos e condições disponíveis',
    YEAR: 'ano',
    MONTH: 'mês',
    HERE_LINK: 'aqui'
}