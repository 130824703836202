/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Lane from '../../../../../shared/Lane';
import CardCourse from '../CardCurso';
import * as Styles from './styles';

function Screen({ courses, title, getMoreCourses, onClick, errorCoursesMessage }) {

  return (
    <Styles.Container>
      <Lane title={title} paginationHook={getMoreCourses}>
        {!courses.length && (
          <Styles.EmptyListText>
            {errorCoursesMessage ? errorCoursesMessage : 'Sem disciplinas'}
          </Styles.EmptyListText>
        )}
        {courses.map(course => {
          return (
            <Styles.Wrapper key={`${course.id}`}>
              <CardCourse 
                course={course} 
                onClick={(course) => onClick(course)}
                progresso  
              />
            </Styles.Wrapper>
          );
        })}
      </Lane>
    </Styles.Container>
  );
}

export default Screen;
