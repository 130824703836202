import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../../constants";

/**
 * @param {Array} feedItems //loads data from display components (cards) of the feed
 * @param {Array} notifications //loads notification data for the current user (logged in)
 * @param {Object} userProfile //display informations of logged in user
 * @param {Array} currentCourse //loads course data in progress
 * @param {Boolean} hasNextPage //function that checks if there is more content to be loaded
 * @param {Function} nextPage //function that loads content on the scroll
 * @typedef {Object} userProfile
 * @property {string} name //user name
 * @property {string} img //path (url) of user photo
 * @property {boolean} hasCurrentCourse //checks if the user has any course in progress
 **/

export default ({
  feedItems = DEFAULT_VALUES.LIST,
  notifications = DEFAULT_VALUES.LIST,
  userProfile = DEFAULT_VALUES.OBJECT,
  currentCourse = DEFAULT_VALUES.LIST,
  hasNextPage = DEFAULT_VALUES.BOOLEAN,
  nextPage = DEFAULT_VALUES.FUNCTION
}) => {
  return (
    <>
      <Screen
        userProfile={userProfile}
        currentCourse={currentCourse}
        notifications={notifications}
        feedItems={feedItems}
        hasNextPage={hasNextPage}
        nextPage={nextPage}
      />
    </>
  );
};
