import React, { useContext } from "react";

import MeetingSidebar from "../MeetingSidebar";
import MeetingVideoBox from "../MeetingVideoBox";
import * as Styles from "./styles";

import { MeetingContext } from "../../MeetingContext";
import MeetingControls from "../MeetingControls";
import MeetingLoading from "../MeetingLoading";
import MeetingExit from "../MeetingExit";

export default function Meeting({ onToggleMeetingFullscreen }) {
  const { meetingLoading, meetingEnded, isMeetingFullscreen } = useContext(
    MeetingContext
  );

  return (
    <Styles.Wrapper>
      { meetingLoading && <MeetingLoading /> }
      { meetingEnded && <MeetingExit /> }
      
      <div style={{ display: meetingLoading ? "none" : "inherit" }}>
        {!meetingEnded && (
          <>
            <Styles.Container id="meeting-container">
              <MeetingVideoBox onToggleMeetingFullscreen={onToggleMeetingFullscreen} />
              {!isMeetingFullscreen && <MeetingSidebar />}
              {isMeetingFullscreen && <MeetingControls />}
            </Styles.Container>
          </>
        )}
      </div>
    </Styles.Wrapper>
  );
}
