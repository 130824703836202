import React from 'react';
import PropTypes from 'prop-types';

import Screen from './screen';

function CardEvaluation({evaluation}) {
  return (
    <Screen evaluation={evaluation}  />
  )
}

CardEvaluation.propTypes = {
  evaluation: PropTypes.object.isRequired,
};


export default CardEvaluation;
