import styled, { css } from "styled-components";
import { SIZE } from "../../../constants";

export const TextArea = styled.textarea`
  
  ${props => (props.size === SIZE.SMALL) && css`
    height: 45px;
    border: 1px solid var(--textarea-border);
    background-color: #F2F4F7;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    padding: 15px 0 0;
    ::-moz-placeholder {
      line-height: 1.2;
    }
    ::-webkit-input-placeholder {
      line-height: 1.2;
    }
    ::-ms-input-placeholder {
      line-height: 1.2;
    }
  `}
  
  ${props => (props.size === SIZE.MEDIUM) && css`
    height: 90px;
    border: 1px solid var(--textarea-border);
    background-color: #F2F4F7;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    ::-moz-placeholder {
      line-height: 88px;
    }
    ::-webkit-input-placeholder {
      line-height: 88px;
    }
    ::-ms-input-placeholder {
      line-height: 88px;
    }
  `}
  
  ${props => (props.size === SIZE.LARGE) && css`
    height: 180px;
    border: 1px solid var(--textarea-border);
    background-color: #F2F4F7;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    ::-moz-placeholder {
      line-height: 178px;
    }
    ::-webkit-input-placeholder {
      line-height: 178px;
    }
    ::-ms-input-placeholder {
      line-height: 178px;
    }
  `}

`;
