import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} title //title displayed above the slider
 * @param {Component} content //content to be rendered in the slider
**/

export default ({
  title = DEFAULT_VALUES.STRING,
  content = DEFAULT_VALUES.COMPONENT
}) => {
  return (
    <>
      <Screen
        title={title}
        content={content}
      />
    </>
  );
};
