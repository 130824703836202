import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import adsTracking from '../../utils/adsTracking';

function FacebookTracking({ location }) {
  try {
    useEffect(() => {
      async function sendGraphApi() {
        const url = window.location.href;
        const data = {
          url
        };
        await adsTracking.sendGraphApiFacebook('PageView', data);
      }
      sendGraphApi();
    }, [location.pathname]);

    return null; // Componente não renderiza nada visível no DOM
  } catch (error) {
    console.log(error)
  }
}

export default withRouter(FacebookTracking);
