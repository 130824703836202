import React, { Component } from "react"

import {API_URL_IMAGES } from "../../utils/constants"
import api from "../../utils/api"
import history from '../../utils/history'

class CursosHabilitados extends Component {
  state = {
    courses: [],
    loadingCourses: false,
  }

  componentDidMount() {
    this.getMediaByProfile()
  }



  getMediaByProfile = async () => {
    this.setState({ loadingCourses: true })
    const idProfile = localStorage.getItem("idProfile")
    const url = `getlitpasscourses/${idProfile}`
    try {
      const response = await api.get(url)
      this.setState({
        courses: response,
        loadingCourses: false,
      })
    } catch (error) {
      console.log(error.response)
      this.setState({ loadingCourses: false })
    }
  }

  

  render() {
    const { loadingCourses, courses } = this.state
    
    if(courses.length > 0 && !loadingCourses) {
      return (
        <div className=" bg-white-perfil" id="cursos-concluidos">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <h5>Cursos selecionados</h5>
              </div>
            </div>
            <div className="row">
              {!loadingCourses &&
                courses.map(course => (
                  <div key={course.totvsId} className="col-3 d-inline-block " style={{ padding: "0 5px", marginBottom: '8px', cursor: "pointer" }} onClick={() => history.push({ pathname: `/curso/${course.idCourse}`}) }>
                    <div>
                      <img
                        alt="Capa curso"
                        height={100}
                        className="img-course img-fluid"
                        src={`${API_URL_IMAGES}images/courses/${course.idCourse}.jpg`}
                      />
                    </div>
                  </div>
                ))}
              
            </div>
          </div>
        </div>
      )
      
    }
    return null
    
  }
}

export default CursosHabilitados
