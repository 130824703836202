import React, { Component } from "react"

import Sobre from "./Sobre"
import Historico from "./Historico"
import Planos from "./Planos";
import Trilhas from "./Trilhas"

import xpON from "../../images/xp_on.png"
import xpOFF from "../../images/xp_off.png"

class Main extends Component {
  constructor(props) {
    super(props)

    this.state = {
      conquista: false,
      about: false,
      trails: false,
      tabAboutClicked: () => {
        document.querySelector("#myTab-HistoryDiv").classList.remove("myTabSeparator")
        document.querySelector("#myTab-TrailsDiv").classList.add("myTabSeparator")
        this.setState({ conquista: false, about: true, trails: false })
      },
      tabHistoryClicked: () => {
        document.querySelector("#myTab-HistoryDiv").classList.remove("myTabSeparator")
        document.querySelector("#myTab-TrailsDiv").classList.remove("myTabSeparator")
        this.setState({ conquista: true, about: false, trails: false })
      },
      tabTrailsClicked: () => {
        document.querySelector("#myTab-HistoryDiv").classList.add("myTabSeparator")
        document.querySelector("#myTab-TrailsDiv").classList.remove("myTabSeparator")
        this.setState({ conquista: false, about: false, trails: true })
      },
    }
  }

  render() {
    const { tabAboutClicked, tabHistoryClicked, conquista, about, trails, tabTrailsClicked } = this.state
    return (
      <>
      {this.props.openPlansModal && <Planos setEndOfMonth={() => {}} />}
      <div>
        <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              onClick={tabAboutClicked}
              className="nav-link active"
              id="sobre-tab"
              data-toggle="tab"
              href="#sobre"
              role="tab"
              aria-controls="sobre"
              aria-selected="true"
            >
              <span data-tip="Sobre perfil">
                <i className="fa fa-user" aria-hidden="true" /> Sobre
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={tabHistoryClicked}
              className="nav-link"
              id="historico-tab"
              data-toggle="tab"
              href="#historico"
              role="tab"
              aria-controls="historico"
              aria-selected="false"
            >
              <div id="myTab-HistoryDiv" data-tip="Acompanhe suas conquistas dentro do LIT">
                <img src={conquista ? xpON : xpOFF} style={{ width: 24, height: 23.33 }} alt="" />{" "}
                Conquistas{" "}
              </div>
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              onClick={tabTrailsClicked}
              className="nav-link"
              id="trilhas-tab"
              data-toggle="tab"
              href="#trilhas"
              role="tab"
              aria-controls="trilhas"
              aria-selected="false"
            >
              <div
                id="myTab-TrailsDiv"
                className="myTabSeparator"
                data-tip="Acompanhe o progresso de seus cursos e trilhas em andamento"
              >
                <i
                  className="fa fa-graduation-cap"
                  aria-hidden="true"
                  style={{ width: 24, height: 23.33, paddingLeft: 3.5 }}
                />{" "}
                Cursos e trilhas
              </div>
            </a>
          </li> */}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="sobre"
            role="tabpanel"
            aria-labelledby="sobre-tab"
          >
            <Sobre about={about} />
          </div>
          <div className="tab-pane fade" id="historico" role="tabpanel" aria-labelledby="historico-tab">
            <Historico conquista={conquista} />
          </div>
          {/* <div className="tab-pane fade" id="trilhas" role="tabpanel" aria-labelledby="trilhas-tab">
            <Trilhas trails={trails} />
          </div> */}
        </div>
      </div>
      </>
    )
  }
}

export default Main
