import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} icon //icon displayed next to the text
 * @param {String} image//image displayed next to the text
 * @param {String} title //title displayed in the component - first line
 * @param {String} subtitle //description displayed in the component - second line
 * @param {String} color //{LIGHT} content color
 * @param {String} size //content size
 * @param {String} orientation //{HORIZONTAL} direction of items within the component
**/

export default ({
  icon = DEFAULT_VALUES.STRING,
  image = DEFAULT_VALUES.STRING,
  title = DEFAULT_VALUES.STRING,
  subtitle = DEFAULT_VALUES.STRING,
  color = DEFAULT_VALUES.STRING,
  size = DEFAULT_VALUES.STRING,
  orientation = DEFAULT_VALUES.STRING
}) => {
  return (
    <>
      <Screen
        icon={icon}
        image={image}
        title={title}
        subtitle={subtitle}
        color={color}
        size={size}
        orientation={orientation}
      />
    </>
  );
};
