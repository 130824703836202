import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    width: 100%;
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }
  
  p {
    width: 100%;
    font-size: 14px;
  }

  p + p {
    margin-top: 10px;
  }

  svg {
    font-size: 100px;
    margin: 0 auto;
    font-weight: lighter;
    margin-bottom: 20px;
    color: #7ae190;
  }
`
