import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { toast } from "react-toastify"
import moment from "moment"
import Axios from "axios";
import fileDownload from "js-file-download";
import { API_URL } from "../../utils/constants"
import { toastDefaultOptions } from '../../utils/functions';
import { gateway } from "../../utils/api"

const TextTitle = styled.h3`
  font-family: "Dosis", sans-serif;
  font-size: 2.2em;
  text-align: center;
  font-weight: lighter;
  margin-bottom: 10px !important;
  font-stretch: condensed;
  color: #27aae1;
`

const Wrapper = styled.div`
  max-width: 100%;
`

const TextSuccessCertificate = styled.p`
  white-space: pre-line;
`

class RightSideBar extends Component {
  static contextTypes = {
    router: PropTypes.shape(),
  }

  constructor() {
    super()
    this.state = {
      hash: "",
      error: "",
      loading: false,
      urlCertificate: "",
      textCertificate: "",
      newCertificate: false,
      downloading: false,
      courseName: "",
    }
  }

  componentDidMount() {
    const path = window.location.pathname.split("/")
    const hash = path[path.length - 1]
    if (hash !== "validador") {
      this.setState({ hash }, this.validarCertificado)
    }
  }

  notify = (text, timeClose = 4000) => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: timeClose,
    })
  }

  validarCertificado = async event => {
    try {

      if (event) {
        event.preventDefault()
      }
      this.setState({ error: "", urlCertificate: "", textCertificate: "", loading: true })
      const { hash } = this.state
      const url = `webbff/lit_app_web/media/validateCertificate/${hash}`

      const response = await gateway.get(url);
        if(response.newCertificate){
          const certificate = {
            textCertificate: `Certificado autêntico para: 
            ${response.profileName} emitido em ${moment(response.generatedAt).format(
              "MMMM [de] YYYY"
            )} para o curso: ${response.courseName}.`,
            urlCertificate: response.urlCertificate,
            newCertificate: response.newCertificate,
            courseName: response.courseName,
          }
          this.setState(certificate);
        } else {
          const certificate = {
            textCertificate: `Certificado autêntico para: 
            ${response.profileName} emitido em ${moment(response.certificate.generatedAt).format(
              "MMMM [de] YYYY"
            )} para o curso: ${response.courseName}.`,
            urlCertificate: `${API_URL}generateDisciplineCertificate/${response.certificate.studentProfileId
            }/${response.certificate.courseId}`,
            newCertificate: response.newCertificate,
            courseName: response.courseName,
          }
          this.setState(certificate);
        }

    } catch (error) {
      console.log(error)
      this.setState({ error: "Ops, erro na validação do certificado, se persistir entre em contato com nosso SAC.", urlCertificate: "", textCertificate: "", loading: false })
    } finally {
      this.setState({ loading: false });
    }
  }

  removeHash = () => {
    this.setState({ hash: "", error: "", urlCertificate: "", textCertificate: "" })
  }

  downloadCertificate = () => {
    try {
      this.setState({ downloading: true })
      const { urlCertificate, courseName } = this.state;
      let extension;
      Axios.get(urlCertificate, {
        responseType: 'blob',
      }).then(res => {
        const { type } = res.data;
        if(type === "application/pdf"){
          extension = 'pdf';
        } else if(type === "image/png"){
          extension = 'png';
        } else if(type === "image/jpeg"){
          extension = 'jpeg';
        } if(type === "image/jpg"){
          extension = 'jpg';
        }
        fileDownload(res.data, `certificado-${courseName}.${extension}`);
      });
    } catch (error) {
      this.setState({ 
        error: error.message, 
        urlCertificate: "", 
        textCertificate: "", 
        loading: false,
        downloading: false
      })
    } finally {
      this.setState({ downloading: false })
    }
  }

  render() {
    const { hash, loading, error, urlCertificate, textCertificate, downloading } = this.state
    return (
      <Fragment>
        <TextTitle>Validação de Certificado</TextTitle>
        <Wrapper>
          <form style={{ marginTop: "2em" }} onSubmit={this.validarCertificado}>
            <div className="field-float-label-pattern">
              <div>
                <input
                  type="text"
                  placeholder="Código de confirmação"
                  className="form-control input-not-yellow input-inline-block"
                  id="inputCodigoConfirmacao"
                  value={hash}
                  onChange={evt => this.setState({ hash: evt.target.value })}
                  required
                />
                <label htmlFor="inputCodigoConfirmacao">Código de confirmação</label>
              </div>
            </div>

            <div className="row justify-content-center">
              <button
                type="submit"
                disabled={loading}
                className={`btn btn-login btn-FontWeight6${loading && " btn-loading"}`}
              >
                Validar
              </button>
            </div>
          </form>
          {error && (
            <p className="text-center m-2" style={{ color: "#e50000" }}>
              {error}
            </p>
          )}
          {textCertificate && (
            <TextSuccessCertificate className="text-center m-2">
              {textCertificate}
            </TextSuccessCertificate>
          )}
          {urlCertificate && (
            <div className="d-flex justify-content-center">
                <button
                  type="button"
                  disabled={downloading}
                  onClick={this.downloadCertificate}
                  style={{ width: 'auto', backgroundColor: '#a2a2a2' }}
                  className={`btn btn-login btn-FontWeight6${downloading && " btn-loading"}`}
                >
                  <i className="fa fa-download"></i> Baixar certificado
                </button>
            </div>
          )}
        </Wrapper>
      </Fragment>
    )
  }
}

export default RightSideBar