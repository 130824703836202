import React from "react"
import styled from "styled-components"
import moment from "moment"

import { getIconNotifications } from "../../utils/functions"
import api from "../../utils/api"

const LineTable = styled.tr`
  background-color: ${props => (!props.read ? "#E6F7FF" : "transparent")};
`

const IconContent = styled.i`
  font-size: x-large;
  color: #9e9e9e;
  height: 25px;
  width: 24px;
  text-align: center;
  background-color: transparent;
`
const LabelContent = styled.label`
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0px;
  font-weight: 500;
  vertical-align: top;
  cursor: pointer;
`

const LabelContentTime = styled(LabelContent)`
  font-size: 13px;
  display: block;
  font-weight: 700;
  text-align: right;
`

const LabelVisualization = styled.label`
  font-size: large;
  color: #9e9e9e;
  margin-bottom: 0px;
  cursor: pointer;
`
const ColTable = styled.td`
  padding: 0.60rem !important;
  line-height: 15px;
  vertical-align: middle !important;
  border-top: 1px solid #cccdce !important;
`

function TableBody({ updateCount, updateList, item }) {
  async function onChangeRead(idHistory) {
    const idProfile = localStorage.getItem("idProfile")
    api
      .get(`saveReadNotification/${idHistory}/${idProfile}`)
      .then(response => {
        if (response.status === 200) {
          updateCount()
          updateList()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <LineTable read={item.readMessage}>
      <ColTable>
        <div className="ml-1">
          <IconContent className={getIconNotifications(item.tipoEnvio)} aria-hidden="true" />
        </div>
      </ColTable>
      <ColTable>
        <LabelContent>{item.texto}</LabelContent>
        <LabelContentTime>{moment(item.dataExecucao).toNow()}</LabelContentTime>
      </ColTable>
      <ColTable>
        <LabelVisualization>
          {item.readMessage ? (
            <i className="fa fa-circle-thin" aria-hidden="true" />
          ) : (
            <i
              onClick={() => onChangeRead(item.idHistory)}
              className="fa fa-circle mark-read"
              aria-hidden="true"
            />
          )}
        </LabelVisualization>
      </ColTable>
    </LineTable>
  )
}

export default TableBody
