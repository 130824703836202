import React from "react";
import { isUserAuthorized } from "../../../utils/functions";
import { MeetingProvider } from "./MeetingContext";
import MeetingContainer from "./components/MeetingContainer";

export default function MeetingWebex() {
  const meetingDial = localStorage.getItem("webex_meeting_dial");

  const hasHostPermissions = isUserAuthorized("PROFESSOR");

  if (hasHostPermissions) {
    const [, , meetingLink] = meetingDial.split(":");
    const returnUrl = `https://litonlearning2.webex.com/webappng/sites/litonlearning2/meeting/info/${meetingLink}`;
    window.open(
      `https://litonlearning2.webex.com/mw3300/mywebex/default.do?siteurl=litonlearning2&viewFrom=modern&login_return_url=${encodeURIComponent(
        returnUrl
      )}`,
      "_blank"
    );
    return null;
  }

  return (
    <MeetingProvider>
      <MeetingContainer meetingDial={meetingDial} />
    </MeetingProvider>
  );
}
