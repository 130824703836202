import styled from "styled-components";

export const ButtonCollapse = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  text-decoration: none;
  padding-left: 0;
  &:hover {
    text-decoration:none;
  }
  &:active, &:focus {
    box-shadow: none;
    outline: none;
    text-decoration:none;
  }

  div span {
    font-weight: bold;
  }

  i {
    color: #27aae1;
  }
`

export const TitleRecommendations = styled.p`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
`
