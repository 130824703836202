import React from "react";
import {
  SIZE,
  APPEARANCE,
  BORDER_TYPE,
  COLOR,
  FONT_TYPE,
  ACTION,
  TRANSFORM,
  ORIENTATION
} from "../../constants";
import Text from "../basicComponents/Text";
import Image from "../basicComponents/Image";
import Spacing from "../basicComponents/Spacing";
import Clickable from "../basicComponents/Clickable";
import Search from "../Inputs/Search";
import ContainerWithTopBackground from "../basicComponents/ContainerWithTopBackground";
import Container from "../containers/PageContainer";
import Row70x30 from "../defaultAreas/Row_70-30";
import Column2x2 from "../defaultAreas/Column_2x2";
import { Agrupable } from "./styles";

export default ({
  hasActionTop,
  topRight,
  topLeft,
  bottomRight,
  bottomLeft,
  logoPage,
  imagePage,
  textHeader,
  textDescription,
  textInformation,
  topMenu,
  size,
  openSearch,
  setOpenSearch,
  appearance
}) => {
  const backgroundSize = !hasActionTop && SIZE.LARGE ? SIZE.MEDIUM_LARGE : size;

  return (
    <>
      <ContainerWithTopBackground size={backgroundSize} appearance={appearance}>
        <Container>
          {hasActionTop && size !== SIZE.MEDIUM && (
            <Column2x2
              orientation={ORIENTATION.AJUSTABLE}
              left={[
                topLeft,
                <Image src={logoPage} appearance={APPEARANCE.LOGO} />,
                topMenu
              ]}
              right={[
                topRight,
                openSearch && (
                  <Agrupable>
                    <Search onChange={() => {}} />
                    <Clickable
                      onClick={() => {
                        setOpenSearch(false);
                      }}
                      appearance={APPEARANCE.LINK}
                    >
                      <Text
                        text={ACTION.ADVANCED_SEARCH}
                        transform={TRANSFORM.UPPERCASE}
                        size={SIZE.SMALL}
                        appearance={APPEARANCE.UNDERLINE}
                      />
                    </Clickable>
                  </Agrupable>
                )
              ]}
            />
          )}
          {size !== SIZE.SMALL && [
            size !== SIZE.MEDIUM && <Spacing size={SIZE.LARGE} />,
            <Row70x30
              left={[
                bottomLeft,
                imagePage && (
                  <Image
                    src={imagePage}
                    size={size}
                    border={BORDER_TYPE.BORDER}
                    appearance={appearance}
                  />
                ),
                textHeader && (
                  <Text
                    text={textHeader}
                    size={SIZE.EXTRA_LARGE}
                    color={COLOR.LIGHT}
                    appearance={APPEARANCE.TITLE}
                  />
                ),
                textDescription && (
                  <Text
                    text={textDescription}
                    size={SIZE.LARGE}
                    color={COLOR.LIGHT}
                    appearance={APPEARANCE.TITLE}
                  />
                ),
                textInformation && (
                  <Text
                    text={textInformation}
                    size={SIZE.LARGE}
                    color={COLOR.LIGHT}
                    fontType={FONT_TYPE.ITALIC}
                    appearance={APPEARANCE.TITLE}
                  />
                )
              ]}
              right={[bottomRight]}
            />
          ]}
        </Container>
      </ContainerWithTopBackground>
    </>
  );
};
