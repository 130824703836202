import React, { useState, useEffect, useMemo } from "react";

import { FaTimesCircle } from "react-icons/fa";
import avatar from "../../../../images/avatar.png";

import {
  Container,
  ContentContainer,
  CloseBtnContainer,
  CloseBtn,
  Content,
  SendMessage,
  BoxMessageEnded
} from "./styles";

function Feedbacks({ feedbacks, sendMessage, numberQuestion, onCloseChat }) {
  const [idProfile, setIdProfile] = useState("");
  const [message, setMessage] = useState("");

  const endedSubject = useMemo(() => {
    const foundItem = [...feedbacks]
      .reverse()
      .find(item => !Number.isNaN(parseInt(item.grade, 10)));
    if (foundItem && foundItem.endedSubject) {
      return true;
    }

    return false;
  }, [feedbacks]);

  function handleChange(event) {
    setMessage(event.target.value);
  }

  useEffect(() => {
    const profile = localStorage.getItem("idProfile");

    setIdProfile(profile);
  }, []);

  return (
    <Container>
      <ContentContainer>
       
        <CloseBtnContainer>
          <p>FEEDBACK QUESTÃO {numberQuestion < 10 ? `0${numberQuestion}` : numberQuestion}</p>
          <CloseBtn type="button" onClick={onCloseChat}>
            <FaTimesCircle />
          </CloseBtn>
        </CloseBtnContainer>
        <Content>
          <ul>
            {feedbacks &&
              feedbacks
                .filter(feedback => {
                  if(feedback.feedbackMessage && (typeof feedback.feedbackMessage === 'string')){
                    return !!feedback.feedbackMessage.trim()
                  }
                  return null
                })
                .map(feedback => {
                  return (
                    <li
                      className={
                        feedback.authorReference === idProfile
                          ? "sent"
                          : "received"
                      }
                    >
                      <img src={avatar} alt="user" />

                      <span>{feedback.feedbackMessage}</span>
                    </li>
                  );
                })}
          </ul>
        </Content>
        {!endedSubject && (
          <SendMessage>
            <textarea value={message} onChange={e => handleChange(e)} rows={5} />
            <button
              type="button"
              onClick={() => {
                sendMessage(message);
                setMessage("");
              }}
            >
              Enviar
            </button>
          </SendMessage>
        )}

       {endedSubject && (
         <BoxMessageEnded>
            <p>O chat sobre este feedback foi encerrado pelo tutor.</p>
         </BoxMessageEnded>
       )}
        
      </ContentContainer>
    </Container>
  );
}

export default Feedbacks;
