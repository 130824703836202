import React from "react";
import { ICON, SIZE, APPEARANCE, BORDER_TYPE } from "../../../../constants";

import Button from "../../../basicComponents/Button";

import { ModalHeader } from "./styles";

export default ({ textHeader, closeModal }) => {
  return (
    <>
      <ModalHeader textHeader={textHeader}>
        <Button
          appearance={APPEARANCE.LINK}
          size={SIZE.LARGE}
          icon={ICON.BACK_ARROW}
          onClick={closeModal}
          border={BORDER_TYPE.NO_BORDER}
        />
      </ModalHeader>
    </>
  );
};
