import React, { Component } from "react"
import Select from "react-select"
import Simplert from "react-simplert"

import AvatarPerfilPublico from "../AvatarUsuarios/AvatarPerfilPublico"
import api from "../../utils/api"

class LeftSideBar extends Component {
  state = {
    selecionado: "",
    groups: [],
    alert: false,
    error: false,
  }

  componentDidMount() {
    this.getGroupsOwner()
  }

  getGroupsOwner = async () => {
    const idProfile = localStorage.getItem("idProfile")
    try {
      const response = await api.get(`showGroupOwner/${idProfile}`)
      this.setState({ groups: response })
    } catch (error) {
      console.log(error)
    }
  }

  sendInvite = async () => {
    const idProfileOwner = localStorage.getItem("idProfile")
    const {
      profile: { idProfile },
    } = this.props
    const { selecionado } = this.state
    try {
      const response = await api.get(
        `sendInviteToNewMember/${idProfileOwner}/${idProfile}/${selecionado}`
      )
      if (JSON.parse(response.message)) {
        this.setState({ alert: true, selecionado: "" })
      } else {
        this.setState({ error: true, selecionado: "" })
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { profile } = this.props
    const { selecionado, groups, alert, error } = this.state
    return (
      <div id="perfil-infos" className="container-fluid">
        <div className="row bg-white-perfil">
          <div className="col-12 perfil-infos">
            <AvatarPerfilPublico profile={profile} />
            {groups.length > 0 && profile.idProfile !== localStorage.getItem("idProfile") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Select
                  id="select-grupos"
                  onChange={({ idGroup }) => this.setState({ selecionado: idGroup })}
                  value={groups.filter(({ idGroup }) => idGroup === selecionado)}
                  name="groups"
                  getOptionLabel={opt => opt.groupName}
                  getOptionValue={opt => opt.idGroup}
                  options={groups}
                  placeholder="Escolha um grupo"
                  isSearchable
                />
                <button type="button" className="btn btn-blue mt-2 btn-sm" onClick={this.sendInvite}>
                  Convidar para grupo
                </button>
              </div>
            )}
          </div>
        </div>
        <Simplert
          showSimplert={alert}
          onClose={() => this.setState({ alert: false })}
          type="success"
          title="Solicitação enviada!"
          message="Aguarde a resposta do usuário"
          customCloseBtnText="Ok"
          customCloseBtnClass="btn btn-blue"
        />
        <Simplert
          showSimplert={error}
          onClose={() => this.setState({ error: false })}
          type="error"
          title="Solicitação não foi enviada!"
          message="Ocorreu um erro ao enviar o convite, verifique se o usuario ja não faz parte do grupo escolhido"
          customCloseBtnText="Ok"
          customCloseBtnClass="btn btn-blue"
        />
      </div>
    )
  }
}

export default LeftSideBar
