import React, { PureComponent } from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { UserActions } from "../../store/ducks/user";

import logLit from "../../images/lit-azul.png";
import api, { gateway } from "../../utils/api";

import Loading from "../Loading/Loading";
import MyCoursesLitPass from "../shared/MyCoursesLitPass";

const naRealUser = JSON.parse(localStorage.getItem("naRealUser"));

class AlertRestrictLitPass extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      paymentMonthly: [],
      paymentYearly: [],
      showUpgrade: false,
      loading: true
    };
  }

  componentDidMount() {
    // this.getPaymentsValues();
    // this.verifyPlans();
  }

  verifyPlans = async () => {
    try {
      const plans = await gateway.get("/plans");
      if (plans.length > 1) {
        this.setState({ showUpgrade: true });
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  };

  onCloseModal = () => {
    const {
      toggleModalRestrict,
      toggleModalRestrictLitPass,
      location,
      history
    } = this.props;
    if (
      !(
        location.pathname.includes("home") ||
        location.pathname.includes("busca")
      )
    ) {
      history.push("/home");
    }
    if (naRealUser) {
      toggleModalRestrict(false);
    } else {
      toggleModalRestrictLitPass(false);
    }
  };

  goToProfile = () => {
    const {
      history,
      setOpenDirectPayment,
      toggleModalRestrict,
      toggleModalRestrictLitPass
    } = this.props;
    if (naRealUser) {
      toggleModalRestrict(false);
    } else {
      toggleModalRestrictLitPass(false);
    }
    setOpenDirectPayment(true);
    history.push("/perfilAluno?openPlanModal=true");
  };

  getPaymentsValues = async () => {
    try {
      const url = "getPaymentTypes";
      const response = await api.get(url);
      this.setState({
        paymentMonthly: response[0],
        paymentYearly: response[1]
      });
      this.pagamentoAnual();
    } catch (error) {
      console.error(error.response);
    }
  };

  renderModalMessage = (type) => {
    const redirectMyCourse = localStorage.getItem("redirectMyCourse");
    if (type === 'curso') {
      return (
        !redirectMyCourse ?
          <p className="mt-4">
            Você já selecionou os seus dois cursos online gratuitos.
            Para continuar aprendendo e destacando seu currículo com
            certificados da Saint Paul Escola de Negócios, é preciso se
            tornar assinante.
          </p>
          :
          <>
            <p className="mt-4">
              Para fazer esse curso e mais de 200 cursos, trilhas de
              cursos e aulas ao vivo semanal,{" "}
              <strong style={{ fontWeight: "bold" }}>
                faça um upgrade e acesse por 12 meses todos os cursos da
                Saint Paul no LIT e ainda:
              </strong>
              <li>Acesse nossa biblioteca online com 8 mil livros</li>
              <li>
                Aprenda com outros alunos e professores na rede social
                de aprendizagem
              </li>
              <li>
                Ganhe uma mentoria para definir os melhores cursos do
                LIT para sua carreira
              </li>
            </p>
            <p className="mt-4">
              Continue aprendendo e se destaque no mercado com os
              certificados da Saint Paul Escola de Negócios.
            </p>
          </>
      )
    }

    if (type === 'livro') {
      return (
        <p className="mt-4">
          A Biblioteca Virtual está disponível apenas para os alunos e alunas
          dos planos de assinatura. Caso queira fazer um upgrade do seu plano atual
          para ter acesso integral aos mais de 300 cursos do LIT e à Biblioteca Virtual,
          entre em contato conosco pelo <u>sac@lit.com.br</u>. Te esperamos
        </p>
      )
    }

    return (
      <p className="mt-4">
        Oi, você escolheu um {type} exclusivo para quem já é assinante
        pagante do LIT.
      </p>
    )
  }

  calcPLan = ({ totalFinal, description }) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2
    });

    const valor = parseFloat(totalFinal);

    const formatado = formatter.format(valor);

    const Texto = (
      <p className="mb-0 text-danger">{`Plano ${description} de ${formatado}`}</p>
    );
    return Texto;
  };

  redirect = course => {
    const id = course.idCourse;
    const {
      history,
      toggleModalRestrict,
      toggleModalRestrictLitPass
    } = this.props;
    if (naRealUser) {
      toggleModalRestrict(false);
    } else {
      toggleModalRestrictLitPass(false);
    }
    history.push({ pathname: `/curso-novo/${id}` });
  };

  render() {
    const { openModal, modalRestrictLitPassType } = this.props;
    const redirectMyCourse = localStorage.getItem("redirectMyCourse");

    let type;

    switch (modalRestrictLitPassType) {
      case "book":
        type = "livro";
        break;
      case "group":
        type = "grupo";
        break;
      default:
        type = "curso";
        break;
    }

    return (
      <Modal
        open={openModal}
        onClose={this.onCloseModal}
        classNames={{ modal: "lit-modal-restrict" }}
        closeOnOverlayClick={false}
      >
        <div className="modal-content">
          <div className="modal-header justify-content-start">
            <img src={logLit} alt="logoLit" height={45} />
          </div>
          <div className="modal-body" style={{ padding: "1em 7em 0 7em" }}>
            <h2 className="font-weight-normal">
              APRENDA AINDA MAIS COM O <strong>LIT</strong>
            </h2>

            {this.renderModalMessage(type)}
            
            {this.state.showUpgrade && !redirectMyCourse && (
              <p>
                São mais de{" "}
                <b className="destaque-modal">
                  150 cursos, aulas ao vivo toda a semana e trilhas de MBA
                  esperando por você
                </b>{" "}
                - com direito a{" "}
                <b className="destaque-modal">
                  diferentes planos e descontos especiais para escolher o que
                  mais combina com seu momento.
                </b>{" "}
                Para começar, é só clicar no botão abaixo:
              </p>
            )}
            {type === "curso" && (
              <MyCoursesLitPass
                redirect={this.redirect}
                redirectMyCourse={redirectMyCourse}
              />
            )}
            <div className="d-flex justify-content-between"></div>
          </div>
          {this.state.loading && <Loading width={20} img />}
          {!this.state.loading && (
            <>
              {this.state.showUpgrade && (
                <div className="modal-footer justify-content-center">
                  <button
                    className="btn btn-alert btn-alert-confirm"
                    type="button"
                    onClick={this.goToProfile}
                  >
                    Upgrade agora
                  </button>
                </div>
              )}
              {!this.state.showUpgrade && (
                <div className="modal-footer justify-content-center">
                  <button
                    className="btn btn-alert btn-alert-confirm"
                    type="button"
                    onClick={this.onCloseModal}
                  >
                    Voltar
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.user.profile.modalRestrictLitPass,
  modalRestrictLitPassType: state.user.profile.modalRestrictLitPassType
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions.ProfileActions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlertRestrictLitPass)
);
