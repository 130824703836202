/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import LaneHoc from "./LaneHoc";
import axios from "axios";

import CardNewGroup from "./CardNewGroup";

import { gateway } from "../../utils/api";

const StyleLivros = {
  margin: 10,
  position: "relative",
  lineHeight: "100px",
  textAlign: "center",
  fontSize: 26
};

export default function LaneSocialGroups({ data }) {
  const docs = _.get(data, "docs", []);
  const laneCrCode = _.get(data, "lane.id", null);
  const laneName = _.get(data, "lane.name", null);

  const { page, limit } = data;
  const [groups, setGroups] = useState(docs);
  const [currentPage, setCurrentPage] = useState(page);

  if (!groups.length) return null;

  const getMoreSocialGroups = () => {
    if (currentPage) {
      const queryParams = `?lane=${laneCrCode}&page=${currentPage}&limit=${limit}`;
      const url = `webbff/lit_app_web/home${queryParams}`;

      gateway
        .get(url)
        .then(response => {
          const hasNextPage = _.get(response, "hasNextPage", false);
          const newGroups = _.get(response, "docs", []);
          setCurrentPage(hasNextPage ? currentPage + 1 : null);
          setGroups([...groups, ...newGroups]);
        })
        .catch(e => {});
    }
  };
  return (
    <div>
      <LaneHoc
        tooltipText=""
        title={laneName}
        paginationHook={getMoreSocialGroups}
      >
        {groups.map(group => {
          return (
            <div style={StyleLivros} key={`${group.groupCode}`}>
              <CardNewGroup group={group} />
            </div>
          );
        })}
      </LaneHoc>
    </div>
  );
}
