import React from "react";
import TabHeader from "../TabHeader";
import TabLink from "../TabLink";
import TabBody from "../TabBody";
import TabContent from "../TabContent";
import {} from "./styles";

export default ({ header, content, appearance }) => {
  return (
    <>
      <TabHeader>
        {header.map(link => (
          <TabLink appearance={appearance} id={link.id}>
            {link.component}
          </TabLink>
        ))}
      </TabHeader>
      <TabBody>
        {content.map(item => (
          <TabContent id={item.id}>{item.component}</TabContent>
        ))}
      </TabBody>
    </>
  );
};
