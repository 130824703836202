import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} alignItems //{TOP_CENTER, TOP_LEFT_CENTER, CENTER, JUSTIFY, TOP_JUSTIFY} alignment of content within the line
 * @param {Component} children //content that will be rendered within the line
 * @param {String} orientation //{AJUSTABLE} defines the orientation of the line within the space
 * @param {Boolean} hidden //defines component visibility
**/

export default ({
  alignItems = DEFAULT_VALUES.STRING,
  children = DEFAULT_VALUES.COMPONENT,
  orientation = DEFAULT_VALUES.STRING,
  hidden = DEFAULT_VALUES.BOOLEAN
}) => {
  return (
    <>
      <Screen
        alignItems={alignItems}
        child={children}
        orientation={orientation}
        hidden={hidden}
      />
    </>
  );
};
