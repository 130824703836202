import React from "react";
import styled from "styled-components";


const UlPagination = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */

  li.page-item.active a.page-link {
    color: #445565 !important;
    background-color: #e3e7eb !important;
    border-color: #ced4da !important;
  }

  a.page-link {
    padding: 0.5rem 1rem;
    min-width: 3.3rem;
    text-align: center;
    box-shadow: none !important;
    border-color: #ced4da !important;
    color: #6b88a4;
    font-weight: 900;
    font-size: 1rem;
  }
  a.page-link:hover {
    background-color: #f4f4f4;
  }
`
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <UlPagination  className="pagination">
      <ul className="pagination justify-content-center">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${number === currentPage ? "active" : ""}`}
          >
            <a
              onClick={() => onPageChange(number)}
              href="#"
              className="page-link"
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
          </UlPagination>
    </nav>
  );
};

export default Pagination;
