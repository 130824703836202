import React from "react";

import { Container } from "./styles";

function Pagination({
  totalPages,
  page,
  onPageChange,
  hasPrevPage,
  hasNextPage
}) {
  return (
    <Container>
      <a
        href="#"
        className={!hasPrevPage ? "disabled" : ""}
        onClick={() => {
          if (hasPrevPage) {
            onPageChange(page - 1);
          }
        }}
      >
        Anterior
      </a>

      {Array(totalPages)
        .fill(0)
        .map((pageNumber, index) => (
          <a
            className={page === index + 1 ? "active" : ""}
            href="#"
            onClick={() => onPageChange(index + 1)}
          >
            {index + 1}
          </a>
        ))}

      <a
        href="#"
        className={!hasNextPage ? "disabled" : ""}
        onClick={() => {
          if (hasNextPage) {
            onPageChange(page + 1);
          }
        }}
      >
        Próximo
      </a>
    </Container>
  );
}

export default Pagination;
