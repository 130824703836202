/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';

import Screen from './screen';

function LaneEvents({ data, onClick, errorEventsMessage, fullButton = false, title, loading = false }) {
  
  const [events, setEvents] = useState(null);

  const isLoading = useMemo(() => {
    if(events) {
      return false
    }
    return true;
  }, [events]);

  useEffect(() => {
    if (data && !loading) {
      setEvents(data);
    }
  }, [data, loading]);

  return (
    <Screen 
      title={title}
      loading={isLoading} 
      fullButton={fullButton} 
      errorEventsMessage={errorEventsMessage} 
      events={events} 
      onClick={onClick} 
    />
  );
}

export default LaneEvents;
