import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import NavBar from "../Termos/Navbar";
import WatsonBadgeFooter from "./WatsonBadgeFooter";
import api from "../../utils/api";

const GIF_LIT = require("../../images/animacao_paul_standby.gif");

class InformacoesGerais extends Component {
  static contextTypes = {
    router: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    const { perfil } = this.props;
  
    this.state = {
      loading: false,
      errors: "",
      isPartner: false,
      birthday: '',
      invalidDate: false,
      age: null,
      howMet: (perfil.howMet) ? perfil.howMet : '',
      educationalDegree: (perfil.educationalDegree) ? perfil.educationalDegree : '',
    };
   
  }

  componentDidMount() {
    const { perfil } = this.props;
    this.getDataCompany();
    this.maskDate(perfil.birthdayDate)
  }

  getDataCompany = async () => {
    try {
      const email = localStorage.getItem("ra")
      const response = await api.get(`onboard/${email}/`)
      if (response && response.company.idCompany === 7) {
        const freeUser = JSON.parse(localStorage.getItem('freeUser'))
        this.setState({
          isPartner: true && freeUser,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  get validationForm() {
    const { birthday, howMet, educationalDegree } = this.state;
    return birthday === '' || birthday === undefined || howMet === '' || educationalDegree === ''
  }

  saveForm = async () => {
    try {
      const { router } = this.context;
      this.setState({ loading: true, errors: "" });
      await this.savePerfil();
      
      this.setState({ loading: false });
      localStorage.setItem("finishOnBoarding", true)
      router.history.push("/home");
    } catch (error) {
      console.log(error);
      this.setState({
        errors:
          "Não foi possivel enviar os dados, por favor entre em contato por ...",
        loading: false
      });
    }
  };

  savePerfil = () => {
    const { perfil } = this.props;
    const { birthday, howMet, educationalDegree } = this.state;
    const url = `updateProfileTO`;
    const newProfile = {
      ...perfil,
      educationalDegree,
      howMet,
      birthdayDate: moment(birthday).format("DD/MM/YYYY"),
    };

    return api.put(url, newProfile);
  };
  
  calculaIdade = (dataEscolhida) => {
    const today = new Date()
    if (dataEscolhida.getUTCFullYear() >= today.getUTCFullYear()) {
      this.setState ({ errors : 'O valor do ano não pode ser maior ou igual ao ano atual', birthday: "", age: "", invalidDate: true})
    } else {
      const dataAniversario = ((dataEscolhida.getMonth()+1) * 100) + dataEscolhida.getDate()
      const hoje = ((today.getMonth()+1) * 100) + today.getDate()
      let age = today.getUTCFullYear() - dataEscolhida.getUTCFullYear()
      
      if (hoje < dataAniversario) {
        age -= 1
      }
      if(age > 100) {
        this.setState ({ errors : 'A idade não pode ser maior que 100 anos', age, birthday: moment(dataEscolhida), invalidDate: true})
      } else {
        this.setState ({ 
          errors : null,
          age,
          birthday: moment(dataEscolhida),
          invalidDate: false
        })
      }
     
     
    }
  }

  maskDate = (value)  => {
    if (value && value.length >= 8) {
      let strDate = value;
      // Retira valores não numéricos
      strDate = strDate.replace(/\D/g, "")
      // Corta a string em 8 digitos
      strDate = strDate.slice(0,8)
      // Põe as '/' no texto
      // O $2 primeiro, pois a formatação US vem com o mês primeiro
      strDate = strDate.replace(/^(\d{2})(\d{2})(\d{4})/g, '$2/$1/$3')
      try {
        const data = new Date(strDate)
        if (data.getUTCFullYear() <= new Date().getUTCFullYear()) {
          this.calculaIdade(data)
        }
      } catch(error) {
        console.log('error', error)
      }
    } else {
      this.setState({age: "", birthday: "", invalidDate: true})
    }
  }

  handleChangeBirthday = date => { 
    if (date) {
      this.calculaIdade(date._d);
    } else {
      this.setState({age: '', errors: "Informe uma data válida"})
    }

    this.setState ({ 
      birthday: date
    })
  };

  render() {
    const {
      errors,
      isPartner,
      birthday,
      age,
      howMet,
      loading,
      invalidDate,
      educationalDegree,
    } = this.state;

    return (

      <div id="interesses">
        <NavBar />

        <div className="container-fluid" style={{ paddingTop: 60 }}>
          <div className="row">
            <div className="col-12">
              <div className="container" id="objetivos-interesses">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="container" id="card-interesses">
                      <div>
                        <div className="row justify-content-md-center">
                          <div className="col-10 text-center">
                            <img
                              alt="gif_lit"
                              className="rounded-0 img-course img-responsive projetos projeto-mascara img-carreira mb-2"
                              src={GIF_LIT}
                            />
                            <h5 style={{ color: "#27aae1" }} className="text-center">
                              NOS CONTE SOBRE VOCÊ
                            </h5>
                          </div>

                          <div className="col-6 text-center" id="objetivos-interesses--desc-usuario">
                            <form>
                              <div className="modalBody-container">
                                <div className="modal-body">

                                  <div className="form-row">
                                    <div className="form-group col">
                                      <label style={{ color: "red" }}>
                                        {this.validationForm ? 'Por favor, preencha todos os campos corretamente antes de prosseguir' : ''}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="form-row">
                                    <div className="form-group col-md-12">
                                      <label>QUAL O SEU NÍVEL DE ESCOLARIDADE?</label>
                                      <select
                                        className="form-control"
                                        required
                                        id="select-educationalDegree"
                                        onChange={ (event) => {
                                          this.setState({ educationalDegree: event.target.value })
                                        }}
                                        value={educationalDegree}
                                        name="nivelEscolaridade"
                                        >
                                        <option value="Nenhum">Nenhum</option>
                                        <option value="Ensino fundamental">Ensino fundamental</option>
                                        <option value="Ensino médio">Ensino médio</option>
                                        <option value="Ensino superior">Ensino superior</option>
                                        <option value="Pós-graduação">Pós-graduação</option>
                                      </select>
                                    </div>
                                  </div>

                                  {isPartner ? (
                                    <div className="form-row">
                                      <div className="form-group col">
                                        <label>COMO CONHECEU A SÉRIE NA REAL? </label>
                                        <select
                                          className="form-control"
                                          required
                                          onChange={ (event) => {
                                            this.setState({ howMet: event.target.value })
                                          }}
                                          id="howMet-2"
                                          value={howMet}
                                          name="howmet"
                                          >
                                          <option value="" readOnly>Escolha...</option>
                                          <option value="Bradesco">Bradesco</option>
                                          <option value="Saint Paul">Saint Paul</option>
                                          <option value="Google">Google</option>
                                          <option value="YouTube">YouTube</option>
                                          <option value="Facebook">Facebook</option>
                                          <option value="Instagram">Instagram</option>
                                          <option value="LinkedIn">LinkedIn</option>
                                          <option value="Mídia (Revista / Jornal / Sites)">Mídia (Revista / Jornal / Sites)</option>
                                          <option value="Indicação">Indicação</option>
                                          <option value="Outros">Outros</option>
                                        </select>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="form-row">
                                      <div className="form-group col">
                                        <label>COMO CONHECEU O LIT? </label>
                                        <select
                                          className="form-control"
                                          required
                                          id="select-howMet"
                                          onChange={ (event) => {
                                            this.setState({ howMet: event.target.value })
                                          }}
                                          value={howMet}
                                          name="howmet"
                                          >
                                          <option value="" readOnly>Escolha...</option>
                                          <option value="Bradesco">Bradesco</option>
                                          <option value="Saint Paul">Saint Paul</option>
                                          <option value="Google">Google</option>
                                          <option value="YouTube">YouTube</option>
                                          <option value="Facebook">Facebook</option>
                                          <option value="Instagram">Instagram</option>
                                          <option value="LinkedIn">LinkedIn</option>
                                          <option value="Mídia (Revista / Jornal / Sites)">Mídia (Revista / Jornal / Sites)</option>
                                          <option value="Indicação">Indicação</option>
                                          <option value="Outros">Outros</option>
                                        </select>
                                      </div>
                                    </div>
                                  )}

                                  <div className="form-row">
                                    <div className="form-group col-md-12">
                                      <label>QUAL A SUA DATA DE NASCIMENTO?</label>
                                      <DatePicker
                                        className="form-control datePicker"
                                        placeholderText="Informe a data do seu nascimento"
                                        selected={birthday}
                                        maxDate={moment()}
                                        onChangeRaw={(e) => this.maskDate(e.target.value)}
                                        onChange={this.handleChangeBirthday}
                                        todayButton="Hoje"
                                        id="input-birthday"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                      />
                                      <label>{age} ANOS</label>
                                    </div>
                                    <div>
                                      <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
                                    </div>
                                  </div>

                                  <div className="row justify-content-center" id="selecionar-personalizar">
                                    <div className="d-flex justify-content-center col-2">
                                      <button
                                        type="button"
                                        disabled={this.validationForm || loading || invalidDate}
                                        onClick={this.saveForm}
                                        id="btn-continue"
                                        className={`btn btn-blue btn-FontWeight6${loading && " btn-loading"}`}
                                      >
                                        {isPartner ? 'ASSISTIR AGORA' : 'Continuar'}
                                      </button>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <WatsonBadgeFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  perfil: state.user.profile.data
});

export default connect(mapStateToProps)(InformacoesGerais);
