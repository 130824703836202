import React from 'react';

import { Row } from './styles';

const styles = {
  subtitle: {
    fontWeight: 'bold'
  },
  modalHeader: {
    width: 'auto',
    height: '50px',
    background: 'transparent',
    boxShadow: 'none',
    marginTop: '60px'
  }
};

export const SubscriptionData = ({ userSubscriptions }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h5 style={{ fontSize: '1.2em', color: '#27aae1' }}>
            Dados do Contrato
          </h5>
          <hr style={{ marginTop: '0.1em' }} />
          <Row>
            <div className="col-sm-3 p-0">
              <h6 style={styles.subtitle}>Nome do plano</h6>
            </div>
            <div className="col-md-3 p-0">
              <h6 style={styles.subtitle}>Data de cadastro</h6>
            </div>
            <div className="col-md-3 p-0">
              <h6 style={styles.subtitle}>Validade</h6>
            </div>
            <div className="col-md-3 p-0">
              <h6 style={styles.subtitle}>Data de cobrança</h6>
            </div>
          </Row>
          {userSubscriptions.map(subscription => {
            return (
              <Row key={subscription.nomePlano}>
                <div className="col-sm-3 p-0">
                  <p>{subscription.nomePlano}</p>
                </div>
                <div className="col-md-3 p-0">
                  <p>{subscription.dataAtivacao}</p>
                </div>
                <div className="col-md-3 p-0">
                  <p>{subscription.dataValidade}</p>
                </div>
                <div className="col-md-3 p-0">
                  <p>{subscription.dataCobranca}</p>
                </div>
              </Row>
            );
          })}
        </div>
      </div>
    </>
  );
};
