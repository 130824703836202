/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import NavBar from "../Termos/Navbar";
import { Creators as ProfileActions } from "../../store/ducks/user/profile/actions";
import { Creators as OnboardingActions } from "../../store/ducks/onboarding/actions";

import {
  Main,
  Container,
  ContainerSidebar,
  ContainerWizard,
  ContainerLoad,
  NavLit
} from "./styles";
import { ONBOARDING_PAGES } from "../../utils/constants";
import OnboardingSidebar from "./components/OnboardingSidebar";
import OnboardingContainer from "./components/OnboardingContainer";
import { gateway } from "../../utils/api";
import { toastErrorAndWarningOptions } from '../../utils/functions'
import Loading from "../Loading/Loading";
import history from '../../utils/history';

import adsTracking from '../../utils/adsTracking';

const IMG_LIT = require('../../images/lit.png');

const createCoupon = async () => {
  try {
    await gateway.post('recommendation/mgm');
  } catch (err) {
    console.log(err);
  }
}

const fetchUserRecommendations = async () => {
  try {
    const {
      ox_recommendedreference: { rk_reference: coupon },
    } = await gateway.get('recommendation/mgm/recommendations')

    if (!coupon) {
      await createCoupon();
    }
  } catch (err) {
    console.log(err);

    const couponNotCreated = err.response.status === 404;

    if (couponNotCreated) {
      await createCoupon();
    }
  }
}

class Onboarding extends Component {

  constructor(props) {
    super(props)
    const { notVisibleNavBar } = this.props
    this.state = {
      onboardingData: [],
      loading: true,
      notVisibleNavBar
    }
  }

  componentDidMount() {
    this.fetchOnboardingPages();
    this.sendCriteoEvent();
  }

  convertTypeFieldValue = item => {
    let value;
    switch (item.ox_questionconfiguration.wx_fieldtype) {
      case "RANGE":
        value = Number(item.wx_lastquestionanswer);
        break;
      case "TOGGLE":
        value = item.wx_lastquestionanswer
          ? item.wx_lastquestionanswer === "true"
          : false;
        break;
      default:
        value = item.wx_lastquestionanswer;
    }

    return value;
  };

  fetchOnboardingPages = async () => {
    try {
      const response = await gateway.get("/onboarding/steps");

      const filterStepPersonalData = response.find(item => item.wx_page === 'PERSONAL_DATA');

      if (response[0].bf_concluded !== true) {
        const areFieldsPresent = (obj) => {
          const { wx_address, wx_addressnumber, wx_country, wx_zipcode } = obj.ox_personaldata;
          return wx_address && wx_addressnumber && wx_country && wx_zipcode;
        };


        if (filterStepPersonalData && !areFieldsPresent(filterStepPersonalData)) {
          response.forEach((item, index) => {
            if (item.wx_page === 'PERSONAL_DATA') {
              response[index].bf_concluded = false;
            }
          });
        }
      }

      const { setOnboardingPages, setDynamicQuestions } = this.props;
      const pages = response.map(page => {
        if (!page.bf_concluded) {
          return {
            ...page,
            bf_concluded: false
          };
        }
        return page;
      });

      localStorage.setItem('tenantOnboarding', pages[0].tenant)
      const findDynamicPage = pages.reduce((acc, curr) => {
        if (curr.wx_page === ONBOARDING_PAGES.GOALS) {
          return [...acc, ...curr.qx_dynamicquestions]
        }
        return acc;
      }, []);

      if (findDynamicPage) {
        const qa = findDynamicPage.map(item => ({
          id: item._id,
          answer: this.convertTypeFieldValue(item)
        }));
        setDynamicQuestions(qa);
      }

      const hasRecommendationPage = pages.find(p => p.wx_page === ONBOARDING_PAGES.MGM);

      if (hasRecommendationPage) {
        await fetchUserRecommendations();
      }

      this.setState({ onboardingData: pages, loading: false });
      setOnboardingPages(pages);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error, toastErrorAndWarningOptions);
      } else {
        toast.error("Erro", toastErrorAndWarningOptions);
      }

      this.setState({ loading: false });
    }
  };

  sendCriteoEvent = () => {
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: 62787 },
      { event: "setEmail", email: "" },
      {
        event: "setSiteType",
        type: /iPad/.test(navigator.userAgent)
          ? "t"
          : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
            ? "m"
            : "d"
      },
      {
        event: "trackTransaction",
        id: new Date().getTime(),
        item: [{ id: "1", price: 1, quantity: 1 }]
      }
    );
  };

  logout() {
    history.push('/logout');
  }


  render() {
    const { onboardingData, loading, notVisibleNavBar } = this.state
    return (
      <>
        {!notVisibleNavBar ? (
          <NavLit className="navbar navbar-lit navbar-expand-lg bg-gradient-lit">
            <a className="navbar-brand">
              <img src={IMG_LIT} alt="logo_lit" style={{ width: '100px' }} />
            </a>
          </NavLit>
        ) : <NavBar />}
        <Main>
          <Container>
            <ContainerSidebar>
              <OnboardingSidebar isVisibleText />
            </ContainerSidebar>
            {loading && (
              <ContainerLoad>
                <Loading width="30px" />
              </ContainerLoad>
            )}

            {onboardingData.length > 0 && (
              <ContainerWizard>
                <OnboardingContainer onboardingData={onboardingData} />
              </ContainerWizard>
            )}
          </Container>
        </Main>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ProfileActions,
      ...OnboardingActions
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Onboarding);
