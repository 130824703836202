import styled from "styled-components";
import { } from "../../../constants";

export const ModalBody = styled.div.attrs({
  className: "modal-body"
})`
  text-align: center;
  position: relative;
`;

export const Info = styled.span`
  width: 20%;
  display: inline-block;
  label:last-of-type {
    font-size: 14px;
  }
  @media (max-width: 1440px) {
    width: 40%;
  }
  @media (max-width: 767px) {
    display: flex !important;
    width: 100%;
    justify-content: space-evenly;
  }
`;

export const TextDiv = styled.span`
  width: 80%;
  margin-left: 15px;
  @media (max-width: 1440px) {
    width: 60%;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const Confirm = styled.div`
  margin-top: 20px;
  display: block;
  width: 96%;
  text-align: center;
  clear: both;
  bottom: 0;
  position: absolute;
`;

export const Block = styled.div`
  width: calc(100% - 3rem);
  margin-left: 1rem;
  text-align: left;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;;
  }
`;
