import React, { memo } from "react"
import styled from "styled-components"

const Gradient = styled.div`
  background-color: #27aae1;
  filter: progid:DXImageTransform.Microsoft.gradient( GradientType=1, startColorstr=#27AAE1, endColorstr=#FCEE1E);
  background-image: -moz-linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -webkit-linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -ms-linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -o-linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0%, #27aae1),
    color-stop(100%, #fcee1e)
  );
`
const ImgContainer = styled(Gradient)`
  height: 152px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const TextBox = styled.label`
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
`

const CardImgDefault = ({ redirect = () => {}, courseName }) => {
  return (
    <ImgContainer onClick={redirect}>
      <TextBox>{courseName}</TextBox>
    </ImgContainer>
  )
}

export default memo(CardImgDefault)
