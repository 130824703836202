import React from "react";
import Professor from "./Professor";
import * as Styles from './styles';

const Professores = ({ data }) => {
  return (
    <Styles.ObjectDescription>
        <h5 style={{ color: "#27aae1" }} className="card-title">
          Professores ministrando o curso
        </h5>
        <div className="row">
          {data.map(professor => {
            return <Professor key={professor.id} {...professor} />;
          })}
        </div>
    </Styles.ObjectDescription>
  );
};

export default Professores;
