/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pdfjs from '@devlit/react-pdfjs';
import '@devlit/react-pdfjs/dist/index.css';

import { toast } from 'react-toastify';
import md5 from 'md5';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { gateway } from '../../utils/api';
import { toastErrorAndWarningOptions } from '../../utils/functions';
// import PlanType from '../Pagamento/planType';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background:
      'linear-gradient(45deg, rgba(2, 196, 255, 1) 25%, rgba(142, 230, 123, 1) 100%)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalViewTerm({
  visible,
  onClose,
  termType,
  termId,
  fromPayment,
  text,
  // planType,
  // idPlan,
  onSetTerm,
  url: termUrl,
  isNewTerm
}) {
  const [termText, setTerm] = useState('');
  const idProfile = localStorage.getItem('idProfile');
  const email = localStorage.getItem('ra');
  async function loadPlanTerm() {
    try {
      let url = '';
      // if (fromPayment) {
      //   url = `/subscription/termcreate?idProfile=${idProfile}&idPlan=${idPlan}&idTerm=${termId}&typePlan=${
      //     planType === PlanType.Yearly ? 'Anual' : 'Mensal'
      //   }`;
      // } else {
      url = `/subscription/term?idProfile=${idProfile}&idTerm=${termId}`;
      // }
      if (fromPayment) {
        const { wu_media_html, ox_term } = await gateway.get(url);
        onSetTerm(ox_term);
        setTerm(wu_media_html);
      } else {
        const response = await gateway.get(url);
        setTerm(response);
      }
    } catch (error) {
      toast.error('Erro ao buscar termo!', toastErrorAndWarningOptions);
      onClose();
    }
  }

  async function loadUseOrPolicyTerm() {
    try {
      const response = await gateway.get(`/term/${termId}`);
      if (
        response &&
        response.qi_presentation &&
        response.qi_presentation[0] &&
        response.qi_presentation[0].wu_media_html
      ) {
        const rawTermText = response.qi_presentation[0].wu_media_html;
        const termHash = md5(`${termId}-${email}`);
        setTerm(rawTermText.replace('**assinatura**', termHash));
      }
    } catch (error) {
      toast.error('Erro ao buscar termo!', toastErrorAndWarningOptions);
      onClose();
    }
  }

  async function fetchTerms() {
    try {
      if (termType === 'PLAN' && !isNewTerm) {
        await loadPlanTerm();
      } else if (termType === 'PLAN' && isNewTerm) {
        setTerm(termUrl);
      } else {
        await loadUseOrPolicyTerm();
      }
    } catch (error) {
      toast.error('Erro ao buscar termo!', toastErrorAndWarningOptions);
      onClose();
    }
  }

  useEffect(() => {
    if (visible && !text) {
      fetchTerms();
    } else {
      setTerm(text);
    }
  }, [visible]);

  function handleClose() {
    setTerm('');
    onClose();
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {termText.length > 0 ? (
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                {/* <CloseIcon /> */}
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Termo
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Fechar
              </Button>
            </Toolbar>
          </AppBar>
          <div className="modal-content">
            <div className="modal-body d-flex justify-content-center">
              {isNewTerm && (
                <div id="pdf-div-id" className="pdf-wrapper">
                  <Pdfjs onDownload onFullScreen onScrollMode file={termText} />
                </div>
              )}

              {!isNewTerm && (
                <div dangerouslySetInnerHTML={{ __html: termText }} />
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-blue"
                onClick={handleClose}
              >
                Fechar
              </button>
            </div>
          </div>
        </Dialog>
      ) : (
        ''
      )}
    </div>
  );
}

ModalViewTerm.propTypes = {
  text: PropTypes.string
};

ModalViewTerm.defaultProps = {
  text: ''
};
