import React, {useMemo} from 'react';
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa';

import Radio from '../../../../../shared/Radio';
import Checkbox from '../../../../../shared/Checkbox';
import {Container, UserAnswer} from './styles';

export default function AnswerResult({data, type}) {

  const {expectedAnswer, status} = data;
  const checked = useMemo(() => {
    return expectedAnswer
  }, [expectedAnswer]);

  return (
    <Container>
      {type === 'MULTIPLE_CHOICE' && (
        <div id="checkbox">
          <label>
            <span style={{ marginRight: 8, color: '#666' }}> 
              RESPOSTA ESPERADA
            </span>
            <Checkbox
              disabled
              styleContainer={{marginTop: 0}}
              checked={checked}
              color="#E0E0E0"
              style ={{marginTop: 0}}
            />   
        </label>
      </div>
      )}

      {type === 'SINGLE_CHOICE' && (
         <Radio
          disabled
          color="#6AC259"
          type="radio" 
          checked={checked}
          textPosition="right"
          viewMode
          // name="singlechoiceradio"
          labelText="RESPOSTA ESPERADA" 
        />
      )}
     
      {(status !== 'EMPTY') && (
        <UserAnswer 
          noMargin={type !== 'MULTIPLE_CHOICE' && type !== 'SINGLE_CHOICE'} status={status}>
          {(status === 'CORRECT') && (
            <>
              <label>LEGAL, VOCÊ ACERTOU</label>
              <FaCheckCircle />
            </>
          )}
          {(status === 'INCORRECT') && (
            <>
              <label>OPS, VOCÊ NÃO MARCOU CERTO</label>
              <FaTimesCircle />
            </>
          )}
        </UserAnswer>
      )}
     
    </Container>
  )
}
