import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {String} title //displays title information - first line
 * @param {String} subtitle //displays subtitle information - second line
 * @param {String} description //displays content description - third line
 * @param {Date} start //display initial date
 * @param {Date} end //display final date
**/

export default ({
  title = DEFAULT_VALUES.STRING,
  subtitle = DEFAULT_VALUES.STRING,
  description = DEFAULT_VALUES.STRING,
  start,
  end
}) => {
  return (
    <>
      <Screen
        title={title}
        subtitle={subtitle}
        description={description}
        start={start}
        end={end}
      />
    </>
  );
};
