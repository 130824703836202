import React from "react";
import { AccordionContainer } from "./styles";

export default ({ child, id, appearance }) => {
  return (
    <>
      <AccordionContainer defaultActiveKey={id} appearance={appearance}>
        {child}
      </AccordionContainer>
    </>
  );
};
