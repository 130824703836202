import React from "react";
import { COLOR, ICON, SIZE, APPEARANCE } from "../../constants";
import Icon from "../basicComponents/Icon";
import Button from "../basicComponents/Button";
import Link from "../basicComponents/Link";
import Block from "../basicComponents/Block";
import { Archives, Agrupable } from "./styles";

export default ({ archives, color, removeAction, validateType }) => {
  return (
    <>
      <Archives>
        {archives.map(archive => (
          <Block color={color} appearance={APPEARANCE.ARCHIVE_ITEM}>
            <Agrupable>
              <Icon
                icon={validateType(archive.type)}
                color={COLOR.TEXT_PRIMARY}
                size={SIZE.SMALL}
              />
              <Link
                href={archive.fileContent}
                target="_blank"
                text={`${archive.name}.${archive.type}`}
              />
            </Agrupable>
            {removeAction ? (
              <Button
                icon={ICON.REMOVE}
                appearance={APPEARANCE.LINK}
                color={COLOR.TEXT_PRIMARY}
                onClick={() => removeAction(archive)}
              />
            ) : (
              <></>
            )}
          </Block>
        ))}
      </Archives>
    </>
  );
};
