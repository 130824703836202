import styled from 'styled-components'

export const Container = styled.div`
 .no-suggestions {
    color: #999;
    padding: 0.5rem 0;
    display: flex;
    font-size: 11px;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
      font-size: 16px;
    }
  }

  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 243px;
    overflow-y: auto;
    padding: 0;
    width: calc(300px + 1rem);
    background: #fff;
    position: absolute;
    z-index: 9999;

  }

  .suggestions li {
    padding: 0.5rem;
    font-size: 12px;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: #f1f1f1;
    color: #27aae1;
    cursor: pointer;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }

  
`