import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../constants";

/**
 * @param {String} grid //{ONE, TWO, THREE} number of blocks per line
 * @param {Component} componentToRender //content that will be rendered
 * @param {String|Number} ref //list reference value
**/

export default ({
  grid = DEFAULT_VALUES.STRING,
  componentToRender = DEFAULT_VALUES.COMPONENT,
  ref
}) => {
  return (
    <>
      <Screen
        grid={grid}
        ref={ref}
        componentToRender={componentToRender}
      />
    </>
  );
};
