import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  padding: 20px 30px;
  max-width: 1024px;
  margin: 0 auto;

  .slick-track:hover {
    transition: none;
    transform: none;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:hover .slick-slide {
    transition: none;
    transform: none;
  }

  .slick-slide:hover ~ .slick-slide {
    transition: none;
    transform: none;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  p {
    margin-bottom: 5px;
    margin-top: 15px;
  }
`;

export const Loading = styled.div`
  position: fixed;
  z-index: 201;
  top: 66px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(0px);
  background: transparent;
  height: calc((100% - 66px) - 75px);
`;

export const BoxInfo = styled.button`
  display: flex !important;
  background: ${({ active }) => (active ? "#27aae1" : "#fff")};
  width: ${props => (props.width ? `${props.width}px` : "200px")};
  height: 100px;
  padding: 5px 5px;
  border: 1px solid ${({ active }) => (active ? "#27aae1" : "#dfdfdf")};
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 673px) {
    width: 100%;
    margin-bottom: 5px;
  }

  & + div {
    margin-left: 5px;
  }

  p {
    text-transform: uppercase;
    font-size: 10px;
    margin: auto;
  }

  p.highlight {
    font-weight: bold;
    font-size: 15px;
    color: ${({ active }) => (active ? "#fff" : "")};
  }
`;
