import styled from "styled-components";
import { } from "../../../constants";

export const Cards = styled.div`
  margin: 2rem 0;
  display: table;
`;

export const AlignButtons = styled.span`
  display: table;
  button { margin-left: 5px; }
  @media screen and (max-width: 991px) {
    margin: 10px auto;
  }
`;
