import React from "react";
import Screen from "./screen";
import { DEFAULT_VALUES } from "../../../constants";

/**
 * @param {Function} onClick //action triggered when clicking on the item
 * @param {Component} children //content that will be clickable
 * @param {String} appearance //{LINK, LINK_ICON_ACTIVE, MORE} appearance of clickable content
**/

export default ({
  onClick = DEFAULT_VALUES.FUNCTION,
  onEnter = DEFAULT_VALUES.FUNCTION,
  onLeave = DEFAULT_VALUES.FUNCTION,
  appearance = DEFAULT_VALUES.STRING,
  children = DEFAULT_VALUES.COMPONENT
}) => {
  return (
    <>
      <Screen
        onClick={onClick}
        onEnter={onEnter}
        onLeave={onLeave}
        appearance={appearance}
        child={children}
      />
    </>
  );
};
