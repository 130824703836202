/* eslint-disable react/no-array-index-key */
import React from 'react'
import {useSelector} from 'react-redux'
import {FaArrowRight, FaArrowLeft, FaSpinner} from 'react-icons/fa'

import {ONBOARDING_BUTTON_PAGES, ONBOARDING_PAGES} from '../../../../utils/constants'

import {
  Container, 
  ActionButton, 
  Steps, 
  Step, 
  StepConcluded, 
  SkipStepContainer,
  SkipStepButton,
  PreviousButton,
  NextButton
} from './styles'

export default function OnboardingBottomStepper({
    currentPage, 
    numberOfPages, 
    onNextPage,
    onSkipPage, 
    onPreviousPage,
    isLoading,
    skipStep
  }) {

  const onboardingData = useSelector(state => state.onboarding.onboardingData)

  function renderTitle() {
    if(onboardingData[currentPage].wx_page === ONBOARDING_PAGES.PERSONALIZE) {
      return 'Personalizar'
    }
    if(onboardingData.length === currentPage + 1) {
      return 'Finalizar'
    }
    return 'Próximo' 
  }
  
  return (
    <>
      <Container>
        <PreviousButton
          id="previousButton"
          onClick={() => {
            if (onPreviousPage) {
              onPreviousPage();
            }
          }}
          disabled={isLoading}
          hide={currentPage <= 0}
        >
          <FaArrowLeft />
          <span>Anterior</span>
        </PreviousButton>

        <Steps>
          {[...Array(numberOfPages)].map((_, index) => (
              onboardingData.length && onboardingData[index].bf_concluded ? (
                <StepConcluded
                  key={`stepper-circle-${index}`}
                  concluded
                  active={currentPage === index}
                >
                  {index + 1}
                </StepConcluded>
              ) : (
                <Step
                  key={`stepper-circle-${index}`}
                  active={currentPage === index}
                >
                  {index + 1}
                </Step>
              )
          ))}
        </Steps>  

        <NextButton
          id="nextButton"
          onClick={() => {
            onNextPage();
          }}
          disabled={isLoading}
        >
          <span>{renderTitle()}</span>
          {isLoading ? (
            <FaSpinner className="animated" />
          ) : (
            <FaArrowRight />
          )}
        
        </NextButton>
      </Container>
      {skipStep && (
        <SkipStepContainer>
          <SkipStepButton id={`skipButton-${currentPage}`} onClick={onSkipPage} type="button">Pular etapa</SkipStepButton>
        </SkipStepContainer>
      )}
    </>
  )
}