import { Types } from "./types";
import { randomNumber } from "../../../../utils/constants";

const postMock = {
  postId: randomNumber(),
  comments: [],
  contentPost:
    "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.",
  member: {
    memberId: 0,
    name: "Marcos Sanchez",
    memberSince: "12/12/12",
    role: [{ id: 1, name: "Administrador" }],
    img: "https://picsum.photos/id/1005/300/300"
  },
  archives: []
};

const INITIAL_STATE = {
  loading: false,
  called: false,
  error: null,
  data: [
    {
      postId: randomNumber(),
      contentPost:
        "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.",
      member: {
        memberId: 0,
        name: "Marcos Sanchez",
        memberSince: "12/12/12",
        role: [{ id: 1, name: "Administrador" }],
        img: "https://picsum.photos/id/1005/300/300"
      },
      archives: [
        {
          extension: "PDF",
          name: "omne_animal_simul_atque.pdf",
          link: "https://camara.fgv.br/sites/camara.fgv.br/files/artigos/teste33_0_3.pdf"
        },
        {
          extension: "IMAGE",
          name: "laudem_et_quasi.jpg",
          link: "https://app.lit.com.br/api/images/courses/1$_$1544.jpg"
        },
        {
          extension: "VIDEO",
          name: "si_sine_dublo_praeclara.avi",
          link: "http://telmaxbr.net.br/download/videos/avi_1.avi"
        },
        {
          extension: "FILE",
          name: "laudem_lorem.doc",
          link: "http://paginapessoal.utfpr.edu.br/amauriassef/tcc-engenharia/Modelo%20para%20formatacao%20de%20Trabalhos%20Academicos%20da%20UTFPR-vs5.doc"
        }
      ],
      totalLikes: 128,
      totalDislikes: 129,
      comments: [
        {
          postId: randomNumber(),
          contentPost:
            "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.",
          member: {
            memberId: 0,
            name: "Marcos Sanchez",
            memberSince: "12/12/12",
            role: [{ id: 1, name: "Administrador" }],
            img: "https://picsum.photos/id/1005/300/300"
          },
          comments: [],
          archives: [
            {
              extension: "PDF",
              name: "omne_animal_simul_atque.pdf",
              link: "https://camara.fgv.br/sites/camara.fgv.br/files/artigos/teste33_0_3.pdf"
            },
            {
              extension: "FILE",
              name: "laudem_lorem.doc",
              link: "http://paginapessoal.utfpr.edu.br/amauriassef/tcc-engenharia/Modelo%20para%20formatacao%20de%20Trabalhos%20Academicos%20da%20UTFPR-vs5.doc"
            }
          ],
          totalLikes: 2,
          totalDislikes: 3
        },
        {
          postId: randomNumber(),
          contentPost:
            "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.",
          member: {
            memberId: 0,
            name: "Marcos Sanchez",
            memberSince: "12/12/12",
            role: [{ id: 1, name: "Administrador" }],
            img: "https://picsum.photos/id/1005/300/300"
          },
          comments: [],
          archives: [
            {
              extension: "VIDEO",
              name: "si_sine_dublo_praeclara.avi",
              link: "http://telmaxbr.net.br/download/videos/avi_1.avi"
            },
            {
              extension: "FILE",
              name: "laudem_lorem.doc",
              link: "http://paginapessoal.utfpr.edu.br/amauriassef/tcc-engenharia/Modelo%20para%20formatacao%20de%20Trabalhos%20Academicos%20da%20UTFPR-vs5.doc"
            }
          ],
          totalLikes: 2,
          totalDislikes: 3
        }
      ]
    }
  ],
  individualPosts: [
    {
      postId: randomNumber(),
      contentPost:
        "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.",
      member: {
        memberId: 0,
        name: "Marcos Sanchez",
        memberSince: "12/12/12",
        role: [{ id: 1, name: "Administrador" }],
        img: "https://picsum.photos/id/1005/300/300",
      },
      comments: [],
      archives: [
        {
          extension: "PDF",
          name: "omne_animal_simul_atque.pdf",
          link: "https://camara.fgv.br/sites/camara.fgv.br/files/artigos/teste33_0_3.pdf"
        },
        {
          extension: "IMAGE",
          name: "laudem_et_quasi.jpg",
          link: "https://app.lit.com.br/api/images/courses/1$_$1544.jpg"
        }
      ],
      vinculatedGroup: {
        name: "Diam sollicitudin tempor id eu nisl nunc mi ipsum",
        date: "08/10/2020"
      },
      totalLikes: 2,
      totalDislikes: 3
    },
    {
      postId: randomNumber(),
      contentPost:
        "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.  Diam sollicitudin tempor id eu nisl nunc mi ipsum. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae.",
      member: {
        memberId: 0,
        name: "Marcos Sanchez",
        memberSince: "12/12/12",
        role: [{ id: 1, name: "Administrador" }],
        img: "https://picsum.photos/id/1005/300/300"
      },
      comments: [],
      archives: [],
      vinculatedGroup: {
        name: "Euismod lacinia at quis",
        date: "10/01/2020"
      },
      totalLikes: 281,
      totalDislikes: 282
    },
    {
      postId: randomNumber(),
      contentPost:
        "Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.",
      member: {
        memberId: 0,
        name: "Marcos Sanchez",
        memberSince: "12/12/12",
        role: [{ id: 1, name: "Administrador" }],
        img: "https://picsum.photos/id/1005/300/300"
      },
      comments: [],
      archives: [],
      vinculatedGroup: {
        name: "Euismod lacinia at quis",
        date: "08/02/2018"
      },
      totalLikes: 37,
      totalDislikes: 38
    },
    {
      postId: randomNumber(),
      contentPost:
        "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.",
      member: {
        memberId: 0,
        name: "Marcos Sanchez",
        memberSince: "12/12/12",
        role: [{ id: 1, name: "Administrador" }],
        img: "https://picsum.photos/id/1005/300/300"
      },
      comments: [],
      archives: [
        {
          extension: "PDF",
          name: "omne_animal_simul_atque.pdf",
          link: "https://camara.fgv.br/sites/camara.fgv.br/files/artigos/teste33_0_3.pdf"
        },
        {
          extension: "FILE",
          name: "laudem_lorem.doc",
          link: "http://paginapessoal.utfpr.edu.br/amauriassef/tcc-engenharia/Modelo%20para%20formatacao%20de%20Trabalhos%20Academicos%20da%20UTFPR-vs5.doc"
        }
      ],
      vinculatedGroup: {
        name: "Arcu non odio euismod lacinia at quis",
        date: "28/01/2018"
      },
      totalLikes: 11,
      totalDislikes: 12
    },
    {
      postId: randomNumber(),
      contentPost:
        "Lacus vestibulum sed arcu non odio euismod lacinia at quis. Mattis rhoncus urna neque viverra justo nec ultrices dui sapien. A cras semper auctor neque vitae. Diam sollicitudin tempor id eu nisl nunc mi ipsum.",
      member: {
        memberId: 0,
        name: "Marcos Sanchez",
        memberSince: "12/12/12",
        role: [{ id: 1, name: "Administrador" }],
        img: "https://picsum.photos/id/1005/300/300"
      },
      comments: [],
      archives: [
        {
          extension: "FILE",
          name: "laudem_lorem.doc",
          link: "http://paginapessoal.utfpr.edu.br/amauriassef/tcc-engenharia/Modelo%20para%20formatacao%20de%20Trabalhos%20Academicos%20da%20UTFPR-vs5.doc"
        }
      ],
      vinculatedGroup: {
        name: "Euismod lacinia at quis",
        date: "18/08/2018"
      },
      totalLikes: 3,
      totalDislikes: 4
    }
  ]
};

function findAndRemovePost(post, id) {
  const result = {
    target: post,
    replaced: false
  };

  if (post.postId == id) {
    result.replaced = true;
    result.target = { ...post };
    return result;
  }

  if (post.comments) {
    for (let i = 0; i < post.comments.length; i++) {
      const subResult = findAndRemovePost(post.comments[i], id);

      if (subResult.replaced) {
        result.replaced = true;
        result.target.comments[i] = subResult.target;
        return result;
      }
    }
  }

  return result;
}

function recursiveFindAndRemovePost(posts, targetId) {
  for (let i = 0; i < posts.length; i++) {
    let post = posts[i];
    const result = findAndRemovePost(post, targetId);
    if (result.replaced) {
      posts[i] = result.target;
      break;
    }
  }

  return posts;
}

function findPostAndReplace(post, id, replace) {
  const result = {
    target: post,
    replaced: false
  };

  if (post.postId == id) {
    result.replaced = true;
    result.target = { ...post, ...replace };
    return result;
  }

  if (post.comments) {
    for (let i = 0; i < post.comments.length; i++) {
      const subResult = findPostAndReplace(post.comments[i], id, replace);

      if (subResult.replaced) {
        result.replaced = true;
        result.target.comments[i] = subResult.target;
        return result;
      }
    }
  }

  return result;
}

function recursivePostsReplace(posts, targetId, replace) {
  for (let i = 0; i < posts.length; i++) {
    let post = posts[i];
    const result = findPostAndReplace(post, targetId, replace);
    if (result.replaced) {
      posts[i] = result.target;
      break;
    }
  }

  return posts;
}

function findPostAndCreateComment(post, id, newComment) {
  const result = {
    target: post,
    replaced: false
  };

  if (post.postId == id) {
    result.replaced = true;
    result.target = {
      ...post,
      comments: [...post.comments, { ...postMock, ...newComment }]
    };
    return result;
  }

  if (post.comments) {
    for (let i = 0; i < post.comments.length; i++) {
      const subResult = findPostAndCreateComment(
        post.comments[i],
        id,
        newComment
      );

      if (subResult.replaced) {
        result.replaced = true;
        result.target.comments[i] = subResult.target;
        return result;
      }
    }
  }

  return result;
}

function recursivePostsCreateComment(posts, targetId, newComment) {
  for (let i = 0; i < posts.length; i++) {
    let post = posts[i];
    const result = findPostAndCreateComment(post, targetId, newComment);
    if (result.replaced) {
      posts[i] = result.target;
      break;
    }
  }

  return posts;
}

export default function members(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_POSTS:
      return {
        ...state,
        loading: false,
        called: true,
        data: action.payload.data
      };
    case Types.CREATE_POST:
      return {
        ...state,
        loading: false,
        called: true,
        data: [
          ...state.data,
          {
            ...postMock,
            contentPost: action.payload.data,
            postId: randomNumber()
          }
        ]
      };
    case Types.UPDATE_POSTS:
      return {
        ...state,
        loading: false,
        called: true,
        data: [
          ...recursivePostsReplace(
            state.data,
            action.payload.data.postId,
            action.payload.data.postContent
          )
        ]
      };
    case Types.REMOVE_POST:
      console.log(state.data, action.payload.data);
      return {
        ...state,
        loading: false,
        called: true,
        data: [...recursiveFindAndRemovePost(state.data, action.payload.data)]
      };
    case Types.CREATE_COMMENT:
      console.log("pay", action.payload.data);
      return {
        ...state,
        loading: false,
        called: true,
        data: [
          ...recursivePostsCreateComment(
            state.data,
            action.payload.data.postId,
            action.payload.data.commentContent
          )
        ]
      };
    default:
      return state;
  }
}
