import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Axios from 'axios';
import fileDownload from 'js-file-download';

import {
  Container,
  Spacing,
  Title,
  WrapperInfo,
  WrapperMedias,
  BoxInfo,
  Button,
  BoxVideo
} from './styles';
import { convertHmsInSeconds } from '../../../../utils/convertHmsInSeconds';
import Video from '../../../shared/Video';

function Instructions({
  questionCount,
  totalAverageTime,
  averageTimePerQuestion,
  timeLimit,
  totalAttempts,
  minimumCriterion,
  medias,
  type,
  tags = [],
  attemptsMade,
  availabitity,
}) {
  function downloadMedia(e, media) {
    if (media.type !== 'VIDEO') {
      e.preventDefault();
    }
    const url = media.path;
    const filename = media.name;
    const extension = media.path.slice(
      ((media.path.lastIndexOf('.') - 1) >>> 0) + 2
    );

    Axios.get(url, {
      responseType: 'blob'
    }).then(res => {
      fileDownload(res.data, `${filename}.${extension}`);
    });
  }

  const hasTagVmp = useMemo(() => {
    return tags.includes('VMP');
  }, [tags]);

  const formatTotalAttempts = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    return totalAttempts > 0 ? totalAttempts : 'Ilimitado';
  }, [totalAttempts])

  const mediaVideo = useMemo(() => {
    return medias.find(media => media.type === 'VIDEO');
  }, [medias])

  return (
    <Container>
      {questionCount > 0 && (
        <>
          <Title>Instruções</Title>
          <WrapperInfo>
            {!hasTagVmp && <BoxInfo>
              <p>QTD Questões</p>
              <p className="highlight">{questionCount}</p>
            </BoxInfo>}
            {type === 'FORUM' || type === 'EXAM' || type === 'Exame' || type === 'Avaliação Final' || type === 'FINAL_EXAM' ? null : (
              <>
                <BoxInfo>
                  <p>Tempo médio total</p>
                  <p className="highlight">{totalAverageTime}</p>
                </BoxInfo>
                <BoxInfo>
                  <p>Tempo médio por questão</p>
                  <p className="highlight">{averageTimePerQuestion}</p>
                </BoxInfo>
              </>
            )}
            {(!hasTagVmp || !!convertHmsInSeconds(timeLimit)) && <BoxInfo>
              <p>Tempo limite</p>
              <p className="highlight">{timeLimit}</p>
            </BoxInfo>}
            <BoxInfo>
              <p>Total de tentativas</p>
              <p className="highlight">{formatTotalAttempts}</p>
            </BoxInfo>
            {minimumCriterion > 0 && <BoxInfo>
              <p>Média esperada</p>
              <p className="highlight">{(minimumCriterion / 10).toFixed(1)}</p>
            </BoxInfo>}

            {/* Status */}
            {type === 'FORUM' || type === 'EXAM' || type === 'Exame' || type === 'Avaliação Final' || type === 'FINAL_EXAM' ? (
              <BoxInfo>
                <p>Tentativas executadas</p>
                <p className="highlight">{attemptsMade} {(totalAttempts > 0) && `/ ${totalAttempts}`}</p>
              </BoxInfo>
            ) : (
              <>
                <BoxInfo>
                  <p>Tentativas executadas</p>
                  <p className="highlight">{attemptsMade} {(totalAttempts > 0) && `/ ${totalAttempts}`}</p>
                </BoxInfo>
                <BoxInfo>
                  <p>Disponibilidade</p>
                  <p className="highlight">{availabitity}</p>
                </BoxInfo>
              </>
            )}

          </WrapperInfo>
        </>
      )}
      {mediaVideo && (
        <BoxVideo>
          <Video id={mediaVideo.id} path={mediaVideo.path} />
        </BoxVideo>
      )}
      {medias.length > 0 && (
        <Spacing>
          <Title>Mídias</Title>
          <WrapperMedias>
            {medias.map(media => {
              return (
                <BoxInfo width={230}>
                  <p>{media.name}</p>
                  <Button onClick={e => downloadMedia(e, media)}>
                    <a href={media.path} download target="_blank">
                      {' '}
                      Baixar
                    </a>
                  </Button>
                </BoxInfo>
              );
            })}
          </WrapperMedias>
        </Spacing>
      )}
    </Container>
  );
}

Instructions.propTypes = {
  questionCount: PropTypes.number.isRequired,
  timeLimit: PropTypes.number.isRequired,
  totalAttempts: PropTypes.number.isRequired,
  minimumCriterion: PropTypes.number.isRequired,
  totalAverageTime: PropTypes.number,
  averageTimePerQuestion: PropTypes.number,
  evaluationRules: PropTypes.string
};

Instructions.defaultProps = {
  totalAverageTime: null,
  averageTimePerQuestion: null,
  evaluationRules: ''
};

export default Instructions;
